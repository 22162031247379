import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import * as currentUser from 'sow/selectors/currentUser';
import { registerRoute } from 'sow/routes';
import actions from 'sow/actions/pure';
import AuthenticatingSpinner from 'sow/components/old/auth/AuthenticatingSpinner';
import CenteredLayout from 'sow/components/templates/CenteredLayout';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';

const mapStateToProps = (state, props) => ({
  isAuthenticated: currentUser.isAuthenticated(state, props),
  isAuthenticating: currentUser.isAuthenticating(state, props),
});

const mapDispatchToProps = {
  ecertLogin: actions.auth.ecertLogin,
};

class EcertLoginPageContainer extends Component {
  componentDidMount() {
    const { ecertLogin, isAuthenticated, location } = this.props;
    const queryParams = R.propOr({}, 'search', location);

    if (!isAuthenticated) {
      ecertLogin(queryParams);
    }
  }

  render() {
    const { isAuthenticating } = this.props;

    return (
      <CenteredLayout>
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2 text-left">
            {isAuthenticating ? (
              <AuthenticatingSpinner />
            ) : (
              <Widget>
                <WidgetBody>
                  <p style={{ marginTop: '10px' }}>
                    Don’t have an account with WFCF Organic yet?{' '}
                    <a
                      href="www.wfcforganic.com/request-a-quote"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Apply now!
                    </a>
                  </p>
                </WidgetBody>
              </Widget>
            )}
          </div>
        </div>
      </CenteredLayout>
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(EcertLoginPageContainer);
