import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import RS from 'react-select';

import { orgType } from 'sow/types';
import * as currentAca from 'sow/selectors/currentAca';
import AcaOrgListLoader from 'sow/containers/AcaOrgListLoader';

const sortOrgsByName = R.sortWith([R.ascend(R.prop('name'))]);

const mapStateToProps = (state, props) => ({
  orgList: sortOrgsByName(currentAca.orgs(state, props)),
});

const mapOrgsToOptions = org => ({
  value: org.id,
  label: org.name,
});

const CurrentAcaOrgSelector = ({ orgList, id, value, onChange }) => (
  <AcaOrgListLoader>
    <RS
      id={id}
      value={value}
      onChange={option => onChange(option.value)}
      options={orgList.map(mapOrgsToOptions)}
      style={{ width: '250px' }}
    />
  </AcaOrgListLoader>
);

CurrentAcaOrgSelector.propTypes = {
  orgList: PropTypes.arrayOf(orgType).isRequired,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
};

CurrentAcaOrgSelector.defaultProps = {
  id: 'orgId',
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(CurrentAcaOrgSelector);
