import * as wsAnswersAction from 'sow/actions/worksheetAnswers';
import { makeImmutable } from 'sow/utils/data';

// const actionToWorksheetAnswerIds = R.compose(
//   R.keys,
//   R.pathOr({}, ['data', 'entities', 'worksheetAnswers'])
// );

const ids = (state = makeImmutable([]), action) => {
  switch (action.type) {
    case wsAnswersAction.LOAD_ALL_FOR_ORG_OSP_APP_SUCCESS:
    // TODO empty this on OSP APP load
    // return action.data.result;
    case wsAnswersAction.SAVE_ONE_WORKSHEET_SUCCESS:
      return makeImmutable(R.union(state, action.data.result));

    // case landAnswersAction.LOAD_ONE_REQUEST:
    // case productAnswersAction.LOAD_ONE_REQUEST:
    //   return [];

    // case landAnswersAction.LOAD_ONE_SUCCESS:
    // case productAnswersAction.LOAD_ONE_SUCCESS:
    //   return actionToWorksheetAnswerIds(action);
    // // case landAnswersAction.LOAD_ONE_FAILURE:
    // // case productAnswersAction.LOAD_ONE_FAILURE:

    default:
      return state;
  }
};

export default ids;
