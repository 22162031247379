import * as R from 'ramda';

import actions from 'sow/actions/pure';

// TODO rename this to `byId` and change the list view (prev byId) to list
const detailById = (state = {}, action) => {
  switch (action.type) {
    // case String(actions.aca.loadOne):
    // case String(actions.aca.loadOneFail):
    case String(actions.aca.loadOneDone):
      if (R.path(['payload', 'entities', 'acas'], action)) {
        return R.merge(state, action.payload.entities.acas);
      }

    default:
      return state;
  }
};

export default detailById;

// export const getIds = state => Object.keys(state);
// export const getAca = (state, id) => state[id];
// export const getAcaList = state => getIds(state).map(id => state[id]);
