import {
  REGISTER,
  REGISTER_DONE,
  REGISTER_FAIL,
  VALIDATE_MAIL,
  VALIDATE_MAIL_DONE,
  VALIDATE_MAIL_FAIL,
} from 'sow/actions/pure/registration';

// Registration
const initialRegistrationState = {
  isRegistering: false,
  registrationErrors: null,
  isValidatingMail: false,
};

export default function registration(state = initialRegistrationState, action) {
  switch (action.type) {
    case REGISTER:
      return { ...state, isRegistering: true, registrationErrors: null };

    case REGISTER_DONE:
      return { ...state, isRegistering: false };

    case REGISTER_FAIL: {
      const { errors } = action.payload;
      return { ...state, isRegistering: false, errors };
    }

    case VALIDATE_MAIL:
      return { ...state, isValidatingMail: true };

    case VALIDATE_MAIL_DONE:
    case VALIDATE_MAIL_FAIL:
      return { ...state, isValidatingMail: false };

    default:
      return state;
  }
}
