import React from 'react';

const FormikColorPicker = ({ value, id, setFieldValue }) => (
  <input
    type="color"
    className="formik-color-picker"
    onChange={event => {
      setFieldValue(id, event.target.value);
    }}
    value={value ? value : '#000000'}
  />
);

export default FormikColorPicker;
