export const formatPhoneNumber = phoneNumber => {
  const regex = /\D/g;
  const letterRegex = /[a-zA-Z]/g;
  const containsLetter = phoneNumber.match(letterRegex);
  const onlyNumbers = phoneNumber.replace(regex, '');
  if (onlyNumbers.length == 10 && !containsLetter) {
    return (
      '(' +
      onlyNumbers.slice(0, 3) +
      ') ' +
      onlyNumbers.slice(3, 6) +
      '-' +
      onlyNumbers.slice(6, 10)
    );
  }
  return phoneNumber;
};
