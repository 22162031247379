import { combineReducers } from 'redux';
import OspDefEditor from './OspDefEditor';
import ospDef from './ospDef';
import ospQualificationQuestions from './ospQualificationQuestions';
import ospSections from './ospSections';
import worksheetQuestions from './worksheetQuestions';
import worksheets from './worksheets';

// TODO (orther) make this use same reducers used by root reducers for these entities?
export default combineReducers({
  OspDefEditor,
  ospDef,
  ospQualificationQuestions,
  ospSections,
  worksheetQuestions,
  worksheets,
});
