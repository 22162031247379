import { apiRequest } from 'sow/utils/api';
import { API_URL } from 'sow/constants/api';

export function fetchDashboardAsync(acaId, filter, additionalFilters) {
  let path = `${API_URL}/v1/aca/${acaId}/dashboard`;

  if (filter && filter !== 'all') {
    path += '?filter=' + filter;
  }

  if (additionalFilters) {
    if (path.indexOf('?') > 0) {
      path = [path].concat(additionalFilters).join('&');
    } else {
      path = [path].concat([additionalFilters]).join('?');
    }
  }

  return apiRequest({
    method: 'GET',
    url: path,
  });
}

export function fetchDashboardCountsAsync(acaId, filter) {
  let path = `${API_URL}/v1/aca/${acaId}/dashboard/counts`;

  if (filter) {
    path += '?' + filter;
  }
  return apiRequest({
    method: 'GET',
    url: path,
  });
}

// org/241/users/16671/role
export function addSpecialistToOrg(payload) {
  const { orgId, userId, userTypeId, userSubtypeId } = payload;
  return apiRequest({
    method: 'PUT',
    url: `${API_URL}/v1/org/${orgId}/users/${userId}/role`,
    data: {
      role: 'certification_specialist',
      userTypeId,
      userSubtypeId,
    },
  });
}

export function removeSpecialistFromOrg(payload) {
  const { orgId, userId, userTypeId, userSubtypeId } = payload;
  return apiRequest({
    method: 'DELETE',
    url: `${API_URL}/v1/org/${orgId}/users/${userId}/role`,
    data: {
      role: 'certification_specialist',
      userTypeId,
      userSubtypeId,
    },
  });
}

export function inspectorDone(orgId, planAppId, updates) {
  return apiRequest({
    method: 'PUT',
    url: `${API_URL}/v1/org/${orgId}/application/${planAppId}/inspector_done`,
    data: updates,
  });
}

export function inspectorUpdatePlanState(orgId, planAppId, state) {
  return apiRequest({
    method: 'PATCH',
    url: `${API_URL}/v1/org/${orgId}/application/${planAppId}/review_state`,
    data: { osp_application: { state } },
  });
}

export function addSpecialistPriority(orgId, planAppId, data) {
  return apiRequest({
    method: 'POST',
    url: `${API_URL}/v1/org/${orgId}/application/${planAppId}/add_priority`,
    data,
  });
}

export function addOrUpdateSpecialistPriority(orgId, planAppId, data) {
  return apiRequest({
    method: 'PUT',
    url: `${API_URL}/v1/org/${orgId}/application/${planAppId}/add_or_update_priority`,
    data,
  });
}

export function removeSpecialistPriority(orgId, planAppId, data) {
  return apiRequest({
    method: 'PUT',
    url: `${API_URL}/v1/org/${orgId}/application/${planAppId}/remove_priority`,
    data,
  });
}
