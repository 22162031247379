import { LOAD_ALL_REQUEST, LOAD_ALL_SUCCESS, LOAD_ALL_FAILURE } from 'sow/actions/aca';

const isLoading = (state = false, action) => {
  switch (action.type) {
    case LOAD_ALL_REQUEST:
      return true;

    case LOAD_ALL_FAILURE:
    case LOAD_ALL_SUCCESS:
      return false;

    default:
      return state;
  }
};

export default isLoading;
