import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Atom from 'sow/components/atoms/Atom';

const Label = ({
  className,
  primary,
  success,
  info,
  warning,
  danger,
  pullLeft,
  pullRight,
  ...props
}) => {
  const classes = classnames(className, 'label', {
    'label-default': !primary && !success && !info && !warning && !danger,
    'label-primary': primary,
    'label-success': success,
    'label-info': info,
    'label-warning': warning,
    'label-danger': danger,
    'pull-left': pullLeft,
    'pull-right': pullRight,
  });

  return <Atom {...props} className={classes} />;
};

Label.defaultProps = {
  className: null,
  primary: false,
  success: false,
  info: false,
  warning: false,
  danger: false,
};

Label.propTypes = {
  className: PropTypes.string,
  primary: PropTypes.bool,
  success: PropTypes.bool,
  info: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  pullLeft: PropTypes.bool,
  pullRight: PropTypes.bool,
};

export default Label;
