import React from 'react';
import { Modal } from 'react-bootstrap';

const EditModal = ({ bsSize, show, title = 'Edit', children, onHide }) => (
  <Modal show={show} onHide={onHide} bsSize={bsSize}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
    <Modal.Footer>
      <button type="button" className="btn btn-primary" onClick={onHide}>
        Done
      </button>
    </Modal.Footer>
  </Modal>
);

export default EditModal;
