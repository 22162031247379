import { resourceDetailRead, resourceListRead } from 'sow/store/helpers';

export const planAppUrl = orgId => `org/${orgId}/application`;
export const planAppReadRequest = orgId =>
  resourceDetailRead(planAppUrl(orgId), 'planAppResp');

export const planAppSummaryUrl = orgId => `org/${orgId}/application`;
export const planAppSummaryReadRequest = orgId =>
  resourceDetailRead(planAppSummaryUrl(orgId), 'planAppSummary');

export const locationListUrl = (orgId, planAppId) =>
  `org/${orgId}/application/${planAppId}/land`;
export const locationListReadRequest = (orgId, planAppId) =>
  resourceListRead(locationListUrl(orgId, planAppId), 'planAppLocation');

export const initialSummaryUrl = (orgId, planAppId) =>
  `org/${orgId}/application/${planAppId}/summarize_initial`;
export const initialSummaryReadRequest = (orgId, planAppId) =>
  resourceDetailRead(initialSummaryUrl(orgId, planAppId), 'planAppInitialSummary');

export const changeRequestUrl = (orgId, planAppId) =>
  `org/${orgId}/application/${planAppId}/change_request`;
export const changeRequestReadRequest = (orgId, planAppId) =>
  resourceDetailRead(changeRequestUrl(orgId, planAppId), 'changeRequest');

export const changeListUrl = (orgId, planAppId, changeRequestId) =>
  `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`;
export const changeListReadRequest = (orgId, planAppId, changeRequestId) =>
  resourceListRead(changeListUrl(orgId, planAppId, changeRequestId), 'change');

export const locationUrl = (orgId, planAppId, locationId) =>
  `org/${orgId}/application/${planAppId}/land/${locationId}`;
export const locationReadRequest = (orgId, planAppId, locationId) =>
  resourceDetailRead(locationUrl(orgId, planAppId, locationId), 'planAppLocation');

export const allLocationAnswersUrl = (orgId, planAppId) =>
  `org/${orgId}/application/${planAppId}/land_answers`;
export const allLocationAnswersReadRequest = (orgId, planAppId) =>
  resourceDetailRead(allLocationAnswersUrl(orgId, planAppId), 'locationAnswers');

export const locationAnswersUrl = (orgId, planAppId, locationId) =>
  `org/${orgId}/application/${planAppId}/land/${locationId}/answers`;
export const locationAnswersReadRequest = (orgId, planAppId, locationId) =>
  resourceDetailRead(locationAnswersUrl(orgId, planAppId, locationId), 'locationAnswers');

export const worksheetAnswersUrl = (orgId, planAppId, worksheetId) =>
  `org/${orgId}/application/${planAppId}/worksheet_answers/${worksheetId}`;
export const worksheetAnswersReadRequest = (orgId, planAppId, worksheetId) =>
  resourceDetailRead(
    worksheetAnswersUrl(orgId, planAppId, worksheetId),
    'worksheetAnswer',
  );

export const allWorksheetAnswersUrl = (orgId, planAppId) =>
  `org/${orgId}/application/${planAppId}/worksheet_answers`;
export const allWorksheetAnswersReadRequest = (orgId, planAppId) =>
  resourceDetailRead(worksheetAnswersUrl(orgId, planAppId), 'worksheetAnswerMap');
