import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { worksheetQuestionType } from 'sow/types';

import Input from 'sow/components/atoms/Input';

const TextField = ({ answer, disabled, handleChange, question }) => (
  <Input
    id={question.uuid}
    placeholder={R.propOr('', 'placeholder', question)}
    value={(typeof answer === 'object' && answer !=null ? (answer.checked != null ? answer.checked : '') : answer === true ? 'Yes' : answer)}
    onChange={e => handleChange(question.uuid, e.target.value)}
    disabled={disabled}
  />
);

TextField.defaultProps = {
  answer: '',
};

// answer may be boolean value
TextField.propTypes = {
  answer: PropTypes.any,
  disabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  question: worksheetQuestionType.isRequired,
};

export default TextField;
