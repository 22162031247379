import React, { Fragment } from 'react';

import './OspStepCard.module.css';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Paragraph from 'sow/components/atoms/Paragraph';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import Link from 'sow/components/atoms/Link';
import Clearfix from 'sow/components/atoms/Clearfix';

const stepInfo = {
  activities: {
    stepNum: 1,
    name: 'Activities Checklist',
    icon: 'tasks',
    variant: 'far',
  },
  worksheets: {
    stepNum: 2,
    name: 'Worksheets',
    icon: 'wpforms',
    variant: 'fab',
  },
  locations: {
    stepNum: 3,
    name: 'Manage Locations',
    icon: 'map-marker-alt',
  },
};

const OspStepCard = ({ cardTitle, cardLink, status, disabled, complete, isInitial }) => {
  const cardInfo = stepInfo[cardTitle];
  return (
    <Widget className={`osp-step-card ${cardTitle == 'locations' && 'end-card'}`}>
      <WidgetBody className={`card-info ${cardTitle}`}>
        <FontAwesome
          className="card-img"
          icon={`${cardInfo.icon}`}
          variant={cardInfo.variant && `${cardInfo.variant}`}
        />
        <Paragraph className="card-text">STEP {cardInfo.stepNum}</Paragraph>
        {/* "isInitial" temporary until logic for renewal worksheets is created */}
        {isInitial && (
          <Fragment>
            {status && (
              <Fragment>
                <Paragraph className="completed-fraction">
                  {status.completed}/{status.total}
                </Paragraph>
                <HorizontalRule className="card-horz-rule" />
              </Fragment>
            )}
            <Paragraph className="card-status">
              {complete ? 'Complete' : 'Incomplete'}
            </Paragraph>
          </Fragment>
        )}
      </WidgetBody>
      <WidgetFooter className="card-link-div">
        <Link className="card-link" to={cardLink} disabled={disabled}>
          {cardInfo.name}
          <FontAwesome className="card-link-icon" icon="chevron-right" />
          <Clearfix />
        </Link>
      </WidgetFooter>
    </Widget>
  );
};

export default OspStepCard;
