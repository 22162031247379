import * as R from 'ramda';

// The order an ACA list should be sorted into
export const priorityOrder = [
  'No Certifier / Other',
  'A Bee Organic',
  'Agricultural Services Certified Organic',
  'Americert International',
  'ARGENCERT SA',
  'Aus-Qual Pty. Ltd.',
  'Australian Certified Organic Pty Ltd',
  'Basin and Range Organics',
  'Baystate Organic Certifiers',
  'Bio Latina',
  'Bio.Inspecta',
  'BioAgriCert',
  'Boliviana de Certificacion - Bolicert',
  'CAAE Certification Service',
  // 'CCOF Certification Services, LLC',
  'CCOF',
  'Certificadora Mexicana de Productos y Procesos Ecologicos SC',
  'Certification of Environmental Standards - GmbH (Ltd.)',
  'Certisys',
  'Clemson University',
  'Colorado Department of Agriculture',
  'Consorzio il Biologico Societa Cooperativa',
  'Control Union Certifications',
  'Eco-Logica',
  'ECOCERT ICO',
  'EcoCert S.A.',
  'Ecoglobe, LLC',
  'Ecological Farming Control Organization',
  'Food Safety S.A.',
  'Georgia Crop Improvement Association, Inc.',
  'Global Culture',
  'Global Organic Alliance, Inc.',
  'IBD Certifications, Ltd.',
  'Idaho State Department Of Agriculture',
  'Institute for Marketecology – Switzerland',
  'International Certification Services, Inc.',
  'Iowa Department of Agriculture and Land Stewardship',
  'Istituto per la Certificazione Etica e Ambientale',
  'Kentucky Department of Agriculture',
  'Kiwa BCS Oko Garantie GmbH',
  'LACON GmbH',
  'LETIS S.A.',
  'Marin Organic Certified Agriculture',
  'Maryland Department of Agriculture',
  'Mayacert S.A.',
  'Midwest Organic Services Association, Inc.',
  'Minnesota Crop Improvement Association',
  'MOFGA Certification Services, LLC',
  'Montana Department of Agriculture',
  'Monterey County Certified Organics',
  'NASAA Certified Organic',
  'Natural Food Certifiers',
  'Natures International Certification Services',
  'New Hampshire Department of Agriculture, Markets & Food',
  'New Jersey Department of Agriculture',
  'New Mexico Department of Agriculture',
  'Northeast Organic Farming Association of New York',
  'Ohio Ecological Food and Farm Association',
  'Oklahoma Department of Agriculture, Food and Forestry',
  'OneCert, Inc.',
  'Oregon Department of Agriculture',
  'Oregon Tilth Certified Organic',
  'Organic Certifiers, Inc.',
  'Organic Crop Improvement Association',
  'Organic Food Development & Certification Centre of China',
  'Organizacion Internacional Agropecuaria',
  'Overseas Merchandise Inspection Co., Ltd.',
  'Pennsylvania Certified Organic',
  'Primus Labs',
  'Pro-Cert Organic Systems Ltd.',
  'Quality Assurance International',
  'Quality Certification Services',
  'Rhode Island Department of Environmental Management',
  'SCS Global Services',
  'Stellar Certification Services',
  'Texas Department of Agriculture',
  'TOC - Tse-Xin Organic Certification Corporation',
  'Utah Department of Agriculture & Food',
  'Vermont Organic Farmers, LLC',
  'Washington State Department of Agriculture',
  'Yolo County Department of Agriculture',
];

export function defaultAca() {
  return {
    name: '',
    timezone: '',
    logo_url: '',
  };
}

const slugIdMap = {
  abee: 4,
  baro: 1,
  ccof: 2,
  tilth: 3,
};

const idSlugMap = R.invertObj(slugIdMap);

export const acaIdToSlug = R.propOr(null, R.__, idSlugMap);
export const acaSlugToId = R.propOr(null, R.__, slugIdMap);

export const acaIdPredFromSlug = R.compose(R.equals, acaSlugToId);
