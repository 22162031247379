import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import ItemTypeListContainer from 'sow/containers/ItemTypeListContainer';

const ItemTypeListPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <ItemTypeListContainer />}
  </AdminPageTemplate>
);

export default ItemTypeListPage;
