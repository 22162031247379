import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';

import { actionGroupRoute } from 'sow/routes';
import * as actionGroupActions from 'sow/actions/pure/actionGroup';

import ActionGroupEdit from 'sow/components/organisms/ActionGroupEdit';

const mapDispatchToProps = {
  loadActionGroupById: actionGroupActions.loadActionGroupById,
  addActionGroup: actionGroupActions.addActionGroup,
  updateActionGroup: actionGroupActions.updateActionGroup,
  deleteActionGroup: actionGroupActions.deleteActionGroup,
  redirect: routerPush,
};

const initialValues = {
  name: '',
  sort_order: 1,
  enabled: true,
};

class ActionGroupEditContainer extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = { isNew: false, isLoading: true, initialValues: R.clone(initialValues) };
  }

  componentDidMount() {
    this._isMounted = true;
    const routeId = R.path(['match', 'params', 'id'], this.props);

    if (routeId !== 'new') {
      this.props.loadActionGroupById(routeId, this.updateState.bind(this));
    } else {
      this.setState({ isNew: true, isLoading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState({ data }) {
    if (this.state.isNew) {
      history.pushState(null, null, `${data.id}`);
    }
    if (this._isMounted) {
      if (!R.isEmpty(data)) {
        this.setState({
          isNew: false,
          isLoading: false,
          actionGroupId: data.id,
          initialValues: { ...data },
        });
      } else {
        this.redirectToList();
      }
    }
  }

  redirectToList() {
    this.props.redirect(actionGroupRoute());
  }

  handleSave(values, formikBag) {
    if (this.state.isNew) {
      this.props.addActionGroup(values, this.updateState.bind(this));
    } else {
      this.props.updateActionGroup(
        this.state.actionGroupId,
        values,
        this.updateState.bind(this),
      );
    }
  }

  handleDelete() {
    this.props.deleteActionGroup(
      this.state.actionGroupId,
      this.redirectToList.bind(this),
    );
  }

  render() {
    return (
      <ActionGroupEdit
        handleSave={this.handleSave.bind(this)}
        handleDelete={this.handleDelete.bind(this)}
        {...this.state}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(ActionGroupEditContainer);
