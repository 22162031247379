function mark(action) {
  return `soworganic/messaging/${action}`;
}

export const SHOW_CONFIRM = mark('SHOW_CONFIRM');
export const HIDE_CONFIRM = mark('HIDE_CONFIRM');

export const confirm = options => ({
  type: SHOW_CONFIRM,
  data: { options },
});

export const hideConfirm = () => ({ type: HIDE_CONFIRM });
