import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import CertificationTypesListContainer from 'sow/containers/CertificationTypesListContainer';

const CertificationTypesListPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <CertificationTypesListContainer />}
  </AdminPageTemplate>
);

export default CertificationTypesListPage;
