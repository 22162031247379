import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Button from 'sow/components/atoms/Button';
import AcaSelect from 'sow/containers/AcaSelect';
import AcaOrgSelect from 'sow/containers/AcaOrgSelect';
import FormikField from 'sow/components/organisms/FormikField';

const LinkUsersToOrgForm = ({ onSubmit }) => {
  return (
    <Formik
      initialValues={{
        acaId: '',
        orgId: '',
      }}
      validationSchema={Yup.object({
        acaId: Yup.number().required(),
        orgId: Yup.number().required(),
      })}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Column md={6}>
              <Field
                name="acaId"
                label="ACA of other organization:"
                component={FormikField}
                type={AcaSelect}
                required
              />
            </Column>
            <Column md={6}>
              <Field
                label="Select organization to link users to: (search by org id, org name, user name, or user email)"
                name="orgId"
                component={FormikField}
                type={AcaOrgSelect}
                acaId={values.acaId}
                required
              />
            </Column>
          </Row>

          <Button type="submit" primary disabled={isSubmitting}>
            Link Organization's Users to Other Organization
          </Button>
        </Form>
      )}
    </Formik>
  );
};

LinkUsersToOrgForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LinkUsersToOrgForm;
