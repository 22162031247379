import React from 'react';

import FormGroup from 'sow/components/organisms/FormGroup';
import Input from 'sow/components/atoms/Input';

const InputFormGroup = ({
  inputClassName,
  className,
  fieldName,
  fieldErrors,
  label,
  value,
  onChange,
  type,
  id,
  required,
  hideErrorLabel,
}) => (
  <FormGroup
    className={className}
    fieldName={fieldName}
    label={label}
    errors={fieldErrors}
    required={required}
    hideErrorLabel={hideErrorLabel}
  >
    <Input
      type={type}
      id={id}
      className={inputClassName}
      value={value}
      onChange={onChange}
    />
  </FormGroup>
);

export default InputFormGroup;
