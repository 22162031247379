import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import Block from 'sow/components/atoms/Block';
import Label from 'sow/components/molecules/Label';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const IconWrapper = ({ children }) => <Block className="ws-status">{children}</Block>;

const WorksheetStatusIcon = ({ answers, isNotApplicable, isStateInitialApplication }) => {
  const isReadyForReview = R.path(['readyForReview'], answers);
  const isComplete = answers && (isReadyForReview || isNotApplicable);

  if (isStateInitialApplication) {
    return (
      <IconWrapper>
        <Label success={isComplete}>
          {isNotApplicable ? 'n/a' : <FontAwesome icon="check" />}
        </Label>
      </IconWrapper>
    );
  }

  if (isNotApplicable) {
    return (
      <IconWrapper>
        <Label>n/a</Label>
      </IconWrapper>
    );
  }

  // Do not render for states other than initial app
  return null;
};

WorksheetStatusIcon.propTypes = {
  answers: sowTypes.planAppWorksheetType,
  isNotApplicable: PropTypes.bool.isRequired,
};

WorksheetStatusIcon.defaultProps = {
  answers: null,
};

export default WorksheetStatusIcon;
