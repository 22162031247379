import React, { Fragment } from 'react';
import { matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  adminRoute,
  adminUserLookupRoute,
  adminUsersLookupRoute,
  systemAdminRoute,
} from 'sow/routes';
import SidebarLink from 'sow/components/molecules/SidebarLink';
import IconWithText from 'sow/components/molecules/IconWithText';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import List from 'sow/components/atoms/List';
import ListItem from 'sow/components/atoms/ListItem';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import NavLink from 'sow/components/molecules/NavLink';
import AdminSubmenu from './AdminSubmenu';

const SidebarAdmin = ({ navClasses }) => (
  <List className={navClasses}>
    <ListItem className="sidebar-heading" />

    <SidebarLink to={adminRoute()} exact>
      <IconWithText icon={<FontAwesome variant="far" icon="file-alt" />} text="ACAs" />
    </SidebarLink>

    {/* <SidebarLink to={adminUserLookupRoute()} exact>
      <IconWithText icon={<FontAwesome icon="search" />} text="User Lookup" />
    </SidebarLink> */}

    <SidebarLink to={adminUsersLookupRoute()}>
      <IconWithText icon={<FontAwesome icon="search" />} text="User Lookup" />
    </SidebarLink>

    {matchPath(window.location.pathname, systemAdminRoute()) ? (
      <Fragment>
        <NavLink
          to={systemAdminRoute()}
          isActive={() => true}
          className={'side-menu-org-active'}
        >
          <Row>
            <Column xs={2}>
              <FontAwesome icon="user-cog" />
            </Column>
            <Column xs={10}>System Administrator</Column>
          </Row>
        </NavLink>
        <AdminSubmenu navClasses={navClasses} />
      </Fragment>
    ) : (
      <SidebarLink to={systemAdminRoute()}>
        <IconWithText
          icon={<FontAwesome variant="fas" icon="user-cog" />}
          text="System Administrator"
        />
      </SidebarLink>
    )}
  </List>
);

SidebarAdmin.propTypes = {
  navClasses: PropTypes.string,
};

export default SidebarAdmin;
