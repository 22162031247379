import React from 'react';
import PropTypes from 'prop-types';

/**
 * Displays text in a `<caption>` tag. All props are passed through.
 */
const Caption = ({ children, ...props }) =>
  <caption {...props}>
    {children}
  </caption>;

Caption.propTypes = {
  children: PropTypes.any,
};

export default Caption;
