import * as R from 'ramda';
import { makeImmutable } from 'sow/utils/data';

const byId = (state = makeImmutable({}), action) => {
  if (R.path(['data', 'entities', 'ospApps'], action)) {
    return state.merge(action.data.entities.ospApps);
  }

  return state;
};

export default byId;

export const getIds = state => Object.keys(state);
export const getOspApp = (state, ospAppId) => state[ospAppId];
export const getOspAppList = state => getIds(state).map(id => state[id]);
