import React from 'react';

const RadioButton = ({
  options,
  onChange,
  currentState,
  labelClassName,
  inputClassName,
}) => {
  return (
    <div>
      {options.map(element => (
        <label key={element.value} className={labelClassName}>
          <input
            className={inputClassName}
            type="radio"
            checked={element.value === currentState}
            name={element.stateName}
            onChange={() => onChange(element.value, element.stateName)}
            disabled={element.disabled}
          ></input>
          {element.label}
        </label>
      ))}
    </div>
  );
};

export default RadioButton;
