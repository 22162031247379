import actions from 'sow/actions/pure';

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case String(actions.ospApp.loadOrgList):
    case String(actions.ospApp.loadOrgListDone):
      return null;

    case String(actions.ospApp.loadOrgListFail):
      // TODO (orther) implement standard error message
      return 'ADD REAL ERROR MESSAGE!!!!';

    default:
      return state;
  }
};

export default errorMessage;
