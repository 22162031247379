import * as R from 'ramda';
import { makeImmutable } from 'sow/utils/data';

const byId = (state = makeImmutable({}), action) => {
  if (R.path(['data', 'entities', 'worksheets'], action)) {
    return state.merge(action.data.entities.worksheets);
  }

  return state;
};

export default byId;

export const getIds = state => Object.keys(state);
export const getWorksheet = (state, id) => R.path([id], state);
export const getWorksheetList = state => getIds(state).map(id => state[id]);
