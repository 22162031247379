import React from 'react';
import PropTypes from 'prop-types';

import CenteredLayout from 'sow/components/templates/CenteredLayout';
import Alert from 'sow/components/molecules/Alert';
import Block from 'sow/components/atoms/Block';
import Heading from 'sow/components/atoms/Heading';

const ErrorPage = ({ warning, danger, message, children, large }) => {
  const defaultWarning = !warning && !danger;

  return (
    <CenteredLayout>
      <Alert warning={warning || defaultWarning} danger={danger}>
        {large ? <Heading level={3}>{message}</Heading> : <Block>{message}</Block>}
      </Alert>
      {children}
    </CenteredLayout>
  );
};

ErrorPage.propTypes = {
  context: PropTypes.string,
  message: PropTypes.node,
  children: PropTypes.node,
  large: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
};

ErrorPage.defaultProps = {
  large: false,
};

export default ErrorPage;
