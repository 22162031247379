import React, { Fragment } from 'react';

import Block from 'sow/components/atoms/Block';
import Strong from 'sow/components/atoms/Strong';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import OptionListPopover from 'sow/components/molecules/OptionListPopover';
import PopoverOption from 'sow/components/molecules/OptionListPopover/PopoverOption';
import SectionsModal from './SectionsModal';
import ItemVersionSectionListContainer from 'sow/containers/ItemVersionSectionListContainer';

const ItemVersionCreatorSection = ({
  showPopover,
  popoverParams,
  modalParams,
  itemVersionSectionList,
  loadItemVersionSections,
  updateListOrder,
  editSection,
  cloneSection,
  onSectionSelect,
  selectedSectionId,
}) => (
  <Fragment>
    <OptionListPopover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...popoverParams}
    >
      <PopoverOption onClick={popoverParams.showModal}>Create A Section</PopoverOption>
    </OptionListPopover>
    <SectionsModal {...modalParams} />
    <Block className="editor-header creator-header">
      <Block>
        <Strong>Sections</Strong>
      </Block>
      <Block className="add-button" onClick={showPopover}>
        <FontAwesome icon="circle-plus" />
      </Block>
    </Block>
    <ItemVersionSectionListContainer
      itemVersionSectionList={itemVersionSectionList}
      loadItemVersionSections={loadItemVersionSections}
      updateListOrder={updateListOrder}
      editSection={editSection}
      cloneSection={cloneSection}
      onSectionSelect={onSectionSelect}
      selectedSectionId={selectedSectionId}
    />
  </Fragment>
);

export default ItemVersionCreatorSection;
