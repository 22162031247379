import { call, put, takeEvery, select } from 'redux-saga/effects';

import * as oldOspAppAction from 'sow/actions/ospApplication';
import * as workflowAction from 'sow/actions/orgWorkflow';
import * as wsaAction from 'sow/actions/worksheetAnswers';
import actions from 'sow/actions/pure';
import { apiGet, apiPut } from 'sow/sagas/api';
import { find } from 'ramda';

export function* loadOspAppListForOrg(orgId) {
  try {
    const { response, error } = yield call(apiGet, `/org/${orgId}/application`);

    if (response) {
      const { data } = response;
      yield put(actions.ospApp.loadOrgListDone({ orgId, result: data }));
    }

    if (error) {
      yield put(actions.ospApp.loadOrgListFail(error));
    }
  } catch (error) {
    yield put(actions.ospApp.loadOrgListFail(error));
  }
}

export const getDashboard = state => state.dashboard;

export function* watchFullOspAppLoad({ data }) {
  const { orgId, ospAppId } = data; // payload;

  yield put(wsaAction.loadAllWorksheetAnswersForOrgOspApp(orgId, ospAppId));
}

export function* watchLoadOspAppListForOrg({ payload }) {
  try {
    const { orgId } = payload;
    yield call(loadOspAppListForOrg, orgId);
  } catch (error) {
    console.error(error);
  }
}

export function* watchSaveApplicationInformation({ data }) {
  try {
    //const { id } = payload;
    const dashboard = yield select(getDashboard);
    const item = dashboard.items.find(x => x.app_id === data.id);
    const index = dashboard.items.findIndex(x => x.app_id === data.id);
    yield put(workflowAction.setNoteForDashboardItem(index, data.note));
  } catch (error) {
    console.error(error);
  }
}

export function* watchchangeInspectionDate({ payload }) {
  const { orgId, planAppId, inspectionDate, onSuccess } = payload;
  try {
    const { response } = yield call(
      apiPut,
      `/org/${orgId}/application/${planAppId}/change_inspection_date`,
      {
        org_id: orgId,
        application_id: planAppId,
        inspection_date: inspectionDate,
      },
    );
    if (response && onSuccess) {
      yield call(onSuccess, response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* rootSaga() {
  try {
    yield takeEvery(actions.ospApp.loadOrgList, watchLoadOspAppListForOrg);
    yield takeEvery(oldOspAppAction.UPDATE_APP_SUCCESS, watchSaveApplicationInformation);
    yield takeEvery(oldOspAppAction.LOAD_ONE_FOR_ORG_REQUEST, watchFullOspAppLoad);
    yield takeEvery(actions.ospApp.changeInspectionDate, watchchangeInspectionDate);
  } catch (error) {
    console.error(error);
  }
}
