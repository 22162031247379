import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Block from 'sow/components/atoms/Block';
import FieldHelpBlock from 'sow/components/atoms/FieldHelpBlock';
import Input from 'sow/components/atoms/Input';
import FormLabel from 'sow/components/atoms/FormLabel';
import Paragraph from 'sow/components/atoms/Paragraph';
import RequiredIndicator from 'sow/components/molecules/RequiredIndicator';

const FieldFeedbackError = ({ name, error, ...props }) => (
  <Block className="text-danger" role="alert" id={`${name}Error`} {...props}>
    {error}
  </Block>
);

FieldFeedbackError.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
};

// eslint-disable-next-line no-unused-vars
const FormHelpBlock = ({ children, name }) => (
  <Paragraph className="help-block">{children || ''}</Paragraph>
);

FormHelpBlock.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
};

const FieldFeedback = ({ error, name }) => (
  <FormHelpBlock name={name}>{error}</FormHelpBlock>
);

FieldFeedback.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
};

const FormGroup = ({ className, invalid, checkbox, type, ...props }) => {
  const classes = classNames(className, {
    'form-group': !checkbox,
    checkbox: checkbox,
    'has-error': invalid,
  });

  return <Block className={classes} {...props} />;
};

FormGroup.propTypes = {
  className: PropTypes.string,
  invalid: PropTypes.bool,
  checkbox: PropTypes.bool,
  type: PropTypes.any,
};

const FormField = ({
  className,
  error,
  name,
  invalid,
  label,
  type,
  large,
  required,
  ...props
}) => {
  const isCheckbox = type === 'checkbox';
  const isInlineRadio = type === 'inline-radio';
  const renderInputFirst = type === 'radio';

  const inputProps = {
    id: name,
    name,
    type,
    'aria-describedby': `${name}Error`,
    className: classNames(className, {
      'form-control': !isCheckbox,
      'input-lg': large,
    }),
    ...props,
  };

  if (isInlineRadio) {
    return (
      <FormGroup invalid={invalid} type={type}>
        <Block>
          <FormLabel>{label}</FormLabel>
        </Block>
        {Array.isArray(props.radioOptions) &&
          props.radioOptions.map(option => {
            const optionProps = R.compose(
              R.omit(['className', 'values', 'radioOptions']),
              R.merge(R.__, {
                checked: inputProps.value === option.value,
                id: `${inputProps.id}--${option.value}`,
                type: 'radio',
                value: option.value,
              }),
            )(inputProps);

            return (
              <FormLabel
                className="radio-inline"
                htmlFor={optionProps.id}
                key={option.value}
              >
                <Input {...optionProps} />
                {option.label}
              </FormLabel>
            );
          })}
        {invalid && <FieldHelpBlock help={error} />}
      </FormGroup>
    );
  }

  if (isCheckbox) {
    return (
      <FormGroup invalid={invalid} type={type} checkbox>
        <FormLabel htmlFor={inputProps.id}>
          <Input {...inputProps} />
          {label}
        </FormLabel>
        {invalid && <FieldHelpBlock help={error} />}
      </FormGroup>
    );
  }

  return (
    <FormGroup invalid={invalid} type={type}>
      {renderInputFirst && <Input {...inputProps} />}
      {label && (
        <FormLabel htmlFor={inputProps.id} className="control-label">
          {label} {required && <RequiredIndicator />}
        </FormLabel>
      )}
      {renderInputFirst || <Input {...inputProps} />}
      {invalid && typeof error === 'string' && <FieldHelpBlock help={error} />}
    </FormGroup>
  );
};

FormField.propTypes = {
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  invalid: PropTypes.bool,
  label: PropTypes.string,
  large: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  textMask: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  radioOptions: PropTypes.array,
  required: PropTypes.bool,
};

FormField.defaultProps = {
  type: 'text',
};

export default FormField;
