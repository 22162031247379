import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { humanize, titleize } from 'sow/utils/string';

import { planAppStateText } from 'sow/constants/planApp';
import { orgRoute } from 'sow/routes';

import Atom from 'sow/components/atoms/Atom';
import DataTable from 'sow/components/molecules/DataTable';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Timestamp from 'sow/components/molecules/Timestamp';
import Link from 'sow/components/atoms/Link';
import ActivityLogChangesOverlayButton from 'sow/components/organisms/ActivityLogChangesOverlayButton';

const NoRows = () => (
  <TableRow>
    <TableCell colSpan={6} textCenter>
      No entries found
    </TableCell>
  </TableRow>
);

const ReportRow = ({ report }) => {
  return (
    <TableRow>
      {Object.keys(report).map((e, i) => {
        if (i > 0) {
          return (
            <TableCell className={e.indexOf('email') >= 0 ? 'wrap' : ''}>
              {report[e]}
            </TableCell>
          );
        }
      })}
    </TableRow>
  );
};

const ReportHeaderRow = ({ report }) => {
  if (report != null) {
    return (
      <TableRow>
        {Object.keys(report).map((e, i) => {
          if (i > 0) {
            return <TableCell heading>{e.replaceAll('_', ' ').substring(4)}</TableCell>;
          }
        })}
      </TableRow>
    );
  } else {
    return null;
  }
};

ReportRow.propTypes = {
  report: PropTypes.object,
};

const AcaReportTable = ({ report }) => {
  return (
    <DataTable
      selector={'.reportTable'}
      tableDef={{
        pageLength: 50,
        destroy: true,
      }}
    >
      <Table
        bordered
        condensed
        striped
        className="reportTable"
        head={<ReportHeaderRow key={0} report={report[0]} />}
      >
        {R.isEmpty(report) && <NoRows />}
        {report.map(report => (
          <ReportRow key={report.id} report={report} />
        ))}
      </Table>
    </DataTable>
  );
};

AcaReportTable.propTypes = {
  report: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AcaReportTable;
