import React from 'react';
import PropTypes from 'prop-types';

import { inspectorRoute } from 'sow/routes';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Block from 'sow/components/atoms/Block';
import List from 'sow/components/atoms/List';
import ListItem from 'sow/components/atoms/ListItem';
import SidebarOrg from 'sow/components/organisms/SidebarOrg';
import IconWithText from 'sow/components/molecules/IconWithText';
import SidebarLink from 'sow/components/molecules/SidebarLink';

const SidebarInspector = ({ aca, org, navClasses }) => (
  <Block className="sidebar-aca-admin">
    <List className={navClasses}>
      <ListItem className="sidebar-heading">Inspector</ListItem>

      <SidebarLink to={inspectorRoute()} exact>
        <IconWithText icon={<FontAwesome icon="star" />} text="Dashboard" />
      </SidebarLink>
    </List>

    {org && <SidebarOrg aca={aca} org={org} />}
  </Block>
);

SidebarInspector.propTypes = {
  aca: PropTypes.object,
  org: PropTypes.object,
  navClasses: PropTypes.string,
};

export default SidebarInspector;
