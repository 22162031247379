import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';

import { monthDayYearDateFormat } from 'sow/utils/dateTime';
import { API_URL } from 'sow/constants/api';

import Block from 'sow/components/atoms/Block';
import Spinner from 'sow/components/atoms/Spinner';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import ImprovedDataTable from 'sow/components/molecules/ImprovedDataTable';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Image from 'sow/components/atoms/Image';

const AllInspectionReportTable = ({ fetchingList, inspectionReport }) => (
  <Fragment>
    {fetchingList ? (
      <Spinner />
    ) : (
      <Widget>
        <WidgetHeading>Inspections</WidgetHeading>
        <WidgetBody>
          <ImprovedDataTable
            selector={'.inspectionTable'}
            tableDef={{
              pageLength: 50,
              destroy: true,
              searching: false,
              data: inspectionReport,
              deferRender: true,
              order: [],
              columns: [
                { data: 'inspection_id' },
                { data: 'org' },
                { data: 'physical_city' },
                { data: 'physical_state' },
                { data: 'anniversary_date' },
                { data: 'inspection_state' },
                { data: 'organization_types' },
                { data: 'inspection_type' },
                { data: 'sample_type' },
                { data: 'inspection_year' },
                { data: 'inspection_age' },
                { data: 'inspection_date' },
                { data: 'inspectors' },
                { data: 'certifications' },
              ],
              columnDefs: [
                {
                  targets: 1,
                  render: org =>
                    `<a class="org-link" href="/org/${org.id}">${org.name}</a>`,
                },
                {
                  targets: 10,
                  createdCell: (td, cellData) => {
                    let cellText;
                    switch (cellData) {
                      case null:
                      case 0:
                        break;
                      case 1:
                        cellText = `${cellData} day old`;
                        break;
                      default:
                        cellText = `${cellData} days old`;
                        break;
                    }
                    ReactDOM.render(<Block>{cellText}</Block>, td);
                  },
                  render: data => data,
                },
                {
                  type: 'date',
                  targets: [4, 11],
                  render: data => {
                    if (data) {
                      return monthDayYearDateFormat(data);
                    }
                    return null;
                  },
                },
                {
                  targets: 13,
                  createdCell: (td, cellData) => {
                    if (cellData) {
                      const imageIcons = cellData.map((cert, index) => (
                        <Image
                          key={index}
                          imageUrl={`${API_URL}/v1/file/by_path/${cert.s3_name}`}
                          title={`${cert.name}`}
                          displayTitleOnError
                        />
                      ));

                      ReactDOM.render(imageIcons, td);
                    }
                  },
                  render: data => {
                    if (data) {
                      return data.map(cert => cert.name).join('');
                    }
                    return '';
                  },
                },
              ],
            }}
          >
            <Table
              className={'inspectionTable'}
              bordered
              striped
              condensed
              head={
                <TableRow>
                  <TableCell heading textCenter>
                    Inspection ID
                  </TableCell>
                  <TableCell heading textCenter>
                    Organization
                  </TableCell>
                  <TableCell heading textCenter>
                    Physical City
                  </TableCell>
                  <TableCell heading textCenter>
                    Physical State
                  </TableCell>
                  <TableCell heading textCenter>
                    Anniversary Date
                  </TableCell>
                  <TableCell heading textCenter>
                    Inspection Status
                  </TableCell>
                  <TableCell heading textCenter>
                    Category
                  </TableCell>
                  <TableCell heading textCenter>
                    Inspection Type
                  </TableCell>
                  <TableCell heading textCenter>
                    Sample Type
                  </TableCell>
                  <TableCell heading textCenter>
                    Inspection Year
                  </TableCell>
                  <TableCell heading textCenter>
                    Inspection Age
                  </TableCell>
                  <TableCell heading textCenter>
                    Inspection Date
                  </TableCell>
                  <TableCell heading textCenter>
                    Inspector
                  </TableCell>
                  <TableCell heading textCenter>
                    Certification
                  </TableCell>
                </TableRow>
              }
              style={{ width: '100%' }}
            >
              <TableRow></TableRow>
            </Table>
          </ImprovedDataTable>
        </WidgetBody>
      </Widget>
    )}
  </Fragment>
);

export default AllInspectionReportTable;
