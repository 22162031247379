import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { systemAdminItemRoute } from 'sow/routes';

import AdminObjectTemplate from 'sow/components/templates/AdminObjectTemplate';

const mapStateToProps = (state, props) => {
  if (props.list) return {};
  const { id, name } = R.path(props.statePath)(state);
  const urlId = R.path(['match', 'params', 'id'], props);
  const isNew = urlId === 'new';
  const isLoading = id != urlId;
  return {
    pageName: isNew ? props.pageName : isLoading ? 'Loading' : name,
  };
};

const mapDispatchToProps = {};

class AdminObjectTemplateContainer extends Component {
  render() {
    const { breadcrumbPath } = this.props;

    const baseBreadcrumb = [
      ['System Administrator', systemAdminItemRoute()],
      ...breadcrumbPath,
    ];

    return <AdminObjectTemplate breadcrumb={baseBreadcrumb} {...this.props} />;
  }
}

AdminObjectTemplateContainer.propTypes = {
  title: PropTypes.string,
};

AdminObjectTemplateContainer.defaultProps = {
  title: 'System Administrator',
  breadcrumbPath: [],
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AdminObjectTemplateContainer);
