import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import FormikField from 'sow/components/organisms/FormikField';
import AddressForm from 'sow/components/organisms/AddressForm';

const OrgSettingsGeneralForm = ({ disabled }) => {
  return (
    <>
      <Field
        type="input"
        component={FormikField}
        name="name"
        label="What is the Organization's name?"
        disabled={disabled}
        required
      />

      {/* <Field
        type="input"
        component={FormikField}
        name="contactName"
        label="What is the Organization's primary contact's name?"
        disabled={disabled}
        required
      />

      <Field
        type="input"
        component={FormikField}
        name="contactPhone"
        label="What is the organization's primary contact's phone number?"
        disabled={disabled}
        required
      /> */}

      <AddressForm prefix="location." disabled={disabled} />
    </>
  );
};

OrgSettingsGeneralForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

OrgSettingsGeneralForm.defaultProps = {
  disabled: false,
};

export default OrgSettingsGeneralForm;
