import * as R from 'ramda';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push as routerPush } from 'connected-react-router';

import actions from 'sow/actions/pure';
import { register } from 'sow/actions/pure/registration';
import { requestPasswordForNewAccount } from 'sow/actions/pure/resetPassword';
import { setupOrgForUserRoute } from 'sow/routes';
import RegistrationForm from 'sow/components/old/user/RegistrationForm';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';

export const setUserSettingAcaFilterId = (user, acaId) =>
  R.assocPath(['settings', 'aca_filter', 'id'], acaId, user);

export const defaultUser = (acaId, isInspector) => {
  const user = {
    name: '',
    email: '',
    settings: { primary_phone_number: '' },
    password: 'srs9l21YfElI',
    password_confirmation: 'srs9l21YfElI',
    inspector: isInspector,
  };

  if (!acaId) return user;

  return setUserSettingAcaFilterId(user, acaId);
};

const mapStateToProps = state => ({
  isRegistering: state.registration.isRegistering,
  errors: state.registration.errors,
});

const mapDispatchToProps = {
  register,
  redirect: routerPush,
  toast: actions.shell.toast,
  requestPasswordForNewAccount,
};

class Register extends Component {
  static propTypes = {
    isInspector: PropTypes.bool,
  };

  static defaultProps = {
    isInspector: false,
  };

  state = {
    user: defaultUser(this.props.acaId, this.props.isInspector),
  };

  handleChange = (field, value) => {
    const path = ['user'].concat(field);
    this.setState(R.assocPath(path, value));
  };

  handleRegisterClientSuccess = data => {
    this.props.redirect(setupOrgForUserRoute(data.id));
  };

  handleRegisterInspectorSuccess = data => {
    this.props.requestPasswordForNewAccount(
      this.state.user.email,
      this.handleInspectorWelcomeEmailSuccess,
    );
  };

  handleInspectorWelcomeEmailSuccess = data => {
    console.log('handleWelcomeEmailSuccess');
  };

  handleSubmitClient = event => {
    event.preventDefault();

    if (this.isSubmitEnabled()) {
      this.props.register(this.state.user, this.handleRegisterClientSuccess);
    }
  };

  handleSubmitInspector = event => {
    event.preventDefault();

    if (this.isSubmitEnabled()) {
      this.props.register(this.state.user, this.handleRegisterInspectorSuccess);
    }
  };

  isSubmitEnabled = () => {
    return !this.props.isRegistering;
  };

  render() {
    const { errors, isInspector } = this.props;
    const { user } = this.state;

    const disableSubmit = !this.isSubmitEnabled();

    const submitAction = isInspector
      ? this.handleSubmitInspector
      : this.handleSubmitClient;

    return (
      <Widget className="widget-primary">
        <WidgetHeading
          style={
            isInspector
              ? {
                  fontSize: '14pt',
                  background: '#337ab7',
                  color: 'white',
                }
              : undefined
          }
        >
          {isInspector ? (
            <span>
              Create a new <b>Inspector</b> account on WFCF Organic
            </span>
          ) : (
            'Create a new account on WFCF Organic'
          )}
        </WidgetHeading>

        <form onSubmit={submitAction}>
          <WidgetBody>
            <RegistrationForm
              user={user}
              handleChange={this.handleChange}
              errors={errors}
            />
          </WidgetBody>

          <WidgetFooter className="text-right">
            <button type="submit" className="btn btn-primary" disabled={disableSubmit}>
              Register
            </button>
          </WidgetFooter>
        </form>
      </Widget>
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Register);
