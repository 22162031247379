import actions from 'sow/actions/pure';
import {
  LOAD_ALL_FOR_ORG_OSP_APP_REQUEST,
  LOAD_ALL_FOR_ORG_OSP_APP_SUCCESS,
  LOAD_ALL_FOR_ORG_OSP_APP_FAILURE,
} from 'sow/actions/worksheetAnswers';

const isLoading = (state = false, action) => {
  switch (action.type) {
    case LOAD_ALL_FOR_ORG_OSP_APP_REQUEST:
    case String(actions.landAnswers.loadOne):
    case String(actions.productAnswers.loadOne):
      return true;

    case LOAD_ALL_FOR_ORG_OSP_APP_FAILURE:
    case LOAD_ALL_FOR_ORG_OSP_APP_SUCCESS:
    case String(actions.landAnswers.loadOneDone):
    case String(actions.landAnswers.loadOneFail):
    case String(actions.productAnswers.loadOneDone):
    case String(actions.productAnswers.loadOneFail):
      return false;

    default:
      return state;
  }
};

export default isLoading;
