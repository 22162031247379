import * as R from 'ramda';
import { combineReducers } from 'redux';
import byId, * as fromById from './byId';

export default combineReducers({
  byId,
});

export const byIdState = R.path(['byId']);

export const getOspSection = R.compose(fromById.getOspSection, byIdState);

export const getOspSectionsByIds = R.curry((state, ids) =>
  R.filter(R.compose(R.contains(R.__, ids), R.prop('uuid')), byIdState(state)),
);

export const getOspSectionsByIdsList = R.curry((state, ids) => {
  if (!ids || R.isEmpty(ids)) return [];

  return R.map(getOspSection(state), ids);
});
