import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { coerceToInt } from 'sow/utils/selectors';
import Strong from 'sow/components/atoms/Strong';
import Atom from 'sow/components/atoms/Atom';
import Panel from 'sow/components/molecules/Panel';
import Button from 'sow/components/atoms/Button';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import NumberFormikField from 'sow/components/organisms/NumberFormikField';
import CheckboxButtonFormikField from 'sow/components/organisms/CheckboxButtonFormikField';

const coerceMaskedNum = R.pipe(
  R.toString,
  R.replace(/\D/g, ''), // << strip non-numeric chars
  coerceToInt,
);

const AcaSettingsWorkflowForm = ({ onSubmit, initialValues }) => (
  <Widget>
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        planMinNumLocations: Yup.number(),
        planEditLocationByOrgAllowed: Yup.boolean(),
      })}
      onSubmit={(values, formikBag) => {
        console.log('wflow', values);
        onSubmit(
          {
            ...values,
            planMinNumLocations: coerceMaskedNum(values.planMinNumLocations),
          },
          formikBag,
        );
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <WidgetBody>
            <Widget>
              <WidgetHeading>Location Workflow Settings</WidgetHeading>
              <WidgetBody>
                <Panel
                  warning
                  heading={
                    <Atom>
                      <Strong>NOTE </Strong>
                      Organizations can only submit Initial Plans once they have added the
                      minimum number of locations. Locations are optional when this is set
                      to <q>0</q> (zero).
                    </Atom>
                  }
                />
                <Field
                  name="planMinNumLocations"
                  label="Minimum number of locations"
                  component={NumberFormikField}
                  disabled={isSubmitting}
                  integerLimit={5}
                  style={{ width: '10em' }}
                  large
                  required
                />

                <Field
                  name="planEditLocationByOrgAllowed"
                  label="Allow organizations to edit locations"
                  disabled={isSubmitting}
                  component={CheckboxButtonFormikField}
                  required
                />
              </WidgetBody>
            </Widget>
          </WidgetBody>
          <WidgetFooter textRight>
            <Button type="submit" primary disabled={isSubmitting}>
              Save Changes
            </Button>
          </WidgetFooter>
        </Form>
      )}
    </Formik>
  </Widget>
);

AcaSettingsWorkflowForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default AcaSettingsWorkflowForm;
