export const accessTypeConstants = {
  everyone: 1,
  organizationType: 2,
  actionGroup: 3,
};

export const accessTypeToOptions = {
  2: 'organizationTypeOptions',
  3: 'actionGroupOptions',
};

export const accessTypeToName = {
  2: 'Organization Type',
  3: 'Action Group',
};
