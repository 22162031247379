import { createActions } from 'redux-actions';

const actions = createActions({
  PROFILE: {
    UPDATE_USER: [
      (userId, user) => ({ userId, user }),
      (userId, user, onSuccess) => ({ onSuccess }),
    ],
    UPDATE_USER_DONE: user => ({ user }),
    UPDATE_USER_FAIL: errors => ({ errors }),
  },
});

export default actions.profile;
