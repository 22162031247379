import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as organizationTypeActions from 'sow/actions/pure/organizationType';
import { apiGet, apiPost } from 'sow/sagas/api';

export const getOrganizationTypes = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/organization_type', payload);
    if (response.data) {
      if (meta.onSuccess) meta.onSuccess(response);
      else yield put(organizationTypeActions.loadOrganizationTypesDone(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getOrganizationTypeById = function*({ id, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/organization_type/${id}`);
    if (response.data) {
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addOrganizationType = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/organization_type', payload);

    if (response.data) {
      yield put(actions.shell.toast('success', 'Organization type added.'));
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateOrganizationType = function*({ id, payload, meta }) {
  try {
    const { response } = yield call(apiPost, `/admin/organization_type/${id}`, payload);

    if (response && response.data) {
      yield put(actions.shell.toast('success', 'Organization type updated.'));
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteOrganizationType = function*({ id, meta }) {
  try {
    const { response } = yield call(apiPost, `/admin/organization_type/delete/${id}`);
    if (response.data) {
      yield put(actions.shell.toast('success', 'Organization type deleted.'));
      if (meta.onSuccess) meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(organizationTypeActions.LOAD_ORGANIZATION_TYPES, getOrganizationTypes);
  yield takeEvery(
    organizationTypeActions.LOAD_ORGANIZATION_TYPE_BY_ID,
    getOrganizationTypeById,
  );
  yield takeEvery(organizationTypeActions.ADD_ORGANIZATION_TYPE, addOrganizationType);
  yield takeEvery(
    organizationTypeActions.UPDATE_ORGANIZATION_TYPE,
    updateOrganizationType,
  );
  yield takeEvery(
    organizationTypeActions.DELETE_ORGANIZATION_TYPE,
    deleteOrganizationType,
  );
}
