import * as R from 'ramda';
import * as ospAppAction from 'sow/actions/ospApplication';
import * as ospDef from 'sow/actions/pure/ospDefinition';
import * as ospDefAction from 'sow/actions/ospDefinition';
import { allQuestions } from '../helpers';

const byId = (state = {}, action) => {
  switch (action.type) {
    case ospAppAction.CREATE_ONE_FOR_ORG_SUCCESS:
    case ospAppAction.LOAD_ONE_FOR_ORG_SUCCESS:
    case ospDefAction.LOAD_ONE_FOR_ACA_SUCCESS:
    // case ospDefAction.SAVE_ONE_FOR_ACA_SUCCESS:
    case ospDef.UPDATE_ONE_DONE:
      return R.merge(state, allQuestions(action));

    default:
      return state;
  }
};

export default byId;

export const getIds = R.keys;
export const getQuestion = (state, id) => state[id];
