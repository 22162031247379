import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { ResourceDetailLoader } from 'sow/store/helpers';
import { fromPlanApp, fromRouter } from 'sow/store/selectors';
import {
  orgIdType,
  planAppChangeIdType,
  planAppChangeRequestIdType,
  planAppIdType,
} from 'sow/types';

const mapStateToProps = (state, props) => ({
  orgId: fromRouter.paramOrgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  changeId: fromPlanApp.changeId(state, props),
});

const PlanAppChangeCommentLoader = ({
  orgId,
  planAppId,
  changeRequestId,
  changeId,
  ...props
}) => (
  <ResourceDetailLoader
    resource={`org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes/${changeId}/comments`}
    entityType="changeComment"
    {...props}
  />
);

PlanAppChangeCommentLoader.defaultProps = {
  autoLoad: false,
};

PlanAppChangeCommentLoader.propTypes = {
  orgId: orgIdType.isRequired,
  planAppId: planAppIdType.isRequired,
  changeRequestId: planAppChangeRequestIdType.isRequired,
  changeId: planAppChangeIdType,
  children: PropTypes.func.isRequired,
  autoLoad: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppChangeCommentLoader);
