import React from 'react';
import styled from 'styled-components';

import Block from 'sow/components/atoms/Block';

const AnnouncementTitle = styled(Block)`
  word-break: break-word;
  font-weight: bold;
  font-size: 12pt;
  color: ${({ primary_color }) => (primary_color ? primary_color : '#da8407')};
`;

export default AnnouncementTitle;
