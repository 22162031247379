import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import OptionListPopover from 'sow/components/molecules/OptionListPopover';
import PopoverOption from 'sow/components/molecules/OptionListPopover/PopoverOption';
import SectionListItem from './SectionListItem';

const ItemVersionSectionList = ({
  anchorEl,
  hidePopover,
  fetchSectionToEdit,
  fetchSectionToClone,
  deleteSection,
  sectionList,
  listItemParams,
}) => (
  <div>
    <OptionListPopover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorEl={anchorEl}
      hidePopover={hidePopover}
    >
      <PopoverOption onClick={fetchSectionToEdit}>Edit</PopoverOption>
      <PopoverOption onClick={fetchSectionToClone}>Clone</PopoverOption>
      <PopoverOption onClick={deleteSection}>Delete</PopoverOption>
    </OptionListPopover>
    <DndProvider backend={HTML5Backend}>
      {sectionList.map((section, index) => (
        <SectionListItem
          key={section.id}
          index={index}
          section={section}
          {...listItemParams}
        />
      ))}
    </DndProvider>
  </div>
);

export default ItemVersionSectionList;
