import * as R from 'ramda';
import { schema } from 'normalizr';
import { makeCompositeKey } from './util';

export const aca = new schema.Entity('aca', {}, { idAttribute: 'id' });

export const inspector = new schema.Entity('inspector');

export const inspectorAccess = new schema.Entity('inspectorAccess');

export const org = new schema.Entity('org', {}, { idAttribute: 'id' });

/**
 * Response containing map of answer values and matrix row values keyed by question uuid
 * {
 *    values: { questionUuid: value, questionUuid: value, ... },
 *    matrixRows: { ... }
 * }
 */
export const worksheetAnswer = new schema.Entity(
  'worksheetAnswer',
  {},
  {
    // Make composite key to create unique index planAppId::worksheetId
    idAttribute: value => {
      const { osp_application_id, worksheet_uuid } = value;
      return makeCompositeKey(osp_application_id, worksheet_uuid);
    },
    processStrategy: entityValue => {
      return {
        id: entityValue.id,
        isNotApplicable: entityValue.is_not_applicable,
        ospApplicationId: entityValue.osp_application_id,
        readyForReview: entityValue.ready_for_review,
        reviewForRenewal: entityValue.review_for_renewal,
        worksheetUuid: entityValue.worksheet_uuid,
        answers: { ...entityValue.answers },
      };
    },
  },
);

// this allow a map (object) JSON resp to get normalized
export const worksheetAnswerMap = new schema.Values(worksheetAnswer);

export const locationWorksheetAnswer = new schema.Entity(
  'locationWorksheetAnswer',
  {},
  {
    idAttribute: value => {
      const { parentId, worksheetUuid } = value;
      return makeCompositeKey(parentId, worksheetUuid);
    },
  },
);

export const locationWorksheetAnswerMap = new schema.Values(locationWorksheetAnswer);

export const locationAnswers = new schema.Entity(
  'locationAnswers',
  {
    worksheetAnswers: locationWorksheetAnswerMap,
  },
  {
    idAttribute: 'parentId',
    processStrategy: entityValue => {
      const worksheetAnswers = R.mapObjIndexed(
        // add the parentId and worksheetUuid to each locationWorksheetAnswers
        // object so a composite key can be generated from it
        (value, key) => ({
          answers: value,
          parentId: entityValue.parentId,
          worksheetUuid: key,
        }),
        entityValue.answers.worksheets,
      );

      return {
        ...entityValue,
        worksheetAnswers,
      };
    },
  },
);

export const changeComment = new schema.Entity('changeComment');

export const change = new schema.Entity('change', { comments: [changeComment] });

export const changeRequest = new schema.Entity('changeRequest');

export const worksheetQuestion = new schema.Entity(
  'worksheetQuestion',
  {},
  { idAttribute: 'uuid' },
);

export const worksheet = new schema.Entity(
  'worksheet',
  { questions: [worksheetQuestion] },
  { idAttribute: 'uuid' },
);

export const planAppSection = new schema.Entity(
  'planAppSection',
  { worksheets: [worksheet] },
  { idAttribute: 'uuid' },
);

export const planApp = new schema.Entity('planApp', {
  changeRequest: changeRequest,
  definition: {
    sections: [planAppSection],
    miniOsp: {
      land: {
        facility: {
          section: planAppSection,
        },
        office: {
          section: planAppSection,
        },
        pasture: {
          section: planAppSection,
        },
        parcel: {
          section: planAppSection,
        },
      },
      product: {
        section: planAppSection,
      },
    },
    locationTypes: [planAppSection],
  },
});

export const planAppSummary = new schema.Entity('planAppSummary', {
  changeRequest: changeRequest,
});

export const planAppLocation = new schema.Entity('planAppLocation');

export const planAppProduct = new schema.Entity('planAppProduct');

export const planAppNote = new schema.Entity('planAppNote');

export const planAppInitialSummary = new schema.Entity(
  'planAppInitialSummary',
  {},
  {
    idAttribute: 'applicationId',
  },
);

/*
 * Resource Response Schemas
 *
 * These schemas describe entity mapping for API response that don't return a
 * single entity type. For example some endpoints show a summary version in the
 * index endpoint results that is different than loading an individual resource.
 */

export const changeRequestOverviewResp = {
  change_request: changeRequest,
  changes: [change],
};

export const planAppResp = {
  index: [planAppSummary],
  summary: planAppSummary,
  detail: planApp,
};
