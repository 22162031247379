import {
  FETCH_PENDING_REQUEST,
  FETCH_PENDING_SUCCESS,
  FETCH_PENDING_FAILURE,
} from 'sow/actions/aca';

const initialState = {
  items: [],
  isFetching: false,
};

export default function acaPendingList(state = initialState, action) {
  switch (action.type) {
    case FETCH_PENDING_REQUEST:
      return { ...initialState, isFetching: true };

    case FETCH_PENDING_SUCCESS:
      return { ...state, isFetching: false, items: [...action.data] };

    case FETCH_PENDING_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
}
