import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { orgRoute, worksheetOverviewRoute } from 'sow/routes';
import { planAppStateText } from 'sow/constants/planApp';
import Link from 'sow/components/atoms/Link';
import Glyphicon from 'sow/components/molecules/Glyphicon';
import Tooltip from 'sow/components/molecules/Tooltip';
import DataTable from 'sow/components/molecules/DataTable';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';

const PendingRow = ({ ospApp }) => (
  <TableRow>
    <TableCell textCenter valignMiddle warning>
      {planAppStateText[ospApp.application.state]}
    </TableCell>
    <TableCell textCenter valignMiddle>
      <Link to={orgRoute(ospApp.organization.id)}>{ospApp.organization.name}</Link>
    </TableCell>
    <TableCell textCenter valignMiddle>
      <Link
        to={worksheetOverviewRoute(ospApp.organization.id, ospApp.application.id)}
        className="btn btn-xs btn-success"
      >
        <Tooltip overlay="Go To Plan">
          <Glyphicon glyph="chevron-right" />
        </Tooltip>
      </Link>
    </TableCell>
  </TableRow>
);

PendingRow.propTypes = {
  ospApp: PropTypes.object.isRequired,
};

const NoItems = () => (
  <TableRow>
    <TableCell colSpan={3} textCenter textSuccess>
      None pending
    </TableCell>
  </TableRow>
);

const OspAppPendingList = ({ pendingList }) => {
  const sortPendingList = R.sortWith([R.ascend(R.path(['application', 'state']))]);
  const sortedPendingList = sortPendingList(pendingList);

  return (
    <DataTable
      selector={'.pendingTable'}
      tableDef={{
        pageLength: 50,
        destroy: true,
        columnDefs: [{ sortable: false, targets: 2 }],
      }}
    >
      <Table
        className={
          pendingList.length > 0
            ? 'dashboardTable dataTable pendingTable'
            : 'dashboardTable pendingTable'
        }
        bordered
        striped
        condensed
        head={
          <TableRow>
            <TableCell heading textCenter>
              State
            </TableCell>
            <TableCell heading textCenter>
              Organization
            </TableCell>
            <TableCell heading />
          </TableRow>
        }
      >
        {sortedPendingList.length > 0 ? (
          sortedPendingList.map(ospApp => (
            <PendingRow key={ospApp.application.id} ospApp={ospApp} />
          ))
        ) : (
          <NoItems />
        )}
      </Table>
    </DataTable>
  );
};

OspAppPendingList.propTypes = {
  pendingList: PropTypes.array.isRequired,
};

export default OspAppPendingList;
