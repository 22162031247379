import * as R from 'ramda';
import { createSelector } from 'reselect';

import {
  coerceToInt,
  coerceToIntOr,
  createSafeCachedSelector,
} from 'sow/utils/selectors';
import { selectors as fromEntities } from 'sow/store/modules/entities';
import { selectors as fromRouter } from 'sow/store/modules/router';

export const propSelector = propName => (state, props = {}) => props[propName];

// --
// -- Id Selectors
// --

export const acaIdProp = propSelector('acaId');

export const currentAcaId = createSelector(
  [acaIdProp, fromRouter.paramAcaId],
  (propId, paramId) => coerceToInt(propId || paramId),
);

/**
 * Create cached selector for acaId. This caches the selector based on the
 * passed in props.
 */
export const acaId = createSafeCachedSelector([currentAcaId], R.identity)(currentAcaId);

// --
// -- ACA - Base Selectors
// --

export const acaEntityDetailFn = state => {
  return acaId => fromEntities.getDetail(state, 'aca', acaId);
};

export const aca = createSelector(
  [acaId, acaEntityDetailFn],
  (entityId, getEntityDetail) => getEntityDetail(entityId),
);

// --
// -- ACA Config Selectors
// --

export const acaConfig = createSelector([aca], R.propOr({}, 'configuration'));

export const configPlanEditLocationByOrgAllowed = createSelector(
  [acaConfig],
  R.pipe(
    R.path(['planEditLocationByOrgAllowed']),
    flag => !!flag, // NOTE this makes it default to false
  ),
);

export const configPlanMinNumLocations = createSelector(
  [acaConfig],
  R.pipe(
    R.path(['planMinNumLocations']),
    coerceToIntOr(0),
  ),
);
