import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { orgIdType, planAppIdType, planAppType } from 'sow/types';
import {
  isProgressComplete,
  isStateClosed,
  isStateInitialApplication,
  isStateRevision,
  isStateWithdrawn,
  isStateClosedAndLocked,
} from 'sow/utils/planAppState';
import { within90Days } from 'sow/utils/dateTime';

import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import Confirm from 'sow/components/molecules/Confirm';
import InfoModal from 'sow/components/molecules/InfoModal';
import Paragraph from 'sow/components/atoms/Paragraph';

const submitInitialConfirmText = (
  <Fragment>
    <Paragraph>You are about to submit your Plan for review by your Certifier.</Paragraph>
    <Paragraph>
      Please take this time to review your Plan and double-check that you have selected
      all of the relevant statements on the Activities Checklist describing your
      operation, and only those statements that apply to your operation, and that you have
      completed all worksheets.
    </Paragraph>
    <Paragraph>
      Once you submit the Plan for review it may still be edited, but there will be
      additional steps and answer verification to make changes to your worksheets. Save
      time, check twice!
    </Paragraph>
  </Fragment>
);

const OrgDashboardActionButtonsOrg = ({
  planApp,
  disabled,
  hasOpenPlanApp,
  isPlanAppIdMostRecent,
  onSubmitInitial,
  onSubmitUpdate,
  onUnlockForUpdate,
  anniversaryDate,
  isStaff,
}) => {
  const allowUpdate = anniversaryDate ? within90Days(anniversaryDate) || isStaff : true;
  const showUnlockForUpdateButton =
    !hasOpenPlanApp &&
    isPlanAppIdMostRecent &&
    isStateClosed(planApp) &&
    !isStateWithdrawn(planApp);

  const showSubmitInitialApplicationButton =
    isStateInitialApplication(planApp) && isProgressComplete(planApp);
  const showSubmitUpdateButton = isStateRevision(planApp);

  return (
    <Block>
      {!isStateClosedAndLocked(planApp) && showUnlockForUpdateButton && (
        <Button
          className="submit-plan-btn"
          onClick={onUnlockForUpdate}
          disabled={disabled}
        >
          Update Plan
        </Button>
      )}

      {showSubmitInitialApplicationButton && (
        <Confirm
          message={submitInitialConfirmText}
          onConfirm={onSubmitInitial}
          cancelButtonText="Cancel and Review"
          confirmButtonText="Submit Plan to Certifier"
        >
          {toggleConfirm => (
            <Button
              className="submit-plan-btn"
              onClick={toggleConfirm}
              disabled={disabled}
            >
              Submit Plan for Review
            </Button>
          )}
        </Confirm>
      )}

      {showSubmitUpdateButton && (
        <Fragment>
          {allowUpdate ? (
            <Confirm
              message={'Submit your Plan updates for review by your Certifier?'}
              onConfirm={onSubmitUpdate}
            >
              {toggleConfirm => (
                <Button
                  className="submit-plan-btn"
                  onClick={toggleConfirm}
                  disabled={disabled}
                >
                  Submit Plan Update for Review
                </Button>
              )}
            </Confirm>
          ) : (
            <InfoModal
              title="You cannot submit your renewal OSP until you are within 90 days of the anniversary date."
              message={`If you made a significant change to your plan and would like your certification specialist to review it quickly, please contact your WFCFO main contact directly via email or phone. Hit this submit plan button again to successfully submit the renewal OSP when you are closer to your anniversary date: ${anniversaryDate}.`}
            >
              {toggle => (
                <Button className="submit-plan-btn" onClick={toggle} disabled={disabled}>
                  Submit Plan Update for Review
                </Button>
              )}
            </InfoModal>
          )}
        </Fragment>
      )}
    </Block>
  );
};

OrgDashboardActionButtonsOrg.propTypes = {
  orgId: orgIdType.isRequired,
  planAppId: planAppIdType.isRequired,
  planApp: planAppType,
  hasOpenPlanApp: PropTypes.bool.isRequired,
  isPlanAppIdMostRecent: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onSubmitInitial: PropTypes.func.isRequired,
  onSubmitUpdate: PropTypes.func.isRequired,
  onUnlockForUpdate: PropTypes.func.isRequired,
};

export default OrgDashboardActionButtonsOrg;
