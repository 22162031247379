import React, { Fragment, Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as sowTypes from 'sow/types';

import { resourceUpdate } from 'sow/store/helpers';
import Heading from 'sow/components/atoms/Heading';
import InfoPanel from 'sow/components/organisms/InfoPanel';
import InfoTooltip from 'sow/components/organisms/InfoTooltip';
import Spacer from 'sow/components/atoms/Spacer';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import PlanAppActionBar from 'sow/components/organisms/PlanAppActionBar';
import WorksheetBaseQuestion from 'sow/components/organisms/WorksheetBaseQuestion';
import WorksheetMatrix from 'sow/components/organisms/WorksheetMatrix';
import WorksheetRegulations from 'sow/components/organisms/WorksheetRegulations';
import WorksheetQuestionChange from 'sow/containers/planApp/WorksheetQuestionChange';
import ScrollToHashTargetOnMount from 'sow/components/atoms/ScrollToHashTargetOnMount';
import WorksheetBaseQuestionChange from 'sow/containers/planApp/WorksheetBaseQuestionChange';
import RouterWillLeaveBlock from 'sow/components/atoms/RouterWillLeaveBlock';
import PlanAppConditionalModal from '../PlanAppConditionalModal';
import PlanAppWorksheetCreateRequestButton from 'sow/containers/planApp/WorksheetCreateRequestButton';
import Button from 'sow/components/atoms/Button';
import Spinner from 'sow/components/atoms/Spinner';
import * as adminRequestActions from 'sow/actions/adminRequest';
import { clearRequestsForWorksheet } from 'sow/utils/worksheet';

const noop = () => {};

const clearAllRequests = (orgId, planAppId, worksheetId, clearRequest, requestList) =>
  clearRequestsForWorksheet(orgId, planAppId, worksheetId, clearRequest, requestList);

const acceptChangeResource = (orgId, planAppId, changeRequestId, worksheet_id) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/ws_changes/${worksheet_id}/accept_all`,
    'changeRequestOverviewResp',
  );

// http://localhost:3002/api/v1/org/5966/application/3374/change_request/149/changes/17361/reject
const discardChangeResource = (orgId, planAppId, changeRequestId, worksheet_id) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/ws_changes/${worksheet_id}/reject_all`,
    'changeRequestOverviewResp',
  );

const WorksheetHeader = ({
  worksheet = {},
  panelHighlight,
  isCSStaff,
  isPlanAppClosed,
  hasPendingChangesForWorksheet,
  acceptAll,
  discardAll,
  clearRequests,
  orgId,
  planAppId,
  showAcaUI,
  worksheetId,
  isStateUnderReview,
  isStateInitialApplication,
  isInspector,
}) => (
  <WidgetHeading className={panelHighlight}>
    {worksheet.name || 'Worksheet'}
    {worksheet.tooltip && <InfoTooltip overlay={worksheet.tooltip} />}
    {isCSStaff && !isPlanAppClosed && isStateUnderReview && !isInspector && (
      <div className="pull-right" style={{ textAlign: 'right', marginTop: '-5px' }}>
        <Button onClick={acceptAll} disabled={!hasPendingChangesForWorksheet}>
          Accept All
        </Button>
        <Button onClick={discardAll} disabled={!hasPendingChangesForWorksheet}>
          Discard All
        </Button>
        <PlanAppWorksheetCreateRequestButton
          orgId={orgId}
          planAppId={planAppId}
          showAcaUI={showAcaUI}
          worksheetId={worksheetId}
          worksheetName={worksheet.name}
          primary
        />
        <Button onClick={clearRequests}>Clear To-dos</Button>
      </div>
    )}
  </WidgetHeading>
);

WorksheetHeader.propTypes = {
  worksheet: sowTypes.planAppWorksheetType.isRequired,
  onClickSubmit: PropTypes.func.isRequired,
  onClickSubmitNext: PropTypes.func.isRequired,
  onClickSubmitPrev: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
  isInspector: PropTypes.bool,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => {
  return {
    acceptChange: action => dispatch(action(null)),
    rejectChange: action => dispatch(action(null)),
    clearRequest: (orgId, planAppId, requestId) =>
      dispatch(adminRequestActions.clearAdminRequest(orgId, planAppId, requestId)),
  };
};

class PlanAppWorksheetForm extends Component {
  state = {
    isLoading: false,
    showModal: false,
    modalType: 'standard',
    formData: {
      form: null,
      field: null,
      value: null,
    },
    conditionalChangeRequests: {
      reset: false,
      parentQuestionId: null,
      conditionalQuestions: [],
    },
  };

  componentDidUpdate(prevProps) {
    const changeList = this.props.changeList;
    if (
      changeList &&
      changeList.length > 0 &&
      changeList.length !== prevProps.changeList.length
    ) {
      const questionId = R.last(changeList).questionId;
      if (this.isParentQuestion(questionId)) {
        this.setState({
          conditionalChangeRequests: {
            reset: true,
            parentQuestionId: questionId,
            conditionalQuestions: this.getConditionalQuestions(questionId),
          },
        });
      }
    }
    if (this.state.isLoading && changeList && changeList.length > 0) {
      let isProcessing = false;
      changeList.map(change => {
        if (this.isOpen(change)) {
          isProcessing = true;
        }
      });
      if (!isProcessing) {
        this.setState({ isLoading: false });
      }
    }
  }

  isOpen = R.allPass([R.pipe(R.prop('state'), R.contains(R.__, ['open']))]);

  isAccepted = R.allPass([
    R.pipe(R.prop('state'), R.contains(R.__, ['accepted', 'applied'])),
  ]);

  resetConditionalChangeRequests = () => {
    this.setState({
      conditionalChangeRequests: {
        reset: false,
        parentQuestionId: null,
        conditionalQuestions: [],
      },
    });
  };

  showModal = () => {
    this.setState({
      showModal: true,
    });
  };

  modalType = type => {
    this.setState({
      modalType: type,
    });
  };

  holdFormData = (form, field, value) => {
    this.setState({
      formData: {
        form,
        field,
        value,
      },
    });
  };

  clearFormData = () => {
    this.setState({
      formData: {
        form: null,
        field: null,
        value: null,
      },
    });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  parentQuestionHasConditionalQuestions = question =>
    question.conditions && question.conditions.length > 0;

  conditionalQuestions = R.filter(
    this.parentQuestionHasConditionalQuestions,
    this.props.worksheetQuestionList,
  );

  questionsWithConditions = () => {
    const ids = R.map(
      R.path(['conditions', 0, 'condition', 'questionUuid']),
      this.conditionalQuestions,
    );
    return ids;
  };

  isParentQuestion = questionId => {
    return R.includes(questionId, this.questionsWithConditions());
  };

  foundCondition(el, id) {
    if (el.conditions && el.conditions.length === 1) {
      if (el.conditions[0].condition.questionUuid === id) return true;
      else return false;
    }
  }

  getConditionalQuestions = questionId => {
    const questions = this.conditionalQuestions.filter(el =>
      this.foundCondition(el, questionId),
    );
    return R.map(R.path(['uuid']), questions);
  };

  render() {
    const {
      orgId,
      planAppId,
      worksheetId,
      worksheet,
      values,
      isPlanAppLocked,
      isPlanAppClosed,
      isStateInitialApplication,
      isStateUnderReview,
      changeRequestIsOpen,
      onClickSubmit,
      onClickSubmitNext,
      isCSStaff,
      onClickSubmitPrev,
      clearRequest,
      showAcaUI,
      changeRequest,
      changeList,
      isSubmitting,
      isWorksheetNotApplicable,
      isDirty,
      requestList,
      acceptChange,
      rejectChange,
      isInspector,
    } = this.props;

    const { showModal, modalType, conditionalChangeRequests } = this.state;

    const worksheetAnswers = R.prop('worksheetAnswers', values);
    const isWorksheetNA = R.pathOr(false, ['isNotApplicable'], worksheetAnswers);

    // Show the N/A option when the worksheet definition specifies the value may be set
    const isWorksheetLocked =
      isWorksheetNA || isPlanAppLocked || !isStateInitialApplication;

    const changeUIDisabled = isWorksheetNotApplicable;

    function foundQuestion(el, id) {
      if (el.uuid === id) return true;
      else return false;
    }

    const getQuestion = (arr, id) => {
      const matchingQuestion = arr.filter(el => foundQuestion(el, id));
      if (matchingQuestion.length == 1) return matchingQuestion[0];
      else return {};
    };

    const showConditionalQuestion = questionId => {
      const question = getQuestion(this.conditionalQuestions, questionId);

      // Show pending questions only for admins
      if (showAcaUI && hasPendingChanges(questionId)) return true;

      if (question.conditions) {
        // There is only one condition per question so we can use zero index
        const condition = R.pathOr({}, ['conditions', 0], question);
        const conditionType = R.path(['conditionType'], condition);
        const conditionQuestionId = R.path(['condition', 'questionUuid'], condition);
        const conditionAnswerValue = R.path(['condition', 'answerValue'], condition);

        if (conditionType === 'basic' && conditionAnswerValue != null) {
          const answerValue = findConditionalAnswerValue(conditionQuestionId);
          if (answerValue !== conditionAnswerValue) {
            return false;
          }
        }
      }

      return true;
    };

    const findConditionalAnswerValue = questionId => {
      const matchingChange = values.worksheetAnswersChanges.values[questionId];
      const matchingValue = values.worksheetAnswers.answers.values[questionId];
      if (matchingChange != null) {
        return matchingChange;
      }
      if (matchingValue != null) {
        return matchingValue;
      }
      return null;
    };

    const clearConditionalQuestions = questionId => {
      const questions = this.getConditionalQuestions(questionId);
      const newValues = R.clone(values.worksheetAnswers.answers.values);
      const changeValues = R.clone(values.worksheetAnswersChanges.values);

      questions.forEach(id => {
        newValues[id] = null;
        changeValues[id] = null;
      });

      values.worksheetAnswers.answers.values = newValues;
      values.worksheetAnswersChanges.values = changeValues;
    };

    const hasConditionalQuestionChanges = (questionId, values) => {
      const questions = this.getConditionalQuestions(questionId);
      let hasChanges = false;
      questions.forEach(id => {
        if (!R.isNil(values[id])) hasChanges = true;
      });
      return hasChanges;
    };

    const hasConditionalChanges = questionId => {
      return hasConditionalQuestionChanges(
        questionId,
        values.worksheetAnswersChanges.values,
      );
    };

    const getChangeForConditionalQuestion = questionId => {
      let pendingChange = null;
      const newChanges = this.props.values.worksheetAnswers.answers.values;

      // check changelist
      if (changeList.length > 0) {
        changeList.forEach(change => {
          if (
            questionId === change.questionId &&
            change.state &&
            change.state !== 'accepted'
          ) {
            pendingChange = change;
          }
        });
      } else if (R.has(questionId, newChanges)) {
        pendingChange = newChanges[questionId];
      }

      return pendingChange;
    };

    const getContentForConditionalQuestion = questionId => {
      let content = null;
      const newChanges = this.props.values.worksheetAnswers.answers.values;

      if (changeList.length > 0) {
        changeList.forEach(change => {
          if (questionId === change.questionId && change.new) {
            content = change.new;
          }
        });
      }

      if (content == null && newChanges[questionId]) {
        content = newChanges[questionId];
      }

      return content;
    };

    const hasConditionalPendingChanges = questionId => {
      const questions = this.getConditionalQuestions(questionId);
      let change = null;
      let hasChanges = false;
      questions.forEach(id => {
        change = getChangeForConditionalQuestion(id);
        if (change != null) {
          hasChanges = true;
          if (change.state && change.state !== 'open') {
            hasChanges = false;
          }
        }
      });
      return hasChanges;
    };

    const hasConditionalQuestionValue = parentQuestionId => {
      const questions = this.getConditionalQuestions(parentQuestionId);
      let content = null;
      let hasContent = false;
      questions.forEach(id => {
        content = getContentForConditionalQuestion(id);
        if (content != null) {
          hasContent = true;
          if (content.state && content.state !== 'accepted') {
            hasContent = false;
          }
        }
      });
      return hasContent;
    };

    const getConditionalQuestionAnswerValue = parentQuestionId => {
      const questions = this.getConditionalQuestions(parentQuestionId);
      let returnVal = null;
      questions.forEach(id => {
        const question = getQuestion(this.conditionalQuestions, id);
        if (question.conditions) {
          // There is only one condition per question so we can use zero index
          const condition = R.pathOr({}, ['conditions', 0], question);
          const conditionType = R.path(['conditionType'], condition);
          const conditionAnswerValue = R.path(['condition', 'answerValue'], condition);
          if (conditionType === 'basic' && conditionAnswerValue != null) {
            returnVal = conditionAnswerValue;
          }
        }
      });
      return returnVal;
    };

    const hasPendingChanges = questionId => {
      const change = R.filter(R.propEq('questionId', questionId), changeList);
      const hasChange = R.filter(R.propEq('state', 'open'), change);
      return hasChange.length ? true : false;
    };

    const getPendingChangesForWorksheet = () =>
      R.filter(R.propEq('state', 'open'))(changeList);

    const hasPendingChangesForWorksheet = R.not(
      R.isEmpty(getPendingChangesForWorksheet()),
    );

    const getWorksheetRequests = worksheetId =>
      R.filter(R.where({ worksheet_id: R.equals(worksheetId), question_id: R.isNil }))(
        requestList,
      );

    const worksheetHasRequest = worksheetId =>
      !R.isEmpty(getWorksheetRequests(worksheetId));

    const headerColor = worksheetHasRequest(worksheetId) ? '#337ab7' : '#000000';
    const panelHighlight = worksheetHasRequest(worksheetId)
      ? 'widget-header-requested'
      : '';

    const isClearingBooleanAction = (value, parentQuestionId) => {
      const answerValue = getConditionalQuestionAnswerValue(parentQuestionId);
      if (value == null) return true;
      else return value !== answerValue;
    };

    const confirmClearConditionals = (parentQuestionId, value, form, field) => {
      let hasPendingChanges = hasConditionalPendingChanges(parentQuestionId);
      const hasValue = hasConditionalQuestionValue(parentQuestionId);
      const { isStateInitialApplication } = this.props;

      if (hasPendingChanges && !isStateInitialApplication) {
        this.modalType('pending');
      } else {
        this.modalType('standard');
      }

      if (!hasPendingChanges && hasValue && !isStateInitialApplication) {
        this.modalType('standard');
        hasPendingChanges = true;
      }

      if (
        hasPendingChanges &&
        this.isParentQuestion(parentQuestionId) &&
        isClearingBooleanAction(value, parentQuestionId) &&
        hasConditionalChanges(parentQuestionId)
      ) {
        this.holdFormData(form, field, value);
        this.showModal();
      } else {
        form.setFieldValue(field.name, value, false);
      }
    };

    const responseFromModal = response => {
      const formData = this.state.formData;
      if (response === 'ok') {
        const questionId = R.last(R.split('.', formData.field.name));
        clearConditionalQuestions(questionId);
        formData.form.setFieldValue(formData.field.name, formData.value, false);
        this.clearFormData();
      }
      this.hideModal();
    };

    const checkParentQuestionOpenChangeRequest = questionId => {
      // A new parent question is being changed, create new cr's for child questions
      let openChangeRequest = false;
      if (conditionalChangeRequests.reset) {
        const questions = this.getConditionalQuestions(
          conditionalChangeRequests.parentQuestionId,
        );

        if (
          R.includes(questionId, conditionalChangeRequests.conditionalQuestions) &&
          R.includes(questionId, questions) &&
          !R.find(R.propEq('questionId', questionId), changeList)
        ) {
          openChangeRequest = true;
        }
      }
      return openChangeRequest;
    };

    const acceptAll = () => {
      if (hasPendingChangesForWorksheet) {
        this.setState({ isLoading: true });
        const { action } = acceptChangeResource(
          orgId,
          planAppId,
          changeRequest.id,
          worksheetId,
        );
        acceptChange(action);
      }
    };

    const discardAll = () => {
      if (hasPendingChangesForWorksheet) {
        this.setState({ isLoading: true });
        const { action } = discardChangeResource(
          orgId,
          planAppId,
          changeRequest.id,
          worksheetId,
        );
        rejectChange(action);
      }
    };

    const clearRequests = () => {
      clearAllRequests(orgId, planAppId, worksheetId, clearRequest, requestList);
    };

    if (this.state.isLoading) return <Spinner />;

    return (
      <Form>
        <RouterWillLeaveBlock
          blocked={isDirty}
          message="This worksheet contains unsaved changes, are you sure you would like to navigate away?"
        />

        <Widget>
          <ScrollToHashTargetOnMount />
          <WorksheetHeader
            worksheet={worksheet}
            onClickSubmit={onClickSubmit}
            onClickSubmitNext={onClickSubmitNext}
            onClickSubmitPrev={onClickSubmitPrev}
            submitDisabled={isSubmitting}
            panelHighlight={panelHighlight}
            isCSStaff={isCSStaff}
            isPlanAppClosed={isPlanAppClosed}
            hasPendingChangesForWorksheet={hasPendingChangesForWorksheet}
            acceptAll={acceptAll}
            discardAll={discardAll}
            clearRequests={clearRequests}
            orgId={orgId}
            planAppId={planAppId}
            showAcaUI={showAcaUI}
            worksheetId={worksheetId}
            isStateUnderReview={isStateUnderReview}
            isStateInitialApplication={isStateInitialApplication}
            isInspector={isInspector}
          />
          <WidgetBody>
            {worksheet.header && <InfoPanel text={worksheet.header} />}

            {showModal && (
              <PlanAppConditionalModal
                questions={worksheet.questions}
                title="Confirm change"
                modalType={modalType}
                showModal={showModal}
                onHideModal={response => responseFromModal(response)}
              />
            )}

            {isWorksheetNA && (
              <WorksheetBaseQuestionChange
                worksheetId={worksheet.uuid}
                name="isNotApplicable"
                baseAnswersName="worksheetAnswers"
                baseAnswersChangesName="worksheetAnswersChanges"
                isSubmitting={isSubmitting}
                // Never disable change UI here, so they can change the N/A value
                changeUIDisabled={false}
                showAcaUI={showAcaUI}
              />
            )}

            <WorksheetRegulations regulations={worksheet.regulations} />

            <Heading level={3} textMuted={isWorksheetLocked}>
              Worksheet Questions
            </Heading>

            {worksheet.matrix === false &&
              worksheet.questions.map(
                questionId =>
                  showConditionalQuestion(questionId) && (
                    <WorksheetQuestionChange
                      key={questionId}
                      questionId={questionId}
                      disabled={isWorksheetLocked}
                      changeUIDisabled={changeUIDisabled}
                      confirmClearConditionals={confirmClearConditionals}
                      openChangeRequest={checkParentQuestionOpenChangeRequest(questionId)}
                      orgId={orgId}
                      planAppId={planAppId}
                      worksheetId={worksheetId}
                      changeRequestId={changeRequest ? changeRequest.id : null}
                      answerValue={findConditionalAnswerValue(questionId)}
                      baseAnswersName="worksheetAnswers.answers.values."
                      baseAnswersChangesName="worksheetAnswersChanges.values."
                    />
                  ),
              )}

            {worksheet.matrix === true && (
              <Fragment>
                <WorksheetMatrix
                  orgId={orgId}
                  planAppId={planAppId}
                  worksheetId={worksheetId}
                  questions={worksheet.questions}
                  values={values}
                  changeRequest={changeRequest}
                  changeList={changeList}
                  showAcaUI={showAcaUI}
                  isWorksheetLocked={isWorksheetLocked}
                  isPlanAppLocked={isPlanAppLocked}
                  isPlanAppClosed={isPlanAppClosed}
                  changeRequestIsOpen={changeRequestIsOpen}
                  changeUIDisabled={changeUIDisabled}
                  isInspector={isInspector}
                />
                <Spacer vertical={20} />
              </Fragment>
            )}

            {!isPlanAppLocked && (
              <WorksheetBaseQuestion
                name="worksheetAnswers.readyForReview"
                text="Are you finished with this worksheet?"
                fieldType="bool"
                orgId={orgId}
                ospId={planAppId}
                worksheetId={worksheet.uuid}
                answer={worksheetAnswers.readyForReview}
                disabled={isSubmitting || isWorksheetLocked}
              />
            )}
          </WidgetBody>

          <PlanAppActionBar
            onClickSubmit={onClickSubmit}
            onClickSubmitNext={onClickSubmitNext}
            onClickSubmitPrev={onClickSubmitPrev}
            submitDisabled={isSubmitting}
            saveable={isStateInitialApplication || changeRequestIsOpen}
          />
        </Widget>
      </Form>
    );
  }
}

PlanAppWorksheetForm.propTypes = {
  onClickSubmit: noop,
  onClickSubmitNext: noop,
  onClickSubmitPrev: noop,
};

PlanAppWorksheetForm.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheetId: sowTypes.planAppWorksheetIdType.isRequired,
  values: PropTypes.object,
  worksheet: sowTypes.planAppWorksheetType.isRequired,
  showAcaUI: PropTypes.bool,
  changeRequest: sowTypes.planAppChangeRequestType,
  changeList: sowTypes.planAppChangeListType,
  submitDisabled: PropTypes.bool,
  changeRequestIsOpen: PropTypes.bool,
  isCSStaff: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isPlanAppClosed: PropTypes.bool,
  isPlanAppLocked: PropTypes.bool,
  isStateInitialApplication: PropTypes.bool,
  onClickSubmit: PropTypes.func.isRequired,
  onClickSubmitNext: PropTypes.func.isRequired,
  onClickSubmitPrev: PropTypes.func.isRequired,
  isWorksheetNotApplicable: PropTypes.bool.isRequired,
  isDirty: PropTypes.bool,
  requestList: PropTypes.array,
  isInspector: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PlanAppWorksheetForm);
