import * as R from 'ramda';
import { createSelector } from 'reselect';
import { propSelector } from 'sow/utils/selectors';
import {
  changeCommentEntity,
  changeList,
  currentLocationId,
  matrixRowId,
  qualifiedWorksheetAnswersFiles,
  worksheetChangeList,
  worksheetId,
  worksheetQuestionList,
} from './core';
import { formWorksheetInitialValues } from './form';
import { allLocationWorksheetAnswersFiles } from './location';
import {
  locationFormInitialValuesAnswers,
  locationFormInitialValuesAnswersChanges,
} from './locationForm';

// --
// -- Predicate Functions
// --

export const isOpen = R.equals('open');
export const isAccepted = R.equals('accepted');
export const isRejected = R.equals('rejected');
export const isApplied = R.equals('applied');
export const isNotApplied = R.equals('not_applied');
export const isOpenOrAccepted = R.anyPass([isOpen, isAccepted]);

// context (change predicates)
export const isContextLocation = R.propEq('context', 'location');
export const isContextMain = R.propEq('context', 'main');

export const isActionAdded = R.propEq('action', 'added');
export const isActionDeleted = R.propEq('action', 'deleted');
export const isActionUpdated = R.propEq('action', 'updated');

/**
 * Change Classifier Predicates
 */

export const isChangeActivityChecklist = R.whereEq({
  context: 'main',
  type: 'plan',
  action: 'updated',
});

export const isChangeLocationAdded = R.whereEq({
  context: 'location',
  type: 'self',
  action: 'added',
});

export const isChangeLocationDeleted = R.whereEq({
  context: 'location',
  type: 'self',
  action: 'deleted',
});

export const isChangeMatrixRowDeleted = R.whereEq({
  type: 'matrix_row',
  action: 'deleted',
});

export const isChangeMatrixRowQuestionAnswer = R.whereEq({
  type: 'matrix_row_answer',
});

export const isChangeWorksheetNotApplicable = R.whereEq({
  context: 'main',
  type: 'worksheet',
  action: 'updated',
});

export const isChangeWorksheetQuestionAnswer = R.whereEq({
  type: 'worksheet_answer',
});

// Change classifier (general)
export const isChangeActive = R.whereEq({
  blocked: false,
  ignored: false,
});

// --
// -- Filter Functions
// --

// --
// -- Selector Functions
// --

export const exportConfigProp = propSelector('exportConfig');

export const exportConfigOrgId = createSelector([exportConfigProp], R.path(['orgId']));

export const exportConfigPlanAppId = createSelector(
  [exportConfigProp],
  R.path(['PlanAppId']),
);

export const exportConfigShowMainPlan = createSelector(
  [exportConfigProp],
  R.path(['showMainPlan']),
);

export const exportConfigShowLocations = createSelector(
  [exportConfigProp],
  R.path(['showLocations']),
);

export const exportConfigAddNotes = createSelector(
  [exportConfigProp],
  R.path(['addNotes']),
);

export const exportConfigAddComments = createSelector(
  [exportConfigProp],
  R.path(['addComments']),
);

export const exportConfigAddFiles = createSelector(
  [exportConfigProp],
  R.path(['addFiles']),
);

/**
 * Returns changes for current Plan App that are not blocked or ignored.
 */
export const activeChangeList = createSelector(
  [changeList],
  R.compose(R.defaultTo([]), R.filter(isChangeActive)),
);

export const exportMainPlanMatrixRowAnswerMap = createSelector(
  [worksheetId, formWorksheetInitialValues],
  (worksheetId, { worksheetAnswers, worksheetAnswersChanges }) => {
    const answers = R.path(['answers', 'matrixRows'], worksheetAnswers);
    const changeAnswers = R.path(['matrixRows'], worksheetAnswersChanges);
    const liveAnswers = R.mergeDeepLeft(changeAnswers, answers);
    return liveAnswers;
  },
);

export const exportLocationMatrixRowAnswerMap = createSelector(
  [
    currentLocationId,
    worksheetId,
    locationFormInitialValuesAnswers,
    locationFormInitialValuesAnswersChanges,
  ],
  (locationId, worksheetId, worksheetAnswers, worksheetAnswersChanges) => {
    if (R.isNil(locationId)) return;

    const answers = R.path(
      ['answers', 'worksheets', worksheetId, 'answers', 'matrixRows'],
      worksheetAnswers,
    );
    const changeAnswers = R.path(
      ['answersChanges', 'worksheets', worksheetId, 'answers', 'matrixRows'],
      worksheetAnswersChanges,
    );

    // TODO conditionally merge based on change state ('open','accepted')
    const liveAnswers = R.mergeDeepLeft(changeAnswers, answers);
    return liveAnswers;
  },
);

export const exportMatrixRowAnswerMap = createSelector(
  [currentLocationId, exportMainPlanMatrixRowAnswerMap, exportLocationMatrixRowAnswerMap],
  (locationId, mainPlanMatrixRowAnswerMap, locationMatrixRowAnswerMap) => {
    if (R.isNil(locationId)) return mainPlanMatrixRowAnswerMap;
    return locationMatrixRowAnswerMap;
  },
);

const sortWorksheet = (matrixRows, questionList) => {
  const firstRowUuid = R.pathOr(null, ['uuid'], questionList[0]);
  const secondRowUuid = R.pathOr(null, ['uuid'], questionList[1]);

  const validAnswer = R.is(String);

  const sortableMatrix = [];
  for (let matrixRowId in matrixRows) {
    const firstRowAnswer = R.pathOr(
      '',
      [matrixRowId, 'values', firstRowUuid],
      matrixRows,
    );
    const secondRowAnswer = R.pathOr(
      '',
      [matrixRowId, 'values', secondRowUuid],
      matrixRows,
    );

    sortableMatrix.push({
      firstRowAnswer: validAnswer(firstRowAnswer) ? firstRowAnswer : '',
      secondRowAnswer: validAnswer(secondRowAnswer) ? secondRowAnswer : '',
      matrixRowId,
    });
  }
  const sortedMatrix = R.sortWith([
    R.ascend(R.compose(R.toLower, R.propOr('', 'firstRowAnswer'))),
    R.ascend(R.compose(R.toLower, R.propOr('', 'secondRowAnswer'))),
  ])(sortableMatrix);

  return sortedMatrix.map(row => row.matrixRowId);
};

export const exportMatrixRowIdList = createSelector(
  [worksheetId, worksheetQuestionList, exportMatrixRowAnswerMap],
  (wsId, questionList, matrixRows) => {
    if (questionList.length > 0) {
      const ids = sortWorksheet(matrixRows, questionList);
      return ids;
    } else {
      const ids = R.keys(matrixRows);
      return ids;
    }
  },
);

/**
 * Return list of changes for current worksheet matrix row (based on worksheetId and changeId)
 */
export const exportMatrixRowChangeList = createSelector(
  [matrixRowId, worksheetChangeList],
  (matrixRowId, wsChangeList) => {
    const changeList = R.filter(R.propEq('matrixRowId', matrixRowId))(wsChangeList);
    return changeList;
  },
);

export const exportMatrixRowCommentList = createSelector(
  [exportMatrixRowChangeList, changeCommentEntity],
  (changeList, commentEntity) => {
    const commentIdList = R.chain(R.prop('comments'))(changeList);
    const commentList = R.map(R.prop(R.__, commentEntity), commentIdList);
    return commentList;
  },
);

export const exportFileIdList = createSelector(
  [
    exportConfigShowMainPlan,
    exportConfigShowLocations,
    qualifiedWorksheetAnswersFiles,
    allLocationWorksheetAnswersFiles,
  ],
  (showMainPlan, showLocations, mainPlanFiles, locationsFiles) => {
    const allFiles = R.concat(
      showMainPlan ? mainPlanFiles : [],
      showLocations ? locationsFiles : [],
    );
    const fileIdList = R.pipe(R.map(R.prop('id')))(allFiles);
    return fileIdList;
  },
);
