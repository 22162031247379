import React from 'react';

import './CSSModules/ProductSearchPage.module.css';
import OrgPageTemplate from 'sow/containers/OrgPageTemplate';
import ProductSearchFiltersOrg from 'sow/containers/ProductSearchFiltersOrg';
import ProductSearchResultsTable from 'sow/containers/ProductSearchResultsTable';

const OrgProductSearchPage = () => (
  <OrgPageTemplate title="Products">
    <div className="product-search-page">
      <ProductSearchFiltersOrg />
      <ProductSearchResultsTable />
    </div>
  </OrgPageTemplate>
);

export default OrgProductSearchPage;
