import React from 'react';

import { API_URL } from 'sow/constants/api';

const fileUrl = fileId => `${API_URL}/v1/file/${fileId}`;

const FileDownloadLink = ({ children, fileId, ...props }) => (
  <div>
    <a href={fileUrl(fileId)} target="_blank" {...props}>
      {children}
    </a>
  </div>
);

export default FileDownloadLink;
