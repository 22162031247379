import React from 'react';
import Button from 'sow/components/atoms/Button';

const PageButton = ({ pageNumber, selectedPage, selectPage }) => (
  <Button
    className={
      selectedPage === pageNumber ? 'selected-page-button' : 'page-number-button'
    }
    disabled={selectedPage === pageNumber}
    onClick={() => selectPage(pageNumber)}
  >
    {pageNumber}
  </Button>
);

export default PageButton;
