import React from 'react';

import * as sowTypes from 'sow/types';
import Button from 'sow/components/atoms/Button';
import Form from 'sow/components/atoms/Form';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';
import AcaSettingsForm from 'sow/containers/AcaSettingsForm';
import AcaForm from 'sow/components/organisms/AcaForm';
import Register from '../old/auth/register/Register';

const AcaSettingsRegisterPage = ({ aca, acaId }) => (
  <Widget>
    <WidgetBody>
      <Register></Register>
    </WidgetBody>
  </Widget>
);

AcaSettingsRegisterPage.propTypes = {
  aca: sowTypes.acaType.isRequired,
  acaId: sowTypes.acaIdType.isRequired,
};

export default AcaSettingsRegisterPage;
