import React from 'react';

import CenteredLayout from 'sow/components/templates/CenteredLayout';
import Heading from 'sow/components/atoms/Heading';
import Spinner from 'sow/components/atoms/Spinner';

const ValidatingTokenPage = () => (
  <CenteredLayout noHeader={true}>
    <Heading level={3}>Please wait while you are logged in...</Heading>
    <Spinner />
  </CenteredLayout>
);

export default ValidatingTokenPage;
