import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { AutoSizer, Column, Table, WindowScroller } from 'react-virtualized';

import 'react-virtualized/styles.css'; // only needs to be imported once

import { orgType } from 'sow/types';
import { orgRoute } from 'sow/routes';

import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';
import Strong from 'sow/components/atoms/Strong';
import Mark from 'sow/components/molecules/Mark';
import OrgContactInfoPopover from 'sow/components/organisms/OrgContactInfoPopover';

const getOrgAcaSettings = org => {
  const acaId = R.path(['aca', 'id'], org);
  return R.path(['settings', 'aca_byid', `${acaId}`], org);
};

// eslint-disable-next-line react/prop-types
const cellRenderOrgName = ({ rowData }) => {
  if (!rowData) return '';
  const org = rowData;
  return (
    <Link className="list-group-item table-hover" to={orgRoute(org.id)}>
      {org.name}{' '}
      {!org.active && <span className="label label-danger pull-right">deactivated</span>}
    </Link>
  );
};

// eslint-disable-next-line react/prop-types
const cellRenderAcaLabels = ({ rowData }) => {
  const org = rowData;
  const acaSettings = getOrgAcaSettings(org);
  if (!acaSettings) return '';

  return (
    <Block>
      <Strong>{acaSettings.client_id_label} </Strong>
      <br />
      <Mark>{acaSettings.client_id_value}</Mark>
    </Block>
  );
};

// eslint-disable-next-line react/prop-types
const cellRenderContactInfo = ({ rowData }) => {
  const org = rowData;
  return <OrgContactInfoPopover contact={org} />;
};

const cellWidth = (tableWidth, cellName) => {
  const contactInfoWidth = 100;
  const remainingTableWidth = tableWidth - contactInfoWidth;
  const orgNameWidth = remainingTableWidth * 0.7;
  const acaLabelWidth = remainingTableWidth * 0.3;

  if (cellName === 'OrgName') {
    return orgNameWidth;
  } else if (cellName === 'AcaLabels') {
    return acaLabelWidth;
  } else if (cellName === 'ContactInfo') {
    return contactInfoWidth;
  }

  return 0;
};

class OrgList extends React.Component {
  render() {
    const list = this.props.orgList;

    return (
      <WindowScroller>
        {({ onChildScroll, isScrolling, height, registerChild, scrollTop }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              // This ref causes an error (cannot be given to function components did you mean React.forwardRef()?)
              // Seems to work without the prop passed down...
              // <Block ref={registerChild}>
              <Block>
                <Table
                  autoHeight
                  width={width}
                  height={height}
                  headerHeight={20}
                  rowHeight={60}
                  rowCount={list.length}
                  rowGetter={({ index }) => list[index]}
                  disableHeader={true}
                  onScroll={onChildScroll}
                  isScrolling={isScrolling}
                  scrollTop={scrollTop}
                  gridStyle={{ borderTop: '1px solid #ddd' }}
                  rowStyle={{ border: '1px solid #ddd', borderTop: 0 }}
                >
                  <Column
                    label="Organization Name"
                    dataKey="name"
                    cellRenderer={cellRenderOrgName}
                    width={cellWidth(width, 'OrgName')}
                  />
                  <Column
                    label="Client ID"
                    dataKey="name"
                    cellRenderer={cellRenderAcaLabels}
                    className="text-center"
                    style={{ verticalAlign: 'middle' }}
                    width={cellWidth(width, 'AcaLabels')}
                  />
                  <Column
                    label="Contact Info"
                    dataKey="name"
                    cellRenderer={cellRenderContactInfo}
                    className="col-xs-1 table-hover"
                    style={{ verticalAlign: 'middle', textAlign: 'center' }}
                    width={cellWidth(width, 'ContactInfo')}
                  />
                </Table>
              </Block>
            )}
          </AutoSizer>
        )}
      </WindowScroller>
    );
  }
}

OrgList.propTypes = {
  orgList: PropTypes.arrayOf(orgType),
};

export default OrgList;
