import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Block from 'sow/components/atoms/Block';

const defaultStyle = {
  padding: '20px',
};

const WidgetBody = ({ children, className, style, ...props }) =>
  <Block className={classnames('widget-body', className)} style={style} {...props}>
    {children}
  </Block>;

WidgetBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};

WidgetBody.defaultProps = {
  style: defaultStyle,
};

export default WidgetBody;
