import { createActions } from 'redux-actions';

const actionCreators = createActions({
  GIS: {
    LOAD_COUNTRY_LIST: () => undefined,
    LOAD_COUNTRY_LIST_DONE: items => ({ items }),
    LOAD_COUNTRY_LIST_FAIL: () => undefined,
  },
});

export default actionCreators.gis;
