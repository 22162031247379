import React from 'react';

import { certificationSpecialistAllClientsRoute } from 'sow/routes';

import AcaPageTemplate from 'sow/containers/AcaPageTemplate';
import Nav from 'sow/components/atoms/Nav';
import NavLink from 'sow/components/molecules/NavLink';
import Spinner from 'sow/components/atoms/Spinner';

const SpecialistDashboardTemplate = ({ children, acaId, fetchingDashboardCounts }) => (
  <AcaPageTemplate title="Certification Specialist Dashboard">
    <Nav tabs>
      <NavLink to={certificationSpecialistAllClientsRoute(acaId, 'all')}>
        All Clients
      </NavLink>
      <NavLink to={certificationSpecialistAllClientsRoute(acaId, 'initial')}>
        Initial Reviews
      </NavLink>
      <NavLink to={certificationSpecialistAllClientsRoute(acaId, 'final')}>
        Final Reviews
      </NavLink>
    </Nav>
    {fetchingDashboardCounts ? <Spinner /> : children}
  </AcaPageTemplate>
);

export default SpecialistDashboardTemplate;
