import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { withRouter } from 'react-router';

import AcaOrgSearchTable from 'sow/components/organisms/AcaOrgSearchTable';

const mapStateToProps = state => ({
  isSearching: state.organizations.orgSearch.isSearching,
  searchInitiated: state.organizations.orgSearch.searchInitiated,
  searchResults: state.organizations.orgSearch.searchResults,
});

class AcaOrgSearchTableContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <AcaOrgSearchTable
        isSearching={this.props.isSearching}
        searchResults={this.props.searchResults}
        searchInitiated={this.props.searchInitiated}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(AcaOrgSearchTableContainer);
