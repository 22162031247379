export default function item(state = {}, { type, payload }) {
  switch (type) {
    case 'ITEM/FETCH_BY_ID':
      return { ...state };

    case 'ITEM/FETCH_BY_ID_DONE':
    case 'ITEM/ADD_ITEM_DONE':
    case 'ITEM/UPDATE_ITEM_DONE':
      return { ...state, id: payload.id, name: payload.name };
    default:
      return state;
  }
}
