import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as fromCurrentUser from 'sow/selectors/currentUser';
import { resourceUpdate } from 'sow/store/helpers';
import { fromPlanApp } from 'sow/store/selectors';
import Button from 'sow/components/atoms/Button';
import Glyphicon from 'sow/components/molecules/Glyphicon';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  locationId: fromPlanApp.locationId(state, props),
  locationDetails: fromPlanApp.locationDetails(state, props),
  showAcaUI: fromCurrentUser.showAcaUI(state, props),
});

const mapResourceToProps = () => {
  const updateChangeResource = (orgId, planAppId, changeRequestId) =>
    resourceUpdate(
      `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`,
      'changeRequestOverviewResp',
    );

  const mapResourceStateToProps = (state, props) => {
    const { orgId, planAppId, changeRequestId } = props;
    const { selectors } = updateChangeResource(orgId, planAppId, changeRequestId);
    return {
      disabled: selectors.pending(state, props),
    };
  };

  const mapResourceDispatchToProps = (dispatch, ownProps) => {
    const { orgId, planAppId, changeRequestId, locationId, locationDetails } = ownProps;
    const { action } = updateChangeResource(orgId, planAppId, changeRequestId);

    const change = {
      context: 'land',
      type: 'self',
      action: 'updated',
      landId: locationId,
      old: locationDetails,
      new: locationDetails,
    };

    return {
      onClick: () => dispatch(action(null, { changes: [change] })),
    };
  };

  return connect(
    mapResourceStateToProps,
    mapResourceDispatchToProps,
  );
};

const LocationEditCreateChangeButtonContainer = ({ onClick, disabled, showAcaUI }) => (
  <Button
    sm
    warning={showAcaUI}
    primary={!showAcaUI}
    onClick={onClick}
    disabled={disabled}
  >
    {showAcaUI ? (
      <Fragment>
        <Glyphicon glyph="flag" /> Request Changes
      </Fragment>
    ) : (
      <Fragment>
        <Glyphicon glyph="pencil" /> Make Changes
      </Fragment>
    )}
  </Button>
);

LocationEditCreateChangeButtonContainer.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  showAcaUI: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
  mapResourceToProps(),
)(LocationEditCreateChangeButtonContainer);
