const orgOptions = (state = {}, { type, payload }) => {
  switch (type) {
    case 'ORG/ORG_SELECT_OPTIONS':
      return {
        ...state,
      };
    case 'ORG/ORG_SELECT_OPTIONS_DONE':
      return {
        ...state,
        selectOptions: payload,
      };
    default:
      return state;
  }
};

export default orgOptions;
