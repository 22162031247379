import React, { Fragment } from 'react';
import * as R from 'ramda';

import ConfirmModal from 'sow/components/molecules/ConfirmModal';
import FormGroup from 'sow/components/organisms/FormGroup';
import InputFormGroup from 'sow/components/molecules/InputFormGroup';
import Block from 'sow/components/atoms/Block';

const ContactAddUserModal = ({
  show,
  handleInputChange,
  onConfirm,
  onCancel,
  formValues,
  errors,
  isNew,
  onExited,
}) => (
  <ConfirmModal
    show={show}
    confirmButtonText={isNew ? 'Invite' : 'Add'}
    onConfirm={onConfirm}
    onCancel={onCancel}
    onExited={onExited}
    title="Add User"
    message={
      isNew ? (
        <Fragment>
          <FormGroup label="Email">
            <Block>{formValues.userEmail}</Block>
          </FormGroup>
          <InputFormGroup
            id="userName"
            fieldName="userName"
            label="Name"
            className={`modal-edit-field ${
              R.has('userName')(errors) ? 'modal-form-error' : ''
            }`}
            inputClassName="input-field"
            onChange={handleInputChange}
            value={formValues.userName}
            fieldErrors={errors}
            hideErrorLabel
            required
          />
          <InputFormGroup
            id="userPhone"
            fieldName="userPhone"
            label="Phone Number"
            className={`modal-edit-field ${
              R.has('userPhone')(errors) ? 'modal-form-error' : ''
            }`}
            inputClassName="input-field"
            onChange={handleInputChange}
            value={formValues.userPhone}
            fieldErrors={errors}
            hideErrorLabel
            required
          />
          <InputFormGroup
            id="userTitle"
            fieldName="userTitle"
            label="Title"
            className="modal-edit-field"
            inputClassName="input-field"
            onChange={handleInputChange}
            value={formValues.userTitle}
          />
        </Fragment>
      ) : (
        <InputFormGroup
          id="userEmail"
          fieldName="userEmail"
          label="Email"
          className={`modal-edit-field ${
            R.has('userEmail')(errors) ? 'modal-form-error' : ''
          }`}
          inputClassName="input-field"
          onChange={handleInputChange}
          value={formValues.userEmail}
          fieldErrors={errors}
          required
        />
      )
    }
  />
);

export default ContactAddUserModal;
