import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';

const StyledSpan = styled.span``;

/**
 * Base component for inline elements
 * @param {*} param0
 */
const Atom = ({
  children,
  className,
  textMuted,
  textSuccess,
  textInfo,
  textWarning,
  textDanger,
  textNowrap,
  hiddenXs,
  hiddenSm,
  hiddenMd,
  hiddenLg,
  visibleXs,
  visibleSm,
  visibleMd,
  visibleLg,
  ...props
}) => {
  const classes = classnames(className, {
    'text-muted': textMuted,
    'text-success': textSuccess,
    'text-info': textInfo,
    'text-warning': textWarning,
    'text-danger': textDanger,
    'text-nowrap': textNowrap,
    'hidden-xs': hiddenXs,
    'hidden-sm': hiddenSm,
    'hidden-md': hiddenMd,
    'hidden-lg': hiddenLg,
    'visible-xs': visibleXs,
    'visible-sm': visibleSm,
    'visible-md': visibleMd,
    'visible-lg': visibleLg,
  });

  return (
    <StyledSpan {...props} className={classes}>
      {children}
    </StyledSpan>
  );
};

Atom.propTypes = {
  children: PropTypes.node,

  /** Additional css classes to be passed through */
  className: PropTypes.string,

  /** Colors text in 'muted' color */
  textMuted: PropTypes.bool,

  /** Colors text in 'success' color */
  textSuccess: PropTypes.bool,

  /** Colors text in 'fino' color */
  textInfo: PropTypes.bool,

  /** Colors text in 'warning' color */
  textWarning: PropTypes.bool,

  /** Colors text in 'danger' color */
  textDanger: PropTypes.bool,

  /** Disable text-wrapping */
  textNowrap: PropTypes.bool,

  hiddenXs: PropTypes.bool,
  hiddenSm: PropTypes.bool,
  hiddenMd: PropTypes.bool,
  hiddenLg: PropTypes.bool,
  visibleXs: PropTypes.bool,
  visibleSm: PropTypes.bool,
  visibleMd: PropTypes.bool,
  visibleLg: PropTypes.bool,
};

export default Atom;
