import React from 'react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import moment from 'moment';

import Atom from 'sow/components/atoms/Atom';
import { planAppStates, planAppFlags } from 'sow/constants/planApp';

const CertificationCountdown = ({ ospApp }) => {
  const anniversary_date = ospApp.organization_anniversary_date;
  let countdownText = null;

  if (anniversary_date == null) {
    if (ospApp.app_state === planAppStates.INITIAL_APPLICATION) {
      const momentCreated = moment.utc(ospApp.application_created_date);
      const momentNow = moment().utc();
      const daysAgo = momentNow.diff(momentCreated, 'days');
      if (daysAgo >= 0) {
        countdownText = `${pluralize('day', Math.abs(daysAgo), true)} old`;
      }
    }
  } else {
    const daysLeft = ospApp.application_stats.countdown;
    if (daysLeft <= 90) {
      const overdueText = daysLeft < 0 ? 'Overdue ' : '';
      countdownText = `${overdueText}${pluralize('day', Math.abs(daysLeft), true)}`;
    }
  }

  return countdownText ? <Atom>{countdownText}</Atom> : <Atom>&nbsp;</Atom>;
};

CertificationCountdown.propTypes = {
  daysLeft: PropTypes.number.isRequired,
};

CertificationCountdown.defaultProps = {
  daysLeft: 90,
};

export default CertificationCountdown;
