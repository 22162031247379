import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as currentAca from 'sow/selectors/currentAca';
import * as currentOspDef from 'sow/selectors/currentOspDef';
import * as currentUser from 'sow/selectors/currentUser';
import * as ospDefAction from 'sow/actions/pure/ospDefinition';
import * as select from './selectors';
import OspDefEditor from './OspDefEditor';

const mapStateToProps = (state, props) => ({
  originalOspDef: {
    ospDef: currentOspDef.ospDef(state, props),
    ospSections: currentOspDef.allOspSections(state, props),
    worksheets: currentOspDef.worksheets(state, props),
    worksheetQuestions: currentOspDef.worksheetQuestions(state, props),
    ospQualificationQuestions: currentOspDef.qualificationQuestions(state, props),
  },
  aca: currentAca.aca(state, props),
  acaId: currentAca.acaId(state, props),
  editorIsDirty: select.editorIsDirty(state, props),
  ospDef: select.ospDef(state, props),
  ospQualificationQuestions: select.ospQualificationQuestions(state, props),
  ospSections: select.ospSections(state, props),
  worksheets: select.worksheets(state, props),
  worksheetQuestions: select.worksheetQuestions(state, props),
  canEditOspDef: currentUser.canEditOspDef(state, props),
});

const mapDispatchToProps = dispatch => ({
  onSave: (acaId, { ospDef }, onSuccess) => {
    dispatch(ospDefAction.updateOne(acaId, ospDef, onSuccess));
  },
});

const OspDefEditorContainer = props => {
  return <OspDefEditor {...props} />;
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(OspDefEditorContainer);
