import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as itemVersionSectionActions from 'sow/actions/pure/itemVersionSection';

import ItemVersionSectionList from 'sow/components/organisms/ItemVersionSectionList';

const mapStateToProps = (state, props) => {
  return { sectionList: props.itemVersionSectionList };
};

const mapDispatchToProps = {
  updateSortOrder: itemVersionSectionActions.updateSortOrder,
  deleteItemVersionSection: itemVersionSectionActions.deleteItemVersionSection,
};

class ItemVersionSectionListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { anchorEl: null };
  }

  reorderList(dragIndex, hoverIndex) {
    const { sectionList, updateListOrder } = this.props;
    let reorderedList = [];
    if (dragIndex < hoverIndex) {
      const listStart = sectionList.slice(0, dragIndex);
      const listSecond = sectionList.slice(dragIndex + 1, hoverIndex + 1);
      const listThird = [sectionList[dragIndex]];
      const listEnd = sectionList.slice(hoverIndex + 1);
      reorderedList = listStart.concat(listSecond, listThird, listEnd);
    } else if (dragIndex > hoverIndex) {
      const listStart = sectionList.slice(0, hoverIndex);
      const listSecond = [sectionList[dragIndex]];
      const listThird = sectionList.slice(hoverIndex, dragIndex);
      const listEnd = sectionList.slice(dragIndex + 1);
      reorderedList = listStart.concat(listSecond, listThird, listEnd);
    }
    updateListOrder(reorderedList);
  }

  updateOrder() {
    const { updateSortOrder, sectionList } = this.props;
    const newOrder = sectionList.map((item, index) => {
      item.sort_order = index + 1;
      return item;
    });
    updateSortOrder(newOrder);
  }

  showPopover({ currentTarget }, sectionId) {
    this.setState({ anchorEl: currentTarget, sectionId });
  }

  hidePopover() {
    this.setState({ anchorEl: null, sectionId: null });
  }

  deleteSection() {
    const { sectionId } = this.state;
    this.props.deleteItemVersionSection(sectionId, this.refreshList.bind(this));
  }

  fetchSectionToEdit() {
    const { sectionId } = this.state;
    this.hidePopover();
    this.props.editSection(sectionId);
  }

  fetchSectionToClone() {
    const { sectionId } = this.state;
    this.hidePopover();
    this.props.cloneSection(sectionId);
  }

  refreshList() {
    const { loadItemVersionSections } = this.props;
    loadItemVersionSections();
    this.hidePopover();
  }

  render() {
    return (
      <ItemVersionSectionList
        sectionList={this.props.sectionList}
        anchorEl={this.state.anchorEl}
        hidePopover={this.hidePopover.bind(this)}
        fetchSectionToEdit={this.fetchSectionToEdit.bind(this)}
        fetchSectionToClone={this.fetchSectionToClone.bind(this)}
        deleteSection={this.deleteSection.bind(this)}
        listItemParams={{
          reorderList: this.reorderList.bind(this),
          updateOrder: this.updateOrder.bind(this),
          showPopover: this.showPopover.bind(this),
          onSectionSelect: this.props.onSectionSelect,
          selectedSectionId: this.props.selectedSectionId,
        }}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ItemVersionSectionListContainer);
