import {
  FETCH_ACA_ACTIVITY_LOG,
  FETCH_ACA_ACTIVITY_LOG_FAILED,
  FETCH_ACA_ACTIVITY_LOG_SUCCESS,
  RESET_ACA_ACTIVITY_LOG,
  FETCH_ACA_ACTIVITY_LOG_OPTIONS,
  FETCH_ACA_ACTIVITY_LOG_OPTIONS_SUCCESS,
  FETCH_ACA_ACTIVITY_LOG_OPTIONS_FAILED,
  FETCH_LOG_OSP_DEFINITION,
  FETCH_LOG_OSP_DEFINITION_SUCCESS,
} from 'sow/actions/aca';

const initialState = {
  items: [],
  selectOptions: {},
  isFetchingOptions: false,
  isFetching: false,
  searchInitiated: false,
  ospWorksheets: {},
};

export default function acaAdminAvailableUsersList(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACA_ACTIVITY_LOG:
      return { ...state, isFetching: true, searchInitiated: true, entriesData: {} };

    case FETCH_ACA_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: [...action.data],
        entriesData: action.entriesData,
      };

    case FETCH_ACA_ACTIVITY_LOG_FAILED:
      return { ...state, isFetching: false };

    case RESET_ACA_ACTIVITY_LOG:
      return {
        ...state,
        items: [],
        searchInitiated: false,
        entriesData: {},
        ospWorksheets: {},
        ospQualifications: {},
      };

    case FETCH_ACA_ACTIVITY_LOG_OPTIONS:
      return { ...state, isFetchingOptions: true };

    case FETCH_ACA_ACTIVITY_LOG_OPTIONS_SUCCESS:
      return { ...state, isFetchingOptions: false, selectOptions: action.data };

    case FETCH_ACA_ACTIVITY_LOG_OPTIONS_FAILED:
      return { ...state, isFetchingOptions: false };

    case FETCH_LOG_OSP_DEFINITION:
      return { ...state };

    case FETCH_LOG_OSP_DEFINITION_SUCCESS:
      return {
        ...state,
        ospWorksheets: { ...state.ospWorksheets, ...action.data },
        ospQualifications: { ...state.ospQualifications, ...action.qualificationList },
      };

    default:
      return state;
  }
}
