import React from 'react';
import styled from 'styled-components';

import Block from 'sow/components/atoms/Block';

const AnnouncementIcon = styled(Block)`
  margin-right: 10px;
  font-size: 12pt;
  vertical-align: top;
  display: inline;
  color: ${({ primary_color }) => (primary_color ? primary_color : '#da8407')};
`;

export default AnnouncementIcon;
