import {
  LOAD_ALL_CERTIFICATIONS,
  LOAD_ALL_CERTIFICATIONS_DONE,
  LOAD_CERTIFICATION_TYPE,
  LOAD_CERTIFICATION_TYPE_DONE,
  UPDATE_CERTIFICATION_TYPE_DONE,
  CLEAR_ONE,
} from 'sow/actions/pure/certificationType';

const initialState = { loadingList: true };

export default function certificationType(state = initialState, { type, payload }) {
  switch (type) {
    case LOAD_ALL_CERTIFICATIONS:
      return { state, loadingList: true };
    case LOAD_ALL_CERTIFICATIONS_DONE:
      return { ...state, list: payload, loadingList: false };
    case LOAD_CERTIFICATION_TYPE:
      return { ...state };
    case LOAD_CERTIFICATION_TYPE_DONE:
      return { ...state, one: payload };
    case UPDATE_CERTIFICATION_TYPE_DONE:
      return { ...state, one: payload };
    case CLEAR_ONE:
      return { ...state, one: {} };
    default:
      return state;
  }
}
