import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as itemTypeActions from 'sow/actions/pure/itemType';
import { apiGet, apiPost, apiPut } from 'sow/sagas/api';

export const loadItemTypes = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/item_type', payload);

    if (response && meta.onSuccess) {
      meta.onSuccess(response.data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchById = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/item_type/${payload}`);

    if (response && meta.onSuccess) {
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addItemType = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/item_type', payload);
    if (response && meta.onSuccess) {
      yield put(actions.shell.toast('success', 'Item type created.'));
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateItemType = function*({ id, payload, meta }) {
  try {
    const { response } = yield call(apiPut, `/admin/item_type/${id}`, payload);
    if (response) {
      yield put(actions.shell.toast('success', 'Item type updated.'));
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateSortOrder = function*({ payload }) {
  try {
    yield call(apiPost, '/admin/item_type/update_order', { updated_list: payload });
  } catch (error) {
    console.error(error);
  }
};

export const deleteItemType = function*({ id, meta }) {
  try {
    const { response } = yield call(apiPut, `/admin/item_type/delete/${id}`);
    if (response) {
      yield put(actions.shell.toast('success', 'Item type deleted.'));
      if (meta.onSuccess) meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(itemTypeActions.LOAD_ITEM_TYPES, loadItemTypes);
  yield takeEvery(itemTypeActions.FETCH_BY_ID, fetchById);
  yield takeEvery(itemTypeActions.ADD_ITEM_TYPE, addItemType);
  yield takeEvery(itemTypeActions.UPDATE_ITEM_TYPE, updateItemType);
  yield takeEvery(itemTypeActions.UPDATE_SORT_ORDER, updateSortOrder);
  yield takeEvery(itemTypeActions.DELETE_ITEM_TYPE, deleteItemType);
}
