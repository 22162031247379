import React from 'react';
import PropTypes from 'prop-types';

import { logoutRoute } from 'sow/routes';

import ErrorPage from 'sow/components/templates/ErrorPageTemplate';
import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';

const SuspendedPage = ({ message }) => (
  <ErrorPage message={message} danger>
    <Block textCenter textMuted>
      You can also go back to the <Link to={logoutRoute()}>Login Page</Link>
    </Block>
  </ErrorPage>
);

SuspendedPage.propTypes = {
  message: PropTypes.node,
};

export default SuspendedPage;
