import { call, put, takeEvery } from 'redux-saga/effects';
import * as R from 'ramda';

import * as orgUserActions from 'sow/actions/pure/orgUser';
import { apiGet } from 'sow/sagas/api';

export const loadUsers = function*({ payload }) {
  const { orgId } = payload;

  try {
    const { response, error } = yield call(apiGet, `/org/${orgId}/users`);

    if (response) {
      const { data } = response;
      yield put(orgUserActions.addUsersDone({ orgId, result: data }));
    }

    if (error) {
      yield put(orgUserActions.addUsersFail(error));
    }
  } catch (error) {
    yield put(orgUserActions.addUsersFail(error));
  }
};

export default function*() {
  yield takeEvery(orgUserActions.LOAD_USERS_FOR_ORG_REQUEST, loadUsers);
}
