import React from 'react';

import { API_URL } from 'sow/constants/api';

const fileUrl = fileId => `${API_URL}/v1/file/${fileId}`;

const DocListItem = ({ doc, canEdit = false, onDelete }) =>
  <tr>
    <td>
      {doc.name}
    </td>
    <td className="text-right">
      <a href={fileUrl(doc.file_id)} target="_blank" className="btn btn-primary btn-xs">
        <span className="glyphicon glyphicon-download" /> Download
      </a>

      {canEdit &&
        onDelete &&
        <button
          type="button"
          className="btn btn-xs btn-danger"
          onClick={onDelete(doc.id)}
        >
          <span className="glyphicon glyphicon-remove" />
        </button>}
    </td>
  </tr>;

const DocList = ({ docs, canEdit, onDelete }) =>
  <table className="table">
    <thead>
      <tr>
        <td>Name</td>
        <td className="col-md-3">
          {/* Actions */}
        </td>
      </tr>
    </thead>
    <tbody>
      {docs.map(doc =>
        <DocListItem key={doc.id} doc={doc} canEdit={canEdit} onDelete={onDelete} />,
      )}
    </tbody>
  </table>;

export default DocList;
