import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { withRouter } from 'react-router';

import actions from 'sow/actions/pure';
import * as currentAca from 'sow/selectors/currentAca';
import ProductSearchFilters from 'sow/components/organisms/ProductSearchFilters';

const mapStateToProps = (state, props) => ({
  orgList: state.organizations.orgOptions.selectOptions,
  isSearching: state.products.isSearching,
  isFetchingOptions: state.products.isFetchingOptions,
  filterOptions: state.products.filterOptions,
  acaId: currentAca.acaId(state, props),
  searchResults: state.products.searchResults,
});

const mapDispatchToProps = {
  loadOrgs: actions.org.orgSelectOptions,
  searchProducts: actions.products.productSearch,
  fetchFilterOptions: actions.products.fetchFilterOptions,
  clearResults: actions.products.clearSearch,
};

const stateValues = {
  p: 'productName',
  s: 'status',
  t: 'type',
  c: 'certStandard',
  o: 'orgSelect',
  n: 'nopId',
  b: 'brandName',
  os: 'org_status',
  as: 'active_statuses',
};

const categoryValues = {
  '1': { value: 1, label: 'Crop' },
  '2': { value: 2, label: 'Handler' },
  '3': { value: 3, label: 'Livestock' },
};

class ProductSearchFiltersContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { formValues: { active_statuses: true, org_status: true } };
  }

  componentDidMount() {
    const urlParams = window.location.href.split('?');
    if (urlParams[1]) {
      this.urlSearch(urlParams[1]);
    }
    this.props.fetchFilterOptions();
    this.props.loadOrgs(this.props.acaId);
  }

  componentDidUpdate() {
    if (this.state.reload) {
      this.handleSearch();
      this.setState({ reload: false });
    }
  }

  componentWillUnmount() {
    this.props.clearResults();
  }

  urlSearch(urlString) {
    const urlFormValues = {};
    const urlParams = urlString.split('&');
    urlParams.forEach(param => {
      const keyValue = param.split('=');
      switch (keyValue[0]) {
        case 't':
          const categories = keyValue[1].split('');
          const categoriesSelected = [];
          categories.map(category => categoriesSelected.push(categoryValues[category]));
          urlFormValues['type'] = categoriesSelected;
          break;
        case 'as':
          urlFormValues[stateValues[keyValue[0]]] = decodeURI(keyValue[1]) === 'true';
          break;
        default:
          urlFormValues[stateValues[keyValue[0]]] = decodeURI(keyValue[1]);
          break;
      }
    });
    this.setState({
      formValues: { ...urlFormValues },
      reload: true,
    });
  }

  convertSearchValues() {
    const { formValues } = this.state;
    const searchParams = {};
    const urlComponents = [];

    for (const property in formValues) {
      if (!R.isNil(formValues[property]) && formValues[property] !== '') {
        switch (property) {
          case 'productName':
            searchParams['product_name'] = formValues[property];
            urlComponents.push(`p=${encodeURIComponent(formValues[property])}`);
            break;
          case 'status':
            searchParams['status'] = formValues[property];
            urlComponents.push(`s=${encodeURIComponent(formValues[property])}`);
            break;
          case 'type':
            if (formValues[property].length > 0) {
              const typeArray = formValues[property].map(type => {
                return type.value;
              });
              searchParams['type'] = typeArray.join(',');
              urlComponents.push(`t=${encodeURIComponent(typeArray.join(''))}`);
            }
            break;
          case 'certStandard':
            searchParams['cert_standard'] = formValues[property];
            urlComponents.push(`c=${encodeURIComponent(formValues[property])}`);
            break;
          case 'orgSelect':
            searchParams['org_id'] = formValues[property];
            urlComponents.push(`o=${encodeURIComponent(formValues[property])}`);
            break;
          case 'nopId':
            searchParams['nop_id'] = formValues[property];
            urlComponents.push(`n=${encodeURIComponent(formValues[property])}`);
            break;
          case 'brandName':
            searchParams['brand_name'] = formValues[property];
            urlComponents.push(`b=${encodeURIComponent(formValues[property])}`);
            break;
          case 'org_status':
            searchParams['org_status'] = formValues[property];
            urlComponents.push(`os=${encodeURIComponent(formValues[property])}`);
            break;
          case 'active_statuses':
            searchParams['active_statuses'] = formValues[property];
            urlComponents.push(`as=${encodeURIComponent(formValues[property])}`);
            break;
          default:
            break;
        }
      }
    }

    this.setState({ searchParams });

    return { searchParams, urlComponents };
  }

  handleSelectChange(target, field) {
    const { formValues } = this.state;
    this.setState({
      formValues: { ...formValues, [field]: target ? target.value : null },
    });
  }

  handleInputChange({ target }) {
    const { formValues } = this.state;
    this.setState({ formValues: { ...formValues, [target.id]: target.value } });
  }

  handleMultiSelectChange(field, target) {
    const { formValues } = this.state;
    this.setState({ formValues: { ...formValues, [field]: target } });
  }

  handleOptionChange(value, field) {
    const { formValues } = this.state;
    this.setState({ formValues: { ...formValues, [field]: value } });
  }

  handleSearch(event) {
    if (event) {
      event.preventDefault();
    }

    const { searchParams, urlComponents } = this.convertSearchValues();

    const urlString = '?' + urlComponents.join('&');
    if (urlString.length > 1) {
      history.pushState(null, null, urlString);
    } else {
      history.pushState(null, null, `/aca/${this.props.acaId}/products`);
    }

    this.props.searchProducts(searchParams);
  }

  handleReset() {
    history.pushState(null, null, `/aca/${this.props.acaId}/products`);
    this.setState({ formValues: { active_statuses: true, org_status: true } });
    this.props.clearResults();
  }

  exportList() {
    const { searchParams } = this.state;
    let paramsString = '';
    const params = [];
    for (const property in searchParams) {
      if (!R.isNil(searchParams[property]) && searchParams[property] !== '') {
        params.push(`${property}=${searchParams[property]}`);
      }
    }
    if (params.length > 0) {
      paramsString = '?' + params.join('&');
    }
    return paramsString;
  }

  render() {
    return (
      <ProductSearchFilters
        orgId={this.props.orgId}
        formValues={this.state.formValues}
        orgList={this.props.orgList}
        dataToExport={this.props.searchResults.length > 0}
        isSearching={this.props.isSearching}
        exportList={this.exportList.bind(this)}
        handleSelectChange={this.handleSelectChange.bind(this)}
        handleInputChange={this.handleInputChange.bind(this)}
        handleMultiSelectChange={this.handleMultiSelectChange.bind(this)}
        handleOptionChange={this.handleOptionChange.bind(this)}
        handleSearch={this.handleSearch.bind(this)}
        handleReset={this.handleReset.bind(this)}
        isFetchingOptions={this.props.isFetchingOptions}
        filterOptions={this.props.filterOptions}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ProductSearchFiltersContainer);
