const mark = action => `ACA_ADMIN/${action}`;

export const ADD_STAFF = mark('ADD_STAFF');
export const ADD_STAFF_DONE = mark('ADD_STAFF_DONE');
export const ADD_STAFF_FAIL = mark('ADD_STAFF_FAIL');

export const addStaff = (acaId, user, onSuccess) => ({
  type: ADD_STAFF,
  payload: { acaId, user },
  meta: { onSuccess },
});

export const addStaffDone = user => ({
  type: ADD_STAFF_DONE,
  payload: { user },
});

export const addStaffFail = errors => ({
  type: ADD_STAFF_FAIL,
  payload: { errors },
});

export const SHOW_INVITE_FIELDS = mark('SHOW_INVITE_FIELDS');
export const HIDE_INVITE_FIELDS = mark('HIDE_INVITE_FIELDS');

export const showInviteFields = () => ({ type: SHOW_INVITE_FIELDS });
export const hideInviteFields = () => ({ type: HIDE_INVITE_FIELDS });
