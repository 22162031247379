import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import PlanAppExportContentLocation from 'sow/components/organisms/PlanAppExportContent/Location';

const mapStateToProps = (state, props) => ({
  locationDetails: fromPlanApp.locationDetails(state, props),
  locationChange: fromPlanApp.locationChange(state, props),
  isExcluded: fromPlanApp.locationIsExcluded(state, props),
  isDiscarded: fromPlanApp.isNewLocationDiscarded(state, props),
});

const PlanAppExportContentLocationContainer = ({ ...props }) => (
  <PlanAppExportContentLocation {...props} />
);

PlanAppExportContentLocationContainer.propTypes = {
  // Passed props
  planAppId: sowTypes.planAppIdType.isRequired,
  locationId: sowTypes.planAppLocationIdType.isRequired,
  exportConfig: sowTypes.planAppExportConfigType.isRequired,

  // Connected props
  locationDetails: sowTypes.planAppLocationType.isRequired,
  locationChange: sowTypes.planAppChangeType,
  isExcluded: PropTypes.bool.isRequired,
  isDiscarded: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppExportContentLocationContainer);
