import React, { Component, Fragment } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import styled from 'styled-components';

import {
  dashboardAddOrUpdateUserPriority,
  dashboardRemoveUserPriority,
} from 'sow/actions/orgWorkflow';

import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const mapDispatchToProps = {
  dashboardAddOrUpdateUserPriority,
  dashboardRemoveUserPriority,
};

const PopoverWrapper = styled(Block)`
  padding: 10px;
`;

const PopoverButton = styled(Button)`
  width: 100%;
  text-align: justify;
  border: none;
`;

class PriorityPopover extends Component {
  constructor(props) {
    super(props);

    this.state = { anchorEl: null };
  }

  showPopover = (event, ospApp) => {
    this.setState({
      anchorEl: event.currentTarget,
      specialistPriorityId: ospApp.priority_id,
      orgName: ospApp.organization_name,
      orgId: ospApp.organization_id,
      organizationUserId: ospApp.organization_user_id,
      appId: ospApp.app_id,
    });
  };

  hidePopover = () =>
    this.setState({
      anchorEl: null,
      specialistPriorityId: '',
      orgName: '',
      orgId: '',
      appId: '',
    });

  updatePriority = level => {
    const { dashboardAddOrUpdateUserPriority } = this.props;
    const { orgId, appId, organizationUserId, specialistPriorityId } = this.state;

    if (specialistPriorityId !== level) {
      dashboardAddOrUpdateUserPriority(orgId, appId, {
        priority_type_id: level,
        organization_user_id: organizationUserId,
      });
    }
    this.hidePopover();
  };

  removePriority = () => {
    const { dashboardRemoveUserPriority } = this.props;
    const { orgId, appId, organizationUserId, specialistPriorityId } = this.state;
    if (specialistPriorityId) {
      dashboardRemoveUserPriority(orgId, appId, {
        organization_user_id: organizationUserId,
      });
    }
    this.hidePopover();
  };

  render() {
    const { ospApp, priorityTypes } = this.props;
    const { anchorEl } = this.state;

    const open = Boolean(anchorEl);

    return (
      <Fragment>
        Edit{' '}
        <Block onClick={event => this.showPopover(event, ospApp)} inline>
          <FontAwesome className="fa-regular" icon="square-caret-down" />
        </Block>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={this.hidePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <PopoverWrapper>
            {priorityTypes.length > 0 &&
              priorityTypes.map(priorityType => (
                <Block key={priorityType.id}>
                  <PopoverButton onClick={() => this.updatePriority(priorityType.id)}>
                    Mark as {priorityType.name} Priority
                  </PopoverButton>
                </Block>
              ))}
            <Block>
              <PopoverButton onClick={() => this.removePriority()}>
                Remove Priority
              </PopoverButton>
            </Block>
          </PopoverWrapper>
        </Popover>
      </Fragment>
    );
  }
}

export default R.compose(withRouter, connect(null, mapDispatchToProps))(PriorityPopover);
