import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { darken } from 'polished';

import * as sowTypes from 'sow/types';
import Block from 'sow/components/atoms/Block';
import ChangeUnreadNotificationsIcon from 'sow/containers/planApp/ChangeUnreadNotificationsIcon';
import ChangeStateIcon from 'sow/components/organisms/ChangeStateIcon';

const BannerWrapper = styled(Block)`
  width: 100%;
  padding: 10px 10px 10px 10px;
  background-color: rgba(200, 200, 200, 0.9);
  font-size: 10pt;
  font-weight: bold;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
`;

const BannerInner = styled(Block)`
  position: relative;
`;

const ChangeStateIconInnerWrapper = styled(Block)`
  position: absolute;
  top: -10px;
  right: -10px;
`;

const ChangeBanner = ({ isNew, isUpdated, isDeleted, changeId, changeState }) => {
  let stateText;
  if (isNew) stateText = 'ADDED';
  else if (isUpdated) stateText = 'UPDATED';
  else if (isDeleted) stateText = 'DELETED';
  else return null;

  return (
    <BannerWrapper changeState={changeState}>
      <BannerInner>
        {/* Large layouts, put icons on next line */}
        <Block hiddenXs hiddenSm>
          {stateText}
          <Block>
            <ChangeUnreadNotificationsIcon changeId={changeId} />
          </Block>
        </Block>
        {/* Small layouts - has 100% width so render all on same line */}
        <Block visibleXs visibleSm>
          {stateText} <ChangeUnreadNotificationsIcon changeId={changeId} />
        </Block>

        <ChangeStateIconInnerWrapper>
          <ChangeStateIcon changeState={changeState} />
        </ChangeStateIconInnerWrapper>
      </BannerInner>
    </BannerWrapper>
  );
};

ChangeBanner.propTypes = {
  isNew: PropTypes.bool.isRequired,
  isUpdated: PropTypes.bool.isRequired,
  isDeleted: PropTypes.bool.isRequired,
  changeState: sowTypes.planAppChangeStateType.isRequired,
  changeId: sowTypes.planAppChangeIdType.isRequired,
};

export default ChangeBanner;
