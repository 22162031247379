import React from 'react';

import './CSSModules/OrgContactsEditPage.module.css';
import ContactInfoEditContainer from 'sow/containers/ContactInfoEdit';
import OrgPageTemplate from 'sow/containers/OrgPageTemplate';

const OrgContactsEditPage = () => (
  <OrgPageTemplate title="Contacts">
    <ContactInfoEditContainer />
  </OrgPageTemplate>
);

export default OrgContactsEditPage;
