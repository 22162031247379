import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { SectionHeading } from './style/atoms';
import Worksheet from 'sow/containers/planAppExport/Content/Worksheet';

const MainPlanSection = ({
  orgId,
  planAppId,
  qualifiedWorksheetIds,
  worksheetSections,
  ...props
}) => (
  <Fragment>
    <SectionHeading pageBreak="always">Main Plan</SectionHeading>

    {worksheetSections.map(section => {
      const worksheetList = R.filter(R.contains(R.__, qualifiedWorksheetIds))(
        section.worksheets,
      );

      return (
        <Fragment key={section.uuid}>
          {worksheetList.map(worksheetId => (
            <Worksheet
              key={worksheetId}
              orgId={orgId}
              planAppId={planAppId}
              worksheetId={worksheetId}
              {...props}
            />
          ))}
        </Fragment>
      );
    })}
  </Fragment>
);

MainPlanSection.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  planApp: sowTypes.planAppType.isRequired,
  planAppSummary: sowTypes.planAppSummaryType.isRequired,
  qualifiedWorksheetIds: PropTypes.arrayOf(sowTypes.planAppWorksheetIdType),
  worksheetSections: PropTypes.arrayOf(sowTypes.planAppWorksheetSectionType),
  userList: sowTypes.userLimitedListType.isRequired,
  exportConfig: sowTypes.planAppExportConfigType.isRequired,
};

export default MainPlanSection;
