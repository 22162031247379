import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { push as routerPush } from 'connected-react-router';

import { userIdType } from 'sow/types';
import { defaultOrg, validationSchema } from 'sow/utils/org';
import actions from 'sow/actions/pure';
import { resourceCreate } from 'sow/store/helpers';
import { orgRoute } from 'sow/routes';
import OrgSetupPage from 'sow/components/pages/OrgSetupPage';

const mapStateToProps = (state, props) => ({
  //userId: currentUser.id(state, props),
  userId: R.path(['match', 'params', 'userId'], props),
});

const mapDispatchToProps = dispatch => ({
  routerPush: (...args) => dispatch(routerPush(...args)),
  toast: (...args) => dispatch(actions.shell.toast(...args)),
  createOrg: organization => dispatch(resourceCreate('org').action({ organization })),
});

function OrgSetupPageContainer({ userId, toast, routerPush, createOrg }) {
  async function handleSubmit(values, formikBag) {
    const org = { ...values, userId };
    try {
      let newOrg = await createOrg(org);
      routerPush(orgRoute(newOrg.data.id));
    } catch (err) {
      const validationErrors = R.pathOr(null, ['error', 'errors'], err);
      toast(
        'danger',
        'There was a problem setting up your Organization.',
        validationErrors,
      );
    } finally {
      formikBag.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={defaultOrg()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <OrgSetupPage disabled={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
}

OrgSetupPageContainer.propTypes = {
  userId: userIdType.isRequired,
  toast: PropTypes.func.isRequired,
  routerPush: PropTypes.func.isRequired,
  createOrg: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrgSetupPageContainer);
