import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Paragraph from 'sow/components/atoms/Paragraph';
import Panel from 'sow/components/molecules/Panel';

const DescriptiveTextQuestionField = ({ question }) => (
  <Panel
    info
    heading={
      <Fragment>
        {question.answers.map((text, i) => <Paragraph key={i}>{text}</Paragraph>)}
      </Fragment>
    }
  />
);

DescriptiveTextQuestionField.propTypes = {
  question: PropTypes.object,
};

export default DescriptiveTextQuestionField;
