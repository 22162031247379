import React, { Fragment } from 'react';
import * as R from 'ramda';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { rgba } from 'polished';

import { planAppChangeType } from 'sow/types';
import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import Clearfix from 'sow/components/atoms/Clearfix';
import Strong from 'sow/components/atoms/Strong';
import Glyphicon from 'sow/components/molecules/Glyphicon';
import Timestamp from 'sow/components/molecules/Timestamp';
import BlockedText from 'sow/components/organisms/BlockedText';

const changeSectionTypeType = PropTypes.oneOf(['old', 'new']);

const sectionTheme = theme => ({
  old: {
    rejected: theme.colors.darkGrey,
    accepted: theme.colors.lightGrey,
    open: theme.colors.lightGrey,
    applied: theme.colors.lightGrey,
    not_applied: theme.colors.darkGrey,
    ignored: theme.colors.darkGrey,
  },
  new: {
    rejected: theme.cr.rejected,
    accepted: theme.cr.accepted,
    open: theme.cr.open,
    applied: theme.cr.accepted,
    not_applied: theme.cr.rejected,
    ignored: theme.colors.lightGrey,
  },
});

const ChangeLabelWrapper = styled(Block)`
  display: inline-block;
  padding: 5px;
  padding-left: 15px;
  padding-right: 20px;
  margin-top: 0;
  font-size: 9pt;
  font-weight: bold;
  color: white;
  background-color: ${({ type, change, theme }) => {
    const stateColor = change.ignored ? 'ignored' : change.state;
    return theme[type][stateColor];
  }}};
`;

const ChangeLabel = ({ type, change }) => {
  let text = 'Answer';
  if (type === 'old') {
    if (change.ignored || change.state === 'rejected' || change.state === 'not_applied')
      text = 'Current Answer';
    else text = 'Original Answer';
  } else if (type === 'new') {
    if (change.ignored) text = 'Ignored Change';
    else if (change.state === 'accepted') text = 'Accepted Change';
    else if (change.state === 'applied') text = 'Applied Change';
    else if (change.state === 'open') text = 'Change (Pending Approval)';
    else text = 'Discarded Change'; // 'rejected', 'not_applied'
  }

  return (
    <ChangeLabelWrapper type={type} change={change}>
      {text}
      {change.blocked && type === 'new' && (
        <Fragment>
          {' '}
          <BlockedText />
        </Fragment>
      )}
    </ChangeLabelWrapper>
  );
};

ChangeLabel.propTypes = {
  type: changeSectionTypeType.isRequired,
  change: planAppChangeType.isRequired,
};

const ChangeLastModifiedWrapper = styled(Block)`
  display: inline-block;
  margin-top: 0;
  padding: 0 15px 5px;
  font-size: 9pt;
  color: ${({ theme, type, change }) => {
    const stateColor = change.ignored ? 'ignored' : change.state;
    return theme[type][stateColor];
  }};
`;

const ChangeLastModified = ({ type, change }) => {
  if (type !== 'new') return null;

  const lastModifyName = R.path(['lastModifiedBy', 'name'], change);
  const lastModifiedAt = R.path(['lastModifiedAt'], change);

  return (
    <ChangeLastModifiedWrapper type={type} change={change} pullRight>
      {'Change last modified '}
      <Timestamp date={lastModifiedAt} />
      {' by '}
      <Strong>{lastModifyName}</Strong>
    </ChangeLastModifiedWrapper>
  );
};

ChangeLastModified.propTypes = {
  type: changeSectionTypeType.isRequired,
  change: planAppChangeType.isRequired,
};

const ToolbarWrapper = styled(Block)`
  padding-top: 5px;
  padding-right: 10px;
`;

const ChangeToolbar = ({ change, onAccept, onReject, disabled }) => (
  <ToolbarWrapper pullRight>
    <Button
      sm
      danger
      onClick={onReject}
      disabled={disabled || change.state === 'rejected'}
    >
      <Glyphicon glyph="trash" /> Discard
    </Button>
    <Button
      sm
      success
      onClick={onAccept}
      disabled={disabled || change.state === 'accepted'}
    >
      <Glyphicon glyph="ok" /> Accept
    </Button>
  </ToolbarWrapper>
);

ChangeToolbar.propTypes = {
  change: planAppChangeType.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const SectionWrapper = styled(Block)`
  border-top: 5px solid #bbb;
  border-left: 5px solid #bbb;
  background-color: ${props => {
    const { type, change, theme } = props;
    if (type === 'old') return null;
    const stateColor = change.ignored ? 'ignored' : change.state;
    const baseColor = theme[type][stateColor];
    if (!R.isNil(baseColor)) return rgba(baseColor, 0.1);
  }};
  border-color: ${({ type, change, theme }) => {
    const stateColor = change.ignored ? 'ignored' : change.state;
    return theme[type][stateColor];
  }};
`;

const ChildrenWrapper = styled(Block)`
  padding: 10px 10px 15px 10px;
`;

const ChangeSection = ({
  type,
  change,
  showToolbar,
  disableToolbar,
  onAccept,
  onReject,
  children,
}) => {
  return (
    <ThemeProvider theme={sectionTheme}>
      <SectionWrapper type={type} change={change} clearfix>
        <ChangeLabel type={type} change={change} />
        {showToolbar && (
          <ChangeToolbar
            change={change}
            onAccept={onAccept}
            onReject={onReject}
            disabled={disableToolbar}
          />
        )}
        <Clearfix />
        <ChildrenWrapper type={type} change={change}>
          {children}
        </ChildrenWrapper>
        <ChangeLastModified type={type} change={change} />
      </SectionWrapper>
    </ThemeProvider>
  );
};

ChangeSection.propTypes = {
  type: changeSectionTypeType.isRequired,
  children: PropTypes.node,
  change: planAppChangeType,
  // Only relevant for "new" sections, not used in "old"
  showToolbar: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
};

ChangeSection.defaultProps = {
  showToolbar: false,
  disableToolbar: true,
  onAccept: null,
  onReject: null,
};

export default ChangeSection;
