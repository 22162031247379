import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import UserManagementTemplateContainer from 'sow/containers/UserManagementTemplateContainer';
import UserManagementUserHistoryContainer from 'sow/containers/UserManagementUserHistoryContainer';

const UserManagementUserHistoryPage = () => (
  <AdminPageTemplate title="Organization User Lookup">
    {() => (
      <UserManagementTemplateContainer>
        <UserManagementUserHistoryContainer />
      </UserManagementTemplateContainer>
    )}
  </AdminPageTemplate>
);

export default UserManagementUserHistoryPage;
