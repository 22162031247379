import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { planAppStates } from 'sow/constants/planApp';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Heading from 'sow/components/atoms/Heading';
import OrgDashboardActionButtonsOrg from 'sow/containers/planApp/OrgDashboardActionButtonsOrg';

const Wrapper = styled.div`
  padding-top: 30px;
`;

const NextText = styled.div`
  font-size: 10pt;
  margin-top: 10px;
  margin-bottom: 20px;
  color: white;
`;

const PlanAppWhatsNext = ({ planApp, hasWorksheets }) => {
  let nextText;
  switch (planApp.state) {
    case planAppStates.INITIAL_APPLICATION:
      if (!hasWorksheets)
        nextText = (
          <p>
            Welcome! To get started, fill out the Activity Checklist to add the necessary
            worksheets to your plan.
          </p>
        );
      else if (planApp.percentageComplete < 100)
        nextText = (
          <p>
            Finish filling out your worksheets and locations. Once you're done, you'll be
            able to submit your plan for review.
          </p>
        );
      else
        nextText = (
          <p>
            Your plan is ready to submit. When you are ready for these changes to be
            reviewed by your certifier click the submit button below and your certifier
            will be notified. Please note that when you click submit this indicates you
            are applying as an applicant with Where Food Comes From Organic (WFCFO). As
            always we appreciate you choosing WFCFO!
          </p>
        );
      break;

    case planAppStates.REVISION:
      nextText = (
        <p>
          You may make changes to your plan. If you have products/inputs that need
          immediate review, please contact your Certification Specialist. Be sure to
          submit the plan for full review by your anniversary date.
        </p>
      );
      break;

    case planAppStates.INITIAL_APPLICATION_SUBMITTED:
      nextText = (
        <p>
          Your certifier has received your plan. You can still make corrections or other
          modifications and your certifier will be notified automatically.
        </p>
      );
      break;

    case planAppStates.REVISION_SUBMITTED:
      nextText = (
        <p>
          You have successfully submitted your OSP for review. The initial review for this
          cycle has not quite started yet. Reach out to your Certification Specialist if
          you have immediate needs.
        </p>
      );
      break;

    case planAppStates.INITIAL_REVIEW:
      nextText = (
        <p>
          Your certifier is reviewing your plan. You can still make corrections or other
          modifications and your certifier will be notified automatically.
        </p>
      );
      break;

    case planAppStates.IN_INITIAL_REVIEW:
      nextText = (
        <p>
          Your OSP is currently being reviewed by your Certification Specialist. You can
          still make corrections or other modifications. If you see items marked as “To
          Dos”, please provide more information. Notify your Certification Specialist of
          any significant changes that need immediate approval.
        </p>
      );
      break;

    case planAppStates.INSPECTION:
      nextText = (
        <p>
          Your file was released for inspection! Your assigned inspector will contact you
          to schedule the inspection. You can still make corrections or other
          modifications. Notify your Certification Specialist of any significant changes
          that need immediate approval.
        </p>
      );
      break;

    case planAppStates.IN_INSPECTION:
      nextText = (
        <p>
          Your inspection has been scheduled by your assigned inspector. Prepare for your
          inspection per the instructions provided by your Certification Specialist.
          Please make sure you have taken care of any “To Dos” visible in the plan from
          initial review.
        </p>
      );
      break;

    case planAppStates.FINAL_REVIEW:
      nextText = (
        <p>
          You have completed inspection and WFCFO has received the inspection documents.
          The final review for the OSP and inspection documents for this cycle has not yet
          begun. If there were any issues of concern noted by your inspector or if “to
          dos” are still open in your plan, please address these ASAP. You can still make
          corrections or other modifications to your plan.
        </p>
      );
      break;

    case planAppStates.IN_FINAL_REVIEW:
      nextText = (
        <p>
          Your certifier is reviewing your plan. WFCFO will notify you when final review
          is complete and final certification decisions have been determined.
        </p>
      );
      break;

    case planAppStates.COMPLETE:
      nextText = (
        <p>
          Your plan has been approved and you have completed the certification cycle. An
          updated certificate can be found in SOW General Attachments. When you are ready
          to make changes to your OSP for certification renewal click the Update Plan
          button below.
        </p>
      );
      break;

    case planAppStates.NONCOMPLIANCE:
      nextText = (
        <p>
          There is a noncompliance in your plan. Please contact your certifier to
          determine how you should proceed.
        </p>
      );
      break;

    case planAppStates.WITHDRAWN:
      nextText = (
        <p>
          Your plan has been withdrawn. Please contact your certifier if you want it
          re-opened.
        </p>
      );
      break;

    case planAppStates.SURRENDERED:
      nextText = (
        <p>
          Your plan and organic certification has been surrendered, it cannot be
          re-opened. A surrendered operation may no longer sell, label or represent
          themselves or products with <i>any</i> organic claim. Contact WFCFO if you wish
          to re-apply.
        </p>
      );
      break;

    case planAppStates.SUSPENDED:
      nextText = (
        <p>
          Your plan has been suspended, it cannot be re-opened. Please contact your
          certifier to determine how you should proceed.
        </p>
      );
      break;

    case planAppStates.REVOKED:
      nextText = (
        <p>
          Your plan has been revoked, it cannot be re-opened. Please contact your
          certifier to determine how you should proceed.
        </p>
      );
      break;

    default:
      nextText = null;
  }

  return (
    <Wrapper>
      <Heading level={4} textInfo className="whats-next-heading">
        {hasWorksheets && "What's Next"}
        {!hasWorksheets && 'Get Started'}
        <FontAwesome className="whats-next-icon" icon="question-circle" />
      </Heading>
      <NextText>{nextText}</NextText>

      <OrgDashboardActionButtonsOrg planAppId={planApp.id} planApp={planApp} />
    </Wrapper>
  );
};

PlanAppWhatsNext.propTypes = {
  planApp: sowTypes.planAppType.isRequired,
  hasWorksheets: PropTypes.bool.isRequired,
};

export default PlanAppWhatsNext;
