import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import Button from 'sow/components/atoms/Button';
import Glyphicon from 'sow/components/molecules/Glyphicon';
import Confirm from 'sow/components/molecules/Confirm';

class WorksheetMatrixDeleteRowButton extends Component {
  deleteRow = () => {
    const { field, form, answerUuid } = this.props;
    // Remove the row under the given answerUuid from the values object
    form.setFieldValue(field.name, R.dissoc(answerUuid, field.value));
  };

  render() {
    return (
      <Confirm
        message="The selected row will be deleted from the application."
        onConfirm={this.deleteRow}
      >
        {toggleConfirm => (
          <React.Fragment>
            <Button sm danger onClick={toggleConfirm}>
              <Glyphicon glyph="remove" />
            </Button>
          </React.Fragment>
        )}
      </Confirm>
    );
  }
}

WorksheetMatrixDeleteRowButton.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  answerUuid: PropTypes.string.isRequired,
};

export default WorksheetMatrixDeleteRowButton;
