import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { sampleRanks } from 'sow/constants/sampleConstants';
import { inspectionTypes } from 'sow/constants/inspectionConstants';
import * as announcementActions from 'sow/actions/pure/announcement';
import * as adminRequestActions from 'sow/actions/adminRequest';
import actions from 'sow/actions/pure';
import * as sowTypes from 'sow/types';
import * as currentOrg from 'sow/selectors/currentOrg';
import { fromPlanApp } from 'sow/store/selectors';
import {
  ResourceDetailLoader,
  resourceListRead,
  resourceUpdate,
} from 'sow/store/helpers';
import {
  PlanAppSummaryLoader,
  PlanAppLoader,
  PlanAppLocationListLoader,
  PlanAppChangeRequestLoader,
  PlanAppChangeLoader,
} from 'sow/store/containers';
import { isStateInitialApplication, isStateComplete } from 'sow/utils/planAppState';
import * as currentUser from 'sow/selectors/currentUser';
import OrgPageTemplate from 'sow/containers/OrgPageTemplate';
import ResourceLoaderStatusRender from 'sow/components/molecules/ResourceLoaderStatusRender';
import OrgDashboardPage from 'sow/components/pages/OrgDashboardPage';
import OrgPlanAppCreate from 'sow/containers/planApp/Create';
import { planAppRequest } from 'sow/store/requests';

const updatePartial = (orgId, planAppId, partialInspectionId) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/update_partial_state/${partialInspectionId}`,
    'planAppResp',
  );

const markInspectionComplete = (orgId, planAppId, inspectionId) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/mark_inspection_complete/${inspectionId}`,
    'planAppResp',
  );

const updateSampleState = (orgId, planAppId, sampleId) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/update_sample_state/${sampleId}`,
    'planAppResp',
  );

const inspectorAccessListUrl = orgId => `org/${orgId}/inspector_access`;

const inspectorAccessListResource = orgId =>
  resourceListRead(inspectorAccessListUrl(orgId), 'inspectorAccess');

const mapStateToProps = (state, props) => {
  const currentOrgId = currentOrg.orgId(state, props);

  const { selectors: inspectorAccessListSelectors } = inspectorAccessListResource(
    currentOrgId,
  );

  const inspectorAccessListResult = inspectorAccessListSelectors.result(state);

  const notCompleteInspectorAccessList = R.filter(
    R.pathEq(['complete'], false),
    inspectorAccessListResult,
  );

  return {
    orgId: fromPlanApp.orgId(state, props),
    org: currentOrg.org(state, props),
    inspectorAccessList: notCompleteInspectorAccessList,
    planAppId: fromPlanApp.planAppSummaryIdMostRecent(state, props),
    changeRequestId: fromPlanApp.changeRequestId(state, props),
    planApp: fromPlanApp.planApp(state, props),
    planAnnualInspection: fromPlanApp.annualInspection(state, props),
    planPartialInspections: fromPlanApp.planPartialInspections(state, props),
    initialSummary: fromPlanApp.planAppInitialSummary(state, props),
    configPlanMinNumLocations: currentOrg.configPlanMinNumLocations(state, props),
    showAcaUI: currentUser.showAcaUI(state, props),
    planAppSummaryList: fromPlanApp.planAppSummaryList(state, props),
    showInspectorUI: currentUser.showInspectorUI(state, props),
    inspectorId: currentUser.id(state, props),
    adminRequests: state.adminRequests,
    requestsFetching: state.adminRequests.worksheetRequests.isFetching,
    requestsLoaded: state.adminRequests.worksheetRequests.isLoaded,
    pendingSampleInspections: state.inspection.list,
    announcements: state.announcements.list,
  };
};

const mapDispatchToProps = {
  fetchAdminRequests: adminRequestActions.fetchAdminRequests,
  updatePartial: (orgId, planAppId, partialInspectionId, updates) =>
    updatePartial(orgId, planAppId, partialInspectionId).action(null, updates),
  markInspectionComplete: (orgId, planAppId, partialInspectionId) =>
    markInspectionComplete(orgId, planAppId, partialInspectionId).action(null),
  updateSampleState: (orgId, planAppId, partialInspectionId, stateUpdate) =>
    updateSampleState(orgId, planAppId, partialInspectionId).action(null, stateUpdate),
  loadInspectorAccessList: orgId => inspectorAccessListResource(orgId).action(),
  loadOrg: actions.org.loadOne,
  loadPartialStateOptions: actions.inspection.getOptions,
  loadSampleStateOptions: actions.sample.getOptions,
  toast: actions.shell.toast,
  getPendingAnnualInspection: actions.inspection.getAllByOrg,
  loadAnnouncements: announcementActions.loadAnnouncements,
};

const fakeStatus = status => ({
  ...status,
  error: false,
  done: true,
});

class OrgDashboardPageContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { orgId } = this.props;
    this.props.loadAnnouncements({
      enabled: true,
      date_filter: true,
      display_options: { org_id: orgId },
    });
    this.props.loadOrg(orgId);
    if (this.props.showAcaUI && !this.props.showInspectorUI) {
      this.props.loadPartialStateOptions();
      this.props.loadSampleStateOptions();
    }
  }

  componentDidUpdate(prevProps) {
    const { orgId, planAppId, showAcaUI, showInspectorUI } = this.props;
    if (prevProps.planAppId !== planAppId) {
      this.props.loadOrg(orgId);
      if (showAcaUI && !showInspectorUI) {
        this.props.getPendingAnnualInspection({
          orgId,
          remove_current_application_id: planAppId,
          minimum_sample_state_rank: sampleRanks.closed,
          inspection_type_id: inspectionTypes.annual,
        });
      }
    }
  }

  render() {
    const {
      orgId,
      planAppId,
      planApp,
      planAppSummaryList,
      changeRequestId,
      fetchAdminRequests,
      loadInspectorAccessList,
      showAcaUI,
    } = this.props;

    const loadAdminRequests = () => {
      const { requestsLoaded, requestsFetching, planAppId, adminRequests } = this.props;
      if (planAppId) {
        if (
          (!requestsLoaded && !requestsFetching) ||
          (requestsLoaded && adminRequests.planAppId != planAppId)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    const loadRequests = loadAdminRequests();
    const isInitial = isStateInitialApplication(planApp);
    const isComplete = isStateComplete(planApp);

    const dashboardProps = {
      ...this.props,
      isInitial,
      isComplete,
    };

    return (
      <OrgPageTemplate title="Organization Dashboard">
        <PlanAppSummaryLoader orgId={orgId} autoLoad>
          {({ status }) => (
            <ResourceLoaderStatusRender
              statusObjects={[status]}
              renderAllSuccess={() => {
                if (loadRequests) {
                  fetchAdminRequests(orgId, planAppId);
                  if (showAcaUI) {
                    loadInspectorAccessList(orgId);
                  }
                }
                if (R.isEmpty(planAppSummaryList)) return <OrgPlanAppCreate />;
                return (
                  <PlanAppLoader planAppId={planAppId} autoLoad>
                    {({ status }) => (
                      <ResourceLoaderStatusRender
                        statusObjects={[status]}
                        renderAllSuccess={() => (
                          <PlanAppLocationListLoader planAppId={planAppId} autoLoad>
                            {({ status }) => (
                              <ResourceLoaderStatusRender
                                statusObjects={[status]}
                                renderAllSuccess={() => (
                                  <ResourceDetailLoader
                                    resource={planAppRequest.initialSummaryUrl(
                                      orgId,
                                      planAppId,
                                    )}
                                    entityType="planAppInitialSummary"
                                    autoLoad={isInitial}
                                  >
                                    {({ status }) => {
                                      const initialStatus = isInitial
                                        ? status
                                        : fakeStatus(status);
                                      return (
                                        <ResourceLoaderStatusRender
                                          statusObjects={[initialStatus]}
                                          renderAllDone={() => (
                                            <PlanAppChangeRequestLoader
                                              autoLoad={!R.isNil(changeRequestId)}
                                            >
                                              {({ status: crStatus }) => (
                                                <PlanAppChangeLoader
                                                  autoLoad={!R.isNil(changeRequestId)}
                                                >
                                                  {({ status: changesStatus }) => {
                                                    const areChangesLoaded =
                                                      changeRequestId &&
                                                      !changesStatus.initial;
                                                    return (
                                                      <ResourceLoaderStatusRender
                                                        statusObjects={[
                                                          areChangesLoaded
                                                            ? changesStatus
                                                            : fakeStatus(changesStatus),
                                                        ]}
                                                        renderAllDone={() => (
                                                          <OrgDashboardPage
                                                            {...dashboardProps}
                                                          />
                                                        )}
                                                      />
                                                    );
                                                  }}
                                                </PlanAppChangeLoader>
                                              )}
                                            </PlanAppChangeRequestLoader>
                                          )}
                                        />
                                      );
                                    }}
                                  </ResourceDetailLoader>
                                )}
                              />
                            )}
                          </PlanAppLocationListLoader>
                        )}
                      />
                    )}
                  </PlanAppLoader>
                );
              }}
            />
          )}
        </PlanAppSummaryLoader>
      </OrgPageTemplate>
    );
  }
}

OrgDashboardPageContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType,
  changeRequestId: sowTypes.planAppChangeRequestIdType,
  planApp: sowTypes.planAppType,
  initialSummary: sowTypes.planAppInitialSummaryType,
  configPlanMinNumLocations: PropTypes.number.isRequired,
  showAcaUI: PropTypes.bool.isRequired,
  planAppSummaryList: PropTypes.arrayOf(sowTypes.planAppSummaryType).isRequired,
  showInspectorUI: PropTypes.bool.isRequired,
};

OrgDashboardPageContainer.defaultProps = {
  planAppId: null,
  changeRequestId: null,
  planApp: null,
  initialSummary: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrgDashboardPageContainer);
