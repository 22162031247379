import React, { Fragment } from 'react';
import * as R from 'ramda';

import { systemAdminRoute, certificationTypeAddEditRoute } from 'sow/routes';
import { API_URL } from 'sow/constants/api';
import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';
import Image from 'sow/components/atoms/Image';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import InputFormGroup from 'sow/components/molecules/InputFormGroup';
import SelectFormGroup from 'sow/components/molecules/SelectFormGroup';
import Button from 'sow/components/atoms/Button';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Spinner from 'sow/components/atoms/Spinner';
import DragDropListContainer from 'sow/containers/DragDropListContainer';

const selectValues = [
  { value: true, label: 'Enabled' },
  { value: false, label: 'Disabled' },
];

const CertificationTypesList = ({
  formValues,
  listFiltered,
  certificationList,
  handleInputChange,
  handleSelectChange,
  handleSearch,
  handleReset,
  updateSortOrder,
  loadingList,
}) => (
  <Widget className="system-admin-list-edit">
    <WidgetHeading>Certification Types</WidgetHeading>
    <WidgetBody>
      <form onSubmit={handleSearch}>
        <Row className="form-row">
          <Column className="form-column" xs={6} sm={6} md={4}>
            <InputFormGroup
              id="certificationName"
              label="Name"
              inputClassName="input-filter"
              onChange={handleInputChange}
              value={formValues.certificationName}
            />
          </Column>
          <Column className="form-column" xs={6} sm={6} md={4}>
            <SelectFormGroup
              fieldName="status"
              label="Status"
              className="search-filter"
              options={selectValues}
              clearable={true}
              onChange={target => handleSelectChange('status', target)}
              value={formValues.status}
              placeholder="Select Status"
            />
          </Column>
          <Column className="form-column" xs={12} sm={6} md={4}>
            <Row className="button-row search-button-row">
              <Column className="button-column" xs={6} sm={6} md={6}>
                <Button className="form-button search-button" type="submit">
                  Search
                </Button>
              </Column>
              <Column className="button-column" xs={6} sm={6} md={6}>
                <Button className="form-button reset-return-button" onClick={handleReset}>
                  Reset
                </Button>
              </Column>
            </Row>
          </Column>
        </Row>
      </form>
      <HorizontalRule className="divider" />
      <Row className="form-row">
        <Column className="form-column" xs={12} sm={6} md={4}>
          <Row className="button-row">
            <Column className="button-column" xs={6} sm={6} md={6}>
              <Button
                className="form-button"
                to={certificationTypeAddEditRoute('new')}
                primary
              >
                <FontAwesome className="button-icon" icon="plus" />
                Add
              </Button>
            </Column>
            <Column className="button-column" xs={6} sm={6} md={6}>
              <Button className="form-button reset-return-button" to={systemAdminRoute()}>
                Back
              </Button>
            </Column>
          </Row>
        </Column>
      </Row>
      {loadingList ? (
        <Spinner />
      ) : (
        <Row className="form-row list-row">
          <Column className="form-column" xs={12} sm={12} md={12}>
            <DragDropListContainer
              list={certificationList}
              updateSortOrder={updateSortOrder}
              listFiltered={listFiltered}
            >
              {listItem => {
                const imgLocation = R.pathOr('', ['file', 's3_name'], listItem);
                return (
                  <Fragment>
                    <Block className="img-block" textCenter>
                      <Image
                        className="cert-img"
                        imageUrl={`${API_URL}/v1/file/by_path/${imgLocation}`}
                      />
                    </Block>
                    <Block>
                      {listItem.enabled ? listItem.name : `${listItem.name} [INACTIVE]`}
                    </Block>
                    <FontAwesome className="edit-icon" icon="pencil" />
                    <Link
                      className="start-end-width"
                      to={certificationTypeAddEditRoute(listItem.id)}
                    >
                      Edit
                    </Link>
                  </Fragment>
                );
              }}
            </DragDropListContainer>
          </Column>
        </Row>
      )}
    </WidgetBody>
  </Widget>
);

export default CertificationTypesList;
