import React from 'react';
import styled from 'styled-components';

import Block from 'sow/components/atoms/Block';

const AnnouncementBlock = styled(Block)`
  display: flex;
  border: 1px solid;
  background-color: ${({ secondary_color }) =>
    secondary_color ? secondary_color : '#fff8ef'};
  border-color: ${({ primary_color }) => (primary_color ? primary_color : '#da8407')};
  margin-bottom: 10px;
  padding: 5px 10px;
`;

export default AnnouncementBlock;
