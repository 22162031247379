import React, { Component, Fragment } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import styled from 'styled-components';

import * as userManagementActions from 'sow/actions/pure/userManagement';

import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const mapDispatchToProps = {
  loadSingleUser: userManagementActions.loadSingleUser,
  addOrUpdatePriority: userManagementActions.addOrUpdatePriorityToOrg,
  removePriority: userManagementActions.removePriorityToOrg,
};

const PopoverWrapper = styled(Block)`
  padding: 10px;
`;

const PopoverButton = styled(Button)`
  width: 100%;
  text-align: justify;
  border: none;
`;

class UserPriorityPopover extends Component {
  constructor(props) {
    super(props);

    this.state = { anchorEl: null };
  }

  showPopover = event => this.setState({ anchorEl: event.currentTarget });

  hidePopover = () => this.setState({ anchorEl: null });

  updateOrgs({ user_id }) {
    const { loadSingleUser } = this.props;
    loadSingleUser(String(user_id));
  }

  updatePriority = level => {
    const { addOrUpdatePriority, org } = this.props;
    const { priorityId, orgId, applicationId, uniqueId } = org;

    if (priorityId !== level) {
      addOrUpdatePriority(
        {
          orgId,
          appId: applicationId,
          priority_type_id: level,
          organization_user_id: uniqueId,
        },
        this.updateOrgs.bind(this),
      );
    }
    this.hidePopover();
  };

  removePriority = () => {
    const { removePriority, org } = this.props;
    const { orgId, applicationId, uniqueId, priorityId } = org;
    if (priorityId) {
      removePriority(
        { orgId, appId: applicationId, organization_user_id: uniqueId },
        this.updateOrgs.bind(this),
      );
    }
    this.hidePopover();
  };

  render() {
    const { priorityTypes } = this.props;
    const { anchorEl } = this.state;

    const open = Boolean(anchorEl);

    return (
      <Fragment>
        Edit{' '}
        <Block onClick={event => this.showPopover(event)} inline>
          <FontAwesome className="fa-regular" icon="square-caret-down" />
        </Block>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={this.hidePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <PopoverWrapper>
            {priorityTypes.length > 0 &&
              priorityTypes.map(priorityType => (
                <Block key={priorityType.id}>
                  <PopoverButton onClick={() => this.updatePriority(priorityType.id)}>
                    Mark as {priorityType.name} Priority
                  </PopoverButton>
                </Block>
              ))}
            <Block>
              <PopoverButton onClick={() => this.removePriority()}>
                Remove Priority
              </PopoverButton>
            </Block>
          </PopoverWrapper>
        </Popover>
      </Fragment>
    );
  }
}

export default R.compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(UserPriorityPopover);
