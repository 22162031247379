import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as itemActions from 'sow/actions/pure/item';
import { apiGet, apiPost, apiPut } from 'sow/sagas/api';

export const loadItems = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/item', payload);

    if (response && meta.onSuccess) {
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchById = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/item/${payload}`);

    if (response && meta.onSuccess) {
      yield put(itemActions.fetchByIdDone(response.data));
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addItem = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/item', payload);

    if (response && meta.onSuccess) {
      yield put(itemActions.addItemDone(response.data));
      yield put(actions.shell.toast('success', 'Item created.'));
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateItem = function*({ id, payload, meta }) {
  try {
    const { response } = yield call(apiPut, `/admin/item/${id}`, payload);

    if (response) {
      yield put(itemActions.updateItemDone(response.data));
      yield put(actions.shell.toast('success', 'Item updated.'));
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateSortOrder = function*({ payload }) {
  try {
    yield call(apiPost, '/admin/item/update_order', { updated_list: payload });
  } catch (error) {
    console.error(error);
  }
};

export const deleteItem = function*({ id, meta }) {
  try {
    const { response } = yield call(apiPut, `/admin/item/delete/${id}`);
    if (response) {
      yield put(actions.shell.toast('success', 'Item deleted.'));
      if (meta.onSuccess) meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export const loadAdminOptions = function*({ meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/item/options');
    if (response && meta.onSuccess) meta.onSuccess(response);
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(itemActions.LOAD_ITEMS, loadItems);
  yield takeEvery(itemActions.FETCH_BY_ID, fetchById);
  yield takeEvery(itemActions.ADD_ITEM, addItem);
  yield takeEvery(itemActions.UPDATE_ITEM, updateItem);
  yield takeEvery(itemActions.UPDATE_SORT_ORDER, updateSortOrder);
  yield takeEvery(itemActions.DELETE_ITEM, deleteItem);
  yield takeEvery(itemActions.LOAD_OPTIONS, loadAdminOptions);
}
