import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

/**
 * Get the hash currently in the browser's location (URL bar)
 * @return {String}
 */
export function getCurrentUrlHash() {
  return window.location.hash.substring(1);
}

/**
 * Scrolls browser window to DOM element for current URL hash.
 * @arg {Number}  offset - Number of pixels above/below target element to scroll.
 * @arg {Boolean} animateScroll
 * @return {Boolean} Indicates if element was scrolled to.
 *
 * This function does the following:
 *  1. Look for hash in the current browser url
 *  2. If hash exist look for element with id==hash
 *  3. If element found scroll to that element
 *
 * By default the scroll will be animated. To scroll without animation set the
 * `animateScroll` to `false`.
 */
export function scrollToCurrentHash(offset, animateScroll) {
  const scrollTargetId = getCurrentUrlHash();
  return scrollToId(scrollTargetId, offset, animateScroll);
}

/**
 * Scrolls browser window to DOM element.
 * @arg {String}  id - The id of scroll to target element.
 * @arg {Number}  offset - Number of pixels above/below target element to scroll.
 * @arg {Boolean} animateScroll
 * @return {Boolean} Indicates if element was scrolled to.
 */
export function scrollToId(id, offset = 0, animateScroll = true) {
  const scrollToElement = document.getElementById(id);

  if (!scrollToElement) return false;

  window.scroll({
    top: scrollToElement.offsetTop - ~~offset,
    behavior: animateScroll ? 'smooth' : 'auto',
  });

  return true;
}

/**
 * This watches the browser history and when it changes if an element with the
 * id matching the URL hash it is scrolled to.
 */
export function syncHistoryScrollTo(history) {
  const unlisten = history.listen((location /*, action */) => {
    try {
      const scrollTargetId = location.hash.substring(1);
      if (scrollTargetId) {
        scrollToId(scrollTargetId, 10);
      }
    } catch (e) {
      console.error(e);
    }
  });

  return unlisten;
}
