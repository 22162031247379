import React from 'react';

import OrgSettingsPageTemplate from 'sow/containers/OrgSettingsPageTemplate';
import OrgSettingsInspectors from 'sow/containers/OrgSettingsInspectors';

const OrgSettingsInspectorsTabPage = () => (
  <OrgSettingsPageTemplate>
    <OrgSettingsInspectors />
  </OrgSettingsPageTemplate>
);

export default OrgSettingsInspectorsTabPage;
