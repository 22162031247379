import {
  LOAD_SINGLE_USER_DONE,
  LOAD_USER_HISTORY_DONE,
  LOAD_ALL_USERS_DONE,
  CLEAR_RESULTS,
  LOAD_FORM_OPTIONS,
  LOAD_FORM_OPTIONS_DONE,
  LOAD_REQUESTED_ORG_USERS,
  LOAD_REQUESTED_ORG_USERS_DONE,
  LOAD_REQUESTED_ORG_USER,
  LOAD_REQUESTED_ORG_USER_DONE,
  FETCH_MANAGEABLE_ORGS_DONE,
} from 'sow/actions/pure/userManagement';

const initialState = {
  fetchingOptions: true,
  selectOptions: {},
  manageableOrgs: [],
};

export default function userManagement(state = initialState, { type, payload }) {
  switch (type) {
    case LOAD_ALL_USERS_DONE:
      return { ...state, users: payload };
    case LOAD_SINGLE_USER_DONE:
      return { ...state, user: payload };
    case LOAD_USER_HISTORY_DONE:
      return { ...state, history: payload };
    case CLEAR_RESULTS:
      return { ...initialState, [payload]: [] };
    case LOAD_FORM_OPTIONS:
      return { ...state, fetchingOptions: true };
    case LOAD_FORM_OPTIONS_DONE:
      return { ...state, selectOptions: payload, fetchingOptions: false };
    case LOAD_REQUESTED_ORG_USERS:
      return { ...state, usersLoaded: false };
    case LOAD_REQUESTED_ORG_USERS_DONE:
      return {
        ...state,
        selectOptions: payload.selectOptions,
        requestedUsers: payload.users,
        usersLoaded: true,
      };
    case LOAD_REQUESTED_ORG_USER:
      return { ...state, userLoaded: false };
    case LOAD_REQUESTED_ORG_USER_DONE:
      return { ...state, requestedUser: payload, userLoaded: true };
    case FETCH_MANAGEABLE_ORGS_DONE:
      return { ...state, manageableOrgs: payload };
    default:
      return state;
  }
}
