import React, { Component, Fragment } from 'react';
import * as R from 'ramda';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';
import PropTypes from 'prop-types';

import { bgColorInfo } from 'sow/constants/style';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';
import RouterWillLeaveBlock from 'sow/components/atoms/RouterWillLeaveBlock';

const mapDispatchToProps = {
  routerPush,
};

class Form extends Component {
  state = {
    disabled: this.props.initialDisabled,
    isDirty: this.props.initialIsDirty,
  };

  handleChange = R.curry((field, value) => {
    this.setDirty(true);
    this.props.handleChange(field, value);
  });

  handleChangeWithErrors = R.curry((field, value, hasError) => {
    this.setDirty(true);
    this.setDisabled(hasError);
    this.props.handleChange(field, value);
  });

  handleSubmit = event => {
    event.preventDefault();
    this.setDisabled(true);
    this.props.handleSubmit(this.handleSubmitSuccess, this.handleSubmitFail);
  };

  handleSubmitSuccess = data => {
    const { routerPush, urlSuccess, handleSubmitSuccess } = this.props;

    this.setDirty(false);
    this.setDisabled(false);

    if (handleSubmitSuccess) {
      handleSubmitSuccess(data);
    } else if (urlSuccess) {
      routerPush(urlSuccess);
    } else {
      console.debug('Nothing to do after SUCCESSFUL submit.');
    }
  };

  handleSubmitFail = () => {
    const { handleSubmitFail } = this.props;

    this.setDisabled(false);

    if (handleSubmitFail) {
      handleSubmitFail();
    } else {
      console.debug('Nothing to do after FAILURE submit.');
    }
  };

  setDirty = isDirty => {
    this.setState(R.assoc('isDirty', isDirty));
  };

  setDisabled = isDisabled => {
    this.setState(R.assoc('disabled', isDisabled));
  };

  renderCancelButton() {
    const { urlCancel } = this.props;
    const { disabled } = this.state;

    if (!urlCancel) return null;

    return (
      <Link
        className="btn btn-default"
        to={urlCancel}
        onClick={this.blockCancelLink}
        disabled={disabled}
      >
        Cancel
      </Link>
    );
  }

  renderChild() {
    const {
      handleChange,
      handleChangeWithErrors,
      handleSubmit,
      handleSubmitSuccess,
      header,
      initialDisabled,
      initialIsDirty,
      urlCancel,
      urlSuccess,
      ...passThruProps
    } = this.props;

    const { disabled, isDirty } = this.state;

    const child = React.Children.only(this.props.children);

    return React.cloneElement(child, {
      ...passThruProps,
      disabled,
      isDirty,
      handleChange: this.handleChange,
      handleChangeWithErrors: this.handleChangeWithErrors,
    });
  }

  render() {
    const { header } = this.props;
    const { disabled, isDirty } = this.state;

    return (
      <Fragment>
        <RouterWillLeaveBlock blocked={isDirty} />

        <Widget>
          {header && <WidgetHeading>{header}</WidgetHeading>}
          <form onSubmit={this.handleSubmit}>
            <WidgetBody
              style={{
                borderLeft: `5px solid ${isDirty ? bgColorInfo : 'white'}`,
                borderRight: `5px solid ${isDirty ? bgColorInfo : 'white'}`,
                borderTop: `5px solid ${isDirty ? bgColorInfo : 'white'}`,
              }}
            >
              {this.renderChild()}
            </WidgetBody>
            <WidgetFooter className={isDirty ? 'bg-info' : ''}>
              <div className="pull-left" style={{ padding: '7px 7px 0' }}>
                {isDirty && (
                  <span className="text-info">Changes have not been saved.</span>
                )}
              </div>
              <div className="pull-right">
                {this.renderCancelButton()}{' '}
                <button type="submit" className="btn btn-primary" disabled={disabled}>
                  Save
                </button>
              </div>
              <div className="clearfix" />
            </WidgetFooter>
          </form>
        </Widget>
      </Fragment>
    );
  }
}

Form.propTypes = {
  children: PropTypes.node,
  routerPush: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSubmitFail: PropTypes.func,
  handleSubmitSuccess: PropTypes.func,
  header: PropTypes.node,
  initialDisabled: PropTypes.bool,
  initialIsDirty: PropTypes.bool,
  urlCancel: PropTypes.string,
  urlSuccess: PropTypes.string,
};

Form.defaultProps = {
  initialDisabled: false,
  initialIsDirty: false,
};

export default R.compose(withRouter, connect(null, mapDispatchToProps))(Form);
