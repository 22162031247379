import * as R from 'ramda';
import RS from 'ramdasauce';

import { createSelector } from 'reselect';

import * as authSelect from 'sow/selectors/core/auth';
import * as acaSelect from 'sow/selectors/core/aca';
import * as orgSelect from 'sow/selectors/core/organization';

export const layoutState = state => state.layout;

export const isAuthenticated = createSelector([authSelect.userId], RS.isNotNil);

export const isAuthenticating = createSelector([authSelect.isAuthenticating], R.identity);

export const isValidating = createSelector([authSelect.isValidating], R.identity);

export const id = createSelector([authSelect.userId], R.identity);

export const acaIds = createSelector([authSelect.acaIds], R.identity);

export const acaId = createSelector([authSelect.acaId], R.identity);

export const orgId = createSelector([authSelect.orgId], R.identity);

export const primaryContactId = createSelector([orgSelect.primaryContactId], R.identity);

export const activeOrgs = createSelector([authSelect.activeOrgs], R.identity);

export const email = createSelector([authSelect.username], R.identity);

export const name = createSelector([authSelect.name], R.identity);

export const impersonating = createSelector([authSelect.impersonating], R.identity);

export const roles = createSelector([authSelect.roles], R.defaultTo([]));

export const isPrimaryContact = createSelector(
  [id, primaryContactId],
  (currentUserId, primaryContactId) => R.equals(currentUserId, primaryContactId),
);

export const isConsultant = createSelector(
  [id, orgSelect.organizationConsultants],
  (currentUserId, orgConsultant) => R.has(currentUserId)(orgConsultant),
);

export const isSuperAdmin = createSelector([roles], R.contains('admin'));

export const isInspector = createSelector([roles], R.contains('inspector'));

export const isAcaAdmin = createSelector([roles], R.contains('aca_admin'));

export const isAcaOspEditor = createSelector([roles], R.contains('aca_osp_editor'));

export const isAcaOwner = createSelector([roles], R.contains('aca_owner'));

export const isWorkflowManager = createSelector([roles], R.contains('workflow_manager'));

export const isCertificationSpecialist = createSelector(
  [roles],
  R.contains('certification_specialist'),
);

export const isStaff = createSelector(
  [isSuperAdmin, isCertificationSpecialist, isWorkflowManager],
  (admin, specialist, manager) => admin || specialist || manager,
);

export const isAcaStaff = createSelector([isAcaAdmin, isAcaOspEditor, isAcaOwner], R.or);

export const isOrgAdmin = createSelector([roles], R.contains('user'));

export const isClient = createSelector([roles], R.contains('user'));

export const isCSStaff = createSelector([isAcaStaff, isCertificationSpecialist], R.or);

// TODO (orther) move all core logic to core selector and add base permissions selector
export const canEditOrg = createSelector([isSuperAdmin, isAcaAdmin], R.or);

// TODO (orther) update OspDefEditor logic so there is a view mode instead
export const canEditOspDef = createSelector([isSuperAdmin, isAcaOspEditor], R.or);

export const showAdminUI = createSelector([isSuperAdmin], R.identity);

export const showAcaUI = createSelector([isSuperAdmin, isAcaStaff], R.or);

export const hideAcaUI = createSelector([showAcaUI], R.not);

export const showInspectorUI = isInspector;

export const showClientUI = isClient;

export const showHardCopyAccount = createSelector([isInspector, showAcaUI], R.or);

export const showOrgUI = createSelector([hideAcaUI, showInspectorUI], R.or);

export const isAdminXrayMode = createSelector([layoutState], R.path(['adminXrayMode']));

export const settingsAcaFilterById = createSelector(
  [authSelect.settings],
  R.path(['aca_filter', 'id']),
);

const acaNameSort = R.sortWith([R.ascend(R.prop('name'))]);

export const acaList = createSelector(
  [acaSelect.acaList, settingsAcaFilterById],
  (acaList, acaId) => {
    const filterList = acaId ? R.filter(R.propEq('id', acaId)) : R.identity;

    return R.compose(acaNameSort, filterList)(acaList);
  },
);
