import * as R from 'ramda';
import { scrollRoute, scrollTargetId, routeLocationDescriptor } from './utils';
import { worksheetRoute, locationRoute } from 'sow/routes';

// --
// -- Scroll Target IDs
// --

// Plan App Worksheet Question
export function planAppWorksheetQuestionScrollTargetId(questionId) {
  return scrollTargetId(`pa-wsq`, questionId);
}

// Plan App Worksheet Matrix Row
export function planAppWorksheetMatrixRowScrollTargetId(matrixRowId) {
  return scrollTargetId(`pa-wsmr`, matrixRowId);
}

// --
// -- Scroll Routes
// --

// Plan App Worksheet Question
export const planAppWorksheetQuestionScrollRoute = (
  orgId,
  planAppId,
  worksheetId,
  wsQuestionId,
) =>
  scrollRoute(
    worksheetRoute(orgId, planAppId, worksheetId),
    planAppWorksheetQuestionScrollTargetId(wsQuestionId),
  );

// Plan App Worksheet Matrix Row
export const planAppWorksheetMatrixRowScrollRoute = (
  orgId,
  planAppId,
  worksheetId,
  matrixRowId,
) =>
  scrollRoute(
    worksheetRoute(orgId, planAppId, worksheetId),
    planAppWorksheetMatrixRowScrollTargetId(matrixRowId),
  );

// Location worksheet question
export const locationWorksheetQuestionScrollRoute = (
  orgId,
  planAppId,
  locationId,
  wsQuestionId,
) =>
  scrollRoute(
    locationRoute(orgId, planAppId, locationId),
    planAppWorksheetQuestionScrollTargetId(wsQuestionId),
  );

export const locationWorksheetMatrixRowScrollRoute = (
  orgId,
  planAppId,
  locationId,
  matrixRowId,
) =>
  scrollRoute(
    locationRoute(orgId, planAppId, locationId),
    planAppWorksheetMatrixRowScrollTargetId(matrixRowId),
  );

export const planAppWorksheetMatrixRowScrollRouteLinkToObject = R.pipe(
  planAppWorksheetMatrixRowScrollRoute,
  routeLocationDescriptor,
);

export const locationWorksheetMatrixRowScrollRouteLinkToObject = R.pipe(
  locationWorksheetMatrixRowScrollRoute,
  routeLocationDescriptor,
);
