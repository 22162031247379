import { uploadPictureAsync, uploadFileAsync } from 'sow/services/fileUpload';
import * as R from 'ramda';
import { apiRequest } from 'sow/utils/api';

const mark = action => `soworganic/fileUpload/${action}`;

export const ADD_TO_PICTURE_QUEUE = mark('ADD_TO_PICTURE_QUEUE');

export const addToPictureQueue = item => dispatch => {
  dispatch({
    type: ADD_TO_PICTURE_QUEUE,
    data: item,
  });
};

export const REMOVE_FROM_PICTURE_QUEUE = mark('REMOVE_FROM_PICTURE_QUEUE');

export const removeFromPictureQueue = guid => dispatch => {
  dispatch({
    type: REMOVE_FROM_PICTURE_QUEUE,
    data: guid,
  });
};

export const UPDATE_PICTURE = mark('UPDATE_PICTURE');

export const updatePicture = (guid, field, value) => dispatch => {
  dispatch({
    type: UPDATE_PICTURE,
    data: { guid, field, value },
  });
};

// Note: This is not currently referenced anywhere - should consider removing
export const uploadPictureQueue = (queue, callback) => dispatch => {
  queue
    .filter(item => {
      // Only upload pending files
      return item.status === 'pending';
    })
    .forEach(item => {
      dispatch(updatePicture(item.guid, 'status', 'uploading'));

      uploadPictureAsync(item, onPictureUploadProgress)
        .done(res => {
          if (callback) callback(res);

          dispatch(updatePicture(item.guid, 'status', 'success'));
        })
        .fail(err => {
          console.log('uploadPictureQueue::uploadFileAsync:fail: err', err);
          dispatch(updatePicture(item.guid, 'status', 'failed'));

          const error = R.path(['responseJSON', 'error', 'message']);
          const errors = R.path(['responseJSON', 'error', 'errors']);

          if (error)
            dispatch(
              updatePicture(
                item.guid,
                'error',
                'There was a problem uploading the file.',
              ),
            );
          if (errors) dispatch(updatePicture(item.guid, 'errors', errors));
        });
    });
};

const onPictureUploadProgress = (guid, progress) => dispatch => {
  dispatch(updatePicture(guid, 'progress', progress));
};

export const ADD_TO_FILE_QUEUE = mark('ADD_TO_FILE_QUEUE');

export const addToFileQueue = item => ({
  type: ADD_TO_FILE_QUEUE,
  data: item,
});

export const REMOVE_ALL_FROM_FILE_QUEUE = mark('REMOVE_ALL_FROM_FILE_QUEUE');

export const removeAllFromFileQueue = () => ({
  type: REMOVE_ALL_FROM_FILE_QUEUE,
});

export const REMOVE_FROM_FILE_QUEUE = mark('REMOVE_FROM_FILE_QUEUE');

export const removeFromFileQueue = guid => dispatch => {
  dispatch({
    type: REMOVE_FROM_FILE_QUEUE,
    data: guid,
  });
};

export const UPDATE_FILE = mark('UPDATE_FILE');

export const updateFile = (guid, field, value) => dispatch => {
  dispatch({
    type: UPDATE_FILE,
    data: { guid, field, value },
  });
};

const constructRecordUploadUrl = (item, fileRef) => {
  let filename = encodeURIComponent(item.file.name);  
  let category = encodeURIComponent(item.category);
  let url = `${item.uploadUrl}?file_ref=${fileRef}&file_size=${item.file.size}&file_name=${filename}&file_mime_type=${item.file.type}`;

  if (item.name) url += `&doc_name=${encodeURIComponent(item.name)}`;
  if (item.category) url += `&category=${category}`;
  if (item.public) url += `&public=${item.public}`;
  //console.log('constructRecordUploadUrl', url);
  
  return url;
};

export const uploadFileQueue = (queue, callback, dontRecordUpload) => dispatch => {
  queue
    .filter(item => {
      // Only upload pending files
      return item.status === 'pending';
    })
    .forEach(item => {
      dispatch(updateFile(item.guid, 'status', 'uploading'));

      // First call API to fetch the signed upload url, then use that for the actual upload. Once the upload has 
      // completed, then call the regular uploadUrl with the fieldId to tell the system it has completed.
      apiRequest({
        method: 'POST',
        url: item.signedUploadUrl,
      })
        .done(response => {
          const fileRef = response.file_ref;
          const signedUrl = response.url;

          uploadFileAsync(
            item, 
            (guid, progress) => {
              dispatch(updateFile(guid, 'progress', progress));
            }, 
            signedUrl
          )
            .done(() => {
              if (dontRecordUpload) {
                if (callback) callback({});
                dispatch(updateFile(item.guid, 'status', 'success'));
              } else {
                const recordUploadUrl = constructRecordUploadUrl(item, fileRef);

                apiRequest({
                  method: 'POST',
                  url: recordUploadUrl,
                })
                  .done(res => {
                    if (callback) callback(res);
                    dispatch(updateFile(item.guid, 'status', 'success'));
                  })
                  .fail(err => {
                    console.log('uploadFileQueue::recordUpload:fail: err', err);
                    dispatch(updateFile(item.guid, 'status', 'failed'));
                  });
              }
            })
            .fail(err => {
              console.log('uploadFileQueue::uploadFileAsync:fail: err', err);
              dispatch(updateFile(item.guid, 'status', 'failed'));

              const error = R.path(['responseJSON', 'error', 'message']);
              const errors = R.path(['responseJSON', 'error', 'errors']);

              if (error)
                dispatch(
                  updateFile(
                    item.guid,
                    'error',
                    'There was a problem uploading the file.',
                  ),
                );
              if (errors) dispatch(updateFile(item.guid, 'errors', errors));
            });
        })
        .fail(err => {
          console.log('uploadFileQueue::signedUploadUrl:fail: err', err);
          dispatch(updateFile(item.guid, 'status', 'failed'));
        });      
    });
};

export const ADD_SINGLE_FILE = mark('ADD_SINGLE_FILE');

export const addSingleFile = file => dispatch => {
  dispatch({
    type: ADD_SINGLE_FILE,
    data: file,
  });
};

export const uploadSingleFile = (item, success) => dispatch => {
  dispatch(updateSingleFile('status', 'uploading'));

  // First call API to fetch the signed upload url, then use that for the actual upload. Once the upload has 
  // completed, then call the regular uploadUrl with the fieldId to tell the system it has completed.
  apiRequest({
    method: 'POST',
    url: item.signedUploadUrl,
  })
    .done(response => {
      const fileRef = response.file_ref;
      const signedUrl = response.url;
      
      uploadFileAsync(
        item, 
        (guid, progress) => { 
          dispatch(updateSingleFile('progress', progress));
        }, 
        signedUrl
      )
        .done(() => {
          const recordUploadUrl = constructRecordUploadUrl(item, fileRef);

          apiRequest({
            method: 'POST',
            url: recordUploadUrl,
          })
            .done(res => {
              dispatch(updateSingleFile('status', 'success'));
              if (success) success(res);
            })
            .fail(err => {
              console.log('uploadSingleFile::recordUpload:fail: err', err);
              dispatch(updateSingleFile('status', 'failed'));
            });
        })
        .fail(err => {
          console.log('uploadSingleFile::uploadFileAsync:fail: err', err);
          dispatch(updateSingleFile('status', 'failed'));

          const error = R.path(['responseJSON', 'error', 'message']);
          const errors = R.path(['responseJSON', 'error', 'errors']);

          if (error)
            dispatch(
              updateSingleFile('error', 'There was a problem uploading the file.'),
            );
          if (errors) dispatch(updateSingleFile('errors', errors));
        });
    })
    .fail(err => {
      console.log('uploadSingleFile::signedUploadUrl:fail: err', err);
      dispatch(updateSingleFile('status', 'failed'));
    }); 
};

export const UPDATE_SINGLE_FILE = mark('UPDATE_SINGLE_FILE');

export const updateSingleFile = (field, value) => dispatch => {
  dispatch({
    type: UPDATE_SINGLE_FILE,
    data: { field, value },
  });
};
