import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import actions from 'sow/actions/pure';
import { resourceCreate, resourceDelete } from 'sow/store/helpers';
import { fromPlanApp } from 'sow/store/selectors';
import * as currentUser from 'sow/selectors/currentUser';
import PlanAppNotes from 'sow/components/organisms/PlanAppNotes';

const createNoteResource = (orgId, planAppId) =>
  resourceCreate(`org/${orgId}/application/${planAppId}/note`, 'planAppNote');
const deleteNoteResource = (orgId, planAppId) =>
  resourceDelete(`org/${orgId}/application/${planAppId}/note`, 'planAppNote');

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  locationId: fromPlanApp.locationId(state, props),
  notes: fromPlanApp.notes(state, props),
  isCreating: (orgId, planAppId, noteId) =>
    createNoteResource(orgId, planAppId, noteId).selectors.pending(state, props),
  isDeleting: (orgId, planAppId, noteId) =>
    deleteNoteResource(orgId, planAppId, noteId).selectors.pending(state, props),
  showAcaUI: currentUser.showAcaUI(state, props) && !currentUser.isInspector(state, props),
});

const mapDispatchToProps = dispatch => ({
  createNote: (orgId, planAppId, questionId) => note => {
    const { action } = createNoteResource(orgId, planAppId, questionId);
    return dispatch(
      action({
        note,
      }),
    );
  },
  deleteNote: (orgId, planAppId) => noteId => {
    const { action } = deleteNoteResource(orgId, planAppId);
    return dispatch(action(noteId));
  },
  toast: (...args) => dispatch(actions.shell.toast(...args)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { orgId, planAppId, locationId } = stateProps;
  const { questionId, matrixRowId } = ownProps;

  const onCreate = (note, onSuccessCb) => {
    const noteWithSubUuid = {
      ...note,
      public: false,
      uuid: questionId,
      // Add the matrix row id as a sub_uuid property if it's present
      sub_uuid: matrixRowId || null,
      land_id: locationId || null,
    };

    return dispatchProps
      .createNote(orgId, planAppId, questionId)(noteWithSubUuid)
      .then(note => {
        onSuccessCb();
        dispatchProps.toast('success', 'Note added successfully.');
      });
  };

  const onDelete = noteId => () => {
    if (!window.confirm('The selected note will be deleted.')) return;
    return dispatchProps
      .deleteNote(orgId, planAppId)(noteId)
      .then(() => {
        dispatchProps.toast(
          'success',
          'Note deleted. You may need to reload the page to see all changes.',
        );
      });
  };

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onCreate,
    onDelete,
    isCreating: stateProps.isCreating(orgId, planAppId, questionId),
    isDeleting: stateProps.isDeleting(orgId, planAppId, questionId),
  };
};

const NotesContainer = ({
  notes,
  onCreate,
  onDelete,
  isCreating,
  isDeleting,
  showAcaUI,
  children,
}) => {
  return (
    <PlanAppNotes
      notes={notes}
      onCreate={onCreate}
      onDelete={onDelete}
      isCreating={isCreating}
      isDeleting={isDeleting}
      showAcaUI={showAcaUI}
    >
      {children}
    </PlanAppNotes>
  );
};

NotesContainer.propTypes = {
  // Passed props
  questionId: sowTypes.planAppWorksheetQuestionIdType.isRequired,
  matrixRowId: sowTypes.planAppWorksheetQuestionMatrixRowIdType,
  children: PropTypes.func.isRequired,

  // Connected props
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  locationId: sowTypes.planAppLocationIdType, // Only when looking at a location page
  notes: PropTypes.arrayOf(sowTypes.planAppNoteType).isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isCreating: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  showAcaUI: PropTypes.bool.isRequired,
};

NotesContainer.defaultProps = {
  matrixRowId: null,
  locationId: null,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
)(NotesContainer);
