import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { acaIdType, acaType } from 'sow/types';
import * as currentAca from 'sow/selectors/currentAca';

const mapStateToProps = (state, props) => ({
  aca: currentAca.aca(state, props),
  acaId: currentAca.acaId(state, props),
});

const CurrentAcaContainer = ({ aca, acaId, children }) => children(aca, acaId);

CurrentAcaContainer.propTypes = {
  aca: acaType.isRequired,
  acaId: acaIdType.isRequired,
};

export default R.compose(withRouter, connect(mapStateToProps))(CurrentAcaContainer);
