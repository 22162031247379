import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { push as routerPush, replace as routerReplace } from 'connected-react-router';

import { itemVersionRoute, itemVersionInfoEditRoute } from 'sow/routes';

import * as itemVersionActions from 'sow/actions/pure/itemVersion';

import ItemVersionInfoEdit from 'sow/components/organisms/ItemVersionInfoEdit';

const mapStateToProps = (state, props) => ({
  itemVersionId: R.path(['match', 'params', 'id'], props),
});

const mapDispatchToProps = {
  loadOptions: itemVersionActions.loadOptions,
  loadItemVersionById: itemVersionActions.fetchById,
  addItemVersion: itemVersionActions.addItemVersion,
  updateItemVersion: itemVersionActions.updateItemVersion,
  redirect: routerPush,
  routerReplace: routerReplace,
};

class ItemVersionInfoEditContainer extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = {
      isNew: false,
      isLoading: true,
      initialValues: { action_group_id: [] },
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadAdminOptions();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadAdminOptions() {
    if (this._isMounted) {
      this.props.loadOptions(this.loadItemVersion.bind(this));
    }
  }

  loadItemVersion({ data }) {
    if (this._isMounted) {
      const { itemVersionId } = this.props;

      this.setState({
        itemOptions: data.item_options,
        actionGroupOptions: data.action_group_options,
      });

      if (itemVersionId !== 'new') {
        this.props.loadItemVersionById(itemVersionId, this.updateState.bind(this));
      } else {
        this.setState({ isNew: true, isLoading: false });
      }
    }
  }

  updateState({ data }) {
    if (this._isMounted) {
      if (this.state.isNew) {
        this.props.routerReplace(itemVersionInfoEditRoute(data.id));
      }

      if (!R.isNil(data)) {
        this.setState({
          isNew: false,
          isLoading: false,
          initialValues: { ...data },
        });
      } else {
        this.redirectToList();
      }
    }
  }

  redirectToList() {
    this.props.redirect(itemVersionRoute());
  }

  handleSave(values, formikBag) {
    const formattedValues = this.formatValues(values);

    if (this.state.isNew) {
      this.props.addItemVersion(formattedValues, this.updateState.bind(this));
    } else {
      this.props.updateItemVersion(
        this.props.itemVersionId,
        formattedValues,
        this.updateState.bind(this),
      );
    }
  }

  formatValues(values) {
    const clonedValues = R.clone(values);
    const actionGroupIds = clonedValues.action_group_id.map(
      actionGroup => actionGroup.value,
    );
    clonedValues.action_group_id = actionGroupIds;
    return clonedValues;
  }

  render() {
    return (
      <ItemVersionInfoEdit
        handleSave={this.handleSave.bind(this)}
        {...this.state}
        {...this.props}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ItemVersionInfoEditContainer);
