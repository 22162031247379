import React from 'react';

import './FilterBlockToggle.module.css';
import Block from 'sow/components/atoms/Block';
import Paragraph from 'sow/components/atoms/Paragraph';
import styled from 'styled-components';

const FilterBlockWrapper = styled(Block)`
  background-color: ${({ theme, toggled, state }) => {
    return toggled && theme.ospChanges[state].primary;
  }};
  border: ${({ theme, toggled, state }) =>
    toggled ? `1px solid ${theme.ospChanges[state].secondary}` : '1px solid #808080'};
  color: ${({ theme, toggled, state }) =>
    toggled ? theme.ospChanges[state].secondary : '#808080'};
`;

const PillDisplay = styled(Block)`
  background-color: ${({ theme, toggled, state }) => {
    return toggled && theme.ospChanges[state].secondary;
  }};
  border: ${({ theme, toggled, state }) =>
    toggled ? `1px solid ${theme.ospChanges[state].secondary}` : '1px solid #808080'};
  color: ${({ toggled }) => (toggled ? 'white' : 'black')};

  ${FilterBlockWrapper}:hover & {
    background-color: ${({ theme, state }) => theme.ospChanges[state].secondary};
    border: ${({ theme, state }) => `1px solid ${theme.ospChanges[state].secondary}`};
    color: white;
  }
`;

const FilterBlockToggle = ({
  type,
  filter,
  onToggleFilter,
  counts,
  children,
  endToggle,
}) => (
  <FilterBlockWrapper
    toggled={filter[type]}
    state={type}
    onClick={() => onToggleFilter(type)}
    className={`filter-block-toggle ${endToggle && 'end-toggle'}`}
  >
    <Block className="count-display">
      {typeof counts === 'number'
        ? counts.toLocaleString()
        : counts[type].toLocaleString()}
    </Block>
    <PillDisplay toggled={filter[type]} state={type} className="filter-pill-display">
      <Paragraph>{`${filter[type] ? 'Hide' : 'Show'} ${children}`}</Paragraph>
    </PillDisplay>
  </FilterBlockWrapper>
);

export default FilterBlockToggle;
