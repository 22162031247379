import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import * as currentUser from 'sow/selectors/currentUser';
import { acaRoute, workflowManagerRoute } from 'sow/routes';
import { acaType } from 'sow/types';
import ListGroup from 'sow/components/molecules/ListGroup';
import ListGroupItem from 'sow/components/atoms/ListGroupItem';

const mapStateToProps = (state, props) => ({
  isWorkflowManager: currentUser.isWorkflowManager(state, props),
  isCertificationSpecialist: currentUser.isCertificationSpecialist(state, props),
});

const AcaLink = ({ aca, isDashboardManager }) => {
  let route = acaRoute(aca.id);
  if (isDashboardManager) {
    route = workflowManagerRoute(aca.id);
  }
  return <ListGroupItem to={route}>{aca.name}</ListGroupItem>;
};

AcaLink.propTypes = {
  aca: acaType.isRequired,
  isDashboardManager: PropTypes.bool,
};

const AcaList = ({ acas, isWorkflowManager, isCertificationSpecialist }) => (
  <ListGroup>
    {acas.map(aca => (
      <AcaLink
        key={aca.id}
        aca={aca}
        isDashboardManager={isWorkflowManager || isCertificationSpecialist}
      />
    ))}
  </ListGroup>
);

AcaList.propTypes = {
  acas: PropTypes.arrayOf(acaType),
  isWorkflowManager: PropTypes.bool,
  isCertificationSpecialist: PropTypes.bool,
};

export default R.compose(withRouter, connect(mapStateToProps))(AcaList);
