import React from 'react';
import * as R from 'ramda';
import { MenuItem, Glyphicon } from 'react-bootstrap';
import FormPanel from '../FormPanel';
import WorksheetQuestion from '../WorksheetQuestion';

export const WorksheetQuestions = ({
  worksheetId,
  questionIds,
  onAddQuestion,
  onDeleteQuestion,
  onMoveQuestionDown,
  onMoveQuestionUp,
  sectionType,
}) => {
  return (
    <FormPanel>
      <FormPanel.Heading>
        Questions{' '}
        <FormPanel.Heading.Dropdown>
          <MenuItem onClick={onAddQuestion(worksheetId)}>
            <Glyphicon glyph="plus" /> Add Question
          </MenuItem>
        </FormPanel.Heading.Dropdown>
      </FormPanel.Heading>
      <FormPanel.Body>
        {R.map(
          questionId =>
            <FormPanel.Body.Row
              key={questionId}
              onDelete={onDeleteQuestion(worksheetId, questionId)}
              onMoveDown={onMoveQuestionDown(worksheetId, questionId)}
              onMoveUp={onMoveQuestionUp(worksheetId, questionId)}
            >
              <WorksheetQuestion
                questionId={questionId}
                wsQuestionIds={questionIds}
                sectionType={sectionType}
              />
            </FormPanel.Body.Row>,
          questionIds,
        )}
       
      </FormPanel.Body>
      <FormPanel.NonHeadingDropdown>
          <MenuItem onClick={onAddQuestion(worksheetId)}>
            <Glyphicon glyph="plus" /> Add Question
          </MenuItem>
      </FormPanel.NonHeadingDropdown>
    </FormPanel>
  );
};

export default WorksheetQuestions;
