import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { ResourceListLoader } from 'sow/store/helpers';
import { fromPlanApp } from 'sow/store/selectors';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  worksheet: fromPlanApp.worksheet(state, props),
  locationQuestionUuids: fromPlanApp.allLocationQuestionIds(state, props),
  locationId: fromPlanApp.locationId(state, props),
});

const PlanAppWorksheetNotesLoader = ({
  orgId,
  planAppId,
  locationId,
  worksheet,
  locationQuestionUuids,
  ...props
}) => {
  const questionUuids = R.propOr([], 'questions', worksheet);

  const uuids = R.isNil(locationId) ? questionUuids : locationQuestionUuids;
  const locationIds = R.isNil(locationId) ? [] : [locationId];

  return (
    <ResourceListLoader
      {...props}
      resource={`org/${orgId}/application/${planAppId}/note`}
      requestParams={{
        uuids,
        land_ids: locationIds,
      }}
      entityType="planAppNote"
    />
  );
};

PlanAppWorksheetNotesLoader.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheet: sowTypes.planAppWorksheetType,
  locationId: sowTypes.planAppLocationIdType,
  locationQuestionUuids: PropTypes.arrayOf(sowTypes.planAppWorksheetQuestionIdType)
    .isRequired,
};

PlanAppWorksheetNotesLoader.defaultProps = {
  worksheet: null,
  locationId: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppWorksheetNotesLoader);
