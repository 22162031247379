import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import PriorityTypeEditContainer from 'sow/containers/PriorityTypeEditContainer';

const PriorityTypeEditPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <PriorityTypeEditContainer />}
  </AdminPageTemplate>
);

export default PriorityTypeEditPage;
