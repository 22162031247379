import React from 'react';
import * as R from 'ramda';
import { DropdownButton, MenuItem, Glyphicon } from 'react-bootstrap';
import { connect } from 'react-redux';

import FormGroup from 'sow/components/organisms/FormGroup';
import FormModal from 'sow/components/old/FormModal';
import FormPanel from 'sow/components/old/OspDefEditor/FormPanel';
import { confirm } from 'sow/actions/pure/messaging';
import { hocEditable } from 'sow/components/old/hocHelpers/uiState';
import * as currentUser from 'sow/selectors/currentUser';

import * as select from '../selectors';
import SubQualificationQuestion from './SubQualificationQuestion';
import Worksheets from './Worksheets';
import { actions as ospDefAction } from '../reducers/ospDef';
import { actions as ospQualQuestionAction } from '../reducers/ospQualificationQuestions';
import { createQualQuestion } from '../reducers/ospQualificationQuestions';

const makeMapStateToProps = () => (state, props) => ({
  ospQualificationQuestion: select.makeOspQQSelector()(state, props),
  canEditOspDef: currentUser.canEditOspDef(state, props),
});

const mapDispatchToProps = (dispatch, props) => {
  const ospQualificationQuestionId = props.ospQualificationQuestionId;
  return {
    onDelete: event => {
      dispatch(
        confirm({
          message: 'Are you sure you want to delete this root question?',
          onConfirm() {
            dispatch(
              ospDefAction.ospQualificationQuestionRemove(ospQualificationQuestionId),
            );
          },
        }),
      );
    },
    onSetInputValue: R.curry((field, event) => {
      dispatch(
        ospQualQuestionAction.setValue(
          ospQualificationQuestionId,
          field,
          event.target.value,
        ),
      );
    }),
    onAddSubQualificationQuestion: event => {
      const newQualQuestion = createQualQuestion();
      dispatch(
        ospQualQuestionAction.subOspQualificationQuestionAdd(
          ospQualificationQuestionId,
          newQualQuestion,
        ),
      );
    },
    onMoveDown: R.curry(event => {
      dispatch(ospDefAction.ospQualificationQuestionMoveDown(ospQualificationQuestionId));
    }),
    onMoveUp: R.curry(event => {
      dispatch(ospDefAction.ospQualificationQuestionMoveUp(ospQualificationQuestionId));
    }),
  };
};

export const QualificationQuestion = ({
  ospQualificationQuestionId,
  ospQualificationQuestion,
  ospSectionIds,
  rootQualificationQuestionIds,
  ospQualificationQuestions,

  onDelete,
  onSetInputValue,
  onAddSubQualificationQuestion,
  onMoveDown,
  onMoveUp,

  isEditing,
  onEditDone,
  onEditStart,
  canEditOspDef,
  ...props
}) => {
  if (!ospQualificationQuestion) return null;
  const errors = {};
  const subQuestionIds = ospQualificationQuestion.sub_questions;
  return (
    <div className="panel panel-info">
      <div className="panel-heading">
        <div className="panel-title">
          {ospQualificationQuestion.question}
          <div className="pull-right">
            <DropdownButton
              id="OspDefEditor-OspQualificationQuestionActionsButton"
              title={<Glyphicon glyph="cog" />}
              bsSize="xs"
              pullRight
            >
              <MenuItem header>Sub-Question</MenuItem>
              <MenuItem onClick={onAddSubQualificationQuestion} disabled={!canEditOspDef}>
                <Glyphicon glyph="plus" /> Add Sub-Question
              </MenuItem>
              <MenuItem divider />
              <MenuItem header>Root Question</MenuItem>
              <MenuItem onClick={onMoveUp} disabled={!canEditOspDef}>
                <Glyphicon glyph="arrow-up" /> Move Up
              </MenuItem>
              <MenuItem onClick={onMoveDown} disabled={!canEditOspDef}>
                <Glyphicon glyph="arrow-down" /> Move Down
              </MenuItem>
              <MenuItem divider />
              <MenuItem onClick={onEditStart}>
                <Glyphicon glyph="pencil" /> Edit Root Question
              </MenuItem>
              <MenuItem onClick={onDelete} disabled={!canEditOspDef}>
                <Glyphicon glyph="remove" /> Delete Root Question
              </MenuItem>
            </DropdownButton>
          </div>
        </div>
      </div>
      <FormPanel.Body>
        {subQuestionIds &&
          subQuestionIds.map(id =>
            <SubQualificationQuestion
              key={id}
              ospQualificationQuestionId={id}
              rootOspQualificationQuestionId={ospQualificationQuestionId}
              rootQualificationQuestionIds={rootQualificationQuestionIds}
              ospQualificationQuestions={ospQualificationQuestions}
              ospSectionIds={ospSectionIds}
            />,
          )}
      </FormPanel.Body>
      <FormModal
        show={isEditing}
        title="Edit Root Question"
        onHide={onEditDone}
        onSubmit={onEditDone}
      >
        <FormGroup fieldName="name" error={errors} label="Root Question" required>
          <input
            type="text"
            id="question"
            className="form-control"
            onChange={onSetInputValue('question')}
            value={ospQualificationQuestion.question}
            required
            disabled={!canEditOspDef}
          />
        </FormGroup>

        <Worksheets
          ospQualificationQuestion={ospQualificationQuestion}
          ospSectionIds={ospSectionIds}
        />
      </FormModal>
    </div>
  );
};

export default R.compose(connect(makeMapStateToProps, mapDispatchToProps), hocEditable)(
  QualificationQuestion,
);
