import React from 'react';

import SpecialistDashboardTemplateContainer from 'sow/containers/SpecialistDashboardTemplateContainer';
import SpecialistDashboardFiltersContainer from 'sow/containers/SpecialistDashboardFiltersContainer';
import SpecialistDashboardSearchContainer from 'sow/containers/SpecialistDashboardSearchContainer';
import SpecialistDashboardTableContainer from 'sow/containers/SpecialistDashboardTableContainer';
import Block from 'sow/components/atoms/Block';
import Spacer from 'sow/components/atoms/Spacer';

const SpecialistDashboardPage = () => (
  <SpecialistDashboardTemplateContainer>
    <Block className="specialist-dashboard">
      <Spacer vertical={20} />
      <SpecialistDashboardFiltersContainer />
      <Spacer vertical={20} />
      <SpecialistDashboardSearchContainer />
      <SpecialistDashboardTableContainer />
    </Block>
  </SpecialistDashboardTemplateContainer>
);

export default SpecialistDashboardPage;
