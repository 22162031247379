import $ from 'jquery';
import * as R from 'ramda';
import { sendFile } from 'sow/utils/api';

export function uploadPictureAsync(file, progressCb) {
  let data = new FormData();
  for (let property in file) {
    data.append(property, file[property]);
  }
  data.append('picture', file.file);

  return sendFile({
    method: 'POST',
    url: file.uploadUrl,
    data: data,
    xhr: () => {
      let xhr = $.ajaxSettings.xhr();

      xhr.upload.addEventListener(
        'progress',
        e => {
          if (e.lengthComputable) {
            const progress = parseInt((e.loaded / e.total) * 100);

            progressCb(file.guid, progress);
          }
        },
        false,
      );

      return xhr;
    },
  });
}

const fileFields = ['file', 'public', 'name', 'category'];
export function uploadFileAsync(file, progressCb, signedUrl) {
  console.log('file', file);
  let data = null;
  
  if (!signedUrl) {
    data = new FormData();

    for (let property in file) {
      if (R.contains(property, fileFields)) {
        // const { uploadUrl, ...data } = item;
        // uploadFileAsync(data, onFileUploadProgress)
        data.append(property, file[property]);
      }
    }
    data.append('file', file.file);
  }

  return sendFile({
    method: signedUrl ? 'PUT' : 'POST',
    url: signedUrl || file.uploadUrl,
    data: signedUrl ? file.file : data, // add file directly to body uploading directly to s3 using signed url
    xhr: () => {
      let xhr = $.ajaxSettings.xhr();

      xhr.upload.addEventListener(
        'progress',
        e => {
          if (e.lengthComputable) {
            const progress = parseInt((e.loaded / e.total) * 100);

            progressCb(file.guid, progress);
          }
        },
        false,
      );

      return xhr;
    },
  });
}
