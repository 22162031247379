import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import InspectionYearEditContainer from 'sow/containers/InspectionYearEditContainer';

const InspectionYearEditPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <InspectionYearEditContainer />}
  </AdminPageTemplate>
);

export default InspectionYearEditPage;
