import React from 'react';

import { systemAdminObjectRoute, systemAdminItemRoute } from 'sow/routes';

import Block from 'sow/components/atoms/Block';
import Nav from 'sow/components/atoms/Nav';
import NavLink from 'sow/components/molecules/NavLink';

const SystemAdminTemplate = ({ children }) => (
  <Block>
    <Nav tabs>
      <NavLink to={systemAdminObjectRoute()}>System Objects</NavLink>
      <NavLink to={systemAdminItemRoute()}>Item Objects</NavLink>
    </Nav>
    {children}
  </Block>
);

export default SystemAdminTemplate;
