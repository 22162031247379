import React from 'react';

import { loginRoute } from 'sow/routes';
import CenteredLayout from 'sow/components/templates/CenteredLayout';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Spacer from 'sow/components/atoms/Spacer';
import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';
import Paragraph from 'sow/components/atoms/Paragraph';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';

const RegisterDonePage = () => (
  <CenteredLayout>
    <Row>
      <Column md={8} mdOffset={2} textLeft>
        <Widget>
          <WidgetHeading>Registration Success</WidgetHeading>
          <WidgetBody>
            <Paragraph>
              The registration was successful. Please notify the client or inspector,
              provide their temporary password and prompt them to change their password in
              their profile.
            </Paragraph>

            <Spacer vertical={20} />

            <Block textCenter>
              <Link to="/">Return to the home page</Link>
            </Block>
          </WidgetBody>
        </Widget>
      </Column>
    </Row>
  </CenteredLayout>
);

export default RegisterDonePage;
