import React from 'react';
import PropTypes from 'prop-types';

import { orgIdType, planAppIdType, planAppSummaryType, planAppType } from 'sow/types';
import { checklistRoute } from 'sow/routes';
import {
  isProgressNotStarted,
  isProgressComplete,
  isStateClosed,
  isStateLocked,
  isProgressStarted,
  isStateOpen,
  isStateInitialApplication,
  isStateRevision,
  isStateSubmitted,
  isStateWithdrawn,
  isUnderReview,
} from 'sow/utils/planAppState';

import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import Link from 'sow/components/atoms/Link';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import Tooltip from 'sow/components/molecules/Tooltip';
import Confirm from 'sow/components/molecules/Confirm';
import OrgWithdrawModal from 'sow/components/organisms/OrgWithdrawModal';

const AppActionButtons = ({
  orgId,
  planAppId,
  planAppSummary,
  planApp,
  disabled,
  showAcaUI,
  showInspectorUI,
  hasOpenPlanApp,
  isPlanAppIdMostRecent,
  changeRequestCanBeApplied,

  // Button events
  onSubmitInitial,
  onSubmitUpdate,
  onComplete,
  onMarkNoncompliant,
  onUnlockForUpdate,
  onWithdrawPlan,
  onUnwithdrawPlan,
}) => {
  const showStartButton =
    isStateInitialApplication(planAppSummary) && isProgressNotStarted(planApp);

  const showContinueButton =
    isStateInitialApplication(planAppSummary) && isProgressStarted(planApp);

  const showUnlockForUpdateButton =
    !hasOpenPlanApp &&
    isPlanAppIdMostRecent &&
    isStateClosed(planAppSummary) &&
    !isStateWithdrawn(planAppSummary);

  const showReviewButton = isStateLocked(planAppSummary);

  const showSubmitInitialApplicationButton =
    isStateInitialApplication(planAppSummary) && isProgressComplete(planApp);
  const showSubmitUpdateButton = isStateRevision(planAppSummary);

  const showWithdrawButton = isPlanAppIdMostRecent && isStateOpen(planAppSummary);

  const showMarkNoncompliantButton =
    (showAcaUI || showInspectorUI) &&
    isPlanAppIdMostRecent &&
    (isStateSubmitted(planAppSummary) || isUnderReview(planAppSummary));

  const showMarkCompleteButton =
    (showAcaUI || showInspectorUI) && isUnderReview(planAppSummary);
  const disableMarkCompleteButton = disabled || !changeRequestCanBeApplied;

  const showUnwithdrawButton =
    showAcaUI && isPlanAppIdMostRecent && isStateWithdrawn(planAppSummary);

  const showSeparator = showMarkNoncompliantButton || showMarkCompleteButton;

  const showReviewOrUpdateText = isStateOpen(planAppSummary);

  return (
    <Block>
      {showStartButton && (
        <Link btn success block to={checklistRoute(orgId, planAppId)} disabled={disabled}>
          Start Plan Application
        </Link>
      )}

      {showContinueButton && (
        <Link btn primary block to={checklistRoute(orgId, planAppId)} disabled={disabled}>
          Continue Plan Application
        </Link>
      )}

      {showReviewButton && (
        <Link btn primary block to={checklistRoute(orgId, planAppId)} disabled={disabled}>
          {showReviewOrUpdateText ? 'Review or Update Plan' : 'Review Plan'}
        </Link>
      )}

      {showUnlockForUpdateButton && (
        <Button success block onClick={onUnlockForUpdate} disabled={disabled}>
          Update Plan
        </Button>
      )}

      {showSubmitInitialApplicationButton && (
        <Confirm
          message="Submit your Plan application for review by your Certifier?"
          onConfirm={onSubmitInitial}
        >
          {toggleConfirm => (
            <Button warning block onClick={toggleConfirm} disabled={disabled}>
              Submit Plan for Review
            </Button>
          )}
        </Confirm>
      )}

      {showSubmitUpdateButton && (
        <Confirm
          message="Submit your Plan updates for review by your Certifier?"
          onConfirm={onSubmitUpdate}
        >
          {toggleConfirm => (
            <Button warning block onClick={toggleConfirm} disabled={disabled}>
              Submit Plan Update for Review
            </Button>
          )}
        </Confirm>
      )}

      {showWithdrawButton &&
        (showAcaUI ? (
          <Confirm
            message="This plan will be marked as Withdrawn. The organization will be shown a message to contact their certifier if they wish to unlock and update the plan for resubmission."
            onConfirm={onWithdrawPlan}
          >
            {toggleConfirm => (
              <Button danger block onClick={toggleConfirm} disabled={disabled}>
                Withdraw Plan
              </Button>
            )}
          </Confirm>
        ) : (
          // If not ACA, show modal with instructions to contact certifier to withdraw
          <OrgWithdrawModal>
            {toggleModal => (
              <Button
                link
                danger
                block
                style={{ color: 'red', padding: '10px' }}
                onClick={toggleModal}
                disabled={disabled}
              >
                Need to withdraw this plan?
              </Button>
            )}
          </OrgWithdrawModal>
        ))}

      {showUnwithdrawButton && (
        <Button warning block onClick={onUnwithdrawPlan} disabled={disabled}>
          Unwithdraw Plan
        </Button>
      )}

      {showSeparator && (
        <HorizontalRule style={{ marginTop: '5px', marginBottom: '5px' }} />
      )}

      {showMarkCompleteButton && (
        <Confirm
          onConfirm={onComplete}
          message="All changes marked accepted will be applied to this application and overwrite their corresponding old answer, and the application will be marked complete. If there are any changes that have not been accepted or discarded, a new plan will be created with those pending changes."
        >
          {toggleConfirm => {
            const button = (
              <Button
                success
                block
                onClick={disableMarkCompleteButton ? () => {} : toggleConfirm}
                className={disableMarkCompleteButton ? 'disabled' : null}
              >
                Mark Plan Complete
              </Button>
            );

            if (disableMarkCompleteButton) {
              return (
                <Tooltip overlay="Before a plan can be completed, a change request must exist and the plan must be in the Final Review state.">
                  <Block>{button}</Block>
                </Tooltip>
              );
            }
            return button;
          }}
        </Confirm>
      )}

      {showMarkNoncompliantButton && (
        <Confirm
          message="This plan will be marked as Non-compliance. The organization will have the option to unlock the plan for update and make changes to bring the plan into compliance."
          onConfirm={onMarkNoncompliant}
        >
          {toggleConfirm => (
            <Button danger block onClick={toggleConfirm} disabled={disabled}>
              Mark Plan Noncompliant
            </Button>
          )}
        </Confirm>
      )}
    </Block>
  );
};

AppActionButtons.propTypes = {
  orgId: orgIdType.isRequired,
  planAppId: planAppIdType.isRequired,
  planAppSummary: planAppSummaryType.isRequired,
  changeRequestCanBeApplied: PropTypes.bool.isRequired,

  // only loaded if state is open
  planApp: planAppType,
  hasOpenPlanApp: PropTypes.bool.isRequired,
  isPlanAppIdMostRecent: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  showAcaUI: PropTypes.bool,
  showInspectorUI: PropTypes.bool,

  // Button actions
  onSubmitInitial: PropTypes.func.isRequired,
  onSubmitUpdate: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onMarkNoncompliant: PropTypes.func.isRequired,
  onUnlockForUpdate: PropTypes.func.isRequired,
  onWithdrawPlan: PropTypes.func.isRequired,
  onUnwithdrawPlan: PropTypes.func.isRequired,
};

AppActionButtons.defaultProps = {
  disabled: false,
  planApp: null,
  showAcaUI: false,
  showInspectorUI: false,
};

export default AppActionButtons;
