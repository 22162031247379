import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Atom from 'sow/components/atoms/Atom';

const Glyphicon = ({ glyph, className, ...props }) => {
  const classes = classnames('glyphicon', `glyphicon-${glyph}`, className);

  return <Atom className={classes} {...props} />;
};

Glyphicon.propTypes = {
  glyph: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Glyphicon;
