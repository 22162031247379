import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as userManagementActions from 'sow/actions/pure/userManagement';

import UserManagementUserHistory from 'sow/components/organisms/UserManagementUserHistory';

const mapStateToProps = state => ({ userHistory: state.userManagement.history });

const mapDispatchToProps = {
  loadUserHistory: userManagementActions.loadUserHistory,
};

class UserManagementUserHistoryContainer extends Component {
  componentDidMount() {
    this.props.loadUserHistory(this.props.match.params.userId);
  }

  render() {
    return <UserManagementUserHistory {...this.props} />;
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(UserManagementUserHistoryContainer);
