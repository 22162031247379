import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import actions from 'sow/actions/pure';
import { fromPlanApp } from 'sow/store/selectors';
import { resourceUpdate } from 'sow/store/helpers';
import OrgDashboardAppFields from 'sow/components/organisms/OrgDashboardAppFields';
import { updateOspApplicationAdditionalInformation } from 'sow/actions/ospApplication';
import * as currentUser from 'sow/selectors/currentUser';

const getUpdateBasicInfo = (orgId, planAppId) =>
  resourceUpdate(`org/${orgId}/application/${planAppId}/tag`, 'planAppResp');

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  isWorkflowManager: currentUser.isWorkflowManager(state, props),
  isCertificationSpecialist: currentUser.isCertificationSpecialist(state, props),
});

const mapDispatchToProps = dispatch => ({
  refreshPlan: (orgId, planAppId, apiData) => {
    const { action } = getUpdateBasicInfo(orgId, planAppId);
    return dispatch(action(null, { ...apiData }));
  },
  updateOspApplicationAdditionalInformation: (orgId, planAppId, apiData) =>
    dispatch(updateOspApplicationAdditionalInformation(orgId, planAppId, apiData)),
  expeditedAdded: () =>
    dispatch(actions.shell.toast('success', 'Plan marked as expedited.')),
  expeditedRemoved: () =>
    dispatch(actions.shell.toast('success', 'Expedited tag removed.')),
});

class OrgDashboardPaidExpeditedContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      noncompliant: false,
      is_expedited: false,
      is_in_mediation: false,
      is_under_investigation: false,
      tagChangePending: false,
    };
  }

  componentDidMount() {
    const { planApp } = this.props;
    this.setState({
      noncompliant: planApp.noncompliant,
      is_expedited: planApp.isExpedited,
      is_in_mediation: planApp.isInMediation ? true : false,
      is_under_investigation: planApp.isUnderInvestigation ? true : false,
    });
  }

  saveTag = (tagName, tagState) => {
    const {
      planAppId,
      orgId,
      refreshPlan,
      expeditedAdded,
      expeditedRemoved,
    } = this.props;
    const apiData = {
      osp_application: {
        [tagName]: tagState,
      },
    };

    this.setState({ tagChangePending: true });

    refreshPlan(orgId, planAppId, apiData).then(() =>
      this.setState({ tagChangePending: false }),
    );

    if (tagName === 'is_expedited') {
      if (tagState) expeditedAdded();
      else expeditedRemoved();
    }
  };

  toggleTag = ({ target }) => {
    const tag = !this.state[target.name];
    this.setState({ [target.name]: tag });
    this.saveTag(target.name, tag);
  };

  render() {
    const {
      is_expedited,
      is_in_mediation,
      is_under_investigation,
      noncompliant,
      tagChangePending,
    } = this.state;

    return (
      <OrgDashboardAppFields
        {...this.props}
        inMediation={is_in_mediation}
        underInvestigation={is_under_investigation}
        expedited={is_expedited}
        noncompliant={noncompliant}
        tagChangePending={tagChangePending}
        toggleTag={this.toggleTag}
      />
    );
  }
}

OrgDashboardPaidExpeditedContainer.propTypes = {
  // Passed props
  planAppId: sowTypes.planAppIdType.isRequired,
  planApp: sowTypes.planAppType.isRequired,

  // Connected props
  orgId: sowTypes.orgIdType.isRequired,
  updateOspApplicationAdditionalInformation: PropTypes.func,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrgDashboardPaidExpeditedContainer);
