import { combineReducers } from 'redux';

import byId, * as fromById from './byId';
import isLoadingById, * as fromIsLoadingById from './isLoadingById';
import listByOrgId, * as fromListByOrgId from './listByOrgId';

export default combineReducers({
  byId,
  isLoadingById,
  listByOrgId,
});

export const getCurrentOspAppId = (state, orgId) =>
  fromListByOrgId.getCurrentOspAppId(state.listByOrgId, orgId);

export const getOspApp = (state, id) => fromById.getOspApp(state.byId, id);

export const getOspAppIdsForOrg = (state, orgId) =>
  fromListByOrgId.getIds(state.listByOrgId, orgId) || [];

export const getOspAppListForOrg = (state, orgId) =>
  fromListByOrgId.getOspAppList(state.listByOrgId, orgId) || [];

export const getOspAppListMapForOrg = (state, orgId) =>
  fromListByOrgId.getOspAppListMap(state.listByOrgId, orgId);

export const getIsLoadedForOrg = (state, orgId) =>
  fromListByOrgId.getIsLoaded(state.listByOrgId, orgId);

export const getIsLoadingForOrg = (state, orgId) =>
  fromListByOrgId.getIsLoading(state.listByOrgId, orgId);

export const getErrorMessageForOrg = (state, orgId) =>
  fromListByOrgId.getErrorMessage(state.listByOrgId, orgId);

export const getIsLoadingForOspApp = (state, ospAppId) =>
  fromIsLoadingById.getIsLoadingById(state.isLoadingById, ospAppId);
