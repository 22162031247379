const mark = action => `PRIORITY_TYPE/${action}`;

export const LOAD_PRIORITY_TYPES = mark('LOAD_PRIORITY_TYPES');
export const FETCH_BY_ID = mark('FETCH_BY_ID');
export const ADD_PRIORITY_TYPE = mark('ADD_PRIORITY_TYPE');
export const UPDATE_PRIORITY_TYPE = mark('UPDATE_PRIORITY_TYPE');
export const UPDATE_SORT_ORDER = mark('UPDATE_SORT_ORDER');
export const DELETE_PRIORITY_TYPE = mark('DELETE_PRIORITY_TYPE');

export const loadPriorityTypes = (payload, onSuccess) => ({
  type: LOAD_PRIORITY_TYPES,
  payload,
  meta: { onSuccess },
});

export const fetchById = (payload, onSuccess) => ({
  type: FETCH_BY_ID,
  payload,
  meta: { onSuccess },
});

export const addPriorityType = (payload, onSuccess) => ({
  type: ADD_PRIORITY_TYPE,
  payload,
  meta: { onSuccess },
});

export const updatePriorityType = (id, payload, onSuccess) => ({
  type: UPDATE_PRIORITY_TYPE,
  id,
  payload,
  meta: { onSuccess },
});

export const updateSortOrder = (payload, onSuccess) => ({
  type: UPDATE_SORT_ORDER,
  payload,
  meta: { onSuccess },
});

export const deletePriorityType = (id, onSuccess) => ({
  type: DELETE_PRIORITY_TYPE,
  id,
  meta: { onSuccess },
});
