import React from 'react';
import PropTypes from 'prop-types';

import { orgType } from 'sow/types';
import LineBreak from 'sow/components/atoms/LineBreak';
import Heading from 'sow/components/atoms/Heading';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import SearchField from 'sow/components/organisms/SearchField';
import OrgList from 'sow/components/organisms/OrgListVirt';

const AcaOrgListPage = ({ orgList, searchText, onSearchTextChange }) => (
  <Widget>
    <WidgetHeading>Organizations</WidgetHeading>
    <WidgetBody>
      <SearchField
        value={searchText}
        placeholder="Organization Name"
        onChange={onSearchTextChange}
      />

      <LineBreak />

      {orgList ? (
        <OrgList orgList={orgList} />
      ) : (
        <Heading level={4}>No organizations.</Heading>
      )}
    </WidgetBody>
  </Widget>
);

AcaOrgListPage.propTypes = {
  orgList: PropTypes.arrayOf(orgType).isRequired,
  searchText: PropTypes.string,
  onSearchTextChange: PropTypes.func.isRequired,
};

export default AcaOrgListPage;
