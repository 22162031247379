import {
  LOAD_ONE_FOR_ACA_REQUEST,
  LOAD_ONE_FOR_ACA_SUCCESS,
  LOAD_ONE_FOR_ACA_FAILURE,
} from 'sow/actions/ospDefinition';

const isLoading = (state = false, action) => {
  switch (action.type) {
    case LOAD_ONE_FOR_ACA_REQUEST:
      return true;
    case LOAD_ONE_FOR_ACA_FAILURE:
    case LOAD_ONE_FOR_ACA_SUCCESS:
      return false;
    default:
      return state;
  }
};

export default isLoading;
