import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { locationRoute, legacyLocationsRoute } from 'sow/routes';

import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';
import Spacer from 'sow/components/atoms/Spacer';
import Glyphicon from 'sow/components/molecules/Glyphicon';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import PlanAppLocationDeleteButton from 'sow/containers/planApp/LocationDeleteButton';
import PlanAppLocationCard from 'sow/containers/planApp/LocationCard';

const PlanAppLocationList = ({ orgId, planAppId, locationIdList }) => (
  <Block>
    {locationIdList.map(locationId => (
      <Fragment key={locationId}>
        <PlanAppLocationCard locationId={locationId}>
          <Link to={locationRoute(orgId, planAppId, locationId)} btn primary sm>
            <Glyphicon glyph="eye-open" /> View
          </Link>
          <PlanAppLocationDeleteButton locationId={locationId} />
        </PlanAppLocationCard>
        <Spacer vertical={10} />
      </Fragment>
    ))}
    {locationIdList.length === 0 && (
      <Widget>
        <WidgetBody>
          <Block textCenter textMuted>
            No locations have been added to this plan.
          </Block>
        </WidgetBody>
      </Widget>
    )}
    <Block>
      {`Don't see a location you expected? `}
      <Link to={legacyLocationsRoute(orgId)}>Check your legacy locations.</Link>
    </Block>
  </Block>
);

PlanAppLocationList.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  locationIdList: PropTypes.arrayOf(sowTypes.planAppLocationIdType).isRequired,
};

export default PlanAppLocationList;
