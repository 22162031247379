import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import LocationReportTable from 'sow/components/organisms/LocationReport/LocationReportTable';

const mapStateToProps = (state, props) => ({
  searchInitiated: state.locationReport.searchInitiated,
  fetchingList: state.locationReport.isFetching,
  locationReport: state.locationReport.list,
});

class LocationReportTableContainer extends Component {
  render() {
    if (this.props.searchInitiated) return <LocationReportTable {...this.props} />;
    return null;
  }
}

LocationReportTableContainer.propTypes = {
  searchInitiated: PropTypes.bool,
  fetchingList: PropTypes.bool,
  locationReport: PropTypes.array,
};

LocationReportTableContainer.defaultProps = {
  searchInitiated: false,
  locationReport: [],
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, null),
)(LocationReportTableContainer);
