import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { loadAllForOrg } from 'sow/actions/pure/certificationDocs';
import Spinner from 'sow/components/atoms/Spinner';
import CertificationDocsPage from 'sow/components/pages/CertificationDocsPage';
import OrgPageTemplate from 'sow/containers/OrgPageTemplate';

const mapStateToProps = state => ({
  isLoading: state.certificationDocs.isLoading,
  docs: state.certificationDocs.items,
});

const mapDispatchToProps = { loadAllForOrg };

class OrgCertificationDocsPageContainer extends Component {
  componentDidMount() {
    const { loadAllForOrg, match } = this.props;
    const orgId = R.path(['params', 'orgId'], match);
    loadAllForOrg(orgId);
  }

  render() {
    const { isLoading, docs } = this.props;

    return (
      <OrgPageTemplate title="Certification Docs">
        {isLoading ? <Spinner /> : <CertificationDocsPage docs={docs} canEdit={false} />}
      </OrgPageTemplate>
    );
  }
}

OrgCertificationDocsPageContainer.propTypes = {
  loadAllForOrg: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  docs: PropTypes.array,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(OrgCertificationDocsPageContainer);
