import actions from 'sow/actions/pure';

const initialState = {
  isUpdating: false,
  updateErrors: null,
};

function profileReducer(state = initialState, action) {
  switch (action.type) {
    case String(actions.profile.updateUser):
      return { ...state, isUpdating: true, updateErrors: null };

    case String(actions.profile.updateUserDone):
      return { ...state, isUpdating: false };

    case String(actions.profile.updateUserFail):
      return {
        ...state,
        isUpdating: false,
        updateErrors: action.payload.errors,
      };

    default:
      return state;
  }
}

export const getProfileState = state => state.profile;

export default profileReducer;
