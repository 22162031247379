import React from 'react';

import {
  locationOverviewRoute,
  checklistRoute,
  worksheetOverviewRoute,
  worksheetRoute,
} from 'sow/routes';
import {
  orgIdType,
  ospAppIdType as planAppIdType,
  planAppWorksheetIdType,
} from 'sow/types';

import Nav from 'sow/components/atoms/Nav';
import StepperStep from 'sow/containers/planApp/StepperStep';
import StepperNextStep from 'sow/components/organisms/PlanAppStepperNextStep';

const PlanAppStepper = ({ orgId, planAppId, worksheetId }) => (
  <Nav pills justified className="osp-app-wizard-nav">
    <StepperStep
      heading="Step 1"
      text="Activities Checklist"
      to={checklistRoute(orgId, planAppId)}
    />

    <StepperNextStep />

    <StepperStep
      heading="Step 2"
      text="Worksheets"
      to={worksheetOverviewRoute(orgId, planAppId)}
      activeOn={[
        worksheetOverviewRoute(orgId, planAppId),
        worksheetRoute(orgId, planAppId, worksheetId),
      ]}
    />

    <StepperNextStep />

    <StepperStep
      heading="Step 3"
      text="Locations"
      to={locationOverviewRoute(orgId, planAppId)}
    />
  </Nav>
);

PlanAppStepper.defaultProps = {
  worksheetId: '',
};

PlanAppStepper.propTypes = {
  orgId: orgIdType.isRequired,
  planAppId: planAppIdType.isRequired,
  worksheetId: planAppWorksheetIdType,
};

export default PlanAppStepper;
