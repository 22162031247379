import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import PlanAppChangeCommentSection from 'sow/components/organisms/PlanAppChangeCommentSection';
import { PlanAppChangeCommentLoader } from 'sow/store/containers';
import { fromPlanApp } from 'sow/store/selectors';
import { planAppChangeCommentListType, planAppChangeIdType } from 'sow/types';

const mapStateToProps = (state, props) => ({
  changeId: fromPlanApp.changeId(state, props),
  changeCommentList: fromPlanApp.changeCommentList(state, props),
  changeRequestIsOpen: fromPlanApp.changeRequestIsOpen(state, props),
});

const PlanAppChangeCommentsContainer = ({
  changeId,
  changeCommentList,
  changeRequestIsOpen,
}) => (
  <PlanAppChangeCommentLoader changeId={changeId}>
    {({ createResource: createComment, status: createCommentStatus }) => (
      <PlanAppChangeCommentSection
        changeCommentList={changeCommentList}
        onAddComment={(commentText, postSubmitCb) => {
          // prevent submitting same comment multiple times during network req
          if (createCommentStatus.loading) return;
          createComment({ changeComment: { text: commentText } }).then(postSubmitCb);
        }}
        addCommentDisabled={createCommentStatus.status}
        changeRequestIsOpen={changeRequestIsOpen}
      />
    )}
  </PlanAppChangeCommentLoader>
);

PlanAppChangeCommentsContainer.propTypes = {
  changeId: planAppChangeIdType,
  changeCommentList: planAppChangeCommentListType,
  changeRequestIsOpen: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppChangeCommentsContainer);
