const mark = action => `ACTION_GROUP/${action}`;

export const LOAD_ACTION_GROUPS = mark('LOAD_ACTION_GROUPS');
export const LOAD_ACTION_GROUPS_DONE = mark('LOAD_ACTION_GROUPS_DONE');
export const LOAD_ACTION_GROUP_BY_ID = mark('LOAD_ACTION_GROUP_BY_ID');
export const ADD_ACTION_GROUP = mark('ADD_ACTION_GROUP');
export const DELETE_ACTION_GROUP = mark('DELETE_ACTION_GROUP');
export const UPDATE_ACTION_GROUP = mark('UPDATE_ACTION_GROUP');

export const loadActionGroups = (payload, onSuccess) => ({
  type: LOAD_ACTION_GROUPS,
  payload,
  meta: { onSuccess },
});

export const loadActionGroupsDone = payload => ({
  type: LOAD_ACTION_GROUPS_DONE,
  payload,
});

export const loadActionGroupById = (id, onSuccess) => ({
  type: LOAD_ACTION_GROUP_BY_ID,
  id,
  meta: { onSuccess },
});

export const addActionGroup = (payload, onSuccess) => ({
  type: ADD_ACTION_GROUP,
  payload,
  meta: { onSuccess },
});

export const updateActionGroup = (id, payload, onSuccess) => ({
  type: UPDATE_ACTION_GROUP,
  id,
  payload,
  meta: { onSuccess },
});

export const deleteActionGroup = (id, onSuccess) => ({
  type: DELETE_ACTION_GROUP,
  id,
  meta: { onSuccess },
});
