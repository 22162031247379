import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/** Bootstrap container class */
const Container = ({ fluid, children, className, ...props }) => {
  const containerClass = fluid ? 'container-fluid' : 'container';

  return (
    <div className={classnames(containerClass, className)} {...props}>
      {children}
    </div>
  );
};

Container.defaultProps = {
  fluid: false,
};

Container.propTypes = {
  children: PropTypes.node,

  /** Adds `container-fluid` class */
  fluid: PropTypes.bool,

  /** Additional CSS classes to be passed through */
  className: PropTypes.string,
};

export default Container;
