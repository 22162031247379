import React, { Fragment } from 'react';
import * as R from 'ramda';

import * as sowTypes from 'sow/types';
import { PLAN_APP_STATES_STATE_EDITABLE } from 'sow/constants/planApp';
import Block from 'sow/components/atoms/Block';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import Button from 'sow/components/atoms/Button';
import Heading from 'sow/components/atoms/Heading';
import PlanAppEditStateModal from 'sow/containers/planApp/EditStateModal';
import OrgDashboardActionButtonsAca from 'sow/containers/planApp/OrgDashboardActionButtonsAca';
import OrgDashboardPaidExpedited from 'sow/containers/planApp/OrgDashboardPaidExpedited';
import { isWorkflowManager, isCertificationSpecialist } from 'sow/selectors/currentUser';
import Spacer from 'sow/components/atoms/Spacer';
import Strong from 'sow/components/atoms/Strong';

const OrgDashboardAcaOptions = ({
  orgId,
  planAppId,
  planApp,
  inspectorAccessList,
  orgName,
}) => {
  const stateCanBeUpdated = R.contains(
    R.__,
    PLAN_APP_STATES_STATE_EDITABLE,
  )(planApp.state);

  return (
    <Block>
      <Spacer vertical={30} />
      <Block>
        <Heading level={4}>
          <Strong>Certifier Options</Strong>
        </Heading>
      </Block>
      <HorizontalRule className="sidebar-button-divider" />

      {stateCanBeUpdated && (
        <Fragment>
          <PlanAppEditStateModal
            orgId={orgId}
            planAppId={planAppId}
            appState={planApp.state}
            finalReview={planApp.movedToFinalReview}
            inspectorAccessList={inspectorAccessList}
            orgName={orgName}
          >
            {showModal => (
              <Button block onClick={showModal} className="state-button">
                Update Plan State
              </Button>
            )}
          </PlanAppEditStateModal>
          <HorizontalRule className="sidebar-button-divider" />
        </Fragment>
      )}

      <OrgDashboardActionButtonsAca planAppId={planAppId} planApp={planApp} />
      {(isCertificationSpecialist || isWorkflowManager) && (
        <OrgDashboardPaidExpedited planAppId={planAppId} planApp={planApp} />
      )}
    </Block>
  );
};

OrgDashboardAcaOptions.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  planApp: sowTypes.planAppType.isRequired,
};

export default OrgDashboardAcaOptions;
