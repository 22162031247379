import { API_URL } from 'sow/constants/api';
import { apiRequest } from 'sow/utils/api';

export const baseUrl = `${API_URL}/v1/org`;
export const getBaseUrlForOrg = orgId => `${API_URL}/v1/org/${orgId}`;

export const fetchAll = () =>
  apiRequest({
    type: 'GET',
    url: baseUrl,
  });

export const fetchOne = orgId =>
  apiRequest({
    type: 'GET',
    url: `${baseUrl}/${orgId}`,
  });
