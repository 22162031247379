import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { planAppChangeCommentIdType, planAppChangeCommentType } from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import * as currentUser from 'sow/selectors/currentUser';
import PlanAppChangeComment from 'sow/components/organisms/PlanAppChangeComment';

const mapStateToProps = (state, props) => {
  const notifyAca = fromPlanApp.changeCommentNotifyAca(state, props);
  const notifyOrg = fromPlanApp.changeCommentNotifyOrg(state, props);
  const showAcaUI = currentUser.showAcaUI(state, props);

  return {
    changeComment: fromPlanApp.changeComment(state, props),
    changeCommentId: fromPlanApp.changeCommentId(state, props),
    notifyUser: showAcaUI ? notifyAca : notifyOrg,
  };
};

const PlanAppChangeCommentContainer = ({ changeComment, notifyUser }) =>
  !!changeComment && (
    <PlanAppChangeComment changeComment={changeComment} notifyUser={notifyUser} />
  );

PlanAppChangeCommentContainer.propTypes = {
  changeCommentId: planAppChangeCommentIdType,
  changeComment: planAppChangeCommentType,
  notifyUser: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppChangeCommentContainer);
