import React from 'react';
import PropTypes from 'prop-types';

import { acaIdType, orgIdType } from 'sow/types';
import { ResourceListLoader } from 'sow/store/helpers';
import AcaOrgSelect from 'sow/components/organisms/AcaOrgSelect';

const AcaOrgSelectContainer = ({ acaId, ...props }) => {
  if (!acaId) {
    return <AcaOrgSelect {...props} orgList={[]} disabled />;
  }

  return (
    <ResourceListLoader resource={`admin/orgs_by_aca/${acaId}`} autoLoad>
      {({ status, result }) => (
        <AcaOrgSelect {...props} disabled={!status.done} orgList={result || []} />
      )}
    </ResourceListLoader>
  );
};

AcaOrgSelectContainer.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  // May be form inputs, which are strings
  acaId: PropTypes.oneOfType([acaIdType, PropTypes.string]).isRequired,
  value: PropTypes.oneOfType([orgIdType, PropTypes.string]).isRequired,
};

AcaOrgSelectContainer.defaultProps = {
  required: false,
};

export default AcaOrgSelectContainer;
