import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';

import { systemAdminItemRoute, itemVersionInfoEditRoute } from 'sow/routes';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormikField from 'sow/components/organisms/FormikField';
import Button from 'sow/components/atoms/Button';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Spinner from 'sow/components/atoms/Spinner';
import DragDropListContainer from 'sow/containers/DragDropListContainer';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Link from 'sow/components/atoms/Link';

const ItemVersionList = ({ loadingList, itemVersionList }) => (
  <Widget className="system-admin-list-edit">
    <WidgetHeading>Item Versions</WidgetHeading>
    <WidgetBody>
      <Row className="form-row">
        <Column className="form-column" xs={12} sm={6} md={4}>
          <Row className="button-row">
            <Column className="button-column" xs={6} sm={6} md={6}>
              <Button
                className="form-button"
                to={itemVersionInfoEditRoute('new')}
                primary
              >
                <FontAwesome className="button-icon" icon="plus" />
                Add
              </Button>
            </Column>
            <Column className="button-column" xs={6} sm={6} md={6}>
              <Button
                className="form-button reset-return-button"
                to={systemAdminItemRoute()}
              >
                Back
              </Button>
            </Column>
          </Row>
        </Column>
      </Row>
      {loadingList ? (
        <Spinner />
      ) : (
        <Row className="form-row list-row">
          <Column className="form-column" xs={12} sm={12} md={12}>
            <Table
              bordered
              striped
              head={
                <TableRow>
                  <TableCell heading>Actions</TableCell>
                  <TableCell heading>Name</TableCell>
                  <TableCell heading>Version</TableCell>
                  <TableCell heading>Item</TableCell>
                  <TableCell heading>Start Date</TableCell>
                  <TableCell heading>End Date</TableCell>
                  <TableCell heading>Groups</TableCell>
                </TableRow>
              }
            >
              {itemVersionList.length > 0 ? (
                <Fragment>
                  {itemVersionList.map(itemVersion => (
                    <TableRow key={itemVersion.id}>
                      <TableCell>
                        <Link
                          className="start-end-width"
                          to={itemVersionInfoEditRoute(itemVersion.id)}
                        >
                          Edit
                        </Link>
                      </TableCell>
                      <TableCell className="wrap">{itemVersion.name}</TableCell>
                      <TableCell>{itemVersion.version}</TableCell>
                      <TableCell>{itemVersion.item}</TableCell>
                      <TableCell>
                        {itemVersion.start_date ? itemVersion.start_date : 'Not set'}
                      </TableCell>
                      <TableCell>
                        {itemVersion.end_date ? itemVersion.end_date : 'Not set'}
                      </TableCell>
                      <TableCell>{itemVersion.action_group_name}</TableCell>
                    </TableRow>
                  ))}
                </Fragment>
              ) : (
                <TableRow>
                  <TableCell colSpan={7} textCenter>
                    No item versions found.
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </Column>
        </Row>
      )}
    </WidgetBody>
  </Widget>
);

export default ItemVersionList;
