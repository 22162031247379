import * as R from 'ramda';

import * as ospDef from 'sow/actions/pure/ospDefinition';
import * as ospDefAction from 'sow/actions/ospDefinition';

import ids from './ids';

const mapReducer = action => (state = {}) =>
  R.merge(state, {
    ids: ids(state.ids, action),
  });

const mapKey = R.path(['data', 'ospDefId']);
const keyLens = R.compose(R.lensProp, mapKey);
const update = (state, action) => R.over(keyLens(action), mapReducer(action), state);

const listByOspDefId = (state = {}, action) => {
  switch (action.type) {
    case ospDefAction.LOAD_ONE_FOR_ACA_SUCCESS:
    // case ospDefAction.SAVE_ONE_FOR_ACA_SUCCESS:
    case ospDef.UPDATE_ONE_DONE:
      return update(state, action);

    default:
      return state;
  }
};

export default listByOspDefId;

export const getIds = (state, ospDefId) => R.pathOr([], [ospDefId, 'ids'], state);
