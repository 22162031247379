import React from 'react';
import * as R from 'ramda';
import { Glyphicon, Popover, OverlayTrigger, Button } from 'react-bootstrap';

import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';

export const isButtonEnabled = ({ contact_name, contact_phone, location }) => {
  if (contact_name || contact_phone) {
    return true;
  }

  if (location) {
    return location.address || location.address_text || location.country;
  }

  return false;
};

export const renderPopoverRow = (value, label) =>
  value && (
    <TableRow>
      <TableCell heading>{label}</TableCell>
      <TableCell>{value}</TableCell>
    </TableRow>
  );

export const renderPopover = contact => {
  const contactName = R.prop('contact_name', contact);
  const contactPhone = R.prop('contact_phone', contact);
  const location = R.prop('location', contact);

  const addressText = R.prop('address_text', location);
  const address = R.prop('address', location) || addressText;
  const city = R.prop('city', location);
  const state = R.prop('state', location);
  const postal = R.prop('postal', location);
  const country = R.prop('country', location);

  return (
    <Popover id="contactInfoPopover" style={{ maxWidth: 'none' }}>
      <Table condensed style={{ marginBottom: 0 }}>
        {renderPopoverRow(contactName, 'Contact name')}
        {renderPopoverRow(contactPhone, 'Contact phone')}
        {renderPopoverRow(address, 'Address')}
        {renderPopoverRow(city, 'City')}
        {renderPopoverRow(state, 'State')}
        {renderPopoverRow(postal, 'Postal')}
        {renderPopoverRow(country, 'Country')}
      </Table>
    </Popover>
  );
};

export const OrgContactInfoPopover = ({ contact }) =>
  contact && isButtonEnabled(contact) ? (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="left"
      overlay={renderPopover(contact)}
    >
      <Button bsSize="sm" style={{ margin: 0 }}>
        <Glyphicon glyph="info-sign" />
      </Button>
    </OverlayTrigger>
  ) : (
    <Button bsSize="sm" style={{ margin: 0 }} disabled>
      <Glyphicon glyph="info-sign" />
    </Button>
  );

export default OrgContactInfoPopover;
