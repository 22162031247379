import React from 'react';
import PropTypes from 'prop-types';

import {
  planAppStates as paStates,
  planAppStateText as paStateText,
} from 'sow/constants/planApp';
import Select from 'sow/components/molecules/Select';

const ActivityLogActionTypeSelector = ({ id, value, onChange }) => (
  <Select id={id} value={value} onChange={onChange} showBlankOption>
    <optgroup label="Plan State Changed">
      <option value="initial_application_created">
        {paStateText[paStates.INITIAL_APPLICATION]}
      </option>
      <option value="initial_application_submitted">
        {paStateText[paStates.INITIAL_APPLICATION_SUBMITTED]}
      </option>
      <option value="revision_created">{paStateText[paStates.REVISION]}</option>
      <option value="revision_submitted">
        {paStateText[paStates.REVISION_SUBMITTED]}
      </option>
      <option value="entered_initial_review">
        {paStateText[paStates.INITIAL_REVIEW]}
      </option>
      <option value="entered_inspection">{paStateText[paStates.INSPECTION]}</option>
      <option value="entered_final_review">{paStateText[paStates.FINAL_REVIEW]}</option>
      <option value="completed">{paStateText[paStates.COMPLETE]}</option>
      {/* <option value="noncompliant">{paStateText[paStates.NONCOMPLIANCE]}</option> */}
      <option value="withdrawn">{paStateText[paStates.WITHDRAWN]}</option>
    </optgroup>

    <optgroup label="Location">
      <option value="land_added">Location added</option>
      <option value="land_changed">Location changed</option>
      <option value="land_deleted">Location deleted</option>
    </optgroup>

    <optgroup label="Organization">
      <option value="created">Organization created</option>
    </optgroup>

    <optgroup label="Note">
      <option value="note_added_by_org">Note added by Org</option>
      <option value="note_added_by_aca">Note added by ACA</option>
      <option value="note_added_by_admin">Note added by Admin</option>
    </optgroup>

    <optgroup label="Change Request">
      <option value="change_request_created">Change Request created</option>
      <option value="change_request_submitted">Change Request submitted</option>
      <option value="change_request_applied">Change Request applied</option>
      <option value="change_added">Change added</option>
      <option value="change_modified">Change modified</option>
      <option value="change_approved">change approved</option>
      <option value="change_rejected">Change rejected</option>
      <option value="comment_added">Comment added</option>
    </optgroup>

    <optgroup label="Plan Flag Changed">
      <option value="noncompliance_added">Noncompliance Added</option>
      <option value="noncompliance_removed">Noncompliance Removed</option>
      <option value="settlement_agreement_added">Settlement Agreement Added</option>
      <option value="settlement_agreement_removed">Settlement Agreement Removed</option>
      <option value="under_investigation_added">Under Investigation Added</option>
      <option value="under_investigation_removed">Under Investigation Removed</option>
    </optgroup>
  </Select>
);

ActivityLogActionTypeSelector.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

ActivityLogActionTypeSelector.defaultProps = {
  id: 'activity_log_action_type',
};

export default ActivityLogActionTypeSelector;
