import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { orgIdType, planAppSummaryType } from 'sow/types';
import { fromRouter, fromPlanApp } from 'sow/store/selectors';
import * as currentAca from 'sow/selectors/currentAca';
import OrgPageTemplate from 'sow/containers/OrgPageTemplate';
import OrgHistoricalPlansPage from 'sow/components/pages/OrgHistoricalPlansPage';
import { PlanAppSummaryLoader } from 'sow/store/containers';
import ResourceLoaderStatusRender from 'sow/components/molecules/ResourceLoaderStatusRender';

const mapStateToProps = (state, props) => ({
  acaName: currentAca.name(state, props),
  orgId: fromRouter.paramOrgId(state, props),
  planAppSummaryList: fromPlanApp.planAppSummaryList(state, props),
});

const OrgHistoricalPlansPageContainer = ({ acaName, orgId, planAppSummaryList }) => (
  <OrgPageTemplate title="Historical Plans">
    <PlanAppSummaryLoader orgId={orgId} autoLoad>
      {({ status }) => (
        <ResourceLoaderStatusRender
          statusObjects={[status]}
          renderAllSuccess={() => (
            <OrgHistoricalPlansPage
              acaName={acaName}
              orgId={orgId}
              planAppSummaryList={planAppSummaryList}
            />
          )}
        />
      )}
    </PlanAppSummaryLoader>
  </OrgPageTemplate>
);

OrgHistoricalPlansPageContainer.propTypes = {
  acaName: PropTypes.string,
  orgId: orgIdType.isRequired,
  planAppSummaryList: PropTypes.arrayOf(planAppSummaryType).isRequired,
};

OrgHistoricalPlansPageContainer.defaultProps = {
  acaName: '',
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(OrgHistoricalPlansPageContainer);
