import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Block from 'sow/components/atoms/Block';

const Row = ({ children, className, ...props }) =>
  <Block className={classNames(className, 'row')} {...props}>
    {children}
  </Block>;

Row.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Row;
