import configureStore from './configure';
import { createApi } from 'sow/services/api';
import { bugsnagClient } from 'sow/services/bugsnag';

const services = {
  api: createApi(),
  bugsnagClient,
};

const store = configureStore({}, services);

export default store;
