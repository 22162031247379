export const updateURL = formValues => {
  const urlParams = [];
  for (let key in formValues) {
    urlParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(formValues[key])}`);
  }
  history.pushState(null, null, `?${urlParams.join('&')}`);
};

export const urlSearchValues = urlParams => {
  const searchValues = {};
  urlParams.split('&').forEach(param => {
    const paramKeyValue = param.split('=');
    searchValues[decodeURIComponent(paramKeyValue[0])] = decodeURIComponent(
      paramKeyValue[1],
    );
  });
  return searchValues;
};
