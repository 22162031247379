import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Block from 'sow/components/atoms/Block';

const isProgressNotStarted = R.equals(0);
const isProgressComplete = R.equals(100);

const ProgressBar = ({ percentageComplete }) => {
  if (R.isNil(percentageComplete)) return null;

  const classes = classnames('progress-bar', 'progress-bar-striped', {
    'progress-bar-success': isProgressComplete(percentageComplete),
    'progress-bar-info': !isProgressComplete(percentageComplete),
    'progress-bar-default': isProgressNotStarted(percentageComplete),
  });

  const percent = `${Math.round(percentageComplete)}%`;
  return (
    <Block className="progress" style={{ margin: 0 }}>
      <Block
        className={classes}
        role="progressbar"
        style={{ minWidth: '3em', width: percent }}
      >
        {percent} Complete
      </Block>
    </Block>
  );
};

ProgressBar.propTypes = {
  percentageComplete: PropTypes.number,
};

export default ProgressBar;
