export const sampleStates = {
  pendingCollection: 1,
  collectedPendingResults: 2,
  resultsReceivedAdditionalAction: 3,
  resultsReceivedNegative: 4,
  closed: 5,
  unsuccessful: 6,
};

export const sampleRanks = {
  pendingCollection: 0,
  collectedPendingResults: 1,
  resultsReceivedAdditionalAction: 2,
  closed: 3,
};
