const mark = action => `REGISTRATION/${action}`;

export const REGISTER = mark('REGISTER');
export const REGISTER_DONE = mark('REGISTER_DONE');
export const REGISTER_FAIL = mark('REGISTER_FAIL');

export const register = (user, onSuccess) => ({
  type: REGISTER,
  payload: { user },
  meta: { onSuccess },
});

export const registerDone = payload => ({
  type: REGISTER_DONE,
  payload,
});

export const registerFail = payload => ({
  type: REGISTER_FAIL,
  payload,
  error: true,
});

export const VALIDATE_MAIL = mark('VALIDATE_MAIL');
export const VALIDATE_MAIL_DONE = mark('VALIDATE_MAIL_DONE');
export const VALIDATE_MAIL_FAIL = mark('VALIDATE_MAIL_FAIL');

export const validateMail = (emailHash, onSuccess) => ({
  type: VALIDATE_MAIL,
  payload: { emailHash },
  meta: { onSuccess },
});

export const validateMailDone = () => ({
  type: VALIDATE_MAIL_DONE,
});

export const validateMailFail = error => ({
  type: VALIDATE_MAIL_FAIL,
  payload: { error },
});
