import React, { Fragment } from 'react';
import * as R from 'ramda';
import ReactDatetime from 'react-datetime';
import PropTypes from 'prop-types';

import { actionTypeOptions } from 'sow/constants/activityLog';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Form from 'sow/components/atoms/Form';
import FormGroup from 'sow/components/organisms/FormGroup';
import SelectFormGroup from 'sow/components/molecules/SelectFormGroup';
import InputFormGroup from 'sow/components/molecules/InputFormGroup';
import Button from 'sow/components/atoms/Button';
import CurrentAcaOrgSelector from 'sow/components/organisms/CurrentAcaOrgSelector';
import ActivityLogActionTypeSelector from 'sow/components/organisms/ActivityLogActionTypeSelector';

const noop = () => {};

const AcaActivityLogFilters = ({
  filters,
  errors,
  onChange,
  onResetFilters,
  handleSearch,
  selectOptions,
}) => (
  <Fragment>
    <Row className="search-filter-row">
      <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
        <FormGroup
          className="search-filter"
          fieldName="start_datetime"
          errors={errors}
          label="From Date"
        >
          <ReactDatetime
            id="start_datetime"
            value={filters.start_datetime}
            onChange={onChange('start_datetime')}
            closeOnSelect={true}
            inputProps={{
              className: 'form-control activity-log-date',
            }}
          />
        </FormGroup>
      </Column>
      <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
        <FormGroup
          className="search-filter"
          fieldName="end_datetime"
          errors={errors}
          label="To Date"
        >
          <ReactDatetime
            id="end_datetime"
            value={filters.end_datetime}
            onChange={onChange('end_datetime')}
            closeOnSelect={true}
            inputProps={{
              className: 'activity-log-date form-control',
            }}
          />
        </FormGroup>
      </Column>
      <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
        <SelectFormGroup
          className="search-filter"
          fieldName="organization_ids"
          label="Organization"
          options={selectOptions.org_options}
          clearable={true}
          onChange={event => {
            onChange('organization_ids')(event ? event.value : null);
          }}
          value={filters.organization_ids.length ? filters.organization_ids[0] : ''}
          placeholder="Select Organization"
        />
      </Column>
      <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
        <SelectFormGroup
          className="search-filter"
          fieldName="user_id"
          label="User"
          options={selectOptions.user_options}
          clearable={true}
          onChange={event => {
            onChange('user_id')(event ? event.value : null);
          }}
          value={filters.user_id}
          placeholder="Select User"
        />
      </Column>
      <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
        <SelectFormGroup
          className="activity-log-select"
          fieldName="action"
          label="Action Type"
          options={actionTypeOptions}
          clearable={true}
          onChange={event => {
            onChange('action')(event ? event.value : null);
          }}
          value={filters.action}
          placeholder="Select Action Type"
        />
      </Column>
      <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
        <InputFormGroup
          fieldName="change_id"
          id="change_id"
          label="Change ID"
          className="search-filter"
          inputClassName="input-filter"
          onChange={event => {
            onChange('change_id')(event.target.value);
          }}
          value={filters.change_id}
          fieldErrors={errors}
        />
      </Column>
    </Row>
    <Row className="search-filter-row">
      <Column className="search-filter-column button-column" xs={12} md={4} lg={3}>
        <Row className="search-filter-row">
          <Column className="search-filter-column button-column" xs={6}>
            <Button
              className="filter-command-button search-button"
              onClick={handleSearch}
              disabled={!R.isEmpty(errors)}
              primary
            >
              Search
            </Button>
          </Column>
          <Column className="search-filter-column button-column" xs={6}>
            <Button
              className="filter-command-button reset-button"
              onClick={onResetFilters}
              primary
            >
              Reset
            </Button>
          </Column>
        </Row>
      </Column>
    </Row>
  </Fragment>
);

AcaActivityLogFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default AcaActivityLogFilters;
