import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'fast-deep-equal';

import * as sowTypes from 'sow/types';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import { InfoTooltipWrapper } from 'sow/components/organisms/InfoTooltip';
import Button from 'sow/components/atoms/Button';
import WorksheetMatrixAnswerModal from 'sow/components/organisms/WorksheetMatrixAnswerModal';
import MatrixRow from 'sow/containers/planApp/MatrixRow';
import QuestionDetails from 'sow/containers/planApp/QuestionDetails';
import Block from 'sow/components/atoms/Block';
import SortableTable from 'sow/components/molecules/SortableTable';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Clearfix from 'sow/components/atoms/Clearfix';

const WorksheetMatrix = ({
  questions,
  values,
  orgId,
  planAppId,
  worksheetId,
  locationId,
  changeRequest,
  showAcaUI,
  isPlanAppLocked,
  isPlanAppClosed,
  isWorksheetLocked,
  changeRequestIsOpen,
  changeUIDisabled,
  baseAnswersName,
  baseAnswersChangesName,
  isInspector,
  locationAnswersChangesMap,
  changeList: worksheetAnswersChangesMap,
}) => {
  const answersPath = locationId
    ? ['answers', 'worksheets', worksheetId, 'answers', 'matrixRows']
    : ['worksheetAnswers', 'answers', 'matrixRows'];
  const answersChangesPath = locationId
    ? ['answersChanges', 'worksheets', worksheetId, 'answers', 'matrixRows']
    : ['worksheetAnswersChanges', 'matrixRows'];

  const answers = R.path(answersPath, values);
  const answersChanges = R.path(answersChangesPath, values);
  const answersRowUuids = R.keys(answers);
  const answersChangesRowUuids = R.keys(answersChanges);
  const rowUuids = R.union(answersRowUuids, answersChangesRowUuids);

  function buildMatrixChangeList(changeMap, formValues) {
    let newMatrixRowIds = {};
    R.values(changeMap).forEach(change => {
      const wsId = R.prop('worksheetId', change);
      const matrixRowId = R.prop('matrixRowId', change);
      if (matrixRowId !== null && wsId === worksheetId) {
        if (!R.has(matrixRowId, newMatrixRowIds)) newMatrixRowIds[matrixRowId] = {};
        if (!R.has('values', newMatrixRowIds[matrixRowId]))
          newMatrixRowIds[matrixRowId]['values'] = {};
        if (change.questionId !== null) {
          newMatrixRowIds[matrixRowId]['values'][change.questionId] = change.new;
        }
      }
    });
    return newMatrixRowIds;
  }

  const changeList = buildMatrixChangeList(locationAnswersChangesMap, values);
  const allUuids = R.union(rowUuids, R.keys(changeList));

  const answersArray = [];
  if (!locationId) {
    const questionsObject = {};
    questions.forEach(question => (questionsObject[question] = ''));

    const buildAnswerChangesMap = changeMap => {
      const filteredChangeMap = R.filter(change => change.type == 'matrix_row_answer')(
        changeMap,
      );
      const changeObject = {};
      filteredChangeMap.forEach(change => {
        switch (change.state) {
          case 'accepted':
          case 'open':
          case 'applied':
          case 'not_applied':
            if (changeObject[change.matrixRowId]) {
              changeObject[change.matrixRowId][change.questionId] = change.new;
            } else {
              changeObject[change.matrixRowId] = {
                [change.questionId]: change.new,
              };
            }
            break;
          case 'rejected':
            if (changeObject[change.matrixRowId]) {
              changeObject[change.matrixRowId][change.questionId] = change.old;
            } else {
              changeObject[change.matrixRowId] = {
                [change.questionId]: change.old,
              };
            }
            break;
        }
      });
      return changeObject;
    };

    const buildAnswersMap = answersMap => {
      const formattedAnswersMap = {};
      for (const wsId in answersMap) {
        formattedAnswersMap[wsId] = answersMap[wsId].values;
      }
      return formattedAnswersMap;
    };

    const answersMap = buildAnswersMap(answers);

    const answerChangesMap = buildAnswerChangesMap(worksheetAnswersChangesMap);

    const combinedAnswersMap = R.mergeDeepRight(answersMap, answerChangesMap);

    for (const wsId in combinedAnswersMap) {
      combinedAnswersMap[wsId] = R.mergeDeepRight(
        questionsObject,
        combinedAnswersMap[wsId],
      );
    }

    for (const rowId in combinedAnswersMap) {
      answersArray.push({ ...combinedAnswersMap[rowId], rowId });
    }
  }
  const controlsDisabled =
    (isPlanAppLocked || isPlanAppClosed) && !(changeRequest && changeRequestIsOpen);

  if (!locationId) {
    return (
      <Widget>
        <WidgetBody>
          <SortableTable
            tableData={answersArray}
            columnData={questions}
            condensed
            head={(questions, sortColumn, sortedAsc, sortedColumn) => (
              <TableRow>
                {questions.map(questionId => (
                  <QuestionDetails questionId={questionId} key={questionId}>
                    {({ name, tooltip }) => (
                      <TableCell
                        heading
                        onClick={() => sortColumn(questionId)}
                        className="matrix-column-width"
                      >
                        <InfoTooltipWrapper tooltipText={tooltip}>
                          {name}
                        </InfoTooltipWrapper>
                        <Block className="sort-caret-block">
                          <FontAwesome
                            className={`${
                              questionId == sortedColumn
                                ? sortedAsc
                                  ? 'sorted-by'
                                  : 'hidden-caret'
                                : ''
                            } sort-caret`}
                            icon="caret-up"
                          />
                          <FontAwesome
                            className={`${
                              questionId == sortedColumn
                                ? !sortedAsc
                                  ? 'sorted-by'
                                  : 'hidden-caret'
                                : ''
                            } sort-caret`}
                            icon="caret-down"
                          />
                        </Block>
                        <Clearfix />
                      </TableCell>
                    )}
                  </QuestionDetails>
                ))}
                <TableCell heading xs={1} />
              </TableRow>
            )}
            foot={
              !controlsDisabled && (
                <TableRow>
                  <TableCell colSpan={questions.length + 1}>
                    <WorksheetMatrixAnswerModal
                      title="Add Answer"
                      orgId={orgId}
                      planAppId={planAppId}
                      worksheetId={worksheetId}
                      locationId={locationId}
                      changeRequest={changeRequest}
                      showAcaUI={showAcaUI}
                      questions={questions}
                      changeUIDisabled={changeUIDisabled}
                      disabled={isWorksheetLocked}
                      showModal={false}
                      onHideModal={() => {}}
                      baseAnswersName={baseAnswersName}
                      baseAnswersChangesName={baseAnswersChangesName}
                      isInspector={isInspector}
                    >
                      {onClick => (
                        <Button success onClick={onClick} disabled={changeUIDisabled}>
                          Add Answer
                        </Button>
                      )}
                    </WorksheetMatrixAnswerModal>
                  </TableCell>
                </TableRow>
              )
            }
          >
            {allUuids =>
              allUuids.length > 0 ? (
                allUuids.map(matrixRowId => (
                  <MatrixRow
                    key={matrixRowId}
                    matrixRowId={matrixRowId}
                    questions={questions}
                    orgId={orgId}
                    planAppId={planAppId}
                    worksheetId={worksheetId}
                    locationId={locationId}
                    changeRequest={changeRequest}
                    showAcaUI={showAcaUI}
                    controlsDisabled={controlsDisabled}
                    isWorksheetLocked={isWorksheetLocked}
                    changeRequestIsOpen={changeRequestIsOpen}
                    changeUIDisabled={changeUIDisabled}
                    baseAnswersName={baseAnswersName}
                    baseAnswersChangesName={baseAnswersChangesName}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={questions.length + 1} className="no-match">
                    No data available.
                  </TableCell>
                </TableRow>
              )
            }
          </SortableTable>
        </WidgetBody>
      </Widget>
    );
  }

  if (locationId) {
    return (
      <Widget>
        <WidgetBody>
          <Table
            className="dataTable"
            condensed
            head={
              <TableRow>
                {/* <TableCell /> */}
                {questions.map(questionId => (
                  <QuestionDetails questionId={questionId} key={questionId}>
                    {({ name, tooltip }) => (
                      <TableCell heading>
                        <InfoTooltipWrapper tooltipText={tooltip}>
                          {name}
                        </InfoTooltipWrapper>
                      </TableCell>
                    )}
                  </QuestionDetails>
                ))}
                <TableCell heading xs={1} />
              </TableRow>
            }
            foot={
              !controlsDisabled && (
                <TableRow>
                  <TableCell colSpan={questions.length + 1}>
                    <WorksheetMatrixAnswerModal
                      title="Add Answer"
                      orgId={orgId}
                      planAppId={planAppId}
                      worksheetId={worksheetId}
                      locationId={locationId}
                      changeRequest={changeRequest}
                      showAcaUI={showAcaUI}
                      questions={questions}
                      changeUIDisabled={changeUIDisabled}
                      disabled={isWorksheetLocked}
                      showModal={false}
                      onHideModal={() => {}}
                      baseAnswersName={baseAnswersName}
                      baseAnswersChangesName={baseAnswersChangesName}
                      isInspector={isInspector}
                    >
                      {onClick => (
                        <Button success onClick={onClick} disabled={changeUIDisabled}>
                          Add Answer
                        </Button>
                      )}
                    </WorksheetMatrixAnswerModal>
                  </TableCell>
                </TableRow>
              )
            }
          >
            {allUuids.map(matrixRowId => (
              <MatrixRow
                key={matrixRowId}
                matrixRowId={matrixRowId}
                questions={questions}
                orgId={orgId}
                planAppId={planAppId}
                worksheetId={worksheetId}
                locationId={locationId}
                changeRequest={changeRequest}
                showAcaUI={showAcaUI}
                controlsDisabled={controlsDisabled}
                isWorksheetLocked={isWorksheetLocked}
                changeRequestIsOpen={changeRequestIsOpen}
                changeUIDisabled={changeUIDisabled}
                baseAnswersName={baseAnswersName}
                baseAnswersChangesName={baseAnswersChangesName}
              />
            ))}
          </Table>
        </WidgetBody>
      </Widget>
    );
  }
};

WorksheetMatrix.propTypes = {
  // Uncategorized props
  questions: PropTypes.arrayOf(sowTypes.planAppWorksheetQuestionIdType).isRequired,
  values: PropTypes.object,
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheetId: sowTypes.planAppWorksheetIdType.isRequired,
  changeRequest: sowTypes.planAppChangeRequestType,
  showAcaUI: PropTypes.bool,
  isPlanAppLocked: PropTypes.bool,
  isPlanAppClosed: PropTypes.bool,
  changeRequestIsOpen: PropTypes.bool,
  isWorksheetLocked: PropTypes.bool,
  changeUIDisabled: PropTypes.bool.isRequired,
  isInspector: PropTypes.bool,

  // Passed props
  baseAnswersName: PropTypes.string,
  baseAnswersChangesName: PropTypes.string,

  // Connected props
  locationId: sowTypes.planAppLocationIdType,
};

WorksheetMatrix.defaultProps = {
  baseAnswersName: 'worksheetAnswers.answers',
  baseAnswersChangesName: 'worksheetAnswersChanges',
  locationId: null,
  isInspector: false,
};

export default WorksheetMatrix;
