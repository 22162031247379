import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import Block from 'sow/components/atoms/Block';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormikField from 'sow/components/organisms/FormikField';
import LatLngPickerMapField from 'sow/components/organisms/LatLngPickerMapField';

const LatLngPickerMap = ({ namePrefix, disabled }) => (
  <Block>
    <Field
      name={namePrefix}
      component={FormikField}
      type={LatLngPickerMapField}
      // Note: these have to be 'native' elements like <div /> or <span />
      containerElement={<div style={{ height: '300px', width: '100%' }} />}
      loaderElement={<div style={{ height: '100%' }} />}
      mapElement={<div style={{ height: '100%' }} />}
      disabled={disabled}
    />

    <Block style={{ marginTop: '10px' }} />

    <Row>
      <Column md={6}>
        <Field
          name={`${namePrefix}.lat`}
          label="Latitude"
          component={FormikField}
          type="number"
          disabled={disabled}
        />
      </Column>

      <Column md={6}>
        <Field
          name={`${namePrefix}.lng`}
          label="Longitude"
          component={FormikField}
          type="number"
          disabled={disabled}
        />
      </Column>
    </Row>
  </Block>
);

LatLngPickerMap.propTypes = {
  namePrefix: PropTypes.string,
  disabled: PropTypes.bool,
};

LatLngPickerMap.defaultProps = {
  namePrefix: '',
  disabled: false,
};

export default LatLngPickerMap;
