const mark = action => `ORG_USERS/${action}`;

export const LOAD_USERS_FOR_ORG_REQUEST = mark('LOAD_USERS_FOR_ORG_REQUEST');
export const LOAD_USERS_FOR_ORG_SUCCESS = mark('LOAD_USERS_FOR_ORG_SUCCESS');
export const LOAD_USERS_FOR_ORG_FAILURE = mark('LOAD_USERS_FOR_ORG_FAILURE');

export const addUsers = (orgId, users, onSuccess) => ({
  type: LOAD_USERS_FOR_ORG_REQUEST,
  payload: { orgId, users },
  meta: { onSuccess },
});

export const addUsersDone = users => ({
  type: LOAD_USERS_FOR_ORG_SUCCESS,
  payload: { users },
});

export const addUsersFail = errors => ({
  type: LOAD_USERS_FOR_ORG_FAILURE,
  payload: { errors },
});
