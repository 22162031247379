import * as R from 'ramda';

export const planAppStates = {
  INITIAL_APPLICATION: 'initial_application',
  REVISION: 'revision',
  INITIAL_APPLICATION_SUBMITTED: 'initial_application_submitted',
  REVISION_SUBMITTED: 'revision_submitted',
  INITIAL_REVIEW: 'initial_review',
  IN_INITIAL_REVIEW: 'in_initial_review',
  INSPECTION: 'inspection',
  IN_INSPECTION: 'in_inspection',
  FINAL_REVIEW: 'final_review',
  IN_FINAL_REVIEW: 'in_final_review',
  COMPLETE: 'complete',
  NONCOMPLIANCE: 'noncompliance',
  WITHDRAWN: 'withdrawn',
  SUSPENDED: 'suspended',
  SURRENDERED: 'surrendered',
  REVOKED: 'revoked',
};

// Display text for plan states
export const planAppStateText = {
  [planAppStates.INITIAL_APPLICATION]: 'Initial Application',
  [planAppStates.REVISION]: 'Unlocked for Update',
  [planAppStates.INITIAL_APPLICATION_SUBMITTED]: 'Initial Application Submitted',
  [planAppStates.REVISION_SUBMITTED]: 'Update Submitted',
  [planAppStates.INITIAL_REVIEW]: 'Ready for Initial Review',
  [planAppStates.IN_INITIAL_REVIEW]: 'In Initial Review',
  [planAppStates.INSPECTION]: 'Ready for Inspection',
  [planAppStates.IN_INSPECTION]: 'In Inspection',
  [planAppStates.FINAL_REVIEW]: 'Ready for Final Review',
  [planAppStates.IN_FINAL_REVIEW]: 'In Final Review',
  [planAppStates.COMPLETE]: 'Complete',
  [planAppStates.NONCOMPLIANCE]: 'Noncompliance',
  [planAppStates.WITHDRAWN]: 'Withdrawn',
  [planAppStates.SUSPENDED]: 'Suspended',
  [planAppStates.SURRENDERED]: 'Surrendered',
  [planAppStates.REVOKED]: 'Revoked',
};

export const planAppStateIcon = {
  [planAppStates.INITIAL_APPLICATION]: 'file',
  [planAppStates.REVISION]: 'unlock-keyhole',
  [planAppStates.INITIAL_APPLICATION_SUBMITTED]: 'file-circle-check',
  [planAppStates.REVISION_SUBMITTED]: 'file-circle-check',
  [planAppStates.INITIAL_REVIEW]: 'file-pen',
  [planAppStates.IN_INITIAL_REVIEW]: 'file-pen',
  [planAppStates.INSPECTION]: 'magnifying-glass-arrow-right',
  [planAppStates.IN_INSPECTION]: 'search',
  [planAppStates.FINAL_REVIEW]: 'search',
  [planAppStates.IN_FINAL_REVIEW]: 'search',
  [planAppStates.COMPLETE]: 'check-circle',
  [planAppStates.WITHDRAWN]: 'right-from-bracket',
  [planAppStates.SUSPENDED]: 'ban',
  [planAppStates.SURRENDERED]: 'flag',
  [planAppStates.REVOKED]: 'arrow-rotate-left',
};

export const planAppFlags = {
  IS_EXPEDITED: 'is_expedited',
  IS_CERTIFIED: 'is_certified',
  IS_NEW: 'is_new',
  IS_PAID: 'is_paid',
  IS_RENEWAL_UNPAID: 'is_renewal_unpaid',
  IS_INSPECTION_UNPAID: 'is_inspection_unpaid',
  IS_NOT_STARTED: 'is_not_started',
  IS_IN_MEDIATION: 'is_in_mediation',
  IS_UNDER_INVESTIGATION: 'is_under_investigation',
  IS_NONCOMPLIANT: 'noncompliant', // there is a flag and status
  IS_HARD_COPY: 'hard_copy_account',
};

export const planAppFlagText = {
  [planAppFlags.IS_EXPEDITED]: 'Expedited',
  [planAppFlags.IS_CERTIFIED]: 'Certified',
  [planAppFlags.IS_NEW]: 'New',
  [planAppFlags.IS_PAID]: 'Paid',
  [planAppFlags.IS_RENEWAL_UNPAID]: 'Unpaid',
  [planAppFlags.IS_INSPECTION_UNPAID]: 'Inspection Unpaid',
  [planAppFlags.IS_NOT_STARTED]: 'Not Started',
  [planAppFlags.IS_IN_MEDIATION]: 'In Mediation',
  [planAppFlags.IS_UNDER_INVESTIGATION]: 'Under Investigation',
  [planAppFlags.IS_NONCOMPLIANT]: 'Noncompliance',
};

export const planAppFlagAbbreviations = {
  [planAppFlags.IS_EXPEDITED]: 'EXP',
  [planAppFlags.IS_CERTIFIED]: 'CRT',
  [planAppFlags.IS_NEW]: 'NEW',
  [planAppFlags.IS_PAID]: 'PD',
  [planAppFlags.IS_RENEWAL_UNPAID]: 'ENR-UPD',
  [planAppFlags.IS_INSPECTION_UNPAID]: 'INS-UPD',
  [planAppFlags.IS_NOT_STARTED]: 'NST',
  [planAppFlags.IS_IN_MEDIATION]: 'SA',
  [planAppFlags.IS_UNDER_INVESTIGATION]: 'INV',
  [planAppFlags.IS_NONCOMPLIANT]: 'NC',
  [planAppFlags.IS_HARD_COPY]: 'HARD COPY',
};

export const planAppCategories = {
  CROP: 'crop',
  HANDLER: 'handler',
  WILD_CROP: 'wild_crop',
  LIVESTOCK: 'livestock',
};

export const planAppCategoryText = {
  [planAppCategories.CROP]: 'Crop',
  [planAppCategories.HANDLER]: 'Handler',
  [planAppCategories.WILD_CROP]: 'Wild Crop',
  [planAppCategories.LIVESTOCK]: 'Livestock',
};

export const planAppCategoryActivity = {
  [planAppCategories.CROP]: 'grow crops',
  [planAppCategories.HANDLER]: 'handle',
  [planAppCategories.WILD_CROP]: 'wild crops',
  [planAppCategories.LIVESTOCK]: 'livestock',
};

export const isCrop = R.contains(planAppCategoryActivity[planAppCategories.CROP]);
export const isHandler = R.contains(planAppCategoryActivity[planAppCategories.HANDLER]);
export const isWildCrop = R.contains(
  planAppCategoryActivity[planAppCategories.WILD_CROP],
);
export const isLivestock = R.contains(
  planAppCategoryActivity[planAppCategories.LIVESTOCK],
);

export const isValidCategory = activity =>
  isCrop(activity) ||
  isHandler(activity) ||
  isWildCrop(activity) ||
  isLivestock(activity);

export const getValidCategoryList = R.filter(isValidCategory);

export const getCategoryText = activity => {
  if (isCrop(activity)) return planAppCategoryText[planAppCategories.CROP];
  if (isHandler(activity)) return planAppCategoryText[planAppCategories.HANDLER];
  if (isWildCrop(activity)) return planAppCategoryText[planAppCategories.WILD_CROP];
  if (isLivestock(activity)) return planAppCategoryText[planAppCategories.LIVESTOCK];
};

// States in which a plan is considered "open" (all states up to completion)
export const PLAN_APP_STATES_OPEN_FOR_ORG = [
  planAppStates.INITIAL_APPLICATION,
  planAppStates.INITIAL_APPLICATION_SUBMITTED,
  planAppStates.REVISION,
  planAppStates.REVISION_SUBMITTED,
  planAppStates.INITIAL_REVIEW,
  planAppStates.IN_INITIAL_REVIEW,
  planAppStates.INSPECTION,
  planAppStates.IN_INSPECTION,
  planAppStates.FINAL_REVIEW,
  planAppStates.IN_FINAL_REVIEW,
];

// States in which an org can freely edit their application
// This can only be done on an initial application, afterwards it is always handled by a change request.
export const PLAN_APP_STATES_UNLOCKED_FOR_ORG = ['initial_application'];

// States in which a CR can be created on a plan if one does not already exist
export const PLAN_APP_STATES_CR_CREATABLE = [
  planAppStates.REVISION,
  planAppStates.INITIAL_APPLICATION_SUBMITTED,
  planAppStates.REVISION_SUBMITTED,
  planAppStates.INITIAL_REVIEW,
  planAppStates.IN_INITIAL_REVIEW,
  planAppStates.INSPECTION,
  planAppStates.IN_INSPECTION,
  planAppStates.FINAL_REVIEW,
  planAppStates.IN_FINAL_REVIEW,
];

// States in which an ACA can change the state of a plan using the state dropdown list
export const PLAN_APP_STATES_STATE_EDITABLE = [
  planAppStates.INITIAL_APPLICATION_SUBMITTED,
  planAppStates.REVISION_SUBMITTED,
  planAppStates.INITIAL_REVIEW,
  planAppStates.IN_INITIAL_REVIEW,
  planAppStates.INSPECTION,
  planAppStates.IN_INSPECTION,
  planAppStates.FINAL_REVIEW,
  planAppStates.IN_FINAL_REVIEW,
];

// States considered "completed"
export const PLAN_APP_STATES_COMPLETED = [
  planAppStates.COMPLETE,
  planAppStates.NONCOMPLIANCE,
  planAppStates.WITHDRAWN,
  planAppStates.SUSPENDED,
  planAppStates.SURRENDERED,
  planAppStates.REVOKED,
];

// States considered "locked"
export const PLAN_APP_STATES_CLOSED_AND_LOCKED = [
  planAppStates.SUSPENDED,
  planAppStates.SURRENDERED,
  planAppStates.REVOKED,
];

export const ospNotificationBanners = {
  noncompliant: {
    header: 'Notice of Noncompliance!',
    body:
      'You currently have an unresolved, open Notice of Noncompliance. It is VERY IMPORTANT that you respond and address all items by the due date in the Notice of Noncompliance letter sent to you. Contact your certification specialist if you have any questions or concerns.',
  },
  inMediation: {
    header: 'Settlement Agreement!',
    body: 'Please note that you have an active settlement agreement.',
  },
  underInvestigation: {
    header: 'Under Investigation!',
    body: 'Please note that this operation is undergoing an open NOP investigation.',
  },
};
