import React from 'react';

import './OrderInput.module.css';

const OrderInput = ({ id, value, className, onChange }) => (
  <input
    id={id}
    className={className ? `${className} order-input` : 'order-input'}
    onChange={onChange}
    type="number"
    min="1"
    value={value ? value : ''}
  />
);

export default OrderInput;
