import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import SearchField from 'sow/components/organisms/SearchField';
import LineBreak from 'sow/components/atoms/LineBreak';

const OrgWorkflowDashboardPage = ({
  searchText,
  searchTextChange,
  children,
  placeholder,
}) => (
  <Fragment>
    {children}
  </Fragment>
);

OrgWorkflowDashboardPage.propTypes = {
  searchText: PropTypes.string,
  searchTextChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  placeholder: PropTypes.string,
};

export default OrgWorkflowDashboardPage;
