import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import { apiGet, apiPost, apiDelete } from 'sow/sagas/api';

export const getAllByOrg = function*({ payload, meta }) {
  const { orgId, ...params } = payload;
  try {
    const { response } = yield call(apiGet, `/org/${orgId}/inspection`, params);

    if (response) {
      yield put(actions.inspection.getAllByOrgDone(response));
      if (meta.onSuccess) {
        meta.onSuccess();
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateInspection = function*({ payload, meta }) {
  const { routeParams, updates } = payload;

  try {
    const { response } = yield call(
      apiPost,
      `/org/${routeParams.orgId}/application/${routeParams.appId}/inspection/${routeParams.inspectionId}`,
      { ...updates },
    );
    if (response.data) {
      yield put(actions.inspection.updateDone(response.data));
      if (meta.onSuccess)
        meta.onSuccess({ responseData: response.data, params: routeParams });
    }
  } catch (error) {
    console.error(error);
  }
};

export const addInspection = function*({ payload, meta }) {
  const { orgId, ...params } = payload;

  try {
    const { response } = yield call(apiPost, `/org/${orgId}/inspection`, params);

    if (response && meta.onSuccess) {
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteInspection = function*({ payload, meta }) {
  const { orgId, partialInspectionId } = payload;

  try {
    const { response } = yield call(
      apiDelete,
      `/org/${orgId}/inspection/${partialInspectionId}`,
    );

    yield put(actions.inspection.deleteDone(response.data));
  } catch (error) {
    console.error(error);
  }
};

export const getOptions = function*() {
  try {
    const { response } = yield call(apiGet, `/inspection/options`);

    yield put(actions.inspection.getOptionsDone(response.data));
  } catch (error) {
    console.error(error);
  }
};

export const getUnannouncedOptions = function*() {
  try {
    const { response } = yield call(apiGet, `/unannounced_inspection/options`);

    yield put(actions.inspection.getUnannouncedOptionsDone(response.data));
  } catch (error) {
    console.error(error);
  }
};

export const watchGetAllByOrg = function*({ payload }) {
  try {
    yield call(getAllByOrg, payload);
  } catch (error) {
    console.error(error);
  }
};

export const watchUpdateInspection = function*({ payload }) {
  try {
    yield call(updateInspection, payload);
  } catch (error) {
    console.error(error);
  }
};

export const watchAddInspection = function*({ payload, meta }) {
  try {
    yield call(addInspection, payload, meta);
  } catch (error) {
    console.error(error);
  }
};

export const watchDeleteInspection = function*({ payload, meta }) {
  try {
    yield call(deleteInspection, payload, meta);
  } catch (error) {
    console.error(error);
  }
};

export const watchGetOptions = function*() {
  try {
    yield call(getOptions);
  } catch (error) {
    console.error(error);
  }
};

export const watchGetUnannouncedOptions = function*() {
  try {
    yield call(getUnannouncedOptions);
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(actions.inspection.getAllByOrg, watchGetAllByOrg);
  yield takeEvery(actions.inspection.update, watchUpdateInspection);
  yield takeEvery(actions.inspection.add, watchAddInspection);
  yield takeEvery(actions.inspection.delete, watchDeleteInspection);
  yield takeEvery(actions.inspection.getOptions, watchGetOptions);
  yield takeEvery(actions.inspection.getUnannouncedOptions, watchGetUnannouncedOptions);
}
