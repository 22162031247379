import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';

const mapStateToProps = (state, props) => ({
  locationDetails: fromPlanApp.locationDetails(state, props),
});

const LocationDetailsContainer = ({ locationDetails, children }) =>
  typeof locationDetails != 'undefined' ? children(locationDetails) : null;

LocationDetailsContainer.propTypes = {
  // Passed props:
  locationId: sowTypes.planAppLocationIdType.isRequired,
  children: PropTypes.func.isRequired,

  // Connected props:
  locationDetails: sowTypes.planAppLocationType,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(LocationDetailsContainer);
