import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import { api } from 'sow/utils/apiNew';

export function* loadCountryList() {
  try {
    const { data } = yield call(api.get, '/org/register/country_list');
    yield put(actions.gis.loadCountryListDone(data));
  } catch (err) {
    yield put(actions.gis.loadCountryListFail());
    yield put(
      actions.shell.toast(
        'danger',
        'There was a problem loading the country list. Please try again later.',
      ),
    );
  }
}

export default function* rootSaga() {
  yield takeEvery(actions.gis.loadCountryList, loadCountryList);
}
