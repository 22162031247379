const mark = action => `CERTIFICATION_DOCS/${action}`;

export const LOAD_ALL = mark('LOAD_ALL');
export const LOAD_ALL_DONE = mark('LOAD_ALL_DONE');
export const LOAD_ALL_FAIL = mark('LOAD_ALL_FAIL');

export const loadAllForAca = acaId => ({
  type: LOAD_ALL,
  payload: { acaId },
});

export const loadAllDone = docs => ({
  type: LOAD_ALL_DONE,
  payload: { docs },
});

export const loadAllFail = () => ({
  type: LOAD_ALL_FAIL,
  error: true,
});

export const DELETE_DOC = mark('DELETE_DOC');
export const DELETE_DOC_DONE = mark('DELETE_DOC_DONE');
export const DELETE_DOC_FAIL = mark('DELETE_DOC_FAIL');

export const deleteDoc = (acaId, docId) => ({
  type: DELETE_DOC,
  payload: { acaId, docId },
});

export const deleteDocDone = docId => ({
  type: DELETE_DOC_DONE,
  payload: { docId },
});

export const loadAllForOrg = orgId => ({
  type: LOAD_ALL,
  payload: { orgId },
});
