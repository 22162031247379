import { combineReducers } from 'redux';
import {
  LOAD_NEW_REGISTRATIONS_LIST,
  LOAD_NEW_REGISTRATIONS_LIST_DONE,
  LOAD_NEW_REGISTRATIONS_LIST_FAIL,
} from 'sow/actions/aca';

const items = (state = [], action) => {
  switch (action.type) {
    case LOAD_NEW_REGISTRATIONS_LIST:
    case LOAD_NEW_REGISTRATIONS_LIST_FAIL:
      return null;

    case LOAD_NEW_REGISTRATIONS_LIST_DONE:
      return action.payload;

    default:
      return state;
  }
};

const isLoading = (state = false, action) => {
  switch (action.type) {
    case LOAD_NEW_REGISTRATIONS_LIST:
      return true;

    case LOAD_NEW_REGISTRATIONS_LIST_FAIL:
    case LOAD_NEW_REGISTRATIONS_LIST_DONE:
      return false;

    default:
      return state;
  }
};

export default combineReducers({
  items,
  isLoading,
});
