import React, { Fragment } from 'react';

import {
  itemVersionInfoEditRoute,
  itemVersionCreatorRoute,
  itemVersionPicklistRoute,
  itemVersionHistoryRoute,
} from 'sow/routes';

import Spinner from 'sow/components/atoms/Spinner';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Block from 'sow/components/atoms/Block';
import Nav from 'sow/components/atoms/Nav';
import NavLink from 'sow/components/molecules/NavLink';

const ItemVersionEditPageTemplate = ({ id, children, isLoading }) => (
  <Block className="item-version-edit">
    <Nav tabs>
      <NavLink to={itemVersionInfoEditRoute(id)} exact>
        Information
      </NavLink>
      {id != 'new' && (
        <Fragment>
          <NavLink to={itemVersionCreatorRoute(id)}>Object Creator</NavLink>
          <NavLink to={itemVersionPicklistRoute(id)}>Picklist</NavLink>
          <NavLink to={itemVersionHistoryRoute(id)}>History</NavLink>
        </Fragment>
      )}
    </Nav>
    {isLoading ? (
      <Widget className="nav-widget">
        <WidgetBody>
          <Spinner />
        </WidgetBody>
      </Widget>
    ) : (
      children
    )}
  </Block>
);

export default ItemVersionEditPageTemplate;
