import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { itemRoute } from 'sow/routes';
import Spinner from 'sow/components/atoms/Spinner';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormikField from 'sow/components/organisms/FormikField';
import FormikReactSelect from 'sow/components/molecules/FormikReactSelect';
import Confirm from 'sow/components/molecules/Confirm';
import Button from 'sow/components/atoms/Button';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const formSchema = Yup.object({
  name: Yup.string()
    .required('Name required.')
    .max(255, 'Maximum characters exceeded: 255/255'),
  item_type_id: Yup.number('Type required.')
    .typeError('Type required')
    .required('Type required.')
    .positive('Type required.'),
});

const ItemEdit = ({
  isLoading,
  isNew,
  itemTypeOptions,
  itemSubtypeOptions,
  onSave,
  initialValues,
  onDelete,
}) => (
  <Fragment>
    {isLoading ? (
      <Spinner />
    ) : (
      <Widget className="system-admin-list-edit">
        <WidgetHeading>{isNew ? 'Add' : 'Edit'}</WidgetHeading>
        <WidgetBody>
          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            enableReinitialize={true}
            validationSchema={formSchema}
            onSubmit={onSave}
          >
            {({ handleSubmit }) => (
              <Form>
                <Row className="form-row">
                  <Column className="form-column" xs={4} sm={3} md={2}>
                    <Button className="form-button" onClick={handleSubmit} primary>
                      Save
                    </Button>
                  </Column>
                  <Column className="form-column" xs={4} sm={3} md={2}>
                    <Button className="form-button reset-return-button" to={itemRoute()}>
                      Cancel
                    </Button>
                  </Column>
                  <Column sm={3} md={6}></Column>
                  <Column className="form-column" xs={4} sm={3} md={2}>
                    <Confirm
                      message="Are you sure you want to delete this item?"
                      onConfirm={onDelete}
                    >
                      {toggleConfirm => (
                        <Button
                          className="delete-button"
                          onClick={toggleConfirm}
                          disabled={isNew}
                        >
                          <FontAwesome className="button-icon" icon="trash" />
                          Delete
                        </Button>
                      )}
                    </Confirm>
                  </Column>
                </Row>
                <HorizontalRule className="divider" />
                <Row className="form-row">
                  <Column className="form-column" md={12}>
                    <Field
                      className="input-filter"
                      label="Name"
                      placeholder=""
                      name={'name'}
                      component={FormikField}
                      required
                    />
                  </Column>
                  <Column className="form-column" xs={12}>
                    <Field
                      label="Item Type"
                      placeholder=""
                      name={'item_type_id'}
                      component={FormikField}
                      type={FormikReactSelect}
                      clearable={true}
                      options={itemTypeOptions}
                      required
                    />
                  </Column>
                  <Column className="form-column" xs={12}>
                    <Field
                      label="Item Subtype"
                      placeholder=""
                      name={'item_subtype_id'}
                      component={FormikField}
                      type={FormikReactSelect}
                      clearable={true}
                      options={itemSubtypeOptions}
                    />
                  </Column>
                  <Column className="form-column" md={12}>
                    <Field
                      className="sort-order-input order-input"
                      min="1"
                      type="number"
                      label="Sort"
                      placeholder=""
                      name={'sort_order'}
                      component={FormikField}
                    />
                  </Column>
                </Row>
              </Form>
            )}
          </Formik>
        </WidgetBody>
      </Widget>
    )}
  </Fragment>
);

export default ItemEdit;
