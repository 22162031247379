import React from 'react';
import ReactDatetime from 'react-datetime';

import FormGroup from 'sow/components/organisms/FormGroup';

const ToFromDateFormGroup = ({
  className,
  fieldName,
  fieldErrors,
  label,
  disabled,
  dateFormat,
  onChange,
  dateTimeClassName,
  startDateClassName,
  startId,
  endId,
  startDateValue,
  endDateValue,
}) => {
  const checkStartDate = currentDate => {
    if (endDateValue) {
      return currentDate.isSameOrBefore(endDateValue);
    } else {
      return true;
    }
  };

  const checkEndDate = currentDate => {
    if (startDateValue) {
      return currentDate.isSameOrAfter(startDateValue);
    } else {
      return true;
    }
  };

  return (
    <FormGroup
      className={className}
      fieldName={fieldName}
      label={label}
      errors={fieldErrors}
    >
      <ReactDatetime
        id={startId}
        timeFormat={false}
        dateFormat={dateFormat}
        closeOnSelect={true}
        onChange={event => onChange(event, startId)}
        isValidDate={checkStartDate}
        value={startDateValue}
        inputProps={{
          disabled: disabled,
          className: startDateClassName,
        }}
      />
      <ReactDatetime
        id={endId}
        timeFormat={false}
        dateFormat={dateFormat}
        closeOnSelect={true}
        onChange={event => onChange(event, endId)}
        isValidDate={checkEndDate}
        value={endDateValue}
        inputProps={{
          disabled: disabled,
          className: dateTimeClassName,
        }}
      />
    </FormGroup>
  );
};

export default ToFromDateFormGroup;
