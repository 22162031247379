import React from 'react';
import PropTypes from 'prop-types';

import AcaPageTemplate from 'sow/containers/AcaPageTemplate';
import AcaSettingsNavigation from 'sow/components/organisms/AcaSettingsNavigation';

const AcaSettingsPageTemplate = ({ children }) => (
  <AcaPageTemplate title="Certifier Settings">
    <AcaSettingsNavigation />
    {children}
  </AcaPageTemplate>
);

AcaSettingsPageTemplate.propTypes = {
  children: PropTypes.node,
};

export default AcaSettingsPageTemplate;
