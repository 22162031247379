import React from 'react';
import PropTypes from 'prop-types';
import { FastField } from 'formik';

import {
  worksheetQuestionType,
  orgIdType,
  planAppIdType,
  worksheetIdType,
} from 'sow/types';

import Block from 'sow/components/atoms/Block';
import WorksheetField from 'sow/components/organisms/WorksheetField';
import BaseQuestion from 'sow/components/organisms/WorksheetBaseQuestion';

const WorksheetQuestion = ({
  name,
  question,
  orgId,
  planAppId,
  worksheetId,
  change,
  confirmClearConditionals,
  conditionalAnswerValue,
  disabled,
}) => {
  const isBooleanValue = value => {
    return value === true || value === false || value === null;
  };
  const setDefaultValue = value => {
    if (value == null && conditionalAnswerValue != null) return conditionalAnswerValue;
    else return value;
  };
  const field = (
    <FastField
      name={name}
      render={({ field, form }) => {
        const answerValue = setDefaultValue(field.value);
        let changeValue = null;
        if (change != null) {
          changeValue = change.new;
        }
        return (
          <WorksheetField
            orgId={orgId}
            planAppId={planAppId}
            worksheetId={worksheetId}
            question={question}
            answer={(!form.dirty && changeValue !== null) ? changeValue : answerValue}
            handleChange={(questionId, value) => {
              if (isBooleanValue(value) && confirmClearConditionals) {
                confirmClearConditionals(questionId, value, form, field);
              } else {
                form.setFieldValue(field.name, value, false /* <= shouldValidate */);
              }
            }}
            disabled={disabled || form.isSubmitting}
          />
        );
      }}
    />
  );

  if (question && question.isBaseQuestion) {
    return (
      <BaseQuestion
        question={question}
        orgId={orgId}
        planAppId={planAppId}
        worksheetId={worksheetId}
        field={field}
        tooltip={question.tooltip}
        disabled={disabled}
      />
    );
  }

  return <Block>{field}</Block>;
};

WorksheetQuestion.defaultProps = {
  disabled: false,
};

WorksheetQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  question: worksheetQuestionType.isRequired,
  orgId: orgIdType.isRequired,
  planAppId: planAppIdType.isRequired,
  worksheetId: worksheetIdType.isRequired,
  confirmClearConditionals: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  conditionalAnswerValue: PropTypes.any,
  // TBI
  // changeRequest: PropTypes.bool.isRequired,
};

export default WorksheetQuestion;
