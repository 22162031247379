import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { isStateOpen } from 'sow/utils/planAppState';

import Block from 'sow/components/atoms/Block';
import PlanTable from './PlanTable';

const OrgPlanAppTable = ({ acaName, orgId, planAppSummaryList }) => {
  const sortedPlanAppList = R.sortWith(
    [R.descend(isStateOpen), R.descend(R.prop('insertedAt'))],
    planAppSummaryList,
  );

  const hasOpenPlanApp = R.any(isStateOpen, sortedPlanAppList);

  const historicalPlans = sortedPlanAppList.filter((_, i) => i > 0);

  return (
    <Block>
      <PlanTable
        orgId={orgId}
        acaName={acaName}
        planList={historicalPlans}
        hasOpenPlanApp={hasOpenPlanApp}
      />
    </Block>
  );
};

OrgPlanAppTable.propTypes = {
  acaName: PropTypes.string.isRequired,
  orgId: sowTypes.orgIdType.isRequired,
  planAppSummaryList: PropTypes.arrayOf(sowTypes.planAppSummaryType).isRequired,
};

export default OrgPlanAppTable;
