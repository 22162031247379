import React, { Fragment, useRef } from 'react';
import { Field } from 'formik';
import { useDrag, useDrop } from 'react-dnd';

import Block from 'sow/components/atoms/Block';
import FormikField from 'sow/components/organisms/FormikField';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import FormikSingleCheckbox from 'sow/components/molecules/FormikSingleCheckbox';

const PicklistModalItem = ({ index, removeItem, reorderList, updateOrder }) => {
  const ref = useRef(null);

  const [{ isOver }, drop] = useDrop({
    accept: 'item',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex !== hoverIndex) {
        reorderList(dragIndex, hoverIndex);
      }
      item.index = hoverIndex;
    },
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
      };
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'item', index },
    end(item) {
      updateOrder();
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));

  return (
    <div ref={ref} className={isDragging ? 'dragging-picklist-item' : 'picklist-item'}>
      {!isDragging && (
        <Fragment>
          <span ref={drag} className="material-icons dnd-icon">
            drag_indicator
          </span>
          <Block className="clear-margin">
            <Field name={`picklist_item_list[${index}].name`} component={FormikField} />
          </Block>
          <Block className="clear-margin">
            <Field
              name={`picklist_item_list[${index}].allow_additional_info`}
              labelText="Additional Information"
              component={FormikField}
              type={FormikSingleCheckbox}
            />
          </Block>
          <Block className="remove-button" onClick={() => removeItem(index)}>
            <FontAwesome icon="x" />
          </Block>
        </Fragment>
      )}
    </div>
  );
};

export default PicklistModalItem;
