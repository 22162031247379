import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push as routerPush } from 'connected-react-router';

import { requestPasswordReset } from 'sow/actions/pure/resetPassword';
import { requestNewPasswordDoneRoute } from 'sow/routes';
import FormGroup from 'sow/components/organisms/FormGroup';
import CenteredLayout from 'sow/components/templates/CenteredLayout';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';

const mapStateToProps = state => ({
  isSubmitting: state.passwordReset.isSubmittingPasswordResetRequest,
});

const mapDispatchToProps = {
  requestPasswordReset,
  redirect: routerPush,
};

class RequestNewPassword extends Component {
  state = { email: '' };

  handleEmailChange = event => {
    this.setState({ email: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.requestPasswordReset(this.state.email, this.handleSubmitSuccess);
  };

  handleUnknownEmail = event => {
    event.preventDefault();
    Intercom('showNewMessage', 'I am unsure of my email address and need help');
  };

  handleSubmitSuccess = () => {
    this.props.redirect(requestNewPasswordDoneRoute());
  };

  render() {
    const { email } = this.state;
    const { isSubmitting } = this.props;

    return (
      <div className="body-wrapper">
        <CenteredLayout className="body-wrapper">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-left">
              <form onSubmit={this.handleSubmit}>
                <Widget>
                  <WidgetHeading>Password Reset</WidgetHeading>

                  <WidgetBody>
                    <p>
                      If you have forgotten your password, we will send a reset link to the{' '}
                      e-mail address on your account.
                    </p>
                    <p>
                      If you’re unsure of your e-mail address, please submit a{' '}
                      <a href="#" onClick={this.handleUnknownEmail}>
                        support request
                      </a>
                      .
                    </p>

                    <FormGroup fieldName="email" label="Your email address" required>
                      <input
                        type="email"
                        value={email}
                        onChange={this.handleEmailChange}
                        className="form-control"
                        required
                      />
                    </FormGroup>
                  </WidgetBody>

                  <WidgetFooter className="text-right">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm"
                      disabled={isSubmitting}
                    >
                      Continue <span className="glyphicon glyphicon-chevron-right" />
                    </button>
                  </WidgetFooter>
                </Widget>
              </form>
            </div>
          </div>
        </CenteredLayout>
      </div>
    );
  }
}

const routedComponent = withRouter(RequestNewPassword);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(routedComponent);
