const FILTER_NAME = 'sow_dashboard_filter';

export function setFilter(filter, rememberMe = true) {
  if (rememberMe) {
    localStorage.setItem(FILTER_NAME, filter);
  } else {
    sessionStorage.setItem(FILTER_NAME, filter);
  }
}

export function removeFilter() {
  localStorage.removeItem(FILTER_NAME);
  sessionStorage.removeItem(FILTER_NAME);
}

export function getFilter() {
  const ls_token = localStorage.getItem(FILTER_NAME);
  return ls_token || sessionStorage.getItem(FILTER_NAME);
}
