import React from 'react';
import * as R from 'ramda';
import { withRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import PlanAppStepperStep from 'sow/components/organisms/PlanAppStepperStep';

const PlanAppStepperStepContainer = ({ to, exact, strict, location, ...props }) => {
  return (
    <Route
      path={typeof to === 'object' ? to.pathname : to}
      exact={exact}
      strict={strict}
      location={location}
    >
      {({ match }) => (
        <PlanAppStepperStep {...props} to={to} isActive={!R.isNil(match)} />
      )}
    </Route>
  );
};

PlanAppStepperStepContainer.propTypes = {
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  strict: PropTypes.bool,

  // From react-router
  location: PropTypes.object.isRequired,
};

PlanAppStepperStepContainer.defaultProps = {
  exact: false,
  strict: false,
};

export default withRouter(PlanAppStepperStepContainer);
