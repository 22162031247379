import * as R from 'ramda';
import { v4 as guid } from 'uuid';

import { moveListItemAway, moveListItemCloser } from 'sow/utils/data';

import { INIT_STATE } from './OspDefEditor';
import { QUESTION_ADD, QUESTION_REMOVE } from './worksheets';

// Actions
export const ANSWER_ADD = 'OspDefEditor/worksheetQuestions/ANSWER_ADD';
export const ANSWER_MOVE_DOWN = 'OspDefEditor/worksheetQuestions/ANSWER_MOVE_DOWN';
export const ANSWER_MOVE_UP = 'OspDefEditor/worksheetQuestions/ANSWER_MOVE_UP';
export const ANSWER_REMOVE = 'OspDefEditor/worksheetQuestions/ANSWER_REMOVE';
export const ANSWER_SET_VALUE = 'OspDefEditor/worksheetQuestions/ANSWER_SET_VALUE';
export const CONDITION_REMOVE = 'OspDefEditor/worksheetQuestions/CONDITION_REMOVE';
export const CONDITION_SET_ANSWER_VALUE =
  'OspDefEditor/worksheetQuestions/CONDITION_SET_ANSWER_VALUE';
export const CONDITION_SET_QUESTION_UUID =
  'OspDefEditor/worksheetQuestions/CONDITION_SET_QUESTION_UUID';
export const SET_VALUE = 'OspDefEditor/worksheetQuestions/SET_VALUE';

// entity defauts/creators
export const getAnswerDefaults = () => ({
  uuid: guid(),
  value: '',
});

export const getConditionDefaults = (questionId, answerValue) => ({
  condition_type: 'basic',
  condition: {
    question_uuid: questionId,
    answer_value: answerValue,
  },
});

export function createAnswer(defaultValues = {}) {
  return R.merge(getAnswerDefaults(), defaultValues);
}

// Reducer functions
const tagAnswersMapper = R.map(value => createAnswer({ value }));
const untagAnswersMapper = R.map(R.prop('value'));
const tagAnswersLens = R.lensProp('answers');
const answersTagger = R.map(R.over(tagAnswersLens, tagAnswersMapper));
export const answersUntagger = R.map(R.over(tagAnswersLens, untagAnswersMapper));
export const reduceInitializeState = (state = {}, action = {}) => {
  return R.compose(answersTagger, R.path(['payload', 'worksheetQuestions']))(action);
};

export const reduceQuestionAdd = (state = {}, action = {}) => {
  const { question } = action.data;
  const questionId = question.uuid;
  return R.assoc(questionId, question, state);
};

export const reduceQuestionRemove = (state = {}, action = {}) => {
  return R.dissoc(action.data.questionId, state);
};

export const reduceQuestionSetValue = (state = {}, action = {}) => {
  const { questionId, field, value } = action.data;
  return R.assocPath([questionId, field], value, state);
};

export const reduceAnswerAdd = (state = {}, action = {}) => {
  const { answer, questionId } = action.data;
  const lens = R.lensPath([questionId, 'answers']);
  return R.over(lens, R.append(answer), state);
};

export const reduceAnswerMoveDown = (state = {}, action = {}) => {
  const { answerId, questionId } = action.data;
  const lens = R.lensPath([questionId, 'answers']);
  const all = R.view(lens, state);
  const count = R.length(all);
  const idx = R.findIndex(R.propEq('uuid', answerId), all);

  // already last item so do nothing
  if (idx >= count - 1) return state;

  return R.over(lens, moveListItemAway(idx), state);
};

export const reduceAnswerMoveUp = (state = {}, action = {}) => {
  const { answerId, questionId } = action.data;
  const lens = R.lensPath([questionId, 'answers']);
  const all = R.view(lens, state);
  const count = R.length(all);
  const idx = R.findIndex(R.propEq('uuid', answerId), all);

  // already first item so do nothing
  if (idx < 1) return state;

  return R.over(lens, moveListItemCloser(idx), state);
};

export const reduceAnswerRemove = (state = {}, action = {}) => {
  const { answerId, questionId } = action.data;
  const lens = R.lensPath([questionId, 'answers']);
  const pred = R.whereEq({ uuid: answerId });
  return R.over(lens, R.reject(pred), state);
};

export const reduceAnswerSetValue = (state = {}, action = {}) => {
  const { answerId, questionId, value } = action.data;
  const lens = R.lensPath([questionId, 'answers']);
  const pred = R.whereEq({ uuid: answerId });
  const op = R.assoc('value', value);
  return R.over(lens, R.map(R.when(pred, op)), state);
};

export const reduceConditionRemove = (state = {}, action = {}) => {
  const { questionId, conditionIndex } = action.data;
  return R.dissocPath([questionId, 'conditions', conditionIndex], state);
};

export const reduceConditionSetAnswerValue = (state = {}, action = {}) => {
  const { questionId, conditionIndex, conditionAnswerValue } = action.data;

  // get current condition answer_value
  const conditionQuestionId = R.path(
    [questionId, 'conditions', conditionIndex, 'condition', 'question_uuid'],
    state,
  );

  // create new condition object every time so it works when none exist yet
  const condition = getConditionDefaults(conditionQuestionId, conditionAnswerValue);

  // replace existing object
  return R.assocPath([questionId, 'conditions', conditionIndex], condition, state);
};

export const reduceConditionSetQuestionId = (state = {}, action = {}) => {
  const { questionId, conditionIndex, conditionQuestionId } = action.data;

  // get current condition answer_value
  const conditionAnswerValue = R.path(
    [questionId, 'conditions', conditionIndex, 'condition', 'answer_value'],
    state,
  );

  // create new condition object every time so it works when none exist yet
  const condition = getConditionDefaults(conditionQuestionId, conditionAnswerValue);

  // replace existing object
  return R.assocPath([questionId, 'conditions', conditionIndex], condition, state);
};

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case INIT_STATE:
      return reduceInitializeState(state, action);

    case QUESTION_ADD:
      return reduceQuestionAdd(state, action);

    case QUESTION_REMOVE:
      return reduceQuestionRemove(state, action);

    case SET_VALUE:
      return reduceQuestionSetValue(state, action);

    // ANSWERS
    case ANSWER_ADD:
      return reduceAnswerAdd(state, action);

    case ANSWER_MOVE_DOWN:
      return reduceAnswerMoveDown(state, action);

    case ANSWER_MOVE_UP:
      return reduceAnswerMoveUp(state, action);

    case ANSWER_REMOVE:
      return reduceAnswerRemove(state, action);

    case ANSWER_SET_VALUE:
      return reduceAnswerSetValue(state, action);

    case CONDITION_REMOVE:
      return reduceConditionRemove(state, action);

    case CONDITION_SET_ANSWER_VALUE:
      return reduceConditionSetAnswerValue(state, action);

    case CONDITION_SET_QUESTION_UUID:
      return reduceConditionSetQuestionId(state, action);

    default:
      return state;
  }
}

// Action Creators
export function setValue(questionId, field, value) {
  return {
    type: SET_VALUE,
    data: { questionId, field, value },
  };
}

export function answerAdd(questionId) {
  const answer = createAnswer();
  return { type: ANSWER_ADD, data: { questionId, answer } };
}

export function answerMoveDown(questionId, answerId) {
  return { type: ANSWER_MOVE_DOWN, data: { questionId, answerId } };
}

export function answerMoveUp(questionId, answerId) {
  return { type: ANSWER_MOVE_UP, data: { questionId, answerId } };
}

export function answerRemove(questionId, answerId) {
  return { type: ANSWER_REMOVE, data: { questionId, answerId } };
}

export function answerSetValue(questionId, answerId, value) {
  return {
    type: ANSWER_SET_VALUE,
    data: { questionId, answerId, value },
  };
}

export function conditionRemove(questionId, conditionIndex) {
  return {
    type: CONDITION_REMOVE,
    data: {
      questionId,
      conditionIndex,
    },
  };
}

export function conditionSetAnswerValue(
  questionId,
  conditionIndex,
  conditionAnswerValue,
) {
  return {
    type: CONDITION_SET_ANSWER_VALUE,
    data: {
      questionId,
      conditionIndex,
      conditionAnswerValue,
    },
  };
}

export function conditionSetQuestionId(questionId, conditionIndex, conditionQuestionId) {
  return {
    type: CONDITION_SET_QUESTION_UUID,
    data: {
      questionId,
      conditionIndex,
      conditionQuestionId,
    },
  };
}

// Action Creator Export Map
export const actions = {
  setValue,

  // answers
  answerAdd,
  answerMoveDown,
  answerMoveUp,
  answerRemove,
  answerSetValue,

  // conditions
  conditionRemove,
  conditionSetAnswerValue,
  conditionSetQuestionId,
};
