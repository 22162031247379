import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import Button from 'sow/components/atoms/Button';

/** Information modal that can be toggled from components */
class InfoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.defaultShow,
    };
  }

  setVisibility = show => () => this.setState({ show });

  handleAcknowledge = () => {
    const { onAcknowledge } = this.props;
    if (onAcknowledge) onAcknowledge();
    this.setVisibility(false)();
  };

  render() {
    const { show } = this.state;
    const { title, message, children, buttonText } = this.props;

    return (
      <Fragment>
        {children(this.setVisibility(true))}

        <Modal show={show} onHide={this.handleAcknowledge} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>{message}</Modal.Body>

          <Modal.Footer>
            <Button onClick={this.handleAcknowledge} primary>
              {buttonText}
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

InfoModal.propTypes = {
  defaultShow: PropTypes.bool,
  onAcknowledge: PropTypes.func,
  title: PropTypes.node,
  message: PropTypes.node.isRequired,
  children: PropTypes.func.isRequired,
  buttonText: PropTypes.node,
};

InfoModal.defaultProps = {
  defaultShow: false,
  onAcknowledge: null,
  title: null,
  buttonText: 'Ok',
};

export default InfoModal;
