const mark = action => `ACCESS_TYPE/${action}`;

export const LOAD_ACCESS_TYPES = mark('LOAD_ACCESS_TYPES');
export const LOAD_ACCESS_TYPES_DONE = mark('LOAD_ACCESS_TYPES_DONE');
export const LOAD_ACCESS_TYPE_BY_ID = mark('LOAD_ACCESS_TYPE_BY_ID');
export const ADD_ACCESS_TYPE = mark('ADD_ACCESS_TYPE');
export const DELETE_ACCESS_TYPE = mark('DELETE_ACCESS_TYPE');
export const UPDATE_ACCESS_TYPE = mark('UPDATE_ACCESS_TYPE');

export const loadAccessTypes = (payload, onSuccess) => ({
  type: LOAD_ACCESS_TYPES,
  payload,
  meta: { onSuccess },
});

export const loadAccessTypesDone = payload => ({
  type: LOAD_ACCESS_TYPES_DONE,
  payload,
});

export const loadAccessTypeById = (id, onSuccess) => ({
  type: LOAD_ACCESS_TYPE_BY_ID,
  id,
  meta: { onSuccess },
});

export const addAccessType = (payload, onSuccess) => ({
  type: ADD_ACCESS_TYPE,
  payload,
  meta: { onSuccess },
});

export const updateAccessType = (id, payload, onSuccess) => ({
  type: UPDATE_ACCESS_TYPE,
  id,
  payload,
  meta: { onSuccess },
});

export const deleteAccessType = (id, onSuccess) => ({
  type: DELETE_ACCESS_TYPE,
  id,
  meta: { onSuccess },
});
