import React, { Fragment } from 'react';

import Spinner from 'sow/components/atoms/Spinner';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import ImprovedDataTable from 'sow/components/molecules/ImprovedDataTable';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';

const LocationReportTable = ({ fetchingList, locationReport }) => (
  <Fragment>
    {fetchingList ? (
      <Spinner />
    ) : (
      <Widget>
        <WidgetHeading>Locations</WidgetHeading>
        <WidgetBody>
          <ImprovedDataTable
            selector={'.locationTable'}
            tableDef={{
              pageLength: 50,
              destroy: true,
              searching: false,
              data: locationReport,
              deferRender: true,
              order: [],
              columns: [{ data: 'location' }, { data: 'org' }, { data: 'location_type' }],
              columnDefs: [
                {
                  targets: 0,
                  render: location =>
                    `<a target="_blank" href="/org/${location.org_id}/plan/${location.application_id}/location/${location.id}">${location.name}</a>`,
                },
                {
                  targets: 1,
                  render: org =>
                    `<a target="_blank" href="/org/${org.id}">${org.name}</a>`,
                },
              ],
            }}
          >
            <Table
              className={'locationTable'}
              bordered
              striped
              condensed
              head={
                <TableRow>
                  <TableCell heading textCenter>
                    Location Name
                  </TableCell>
                  <TableCell heading textCenter>
                    Organization
                  </TableCell>
                  <TableCell heading textCenter>
                    Location Type
                  </TableCell>
                </TableRow>
              }
              style={{ width: '100%' }}
            >
              <TableRow></TableRow>
            </Table>
          </ImprovedDataTable>
        </WidgetBody>
      </Widget>
    )}
  </Fragment>
);

export default LocationReportTable;
