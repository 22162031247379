import * as R from 'ramda';
import { createSelector } from 'reselect';
import * as fromChanges from 'sow/reducers/worksheetAnswers/changes';
import * as wsAnswersReducer from 'sow/reducers/worksheetAnswers';
import { currentOspAppId } from 'sow/selectors/core/ospApplication';

export const getIsNotApplicable = wsAnswersReducer.getIsNotApplicable;

export const filterWorksheetId = worksheetIds => wsAnswer =>
  R.contains(wsAnswersReducer.getWorksheetId(wsAnswer), worksheetIds);

export const worksheetAnswersState = state => state.worksheetAnswers;

export const isLoadingForCurrentOspApp = createSelector(
  [worksheetAnswersState, currentOspAppId],
  wsAnswersReducer.getIsLoadingForOspApp,
);

export const getChangesForWorksheet = (state, ospAppId, worksheetId) =>
  fromChanges.getChangesForWorksheet(state, ospAppId, worksheetId);

export const getRawChangesForWorksheet = (state, ospAppId, worksheetId) =>
  fromChanges.getRawChangesForWorksheet(state, ospAppId, worksheetId);
