import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { utcStrToLocalMoment } from 'sow/utils/dateTime';

import './WorkflowManagerList.module.css';
import { userTypeId, userSubtypeId } from 'sow/constants/userType';
import { planAppStateText } from 'sow/constants/planApp';
import { removeSpecialist } from 'sow/actions/orgWorkflow';
import { API_URL } from 'sow/constants/api';
import ImprovedDataTable from 'sow/components/molecules/ImprovedDataTable';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
// import OrgUserList from 'sow/components/organisms/OrgUserList';
import Button from 'sow/components/atoms/Button';
import PriorityFlag from 'sow/components/molecules/PriorityFlag';
import CountdownFlag from 'sow/components/molecules/CountdownFlag';
import CertificationCountdown from 'sow/components/molecules/CertificationCountdown';
import Image from 'sow/components/atoms/Image';

const mapStateToProps = state => ({
  specialistsByOrg: state.dashboard.specialistsByOrg,
});

const mapDispatchToProps = {
  removeSpecialist: removeSpecialist,
};

const AssignedSpecialists = ({ orgId, userList, removeUserFromOrg, type }) => {
  if (userList) {
    return userList
      .filter(user => user.type === type)
      .map(user => {
        return (
          <div style={{ whiteSpace: 'nowrap' }} key={user.id}>
            <span>{user.name}</span>
            <span>
              <Button
                xs
                onClick={() =>
                  removeUserFromOrg(orgId, user.id, userTypeId.specialist, user.type)
                }
              >
                x
              </Button>
            </span>
          </div>
        );
      });
  } else {
    return null;
  }
};

const statusStyle = appState =>
  appState === 'noncompliance' ? { color: 'red', clear: 'right' } : { clear: 'right' };

class WorkflowManagerList extends Component {
  constructor(props) {
    super(props);
  }

  removeUserFromOrg(orgId, userId, userTypeId, userSubtypeId) {
    const { removeSpecialist, specialistsByOrg } = this.props;

    const index = specialistsByOrg[orgId].findIndex(
      user => user.id == userId && user.type == userSubtypeId,
    );
    if (index != -1) {
      const newVal = R.remove(index, 1, specialistsByOrg[orgId]);
      specialistsByOrg[orgId] = [...newVal];
      removeSpecialist({ orgId, userId, userTypeId, userSubtypeId, specialistsByOrg });
    }
  }

  render() {
    return (
      <div className="workflowManagerList">
        <div className="tableDiv">
          {this.props.dashboardList.length > 0 ? (
            <ImprovedDataTable
              selector={'.workflowManagerTable'}
              tableDef={{
                pageLength: 50,
                destroy: true,
                stateSave: true,
                deferRender: true,
                data: this.props.dashboardList,
                columns: [
                  { data: 'organization_data' },
                  { data: 'countdown_data' },
                  { data: 'status' },
                  { data: 'change_updated_at' },
                  { data: 'organization_types' },
                  { data: 'organization_id' },
                  { data: 'organization_id' },
                  { data: 'certifications' },
                ],
                columnDefs: [
                  {
                    targets: [0, 1, 2, 3, 4, 5, 6],
                    className: 'dt-body-center',
                  },
                  {
                    targets: 0,
                    render: data => {
                      return `<a class="org-link" href="/org/${data.organization_id}">${data.organization_name}</a>`;
                    },
                  },
                  {
                    targets: 1,
                    createdCell: (td, cellData, rowData, row, col) => {
                      ReactDOM.render(
                        <Fragment>
                          <CountdownFlag ospApp={cellData} />
                          <div style={{ clear: 'right' }}>
                            <CertificationCountdown ospApp={cellData} />
                          </div>
                        </Fragment>,
                        td,
                      );
                    },
                  },
                  {
                    targets: 2,
                    createdCell: (td, cellData, rowData, row, col) => {
                      ReactDOM.render(
                        <Fragment>
                          <PriorityFlag flags={cellData.application_priority_flags} />
                          <div style={statusStyle(cellData.app_state)}>
                            {planAppStateText[cellData.app_state]}
                          </div>
                        </Fragment>,
                        td,
                      );
                    },
                    render: data => {
                      return planAppStateText[data.app_state];
                    },
                  },
                  {
                    type: 'date',
                    targets: 3,
                    render: data => {
                      if (data) {
                        return `${utcStrToLocalMoment(data).format('L LT')}`;
                      } else {
                        return '';
                      }
                    },
                  },
                  {
                    targets: 5,
                    createdCell: (td, cellData) => {
                      ReactDOM.render(
                        <AssignedSpecialists
                          orgId={cellData}
                          userList={this.props.specialistsByOrg[cellData]}
                          removeUserFromOrg={this.removeUserFromOrg.bind(this)}
                          type={userSubtypeId.initialReviewer}
                        />,
                        td,
                      );
                    },
                    render: data => {
                      if (this.props.specialistsByOrg[data]) {
                        return this.props.specialistsByOrg[data]
                          .filter(user => user.type === userSubtypeId.initialReviewer)
                          .map(user => user.name)
                          .join('');
                      }
                      return '';
                    },
                  },
                  {
                    targets: 6,
                    createdCell: (td, cellData) => {
                      ReactDOM.render(
                        <AssignedSpecialists
                          orgId={cellData}
                          userList={this.props.specialistsByOrg[cellData]}
                          removeUserFromOrg={this.removeUserFromOrg.bind(this)}
                          type={userSubtypeId.finalReviewer}
                        />,
                        td,
                      );
                    },
                    render: data => {
                      if (this.props.specialistsByOrg[data]) {
                        return this.props.specialistsByOrg[data]
                          .filter(user => user.type === userSubtypeId.finalReviewer)
                          .map(user => user.name)
                          .join('');
                      }
                      return '';
                    },
                  },
                  {
                    targets: 7,
                    createdCell: (td, cellData) => {
                      if (cellData) {
                        const imageIcons = cellData.map((cert, index) => (
                          <Image
                            key={index}
                            imageUrl={`${API_URL}/v1/file/by_path/${cert.s3_name}`}
                            title={`${cert.name}`}
                          />
                        ));
                        ReactDOM.render(imageIcons, td);
                      }
                    },
                    render: data => {
                      if (data) {
                        return data.map(cert => cert.name).join('');
                      }
                      return '';
                    },
                  },
                ],
              }}
            >
              <Table
                className="workflowManagerTable"
                bordered
                striped
                condensed
                head={
                  <TableRow>
                    <TableCell heading textCenter className="medium-column">
                      Client
                      {/* removing sortby for now <Link onClick={sortBy('name')}>Client</Link> */}
                    </TableCell>
                    <TableCell heading textCenter className="small-column">
                      Countdown
                    </TableCell>
                    <TableCell heading textCenter className="medium-column">
                      Status
                    </TableCell>
                    <TableCell heading textCenter className="medium-column">
                      Last Change
                    </TableCell>
                    <TableCell heading textCenter className="small-column">
                      Category
                    </TableCell>
                    <TableCell heading textCenter className="small-column">
                      Initial Reviewer
                    </TableCell>
                    <TableCell heading textCenter className="small-column">
                      Final Reviewer
                    </TableCell>
                    <TableCell heading textCenter className="large-column">
                      Certifications
                    </TableCell>
                  </TableRow>
                }
              >
                <TableRow></TableRow>
              </Table>
            </ImprovedDataTable>
          ) : (
            <Widget>
              <WidgetBody className="no-results">No organizations found.</WidgetBody>
            </Widget>
          )}
        </div>
      </div>
    );
  }
}

WorkflowManagerList.propTypes = {
  dashboardList: PropTypes.array,
  orgId: PropTypes.number,
  removeSpecialist: PropTypes.func,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(WorkflowManagerList);
