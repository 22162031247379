import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import AnnouncementsEditContainer from 'sow/containers/AnnouncementsEditContainer';

const AnnouncementsEditPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <AnnouncementsEditContainer />}
  </AdminPageTemplate>
);

export default AnnouncementsEditPage;
