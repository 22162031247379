import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import Spinner from 'sow/components/atoms/Spinner';

const isAllInitial = R.all(R.prop('initial'));
const isAllDone = R.all(R.prop('done'));
const isAllSuccess = R.all(R.prop('success'));
const isAnyError = R.any(R.prop('error'));
const isAnyLoading = R.any(R.prop('loading'));

const ResourceLoaderStatusRender = ({
  renderAllDone = () => null,
  renderAllInitial = () => null,
  renderAllSuccess = () => null,
  renderAnyError = () => null,
  renderAnyLoading = () => <Spinner />, // eslint-disable-line react/display-name
  statusObjects,
}) => {
  return (
    <Fragment>
      {isAllInitial(statusObjects) && renderAllInitial()}
      {isAnyLoading(statusObjects) && renderAnyLoading()}
      {isAnyError(statusObjects) && renderAnyError()}
      {isAllSuccess(statusObjects) && renderAllSuccess()}
      {isAllDone(statusObjects) && renderAllDone()}
    </Fragment>
  );
};

export const resourceLoaderStatusObjectType = PropTypes.shape({
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  initial: PropTypes.bool.isRequired,
  done: PropTypes.bool.isRequired,
});

ResourceLoaderStatusRender.propTypes = {
  /* array of status objects returned from ResourceLoader */
  statusObjects: PropTypes.arrayOf(resourceLoaderStatusObjectType).isRequired,

  /* renders when ALL status objects are `initial` */
  renderAllInitial: PropTypes.func,

  /* renders when ANY status object is `loading` and none `error` */
  renderAnyLoading: PropTypes.func,

  /* renders when ANY status object is `error` */
  renderAnyError: PropTypes.func,

  /* renders when ALL status objects are `success` */
  renderAllSuccess: PropTypes.func,

  /* renders when ALL status objects are `done` */
  renderAllDone: PropTypes.func,
};

export default ResourceLoaderStatusRender;
