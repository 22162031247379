import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { planAppQualificationQuestionType } from 'sow/types';

import Block from 'sow/components/atoms/Block';
import Checkbox from 'sow/components/molecules/Checkbox';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';

const CheckboxItem = ({ question, checked, onChange, disabled }) => (
  <Checkbox
    value="1"
    onChange={() => onChange(question)}
    checked={checked}
    disabled={disabled}
  >
    {question.question}
  </Checkbox>
);

CheckboxItem.propTypes = {
  checked: PropTypes.bool.isRequired,
  question: planAppQualificationQuestionType.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const QualificationChecklistQuestion = ({ question, answers, onChange, disabled }) => {
  const checked = R.contains(R.__, answers);

  return (
    <Widget style={{ marginBottom: '10px' }}>
      <WidgetBody>
        <CheckboxItem
          checked={checked(question.uuid)}
          question={question}
          onChange={onChange}
          disabled={disabled}
        />

        {checked(question.uuid) && (
          <Block style={{ marginTop: '5px' }}>
            <Block className="sub-question">
              {question.subQuestions.map(q => (
                <CheckboxItem
                  key={q.uuid}
                  question={q}
                  checked={checked(q.uuid)}
                  onChange={onChange}
                  disabled={disabled}
                />
              ))}
            </Block>
          </Block>
        )}
      </WidgetBody>
    </Widget>
  );
};

QualificationChecklistQuestion.propTypes = {
  question: planAppQualificationQuestionType.isRequired,
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default QualificationChecklistQuestion;
