import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as currentUser from 'sow/selectors/currentUser';
import * as sowTypes from 'sow/types';
import { resourceListRead, resourceUpdate } from 'sow/store/helpers';
import { fromPlanApp } from 'sow/store/selectors';
import { resourceListReadRequest } from 'sow/store/actions';

import OrgDashboardActionButtonsAca from 'sow/components/organisms/OrgDashboardActionButtonsAca';

const withResourceHelpers = () => {
  const mapStateToProps = (state, props) => ({
    orgId: fromPlanApp.orgId(state, props),
    isPlanAppIdMostRecent: fromPlanApp.isPlanAppIdMostRecent(state, props),
  });

  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { orgId } = stateProps;
    const { planAppId } = ownProps;
    const baseUrl = `org/${orgId}/application/${planAppId}`;
    return {
      ...stateProps,
      ...ownProps,
      complete: resourceUpdate(`${baseUrl}/complete`, 'planAppResp'),
      markNoncompliant: resourceUpdate(`${baseUrl}/noncompliant`, 'planAppResp'),
      withdrawPlan: resourceUpdate(`${baseUrl}/withdraw`, 'planAppResp'),
      unwithdrawPlan: resourceUpdate(`${baseUrl}/unwithdraw`, 'planAppResp'),
      revokePlan: resourceUpdate(`${baseUrl}/decommission/revoked`, 'planAppResp'),
      surrenderPlan: resourceUpdate(`${baseUrl}/decommission/surrendered`, 'planAppResp'),
      suspendPlan: resourceUpdate(`${baseUrl}/decommission/suspended`, 'planAppResp'),
    };
  };

  return connect(mapStateToProps, null, mergeProps);
};

const mapStateToProps = (state, props) => {
  const {
    complete,
    markNoncompliant,
    withdrawPlan,
    unwithdrawPlan,
    revokePlan,
    surrenderPlan,
    suspendPlan,
  } = props;

  return {
    hasOpenPlanApp: fromPlanApp.hasOpenPlanApp(state, props),
    showAcaUI: currentUser.showAcaUI(state, props),
    showInspectorUI: currentUser.showInspectorUI(state, props),
    changeRequestCanBeApplied: fromPlanApp.changeRequestCanBeApplied(state, props),
    disabled: R.any(R.equals(true), [
      complete.selectors.pending(state, props),
      markNoncompliant.selectors.pending(state, props),
      withdrawPlan.selectors.pending(state, props),
      unwithdrawPlan.selectors.pending(state, props),
      revokePlan.selectors.pending(state, props),
      surrenderPlan.selectors.pending(state, props),
      suspendPlan.selectors.pending(state, props),
    ]),
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    submitInitial,
    submitUpdate,
    complete,
    markNoncompliant,
    unlockForUpdate,
    withdrawPlan,
    unwithdrawPlan,
    revokePlan,
    surrenderPlan,
    suspendPlan,
    orgId,
  },
) => {
  return {
    onSubmitInitial: () => dispatch(submitInitial.action()),
    onSubmitUpdate: () => dispatch(submitUpdate.action()),
    onComplete: async () => {
      // Submit the plan to be marked 'completed'. Changes will be rolled over...
      const resp = await dispatch(complete.action());

      // Load the new list of changes for this plan
      const planAppId = R.path(['data', 'detail', 'id'], resp);
      const changeRequestId = R.path(['data', 'detail', 'changeRequest', 'id'], resp);
      const loadChangesUrl = `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`;
      return dispatch(resourceListRead(loadChangesUrl, 'change').action());
    },
    onMarkNoncompliant: () => dispatch(markNoncompliant.action()),
    onUnlockForUpdate: () => dispatch(unlockForUpdate.action()),
    onWithdrawPlan: () => dispatch(withdrawPlan.action()),
    onUnwithdrawPlan: () => dispatch(unwithdrawPlan.action()),
    onRevokePlan: () => dispatch(revokePlan.action()),
    onSurrenderPlan: () => dispatch(surrenderPlan.action()),
    onSuspendPlan: () => dispatch(suspendPlan.action()),
    loadUsers: async orgId => {
      const url = `org/${orgId}/users`;
      const action = resourceListReadRequest(url);
      return await dispatch(action);
    },
  };
};

class OrgDashboardActionButtonsAcaContainer extends React.Component {
  state = {
    orgUserList: [],
    email: '',
  };

  componentDidMount() {
    const { loadUsers } = this.props;
    const fetchUserEmail = async () => {
      const response = await loadUsers(this.props.orgId);
      const users = await response.data;
      this.setState({
        orgUserList: users,
        email: users[0].email,
      });
    };
    fetchUserEmail();
  }

  render() {
    const { email } = this.state;
    return <OrgDashboardActionButtonsAca {...this.props} email={email} />;
  }
}

OrgDashboardActionButtonsAcaContainer.propTypes = {
  // Passed props
  planAppId: sowTypes.planAppIdType.isRequired,
  planApp: sowTypes.planAppType.isRequired,

  // Connected props
  orgId: sowTypes.orgIdType.isRequired,
  hasOpenPlanApp: PropTypes.bool.isRequired,
  isPlanAppIdMostRecent: PropTypes.bool.isRequired,
  showAcaUI: PropTypes.bool,
  showInspectorUI: PropTypes.bool,
  disabled: PropTypes.bool,
  onComplete: PropTypes.func.isRequired,
  onMarkNoncompliant: PropTypes.func.isRequired,
  onWithdrawPlan: PropTypes.func.isRequired,
  onRevokePlan: PropTypes.func.isRequired,
  onSurrenderPlan: PropTypes.func.isRequired,
  onSuspendPlan: PropTypes.func.isRequired,
};

OrgDashboardActionButtonsAcaContainer.defaultProps = {
  disabled: false,
};

export default R.compose(
  withRouter,
  withResourceHelpers(),
  connect(mapStateToProps, mapDispatchToProps),
)(OrgDashboardActionButtonsAcaContainer);
