import React from 'react';
import PropTypes from 'prop-types';
import RS from 'ramdasauce';

import Panel from 'sow/components/molecules/Panel';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Link from 'sow/components/atoms/Link';

const WorksheetRegulations = ({ regulations }) => {
  if (RS.isNilOrEmpty(regulations)) return null;

  return (
    <Panel title="Guidance &amp; Additional Regulations" noBody>
      <Table striped>
        <TableRow style={{ display: 'none' }}>
          <TableCell colSpan={2}>Fix first row bg white</TableCell>
        </TableRow>

        {regulations.map(reg => (
          <TableRow key={reg.uuid}>
            <TableCell textRight>
              <Link to={{ pathname: reg.url }} target="_blank">
                {reg.code}
              </Link>
            </TableCell>

            <TableCell>{reg.description}</TableCell>
          </TableRow>
        ))}
      </Table>
    </Panel>
  );
};

WorksheetRegulations.propTypes = {
  regulations: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      url: PropTypes.string,
      code: PropTypes.string,
    }),
  ),
};

export default WorksheetRegulations;
