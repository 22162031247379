import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import Block from 'sow/components/atoms/Block';
import Input from 'sow/components/atoms/Input';
import Spacer from 'sow/components/atoms/Spacer';
import Button from 'sow/components/atoms/Button';

class PlanAppChangeAddCommentForm extends Component {
  state = {
    comment: '',
  };

  handleChange = event => this.setState(R.assoc('comment', event.target.value));

  handleSubmit = () => {
    this.props.onSubmit(this.state.comment, this.handleSubmitDone);
  };

  handleSubmitDone = () => this.setState({ comment: '' });

  render() {
    const { disabled } = this.props;

    return (
      <Block>
        <Input
          type="textarea"
          placeholder="Add comment..."
          onChange={this.handleChange}
          value={this.state.comment}
          disabled={disabled}
        />
        <Spacer vertical={5} />

        <Button info sm onClick={this.handleSubmit} disabled={disabled}>
          Add Comment
        </Button>
        <Block textMuted>
          The other party will be notified automatically when your comment is added.
        </Block>
      </Block>
    );
  }
}

PlanAppChangeAddCommentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default PlanAppChangeAddCommentForm;
