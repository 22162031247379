import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { MenuItem, Glyphicon } from 'react-bootstrap';

import FormModal from 'sow/components/old/FormModal';
import FormPanel from 'sow/components/old/OspDefEditor/FormPanel';
import { confirm } from 'sow/actions/pure/messaging';
import { hocEditable } from 'sow/components/old/hocHelpers/uiState';

import { actions as wsAction } from '../reducers/worksheets';
import WorksheetQuestions from '../Worksheet/WorksheetQuestions';

const allWorksheetsSelector = state => state.worksheets;
const worksheetIdSelector = (state, props) => props.worksheetId;
const worksheetSelector = createSelector(
  [allWorksheetsSelector, worksheetIdSelector],
  (worksheet, worksheetId) => worksheet[worksheetId],
);

const mapDispatchToProps = (dispatch, props) => {
  return {
    // question event handlers
    onAddQuestion: R.curry((worksheetId, event) => {
      dispatch(wsAction.questionAdd(worksheetId));
    }),
    onDeleteQuestion: R.curry((worksheetId, questionId, event) =>
      dispatch(
        confirm({
          message: 'Are you sure you want to delete this worksheet question?',
          onConfirm: () => dispatch(wsAction.questionRemove(worksheetId, questionId)),
        }),
      ),
    ),
    onMoveQuestionDown: R.curry((worksheetId, questionId, event) => {
      dispatch(wsAction.questionMoveDown(worksheetId, questionId));
    }),
    onMoveQuestionUp: R.curry((worksheetId, questionId, event) => {
      dispatch(wsAction.questionMoveUp(worksheetId, questionId));
    }),
  };
};

const mapStateToProps = (componentState, ownProps) => ({
  worksheet: worksheetSelector(componentState, ownProps),
});

export const StaticWorksheet = ({
  title,
  worksheet,
  worksheetId,
  onDelete,
  onSetValue,
  onSetStaticValue,
  onMoveWorksheetDown,
  onMoveWorksheetUp,

  // question event handlers
  onAddQuestion,
  onDeleteQuestion,
  onMoveQuestionDown,
  onMoveQuestionUp,

  isEditing,
  onEditDone,
  onEditStart,
}) => {
  const questionIds = R.propOr([], 'questions', worksheet);
  const errors = {};

  const worksheetDropdown = (
    <FormPanel.Heading.Dropdown id={`Worksheet-${worksheetId}`}>
      <MenuItem onClick={onEditStart}>
        <Glyphicon glyph="pencil" /> Edit
      </MenuItem>
    </FormPanel.Heading.Dropdown>
  );

  return (
    <FormPanel.Body.ShortRow handlerId={worksheetId} customControl={worksheetDropdown}>
      {title}
      <FormModal
        bsSize="large"
        show={isEditing}
        title={title}
        onHide={onEditDone}
        onSubmit={onEditDone}
      >
        <WorksheetQuestions
          worksheetId={worksheetId}
          questionIds={questionIds}
          onAddQuestion={onAddQuestion}
          onDeleteQuestion={onDeleteQuestion}
          onMoveQuestionDown={onMoveQuestionDown}
          onMoveQuestionUp={onMoveQuestionUp}
        />
      </FormModal>
    </FormPanel.Body.ShortRow>
  );
};

export default R.compose(connect(mapStateToProps, mapDispatchToProps), hocEditable)(
  StaticWorksheet,
);
