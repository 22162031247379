import React from 'react';
import PropTypes from 'prop-types';

import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';
import Glyphicon from 'sow/components/molecules/Glyphicon';

const CompletedPlanLink = ({ linkTo, isNoncompliant }) => {
  return (
    <Link block btn warning to={linkTo}>
      View Most Recent Completed Plan
      {isNoncompliant && (
        <Block textWarning>
          <Glyphicon glyph="exclamation-sign" /> Contains Noncompliances
        </Block>
      )}
    </Link>
  );
};

CompletedPlanLink.propTypes = {
  linkTo: PropTypes.string.isRequired,
  isNoncompliant: PropTypes.bool.isRequired,
};

export default CompletedPlanLink;
