import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { __DO_NOT_USE_OR_YOU_WILL_BE_HAUNTED_BY_SPOOKY_GHOSTS as sc } from 'styled-components';
import { Provider } from 'react-redux';
import { MemoryRouter } from 'react-router';

import store from 'sow/store';
import PlanAppExportContent from 'sow/containers/planAppExport/Content';
import exportCss, { grdCss } from './css';

// NOTE: This gets sc style rules and join them into a single string
//       to be added to the static html in <style> tag. This hack was
//       taken from:
//    https://github.com/styled-components/styled-components/issues/1483#issuecomment-372145397
const getStyledCss = () => {
  const cssString = sc.StyleSheet.instance.tags.map(t => t.css()).join('');
  return cssString;
};

function truncate(content, limit) {
  const affix = content.length > limit ? '...' : '';
  return content.substr(0, limit) + affix;
}

/**
 * Return content HTML wrapped in header and
 **/
export const wrapHtmlContent = ({ head = '', title = '', content = '' } = {}) => {
  return `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="utf-8">
  <style>${grdCss}</style>
  <style>${exportCss}</style>
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.2/css/all.css" integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr" crossorigin="anonymous">
  ${head}
  <title>${truncate(title, 50)}</title>
</head>
<body>
  <div id="frame">
    ${content}
  </div>
</body>
</html>`.trim();
};

const renderHtml = ({ exportConfig, userList }, title) => {
  const contentHtml = ReactDOMServer.renderToStaticMarkup(
    <Provider store={store}>
      <MemoryRouter>
        <div>
          <PlanAppExportContent
            {...exportConfig}
            exportConfig={exportConfig}
            userList={userList}
          />
        </div>
      </MemoryRouter>
    </Provider>,
  );

  const styledCss = getStyledCss();

  const exportHtml = wrapHtmlContent({
    title,
    content: contentHtml,
    head: `<style>${styledCss}</style>`,
  });

  return exportHtml;
};

export default renderHtml;
