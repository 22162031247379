import React from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import actions from 'sow/actions/pure';
import LogoutButton from 'sow/components/organisms/LogoutButton';

const mapDispatchToProps = { onLogout: actions.auth.logout };

const LogoutButtonContainer = ({ onLogout }) => <LogoutButton onLogout={onLogout} />;

LogoutButtonContainer.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps,
  ),
)(LogoutButtonContainer);
