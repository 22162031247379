import React from 'react';
import * as R from 'ramda';
import { Formik, Form, Field } from 'formik';

import Spinner from 'sow/components/atoms/Spinner';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormikField from 'sow/components/organisms/FormikField';
import FormikReactSelect from 'sow/components/molecules/FormikReactSelect';
import Button from 'sow/components/atoms/Button';

const UnannouncedInspectionReportFilters = ({
  initialValues,
  filterOptions,
  handleSearch,
  resetForm,
}) => (
  <Widget className="search-filter-block">
    <WidgetHeading>Unannounced Inspection Filter</WidgetHeading>
    <WidgetBody>
      {filterOptions ? (
        <Formik initialValues={initialValues} onSubmit={handleSearch} onReset={resetForm}>
          {({ values, handleSubmit, handleReset }) => (
            <Form onSubmit={handleSubmit}>
              <Row className="search-filter-row">
                <Column className="column-no-margin" xs={12}>
                  <Field
                    label="Inspection Year"
                    placeholder=""
                    name={'inspection_year'}
                    component={FormikField}
                    type={FormikReactSelect}
                    clearable={true}
                    options={filterOptions && filterOptions.inspection_years}
                  />
                </Column>
              </Row>
              <Row className="search-filter-row">
                <Column
                  className="search-filter-column button-column"
                  xs={12}
                  md={4}
                  lg={3}
                >
                  <Row className="search-filter-row">
                    <Column className="search-filter-column button-column" xs={6}>
                      <Button
                        className="filter-command-button search-button"
                        disabled={R.isNil(values.inspection_year)}
                        type="submit"
                        primary
                      >
                        Search
                      </Button>
                    </Column>
                    <Column className="search-filter-column button-column" xs={6}>
                      <Button
                        className="filter-command-button reset-button"
                        onClick={handleReset}
                        primary
                      >
                        Reset
                      </Button>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </Form>
          )}
        </Formik>
      ) : (
        <Spinner />
      )}
    </WidgetBody>
  </Widget>
);

export default UnannouncedInspectionReportFilters;
