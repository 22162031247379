import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import * as announcementTypeActions from 'sow/actions/pure/announcementType';
import AnnouncementTypeList from 'sow/components/organisms/AnnouncementTypeList';

const cleanFilters = filters => {
  let cleanedFilters = {};
  for (let key in filters) {
    switch (R.type(filters[key])) {
      case 'String':
      case 'Number':
        if (R.isEmpty(filters[key])) {
          break;
        } else {
          cleanedFilters[key] = filters[key];
          break;
        }

      default:
        break;
    }
  }
  return cleanedFilters;
};

const mapDispatchToProps = {
  loadAnnouncementTypes: announcementTypeActions.loadAnnouncementTypes,
  updateOrder: announcementTypeActions.updateSortOrder,
};

const initialState = { loadingList: true, initialValues: {} };

class AnnouncementTypeListContainer extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = R.clone(initialState);
  }

  updateState({ data }) {
    if (this._isMounted) {
      this.setState({
        loadingList: false,
        announcementTypeList: data.announcement_types,
      });
    }
  }

  loadAnnouncementTypes(params) {
    this.props.loadAnnouncementTypes(params, this.updateState.bind(this));
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.location.search) {
      const searchValues = queryString.parse(this.props.location.search);
      this.loadAnnouncementTypes(searchValues);
      this.setState({ initialValues: { ...searchValues } });
    } else {
      this.loadAnnouncementTypes(null);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSearch(values, formikBag) {
    this.setState({ loadingList: true });
    const cleanedFilters = cleanFilters(values);
    const urlParams = queryString.stringify(cleanedFilters);

    if (urlParams) {
      this.setState({ listFiltered: true });
      history.pushState(null, null, `?${urlParams}`);
    } else {
      history.pushState(null, null, '/admin/system-admin/announcement-type');
    }
    this.loadAnnouncementTypes(cleanedFilters, this.updateState.bind(this));
  }

  resetSearch(values, formikBag) {
    this.setState(initialState);
    formikBag.resetForm({ ...initialState.initialValues });
    this.loadAnnouncementTypes(null);
    history.pushState(null, null, '/admin/system-admin/announcement-type');
  }

  updateSortOrder(updatedList) {
    this.props.updateOrder(updatedList);
  }

  render() {
    return (
      <AnnouncementTypeList
        announcementTypeList={this.state.announcementTypeList}
        handleSearch={this.handleSearch.bind(this)}
        resetSearch={this.resetSearch.bind(this)}
        updateSortOrder={this.updateSortOrder.bind(this)}
        {...this.state}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(AnnouncementTypeListContainer);
