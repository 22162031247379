import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import Block from 'sow/components/atoms/Block';
import Panel from 'sow/components/molecules/Panel';
// import WorksheetQuestion from 'sow/components/organisms/WorksheetQuestion';
import WorksheetQuestion from 'sow/components/old/OspApp/Editor/Question';

// This hacks base answers to work w/ regular answer field components
const WorksheetBaseQuestion = ({ name, text, fieldType, answer, children, ...props }) => {
  return (
    <Field
      name={name}
      render={({ field, form }) => {
        const conversionProps = {
          answer: field.value,
          handleChange: (questionId, value) => {
            form.setFieldValue(field.name, value, /* shouldValidate => */ false);
          },
          question: {
            uuid: field.name,
            name: text,
            field_type: fieldType,
            isBaseQuestion: true,
          },
        };

        return (
          <Panel
            info={answer === true}
            heading={
              <Block>
                {children && <Block pullRight>{children}</Block>}
                <WorksheetQuestion {...props} {...conversionProps} />
              </Block>
            }
          />
        );
      }}
    />
  );
};

WorksheetBaseQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  fieldType: PropTypes.string.isRequired,
  answer: PropTypes.any,
  children: PropTypes.node,
};

WorksheetBaseQuestion.defaultProps = {
  children: null,
};

export default WorksheetBaseQuestion;
