import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import moment from 'moment';
import { v4 as guid } from 'uuid';
import { Table } from 'react-bootstrap';

import { confirm } from 'sow/actions/pure/messaging';
// import * as wsAnswersAction from 'sow/actions/worksheetAnswers';
import * as wsAnswersAction from 'sow/actions/pure/worksheetAnswers';

import FileDownloadLink from 'sow/components/old/File/DownloadLink';
import EditMatrixRowModal from './EditMatrixRowModal';
import MatrixRowActionsButton from './MatrixRowActionsButton';
import InfoTooltipWrapper from 'sow/components/old/OspApp/Editor/Overview/InfoTooltipWrapper';

// TODO replace with general use function after CR merged in
const makeBugsnagNotifyFn = () => {
  const bugsnagAvailable = window.bugsnagClient && window.bugsnagClient.notify;

  if (!bugsnagAvailable) {
    // noop
    return () => {};
  }

  return window.bugsnagClient.notify;
};

const bugsnagNotify = makeBugsnagNotifyFn();

// TODO (orther) move this to a general function
const matrixRowQuestionValue = R.curry((rowId, questionId) =>
  R.path(['answers', 'matrixRows', rowId, 'values', questionId]),
);

class WorksheetMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingRowIds: [],
    };
  }

  getMatrixAnswerFn(rowId) {
    const { wsAnswers } = this.props;
    return questionId => matrixRowQuestionValue(rowId, questionId)(wsAnswers);
  }

  isRowInEditMode(rowId) {
    return R.contains(rowId, this.state.editingRowIds);
  }

  handleAddRow = () => {
    const { ospAppId, worksheetId } = this.props;
    const rowId = guid();

    this.props.dispatch(wsAnswersAction.createMatrixRow(ospAppId, worksheetId, rowId));
    this.handleEditRowFn(rowId)();
  };

  handleEditRowFn(rowId) {
    return () =>
      this.setState({
        editingRowIds: R.append(rowId, this.state.editingRowIds),
      });
  }

  handleEditCloseRowFn(rowId) {
    return () =>
      this.setState({
        editingRowIds: R.without([rowId], this.state.editingRowIds),
      });
  }

  handleDeleteRowFn = rowId => () => {
    const { ospAppId, worksheetId } = this.props;
    const { dispatch } = this.props;
    dispatch(
      confirm({
        message: 'Are you sure you want to delete this row?',
        onConfirm() {
          dispatch(wsAnswersAction.deleteMatrixRow(ospAppId, worksheetId, rowId));
        },
      }),
    );
  };

  handleChangeMatrixAnswerFn = rowId => (questionId, value) => {
    const { ospAppId, worksheetId } = this.props;
    this.props.dispatch(
      wsAnswersAction.setMatrixRowAnswer(ospAppId, worksheetId, rowId, questionId, value),
    );
  };

  renderMatrixRows() {
    const { wsAnswers } = this.props;
    const matrixRows = R.path(['answers', 'matrixRows'], wsAnswers);

    return R.keys(matrixRows).map(rowId =>
      this.renderMatrixRow(rowId, matrixRows[rowId]),
    );
  }

  renderMatrixCellContent(rowId, question) {
    const value = this.getMatrixAnswerFn(rowId)(question.uuid);

    switch (question.field_type) {
      case 'bool':
        switch (value) {
          case false:
            return 'No';
          case true:
            return 'Yes';
          default:
            return '';
        }

      case 'checkbox_list':
        if (Array.isArray(value)) return value.join(', ');

        if (R.path(['__version'], value) == 1) {
          const answers = R.defaultTo([], R.path(['answers'], question));
          const yesAnswers = answers.filter(R.propEq(R.__, true, value));

          return (
            <ul>
              {yesAnswers.map(answer => (
                <li key={answer}>{answer}</li>
              ))}
            </ul>
          );
        }

        return '';

      case 'date':
        try {
          return value ? moment(value).format('L') : '';
        } catch (e) {
          console.error('Error formatting date for matrix cell', e);
        }

        return '';

      case 'files':
        return (
          value &&
          value.map(({ id, name }) => (
            <FileDownloadLink fileId={id} key={id}>
              {name}
            </FileDownloadLink>
          ))
        );

      default:
        return value;
    }
  }

  reportRenderCellError(...args) {
    try {
      bugsnagNotify(...args);
    } catch (err) {
      console.log(
        'Bugsnag Notify Errored reporting formatting error in worksheet matrix',
      );
    }
  }

  safeRenderMatrixCellContent(rowId, question) {
    try {
      return this.renderMatrixCellContent(rowId, question);
    } catch (err) {
      this.reportRenderCellError(
        `Error formatting '${question.field_type}' for matrix cell`,
        err,
      );
    }

    return '';
  }

  renderMatrixRow(rowId, row) {
    const { worksheet, disabled } = this.props;

    return (
      <tr key={`matrix-row-${rowId}`}>
        {worksheet.questions.map(q => (
          <td key={`matrix-row-q-${rowId}-${q.uuid}`}>
            {this.safeRenderMatrixCellContent(rowId, q)}
          </td>
        ))}
        <td>
          <MatrixRowActionsButton
            rowId={rowId}
            handleEditClick={this.handleEditRowFn(rowId)}
            handleDeleteClick={this.handleDeleteRowFn(rowId)}
            {...this.props}
            disabled={disabled}
          />
          <EditMatrixRowModal
            rowId={rowId}
            showModal={this.isRowInEditMode(rowId)}
            getAnswer={this.getMatrixAnswerFn(rowId)}
            handleChangeAnswer={this.handleChangeMatrixAnswerFn(rowId)}
            handleEditCloseRow={this.handleEditCloseRowFn(rowId)}
            {...this.props}
          />
        </td>
      </tr>
    );
  }

  render() {
    const { worksheet, disabled } = this.props;

    return (
      <Table bordered condensed>
        <thead>
          <tr>
            {worksheet.questions.map(q => (
              <th key={`ws-matrix-th-${q.uuid}`}>
                <InfoTooltipWrapper tooltipText={q.tooltip}>{q.name}</InfoTooltipWrapper>
              </th>
            ))}
            <th className="col-xs-1">{/* Actions */}</th>
          </tr>
        </thead>
        <tbody>
          {this.renderMatrixRows()}
          <tr>
            <td colSpan={worksheet.questions.length + 1}>
              <button
                type="button"
                className="btn btn-success"
                onClick={this.handleAddRow}
                disabled={disabled}
              >
                Add Answer
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
}

// export default WorksheetMatrix;
export default connect()(WorksheetMatrix);
