import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';

const mapStateToProps = (state, props) => ({
  worksheet: fromPlanApp.worksheet(state, props),
});

const WorksheetDetailsContainer = ({ worksheet, children }) => children(worksheet);

WorksheetDetailsContainer.propTypes = {
  // Passed props
  worksheetId: sowTypes.planAppWorksheetIdType.isRequired,
  children: PropTypes.func.isRequired,

  // Connected props
  worksheet: sowTypes.planAppWorksheetType.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(WorksheetDetailsContainer);
