import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { push as routerPush } from 'connected-react-router';

import { acaRoute } from 'sow/routes';
import * as acaAdminAction from 'sow/actions/acaAdmin';
import CreateAcaPage from 'sow/components/pages/CreateAcaPage';

const mapStateToProps = state => ({
  isCreating: state.acaAdminList.isCreating,
  errors: state.acaAdminList.createErrors,
});

const mapDispatchToProps = {
  createAca: acaAdminAction.createAca,
  redirect: routerPush,
};

class CreateAcaPageContainer extends Component {
  handleSubmit = aca => {
    this.props.createAca(aca, this.handleCreateSuccess);
  };

  handleCreateSuccess = aca => {
    this.props.redirect(acaRoute(aca.id));
  };

  render() {
    const { isCreating, createErrors } = this.props;

    return (
      <CreateAcaPage
        onSubmit={this.handleSubmit}
        isCreating={isCreating}
        createErrors={createErrors}
      />
    );
  }
}

CreateAcaPageContainer.propTypes = {
  createAca: PropTypes.func,
  router: PropTypes.object,
  isCreating: PropTypes.bool,
  createErrors: PropTypes.object,
  redirect: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(CreateAcaPageContainer);
