import * as R from 'ramda';

import {
  planAppStates as paStates,
  planAppStateText as paStateText,
} from 'sow/constants/planApp';
import { monthDayYearDateFormat } from 'sow/utils/dateTime';

export const actionTypeOptions = [
  { value: 'plan_state_changed', label: 'Plan State Changed', disabled: true },
  {
    value: 'initial_application_created',
    label: paStateText[paStates.INITIAL_APPLICATION],
  },
  {
    value: 'initial_application_submitted',
    label: paStateText[paStates.INITIAL_APPLICATION_SUBMITTED],
  },
  { value: 'revision_created', label: paStateText[paStates.REVISION] },
  { value: 'revision_submitted', label: paStateText[paStates.REVISION_SUBMITTED] },
  { value: 'entered_initial_review', label: paStateText[paStates.INITIAL_REVIEW] },
  { value: 'entered_in_initial_review', label: paStateText[paStates.IN_INITIAL_REVIEW] },
  { value: 'entered_inspection', label: paStateText[paStates.INSPECTION] },
  { value: 'entered_in_inspection', label: paStateText[paStates.IN_INSPECTION] },
  { value: 'entered_final_review', label: paStateText[paStates.FINAL_REVIEW] },
  { value: 'entered_in_final_review', label: paStateText[paStates.IN_FINAL_REVIEW] },
  { value: 'completed', label: paStateText[paStates.COMPLETE] },
  { value: 'withdrawn', label: paStateText[paStates.WITHDRAWN] },
  // Hiding for now until confirmed needed by organic team
  // { value: 'unwithdrawn', label: 'Unwithdrawn' },
  // { value: 'revoked', label: paStateText[paStates.REVOKED] },
  // { value: 'surrendered', label: paStateText[paStates.SURRENDERED] },
  // { value: 'suspended', label: paStateText[paStates.SUSPENDED] },
  { value: 'location', label: 'Location', disabled: true },
  { value: 'land_added', label: 'Location added' },
  { value: 'land_changed', label: 'Location changed' },
  { value: 'land_deleted', label: 'Location deleted' },
  { value: 'organization', label: 'Organization', disabled: true },
  { value: 'organization_created', label: 'Organization created' },
  { value: 'user_added', label: 'User Added' },
  { value: 'user_updated', label: 'User Updated' },
  { value: 'user_removed', label: 'User Removed' },
  { value: 'note', label: 'Note', disabled: true },
  { value: 'note_added_by_org', label: 'Note added by Org' },
  { value: 'note_added_by_aca', label: 'Note added by ACA' },
  { value: 'note_added_by_admin', label: 'Note added by Admin' },
  { value: 'change_request', label: 'Change Request', disabled: true },
  { value: 'change_request_created', label: 'Change Request created' },
  { value: 'change_request_submitted', label: 'Change Request submitted' },
  { value: 'change_request_applied', label: 'Change Request applied' },
  { value: 'change_added', label: 'Change added' },
  { value: 'change_modified', label: 'Change modified' },
  { value: 'change_approved', label: 'Change approved' },
  { value: 'change_rejected', label: 'Change rejected' },
  { value: 'comment_added', label: 'Comment added' },
  { value: 'plan_flag_changed', label: 'Plan Flag Changed', disabled: true },
  { value: 'noncompliance_added', label: 'Noncompliance Added' },
  { value: 'noncompliance_removed', label: 'Noncompliance Removed' },
  { value: 'settlement_agreement_added', label: 'Settlement Agreement Added' },
  { value: 'settlement_agreement_removed', label: 'Settlement Agreement Removed' },
  { value: 'under_investigation_added', label: 'Under Investigation Added' },
  { value: 'under_investigation_removed', label: 'Under Investigation Removed' },
  { value: 'broiler_olps_extension_added', label: 'Broiler OLPS Extension Added' },
  { value: 'broiler_olps_extension_removed', label: 'Broiler OLPS Extension Removed' },
  {
    value: 'pullet_layer_olps_extension_added',
    label: 'Pullet/Layer OLPS Extension Added',
  },
  {
    value: 'pullet_layer_olps_extension_removed',
    label: 'Pullet/Layer OLPS Extension Removed',
  },
  {
    value: 'other_poultry_olps_extension_added',
    label: 'Other Poultry OLPS Extension Added',
  },
  {
    value: 'other_poultry_olps_extension_removed',
    label: 'Other Poultry OLPS Extension Removed',
  },
  { value: 'inspection', label: 'Inspection', disabled: true },
  { value: 'inspection_created', label: 'Inspection created' },
  { value: 'inspection_updated', label: 'Inspection updated' },
  { value: 'inspection_deleted', label: 'Inspection deleted' },
  { value: 'sample', label: 'Sample', disabled: true },
  { value: 'sample_updated', label: 'Sample updated' },
];

export const stateChangeFormat = (log, ospData) => {
  const { ospWorksheetsByDefId } = ospData;
  const definitionWorksheets = ospWorksheetsByDefId[log.definition_id];
  const worksheetId = R.path(['change', 'worksheet_id'], log);
  const questionId = R.path(['change', 'question_id'], log);
  const questionName = fetchQuestionName(definitionWorksheets, worksheetId, questionId);

  if (R.path(['change', 'type'], log) === 'matrix_row') {
    return {
      username: { new_value: R.path(['user', 'name'], log) },
      worksheet: {
        new_value: R.pathOr('', [worksheetId, 'name'], definitionWorksheets),
      },
      action: { new_value: 'Matrix row deleted' },
      change: {
        new_value: {
          change_id: R.pathOr('', ['change', 'id'], log),
          aca_id: R.pathOr('', ['organization', 'aca_id'], log),
        },
      },
    };
  } else if (R.path(['change', 'type'], log) === 'plan') {
    return {
      username: { new_value: R.path(['user', 'name'], log) },
      action: { new_value: 'Activities Checklist Change' },
      change: {
        new_value: {
          change_id: R.pathOr('', ['change', 'id'], log),
          aca_id: R.pathOr('', ['organization', 'aca_id'], log),
        },
      },
    };
  } else if (R.path(['change', 'type'], log) === 'self') {
    const action = R.pathOr('', ['change', 'action'], log);
    const isDeleted = action === 'deleted';
    const isUpdated = action === 'updated';
    const locationName = isDeleted
      ? R.pathOr('', ['change', 'old', 'name'], log)
      : R.pathOr('', ['change', 'new', 'name'], log);

    return {
      username: { new_value: R.path(['user', 'name'], log) },
      action: {
        new_value: isDeleted
          ? 'Location Deleted'
          : isUpdated
          ? 'Location Details Updated'
          : 'Location Added',
      },
      location_name: { new_value: locationName },
      change: {
        new_value: {
          change_id: R.pathOr('', ['change', 'id'], log),
          aca_id: R.pathOr('', ['organization', 'aca_id'], log),
        },
      },
    };
  }

  return {
    username: { new_value: R.path(['user', 'name'], log) },
    worksheet: {
      new_value: R.pathOr('', [worksheetId, 'name'], definitionWorksheets),
    },
    question: {
      new_value: questionName ? questionName : '',
    },
    change: {
      new_value: {
        change_id: R.pathOr('', ['change', 'id'], log),
        aca_id: R.pathOr('', ['organization', 'aca_id'], log),
      },
    },
  };
};

export const answerChangeFormat = (log, ospData) => {
  const { ospWorksheetsByDefId, ospQualificationByDefId } = ospData;
  const definitionWorksheets = ospWorksheetsByDefId[log.definition_id];
  const worksheetId = R.path(['change', 'worksheet_id'], log);
  const questionId = R.path(['change', 'question_id'], log);
  const questionName = fetchQuestionName(definitionWorksheets, worksheetId, questionId);
  if (R.path(['change', 'type'], log) === 'plan') {
    const definitionQualifications = ospQualificationByDefId[log.definition_id];
    const newValue = R.pathOr(
      [],
      ['data', 'changes', 'new', 'new_value', 'qualification_answers', 'values'],
      log,
    );
    const oldValue =
      log.action === 'change_modified'
        ? R.pathOr(
            [],
            ['data', 'changes', 'new', 'old_value', 'qualification_answers', 'values'],
            log,
          )
        : R.pathOr(
            [],
            ['data', 'changes', 'old', 'new_value', 'qualification_answers', 'values'],
            log,
          );
    return {
      old_qualifications: {
        new_value: oldValue.map(
          qualificationId => definitionQualifications[qualificationId],
        ),
      },
      new_qualifications: {
        new_value: newValue.map(
          qualificationId => definitionQualifications[qualificationId],
        ),
      },
      change: {
        new_value: {
          change_id: R.pathOr('', ['change', 'id'], log),
          aca_id: R.pathOr('', ['organization', 'aca_id'], log),
        },
      },
    };
  } else if (R.path(['change', 'type'], log) === 'matrix_row') {
    return {
      username: { new_value: R.path(['user', 'name'], log) },
      worksheet: {
        new_value: R.pathOr('', [worksheetId, 'name'], definitionWorksheets),
      },
      action: { new_value: 'Matrix row deleted' },
      change: {
        new_value: {
          change_id: R.pathOr('', ['change', 'id'], log),
          aca_id: R.pathOr('', ['organization', 'aca_id'], log),
        },
      },
    };
  } else if (R.path(['change', 'type'], log) === 'self') {
    const action = R.pathOr('', ['change', 'action'], log);
    const isDeleted = action === 'deleted';
    const isUpdated = action === 'updated';
    const locationName = isDeleted
      ? R.pathOr('', ['change', 'old', 'name'], log)
      : R.pathOr('', ['change', 'new', 'name'], log);

    return {
      username: { new_value: R.path(['user', 'name'], log) },
      action: {
        new_value: isDeleted
          ? 'Location Deleted'
          : isUpdated
          ? 'Location Details Updated'
          : 'Location Added',
      },
      location_name: { new_value: locationName },
      change: {
        new_value: {
          change_id: R.pathOr('', ['change', 'id'], log),
          aca_id: R.pathOr('', ['organization', 'aca_id'], log),
        },
      },
    };
  }

  return {
    username: { new_value: R.path(['user', 'name'], log) },
    worksheet: {
      new_value: R.pathOr('', [worksheetId, 'name'], definitionWorksheets),
    },
    question: {
      new_value: questionName ? questionName : '',
    },
    old_answer: {
      new_value:
        log.action === 'change_modified'
          ? R.pathOr('', ['data', 'changes', 'new', 'old_value'], log)
          : R.pathOr('', ['data', 'changes', 'old', 'new_value'], log),
    },
    new_answer: {
      new_value: R.pathOr('', ['data', 'changes', 'new', 'new_value'], log),
    },
    change: {
      new_value: {
        change_id: R.pathOr('', ['change', 'id'], log),
        aca_id: R.pathOr('', ['organization', 'aca_id'], log),
      },
    },
  };
};

export const changeCommentFormat = (log, ospData) => {
  const { ospWorksheetsByDefId } = ospData;
  const definitionWorksheets = ospWorksheetsByDefId[log.definition_id];
  const worksheetId = R.path(['change', 'worksheet_id'], log);
  const questionId = R.path(['change', 'question_id'], log);
  const questionName = fetchQuestionName(definitionWorksheets, worksheetId, questionId);

  if (R.path(['change', 'type'], log) === 'matrix_row') {
    return {
      username: { new_value: R.path(['user', 'name'], log) },
      worksheet: {
        new_value: R.pathOr('', [worksheetId, 'name'], definitionWorksheets),
      },
      action: { new_value: 'Matrix row deleted' },
      comment: R.pathOr('', ['data', 'changes', 'text'], log),
      change: {
        new_value: {
          change_id: R.pathOr('', ['change', 'id'], log),
          aca_id: R.pathOr('', ['organization', 'aca_id'], log),
        },
      },
    };
  }

  return {
    username: { new_value: R.path(['user', 'name'], log) },
    worksheet: {
      new_value: R.pathOr('', [worksheetId, 'name'], definitionWorksheets),
    },
    question: {
      new_value: questionName ? questionName : '',
    },
    comment: R.pathOr('', ['data', 'changes', 'text'], log),
    change: {
      new_value: {
        change_id: R.pathOr('', ['change', 'id'], log),
        aca_id: R.pathOr('', ['organization', 'aca_id'], log),
      },
    },
  };
};

export const orgAssociationFormat = log => {
  const data = R.path(['data'], log);
  const changeObject = {};
  for (let key in data) {
    if (key !== 'user_id') {
      changeObject[key] = { new_value: data[key] };
    }
  }
  return changeObject;
};

export const inspectionCreatedFormat = ({ data }) => ({
  inspection_id: { new_value: data.id },
  inspection_type: { new_value: `${data.inspection_sub_type} ${data.inspection_type}` },
  inspection_status: { new_value: data.inspection_state },
  application_id: { new_value: data.application_id },
});

export const inspectionUpdatedFormat = ({ data }) => {
  const { changes } = data;
  const display = {
    inspection_id: { new_value: data.id },
    application_id: { new_value: data.application_id },
  };

  if (changes.is_expedited) {
    if (changes.is_expedited.new_value) {
      display.expedited = { new_value: 'Added' };
    } else {
      display.expedited = { new_value: 'Removed' };
    }
  }
  if (changes.inspection_date) {
    if (changes.inspection_date.new_value) {
      display.new_inspection_date = {
        new_value: monthDayYearDateFormat(changes.inspection_date.new_value),
      };
    } else {
      display.new_inspection_date = { new_value: changes.inspection_date.new_value };
    }
    if (changes.inspection_date.old_value) {
      display.old_inspection_date = {
        new_value: monthDayYearDateFormat(changes.inspection_date.old_value),
      };
    } else {
      display.old_inspection_date = { new_value: changes.inspection_date.old_value };
    }
  }
  if (changes.inspection_sub_type) {
    display.new_sub_type = { new_value: changes.inspection_sub_type.new_value.type };
    display.old_sub_type = { new_value: changes.inspection_sub_type.old_value.type };
  }
  if (changes.inspection_state) {
    display.new_status = { new_value: changes.inspection_state.new_value.state };
    display.old_status = { new_value: changes.inspection_state.old_value.state };
  }
  if (changes.inspection_year) {
    display.new_inspection_year = { new_value: changes.inspection_year.new_value };
    display.old_inspection_year = { new_value: changes.inspection_year.old_value };
  }
  if (changes.added_samples) {
    display.added_samples = { new_value: changes.added_samples };
  }
  if (changes.removed_samples) {
    display.removed_samples = { new_value: changes.removed_samples };
  }
  if (changes.added_inspectors) {
    display.added_inspectors = {
      new_value: changes.added_inspectors.map(inspector => inspector.name).join(', '),
    };
  }
  if (changes.removed_inspectors) {
    if (R.type(changes.removed_inspectors) === 'String') {
      display.removed_inspectors = { new_value: changes.removed_inspectors };
    } else {
      display.removed_inspectors = {
        new_value: changes.removed_inspectors.map(inspector => inspector.name).join(', '),
      };
    }
  }

  return display;
};

export const sampleUpdatedFormat = ({ data }) => {
  const display = {
    sample_id: { new_value: data.id },
    inspection_id: { new_value: data.inspection_id },
    application_id: { new_value: data.application_id },
    new_status: { new_value: data.changes.sample_state.new_value.state },
    old_status: { new_value: data.changes.sample_state.old_value.state },
  };

  return display;
};

const fetchQuestionName = (worksheetList, worksheetId, questionId) => {
  if (questionId) {
    const questionData = R.find(
      R.propEq('uuid', questionId),
      R.pathOr({}, [worksheetId, 'questions'], worksheetList),
    );
    return R.pathOr('', ['name'], questionData);
  }
};
