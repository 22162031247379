import { combineReducers } from 'redux';
import byId, * as fromById from './byId';
import detailById from './detailById';
import isLoading from './isLoading';
import isLoadingOne from './isLoadingOne';

export default combineReducers({
  byId, // new convention for storing results in id=>key map
  detailById,
  isLoading,
  isLoadingOne,
});

export const getAllAcaIds = state => fromById.getIds(state.byId);
export const getIsLoading = state => state.isLoading;
export const getAca = (state, id) => fromById.getAca(state.byId, id);
export const getAcaList = state => fromById.getAcaList(state.byId);
