import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { push as routerPush } from 'connected-react-router';

import { locationRoute } from 'sow/routes';
import actions from 'sow/actions/pure';
import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import { resourceCreate, resourceUpdate } from 'sow/store/helpers';
import LocationCreateModal from 'sow/components/organisms/PlanAppLocationCreateModal';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  isStateInitialApplication: fromPlanApp.isStateInitialApplication(state, props),
  isPlanAppOpen: fromPlanApp.isPlanAppOpen(state, props),
});

const mapDispatchToProps = { routerPush };

const connectResource = () => {
  const createLocationResource = (orgId, planAppId) =>
    resourceCreate(`org/${orgId}/application/${planAppId}/land`, 'planAppLocation');
  const createLocationChangeResource = (orgId, planAppId, changeRequestId) =>
    resourceUpdate(
      `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`,
      'changeRequestOverviewResp',
    );
  const getAcceptChangeResource = (orgId, planAppId, changeRequestId, changeId) =>
    resourceUpdate(
      `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes/${changeId}/accept`,
      'changeRequestOverviewResp',
    );
  const mapResourceDispatchToProps = (dispatch, ownProps) => {
    const {
      orgId,
      planAppId,
      changeRequestId,
      isStateInitialApplication,
      routerPush,
    } = ownProps;
    const resource = isStateInitialApplication
      ? createLocationResource(orgId, planAppId)
      : createLocationChangeResource(orgId, planAppId, changeRequestId);

    const action = location =>
      isStateInitialApplication
        ? resource.action({ land: location })
        : resource.action(null, {
            changes: [
              {
                context: 'land',
                type: 'self',
                action: 'added',
                old: null,
                new: location,
              },
            ],
          });

    return {
      onCreate: location =>
        dispatch(action(location))
          .then(resp => {
            if (!isStateInitialApplication) {
              const newChange = resp.data.changes[0];
              const acceptChangeResource = getAcceptChangeResource(
                orgId,
                planAppId,
                changeRequestId,
                newChange.id,
              );
              dispatch(
                acceptChangeResource.action(
                  null,
                  orgId,
                  planAppId,
                  changeRequestId,
                  newChange.id,
                ),
              );
            }
            dispatch(actions.shell.toast('success', 'Location created.'));
            const createdLandId = isStateInitialApplication
              ? R.path(['data', 'id'], resp)
              : R.path(['data', 'changes', 0, 'landId'], resp);

            return routerPush(locationRoute(orgId, planAppId, createdLandId));
          })
          .catch(() => {
            dispatch(actions.shell.toast('danger', 'Error creating location'));
          }),
    };
  };

  return connect(null, mapResourceDispatchToProps);
};

const LocationCreateModalContainer = ({
  onCreate,
  isPlanAppOpen,
  isStateInitialApplication,
  changeRequestId,
}) => {
  // Only render a button if it's possible for a location to be created
  if (!isPlanAppOpen) return null;

  // Disable button if a location could be created through CR, but CR does not exist yet
  const disabled = !isStateInitialApplication && isPlanAppOpen && !changeRequestId;

  return <LocationCreateModal onCreate={onCreate} disabled={disabled} />;
};

LocationCreateModalContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  changeRequestId: sowTypes.planAppChangeRequestIdType,
  isStateInitialApplication: PropTypes.bool.isRequired,
  isPlanAppOpen: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
};

LocationCreateModalContainer.defaultProps = {
  changeRequestId: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  connectResource(),
)(LocationCreateModalContainer);
