import React from 'react';
import { Formik, Form, Field } from 'formik';

import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormikField from 'sow/components/organisms/FormikField';
import FormikReactSelect from 'sow/components/molecules/FormikReactSelect';
import Button from 'sow/components/atoms/Button';
import Block from 'sow/components/atoms/Block';
import Glyphicon from 'sow/components/molecules/Glyphicon';

const LocationReportFilters = ({
  options,
  handleSearch,
  resetReport,
  exportList,
  hasResults,
  initialValues,
}) => (
  <Formik onSubmit={handleSearch} onReset={resetReport} initialValues={initialValues}>
    {({ values, handleSubmit, handleReset }) => (
      <Widget className="search-filter-block">
        <WidgetHeading>
          Location Search Filter
          {hasResults && (
            <Block pullRight>
              <Button onClick={() => exportList(values)} primary sm>
                <Glyphicon glyph="export" /> Export
              </Button>
            </Block>
          )}
        </WidgetHeading>
        <WidgetBody>
          <Form onSubmit={handleSubmit}>
            <Row className="search-filter-row">
              <Column className="column-no-margin" xs={12} sm={6} md={4}>
                <Field
                  className="input-filter"
                  label="Name"
                  placeholder=""
                  name={'name'}
                  component={FormikField}
                />
              </Column>
              <Column className="column-no-margin" xs={12} sm={6} md={4}>
                <Field
                  label="Organization"
                  placeholder=""
                  name={'org_id'}
                  component={FormikField}
                  type={FormikReactSelect}
                  clearable={true}
                  options={options.org_options}
                />
              </Column>
              <Column className="column-no-margin" xs={12} sm={6} md={4}>
                <Field
                  label="Location Type"
                  placeholder=""
                  name={'location_type_id'}
                  component={FormikField}
                  type={FormikReactSelect}
                  clearable={true}
                  options={options.location_type_options}
                />
              </Column>
            </Row>
            <Row className="search-filter-row">
              <Column className="search-filter-column button-column" xs={12} md={4}>
                <Row className="search-filter-row">
                  <Column className="search-filter-column button-column" xs={6}>
                    <Button
                      className="filter-command-button search-button"
                      type="submit"
                      primary
                    >
                      Search
                    </Button>
                  </Column>
                  <Column className="search-filter-column button-column" xs={6}>
                    <Button
                      className="filter-command-button reset-button"
                      onClick={handleReset}
                      primary
                    >
                      Reset
                    </Button>
                  </Column>
                </Row>
              </Column>
            </Row>
          </Form>
        </WidgetBody>
      </Widget>
    )}
  </Formik>
);

export default LocationReportFilters;
