import React from 'react';

import ListItem from 'sow/components/atoms/ListItem';
import Glyphicon from 'sow/components/molecules/Glyphicon';

const PlanAppStepperNextStep = ({}) =>
  <ListItem textCenter textMuted style={{ width: '0.1%', verticalAlign: 'middle' }}>
    <Glyphicon glyph="chevron-right" hiddenXs />
    <Glyphicon glyph="chevron-down" hiddenSm hiddenMd hiddenLg />
  </ListItem>;

export default PlanAppStepperNextStep;
