import React from 'react';

import './CSSModules/AcaOrgSearchPage.module.css';
import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import AcaOrgSearch from 'sow/containers/AcaOrgSearch';
import AcaOrgSearchTable from 'sow/containers/AcaOrgSearchTable';

const AcaOrgSearchPage = () => (
  <AdminPageTemplate title="Organizations">
    {() => (
      <div className="aca-org-search-page">
        <AcaOrgSearch />
        <AcaOrgSearchTable />
      </div>
    )}
  </AdminPageTemplate>
);

export default AcaOrgSearchPage;
