import { apiRequest } from 'sow/utils/api';
import { API_URL } from 'sow/constants/api';

export const fetchOrgOspAppListAsync = orgId =>
  apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/org/${orgId}/application`,
  });

export function saveApplicationInformation(orgId, appId, data) {
  return apiRequest({
    type: 'PUT',
    url: `${API_URL}/v1/org/${orgId}/application/${appId}/info`,
    data,
  });
}
