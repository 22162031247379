import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import { checklistRoute } from 'sow/routes';
import CompletedPlanLink from 'sow/components/organisms/CompletedPlanLink';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planApp: fromPlanApp.planAppSummaryMostRecentCompleted(state, props),
  isNoncompliant: fromPlanApp.planAppSummaryMostRecentCompletedIsNoncompliant(
    state,
    props,
  ),
});

const CompletedPlanLinkContainer = ({ orgId, planApp, isNoncompliant }) => {
  if (!planApp) return null;

  const planAppId = R.prop('id', planApp);

  return (
    <CompletedPlanLink
      linkTo={checklistRoute(orgId, planAppId)}
      isNoncompliant={isNoncompliant}
    />
  );
};

CompletedPlanLinkContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planApp: sowTypes.planAppType,
  isNoncompliant: PropTypes.bool.isRequired,
};

CompletedPlanLinkContainer.defaultProps = {
  planApp: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(CompletedPlanLinkContainer);
