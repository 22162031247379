import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as currentUser from 'sow/selectors/currentUser';
import { fromPlanApp } from 'sow/store/selectors';
import * as sowTypes from 'sow/types';
import RequestListItem from 'sow/components/organisms/PlanAppChangeRequestManager/RequestListItem';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  worksheetName: fromPlanApp.changeWorksheetName(state, props),
  showAcaUI: currentUser.showAcaUI(state, props),
});

class RequestListItemContainer extends Component {
  state = {
    showComments: false,
  };

  render() {
    return (
      <RequestListItem
        {...this.props}
      />
    );
  }
}

RequestListItemContainer.propTypes = {
  orgId: sowTypes.orgIdType,
  questionId: PropTypes.any,
  worksheetName: PropTypes.string,
  children: PropTypes.node,
  showAcaUI: PropTypes.bool.isRequired,
  requestItem: PropTypes.object,
  questionName: PropTypes.string,
  locationId: PropTypes.number,
  locationName: PropTypes.string,
};

RequestListItemContainer.defaultProps = {
  children: null,
  questionId: null,
  locationId: null,
  worksheetId: null,
  matrixRowId: null,
  questionName: null,
  locationName: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(RequestListItemContainer);
