import { API_URL } from 'sow/constants/api';
import { apiRequest } from 'sow/utils/api';
import { getBaseUrlForOrg } from './index';

const getBaseUrl = (orgId, ospAppId) =>
  `${getBaseUrlForOrg(orgId)}/application/${ospAppId}/worksheet_answers`;

export const fetchAll = (orgId, ospAppId) =>
  apiRequest({
    type: 'GET',
    url: getBaseUrl(orgId, ospAppId),
  });

export const fetchOne = (orgId, ospAppId, worksheetId) =>
  apiRequest({
    type: 'GET',
    url: `${getBaseUrl(orgId, ospAppId)}/${worksheetId}`,
  });

export const saveOne = (orgId, ospAppId, worksheetId, data) => {
  const { answers, isNotApplicable, readyForReview } = data;

  return apiRequest({
    method: 'PUT',
    url: `${getBaseUrl(orgId, ospAppId)}/${worksheetId}`,
    data: {
      osp_application_answer: {
        osp_application_id: ospAppId,
        worksheet_uuid: worksheetId,
        answers,
        is_not_applicable: isNotApplicable,
        ready_for_review: readyForReview,
      },
    },
  });
};
