import React, { Fragment } from 'react';
import * as R from 'ramda';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { planAppStateText, planAppStates } from 'sow/constants/planApp';

import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import Strong from 'sow/components/atoms/Strong';
import Clearfix from 'sow/components/atoms/Clearfix';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Paragraph from 'sow/components/atoms/Paragraph';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import FormikField from 'sow/components/organisms/FormikField';
import FormikReactSelect from 'sow/components/molecules/FormikReactSelect';
import FormikDateRange from 'sow/components/molecules/FormikDateRange';
import FormikSingleCheckbox from 'sow/components/molecules/FormikSingleCheckbox';

const formSchema = Yup.object({
  anniversary_date_range: Yup.object({
    anniversary_date_start: Yup.string().matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Incorrect Format',
    ),
    anniversary_date_end: Yup.string().matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Incorrect Format',
    ),
  }),
  inspection_date_range: Yup.object({
    inspection_date_start: Yup.string().matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Incorrect Format',
    ),
    inspection_date_end: Yup.string().matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Incorrect Format',
    ),
  }),
});

const SpecialistDashboardSearch = ({
  showFilters,
  toggleFilters,
  filterOptions,
  handleSearch,
  resetSearch,
  fetchingFilters,
  initialValues,
}) => {
  const ospStatusOptions = [];

  for (const property in planAppStates) {
    if (property !== 'NONCOMPLIANCE')
      ospStatusOptions.push({
        value: planAppStates[property],
        label: planAppStateText[planAppStates[property]],
      });
  }
  return (
    <Fragment>
      {fetchingFilters ? null : (
        <Widget className="search-filter-block">
          <WidgetBody
            className={
              showFilters
                ? 'specialist-dashboard-search-show-form'
                : 'specialist-dashboard-search-hide-form'
            }
          >
            <Block pullRight>
              {showFilters ? (
                <Button
                  className="toggle-filter-button no-margin-button"
                  onClick={toggleFilters}
                >
                  <span className="material-icons-outlined">filter_list</span>
                  <Paragraph className="filter-text">Hide Filters</Paragraph>
                  <FontAwesome icon="caret-up" className="filter-caret" />
                </Button>
              ) : (
                <Button
                  className="toggle-filter-button no-margin-button"
                  onClick={toggleFilters}
                >
                  <span className="material-icons-outlined">filter_list</span>
                  <Paragraph className="filter-text">Show Filters</Paragraph>
                  <FontAwesome icon="caret-down" className="filter-caret" />
                </Button>
              )}
            </Block>
            <Clearfix />
            {showFilters && (
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={formSchema}
                onSubmit={handleSearch}
                onReset={resetSearch}
              >
                {({ errors, handleReset }) => (
                  <Form>
                    <Row className="search-filter-row">
                      <Column className="column-no-margin" xs={12} sm={6} md={4}>
                        <Field
                          label="Client Name"
                          placeholder=""
                          name={'org_id'}
                          component={FormikField}
                          type={FormikReactSelect}
                          clearable={true}
                          options={filterOptions.org_options}
                        />
                      </Column>
                      <Column className="column-no-margin" xs={12} sm={6} md={4}>
                        <Field
                          label="Consultant Group"
                          placeholder=""
                          name={'consultant_ids'}
                          component={FormikField}
                          type={FormikReactSelect}
                          clearable={true}
                          multi={true}
                          options={filterOptions.consultant_options}
                        />
                      </Column>
                      <Column className="column-no-margin" xs={12} sm={6} md={4}>
                        <Field
                          label="Scope"
                          placeholder=""
                          name={'category'}
                          component={FormikField}
                          type={FormikReactSelect}
                          clearable={true}
                          multi={true}
                          options={filterOptions.category_options}
                        />
                      </Column>
                      <Column className="column-no-margin" xs={12} sm={6} md={4}>
                        <Field
                          label="OSP Tag"
                          placeholder=""
                          name={'osp_tags'}
                          component={FormikField}
                          type={FormikReactSelect}
                          clearable={true}
                          multi={true}
                          options={[
                            { value: 'noncompliance', label: 'Noncompliance' },
                            {
                              value: 'under_investigation',
                              label: 'Under Investigation',
                            },
                            {
                              value: 'settlement_agreement',
                              label: 'Settlement Agreement',
                            },
                          ]}
                        />
                      </Column>
                      <Column className="column-no-margin" xs={12} sm={6} md={4}>
                        <Field
                          label="Anniversary Date"
                          placeholder=""
                          name={'anniversary_date_range'}
                          dateFormat="MM/DD/YYYY"
                          startDateID={'anniversary_date_start'}
                          endDateID={'anniversary_date_end'}
                          component={FormikField}
                          type={FormikDateRange}
                        />
                        {R.has('anniversary_date_range', errors) && (
                          <Block textDanger>
                            <Strong>Error:</Strong>{' '}
                            {
                              'Date is invalid. Please use MM/DD/YYYY format ex. (04/05/2022)'
                            }
                          </Block>
                        )}
                      </Column>
                      <Column className="column-no-margin" xs={12} sm={6} md={4}>
                        <Field
                          label="Certifications"
                          placeholder=""
                          name={'certifications'}
                          component={FormikField}
                          type={FormikReactSelect}
                          clearable={true}
                          multi={true}
                          options={filterOptions.cert_options}
                        />
                      </Column>
                      <Column className="column-no-margin" xs={12} sm={6} md={4}>
                        <Field
                          label="OSP Status"
                          placeholder=""
                          name={'osp_states'}
                          component={FormikField}
                          type={FormikReactSelect}
                          clearable={true}
                          multi={true}
                          options={ospStatusOptions}
                        />
                      </Column>
                      <Column className="column-no-margin" xs={12} sm={6} md={4}>
                        <Field
                          label="Annual Inspection Date"
                          placeholder=""
                          name={'inspection_date_range'}
                          startDateID={'inspection_date_start'}
                          endDateID={'inspection_date_end'}
                          component={FormikField}
                          type={FormikDateRange}
                        />
                        {R.has('inspection_date_range', errors) && (
                          <Block textDanger>
                            <Strong>Error:</Strong>{' '}
                            {
                              'Date is invalid. Please use MM/DD/YYYY format ex. (04/05/2022)'
                            }
                          </Block>
                        )}
                      </Column>
                      <Column className="column-no-margin" xs={12} sm={6} md={4}>
                        <Field
                          label="Hard Copy"
                          placeholder=""
                          name={'hard_copy'}
                          component={FormikField}
                          type={FormikSingleCheckbox}
                        />
                      </Column>
                    </Row>
                    <Row className="search-filter-row">
                      <Column
                        className="search-filter-column button-column"
                        xs={12}
                        md={4}
                      >
                        <Row className="search-filter-row">
                          <Column className="search-filter-column button-column" xs={6}>
                            <Button
                              className="filter-command-button search-button"
                              disabled={!R.isEmpty(errors)}
                              type="submit"
                              primary
                            >
                              Search
                            </Button>
                          </Column>
                          <Column className="search-filter-column button-column" xs={6}>
                            <Button
                              className="filter-command-button reset-button"
                              onClick={handleReset}
                              primary
                            >
                              Reset
                            </Button>
                          </Column>
                        </Row>
                      </Column>
                    </Row>
                  </Form>
                )}
              </Formik>
            )}
          </WidgetBody>
        </Widget>
      )}
    </Fragment>
  );
};

export default SpecialistDashboardSearch;
