import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/** Renders a nav tag. Includes support for 'nav-tabs' bootstrap class */
const Nav = ({ tabs, pills, justified, className, children, ...props }) => {
  const classes = classnames(className, {
    'nav nav-tabs': tabs,
    'nav nav-pills': pills,
    'nav-justified': justified,
  });

  return (
    <nav role="navigation" className={classes} {...props}>
      {children}
    </nav>
  );
};

Nav.propTypes = {
  children: PropTypes.node,

  /** Additional classnames to be passed through */
  className: PropTypes.string,

  /** Adds the 'nav nav-tabs' bootstrap class to render as tabbed navigation */
  tabs: PropTypes.bool,

  /** Adds the 'nav nav-pills' bootstrap class to render as pill navigation */
  pills: PropTypes.bool,

  /** Set alignment to justified */
  justified: PropTypes.bool,
};

Nav.defaultProps = {
  tabs: false,
  pills: false,
  justified: false,
};

export default Nav;
