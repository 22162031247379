import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/** Renders a `pre` tag */
const PreformattedText = ({
  children,
  className,
  scrollable,
  text,
  wrapped,
  ...props
}) => {
  const classes = classNames(className, {
    'pre-scrollable': scrollable,
  });

  const style = {
    whiteSpace: wrapped ? 'pre-wrap' : 'nowrap',
  };

  return (
    <pre {...props} className={classes} style={style}>
      {text || children}
    </pre>
  );
};

PreformattedText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  scrollable: PropTypes.bool,
  text: PropTypes.string,
  wrapped: PropTypes.bool,
};

PreformattedText.defaultProps = {
  wrapped: true,
};

export default PreformattedText;
