import React from 'react';
import PropTypes from 'prop-types';

import Select from 'sow/components/molecules/Select';

const optionMapFn = type => {
  if (typeof type === 'string') {
    return { value: type, text: type };
  } else {
    return type;
  }
};

const LandTypeSelect = ({ visibleTypes, ...props }) => (
  <Select {...props} options={visibleTypes.map(optionMapFn)} />
);

LandTypeSelect.propTypes = {
  visibleTypes: PropTypes.array.isRequired,
};

export default LandTypeSelect;
