import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as itemVersionActions from 'sow/actions/pure/itemVersion';
import { apiGet, apiPost, apiPut } from 'sow/sagas/api';

export const loadItemVersions = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/item_version', payload);

    if (response && meta.onSuccess) {
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchById = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/item_version/${payload}`);

    if (response && meta.onSuccess) {
      yield put(itemVersionActions.fetchByIdDone(response.data));
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addItemVersion = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/item_version', payload);

    if (response && meta.onSuccess) {
      yield put(itemVersionActions.addItemVersionDone(response.data));
      yield put(actions.shell.toast('success', 'Item version created.'));
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateItemVersion = function*({ id, payload, meta }) {
  try {
    const { response } = yield call(apiPut, `/admin/item_version/${id}`, payload);

    if (response) {
      yield put(itemVersionActions.updateItemVersionDone(response.data));
      yield put(actions.shell.toast('success', 'Item version updated.'));
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteItemVersion = function*({ id, meta }) {
  try {
    const { response } = yield call(apiPut, `/admin/item_version/delete/${id}`);
    if (response) {
      yield put(actions.shell.toast('success', 'Item version deleted.'));
      if (meta.onSuccess) meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export const loadAdminOptions = function*({ meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/item_version/options');
    if (response && meta.onSuccess) meta.onSuccess(response);
  } catch (error) {
    console.error(error);
  }
};

export const getItemVersionHistory = function*({ id, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/item_version/history/${id}`);

    if (response.data) {
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(itemVersionActions.LOAD_ITEM_VERSIONS, loadItemVersions);
  yield takeEvery(itemVersionActions.FETCH_BY_ID, fetchById);
  yield takeEvery(itemVersionActions.ADD_ITEM_VERSION, addItemVersion);
  yield takeEvery(itemVersionActions.UPDATE_ITEM_VERSION, updateItemVersion);
  yield takeEvery(itemVersionActions.DELETE_ITEM_VERSION, deleteItemVersion);
  yield takeEvery(itemVersionActions.LOAD_OPTIONS, loadAdminOptions);
  yield takeEvery(itemVersionActions.GET_HISTORY, getItemVersionHistory);
}
