const initialState = {};

export default function announcement(state = initialState, { type, payload }) {
  switch (type) {
    case 'ANNOUNCEMENT/LOAD_ANNOUNCEMENTS_DONE':
      return { list: payload.announcements };

    default:
      return state;
  }
}
