import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as guid } from 'uuid';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

import { getFileUploadState } from 'sow/reducers/fileUpload';
import {
  addSingleFile,
  uploadSingleFile,
  updateSingleFile,
} from 'sow/actions/fileUpload';

import DocForm from './DocForm';

const mapStateToProps = state => ({
  queue: getFileUploadState(state).singleFile,
});

class AttachmentFileUpload extends Component {
  static propTypes = {
    uploadUrl: PropTypes.string,
    onUploadSuccess: PropTypes.func,
  };

  componentDidMount() {
    // Clear the current file if there is one
    this.props.dispatch(addSingleFile(null));
  }

  handleDrop(files) {
    // Dropzone always returns an array onDrop
    const file = files[0];

    this.props.dispatch(
      addSingleFile({
        guid: guid(),
        uploadUrl: this.props.uploadUrl,
        signedUploadUrl: `${this.props.uploadUrl}/signedUrl`,
        status: 'pending',
        progress: 0,
        error: null,
        errors: [],
        file,
      }),
    );
  }

  handleSubmit = event => {
    event.preventDefault();

    const { queue, onUploadSuccess } = this.props;
    this.props.dispatch(uploadSingleFile(queue, onUploadSuccess));
  };

  prettyStatus(status) {
    switch (status) {
      case 'pending':
        return 'Pending';

      case 'uploading':
        return (
          <span className="text-muted">
            <i>Uploading...</i>
          </span>
        );

      case 'success':
        return (
          <span className="text-success">
            <span className="glyphicon glyphicon-ok" /> Success
          </span>
        );

      case 'failed':
        return (
          <span className="text-danger">
            <span className="glyphicon glyphicon-remove" /> Failed
          </span>
        );

      default:
        return null;
    }
  }

  canUpload(queue) {
    return !!queue;
  }

  handleFormChange = (field, value) => {
    this.props.dispatch(updateSingleFile(field, value));
  };

  render() {
    const { queue } = this.props;

    const queueItem = queue && (
      <div>
        <div className="pull-left">{queue.file.name}</div>
        <div className="pull-right">{this.prettyStatus(queue.status)}</div>
        <div className="clearfix" />
        <div className="progress" style={{ height: '30px' }}>
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            aria-valuenow={queue.progress}
            style={{ width: `${queue.progress}%` }}
          />
        </div>

        <DocForm attachment={queue} handleChange={this.handleFormChange} />
      </div>
    );

    return (
      <div>
        <Dropzone
          onDrop={files => this.handleDrop(files)}
          className="drop-zone"
          multiple={false}
        >
          <p>Drop file here or click to browse.</p>
        </Dropzone>

        <form onSubmit={this.handleSubmit}>
          {queueItem}

          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!this.canUpload(queue)}
            >
              Upload
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(mapStateToProps)(AttachmentFileUpload);
