import React, { Fragment } from 'react';
import moment from 'moment';

import { monthDayYearDateFormat } from 'sow/utils/dateTime';
import { orgRoute } from 'sow/routes';

import PlanAppProgressCircle from 'sow/components/organisms/PlanAppProgressCircle';
import Block from 'sow/components/atoms/Block';
import Spinner from 'sow/components/atoms/Spinner';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Spacer from 'sow/components/atoms/Spacer';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Heading from 'sow/components/atoms/Heading';
import Link from 'sow/components/atoms/Link';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';

const UnannouncedInspectionReport = ({
  isFetching,
  unannouncedReportGenerated,
  completedCount,
  pendingCount,
  inspectionYearData,
  completedInspections,
  pendingInspections,
}) => (
  <Fragment>
    {!isFetching && unannouncedReportGenerated ? (
      <Widget className="unannounced-inspection-report">
        <WidgetHeading>Inspection Year: {inspectionYearData.year}</WidgetHeading>
        <WidgetBody>
          <Row className="info-row">
            <Column xs={12} md={3} lg={2}>
              <PlanAppProgressCircle
                percentageComplete={
                  (completedCount / inspectionYearData.required_quantity) * 100
                }
                innerTextContent={
                  <Fragment>
                    <Block className="percentage">
                      {Math.round(
                        (completedCount / inspectionYearData.required_quantity) * 100,
                      )}
                      %
                    </Block>
                    <Block className="percentage-label">Completed</Block>
                  </Fragment>
                }
              />
            </Column>
            <Column xs={12} md={3} lg={2} className="info-card">
              <Spacer vertical={50} />
              <Block className="data-display">
                {completedCount} of {inspectionYearData.required_quantity}
              </Block>
              <Spacer vertical={10} />
              <Block className="data-text">Completed</Block>
            </Column>
            <Column xs={12} md={3} lg={2} className="info-card">
              <Spacer vertical={50} />
              <Block className="data-display">{pendingCount}</Block>
              <Spacer vertical={10} />
              <Block className="data-text">Pending</Block>
            </Column>
            <Column xs={12} md={3} lg={2} className="info-card">
              <Spacer vertical={50} />
              <Block className="data-display">
                {inspectionYearData.average_inspection_age
                  ? inspectionYearData.average_inspection_age === 1
                    ? `${inspectionYearData.average_inspection_age} day`
                    : `${inspectionYearData.average_inspection_age} days`
                  : `0 days`}
              </Block>
              <Spacer vertical={10} />
              <Block className="data-text">Average Inspection Age</Block>
            </Column>
          </Row>
          {completedCount > 0 && (
            <Fragment>
              <Spacer vertical={50} />
              <Heading>Completed Inspections</Heading>
              <Table
                className="unannounced-table"
                striped
                head={
                  <TableRow>
                    <TableCell heading>Inspection ID</TableCell>
                    <TableCell heading>Organization</TableCell>
                    <TableCell heading>Inspection Type</TableCell>
                    <TableCell heading>Inspection Date</TableCell>
                    <TableCell heading>Completed On</TableCell>
                    <TableCell heading>Inspection Age</TableCell>
                  </TableRow>
                }
              >
                {completedInspections.map(inspection => (
                  <TableRow key={inspection.id}>
                    <TableCell>{inspection.id}</TableCell>
                    <TableCell>
                      <Link to={orgRoute(inspection.org.id)}>{inspection.org.name}</Link>
                    </TableCell>
                    <TableCell>{inspection.inspection_type}</TableCell>
                    <TableCell>
                      {inspection.inspection_date
                        ? monthDayYearDateFormat(inspection.inspection_date)
                        : 'Not set'}
                    </TableCell>
                    <TableCell>
                      {moment
                        .utc(inspection.completed_on)
                        .local()
                        .format('MM/DD/YYYY')}
                    </TableCell>
                    <TableCell>
                      {inspection.inspection_age > 0
                        ? `${inspection.inspection_age} days old`
                        : inspection.inspection_age === 0
                        ? '< 1 day old'
                        : 'Not released'}
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </Fragment>
          )}
          {pendingCount > 0 && (
            <Fragment>
              <Spacer vertical={50} />
              <Heading>Pending Inspections</Heading>
              <Table
                className="unannounced-table"
                striped
                head={
                  <TableRow>
                    <TableCell heading>Inspection ID</TableCell>
                    <TableCell heading>Organization</TableCell>
                    <TableCell heading>Inspection Type</TableCell>
                    <TableCell heading>Inspection Date</TableCell>
                    <TableCell heading>Released To Inspector</TableCell>
                    <TableCell heading>Inspection Age</TableCell>
                  </TableRow>
                }
              >
                {pendingInspections.map(inspection => (
                  <TableRow key={inspection.id}>
                    <TableCell>{inspection.id}</TableCell>
                    <TableCell>
                      <Link to={orgRoute(inspection.org.id)}>{inspection.org.name}</Link>
                    </TableCell>
                    <TableCell>{inspection.inspection_type}</TableCell>
                    <TableCell>
                      {inspection.inspection_date
                        ? monthDayYearDateFormat(inspection.inspection_date)
                        : 'Not set'}
                    </TableCell>
                    <TableCell>
                      {inspection.released_on
                        ? moment
                            .utc(inspection.released_on)
                            .local()
                            .format('MM/DD/YYYY')
                        : 'Not released'}
                    </TableCell>
                    <TableCell>
                      {inspection.inspection_age > 0
                        ? `${inspection.inspection_age} days old`
                        : inspection.inspection_age === 0
                        ? '< 1 day old'
                        : 'Not released'}
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </Fragment>
          )}
        </WidgetBody>
      </Widget>
    ) : (
      <Spinner />
    )}
  </Fragment>
);

export default UnannouncedInspectionReport;
