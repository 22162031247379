import * as R from 'ramda';

import actions from 'sow/actions/pure';

const ids = (state = [], action) => {
  switch (action.type) {
    case String(actions.ospApp.loadOrgListDone):
      // overwrite current ids, use R.union to join
      return R.pluck('id', action.payload.result);

    default:
      return state;
  }
};

export default ids;
