export const UPDATE_ONE = 'OSP_DEF/UPDATE_ONE';
export const UPDATE_ONE_DONE = 'OSP_DEF/UPDATE_ONE_DONE';
export const UPDATE_ONE_FAIL = 'OSP_DEF/UPDATE_ONE_FAIL';

export const updateOne = (acaId, ospDef, onSuccess) => ({
  type: UPDATE_ONE,
  payload: {
    acaId,
    ospDef,
  },
  meta: {
    onSuccess, // TODO (orther) remove?
  },
});

export const updateOneDone = payload => ({
  type: UPDATE_ONE_DONE,
  payload,
});

export const updateOneFail = payload => ({
  type: UPDATE_ONE_FAIL,
  payload,
  error: true,
});
