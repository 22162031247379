import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { withRouter } from 'react-router';

import actions from 'sow/actions/pure';
import * as currentOrg from 'sow/selectors/currentOrg';
import OrgLocations from 'sow/components/organisms/OrgLocations';

const mapStateToProps = (state, props) => ({
  appId: currentOrg.currentAppId(state, props),
  orgId: currentOrg.orgId(state, props),
  locationList: currentOrg.orgLocations(state, props),
  isFetchingLocations: currentOrg.fetchingLocations(state, props),
});

const mapDispatchToProps = {
  listLocations: actions.org.listLocations,
};

class OrgLocationsContainer extends Component {
  componentDidMount() {
    this.props.listLocations(this.props.orgId, this.props.appId);
  }

  render() {
    return <OrgLocations locationList={this.props.locationList} {...this.props} />;
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrgLocationsContainer);
