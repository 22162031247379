import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as currentOrg from 'sow/selectors/currentOrg';
import * as organizationHistoryActions from 'sow/actions/pure/organizationHistory';

import OrgSettingsHistory from 'sow/components/organisms/OrgSettingsHistory';

const mapStateToProps = (state, props) => ({ orgId: currentOrg.orgId(state, props) });

const mapDispatchToProps = {
  loadHistory: organizationHistoryActions.loadOrganizationHistory,
};

class OrgSettingsHistoryContainer extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = { isLoading: true };
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.loadHistory({ orgId: this.props.orgId }, this.updateState.bind(this));
  }

  updateState({ data }) {
    if (this._isMounted) {
      this.setState({ isLoading: false, organizationHistory: data.organization_history });
    }
  }

  render() {
    return <OrgSettingsHistory {...this.state} />;
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrgSettingsHistoryContainer);
