import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter, Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getAuthState, isAuthenticated as isAuthenticatedState } from 'sow/reducers/auth';
import { loginRoute, errorForbiddenRoute } from 'sow/routes';

const mapStateToProps = state => ({
  userRoles: getAuthState(state).roles,
  isAuthenticated: isAuthenticatedState(state),
});

const SecuredRoute = ({
  allowedRoles,
  blockedRoles,
  location,
  userRoles,
  isAuthenticated,
  ...props
}) => {
  if (!isAuthenticated) {
    // If not authenticated, redirect to the login page.
    // The current route will be saved in the router's `state` prop so
    // it can be used for redirect after a successful login attempt.
    return (
      <Redirect
        to={{
          pathname: loginRoute(),
          state: {
            postLoginRedirect: {
              pathname: location.pathname,
              search: location.search,
            },
          },
        }}
      />
    );
  }

  const hasAllowedRole = R.pipe(
    R.prepend('admin'), // Admin has access to all routes
    R.intersection(userRoles),
    R.length,
    R.gt(R.__, 0),
  )(allowedRoles);

  let hasBlockedRole;
  if (R.contains(userRoles, 'admin')) {
    hasBlockedRole = false; // Admin has access to all routes
  } else {
    hasBlockedRole = R.pipe(
      R.intersection(userRoles),
      R.length,
      R.gt(R.__, 0),
    )(blockedRoles);
  }

  // Allow access if the role list provided was empty; they just need to be logged in
  const isAllowed = (hasAllowedRole || !allowedRoles.length) && !hasBlockedRole;

  if (!isAllowed) {
    return <Redirect to={errorForbiddenRoute()} />;
  }

  return <Route {...props} />;
};

SecuredRoute.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  blockedRoles: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isAuthenticated: PropTypes.bool.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string),
};

SecuredRoute.defaultProps = {
  userRoles: [],
  allowedRoles: [],
  blockedRoles: [],
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(SecuredRoute);
