import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';

import FormModal from 'sow/components/old/FormModal';
import * as select from '../selectors';
import ScopeWorksheets from './ScopeWorksheets';
import { actions as ospSecAction } from '../reducers/ospSections';

const makeMapStateToProps = () => (state, props) => ({
  ospSection: select.makeOspSectionSelector()(state, props),
});

const mapDispatchToProps = dispatch => ({
  onEditDone: () => dispatch(ospSecAction.scopeWorksheetsEditDone()),
});

export const ScopeWorksheetsModal = ({ ospSectionId, ospSection, onEditDone }) => {
  const miniOspName = R.path(['name'], ospSection);
  const modalTitle = (
    <span>
      Location/Product Plan Worksheets <small>{miniOspName}</small>
    </span>
  );

  return (
    <FormModal
      show={!!ospSectionId}
      title={modalTitle}
      onHide={onEditDone}
      onSubmit={onEditDone}
      bsSize="lg"
    >
      <ScopeWorksheets ospSectionId={ospSectionId} />
    </FormModal>
  );
};

export default R.compose(
  connect(
    makeMapStateToProps,
    mapDispatchToProps,
  ),
)(ScopeWorksheetsModal);
