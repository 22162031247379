import * as R from 'ramda';

import { getAuthToken } from 'sow/utils/authToken';

export const requestAuthToken = request => {
  if (!request.headers['X-Auth-Token']) {
    let token = getAuthToken();

    if (token) {
      request.headers['X-Auth-Token'] = token;
    }
  }
  return request;
};

export const responseCheckStatus = response => {
  if (response.ok) {
    return response;
  }

  const error = new Error(`${response.status} ${response.statusText}`);
  error.response = response;
  throw error;
};

export const responseUnnestData = response => {
  const nestedData = R.path(['data', 'data'], response);
  if (nestedData) {
    response.data = nestedData;
  }
  return response;
};
