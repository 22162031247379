import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import Spacer from 'sow/components/atoms/Spacer';
import PlanAppWorksheetForm from 'sow/containers/planApp/WorksheetForm';
import ChangeRequestManager from 'sow/containers/planApp/ChangeRequestManager';

const OrgPlanAppWorksheetPage = props => {
  return props.isStateUnderReview ? (
    <Fragment>
      <ChangeRequestManager
        title="Changes for this worksheet"
        showPlanWorksheets
        clearTodos
      />
      <Spacer vertical={20} />
      <PlanAppWorksheetForm />
    </Fragment>
  ) : (
    <Fragment>
      <PlanAppWorksheetForm />
    </Fragment>
  );
};

OrgPlanAppWorksheetPage.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheet: sowTypes.planAppWorksheetType.isRequired,
  isStateUnderReview: PropTypes.bool,
  isStateInitialApplication: PropTypes.bool,
};

export default OrgPlanAppWorksheetPage;
