import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import Atom from 'sow/components/atoms/Atom';
import Tooltip from 'sow/components/molecules/Tooltip';

const Timestamp = ({ date, format, tooltipFormat }) => {
  const converted = moment.utc(date).tz(moment.tz.guess());

  const formattedDate = format
    ? moment(converted).format(format)
    : moment(converted).fromNow();

  const tooltip = converted.format(tooltipFormat);

  return (
    <Tooltip overlay={tooltip}>
      <Atom>{formattedDate}</Atom>
    </Tooltip>
  );
};

Timestamp.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  /** Moment format that the tooltip will be displayed in. If not set, defaults to `fromNow` */
  format: PropTypes.string,
  /** Moment format that the tooltip will be displayed in */
  tooltipFormat: PropTypes.string,
};

Timestamp.defaultProps = {
  format: null,
  tooltipFormat: 'MMM Do, YYYY [at] h:mm a zz',
};

export default Timestamp;
