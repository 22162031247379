import { createEntitiesHelpers, createResourceHelpers } from 'uptrend-redux-modules';
import store from 'sow/store';
import * as entities from 'sow/store/modules/entities';
import * as resource from 'sow/store/modules/resource';

const { EntityDetail, EntityList } = createEntitiesHelpers({ entities });
const {
  ResourceDetailLoader,
  ResourceListLoader,
  resourceCreate,
  resourceDelete,
  resourceDetailRead,
  resourceListCreate,
  resourceListRead,
  resourceUpdate,
} = createResourceHelpers({ entities, resource, store });

export {
  EntityDetail,
  EntityList,
  ResourceDetailLoader,
  ResourceListLoader,
  resourceCreate,
  resourceDelete,
  resourceDetailRead,
  resourceListCreate,
  resourceListRead,
  resourceUpdate,
};
