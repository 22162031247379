import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';

import { uploadFileQueue, removeAllFromFileQueue } from 'sow/actions/fileUpload';
import { getFileUploadState } from 'sow/reducers/fileUpload';
import { certificationTypeRoute } from 'sow/routes';
import actions from 'sow/actions/pure';
import * as certificationTypeActions from 'sow/actions/pure/certificationType';
import CertificationTypesEdit from 'sow/components/organisms/CertificationTypesEdit';

const mapStateToProps = state => ({
  certification: state.certificationType.one,
  queue: getFileUploadState(state).fileQueue,
});

const mapDispatchToProps = {
  loadList: certificationTypeActions.loadAllCertificationTypes,
  loadOne: certificationTypeActions.loadCertificationType,
  clearOne: certificationTypeActions.clearOne,
  saveCertification: certificationTypeActions.saveCertificationType,
  updateCertification: certificationTypeActions.updateCertificationType,
  deleteCertification: certificationTypeActions.deleteCertificationType,
  deleteImage: certificationTypeActions.deleteCertificationImage,
  toast: actions.shell.toast,
  uploadFileQueue,
  removeAllFromFileQueue,
  redirect: routerPush,
};

class CertificationTypesEditContainer extends Component {
  constructor(props) {
    super(props);

    // Used to prevent react memory leak.
    this._isMounted = false;
    this.state = { enabled: true, isNew: false, isLoading: true };
  }

  updateState({ data }) {
    if (this.props.queue.length > 0) {
      this.props.removeAllFromFileQueue();
    }
    if (this.state.isNew) {
      history.pushState(null, null, `${data.id}`);
    }
    if (this._isMounted) {
      this.setState({
        id: data.id,
        name: data.name,
        sort_order: data.sort_order,
        enabled: data.enabled,
        file_path: data.file && !data.file.removed_from_bucket ? data.file.s3_name : null,
        isLoading: false,
        isNew: false,
      });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    const id = this.props.match.params.id;
    this.setState({ isNew: id === 'new' });
    if (id !== 'new') {
      this.props.loadOne(id, this.updateState.bind(this), () =>
        this.props.redirect(certificationTypeRoute()),
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.clearOne();
    this.props.removeAllFromFileQueue();
  }

  handleInputChange({ target }) {
    this.setState({
      [target.id]: target.id === 'sort_order' ? Number(target.value) : target.value,
    });
  }

  handleOptionChange() {
    const { enabled } = this.state;
    this.setState({ enabled: !enabled });
  }

  validCertificationEntry() {
    const requiredEntries = ['enabled', 'name'];

    for (let i = 0; i < requiredEntries.length; i++) {
      if (
        !R.has(requiredEntries[i])(this.state) ||
        R.isEmpty(this.state[requiredEntries[i]])
      ) {
        return false;
      }
    }

    return true;
  }

  handleUploadSuccess({ data }) {
    if (this.state.isNew) {
      this.addCertificationType(data.id);
    } else {
      this.props.updateCertification(
        {
          id: this.state.id,
          name: this.state.name,
          sort_order: this.state.sort_order,
          enabled: this.state.enabled,
          file_id: data.id,
        },
        this.updateState.bind(this),
      );
    }
  }

  addCertificationType(fileId) {
    this.props.saveCertification(
      {
        name: this.state.name,
        sort_order: this.state.sort_order,
        enabled: this.state.enabled,
        file_id: fileId,
      },
      this.updateState.bind(this),
    );
  }

  // Verify changes have been made before making update
  validUpdate() {
    const requiredEntries = ['enabled', 'name', 'sort_order'];

    for (let i = 0; i < requiredEntries.length; i++) {
      if (
        this.props.certification[requiredEntries[i]] !== this.state[requiredEntries[i]]
      ) {
        this.props.updateCertification(
          {
            id: this.state.id,
            name: this.state.name,
            sort_order: this.state.sort_order ? this.state.sort_order : null,
            enabled: this.state.enabled,
          },
          this.updateState.bind(this),
        );
        break;
      }
    }
  }

  handleSave() {
    if (this.validCertificationEntry() && this.props.queue.length > 0) {
      this.props.uploadFileQueue(this.props.queue, this.handleUploadSuccess.bind(this));
    } else if (this.validCertificationEntry() && this.state.isNew) {
      this.addCertificationType(null);
    } else if (!this.state.isNew) {
      this.validUpdate();
    } else {
      this.props.toast('danger', 'Missing Input');
    }
  }

  handleDelete() {
    const hasImage = !R.isNil(this.state.file_path);
    this.props.deleteCertification({ id: this.state.id, hasImage }, () =>
      this.props.redirect(certificationTypeRoute()),
    );
  }

  deleteImage() {
    this.props.deleteImage(this.state.id, this.updateState.bind(this));
  }

  render() {
    return (
      <CertificationTypesEdit
        formValues={this.state}
        isLoading={this.state.isLoading}
        handleInputChange={this.handleInputChange.bind(this)}
        handleOptionChange={this.handleOptionChange.bind(this)}
        handleSave={this.handleSave.bind(this)}
        handleDelete={this.handleDelete.bind(this)}
        deleteImage={this.deleteImage.bind(this)}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CertificationTypesEditContainer);
