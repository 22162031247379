import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { planAppStateText } from 'sow/constants/planApp';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import PlanAppStepper from 'sow/components/organisms/PlanAppStepper';
import Label from 'sow/components/molecules/Label';
import Spacer from 'sow/components/atoms/Spacer';
import PlanAppProgressBar from 'sow/components/organisms/PlanAppProgressBar';

const PlanAppOverview = ({
  orgId,
  planAppId,
  worksheetId,
  planApp,
  isStateInitialApplication,
}) => {
  const percentageComplete = R.prop('percentageComplete', planApp);
  const showProgress = isStateInitialApplication && !R.isNil(percentageComplete);

  return (
    <Widget>
      <WidgetHeading>
        Application Status <Label info>{planAppStateText[planApp.state]}</Label>
      </WidgetHeading>
      <WidgetBody style={{ paddingBottom: 0 }}>
        {showProgress && (
          <Fragment>
            <PlanAppProgressBar percentageComplete={percentageComplete} />
            <Spacer vertical={20} />
          </Fragment>
        )}

        <PlanAppStepper orgId={orgId} planAppId={planAppId} worksheetId={worksheetId} />
        <Spacer vertical={20} />
      </WidgetBody>
    </Widget>
  );
};

PlanAppOverview.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheetId: sowTypes.planAppWorksheetIdType,
  planApp: sowTypes.planAppType.isRequired,
  isStateInitialApplication: PropTypes.bool.isRequired,
};

export default PlanAppOverview;
