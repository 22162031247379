import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import CertificationTypesEditContainer from 'sow/containers/CertificationTypesEditContainer';

const CertificationTypesEditPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <CertificationTypesEditContainer />}
  </AdminPageTemplate>
);

export default CertificationTypesEditPage;
