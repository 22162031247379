import React from 'react';

import './CSSModules/OrgLocationsPage.module.css';
import OrgPageTemplate from 'sow/containers/OrgPageTemplate';
import OrgLocations from 'sow/containers/OrgLocations';

const OrgLocationsPage = () => (
  <OrgPageTemplate title="Locations">
    <OrgLocations />
  </OrgPageTemplate>
);

export default OrgLocationsPage;
