import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { resourceUpdate, resourceDelete } from 'sow/store/helpers';
import { userSubtypeId } from 'sow/constants/userType';

import InspectionManagerBlock from 'sow/components/organisms/InspectionManager/InspectionManagerBlock';

const compareArrays = (initialValues, newValues) => {
  if (initialValues.length === 0 && newValues.length === 0) {
    return [[], []];
  } else if (initialValues.length === 0 && newValues.length > 0) {
    return [newValues, []];
  } else if (initialValues.length > 0 && newValues.length === 0) {
    return [[], initialValues];
  } else if (initialValues.length > 0 && newValues.length > 0) {
    const addedValues = R.filter(item => !R.contains(item, initialValues), newValues);
    const removedValues = R.filter(item => !R.contains(item, newValues), initialValues);
    return [addedValues, removedValues];
  }
};

const updateInspectionAssociations = (orgId, planAppId) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/update_inspection_associations`,
    'planAppResp',
  );

const deleteInspection = (orgId, planAppId, inspectionId) =>
  resourceDelete(
    `org/${orgId}/application/${planAppId}/delete_partial_inspection/${inspectionId}`,
    'planAppResp',
  );

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {
  updateInspectionAssociations: (orgId, planAppId, updates) =>
    updateInspectionAssociations(orgId, planAppId).action(null, updates),
  deleteInspection: (orgId, planAppId, inspectionId) =>
    deleteInspection(orgId, planAppId, inspectionId).action(),
};

class InspectionManagerBlockContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      manageInspectionModalShow: false,
    };
  }

  initialValues() {
    const { inspection } = this.props;

    return {
      inspection_year: inspection.inspectionYear,
      inspection_sub_type_id: inspection.inspectionSubTypeId,
      inspector_id: inspection.inspectors
        ? inspection.inspectors.map(inspector => ({
            value: inspector.id,
            label: inspector.name,
          }))
        : [],
      sample: inspection.samples.map(sample => ({
        value: sample.typeId,
        label: sample.typeName,
      })),
    };
  }

  compareValues(initialValues, values) {
    const { annual, inspection } = this.props;
    const updatedValues = {};
    for (let key in initialValues) {
      switch (key) {
        case 'inspection_sub_type_id':
          if (initialValues[key] !== values[key]) {
            updatedValues[key] = values[key];
          }
          break;
        case 'inspection_year':
          if (initialValues[key] !== values[key]) {
            updatedValues[key] = values[key];
          }
          break;
        case 'inspector_id':
          const [addedInspectors, removedInspectors] = compareArrays(
            initialValues[key],
            values[key],
          );
          if (!R.isEmpty(addedInspectors)) {
            updatedValues[`${key}_added`] = addedInspectors.map(inspector => ({
              user_id: inspector.value,
              user_subtype_id: annual
                ? userSubtypeId.annualInspector
                : userSubtypeId.partialInspector,
            }));
          }
          if (!R.isEmpty(removedInspectors)) {
            updatedValues[`${key}_removed`] = removedInspectors.map(inspector => ({
              row_id: R.find(R.propEq('id', inspector.value), inspection.inspectors)
                .organizationUserId,
            }));
          }
          break;

        case 'sample':
          const [addedSamples, removedSamples] = compareArrays(
            initialValues[key],
            values[key],
          );

          if (!R.isEmpty(addedSamples)) {
            updatedValues[`${key}_added`] = addedSamples.map(sample => ({
              inspection_id: inspection.id,
              sample_type_id: sample.value,
            }));
          }

          if (!R.isEmpty(removedSamples)) {
            updatedValues[`${key}_removed`] = removedSamples.map(sample => {
              return {
                sample_id: R.find(R.propEq('typeId', sample.value), inspection.samples)
                  .id,
              };
            });
          }
          break;

        default:
          break;
      }
    }

    if (!R.isEmpty(updatedValues)) {
      const {
        orgId,
        application_id,
        inspection,
        updateInspectionAssociations,
      } = this.props;
      updatedValues.inspection_id = inspection.id;

      updateInspectionAssociations(orgId, application_id, updatedValues).then(() =>
        this.toggleEditModal(),
      );
    } else {
      this.toggleEditModal();
    }
  }

  toggleEditModal() {
    const { manageInspectionModalShow } = this.state;
    this.setState(R.assocPath(['manageInspectionModalShow'], !manageInspectionModalShow));
  }

  deletePartialInspection(partialInspectionId) {
    const { orgId, application_id } = this.props;
    this.props.deleteInspection(orgId, application_id, partialInspectionId);
  }

  editInspection(initialValues, values, annual) {
    this.compareValues(initialValues, values, annual);
  }

  render() {
    return (
      <InspectionManagerBlock
        toggleEditModal={this.toggleEditModal.bind(this)}
        show={this.state.manageInspectionModalShow}
        deletePartialInspection={this.deletePartialInspection.bind(this)}
        editInspection={this.editInspection.bind(this)}
        initialValues={this.initialValues()}
        {...this.props}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InspectionManagerBlockContainer);
