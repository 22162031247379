import React from 'react';
import Spinner from 'sow/components/atoms/Spinner';

const AuthenticatingSpinner = () =>
  <div className="centered">
    <Spinner>
      <i>Please wait, logging you in</i>
    </Spinner>
  </div>;

export default AuthenticatingSpinner;
