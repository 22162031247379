import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as certificationTypeActions from 'sow/actions/pure/certificationType';
import { apiGet, apiDelete, apiPost } from 'sow/sagas/api';

export const getAllCertificationTypes = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/certification_type', payload);

    if (response) {
      yield put(certificationTypeActions.loadAllCertificationTypesDone(response.data));
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getOneCertificationType = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/certification_type/${payload}`);
    if (response.data) {
      yield put(certificationTypeActions.loadCertificationTypeDone(response.data));
      if (meta.onSuccess) {
        meta.onSuccess(response);
      }
    } else {
      if (meta.onFailure) meta.onFailure();
    }
  } catch (error) {
    console.error(error);
  }
};

export const saveCertificationType = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/certification_type', payload);

    if (response) {
      yield put(actions.shell.toast('success', 'Certification type added.'));
      if (meta.onSuccess) {
        meta.onSuccess(response);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateCertificationType = function*({ payload, meta }) {
  try {
    const { response } = yield call(
      apiPost,
      `/admin/certification_type/${payload.id}`,
      payload,
    );
    if (response) {
      yield put(actions.shell.toast('success', 'Certification type updated.'));
      yield put(certificationTypeActions.updateCertificationTypeDone(response.data));
      if (meta.onSuccess) {
        meta.onSuccess(response);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateSortOrder = function*({ payload }) {
  try {
    const { response } = yield call(apiPost, '/admin/certification_type/update_order', {
      updated_list: payload,
    });

    if (response) {
      yield put(certificationTypeActions.loadAllCertificationTypesDone(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteCertificationType = function*({ payload, meta }) {
  try {
    const { id, hasImage } = payload;
    const { response } = yield call(apiPost, `/admin/certification_type/delete/${id}`, {
      has_image: hasImage,
    });

    if (response.data) {
      yield put(actions.shell.toast('success', 'Certification type deleted.'));
      if (meta.onSuccess) meta.onSuccess();
    } else if (response.error) {
      yield put(actions.shell.toast('danger', response.error));
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteCertificationImage = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiDelete, `/admin/certification_img/${payload}`);
    if (response.data) {
      yield put(actions.shell.toast('success', 'Image deleted.'));
      yield put(certificationTypeActions.loadCertificationTypeDone(response.data));
      if (meta.onSuccess) meta.onSuccess(response);
    } else if (response.error) {
      yield put(actions.shell.toast('danger', response.error));
    }
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(
    certificationTypeActions.LOAD_ALL_CERTIFICATIONS,
    getAllCertificationTypes,
  );
  yield takeEvery(
    certificationTypeActions.LOAD_CERTIFICATION_TYPE,
    getOneCertificationType,
  );
  yield takeEvery(
    certificationTypeActions.SAVE_CERTIFICATION_TYPE,
    saveCertificationType,
  );
  yield takeEvery(
    certificationTypeActions.UPDATE_CERTIFICATION_TYPE,
    updateCertificationType,
  );
  yield takeEvery(certificationTypeActions.UPDATE_SORT_ORDER, updateSortOrder);
  yield takeEvery(
    certificationTypeActions.DELETE_CERTIFICATION_TYPE,
    deleteCertificationType,
  );
  yield takeEvery(
    certificationTypeActions.DELETE_CERTIFICATION_IMAGE,
    deleteCertificationImage,
  );
}
