import React from 'react';
import PropTypes from 'prop-types';
import Block from 'sow/components/atoms/Block';
import HeaderContent from 'sow/components/organisms/HeaderContent';


const CenteredLayout = ({ noHeader, isLogin, children }) => (
  <Block className="container text-center">
    <Block className="centered-layout">
      {!noHeader && (
        <HeaderContent isLogin={isLogin} />
      )}
      {children}
    </Block>
  </Block>
);

CenteredLayout.propTypes = {
  noHeader: PropTypes.bool,
  isLogin: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

CenteredLayout.defaultProps = {
  noHeader: false,
  isLogin: false
};

export default CenteredLayout;
