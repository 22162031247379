import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { generateRandomPassword } from 'sow/utils/randomPassword';
import { userTypeId } from 'sow/constants/userType';

import * as currentOrg from 'sow/selectors/currentOrg';
import * as userManagementActions from 'sow/actions/pure/userManagement';
import Spinner from 'sow/components/atoms/Spinner';
import ContactInfoStaff from 'sow/components/organisms/ContactInfoStaff';

const compactObj = R.reject(R.isEmpty);

const validEmailFormat = emailEntry => {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const validEmail = regex.test(emailEntry);
  return validEmail;
};

const initialState = {
  modalProperties: {
    show: false,
    formValues: { userEmail: '', userPhone: '', userName: '' },
    errors: {},
  },
  assignValues: {},
  showModal: false,
  currentPrimary: {},
};

const mapStateToProps = (state, props) => ({
  orgId: currentOrg.orgId(state, props),
  clientUsers: state.userManagement.requestedUsers,
  selectOptions: state.userManagement.selectOptions,
  usersLoaded: state.userManagement.usersLoaded,
});

const mapDispatchToProps = {
  loadClientUsers: userManagementActions.loadRequestedUsers,
  reassignUser: userManagementActions.assignUser,
  unassignPrimaryUser: userManagementActions.revokeUser,
  clearResults: userManagementActions.clearResults,
  addClientToOrg: userManagementActions.contactPageAddClientToOrg,
  createNewClientAndAdd: userManagementActions.createUserAddToOrg,
};

class ContactInfoStaffContainer extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    this.loadClients();
  }

  componentWillUnmount() {
    this.props.clearResults('requestedUsers');
  }

  loadClients() {
    this.props.loadClientUsers({
      orgId: this.props.orgId,
      userTypeId: userTypeId.client,
      selectOptions: true,
    });
  }

  handleInputChange({ target }) {
    this.setState(
      R.assocPath(['modalProperties', 'formValues', target.id], target.value),
      () => this.errorSpotCheck(target.id),
    );
  }

  errorSpotCheck(id) {
    const { modalProperties } = this.state;
    const { errors } = modalProperties;
    if (R.has(id)(errors)) {
      const resetErrors = compactObj({
        ...errors,
        [id]: [],
      });
      this.setState(R.assocPath(['modalProperties', 'errors'], resetErrors));
    }
  }

  handleSelectChange(target, field) {
    const { assignValues } = this.state;
    if (field == 'userSelect') {
      this.setState({
        assignValues: {
          ...assignValues,
          [field]: target ? target.value : null,
          userName: target ? target.label : null,
        },
      });
    } else {
      this.setState({
        assignValues: { ...assignValues, [field]: target ? target.value : null },
      });
    }
  }

  handleAssign() {
    const { assignValues } = this.state;
    const { orgId } = this.props;
    this.props.reassignUser(
      {
        user_id: assignValues.userSelect,
        organization_id: orgId,
        admin: true,
        user_type_id: userTypeId.client,
        user_subtype_id: assignValues.userSubtype,
      },
      this.loadClients.bind(this),
      this.primaryUserAssigned.bind(this),
    );
  }

  primaryUserAssigned(data, revoke) {
    this.setState({ showModal: true, currentPrimary: data, revoke: revoke });
  }

  onSavePrimary() {
    const { assignValues, revoke } = this.state;
    const { orgId } = this.props;
    if (revoke) {
      this.props.reassignUser(
        {
          overwrite: true,
          user_id: revoke.user_id,
          organization_id: revoke.org_id,
          user_type_id: userTypeId.client,
        },
        this.loadClients.bind(this),
      );
    } else {
      this.props.reassignUser(
        {
          overwrite: true,
          user_id: assignValues.userSelect,
          organization_id: orgId,
          admin: true,
          user_type_id: userTypeId.client,
          user_subtype_id: assignValues.userSubtype,
        },
        this.loadClients.bind(this),
      );
    }

    this.setState({ showModal: false });
  }

  onCancelPrimary() {
    this.setState({ showModal: false });
  }

  onModalExited() {
    this.setState({ currentPrimary: {}, revoke: false });
  }

  handleUnassign(userId) {
    const { orgId } = this.props;
    this.props.unassignPrimaryUser(
      { user_id: userId, organization_id: orgId },
      this.loadClients.bind(this),
      this.primaryUserAssigned.bind(this),
    );
  }

  toggleNewContactModal() {
    const { modalProperties } = this.state;
    this.setState({
      modalProperties: { ...modalProperties, show: !modalProperties.show },
    });
  }

  errorCheck() {
    const { modalProperties } = this.state;
    const { formValues, isNew } = modalProperties;

    let errors = {};

    if (isNew) {
      errors = compactObj({
        userName: R.isEmpty(formValues.userName) ? ['Field must be filled out.'] : [],
        userEmail: R.isEmpty(formValues.userEmail) ? ['Field must be filled out.'] : [],
        userPhone: R.isEmpty(formValues.userPhone) ? ['Field must be filled out.'] : [],
      });
    } else {
      errors = compactObj({
        userEmail: R.isEmpty(formValues.userEmail)
          ? ['Field must be filled out.']
          : validEmailFormat(formValues.userEmail)
          ? []
          : ['Enter valid email address'],
      });
    }

    this.setState(R.assocPath(['modalProperties', 'errors'], errors));

    return errors;
  }

  addUserToOrg() {
    const { modalProperties } = this.state;
    const { formValues, isNew } = modalProperties;
    const errors = this.errorCheck();
    if (R.isEmpty(errors)) {
      if (isNew) {
        const randomPassword = generateRandomPassword();
        const user = {
          name: formValues.userName,
          email: formValues.userEmail,
          inspector: false,
          is_workflow_manager: false,
          admin: false,
          is_certification_specialist: false,
          is_enabled: true,
          consultant: false,
          consultant_company_id: null,
          password: randomPassword,
          password_confirmation: randomPassword,
          settings: {
            primary_phone_number: formValues.userPhone,
            title: formValues.userTitle,
          },
        };
        this.props.createNewClientAndAdd(
          { orgId: this.props.orgId, user, welcomeEmail: true },
          this.handleUser.bind(this),
        );
      } else {
        this.props.addClientToOrg(
          { email: formValues.userEmail, orgId: this.props.orgId },
          this.handleUser.bind(this),
          this.toggleNewContactModal.bind(this),
        );
      }
    }
  }

  handleUser(data) {
    if (data.new) {
      const { modalProperties } = this.state;
      this.setState({ modalProperties: { ...modalProperties, isNew: true } });
    } else {
      this.loadClients();
      this.resetState();
    }
  }

  resetState() {
    this.setState(initialState);
  }

  render() {
    if (this.props.usersLoaded) {
      return (
        <ContactInfoStaff
          {...this.props}
          handleSelectChange={this.handleSelectChange.bind(this)}
          handleAssign={this.handleAssign.bind(this)}
          handleUnassign={this.handleUnassign.bind(this)}
          assignValues={this.state.assignValues}
          showModal={this.state.showModal}
          currentPrimary={this.state.currentPrimary}
          onSavePrimary={this.onSavePrimary.bind(this)}
          onCancelPrimary={this.onCancelPrimary.bind(this)}
          onModalExited={this.onModalExited.bind(this)}
          toggleNewContactModal={this.toggleNewContactModal.bind(this)}
          modalProperties={{
            ...this.state.modalProperties,
            handleInputChange: this.handleInputChange.bind(this),
            onCancel: this.toggleNewContactModal.bind(this),
            onConfirm: this.addUserToOrg.bind(this),
            onExited: this.resetState.bind(this),
          }}
        />
      );
    }
    return <Spinner />;
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ContactInfoStaffContainer);
