import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as sowTypes from 'sow/types';
import { fromRouter } from 'sow/store/selectors';
import { ResourceListLoader } from 'sow/store/helpers';
import OrgPageTemplate from 'sow/containers/OrgPageTemplate';
import Spinner from 'sow/components/atoms/Spinner';
import OrgLegacyLocationsPage from 'sow/components/pages/OrgLegacyLocationsPage';

const mapStateToProps = (state, props) => ({
  orgId: fromRouter.paramOrgId(state, props),
});

const OrgLegacyLocationsPageContainer = ({ orgId }) => {
  return (
    <OrgPageTemplate title="Legacy Locations">
      <ResourceListLoader
        resource={`org/${orgId}/land`}
        entityType="planAppLocation"
        autoLoad
      >
        {({ status, entities: locationIdList }) => {
          if (status.loading) return <Spinner />;
          if (!status.success) return null;
          return <OrgLegacyLocationsPage orgId={orgId} locationIdList={locationIdList} />;
        }}
      </ResourceListLoader>
    </OrgPageTemplate>
  );
};

OrgLegacyLocationsPageContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(OrgLegacyLocationsPageContainer);
