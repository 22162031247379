import * as R from 'ramda';
import { createSelector } from 'reselect';
import { locationAnswers, locationAnswersChangesMap } from './location';

const filterChangeListWorksheetAnswers = R.filter(
  R.whereEq({ type: 'worksheet_answer' }),
);

const filterChangeListWorksheetMatrixRowAnswers = R.filter(
  R.whereEq({ type: 'matrix_row_answer' }),
);

/**
 * Returns worksheet answers augmented with change old values
 */
export const locationFormInitialValuesAnswers = createSelector(
  [locationAnswers, locationAnswersChangesMap],
  (answers, changeMap) => {
    // Convert changes into structure that can be merged into answers
    const answersFromChangesOld = R.pipe(
      R.values,
      filterChangeListWorksheetAnswers,
      R.reduce((acc, change) => {
        const { worksheetId, questionId, old } = change;
        return R.assocPath(
          ['answers', 'worksheets', worksheetId, 'answers', 'values', questionId],
          old,
          acc,
        );
      }, {}),
      R.mergeDeepRight({ answers: {} }),
    )(changeMap);

    // Convert 'old' matrix answers into structure that can be merged into answers
    const matrixAnswersFromChangesOld = R.pipe(
      R.values,
      filterChangeListWorksheetMatrixRowAnswers,
      R.reduce((acc, change) => {
        const { worksheetId, matrixRowId, questionId, old } = change;
        return R.assocPath(
          [
            'answers',
            'worksheets',
            worksheetId,
            'answers',
            'matrixRows',
            matrixRowId,
            'values',
            questionId,
          ],
          old,
          acc,
        );
      }, {}),
    )(changeMap);

    return R.pipe(
      R.mergeDeepLeft(R.__, answersFromChangesOld),
      R.mergeDeepLeft(R.__, matrixAnswersFromChangesOld),
    )(answers);
  },
);

/**
 * Returns worksheet answers changes object for formik initial values.
 */
export const locationFormInitialValuesAnswersChanges = createSelector(
  [locationAnswersChangesMap],
  changeMap => {
    // Convert changes into structure that can be merged into answers
    const answersFromChangesNew = R.pipe(
      R.values,
      filterChangeListWorksheetAnswers,
      R.reduce((acc, change) => {
        const { worksheetId, questionId, new: newAnswer } = change;
        return R.assocPath(
          ['answersChanges', 'worksheets', worksheetId, 'answers', 'values', questionId],
          newAnswer,
          acc,
        );
      }, {}),
    )(changeMap);

    // Convert matrix answer changes into structure that can be merged into answers
    const matrixAnswersFromChangesNew = R.pipe(
      R.values,
      filterChangeListWorksheetMatrixRowAnswers,
      R.reduce((acc, change) => {
        const { worksheetId, matrixRowId, questionId, new: newAnswer } = change;
        return R.assocPath(
          [
            'answersChanges',
            'worksheets',
            worksheetId,
            'answers',
            'matrixRows',
            matrixRowId,
            'values',
            questionId,
          ],
          newAnswer,
          acc,
        );
      }, {}),
    )(changeMap);

    return R.mergeDeepLeft(answersFromChangesNew, matrixAnswersFromChangesNew);
  },
);

/**
 * Returns initial values for worksheet form.
 */
export const locationFormInitialValues = createSelector(
  [locationFormInitialValuesAnswers, locationFormInitialValuesAnswersChanges],
  (answers, answersChanges) => R.mergeDeepLeft(answers, answersChanges),
);
