import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const GuideStepWrapper = styled.div`
  padding: 0;
  display: flex;
  opacity: ${props => (props.dimmed ? 0.3 : 1)};
  transition: all 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
`;

const GuideStepGuide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  flex: 0 0;
  z-index: 10;
`;

const GuideStepNumber = styled.div`
  flex: 0 0;
  border-radius: 2em;
  height: 2em;
  width: 2em;
  line-height: 2em;
  text-align: center;
  background: #2f78b920;
  color: #4d6e95ee;
  font-weight: bold;
`;

const GuideStepLine = styled.div`
  flex: 1 1;
  background: #cdd1d5;
  width: 1px;
  margin: 4px 0;

  transition: all 300ms cubic-bezier(0.02, 0.01, 0.47, 1);
  transform: ${props => (props.showGuideLine ? 'scale(1, 1)' : 'scale(1, 0)')};
  transform-origin: top;
`;

const GuideStepContent = styled.div`
  flex: 1 1;
`;

const GuideStep = ({ number, children, dimmed = false, showGuideLine = false }) => (
  <GuideStepWrapper dimmed={dimmed}>
    <GuideStepGuide>
      <GuideStepNumber>{number}</GuideStepNumber>
      <GuideStepLine showGuideLine={showGuideLine} />
    </GuideStepGuide>
    <GuideStepContent>{children}</GuideStepContent>
  </GuideStepWrapper>
);

GuideStep.propTypes = {
  number: PropTypes.number,
  children: PropTypes.node,
  dimmed: PropTypes.bool,
  showGuideLine: PropTypes.bool,
};

export default GuideStep;
