import React from 'react';
import styled from 'styled-components';
import { darken, lighten, rgba } from 'polished';

import * as sowTypes from 'sow/types';
import Block from 'sow/components/atoms/Block';
import Glyphicon from 'sow/components/molecules/Glyphicon';

const IconWrapper = styled(Block)`
  display: inline-block;
  /* position: absolute; */
  /* top: 0; */
  /* right: 0; */
  padding: 4px 6px 0 6px;
  margin: 0;
  /* margin-left: 5px; */
  border: 2px solid;
  border-color: ${({ changeState, theme }) => {
    switch (changeState) {
      case 'open':
        return darken(0.05, theme.cr.open);
      case 'rejected':
      case 'not_applied':
        return darken(0.05, theme.cr.rejected);
      case 'accepted':
      case 'applied':
        return darken(0.05, theme.cr.accepted);
      default:
        return 'transparent';
    }
  }};
  color: ${({ changeState, theme }) => {
    switch (changeState) {
      case 'open':
        return lighten(0.5, theme.cr.open);
      case 'rejected':
      case 'not_applied':
        return lighten(0.4, theme.cr.rejected);
      case 'accepted':
      case 'applied':
        return lighten(0.7, theme.cr.accepted);
      default:
        return null;
    }
  }};
  background-color: ${({ changeState, theme }) => {
    switch (changeState) {
      case 'open':
        return rgba(theme.cr.open, 0.75);
      case 'rejected':
      case 'not_applied':
        return rgba(theme.cr.rejected, 0.75);
      case 'accepted':
      case 'applied':
        return rgba(theme.cr.accepted, 0.6);
      default:
        return null;
    }
  }};
`;

const ChangeStateIcon = ({ changeState }) => {
  let icon;
  switch (changeState) {
    case 'open':
      icon = <Glyphicon glyph="flag" />;
      break;
    case 'rejected':
    case 'not_applied':
      icon = <Glyphicon glyph="remove" />;
      break;
    case 'accepted':
    case 'applied':
      icon = <Glyphicon glyph="ok" />;
      break;
    default:
      return null;
  }
  return <IconWrapper changeState={changeState}>{icon}</IconWrapper>;
};
ChangeStateIcon.propTypes = { changeState: sowTypes.planAppChangeStateType };
ChangeStateIcon.defaultProps = { changeState: null };

export default ChangeStateIcon;
