import React from 'react';

import InspectionReportPageTemplateContainer from 'sow/containers/InspectionReportPageTemplateContainer';
import UnannouncedInspectionReportFiltersContainer from 'sow/containers/UnannouncedInspectionReportFiltersContainer';
import UnannouncedInspectionReportContainer from 'sow/containers/UnannouncedInspectionReportContainer';

const InspectionReportUnannouncedPage = () => (
  <InspectionReportPageTemplateContainer>
    <UnannouncedInspectionReportFiltersContainer />
    <UnannouncedInspectionReportContainer />
  </InspectionReportPageTemplateContainer>
);

export default InspectionReportUnannouncedPage;
