import * as R from 'ramda';
import * as Yup from 'yup';
import { validateYupSchema, yupToFormErrors } from 'formik';

import { geogUSCenter } from 'sow/constants/googleMapCoords';

export const defaultLocation = () => ({
  name: '',
  locationTypeId: '',
  location: {
    address: '',
    city: '',
    state: '',
    postal: '',
    country: 'United States',
  },
  geom: {
    ...geogUSCenter,
  },
});

const containsValues = comparator =>
  R.pipe(
    obj => Object.keys(obj).map(k => obj[k]),
    R.filter(comparator),
    matches => matches.length > 0,
  );

function fieldShape(values) {
  const fields = {
    name: Yup.string().required('Location name is required'),
    type: Yup.string(),
    locationTypeId: Yup.string().required('Location type is required'),
  };

  const addressHasValues = containsValues(val => val !== '')(values.location);
  const geomHasValues = containsValues(val => val !== '' && val != 0)(values.geom);

  if (!addressHasValues && !geomHasValues) {
    fields.location = Yup.object().shape({
      address: Yup.string()
        .required('An address or coordinate set is required')
        .nullable(),
    });
  } else if (addressHasValues) {
    fields.location = Yup.object().shape({
      address: Yup.string()
        .required('Address is required')
        .nullable(),
      city: Yup.string()
        .required('City is required')
        .nullable(),
      state: Yup.string()
        .required('State is required')
        .nullable(),
      postal: Yup.string()
        .required('Postal code is required')
        .nullable(),
      country: Yup.string()
        .required('Country is required')
        .nullable(),
    });
  } else if (geomHasValues) {
    fields.geom = Yup.object().shape({
      lat: Yup.string()
        .nullable()
        .required('Latitude is required')
        .notOneOf([0, '0'], 'Cannot be zero'),
      lng: Yup.string()
        .nullable()
        .required('Longitude is required')
        .notOneOf([0, '0'], 'Cannot be zero'),
    });
  }

  return Yup.object().shape(fields);
}

export function validateLocation(values) {
  const validationSchema = fieldShape(values);

  return validateYupSchema(values, validationSchema).catch(err => {
    throw yupToFormErrors(err);
  });
}

export function validateLocationChange(values) {
  const { location, locationChange } = values;

  const validationSchema = Yup.object().shape({
    // location: not validated during change
    locationChange: fieldShape(locationChange),
  });

  return validateYupSchema(values, validationSchema).catch(err => {
    throw yupToFormErrors(err);
  });
}
