import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import UserButton from 'sow/containers/UserButton';
import Atom from 'sow/components/atoms/Atom';
import Block from 'sow/components/atoms/Block';
import Column from 'sow/components/molecules/Column';
import Clearfix from 'sow/components/atoms/Clearfix';
import Link from 'sow/components/atoms/Link';
import Row from 'sow/components/molecules/Row';

const headerStyles = {
  marginLeft: '-30px',
  marginRight: '-15px',
  paddingLeft: '15px',
};

const HeaderBar = ({ title, breadcrumb, pageName }) => (
  <Row className="row header header-bar" style={headerStyles}>
    <Column xs={12}>
      <Row className="row">
        <Column xs={8}>
          <Block className="meta">
            <Block className="page">{title}</Block>
            <Block className="breadcrumb-links">
              {breadcrumb.map(([text, url], i) => (
                <Fragment key={i}>
                  {R.isNil(url) && <Atom textMuted>{text}</Atom>}
                  {!R.isNil(url) && <Link to={url}>{text}</Link>}
                  {breadcrumb.length > 0 && ' / '}
                </Fragment>
              ))}
              {pageName ? (
                <Atom textMuted>{pageName}</Atom>
              ) : (
                breadcrumb.length > 0 && <Atom textMuted>{title}</Atom>
              )}
            </Block>
          </Block>
        </Column>

        <Column xs={4}>
          <UserButton />
        </Column>
      </Row>
    </Column>

    <Clearfix />
  </Row>
);

HeaderBar.propTypes = {
  title: PropTypes.string,
  breadcrumb: PropTypes.arrayOf(PropTypes.array),
};

HeaderBar.defaultProps = {
  title: 'WFCF Organic',
  breadcrumb: [],
};

export default HeaderBar;
