import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { MenuItem, Glyphicon } from 'react-bootstrap';

import * as currentUser from 'sow/selectors/currentUser';
import FormGroup from 'sow/components/organisms/FormGroup';
import FormModal from 'sow/components/old/FormModal';
import { confirm } from 'sow/actions/pure/messaging';
import { hocEditable } from 'sow/components/old/hocHelpers/uiState';
import { actions as ospQualQuestionAction } from '../reducers/ospQualificationQuestions';

import * as select from '../selectors';
import FormPanel from '../FormPanel';
import Worksheets from './Worksheets';

const makeMapStateToProps = () => (state, props) => ({
  ospQualificationQuestion: select.makeOspQQSelector()(state, props),
  ospQualificationQuestionList: select.ospQualificationQuestions(state, props),
  canEditOspDef: currentUser.canEditOspDef(state, props),
});

const mapDispatchToProps = (
  dispatch,
  {
    // worksheetId,
    rootOspQualificationQuestionId,
    ospQualificationQuestionId,
  },
) => {
  return {
    onDelete: event => {
      dispatch(
        confirm({
          message: 'Are you sure you want to delete this root question?',
          onConfirm() {
            dispatch(
              ospQualQuestionAction.subOspQualificationQuestionRemove(
                rootOspQualificationQuestionId,
                ospQualificationQuestionId,
              ),
            );
          },
        }),
      );
    },
    onSetInputValue: R.curry((field, event) => {
      dispatch(
        ospQualQuestionAction.setValue(
          ospQualificationQuestionId,
          field,
          event.target.value,
        ),
      );
    }),
    onMoveQuestionDown: R.curry((subId, event) => {
      dispatch(
        ospQualQuestionAction.subQuestionMoveDown(rootOspQualificationQuestionId, subId),
      );
    }),
    onMoveQuestionUp: R.curry((subId, event) => {
      dispatch(
        ospQualQuestionAction.subQuestionMoveUp(rootOspQualificationQuestionId, subId),
      );
    }),
    onAddReference: R.curry((rootId, event) => {
      dispatch(ospQualQuestionAction.referenceAdd(ospQualificationQuestionId, rootId));
    }),
    onDeleteReference: R.curry((rootId, event) => {
      dispatch(ospQualQuestionAction.referenceRemove(ospQualificationQuestionId, rootId));
    }),
  };
};

export const SubQualificationQuestion = ({
  ospQualificationQuestion,
  ospQualificationQuestionId,
  rootQualificationQuestionIds,
  ospQualificationQuestionList,

  onDelete,
  onSetInputValue,
  ospSectionIds,

  // // question event handlers
  // onAddQuestion, onDeleteQuestion,
  onMoveQuestionDown,
  onMoveQuestionUp,

  // reference
  onAddReference,
  onDeleteReference,

  isEditing,
  onEditDone,
  onEditStart,

  canEditOspDef,
}) => {
  if (!ospQualificationQuestion) return null;
  const worksheetDropdown = (
    <FormPanel.Heading.Dropdown
      id={`SubQualificationQuestion-${ospQualificationQuestionId}`}
    >
      <MenuItem onClick={onEditStart}>
        <Glyphicon glyph="pencil" /> Edit Sub-Activities Question
      </MenuItem>
      <MenuItem onClick={onDelete} disabled={!canEditOspDef}>
        <Glyphicon glyph="remove" /> Delete Sub-Activities Question
      </MenuItem>
    </FormPanel.Heading.Dropdown>
  );

  return (
    <FormPanel.Body.ShortRow
      handlerId={ospQualificationQuestionId}
      onMoveDown={onMoveQuestionDown}
      onMoveUp={onMoveQuestionUp}
      customControl={worksheetDropdown}
      stackMoveButtons={true}
    >
      {ospQualificationQuestion.question}
      {isEditing &&
        <FormModal
          bsSize="large"
          show={isEditing}
          title="Edit Sub-Activities Question"
          onHide={onEditDone}
          onSubmit={onEditDone}
        >
          <FormPanel>
            <FormPanel.Heading>General</FormPanel.Heading>
            <FormPanel.Body>
              <FormPanel.Body.Row>
                <FormGroup fieldName="header" label="Sub-Activities Question Header Text">
                  <input
                    type="text"
                    id="question"
                    className="form-control"
                    onChange={onSetInputValue('question')}
                    value={ospQualificationQuestion.question}
                    required
                    disabled={!canEditOspDef}
                  />
                </FormGroup>
              </FormPanel.Body.Row>
            </FormPanel.Body>
          </FormPanel>

          <FormPanel>
            <FormPanel.Heading>Root Question Dependencies</FormPanel.Heading>
            <ul className="list-group">
              {rootQualificationQuestionIds &&
                rootQualificationQuestionIds.map(id => {
                  const rootQuestion = R.path([id], ospQualificationQuestionList);
                  if (!rootQuestion) return null; // block invalid ids from breaking things
                  const isChecked = R.contains(id, ospQualificationQuestion.references);
                  const handleChange = isChecked ? onDeleteReference : onAddReference;
                  return (
                    <li className="list-group-item" key={id}>
                      <label>
                        <input
                          type="checkbox"
                          value={id}
                          checked={isChecked}
                          onChange={handleChange(id)}
                          disabled={!canEditOspDef}
                        />{' '}
                        {rootQuestion.question}
                      </label>
                    </li>
                  );
                })}
            </ul>
          </FormPanel>

          <Worksheets
            ospQualificationQuestion={ospQualificationQuestion}
            ospSectionIds={ospSectionIds}
          />
        </FormModal>}
    </FormPanel.Body.ShortRow>
  );
};

export default R.compose(connect(makeMapStateToProps, mapDispatchToProps), hocEditable)(
  SubQualificationQuestion,
);
