import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Block from 'sow/components/atoms/Block';

/**
 * Widget panel component
 * @param {Object} props
 */
const Widget = ({ children, className, style, ...props }) =>
  <Block className={classnames('widget', className)} style={style} {...props}>
    {children}
  </Block>;

Widget.propTypes = {
  children: PropTypes.node,

  /** CSS classes on container element to be passed through */
  className: PropTypes.string,

  /** CSS styles on container element to be passed through */
  style: PropTypes.object,
};

export default Widget;
