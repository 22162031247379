import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { orgIdType, ospAppIdType } from 'sow/types';
import { worksheetRoute } from 'sow/routes';

import Link from 'sow/components/atoms/Link';
import Glyphicon from 'sow/components/molecules/Glyphicon';

const AttachmentWorksheetLink = ({ orgId, ospAppId, attachment }) => {
  const worksheetId = R.pathOr(null, ['file', 'worksheet_id'], attachment);

  // Don't render if ospAppId or worksheet_id are unset
  // (they might be in the case of ACA attachments)
  if (!ospAppId || !worksheetId) {
    return null;
  }

  return (
    <Link to={worksheetRoute(orgId, ospAppId, worksheetId)} target="_blank">
      Worksheet <Glyphicon glyph="new-window" />
    </Link>
  );
};

AttachmentWorksheetLink.defaultProps = {
  ospAppId: null,
};

AttachmentWorksheetLink.propTypes = {
  orgId: orgIdType.isRequired,
  ospAppId: ospAppIdType,
  attachment: PropTypes.shape({
    file: PropTypes.shape({
      worksheet_id: PropTypes.string,
    }).isRequired,
  }),
};

export default AttachmentWorksheetLink;
