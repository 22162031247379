import { call, put, takeEvery } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import * as schema from 'sow/schema';
import actions from 'sow/actions/pure';
import { apiGet } from 'sow/sagas/api';

export function* loadAca(acaId) {
  try {
    const { response, error } = yield call(apiGet, `/aca/${acaId}`);

    if (response) {
      const entity = yield call(normalize, response.data, schema.aca);
      yield put(actions.aca.loadOneDone(entity));
    }

    if (error) {
      yield put(actions.aca.loadOneFail(acaId, error));
    }
  } catch (error) {
    yield put(actions.aca.loadOneFail(acaId, error));
  }
}

export function* watchLoadAca({ payload }) {
  const { acaId } = payload;

  try {
    yield call(loadAca, acaId);
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  try {
    yield takeEvery(actions.aca.loadOne, watchLoadAca);
  } catch (error) {
    console.error(error);
  }
}
