import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import AllInspectionReportTable from 'sow/components/organisms/AllInspectionReportTable';

const mapStateToProps = state => ({
  searchInitiated: state.inspectionReport.searchInitiated,
  fetchingList: state.inspectionReport.isFetching,
  inspectionReport: state.inspectionReport.list,
});

const mapDispatchToProps = {};

class AllInspectionReportTableContainer extends Component {
  render() {
    if (this.props.searchInitiated) return <AllInspectionReportTable {...this.props} />;
    return null;
  }
}

AllInspectionReportTableContainer.propTypes = {
  searchInitiated: PropTypes.bool,
  fetchingList: PropTypes.bool,
  inspectionReport: PropTypes.array,
};

AllInspectionReportTableContainer.defaultProps = {
  searchInitiated: false,
  inspectionReport: [],
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AllInspectionReportTableContainer);
