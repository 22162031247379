import React from 'react';
import PropTypes from 'prop-types';
import {
  OverlayTrigger as BSOverlayTrigger,
  Popover as BSPopover,
  Tooltip as BSTooltip,
} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

const Tooltip = ({ trigger, placement, title, overlay, popover, children }) => {
  // Bootstrap requires a unique id attribute for the overlay trigger to know which element to attach to
  const instanceId = uuid();

  // If 'popover' is set, render a Popover component instead of a Tooltip
  const tooltipOverlay = popover ? (
    <BSPopover id={instanceId} title={title}>
      {overlay}
    </BSPopover>
  ) : (
    <BSTooltip id={instanceId}>{overlay}</BSTooltip>
  );

  return (
    <BSOverlayTrigger trigger={trigger} placement={placement} overlay={tooltipOverlay}>
      {children}
    </BSOverlayTrigger>
  );
};

Tooltip.propTypes = {
  trigger: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  placement: PropTypes.string,
  title: PropTypes.node,
  overlay: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  popover: PropTypes.bool,
};

Tooltip.defaultProps = {
  trigger: ['focus', 'hover'],
  placement: 'top',
  popover: false,
};

export default Tooltip;
