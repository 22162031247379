import aca from './aca';
import api from './api';
import auth from './auth';
import core from './core';
import gis from './gis';
import inspector from './inspector';
import layout from './layout';
import org from './organization';
import orgUser from './orgUser';
import ospApp from './ospApplication';
import products from './products';
import profile from './profile';
import shell from './shell';
import userManagement from './userManagement';
import inspection from './inspection';
import sample from './sample';

const actionCreators = {
  aca,
  api,
  auth,
  core,
  gis,
  inspector,
  layout,
  org,
  orgUser,
  ospApp,
  products,
  profile,
  shell,
  userManagement,
  inspection,
  sample,
};

export default actionCreators;
