import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import UserManagementUserLookup from 'sow/containers/UserManagementUserLookup';

const UserManagementUserLookupPage = () => (
  <AdminPageTemplate title="Organization User Lookup">
    {() => <UserManagementUserLookup />}
  </AdminPageTemplate>
);

export default UserManagementUserLookupPage;
