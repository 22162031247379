import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import AnnouncementTypeEditContainer from 'sow/containers/AnnouncementTypeEditContainer';

const AnnouncementTypeEditPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <AnnouncementTypeEditContainer />}
  </AdminPageTemplate>
);

export default AnnouncementTypeEditPage;
