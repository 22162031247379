const mark = action => `ANNOUNCEMENT/${action}`;

export const LOAD_ANNOUNCEMENTS = mark('LOAD_ANNOUNCEMENTS');
export const LOAD_ANNOUNCEMENTS_DONE = mark('LOAD_ANNOUNCEMENTS_DONE');
export const LOAD_ANNOUNCEMENT_BY_ID = mark('LOAD_ANNOUNCEMENT_BY_ID');
export const ADD_ANNOUNCEMENT = mark('ADD_ANNOUNCEMENT');
export const DELETE_ANNOUNCEMENT = mark('DELETE_ANNOUNCEMENT');
export const UPDATE_ANNOUNCEMENT = mark('UPDATE_ANNOUNCEMENT');
export const LOAD_SHOW_FOR_OPTIONS = mark('LOAD_SHOW_FOR_OPTIONS');
export const GET_ANNOUNCEMENT_HISTORY = mark('GET_ANNOUNCEMENT_HISTORY');

export const loadAnnouncements = (payload, onSuccess) => ({
  type: LOAD_ANNOUNCEMENTS,
  payload,
  meta: { onSuccess },
});

export const loadAnnouncementsDone = payload => ({
  type: LOAD_ANNOUNCEMENTS_DONE,
  payload,
});

export const loadAnnouncementById = (id, onSuccess) => ({
  type: LOAD_ANNOUNCEMENT_BY_ID,
  id,
  meta: { onSuccess },
});

export const addAnnouncement = (payload, onSuccess) => ({
  type: ADD_ANNOUNCEMENT,
  payload,
  meta: { onSuccess },
});

export const updateAnnouncement = (id, payload, onSuccess) => ({
  type: UPDATE_ANNOUNCEMENT,
  id,
  payload,
  meta: { onSuccess },
});

export const deleteAnnouncement = (id, onSuccess) => ({
  type: DELETE_ANNOUNCEMENT,
  id,
  meta: { onSuccess },
});

export const loadShowForOptions = onSuccess => ({
  type: LOAD_SHOW_FOR_OPTIONS,
  meta: { onSuccess },
});

export const getAnnouncementHistory = (id, onSuccess) => ({
  type: GET_ANNOUNCEMENT_HISTORY,
  id,
  meta: { onSuccess },
});
