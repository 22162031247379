import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as adminRequestActions from 'sow/actions/adminRequest';
import * as sowTypes from 'sow/types';
import { fromPlanApp, fromRouter } from 'sow/store/selectors';
import { PlanAppLoader, PlanAppChangeLoader } from 'sow/store/containers';
import Spinner from 'sow/components/atoms/Spinner';
import OrgPlanAppPageTemplate from 'sow/containers/OrgPlanAppPageTemplate';
import OrgPlanAppChecklistPage from 'sow/components/pages/OrgPlanAppChecklistPage';

const mapStateToProps = (state, props) => ({
  orgId: fromRouter.paramOrgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  qualificationQuestions: fromPlanApp.qualificationQuestions(state, props),
  qualificationAnswers: fromPlanApp.qualificationAnswers(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  isStateUnderReview: fromPlanApp.isStateUnderReview(state, props),
  activityRequests: state.adminRequests.activityRequests,
  requestsFetching: state.adminRequests.activityRequests.isFetching,
  requestsLoaded: state.adminRequests.activityRequests.isLoaded,
});

const mapDispatchToProps = {
  fetchActivityRequests: adminRequestActions.fetchActivityRequests,
};

const OrgPlanAppChecklistPageContainer = ({
  orgId,
  planAppId,
  qualificationQuestions,
  qualificationAnswers,
  changeRequestId,
  fetchActivityRequests,
  isStateUnderReview,
  activityRequests,
  requestsFetching,
  requestsLoaded,
}) => {

  const loadActivityRequests = () => {
    if ((!requestsLoaded && !requestsFetching) || (requestsLoaded && activityRequests.planAppId != planAppId)) {
      return true;
    } else {
      return false;
    }
  }

  const loadRequests = loadActivityRequests();

  return (
    <OrgPlanAppPageTemplate title="Activities Checklist">
      <PlanAppLoader autoLoad>
        {({ status }) => {
          if (status.loading) return <Spinner />;
          if (!status.success) return null;
          return (
            <PlanAppChangeLoader autoLoad={!!changeRequestId}>
              {({ status }) => {
                if (status.loading) return <Spinner />;
                if (!status.done && changeRequestId) return null;
                if (status.done && loadRequests && !requestsLoaded) {
                  fetchActivityRequests(orgId, planAppId);
                }
                return (
                  <OrgPlanAppChecklistPage
                    questions={qualificationQuestions}
                    answers={qualificationAnswers}
                    isStateUnderReview={isStateUnderReview}
                  />
                );
              }}
            </PlanAppChangeLoader>
          );
        }}
      </PlanAppLoader>
    </OrgPlanAppPageTemplate>
  );
};

OrgPlanAppChecklistPageContainer.propTypes = {
  qualificationAnswers: PropTypes.arrayOf(sowTypes.planAppQualificationAnswerType),
  qualificationQuestions: PropTypes.arrayOf(sowTypes.planAppQualificationQuestionType),
  changeRequestId: sowTypes.planAppChangeRequestIdType,
  isStateUnderReview: PropTypes.bool,
};

OrgPlanAppChecklistPageContainer.defaultProps = {
  org: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrgPlanAppChecklistPageContainer);
