import React from 'react';
import PropTypes from 'prop-types';

import { planAppWorksheetQuestionType } from 'sow/types';

import Heading from 'sow/components/atoms/Heading';
import FormLabel from 'sow/components/atoms/FormLabel';
import { InfoTooltipWrapper } from 'sow/components/organisms/InfoTooltip';

const WorksheetQuestionName = ({ question, disabled }) => (
  <FormLabel htmlFor={question.uuid}>
    <Heading level={4} textMuted={disabled} style={{ marginTop: '5px' }}>
      <InfoTooltipWrapper overlay={question.tooltip}>{question.name}</InfoTooltipWrapper>
    </Heading>
  </FormLabel>
);

WorksheetQuestionName.defaultProps = {
  disabled: false,
};

WorksheetQuestionName.propTypes = {
  question: planAppWorksheetQuestionType.isRequired,
  disabled: PropTypes.bool,
};

export default WorksheetQuestionName;
