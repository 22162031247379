import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import AnnouncementsListContainer from 'sow/containers/AnnouncementsListContainer';

const AnnouncementsListPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <AnnouncementsListContainer />}
  </AdminPageTemplate>
);

export default AnnouncementsListPage;
