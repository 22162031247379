import React, { Fragment } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { orgIdType } from 'sow/types';
import { ResourceDetailLoader } from 'sow/store/helpers';
import { fromRouter } from 'sow/store/selectors';

import OrgPlanAppCreate from 'sow/components/organisms/OrgPlanAppCreate';
import Spinner from 'sow/components/atoms/Spinner';

const mapStateToProps = (state, props) => ({
  orgId: fromRouter.paramOrgId(state, props),
});

const OrgPlanAppCreateContainer = ({ orgId }) => (
  <ResourceDetailLoader resource={`org/${orgId}/application`} entityType="planAppResp">
    {({ status, createResource }) => (
      <Fragment>
        {status.initial && <OrgPlanAppCreate onCreate={() => createResource()} />}
        {status.loading && <Spinner />}
      </Fragment>
    )}
  </ResourceDetailLoader>
);

OrgPlanAppCreateContainer.propTypes = {
  orgId: orgIdType.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(OrgPlanAppCreateContainer);
