import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SpecialistAssignBlock from 'sow/components/organisms/SpecialistAssignBlock';
import { removeSpecialist, addSpecialist } from 'sow/actions/orgWorkflow';

const mapStateToProps = state => ({
  certificationSpecialistList: state.acaAdminList.items,
  specialistsByOrg: state.dashboard.specialistsByOrg,
});

const mapDispatchToProps = {
  removeSpecialist: removeSpecialist,
  addSpecialistToOrg: addSpecialist,
};

class SpecialistAssignBlockContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      csForm: {},
    };
  }

  dropDownHandler(target, field) {
    const { csForm } = this.state;
    this.setState({ csForm: { ...csForm, [field]: target ? target.value : null } });
    if (target && field === 'specialistDropDown') {
      this.setState({ specialistName: target.label });
    }
  }

  onAssign(orgId, userId, userTypeId, userSubtypeId) {
    const { specialistsByOrg } = this.props;

    const newUser = {
      id: userId,
      name: this.state.specialistName,
      organization_id: orgId,
      type: userSubtypeId,
    };
    const users = specialistsByOrg[orgId];
    if (users) {
      if (
        users.findIndex(user => user.id == userId && user.type == userSubtypeId) == -1
      ) {
        specialistsByOrg[orgId] = [...users, newUser];
      }
    } else {
      specialistsByOrg[orgId] = [newUser];
    }
    this.props.addSpecialistToOrg({
      orgId,
      userId,
      userTypeId,
      userSubtypeId,
      specialistsByOrg,
    });
    this.setState({ csForm: {} });
  }

  render() {
    return (
      <SpecialistAssignBlock
        dropDownHandler={this.dropDownHandler.bind(this)}
        onAssign={this.onAssign.bind(this)}
        certificationSpecialistList={this.props.certificationSpecialistList}
        dashboardList={this.props.dashboardList}
        csForm={this.state.csForm}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SpecialistAssignBlockContainer);
