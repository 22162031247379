/**
 * Return location descriptor object for use with react-router v3 Link
 * component to prop.
 * See:
 *  - https://github.com/ReactTraining/react-router/blob/v3/docs/API.md#link
 *  - https://github.com/ReactTraining/react-router/blob/v3/docs/Glossary.md#locationdescriptor
 * @arg {String} route
 * @return {Object}
 */
export function routeLocationDescriptor(route) {
  const [pathname, hash] = route.split('#');
  return { pathname, hash: `#${hash}` };
}

/**
 * Append scroll target hash to a route string.
 * @arg {String} route
 * @arg {String} hash
 * @return {String}
 */
export function scrollRoute(route, hash) {
  return `${route}#${hash}`;
}

/**
 * This function is provided to standardize the format of scroll target id's
 * across the app. The idea is to create standard prefixes for the different
 * places in the app scroll targets are added to.
 *
 * @arg {String} prefix
 * @arg {Number|String} uniqueId
 * @return {String}
 */
export function scrollTargetId(prefix, uniqueId) {
  return `${prefix}--${uniqueId}`;
}
