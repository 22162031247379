import {
  FETCH_ACA_ADMIN_LIST,
  FETCH_ACA_ADMIN_LIST_SUCCESS,
  FETCH_ACA_ADMIN_LIST_FAILED,
  REVOKE_ACA_ADMIN,
  REVOKE_ACA_ADMIN_SUCCESS,
  REVOKE_ACA_ADMIN_FAILED,
  CREATE_ACA,
  CREATE_ACA_SUCCESS,
  CREATE_ACA_FAILED,
} from 'sow/actions/acaAdmin';
import * as acaAdminActions from 'sow/actions/pure/acaAdmin';

const initialState = {
  items: [],
  isFetching: false,
  revokeErrors: null,
  isCreating: false,
  createErrors: null,
};

export default function acaAdminList(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACA_ADMIN_LIST:
      return { ...initialState, isFetching: true };

    case FETCH_ACA_ADMIN_LIST_SUCCESS:
      return { ...state, isFetching: false, items: [...action.data] };

    case FETCH_ACA_ADMIN_LIST_FAILED:
      return { ...state, isFetching: false };

    case acaAdminActions.ADD_STAFF_DONE:
      return { ...state, items: [...action.payload.user] };

    case REVOKE_ACA_ADMIN:
      return { ...state, revokeErrors: null };

    case REVOKE_ACA_ADMIN_SUCCESS:
      return { ...state, items: [...action.data] };

    case REVOKE_ACA_ADMIN_FAILED:
      return { ...state, revokeErrors: action.data.errors };

    case CREATE_ACA:
      return { ...state, isCreating: true, createErrors: null };

    case CREATE_ACA_SUCCESS:
      return { ...state, isCreating: false };

    case CREATE_ACA_FAILED:
      return { ...state, isCreating: false, createErrors: action.data.errors };

    default:
      return state;
  }
}
