import ReduxThunk from 'redux-thunk';
import { middleware as ReduxSagaThunk } from 'redux-saga-thunk';
import { routerMiddleware } from 'connected-react-router';

import browserHistory from 'sow/browserHistory';
import { middleware as entities } from 'sow/store/modules/entities';

export default [
  // redux-modules
  entities,

  // following middleware must be in this order
  ReduxSagaThunk,
  ReduxThunk,
  routerMiddleware(browserHistory),
];
