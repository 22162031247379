import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';
import styled from 'styled-components';

const LinkWrapper = styled.a`
  cursor: pointer;
`;

const handleClickDisabler = e => e.preventDefault();

/** Renders a react-router Link or anchor tag based on context */
const Link = ({
  className,
  btn,
  primary,
  info,
  success,
  warning,
  danger,
  xs,
  sm,
  lg,
  block,
  mailTo,
  href,
  disabled,
  to,
  ...props
}) => {
  let classes;
  if (btn) {
    classes = classnames(className, 'btn', {
      disabled: disabled,
      'btn-default': !className && !primary && !info && !success && !warning && !danger,
      'btn-primary': primary,
      'btn-info': info,
      'btn-success': success,
      'btn-warning': warning,
      'btn-danger': danger,
      'btn-xs': xs,
      'btn-sm': sm,
      'btn-lg': lg,
      'btn-block': btn && block,
    });
  } else {
    classes = classnames(className, {
      'text-muted': disabled,
    });
  }

  if (disabled) {
    return (
      <LinkWrapper
        {...props}
        onClick={handleClickDisabler}
        className={classes}
        style={{ cursor: 'not-allowed' }}
      />
    );
  }

  if (mailTo) {
    return (
      <LinkWrapper className={classes} href={`mailto:${mailTo}`}>
        {props.children}
      </LinkWrapper>
    );
  }

  if (!to && !href) {
    return <LinkWrapper {...props} />;
  }

  if (!to && href) {
	return <LinkWrapper href={href} {...props} className={classes} />;
  }

  return <RouterLink {...props} to={to} className={classes} />;
};

Link.propTypes = {
  className: PropTypes.string,
  btn: PropTypes.bool,
  primary: PropTypes.bool,
  success: PropTypes.bool,
  info: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  xs: PropTypes.bool,
  sm: PropTypes.bool,
  lg: PropTypes.bool,
  block: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  mailTo: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  href: PropTypes.string,
};

Link.defaultProps = {
  to: null,
  href: null,
};

export default Link;
