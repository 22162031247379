import React from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';

import Button from 'sow/components/atoms/Button';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormikField from 'sow/components/organisms/FormikField';
import PicklistModalItemListContainer from 'sow/containers/PicklistModalItemListContainer';

const PicklistModal = ({ closeModal, handleSave, show, isNew, initialValues }) => (
  <Modal backdrop="static" show={show} onHide={closeModal}>
    <Modal.Header closeButton>{isNew ? 'Add' : 'Edit'} Picklist</Modal.Header>
    <Modal.Body>
      <Formik initialValues={initialValues} onSubmit={handleSave} enableReinitialize>
        {({ values, handleSubmit, setFieldValue }) => (
          <Form>
            <Row className="form-row picklist-form">
              <Column className="form-column form-group" xs={12}>
                <Button className="no-margin-button" onClick={handleSubmit} primary>
                  Save
                </Button>
              </Column>
              <Column className="form-column" xs={12}>
                <Field
                  className="input-filter"
                  label="Name"
                  placeholder=""
                  name={'name'}
                  component={FormikField}
                  required
                />
              </Column>
            </Row>
            <PicklistModalItemListContainer
              key={values.picklist_item_list}
              itemList={values.picklist_item_list}
              setFieldValue={setFieldValue}
            />
          </Form>
        )}
      </Formik>
    </Modal.Body>
  </Modal>
);

export default PicklistModal;
