import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as currentAca from 'sow/selectors/currentAca';

import InspectionReportPageTemplate from 'sow/components/templates/InspectionReportPageTemplate';

const mapStateToProps = (state, props) => ({ acaId: currentAca.acaId(state, props) });

class InspectionReportPageTemplateContainer extends Component {
  render() {
    return <InspectionReportPageTemplate {...this.props} />;
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(InspectionReportPageTemplateContainer);
