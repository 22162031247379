import * as R from 'ramda';
import { combineReducers } from 'redux';
import byId, * as fromById from './byId';

export default combineReducers({
  byId,
});

export const byIdState = R.path(['byId']);

export const getWorksheetState = state => {
  // console.log('getWorksheetState', state);
  return R.path(['worksheets'], state);
};

export const getWorksheet = (state, id) => {
  return fromById.getWorksheet(byIdState(state), id);
};

// export const getWorksheetId = state =>
//   R.path(['uuid'], getWorksheet(state, id));

// export const getWorksheetName = state =>
//   R.path(['name'], getWorksheet(state, id));

export const getName = worksheet => R.path(['name'], worksheet);

export const getId = worksheet => R.path(['uuid'], worksheet);

export const getWorksheetsByIds = R.curry((state, ids) => {
  const worksheetById = byIdState(state);
  if (!worksheetById) return [];
  // console.log({state,ids,worksheetById})
  return R.filter(R.compose(R.contains(R.__, ids), R.prop('uuid')), worksheetById);
});
