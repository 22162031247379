import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';

import { inspectionYearRoute } from 'sow/routes';
import * as inspectionYearActions from 'sow/actions/pure/inspectionYear';

import InspectionYearEdit from 'sow/components/organisms/InspectionYearEdit';

const mapDispatchToProps = {
  loadInspectionYearById: inspectionYearActions.loadInspectionYearById,
  addInspectionYear: inspectionYearActions.addInspectionYear,
  updateInspectionYear: inspectionYearActions.updateInspectionYear,
  deleteInspectionYear: inspectionYearActions.deleteInspectionYear,
  redirect: routerPush,
};

const initialValues = {
  year: '',
  start_on: '',
  certified_operations_count: '',
  required_quantity: '',
};

class InspectionYearEditContainer extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = { isNew: false, isLoading: true, initialValues: R.clone(initialValues) };
  }

  componentDidMount() {
    this._isMounted = true;
    const routeId = R.path(['match', 'params', 'id'], this.props);

    if (routeId !== 'new') {
      this.props.loadInspectionYearById(routeId, this.updateState.bind(this));
    } else {
      this.setState({ isNew: true, isLoading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState({ data }) {
    if (this.state.isNew) {
      history.pushState(null, null, `${data.id}`);
    }
    if (this._isMounted) {
      if (!R.isEmpty(data)) {
        this.setState({
          isNew: false,
          isLoading: false,
          inspectionYearId: data.id,
          initialValues: { ...data },
        });
      } else {
        this.redirectToList();
      }
    }
  }

  redirectToList() {
    this.props.redirect(inspectionYearRoute());
  }

  handleSave(values, formikBag) {
    if (this.state.isNew) {
      this.props.addInspectionYear(values, this.updateState.bind(this));
    } else {
      this.props.updateInspectionYear(
        this.state.inspectionYearId,
        values,
        this.updateState.bind(this),
      );
    }
  }

  handleDelete({ id }) {
    this.props.deleteInspectionYear(id, this.redirectToList.bind(this));
  }

  render() {
    return (
      <InspectionYearEdit
        handleSave={this.handleSave.bind(this)}
        handleDelete={this.handleDelete.bind(this)}
        {...this.state}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(InspectionYearEditContainer);
