import React from 'react';
import StyledFrame from 'react-styled-frame';

import * as sowTypes from 'sow/types';
import PlanAppExportContent from 'sow/containers/planAppExport/Content';
import { wrapHtmlContent } from 'sow/components/organisms/PlanAppExportContent/renderHtml';

/**
 * NOTE this is a HACK to get the iframe to auto resize height to fit it's content
 **/
const iframeReadyAutoHeightHack = () => {
  const iframe = document.getElementById('iframeAutoHeightHackTarget');
  const iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow;
  if (iframeWin.document.body) {
    iframe.height =
      (iframeWin.document.documentElement.scrollHeight ||
        iframeWin.document.body.scrollHeight) + 10;
  }
};

const PlanAppExportPreviewFrame = ({ exportConfig, userList }) => {
  // no export config yet
  if (!exportConfig) return null;

  const initialContent = wrapHtmlContent();
  return (
    <StyledFrame
      id="iframeAutoHeightHackTarget"
      contentDidMount={iframeReadyAutoHeightHack}
      style={{ width: '8.27in', margin: '0 auto', backgroundColor: 'white' }}
      initialContent={initialContent}
    >
      <PlanAppExportContent
        {...exportConfig}
        exportConfig={exportConfig}
        userList={userList}
      />
    </StyledFrame>
  );
};

PlanAppExportPreviewFrame.propTypes = {
  exportConfig: sowTypes.planAppExportConfigType.isRequired,
  userList: sowTypes.userLimitedListType.isRequired,
};

export default PlanAppExportPreviewFrame;
