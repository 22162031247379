import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { planAppIdType, orgIdType, worksheetIdType } from 'sow/types';

import Paragraph from 'sow/components/atoms/Paragraph';

import TextField from './TextField';
import CheckboxList from './CheckboxList';
import SelectBox from './SelectBox';
import Checkbox from './Checkbox';
import BooleanSelector from './BooleanSelector';
import TextareaField from './TextareaField';
import DatePicker from './DatePicker';
import DescriptiveText from './DescriptiveText';
import ProductIngredient from './ProductIngredient';

// Non-atomic components:
import FileListQuestionField from 'sow/components/old/OspApp/Editor/Question/Field/FileList';

const WorksheetField = ({
  orgId,
  planAppId,
  worksheetId,
  question,
  answer,
  handleChange,
  disabled,
}) => {
  const fieldProps = {
    orgId,
    planAppId,
    worksheetId,
    question,
    answer,
    handleChange,
    disabled,
  };

  const fieldType = R.pipe(R.propOr('text', 'fieldType'), fieldType =>
    fieldType.toLowerCase(),
  )(question);

  switch (fieldType) {
    case 'text':
    case 'number':
      return <TextField {...fieldProps} />;

    case 'checkbox_list':
      return <CheckboxList {...fieldProps} />;

    case 'select':
      return <SelectBox {...fieldProps} />;

    case 'checkbox':
      return <Checkbox {...fieldProps} />;

    case 'bool':
      return <BooleanSelector {...fieldProps} />;

    case 'files':
      // TODO: Replace with atomic component
      return <FileListQuestionField {...fieldProps} />;

    case 'textarea':
      return <TextareaField {...fieldProps} />;

    case 'date':
      return <DatePicker {...fieldProps} />;

    case 'descriptive_text':
      return <DescriptiveText {...fieldProps} />;

    case 'product_ingredient':
      // TODO: Refactor to something more atomic?
      return <ProductIngredient {...fieldProps} />;

    default:
      return <Paragraph textDanger>Question type not implemented</Paragraph>;
  }
};

WorksheetField.defaultProps = {
  disabled: false,
};

WorksheetField.propTypes = {
  orgId: orgIdType.isRequired,
  planAppId: planAppIdType.isRequired,
  worksheetId: worksheetIdType.isRequired,
  question: PropTypes.shape({}).isRequired,
  answer: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default WorksheetField;
