import { apiDelete, apiGet, apiPost, apiPut } from 'sow/utils/apiNew';

import { orgUrl } from './index';

export const baseUrl = orgId => `${orgUrl(orgId)}/ingredient`;
export const ingredientUrl = (orgId, ingredientId) => `${baseUrl(orgId)}/${ingredientId}`;
export const typeaheadUrl = orgId => `${baseUrl(orgId)}/typeahead`;

export const createOne = (orgId, ingredient) => {
  const url = baseUrl(orgId);
  return apiPost(url, { ingredient });
};

export const deleteOne = (orgId, ingredientId) => {
  const url = ingredientUrl(orgId, ingredientId);
  return apiDelete(url);
};

export const loadAll = orgId => {
  const url = baseUrl(orgId);
  return apiGet(url);
};

export const loadOne = (orgId, ingredientId) => {
  const url = ingredientUrl(orgId, ingredientId);
  return apiGet(url);
};

export const loadTypeahead = (orgId, field, value) => {
  const url = typeaheadUrl(orgId);
  return apiGet(url, { field, value });
};

export const updateOne = (orgId, ingredient) => {
  const url = ingredientUrl(orgId, ingredient.id);
  return apiPut(url, { ingredient });
};
