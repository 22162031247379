import React from 'react';
import PropTypes from 'prop-types';
import { Glyphicon } from 'react-bootstrap';

import Atom from 'sow/components/atoms/Atom';
import Tooltip from 'sow/components/molecules/Tooltip';

const InfoTooltip = ({ trigger, placement, title, overlay, children }) => (
  <Tooltip trigger={trigger} placement={placement} title={title} overlay={overlay}>
    <Atom>
      {children}
      {children ? ' ' : ''}
      <Glyphicon glyph="info-sign" />
    </Atom>
  </Tooltip>
);

InfoTooltip.propTypes = {
  trigger: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  placement: PropTypes.string,
  title: PropTypes.node,
  overlay: PropTypes.node.isRequired,
  children: PropTypes.node,
};

export const InfoTooltipWrapper = ({ overlay, children, ...props }) => {
  if (!overlay) {
    return <Atom>{children}</Atom>;
  }

  return (
    <InfoTooltip {...props} overlay={overlay}>
      {children}
    </InfoTooltip>
  );
};
InfoTooltipWrapper.propTypes = {
  overlay: PropTypes.node,
  children: PropTypes.node,
};

export default InfoTooltip;
