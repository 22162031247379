import { all, call, select, takeEvery } from 'redux-saga/effects';
import * as R from 'ramda';

import actions from 'sow/actions/pure';
import { isDeployAlpha, isDeployBeta, isDeployProd } from 'sow/config';

export function* watchLoginDone({ payload }) {
  const userId = R.path(['user_id'], payload);
  const roles = R.path(['roles'], payload);
  const acaIds = R.path(['metadata', 'acas'], payload);
  const orgIds = R.path(['metadata', 'orgs'], payload);
  const orgAcaIds = R.pathOr([], ['metadata', 'orgs_v2'], payload);
  const email = R.path(['metadata', 'username'], payload);
  const name = R.path(['metadata', 'name'], payload);

  const traits = {
    userId,
    acaIds,
    orgIds,
    orgAcaIds,
    roles,
    email,
    name,
  };

  yield all([call(identifyBugsnag, traits)]);
}

export function* watchLogout({ payload }) {}

export function* identifyBugsnag({ userId, name, email }) {
  const bugsnagClient = window.bugsnagClient;

  if (!bugsnagClient) return;

  bugsnagClient.user = { id: userId, name, email };
}

export default function* rootSaga() {
  yield takeEvery(actions.auth.ecertLoginDone, watchLoginDone);
  yield takeEvery(actions.auth.loginDone, watchLoginDone);
  yield takeEvery(actions.auth.logoutDone, watchLogout);
  yield takeEvery(actions.auth.validateTokenDone, watchLoginDone);
}
