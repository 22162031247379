import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import './DragDropList.module.css';
import Block from 'sow/components/atoms/Block';
import ListItem from './ListItem';

const DragDropList = ({
  list,
  reorderList,
  updateSortOrder,
  listItemComponents,
  editRoute,
}) => (
  <Block className="drag-drop-list-block">
    {list.length > 0 ? (
      <DndProvider backend={HTML5Backend}>
        {list.map((item, index) => (
          <ListItem
            key={item.id}
            id={item.id}
            index={index}
            listItem={item}
            reorderList={reorderList}
            updateSortOrder={updateSortOrder}
            listItemComponents={listItemComponents}
            editRoute={editRoute}
          />
        ))}
      </DndProvider>
    ) : (
      <Block className="empty-list">No items found.</Block>
    )}
  </Block>
);

export default DragDropList;
