import * as R from 'ramda';
import { createSelector } from 'reselect';
import { worksheetIdProp } from 'sow/selectors/core/worksheet';
import {
  currentWorksheetId,
  changeList,
  worksheetChangeList,
  worksheetEntity,
} from './core';
import { locationId, locationWorksheetChangeList, locationChangeList } from './location';

// --
// -- Predicate Functions
// --

const isStateOpen = R.propEq('state', 'open');
const isStateAccepted = R.propEq('state', 'accepted');
const isStateRejected = R.propEq('state', 'rejected');

// --
// -- CR Overview Selectors  - Based on fromPlanApp.changeList
// --

//
// FILTERED CHANGE LISTS
//

// -- filtered on: change.state
export const changeOpenList = createSelector(
  [changeList],
  R.filter(isStateOpen),
);
export const changeAcceptedList = createSelector(
  [changeList],
  R.filter(isStateAccepted),
);
export const changeRejectedList = createSelector(
  [changeList],
  R.filter(isStateRejected),
);

//
// FILTER LIST COUNTS
//

// -- filtered on: change.state
export const changeOpenCount = createSelector(
  [changeOpenList],
  R.length,
);
export const changeAcceptedCount = createSelector(
  [changeAcceptedList],
  R.length,
);
export const changeRejectedCount = createSelector(
  [changeRejectedList],
  R.length,
);

// CR Manager props -- used for determining which sets of changes to show
export const crManagerShowPlanChecklist = (state, props) =>
  R.propOr(false, 'showPlanChecklist', props);
export const crManagerShowPlanWorksheets = (state, props) =>
  R.propOr(false, 'showPlanWorksheets', props);
export const crManagerShowLocations = (state, props) =>
  R.propOr(false, 'showLocations', props);
export const crManagerShowLocationWorksheets = (state, props) =>
  R.propOr(false, 'showLocationWorksheets', props);

/**
 * Get list of changes to be displayed in the CR manager overview
 */
export const crOverviewChangeList = createSelector(
  [
    crManagerShowPlanChecklist,
    crManagerShowPlanWorksheets,
    crManagerShowLocations,
    crManagerShowLocationWorksheets,
    changeList,
    currentWorksheetId,
    locationId,
  ],
  (
    showPlanChecklist,
    showPlanWorksheets,
    showLocations,
    showLocationWorksheets,
    changeList,
    worksheetId,
    locationId,
  ) => {
    const baseFilter = R.filter(
      R.where({
        ignored: R.either(R.isNil, R.equals(false)),
      }),
    );

    const contexts = [];

    if (showPlanChecklist) {
      contexts.push(
        R.whereEq({
          context: 'main',
          type: 'plan',
        }),
      );
    }

    if (showPlanWorksheets) {
      contexts.push(
        R.where({
          context: R.equals('main'),
          type: R.contains(R.__, [
            'worksheet',
            'worksheet_answer',
            'matrix_row',
            'matrix_row_answer',
          ]),
        }),
      );
    }

    if (showLocations) {
      const where = {
        context: R.equals('land'),
        type: R.contains(R.__, [
          'self',
          'plan',
          'worksheet',
          'worksheet_answer',
          'matrix_row',
          'matrix_row_answer',
        ]),
      };

      if (!R.isNil(locationId)) {
        where.landId = R.equals(locationId);
      }

      contexts.push(R.where(where));
    }

    const contextTypeFilter = R.filter(R.anyPass(contexts));

    const worksheetFilter = worksheetId
      ? R.filter(R.whereEq({ worksheetId }))
      : R.identity;

    const locationFilter = locationId
      ? R.filter(R.whereEq({ landId: locationId }))
      : R.identity;

    return R.pipe(
      baseFilter,
      contextTypeFilter,
      worksheetFilter,
      locationFilter,
    )(changeList);
  },
);

/**
 * Get a list of worksheets with an added 'changes' array for changes under that worksheet.
 * Filtered by worksheets that have changes.
 */
export const worksheetsWithChanges = createSelector(
  [worksheetEntity, changeList, worksheetChangeList, worksheetIdProp],
  (worksheets, changes, worksheetChanges, worksheetId) => {
    const changesWorksheetIds = R.map(
      R.prop('worksheetId'),
      worksheetId ? worksheetChanges : changes,
    );

    return R.pipe(
      R.values,
      R.filter(ws => R.contains(ws.uuid, changesWorksheetIds)),
    )(worksheets);
  },
);

/**
 * Get a list of locations with added 'changes' array for changes under that worksheet.
 * Filtered by locations that have changes.
 */
export const locationsWithChanges = createSelector(
  [locationChangeList, locationWorksheetChangeList],
  (locationChanges, worksheetChanges) => {
    const locationChangeIds = R.map(R.prop('landId'))(locationChanges);
    const worksheetChangeIds = R.map(R.prop('landId'))(worksheetChanges);

    return R.pipe(
      R.union(worksheetChangeIds),
      R.uniq,
    )(locationChangeIds);
  },
);
