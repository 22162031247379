import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import Button from 'sow/components/atoms/Button';

/** Confirmation modal that can be toggled from components */
class Confirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.defaultShow,
    };
  }

  setVisibility = show => () => this.setState({ show });

  handleConfirm = () => {
    const { onConfirm } = this.props;
    if (onConfirm) onConfirm();
    this.setVisibility(false)();
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    if (onCancel) onCancel();
    this.setVisibility(false)();
  };

  render() {
    const { show } = this.state;
    const { title, message, children, confirmButtonText, cancelButtonText } = this.props;

    return (
      <Fragment>
        {children(this.setVisibility(true))}

        <Modal show={show} onHide={this.handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>{message}</Modal.Body>

          <Modal.Footer>
            <Button onClick={this.handleCancel}>{cancelButtonText}</Button>

            <Button primary onClick={this.handleConfirm}>
              {confirmButtonText}
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

Confirm.propTypes = {
  defaultShow: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  title: PropTypes.node,
  message: PropTypes.node.isRequired,
  children: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.node,
  cancelButtonText: PropTypes.node,
};

Confirm.defaultProps = {
  defaultShow: false,
  onCancel: null,
  title: 'Confirmation',
  confirmButtonText: 'Ok',
  cancelButtonText: 'Cancel',
};

export default Confirm;
