import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { withRouter } from 'react-router';

import ProductSearchResultsTable from 'sow/components/organisms/ProductSearchResultsTable';

const mapStateToProps = state => ({
  searchResults: state.products.searchResults,
  isSearching: state.products.isSearching,
  searchInitiated: state.products.searchInitiated,
  filteredResults: state.products.filteredOrgResults,
  filtered: state.products.filtered,
});

class ProductSearchResultsTableContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ProductSearchResultsTable
        updatedOrgList={this.props.filteredResults}
        filtered={this.props.filtered}
        searchResults={this.props.searchResults}
        isSearching={this.props.isSearching}
        searchInitiated={this.props.searchInitiated}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(ProductSearchResultsTableContainer);
