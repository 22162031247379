import React from 'react';
import PropTypes from 'prop-types';

import Block from 'sow/components/atoms/Block';
import Input from 'sow/components/atoms/Input';
import Select from 'sow/components/molecules/Select';
import FormGroup from 'sow/components/organisms/FormGroup';

const roles = [
  { value: 'aca_owner', text: 'Owner' },
  { value: 'aca_osp_editor', text: 'Plan Editor' },
  { value: 'aca_admin', text: 'Staff' },
];

const AcaStaffForm = ({ user, onChange, errors }) =>
  <Block>
    <FormGroup fieldName="email" errors={errors} label="Email address" required>
      <Input
        type="email"
        id="email"
        value={user.email}
        onChange={onChange('email')}
        required
      />
    </FormGroup>

    <FormGroup fieldName="role" errors={errors} label="Role" required>
      <Select
        id="role"
        value={user.role}
        onChange={onChange('role')}
        required
        options={roles}
      />
    </FormGroup>
  </Block>;

AcaStaffForm.propTypes = {
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default AcaStaffForm;
