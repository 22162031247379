import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as itemVersionSectionActions from 'sow/actions/pure/itemVersionSection';
import { apiGet, apiPost, apiPut } from 'sow/sagas/api';

export const loadItemVersionSections = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/item_version_section', payload);

    if (response && meta.onSuccess) {
      yield put(itemVersionSectionActions.loadItemVersionSectionsDone(response));
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchById = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/item_version_section/${payload}`);

    if (response && meta.onSuccess) {
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addItemVersionSection = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/item_version_section', payload);

    if (response && meta.onSuccess) {
      yield put(actions.shell.toast('success', 'Item version section created.'));
      meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateItemVersionSection = function*({ id, payload, meta }) {
  try {
    const { response } = yield call(apiPut, `/admin/item_version_section/${id}`, payload);

    if (response && meta.onSuccess) {
      meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateSortOrder = function*({ payload }) {
  try {
    yield call(apiPost, '/admin/item_version_section/update_order', {
      updated_list: payload,
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteItemVersionSection = function*({ id, meta }) {
  try {
    const { response } = yield call(apiPut, `/admin/item_version_section/delete/${id}`);
    if (response) {
      yield put(actions.shell.toast('success', 'Item version section deleted.'));

      if (meta.onSuccess) meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export const loadAdminOptions = function*({ meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/item_version_section/options');
    if (response && meta.onSuccess) meta.onSuccess(response);
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(
    itemVersionSectionActions.LOAD_ITEM_VERSION_SECTIONS,
    loadItemVersionSections,
  );
  yield takeEvery(itemVersionSectionActions.FETCH_BY_ID, fetchById);
  yield takeEvery(
    itemVersionSectionActions.ADD_ITEM_VERSION_SECTION,
    addItemVersionSection,
  );
  yield takeEvery(
    itemVersionSectionActions.UPDATE_ITEM_VERSION_SECTION,
    updateItemVersionSection,
  );
  yield takeEvery(itemVersionSectionActions.UPDATE_SORT_ORDER, updateSortOrder);
  yield takeEvery(
    itemVersionSectionActions.DELETE_ITEM_VERSION_SECTION,
    deleteItemVersionSection,
  );
  yield takeEvery(itemVersionSectionActions.LOAD_OPTIONS, loadAdminOptions);
}
