import React from 'react';

import { itemVersionRoute } from 'sow/routes';

import AdminObjectTemplateContainer from 'sow/containers/AdminObjectTemplateContainer';
import ItemVersionInfoEditContainer from 'sow/containers/ItemVersionInfoEditContainer';

const ItemVersionInfoEditPage = () => (
  <AdminObjectTemplateContainer
    breadcrumbPath={[['Item Versions', itemVersionRoute()]]}
    statePath={['itemVersion']}
    pageName="New Item Version"
  >
    <ItemVersionInfoEditContainer />
  </AdminObjectTemplateContainer>
);

export default ItemVersionInfoEditPage;
