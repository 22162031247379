import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import pretty from 'pretty';
import styled from 'styled-components';
import deepEqual from 'fast-deep-equal';

import * as sowTypes from 'sow/types';
import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Heading from 'sow/components/atoms/Heading';
import Paragraph from 'sow/components/atoms/Paragraph';
import CheckboxButton from 'sow/components/molecules/CheckboxButton';
import Select from 'sow/components/molecules/Select';
import GuideStep from 'sow/components/atoms/GuideStep';
import PlanAppExportPreviewFrame from 'sow/components/organisms/PlanAppExportPreviewFrame';
import WellFormGroup from 'sow/components/organisms/WellFormGroup';
import Spinner from 'sow/components/atoms/Spinner';
import InfoPanel from 'sow/components/organisms/InfoPanel';
import OptGroup from 'sow/components/atoms/OptGroup';
import Option from 'sow/components/atoms/Option';

const PlanAppExportFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlanAppExportFormContent = styled.div`
  padding: 0 20px;
  padding: 10px 10px 30px;
  max-width: 1200px;
  margin: auto;
  flex: 1 1;
`;

const PlanAppExportFormLivePreview = styled.div`
  padding: 0 20px;
  padding: 10px 10px 30px;
  max-width: 800px;
  min-width: 800px;
  margin: auto;
  flex: 1 1;
`;

const SelectWrapper = styled.div`
  margin: 4px;

  & select:not([multiple]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    padding: 0.5em 1em;
    padding-right: 1.5em;
  }
  & select {
    border-radius: 10px;
    line-height: 1;
  }
`;

class PlanAppExportForm extends React.Component {
  onBuildExport = () => this.props.buildExport();

  isReadyToLivePreview() {
    const { exportConfig, latestBuildExportConfig } = this.props;
    return (
      exportConfig &&
      latestBuildExportConfig &&
      exportConfig.planAppId === latestBuildExportConfig.planAppId
    );
  }

  render() {
    const {
      formValues,
      planAppOptionList,
      onChangePlanAppId,
      setFormValue,
      isBuildDisabled,
      exportConfig,
      isLivePreviewEnabled,
      // builder passed props
      isBuilding,
      downloadUrl,
      exportHtml,
      userList,
      latestBuildExportConfig,
    } = this.props;

    const isExportConfigChanged = !deepEqual(exportConfig, latestBuildExportConfig);
    const isDownloadDisabled = isBuilding || !downloadUrl || isExportConfigChanged;

    const currentPlan = R.prop(0, planAppOptionList);
    const historicalPlans = planAppOptionList.filter((_, i) => i > 0);

    return (
      <PlanAppExportFormWrapper>
        <PlanAppExportFormContent>
          <GuideStep number={1} showGuideLine>
            <WellFormGroup title="Choose a plan to export">
              <SelectWrapper>
                <Select
                  name="planAppId"
                  // options={planAppOptionList}
                  onChange={onChangePlanAppId}
                  value={formValues.planAppId}
                  showBlankOption
                  lg
                >
                  {currentPlan && (
                    <OptGroup label="Current Plan">
                      <Option value={currentPlan.value}>{currentPlan.text}</Option>
                    </OptGroup>
                  )}

                  {historicalPlans.length && (
                    <OptGroup label="Historical Plans">
                      {historicalPlans.map(opt => (
                        <Option key={opt.value} value={opt.value}>
                          {opt.text}
                        </Option>
                      ))}
                    </OptGroup>
                  )}
                </Select>
              </SelectWrapper>
            </WellFormGroup>
          </GuideStep>

          <GuideStep number={2} showGuideLine={!isBuildDisabled}>
            <WellFormGroup title="Select what to include in export">
              <CheckboxButton
                label="Main Plan"
                name="showMainPlan"
                value={formValues.showMainPlan}
                setFieldValue={setFormValue}
                disabled={isBuilding}
              />
              <CheckboxButton
                label="Locations"
                name="showLocations"
                value={formValues.showLocations}
                setFieldValue={setFormValue}
                disabled={isBuilding}
              />
            </WellFormGroup>
            <WellFormGroup small>
              <CheckboxButton
                label="Include Notes"
                name="addNotes"
                value={formValues.addNotes}
                setFieldValue={setFormValue}
                disabled={isBuilding}
              />

              <CheckboxButton
                label="Include Comments"
                name="addComments"
                value={formValues.addComments}
                setFieldValue={setFormValue}
                disabled={isBuilding}
              />
              <CheckboxButton
                label="Include Files"
                name="addFiles"
                value={formValues.addFiles}
                setFieldValue={setFormValue}
                disabled={isBuilding}
              />
            </WellFormGroup>
          </GuideStep>

          <GuideStep
            number={3}
            showGuideLine={!isDownloadDisabled}
            dimmed={isBuildDisabled}
          >
            <WellFormGroup title="Generate and download export bundle based on above settings">
              <Button
                onClick={this.onBuildExport}
                disabled={isBuildDisabled || isBuilding || !isDownloadDisabled}
                primary
                block
                lg
              >
                <FontAwesome icon="download" /> Generate and Download Export
              </Button>
            </WellFormGroup>
          </GuideStep>

          
        </PlanAppExportFormContent>
        {isLivePreviewEnabled && (
          <PlanAppExportFormLivePreview>
            <InfoPanel
              text={
                <span>
                  <b>NOTE:</b> This Live Preview is ONLY shown for testing purposes and
                  will never be visible to org users.
                </span>
              }
            />
            <Heading level={2}>Live Preview</Heading>
            <Paragraph textMuted>
              A live preview will show after generating an export.
            </Paragraph>
            {!isBuilding && this.isReadyToLivePreview() && (
              <PlanAppExportPreviewFrame
                exportConfig={exportConfig}
                userList={userList}
              />
            )}
            {false && // disable for now
              !isBuilding &&
              exportHtml && (
                <Block>
                  <Heading level={2}>Source Code (pretty)</Heading>
                  <pre>{pretty(exportHtml)}</pre>
                </Block>
              )}
          </PlanAppExportFormLivePreview>
        )}
      </PlanAppExportFormWrapper>
    );
  }
}

PlanAppExportForm.propTypes = {
  isBuildDisabled: PropTypes.bool.isRequired,
  planAppOptionList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  formValues: PropTypes.shape({
    planAppId: sowTypes.planAppIdType,
    showMainPlan: PropTypes.bool.isRequired,
    showLocations: PropTypes.bool.isRequired,
    addNotes: PropTypes.bool.isRequired,
    addFiles: PropTypes.bool.isRequired,
  }),
  exportConfig: sowTypes.planAppExportConfigType,
  onChangePlanAppId: PropTypes.func.isRequired,
  setFormValue: PropTypes.func.isRequired,
  isLivePreviewEnabled: PropTypes.bool.isRequired,
  // Builder Props
  buildExport: PropTypes.func.isRequired,
  isBuilding: PropTypes.bool.isRequired,
  isLoadingData: PropTypes.bool.isRequired,
  exportHtml: PropTypes.string,
  userList: sowTypes.userLimitedListType,
  downloadUrl: PropTypes.string,
  latestBuildExportConfig: sowTypes.planAppExportConfigType,
};

export default PlanAppExportForm;
