import React, { Fragment, useState } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { resourceUpdate } from 'sow/store/helpers';
import * as adminRequestActions from 'sow/actions/adminRequest';
import { fromPlanApp } from 'sow/store/selectors';

import Button from 'sow/components/atoms/Button';
import Glyphicon from 'sow/components/molecules/Glyphicon';

const getCreateChangeResource = (orgId, planAppId, changeRequestId) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`,
    'changeRequestOverviewResp',
  );

const mapStateToProps = (state, props) => {
  const { selectors } = getCreateChangeResource(
    props.orgId,
    props.planAppId,
    props.changeRequestId,
  );
  return {
    requestList: state.adminRequests.worksheetRequests.list,
    locationRequestList: state.adminRequests.locationRequests.list,
    worksheet: fromPlanApp.worksheet(state, props),
    locationDetails: fromPlanApp.locationDetails(state, props),
    isStateUnderReview: fromPlanApp.isStateUnderReview(state, props),
    isStateInitialApplication: fromPlanApp.isStateInitialApplication(state, props),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    orgId,
    planAppId,
    changeRequestId,
    worksheetId,
    matrixRowId,
    locationId,
    questionId,
    field,
    form,
    changeFieldName,
  } = ownProps;
  const { action } = getCreateChangeResource(orgId, planAppId, changeRequestId);

  const newChange = {
    context: R.isNil(locationId) ? 'main' : 'land',
    type: R.isNil(matrixRowId) ? 'worksheet_answer' : 'matrix_row_answer',
    action: R.isNil(field.value) ? 'added' : 'updated',
    applicationId: planAppId,
    changeRequestId: changeRequestId,
    worksheetId: worksheetId,
    questionId: questionId,
    matrixRowId: matrixRowId,
    landId: locationId,
    old: field.value,
    new: field.value,
  };

  return {
    createChange: () =>
    dispatch(action(null, { changes: [newChange] })).then(() =>
      form.setFieldValue(changeFieldName, field.value),
    ),
    createRequest: (orgId, planAppId, body) => dispatch(adminRequestActions.createAdminRequest(orgId, planAppId, body)),
    clearRequest: (orgId, planAppId, requestId) => dispatch(adminRequestActions.clearAdminRequest(orgId, planAppId, requestId)),
  };
};

const PlanAppWorksheetCreateChangeButtonContainer = ({
  createChange,
  orgId,
  planAppId,
  questionId,
  locationId,
  questionName,
  worksheetId,
  worksheet,
  locationDetails,
  showAcaUI,
  disabled,
  createRequest,
  matrixRowId,
  clearRequest,
  requestList,
  locationRequestList,
  isStateUnderReview,
  isStateInitialApplication
}) => {

  const onCreateRequest = () => {
    const body = {
      question_id: questionId,
      question_name: questionName,
      worksheet_id: worksheetId,
      worksheet_name: worksheet.name,
    };
    if (!R.isNil(matrixRowId)) {
      body['matrix_row_id'] = matrixRowId;
    }
    if (!R.isNil(locationId)) {
      body['location_id'] = locationId;
      body['location_name'] = locationDetails.name;
    }

    createRequest(orgId, planAppId, body);
  }

  const onClearRequest = (requestId) => {
    clearRequest(orgId, planAppId, requestId)
  }

  let handler = createChange;
  const match = requestList.filter(item => 
    questionId == item.question_id 
    && matrixRowId == item.matrix_row_id);
  const locationMatch = locationRequestList.filter(item => 
    locationId == item.location_id 
    && questionId == item.question_id
    && matrixRowId == item.matrix_row_id);
  const label = (match.length > 0 || locationMatch.length > 0) ? 'Clear To-do' : 'Request To-do';

  if (!showAcaUI) return null;
  if (showAcaUI) {
    if (match.length == 0 && locationMatch.length == 0) {
      handler = () => onCreateRequest();
    } else if (match.length > 0) {
      const requestId = match.length > 0 ? match[0].id : 0;
      handler = () => onClearRequest(requestId);
    } else if (locationMatch.length > 0) {
      const requestLocationId = locationMatch.length > 0 ? locationMatch[0].id : 0;
      handler = () => onClearRequest(requestLocationId);
    }
  }

  if (!isStateUnderReview) return null;

  return (
      <Button
        sm
        warning={!showAcaUI}
        primary={showAcaUI}
        onClick={handler}
        disabled={disabled}
      >
        <Fragment>
          { label }
        </Fragment>
    </Button>
  );
};

PlanAppWorksheetCreateChangeButtonContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheetId: sowTypes.worksheetIdType.isRequired,
  changeRequestId: sowTypes.planAppChangeRequestIdType,
  matrixRowId: PropTypes.string,
  locationId: sowTypes.planAppLocationIdType,
  questionId: sowTypes.planAppWorksheetQuestionIdType.isRequired,
  questionName: PropTypes.string,
  showAcaUI: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  createChange: PropTypes.func.isRequired,
};

PlanAppWorksheetCreateChangeButtonContainer.defaultProps = {
  matrixRowId: null,
  locationId: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanAppWorksheetCreateChangeButtonContainer);
