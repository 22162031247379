import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Spacer = ({ clearfix, vertical, horizontal, unit, inline }) => {
  const classes = classNames({
    clearfix: clearfix,
  });

  const styles = {
    marginTop: `${vertical}${unit}`,
    marginLeft: `${horizontal}${unit}`,
  };

  if (horizontal !== 0 || inline) {
    styles.display = 'inline-block';
  }

  return <div className={classes} style={styles} />;
};

Spacer.propTypes = {
  /** Assigns the 'clearfix' class */
  clearfix: PropTypes.bool,
  vertical: PropTypes.number,
  horizontal: PropTypes.number,
  unit: PropTypes.string,
  inline: PropTypes.bool.isRequired,
};

Spacer.defaultProps = {
  vertical: 0,
  horizontal: 0,
  unit: 'px',
  inline: false,
};

export default Spacer;
