import React from 'react';
import { decamelize } from 'humps';
import PropTypes from 'prop-types';

import Block from 'sow/components/atoms/Block';
import List from 'sow/components/atoms/List';
import ListItem from 'sow/components/atoms/ListItem';
import Strong from 'sow/components/atoms/Strong';

// 'foo_bar' => 'Foo Bar'
const formatFieldName = fieldName =>
  decamelize(fieldName, { separator: ' ' }).replace(/\b\w/g, m => m.toUpperCase());

const formatErrors = (errors, parentFields = []) =>
  Object.keys(errors).map(field => {
    if (errors[field] instanceof Array) {
      return (
        <ListItem key={field}>
          <Strong>
            {parentFields.length
              ? parentFields.map(f => formatFieldName(f)).join(' ') + ' - '
              : ''}
            {formatFieldName(field)}:
          </Strong>{' '}
          {errors[field].join('. ')}
        </ListItem>
      );
    } else {
      return formatErrors(errors[field], [...parentFields, field]);
    }
  });

const ToastMessage = ({ message, validationErrors }) => (
  <Block>
    {message}
    {validationErrors && <List>{formatErrors(validationErrors)}</List>}
  </Block>
);

ToastMessage.propTypes = {
  message: PropTypes.node,
  validationErrors: PropTypes.object,
};

ToastMessage.defaultProps = {
  message: null,
  validationErrors: null,
};

export default ToastMessage;
