import {
  FETCH_LOCATION_REPORT_OPTIONS_DONE,
  FETCH_LOCATION_REPORT,
  FETCH_LOCATION_REPORT_DONE,
  CLEAR_LOCATION_REPORT,
} from 'sow/actions/aca';

const initialState = {
  loadingOptions: true,
  searchInitiated: false,
  hasResults: false,
};

export default function locationReport(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_LOCATION_REPORT_OPTIONS_DONE:
      return { ...state, options: { ...payload }, loadingOptions: false };
    case FETCH_LOCATION_REPORT:
      return { ...state, searchInitiated: true, isFetching: true };
    case FETCH_LOCATION_REPORT_DONE:
      return {
        ...state,
        list: payload.list,
        isFetching: false,
        hasResults: payload.hasResults,
      };
    case CLEAR_LOCATION_REPORT:
      return { ...state, list: [], searchInitiated: false, hasResults: false };
    default:
      return state;
  }
}
