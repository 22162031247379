import * as R from 'ramda';
import { createSelector } from 'reselect';

import * as acaSelect from 'sow/selectors/core/aca';
import * as orgSelect from 'sow/selectors/core/organization';
import { createSafeCachedSelector } from 'sow/utils/selectors';

export const aca = createSelector(
  [acaSelect.currentAca],
  R.identity,
);

export const acaDetail = createSelector(
  [acaSelect.currentAcaDetail],
  R.identity,
);

export const acaId = createSelector(
  [acaSelect.currentAcaId],
  R.identity,
);

export const acaSlug = createSelector(
  [acaSelect.currentAcaSlug],
  R.identity,
);

export const acaConfig = createSelector(
  [acaDetail],
  R.propOr(null, 'configuration'),
);

const defaultScopes = [
  'Grower',
  'Livestock',
  'Handler',
  'Handler COR',
  'Private Label',
  'Retail',
];

export const customScopesMap = createSafeCachedSelector([acaConfig], config => {
  const scopesMap = {
    pasture: R.propOr(null, 'pasture_scopes', config),
    parcel: R.propOr(null, 'parcel_scopes', config),
    office: R.propOr(null, 'office_scopes', config),
    facility: R.propOr(null, 'facility_scopes', config),
  };

  return R.reject(R.isNil, scopesMap);
})(acaId);

export const scopes = createSafeCachedSelector([customScopesMap], customScopesMap => {
  const customScopes = R.compose(
    R.uniq,
    R.unnest,
    R.values,
  )(customScopesMap);

  return R.isEmpty(customScopes) ? defaultScopes : customScopes;
})(acaId);

export const defaultScope = createSafeCachedSelector([scopes], R.head)(acaId);

export const name = createSafeCachedSelector([aca], R.path(['name']))(acaId);

export const orgMap = createSelector(
  [acaId, orgSelect.allOrgsMap],
  (acaId, orgs) => {
    return R.filter(R.pathEq(['aca', 'id'], parseInt(acaId)), orgs);
  },
);

export const orgs = createSelector(
  [acaId, orgSelect.allOrgs],
  (acaId, orgs) => {
    return R.filter(R.pathEq(['aca', 'id'], parseInt(acaId)), orgs);
  },
);

export const isLoading = createSafeCachedSelector(
  [acaSelect.isLoadingCurrentAca],
  R.identity,
)(acaId);

export const isLoadFail = createSafeCachedSelector(
  [acaSelect.isLoadFailedCurrentAca],
  R.identity,
)(acaId);

export const isLoaded = createSafeCachedSelector(
  [acaSelect.isLoadedCurrentAca],
  R.identity,
)(acaId);

export const shouldLoad = createSafeCachedSelector(
  [acaSelect.shouldLoadCurrentAca],
  R.identity,
)(acaId);

// TODO - replace this with an aca_settings based version for online UI setting of this
// const baroSuspendMsg = `
// Thanks for using Sow Organic. This account is temporarily suspended.
//  Please contact Basin and Range Organic with any questions.`;

export const suspended = createSelector(
  [acaId],
  acaId => {
    // if (acaId === 1) {
    //   return {
    //     message: baroSuspendMsg,
    //   };
    // }

    return null;
  },
);

export const isCcof = createSafeCachedSelector([acaId], acaId => acaId === 2)(acaId);
