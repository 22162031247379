import React from 'react';

import { itemRoute } from 'sow/routes';

import AdminObjectTemplateContainer from 'sow/containers/AdminObjectTemplateContainer';
import ItemEditContainer from 'sow/containers/ItemEditContainer';

const ItemEditPage = () => (
  <AdminObjectTemplateContainer
    breadcrumbPath={[['Items', itemRoute()]]}
    statePath={['item']}
    pageName="New Item"
  >
    <ItemEditContainer />
  </AdminObjectTemplateContainer>
);

export default ItemEditPage;
