import React from 'react';

const Checkbox = ({ disabled, value, text, onChange, field }) => (
  <div className="checkbox">
    <label style={{ float: 'left', marginRight: '75px' }}>
      <input
        type="checkbox"
        checked={value === true}
        onChange={() => onChange(!value, field)}
        disabled={disabled}
      />
      {text}
    </label>
  </div>
);

export default Checkbox;
