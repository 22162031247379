import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchTimezoneList } from 'sow/actions/aca';

import Atom from 'sow/components/atoms/Atom';

const mapStateToProps = state => ({
  timezoneList: state.timezoneList.items,
  isFetching: state.timezoneList.isFetching,
});

class TimezoneListContainer extends Component {
  componentDidMount() {
    if (!this.props.isFetching && !this.props.timezoneList.length) {
      this.props.dispatch(fetchTimezoneList());
    }
  }

  render() {
    const { timezoneList, isFetching } = this.props;

    if (isFetching) return <Atom>Loading timezones...</Atom>;

    return this.props.children(timezoneList, isFetching);
  }
}

TimezoneListContainer.propTypes = {
  timezoneList: PropTypes.array,
  isFetching: PropTypes.bool,
  children: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(TimezoneListContainer);
