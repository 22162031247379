import React from 'react';

import ErrorPage from 'sow/components/templates/ErrorPageTemplate';
import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';

const ErrorLoadingDataPage = () =>
  <ErrorPage
    message="There was an error when loading data! You can refresh the page to try again."
    large
  >
    <Block textCenter textMuted>
      <Link to="/">Go Home</Link>
    </Block>
  </ErrorPage>;

export default ErrorLoadingDataPage;
