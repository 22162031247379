import React, { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Link from 'sow/components/atoms/Link';
import * as currentUser from 'sow/selectors/currentUser';

const mapStateToProps = (state, props) => ({
  isInspector: currentUser.isInspector(state, props),
});

const getColor = props => {
  const { grey, green, red, blue } = props;
  if (grey) return 'grey';
  if (green) return '#5cb85c';
  if (blue) return '#4478b6';
  if (red) return '#cf2b1f';
  else return 'orange';
};

const FilterCount = styled.div`
  width: 70px;
  border-radius: 5px;
  margin: 0 auto;
  margin-left: 0px;
  padding: 3px 0;
  color: white;
  background-color: ${props => getColor(props)};
  text-align: center;
`;

const FilterLabel = styled.div`
  width: 70px;
  text-align: center;
  margin: 0 auto;
  margin-left: 0px;
  margin-top: 5px;
`;

const FilterSelected = styled.div`
  margin-left: 23px;
  font-size: 18pt;
`;

const DashboardFilterCounts = ({
  dashboardCounts,
  handleFilterChange,
  filter = 'all',
  isInspector,
}) => (
  <div style={{ maxWidth: '600px' }}>
    <Table>
      <TableRow>
        <TableCell>
          <FilterCount grey>{dashboardCounts ? dashboardCounts.all : 0}</FilterCount>
          <Link onClick={() => handleFilterChange('all')}>
            <FilterLabel>All Plans</FilterLabel>
          </Link>
          {filter === 'all' && (
            <FilterSelected className="glyphicon glyphicon-triangle-bottom" />
          )}
        </TableCell>
        <TableCell>
          <FilterCount>{dashboardCounts ? dashboardCounts.expedited : 0}</FilterCount>
          <Link onClick={() => handleFilterChange('expedited')}>
            <FilterLabel>Expedited</FilterLabel>
          </Link>
          {filter === 'expedited' && (
            <FilterSelected className="glyphicon glyphicon-triangle-bottom" />
          )}
        </TableCell>
        {isInspector ? (
          <Fragment>
            <TableCell>
              <FilterCount green>
                {dashboardCounts ? dashboardCounts.ready_for_inspection : 0}
              </FilterCount>
              <Link onClick={() => handleFilterChange('ready_for_inspection')}>
                <FilterLabel>Ready for Inspection</FilterLabel>
              </Link>
              {filter === 'ready_for_inspection' && (
                <FilterSelected className="glyphicon glyphicon-triangle-bottom" />
              )}
            </TableCell>
            <TableCell>
              <FilterCount blue>
                {dashboardCounts ? dashboardCounts.in_inspection : 0}
              </FilterCount>
              <Link onClick={() => handleFilterChange('in_inspection')}>
                <FilterLabel>In Inspection</FilterLabel>
              </Link>
              {filter === 'in_inspection' && (
                <FilterSelected className="glyphicon glyphicon-triangle-bottom" />
              )}
            </TableCell>
          </Fragment>
        ) : (
          <Fragment>
            <TableCell>
              <FilterCount green>
                {dashboardCounts ? dashboardCounts.is_new : 0}
              </FilterCount>
              <Link onClick={() => handleFilterChange('new')}>
                <FilterLabel>New</FilterLabel>
              </Link>
              {filter === 'new' && (
                <FilterSelected className="glyphicon glyphicon-triangle-bottom" />
              )}
            </TableCell>
            <TableCell>
              <FilterCount red>
                {dashboardCounts ? dashboardCounts.not_started : 0}
              </FilterCount>
              <Link onClick={() => handleFilterChange('not_started')}>
                <FilterLabel>Not Started</FilterLabel>
              </Link>
              {filter === 'not_started' && (
                <FilterSelected className="glyphicon glyphicon-triangle-bottom" />
              )}
            </TableCell>
            <TableCell>
              <FilterCount blue>
                {dashboardCounts ? dashboardCounts.is_renewal : 0}
              </FilterCount>
              <Link onClick={() => handleFilterChange('renewal')}>
                <FilterLabel>Renewals</FilterLabel>
              </Link>
              {filter === 'renewal' && (
                <FilterSelected className="glyphicon glyphicon-triangle-bottom" />
              )}
            </TableCell>
          </Fragment>
        )}
      </TableRow>
    </Table>
  </div>
);

DashboardFilterCounts.propTypes = {
  dashboardCounts: PropTypes.object,
  filter: PropTypes.string,
  handleFilterChange: PropTypes.func,
};

export default connect(mapStateToProps)(DashboardFilterCounts);
