import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push as routerPush } from 'connected-react-router';

import { loginRoute } from 'sow/routes';
import { resetPassword } from 'sow/actions/pure/resetPassword';
import FormGroup from 'sow/components/organisms/FormGroup';
import CenteredLayout from 'sow/components/templates/CenteredLayout';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';

const mapStateToProps = state => ({
  isResetting: state.passwordReset.isResetting,
});

const mapDispatchToProps = { resetPassword, redirect: routerPush };

class ResetPassword extends Component {
  state = {
    password: '',
    confirmPassword: '',
  };

  handleChange = field => event => {
    this.setState({ [field]: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { match, resetPassword } = this.props;
    const payload = {
      forgotten_password_hash: match.params.resetPasswordHash,
      new_password: this.state.password,
    };

    resetPassword(payload, this.handleSubmitSuccess);
  };

  handleSubmitSuccess = () => {
    this.props.redirect(loginRoute());
  };

  render() {
    const { password, confirmPassword } = this.state;
    const { isResetting } = this.props;

    const submitDisabled = isResetting || !password || password !== confirmPassword;

    return (
      <div className="body-wrapper">
        <CenteredLayout>
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-left">
              <form onSubmit={this.handleSubmit}>
                <Widget>
                  <WidgetHeading>Password Reset</WidgetHeading>

                  <WidgetBody>
                    <p>
                      Please enter a new password to be used for your WFCF Organic account.
                    </p>

                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup fieldName="password" label="Your new password" required>
                          <input
                            type="password"
                            value={password}
                            onChange={this.handleChange('password')}
                            className="form-control"
                            required
                          />
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup
                          fieldName="confirmPassword"
                          label="Confirm password"
                          required
                        >
                          <input
                            type="password"
                            value={confirmPassword}
                            onChange={this.handleChange('confirmPassword')}
                            className="form-control"
                            required
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </WidgetBody>

                  <WidgetFooter className="text-right">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm"
                      disabled={submitDisabled}
                    >
                      Continue <span className="glyphicon glyphicon-chevron-right" />
                    </button>
                  </WidgetFooter>
                </Widget>
              </form>
            </div>
          </div>
        </CenteredLayout>
      </div>
    );
  }
}

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ResetPassword);
