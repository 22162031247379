import React from 'react';
import PropTypes from 'prop-types';
import * as sowTypes from 'sow/types';

import {
  planAppWorksheetQuestionScrollTargetId,
  planAppWorksheetMatrixRowScrollTargetId,
} from './scrollRoutes';

class ScrollTarget extends React.Component {
  render() {
    return (
      <div id={this.props.id} style={{ position: 'relative' }}>
        {this.props.children}
      </div>
    );
  }
}

ScrollTarget.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default ScrollTarget;

ScrollTarget.PlanAppWorksheetQuestion = ScrollTargetPlanAppWorksheetQuestion;
ScrollTarget.PlanAppWorksheetMatrixRow = ScrollTargetPlanAppWorksheetMatrixRow;

// NOTE: The following scroll target components should be accessed through the
//       main ScrollTarget component class like this:
//
//         <ScrollTarget.PlanAppWorksheetQuestion questionId={'uuid-bbbb'} />
//

// Plan App Worksheet Question Scroll Target
function ScrollTargetPlanAppWorksheetQuestion({ questionId, ...props }) {
  const id = planAppWorksheetQuestionScrollTargetId(questionId);
  return <ScrollTarget {...props} id={id} />;
}

ScrollTargetPlanAppWorksheetQuestion.propTypes = {
  questionId: sowTypes.planAppWorksheetQuestionIdType,
};

// Plan App Worksheet Matrix Row Scroll Target
function ScrollTargetPlanAppWorksheetMatrixRow({ matrixRowId, ...props }) {
  const id = planAppWorksheetMatrixRowScrollTargetId(matrixRowId);
  return <ScrollTarget {...props} id={id} />;
}

ScrollTargetPlanAppWorksheetMatrixRow.propTypes = {
  matrixRowId: sowTypes.planAppWorksheetQuestionMatrixRowIdType,
};
