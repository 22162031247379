import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import * as sowTypes from 'sow/types';
import * as currentAca from 'sow/selectors/currentAca';
import { fetchActivityLog, fetchLogOspDefinition } from 'sow/actions/aca';
import Spinner from 'sow/components/atoms/Spinner';
import AcaActivityTable from 'sow/components/organisms/AcaActivityTable';

const isMoment = R.is(moment);

const utcFormatDatetimeForApi = datetime => {
  if (!isMoment(datetime)) return null;
  return moment(datetime)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss');
};

const compactObj = R.reject(R.isEmpty);

const formatFiltersDataForApi = R.pipe(
  R.evolve({
    start_datetime: utcFormatDatetimeForApi,
    end_datetime: utcFormatDatetimeForApi,
  }),
  compactObj,
);

const mapStateToProps = (state, props) => ({
  acaId: currentAca.acaId(state, props),
  logs: state.acaActivityLog.items,
  fetchingOptions: state.acaActivityLog.isFetchingOptions,
  tableData: state.acaActivityLog.entriesData,
  ospWorksheetsByDefId: state.acaActivityLog.ospWorksheets,
  ospQualificationByDefId: state.acaActivityLog.ospQualifications,
});

const mapDispatchToProps = { fetchActivityLog, fetchLogOspDefinition };

class AcaActivityTableContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { fetchingWorksheets: true };
  }
  componentDidMount() {
    const ospDefIds = R.path(['ospDefIds'], this.props.tableData);

    ospDefIds.forEach(id => {
      if (!R.has(id, this.props.ospWorksheetsByDefId)) {
        this.props.fetchLogOspDefinition(this.props.acaId, id);
      }
    });
    this.setState({ fetchingWorksheets: true });
  }

  componentDidUpdate() {
    if (this.state.fetchingWorksheets) {
      let allOspsFound;
      const ospDefIds = R.path(['ospDefIds'], this.props.tableData);
      const foundOspDefIds = Object.keys(this.props.ospWorksheetsByDefId).map(id =>
        Number(id),
      );
      for (let i = 0; i < ospDefIds.length; i++) {
        if (!R.contains(ospDefIds[i], foundOspDefIds)) {
          allOspsFound = false;
          break;
        } else {
          allOspsFound = true;
        }
      }

      if (allOspsFound || ospDefIds.length === 0) {
        this.setState({ fetchingWorksheets: false });
      }
    }
  }

  selectPage(page) {
    const { fetchActivityLog, acaId } = this.props;
    const { start_datetime, end_datetime } = this.props.filters;

    const filters = {
      ...this.props.filters,
      page: page,
      start_datetime: moment(start_datetime).second(0),
      end_datetime: moment(end_datetime)
        .second(0)
        .add('1', 'minute'),
    };

    const filtersData = formatFiltersDataForApi(filters);

    fetchActivityLog(acaId, filtersData);
  }

  render() {
    const {
      logs,
      tableData,
      ospWorksheetsByDefId,
      ospQualificationByDefId,
      fetchingOptions,
    } = this.props;
    const { fetchingWorksheets } = this.state;

    return (
      <Fragment>
        {fetchingWorksheets || fetchingOptions ? (
          <Spinner />
        ) : (
          <AcaActivityTable
            logs={logs}
            tableData={tableData}
            selectPage={this.selectPage.bind(this)}
            ospData={{ ospWorksheetsByDefId, ospQualificationByDefId }}
          />
        )}
      </Fragment>
    );
  }
}

AcaActivityTableContainer.propTypes = {
  acaId: sowTypes.acaIdType,
  logs: PropTypes.arrayOf(PropTypes.object),
  fetchActivityLog: PropTypes.func,
  tableData: PropTypes.object,
  ospWorksheetsByDefId: PropTypes.object,
  ospQualificationByDefId: PropTypes.object,
};

AcaActivityTableContainer.defaultProps = {
  acaId: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AcaActivityTableContainer);
