import React from 'react';
import PropTypes from 'prop-types';

import { acaIdType, acaAdminType } from 'sow/types';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Button from 'sow/components/atoms/Button';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import AcaAddStaffModalContainer from 'sow/containers/AcaAddStaffModal';

const roleNames = {
  aca_owner: 'Owner',
  aca_osp_editor: 'Plan Editor',
  aca_admin: 'Staff',
};

const StaffRow = ({ acaId, admin, onRevoke }) => (
  <TableRow>
    <TableCell>{admin.name}</TableCell>
    <TableCell>{roleNames[admin.role]}</TableCell>
    <TableCell>{admin.email}</TableCell>
    <TableCell textRight>
      <Button danger xs onClick={onRevoke(acaId, admin)}>
        Revoke
      </Button>
    </TableCell>
  </TableRow>
);

StaffRow.propTypes = {
  acaId: acaIdType.isRequired,
  onRevoke: PropTypes.func.isRequired,
  admin: acaAdminType.isRequired,
};

const NoStaffRow = () => (
  <TableRow>
    <TableCell colSpan={3}>No staff</TableCell>
  </TableRow>
);

const AcaStaffPage = ({ acaId, acaAdmins, onRevoke }) => (
  <Widget>
    <WidgetBody>
      <Table
        condensed
        bordered
        head={
          <TableRow>
            <TableCell heading>Name</TableCell>
            <TableCell heading>Role</TableCell>
            <TableCell heading>Email</TableCell>
            <TableCell heading md={1} />
          </TableRow>
        }
      >
        {!acaAdmins.length && <NoStaffRow />}
        {acaAdmins.map(admin => (
          <StaffRow key={admin.id} admin={admin} acaId={acaId} onRevoke={onRevoke} />
        ))}
      </Table>
      <AcaAddStaffModalContainer acaId={acaId} />
    </WidgetBody>
  </Widget>
);

AcaStaffPage.propTypes = {
  acaId: acaIdType.isRequired,
  acaAdmins: PropTypes.arrayOf(acaAdminType).isRequired,
  onRevoke: PropTypes.func.isRequired,
};

export default AcaStaffPage;
