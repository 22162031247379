import { makeImmutable, makeImmutableData } from 'sow/utils/data';

// TODO (orther) remove mark fn once port to sagas
import {
  // LOAD_ALL_FOR_ORG_OSP_APP_REQUEST,
  // LOAD_ALL_FOR_ORG_OSP_APP_SUCCESS,
  // LOAD_ALL_FOR_ORG_OSP_APP_FAILURE,
  MATRIX_ROW_CREATE,
  MATRIX_ROW_DELETE,
  MATRIX_ROW_UNDELETE,
  MATRIX_ROW_SET_ANSWER,
  // SAVE_ONE_WORKSHEET_REQUEST,
  // SAVE_ONE_WORKSHEET_SUCCESS,
  // SAVE_ONE_WORKSHEET_FAILURE,
  SET_ANSWER,
  SET_BASE_ANSWER,
} from '../worksheetAnswers';

export const setBaseAnswer = (ospAppId, worksheetId, name, value) => ({
  type: SET_BASE_ANSWER,
  data: makeImmutable({ ospAppId, worksheetId, name, value }),
});

export const setIsNotApplicable = (ospAppId, worksheetId, value) => {
  return setBaseAnswer(ospAppId, worksheetId, 'isNotApplicable', !!value);
};

export const setAnswer = (ospAppId, worksheetId, questionId, value) => ({
  type: SET_ANSWER,
  data: makeImmutable({ ospAppId, worksheetId, questionId, value }),
});

export const createMatrixRow = (ospAppId, worksheetId, rowId) => ({
  type: MATRIX_ROW_CREATE,
  data: makeImmutable({ ospAppId, worksheetId, rowId }),
});

export const deleteMatrixRow = (ospAppId, worksheetId, rowId) => ({
  type: MATRIX_ROW_DELETE,
  data: makeImmutable({ ospAppId, worksheetId, rowId }),
});

export const undeleteMatrixRow = (ospAppId, worksheetId, rowId) => ({
  type: MATRIX_ROW_UNDELETE,
  data: makeImmutable({ ospAppId, worksheetId, rowId }),
});

export const setMatrixRowAnswer = (ospAppId, worksheetId, rowId, questionId, value) => ({
  type: MATRIX_ROW_SET_ANSWER,
  data: makeImmutable({ ospAppId, worksheetId, rowId, questionId, value }),
});

// export const clearAnswers = (ospAppId, worksheetId) => ({
//   type: CLEAR,
//   data: makeImmutable({ ospAppId, worksheetId }),
// });
