import * as R from 'ramda';
import { makeImmutable } from 'sow/utils/data';
import {
  MATRIX_ROW_CREATE,
  MATRIX_ROW_DELETE,
  MATRIX_ROW_UNDELETE,
  MATRIX_ROW_SET_ANSWER,
} from 'sow/actions/worksheetAnswers';
import valuesByQuestionId from './valuesByQuestionId';

const isDeleted = (state = false, action) => {
  switch (action.type) {
    case MATRIX_ROW_DELETE:
      return true;

    case MATRIX_ROW_UNDELETE:
      return false;

    default:
      return state;
  }
};

const mapKey = R.path(['data', 'rowId']);

const mapReducer = (state = makeImmutable({}), action) =>
  state.merge({
    valuesByQuestionId: valuesByQuestionId(true)(state.valuesByQuestionId, action),
    isDeleted: isDeleted(state.matrixMode, action),
  });

const matrixRowsById = (state = makeImmutable({}), action) => {
  switch (action.type) {
    case MATRIX_ROW_CREATE:
      return state.updateIn([mapKey(action)], mapReducer, action);

    case MATRIX_ROW_SET_ANSWER:
    case MATRIX_ROW_DELETE:
    case MATRIX_ROW_UNDELETE:
      return state.updateIn([mapKey(action)], mapReducer, action);

    default:
      return state;
  }
};

export default matrixRowsById;
