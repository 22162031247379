import React from 'react';
import PropTypes from 'prop-types';

import Block from 'sow/components/atoms/Block';
import Strong from 'sow/components/atoms/Strong';
import InfoPanel from 'sow/components/organisms/InfoPanel';
import Panel from 'sow/components/molecules/Panel';
import Spinner from 'sow/components/atoms/Spinner';
import InspectionManagerContainer from 'sow/containers/InspectionManagerContainer';

const HeaderNoticeMsg = (
  <Block>
    <Strong>NOTE:</Strong> The <Strong>Inspections</Strong> settings tab is only
    accessible to ACA staff and organization users will not see it.
  </Block>
);

const OrgSettingsInspectors = ({
  inspectorList,
  inspectorListDone,
  inspectorListFailed,
  inspectorListPending,
}) => {
  if (inspectorListFailed) {
    return (
      <Panel
        title="Something went wrong while fetching inspectors. Please try again later."
        danger
      />
    );
  }

  if (!inspectorListDone || inspectorListPending) {
    return <Spinner />;
  }

  return (
    <Block className="settings-inspector-tab">
      <InfoPanel text={HeaderNoticeMsg} />
      <InspectionManagerContainer inspectorList={inspectorList} />
    </Block>
  );
};

OrgSettingsInspectors.propTypes = {
  inspectorList: PropTypes.array,
  inspectorListDone: PropTypes.bool,
  inspectorListFailed: PropTypes.bool,
  inspectorListPending: PropTypes.bool,
};

export default OrgSettingsInspectors;
