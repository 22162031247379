import React from 'react';
import styled from 'styled-components';

import Block from 'sow/components/atoms/Block';

const PopoverWrapper = styled(Block)`
  padding: 5px 10px;
`;

export default PopoverWrapper;
