import { call, put, takeEvery } from 'redux-saga/effects';
import * as R from 'ramda';

import * as acaAdminActions from 'sow/actions/pure/acaAdmin';
import * as acaAdminApi from 'sow/api/aca/acaAdmin';

export const addStaff = function*({ payload, meta }) {
  const { acaId, user } = payload;
  const { onSuccess } = meta;

  try {
    const res = yield call(acaAdminApi.addStaff, acaId, user);
    yield put(acaAdminActions.addStaffDone(res.data));
    if (typeof onSuccess === 'function') {
      yield call(onSuccess);
    }
  } catch (err) {
    const validationErrors = R.path(['responseJSON', 'error', 'errors'], err);

    yield put(acaAdminActions.addStaffFail(validationErrors));

    if (err.responseJSON.error && err.responseJSON.error.message === 'user_not_found') {
      yield put(acaAdminActions.showInviteFields());
    }
  }
};

export default function*() {
  yield takeEvery(acaAdminActions.ADD_STAFF, addStaff);
}
