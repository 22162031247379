import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { replace as routerReplace } from 'connected-react-router';

import { itemVersionRoute } from 'sow/routes';

import * as itemVersionPicklistActions from 'sow/actions/pure/itemVersionPicklist';

import ItemVersionPicklist from 'sow/components/organisms/ItemVersionPicklist';

const mapStateToProps = (state, props) => ({
  itemVersionId: R.path(['match', 'params', 'id'], props),
});

const mapDispatchToProps = {
  fetchPicklists: itemVersionPicklistActions.loadItemVersionPicklists,
  createPicklist: itemVersionPicklistActions.addItemVersionPicklist,
  updatePicklist: itemVersionPicklistActions.updateItemVersionPicklist,
  deletePicklist: itemVersionPicklistActions.deleteItemVersionPicklist,
  redirectNoHistory: routerReplace,
};

const initialValues = {
  isLoading: true,
  picklistList: [],
  modalParams: { show: false, isNew: true, initialValues: { picklist_item_list: [] } },
  anchorEl: null,
};

class ItemVersionPicklistContainer extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = R.clone(initialValues);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.itemVersionId === 'new') {
      this.redirectToList();
    } else {
      this.fetchAllPicklists();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchAllPicklists() {
    const { itemVersionId, fetchPicklists } = this.props;
    fetchPicklists({ item_version_id: itemVersionId }, this.updateState.bind(this));
  }

  updateState({ data }) {
    if (this._isMounted) {
      const { modalParams } = this.state;
      this.setState({
        isLoading: false,
        picklistList: data,
        modalParams: { ...modalParams, show: false },
      });
    }
  }

  redirectToList() {
    this.props.redirectNoHistory(itemVersionRoute());
  }

  showModal() {
    const { modalParams } = this.state;
    this.setState({
      modalParams: { ...modalParams, show: true },
    });
  }

  closeModal() {
    this.setState({
      modalParams: {
        isNew: true,
        show: false,
        initialValues: { picklist_item_list: [] },
      },
    });
  }

  handleSave(values) {
    const { itemVersionId, updatePicklist, createPicklist } = this.props;
    if (R.has('id')(values)) {
      updatePicklist(values.id, values, this.fetchAllPicklists.bind(this));
    } else {
      values.item_version_id = itemVersionId;
      createPicklist(values, this.fetchAllPicklists.bind(this));
    }
  }

  showPopover(target, picklistId) {
    this.setState({
      anchorEl: target,
      picklistId,
    });
  }

  hidePopover() {
    this.setState({ anchorEl: null, picklistId: null });
  }

  editPicklist(id) {
    const { picklistId, picklistList } = this.state;
    const selectedPicklistId = id ? id : picklistId;
    const picklist = R.clone(R.find(R.propEq('id', selectedPicklistId), picklistList));
    this.hidePopover();
    this.setState({
      modalParams: { show: true, isNew: false, initialValues: { ...picklist } },
    });
  }

  removePicklist() {
    const { picklistId } = this.state;
    this.hidePopover();
    this.props.deletePicklist(picklistId, this.fetchAllPicklists.bind(this));
  }

  render() {
    return (
      <ItemVersionPicklist
        isLoading={this.state.isLoading}
        picklistList={this.state.picklistList}
        showModal={this.showModal.bind(this)}
        handleSave={this.handleSave.bind(this)}
        showPopover={this.showPopover.bind(this)}
        editPicklist={this.editPicklist.bind(this)}
        modalParams={{
          ...this.state.modalParams,
          closeModal: this.closeModal.bind(this),
          handleSave: this.handleSave.bind(this),
        }}
        popoverParams={{
          anchorEl: this.state.anchorEl,
          hidePopover: this.hidePopover.bind(this),
          editPicklist: this.editPicklist.bind(this),
          removePicklist: this.removePicklist.bind(this),
        }}
        {...this.props}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ItemVersionPicklistContainer);
