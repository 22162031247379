import React from 'react';

import InfoTooltip from 'sow/components/organisms/InfoTooltip';

export const BaseQuestion = ({
  question,
  answer,
  handleChange,
  orgId,
  ospAppId,
  worksheetId,
  disabled,
  showNotes,
  showNotesToggle,
  onToggleNotes,
  noteEntityId,
  field,
  tooltip,
}) => {
  if (question.field_type === 'checkbox') {
    return (
      <div className="row">
        <div className="pull-left">
          <h3 className="panel-title" style={{ padding: '9px 0 5px' }}>
            <label className={disabled ? 'text-muted' : ''}>
              {field}
              {question.name}{' '}
            </label>
          </h3>
        </div>
      </div>
    );
  }

  const TooltipWrapper = ({ children }) => {
    if (tooltip) {
      return <InfoTooltip overlay={tooltip}>{children}</InfoTooltip>;
    }

    return <span>{children}</span>;
  };

  return (
    <div className="row">
      <div className="pull-left" style={{ margin: '0 10px' }}>
        {field}
      </div>
      <div className="pull-left">
        <h3 className="panel-title" style={{ padding: '9px 0 5px' }}>
          <TooltipWrapper>
            <span className={disabled ? 'text-muted' : ''}>{question.name} </span>
          </TooltipWrapper>{' '}
        </h3>
      </div>
    </div>
  );
};

export default BaseQuestion;
