import * as R from 'ramda';
import { makeImmutable } from 'sow/utils/data';
import {
  CREATE_ONE_FOR_ACA_SUCCESS,
  LOAD_ONE_FOR_ACA_REQUEST,
  LOAD_ONE_FOR_ACA_SUCCESS,
  LOAD_ONE_FOR_ACA_FAILURE,
  LOAD_ALL_FOR_ACA_REQUEST,
  LOAD_ALL_FOR_ACA_SUCCESS,
  LOAD_ALL_FOR_ACA_FAILURE,
} from 'sow/actions/ospDefinition';
import ids from './ids';
import errorMessage from './errorMessage';
import isLoading from './isLoading';
import isLoadingOne from './isLoadingOne';

const mapKey = action => action.data.acaId;

const mapReducer = (state = makeImmutable({}), action) =>
  state.merge({
    errorMessage: errorMessage(state.errorMessage, action),
    ids: ids(state.ids, action),
    isLoading: isLoading(state.isLoading, action),
    isLoadingOne: isLoadingOne(state.isLoadingOne, action),
  });

const listByAcaId = (state = makeImmutable({}), action) => {
  switch (action.type) {
    case CREATE_ONE_FOR_ACA_SUCCESS:
    case LOAD_ONE_FOR_ACA_FAILURE:
    case LOAD_ONE_FOR_ACA_REQUEST:
    case LOAD_ONE_FOR_ACA_SUCCESS:
    case LOAD_ALL_FOR_ACA_FAILURE:
    case LOAD_ALL_FOR_ACA_REQUEST:
    case LOAD_ALL_FOR_ACA_SUCCESS:
      return state.updateIn([mapKey(action)], mapReducer, action);

    case 'ADD_DROP_ACTION_TYPES_HERE':
      // TODO (orther) implement this or remove the case
      return state.without(mapKey(action));
    default:
      return state;
  }
};

export default listByAcaId;

export const getIds = (state, acaId) => R.pathOr([], [acaId, 'ids'], state);

export const getIsLoading = (state, acaId) => R.path([acaId, 'isLoading'], state);

export const getIsLoadingOne = (state, acaId) => R.path([acaId, 'isLoadingOne'], state);

export const getErrorMessage = (state, acaId) => R.path([acaId, 'errorMessage'], state);
