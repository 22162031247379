import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';

import * as currentAca from 'sow/selectors/currentAca';
import * as currentUser from 'sow/selectors/currentUser';
import * as select from '../selectors';
import { actions as ospSecAction } from '../reducers/ospSections';

// const scopes = [
//   'Grower',
//   'Livestock',
//   'Handler',
//   'Handler COR',
//   'Private Label',
//   'Retail',
// ];

// const defaultScope = scopes[0];

const makeMapStateToProps = () => (state, props) => ({
  scopes: currentAca.scopes(state, props),
  defaultScope: currentAca.defaultScope(state, props),
  canEditOspDef: currentUser.canEditOspDef(state, props),
  ospSection: select.makeOspSectionSelector()(state, props),
  worksheets: select.worksheets(state, props),
});

const mapDispatchToProps = (dispatch, props) => {
  const ospSectionId = props.ospSectionId;

  return {
    onWorksheetAdd: (scope, worksheetId) => () =>
      dispatch(ospSecAction.scopeWorksheetsAdd(ospSectionId, scope, worksheetId)),
    onWorksheetRemove: (scope, worksheetId) => () =>
      dispatch(ospSecAction.scopeWorksheetsRemove(ospSectionId, scope, worksheetId)),
    onScopeWorksheetsEditDone: () => dispatch(ospSecAction.scopeWorksheetsEditDone()),
  };
};

export const CurrentBehaviorMessage = ({ numChecked, scope }) => {
  let alertStyle;
  let message;

  if (numChecked === '*') {
    alertStyle = 'success';
    message = 'All worksheets will be included for this scope.';
  } else if (numChecked === 0) {
    alertStyle = 'danger';
    message = 'The Location/Product Plans will be disabled for this scope.';
  } else {
    alertStyle = 'info';
    message = 'Selected worksheets will be included for this scope.';
  }

  return (
    <div
      className={R.join(' ', [
        `alert alert-${alertStyle}`,
        'text-center',
        'miniosp-scope-worksheets--behavior-warning',
      ])}
    >
      {message}
    </div>
  );
};

export const ScopeWorksheets = ({
  ospSection,
  worksheets,
  onWorksheetAdd,
  onWorksheetRemove,
  canEditOspDef,
  defaultScope,
  scopes,
}) => {
  if (!ospSection) return null;

  const worksheetIds = R.path(['worksheets'], ospSection);

  const scopeWorksheetsMap = R.pathOr({}, ['scopeWorksheets'], ospSection);
  const isScopeWorksheetsSet = scope => R.path([scope], scopeWorksheetsMap);
  const scopeWorksheets = R.propOr([], R.__, scopeWorksheetsMap);

  return (
    <div>
      <Tabs
        bsStyle="tabs"
        id="mini-osp-scope-tabs"
        defaultActiveKey={defaultScope}
        animation={false}
      >
        {scopes.map(scope => {
          const isWorksheetChecked = R.contains(R.__, scopeWorksheets(scope));

          let numChecked = '*';
          if (isScopeWorksheetsSet(scope)) {
            const checkedWorksheetIds = R.filter(isWorksheetChecked, worksheetIds);
            numChecked = R.length(checkedWorksheetIds);
          }

          return (
            <Tab eventKey={scope} title={`${scope} (${numChecked})`} key={scope}>
              <div className="panel">
                <div className="panel-body">
                  <CurrentBehaviorMessage numChecked={numChecked} scope={scope} />
                  <div className="list-group">
                    {worksheetIds &&
                      worksheetIds.map(worksheetId => {
                        const worksheet = R.path([worksheetId], worksheets);
                        const isChecked = isWorksheetChecked(worksheetId);
                        const handleChange = isChecked
                          ? onWorksheetRemove
                          : onWorksheetAdd;

                        return (
                          <button
                            className="list-group-item"
                            key={worksheetId}
                            onClick={handleChange(scope, worksheetId)}
                            type="button"
                            disabled={!canEditOspDef}
                          >
                            <input
                              type="checkbox"
                              checked={isChecked}
                              onChange={handleChange(scope, worksheetId)}
                              disabled={!canEditOspDef}
                            />{' '}
                            {worksheet.name}
                          </button>
                        );
                      })}
                  </div>
                </div>
              </div>
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
};

export default R.compose(
  connect(
    makeMapStateToProps,
    mapDispatchToProps,
  ),
)(ScopeWorksheets);
