import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import * as currentOrg from 'sow/selectors/currentOrg';
import * as currentUser from 'sow/selectors/currentUser';
import OrgSettingsPageTemplate from 'sow/components/templates/OrgSettingsPageTemplate';

const mapStateToProps = (state, props) => ({
  orgId: currentOrg.orgId(state, props),
  org: currentOrg.org(state, props),
  showAcaUI: currentUser.showAcaUI(state, props),
  showAdminUI: currentUser.showAdminUI(state, props),
  isInspector: currentUser.isInspector(state, props),
});

const OrgSettingsPageTemplateContainer = props => <OrgSettingsPageTemplate {...props} />;

OrgSettingsPageTemplateContainer.propTypes = {
  // Passed props
  children: PropTypes.node.isRequired,

  // Connected props
  orgId: sowTypes.orgIdType.isRequired,
  showAcaUI: PropTypes.bool.isRequired,
  showAdminUI: PropTypes.bool.isRequired,
  isInspector: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(OrgSettingsPageTemplateContainer);
