import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import ItemTypeEditContainer from 'sow/containers/ItemTypeEditContainer';

const ItemTypeEditPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <ItemTypeEditContainer />}
  </AdminPageTemplate>
);

export default ItemTypeEditPage;
