import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import AccessTypeListContainer from 'sow/containers/AccessTypeListContainer';

const AccessTypeListPage = () => (
  <AdminPageTemplate title="System Adminstrator">
    {() => <AccessTypeListContainer />}
  </AdminPageTemplate>
);

export default AccessTypeListPage;
