import { createActions } from 'redux-actions';

const actionCreators = createActions({
  SHELL: {
    CONFIRM: {
      DISMISS: () => undefined,
      HIDE: () => undefined,
      SHOW: ({ title, message, noButtonText, yesButtonText }) => ({
        title,
        message,
        noButtonText,
        yesButtonText,
      }),
      ANSWER_YES: () => undefined,
      ANSWER_NO: () => undefined,
    },
    TOAST: (type, message, validationErrors, options) => ({
      type,
      message,
      validationErrors,
      options,
    }),
  },
});

export default actionCreators.shell;
