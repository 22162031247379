import React from 'react';
import PropTypes from 'prop-types';

/** Renders a list tag */
const List = ({ children, ordered, ...props }) => {
  if (ordered) {
    return (
      <ol {...props}>
        {children}
      </ol>
    );
  }

  return (
    <ul {...props}>
      {children}
    </ul>
  );
};

List.propTypes = {
  children: PropTypes.node,

  /** Renders an `ol` tag instead of `ul` tag */
  ordered: PropTypes.bool,
};

List.defaultProps = {
  ordered: false,
};

export default List;
