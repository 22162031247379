import React from 'react';
import PropTypes from 'prop-types';

import Atom from 'sow/components/atoms/Atom';
import Tooltip from 'sow/components/molecules/Tooltip';

// If the text is greater than the specified max length:
// truncate with '...' suffix and render the full text in a tooltip
const TruncatedText = ({ text, maxLength, ...props }) => {
  if (text.length < maxLength) {
    return (
      <Atom {...props}>
        {text}
      </Atom>
    );
  }

  return (
    <Tooltip overlay={text}>
      <Atom>
        {text.substr(0, maxLength)}
        {text.length > maxLength && '...'}
      </Atom>
    </Tooltip>
  );
};

TruncatedText.propTypes = {
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
};

export default TruncatedText;
