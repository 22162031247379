import React, { Fragment } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { orgIdType, planAppIdType } from 'sow/types';
import * as currentUser from 'sow/selectors/currentUser';
import { fromPlanApp } from 'sow/store/selectors';
import { resourceUpdate } from 'sow/store/helpers';

import Button from 'sow/components/atoms/Button';
import Glyphicon from 'sow/components/molecules/Glyphicon';

const getCreateChangeResource = (orgId, planAppId, changeRequestId) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`,
    'changeRequestOverviewResp',
  );

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  showAcaUI: currentUser.showAcaUI(state, props),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  createChange: (orgId, planAppId, changeRequestId, showAcaUI) => {
    const { action } = getCreateChangeResource(orgId, planAppId, changeRequestId);
    return dispatch(
      action(null, {
        changes: [
          {
            context: 'main',
            type: 'plan',
            action: 'updated',
            application_id: planAppId,
            change_request_id: changeRequestId,
            old: {
              qualification_answers: {
                _schema: '1',
                values: ownProps.answers,
              },
            },
            new: {
              qualification_answers: {
                _schema: '1',
                values: ownProps.answers,
              },
            },
          },
        ],
      }),
    );
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onClick: () =>
    dispatchProps.createChange(
      stateProps.orgId,
      stateProps.planAppId,
      stateProps.changeRequestId,
      stateProps.showAcaUI,
    ),
});

const QualificationChecklistCreateChangeButtonContainer = ({ onClick, showAcaUI }) => (
  <Button xs warning={showAcaUI} primary={!showAcaUI} onClick={onClick}>
    {showAcaUI ? (
      <Fragment>
        <Glyphicon glyph="flag" /> Request Changes
      </Fragment>
    ) : (
      <Fragment>
        <Glyphicon glyph="pencil" /> Make Changes
      </Fragment>
    )}
  </Button>
);

QualificationChecklistCreateChangeButtonContainer.propTypes = {
  orgId: orgIdType.isRequired,
  planAppId: planAppIdType.isRequired,
  onClick: PropTypes.func.isRequired,
  showAcaUI: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
)(QualificationChecklistCreateChangeButtonContainer);
