import React, { Fragment } from 'react';

import { planAppWorksheetMatrixRowScrollRoute } from 'sow/components/atoms/ScrollTarget/scrollRoutes';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import ImprovedDataTable from 'sow/components/molecules/ImprovedDataTable';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Block from 'sow/components/atoms/Block';
import Spinner from 'sow/components/atoms/Spinner';

const ProductSearchResultsTable = ({ searchInitiated, isSearching, searchResults }) => (
  <Fragment>
    {searchInitiated && (
      <Widget className="product-results">
        <WidgetHeading>Products</WidgetHeading>
        <WidgetBody>
          {isSearching ? (
            <Spinner />
          ) : searchResults.length > 0 ? (
            <ImprovedDataTable
              selector=".productSearchResultTable"
              tableDef={{
                pageLength: 25,
                destroy: true,
                searching: false,
                data: searchResults,
                order: [],
                deferRender: true,
                columns: [
                  { data: 'status', width: '8%' },
                  { data: 'productName', width: '21%' },
                  { data: 'type', width: '8%' },
                  { data: 'certStandard', width: '16%' },
                  { data: 'orgName', width: '21%' },
                  { data: 'nopId', width: '8%' },
                  { data: 'brandName', width: '12%' },
                  { data: 'action', width: '6%' },
                ],
                stateSave: true,
                columnDefs: [
                  {
                    orderable: false,
                    targets: 7,
                    render: (data, type, row, meta) => {
                      return `<a class="org-link" href=${planAppWorksheetMatrixRowScrollRoute(
                        data[0],
                        data[1],
                        data[3],
                        data[2],
                      )}>View</a>`;
                    },
                  },
                ],
              }}
            >
              <Table
                className="productSearchResultTable"
                bordered
                striped
                condensed
                head={
                  <TableRow>
                    <TableCell heading textCenter className="statusColumn">
                      Status
                    </TableCell>
                    <TableCell heading textCenter className="nameColumn">
                      Name
                    </TableCell>
                    <TableCell heading textCenter className="typeColumn">
                      Type
                    </TableCell>
                    <TableCell heading textCenter className="certColumn">
                      Certification Standard
                    </TableCell>
                    <TableCell heading textCenter className="orgColumn">
                      Organization
                    </TableCell>
                    <TableCell heading textCenter className="nopColumn">
                      NOP ID
                    </TableCell>
                    <TableCell heading textCenter className="brandColumn">
                      Brand Name
                    </TableCell>
                    <TableCell heading textCenter className="actionColumn">
                      Action
                    </TableCell>
                  </TableRow>
                }
              >
                <TableRow></TableRow>
              </Table>
            </ImprovedDataTable>
          ) : (
            <Block className="no-results">No products found.</Block>
          )}
        </WidgetBody>
      </Widget>
    )}
  </Fragment>
);

export default ProductSearchResultsTable;
