export const userType = {
  1: 'Certification Specialist',
  2: 'Inspector',
  3: 'Client',
};

export const userSubtype = {
  1: 'Initial Reviewer',
  2: 'Final Reviewer',
  3: 'Primary Contact',
};

export const userTypeId = {
  specialist: 1,
  inspector: 2,
  client: 3,
};

export const userSubtypeId = {
  initialReviewer: 1,
  finalReviewer: 2,
  primaryContact: 3,
  annualInspector: 4,
  partialInspector: 5,
};

export const urlSubtypeId = {
  initial: userSubtypeId.initialReviewer,
  final: userSubtypeId.finalReviewer,
};

export const userTypeDropdownOptions = {
  specialist: [
    { value: 1, label: 'Initial Reviewer' },
    { value: 2, label: 'Final Reviewer' },
  ],
};
