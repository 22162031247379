import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Button from 'sow/components/atoms/Button';

class PlanAppConditionalModal extends Component {
  state = {
    showModal: this.props.showModal,
  };

  showModal = () => {
    this.setState({
      showModal: true,
    });
  };

  hideModal = response => {
    //this.setState({ showModal: false });
    this.props.onHideModal(response);
  };

  render() {
    const { showModal } = this.state;
    const { title, modalType } = this.props;

    const message =
      modalType === 'standard'
        ? 'Changing your response to this question will remove responses to the conditional questions below it. Continue anyway?'
        : 'The response to this question cannot be changed until the pending changes to its conditional questions have been cleared. Please contact a Certification Specialist for assistance.';

    return (
      <Fragment>
        {/* {children(this.showModal)} */}

        <Modal show={showModal} onHide={() => this.hideModal('cancel')} bsSize="lg">
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>{message}</Modal.Body>

          <Modal.Footer>
            {modalType === 'standard' ? (
              <Fragment>
                <Button onClick={() => this.hideModal('cancel')}>Cancel</Button>
                <Button primary onClick={() => this.hideModal('ok')}>
                  OK
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <Button primary onClick={() => this.hideModal('cancel')}>
                  OK
                </Button>
              </Fragment>
            )}
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

PlanAppConditionalModal.propTypes = {
  title: PropTypes.string.isRequired,
  //children: PropTypes.func.isRequired,
  disabled: PropTypes.bool,

  // Whether the modal should trigger an open state, based on clicks elsewhere in the app
  showModal: PropTypes.bool.isRequired,
  // Manages router state when the modal is closed so it doesn't reopen automatically
  onHideModal: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
};

PlanAppConditionalModal.defaultProps = {
  matrixRowId: null, // Is created by this component if new row
  disabled: false,
};

export default PlanAppConditionalModal;
