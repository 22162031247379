import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { ResourceDetailLoader } from 'sow/store/helpers';
import { planAppRequest } from 'sow/store/requests';
import { fromPlanApp, fromRouter } from 'sow/store/selectors';
import { orgIdType, planAppIdType, planAppChangeRequestIdType } from 'sow/types';

const mapStateToProps = (state, props) => ({
  orgId: fromRouter.paramOrgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
});

const PlanAppChangeRequestLoader = ({
  orgId,
  planAppId,
  changeRequestId, // eslint-disable-line no-unused-vars
  ...props
}) => {
  return (
    <ResourceDetailLoader
      resource={planAppRequest.changeRequestUrl(orgId, planAppId)}
      entityType="changeRequest"
      {...props}
    />
  );
};

PlanAppChangeRequestLoader.defaultProps = {
  autoLoad: false,
};

PlanAppChangeRequestLoader.propTypes = {
  orgId: orgIdType.isRequired,
  planAppId: planAppIdType.isRequired,
  changeRequestId: planAppChangeRequestIdType,
  children: PropTypes.func.isRequired,
  autoLoad: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppChangeRequestLoader);
