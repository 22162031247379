import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';

import { itemTypeRoute } from 'sow/routes';
import * as itemTypeActions from 'sow/actions/pure/itemType';

import ItemTypeEdit from 'sow/components/organisms/ItemTypeEdit';

const mapStateToProps = (state, props) => ({
  isNew: R.propEq('id', 'new')(props.match.params),
  itemTypeId: R.path(['match', 'params', 'id'], props),
});

const mapDispatchToProps = {
  fetchItemType: itemTypeActions.fetchById,
  addItemType: itemTypeActions.addItemType,
  updateItemType: itemTypeActions.updateItemType,
  deleteItemType: itemTypeActions.deleteItemType,
  redirect: routerPush,
};

const initialState = { isLoading: true, initialValues: { sort_order: 1, enabled: true } };

class ItemTypeEditContainer extends Component {
  constructor(props) {
    super(props);

    this.state = R.clone(initialState);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.isNew) {
      this.props.fetchItemType(this.props.itemTypeId, this.setInitialValues.bind(this));
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState({ id }) {
    if (this.props.isNew) {
      this.props.history.push(`/admin/system-admin/item-type/${id}`);
    }
  }

  redirectToList() {
    this.props.redirect(itemTypeRoute());
  }

  setInitialValues(data) {
    if (this._isMounted) {
      this.setState({
        isLoading: false,
        initialValues: {
          sort_order: data.sort_order,
          name: data.name,
          enabled: data.enabled,
        },
      });
    }
  }

  onSave(values, formikBag) {
    const { isNew, itemTypeId, addItemType, updateItemType } = this.props;
    if (isNew) {
      addItemType(values, this.updateState.bind(this));
    } else {
      updateItemType(itemTypeId, values);
    }
  }

  onDelete() {
    const { itemTypeId, deleteItemType } = this.props;
    deleteItemType(itemTypeId, this.redirectToList.bind(this));
  }

  render() {
    return (
      <ItemTypeEdit
        onSave={this.onSave.bind(this)}
        onDelete={this.onDelete.bind(this)}
        {...this.props}
        {...this.state}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ItemTypeEditContainer);
