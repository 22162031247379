import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';

import * as currentUser from 'sow/selectors/currentUser';
import * as select from '../selectors';
import FormPanel from '../FormPanel';
import { actions as ospQualQuestionAction } from '../reducers/ospQualificationQuestions';

const mapDispatchToProps = (dispatch, props) => {
  const ospQualificationQuestionId = props.ospQualificationQuestion.uuid;
  return {
    onAddWorksheet: R.curry((worksheetId, event) => {
      dispatch(
        ospQualQuestionAction.worksheetAdd(ospQualificationQuestionId, worksheetId),
      );
    }),
    onRemoveWorksheet: R.curry((worksheetId, event) => {
      dispatch(
        ospQualQuestionAction.worksheetRemove(ospQualificationQuestionId, worksheetId),
      );
    }),
  };
};

const mapStateToProps = (state, props) => ({
  ospSections: select.ospSections(state, props),
  worksheets: select.worksheets(state, props),
  canEditOspDef: currentUser.canEditOspDef(state, props),
});

// TODO (orther) have this type of data scrubbing done before it is in component
const stripNil = R.reject(R.isNil);

export const Worksheets = ({
  ospSectionIds,
  ospSections,
  worksheets,
  ospQualificationQuestion,
  onAddWorksheet,
  onRemoveWorksheet,
  // onAddQuestion, onDeleteQuestion,
  // onMoveQuestionDown, onMoveQuestionUp,
  canEditOspDef,
  ...props
}) => {
  const isQualifiedWorksheet = R.contains(R.__, ospQualificationQuestion.worksheets);
  return (
    <div>
      <h3>Worksheets</h3>
      {ospSectionIds &&
        stripNil(ospSectionIds).map(ospSectionId => {
          const ospSection = R.path([ospSectionId], ospSections);
          const worksheetIds = R.path(['worksheets'], ospSection);

          return (
            <FormPanel key={`ospQualificationQuestion-ospSection-${ospSectionId}`}>
              <FormPanel.Heading>{ospSection.name}</FormPanel.Heading>
              <ul className="list-group">
                {worksheetIds &&
                  worksheetIds.map(worksheetId => {
                    const worksheet = R.path([worksheetId], worksheets);
                    const isChecked = isQualifiedWorksheet(worksheetId);
                    const handleChange = isChecked ? onRemoveWorksheet : onAddWorksheet;
                    return (
                      <li className="list-group-item" key={worksheetId}>
                        <label>
                          <input
                            type="checkbox"
                            value={worksheetId}
                            checked={isChecked}
                            onChange={handleChange(worksheetId)}
                            disabled={!canEditOspDef}
                          />{' '}
                          {worksheet.name}
                        </label>
                      </li>
                    );
                  })}
              </ul>
            </FormPanel>
          );
        })}
    </div>
  );
};

export default R.compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Worksheets);
