import React from 'react';

import FormGroup from 'sow/components/organisms/FormGroup';

export default ({ attachment, errors, handleChange }) =>
  <div>
    <FormGroup fieldName="name" errors={errors} label="Name" required>
      <input
        type="text"
        id="name"
        value={attachment.name}
        onChange={e => handleChange('name', e.target.value)}
        className="form-control"
        required
      />
    </FormGroup>
  </div>;
