import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as adminRequestActions from 'sow/actions/adminRequest';
import * as sowTypes from 'sow/types';
import * as currentOrg from 'sow/selectors/currentOrg';
import { fromPlanApp } from 'sow/store/selectors';
import OrgPlanAppLocationOverviewPage from 'sow/components/pages/OrgPlanAppLocationOverviewPage';
import {
  PlanAppLoader,
  PlanAppLocationListLoader,
  PlanAppChangeRequestLoader,
  PlanAppChangeLoader,
} from 'sow/store/containers';
import { ResourceDetailLoader } from 'sow/store/helpers';
import ResourceLoaderStatusRender from 'sow/components/molecules/ResourceLoaderStatusRender';
import OrgPlanAppPageTemplate from 'sow/containers/OrgPlanAppPageTemplate';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  locationIdList: fromPlanApp.planAppLocationIdList(state, props),
  minNumLocations: currentOrg.configPlanMinNumLocations(state, props),
  isStateUnderReview: fromPlanApp.isStateUnderReview(state, props),
  locationRequests: state.adminRequests.locationRequests,
  requestsFetching: state.adminRequests.locationRequests.isFetching,
  requestsLoaded: state.adminRequests.locationRequests.isLoaded,
});

const mapDispatchToProps = {
  fetchLocationRequests: adminRequestActions.fetchLocationRequests,
};

const OrgPlanAppLocationOverviewPageContainer = ({
  orgId,
  planAppId,
  changeRequestId,
  locationIdList,
  minNumLocations,
  fetchLocationRequests,
  isStateUnderReview,
  locationRequests,
  requestsFetching,
  requestsLoaded,
}) => {

  const loadLocationRequests = () => {
    if ((!requestsLoaded && !requestsFetching) || (requestsLoaded && locationRequests.planAppId != planAppId)) {
      return true;
    } else {
      return false;
    }
  }
  
  const loadRequests = loadLocationRequests();

  return (
    <OrgPlanAppPageTemplate title="Locations">
      <PlanAppLoader orgId={orgId} planAppId={planAppId} autoLoad>
        {({ status: paStatus }) => (
          <PlanAppLocationListLoader autoLoad>
            {({ status: locListStatus }) => (
              <ResourceDetailLoader
                resource={`org/${orgId}/application/${planAppId}/land_answers`}
                entityType="locationAnswers"
                autoLoad
              >
                {({ status: locAnswersStatus }) => (
                  <ResourceLoaderStatusRender
                    statusObjects={[paStatus, locListStatus, locAnswersStatus]}
                    renderAllSuccess={() => (
                      <PlanAppChangeRequestLoader autoLoad>
                        {({ status: crStatus }) => (
                          <ResourceLoaderStatusRender
                            statusObjects={[crStatus]}
                            renderAllDone={() => (
                              <PlanAppChangeLoader
                                requestParams={{ contexts: ['land'] }}
                                autoLoad={!R.isNil(changeRequestId)}
                              >
                                {({ status: changesStatus }) => {
                                  const areChangesLoaded = changeRequestId && !changesStatus.initial;
                                  if (areChangesLoaded && loadRequests && !requestsLoaded) {
                                    fetchLocationRequests(orgId, planAppId);
                                  }
                                  return (
                                    <ResourceLoaderStatusRender
                                      statusObjects={[
                                        areChangesLoaded
                                          ? changesStatus
                                          : {
                                              ...changesStatus,
                                              initial: false,
                                              done: true,
                                            },
                                      ]}
                                      renderAllDone={() => (
                                        <OrgPlanAppLocationOverviewPage
                                          orgId={orgId}
                                          planAppId={planAppId}
                                          locationIdList={locationIdList}
                                          minNumLocations={minNumLocations}
                                          isStateUnderReview={isStateUnderReview}
                                        />
                                      )}
                                    />
                                  );
                                }}
                              </PlanAppChangeLoader>
                            )}
                          />
                        )}
                      </PlanAppChangeRequestLoader>
                    )}
                  />
                )}
              </ResourceDetailLoader>
            )}
          </PlanAppLocationListLoader>
        )}
      </PlanAppLoader>
    </OrgPlanAppPageTemplate>
  );
};

OrgPlanAppLocationOverviewPageContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  changeRequestId: sowTypes.planAppChangeRequestIdType,
  locationIdList: PropTypes.arrayOf(sowTypes.planAppLocationIdType).isRequired,
  minNumLocations: PropTypes.number.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrgPlanAppLocationOverviewPageContainer);
