import * as R from 'ramda';

const initialState = {};

export default function sample(state = initialState, { type, payload }) {
  switch (type) {
    case 'SAMPLE/GET_ALL_BY_ORG_DONE':
      return { list: [...payload] };

    case 'SAMPLE/GET_OPTIONS_DONE':
      return {
        ...state,
        sampleTypeOptions: payload.sample_type_options,
        sampleStateOptions: payload.sample_state_options,
      };
    default:
      return state;
  }
}
