import {
  LOAD_ALL_FOR_ACA_REQUEST,
  LOAD_ALL_FOR_ACA_SUCCESS,
  LOAD_ALL_FOR_ACA_FAILURE,
} from 'sow/actions/ospDefinition';

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case LOAD_ALL_FOR_ACA_REQUEST:
    case LOAD_ALL_FOR_ACA_SUCCESS:
      return null;
    case LOAD_ALL_FOR_ACA_FAILURE:
      // TODO (orther) implement standard error message
      return 'ADD REAL ERROR MESSAGE!!!!';
    default:
      return state;
  }
};

export default errorMessage;
