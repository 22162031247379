import { create } from 'apisauce';

import { apiUrl } from 'sow/config';

import {
  requestAuthToken,
  responseCheckStatus,
  responseUnnestData,
} from './transformers';

export function createApi(settings = {}) {
  const api = create({
    baseURL: apiUrl,
    ...settings,
  });

  // TODO replace this when this issue solved https://github.com/infinitered/apisauce/issues/139
  api.axiosInstance.defaults.withCredentials = true;

  // request transforms
  api.addRequestTransform(requestAuthToken);

  // response transforms
  api.addResponseTransform(responseCheckStatus);
  api.addResponseTransform(responseUnnestData);

  return api;
}
