import { createActions } from 'redux-actions';

const actionCreators = createActions({
  AUTH: {
    ECERT_LOGIN: credentials => ({ credentials }),
    ECERT_LOGIN_DONE: respData => respData,
    ECERT_LOGIN_FAIL: error => ({ error }),

    LOGIN: credentials => ({ credentials }),
    LOGIN_DONE: respData => respData,
    LOGIN_FAIL: error => error,

    LOGOUT: () => undefined,
    LOGOUT_DONE: () => undefined,
    LOGOUT_FAIL: () => undefined,

    INACTIVE: () => undefined,

    VALIDATE_TOKEN: () => undefined,
    VALIDATE_TOKEN_DONE: respData => respData,
    VALIDATE_TOKEN_FAIL: error => error,

    IMPERSONATE_USER: payload => payload,
    IMPERSONATE_USER_DONE: respData => respData,

    END_IMPERSONATION: userId => userId,
    END_IMPERSONATION_DONE: respData => respData,
  },
});

export default actionCreators.auth;
