import React, { Fragment } from 'react';
import * as R from 'ramda';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';

import { formattedDate } from 'sow/utils/dateTime';

import Block from 'sow/components/atoms/Block';
import Strong from 'sow/components/atoms/Strong';
import FormikField from 'sow/components/organisms/FormikField';
import FormikReactSelect from 'sow/components/molecules/FormikReactSelect';
import Spacer from 'sow/components/atoms/Spacer';
import Button from 'sow/components/atoms/Button';

const ManageInspectionModal = ({
  show,
  toggleModal,
  inspection,
  annual,
  isNew,
  selectOptions,
  createPartial,
  editInspection,
  initialValues,
  toggleConfirm,
}) => (
  <Modal backdrop="static" show={show} onHide={toggleModal}>
    <Modal.Header closeButton>
      <Modal.Title>{isNew ? 'New' : 'Edit'} Inspection</Modal.Title>
    </Modal.Header>
    <Formik
      initialValues={initialValues && initialValues}
      onSubmit={isNew ? createPartial : values => editInspection(initialValues, values)}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            {!isNew && (
              <Fragment>
                {!annual && (
                  <Block>
                    <Strong>Inspection Status: </Strong>
                    {inspection.inspectionState}
                  </Block>
                )}
                <Block>
                  <Strong>Inspection Type: </Strong>
                  {`${inspection.inspectionSubType} ${inspection.inspectionType}`}
                </Block>
                <Block>
                  <Strong>Inspection Date: </Strong>
                  {inspection.inspectionDate
                    ? formattedDate(inspection.inspectionDate)
                    : 'Not Set'}
                </Block>
                <Spacer vertical={15} />
              </Fragment>
            )}
            <Strong>Inspection Type:</Strong>
            <Field
              placeholder=""
              name={'inspection_sub_type_id'}
              component={FormikField}
              type={FormikReactSelect}
              clearable={false}
              options={selectOptions.inspectionTypeOptions}
            />
            <Strong>Inspection Year:</Strong>
            <Field
              placeholder=""
              name={'inspection_year'}
              component={FormikField}
              type={FormikReactSelect}
              clearable={false}
              options={selectOptions.inspectionYearOptions}
            />
            <Strong>Inspectors:</Strong>
            <Field
              placeholder=""
              name="inspector_id"
              component={FormikField}
              type={FormikReactSelect}
              multi={true}
              options={selectOptions.inspectorList.map(inspector => ({
                value: inspector.id,
                label: inspector.name,
              }))}
            />
            <Strong>Sample:</Strong>
            <Field
              placeholder=""
              name="sample"
              multi={true}
              component={FormikField}
              type={FormikReactSelect}
              options={selectOptions.sampleTypeOptions}
            />
          </Modal.Body>
          <Modal.Footer>
            {annual || isNew ? (
              <Button disabled={isSubmitting} onClick={toggleModal}>
                Cancel
              </Button>
            ) : (
              <Button
                disabled={isSubmitting}
                onClick={() => {
                  toggleModal();
                  toggleConfirm();
                }}
              >
                Delete
              </Button>
            )}
            <Button
              type="submit"
              primary
              disabled={
                (R.isNil(values.unannounced_inspection) &&
                  R.isNil(values.inspection_sub_type_id)) ||
                R.isNil(values.inspection_year) ||
                isSubmitting
              }
            >
              {isNew ? 'Create' : 'Save'}
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  </Modal>
);

export default ManageInspectionModal;
