import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as sowTypes from 'sow/types';
import { ResourceListLoader } from 'sow/store/helpers';
import { fromPlanApp } from 'sow/store/selectors';
import { planAppRequest } from 'sow/store/requests';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
});

const PlanAppLocationListLoader = ({ orgId, planAppId, ...props }) => (
  <ResourceListLoader
    {...props}
    resource={planAppRequest.locationListUrl(orgId, planAppId)}
    entityType="planAppLocation"
  />
);

PlanAppLocationListLoader.propTypes = {
  // Connected props
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppLocationListLoader);
