import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import {
  fetchDashboardList,
  fetchDashboardCounts,
  updatePlanState,
} from 'sow/actions/orgWorkflow';
import { inspectionStates } from 'sow/constants/inspectionConstants';
import { planAppStates } from 'sow/constants/planApp';
import { setFilter, getFilter } from 'sow/utils/dashboardFilterStorage';
import actions from 'sow/actions/pure';
import InspectorDashboard from 'sow/components/organisms/InspectorDashboard';

const mapStateToProps = state => ({
  isDashboardFetching: state.dashboard.isFetching,
  isFetchingCounts: state.dashboard.isFetchingCounts,
  dashboardList: state.dashboard.items,
  dashboardCounts: state.dashboard.counts,
  acaId: () => {
    if (state.auth.metadata.orgs_v2[0]) {
      return state.auth.metadata.orgs_v2[0].aca_id;
    }
  },
});

const mapDispatchToProps = {
  fetchDashboardList,
  fetchDashboardCounts,
  updatePlanState,
  changeInspectionDate: actions.ospApp.changeInspectionDate,
  updateInspection: actions.inspection.update,
};

class InspectorDashboardPageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inspectionSelect: 0,
      inspectionDate: '',
    };
  }

  componentDidMount() {
    this.loadDashboard();
  }

  loadDashboard() {
    if (this.props.acaId()) {
      this.props.fetchDashboardList(this.props.acaId(), getFilter());
      this.props.fetchDashboardCounts(this.props.acaId());
    }
  }

  handleFilterChange(filter) {
    setFilter(filter);
    this.loadDashboard();
    if (!this.props.acaId()) {
      window.location.reload();
    }
  }

  handleSelectChange({
    orgId,
    orgState,
    planAppId,
    value,
    partialInspection,
    inspectionId,
  }) {
    this.setState({
      orgId,
      orgState,
      planAppId,
      partialInspection,
      inspectionId,
      inspectionSelect: value,
    });
  }

  resetState() {
    this.setState({
      inspectionSelect: 0,
      planAppId: 0,
      orgState: '',
      inspectionDate: 'Select Inspection Date',
      inspectionId: 0,
      partialInspection: false,
    });
  }

  handleRemoveInspectionDate(org) {
    const { organization_id, app_id, partial_inspection, inspection_id } = org;

    this.props.updateInspection(
      {
        orgId: organization_id,
        appId: app_id,
        inspectionId: inspection_id,
      },
      {
        inspection_date: null,
        inspection_state_id: inspectionStates.readyForInspection,
      },
      partial_inspection
        ? this.loadDashboard.bind(this)
        : this.changePlanState.bind(this),
    );

    this.resetState();
  }

  handleAssign() {
    const {
      inspectionId,
      orgId,
      planAppId,
      inspectionDate,
      partialInspection,
    } = this.state;
    if (!moment(inspectionDate).isValid()) {
      this.setState({
        fieldErrors: {
          dateSelect: ['Date is invalid. Please use MM/DD/YYYY ex. (04/05/2020)'],
        },
      });
    } else {
      const formattedInspectionDate = moment(inspectionDate).format('YYYY-MM-DD');

      this.props.updateInspection(
        {
          orgId: orgId,
          appId: planAppId,
          inspectionId,
        },
        {
          inspection_date: formattedInspectionDate,
          inspection_state_id: inspectionStates.inInspection,
        },
        partialInspection
          ? this.loadDashboard.bind(this)
          : this.changePlanState.bind(this),
      );

      this.resetState();
    }
  }

  handleDateChange(date) {
    if (typeof date === 'object') {
      this.setState({ inspectionDate: date, fieldErrors: {} });
    } else {
      this.setState({ inspectionDate: '' });
    }
  }

  changePlanState({ params, responseData }) {
    const { orgId, appId, inspectionId } = params;
    const targetInspection = R.find(R.propEq('id', inspectionId), responseData);

    let newPlanState = '';
    switch (targetInspection.inspection_state_id) {
      case inspectionStates.inInspection:
        newPlanState = planAppStates.IN_INSPECTION;
        break;
      case inspectionStates.readyForFinalReview:
        newPlanState = planAppStates.FINAL_REVIEW;
        break;
      case inspectionStates.readyForInspection:
      default:
        newPlanState = planAppStates.INSPECTION;
        break;
    }

    this.props.updatePlanState(orgId, appId, newPlanState, this.loadDashboard.bind(this));
  }

  getInspectionAge(numOfDays) {
    switch (numOfDays) {
      case 0:
      case null:
        return '';
      case 1:
        return '1 day old';
      default:
        return `${numOfDays} days old`;
    }
  }

  render() {
    const { loadOrgs, ...componentProps } = this.props;
    return (
      <InspectorDashboard
        {...componentProps}
        handleSelectChange={this.handleSelectChange.bind(this)}
        handleFilterChange={this.handleFilterChange.bind(this)}
        handleAssign={this.handleAssign.bind(this)}
        handleRemoveInspectionDate={this.handleRemoveInspectionDate.bind(this)}
        handleDateChange={this.handleDateChange.bind(this)}
        getInspectionAge={this.getInspectionAge.bind(this)}
        filter={getFilter()}
        state={this.state}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InspectorDashboardPageContainer);
