import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Block from 'sow/components/atoms/Block';

const Column = ({
  children,
  className,
  xs,
  xsOffset,
  xsPull,
  xsPush,
  sm,
  smOffset,
  smPull,
  smPush,
  md,
  mdOffset,
  mdPull,
  mdPush,
  lg,
  lgOffset,
  lgPull,
  lgPush,
  ...props
}) => {
  const classes = classNames(className, {
    [`col-xs-${xs}`]: xs,
    [`col-xs-offset-${xsOffset}`]: xsOffset,
    [`col-xs-pull-${xsPull}`]: xsPull,
    [`col-xs-push-${xsPush}`]: xsPush,

    [`col-sm-${sm}`]: sm,
    [`col-sm-offset-${smOffset}`]: smOffset,
    [`col-sm-pull-${smPull}`]: smPull,
    [`col-sm-push-${smPush}`]: smPush,

    [`col-md-${md}`]: md,
    [`col-md-offset-${mdOffset}`]: mdOffset,
    [`col-md-pull-${mdPull}`]: mdPull,
    [`col-md-push-${mdPush}`]: mdPush,

    [`col-lg-${lg}`]: lg,
    [`col-lg-offset-${lgOffset}`]: lgOffset,
    [`col-lg-pull-${lgPull}`]: lgPull,
    [`col-lg-push-${lgPush}`]: lgPush,
  });

  return (
    <Block className={classes} {...props}>
      {children}
    </Block>
  );
};

Column.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,

  xs: PropTypes.number,
  xsOffset: PropTypes.number,
  xsPull: PropTypes.number,
  xsPush: PropTypes.number,

  sm: PropTypes.number,
  smOffset: PropTypes.number,
  smPull: PropTypes.number,
  smPush: PropTypes.number,

  md: PropTypes.number,
  mdOffset: PropTypes.number,
  mdPull: PropTypes.number,
  mdPush: PropTypes.number,

  lg: PropTypes.number,
  lgOffset: PropTypes.number,
  lgPull: PropTypes.number,
  lgPush: PropTypes.number,
};

export default Column;
