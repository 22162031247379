import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { planAppRequest } from 'sow/store/requests';
import { fromRouter } from 'sow/store/selectors';
import { ResourceListLoader } from 'sow/store/helpers';
import { orgIdType } from 'sow/types';

const mapStateToProps = (state, props) => ({
  orgId: fromRouter.paramOrgId(state, props),
});

const PlanAppSummaryLoader = ({ orgId, ...props }) => (
  <ResourceListLoader
    {...props}
    resource={planAppRequest.planAppSummaryUrl(orgId)}
    entityType="planAppSummary"
  />
);

PlanAppSummaryLoader.defaultProps = {
  children: () => null,
};

PlanAppSummaryLoader.propTypes = {
  orgId: orgIdType.isRequired,
  children: PropTypes.func.isRequired,
  autoLoad: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppSummaryLoader);
