import bugsnag from '@bugsnag/js';
import { isDeployLocalDev } from 'sow/config';

const bugsnagConfig = window.bugsnagConfig;

if (isDeployLocalDev) {
  // stop bugsnag from messing up console file line number
  bugsnagConfig.consoleBreadcrumbsEnabled = false;
}

export const bugsnagClient = bugsnag(bugsnagConfig);
