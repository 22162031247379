import React, { Fragment, useState } from 'react';

import { locationOverviewRoute } from 'sow/routes';
import Spinner from 'sow/components/atoms/Spinner';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Paragraph from 'sow/components/atoms/Paragraph';
import Strong from 'sow/components/atoms/Strong';
import Clearfix from 'sow/components/atoms/Clearfix';
import OrgLocationsMap from 'sow/components/organisms/OrgLocationsMap';

const OrgLocations = ({ isFetchingLocations, locationList, orgId, appId }) => {
  const [locationCoords, setLocationCoords] = useState({});

  if (isFetchingLocations) {
    return <Spinner />;
  }

  if (locationList.length < 1) {
    return (
      <Widget className="org-locations-page">
        <WidgetBody className="no-locations-widget">
          <Strong>No locations have been added to this plan.</Strong>
        </WidgetBody>
      </Widget>
    );
  }
  return (
    <Block className="org-locations-page">
      <Row className="manage-location-block">
        <Paragraph className="location-heading">Locations</Paragraph>
        <Link
          btn
          success
          className="manage-location-link"
          to={locationOverviewRoute(orgId, appId)}
        >
          <FontAwesome icon="map-marker-alt" /> Manage Locations
        </Link>
        <Clearfix />
      </Row>
      <Row>
        <Column md={4}>
          <Block className="location-list">
            {locationList.map(location => (
              <Fragment key={location.id}>
                <Widget
                  className="location-card"
                  onClick={() => {
                    setLocationCoords({ ...location.geom });
                  }}
                >
                  <WidgetBody>
                    <Row>
                      <Column xs={2} sm={2} md={2} lg={2}>
                        <FontAwesome className="label-circle" icon="circle" />
                        <Block className="number-block">
                          <Paragraph className="label-number">
                            {location.location_index}
                          </Paragraph>
                        </Block>
                      </Column>
                      <Column xs={10} sm={10} md={10} lg={10} className="location-data">
                        <Paragraph>
                          <Strong>{location.name}</Strong>
                        </Paragraph>
                        {location.location.address ? (
                          <Fragment>
                            <Paragraph>{location.location.address}</Paragraph>
                            <Paragraph>
                              {location.location.city}, {location.location.state}{' '}
                              {location.location.postal}
                            </Paragraph>
                          </Fragment>
                        ) : (
                          <Paragraph>{location.location.full_address}</Paragraph>
                        )}
                      </Column>
                    </Row>
                  </WidgetBody>
                </Widget>
              </Fragment>
            ))}
          </Block>
        </Column>
        <Column md={8}>
          <OrgLocationsMap
            orgId={orgId}
            locationCoords={locationCoords}
            locationList={locationList}
            containerElement={<div className="org-map-container" />}
            mapElement={<div className="org-map-element" />}
          />
        </Column>
      </Row>
    </Block>
  );
};

export default OrgLocations;
