import React from 'react';
import * as R from 'ramda';
import { MenuItem, Glyphicon } from 'react-bootstrap';
import { connect } from 'react-redux';

import { confirm } from 'sow/actions/pure/messaging';
import FormGroup from 'sow/components/organisms/FormGroup';
import FormModal from 'sow/components/old/FormModal';
import OspAppEditorPreviewWorksheet from 'sow/components/old/OspApp/Editor/Preview/Worksheet';
import { hocEditable, hocPreviewable } from 'sow/components/old/hocHelpers/uiState';

import * as select from '../selectors';
import BooleanCheckbox from '../FormFields/BooleanCheckbox';
import FormPanel from '../FormPanel';
import WorksheetQuestions from './WorksheetQuestions';
import WorksheetRegulations from './WorksheetRegulations';
import { actions as ospSecAction } from '../reducers/ospSections';
import { actions as wsAction } from '../reducers/worksheets';
import { answersUntagger } from '../reducers/worksheetQuestions';

// const makeMapStateToProps = () => (state, props) => ({
//   worksheet: select.makeWorksheetSelector()(state, props),
// });

const makeMapStateToProps = () => (state, props) => ({
  questions: select.worksheetQuestions(state, props),
});

const mapDispatchToProps = (dispatch, { ospSectionId, worksheetId }) => {
  return {
    // worksheet events
    onDelete: event =>
      dispatch(
        confirm({
          message: 'Are you sure you want to delete this worksheet?',
          onConfirm: () =>
            dispatch(ospSecAction.worksheetRemove(ospSectionId, worksheetId)),
        }),
      ),
    onSetValue: R.curry((field, event) => {
      dispatch(wsAction.setValue(worksheetId, field, event.target.value));
    }),
    onSetStaticValue: R.curry((field, value, event) => {
      dispatch(wsAction.setValue(worksheetId, field, value));
    }),

    // regulation event handlers
    onAddRegulation: R.curry((worksheetId, event) => {
      dispatch(wsAction.regulationAdd(worksheetId));
    }),
    onDeleteRegulation: R.curry((worksheetId, regulationId, event) =>
      dispatch(
        confirm({
          message: 'Are you sure you want to delete this worksheet regulation?',
          onConfirm: () => dispatch(wsAction.regulationRemove(worksheetId, regulationId)),
        }),
      ),
    ),
    onMoveRegulationDown: R.curry((worksheetId, regulationId, event) => {
      dispatch(wsAction.regulationMoveDown(worksheetId, regulationId));
    }),
    onMoveRegulationUp: R.curry((worksheetId, regulationId, event) => {
      dispatch(wsAction.regulationMoveUp(worksheetId, regulationId));
    }),
    onSetRegulationValue: R.curry((worksheetId, regulationId, field, event) => {
      dispatch(
        wsAction.regulationSetValue(worksheetId, regulationId, field, event.target.value),
      );
    }),

    // question event handlers
    onAddQuestion: R.curry((worksheetId, event) => {
      dispatch(wsAction.questionAdd(worksheetId));
    }),
    onDeleteQuestion: R.curry((worksheetId, questionId, event) =>
      dispatch(
        confirm({
          message: 'Are you sure you want to delete this worksheet question?',
          onConfirm: () => dispatch(wsAction.questionRemove(worksheetId, questionId)),
        }),
      ),
    ),
    onMoveQuestionDown: R.curry((worksheetId, questionId, event) => {
      dispatch(wsAction.questionMoveDown(worksheetId, questionId));
    }),
    onMoveQuestionUp: R.curry((worksheetId, questionId, event) => {
      dispatch(wsAction.questionMoveUp(worksheetId, questionId));
    }),
  };
};

export const PreviewWorksheet = ({
  worksheet,
  questions,
  //   worksheet, worksheetId,
  //   onDelete, onSetValue, onSetStaticValue,
  //   onMoveWorksheetDown, onMoveWorksheetUp,
  //
  //   // question event handlers
  //   onAddQuestion, onDeleteQuestion,
  //   onMoveQuestionDown, onMoveQuestionUp,
  //
  //   // regulation event handlers
  //   onAddRegulation, onDeleteRegulation, onSetRegulationValue,
  //   onMoveRegulationDown, onMoveRegulationUp,
  //
  //   isEditing, onEditDone, onEditStart,
  //   isPreviewing, onPreviewDone, onPreviewStart,
}) => {
  if (!worksheet) return null;
  // const questionIds = R.path(['questions'], worksheet);
  // const regulations = R.pathOr([], ['regulations'], worksheet);
  // {worksheet.name}

  return (
    <OspAppEditorPreviewWorksheet
      worksheet={worksheet}
      questions={answersUntagger(questions)}
    />
  );
};

export default R.compose(
  connect(
    makeMapStateToProps,
    mapDispatchToProps,
  ),
)(PreviewWorksheet);
