import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as acaActions from 'sow/actions/aca';
import * as acaSelect from 'sow/selectors/core/aca';
import * as orgSelect from 'sow/selectors/core/organization';
import AdminPageTemplate from 'sow/components/templates/AdminPageTemplate';

const mapStateToProps = (state, props) => ({
  isLoadingAcas: acaSelect.isLoading(state, props),
  isLoadingOrgs: orgSelect.isLoadingList(state, props),
  acas: acaSelect.acaList(state, props),
});

const mapDispatchToProps = {
  loadAcas: acaActions.loadAllRegister,
};

class AdminPageTemplateContainer extends Component {
  componentDidMount() {
    this.props.loadAcas();
  }

  render() {
    return <AdminPageTemplate {...this.props} />;
  }
}

AdminPageTemplateContainer.propTypes = {
  // Passed props
  children: PropTypes.func.isRequired,

  // Connected props
  loadAcas: PropTypes.func.isRequired,
  acas: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoadingAcas: PropTypes.bool.isRequired,
  isLoadingOrgs: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AdminPageTemplateContainer);
