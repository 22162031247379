import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';

import { accessTypeRoute } from 'sow/routes';
import * as accessTypeActions from 'sow/actions/pure/accessType';

import AccessTypeEdit from 'sow/components/organisms/AccessTypeEdit';

const mapDispatchToProps = {
  loadAccessTypeById: accessTypeActions.loadAccessTypeById,
  addAccessType: accessTypeActions.addAccessType,
  updateAccessType: accessTypeActions.updateAccessType,
  deleteAccessType: accessTypeActions.deleteAccessType,
  redirect: routerPush,
};

const initialValues = {
  name: '',
};

class AccessTypeEditContainer extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = { isNew: false, isLoading: true, initialValues: R.clone(initialValues) };
  }

  componentDidMount() {
    this._isMounted = true;
    const routeId = R.path(['match', 'params', 'id'], this.props);

    if (routeId !== 'new') {
      this.props.loadAccessTypeById(routeId, this.updateState.bind(this));
    } else {
      this.setState({ isNew: true, isLoading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState({ data }) {
    if (this.state.isNew) {
      history.pushState(null, null, `${data.id}`);
    }
    if (this._isMounted) {
      if (!R.isEmpty(data)) {
        this.setState({
          isNew: false,
          isLoading: false,
          accessTypeId: data.id,
          initialValues: { ...data },
        });
      } else {
        this.redirectToList();
      }
    }
  }

  redirectToList() {
    this.props.redirect(accessTypeRoute());
  }

  handleSave(values, formikBag) {
    if (this.state.isNew) {
      this.props.addAccessType(values, this.updateState.bind(this));
    } else {
      this.props.updateAccessType(
        this.state.accessTypeId,
        values,
        this.updateState.bind(this),
      );
    }
  }

  handleDelete() {
    this.props.deleteAccessType(this.state.accessTypeId, this.redirectToList.bind(this));
  }

  render() {
    return (
      <AccessTypeEdit
        handleSave={this.handleSave.bind(this)}
        handleDelete={this.handleDelete.bind(this)}
        {...this.state}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(AccessTypeEditContainer);
