import * as R from 'ramda';

export const rootQuestions = R.path(['data', 'entities', 'ospQualificationQuestions']);

export const subQuestions = R.path([
  'data',
  'entities',
  'ospQualificationQuestionSubQuestions',
]);

export const allQuestions = R.converge(R.merge, [rootQuestions, subQuestions]);

export const allQuestionIds = R.compose(R.keys, allQuestions);

export const rootQuestionIds = R.compose(R.keys, rootQuestions);

export const subQuestionIds = R.compose(R.keys, subQuestions);
