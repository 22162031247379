import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { ResourceDetailLoader } from 'sow/store/helpers';
import { fromPlanApp, fromRouter } from 'sow/store/selectors';
import { orgIdType, planAppIdType, worksheetIdType } from 'sow/types';

const mapStateToProps = (state, props) => ({
  orgId: fromRouter.paramOrgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  worksheetId: fromPlanApp.worksheetId(state, props),
});

const PlanAppWorksheetAnswersLoader = ({ orgId, planAppId, worksheetId, ...props }) => {
  const entityType = worksheetId
    ? // loading a single Worksheet's answer returns worksheetAnswer object
      'worksheetAnswer'
    : // loading entire PlanApp's answer returns worksheetAnswer object
      'worksheetAnswerMap';

  return (
    <ResourceDetailLoader
      resource={`org/${orgId}/application/${planAppId}/worksheet_answers`}
      resourceId={worksheetId}
      entityType={entityType}
      autoCaseKeys={false}
      {...props}
    />
  );
};

PlanAppWorksheetAnswersLoader.defaultProps = {
  autoLoad: false,
};

PlanAppWorksheetAnswersLoader.propTypes = {
  orgId: orgIdType.isRequired,
  planAppId: planAppIdType.isRequired,
  worksheetId: worksheetIdType,
  children: PropTypes.func.isRequired,
  autoLoad: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppWorksheetAnswersLoader);
