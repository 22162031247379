const orgSearch = (state = { searchResults: [] }, { type, payload }) => {
  switch (type) {
    case 'ORG/ORG_SEARCH':
      return {
        ...state,
        isSearching: true,
        searchInitiated: true,
        savedSearch: payload.savedSearch,
      };
    case 'ORG/ORG_SEARCH_DONE':
      return {
        ...state,
        isSearching: false,
        searchResults: payload,
      };
    case 'ORG/ORG_SEARCH_CLEAR':
      return { ...state, searchInitiated: false, searchResults: [], savedSearch: {} };
    default:
      return state;
  }
};

export default orgSearch;
