import React, { Component, Fragment } from 'react';
// Need to install dependencies before uncommenting next two lines.
// import $ from 'jquery';
// import 'datatables.net-dt';

const waitForEl = (selector, quantity, callback, tableDef) => {
  if (jQuery(selector).length >= quantity) {
    callback(selector, tableDef);
  }
};

class DataTable extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.loadTable();
    if ($.fn.DataTable.isDataTable(`${this.props.selector}`)) {
      const table = $(`${this.props.selector}`).DataTable();
      table.state.clear();
      if (this.props.pageNumber) {
        table.page(this.props.pageNumber).draw('page');
        this.getPageNumber();
      }
    }
  }

  componentDidUpdate() {
    this.loadTable();
    if ($.fn.DataTable.isDataTable(`${this.props.selector}`)) {
      const table = $(`${this.props.selector}`).DataTable();
      if (this.props.pageNumber) {
        table.page(this.props.pageNumber).draw('page');
        this.getPageNumber();
      }
    }
  }

  componentWillUnmount() {
    if ($.fn.DataTable.isDataTable(`${this.props.selector}`)) {
      const table = $(`${this.props.selector}`).DataTable();
      table.clear();
      table.destroy(false);
    }
  }

  loadTable() {
    waitForEl(
      `${this.props.selector}`,
      1,
      function(selector, tableDef) {
        if (
          $(`${selector} tr`).length > 2 &&
          !$.fn.DataTable.isDataTable(`${selector}`)
        ) {
          $(`${selector}`).DataTable(tableDef);
        }
      },
      this.props.tableDef,
    );
    if (this.props.getTablePage) {
      $(`${this.props.selector}`)
        .off()
        .on('page.dt', this.getPageNumber.bind(this));
    }
  }

  getPageNumber() {
    const table = $(`${this.props.selector}`).DataTable();
    this.props.getTablePage(table.page.info().page);
  }

  render() {
    if ($.fn.DataTable.isDataTable(`${this.props.selector}`)) {
      const table = $(`${this.props.selector}`).DataTable();
      table.destroy();
    }

    return <Fragment>{this.props.children}</Fragment>;
  }
}

export default DataTable;
