import * as R from 'ramda';
import { makeImmutable } from 'sow/utils/data';

const byId = (state = makeImmutable({}), action) => {
  if (R.path(['data', 'entities', 'worksheetQuestions'], action)) {
    return state.merge(action.data.entities.worksheetQuestions);
  }

  return state;
};

export default byId;

export const getIds = state => Object.keys(state);
export const getWorksheetQuestion = (state, id) => state[id];
export const getWorksheetQuestionList = state => getIds(state).map(id => state[id]);
