import React from 'react';

import './CSSModules/ProductSearchPage.module.css';
import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import ProductSearchFiltersAdmin from 'sow/containers/ProductSearchFiltersAdmin';
import ProductSearchResultsTable from 'sow/containers/ProductSearchResultsTable';

const AdminProductSearchPage = () => (
  <AdminPageTemplate title="Products">
    {() => (
      <div className="product-search-page">
        <ProductSearchFiltersAdmin />
        <ProductSearchResultsTable />
      </div>
    )}
  </AdminPageTemplate>
);

export default AdminProductSearchPage;
