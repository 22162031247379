import React from 'react';
import PropTypes from 'prop-types';

import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Panel from 'sow/components/molecules/Panel';
import OspAppPending from 'sow/components/organisms/OspAppPendingList';
import OspAppInProgress from 'sow/components/organisms/OspAppInProgressList';
import NewRegistrations from 'sow/components/organisms/OspAppNewRegistrationsList';

const AcaDashboardPage = ({ pendingList, newRegistrationsList, inProgressList }) => (
  <Row>
    <Column xs={12} sm={6}>
      <Panel success title="Pending Plans">
        <OspAppPending pendingList={pendingList} />
      </Panel>
    </Column>

    <Column xs={12} sm={6}>
      <Panel info title="New Registrations">
        <NewRegistrations registrationsList={newRegistrationsList} />
      </Panel>
      <Panel info title="Applications In Progress">
        <OspAppInProgress inProgressList={inProgressList} />
      </Panel>
    </Column>
  </Row>
);

AcaDashboardPage.propTypes = {
  pendingList: PropTypes.array.isRequired,
  newRegistrationsList: PropTypes.array.isRequired,
  inProgressList: PropTypes.array.isRequired,
};

export default AcaDashboardPage;
