import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

const BlockDiv = styled.div`
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
`;

/**
 * Base component for block elements
 * @param {Object} props
 */
const Block = ({
  children,
  className,
  clearfix,
  pullLeft,
  pullRight,
  textCenter,
  textLeft,
  textRight,
  textMuted,
  textSuccess,
  textInfo,
  textWarning,
  textDanger,
  hiddenXs,
  hiddenSm,
  hiddenMd,
  hiddenLg,
  visibleXs,
  visibleSm,
  visibleMd,
  visibleLg,
  inline,
  ...props
}) => {
  const classes = classNames(className, {
    clearfix: clearfix,
    'pull-left': pullLeft,
    'pull-right': pullRight,
    'text-center': textCenter,
    'text-left': textLeft,
    'text-right': textRight,
    'text-muted': textMuted,
    'text-success': textSuccess,
    'text-info': textInfo,
    'text-warning': textWarning,
    'text-danger': textDanger,
    'hidden-xs': hiddenXs,
    'hidden-sm': hiddenSm,
    'hidden-md': hiddenMd,
    'hidden-lg': hiddenLg,
    'visible-xs': visibleXs,
    'visible-sm': visibleSm,
    'visible-md': visibleMd,
    'visible-lg': visibleLg,
  });

  return (
    <BlockDiv {...props} className={classes} inline={inline}>
      {children}
    </BlockDiv>
  );
};

Block.propTypes = {
  children: PropTypes.node,

  /** Additional classes to be passed through */
  className: PropTypes.string,

  /** Assigns the 'clearfix' class */
  clearfix: PropTypes.bool,

  /** Assigns display: 'inline-block' */
  inline: PropTypes.bool,

  /** Floats the element left */
  pullLeft: PropTypes.bool,

  /** Floats the element right */
  pullRight: PropTypes.bool,

  /** Aligns text center */
  textCenter: PropTypes.bool,

  /** Aligns text left */
  textLeft: PropTypes.bool,

  /** Aligns text right */
  textRight: PropTypes.bool,

  /** Colors text in 'muted' color */
  textMuted: PropTypes.bool,

  /** Colors text in 'success' color */
  textSuccess: PropTypes.bool,

  /** Colors text in 'fino' color */
  textInfo: PropTypes.bool,

  /** Colors text in 'warning' color */
  textWarning: PropTypes.bool,

  /** Colors text in 'danger' color */
  textDanger: PropTypes.bool,

  hiddenXs: PropTypes.bool,
  hiddenSm: PropTypes.bool,
  hiddenMd: PropTypes.bool,
  hiddenLg: PropTypes.bool,
  visibleXs: PropTypes.bool,
  visibleSm: PropTypes.bool,
  visibleMd: PropTypes.bool,
  visibleLg: PropTypes.bool,
};

export default Block;
