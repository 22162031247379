import React, { Fragment } from 'react';

import './ImpersonationBorder.module.css';
import Block from 'sow/components/atoms/Block';
import Paragraph from 'sow/components/atoms/Paragraph';
import Button from 'sow/components/atoms/Button';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const ImpersonationBorder = ({ impersonatedUser, endImpersonation, show }) => (
  <Block className="impersonation-banner">
    {show && (
      <Fragment>
        <Block className="fixed-banner">
          <Paragraph className="impersonation-text">
            You are currently impersonating {impersonatedUser}
          </Paragraph>
          <Paragraph className="impersonation-text impersonation-divider">|</Paragraph>
          <Button
            className="stop-impersonation-button"
            onClick={endImpersonation}
            disabled={!show}
          >
            <FontAwesome icon="arrow-left" /> RETURN TO MY ACCOUNT
          </Button>
        </Block>
        <Block className="impersonation-spacer" />
      </Fragment>
    )}
  </Block>
);

export default ImpersonationBorder;
