import * as R from 'ramda';
import { connect } from 'react-redux';

import * as select from '../selectors';
import ScopeWorksheetsModal from './ScopeWorksheetsModal';

const makeMapStateToProps = () => (state, props) => ({
  ospSectionId: select.scopeWorksheetsEditOspSectionId(state, props),
});

export default R.compose(connect(makeMapStateToProps))(ScopeWorksheetsModal);
