import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';

import { announcementsRoute } from 'sow/routes';
import {
  accessTypeConstants,
  accessTypeToOptions,
  accessTypeToName,
} from 'sow/constants/announcementShowFor';

import AnnouncementEditPageTemplate from 'sow/components/templates/AnnouncementEditPageTemplate';
import Block from 'sow/components/atoms/Block';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Confirm from 'sow/components/molecules/Confirm';
import FormikField from 'sow/components/organisms/FormikField';
import FormikDate from 'sow/components/molecules/FormikDate';
import FormikReactSelect from 'sow/components/molecules/FormikReactSelect';
import FormikSingleCheckbox from 'sow/components/molecules/FormikSingleCheckbox';
import FormikWysiwyg from 'sow/components/molecules/FormikWysiwyg';
import Button from 'sow/components/atoms/Button';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Spinner from 'sow/components/atoms/Spinner';
import Heading from 'sow/components/atoms/Heading';

const formSchema = Yup.object({
  title: Yup.string()
    .required('Title required.')
    .max(255, 'Maximum characters exceeded: 255/255'),
  announcement_type_id: Yup.number('Type required.')
    .typeError('Type required')
    .required('Type required.')
    .positive('Type required.'),
  start_on: Yup.string()
    .required('Date is invalid. Please use MM/DD/YYYY ex. (04/05/2020)')
    .matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Date is invalid. Please use MM/DD/YYYY ex. (04/05/2020)',
    )
    .typeError('Date is invalid. Please use MM/DD/YYYY ex. (04/05/2020)'),
  end_on: Yup.string()
    .ensure()
    .matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Date is invalid. Please use MM/DD/YYYY ex. (04/05/2020)',
    ),
});

const AnnouncementsEdit = ({
  isLoading,
  isNew,
  initialValues,
  handleSave,
  handleDelete,
  announcementTypes,
  addShowTypeRow,
  removeShowTypeRow,
  accessTypeOptions,
  groupOptions,
  announcementId,
}) => (
  <AnnouncementEditPageTemplate isLoading={isLoading} id={announcementId}>
    <Widget className="system-admin-list-edit">
      <WidgetHeading>{isNew ? 'Add' : 'Edit'}</WidgetHeading>
      <WidgetBody>
        <Formik
          initialValues={initialValues}
          validateOnChange={false}
          enableReinitialize={true}
          validationSchema={formSchema}
          onSubmit={handleSave}
          onReset={handleDelete}
        >
          {({ values, handleSubmit, handleReset, setFieldValue }) => (
            <Form>
              <Row className="form-row">
                <Column className="form-column" xs={4} sm={3} md={2}>
                  <Button className="form-button" onClick={handleSubmit} primary>
                    Save
                  </Button>
                </Column>
                <Column className="form-column" xs={4} sm={3} md={2}>
                  <Button
                    className="form-button reset-return-button"
                    to={announcementsRoute()}
                  >
                    Cancel
                  </Button>
                </Column>
                <Column sm={3} md={6}></Column>
                <Column className="form-column" xs={4} sm={3} md={2}>
                  <Confirm
                    message="Are you sure you want to delete this announcement?"
                    onConfirm={handleReset}
                  >
                    {toggleConfirm => (
                      <Button
                        className="delete-button"
                        onClick={toggleConfirm}
                        disabled={isNew}
                      >
                        <FontAwesome className="button-icon" icon="trash" />
                        Delete
                      </Button>
                    )}
                  </Confirm>
                </Column>
              </Row>
              <HorizontalRule className="divider" />
              <Row className="form-row">
                <Column className="form-column" xs={12}>
                  <Field
                    className="input-filter"
                    label="Title"
                    placeholder=""
                    name={'title'}
                    component={FormikField}
                    required
                  />
                </Column>
                <Column className="form-column" xs={12}>
                  <Field
                    label="Type"
                    placeholder=""
                    name={'announcement_type_id'}
                    component={FormikField}
                    type={FormikReactSelect}
                    clearable={true}
                    options={announcementTypes}
                    required
                  />
                </Column>
                <Column className="form-column" xs={12} sm={6}>
                  <Field
                    className="form-control date-input"
                    label="Start Date"
                    placeholder=""
                    name={'start_on'}
                    dateFormat={'MM/DD/YYYY'}
                    component={FormikField}
                    type={FormikDate}
                    selectedDate={values.end_on ? { end: values.end_on } : null}
                    required
                  />
                </Column>
                <Column className="form-column" xs={12} sm={6}>
                  <Field
                    className="form-control date-input"
                    label="End Date"
                    placeholder=""
                    name={'end_on'}
                    dateFormat={'MM/DD/YYYY'}
                    component={FormikField}
                    type={FormikDate}
                    selectedDate={values.start_on ? { start: values.start_on } : null}
                  />
                </Column>
                <Column className="form-column" xs={12}>
                  <Field
                    label="Message"
                    name={'message'}
                    component={FormikField}
                    type={FormikWysiwyg}
                  />
                </Column>
              </Row>
              <Row>
                <Column xs={12}>
                  <HorizontalRule />
                </Column>
              </Row>
              <Row className="form-row">
                <Column className="form-column" xs={12}>
                  <Heading level={4}>Show Types</Heading>
                </Column>
                {values.showForRows &&
                  values.showForRows.map((showType, index) => (
                    <Block key={index} className="show-type-row">
                      <Block className={'show-type-select'}>
                        <Field name={`showForRows[${index}].access_type_id`}>
                          {({ field }) => (
                            <Fragment>
                              <label>Type</label>
                              <Select
                                placeholder=""
                                clearable={true}
                                options={accessTypeOptions}
                                value={field.value}
                                onChange={option => {
                                  setFieldValue(field.name, option ? option.value : null);
                                  setFieldValue(
                                    `showForRows[${index}].group_type_id`,
                                    null,
                                  );
                                }}
                              />
                            </Fragment>
                          )}
                        </Field>
                      </Block>
                      {values.showForRows[index].access_type_id &&
                        values.showForRows[index].access_type_id !==
                          accessTypeConstants.everyone && (
                          <Block className="show-type-select">
                            <Field
                              label={
                                accessTypeToName[values.showForRows[index].access_type_id]
                                  ? accessTypeToName[
                                      values.showForRows[index].access_type_id
                                    ]
                                  : ''
                              }
                              placeholder=""
                              name={`showForRows[${index}].group_type_id`}
                              component={FormikField}
                              type={FormikReactSelect}
                              clearable={true}
                              options={
                                values.showForRows[index].access_type_id
                                  ? groupOptions[
                                      accessTypeToOptions[
                                        values.showForRows[index].access_type_id
                                      ]
                                    ]
                                  : []
                              }
                              disabled={values.access_type_id === null}
                              multi={true}
                            />
                          </Block>
                        )}
                      <Block className="button-column">
                        <Button
                          onClick={() =>
                            removeShowTypeRow(index, values[`showForRows`], setFieldValue)
                          }
                        >
                          X
                        </Button>
                      </Block>
                    </Block>
                  ))}
                <Column className="form-column" xs={12} sm={6}>
                  ADD SHOW TYPE{' '}
                  <Button
                    className="addition-button"
                    onClick={() => addShowTypeRow(values[`showForRows`], setFieldValue)}
                  >
                    +
                  </Button>
                </Column>
              </Row>
              <Row>
                <Column xs={12}>
                  <HorizontalRule />
                </Column>
              </Row>
              <Row className="form-row">
                <Column className="form-column" xs={12}>
                  <Field
                    label="Status"
                    name={'enabled'}
                    labelText="Enabled"
                    component={FormikField}
                    type={FormikSingleCheckbox}
                  />
                </Column>
              </Row>
            </Form>
          )}
        </Formik>
      </WidgetBody>
    </Widget>
  </AnnouncementEditPageTemplate>
);

export default AnnouncementsEdit;
