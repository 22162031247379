import React from 'react';

import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Block from 'sow/components/atoms/Block';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Strong from 'sow/components/atoms/Strong';
import PicklistModalItem from './PicklistModalItem';

const PicklistModalItemList = ({
  itemList,
  addItem,
  removeItem,
  reorderList,
  updateOrder,
}) => (
  <Row className="item-version-editor-block">
    <Column className="editor-column grey-background" xs={12}>
      <Block className="editor-header">
        <Block>
          <Strong>Items</Strong>
        </Block>
        <Block>
          <FontAwesome className="add-button" icon="circle-plus" onClick={addItem} />
        </Block>
      </Block>
      {itemList.map((item, index) => (
        <PicklistModalItem
          key={item.sort_order}
          index={index}
          removeItem={removeItem}
          reorderList={reorderList}
          updateOrder={updateOrder}
        />
      ))}
    </Column>
  </Row>
);

export default PicklistModalItemList;
