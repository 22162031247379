import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import * as sowTypes from 'sow/types';
import theme from './style/theme';
import Header from './Header';
import OrgInfoSection from './OrgInfoSection';
import MainPlanSection from './MainPlanSection';
import LocationsSection from './LocationsSection';

// --
// -- Components
// --

const ContentWrapper = styled.div`
  & * {
    font-family: sans-serif;
  }
`;

// TODO populate with data from container
const PlanAppExportContent = ({ showMainPlan, showLocations, ...props }) => (
  <ThemeProvider theme={theme}>
    <ContentWrapper>
      <Header {...props} />
      <OrgInfoSection {...props} />
      {showMainPlan && <MainPlanSection {...props} />}
      {showLocations && <LocationsSection {...props} />}
    </ContentWrapper>
  </ThemeProvider>
);

PlanAppExportContent.propTypes = {
  // passed props
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  exportConfig: sowTypes.planAppExportConfigType,
  userList: sowTypes.userLimitedListType.isRequired,

  planApp: sowTypes.planAppType.isRequired,
  planAppSummary: sowTypes.planAppSummaryType.isRequired,
  qualifiedWorksheetIds: PropTypes.arrayOf(sowTypes.planAppWorksheetIdType),
  worksheetSections: PropTypes.arrayOf(sowTypes.planAppWorksheetSectionType),
  locationIdList: sowTypes.planAppLocationIdListType.isRequired,
  exportConfig: sowTypes.planAppExportConfigType.isRequired,
  userList: sowTypes.userLimitedListType.isRequired,
  showMainPlan: PropTypes.bool.isRequired,
  showLocations: PropTypes.bool.isRequired,
};

export default PlanAppExportContent;
