import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import ItemSectionTypeEditContainer from 'sow/containers/ItemSectionTypeEditContainer';

const ItemSectionTypeEditPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <ItemSectionTypeEditContainer />}
  </AdminPageTemplate>
);

export default ItemSectionTypeEditPage;
