const orgLocations = (state = { fetchingLocations: true }, { type, payload }) => {
  switch (type) {
    case 'ORG/LIST_LOCATIONS':
      return { fetchingLocations: true };
    case 'ORG/LIST_LOCATIONS_DONE':
      return { fetchingLocations: false, locationList: payload };
    default:
      return state;
  }
};

export default orgLocations;
