import React from 'react';
import PropTypes from 'prop-types';

const renderAnswerLine = (text, i) =>
  <p key={i}>
    {text}
  </p>;

const DescriptiveTextQuestionField = ({ question }) =>
  <div className="panel panel-info">
    <div className="panel-heading">
      {question.answers.map(renderAnswerLine)}
    </div>
  </div>;

DescriptiveTextQuestionField.propTypes = {
  question: PropTypes.object,
};

export default DescriptiveTextQuestionField;
