import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import Fuse from 'fuse.js';
import * as R from 'ramda';

import { orgIdType } from 'sow/types';

const filterOptions = (options, filter) => {
  if (!filter) return options;

  const fuseOptions = {
    shouldSort: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['id', 'name', 'users.name', 'users.email'],
  };
  const fuse = new Fuse(options, fuseOptions);
  return fuse.search(filter);
};

const AcaOrgSelect = ({ name, value, setFieldValue, disabled, orgList }) => {
  return (
    <ReactSelect
      name={name}
      value={value}
      onChange={selectedOption =>
        setFieldValue(name, R.propOr('', 'value', selectedOption))
      }
      disabled={disabled}
      options={orgList.map(org => ({
        ...org,
        value: org.id,
        label: `${org.name} [${org.users.map(u => u.email).join(', ')}]`,
      }))}
      filterOptions={filterOptions}
    />
  );
};

AcaOrgSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([orgIdType, PropTypes.string]).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  orgList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

AcaOrgSelect.defaultProps = {
  disabled: false,
};

export default AcaOrgSelect;
