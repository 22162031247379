import * as R from 'ramda';
import { v4 as guid } from 'uuid';

import { moveListItemAway, moveListItemCloser } from 'sow/utils/data';

import { INIT_STATE } from './OspDefEditor';
import { QUAL_QUESTION_ADD, QUAL_QUESTION_REMOVE } from './ospDef';

// Actions
export const QUESTION_ADD = 'OspDefEditor/ospQualQuestions/QUESTION_ADD';
export const QUESTION_MOVE_DOWN = 'OspDefEditor/ospQualQuestions/QUESTION_MOVE_DOWN';
export const QUESTION_MOVE_UP = 'OspDefEditor/ospQualQuestions/QUESTION_MOVE_UP';
export const QUESTION_REMOVE = 'OspDefEditor/ospQualQuestions/QUESTION_REMOVE';
export const SET_VALUE = 'OspDefEditor/ospQualQuestions/SET_VALUE';
export const WORKSHEET_ADD = 'OspDefEditor/ospQualWorksheets/WORKSHEET_ADD';
export const WORKSHEET_REMOVE = 'OspDefEditor/ospQualWorksheets/WORKSHEET_REMOVE';
export const QUAL_SUB_QUESTION_ADD = 'OspDefEditor/ospDef/QUAL_SUB_QUESTION_ADD';
export const QUAL_SUB_QUESTION_REMOVE = 'OspDefEditor/ospDef/QUAL_SUB_QUESTION_REMOVE';
export const QUAL_SUB_QUESTION_MOVE_DOWN =
  'OspDefEditor/ospDef/QUAL_SUB_QUESTION_MOVE_DOWN';
export const QUAL_SUB_QUESTION_MOVE_UP = 'OspDefEditor/ospDef/QUAL_SUB_QUESTION_MOVE_UP';
export const REFERENCE_ADD = 'OspDefEditor/ospDef/REFERENCE_ADD';
export const REFERENCE_REMOVE = 'OspDefEditor/ospDef/REFERENCE_REMOVE';

// entity defauts/creators
export const getQualQuestionDefaults = () => ({
  uuid: guid(),
  question: '(question text)',
  references: [],
  sub_questions: [],
  worksheets: [],
});

export function createQualQuestion(defaultValues = {}) {
  return R.merge(getQualQuestionDefaults(), defaultValues);
}

// Reducer functions
export const reduceWorksheetAdd = (state = {}, action = {}) => {
  const { ospQualificationQuestionId, worksheetId } = action.data;
  const lens = R.lensPath([ospQualificationQuestionId, 'worksheets']);
  return R.over(lens, R.append(worksheetId), state);
};

export const reduceWorksheetRemove = (state = {}, action = {}) => {
  const { ospQualificationQuestionId, worksheetId } = action.data;
  const lens = R.lensPath([ospQualificationQuestionId, 'worksheets']);
  return R.over(lens, R.without([worksheetId]), state);
};

export const reduceOspQualificationQuestionSetValue = (state = {}, action = {}) => {
  const { ospQualificationQuestionId, field, value } = action.data;
  return R.assocPath([ospQualificationQuestionId, field], value, state);
};

export const reduceQuestionAdd = (state = {}, action = {}) => {
  const { question, worksheetId } = action.data;
  const questionId = question.uuid;
  const lens = R.lensPath([worksheetId, 'questions']);
  return R.over(lens, R.append(questionId), state);
};

export const reduceQuestionMoveDown = (state = {}, action = {}) => {
  const { questionId, worksheetId } = action.data;
  const lens = R.lensPath([worksheetId, 'questions']);
  const all = R.view(lens, state);
  const count = R.length(all);
  const idx = R.findIndex(R.equals(questionId), all);

  // already last item so do nothing
  if (idx >= count - 1) return state;

  return R.over(lens, moveListItemAway(idx), state);
};

export const reduceQuestionMoveUp = (state = {}, action = {}) => {
  const { questionId, worksheetId } = action.data;
  const lens = R.lensPath([worksheetId, 'questions']);
  const all = R.view(lens, state);
  const count = R.length(all);
  const idx = R.findIndex(R.equals(questionId), all);

  // already first item so do nothing
  if (idx < 1) return state;

  return R.over(lens, moveListItemCloser(idx), state);
};

export const reduceQuestionRemove = (state = {}, action = {}) => {
  const { questionId, worksheetId } = action.data;
  const lens = R.lensPath([worksheetId, 'questions']);
  const pred = R.equals(questionId);
  return R.over(lens, R.reject(pred), state);
};

export const reduceRegulationAdd = (state = {}, action = {}) => {
  const { regulation, worksheetId } = action.data;
  const lens = R.lensPath([worksheetId, 'regulations']);
  return R.over(lens, R.append(regulation), state);
};

export const reduceRegulationMoveDown = (state = {}, action = {}) => {
  const { regulationId, worksheetId } = action.data;
  const lens = R.lensPath([worksheetId, 'regulations']);
  const all = R.view(lens, state);
  const count = R.length(all);
  const idx = R.findIndex(R.propEq('uuid', regulationId), all);

  // already last item so do nothing
  if (idx >= count - 1) return state;

  return R.over(lens, moveListItemAway(idx), state);
};

export const reduceRegulationMoveUp = (state = {}, action = {}) => {
  const { regulationId, worksheetId } = action.data;
  const lens = R.lensPath([worksheetId, 'regulations']);
  const all = R.view(lens, state);
  const count = R.length(all);
  const idx = R.findIndex(R.propEq('uuid', regulationId), all);

  // already first item so do nothing
  if (idx < 1) return state;

  return R.over(lens, moveListItemCloser(idx), state);
};

export const reduceRegulationRemove = (state = {}, action = {}) => {
  const { regulationId, worksheetId } = action.data;
  const lens = R.lensPath([worksheetId, 'regulations']);
  const pred = R.whereEq({ uuid: regulationId });
  return R.over(lens, R.reject(pred), state);
};

export const reduceRegulationSetValue = (state = {}, action = {}) => {
  const lens = R.lensPath([action.data.worksheetId, 'regulations']);
  const pred = R.whereEq({ uuid: action.data.regulationId });
  const op = R.assoc(action.data.field, action.data.value);
  return R.over(lens, R.map(R.when(pred, op)), state);
};

export const reduceOspQualificationQuestionAdd = (state = {}, action = {}) => {
  const ospQulificationQuestion = action.data.ospQualificationQuestion;
  return R.assoc(ospQulificationQuestion.uuid, ospQulificationQuestion, state);
};

export const reduceOspQualificationQuestionRemove = (state = {}, action = {}) => {
  const { ospQualificationQuestionId } = action.data;
  return R.dissoc(ospQualificationQuestionId, state);
};

export const reduceReferenceAdd = (state = {}, action = {}) => {
  const { ospQualificationQuestionId, referenceId } = action.data;
  const lens = R.lensPath([ospQualificationQuestionId, 'references']);
  return R.over(lens, R.union([referenceId]), state);
};

export const reduceReferenceRemove = (state = {}, action = {}) => {
  const { ospQualificationQuestionId, referenceId } = action.data;
  const lens = R.lensPath([ospQualificationQuestionId, 'references']);
  return R.over(lens, R.without([referenceId]), state);
};

export const reduceSubOspQualificationQuestionAdd = (state = {}, action = {}) => {
  const { rootOspQualificationQuestionId, ospQualificationQuestion } = action.data;
  const addState = R.assoc(
    ospQualificationQuestion.uuid,
    ospQualificationQuestion,
    state,
  );
  const lens = R.lensPath([rootOspQualificationQuestionId, 'sub_questions']);
  return R.over(lens, R.prepend(ospQualificationQuestion.uuid), addState);
};

export const reduceSubOspQualificationQuestionRemove = (state = {}, action = {}) => {
  const { rootOspQualificationQuestionId, ospQualificationQuestionId } = action.data;
  const lens = R.lensPath([rootOspQualificationQuestionId, 'sub_questions']);
  return R.over(lens, R.without([ospQualificationQuestionId]), state);
};

export const reduceSubQualQuestionMoveDown = (state = {}, action = {}) => {
  const { rootId, subId } = action.data;
  const lens = R.lensPath([rootId, 'sub_questions']);
  const all = R.view(lens, state);
  const count = R.length(all);
  const idx = R.findIndex(R.equals(subId), all);

  // already last item so do nothing
  if (idx >= count - 1) return state;

  return R.over(lens, moveListItemAway(idx), state);
};

export const reduceSubQualQuestionMoveUp = (state = {}, action = {}) => {
  const { rootId, subId } = action.data;
  const lens = R.lensPath([rootId, 'sub_questions']);
  const all = R.view(lens, state);
  const idx = R.findIndex(R.equals(subId), all);

  // already first item so do nothing
  if (idx < 1) return state;

  return R.over(lens, moveListItemCloser(idx), state);
};

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case INIT_STATE:
      return R.path(['payload', 'ospQualificationQuestions'], action);

    // QUALIFICATION QUESTIONS
    case QUAL_QUESTION_ADD:
      return reduceOspQualificationQuestionAdd(state, action);

    case QUAL_QUESTION_REMOVE:
      return reduceOspQualificationQuestionRemove(state, action);

    case QUAL_SUB_QUESTION_ADD:
      return reduceSubOspQualificationQuestionAdd(state, action);

    case QUAL_SUB_QUESTION_REMOVE:
      return reduceSubOspQualificationQuestionRemove(state, action);

    case QUAL_SUB_QUESTION_MOVE_DOWN:
      return reduceSubQualQuestionMoveDown(state, action);

    case QUAL_SUB_QUESTION_MOVE_UP:
      return reduceSubQualQuestionMoveUp(state, action);

    case SET_VALUE:
      return reduceOspQualificationQuestionSetValue(state, action);

    // QUESTIONS
    case QUESTION_ADD:
      return reduceQuestionAdd(state, action);

    case QUESTION_MOVE_DOWN:
      return reduceQuestionMoveDown(state, action);

    case QUESTION_MOVE_UP:
      return reduceQuestionMoveUp(state, action);

    case QUESTION_REMOVE:
      return reduceQuestionRemove(state, action);

    // REFERENCES
    case REFERENCE_ADD:
      return reduceReferenceAdd(state, action);

    case REFERENCE_REMOVE:
      return reduceReferenceRemove(state, action);

    // WORKSHEETS
    case WORKSHEET_ADD:
      return reduceWorksheetAdd(state, action);

    case WORKSHEET_REMOVE:
      return reduceWorksheetRemove(state, action);

    default:
      return state;
  }
}

// Action Creators
export function questionAdd(worksheetId) {
  const question = createQuestion();
  return { type: QUESTION_ADD, data: { worksheetId, question } };
}

export function questionMoveDown(worksheetId, questionId) {
  return { type: QUESTION_MOVE_DOWN, data: { worksheetId, questionId } };
}

export function questionMoveUp(worksheetId, questionId) {
  return { type: QUESTION_MOVE_UP, data: { worksheetId, questionId } };
}

export function questionRemove(worksheetId, questionId) {
  return { type: QUESTION_REMOVE, data: { worksheetId, questionId } };
}

export function setValue(ospQualificationQuestionId, field, value) {
  return {
    type: SET_VALUE,
    data: { ospQualificationQuestionId, field, value },
  };
}

export function subOspQualificationQuestionAdd(
  rootOspQualificationQuestionId,
  ospQualificationQuestion,
) {
  return {
    type: QUAL_SUB_QUESTION_ADD,
    data: {
      ospQualificationQuestion,
      rootOspQualificationQuestionId,
    },
  };
}

export function subOspQualificationQuestionRemove(
  rootOspQualificationQuestionId,
  ospQualificationQuestionId,
) {
  return {
    type: QUAL_SUB_QUESTION_REMOVE,
    data: {
      ospQualificationQuestionId,
      rootOspQualificationQuestionId,
    },
  };
}

export function subQuestionMoveDown(rootId, subId) {
  return { type: QUAL_SUB_QUESTION_MOVE_DOWN, data: { rootId, subId } };
}

export function subQuestionMoveUp(rootId, subId) {
  return { type: QUAL_SUB_QUESTION_MOVE_UP, data: { rootId, subId } };
}

export function referenceAdd(ospQualificationQuestionId, referenceId) {
  return {
    type: REFERENCE_ADD,
    data: { ospQualificationQuestionId, referenceId },
  };
}

export function referenceRemove(ospQualificationQuestionId, referenceId) {
  return {
    type: REFERENCE_REMOVE,
    data: { ospQualificationQuestionId, referenceId },
  };
}

export function worksheetAdd(ospQualificationQuestionId, worksheetId) {
  return {
    type: WORKSHEET_ADD,
    data: { ospQualificationQuestionId, worksheetId },
  };
}

export function worksheetRemove(ospQualificationQuestionId, worksheetId) {
  return {
    type: WORKSHEET_REMOVE,
    data: { ospQualificationQuestionId, worksheetId },
  };
}

// Action Creator Export Map
export const actions = {
  setValue,
  subOspQualificationQuestionAdd,
  subOspQualificationQuestionRemove,
  subQuestionMoveDown,
  subQuestionMoveUp,

  // questions
  questionAdd,
  questionMoveDown,
  questionMoveUp,
  questionRemove,

  // reference
  referenceAdd,
  referenceRemove,

  // worksheets
  worksheetAdd,
  worksheetRemove,
};
