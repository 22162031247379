import React, { Fragment } from 'react';
import { ButtonToolbar, DropdownButton, MenuItem } from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  ospDefType,
  acaIdType,
  ospQualificationQuestionType,
  ospSectionType,
  worksheetQuestionType,
  worksheetType,
  ospDefUserType,
} from 'sow/types';
import { isDeployProd } from 'sow/config';
import { acaOspDefRoute } from 'sow/routes';
import Link from 'sow/components/atoms/Link';
import ListItem from 'sow/components/atoms/ListItem';
import Glyphicon from 'sow/components/molecules/Glyphicon';
import SetOspDefNameModal from 'sow/components/old/aca/osp/SetOspDefNameModal';
import CopyOspDefModal from 'sow/components/old/aca/osp/CopyOspDefModal';

export const OspDefAdminActionsButton = ({
  user,
  acaId,
  ospDef,
  sections,
  worksheets,
  worksheetQuestions,
  ospQualificationQuestions,
  confirmPublish,
  confirmUnpublish,
  currentOspDefId,
}) => {
  const isEditable = !isDeployProd || !ospDef.published;
  const canCopy = ospDef.id >= currentOspDefId;

  return (
    <ButtonToolbar>
      <DropdownButton
        id="landRowActionsButton"
        bsSize="sm"
        title={<Glyphicon glyph="cog" />}
        pullRight
      >
        {isEditable && <MenuItem header>Edit</MenuItem>}
        {isEditable && (
          <SetOspDefNameModal
            user={user}
            ospDefName={ospDef.name}
            acaId={acaId}
            ospDefId={ospDef.id}
            disabled={ospDef.published}
          />
        )}
        {isEditable && (
          <ListItem>
            <Link to={acaOspDefRoute(acaId, ospDef.id)} disabled={!isEditable}>
              <Glyphicon glyph="wrench" /> Edit
            </Link>
          </ListItem>
        )}
        {isEditable && !ospDef.published && <MenuItem divider />}

        {!ospDef.published && <MenuItem header>Actions</MenuItem>}
        {ospDef.published && false && (
          <MenuItem
            id={`unpublishOspDefButton-${ospDef.id}`}
            onClick={confirmUnpublish(acaId, ospDef.id)}
            disabled={!ospDef.published}
          >
            <Glyphicon glyph="eye-close" /> Unpublish
          </MenuItem>
        )}
        {!ospDef.published && (
          <MenuItem
            id={`publishOspDefButton-${ospDef.id}`}
            onClick={confirmPublish(acaId, ospDef.id)}
            disabled={ospDef.published}
          >
            <Glyphicon glyph="eye-open" /> Publish
          </MenuItem>
        )}

        {canCopy && (
          <Fragment>
            <MenuItem divider />

            <CopyOspDefModal
              user={user}
              acaId={acaId}
              ospDef={ospDef}
              ospDefId={ospDef.id}
              sections={sections}
              ospQualificationQuestions={ospQualificationQuestions}
              worksheets={worksheets}
              worksheetQuestions={worksheetQuestions}
            />
          </Fragment>
        )}
      </DropdownButton>
    </ButtonToolbar>
  );
};

OspDefAdminActionsButton.propTypes = {
  ospDef: ospDefType.isRequired,
  user: ospDefUserType.isRequired,
  acaId: acaIdType.isRequired,
  ospQualificationQuestions: PropTypes.objectOf(ospQualificationQuestionType),
  sections: PropTypes.objectOf(ospSectionType),
  worksheetQuestions: PropTypes.objectOf(worksheetQuestionType),
  worksheets: PropTypes.objectOf(worksheetType),
  confirmPublish: PropTypes.func.isRequired,
  confirmUnpublish: PropTypes.func.isRequired,
};

export default OspDefAdminActionsButton;
