import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { withRouter } from 'react-router';

import UserManagementUserLookup from 'sow/components/organisms/UserManagementUserLookup';
import {
  loadUsers,
  clearResults,
  loadFormOptions,
} from 'sow/actions/pure/userManagement';
import { acaIdConstant } from 'sow/constants/acaId';
import { ResourceListLoader } from 'sow/store/helpers';
import Spinner from 'sow/components/atoms/Spinner';

const mapStateToProps = state => ({
  users: state.userManagement.users,
  fetchingOptions: state.userManagement.fetchingOptions,
  selectOptions: state.userManagement.selectOptions,
});

const mapDispatchToProps = {
  loadUsers,
  clearResults,
  loadFormOptions,
};

class UserManagementUserLookupContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      aca: 4,
      reload: false,
      searchDone: false,
      isSearching: false,
    };
  }

  componentDidMount() {
    this.props.loadFormOptions(acaIdConstant);
    if (window.location.href.split('?')[1]) {
      this.setState({ reload: true });
      const urlSearch = window.location.href.split('?')[1].split('&');
      urlSearch.forEach(field => {
        switch (field.slice(0, 1)) {
          case 'c':
            this.setState({ consultantCompany: Number(field.slice(2)) });
            break;
          case 'n':
            this.setState({ name: decodeURIComponent(field.slice(2)) });
            break;
          case 'e':
            this.setState({ email: decodeURIComponent(field.slice(2)) });
            break;
          case 'r':
            this.setState({ systemRole: decodeURIComponent(field.slice(2)) });
            break;
          case 's':
            this.setState({ staffRole: decodeURIComponent(field.slice(2)) });
            break;
          case 'b':
            this.setState({ enabled: field.slice(2) });
            break;
          case 'a':
            this.setState({ aca: Number(field.slice(2)) });
            break;
          case 'o':
            this.setState({ orgId: Number(field.slice(2)) });
            break;
          case 'p':
            this.setState({ pageNumber: Number(field.slice(2)) });
          default:
            break;
        }
      });
    }
  }

  componentDidUpdate() {
    if (this.state.reload) {
      this.handleSearchSubmit();
      this.setState({ reload: false });
    }
  }

  componentWillUnmount() {
    this.props.clearResults('users');
  }

  handleInputChange(target) {
    this.setState({ [target.id]: target.value });
  }

  dropDownHandler(field, target) {
    this.setState({ [field]: target ? target.value : null });
    if (field === 'aca') {
      if (target) {
        this.setState({ orgId: null });
        this.props.loadFormOptions(target.value);
      } else {
        this.setState({ orgId: null });
      }
    }
  }

  // Used in DataTables wrapper to get page number
  // getTablePage(pageNumber) {
  //   if (this.state.pageNumber !== pageNumber) {
  //     this.setState({ pageNumber });
  //   }
  //   const filteredUrlArray = window.location.href
  //     .split('?')[1]
  //     .split('&')
  //     .filter(el => el.slice(0, 1) !== 'p');
  //   const filteredUrl = filteredUrlArray.join('&');
  //   let updatedUrl = '?' + filteredUrl;
  //   if (pageNumber !== 0) {
  //     updatedUrl += `&p=${pageNumber}`;
  //   }
  //   history.pushState(null, null, updatedUrl);
  // }

  searchSuccess() {
    this.setState({ isSearching: false, searchDone: true });
  }

  handleSearchSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    if (!this.state.reload) {
      this.setState({ pageNumber: 0 });
    }
    if (
      this.state.name !== '' ||
      this.state.email !== '' ||
      !R.isNil(this.state.systemRole) ||
      !R.isNil(this.state.enabled) ||
      !R.isNil(this.state.orgId) ||
      !R.isNil(this.state.staffRole) ||
      !R.isNil(this.state.consultantCompany)
    ) {
      const searchArray = [];
      const apiQuery = {};
      if (this.state.name !== '') {
        const encodedName = encodeURIComponent(this.state.name);
        searchArray.push(`n=${encodedName}`);
        apiQuery.name = this.state.name;
      }
      if (this.state.email !== '') {
        const encodedEmail = encodeURIComponent(this.state.email);
        searchArray.push(`e=${encodedEmail}`);
        apiQuery.email = this.state.email;
      }
      if (!R.isNil(this.state.systemRole)) {
        const encodedSystemRole = encodeURIComponent(this.state.systemRole);
        searchArray.push(`r=${encodedSystemRole}`);
        apiQuery.systemRole = this.state.systemRole;
      }
      if (!R.isNil(this.state.staffRole)) {
        const encodedStaffRole = encodeURIComponent(this.state.staffRole);
        searchArray.push(`s=${encodedStaffRole}`);
        apiQuery.staffRole = this.state.staffRole;
      }
      if (!R.isNil(this.state.enabled)) {
        searchArray.push(`b=${this.state.enabled}`);
        apiQuery.enabled = this.state.enabled;
      }
      if (!R.isNil(this.state.consultantCompany)) {
        searchArray.push(`c=${this.state.consultantCompany}`);
        apiQuery.consultantCompany = this.state.consultantCompany;
      }
      if (!R.isNil(this.state.orgId)) {
        const encodedOrg = encodeURIComponent(this.state.orgId);
        searchArray.push(`a=${this.state.aca}&o=${encodedOrg}`);
        apiQuery.orgId = this.state.orgId;
      }
      let searchQuery = '?';
      searchArray.map((query, index) => {
        if (index === 0) {
          searchQuery += query;
          return;
        }
        searchQuery += '&' + query;
      });
      history.pushState(null, null, searchQuery);
      this.props.loadUsers(apiQuery, this.searchSuccess.bind(this));
      this.setState({ isSearching: true, searchDone: false });
    }
  }

  handleReset() {
    this.setState({
      name: '',
      email: '',
      systemRole: null,
      staffRole: null,
      enabled: null,
      aca: 4,
      orgId: null,
      pageNumber: '',
      searchDone: false,
      consultantCompany: null,
    });
    this.props.clearResults('users');
    this.props.loadFormOptions(acaIdConstant);
    history.pushState(null, null, '/admin/user-lookup');
  }

  render() {
    if (this.props.fetchingOptions) {
      <Spinner />;
    }

    return (
      <ResourceListLoader resource="aca/register" autoLoad>
        {({ result: acaList }) => (
          <UserManagementUserLookup
            fetchingOptions={this.props.fetchingOptions}
            selectOptions={this.props.selectOptions}
            acaList={acaList}
            dropDownHandler={this.dropDownHandler.bind(this)}
            handleSearchSubmit={this.handleSearchSubmit.bind(this)}
            handleInputChange={this.handleInputChange.bind(this)}
            handleReset={this.handleReset.bind(this)}
            // getTablePage={this.getTablePage.bind(this)}
            state={this.state}
            users={this.props.users}
          />
        )}
      </ResourceListLoader>
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(UserManagementUserLookupContainer);
