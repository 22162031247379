export default function itemVersion(state = {}, { type, payload }) {
  switch (type) {
    case 'ITEM_VERSION/FETCH_BY_ID':
      return { ...state };

    case 'ITEM_VERSION/FETCH_BY_ID_DONE':
    case 'ITEM_VERSION/ADD_ITEM_VERSION_DONE':
    case 'ITEM_VERSION/UPDATE_ITEM_VERSION_DONE':
      return { ...state, id: payload.id, name: payload.name };

    case 'ITEM_VERSION_PICKLIST/LOAD_ITEM_VERSION_PICKLISTS_DONE':
      return { ...state, id: payload.item_version_id, name: payload.item_version_name };
    default:
      return state;
  }
}
