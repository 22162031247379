import { makeImmutable } from 'sow/utils/data';
import { MATRIX_ROW_SET_ANSWER, SET_ANSWER } from 'sow/actions/worksheetAnswers';

const valuesByQuestionId = isMatrix => (state = makeImmutable({}), action) => {
  switch (action.type) {
    case MATRIX_ROW_SET_ANSWER:
      if (!isMatrix) return state;
      return state.setIn([action.data.questionId], action.data.value);

    case SET_ANSWER:
      if (isMatrix) return state;
      return state.setIn([action.data.questionId], action.data.value);

    default:
      return state;
  }
};

export default valuesByQuestionId;
