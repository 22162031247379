import React, { Fragment } from 'react';

import Block from 'sow/components/atoms/Block';
import Strong from 'sow/components/atoms/Strong';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import OptionListPopover from 'sow/components/molecules/OptionListPopover';
import PopoverOption from 'sow/components/molecules/OptionListPopover/PopoverOption';

const ItemVersionCreatorQuestion = ({ enableAdd, showPopover, popoverParams }) => (
  <Fragment>
    <OptionListPopover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...popoverParams}
    >
      <PopoverOption>Create A Question</PopoverOption>
    </OptionListPopover>
    <Block>
      <Strong>Question & Answers</Strong>
    </Block>
    <Block
      className={enableAdd ? 'add-button' : 'add-button-disabled'}
      onClick={enableAdd ? showPopover : null}
    >
      <FontAwesome icon="circle-plus" />
    </Block>
  </Fragment>
);

export default ItemVersionCreatorQuestion;
