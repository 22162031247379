import React, { Fragment } from 'react';

import { reportRoute } from 'sow/routes';
import * as sowTypes from 'sow/types';
import Button from 'sow/components/atoms/Button';
import Form from 'sow/components/atoms/Form';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Spacer from 'sow/components/atoms/Spacer';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Link from 'sow/components/atoms/Link';
import AcaSettingsForm from 'sow/containers/AcaSettingsForm';
import AcaForm from 'sow/components/organisms/AcaForm';

const AcaReportsPage = ({ aca, acaId }) => (
  <Fragment>
    <Row>
      <div className="report_tiles">
        <Column md={3} textCenter style={{ paddingLeft: '8px', paddingRight: '8px' }}>
          <Widget>
            <WidgetBody>
              <div className="report-card">
                <FontAwesome icon="users" />
                <br />
                <Spacer vertical={13} />
                Active
                <br />
                Users
                <Spacer vertical={13} />
                <Link to={reportRoute(acaId, 6)} btn lg primary>
                  View Report
                </Link>
              </div>
            </WidgetBody>
          </Widget>
        </Column>
        <Column md={3} textCenter style={{ paddingLeft: '8px', paddingRight: '8px' }}>
          <Widget>
            <WidgetBody>
              <div className="report-card">
                <FontAwesome icon="sitemap" />
                <br />
                <Spacer vertical={13} />
                Users With
                <br />
                Multiple Orgs
                <Spacer vertical={13} />
                <Link to={reportRoute(acaId, 7)} btn lg primary>
                  View Report
                </Link>
              </div>
            </WidgetBody>
          </Widget>
        </Column>
        <Column md={3} textCenter style={{ paddingLeft: '8px', paddingRight: '8px' }}>
          <Widget>
            <WidgetBody>
              <div className="report-card">
                <FontAwesome icon="clock" />
                <br />
                <Spacer vertical={13} />
                Certification Specialist
                <br />
                Overdue List
                <Spacer vertical={13} />
                <Link to={reportRoute(acaId, 1)} btn lg primary>
                  View Report
                </Link>
              </div>
            </WidgetBody>
          </Widget>
        </Column>
      </div>
    </Row>
    <Spacer vertical={16} />
    <Row>
      <div className="report_tiles">
        <Column md={3} textCenter style={{ paddingLeft: '8px', paddingRight: '8px' }}>
          <Widget>
            <WidgetBody>
              <div className="report-card">
                <FontAwesome icon="check-circle" />
                <br />
                <Spacer vertical={13} />
                Current
                <br />
                Status
                <Spacer vertical={13} />
                <Link to={reportRoute(acaId, 2)} btn lg primary>
                  View Report
                </Link>
              </div>
            </WidgetBody>
          </Widget>
        </Column>
        <Column md={3} textCenter style={{ paddingLeft: '8px', paddingRight: '8px' }}>
          <Widget>
            <WidgetBody>
              <div className="report-card">
                <FontAwesome icon="box-open" />
                <br />
                <Spacer vertical={13} />
                Materials
                <br />
                List
                <Spacer vertical={13} />
                <Link to={reportRoute(acaId, 3)} btn lg primary>
                  View Report
                </Link>
              </div>
            </WidgetBody>
          </Widget>
        </Column>
        <Column md={3} textCenter style={{ paddingLeft: '8px', paddingRight: '8px' }}>
          <Widget>
            <WidgetBody>
              <div className="report-card">
                <FontAwesome icon="boxes" />
                <br />
                <Spacer vertical={13} />
                Materials
                <br />
                Count
                <Spacer vertical={13} />
                <Link to={reportRoute(acaId, 8)} btn lg primary>
                  View Report
                </Link>
              </div>
            </WidgetBody>
          </Widget>
        </Column>
      </div>
    </Row>
    <Spacer vertical={16} />
    <Row>
      <div className="report_tiles">
        {/* <Column md={3} textCenter style={{ paddingLeft: '8px', paddingRight: '8px' }}>
		<Widget>
			<WidgetBody>
				<div className="report-card">
					<i className="fab fa-product-hunt" /><br/>
					<Spacer vertical={13} />
					Products<br/>List
					<Spacer vertical={13} />
					<Link to={reportRoute(acaId, 4)} btn lg primary>
						View Report
					</Link>
				</div>
	        </WidgetBody>
		  </Widget>
	  </Column> */}
        <Column md={3} textCenter style={{ paddingLeft: '8px', paddingRight: '8px' }}>
          <Widget>
            <WidgetBody>
              <div className="report-card">
                <FontAwesome icon="project-diagram" />
                <br />
                <Spacer vertical={13} />
                Workflow
                <br />
                Status
                <Spacer vertical={13} />
                <Link to={reportRoute(acaId, 5)} btn lg primary>
                  View Report
                </Link>
              </div>
            </WidgetBody>
          </Widget>
        </Column>
        <Column md={3} textCenter style={{ paddingLeft: '8px', paddingRight: '8px' }}>
          <Widget>
            <WidgetBody>
              <div className="report-card">
                <FontAwesome icon="search" />
                <br />
                <Spacer vertical={13} />
                Inspections
                <br />
                Coordinator Report
                <Spacer vertical={13} />
                <Link to={reportRoute(acaId, 9)} btn lg primary>
                  View Report
                </Link>
              </div>
            </WidgetBody>
          </Widget>
        </Column>
      </div>
    </Row>
  </Fragment>
);

AcaReportsPage.propTypes = {
  aca: sowTypes.acaType.isRequired,
  acaId: sowTypes.acaIdType.isRequired,
};

export default AcaReportsPage;
