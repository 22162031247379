import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Block from 'sow/components/atoms/Block';

const defaultStyle = {
  fontSize: '14pt',
};

const WidgetHeading = ({ children, className, style, ...props }) => (
  <Block className={classnames('widget-header', className)} style={style} {...props}>
    {children}
  </Block>
);

WidgetHeading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};

WidgetHeading.defaultProps = {
  style: defaultStyle,
};

export default WidgetHeading;
