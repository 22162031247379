import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import Glyphicon from 'sow/components/molecules/Glyphicon';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Spacer from 'sow/components/atoms/Spacer';
import Tooltip from 'sow/components/molecules/Tooltip';

const noop = () => {};

const buttonPropTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  saveable: PropTypes.bool.isRequired,
};

const SubmitButton = ({ onClick, disabled, saveable }) =>
  saveable && (
    <Tooltip overlay={'Save this form'}>
      <Button type="submit" onClick={onClick} disabled={disabled} primary>
        <FontAwesome variant="fas" icon="cloud-upload-alt" /> Save
      </Button>
    </Tooltip>
  );
SubmitButton.propTypes = buttonPropTypes;

const SubmitNextButton = ({ onClick, disabled, saveable }) => {
  const saveOverlay = 'Save this form and continue to the next worksheet';
  const textOverlay = 'Navigate to the next worksheet';
  return (
    <Tooltip overlay={saveable ? saveOverlay : textOverlay}>
      <Button onClick={onClick} disabled={disabled} primary>
        {saveable ? 'Save' : 'Next'} <Glyphicon glyph="chevron-right" />
      </Button>
    </Tooltip>
  );
};
SubmitNextButton.propTypes = buttonPropTypes;

const SubmitPrevButton = ({ onClick, disabled, saveable }) => {
  const saveOverlay = 'Save this form and continue to the previous worksheet';
  const textOverlay = 'Navigate to the previous worksheet';
  return (
    <Tooltip overlay={saveable ? saveOverlay : textOverlay}>
      <Button onClick={onClick} disabled={disabled} primary>
        <Glyphicon glyph="chevron-left" /> {saveable ? 'Save' : 'Prev'}
      </Button>
    </Tooltip>
  );
};
SubmitPrevButton.propTypes = buttonPropTypes;

const ActionBarWrapper = styled(Block)`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(240, 240, 240, 0.9);
  box-shadow: 0 0 10px #777;
`;

const PlanAppActionBar = ({
  onClickSubmit,
  onClickSubmitNext,
  onClickSubmitPrev,
  submitDisabled,
  prevButtonDisabled,
  saveButtonDisabled,
  nextButtonDisabled,
  saveable,
  hidePrev,
  hideNext,
}) => {
  return (
    <ActionBarWrapper>
      <Block className="container" textCenter>
        {!hidePrev && (
          <Fragment>
            <SubmitPrevButton
              onClick={onClickSubmitPrev}
              disabled={submitDisabled || prevButtonDisabled}
              saveable={saveable}
            />
            <Spacer horizontal={10} />
          </Fragment>
        )}
        <SubmitButton
          onClick={onClickSubmit}
          disabled={submitDisabled || saveButtonDisabled}
          saveable={saveable}
        />
        {!hideNext && (
          <Fragment>
            <Spacer horizontal={10} />
            <SubmitNextButton
              onClick={onClickSubmitNext}
              disabled={submitDisabled || nextButtonDisabled}
              saveable={saveable}
            />
          </Fragment>
        )}
      </Block>
    </ActionBarWrapper>
  );
};

PlanAppActionBar.propTypes = {
  onClickSubmit: PropTypes.func.isRequired,
  onClickSubmitNext: PropTypes.func.isRequired,
  onClickSubmitPrev: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
  prevButtonDisabled: PropTypes.bool,
  saveButtonDisabled: PropTypes.bool,
  nextButtonDisabled: PropTypes.bool,
  saveable: PropTypes.bool.isRequired,
  hidePrev: PropTypes.bool,
  hideNext: PropTypes.bool,
};

PlanAppActionBar.defaultProps = {
  onClickSubmit: noop,
  onClickSubmitNext: noop,
  onClickSubmitPrev: noop,
  hidePrev: false,
  hideNext: false,
};

export default PlanAppActionBar;
