import React, { Fragment } from 'react';

import { formattedDate } from 'sow/utils/dateTime';
import { inspectionStatesRank } from 'sow/constants/inspectionConstants';

import Confirm from 'sow/components/molecules/Confirm';
import ManageInspectionModal from 'sow/components/organisms/InspectionManager/ManageInspectionModal';
import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import Strong from 'sow/components/atoms/Strong';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import Spacer from 'sow/components/atoms/Spacer';

const InspectionManagerBlock = ({
  toggleEditModal,
  inspection,
  annual,
  deletePartialInspection,
  inspectorList,
  inspectionTypeOptions,
  inspectionYearOptions,
  sampleTypeOptions,
  show,
  editInspection,
  initialValues,
  unannouncedAnnualInspection,
}) => (
  <Fragment>
    {(inspection.inspectionStateRank < inspectionStatesRank.complete || annual) && (
      <Confirm
        message="Are you sure you want to delete this inspection?"
        onConfirm={() => deletePartialInspection(inspection.id)}
      >
        {toggleConfirm => (
          <ManageInspectionModal
            selectOptions={{
              inspectorList,
              inspectionTypeOptions,
              sampleTypeOptions,
              inspectionYearOptions,
            }}
            deletePartialInspection={deletePartialInspection}
            annualUnannounced={unannouncedAnnualInspection}
            editInspection={editInspection}
            toggleModal={toggleEditModal}
            show={show}
            inspection={inspection}
            annual={annual}
            initialValues={initialValues}
            toggleConfirm={toggleConfirm}
          />
        )}
      </Confirm>
    )}
    <Widget className="inspection-card">
      <WidgetHeading className={annual ? 'annual' : ''}>
        {`${inspection.inspectionType} ID: ${inspection.id}`}
        {inspection.inspectionStateRank < inspectionStatesRank.complete && (
          <Button
            className="edit-button no-margin-button"
            onClick={toggleEditModal}
            pullRight
            sm
          >
            Edit
          </Button>
        )}
      </WidgetHeading>
      <WidgetBody>
        {!annual && (
          <Block>
            <Strong>Inspection Status: </Strong>
            {inspection.inspectionState}
          </Block>
        )}
        <Block>
          <Strong>Inspection Year: </Strong>
          {inspection.inspectionYear || 'Not assigned'}
        </Block>
        <Block>
          <Strong>Inspection Type: </Strong>
          {inspection.inspectionSubType} {inspection.inspectionType}
        </Block>
        <Block>
          <Strong>Inspection Date: </Strong>
          {inspection.inspectionDate
            ? formattedDate(inspection.inspectionDate)
            : 'Not Set'}
        </Block>
        <Spacer vertical={15} />
        <Strong>Inspectors:</Strong>
        <Block>
          {inspection.inspectors && inspection.inspectors.length > 0
            ? inspection.inspectors.map(inspector => inspector.name).join(', ')
            : 'No inspectors assigned.'}
        </Block>
        <Spacer vertical={15} />
        <Strong>Sample:</Strong>
        <Block>
          {inspection.samples && inspection.samples.length > 0
            ? inspection.samples.map(sample => sample.typeName).join(', ')
            : 'No sample included.'}
        </Block>
      </WidgetBody>
    </Widget>
  </Fragment>
);

export default InspectionManagerBlock;
