import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as certificationTypeActions from 'sow/actions/pure/certificationType';
import * as currentAca from 'sow/selectors/currentAca';
import OrgSettingsCertification from 'sow/components/organisms/OrgSettingsCertification';

const mapStateToProps = (state, props) => ({ acaId: currentAca.acaId(state, props) });

const mapDispatchToProps = {
  loadList: certificationTypeActions.loadAllCertificationTypes,
};

class OrgSettingsCertificationsContainer extends Component {
  constructor(props) {
    super(props);

    // Used to prevent react memory leak.
    this._isMounted = false;
    this.state = { isLoading: true };
  }

  getSettings() {
    return R.defaultTo({}, R.prop('settings', this.props.org));
  }

  getCertificationPath() {
    return ['aca_byid', `${this.props.acaId}`, 'certification_ids'];
  }

  updateState({ data }) {
    if (this._isMounted) {
      const selectedCerts = {};
      const savedCerts = R.defaultTo(
        [],
        R.path(this.getCertificationPath(), this.getSettings()),
      );

      savedCerts.forEach(id => (selectedCerts[id] = true));

      data.forEach(cert => {
        if (!R.has(cert.id)(selectedCerts)) {
          selectedCerts[cert.id] = false;
        }
      });

      this.setState({ isLoading: false, certificationList: data, selectedCerts });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.loadList({ enabled: true }, this.updateState.bind(this));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  formatCertificationSettings(selectedCerts) {
    const certificationIds = [];
    for (let key in selectedCerts) {
      if (selectedCerts[key]) {
        certificationIds.push(Number(key));
      }
    }
    return certificationIds;
  }

  handleSelect(id) {
    const { selectedCerts } = this.state;
    const updatedSelectedCerts = selectedCerts;
    updatedSelectedCerts[id] = !selectedCerts[id];
    this.setState({ selectedCerts: updatedSelectedCerts });
    this.props.handleChangeWithErrors(
      'settings',
      R.assocPath(
        this.getCertificationPath(),
        this.formatCertificationSettings(updatedSelectedCerts),
        this.getSettings(),
      ),
    )(false);
  }

  render() {
    const { isLoading, certificationList, selectedCerts } = this.state;
    return (
      <OrgSettingsCertification
        isLoading={isLoading}
        certificationList={certificationList}
        selectedCerts={selectedCerts}
        handleSelect={this.handleSelect.bind(this)}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrgSettingsCertificationsContainer);
