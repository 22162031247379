import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Atom from 'sow/components/atoms/Atom';

const Mark = ({ children, className, ...props }) => {
  const classes = classnames('mark', className);

  return (
    <Atom className={classes} {...props}>
      {children}
    </Atom>
  );
};

Mark.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Mark;
