import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as currentUser from 'sow/selectors/currentUser';
import { fromPlanApp } from 'sow/store/selectors';
import * as sowTypes from 'sow/types';
import ChangeListItem from 'sow/components/organisms/PlanAppChangeRequestManager/ChangeListItem';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.changePlanAppId(state, props),
  worksheetId: fromPlanApp.changeWorksheetId(state, props),
  questionId: fromPlanApp.changeQuestionId(state, props),
  locationId: fromPlanApp.changeLocationId(state, props),
  change: fromPlanApp.change(state, props),
  comments: fromPlanApp.changeCommentList(state, props),
  worksheetName: fromPlanApp.changeWorksheetName(state, props),
  questionName: fromPlanApp.changeQuestionName(state, props),
  showAcaUI: currentUser.showAcaUI(state, props),
});

class ChangeListItemContainer extends Component {
  state = {
    showComments: false,
  };

  toggleComments = () => this.setState(R.over(R.lensPath(['showComments']), R.not));

  render() {
    const { comments, showAcaUI } = this.props;
    const { showComments } = this.state;

    const commentsWithNotifications = R.filter(
      R.where({
        [showAcaUI ? 'notifyAca' : 'notifyOrg']: R.equals(true),
      }),
    )(comments);

    return (
      <ChangeListItem
        {...this.props}
        comments={commentsWithNotifications}
        showComments={showComments}
        toggleComments={this.toggleComments}
      />
    );
  }
}

ChangeListItemContainer.propTypes = {
  // Passed props
  changeId: sowTypes.planAppChangeIdType,

  // Connected props
  change: sowTypes.planAppChangeType.isRequired,
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  questionId: sowTypes.planAppWorksheetQuestionIdType,
  locationId: sowTypes.planAppLocationIdType,
  worksheetId: sowTypes.planAppWorksheetIdType,
  questionName: PropTypes.string,
  worksheetName: PropTypes.string,
  children: PropTypes.node,
  comments: PropTypes.arrayOf(sowTypes.planAppChangeCommentType).isRequired,
  showAcaUI: PropTypes.bool.isRequired,
};

ChangeListItemContainer.defaultProps = {
  children: null,
  questionId: null,
  locationId: null,
  worksheetId: null,
  questionName: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(ChangeListItemContainer);
