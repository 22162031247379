// import { call, put, takeEvery } from 'redux-saga/effects';

// import { REGISTER_SUCCESS } from 'sow/actions/orgRegistration';
// import * as exportApi from 'sow/api/org/exportFiles';
// import * as actions from 'sow/actions/pure';
// import { downloadExportUrl } from 'sow/utils/org';

// export function* orgRegistration({ payload }) {
//   const { orgId, appId, options } = payload;
//
//   try {
//     const res = yield call(exportApi.exportFiles, orgId, appId, options);
//     yield put(exportActions.exportFilesDone(res.data.path));
//     yield call(window.open, downloadExportUrl(res.data.path), '_blank');
//   } catch (err) {
//     yield put(exportActions.exportFilesFail(err.responseJSON));
//     yield put(actions.api.requestError(err));
//   }
// }

export default function* rootSaga() {
  // yield takeEvery(REGISTER_SUCCESS, orgRegistration);
}
