import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { planAppRequest } from 'sow/store/requests';
import { ResourceDetailLoader } from 'sow/store/helpers';
import { fromPlanApp, fromRouter } from 'sow/store/selectors';
import { orgIdType, planAppIdType } from 'sow/types';

const mapStateToProps = (state, props) => ({
  orgId: fromRouter.paramOrgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
});

const PlanAppLoader = ({ orgId, planAppId, ...props }) => (
  <ResourceDetailLoader
    resource={planAppRequest.planAppUrl(orgId)}
    resourceId={planAppId}
    entityType="planAppResp"
    {...props}
  />
);

PlanAppLoader.defaultProps = {
  autoLoad: false,
};

PlanAppLoader.propTypes = {
  orgId: orgIdType.isRequired,
  planAppId: planAppIdType.isRequired,
  children: PropTypes.func.isRequired,
  autoLoad: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppLoader);
