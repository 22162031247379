import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as registrationAction from 'sow/actions/pure/registration';
import * as userApi from 'sow/api/newApi/user';

export function* register({ payload, meta }) {
  const { user } = payload;

  try {
    const resp = yield call(userApi.register, user);
    const data = { ...resp.data };
    yield put(registrationAction.registerDone(data));

    if (meta && typeof meta.onSuccess === 'function') {
      yield call(meta.onSuccess, data);
    }
  } catch (error) {
    yield put(registrationAction.registerFail(error));
    yield put(actions.api.requestError(error));
  }
}

export function* validateMail({ payload, meta }) {
  const { emailHash } = payload;

  try {
    const resp = yield call(userApi.verifyEmail, emailHash);
    yield put(registrationAction.validateMailDone(resp.data));
    yield put(
      actions.shell.toast(
        'success',
        'Your account has been validated, you may now log in.',
      ),
    );

    if (meta && typeof meta.onSuccess === 'function') {
      yield call(meta.onSuccess, resp.data);
    }
  } catch (error) {
    if (error) {
      yield put(registrationAction.validateMailFail(error));
      yield put(actions.api.requestError(error));
    }
  }
}

export default function* rootSaga() {
  yield takeEvery(registrationAction.REGISTER, register);
  yield takeEvery(registrationAction.VALIDATE_MAIL, validateMail);
}
