import {
  UPDATE_FIELD,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
} from 'sow/actions/orgRegistration';

import { defaultOrg } from 'sow/utils/org';

const initialState = {
  org: defaultOrg(),
  isRegistering: false,
  registerErrors: null,
};

const actionHandlers = {
  [UPDATE_FIELD]: (state, { field, value }) => {
    return {
      ...state,
      org: {
        ...state.org,
        [field]: value,
      },
    };
  },
  [REGISTER]: state => ({ ...state, isRegistering: true }),
  [REGISTER_SUCCESS]: state => ({ ...state, isRegistering: false }),
  [REGISTER_FAILED]: (state, { errors }) => ({
    ...state,
    isRegistering: false,
    registerErrors: errors,
  }),
};

export default function orgRegistration(state = initialState, action) {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action.data) : state;
}

export function getOrgRegistrationState(state) {
  return state.orgRegistration;
}
