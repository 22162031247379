import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as itemSubtypeActions from 'sow/actions/pure/itemSubtype';
import { apiGet, apiPost, apiPut } from 'sow/sagas/api';

export const loadItemSubtypes = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/item_subtype', payload);

    if (response && meta.onSuccess) {
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchById = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/item_subtype/${payload}`);

    if (response && meta.onSuccess) {
      yield put(itemSubtypeActions.fetchByIdDone(response.data));
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addItemSubtype = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/item_subtype', payload);

    if (response && meta.onSuccess) {
      yield put(itemSubtypeActions.addItemSubtypeDone(response.data));
      yield put(actions.shell.toast('success', 'Item subtype created.'));
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateItemSubtype = function*({ id, payload, meta }) {
  try {
    const { response } = yield call(apiPut, `/admin/item_subtype/${id}`, payload);

    if (response) {
      yield put(itemSubtypeActions.updateItemSubtypeDone(response.data));
      yield put(actions.shell.toast('success', 'Item subtype updated.'));
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateSortOrder = function*({ payload }) {
  try {
    yield call(apiPost, '/admin/item_subtype/update_order', { updated_list: payload });
  } catch (error) {
    console.error(error);
  }
};

export const deleteItemSubtype = function*({ id, meta }) {
  try {
    const { response } = yield call(apiPut, `/admin/item_subtype/delete/${id}`);
    if (response) {
      yield put(actions.shell.toast('success', 'Item subtype deleted.'));
      if (meta.onSuccess) meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(itemSubtypeActions.LOAD_ITEM_SUBTYPES, loadItemSubtypes);
  yield takeEvery(itemSubtypeActions.FETCH_BY_ID, fetchById);
  yield takeEvery(itemSubtypeActions.ADD_ITEM_SUBTYPE, addItemSubtype);
  yield takeEvery(itemSubtypeActions.UPDATE_ITEM_SUBTYPE, updateItemSubtype);
  yield takeEvery(itemSubtypeActions.UPDATE_SORT_ORDER, updateSortOrder);
  yield takeEvery(itemSubtypeActions.DELETE_ITEM_SUBTYPE, deleteItemSubtype);
}
