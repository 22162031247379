import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import actions from 'sow/actions/pure';
import * as currentUser from 'sow/selectors/currentUser';
import LoginPage from 'sow/components/pages/LoginPage';

const mapStateToProps = (state, props) => ({
  isAuthenticating: currentUser.isAuthenticating(state, props),
  isAuthenticated: currentUser.isAuthenticated(state, props),
});

const mapDispatchToProps = {
  login: actions.auth.login,
};

class LoginPageContainer extends Component {
  state = {
    credentials: {
      email: '',
      password: '',
      remember_me: true,
    },
  };

  handleChange = (field, value) => {
    this.setState(R.assocPath(['credentials', field], value));
  };

  handleSubmit = () => {
    this.props.login(this.state.credentials);
  };

  render() {
    const { credentials } = this.state;
    const { isAuthenticated, isAuthenticating, location } = this.props;

    if (isAuthenticated) {
      const redirectTo = R.pathOr('/', ['state', 'postLoginRedirect'], location);
      return <Redirect to={redirectTo} />;
    }

    return (
      <LoginPage
        credentials={credentials}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        isAuthenticating={isAuthenticating}
      />
    );
  }
}

LoginPageContainer.propTypes = {
  // Connected props
  login: PropTypes.func.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(LoginPageContainer);
