import React from 'react';
import PropTypes from 'prop-types';

import { ResourceListLoader } from 'sow/store/helpers';
import Select from 'sow/components/molecules/Select';

const CountrySelectContainer = ({ disabled, ...props }) => (
  <ResourceListLoader autoLoad resource="org/register/country_list">
    {({ status, result: countryList }) => (
      <Select
        {...props}
        disabled={!status.success || disabled}
        options={(countryList || []).map(country => ({ value: country, text: country }))}
      />
    )}
  </ResourceListLoader>
);

CountrySelectContainer.propTypes = {
  disabled: PropTypes.bool,
};

CountrySelectContainer.defaultProps = {
  disabled: false,
};

export default CountrySelectContainer;
