import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import { apiGet, apiPost, apiDelete } from 'sow/sagas/api';

export const getAllByOrg = function*({ payload, meta }) {
  try {
    const { orgId, ...params } = payload;
    const { response } = yield call(apiGet, `/org/${orgId}/sample`, params);
    if (response.data) yield put(actions.sample.getAllByOrgDone(response.data));
  } catch (error) {
    console.error(error);
  }
};

export const addSample = function*({ payload, meta }) {
  const { orgId, ...params } = payload;

  try {
    const { response } = yield call(apiPost, `/org/${orgId}/sample`, params);

    if (response && meta.onSuccess) {
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteSample = function*({ payload, meta }) {
  const { orgId, sampleId } = payload;

  try {
    const { response } = yield call(apiDelete, `/org/${orgId}/sample/${sampleId}`);

    if (response && meta.onSuccess) {
      meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export const getOptions = function*() {
  try {
    const { response } = yield call(apiGet, `/sample/options`);

    yield put(actions.sample.getOptionsDone(response.data));
  } catch (error) {
    console.error(error);
  }
};

export const watchGetAllByOrg = function*({ payload, meta }) {
  try {
    yield call(getAllByOrg, payload, meta);
  } catch (error) {
    console.error(error);
  }
};

export const watchAddSample = function*({ payload, meta }) {
  try {
    yield call(addSample, payload, meta);
  } catch (error) {
    console.error(error);
  }
};

export const watchDeleteSample = function*({ payload, meta }) {
  try {
    yield call(deleteSample, payload, meta);
  } catch (error) {
    console.error(error);
  }
};

export const watchGetOptions = function*() {
  try {
    yield call(getOptions);
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(actions.sample.getAllByOrg, watchGetAllByOrg);
  yield takeEvery(actions.sample.add, watchAddSample);
  yield takeEvery(actions.sample.delete, watchDeleteSample);
  yield takeEvery(actions.sample.getOptions, watchGetOptions);
}
