import React from 'react';
import { Link } from 'react-router-dom';

import { loginRoute } from 'sow/routes';
import CenteredLayout from 'sow/components/templates/CenteredLayout';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';

const RequestNewPasswordDone = () => (
  <div className="body-wrapper">
    <CenteredLayout className="body-wrapper">
      <div className="row">
        <div className="col-md-8 col-md-offset-2 text-left">
          <Widget>
            <WidgetHeading>Password Reset</WidgetHeading>

            <WidgetBody>
              <p>
                You’ve successfully sent a request to reset your password. Check your e-
                mail for further instructions.
              </p>

              <div style={{ marginTop: '20px' }} />

              <div className="text-center">
                <Link to={loginRoute()}>Return to login page</Link>
              </div>
            </WidgetBody>
          </Widget>
        </div>
      </div>
    </CenteredLayout>
  </div>
);

export default RequestNewPasswordDone;
