import React, { Fragment } from 'react';

const PendingAsterisk = ({ deleted, updated, children, centered }) =>
  deleted || updated ? (
    <div className={centered ? 'answerBlock centered' : 'answerBlock'}>
      <div className="answerCell one">
        <span className="pendingAsterisk">*</span>
      </div>
      <div className="answerCell">
        <span className={deleted ? 'strikeThru' : ''}>{children}</span>
      </div>
    </div>
  ) : (
    <Fragment>{children}</Fragment>
  );

export default PendingAsterisk;
