import * as R from 'ramda';
import _words from 'underscore.string/words';
import _humanize from 'underscore.string/humanize';
import _titleize from 'underscore.string/titleize';
import _underscored from 'underscore.string/underscored';
import _isBlank from 'underscore.string/isBlank';

export const wordsToInitials = R.map(R.compose(R.toUpper, R.head));

export const words = R.curry(function(str) {
  return _words(str);
});

export const humanize = R.curry(_humanize);
export const titleize = R.curry(_titleize);
export const underscored = R.curry(_underscored);
export const isBlank = R.curry(_isBlank);
export const fullNameToInitials = R.compose(R.join(''), wordsToInitials);

export const initialize = (name, defaultInitials = 'N/A') => {
  if (!R.is(String, name) || isBlank(name)) {
    return defaultInitials;
  }

  const names = words(name);

  if (names.length > 1) {
    return fullNameToInitials([names[0], names.pop()]);
  }

  return fullNameToInitials(names);
};

export const abbreviateLoc = (name, defaultInitials = 'N/A') => {
  if (!R.is(String, name) || isBlank(name)) {
    return defaultInitials;
  }

  const names = words(name);

  if (names.length > 1) {
    return fullNameToInitials([names[0], names[1]]);
  }

  return fullNameToInitials(names);
};
