import * as R from 'ramda';

const byId = (state = {}, action) => {
  const newAcas = R.path(['payload', 'entities', 'acas'], action);
  if (newAcas) {
    return R.merge(state, newAcas);
  }

  return state;
};

export default byId;

export const getIds = state => Object.keys(state);
export const getAca = (state, id) => state[id];
export const getAcaList = state => getIds(state).map(id => state[id]);
