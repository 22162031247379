import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { acaIdType } from 'sow/types';
import * as acaAdminAction from 'sow/actions/acaAdmin';
import * as messagingAction from 'sow/actions/pure/messaging';
import * as currentAca from 'sow/selectors/currentAca';
import AcaSettingsPageTemplate from 'sow/components/templates/AcaSettingsPageTemplate';
import AcaStaffPage from 'sow/components/pages/AcaStaffPage';
import Spinner from 'sow/components/atoms/Spinner';

const mapStateToProps = (state, props) => ({
  acaId: currentAca.acaId(state, props),
  isAcaAdminsFetching: state.acaAdminList.isFetching,
  acaAdmins: state.acaAdminList.items,
});

const mapDispatchToProps = {
  fetchAcaAdminList: acaAdminAction.fetchAcaAdminList,
  confirm: messagingAction.confirm,
  revokeAcaAdmin: acaAdminAction.revokeAcaAdmin,
};

class AcaSettingsStaffPageContainer extends Component {
  componentDidMount() {
    this.props.fetchAcaAdminList(this.props.acaId);
  }

  handleRevoke = (acaId, admin) => () => {
    this.props.confirm({
      message: 'Are you sure you want to revoke access for this user?',
      onConfirm: () => {
        this.props.revokeAcaAdmin(acaId, admin.id);
      },
    });
  };

  render() {
    const { acaAdmins, isAcaAdminsFetching } = this.props;
    const { acaId } = this.props;

    return (
      <AcaSettingsPageTemplate>
        {isAcaAdminsFetching && <Spinner />}
        {!isAcaAdminsFetching && (
          <AcaStaffPage
            acaId={acaId}
            acaAdmins={acaAdmins}
            onRevoke={this.handleRevoke}
          />
        )}
      </AcaSettingsPageTemplate>
    );
  }
}

AcaSettingsStaffPageContainer.propTypes = {
  acaId: acaIdType.isRequired,
  isAcaAdminsFetching: PropTypes.any,
  acaAdmins: PropTypes.any,
  fetchAcaAdminList: PropTypes.any,
  confirm: PropTypes.any,
  revokeAcaAdmin: PropTypes.any,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AcaSettingsStaffPageContainer);
