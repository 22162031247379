import { apiPost } from 'sow/utils/apiNew';
import { baseUrl } from './index';

export const requestPasswordReset = email =>
  apiPost(`${baseUrl}/request_password_reset`, { email });

export const resetPassword = data => apiPost(`${baseUrl}/reset_password`, data);

export const requestPasswordForNewAccount = email =>
  apiPost(`${baseUrl}/set_password_email`, { email });
