import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as currentUser from 'sow/selectors/currentUser';
import actions from 'sow/actions/pure';
import ImpersonationBorder from 'sow/components/organisms/ImpersonationBorder';

const mapStateToProps = (state, props) => ({
  impersonatedUser: currentUser.name(state, props),
  impersonatedUserId: currentUser.id(state, props),
  impersonating: currentUser.impersonating(state, props),
});

const mapDispatchToProps = {
  endUserImpersonation: actions.auth.endImpersonation,
};

class ImpersonationBorderContainer extends Component {
  endImpersonation() {
    const { impersonatedUserId } = this.props;
    this.props.endUserImpersonation({ impersonatedUserId });
  }

  render() {
    return (
      <ImpersonationBorder
        endImpersonation={this.endImpersonation.bind(this)}
        show={this.props.impersonating}
        {...this.props}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ImpersonationBorderContainer);
