import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import * as adminRequestActions from 'sow/actions/adminRequest';

import Button from 'sow/components/atoms/Button';

const mapStateToProps = (state, props) => {
  return {
    requestList: state.adminRequests.worksheetRequests.list
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    orgId,
    planAppId,
    changeRequestId,
  } = ownProps;

  return {
    createRequest: (orgId, planAppId, body) => dispatch(adminRequestActions.createAdminRequest(orgId, planAppId, body)),
    clearRequest: (orgId, planAppId, requestId) => dispatch(adminRequestActions.clearAdminRequest(orgId, planAppId, requestId)),
  };
};

const PlanAppWorksheetCreateRequestButtonContainer = ({
  showAcaUI,
  disabled,
  orgId,
  planAppId,
  worksheetId,
  worksheetName,
  createRequest,
  clearRequest,
  requestList,
}) => {

  const onCreateRequest = () => {
    const body = {
      worksheet_id: worksheetId,
      worksheet_name: worksheetName,
    };
    createRequest(orgId, planAppId, body);
  }

  const onClearRequest = (requestId) => {
    clearRequest(orgId, planAppId, requestId)
  }

  const match = requestList.filter(item => worksheetId == item.worksheet_id && item.question_id == null);
  const requestId = match.length > 0 ? match[0].id : 0;
  const label = (match.length > 0) ? 'Clear To-do' : 'Request To-do';
  const handler = (match.length > 0) ? () => onClearRequest(requestId) : () => onCreateRequest();
  
  return (
    <Button
      sm
      warning={!showAcaUI}
      primary={showAcaUI}
      onClick={handler}
      disabled={disabled}
    >
      {showAcaUI && 
        <Fragment>
          { label }
        </Fragment>
      }
    </Button>
  );
};

PlanAppWorksheetCreateRequestButtonContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheetId: sowTypes.worksheetIdType,
  worksheetName: PropTypes.string,
  matrixRowId: PropTypes.string,
  questionId: sowTypes.planAppWorksheetQuestionIdType,
  questionName: PropTypes.string,
  showAcaUI: PropTypes.bool
};

PlanAppWorksheetCreateRequestButtonContainer.defaultProps = {
  matrixRowId: null,
  locationId: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanAppWorksheetCreateRequestButtonContainer);
