import React, { Component } from 'react';

import Atom from 'sow/components/atoms/Atom';
import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import PageButton from 'sow/components/molecules/PaginatedTable/PageButton';

class PaginatedTable extends Component {
  constructor(props) {
    super(props);
    this.state = { firstIndex: 1, secondIndex: 25 };
  }

  componentDidMount() {
    const { tableData } = this.props;
    const { pageNumber, totalPages, totalEntries, pageSize } = tableData;

    const firstIndex = pageNumber * pageSize - pageSize + 1;
    const secondIndex =
      pageNumber === totalPages
        ? totalEntries - (pageNumber - 1) * pageSize + (pageNumber - 1) * pageSize
        : pageNumber * pageSize;
    this.setState({ firstIndex, secondIndex });
  }

  generateButtons() {
    const { tableData, selectPage } = this.props;
    const buttonList = [];
    if (tableData.totalPages <= 5) {
      for (let i = 1; i <= tableData.totalPages; i++) {
        buttonList.push(
          <PageButton
            key={i}
            pageNumber={i}
            selectedPage={tableData.pageNumber}
            selectPage={selectPage}
          />,
        );
      }
    } else if (tableData.pageNumber < 4) {
      for (let i = 1; i <= 6; i++) {
        if (i < 5) {
          buttonList.push(
            <PageButton
              key={i}
              pageNumber={i}
              selectedPage={tableData.pageNumber}
              selectPage={selectPage}
            />,
          );
        } else if (i === 5) {
          buttonList.push(<Atom key={5}>...</Atom>);
        } else if (i === 6) {
          buttonList.push(
            <PageButton
              key={6}
              pageNumber={tableData.totalPages}
              selectedPage={tableData.pageNumber}
              selectPage={selectPage}
            />,
          );
        }
      }
    } else if (tableData.pageNumber <= tableData.totalPages - 3) {
      buttonList.push(
        <PageButton
          key={1}
          pageNumber={1}
          selectedPage={tableData.pageNumber}
          selectPage={selectPage}
        />,
        <Atom key={2}>...</Atom>,
        <PageButton
          key={tableData.pageNumber - 1}
          pageNumber={tableData.pageNumber - 1}
          selectedPage={tableData.pageNumber}
          selectPage={selectPage}
        />,
        <PageButton
          key={tableData.pageNumber}
          pageNumber={tableData.pageNumber}
          selectedPage={tableData.pageNumber}
          selectPage={selectPage}
        />,
        <PageButton
          key={tableData.pageNumber + 1}
          pageNumber={tableData.pageNumber + 1}
          selectedPage={tableData.pageNumber}
          selectPage={selectPage}
        />,
        <Atom key={tableData.totalPages - 1}>...</Atom>,
        <PageButton
          key={tableData.totalPages}
          pageNumber={tableData.totalPages}
          selectedPage={tableData.pageNumber}
          selectPage={selectPage}
        />,
      );
    } else {
      buttonList.push(
        <PageButton
          key={1}
          pageNumber={1}
          selectedPage={tableData.pageNumber}
          selectPage={selectPage}
        />,
        <Atom key={2}>...</Atom>,
        <PageButton
          key={tableData.totalPages - 3}
          pageNumber={tableData.totalPages - 3}
          selectedPage={tableData.pageNumber}
          selectPage={selectPage}
        />,
        <PageButton
          key={tableData.totalPages - 2}
          pageNumber={tableData.totalPages - 2}
          selectedPage={tableData.pageNumber}
          selectPage={selectPage}
        />,
        <PageButton
          key={tableData.totalPages - 1}
          pageNumber={tableData.totalPages - 1}
          selectedPage={tableData.pageNumber}
          selectPage={selectPage}
        />,
        <PageButton
          key={tableData.totalPages}
          pageNumber={tableData.totalPages}
          selectedPage={tableData.pageNumber}
          selectPage={selectPage}
        />,
      );
    }
    return buttonList;
  }

  render() {
    const { firstIndex, secondIndex } = this.state;
    const { children, tableData, selectPage } = this.props;

    return (
      <Block className="paginated-table">
        {children}
        <Block className="page-data-block">
          <Block>
            Showing {tableData.totalEntries > 0 ? firstIndex : 0} to {secondIndex} of{' '}
            {tableData.totalEntries} entries
          </Block>
          <Block className="page-select-block">
            <Button
              className="page-index-button"
              onClick={() => selectPage(tableData.pageNumber - 1)}
              disabled={tableData.pageNumber < 2}
            >
              Previous
            </Button>
            {this.generateButtons().map(button => button)}
            <Button
              className="page-index-button"
              onClick={() => selectPage(tableData.pageNumber + 1)}
              disabled={tableData.pageNumber === tableData.totalPages}
            >
              Next
            </Button>
          </Block>
        </Block>
      </Block>
    );
  }
}

export default PaginatedTable;
