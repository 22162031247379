const mark = action => `INSPECTION_YEAR/${action}`;

export const LOAD_INSPECTION_YEARS = mark('LOAD_INSPECTION_YEARS');
export const LOAD_INSPECTION_YEAR_BY_ID = mark('LOAD_INSPECTION_YEAR_BY_ID');
export const ADD_INSPECTION_YEAR = mark('ADD_INSPECTION_YEAR');
export const DELETE_INSPECTION_YEAR = mark('DELETE_INSPECTION_YEAR');
export const UPDATE_INSPECTION_YEAR = mark('UPDATE_INSPECTION_YEAR');

export const loadInspectionYears = (payload, onSuccess) => ({
  type: LOAD_INSPECTION_YEARS,
  payload,
  meta: { onSuccess },
});

export const loadInspectionYearById = (id, onSuccess) => ({
  type: LOAD_INSPECTION_YEAR_BY_ID,
  id,
  meta: { onSuccess },
});

export const addInspectionYear = (payload, onSuccess) => ({
  type: ADD_INSPECTION_YEAR,
  payload,
  meta: { onSuccess },
});

export const updateInspectionYear = (id, payload, onSuccess) => ({
  type: UPDATE_INSPECTION_YEAR,
  id,
  payload,
  meta: { onSuccess },
});

export const deleteInspectionYear = (id, onSuccess) => ({
  type: DELETE_INSPECTION_YEAR,
  id,
  meta: { onSuccess },
});
