import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import deepEqual from 'fast-deep-equal';

import * as sowTypes from 'sow/types';
import actions from 'sow/actions/pure';
import { fromPlanApp } from 'sow/store/selectors';
import { resourceUpdate } from 'sow/store/helpers';
import * as currentUser from 'sow/selectors/currentUser';
import PlanAppLocationEditChangeModal from 'sow/components/organisms/PlanAppLocationEditChangeModal';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  locationId: fromPlanApp.locationId(state, props),
  locationDetails: fromPlanApp.locationDetails(state, props),
  planAppLocation: fromPlanApp.planAppLocation(state, props),
  planAppLocationChange: fromPlanApp.locationChange(state, props),
  isStateInitialApplication: fromPlanApp.isStateInitialApplication(state, props),
  isPlanAppOpen: fromPlanApp.isPlanAppOpen(state, props),
  isCSStaff: currentUser.isCSStaff(state, props),
});

const mapResource = () => {
  const updateChangeResource = (orgId, planAppId, changeRequestId) =>
    resourceUpdate(
      `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`,
      'changeRequestOverviewResp',
    );

  const acceptChangeResource = (orgId, planAppId, changeRequestId, changeId) =>
    resourceUpdate(
      `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes/${changeId}/accept`,
      'changeRequestOverviewResp',
    );

  const mapResourceDispatchToProps = (dispatch, ownProps) => {
    const {
      orgId,
      planAppId,
      changeRequestId,
      planAppLocationChange,
      locationId,
      locationDetails,
      isCSStaff,
    } = ownProps;
    const { action } = updateChangeResource(orgId, planAppId, changeRequestId);

    return {
      onSubmit: values => {
        const { locationChange, location } = values;
        const {
          imageSrc: oldImage,
          type: oldType,
          scope: oldScope,
          ...oldLocation
        } = locationDetails;
        const { imageSrc, type, scope, ...newLocation } = location;
        if (R.isNil(planAppLocationChange) && !deepEqual(location, locationDetails)) {
          const change = {
            context: 'land',
            type: 'self',
            action: 'updated',
            landId: locationId,
            old: oldLocation,
            new: newLocation,
          };
          return dispatch(action(null, { changes: [change] }))
            .then(() => dispatch(actions.shell.toast('success', 'Changes saved.')))
            .catch(() =>
              dispatch(
                actions.shell.toast('danger', 'There was a problem saving the changes.'),
              ),
            );
        } else {
          // If location values have not been updated with previous version of change, do not attempt to update
          if (!R.isNil(planAppLocationChange)) {
            if (
              !planAppLocationChange ||
              deepEqual(locationChange, planAppLocationChange.new)
            ) {
              console.log('Form values unchanged, no update needed');
              return Promise.resolve();
            }
            if (!deepEqual(locationChange, planAppLocationChange.new)) {
              // Update the 'new' property in the change object with the values submitted by the form
              const updatedChange = R.assoc('new', locationChange, planAppLocationChange);
              return dispatch(action(null, { changes: [updatedChange] }))
                .then(response => {
                  if (isCSStaff) {
                    // Find the location details change to auto accept if changed by staff member.
                    const changes = R.pathOr([], ['data', 'changes'], response);
                    const change = R.find(R.propEq('type', 'self'), changes);

                    if (change) {
                      const { action } = acceptChangeResource(
                        orgId,
                        planAppId,
                        changeRequestId,
                        change.id,
                      );
                      return dispatch(action(null)).then(() =>
                        dispatch(actions.shell.toast('success', 'Changes saved.')),
                      );
                    }
                  } else {
                    return dispatch(actions.shell.toast('success', 'Changes saved.'));
                  }
                })
                .catch(() =>
                  dispatch(
                    actions.shell.toast(
                      'danger',
                      'There was a problem saving the changes.',
                    ),
                  ),
                );
            } else {
              // Update the 'new' property in the change object with the values submitted by the form
              const updatedChange = R.assoc('new', values, planAppLocationChange);
              return dispatch(action(null, { changes: [updatedChange] }))
                .then(() => dispatch(actions.shell.toast('success', 'Changes saved.')))
                .catch(() =>
                  dispatch(
                    actions.shell.toast(
                      'danger',
                      'There was a problem saving the changes.',
                    ),
                  ),
                );
            }
          } else {
            console.log('Form values unchanged, no update needed');
            return Promise.resolve();
          }
        }
      },
    };
  };

  return connect(null, mapResourceDispatchToProps);
};

const LocationEditChangeModalContainer = ({ children, ...props }) => {
  return (
    <PlanAppLocationEditChangeModal {...props}>{children}</PlanAppLocationEditChangeModal>
  );
};

LocationEditChangeModalContainer.propTypes = {
  children: PropTypes.func.isRequired,
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  changeRequestId: sowTypes.planAppChangeRequestIdType,
  locationId: sowTypes.planAppLocationIdType.isRequired,
  planAppLocation: sowTypes.planAppLocationType,
  planAppLocationChange: sowTypes.planAppChangeType,
  onSubmit: PropTypes.func.isRequired,
  isStateInitialApplication: PropTypes.bool.isRequired,
  isPlanAppOpen: PropTypes.bool.isRequired,
  isCSStaff: PropTypes.bool.isRequired,
  isEditAllowed: PropTypes.bool.isRequired,
};

LocationEditChangeModalContainer.defaultProps = {
  changeRequestId: null,
  planAppLocation: null,
  planAppLocationChange: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
  mapResource(),
)(LocationEditChangeModalContainer);
