import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import Image from 'sow/components/atoms/Image';

import * as sowTypes from 'sow/types';
import * as currentAca from 'sow/selectors/currentAca';
import { confirm } from 'sow/actions/pure/messaging';
import * as ospDefAction from 'sow/actions/ospDefinition';
import * as currentUser from 'sow/selectors/currentUser';
import * as currentOspDef from 'sow/selectors/currentOspDef';
import * as ospDefReducer from 'sow/reducers/ospDefinitions';
import AcaPlanDashboardPage from 'sow/components/pages/AcaPlanDashboardPage';
import AcaPageTemplate from 'sow/containers/AcaPageTemplate';
import Spinner from 'sow/components/atoms/Spinner';
import OspDefListLoader from '../OspDefListLoader';

const sortOspDefList = (ospDefList, currentOspDefId) =>
  R.sortWith([
    R.descend(R.pathEq(['id'], currentOspDefId)),
    R.descend(ospDefReducer.whenUpdated),
  ])(ospDefList);

export const sortedOspDefList = createSelector(
  [currentOspDef.ospDefList, currentOspDef.ospDefId],
  sortOspDefList,
);

const mapStateToProps = (state, props) => ({
  aca: currentAca.aca(state, props),
  acaId: currentAca.acaId(state, props),
  acaName: currentAca.name(state, props),
  user: {
    isSuperAdmin: currentUser.isSuperAdmin(state, props),
    isAcaAdmin: currentUser.isAcaAdmin(state, props),
    isAdminXrayMode: currentUser.isAdminXrayMode(state, props),
  },
  currentOspDefId: currentOspDef.ospDefId(state, props),
  ospDefList: sortedOspDefList(state, props),
  ospQualificationQuestions: state.ospQualificationQuestions.byId,
  sections: state.ospSections.byId, //currentOspDef.allOspSections(state, props),
  worksheetQuestions: state.worksheetQuestions.byId,
  worksheets: state.worksheets.byId,
});

const mapDispatchToProps = dispatch => ({
  confirmPublish: (acaId, ospDefId) => event =>
    dispatch(
      confirm({
        message: 'Publish this Plan Definition?',
        onConfirm() {
  		  showModal();
		  dispatch(ospDefAction.publishOspDefForAca(acaId, ospDefId));
        },
      }),
    ),

  confirmUnpublish: (acaId, ospDefId) => event =>
    dispatch(
      confirm({
        message: 'Unpublish this Plan Definition?',
        onConfirm() {
          dispatch(ospDefAction.unpublishOspDefForAca(acaId, ospDefId));
        },
      }),
    ),
});

		
function hideModal() {
	$("#saveModalBackdrop").hide();
	$("#saveModal").hide();
}

function showModal() {
	$("#saveModalBackdrop").show();
	$("#saveModal").show();
}


const AcaPlanDashboardPageContainer = ({
  ospDefList,
  user,
  aca,
  acaId,
  acaName,
  currentOspDefId,
  ospQualificationQuestions,
  sections,
  worksheetQuestions,
  worksheets,
  confirmPublish,
  confirmUnpublish,
}) => (
  <AcaPageTemplate title="Plan Definitions">
	  <div role="dialog">
			<div id="saveModalBackdrop" className="fade modal-backdrop in" style={{'display': 'none'}}></div>
			<div id="saveModal" role="dialog" className="modal">
				<div className="modal-dialog">
					<div className="modal-content" role="document">
						<div className="modal-header">
							<h4 className="modal-title">Publishing Plan Definition <Image src="/assets/images/spinner-small.gif" /></h4>
						</div>
						<div className="modal-body">Updating all associated applications. Please wait. This will take some time.
						</div>
					</div>
				</div>
			</div>
		</div>
    <OspDefListLoader>
      {({ isLoading }) =>
        isLoading ? (
          <Spinner />
        ) : (
          <AcaPlanDashboardPage
            user={user}
            ospDefList={ospDefList}
            aca={aca}
            acaId={acaId}
            acaName={acaName}
            currentOspDefId={currentOspDefId}
            ospQualificationQuestions={ospQualificationQuestions}
            sections={sections}
            worksheetQuestions={worksheetQuestions}
            worksheets={worksheets}
            confirmPublish={confirmPublish}
            confirmUnpublish={confirmUnpublish}
          />
        )
      }
    </OspDefListLoader>
  </AcaPageTemplate>
);

AcaPlanDashboardPageContainer.propTypes = {
  ospDefList: PropTypes.arrayOf(sowTypes.ospDefType),
  user: sowTypes.ospDefUserType.isRequired,
  aca: sowTypes.acaType,
  acaId: sowTypes.acaIdType,
  acaName: PropTypes.string,
  currentOspDefId: sowTypes.ospDefIdType,
  ospQualificationQuestions: PropTypes.objectOf(sowTypes.ospQualificationQuestionType),
  sections: PropTypes.objectOf(sowTypes.ospSectionType),
  worksheetQuestions: PropTypes.objectOf(sowTypes.worksheetQuestionType),
  worksheets: PropTypes.objectOf(sowTypes.worksheetType),
  confirmPublish: PropTypes.func.isRequired,
  confirmUnpublish: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AcaPlanDashboardPageContainer);
