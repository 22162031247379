import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import moment from 'moment';

import { planAppWorksheetQuestionType } from 'sow/types';
import { API_URL } from 'sow/constants/api';

import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';
import List from 'sow/components/atoms/List';
import ListItem from 'sow/components/atoms/ListItem';

const fileUrl = fileId => `${API_URL}/v1/file/${fileId}`;

export const FileDownloadLink = ({ children, fileId }) => (
  <Block>
    <Link href={fileUrl(fileId)} target="_blank">{children}</Link>
  </Block>
);

FileDownloadLink.propTypes = {
  children: PropTypes.node,
  fileId: PropTypes.number,
};

const BoolAnswerRenderer = ({ value }) => {
  if (value === false) return 'No';
  if (value === true) return 'Yes';
  return '';
};

BoolAnswerRenderer.propTypes = {
  value: PropTypes.any,
  question: planAppWorksheetQuestionType.isRequired,
};

const TextAnswerRenderer = ({ value }) => {
  return value || '';
};

TextAnswerRenderer.propTypes = {
  value: PropTypes.any,
  question: planAppWorksheetQuestionType.isRequired,
};

const CheckboxListAnswerRenderer = ({ value, question }) => {
  if (Array.isArray(value)) {
    return value.join(', ');
  }

  const valueFormatVerion = R.pipe(
    R.pathOr(0, ['__version']),
    parseInt,
  )(value);

  if (valueFormatVerion === 1) {
    const answers = R.defaultTo([], R.path(['answers'], question));
    const yesAnswers = answers.filter(R.propEq(R.__, true, value));
    return (
      <List>
        {yesAnswers.map(answer => (
          <ListItem key={answer}>{answer}</ListItem>
        ))}
      </List>
    );
  }

  return '';
};

CheckboxListAnswerRenderer.propTypes = {
  value: PropTypes.any,
  question: planAppWorksheetQuestionType.isRequired,
};

const DateAnswerRenderer = ({ value, question }) => {
  try {
    return value ? moment(value).format('L') : '';
  } catch (e) {
    console.error('Error formatting date for matrix cell', e);
  }

  return '';
};

DateAnswerRenderer.propTypes = {
  value: PropTypes.any,
  question: planAppWorksheetQuestionType.isRequired,
};

const FilesAnswerRenderer = ({ value, question }) => {
  if (value) {
    return value.map(({ id, name }) => (
      <FileDownloadLink fileId={id} key={id}>
        {name}
      </FileDownloadLink>
    ));
  }

  return '';
};

FilesAnswerRenderer.propTypes = {
  value: PropTypes.any,
  question: planAppWorksheetQuestionType.isRequired,
};

const WorksheetMatrixCellAnswer = ({ field, question }) => {
  const { value } = field;
  const fieldProps = { value, question };

  switch (question.fieldType) {
    case 'bool':
      return <BoolAnswerRenderer {...fieldProps} />;

    case 'checkbox_list':
      return <CheckboxListAnswerRenderer {...fieldProps} />;

    case 'date':
      return <DateAnswerRenderer {...fieldProps} />;

    case 'files':
      return <FilesAnswerRenderer {...fieldProps} />;

    default:
      return <TextAnswerRenderer {...fieldProps} />;
  }
};

WorksheetMatrixCellAnswer.propTypes = {
  value: PropTypes.any,
  question: planAppWorksheetQuestionType.isRequired,
};

export default WorksheetMatrixCellAnswer;
