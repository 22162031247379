import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/** Renders an basic heading tag */
const Heading = ({
  level,
  children,
  className,
  pullLeft,
  pullRight,
  textCenter,
  textLeft,
  textRight,
  textMuted,
  textSuccess,
  textInfo,
  textWarning,
  textDanger,
  pageTitle,
  noMargin,
  style,
  ...props
}) => {
  const classes = classNames(className, {
    'pull-left': pullLeft,
    'pull-right': pullRight,
    'text-center': textCenter,
    'text-left': textLeft,
    'text-right': textRight,
    'text-muted': textMuted,
    'text-success': textSuccess,
    'text-info': textInfo,
    'text-warning': textWarning,
    'text-danger': textDanger,
    'page-title': pageTitle,
  });

  const styles = { ...style };
  if (noMargin) {
    styles.margin = '0 auto 0 auto';
  }

  return React.createElement(
    `h${level}`,
    { ...props, className: classes, style: styles },
    children,
  );
};

Heading.propTypes = {
  style: PropTypes.object,
  level: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  pullLeft: PropTypes.bool,
  pullRight: PropTypes.bool,
  textCenter: PropTypes.bool,
  textLeft: PropTypes.bool,
  textRight: PropTypes.bool,
  textMuted: PropTypes.bool,
  pageTitle: PropTypes.bool,
  noMargin: PropTypes.bool,
  textSuccess: PropTypes.bool,
  textInfo: PropTypes.bool,
  textWarning: PropTypes.bool,
  textDanger: PropTypes.bool,
};

Heading.defaultProps = {
  style: null,
  level: 1,
  noMargin: false,
};

export default Heading;
