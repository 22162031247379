import { apiPut } from 'sow/utils/apiNew';

import { acaUrl } from './index';

export const baseUrl = acaId => `${acaUrl(acaId)}/osp`;
export const ospDefUrl = (acaId, ospDefId) => `${baseUrl(acaId)}/${ospDefId}`;

export const updateOne = (acaId, ospDef) => {
  const url = ospDefUrl(acaId, ospDef.id);
  return apiPut(url, { osp_definition: ospDef });
};
