const mark = action => `ITEM_SECTION_TYPE/${action}`;

export const LOAD_ITEM_SECTION_TYPES = mark('LOAD_ITEM_SECTION_TYPES');
export const FETCH_BY_ID = mark('FETCH_BY_ID');
export const ADD_ITEM_SECTION_TYPE = mark('ADD_ITEM_SECTION_TYPE');
export const UPDATE_ITEM_SECTION_TYPE = mark('UPDATE_ITEM_SECTION_TYPE');
export const UPDATE_SORT_ORDER = mark('UPDATE_SORT_ORDER');
export const DELETE_ITEM_SECTION_TYPE = mark('DELETE_ITEM_SECTION_TYPE');

export const loadItemSectionTypes = (payload, onSuccess) => ({
  type: LOAD_ITEM_SECTION_TYPES,
  payload,
  meta: { onSuccess },
});

export const fetchById = (payload, onSuccess) => ({
  type: FETCH_BY_ID,
  payload,
  meta: { onSuccess },
});

export const addItemSectionType = (payload, onSuccess) => ({
  type: ADD_ITEM_SECTION_TYPE,
  payload,
  meta: { onSuccess },
});

export const updateItemSectionType = (id, payload, onSuccess) => ({
  type: UPDATE_ITEM_SECTION_TYPE,
  id,
  payload,
  meta: { onSuccess },
});

export const updateSortOrder = (payload, onSuccess) => ({
  type: UPDATE_SORT_ORDER,
  payload,
  meta: { onSuccess },
});

export const deleteItemSectionType = (id, onSuccess) => ({
  type: DELETE_ITEM_SECTION_TYPE,
  id,
  meta: { onSuccess },
});
