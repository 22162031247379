import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { planAppQualificationQuestionType, planAppChangeType } from 'sow/types';

import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Block from 'sow/components/atoms/Block';
import Form from 'sow/components/atoms/Form';
import PlanAppActionBar from 'sow/components/organisms/PlanAppActionBar';
import QualificationChecklistQuestion from 'sow/components/organisms/QualificationChecklistQuestion';
import QualificationChecklistCreateChangeButton from 'sow/containers/planApp/QualificationChecklistCreateChangeButton';
import PlanAppChange from 'sow/containers/planApp/Change';
import Confirm from 'sow/components/molecules/Confirm';
import Paragraph from 'sow/components/atoms/Paragraph';

// no operation fn
const noop = () => {};

const confirmSubmitMessage = (
  <Fragment>
    <Paragraph>
      The Activities Checklist determines which worksheets will be included in your
      Organic System Plan (“Plan”). It is important to select the correct activities to
      create an accurate Plan.
    </Paragraph>
    <Paragraph>
      Please double-check that you have selected all of the relevant statements describing
      your operation, and only those statements that apply to your operation.
    </Paragraph>
    <Paragraph>
      Once you submit the Plan for review it may still be edited, but there will be
      additional steps and answer verification to make changes to your worksheets. Save
      time, check twice!
    </Paragraph>
  </Fragment>
);

const QualificationChecklistForm = ({
  questions,
  answers,
  onChange,
  onClickSubmit,
  onClickSubmitNext,
  isUpdating,
  isNewApp,
  changeRequestIsOpen,
  qualificationAnswersChange,
  onQualificationAnswersChangeChange,
}) => {
  const hasChange = !R.isNil(qualificationAnswersChange);
  const showCreateChangeButton = changeRequestIsOpen && !hasChange;

  const changeAnswers = R.pathOr(
    [],
    ['new', 'qualificationAnswers', 'values'],
    qualificationAnswersChange,
  );

  const qualificationAnswersChangeId = R.path(['id'], qualificationAnswersChange);

  const submitDisabled =
    isUpdating ||
    (!isNewApp && changeRequestIsOpen && !qualificationAnswersChange) ||
    (!isNewApp && !changeRequestIsOpen);

  const ConfirmSubmit = props => (
    <Confirm
      title="Please review your selections"
      message={confirmSubmitMessage}
      cancelButtonText="Cancel and Review"
      confirmButtonText="Submit Activities Checklist"
      {...props}
    />
  );

  return (
    <Widget>
      <WidgetHeading>
        Activities Checklist
        {showCreateChangeButton && (
          <Block pullRight>
            <QualificationChecklistCreateChangeButton answers={answers} />
          </Block>
        )}
      </WidgetHeading>

      <Form onSubmit={noop}>
        <WidgetBody>
          <PlanAppChange
            changeId={qualificationAnswersChangeId}
            renderAnswer={() => (
              <Fragment>
                {questions.map(q => (
                  <QualificationChecklistQuestion
                    key={q.uuid}
                    question={q}
                    answers={answers}
                    onChange={onChange}
                    disabled={!isNewApp}
                  />
                ))}
              </Fragment>
            )}
            renderChange={() => (
              <Fragment>
                {questions.map(q => (
                  <QualificationChecklistQuestion
                    key={q.uuid}
                    question={q}
                    answers={changeAnswers}
                    onChange={onQualificationAnswersChangeChange}
                    disabled={!changeRequestIsOpen}
                  />
                ))}
              </Fragment>
            )}
          />
        </WidgetBody>

        <ConfirmSubmit onConfirm={onClickSubmit}>
          {confirmSubmit => (
            <ConfirmSubmit onConfirm={onClickSubmitNext}>
              {confirmSubmitNext => (
                <PlanAppActionBar
                  onClickSubmit={isNewApp ? confirmSubmit : onClickSubmit}
                  onClickSubmitNext={isNewApp ? confirmSubmitNext : onClickSubmitNext}
                  submitDisabled={submitDisabled}
                  prevButtonDisabled
                  saveable
                />
              )}
            </ConfirmSubmit>
          )}
        </ConfirmSubmit>
      </Form>
    </Widget>
  );
};

QualificationChecklistForm.propTypes = {
  /** Array of qualification questions that can be selected */
  questions: PropTypes.arrayOf(planAppQualificationQuestionType).isRequired,

  /** Array of question uuids that have been selected */
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,

  onChange: PropTypes.func.isRequired,
  onClickSubmit: PropTypes.func,
  onClickSubmitNext: PropTypes.func,
  isUpdating: PropTypes.bool.isRequired,
  isNewApp: PropTypes.bool.isRequired,
  changeRequestIsOpen: PropTypes.bool.isRequired,
  qualificationAnswersChange: planAppChangeType,
  onQualificationAnswersChangeChange: PropTypes.func,
};

export default QualificationChecklistForm;
