import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';

import * as wsAnswersAction from 'sow/actions/pure/worksheetAnswers';
import Question from 'sow/components/old/OspApp/Editor/Question';

const defaultWorksheetState = () => ({
  showNotesMap: {},
});

// TODO (orther) change this to functional stateless component and pass in onChange/getAnswer
class Worksheet extends React.Component {
  state = defaultWorksheetState();

  getAnswer = questionId => {
    const { getAnswer, wsAnswers } = this.props;

    if (typeof getAnswer === 'function') {
      return getAnswer(questionId);
    }

    return R.path(['answers', 'values', questionId], wsAnswers);
  };

  handleChangeAnswer = (questionId, value) => {
    if (this.props.handleChangeAnswer) {
      this.props.handleChangeAnswer(questionId, value);
    } else {
      const { ospAppId, worksheetId } = this.props;
      this.props.dispatch(
        wsAnswersAction.setAnswer(ospAppId, worksheetId, questionId, value),
      );
    }
  };

  /**
   * Determine if question should be visible based on question conditions.
   */
  isQuestionVisible = question => {
    // NOTE: we only implement a single basic condition at this point
    const conditionItem = R.pathOr(null, ['conditions', 0], question);

    if (conditionItem && conditionItem.condition_type === 'basic') {
      const condition = conditionItem.condition;
      const conditionAnswerValue = condition.answer_value;

      if (conditionAnswerValue !== null) {
        const answerValue = this.getAnswer(condition.question_uuid);
        if (answerValue !== conditionAnswerValue) {
          return false;
        }
      }
    }

    return true;
  };

  render() {
    const { orgId, ospAppId, worksheetId, worksheet, wsAnswers, disabled } = this.props;

    return (
      <ul className="list-group">
        {worksheet.questions.map(question => {
          // TODO: determine if conditional question and if value matches
          if (!this.isQuestionVisible(question)) return null;

          return (
            <li className="list-group-item" key={question.uuid}>
              <Question
                key={question.uuid}
                orgId={orgId}
                ospAppId={ospAppId}
                worksheetId={worksheetId}
                question={question}
                answer={this.getAnswer(question.uuid)}
                handleChange={this.handleChangeAnswer}
                disabled={disabled}
              />
            </li>
          );
        })}
      </ul>
    );
  }
}

const mapStateToProps = (state, props) => ({});

export default connect(mapStateToProps)(Worksheet);
