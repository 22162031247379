import React from 'react';

import './OspNotification.module.css';
import { ospNotificationBanners } from 'sow/constants/planApp';
import Block from 'sow/components/atoms/Block';
import Glyphicon from 'sow/components/molecules/Glyphicon';

const OspNotification = ({ banner }) => (
  <Block className="banner-container">
    <Block className="banner-header">
      <Glyphicon className="banner-icon" glyph="exclamation-sign" />
      {ospNotificationBanners[banner].header}
    </Block>
    {ospNotificationBanners[banner].body}
  </Block>
);

export default OspNotification;
