import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';

import { consultantCompaniesRoute } from 'sow/routes';
import * as consultantCompanyActions from 'sow/actions/pure/consultantCompany';
import ConsultantCompaniesEdit from 'sow/components/organisms/ConsultantCompaniesEdit';

const mapDispatchToProps = {
  loadConsultantCompanyById: consultantCompanyActions.loadConsultantCompanyById,
  addConsultantCompany: consultantCompanyActions.addConsultantCompany,
  deleteConsultantCompany: consultantCompanyActions.deleteConsultantCompany,
  updateConsultantCompany: consultantCompanyActions.updateConsultantCompany,
  redirect: routerPush,
};

class ConsultantCompaniesEditContainer extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = { isNew: false, isLoading: true, formValues: {} };
  }

  updateState({ data }) {
    if (this.state.isNew) {
      history.pushState(null, null, `${data.id}`);
    }
    if (this._isMounted) {
      if (!R.isEmpty(data)) {
        this.setState({
          isNew: false,
          isLoading: false,
          consultantId: data.id,
          formValues: { name: data.name },
        });
      } else {
        this.redirectToList();
      }
    }
  }

  redirectToList() {
    this.props.redirect(consultantCompaniesRoute());
  }

  componentDidMount() {
    this._isMounted = true;
    const routeId = R.path(['match', 'params', 'id'], this.props);

    if (routeId !== 'new') {
      this.props.loadConsultantCompanyById(routeId, this.updateState.bind(this));
    } else {
      this.setState({ isNew: true, isLoading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInputChange({ target }) {
    const { id, value } = target;
    const { formValues } = this.state;
    this.setState({ formValues: { ...formValues, [id]: value } });
  }

  handleSave() {
    const { isNew, formValues, consultantId } = this.state;
    formValues.name = formValues.name.trim();
    if (isNew) {
      this.props.addConsultantCompany(formValues, this.updateState.bind(this));
    } else {
      this.props.updateConsultantCompany(
        consultantId,
        formValues,
        this.updateState.bind(this),
      );
    }
  }

  handleDelete() {
    this.props.deleteConsultantCompany(
      this.state.consultantId,
      this.redirectToList.bind(this),
    );
  }

  render() {
    return (
      <ConsultantCompaniesEdit
        isNew={this.state.isNew}
        isLoading={this.state.isLoading}
        formValues={this.state.formValues}
        handleInputChange={this.handleInputChange.bind(this)}
        handleSave={this.handleSave.bind(this)}
        handleDelete={this.handleDelete.bind(this)}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(ConsultantCompaniesEditContainer);
