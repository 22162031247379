import actions from 'sow/actions/pure';

const initialState = {
  isFetchingCountryList: false,
  countryList: [],
};

const gisReducer = (state = initialState, action) => {
  switch (action.type) {
    case String(actions.gis.loadCountryList):
      return R.merge(state, {
        isFetchingCountryList: true,
        countryList: [],
      });

    case String(actions.gis.loadCountryListDone):
      return R.merge(state, {
        isFetchingCountryList: false,
        countryList: action.payload.items,
      });

    case String(actions.gis.loadCountryListFail):
      return R.assoc('isFetchingCountryList', false, state);

    default:
      return state;
  }
};
export default gisReducer;

export function getCountryListState(state) {
  return state.countryList.items;
}
