import React from 'react';
import * as R from 'ramda';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { API_URL } from 'sow/constants/api';

import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import FileUpload from 'sow/components/old/fileUpload/FileUpload';

class AcaUploadLogoModal extends React.Component {
  state = {
    show: false,
  };

  handleShow = event => {
    this.setState(R.assoc('show', true));
  };

  handleUploadFinish = () => {
    if (typeof this.props.onUploadFinish === 'function') {
      this.props.onUploadFinish();
    }
  };

  handleUploadStart = () => {
    if (typeof this.props.onUploadStart === 'function') {
      this.props.onUploadStart();
    }
  };

  handleUploadSuccess = file => {
    this.setState(R.assoc('show', false));
    this.handleUploadFinish();
  };

  render() {
    const { show } = this.state;

    const acaId = R.path(['aca', 'id'], this.props);
    const uploadUrl = `${API_URL}/v1/aca/${acaId}/logo`;

    return (
      <Block>
        <Button primary sm onClick={this.handleShow}>
          Upload Logo
        </Button>

        <Modal
          backdrop="static"
          bsSize="lg"
          show={show}
          onHide={() => this.setState({ show: false })}
        >
          <Modal.Header closeButton>Upload Logo</Modal.Header>

          <Modal.Body>
            <FileUpload
              uploadUrl={uploadUrl}
              onUploadSuccess={this.handleUploadSuccess}
              onUploadStart={this.handleUploadStart}
              dontRecordUpload
            />
          </Modal.Body>
        </Modal>
      </Block>
    );
  }
}

AcaUploadLogoModal.propTypes = {
  onUploadStart: PropTypes.func.isRequired,
  onUploadFinish: PropTypes.func.isRequired,
};

export default AcaUploadLogoModal;
