import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as currentOrg from 'sow/selectors/currentOrg';

import LandScopeSelect from 'sow/components/organisms/LandScopeSelect';

export const mapStateToProps = (state, props) => ({
  facilityScopes: currentOrg.facilityScopes(state, props),
  officeScopes: currentOrg.officeScopes(state, props),
  parcelScopes: currentOrg.parcelScopes(state, props),
  pastureScopes: currentOrg.pastureScopes(state, props),
});

export const LandScopeSelectContainer = ({
  landType,
  facilityScopes,
  officeScopes,
  parcelScopes,
  pastureScopes,
  ...props
}) => {
  const activeScopes = R.cond([
    [R.equals('Facility'), R.always(facilityScopes)],
    [R.equals('Office'), R.always(officeScopes)],
    [R.equals('Parcel'), R.always(parcelScopes)],
    [R.equals('Pasture'), R.always(pastureScopes)],
    [R.T, R.always([])],
  ])(landType);

  // Filter out unneeded props
  const { router, match, location, routes, dispatch, type, ...fieldProps } = props;
  return <LandScopeSelect {...fieldProps} scopeList={activeScopes} />;
};

LandScopeSelectContainer.propTypes = {
  required: PropTypes.bool,
  landType: PropTypes.string,
  facilityScopes: PropTypes.array,
  officeScopes: PropTypes.array,
  parcelScopes: PropTypes.array,
  pastureScopes: PropTypes.array,
  allScopes: PropTypes.array,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(LandScopeSelectContainer);
