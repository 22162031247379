import React from 'react';

import OrgSettingsPageTemplate from 'sow/containers/OrgSettingsPageTemplate';
import OrgSettingsEdit from 'sow/components/old/OrgSettings/Edit';
import OrgSettingsCertifications from 'sow/containers/OrgSettingsCertifications';

const OrgSettingsCertificationsTabPage = () => (
  <OrgSettingsPageTemplate>
    <OrgSettingsEdit>
      <OrgSettingsCertifications />
    </OrgSettingsEdit>
  </OrgSettingsPageTemplate>
);

export default OrgSettingsCertificationsTabPage;
