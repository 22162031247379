import * as R from 'ramda';
import {
  LOAD_ONE_FOR_ORG_REQUEST,
  LOAD_ONE_FOR_ORG_SUCCESS,
  LOAD_ONE_FOR_ORG_FAILURE,
} from 'sow/actions/ospApplication';

const ospAppId = R.path(['data', 'ospAppId']);

const isLoadingById = (state = [], action) => {
  switch (action.type) {
    case LOAD_ONE_FOR_ORG_REQUEST:
      return R.union([ospAppId(action)], state);

    case LOAD_ONE_FOR_ORG_FAILURE:
    case LOAD_ONE_FOR_ORG_SUCCESS:
      return R.without([ospAppId(action)], state);

    default:
      return state;
  }
};

export default isLoadingById;

export const getIsLoadingById = (state, id) => {
  // console.log('getIsLoadingById', R.contains(id, state), id, state);
  return R.contains(id, state);
};
