import React from 'react';

import Block from 'sow/components/atoms/Block';

const FormikSingleCheckbox = ({ value, id, setFieldValue, labelText }) => (
  <Block className="formik-checkbox-div">
    <input
      type="checkbox"
      className="formik-checkbox-input"
      checked={value}
      onChange={event => setFieldValue(id, event.target.checked)}
    />
    {labelText && <label className="formik-checkbox-label">{labelText}</label>}
  </Block>
);

export default FormikSingleCheckbox;
