import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import PlanAppExportContentWorksheet from 'sow/components/organisms/PlanAppExportContent/Worksheet';

const mapStateToProps = (state, props) => ({
  worksheet: fromPlanApp.worksheet(state, props),
  isNotApplicable: fromPlanApp.isWorksheetNotApplicable(state, props),
});

const PlanAppExportContentWorksheetContainer = ({ ...props }) => (
  <PlanAppExportContentWorksheet {...props} />
);

PlanAppExportContentWorksheetContainer.propTypes = {
  // Passed props
  planAppId: sowTypes.planAppIdType.isRequired,
  locationId: sowTypes.planAppLocationIdType.isRequired,
  worksheetId: sowTypes.planAppWorksheetIdType.isRequired,
  exportConfig: sowTypes.planAppExportConfigType.isRequired,

  // Connected props
  worksheet: sowTypes.planAppWorksheetType.isRequired,
  isNotApplicable: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppExportContentWorksheetContainer);
