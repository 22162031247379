import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { planAppChangeType } from 'sow/types';
import Block from 'sow/components/atoms/Block';
import Strong from 'sow/components/atoms/Strong';
import Link from 'sow/components/atoms/Link';
import Spacer from 'sow/components/atoms/Spacer';
import PlanAppChangeComments from 'sow/containers/planApp/ChangeCommentSection';
import ChangeSection from './ChangeSection';

const ToggleChangeVisibility = ({ visible, onClick }) => (
  <Block textRight>
    <Spacer vertical={10} />
    <Strong>
      {!visible && 'This answer has been changed. '}
      <Link href="" onClick={onClick} style={{ cursor: 'pointer' }}>
        {visible ? 'Hide change' : 'Show more'} details
      </Link>
    </Strong>
    <Spacer vertical={10} />
  </Block>
);

ToggleChangeVisibility.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

class PlanAppChange extends Component {
  constructor(props) {
    super(props);

    const { noCollapse, change, hasUnreadNotifications } = props;
    const changeState = R.pathOr(null, ['state'], change);

    let defaultCollapsed;
    // If a change doesn't exist or `noCollapse` prop is set, don't collapse
    if (noCollapse || R.isNil(change)) {
      defaultCollapsed = false;
      // If the change has unread notifications, don't collapse
    } else if (hasUnreadNotifications) {
      defaultCollapsed = false;
    } else {
      // Collapse if change is ignored or the state isn't "open" (pending)
      const isChangeIgnored = R.prop('ignored', change);
      const isChangeClosed = R.complement(R.equals('open'))(changeState);
      defaultCollapsed = R.any(R.equals(true), [isChangeIgnored, isChangeClosed]);
    }

    this.state = {
      collapsed: defaultCollapsed,
    };
  }

  toggleChangeVisbility = () =>
    this.setState(prevState => ({ collapsed: !prevState.collapsed }));

  componentDidUpdate(prevProps) {
    const { change } = this.props;
    if (prevProps.change && change && prevProps.change.state !== change.state) {
      this.setState({ collapsed: false });
    }
  }

  render() {
    const {
      change,
      renderAnswer,
      renderChange,
      onAccept,
      onReject,
      acceptStatus,
      rejectStatus,
      showAcaUI,
      showChangeUI,
      changeRequestIsOpen,
      noCollapse,
      changeUIDisabled,
    } = this.props;
    const { collapsed } = this.state;

    // If no change exists for the question, just render the answer
    if (!change) {
      return (
        <Block>
          <Spacer vertical={20} />
          {renderAnswer()}
          <Spacer vertical={20} />
        </Block>
      );
    }

    const visibilityToggle = noCollapse ? null : (
      <ToggleChangeVisibility visible={!collapsed} onClick={this.toggleChangeVisbility} />
    );

    // Render a partial view with only "current" answer shown
    if (collapsed) {
      return (
        <Block>
          <Spacer vertical={20} />
          {change.ignored || change.state === 'rejected' || change.state === 'not_applied'
            ? renderAnswer(change)
            : renderChange(change)}
          {visibilityToggle}
          <Spacer vertical={10} />
        </Block>
      );
    }

    // Render full change UI
    return (
      <Block>
        <Spacer vertical={10} />

        {renderAnswer && (
          <ChangeSection type="old" change={change}>
            {renderAnswer(change)}
          </ChangeSection>
        )}

        <Spacer vertical={10} />

        <ChangeSection
          type="new"
          change={change}
          showToolbar={showAcaUI && showChangeUI && changeRequestIsOpen}
          disableToolbar={
            acceptStatus.loading || rejectStatus.loading || changeUIDisabled
          }
          onAccept={onAccept}
          onReject={onReject}
        >
          {renderChange(change)}
        </ChangeSection>

        <Spacer vertical={10} />

        <PlanAppChangeComments changeId={change.id} />

        {visibilityToggle}
      </Block>
    );
  }
}

PlanAppChange.propTypes = {
  noCollapse: PropTypes.bool,
  change: planAppChangeType,
  showChangeUI: PropTypes.bool,
  showAcaUI: PropTypes.bool,
  renderAnswer: PropTypes.func,
  renderChange: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  acceptStatus: PropTypes.object.isRequired,
  rejectStatus: PropTypes.object.isRequired,
  changeRequestIsOpen: PropTypes.bool,
  changeUIDisabled: PropTypes.bool.isRequired,
  hasUnreadNotifications: PropTypes.bool.isRequired,
};

PlanAppChange.defaultProps = {
  renderAnswer: null,
  noCollapse: false,
};

export default PlanAppChange;
