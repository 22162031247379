import React from 'react';
import Select from 'react-select';

const FormikReactSelect = ({
  id,
  value,
  clearable,
  options,
  disabled,
  placeholder,
  setFieldValue,
  multi,
}) => (
  <Select
    ignoreAccents={false}
    clearable={clearable}
    value={value}
    onChange={event => {
      if (multi) {
        setFieldValue(id, event);
      } else {
        setFieldValue(id, event ? event.value : null);
      }
    }}
    options={options}
    disabled={disabled}
    multi={multi}
    placeholder={placeholder}
  />
);

export default FormikReactSelect;
