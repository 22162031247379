import * as R from 'ramda';
import { combineReducers } from 'redux';

import actions from 'sow/actions/pure';

const onePayloadId = R.path(['payload', 'acaId']);
const oneResultId = R.path(['payload', 'result']);

export const isLoadingOne = (state = [], action) => {
  switch (action.type) {
    case String(actions.aca.loadOne):
      return R.union([onePayloadId(action)], state);

    case String(actions.aca.loadOneDone):
      return R.without([oneResultId(action)], state);

    case String(actions.aca.loadOneFail):
      return R.without([onePayloadId(action)], state);

    default:
      return state;
  }
};

export const isLoadingOneFailed = (state = [], action) => {
  switch (action.type) {
    case String(actions.aca.loadOne):
      return R.without([onePayloadId(action)], state);

    case String(actions.aca.loadOneDone):
      return R.without([oneResultId(action)], state);

    case String(actions.aca.loadOneFail):
      return R.union([onePayloadId(action)], state);

    default:
      return state;
  }
};

export const isLoadingOneLoaded = (state = [], action) => {
  switch (action.type) {
    case String(actions.aca.loadOne):
      return R.without([onePayloadId(action)], state);

    case String(actions.aca.loadOneDone):
      return R.union([oneResultId(action)], state);

    case String(actions.aca.loadOneFail):
      return R.without([onePayloadId(action)], state);

    default:
      return state;
  }
};

export default combineReducers({
  failed: isLoadingOneFailed,
  loaded: isLoadingOneLoaded,
  loading: isLoadingOne,
});
