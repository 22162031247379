const mark = action => `USER_MANAGEMENT/${action}`;

export const LOAD_ALL_USERS = mark('LOAD_ALL_USERS');
export const LOAD_ALL_USERS_DONE = mark('LOAD_ALL_USERS_DONE');
export const LOAD_SINGLE_USER = mark('LOAD_SINGLE_USERS');
export const LOAD_SINGLE_USER_DONE = mark('LOAD_SINGLE_USER_DONE');
export const LOAD_USER_HISTORY = mark('LOAD_USER_HISTORY');
export const LOAD_USER_HISTORY_DONE = mark('LOAD_USER_HISTORY_DONE');
export const REMOVE_USER_FROM_ORG = mark('REMOVE_USER_FROM_ORG');
export const CLEAR_RESULTS = mark('CLEAR_RESULTS');
export const LOAD_USER_FOR_EDIT_REQUEST = mark('LOAD_USER_FOR_EDIT_REQUEST');
export const ADD_USER_REQUEST = mark('ADD_USER_REQUEST');
export const ASSIGN_USER_TO_ORG = mark('ASSIGN_USER_TO_ORG');
export const ASSIGN_INSPECTOR_TO_ORG = mark('ASSIGN_INSPECTOR_TO_ORG');
export const REMOVE_INSPECTOR = mark('REMOVE_INSPECTOR');
export const ADD_SPECIALIST = mark('ADD_SPECIALIST');
export const REMOVE_SPECIALIST = mark('REMOVE_SPECIALIST');
export const LOAD_FORM_OPTIONS = mark('LOAD_FORM_OPTIONS');
export const LOAD_FORM_OPTIONS_DONE = mark('LOAD_FORM_OPTIONS_DONE');
export const LOAD_REQUESTED_ORG_USERS = mark('LOAD_REQUESTED_ORG_USERS');
export const LOAD_REQUESTED_ORG_USERS_DONE = mark('LOAD_REQUESTED_ORG_USERS_DONE');
export const LOAD_REQUESTED_ORG_USER = mark('LOAD_REQUESTED_ORG_USER');
export const LOAD_REQUESTED_ORG_USER_DONE = mark('LOAD_REQUESTED_ORG_USER_DONE');
export const UPDATE_ORG_CONTACT = mark('UPDATE_ORG_CONTACT');
export const FETCH_MANAGEABLE_ORGS = mark('FETCH_MANAGEABLE_ORGS');
export const FETCH_MANAGEABLE_ORGS_DONE = mark('FETCH_MANAGEABLE_ORGS_DONE');
export const CONTACT_PAGE_ADD_CLIENT_TO_ORG = mark('CONTACT_PAGE_ADD_CLIENT_TO_ORG');
export const CREATE_USER_ADD_TO_ORG = mark('CREATE_USER_ADD_TO_ORG');
export const ADD_OR_UPDATE_USER_PRIORITY = mark('ADD_OR_UPDATE_USER_PRIORITY');
export const REMOVE_USER_PRIORITY = mark('REMOVE_USER_PRIORITY');

export const loadUsers = (searchQuery, onSuccess) => ({
  type: LOAD_ALL_USERS,
  payload: { searchQuery },
  meta: { onSuccess },
});

export const loadUsersDone = users => ({
  type: LOAD_ALL_USERS_DONE,
  payload: users,
});

export const loadSingleUser = userId => ({
  type: LOAD_SINGLE_USER,
  payload: userId,
});

export const loadSingleUserDone = user => ({
  type: LOAD_SINGLE_USER_DONE,
  payload: user,
});

export const loadUserHistory = userId => ({
  type: LOAD_USER_HISTORY,
  payload: userId,
});

export const loadUserHistoryDone = history => ({
  type: LOAD_USER_HISTORY_DONE,
  payload: history,
});

export const revokeUser = (user, onSuccess, onFailure) => ({
  type: REMOVE_USER_FROM_ORG,
  payload: user,
  meta: { onSuccess, onFailure },
});

export const clearResults = category => ({
  type: CLEAR_RESULTS,
  payload: category,
});

export const editUser = (user, onSuccess) => ({
  type: LOAD_USER_FOR_EDIT_REQUEST,
  payload: { user },
  meta: { onSuccess },
});

export const newUser = (user, onSuccess) => ({
  type: ADD_USER_REQUEST,
  payload: { user },
  meta: { onSuccess },
});

export const assignUser = (user, onSuccess, onFailure) => ({
  type: ASSIGN_USER_TO_ORG,
  payload: user,
  meta: { onSuccess, onFailure },
});

export const assignInspector = (user, onSuccess) => ({
  type: ASSIGN_INSPECTOR_TO_ORG,
  payload: user,
  meta: { onSuccess },
});

export const removeInspector = (user, onSuccess) => ({
  type: REMOVE_INSPECTOR,
  payload: user,
  meta: { onSuccess },
});

export const addSpecialist = (user, onSuccess) => ({
  type: ADD_SPECIALIST,
  payload: user,
  meta: { onSuccess },
});

export const removeSpecialist = (user, onSuccess) => ({
  type: REMOVE_SPECIALIST,
  payload: user,
  meta: { onSuccess },
});

export const loadFormOptions = acaId => ({
  type: LOAD_FORM_OPTIONS,
  payload: acaId,
});

export const loadFormOptionsDone = options => ({
  type: LOAD_FORM_OPTIONS_DONE,
  payload: options,
});

export const loadRequestedUsers = payload => ({
  type: LOAD_REQUESTED_ORG_USERS,
  payload,
});

export const loadRequestedUsersDone = payload => ({
  type: LOAD_REQUESTED_ORG_USERS_DONE,
  payload,
});

export const loadRequestedUser = (orgId, userId, userTypeId) => ({
  type: LOAD_REQUESTED_ORG_USER,
  payload: { orgId, userId, userTypeId },
});

export const loadRequestedUserDone = payload => ({
  type: LOAD_REQUESTED_ORG_USER_DONE,
  payload,
});

export const updateOrgContact = (payload, onFailure) => ({
  type: UPDATE_ORG_CONTACT,
  payload,
  meta: { onFailure },
});

export const fetchManageableOrgs = (payload, onSuccess) => ({
  type: FETCH_MANAGEABLE_ORGS,
  payload,
  meta: { onSuccess },
});

export const fetchManageableOrgsDone = payload => ({
  type: FETCH_MANAGEABLE_ORGS_DONE,
  payload,
});

export const contactPageAddClientToOrg = (payload, onSuccess, onFailure) => ({
  type: CONTACT_PAGE_ADD_CLIENT_TO_ORG,
  payload,
  meta: { onSuccess, onFailure },
});

export const createUserAddToOrg = (payload, onSuccess, onFailure) => ({
  type: CREATE_USER_ADD_TO_ORG,
  payload,
  meta: { onSuccess, onFailure },
});

export const addOrUpdatePriorityToOrg = (payload, onSuccess, onFailure) => ({
  type: ADD_OR_UPDATE_USER_PRIORITY,
  payload,
  meta: { onSuccess, onFailure },
});

export const removePriorityToOrg = (payload, onSuccess, onFailure) => ({
  type: REMOVE_USER_PRIORITY,
  payload,
  meta: { onSuccess, onFailure },
});
