import React from 'react';

import InspectionReportPageTemplateContainer from 'sow/containers/InspectionReportPageTemplateContainer';
import AllInspectionReportFiltersContainer from 'sow/containers/AllInspectionReportFiltersContainer';
import AllInspectionReportTableContainer from 'sow/containers/AllInspectionReportTableContainer';

const InspectionReportAllPage = () => (
  <InspectionReportPageTemplateContainer>
    <AllInspectionReportFiltersContainer />
    <AllInspectionReportTableContainer />
  </InspectionReportPageTemplateContainer>
);

export default InspectionReportAllPage;
