const mark = action => `ITEM/${action}`;

export const LOAD_ITEMS = mark('LOAD_ITEMS');
export const FETCH_BY_ID = mark('FETCH_BY_ID');
export const FETCH_BY_ID_DONE = mark('FETCH_BY_ID_DONE');
export const ADD_ITEM = mark('ADD_ITEM');
export const ADD_ITEM_DONE = mark('ADD_ITEM_DONE');
export const UPDATE_ITEM = mark('UPDATE_ITEM');
export const UPDATE_ITEM_DONE = mark('UPDATE_ITEM_DONE');
export const UPDATE_SORT_ORDER = mark('UPDATE_SORT_ORDER');
export const DELETE_ITEM = mark('DELETE_ITEM');
export const LOAD_OPTIONS = mark('LOAD_OPTIONS');

export const loadItems = (payload, onSuccess) => ({
  type: LOAD_ITEMS,
  payload,
  meta: { onSuccess },
});

export const fetchById = (payload, onSuccess) => ({
  type: FETCH_BY_ID,
  payload,
  meta: { onSuccess },
});

export const fetchByIdDone = payload => ({
  type: FETCH_BY_ID_DONE,
  payload,
});

export const addItem = (payload, onSuccess) => ({
  type: ADD_ITEM,
  payload,
  meta: { onSuccess },
});

export const addItemDone = payload => ({
  type: ADD_ITEM_DONE,
  payload,
});

export const updateItem = (id, payload, onSuccess) => ({
  type: UPDATE_ITEM,
  id,
  payload,
  meta: { onSuccess },
});

export const updateItemDone = payload => ({
  type: UPDATE_ITEM_DONE,
  payload,
});

export const updateSortOrder = (payload, onSuccess) => ({
  type: UPDATE_SORT_ORDER,
  payload,
  meta: { onSuccess },
});

export const deleteItem = (id, onSuccess) => ({
  type: DELETE_ITEM,
  id,
  meta: { onSuccess },
});

export const loadOptions = onSuccess => ({
  type: LOAD_OPTIONS,
  meta: { onSuccess },
});
