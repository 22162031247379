import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { planAppNoteType } from 'sow/types';
import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';
import Atom from 'sow/components/atoms/Atom';
import Strong from 'sow/components/atoms/Strong';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Spacer from 'sow/components/atoms/Spacer';
import Tooltip from 'sow/components/molecules/Tooltip';
import Timestamp from 'sow/components/molecules/Timestamp';
import DeleteNoteButton from './DeleteNoteButton';

const NoteWrapper = styled(Block)`
  padding: 10px;
  margin-bottom: 10px;
  background-color: ${({ theme, isDeleted }) =>
    isDeleted ? theme.note.backgroundDeleted : theme.note.background};
  cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
`;

const AuthorWrapper = styled(Strong)`
  font-size: 11pt;
`;

const DateTimeWrapper = styled(Atom)`
  border-bottom: 1px dotted rgb(204, 204, 204);
`;

const Note = ({ note, isDeleting, onDelete, showAcaUI, toggleDeletedVisibility }) => {
  const { public: isPublic, isDeleted } = note;

  // Note is hidden to org users if it is private or deleted
  if (!showAcaUI && (!isPublic || isDeleted)) {
    return null;
  }

  return (
    <NoteWrapper isDeleted={isDeleted} textWarning={!isDeleted} textMuted={isDeleted}>
      <Block pullRight textRight>
        {showAcaUI && (
          <Block>
            <Tooltip overlay={`Note is ${isPublic ? 'public' : 'private'}`}>
              <FontAwesome icon={isPublic ? 'lock-open' : 'lock'} />
            </Tooltip>
          </Block>
        )}

        {isDeleted ? (
          <Block>
            <Block textDanger>
              <Strong>DELETED</Strong>
            </Block>
            <Block>
              <Link onClick={toggleDeletedVisibility}>[hide]</Link>
            </Block>
          </Block>
        ) : (
          showAcaUI && (
            <DeleteNoteButton
              noteId={note.id}
              onDelete={onDelete}
              isDeleting={isDeleting}
            />
          )
        )}
      </Block>

      <Block>
        <AuthorWrapper>{note.author}</AuthorWrapper> wrote{' '}
        <DateTimeWrapper>
          <Timestamp date={note.createdAt} />
        </DateTimeWrapper>
        :
      </Block>
      <Spacer vertical={8} />
      {note.text}
    </NoteWrapper>
  );
};

Note.propTypes = {
  note: planAppNoteType.isRequired,
  onDelete: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  showAcaUI: PropTypes.bool.isRequired,
  toggleDeletedVisibility: PropTypes.func.isRequired,
};

export default Note;
