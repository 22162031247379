import React from 'react';
import styled from 'styled-components';

import Block from 'sow/components/atoms/Block';

const PopoverOption = styled(Block)`
  cursor: pointer;
  margin: 5px;
  padding: 5px;
  &:hover {
    background-color: #d2d2d2;
  }
`;

export default PopoverOption;
