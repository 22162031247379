import React from 'react';
import PropTypes from 'prop-types';

import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import Panel from 'sow/components/molecules/Panel';

const PlanAppChangeRequestCreatePanel = ({ onCreate, showAcaUI, disableCreate }) => {
  if (showAcaUI) {
    return (
      <Panel title="Request Changes" info>
        If you would like to request changes to or Complete this plan, please click the
        button below to initialize and unlock this feature.
        <Block textCenter>
          <Button primary disabled={disableCreate} onClick={onCreate}>
            Request Changes
          </Button>
        </Block>
      </Panel>
    );
  }

  return (
    <Panel title="Request Changes" info>
      If you would like to make changes to your plan, please click the button below to
      initialize and unlock this feature.
      <Block textCenter>
        <Button primary disabled={disableCreate} onClick={onCreate}>
          Make Changes to Application
        </Button>
      </Block>
    </Panel>
  );
};

PlanAppChangeRequestCreatePanel.propTypes = {
  disableCreate: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  showAcaUI: PropTypes.bool.isRequired,
};

export default PlanAppChangeRequestCreatePanel;
