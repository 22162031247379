import React, { Fragment } from 'react';

import { orgContactsEditRoute } from 'sow/routes';

import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Block from 'sow/components/atoms/Block';
import SelectFormGroup from 'sow/components/molecules/SelectFormGroup';
import Button from 'sow/components/atoms/Button';
import TableCell from 'sow/components/atoms/TableCell';
import Clearfix from 'sow/components/atoms/Clearfix';
import Strong from 'sow/components/atoms/Strong';
import Paragraph from 'sow/components/atoms/Paragraph';
import ConfirmModal from 'sow/components/molecules/ConfirmModal';
import ListItem from 'sow/components/atoms/ListItem';
import List from 'sow/components/atoms/List';
import ContactAddUserModal from 'sow/components/organisms/ContactAddUserModal';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Link from 'sow/components/atoms/Link';
import ContactInfoTable from 'sow/components/organisms/ContactInfoTable';

const ContactInfoStaff = ({
  orgId,
  clientUsers,
  selectOptions,
  handleSelectChange,
  handleAssign,
  handleUnassign,
  assignValues,
  showModal,
  currentPrimary,
  onSavePrimary,
  onCancelPrimary,
  onModalExited,
  toggleNewContactModal,
  modalProperties,
}) => (
  <Widget className="general-contact-widget">
    <WidgetHeading>
      Contacts
      <ContactAddUserModal {...modalProperties} />
      <Block pullRight>
        <Button onClick={toggleNewContactModal} primary sm>
          Add New User
        </Button>
      </Block>
    </WidgetHeading>
    <WidgetBody>
      <ConfirmModal
        title={
          currentPrimary
            ? 'Primary contact for organization already assigned.'
            : 'Remove primary contact for organization.'
        }
        confirmButtonText="Confirm"
        message={
          currentPrimary ? (
            <Fragment>
              <Paragraph>The current primary contact is:</Paragraph>
              <List>
                <ListItem>{currentPrimary.name}</ListItem>
                <ListItem>{currentPrimary.email}</ListItem>
              </List>
              <Paragraph>
                Do you want to switch the primary contact to {assignValues.userName}?
              </Paragraph>
            </Fragment>
          ) : (
            <Paragraph>
              Do you wish to remove the primary contact for this organization?
            </Paragraph>
          )
        }
        onConfirm={() => onSavePrimary()}
        onCancel={() => onCancelPrimary()}
        onExited={() => onModalExited()}
        show={showModal}
      />
      <Block className="assign-block">
        <Paragraph>
          <Strong>Assign a Contact:</Strong>
        </Paragraph>
        <SelectFormGroup
          fieldName="userSelect"
          className="contact-select"
          placeholder="Contact"
          options={selectOptions.orgUserOptions}
          clearable={true}
          onChange={handleSelectChange}
          value={assignValues.userSelect}
        />
        <SelectFormGroup
          fieldName="userSubtype"
          className="contact-type-select"
          placeholder="Contact Type"
          options={selectOptions.userSubtypeOptions}
          clearable={true}
          onChange={handleSelectChange}
          value={assignValues.userSubtype}
        />
        <Button
          primary
          className="assign-button"
          disabled={!assignValues.userSelect || !assignValues.userSubtype}
          onClick={handleAssign}
        >
          Assign
        </Button>
        <Clearfix />
      </Block>
      <ContactInfoTable
        orgId={orgId}
        clientUsers={clientUsers}
        handleUnassign={handleUnassign}
        actionColumn={(orgId, user) => (
          <TableCell>
            <FontAwesome icon="pencil-alt" className="edit-icon" />
            <Link to={orgContactsEditRoute(orgId, user.userId)}>Edit</Link>
          </TableCell>
        )}
      />
    </WidgetBody>
  </Widget>
);

export default ContactInfoStaff;
