import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import classnames from 'classnames';

/**
 * Renders a text input tag
 */
const Input = ({
  className,
  lg,
  sm,
  setFieldValue,
  setValues,
  textMask,
  updateValues,
  ...props
}) => {
  const classes = classnames(className, {
    'form-control': props.type !== 'checkbox',
    'input-lg': lg,
    'input-sm': sm,
  });

  const value = props.value || '';

  if (props.type === 'textarea') {
    return <textarea className={classes} {...props} value={value} />;
  }

  if (props.type === 'select') {
    return <select className={classes} {...props} />;
  }

  if (typeof props.type === 'function') {
    return (
      <props.type
        {...props}
        className={classes}
        setFieldValue={setFieldValue}
        setValues={setValues}
        updateValues={updateValues}
      />
    );
  }

  if (textMask) {
    return <MaskedInput mask={textMask} className={classes} {...props} />;
  }

  return <input className={classes} {...props} value={value} />;
};

Input.propTypes = {
  setFieldValue: PropTypes.func,
  setValues: PropTypes.func,
  textMask: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.array]),
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  updateValues: PropTypes.func,
  className: PropTypes.string,
  lg: PropTypes.bool,
  sm: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
};

export default Input;
