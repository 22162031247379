import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as itemAttributeTypeActions from 'sow/actions/pure/itemAttributeType';
import { apiGet, apiPost, apiPut } from 'sow/sagas/api';

export const loadItemAttributeTypes = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/item_attribute_type', payload);

    if (response && meta.onSuccess) {
      meta.onSuccess(response.data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchById = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/item_attribute_type/${payload}`);

    if (response && meta.onSuccess) {
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addItemAttributeType = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/item_attribute_type', payload);
    if (response && meta.onSuccess) {
      yield put(actions.shell.toast('success', 'Item attribute type created.'));
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateItemAttributeType = function*({ id, payload, meta }) {
  try {
    const { response } = yield call(apiPut, `/admin/item_attribute_type/${id}`, payload);
    if (response) {
      yield put(actions.shell.toast('success', 'Item attribute type updated.'));
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateSortOrder = function*({ payload }) {
  try {
    yield call(apiPost, '/admin/item_attribute_type/update_order', {
      updated_list: payload,
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteItemAttributeType = function*({ id, meta }) {
  try {
    const { response } = yield call(apiPut, `/admin/item_attribute_type/delete/${id}`);
    if (response) {
      yield put(actions.shell.toast('success', 'Item attribute type deleted.'));
      if (meta.onSuccess) meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(
    itemAttributeTypeActions.LOAD_ITEM_ATTRIBUTE_TYPES,
    loadItemAttributeTypes,
  );
  yield takeEvery(itemAttributeTypeActions.FETCH_BY_ID, fetchById);
  yield takeEvery(itemAttributeTypeActions.ADD_ITEM_ATTRIBUTE_TYPE, addItemAttributeType);
  yield takeEvery(
    itemAttributeTypeActions.UPDATE_ITEM_ATTRIBUTE_TYPE,
    updateItemAttributeType,
  );
  yield takeEvery(itemAttributeTypeActions.UPDATE_SORT_ORDER, updateSortOrder);
  yield takeEvery(
    itemAttributeTypeActions.DELETE_ITEM_ATTRIBUTE_TYPE,
    deleteItemAttributeType,
  );
}
