import React from 'react';
import PropTypes from 'prop-types';

import { worksheetQuestionType } from 'sow/types';

import Select from 'sow/components/molecules/Select';

const SelectBox = ({ question, answer, handleChange, disabled }) => (
  <Select
    id={question.uuid}
    value={answer || ''}
    onChange={e => handleChange(question.uuid, e.target.value)}
    disabled={disabled}
    showBlankOption={true}
    blankOptionText="Select..."
    options={question.answers.map(answer => ({
      value: answer,
      text: answer,
    }))}
  />
);

SelectBox.propTypes = {
  question: worksheetQuestionType.isRequired,
  answer: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

SelectBox.defaultProps = {
  answer: '',
  disabled: false,
};

export default SelectBox;
