import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push as routerPush } from 'connected-react-router';
import PropTypes from 'prop-types';

import actions from 'sow/actions/pure';
import { orgRoute } from 'sow/routes';
import { resourceCreate } from 'sow/store/helpers';
import { orgIdType } from 'sow/types';
import * as currentOrg from 'sow/selectors/currentOrg';
import LinkUsersToOrgForm from 'sow/components/organisms/LinkUsersToOrgForm';

const mapStateToProps = (state, props) => ({
  orgId: currentOrg.orgId(state, props),
});

const mapDispatchToProps = dispatch => {
  return {
    migrateUsers: (fromOrgId, toOrgId) => {
      const { action } = resourceCreate(
        `admin/migrate_org_users/from/${fromOrgId}/to/${toOrgId}`,
      );
      return dispatch(action());
    },
    notify: (...args) => dispatch(actions.shell.toast(...args)),
    routerPush: (...args) => dispatch(routerPush(...args)),
  };
};

class LinkUsersToOrgFormContainer extends Component {
  handleSubmit = (values, formikBag) => {
    const confirmText =
      'Users from this organization will be linked to the selected organization. This action cannot be undone. Would you like to continue?';
    if (!window.confirm(confirmText)) return;

    const { orgId: fromOrgId, migrateUsers, routerPush, notify } = this.props;
    const { orgId: toOrgId } = values;

    migrateUsers(fromOrgId, toOrgId)
      .then(() => {
        notify('success', `Users successfully linked to new organization.`);
        routerPush(orgRoute(toOrgId));
      })
      .catch(err => {
        notify('danger', 'Error migrating users; see console for details.');
        console.log('Error migrating users:', err);
        formikBag.setSubmitting(false);
      });
  };

  render() {
    return <LinkUsersToOrgForm onSubmit={this.handleSubmit} />;
  }
}

LinkUsersToOrgFormContainer.propTypes = {
  orgId: orgIdType.isRequired,
  migrateUsers: PropTypes.func.isRequired,
  routerPush: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(LinkUsersToOrgFormContainer);
