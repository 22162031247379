import React, { Component, Fragment } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { PlanAppLoader } from 'sow/store/containers';
import { fromPlanApp } from 'sow/store/selectors';
import { resourceCreate } from 'sow/store/helpers';
import { userSubtypeId } from 'sow/constants/userType';
import { sampleRanks } from 'sow/constants/sampleConstants';
import { inspectionTypes } from 'sow/constants/inspectionConstants';
import * as currentOrg from 'sow/selectors/currentOrg';
import actions from 'sow/actions/pure';

import ResourceLoaderStatusRender from 'sow/components/molecules/ResourceLoaderStatusRender';
import InspectionManager from 'sow/components/organisms/InspectionManager';
import ManageInspectionModal from 'sow/components/organisms/InspectionManager/ManageInspectionModal';

const createPartialInspection = (orgId, planAppId) =>
  resourceCreate(
    `org/${orgId}/application/${planAppId}/new_partial_inspection`,
    'planAppResp',
  );

const mapStateToProps = (state, props) => ({
  orgId: currentOrg.orgId(state, props),
  application_id: currentOrg.currentAppId(state, props),
  annualInspection: fromPlanApp.annualInspection(state, props),
  partialInspectionList: fromPlanApp.planPartialInspections(state, props),
  inspectionTypeOptions: state.inspection.inspectionTypeOptions,
  inspectionYearOptions: state.inspection.inspectionYearOptions,
  sampleTypeOptions: state.sample.sampleTypeOptions,
  pendingSampleInspections: state.inspection.list,
});

const mapDispatchToProps = {
  createPartialInspection: (orgId, planAppId, data) =>
    createPartialInspection(orgId, planAppId).action(data),
  getPendingAnnualInspection: actions.inspection.getAllByOrg,
};

class InspectionManagerContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { createNewModalShow: false };
  }

  componentDidMount() {
    const { orgId, application_id, getPendingAnnualInspection } = this.props;
    getPendingAnnualInspection({
      orgId,
      remove_current_application_id: application_id,
      minimum_sample_state_rank: sampleRanks.closed,
      inspection_type_id: inspectionTypes.annual,
    });
  }

  toggleCreateNewModal() {
    const { createNewModalShow } = this.state;
    this.setState(R.assocPath(['createNewModalShow'], !createNewModalShow));
  }

  createPartial(values) {
    const { orgId, application_id } = this.props;
    const newParams = {
      inspection_sub_type_id: values.inspection_sub_type_id,
      inspection_year: values.inspection_year,
    };
    if (R.has('inspector_id', values)) {
      newParams.inspector_id = values.inspector_id.map(inspector => ({
        user_id: inspector.value,
        user_subtype_id: userSubtypeId.partialInspector,
      }));
    }
    if (R.has('sample', values)) {
      newParams.sample = values.sample.map(sample => ({
        sample_type_id: sample.value,
      }));
    }
    this.props
      .createPartialInspection(orgId, application_id, newParams)
      .then(() => this.toggleCreateNewModal());
  }

  render() {
    return (
      <PlanAppLoader planAppId={this.props.application_id} autoLoad>
        {({ status }) => {
          return (
            <ResourceLoaderStatusRender
              statusObjects={[status]}
              renderAllSuccess={() => (
                <Fragment>
                  <ManageInspectionModal
                    toggleModal={this.toggleCreateNewModal.bind(this)}
                    show={this.state.createNewModalShow}
                    selectOptions={{
                      inspectorList: this.props.inspectorList,
                      inspectionTypeOptions: this.props.inspectionTypeOptions,
                      inspectionYearOptions: this.props.inspectionYearOptions,
                      sampleTypeOptions: this.props.sampleTypeOptions,
                    }}
                    createPartial={this.createPartial.bind(this)}
                    isNew
                  />
                  <InspectionManager
                    toggleCreateNewModal={this.toggleCreateNewModal.bind(this)}
                    {...this.props}
                  />
                </Fragment>
              )}
            ></ResourceLoaderStatusRender>
          );
        }}
      </PlanAppLoader>
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InspectionManagerContainer);
