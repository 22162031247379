import { makeImmutable, getIn } from 'sow/utils/data';
import {
  MATRIX_ROW_CREATE,
  MATRIX_ROW_DELETE,
  MATRIX_ROW_UNDELETE,
  MATRIX_ROW_SET_ANSWER,
  SAVE_ONE_WORKSHEET_SUCCESS,
  SET_ANSWER,
  SET_BASE_ANSWER,
} from 'sow/actions/worksheetAnswers';
import actions from 'sow/actions/pure';

import valuesByQuestionId from './valuesByQuestionId';
import matrixRowsById from './matrixRowsById';

const mapKey = action => action.data.worksheetId;

const mapReducer = (state = makeImmutable({}), action) =>
  state.merge({
    valuesByQuestionId: valuesByQuestionId(false)(state.valuesByQuestionId, action),
    matrixRowsById: matrixRowsById(state.matrixRowsById, action),
  });

const byWorksheetId = (state = makeImmutable({}), action) => {
  switch (action.type) {
    case MATRIX_ROW_CREATE:
    case MATRIX_ROW_DELETE:
    case MATRIX_ROW_UNDELETE:
    case MATRIX_ROW_SET_ANSWER:
    case SET_ANSWER:
      return state.updateIn([mapKey(action)], mapReducer, action);

    case SET_BASE_ANSWER:
      return state.setIn([mapKey(action), action.data.name], action.data.value);

    case SAVE_ONE_WORKSHEET_SUCCESS:
      return state.without([mapKey(action)]);

    default:
      return state;
  }
};

export default byWorksheetId;

export const getIsNotApplicable = (state, worksheetId) =>
  getIn(state, [worksheetId, 'isNotApplicable'], null);

export const getReadyForReview = (state, worksheetId) =>
  getIn(state, [worksheetId, 'readyForReview'], null);
