import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';

import Atom from 'sow/components/atoms/Atom';

/** Displays a character from the Font Awesome library */
const FontAwesome = ({
  variant,
  icon,
  fixedWidth,
  containerElement,
  className,
  ...props
}) => {
  const WrapperEl = styled(containerElement || Atom)``;

  const classes = classnames(className, variant, `fa-${icon}`, {
    'fa-fw': fixedWidth,
  });

  return <WrapperEl {...props} className={classes} />;
};

FontAwesome.propTypes = {
  /** The variant of the icon */
  variant: PropTypes.oneOf(['fa', 'fas', 'far', 'fab']),

  /** The name of the icon */
  icon: PropTypes.string,

  /** Adds the `'fa-fw'` class */
  fixedWidth: PropTypes.bool,

  /** Optional container to use instead of a `<span>` */
  containerElement: PropTypes.node,

  className: PropTypes.string,
};

FontAwesome.defaultProps = {
  variant: 'fa',
  fixedWidth: false,
  containerElement: null,
  className: null,
};

export default FontAwesome;
