import React, { Fragment } from 'react';

import { announcementsEditRoute, announcementHistoryRoute } from 'sow/routes';

import Spinner from 'sow/components/atoms/Spinner';
import Nav from 'sow/components/atoms/Nav';
import NavLink from 'sow/components/molecules/NavLink';

const AnnouncementEditPageTemplate = ({ id, isLoading, children }) => (
  <Fragment>
    {isLoading ? (
      <Spinner />
    ) : (
      <Fragment>
        <Nav tabs>
          <NavLink to={announcementsEditRoute(id)} exact>
            Announcement
          </NavLink>
          {id !== 'new' && <NavLink to={announcementHistoryRoute(id)}>History</NavLink>}
        </Nav>
        {children}
      </Fragment>
    )}
  </Fragment>
);

export default AnnouncementEditPageTemplate;
