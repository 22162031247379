import React from 'react';

import { createField } from 'sow/components/old/osp/FieldTypes';

import InfoTooltipWrapper from 'sow/components/old/OspApp/Editor/Overview/InfoTooltipWrapper';
import BaseQuestion from './BaseQuestion';

export const Question = ({
  question,
  answer,
  handleChange,
  orgId,
  ospAppId,
  worksheetId,
  disabled,
  showNotes,
  showNotesToggle,
  onToggleNotes,
  noteEntityId,
}) => {
  const field = createField(
    orgId,
    ospAppId,
    worksheetId,
    question,
    answer,
    handleChange,
    disabled,
  );

  if (question.isBaseQuestion) {
    return (
      <BaseQuestion
        question={question}
        answer={answer}
        handleChange={handleChange}
        orgId={orgId}
        ospAppId={ospAppId}
        worksheetId={worksheetId}
        disabled={disabled}
        showNotes={showNotes}
        showNotesToggle={showNotesToggle}
        onToggleNotes={onToggleNotes}
        noteEntityId={noteEntityId}
        field={field}
        tooltip={question.tooltip}
      />
    );
  }

  return (
    <div>
      <label htmlFor={question.uuid} className="control-label">
        <InfoTooltipWrapper tooltipText={question.tooltip}>
          <span className={disabled ? 'text-muted' : ''}>{question.name} </span>
        </InfoTooltipWrapper>{' '}
      </label>
      {field}
    </div>
  );
};

export default Question;
