import React from 'react';

import { itemVersionRoute } from 'sow/routes';

import AdminObjectTemplateContainer from 'sow/containers/AdminObjectTemplateContainer';
import ItemVersionPicklistContainer from 'sow/containers/ItemVersionPicklistContainer';

const ItemVersionPicklistPage = () => (
  <AdminObjectTemplateContainer
    breadcrumbPath={[['Item Versions', itemVersionRoute()]]}
    statePath={['itemVersion']}
  >
    <ItemVersionPicklistContainer />
  </AdminObjectTemplateContainer>
);

export default ItemVersionPicklistPage;
