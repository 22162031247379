import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import './CSSModules/OrgDashboardPage.module.css';
import { API_URL } from 'sow/constants/api';
import { formattedDate } from 'sow/utils/dateTime';
import { initialize, abbreviateLoc } from 'sow/utils/string';
import * as sowTypes from 'sow/types';
import { planAppStateText, planAppStateIcon } from 'sow/constants/planApp';
import { inspectionStatesRank } from 'sow/constants/inspectionConstants';
import { acaIdConstant } from 'sow/constants/acaId';
import {
  historicalPlansRoute,
  checklistRoute,
  worksheetOverviewRoute,
  locationOverviewRoute,
  orgLocationsRoute,
  orgContactsRoute,
} from 'sow/routes';
import OspNotification from 'sow/components/organisms/OspNotification';
import PlanAppProgressCircle from 'sow/components/organisms/PlanAppProgressCircle';
import InformationIcon from 'sow/components/molecules/InformationIcon';
import Block from 'sow/components/atoms/Block';
import Image from 'sow/components/atoms/Image';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Link from 'sow/components/atoms/Link';
import Clearfix from 'sow/components/atoms/Clearfix';
import Heading from 'sow/components/atoms/Heading';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import List from 'sow/components/atoms/List';
import ListItem from 'sow/components/atoms/ListItem';
import Spacer from 'sow/components/atoms/Spacer';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Confirm from 'sow/components/molecules/Confirm';
import OspStepCard from 'sow/components/organisms/OspStepCard';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import AnnouncementDisplay from 'sow/components/organisms/AnnouncementDisplay';
import PlanAppWhatsNext from 'sow/components/organisms/PlanAppWhatsNext';
import OrgWithdrawModal from 'sow/components/organisms/OrgWithdrawModal';
import OrgDashboardAcaOptions from 'sow/containers/planApp/OrgDashboardAcaOptions';
import ChangeRequestManager from 'sow/containers/planApp/ChangeRequestManager';
import InspectionStateChangeModalContainer from 'sow/containers/InspectionStateChangeModalContainer';
import Button from 'sow/components/atoms/Button';
import CompletedPlanLink from 'sow/containers/planApp/CompletedPlanLink';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import Paragraph from 'sow/components/atoms/Paragraph';
import Strong from 'sow/components/atoms/Strong';

const CompletionWrapper = styled(Block)`
  float: right;
  text-align: right;
  margin-left: 20px;
  margin-bottom: 20px;
  font-size: 20pt;
  font-weight: bold;
`;

const CompletedText = styled(Block)`
  margin-top: 0.4em;
  font-size: 12pt;
  color: ${({ theme }) => theme.colors.lightGrey};
  font-weight: normal;
`;

const SampleStatus = styled(Block)`
  color: ${({ status }) => {
    switch (status) {
      case 'Pending Collection':
        return '#efac4e';
      case 'Collected - Pending Results':
        return '#bc7614';
      case 'Results Received - Additional Action Needed':
        return '#ff1000';
      case 'Results Received - Negative':
        return '#8d0900';
      case 'Closed':
        return '#c1c1c1';
      case 'Unsuccessful':
        return '#ce2b20';
      default:
        return 'black';
    }
  }};
`;

const CompletionStatus = ({ status }) => (
  <CompletionWrapper>
    {status.completed}/{status.total}
    <CompletedText>completed</CompletedText>
  </CompletionWrapper>
);

CompletionStatus.propTypes = {
  status: PropTypes.shape({
    completed: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
};

const displayInspectorPOC = (contactObj, currentList, appState) => {
  switch (appState) {
    case 'inspection':
    case 'in_inspection':
    case 'final_review':
    case 'in_final_review':
      const updatedList = currentList.concat(contactObj.inspector);
      return updatedList;
    default:
      return currentList;
  }
};

const displayFinalReviewerPOC = (contactObj, currentList, appState) => {
  switch (appState) {
    case 'in_final_review':
      const updatedList = currentList.concat(contactObj.final_reviewer);
      return updatedList;
    default:
      return currentList;
  }
};

const formatPhoneNumber = (settings, defaultNumber) => {
  if (settings && settings.primary_phone_number) {
    const phoneNumber = settings.primary_phone_number;
    const regex = /\D/g;
    const onlyNumbers = phoneNumber.replace(regex, '');
    if (onlyNumbers.length == 10) {
      return (
        '(' +
        onlyNumbers.slice(0, 3) +
        ') ' +
        onlyNumbers.slice(3, 6) +
        '-' +
        onlyNumbers.slice(6, 10)
      );
    }
    return phoneNumber;
  } else {
    if (defaultNumber) {
      return '(303) 895-3002';
    } else {
      return '';
    }
  }
};

const ContactDisplay = ({ orgPOC, orgName, planAppState, contactType }) => {
  let pocList = [];
  if (contactType === 1) {
    if (!R.isEmpty(orgPOC.primary_contact)) {
      pocList.push(orgPOC.primary_contact);
    }
    pocList = pocList.concat(orgPOC.non_primary_contact);
  } else if (contactType === 2) {
    pocList = pocList.concat(orgPOC.initial_reviewer);
    pocList = displayInspectorPOC(orgPOC, pocList, planAppState);
    pocList = displayFinalReviewerPOC(orgPOC, pocList, planAppState);
  }

  return pocList.map((user, index) => (
    <Fragment key={index}>
      {index > 0 && pocList.length !== index && (
        <HorizontalRule className="location-contact-divider" />
      )}
      <Block className="poc-info">
        <Block className="circle">{initialize(user.name)}</Block>
        <Block className="poc-contact">
          {contactType === 2 ? (
            <Fragment>
              <Paragraph>
                <Strong>{user.name}</Strong>
              </Paragraph>
              <Paragraph>{user.user_type === 2 ? 'Inspector' : user.type}</Paragraph>
            </Fragment>
          ) : (
            <Fragment>
              <Paragraph className="primary-name">
                <Strong>{user.name} </Strong>
              </Paragraph>
              {user.type === 'Primary' && (
                <Block className="primary-flag">
                  <FontAwesome icon="star" /> PRIMARY
                </Block>
              )}
            </Fragment>
          )}
          <Paragraph>{formatPhoneNumber(user.settings, contactType !== 1)}</Paragraph>
          <a
            href={`mailto:${user.email}?subject=${encodeURIComponent(orgName)}: ${
              planAppStateText[planAppState]
            }`}
          >
            {user.email}
          </a>
        </Block>
      </Block>
    </Fragment>
  ));
};

// Determines what inspection the inspector is linked to and displays
const InspectorDisplay = (annualInspection, partialInspections, inspectorId) => {
  const isAnnual = R.find(R.propEq('id', inspectorId), annualInspection.inspectors);

  const partials = R.filter(
    ({ inspectors }) => R.find(R.propEq('id', inspectorId), inspectors),
    partialInspections,
  );

  return (
    <Fragment>
      <Spacer vertical={10} />
      <Widget className="inspector-display">
        <WidgetBody>
          <Block className="inspector-heading">
            <FontAwesome icon="file-magnifying-glass" /> Inspection
          </Block>
          {isAnnual && (
            <Fragment>
              <Spacer vertical={10} />
              <Block className="inspection-block">
                <Row className="inspection-block-row">
                  <Column className="inspection-block-column" xs={12} md={3} lg={2}>
                    {annualInspection.inspectionDate
                      ? formattedDate(annualInspection.inspectionDate)
                      : 'Date not set'}
                  </Column>
                  <Column className="inspection-block-column" xs={12} md={9} lg={10}>
                    <Block inline>
                      You have an{' '}
                      <Strong>
                        {annualInspection.inspectionSubType}{' '}
                        {annualInspection.inspectionType} Inspection{' '}
                      </Strong>
                      coming up.
                    </Block>{' '}
                    {annualInspection.samples.length > 0 && (
                      <Block inline>
                        <Strong>Samples needed: </Strong>
                        {annualInspection.samples
                          .map(sample => sample.typeName)
                          .join(', ')}
                      </Block>
                    )}
                  </Column>
                </Row>
              </Block>
            </Fragment>
          )}
          {partials
            .filter(
              inspection =>
                inspection.inspectionStateRank < inspectionStatesRank.complete,
            )
            .map(inspection => (
              <Fragment key={inspection.id}>
                <Spacer vertical={10} />
                <Block className="inspection-block">
                  <Row className="inspection-block-row">
                    <Column className="inspection-block-column" xs={12} md={3} lg={2}>
                      {inspection.inspectionDate
                        ? formattedDate(inspection.inspectionDate)
                        : 'Date not set'}
                    </Column>
                    <Column className="inspection-block-column" xs={12} md={9} lg={10}>
                      <Block inline>
                        You have an{' '}
                        <Strong>
                          {inspection.inspectionSubType} {inspection.inspectionType}{' '}
                          Inspection{' '}
                        </Strong>
                        coming up.
                      </Block>{' '}
                      {inspection.samples.length > 0 && (
                        <Block inline>
                          <Strong>Samples needed: </Strong>
                          {inspection.samples.map(sample => sample.typeName).join(', ')}
                        </Block>
                      )}
                    </Column>
                  </Row>
                </Block>
              </Fragment>
            ))}
        </WidgetBody>
      </Widget>
      <Spacer vertical={20} />
    </Fragment>
  );
};

const clientInspectionDisplay = partialInspections => {
  const partials = R.filter(
    inspection => inspection.inspectionStateRank < inspectionStatesRank.complete,
    partialInspections,
  );
  return partials.length > 0 ? (
    <Fragment>
      <Spacer vertical={10} />
      <Widget className="inspector-display">
        <WidgetBody>
          <Block className="inspector-heading">
            <FontAwesome icon="file-magnifying-glass" /> Inspection
          </Block>
          {partials.map(inspection => (
            <Fragment key={inspection.id}>
              <Spacer vertical={10} />
              <Block className="inspection-block">
                <Row className="inspection-block-row">
                  <Column className="inspection-block-column" xs={12} md={3} lg={2}>
                    {inspection.inspectionDate
                      ? formattedDate(inspection.inspectionDate)
                      : 'Date not set'}
                  </Column>
                  <Column className="inspection-block-column" xs={12} md={9} lg={10}>
                    <Block inline>
                      You have a <Strong>Partial Inspection </Strong>
                      coming up.
                    </Block>
                  </Column>
                </Row>
              </Block>
            </Fragment>
          ))}
        </WidgetBody>
      </Widget>
      <Spacer vertical={20} />
    </Fragment>
  ) : (
    <Spacer vertical={10} />
  );
};

const OrganizationDashboard = ({
  org,
  orgId,
  planAppId,
  planApp,
  isInitial,
  isComplete,
  initialSummary,
  configPlanMinNumLocations,
  showAcaUI,
  planAppSummaryList,
  showInspectorUI,
  inspectorAccessList,
  unannouncedAnnualInspection,
  planAnnualInspection,
  planPartialInspections,
  inspectorId,
  annualInspectorList,
  annualInspectionSamples,
  pendingSampleInspections,
  announcements,
  ...props
}) => {
  const { org_poc: orgPOC, certifications } = org;
  const orgSurrendered = planApp.state == 'surrendered';

  const hasWorksheets =
    !isInitial ||
    R.pipe(R.pathOr(0, ['worksheets', 'total']), R.gt(R.__, 0))(initialSummary);

  return (
    <Block className="organization-dashboard">
      {announcements && announcements.length > 0 && (
        <Row className="announcements-block">
          {announcements.map(announcement => (
            <Column key={announcement.id} xs={12}>
              <AnnouncementDisplay announcement={announcement}></AnnouncementDisplay>
            </Column>
          ))}
        </Row>
      )}
      <Row>
        <Column md={8}>
          {planApp.noncompliant && (
            <Fragment>
              <OspNotification banner="noncompliant" />
              <Spacer vertical={10} />
            </Fragment>
          )}
          {planApp.isUnderInvestigation && (showAcaUI || showInspectorUI) && (
            <Fragment>
              <OspNotification banner="underInvestigation" />
              <Spacer vertical={10} />
            </Fragment>
          )}
          {planApp.isInMediation && (
            <Fragment>
              <OspNotification banner="inMediation" />
              <Spacer vertical={10} />
            </Fragment>
          )}
          <Heading pageTitle noMargin>
            My Plan
          </Heading>
          {showInspectorUI ? (
            InspectorDisplay(planAnnualInspection, planPartialInspections, inspectorId)
          ) : !showInspectorUI && !showAcaUI ? (
            clientInspectionDisplay(planPartialInspections)
          ) : (
            <Spacer vertical={10} />
          )}
          {showAcaUI && !showInspectorUI ? (
            <Widget>
              <WidgetHeading className="organization-dashboard-heading">
                Inspections
              </WidgetHeading>
              <WidgetBody>
                <Table
                  className="inspection-table"
                  condensed
                  head={
                    <TableRow>
                      <TableCell valignTop heading>
                        Inspection Type:
                      </TableCell>
                      <TableCell valignTop heading>
                        Inspection Date:
                      </TableCell>
                      <TableCell valignTop heading>
                        Inspectors Assigned:
                      </TableCell>
                      <TableCell valignTop heading>
                        Inspection Status:
                      </TableCell>
                      <TableCell valignTop heading>
                        Sample:
                      </TableCell>
                      <TableCell valignTop heading>
                        Inspection ID:
                      </TableCell>
                    </TableRow>
                  }
                >
                  <TableRow className="annual-row">
                    <TableCell>
                      <Block className="inspection-type">
                        <Block className="annual-icon icon" inline>
                          <FontAwesome icon={planAnnualInspection.inspectionTypeIcon} />
                        </Block>
                        <Block inline>
                          <Block className="annual-heading">
                            <Block>
                              {planAnnualInspection.inspectionSubType}{' '}
                              {planAnnualInspection.inspectionType}
                            </Block>
                          </Block>
                          <Block>Anniversary Date:</Block>
                          <Block className="anniversary-date">
                            {planApp.anniversaryDate
                              ? formattedDate(planApp.anniversaryDate)
                              : 'N/A'}
                          </Block>
                        </Block>
                      </Block>
                    </TableCell>
                    <TableCell>
                      {planAnnualInspection.inspectionDate
                        ? formattedDate(planAnnualInspection.inspectionDate)
                        : 'Not Set'}
                    </TableCell>
                    <TableCell>
                      {planAnnualInspection.inspectors.length > 0 ? (
                        planAnnualInspection.inspectors
                          .map(inspector => inspector.name)
                          .join(', ')
                      ) : (
                        <Block>No inspector assigned.</Block>
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {planAnnualInspection.samples.length > 0 ? (
                        <List>
                          {planAnnualInspection.samples.map(sample => (
                            <ListItem key={sample.id}>
                              {sample.typeName}
                              <SampleStatus status={sample.state}>
                                {`(${sample.state})`}
                              </SampleStatus>
                              <InspectionStateChangeModalContainer
                                type="sample"
                                orgId={orgId}
                                appId={planAppId}
                                updateState={props.updateSampleState}
                                initialValues={{
                                  item_id: sample.id,
                                  sample_state_id: {
                                    value: sample.stateId,
                                    label: sample.state,
                                  },
                                }}
                              >
                                {toggleModal => (
                                  <Button
                                    className="annual-button status-button"
                                    onClick={toggleModal}
                                  >
                                    Change Status
                                  </Button>
                                )}
                              </InspectionStateChangeModalContainer>
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        'Sample not included.'
                      )}
                    </TableCell>
                    <TableCell>{planAnnualInspection.id}</TableCell>
                  </TableRow>
                  {pendingSampleInspections &&
                    pendingSampleInspections.map(inspection => (
                      <TableRow className="annual-row">
                        <TableCell>
                          <Block className="inspection-type">
                            <Block className="annual-icon icon" inline>
                              <FontAwesome
                                icon={planAnnualInspection.inspectionTypeIcon}
                              />
                            </Block>
                            <Block inline>
                              <Block className="annual-heading">
                                <Block>
                                  {inspection.inspectionSubType}{' '}
                                  {inspection.inspectionType}
                                </Block>
                              </Block>
                            </Block>
                          </Block>
                        </TableCell>
                        <TableCell>
                          {inspection.inspectionDate
                            ? formattedDate(inspection.inspectionDate)
                            : 'Not Set'}
                        </TableCell>
                        <TableCell>
                          {inspection.inspectors && inspection.inspectors.length > 0 ? (
                            inspection.inspectors
                              .map(inspector => inspector.name)
                              .join(', ')
                          ) : (
                            <Block>No inspector assigned.</Block>
                          )}
                        </TableCell>
                        <TableCell>
                          <Block className="annual-status" textCenter>
                            {inspection.inspectionState.toUpperCase()}
                          </Block>
                        </TableCell>
                        <TableCell>
                          <List>
                            {inspection.samples.map(sample => (
                              <ListItem key={sample.id}>
                                {sample.typeName}
                                <SampleStatus status={sample.stateName}>
                                  {`(${sample.stateName})`}
                                </SampleStatus>
                                <InspectionStateChangeModalContainer
                                  type="sample"
                                  orgId={orgId}
                                  appId={planAppId}
                                  updateState={props.updateSampleState}
                                  refreshList={props.getPendingAnnualInspection}
                                  initialValues={{
                                    item_id: sample.id,
                                    sample_state_id: {
                                      value: sample.stateId,
                                      label: sample.stateName,
                                    },
                                  }}
                                >
                                  {toggleModal => (
                                    <Button
                                      className="partial-button status-button"
                                      onClick={toggleModal}
                                    >
                                      Change Status
                                    </Button>
                                  )}
                                </InspectionStateChangeModalContainer>
                              </ListItem>
                            ))}
                          </List>
                        </TableCell>
                        <TableCell>{inspection.id}</TableCell>
                      </TableRow>
                    ))}
                  {planPartialInspections
                    .filter(
                      inspection =>
                        inspection.inspectionStateRank < inspectionStatesRank.complete,
                    )
                    .map(inspection => (
                      <TableRow key={inspection.id}>
                        <TableCell>
                          <Block className="inspection-type">
                            <Block className="icon" inline>
                              <FontAwesome icon={inspection.inspectionTypeIcon} />
                            </Block>
                            <Block inline>
                              <Block>
                                {inspection.inspectionSubType} {inspection.inspectionType}
                              </Block>
                              <Button
                                className="partial-button status-button"
                                onClick={() =>
                                  props.updatePartial(orgId, planAppId, inspection.id, {
                                    is_expedited: !inspection.inspectionExpedited,
                                  })
                                }
                              >
                                {inspection.inspectionExpedited
                                  ? 'Remove Expedite'
                                  : 'Expedite Inspection'}
                              </Button>
                            </Block>
                          </Block>
                        </TableCell>
                        <TableCell>
                          {inspection.inspectionDate
                            ? formattedDate(inspection.inspectionDate)
                            : 'Not Set'}
                        </TableCell>
                        <TableCell>
                          {inspection.inspectors.length > 0 ? (
                            inspection.inspectors
                              .map(inspector => inspector.name)
                              .join(', ')
                          ) : (
                            <Block>No inspector assigned.</Block>
                          )}
                        </TableCell>
                        <TableCell>
                          {inspection.inspectionState}{' '}
                          <Confirm
                            message="This partial inspection review will be set to complete. This will not impact the overall certification cycle status or OSP for the annual cycle. If you feel future follow up will be needed with another partial inspection please make sure to create an applicable VR in SF for the future.  "
                            onConfirm={() => {
                              props
                                .markInspectionComplete(orgId, planAppId, inspection.id)
                                .then(({ api }) => {
                                  if (R.has('error', api.response.data)) {
                                    props.toast('danger', api.response.data.error);
                                  }
                                });
                            }}
                          >
                            {toggleConfirm => (
                              <InspectionStateChangeModalContainer
                                type="inspection"
                                key={inspection.id}
                                orgId={orgId}
                                appId={planAppId}
                                updateState={props.updatePartial}
                                toggleConfirm={toggleConfirm}
                                inspectionSamples={inspection.samples}
                                initialValues={{
                                  item_id: inspection.id,
                                  inspection_state_id: {
                                    value: inspection.inspectionStateId,
                                    label: inspection.inspectionState,
                                  },
                                }}
                              >
                                {toggleModal => (
                                  <Button
                                    className="partial-button status-button"
                                    onClick={toggleModal}
                                  >
                                    Change Status
                                  </Button>
                                )}
                              </InspectionStateChangeModalContainer>
                            )}
                          </Confirm>
                        </TableCell>
                        <TableCell>
                          {inspection.samples.length > 0 ? (
                            <List>
                              {inspection.samples.map(sample => (
                                <ListItem key={sample.id}>
                                  {sample.typeName}
                                  <SampleStatus status={sample.state}>
                                    {`(${sample.state})`}
                                  </SampleStatus>
                                  <InspectionStateChangeModalContainer
                                    type="sample"
                                    orgId={orgId}
                                    appId={planAppId}
                                    updateState={props.updateSampleState}
                                    initialValues={{
                                      item_id: sample.id,
                                      sample_state_id: {
                                        value: sample.stateId,
                                        label: sample.state,
                                      },
                                    }}
                                  >
                                    {toggleModal => (
                                      <Button
                                        className="partial-button status-button"
                                        onClick={toggleModal}
                                      >
                                        Change Status
                                      </Button>
                                    )}
                                  </InspectionStateChangeModalContainer>
                                </ListItem>
                              ))}
                            </List>
                          ) : (
                            <Block>Sample not included.</Block>
                          )}
                        </TableCell>
                        <TableCell>{inspection.id}</TableCell>
                      </TableRow>
                    ))}
                </Table>
              </WidgetBody>
            </Widget>
          ) : (
            <Widget>
              <WidgetBody className="date-display">
                <Row>
                  <Column md={6}>
                    <FontAwesome icon="calendar-day" className="date-icon" />
                    <Strong>Anniversary Date: </Strong>
                    {planApp.anniversaryDate
                      ? formattedDate(planApp.anniversaryDate)
                      : 'N/A'}
                  </Column>
                  <Column md={6}>
                    <FontAwesome icon="calendar-check" className="date-icon" />
                    <Strong>Inspection Date: </Strong>
                    {planApp.inspectionDate
                      ? formattedDate(planApp.inspectionDate)
                      : 'N/A'}
                  </Column>
                </Row>
              </WidgetBody>
            </Widget>
          )}
          <Spacer vertical={20} />
          {certifications.length > 0 && (
            <Fragment>
              <Widget>
                <WidgetHeading className="organization-dashboard-heading">
                  Certifications
                </WidgetHeading>
                <WidgetBody className="certification-block">
                  <Block className="scrollable-window certification-display">
                    {certifications
                      .filter(
                        certification =>
                          certification.file_id &&
                          !certification.file.removed_from_bucket,
                      )
                      .map(certification => (
                        <Image
                          key={certification.id}
                          title={certification.name}
                          className="certification-image"
                          imageUrl={`${API_URL}/v1/file/by_path/${certification.file.s3_name}`}
                        />
                      ))}
                  </Block>
                </WidgetBody>
              </Widget>
              <Spacer vertical={20} />
            </Fragment>
          )}
          <Widget>
            <WidgetHeading className="organization-dashboard-heading">
              Worksheets
              <InformationIcon className="heading-info" overlay="Test" />
            </WidgetHeading>
            <WidgetBody>
              <Block>
                <OspStepCard
                  cardTitle="activities"
                  cardLink={checklistRoute(orgId, planAppId)}
                  complete={hasWorksheets}
                  isInitial={isInitial}
                />
                <OspStepCard
                  cardTitle="worksheets"
                  cardLink={worksheetOverviewRoute(orgId, planAppId)}
                  status={isInitial && initialSummary.worksheets}
                  disabled={!hasWorksheets}
                  complete={
                    !isInitial ||
                    initialSummary.worksheets.completed /
                      initialSummary.worksheets.total ==
                      1
                  }
                  isInitial={isInitial}
                />
                <OspStepCard
                  cardTitle="locations"
                  cardLink={locationOverviewRoute(orgId, planAppId)}
                  status={isInitial && initialSummary.lands}
                  complete={
                    !isInitial ||
                    initialSummary.lands.completed / initialSummary.lands.total == 1
                  }
                  isInitial={isInitial}
                />
              </Block>
            </WidgetBody>
          </Widget>
          <Spacer vertical={20} />
          {!isInitial && (
            <Row className="location-contact-row">
              <Column xs={6} className="contact-column">
                <Widget className="poc-section">
                  <WidgetHeading className="organization-dashboard-heading">
                    <Link to={orgContactsRoute(orgId)} className="heading-link">
                      Contacts
                      <InformationIcon className="heading-info" overlay="Test" />
                      <FontAwesome className="heading-link-icon" icon="chevron-right" />
                      <Clearfix />
                    </Link>
                  </WidgetHeading>
                  <WidgetBody className="location-contact-body">
                    <Block className="scrollable-window location-contact-list">
                      <ContactDisplay
                        orgPOC={orgPOC}
                        orgName={org.name}
                        planAppState={planApp.state}
                        contactType={1}
                      />
                    </Block>
                  </WidgetBody>
                </Widget>
              </Column>
              <Column xs={6} className="location-column">
                <Widget className="poc-section">
                  <WidgetHeading className="organization-dashboard-heading">
                    <Link to={orgLocationsRoute(orgId)} className="heading-link">
                      Locations
                      <InformationIcon className="heading-info" overlay="Test" />
                      <FontAwesome className="heading-link-icon" icon="chevron-right" />
                      <Clearfix />
                    </Link>
                  </WidgetHeading>
                  <WidgetBody className="location-contact-body">
                    <Block className="scrollable-window location-contact-list">
                      {org.location_list.map((location, index) => {
                        return (
                          <Fragment key={location.land_id}>
                            {index > 0 && (
                              <HorizontalRule className="location-contact-divider" />
                            )}
                            <Block className="poc-info">
                              <Block className="circle location-icon">
                                {abbreviateLoc(location.name)}
                              </Block>
                              <Block className="poc-contact">
                                <Paragraph>
                                  <Strong>{location.name}</Strong>
                                </Paragraph>
                                <Paragraph>{location.address},</Paragraph>
                                <Paragraph>
                                  {`${location.city}, ${location.state} ${location.postal}`}
                                </Paragraph>
                              </Block>
                            </Block>
                          </Fragment>
                        );
                      })}
                    </Block>
                  </WidgetBody>
                </Widget>
              </Column>
            </Row>
          )}
          <Spacer vertical={20} />
          {!isInitial && (
            <Block className="changes-widget">
              <ChangeRequestManager
                planAppId={planAppId}
                showPlanChecklist
                showPlanWorksheets
                showLocations
              />
            </Block>
          )}
          <Spacer vertical={20} />
          <Row>
            <Column md={6}>
              <Widget className="poc-section">
                <WidgetHeading className="organization-dashboard-heading">
                  CS & Inspector Contacts
                </WidgetHeading>
                <WidgetBody className="location-contact-body">
                  <Block className="scrollable-window cs-inspector-list">
                    <ContactDisplay
                      orgPOC={orgPOC}
                      orgName={org.name}
                      planAppState={planApp.state}
                      contactType={2}
                    />
                  </Block>
                </WidgetBody>
              </Widget>
            </Column>
          </Row>
          <Spacer vertical={20} />
          {planAppSummaryList.length > 1 && (
            // Show link to historical plans if they have more than 1
            <Fragment>
              <Link to={historicalPlansRoute(orgId)}>View previous plans</Link>
              <Spacer vertical={10} />
            </Fragment>
          )}
          {!showAcaUI && !showInspectorUI && (
            <OrgWithdrawModal>
              {showModal => <Link onClick={showModal}>Need to withdraw your plan?</Link>}
            </OrgWithdrawModal>
          )}
        </Column>
        <Column md={4}>
          <Widget className="org-status-sidebar">
            <WidgetBody>
              {isInitial && (
                <Fragment>
                  <Heading level={4} noMargin>
                    <Strong>Progress</Strong>
                  </Heading>
                  <HorizontalRule className="progress-divider" />
                  <PlanAppProgressCircle
                    className="percentage-complete"
                    percentageComplete={R.prop('percentageComplete', planApp)}
                  />
                </Fragment>
              )}
              <Block>
                <Heading level={4} noMargin>
                  <Strong>Current Status</Strong>
                </Heading>
                <Widget
                  className={
                    orgSurrendered ? 'flagged-status status-widget' : 'status-widget'
                  }
                >
                  <WidgetBody>
                    <Block className="status-line">
                      <Block className="org-state">
                        {planAppStateText[planApp.state]}
                      </Block>
                      <FontAwesome
                        icon={planAppStateIcon[planApp.state]}
                        className="state-icon"
                      />
                      <Clearfix />
                    </Block>
                    <PlanAppWhatsNext planApp={planApp} hasWorksheets={hasWorksheets} />
                  </WidgetBody>
                </Widget>
              </Block>
              {showAcaUI && !showInspectorUI && (
                <Fragment>
                  <OrgDashboardAcaOptions
                    planAppId={planAppId}
                    planApp={planApp}
                    inspectorAccessList={inspectorAccessList}
                    orgName={org.name}
                  />
                </Fragment>
              )}

              {/* Hidden in WO-684 */}
              {/* {!isComplete && (
                <Block textCenter>
                  <Spacer vertical={20} />
                  <CompletedPlanLink />
                </Block>
              )} */}
            </WidgetBody>
          </Widget>
        </Column>
      </Row>
    </Block>
  );
};

OrganizationDashboard.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  planApp: sowTypes.planAppType.isRequired,
  isInitial: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
  initialSummary: sowTypes.planAppInitialSummaryType,
  configPlanMinNumLocations: PropTypes.number.isRequired,
  showAcaUI: PropTypes.bool.isRequired,
  planAppSummaryList: PropTypes.arrayOf(sowTypes.planAppSummaryType).isRequired,
  showInspectorUI: PropTypes.bool.isRequired,
};

OrganizationDashboard.defaultProps = {
  initialSummary: null,
};

export default OrganizationDashboard;
