import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import InspectionYearListContainer from 'sow/containers/InspectionYearListContainer';

const InspectionYearListPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <InspectionYearListContainer />}
  </AdminPageTemplate>
);

export default InspectionYearListPage;
