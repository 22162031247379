import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

import theme from 'sow/theme';
import Routes from 'sow/routes';
import ImpersonationBorder from 'sow/containers/ImpersonationBorder';
import BranchRibbon from 'sow/components/molecules/BranchRibbon';
import TokenValidator from 'sow/containers/TokenValidator';
import ConfirmModal from 'sow/containers/ConfirmModal';
import ScrollToTop from 'sow/containers/ScrollToTop';

const App = () => (
  <ThemeProvider theme={theme}>
    <Fragment>
      <ImpersonationBorder />
      <BranchRibbon branch={process.env.NODE_ENV} />
      <ConfirmModal />
      <ToastContainer />

      <TokenValidator>
        <ScrollToTop>
          <Routes />
        </ScrollToTop>
      </TokenValidator>
    </Fragment>
  </ThemeProvider>
);

App.propTypes = {
  routes: PropTypes.array,
  params: PropTypes.object,
  children: PropTypes.node,
};

export default App;
