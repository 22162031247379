import React, { Fragment } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import { orgRoute } from 'sow/routes';
import { planAppStateText } from 'sow/constants/planApp';
import { utcStrToLocalMoment, monthDayYearDateFormat } from 'sow/utils/dateTime';
import StandardLayout from 'sow/components/templates/StandardLayout';
import Table from 'sow/components/molecules/Table';
import DataTable from 'sow/components/molecules/DataTable';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Link from 'sow/components/atoms/Link';
import Spinner from 'sow/components/atoms/Spinner';
import PriorityFlag from 'sow/components/molecules/PriorityFlag';
import DashboardFilterCounts from 'sow/components/organisms/DashboardFilterCounts';

const ClientTable = ({ children }) => {
  const TableRow = styled.tr`
    background: #dff0d8;
    color: #3c763d;
    font-weight: bold;
  `;

  return (
    <div style={{ maxWidth: '1000px' }}>
      <DataTable
        selector={'.clientWorkflowTable'}
        tableDef={{
          pageLength: 50,
          destroy: true,
          columnDefs: [
            { type: 'html', targets: 0 },
            { type: 'date', targets: 3 },
          ],
        }}
      >
        <Table
          className="clientWorkflowTable"
          bordered
          striped
          condensed
          style={{ tableLayout: 'fixed' }}
          head={
            <TableRow>
              <TableCell heading textCenter valignMiddle>
                Organization
              </TableCell>
              <TableCell heading textCenter valignMiddle>
                Anniversary Date
              </TableCell>
              <TableCell heading textCenter valignMiddle>
                Status
              </TableCell>
              <TableCell heading textCenter valignMiddle>
                Last Change
              </TableCell>
              <TableCell heading textCenter valignMiddle>
                Category
              </TableCell>
              <TableCell heading textCenter valignMiddle>
                <Row>
                  <Column xs={12}>Changes</Column>
                </Row>
                <Row>
                  <Column xs={6}>To-do</Column>
                  <Column xs={6}>Pending</Column>
                </Row>
              </TableCell>
            </TableRow>
          }
        >
          {children}
        </Table>
      </DataTable>
    </div>
  );
};

const NoItems = () => (
  <TableRow>
    <TableCell colSpan={6} textCenter textSuccess>
      No results
    </TableCell>
  </TableRow>
);

const AcaBubble = styled.div`
  width: 50px;
  border-radius: 5px;
  margin: 5px;
  padding: 3px 0;
  color: white;
  background-color: ${props => (props.blue ? '#4478b6' : 'orange')};
  float: ${props => (props.right ? 'right' : 'left')};
  position: ${props => (props.left ? 'absolute' : 'inherit')};
`;

const statusStyle = appState =>
  appState === 'noncompliance' ? { color: 'red', clear: 'right' } : { clear: 'right' };

const ClientDashboardPage = props => {
  const { ospApp, isDashboardFetching, orgCounts, filter, handleFilterChange } = props;

  return (
    <StandardLayout title="Dashboard">
      {!isDashboardFetching ? (
        <Fragment>
          <DashboardFilterCounts
            dashboardCounts={orgCounts}
            filter={filter}
            handleFilterChange={handleFilterChange}
          />
          <ClientTable>
            {ospApp.length > 0 ? (
              ospApp.map(ospApp => (
                <TableRow key={ospApp.app_id}>
                  <TableCell textCenter valignMiddle>
                    <Link to={orgRoute(ospApp.organization_id)}>
                      {ospApp.organization_name}
                    </Link>
                  </TableCell>
                  <TableCell textCenter valignMiddle style={{ width: '125px' }}>
                    {ospApp.organization_anniversary_date &&
                      monthDayYearDateFormat(ospApp.organization_anniversary_date)}
                  </TableCell>
                  <TableCell textCenter valignMiddle>
                    <PriorityFlag flags={ospApp.application_priority_flags} isClient />
                    <div style={statusStyle(ospApp.app_state)}>
                      {planAppStateText[ospApp.app_state]}
                    </div>
                  </TableCell>
                  <TableCell textCenter valignMiddle style={{ width: '100px' }}>
                    {ospApp.change_updated_at &&
                      utcStrToLocalMoment(ospApp.change_updated_at).format('L LT')}
                  </TableCell>
                  <TableCell textCenter valignMiddle>
                    {ospApp.organization_types}
                  </TableCell>
                  <TableCell textCenter valignMiddle style={{ width: '130px' }}>
                    {ospApp.application_stats && (
                      <AcaBubble blue left>
                        {ospApp.application_stats.num_requests_by_internal}
                      </AcaBubble>
                    )}
                    {ospApp.application_stats && (
                      <AcaBubble right>
                        {ospApp.application_stats.num_changes_by_client +
                          ospApp.application_stats.num_changes_by_internal}
                      </AcaBubble>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <NoItems />
            )}
          </ClientTable>
        </Fragment>
      ) : (
        <Spinner />
      )}
    </StandardLayout>
  );
};

export default ClientDashboardPage;
