import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { SectionHeading } from './style/atoms';
import Worksheet from 'sow/containers/planAppExport/Content/Worksheet';
import { WorksheetLocationContainer } from './style/atoms';
import PendingAsterisk from 'sow/components/molecules/PendingAsterisk';

const Location = ({
  locationDetails,
  isDiscarded,
  isExcluded,
  locationChange,
  ...props
}) => {
  let updated = false;
  let deleted = false;

  const checkDelete = () => {
    if (
      locationChange &&
      locationChange.state === 'accepted' &&
      locationChange.action === 'deleted'
    ) {
      return true;
    }
  };

  if (locationChange && locationChange.state === 'open') {
    if (locationChange.action === 'deleted') deleted = true;
    if (locationChange.action === 'updated') updated = true;
  }

  const locationChangePath = path => R.path(path, locationChange);

  const findUpdates = path => {
    if (updated) {
      if (locationChangePath(['new', ...path]) !== locationChangePath(['old', ...path])) {
        return true;
      }
    }
    return false;
  };

  if (isDiscarded) return null;

  // TODO figure out if we need to render something when location is excluded
  if (isExcluded) return null;

  const locationPath = path => R.path(path, locationDetails);
  const name = locationPath(['name']);
  const type = locationPath(['type']);
  const scope = locationPath(['scope']);
  const address = locationPath(['location', 'address']);
  const addressText = locationPath(['location', 'addressText']);
  const city = locationPath(['location', 'city']);
  const state = locationPath(['location', 'state']);
  const postal = locationPath(['location', 'postal']);
  const country = locationPath(['location', 'country']);

  const worksheetIdList = R.propOr([], 'worksheetIds', locationDetails);

  return (
    <Fragment>
      {!checkDelete() && (
        <Fragment>
          <SectionHeading pageBreak="always" />
          <WorksheetLocationContainer>
            <div className="location-table">
              <table>
                <thead className="location-header">
                  <tr>
                    <th colSpan={7}>
                      <PendingAsterisk deleted={deleted} updated={updated}>
                        {name}
                      </PendingAsterisk>
                    </th>
                  </tr>
                </thead>
                <tbody className="location-body">
                  <tr className="headers">
                    <td>Type:</td>
                    <td>Scope:</td>
                    <td>Street Address:</td>
                    <td>City:</td>
                    <td>State:</td>
                    <td>Postal Code:</td>
                    <td>Country:</td>
                  </tr>
                  <tr>
                    <td>
                      <PendingAsterisk deleted={deleted} updated={findUpdates(['type'])}>
                        {type}
                      </PendingAsterisk>
                    </td>
                    <td>
                      <PendingAsterisk deleted={deleted} updated={findUpdates(['scope'])}>
                        {scope}
                      </PendingAsterisk>
                    </td>
                    <td>
                      <PendingAsterisk
                        deleted={deleted}
                        updated={findUpdates(['location', 'address'])}
                      >
                        {address}
                      </PendingAsterisk>
                    </td>
                    <td>
                      <PendingAsterisk
                        deleted={deleted}
                        updated={findUpdates(['location', 'city'])}
                      >
                        {city}
                      </PendingAsterisk>
                    </td>
                    <td>
                      <PendingAsterisk
                        deleted={deleted}
                        updated={findUpdates(['location', 'state'])}
                      >
                        {state}
                      </PendingAsterisk>
                    </td>
                    <td>
                      <PendingAsterisk
                        deleted={deleted}
                        updated={findUpdates(['location', 'postal'])}
                      >
                        {postal}
                      </PendingAsterisk>
                    </td>
                    <td>
                      <PendingAsterisk
                        deleted={deleted}
                        updated={findUpdates(['location', 'country'])}
                      >
                        {country}
                      </PendingAsterisk>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </WorksheetLocationContainer>

          {worksheetIdList.map(worksheetId => (
            <Worksheet
              key={worksheetId}
              locationId={location.id}
              worksheetId={worksheetId}
              locationPending={deleted}
              {...props}
            />
          ))}
        </Fragment>
      )}
    </Fragment>
  );
};

Location.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  qualifiedWorksheetIds: PropTypes.arrayOf(sowTypes.planAppWorksheetIdType),
  worksheetSections: PropTypes.arrayOf(sowTypes.planAppWorksheetSectionType),
  showMainPlan: PropTypes.bool,
  showLocations: PropTypes.bool,
  addNotes: PropTypes.bool,
  addComments: PropTypes.bool,
  addFiles: PropTypes.bool,
  userList: sowTypes.userLimitedListType.isRequired,
  locationDetails: sowTypes.planAppLocationType.isRequired,
  locationChange: sowTypes.planAppChangeType,
  isExcluded: PropTypes.bool.isRequired,
  isDiscarded: PropTypes.bool.isRequired,
};

export default Location;
