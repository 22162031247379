import * as R from 'ramda';
import React from 'react';

import { adminCreateAcaRoute } from 'sow/routes';
import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import Link from 'sow/components/atoms/Link';
import Heading from 'sow/components/atoms/Heading';
import AcaList from 'sow/components/organisms/AcaList';

const DashboardPage = () => {
  return (
    <AdminPageTemplate title="ACA Listing">
      {acas => {
        const showList = acas && !R.isEmpty(acas);
        return (
          <Widget>
            <WidgetHeading>ACAs</WidgetHeading>
            <WidgetBody>
              {showList ? <AcaList acas={acas} /> : <Heading level={4}>No ACAs</Heading>}

              <Link to={adminCreateAcaRoute()} className="btn btn-primary">
                Create New ACA
              </Link>
            </WidgetBody>
          </Widget>
        );
      }}
    </AdminPageTemplate>
  );
};

DashboardPage.propTypes = {};

export default DashboardPage;
