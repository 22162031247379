const mark = action => `ITEM_SUBTYPE/${action}`;

export const LOAD_ITEM_SUBTYPES = mark('LOAD_ITEM_SUBTYPES');
export const FETCH_BY_ID = mark('FETCH_BY_ID');
export const FETCH_BY_ID_DONE = mark('FETCH_BY_ID_DONE');
export const ADD_ITEM_SUBTYPE = mark('ADD_ITEM_SUBTYPE');
export const ADD_ITEM_SUBTYPE_DONE = mark('ADD_ITEM_SUBTYPE_DONE');
export const UPDATE_ITEM_SUBTYPE = mark('UPDATE_ITEM_SUBTYPE');
export const UPDATE_ITEM_SUBTYPE_DONE = mark('UPDATE_ITEM_SUBTYPE_DONE');
export const UPDATE_SORT_ORDER = mark('UPDATE_SORT_ORDER');
export const DELETE_ITEM_SUBTYPE = mark('DELETE_ITEM_SUBTYPE');

export const loadItemSubtypes = (payload, onSuccess) => ({
  type: LOAD_ITEM_SUBTYPES,
  payload,
  meta: { onSuccess },
});

export const fetchById = (payload, onSuccess) => ({
  type: FETCH_BY_ID,
  payload,
  meta: { onSuccess },
});

export const fetchByIdDone = payload => ({
  type: FETCH_BY_ID_DONE,
  payload,
});

export const addItemSubtype = (payload, onSuccess) => ({
  type: ADD_ITEM_SUBTYPE,
  payload,
  meta: { onSuccess },
});

export const addItemSubtypeDone = payload => ({
  type: ADD_ITEM_SUBTYPE_DONE,
  payload,
});

export const updateItemSubtype = (id, payload, onSuccess) => ({
  type: UPDATE_ITEM_SUBTYPE,
  id,
  payload,
  meta: { onSuccess },
});

export const updateItemSubtypeDone = payload => ({
  type: UPDATE_ITEM_SUBTYPE_DONE,
  payload,
});

export const updateSortOrder = (payload, onSuccess) => ({
  type: UPDATE_SORT_ORDER,
  payload,
  meta: { onSuccess },
});

export const deleteItemSubtype = (id, onSuccess) => ({
  type: DELETE_ITEM_SUBTYPE,
  id,
  meta: { onSuccess },
});
