import React, { Fragment } from 'react';

import {
  reportCSVRoute
} from 'sow/routes';
import * as sowTypes from 'sow/types';
import Button from 'sow/components/atoms/Button';
import Form from 'sow/components/atoms/Form';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Spacer from 'sow/components/atoms/Spacer';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Spinner from 'sow/components/atoms/Spinner';
import Link from 'sow/components/atoms/Link';
import AcaSettingsForm from 'sow/containers/AcaSettingsForm';
import AcaForm from 'sow/components/organisms/AcaForm';
import AcaReportTable from 'sow/components/organisms/AcaReportTable';
import { API_URL } from 'sow/constants/api';

const AcaReportPage = ({ aca, acaId, reportId, reportRequests, isFetching }) => (
  <Fragment>
	<Row>
	  <Column md={12}>
		<div>
			<Spacer vertical={10} />
			{isFetching ? '' : 
				<Link href={`${API_URL}/v1/aca/${acaId}/reports/${reportId}/export`}target="_blank" btn lg primary>
					<FontAwesome icon="file-excel" /> Download Report
				</Link>}
			{isFetching ? <Spinner /> : <AcaReportTable report={reportRequests} />}
			{isFetching ? '' : 
				<Link href={`${API_URL}/v1/aca/${acaId}/reports/${reportId}/export`}target="_blank" btn lg primary>
					<FontAwesome icon="file-excel" /> Download Report
				</Link>}
		</div>
	  </Column>
	</Row>
  </Fragment>
);

AcaReportPage.propTypes = {
  aca: sowTypes.acaType.isRequired,
  acaId: sowTypes.acaIdType.isRequired,
};

export default AcaReportPage;
