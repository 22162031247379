import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import UserManagementTemplate from 'sow/components/templates/UserManagementTemplate';

const mapStateToProps = (state, props) => ({});

class UserManagementTemplateContainer extends Component {
  render() {
    return (
      <UserManagementTemplate userId={this.props.match.params.userId} {...this.props} />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(UserManagementTemplateContainer);
