import React, { Fragment } from 'react';

import { adminUserEdit, adminUserHistory } from 'sow/routes';

import Nav from 'sow/components/atoms/Nav';
import NavLink from 'sow/components/molecules/NavLink';

const UserManagementTemplate = ({ children, userId }) => {
  return (
    <Fragment>
      <Nav tabs>
        <NavLink to={adminUserEdit(userId)} exact>
          User
        </NavLink>
        <NavLink to={adminUserHistory(userId)}>History</NavLink>
      </Nav>
      {children}
    </Fragment>
  );
};

export default UserManagementTemplate;
