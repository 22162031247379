const mark = action => `ORGANIZATION_TYPE/${action}`;

export const LOAD_ORGANIZATION_TYPES = mark('LOAD_ORGANIZATION_TYPES');
export const LOAD_ORGANIZATION_TYPES_DONE = mark('LOAD_ORGANIZATION_TYPES_DONE');
export const LOAD_ORGANIZATION_TYPE_BY_ID = mark('LOAD_ORGANIZATION_TYPE_BY_ID');
export const ADD_ORGANIZATION_TYPE = mark('ADD_ORGANIZATION_TYPE');
export const DELETE_ORGANIZATION_TYPE = mark('DELETE_ORGANIZATION_TYPE');
export const UPDATE_ORGANIZATION_TYPE = mark('UPDATE_ORGANIZATION_TYPE');

export const loadOrganizationTypes = (payload, onSuccess) => ({
  type: LOAD_ORGANIZATION_TYPES,
  payload,
  meta: { onSuccess },
});

export const loadOrganizationTypesDone = payload => ({
  type: LOAD_ORGANIZATION_TYPES_DONE,
  payload,
});

export const loadOrganizationTypeById = (id, onSuccess) => ({
  type: LOAD_ORGANIZATION_TYPE_BY_ID,
  id,
  meta: { onSuccess },
});

export const addOrganizationType = (payload, onSuccess) => ({
  type: ADD_ORGANIZATION_TYPE,
  payload,
  meta: { onSuccess },
});

export const updateOrganizationType = (id, payload, onSuccess) => ({
  type: UPDATE_ORGANIZATION_TYPE,
  id,
  payload,
  meta: { onSuccess },
});

export const deleteOrganizationType = (id, onSuccess) => ({
  type: DELETE_ORGANIZATION_TYPE,
  id,
  meta: { onSuccess },
});
