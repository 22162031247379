import React, { Fragment } from 'react';
import Select from 'react-select';
import ReactDatetime from 'react-datetime';

import './InspectorDashboard.module.css';
import { planAppStateText } from 'sow/constants/planApp';
import { API_URL } from 'sow/constants/api';
import { orgRoute } from 'sow/routes';
import { monthDayYearDateFormat } from 'sow/utils/dateTime';
import DataTable from 'sow/components/molecules/DataTable';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Link from 'sow/components/atoms/Link';
import Paragraph from 'sow/components/atoms/Paragraph';
import Panel from 'sow/components/molecules/Panel';
import Button from 'sow/components/atoms/Button';
import FormGroup from 'sow/components/organisms/FormGroup';
import Clearfix from 'sow/components/atoms/Clearfix';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Spinner from 'sow/components/atoms/Spinner';
import PriorityFlag from 'sow/components/molecules/PriorityFlag';
import DashboardFilterCounts from 'sow/components/organisms/DashboardFilterCounts';
import Image from 'sow/components/atoms/Image';
import PartialInspectionRow from 'sow/components/organisms/InspectorDashboard/PartialInspectionRow';
import SampleFlag from 'sow/components/molecules/SampleFlag';
import InspectionDoneModal from 'sow/components/organisms/InspectorDashboard/InspectionDoneModal';

const inspectionDateAssigned = org =>
  (org.inspection_date && org.partial_inspection_id) || org.app_state === 'in_inspection';

const InspectionState = ({ inspection }) => {
  switch (inspection.app_state) {
    case 'final_review':
    case 'in_final_review':
      return (
        <Fragment>
          <Paragraph className="inspection-done-text">Inspection Done</Paragraph>
          <FontAwesome className="inspection-done-check" icon="check-circle" />
        </Fragment>
      );
    case 'in_inspection':
      return (
        <InspectionDoneModal annual={true} inspection={inspection}>
          {toggleSampleModal => (
            <Button className="inspection-done" primary onClick={toggleSampleModal}>
              Inspection Done
            </Button>
          )}
        </InspectionDoneModal>
      );

    default:
      return null;
  }
};

const InspectorDashboard = ({
  acaId,
  isDashboardFetching,
  isFetchingCounts,
  dashboardList,
  dashboardCounts,
  handleSelectChange,
  handleFilterChange,
  filter,
  state,
  handleAssign,
  handleDateChange,
  handleRemoveInspectionDate,
  getInspectionAge,
}) => {
  const orgDropdownList = [];

  if (!isDashboardFetching) {
    dashboardList
      .filter(
        org =>
          (!org.partial_inspection && org.app_state === 'inspection') ||
          (org.partial_inspection && org.inspection_state_id === 1),
      )
      .map(org =>
        orgDropdownList.push({
          orgState: org.app_state,
          planAppId: org.app_id,
          orgId: org.organization_id,
          value: org.inspection_id,
          label: `${org.organization_name} (${org.inspection_type})`,
          partialInspection: org.partial_inspection,
          inspectionId: org.inspection_id,
          stateName: 'inspectionSelect',
        }),
      );
  }

  return (
    <div className="inspector-dashboard">
      {isDashboardFetching || isFetchingCounts ? (
        <Spinner />
      ) : (
        <Fragment>
          <DashboardFilterCounts
            dashboardCounts={dashboardCounts}
            filter={filter}
            handleFilterChange={handleFilterChange}
          />
          <Panel title="Add an Inspection Date to an Organization">
            <FormGroup className="date-select">
              <Select
                options={orgDropdownList}
                clearable={false}
                onChange={handleSelectChange}
                value={state.inspectionSelect}
              />
            </FormGroup>
            <FormGroup
              className="date-select"
              fieldName="dateSelect"
              errors={state.fieldErrors}
            >
              <ReactDatetime
                id="anniversary_date"
                timeFormat={false}
                dateFormat="MM/DD/YYYY"
                closeOnSelect={true}
                onChange={handleDateChange}
                value={state.inspectionDate}
                inputProps={{
                  placeholder: 'Select Inspection Date',
                  disabled: state.inspectionSelect === 0,
                  style: { height: '36px', fontSize: '13px' },
                }}
              />
            </FormGroup>
            <Button
              onClick={handleAssign}
              disabled={state.inspectionSelect === 0}
              className="assign-button"
              style={{ margin: '0px' }}
              primary
            >
              Assign
            </Button>
            <Clearfix />
          </Panel>
          <DataTable
            selector={'.inspectorWorkflowTable'}
            tableDef={{
              pageLength: 50,
              destroy: true,
              order: [],
              autoWidth: false,
              columnDefs: [{ targets: 10, sortable: false }],
            }}
          >
            <Table
              className="inspectorWorkflowTable"
              bordered
              striped
              condensed
              head={
                <TableRow className="header-row">
                  <TableCell heading valignMiddle className="client-column">
                    Client
                  </TableCell>
                  <TableCell heading valignMiddle className="client-column">
                    Inspection Type
                  </TableCell>
                  <TableCell heading valignMiddle className="physical-column">
                    Physical City
                  </TableCell>
                  <TableCell heading valignMiddle className="physical-column">
                    Physical State
                  </TableCell>
                  <TableCell heading valignMiddle className="anniversary-column">
                    Anniversary Date
                  </TableCell>
                  <TableCell heading valignMiddle className="category-column">
                    Category
                  </TableCell>
                  <TableCell heading valignMiddle className="age-column">
                    Inspection Age
                  </TableCell>
                  <TableCell heading valignMiddle className="date-column">
                    Inspection Date
                  </TableCell>
                  <TableCell heading valignMiddle className="status-column">
                    Status
                  </TableCell>
                  <TableCell heading valignMiddle className="status-column">
                    Certifications
                  </TableCell>
                  <TableCell heading valignMiddle className="done-column"></TableCell>
                </TableRow>
              }
            >
              {dashboardList.length > 0 ? (
                dashboardList.map((inspection, index) => {
                  if (inspection.partial_inspection)
                    return (
                      <PartialInspectionRow
                        key={index}
                        inspection={inspection}
                        handleRemoveInspectionDate={handleRemoveInspectionDate}
                        getInspectionAge={getInspectionAge}
                      />
                    );
                  return (
                    <TableRow key={index}>
                      <TableCell valignMiddle className="client-column">
                        {inspection.app_state === 'in_inspection' ||
                        inspection.app_state === 'inspection' ||
                        inspection.partial_inspection_id ? (
                          <Link to={orgRoute(inspection.organization_id)}>
                            {inspection.organization_name}
                          </Link>
                        ) : (
                          <Fragment>{inspection.organization_name}</Fragment>
                        )}
                      </TableCell>
                      <TableCell className="inspection-type-column" valignMiddle>
                        {inspection.has_sample && <SampleFlag />}
                        <FontAwesome icon={inspection.inspection_type_icon} />{' '}
                        {inspection.inspection_type}
                      </TableCell>
                      <TableCell valignMiddle className="physical-column">
                        {inspection.org_city}
                      </TableCell>
                      <TableCell valignMiddle className="physical-column">
                        {inspection.org_state}
                      </TableCell>
                      <TableCell valignMiddle className="anniversary-column">
                        {inspection.organization_anniversary_date &&
                          monthDayYearDateFormat(
                            inspection.organization_anniversary_date,
                          )}
                      </TableCell>
                      <TableCell valignMiddle className="category-column">
                        {inspection.organization_types}
                      </TableCell>
                      <TableCell valignMiddle className="age-column">
                        {getInspectionAge(inspection.inspection_age)}
                      </TableCell>
                      <TableCell valignMiddle className="date-column">
                        {inspection.app_state !== 'complete' &&
                          inspection.inspection_date && (
                            <Fragment>
                              <Paragraph className="inspection-date">
                                {monthDayYearDateFormat(inspection.inspection_date)}
                              </Paragraph>
                              {inspectionDateAssigned(inspection) && (
                                <Button
                                  className="remove-date"
                                  onClick={() => handleRemoveInspectionDate(inspection)}
                                >
                                  <FontAwesome className="remove-date-x" icon="times" />
                                </Button>
                              )}
                            </Fragment>
                          )}
                      </TableCell>
                      <TableCell valignMiddle className="status-column">
                        <PriorityFlag
                          flags={inspection.application_priority_flags}
                          isInspector={true}
                        />
                        <Paragraph className="state-text">
                          {planAppStateText[inspection.app_state]}
                        </Paragraph>
                      </TableCell>
                      <TableCell className="certification-column">
                        {inspection.certifications &&
                          inspection.certifications.map((cert, index) => (
                            <Fragment key={index}>
                              <div className="cert-name">{cert.name}</div>
                              <Image
                                key={index}
                                imageUrl={`${API_URL}/v1/file/by_path/${cert.s3_name}`}
                                title={`${cert.name}`}
                              />
                            </Fragment>
                          ))}
                      </TableCell>
                      <TableCell valignMiddle textCenter className="done-column">
                        <InspectionState inspection={inspection} />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : acaId() ? (
                <TableRow>
                  <TableCell textCenter colSpan={11}>
                    No results.
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell textCenter colSpan={11}>
                    No organizations have been assigned.
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </DataTable>
        </Fragment>
      )}
    </div>
  );
};

export default InspectorDashboard;
