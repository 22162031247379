import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { inspectionStates, inspectionTypes } from 'sow/constants/inspectionConstants';
import { sampleRanks } from 'sow/constants/sampleConstants';

import InspectionStateChangeModal from 'sow/components/organisms/InspectionStateChangeModal';

const mapStateToProps = state => ({
  inspectionOptions: state.inspection.inspectionStateOptions,
  sampleOptions: state.sample.sampleStateOptions,
});

const mapDispatchToProps = {};

class InspectionStateChangeModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  componentDidMount() {
    const { inspectionSamples } = this.props;
    if (inspectionSamples) {
      this.setState({ saveBlocked: this.blockSave(inspectionSamples) });
    }
  }

  componentDidUpdate(prevProps) {
    const { inspectionSamples } = this.props;
    if (
      this.blockSave(inspectionSamples) !== this.blockSave(prevProps.inspectionSamples)
    ) {
      this.setState({ saveBlocked: this.blockSave(inspectionSamples) });
    }
  }

  blockSave(list) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].stateRank !== sampleRanks.closed) {
        return true;
      }
    }

    return false;
  }

  toggleModal() {
    this.setState(R.assocPath(['show'], !this.state.show));
  }

  updateState(values) {
    const { orgId, appId, updateState, type, refreshList } = this.props;

    if (
      type === 'inspection' &&
      values[`${type}_state_id`] === inspectionStates.complete
    ) {
      this.toggleModal();
      this.props.toggleConfirm();
    } else {
      const updatedStateObj = {};
      if (type === 'inspection') {
        updatedStateObj.inspection_state_id = values[`${type}_state_id`];
      } else if (type === 'sample') {
        updatedStateObj.sample_state_id = values[`${type}_state_id`];
      }

      updateState(orgId, appId, values.item_id, updatedStateObj).then(() => {
        if (refreshList) {
          refreshList(
            {
              orgId,
              remove_current_application_id: appId,
              minimum_sample_state_rank: sampleRanks.closed,
              inspection_type_id: inspectionTypes.annual,
            },
            this.toggleModal.bind(this),
          );
        } else {
          this.toggleModal();
        }
      });
    }
  }

  render() {
    return (
      <InspectionStateChangeModal
        show={this.state.show}
        toggleModal={this.toggleModal.bind(this)}
        onSubmit={this.updateState.bind(this)}
        options={this.props[`${this.props.type}Options`]}
        saveBlocked={this.state.saveBlocked}
        {...this.props}
      />
    );
  }
}

InspectionStateChangeModalContainer.propTypes = {
  inspectionSamples: PropTypes.array,
};

InspectionStateChangeModalContainer.defaultProps = {
  inspectionSamples: [],
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InspectionStateChangeModalContainer);
