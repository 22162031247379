import { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { loadAllOspDefsForAca } from 'sow/actions/ospDefinition';
import * as currentAca from 'sow/selectors/currentAca';
import * as currentOspDef from 'sow/selectors/currentOspDef';

const mapStateToProps = (state, props) => ({
  acaId: currentAca.acaId(state, props),
  isLoading: currentOspDef.isLoadingOspDefList(state, props),
  ospDefList: currentOspDef.ospDefList(state, props),
});

const mapDispatchToProps = { loadAllOspDefsForAca };

class OspDefListLoaderContainer extends Component {
  componentDidMount() {
    const { acaId, isLoading } = this.props;

    if (!isLoading && acaId) {
      this.props.loadAllOspDefsForAca(acaId);
    }
  }

  render() {
    const { children, isLoading, ospDefList } = this.props;
    return children && children({ isLoading, ospDefList });
  }
}

OspDefListLoaderContainer.propTypes = {
  children: PropTypes.func,
  acaId: sowTypes.acaIdType.isRequired,
  loadAllOspDefsForAca: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  ospDefList: PropTypes.arrayOf(sowTypes.ospDefType).isRequired,
};

OspDefListLoaderContainer.defaultProps = {
  children: null,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(OspDefListLoaderContainer);
