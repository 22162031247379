import { apiRequest } from 'sow/utils/api';
import { apiUrl } from 'sow/config';

export const apiXhr = request => {
  return apiRequest(request)
    .then((data, textStatus, xhr) => {
      // TODO (orther) reject for non-200 status so they can catch in saga
      if (xhr.status < 200 || xhr.status >= 300) {
        // if (xhr.status !== 200) {
        return Promise.reject(xhr);
      }

      return data;
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const apiDelete = (url, data) => {
  return apiXhr({ method: 'DELETE', url, data });
};

export const apiGet = (url, data) => {
  return apiXhr({ method: 'GET', url, data });
};

export const apiPost = (url, data) => {
  return apiXhr({ method: 'POST', url, data });
};

export const apiPut = (url, data) => {
  return apiXhr({ method: 'PUT', url, data });
};

export const apiPatch = (url, data) => {
  return apiXhr({ method: 'PATCH', url, data });
};

// TODO: hopefully replace this with new arc api service * middlewares
const prefixUrl = apiMethod => (url, ...args) => apiMethod(`${apiUrl}${url}`, ...args);

export const api = {
  delete: prefixUrl(apiDelete),
  get: prefixUrl(apiGet),
  post: prefixUrl(apiPost),
  put: prefixUrl(apiPut),
  patch: prefixUrl(apiPatch),
};
