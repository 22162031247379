import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import OrgDashboardAcaOptions from 'sow/components/organisms/OrgDashboardAcaOptions';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
});

const OrgDashboardAcaOptionsContainer = props => {
  return <OrgDashboardAcaOptions {...props} />;
};

OrgDashboardAcaOptionsContainer.propTypes = {
  // Passed props
  planAppId: sowTypes.planAppIdType.isRequired,
  planApp: sowTypes.planAppType.isRequired,

  // Connected props
  orgId: sowTypes.orgIdType.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(OrgDashboardAcaOptionsContainer);
