import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import { acaIdType } from 'sow/types';
import { ResourceListLoader } from 'sow/store/helpers';

const AcaSelectContainer = ({ name, value, setFieldValue, required }) => (
  <ResourceListLoader resource="aca/register" autoLoad>
    {({ status, result }) => (
      <ReactSelect
        name={name}
        value={value}
        onChange={selectedOption => setFieldValue(name, selectedOption.value)}
        disabled={!status.done}
        required={required}
        options={(result || []).map(aca => ({ value: aca.id, label: aca.name }))}
        showBlankOption
      />
    )}
  </ResourceListLoader>
);

AcaSelectContainer.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([acaIdType, PropTypes.string]).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

AcaSelectContainer.defaultProps = {
  required: false,
};

export default AcaSelectContainer;
