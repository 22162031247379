import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { fromPlanApp, fromRouter } from 'sow/store/selectors';

import PlanAppOverview from 'sow/components/organisms/PlanAppOverview';

const mapStateToProps = (state, props) => ({
  orgId: fromRouter.paramOrgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  worksheetId: fromPlanApp.worksheetId(state, props),
  planApp: fromPlanApp.planApp(state, props),
  isStateInitialApplication: fromPlanApp.isStateInitialApplication(state, props),
});

const PlanAppOverviewContainer = props => {
  if (!props.planApp) return null;
  return <PlanAppOverview {...props} />;
};

PlanAppOverviewContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheetId: sowTypes.planAppWorksheetIdType,
  planApp: sowTypes.planAppType,
  isStateInitialApplication: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppOverviewContainer);
