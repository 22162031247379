import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as sowTypes from 'sow/types';
import { worksheetRoute, checklistRoute, locationRoute } from 'sow/routes';
import Atom from 'sow/components/atoms/Atom';
import Link from 'sow/components/atoms/Link';
import Block from 'sow/components/atoms/Block';
import Spacer from 'sow/components/atoms/Spacer';

import BlockedText from 'sow/components/organisms/BlockedText';

import {
  locationWorksheetMatrixRowScrollRoute,
  locationWorksheetMatrixRowScrollRouteLinkToObject,
  locationWorksheetQuestionScrollRoute,
  planAppWorksheetMatrixRowScrollRoute,
  planAppWorksheetMatrixRowScrollRouteLinkToObject,
  planAppWorksheetQuestionScrollRoute,
} from 'sow/components/atoms/ScrollTarget/scrollRoutes';

const RequestWrapper = styled(Block)`
  padding: 10px;
  margin-bottom: 5px;
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
`;

const RequestStateText = styled(Block)`
  float: right;
  margin: 0 0 10px 10px;
  font-weight: 700;
  font-size: 12pt;
  color: #31708f;
`;

const DisplayNameWrapper = styled(Block)`
  color: black;
  font-size: 12pt;
`;

const ChangeActionText = styled(Atom)`
  font-weight: 500;
  color: #555555;
`;

function locationSelfLink(orgId, planAppId, locationId, change) {
  const route = locationRoute(orgId, planAppId, locationId);

  if (typeof change !== 'undefined') {
    switch (change.action) {
      case 'added':
      case 'updated':
        return {
          pathname: route,
          state: {
            openModalId: `location-${locationId}`,
          },
        };
  
      case 'deleted':
      case 'default':
        return route;
    }
  } else {
    return route;
  }
}

const RequestListItem = ({
  orgId,
  planAppId,
  worksheetId,
  worksheetName,
  locationId,
  locationName,
  questionId,
  questionName,
  matrixRowId,
  requestItem,
}) => {
  let linkTo;
  let displayName;

  const formatQuestionName = qName =>
    qName ? qName.trim().replace(/:$/, '') : '(unknown)';

  if (R.isNil(locationId) && !R.isNil(matrixRowId)) {
    linkTo = {
        ...planAppWorksheetMatrixRowScrollRouteLinkToObject(
          orgId,
          planAppId,
          worksheetId,
          matrixRowId,
        ),
        state: { openModalId: matrixRowId },
      };
      displayName = (
        <Atom>
          {formatQuestionName(questionName)}{' '}
          <RequestStateText>to-do</RequestStateText>
        </Atom>
      );
  } else if (!R.isNil(locationId)) {
    if (!R.isNil(matrixRowId)) {
      linkTo = {
        ...locationWorksheetMatrixRowScrollRouteLinkToObject(
          orgId,
          planAppId,
          locationId,
          matrixRowId,
        ),
        state: { openModalId: matrixRowId },
      };
      displayName = (
        <Atom>
          {formatQuestionName(questionName)}{' '}
          <RequestStateText>to-do</RequestStateText>
        </Atom>
      );
    } else {
      linkTo = locationWorksheetQuestionScrollRoute(
        orgId,
        planAppId,
        locationId,
        questionId,
      );
      displayName = (
        <Atom>
          {formatQuestionName(locationName)}{' '}
          <RequestStateText>to-do</RequestStateText>
        </Atom>
      );
    }
  } else {
    linkTo = questionId
          ? planAppWorksheetQuestionScrollRoute(orgId, planAppId, worksheetId, questionId)
          : worksheetRoute(orgId, planAppId, worksheetId);
        displayName = (
          <Atom>
            {formatQuestionName(questionName)}{' '}
            <RequestStateText>to-do</RequestStateText>
          </Atom>
        );
  }

  if (linkTo) {
    return (
      <RequestWrapper>
        <Link to={linkTo}>
          <DisplayNameWrapper>{displayName}</DisplayNameWrapper>
        </Link>
      </RequestWrapper>
    );
  }
  return null;
};

RequestListItem.propTypes = {
  orgId: sowTypes.orgIdType,
  planAppId: sowTypes.planAppIdType,
  changeId: sowTypes.planAppChangeIdType,
  worksheetId: sowTypes.planAppWorksheetIdType,
  worksheetName: PropTypes.string,
  matrixRowId: sowTypes.planAppWorksheetQuestionMatrixRowIdType,
  locationId: sowTypes.planAppLocationIdType,
  locationName: PropTypes.string,
  questionId: sowTypes.planAppWorksheetQuestionIdType,
  questionName: PropTypes.string,
  children: PropTypes.node,
  showAcaUI: PropTypes.bool,
  requestItem: PropTypes.object,
};

RequestListItem.defaultProps = {
  children: null,
  worksheetId: null,
  locationId: null,
  locationName: null,
  questionId: null,
  questionName: null,
};

export default RequestListItem;
