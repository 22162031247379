import React from 'react';
import PropTypes from 'prop-types';
import ReactHelmet from 'react-helmet';

const Helmet = ({ title }) => {
  const titleTemplate = title === 'WFCF Organic' ? 'WFCF Organic' : '%s | WFCF Organic';
  return <ReactHelmet title={title} titleTemplate={titleTemplate} />;
};

Helmet.propTypes = {
  title: PropTypes.string,
};

Helmet.defaultProps = {
  title: 'WFCF Organic',
};

export default Helmet;
