import React from 'react';
import PropTypes from 'prop-types';

import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Heading from 'sow/components/atoms/Heading';
import Button from 'sow/components/atoms/Button';

const OrgPlanAppCreate = ({ onCreate }) => (
  <Widget>
    <WidgetHeading>Organic Certification Application</WidgetHeading>
    <WidgetBody textCenter>
      <Heading textMuted level={3}>
        Click the button below to get started.
      </Heading>
      <Button lg success onClick={onCreate}>
        Start
      </Button>
    </WidgetBody>
  </Widget>
);

OrgPlanAppCreate.propTypes = {
  onCreate: PropTypes.func.isRequired,
};

export default OrgPlanAppCreate;
