const mark = action => `CERTIFICATION_TYPE/${action}`;

export const LOAD_ALL_CERTIFICATIONS = mark('LOAD_ALL_CERTIFICATIONS');
export const LOAD_ALL_CERTIFICATIONS_DONE = mark('LOAD_ALL_CERTIFICATIONS_DONE');
export const LOAD_CERTIFICATION_TYPE = mark('LOAD_CERTIFICATION_TYPE');
export const LOAD_CERTIFICATION_TYPE_DONE = mark('LOAD_CERTIFICATION_TYPE_DONE');
export const SAVE_CERTIFICATION_TYPE = mark('SAVE_CERTIFICATION_TYPE');
export const UPDATE_CERTIFICATION_TYPE = mark('UPDATE_CERTIFICATION_TYPE');
export const UPDATE_CERTIFICATION_TYPE_DONE = mark('UPDATE_CERTIFICATION_TYPE_DONE');
export const UPDATE_SORT_ORDER = mark('UPDATE_SORT_ORDER');
export const DELETE_CERTIFICATION_TYPE = mark('DELETE_CERTIFICATION_TYPE');
export const DELETE_CERTIFICATION_IMAGE = mark('DELETE_CERTIFICATION_IMAGE');
export const CLEAR_ONE = mark('CLEAR_ONE');

export const loadAllCertificationTypes = (payload, onSuccess) => ({
  type: LOAD_ALL_CERTIFICATIONS,
  payload,
  meta: { onSuccess },
});

export const loadAllCertificationTypesDone = payload => ({
  type: LOAD_ALL_CERTIFICATIONS_DONE,
  payload,
});

export const loadCertificationType = (payload, onSuccess, onFailure) => ({
  type: LOAD_CERTIFICATION_TYPE,
  payload,
  meta: { onSuccess, onFailure },
});

export const loadCertificationTypeDone = payload => ({
  type: LOAD_CERTIFICATION_TYPE_DONE,
  payload,
});

export const saveCertificationType = (payload, onSuccess) => ({
  type: SAVE_CERTIFICATION_TYPE,
  payload,
  meta: { onSuccess },
});

export const updateCertificationType = (payload, onSuccess) => ({
  type: UPDATE_CERTIFICATION_TYPE,
  payload,
  meta: { onSuccess },
});

export const updateCertificationTypeDone = payload => ({
  type: UPDATE_CERTIFICATION_TYPE_DONE,
  payload,
});

export const updateSortOrder = payload => ({
  type: UPDATE_SORT_ORDER,
  payload,
});

export const deleteCertificationType = (payload, onSuccess) => ({
  type: DELETE_CERTIFICATION_TYPE,
  payload,
  meta: { onSuccess },
});

export const deleteCertificationImage = (payload, onSuccess) => ({
  type: DELETE_CERTIFICATION_IMAGE,
  payload,
  meta: { onSuccess },
});

export const clearOne = () => ({
  type: CLEAR_ONE,
});
