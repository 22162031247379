import * as R from 'ramda';
import RS from 'ramdasauce';

import * as orgSelect from 'sow/selectors/core/organization';
import * as ospAppSelect from 'sow/selectors/core/ospApplication';
import * as ospQQReducer from 'sow/reducers/ospQualificationQuestions';
import * as ospSectionReducer from 'sow/reducers/ospSections';
import * as ospSectionSelect from 'sow/selectors/core/ospSection';
import * as wsAnswersReducer from 'sow/reducers/worksheetAnswers';
import * as wsAnswersSelect from 'sow/selectors/core/worksheetAnswers';
import * as wsQuestionReducer from 'sow/reducers/worksheetQuestions';
import * as wsReducer from 'sow/reducers/worksheets';
import * as wsSelect from 'sow/selectors/core/worksheet';
import { createSafeCachedSelector } from 'sow/utils/selectors';
import { isStateLocked } from 'sow/components/old/OspAppEditor/helpers';

export const orgId = createSafeCachedSelector([orgSelect.currentOrgId], R.identity)(
  orgSelect.currentOrgId,
);

export const org = createSafeCachedSelector([orgSelect.currentOrg], R.identity)(orgId);

export const ospAppId = createSafeCachedSelector(
  [ospAppSelect.currentOspAppId],
  R.identity,
)(ospAppSelect.currentOspAppId);

export const isLoadingOspApp = createSafeCachedSelector(
  [ospAppSelect.currentOspAppIsLoading],
  R.identity,
)(ospAppId);

export const isLoaded = createSafeCachedSelector(
  [ospAppSelect.currentOspAppIsLoaded],
  R.identity,
)(ospAppId);

export const ospApp = createSafeCachedSelector([ospAppSelect.currentOspApp], R.identity)(
  ospAppId,
);

export const ospAppInfo = createSafeCachedSelector(
  [ospAppSelect.currentOspAppInfo],
  R.identity,
)(ospAppId);

export const isLocked = createSafeCachedSelector([ospAppInfo], isStateLocked)(ospAppId);

export const ospAppSectionIds = createSafeCachedSelector(
  [ospAppSelect.currentOspApp],
  R.path(['definition', 'sections']),
)(ospAppId);

export const ospAppSections = createSafeCachedSelector(
  [ospSectionSelect.ospSectionState, ospAppSectionIds],
  ospSectionReducer.getOspSectionsByIdsList,
)(ospAppId);

// NOTE (orther) these are purposely not qualified
const getAllWorksheetsFromSections = R.chain(R.pathOr([], ['worksheets']));
export const ospAppWorksheetIds = createSafeCachedSelector(
  [ospAppSections],
  getAllWorksheetsFromSections,
)(ospAppId);

export const isLoadingWorksheetAnswers = createSafeCachedSelector(
  [wsAnswersSelect.isLoadingForCurrentOspApp],
  R.identity,
)(ospAppId);

export const worksheetAnswers = createSafeCachedSelector(
  [wsAnswersSelect.worksheetAnswersState, ospAppId],
  (state, ospAppId) => {
    return wsAnswersReducer.getWorksheetAnswersForOspApp(state, ospAppId);
  },
)(ospAppId);

export const worksheetAnswersMap = createSafeCachedSelector(
  [worksheetAnswers],
  R.indexBy(R.prop('id')),
)(ospAppId);

export const worksheetAnswersForMiniOsp = createSafeCachedSelector(
  [wsAnswersSelect.worksheetAnswersState, ospAppId],
  (state, ospAppId) => {
    return wsAnswersReducer.getWorksheetAnswersForOspAppMiniOsp(state, ospAppId);
  },
)(ospAppId);

export const worksheetAnswersMapForMiniOsp = createSafeCachedSelector(
  [worksheetAnswersForMiniOsp],
  R.indexBy(R.prop('id')),
)(ospAppId);

export const qualificationAnswers = createSafeCachedSelector(
  [ospAppSelect.currentOspApp],
  R.pathOr([], ['qualification_answers', 'values']),
)(ospAppId);

export const qualificationQuestionRootIds = createSafeCachedSelector(
  [ospAppSelect.currentOspApp],
  R.pathOr([], ['definition', 'qualifications', 'questions']),
)(ospAppId);

export const qualificationQuestionIds = createSafeCachedSelector(
  [ospQQReducer.getListByOspAppIdState, ospAppId],
  ospQQReducer.getIdsForOspAppId,
)(ospAppId);

export const qualificationQuestions = createSafeCachedSelector(
  [ospQQReducer.getByIdState, qualificationQuestionIds],
  (qqState, ids) => {
    if (!qqState) return qqState;
    if (!ids) return ids;
    return R.map(ospQQReducer.getQuestion(qqState), ids);
  },
)(ospAppId);

export const qualificationQuestionMap = createSafeCachedSelector(
  [qualificationQuestions],
  R.indexBy(R.prop('uuid')),
)(ospAppId);

export const qualificationQuestionsActiveRootIds = createSafeCachedSelector(
  [qualificationQuestionRootIds, qualificationAnswers],
  R.intersection,
)(ospAppId);

export const qualificationQuestionsActiveRootSubIds = createSafeCachedSelector(
  [qualificationQuestionMap, qualificationQuestionsActiveRootIds],
  (questionMap, ids) => {
    return R.chain(id => R.path([id, 'sub_questions'], questionMap), ids);
  },
)(ospAppId);

export const qualificationQuestionsActiveSubIds = createSafeCachedSelector(
  [qualificationQuestionsActiveRootSubIds, qualificationAnswers],
  R.intersection,
)(ospAppId);

export const qualificationQuestionsActiveIds = createSafeCachedSelector(
  [qualificationQuestionsActiveRootIds, qualificationQuestionsActiveSubIds],
  R.union,
)(ospAppId);

export const qualificationQuestionsActiveWorksheetIds = createSafeCachedSelector(
  [qualificationQuestionMap, qualificationQuestionsActiveIds],
  (questionMap, ids) => {
    const worksheetIds = R.chain(id => R.path([id, 'worksheets'], questionMap), ids);
    return R.uniq(worksheetIds);
  },
)(ospAppId);

export const qualifiedWorksheetIds = createSafeCachedSelector(
  [qualificationQuestionsActiveWorksheetIds],
  R.identity,
)(ospAppId);

export const qualifiedWorksheetIdsOrdered = createSafeCachedSelector(
  [ospAppWorksheetIds, qualifiedWorksheetIds],
  (allWorksheetIds, qualifiedWorksheetIds) => {
    if (!allWorksheetIds || !qualifiedWorksheetIds) return allWorksheetIds;
    return R.filter(R.contains(R.__, qualifiedWorksheetIds), allWorksheetIds);
  },
)(ospAppId);

export const qualifiedWorksheetAnswers = createSafeCachedSelector(
  [worksheetAnswers, qualifiedWorksheetIds],
  (wsAnswers, worksheetIds) => {
    if (!wsAnswers) return wsAnswers;
    if (!worksheetIds) return wsAnswers;

    return wsAnswers.filter(wsAnswersSelect.filterWorksheetId(worksheetIds));
  },
)(ospAppId);

export const qualifiedWorksheetAnswersMap = createSafeCachedSelector(
  [qualifiedWorksheetAnswers],
  wsAnswers => {
    if (!wsAnswers) return wsAnswers;

    return (
      wsAnswers &&
      wsAnswers.asObject(answer => [wsAnswersReducer.getWorksheetId(answer), answer])
    );
  },
)(ospAppId);

export const qualifiedSectionsWithWorksheetIds = createSafeCachedSelector(
  [ospAppSections, qualifiedWorksheetIds],
  (sections, worksheetIds) => {
    if (!sections) return sections;
    if (!worksheetIds) return sections;

    const isQualified = id => R.contains(id, worksheetIds);
    return R.chain(section => {
      if (!R.path(['worksheets'], section)) return [];
      const qualifiedWorksheets = section.worksheets.filter(isQualified);
      if (!qualifiedWorksheets.length) return [];
      return section.merge({ worksheets: qualifiedWorksheets });
    })(sections);
  },
)(ospAppId);

const getWorksheetWithQuestions = R.curry((wsState, wsQuestionState, id) => {
  if (!id) return null;
  const worksheet = wsReducer.getWorksheet(wsState, id);
  const questionIds = R.path(['questions'], worksheet);
  const wsQuestions = wsQuestionReducer.getQuestions(wsQuestionState, questionIds);
  // console.log('wsQuestions',wsQuestions)
  // console.log({wsState, questionIds,id})
  return worksheet.merge({
    questions: wsQuestions,
  });
});

// TODO (orther) I copied these from ospDef. They are duplicate of some existing
//               selectors in this file. They are written much cleaner and we should
//               circle back and make all selectors use the same code conventions.
export const ospSections = createSafeCachedSelector(
  [ospSectionSelect.ospSectionsForCurrentOspApp],
  R.identity,
)(ospAppId);

export const worksheetIds = createSafeCachedSelector(
  [ospSections],
  R.compose(
    R.chain(R.prop('worksheets')),
    R.values,
  ),
)(ospAppId);

export const worksheets = createSafeCachedSelector(
  [wsSelect.worksheetState, worksheetIds],
  (state = {}, worksheetIds) => {
    // console.log({state, worksheetIds});
    return wsReducer.getWorksheetsByIds(state, worksheetIds);
  },
)(ospAppId);

export const worksheetQuestions = createSafeCachedSelector(
  [wsQuestionReducer.wsQuestionState, worksheets],
  (wsQuestionState, worksheets) => {
    const questions = R.compose(
      R.indexBy(R.prop('uuid')),
      R.chain(
        R.compose(
          wsQuestionReducer.getQuestions(wsQuestionState),
          R.path(['questions']),
        ),
      ),
      R.values,
    )(worksheets);
    return questions;
  },
)(ospAppId);

export const worksheetQuestionIds = createSafeCachedSelector(
  [worksheetQuestions],
  R.keys,
)(ospAppId);

// TODO (orther) consider breaking this apart so we don't have to build data up
//               inside of each other. Instead we passed the filtered data side
//               by side. We would do this with everything but for this specific
//               example we would pass ospApp, ospSections, worksheets,
//               worksheetAnswers, and worksheetQuestions instead of it
//               denormalized in the ospApp.
export const qualifiedSections = createSafeCachedSelector(
  [
    wsReducer.getWorksheetState,
    wsQuestionReducer.wsQuestionState,
    qualifiedSectionsWithWorksheetIds,
  ],
  (wsState, wsQuestionState, sections) => {
    if (!wsState) return wsState;
    if (!wsQuestionState) return wsQuestionState;
    if (!sections) return sections;
    const mapGetWorksheet = getWorksheetWithQuestions(wsState, wsQuestionState);
    const qualSections = R.map(
      R.evolve({
        worksheets: R.map(mapGetWorksheet),
      }),
      sections,
    );

    return sections.map(section =>
      section.merge({
        worksheets: section.worksheets.map(mapGetWorksheet),
      }),
    );
  },
)(ospAppId);

export const numQualifiedWorksheets = createSafeCachedSelector(
  [qualifiedWorksheetIds, worksheetIds],
  (qualWorksheetIds, ospWorksheetIds) => {
    if (qualWorksheetIds && ospWorksheetIds) {
      return R.intersection(qualWorksheetIds, ospWorksheetIds).length;
      // return qualWorksheetIds && qualWorksheetIds.length
    }

    return undefined;
  },
)(ospAppId);

const getNumCompleteQualifiedWorksheets = R.compose(
  R.length,
  R.keys,
  R.filter(wsAnswersReducer.isComplete),
);
export const numCompleteQualifiedWorksheets = createSafeCachedSelector(
  [qualifiedWorksheetAnswersMap],
  getNumCompleteQualifiedWorksheets,
)(ospAppId);

export const numIncompleteQualifiedWorksheets = createSafeCachedSelector(
  [numQualifiedWorksheets, numCompleteQualifiedWorksheets],
  (numTotal, numComplete) => {
    if (!numTotal) return numTotal;
    if (!numComplete) return numTotal;

    return numTotal - numComplete;
  },
)(ospAppId);

export const progressReport = createSafeCachedSelector(
  [
    numQualifiedWorksheets,
    numCompleteQualifiedWorksheets,
    numIncompleteQualifiedWorksheets,
  ],
  (numTotal, numComplete, numIncomplete) => {
    // console.log('progressReport', [numTotal, numComplete, numIncomplete]);
    let percentComplete = ~~Math.round((numComplete / numTotal) * 1000) / 10;
    if (percentComplete > 100) percentComplete = 100;

    return {
      numComplete,
      numIncomplete,
      numTotal,
      percentComplete,
    };
  },
)(ospAppId);

export const hasSections = createSafeCachedSelector(
  [qualifiedSections],
  R.compose(
    R.not,
    R.isEmpty,
  ),
)(ospAppId);

export const hasQualificationQuestionIds = createSafeCachedSelector(
  [qualificationQuestionIds],
  R.compose(
    R.not,
    RS.isNilOrEmpty,
  ),
)(ospAppId);

export const needsActivityChecklist = createSafeCachedSelector(
  // [hasSections, hasQualificationQuestionIds],
  [hasSections],
  R.not,
)(ospAppId);
