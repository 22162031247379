import React, { Fragment } from 'react';

import Block from 'sow/components/atoms/Block';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Button from 'sow/components/atoms/Button';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import InspectionManagerBlockContainer from 'sow/containers/InspectionManagerBlockContainer';

const InspectionManager = ({
  partialInspectionList,
  toggleCreateNewModal,
  annualInspection,
  pendingSampleInspections,
  ...props
}) => (
  <Widget className="inspection-manager">
    <WidgetHeading>
      Inspections
      <Block pullRight>
        <Button
          type="button"
          className="no-margin-button"
          onClick={toggleCreateNewModal}
          primary
          sm
        >
          Add New Partial
        </Button>
      </Block>
    </WidgetHeading>
    <WidgetBody>
      <Row>
        <Column className="inspection-block" xs={12} md={6} lg={4}>
          <InspectionManagerBlockContainer
            inspection={annualInspection}
            annual
            {...props}
          />
        </Column>
        {pendingSampleInspections &&
          pendingSampleInspections.length > 0 &&
          pendingSampleInspections.map(inspection => (
            <Column
              key={inspection.id}
              className="inspection-block"
              xs={12}
              md={6}
              lg={4}
            >
              <InspectionManagerBlockContainer
                {...props}
                inspection={inspection}
                annual
              />
            </Column>
          ))}
        {partialInspectionList &&
          partialInspectionList.length > 0 &&
          partialInspectionList.map(partialInspection => (
            <Column
              key={partialInspection.id}
              className="inspection-block"
              xs={12}
              md={6}
              lg={4}
            >
              <InspectionManagerBlockContainer
                {...props}
                inspection={partialInspection}
              />
            </Column>
          ))}
      </Row>
    </WidgetBody>
  </Widget>
);

export default InspectionManager;
