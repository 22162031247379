import * as R from 'ramda';
import actions from 'sow/actions/pure';
import { registerAsync } from 'sow/services/orgRegistration';

function mark(action) {
  return `soworganic/orgRegistration/${action}`;
}

export const UPDATE_FIELD = mark('UPDATE_FIELD');

export const updateField = (field, value) => dispatch => {
  dispatch({
    type: UPDATE_FIELD,
    data: { field, value },
  });
};

const registerErrorMessage = 'There was an error processing your registration.';

export const REGISTER = mark('REGISTER');
export const REGISTER_SUCCESS = mark('REGISTER_SUCCESS');
export const REGISTER_FAILED = mark('REGISTER_FAILED');

export const register = (org, success, onFailure) => dispatch => {
  dispatch({ type: REGISTER });

  registerAsync(org)
    .done(res => {
      dispatch({
        type: REGISTER_SUCCESS,
        data: res.data,
      });

      if (success) success(res.data);
    })
    .fail(err => {
      dispatch({
        type: REGISTER_FAILED,
        data: err.responseJSON,
      });

      dispatch(
        actions.shell.toast(
          'danger',
          registerErrorMessage,
          R.path(['responseJSON', 'error', 'errors'], err),
        ),
      );

      if (onFailure) onFailure(err);
    });
};
