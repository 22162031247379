import React from 'react';
import { call, take, put, race, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import actions from 'sow/actions/pure';
import ToastMessage from 'sow/components/organisms/ToastMessage';

export function* confirmSaga({ title, message, noButtonText, yesButtonText }) {
  return !!window.confirm(message);
}

const toastType = color => {
  switch (color) {
    case 'danger':
      return toast.TYPE.ERROR;
    case 'warning':
      return toast.TYPE.WARN;
    case 'info':
      return toast.TYPE.INFO;
    case 'success':
      return toast.TYPE.SUCCESS;
    default:
      return toast.TYPE.DEFAULT;
  }
};

export function* toastTask({ type, message, validationErrors, options }) {
  yield call(
    toast,
    <ToastMessage message={message} validationErrors={validationErrors} />,
    {
      type: toastType(type),
      position: toast.POSITION.TOP_CENTER,
      ...options,
    },
  );
}

export function* watchToastTask({ payload }) {
  yield call(toastTask, payload);
}

export default function* rootSaga() {
  yield takeEvery(actions.shell.toast, watchToastTask);
}
