import * as R from 'ramda';
import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import { api } from 'sow/utils/apiNew';

export function* updateUser({ payload, meta }) {
  const { userId, user } = payload;
  const { onSuccess } = meta;

  try {
    const { data } = yield call(api.put, `/user/${userId}`, { user });
    yield put(actions.profile.updateUserDone(data));
    yield put(
      actions.shell.toast(
        'success',
        'Your profile has been updated successfully. You may need to log in again for all changes to take effect.',
      ),
    );

    if (onSuccess) {
      yield call(onSuccess, data);
    }
  } catch (err) {
    const message = 'There was a problem updating your profile.';
    const validationErrors = R.path(['responseJSON', 'error', 'errors'], err) || null;

    yield put(actions.profile.updateUserFail(validationErrors));
    yield put(actions.shell.toast('danger', message, validationErrors));
  }
}

export function* rootSaga() {
  yield takeEvery(actions.profile.updateUser, updateUser);
}

export default rootSaga;
