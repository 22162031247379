import * as R from 'ramda';
import { createSafeCachedSelector } from 'sow/utils/selectors';

import {
  getCurrentOspAppId,
  getIsLoadedForOrg,
  getIsLoadingForOrg,
  getIsLoadingForOspApp,
  getOspApp,
  getOspAppListForOrg,
  getOspAppListMapForOrg,
} from 'sow/reducers/ospApplications';

// NOTE: In core selectors we only import other core selectors that have a parent
//       relationship to the core selector your importing to. This is to stop
//       circular dependencies. For example ospApplication can import
//       organization core selectors but not worksheets/worksheetAnswers core
//       selectors. Instead we would import ospApplication core selectors into
//       worksheet/worksheetAnswers core selectors and compose selectors there.
//       For higher level selectors that compose multiple core selectors you
//       should create them in files directly inside soworganic/selectors/
// import { currentOrg } from 'sow/selectors/core/organization';
import * as orgSelect from 'sow/selectors/core/organization';

export const ospAppIdProp = (state, props) => R.path(['ospAppId'], props);

export const ospAppIdRouteParam = (state, props) =>
  R.path(['match', 'params', 'ospAppId'], props);

export const currentOspAppIdFromProps = createSafeCachedSelector(
  [ospAppIdRouteParam, ospAppIdProp],
  R.defaultTo,
)(ospAppIdProp);

export const ospAppState = state => state.ospApplications;

export const isLoadedForCurrentOrg = createSafeCachedSelector(
  [ospAppState, orgSelect.currentOrgId],
  getIsLoadedForOrg,
)(orgSelect.currentOrgId);

export const isLoadingForCurrentOrg = createSafeCachedSelector(
  [ospAppState, orgSelect.currentOrgId],
  getIsLoadingForOrg,
)(orgSelect.currentOrgId);

export const ospAppListMapForCurrentOrg = createSafeCachedSelector(
  [ospAppState, orgSelect.currentOrgId],
  getOspAppListMapForOrg,
)(orgSelect.currentOrgId);

export const currentOspAppIdForCurrentOrg = createSafeCachedSelector(
  [ospAppState, orgSelect.currentOrgId],
  (ospAppState, orgId) => {
    if (!orgId) return orgId;
    const ospAppId = getCurrentOspAppId(ospAppState, orgId);
    return ospAppId && ~~ospAppId;
  },
)(orgSelect.currentOrgId);

export const currentOspAppIdRaw = createSafeCachedSelector(
  [currentOspAppIdForCurrentOrg, currentOspAppIdFromProps],
  R.defaultTo,
)(ospAppIdRouteParam);

export const currentOspAppId = createSafeCachedSelector([currentOspAppIdRaw], ospAppId =>
  ospAppId ? ~~ospAppId : null,
)(currentOspAppIdRaw);

export const currentOspApp = createSafeCachedSelector(
  [ospAppState, currentOspAppId],
  getOspApp,
)(currentOspAppId);

export const currentOspAppIsLoading = createSafeCachedSelector(
  [ospAppState, currentOspAppId],
  // (ospAppState, currentOspAppId) => {
  //   console.log({ ospAppState, currentOspAppId });
  //   return getIsLoadingForOspApp(ospAppState, currentOspAppId);
  // },
  getIsLoadingForOspApp,
)(currentOspAppId);

export const currentOspAppIsLoaded = createSafeCachedSelector([currentOspApp], ospApp => {
  return !!ospApp;
})(currentOspAppId);

export const ospAppListForCurrentOrg = createSafeCachedSelector(
  [ospAppState, orgSelect.currentOrgId],
  getOspAppListForOrg,
)(orgSelect.currentOrgId);

export const currentOspAppInfo = createSafeCachedSelector(
  [currentOspAppId, ospAppListMapForCurrentOrg],
  (ospAppId, ospAppListMap) => R.path([ospAppId], ospAppListMap),
)(currentOspAppId);

export const currentOspAppSectionIds = createSafeCachedSelector(
  [currentOspApp],
  R.pathOr([], ['definition', 'sections']),
)(currentOspAppId);
