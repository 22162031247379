export const inspectionStates = {
  readyForInspection: 1,
  inInspection: 2,
  readyForFinalReview: 3,
  inFinalReview: 4,
  complete: 5,
};

export const inspectionStatesRank = {
  readyForInspection: 0,
  inInspection: 1,
  readyForFinalReview: 2,
  inFinalReview: 3,
  complete: 4,
};

export const inspectionTypes = {
  annual: 1,
  partial: 2,
};

export const inspectionSubTypes = {
  announced: 1,
  unannounced: 2,
};
