import actions from 'sow/actions/pure';

const defaultState = {
  currentAcaId: null,
  currentOrgId: null,
  adminXrayMode: false,
};

const layout = (state = defaultState, action) => {
  switch (action.type) {
    case String(actions.layout.toggleAdminEditMode):
      return { ...state, adminXrayMode: !state.adminXrayMode };

    default:
      return state;
  }
};

export default layout;
