import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as announcementActions from 'sow/actions/pure/announcement';
import { apiGet, apiPost } from 'sow/sagas/api';

export const getAnnouncements = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/announcement', payload);
    if (response.data) {
      if (meta.onSuccess) meta.onSuccess(response);
      else yield put(announcementActions.loadAnnouncementsDone(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAnnouncementById = function*({ id, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/announcement/${id}`);
    if (response.data) {
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addAnnouncement = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/announcement', payload);

    if (response.data) {
      yield put(actions.shell.toast('success', 'Announcement added.'));
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateAnnouncement = function*({ id, payload, meta }) {
  try {
    const { response } = yield call(apiPost, `/admin/announcement/${id}`, payload);

    if (response && response.data) {
      yield put(actions.shell.toast('success', 'Announcement updated.'));
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteAnnouncement = function*({ id, meta }) {
  try {
    const { response } = yield call(apiPost, `/admin/announcement/delete/${id}`);
    if (response) {
      yield put(actions.shell.toast('success', 'Announcement deleted.'));
      if (meta.onSuccess) meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export const loadShowForOptions = function*({ meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/announcement_type/show_for_options`);
    if (response.data) {
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAnnouncementHistory = function*({ id, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/announcement/history/${id}`);
    if (response.data) {
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(announcementActions.LOAD_ANNOUNCEMENTS, getAnnouncements);
  yield takeEvery(announcementActions.LOAD_ANNOUNCEMENT_BY_ID, getAnnouncementById);
  yield takeEvery(announcementActions.ADD_ANNOUNCEMENT, addAnnouncement);
  yield takeEvery(announcementActions.UPDATE_ANNOUNCEMENT, updateAnnouncement);
  yield takeEvery(announcementActions.DELETE_ANNOUNCEMENT, deleteAnnouncement);
  yield takeEvery(announcementActions.LOAD_SHOW_FOR_OPTIONS, loadShowForOptions);
  yield takeEvery(announcementActions.GET_ANNOUNCEMENT_HISTORY, getAnnouncementHistory);
}
