import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';

import * as sowTypes from 'sow/types';
import Block from 'sow/components/atoms/Block';

const AddressWrapper = styled(Block)`
  color: #666666;
  font-size: 12pt;
  font-weight: lighter;
`;

const FormattedAddress = ({ loc, ...props }) => {
  // Only one of `location` or `geom` properties are required for a land
  if (R.isNil(loc)) {
    return null;
  }

  const addrProp = field => R.prop(field, loc);

  const addressText = addrProp('addressText');
  const address = addrProp('address');
  const street = addrProp('address');
  const city = addrProp('city');
  const state = addrProp('state');
  const postal = addrProp('postal');
  const country = addrProp('country');

  // Some older lands may have their full address as a single string in address_text.
  // If `address` property is null, fall back to rendering address_text instead.
  if (R.isNil(address)) {
    return (
      <AddressWrapper {...props}>
        <Block>{addressText}</Block>
        <Block>{country}</Block>
      </AddressWrapper>
    );
  }

  return (
    <AddressWrapper {...props}>
      <Block>{street}</Block>
      {city}
      {city && state && ', '}
      {state}
      {state && postal && ' '}
      {postal}
      <Block>{country}</Block>
    </AddressWrapper>
  );
};

FormattedAddress.propTypes = {
  loc: sowTypes.locationType.isRequired,
};

export default FormattedAddress;
