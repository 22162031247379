import * as R from 'ramda';
import { combineReducers } from 'redux';
import { defaultToView, mergeView, mergeWithView } from 'sow/utils/data';
import byId, * as fromById from './byId';
import listByOspAppId, * as fromListByOspAppId from './listByOspAppId';
import changes from './changes';

export default combineReducers({
  byId, // new convention for storing results in id=>key map
  changes,
  listByOspAppId,
});

export const getIsNotApplicable = R.path(['isNotApplicable']);
export const getReadyForReview = R.path(['readyForReview']);
export const getWorksheetId = R.path(['worksheet_uuid']);
export const getMatrixRows = R.path(['answers', 'matrixRows']);
export const getValues = R.path(['answers', 'values']);
export const isComplete = R.anyPass([getIsNotApplicable, getReadyForReview]);

export const getWorksheetAnswers = (state, id) =>
  fromById.getWorksheetAnswers(state.byId, id);

export const getWorksheetAnswersIdsForOspApp = (state, ospAppId) =>
  fromListByOspAppId.getIds(state.listByOspAppId, ospAppId);

export const getWorksheetAnswersForOspApp = (state, ospAppId) =>
  getWorksheetAnswersIdsForOspApp(state, ospAppId).map(id =>
    getWorksheetAnswers(state, id),
  );

// HACKY solution to not wipe out main osp app answer ids
export const getWorksheetAnswersIdsForOspAppMiniOsp = (state, ospAppId) =>
  fromListByOspAppId.getIdsForMiniOsp(state.listByOspAppId, ospAppId);

export const getWorksheetAnswersForOspAppMiniOsp = (state, ospAppId) =>
  getWorksheetAnswersIdsForOspAppMiniOsp(state, ospAppId).map(id =>
    getWorksheetAnswers(state, id),
  );

export const getIsLoadingForOspApp = (state, ospAppId) =>
  fromListByOspAppId.getIsLoading(state.listByOspAppId, ospAppId);

export const getErrorMessageForOspApp = (state, ospAppId) =>
  fromListByOspAppId.getErrorMessage(state.listByOspAppId, ospAppId);

const rejectDeletedMatrixRows = R.reject(R.prop('isDeleted'));
const omitMatrixRowsMetaData = R.map(R.omit(['isDeleted']));

const mergeIsNotApplicable = defaultToView(getIsNotApplicable);

const mergeReadyForReview = defaultToView(getReadyForReview);

const mergeMatrixValues = R.mergeWithKey((k, l, r) => {
  if (k === 'values') return R.merge(l, r);
  return R.defaultTo(r, l);
});

const mergeMatrixRows = R.compose(
  omitMatrixRowsMetaData,
  rejectDeletedMatrixRows,
  mergeWithView(mergeMatrixValues, getMatrixRows),
);

const mergeValues = mergeView(getValues);

export const mergeWorksheetAnswers = (base, changes) => ({
  isNotApplicable: mergeIsNotApplicable(base, changes),
  readyForReview: mergeReadyForReview(base, changes),
  answers: {
    matrixRows: mergeMatrixRows(base, changes),
    values: mergeValues(base, changes),
  },
});
