import React from 'react';

import Tooltip from 'sow/components/molecules/Tooltip';

const RequiredIndicator = () =>
  <Tooltip overlay="This field is required">
    <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>
  </Tooltip>;

export default RequiredIndicator;
