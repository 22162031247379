import * as R from 'ramda';
import { combineReducers } from 'redux';
import byId, * as fromById from './byId';
import listByAcaId, * as fromListByAcaId from './listByAcaId';

export default combineReducers({
  byId,
  listByAcaId,
});

const getByIdState = R.path(['byId']);
const getListByAcaIdState = R.path(['listByAcaId']);

export const getOspDef = (state, id) => fromById.getOspDef(getByIdState(state), id);

export const getOspDefIdsForAca = (state, acaId) =>
  fromListByAcaId.getIds(getListByAcaIdState(state), acaId);

export const getOspDefsForAca = (state, acaId) =>
  getOspDefIdsForAca(state, acaId).map(id => getOspDef(state, id));

export const getIsLoadingForAca = (state, acaId) =>
  fromListByAcaId.getIsLoading(getListByAcaIdState(state), acaId);

export const getIsLoadingOneForAca = (state, acaId) =>
  fromListByAcaId.getIsLoadingOne(getListByAcaIdState(state), acaId);

export const getErrorMessageForAca = (state, acaId) =>
  fromListByAcaId.getErrorMessage(state.listByAcaId, acaId);

// Lenses
export const ospSectionsLens = R.lensPath(['definition', 'sections']);
export const miniOspLens = R.lensPath(['definition', 'mini_osp']);
export const qualQuestionsLens = R.lensPath([
  'definition',
  'qualifications',
  'questions',
]);
export const locationTypesLens = R.lensPath(['definition', 'location_types']);

export const landFacilityLens = R.compose(miniOspLens, R.lensPath(['land', 'facility']));
export const landOfficeLens = R.compose(miniOspLens, R.lensPath(['land', 'office']));
export const landParcelLens = R.compose(miniOspLens, R.lensPath(['land', 'parcel']));
export const landPastureLens = R.compose(miniOspLens, R.lensPath(['land', 'pasture']));
export const productLens = R.compose(miniOspLens, R.lensPath(['product']));

export const landFacilitySectionLens = R.compose(
  landFacilityLens,
  R.lensPath(['section']),
);
export const landOfficeSectionLens = R.compose(landOfficeLens, R.lensPath(['section']));
export const landParcelSectionLens = R.compose(landParcelLens, R.lensPath(['section']));
export const landPastureSectionLens = R.compose(landPastureLens, R.lensPath(['section']));
export const productSectionLens = R.compose(productLens, R.lensPath(['section']));

// Helpers
export const isPublished = R.prop('published');
export const whenUpdated = R.prop('updated_at');

export const sortForCurrentPublishedOspDef = R.sortWith([
  R.descend(R.prop('updated_at')),
  R.descend(isPublished),
]);

export const currentPublishedOspDefId = R.compose(
  R.path(['id']),
  R.find(isPublished),
  sortForCurrentPublishedOspDef,
);
