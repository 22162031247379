import * as R from 'ramda';
import { v4 as guid } from 'uuid';

import { moveListItemAway, moveListItemCloser } from 'sow/utils/data';
import {
  locationTypesLens,
  ospSectionsLens,
  qualQuestionsLens,
} from 'sow/reducers/ospDefinitions';

import { INIT_STATE } from './OspDefEditor';

// Actions
export const LAND_FACILITY_WORKSHEET_ADD =
  'OspDefEditor/ospDef/LAND_FACILITY_WORKSHEET_ADD';
export const LAND_OFFICE_WORKSHEET_ADD = 'OspDefEditor/ospDef/LAND_OFFICE_WORKSHEET_ADD';
export const LAND_PARCEL_WORKSHEET_ADD = 'OspDefEditor/ospDef/LAND_PARCEL_WORKSHEET_ADD';
export const LAND_PASTURE_WORKSHEET_ADD =
  'OspDefEditor/ospDef/LAND_PASTURE_WORKSHEET_ADD';
export const PRODUCT_WORKSHEET_ADD = 'OspDefEditor/ospDef/PRODUCT_WORKSHEET_ADD';

export const LOCATION_TYPE_ADD = 'OspDefEditor/ospDef/LOCATION_TYPE_ADD';

export const OSP_SECTION_ADD = 'OspDefEditor/ospDef/OSP_SECTION_ADD';
export const OSP_SECTION_MOVE_DOWN = 'OspDefEditor/ospDef/OSP_SECTION_MOVE_DOWN';
export const OSP_SECTION_MOVE_UP = 'OspDefEditor/ospDef/OSP_SECTION_MOVE_UP';
export const OSP_SECTION_REMOVE = 'OspDefEditor/ospDef/OSP_SECTION_REMOVE';

export const SET_VALUE = 'OspDefEditor/ospDef/SET_VALUE';

export const QUAL_QUESTION_REF_ADD = 'OspDefEditor/ospDef/QUAL_QUESTION_REF_ADD';
export const QUAL_QUESTION_REF_REMOVE = 'OspDefEditor/ospDef/QUAL_QUESTION_REF_REMOVE';
export const QUAL_QUESTION_WS_ADD = 'OspDefEditor/ospDef/QUAL_QUESTION_WS_ADD';
export const QUAL_QUESTION_WS_REMOVE = 'OspDefEditor/ospDef/QUAL_QUESTION_WS_REMOVE';
export const QUAL_QUESTION_ADD = 'OspDefEditor/ospDef/QUAL_QUESTION_ADD';
export const QUAL_QUESTION_REMOVE = 'OspDefEditor/ospDef/QUAL_QUESTION_REMOVE';
export const QUAL_QUESTION_MOVE_DOWN = 'OspDefEditor/ospDef/QUAL_QUESTION_MOVE_DOWN';
export const QUAL_QUESTION_MOVE_UP = 'OspDefEditor/ospDef/QUAL_QUESTION_MOVE_UP';

// entity defauts/creators
export const getSectionDefaults = () => ({
  uuid: guid(),
  name: 'New Section',
  worksheets: [],
});

export function createSection(sectionValues = {}) {
  return R.merge(getSectionDefaults(), sectionValues);
}

export function createLocationType(locationTypeValues = { name: 'New Location Type' }) {
  return R.merge(getSectionDefaults(), locationTypeValues);
}

export const getMiniOspDefaults = () => ({
  land: {
    facility: {
      section: createSection({ name: 'Land - Facility' }),
    },
    office: {
      section: createSection({ name: 'Land - Office' }),
    },
    pasture: {
      section: createSection({ name: 'Land - Pasture' }),
    },
    parcel: {
      section: createSection({ name: 'Land - Parcel' }),
    },
  },
  product: {
    section: createSection({ name: 'Product' }),
  },
});

// Reducer functions
export const reduceLocationTypeAdd = (state = {}, action = {}) => {
  const locationTypeId = action.data.locationType.uuid;
  return R.over(locationTypesLens, R.append(locationTypeId), state);
};

export const reduceOspSectionAdd = (state = {}, action = {}) => {
  const ospSectionId = action.data.ospSection.uuid;
  return R.over(ospSectionsLens, R.prepend(ospSectionId), state);
};

export const reduceOspSectionRemove = (state = {}, action = {}) => {
  const { ospSectionId } = action.data;
  return R.over(ospSectionsLens, R.without([ospSectionId]), state);
};

export const reduceOspSectionMoveDown = (state = {}, action = {}) => {
  const { rootId } = action.data;
  const lens = ospSectionsLens;
  const all = R.view(lens, state);
  const count = R.length(all);
  const idx = R.findIndex(R.equals(rootId), all);

  // already last item so do nothing
  if (idx >= count - 1) return state;

  return R.over(lens, moveListItemAway(idx), state);
};

export const reduceOspSectionMoveUp = (state = {}, action = {}) => {
  const { rootId } = action.data;
  const lens = ospSectionsLens;
  const all = R.view(lens, state);
  const idx = R.findIndex(R.equals(rootId), all);

  // already first item so do nothing
  if (idx < 1) return state;

  return R.over(lens, moveListItemCloser(idx), state);
};

export const reduceOspQualificationQuestionAdd = (state = {}, action = {}) => {
  const ospQualificationQuestionId = action.data.ospQualificationQuestion.uuid;
  return R.over(qualQuestionsLens, R.prepend(ospQualificationQuestionId), state);
};

export const reduceOspQualificationQuestionRemove = (state = {}, action = {}) => {
  const { ospQualificationQuestionId } = action.data;
  return R.over(qualQuestionsLens, R.without([ospQualificationQuestionId]), state);
};

export const reduceSetValue = (state = {}, action = {}) => {
  const { field, value } = action.data;
  return R.assocPath([field], value, state);
};

export const reduceQualQuestionMoveDown = (state = {}, action = {}) => {
  const { rootId } = action.data;
  const lens = qualQuestionsLens;
  const all = R.view(lens, state);
  const count = R.length(all);
  const idx = R.findIndex(R.equals(rootId), all);

  // already last item so do nothing
  if (idx >= count - 1) return state;

  return R.over(lens, moveListItemAway(idx), state);
};

export const reduceQualQuestionMoveUp = (state = {}, action = {}) => {
  const { rootId } = action.data;
  const lens = qualQuestionsLens;
  const all = R.view(lens, state);
  const idx = R.findIndex(R.equals(rootId), all);

  // already first item so do nothing
  if (idx < 1) return state;

  return R.over(lens, moveListItemCloser(idx), state);
};

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case INIT_STATE:
      // const ospDef = R.path(['payload', 'ospDef'], action);
      // return ospDef;
      return R.path(['payload', 'ospDef'], action);

    case QUAL_QUESTION_ADD:
      return reduceOspQualificationQuestionAdd(state, action);

    case QUAL_QUESTION_REMOVE:
      return reduceOspQualificationQuestionRemove(state, action);

    case QUAL_QUESTION_MOVE_DOWN:
      return reduceQualQuestionMoveDown(state, action);

    case QUAL_QUESTION_MOVE_UP:
      return reduceQualQuestionMoveUp(state, action);

    case LOCATION_TYPE_ADD:
      return reduceLocationTypeAdd(state, action);

    case OSP_SECTION_ADD:
      return reduceOspSectionAdd(state, action);

    case OSP_SECTION_REMOVE:
      return reduceOspSectionRemove(state, action);

    case OSP_SECTION_MOVE_DOWN:
      return reduceOspSectionMoveDown(state, action);

    case OSP_SECTION_MOVE_UP:
      return reduceOspSectionMoveUp(state, action);

    case SET_VALUE:
      return reduceSetValue(state, action);

    default:
      return state;
  }
}

export function ospQualificationQuestionAdd(ospQualificationQuestion) {
  return { type: QUAL_QUESTION_ADD, data: { ospQualificationQuestion } };
}

export function ospQualificationQuestionRemove(ospQualificationQuestionId) {
  return { type: QUAL_QUESTION_REMOVE, data: { ospQualificationQuestionId } };
}

export function ospQualificationQuestionMoveDown(rootId) {
  return { type: QUAL_QUESTION_MOVE_DOWN, data: { rootId } };
}

export function ospQualificationQuestionMoveUp(rootId) {
  return { type: QUAL_QUESTION_MOVE_UP, data: { rootId } };
}

export function locationTypeAdd(locationType) {
  return { type: LOCATION_TYPE_ADD, data: { locationType } };
}

export function sectionAdd(ospSection) {
  return { type: OSP_SECTION_ADD, data: { ospSection } };
}

export function sectionRemove(ospSectionId) {
  return { type: OSP_SECTION_REMOVE, data: { ospSectionId } };
}

export function sectionMoveDown(rootId) {
  return { type: OSP_SECTION_MOVE_DOWN, data: { rootId } };
}

export function sectionMoveUp(rootId) {
  return { type: OSP_SECTION_MOVE_UP, data: { rootId } };
}

export function setValue(field, value) {
  return { type: SET_VALUE, data: { field, value } };
}

// Action Creator Export Map
export const actions = {
  ospQualificationQuestionAdd,
  ospQualificationQuestionRemove,
  ospQualificationQuestionMoveDown,
  ospQualificationQuestionMoveUp,

  locationTypeAdd,
  sectionAdd,
  sectionRemove,
  sectionMoveDown,
  sectionMoveUp,
  setValue,
};
