import React, { useEffect } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as sowTypes from 'sow/types';
import * as adminRequestActions from 'sow/actions/adminRequest';
import { fromPlanApp } from 'sow/store/selectors';
import ResourceLoaderStatusRender from 'sow/components/molecules/ResourceLoaderStatusRender';
import OrgPlanAppWorksheetPage from 'sow/components/pages/OrgPlanAppWorksheetPage';
import {
  PlanAppChangeLoader,
  PlanAppLoader,
  PlanAppWorksheetAnswersLoader,
  PlanAppWorksheetNotesLoader,
} from 'sow/store/containers';
import { worksheetOverviewRoute } from 'sow/routes';

import OrgPlanAppPageTemplate from 'sow/containers/OrgPlanAppPageTemplate';
import Spinner from 'sow/components/atoms/Spinner';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  worksheetId: fromPlanApp.worksheetId(state, props),
  worksheet: fromPlanApp.worksheet(state, props),
  isStateUnderReview: fromPlanApp.isStateUnderReview(state, props),
  isStateInitialApplication: fromPlanApp.isStateInitialApplication(state, props),
  adminRequests: state.adminRequests,
  requestsFetching: state.adminRequests.worksheetRequests.isFetching,
  requestsLoaded: state.adminRequests.worksheetRequests.isLoaded,
});

const mapDispatchToProps = {
  fetchAdminRequests: adminRequestActions.fetchAdminRequests,
};

// TODO help the shiftng right with react-composer
const OrgPlanAppWorksheetPageContainer = ({
  orgId,
  planAppId,
  changeRequestId,
  worksheetId,
  worksheet,
  fetchAdminRequests,
  requestsLoaded,
  requestsFetching,
  adminRequests,
  isStateUnderReview,
  isStateInitialApplication,
}) => {

  const loadAdminRequests = () => {
    if ((!requestsLoaded && !requestsFetching) || (requestsLoaded && adminRequests.planAppId != planAppId)) {
      return true;
    } else {
      return false;
    }
  }
  
  const loadRequests = loadAdminRequests();

  return (
    <OrgPlanAppPageTemplate
      title={R.pathOr('', ['name'], worksheet)}
      breadcrumb={[['Worksheets', worksheetOverviewRoute(orgId, planAppId)]]}
    >
      <PlanAppLoader autoLoad>
        {({ status: planAppStatus }) => (
          <PlanAppWorksheetAnswersLoader autoLoad>
            {({ status: answersStatus }) => {
              if (planAppStatus.loading || answersStatus.loading) return <Spinner />;
              if (!planAppStatus.success && !answersStatus.done) return null;
              return (
                <PlanAppWorksheetNotesLoader autoLoad>
                  {({ status: notesStatus }) => (
                    <PlanAppChangeLoader
                      requestParams={{ worksheet_ids: [worksheetId] }}
                      autoLoad={!!changeRequestId}
                    >
                      {({ status: changesStatus }) => {
                        const isChangesLoaded = changeRequestId && !changesStatus.initial;
                        if (isChangesLoaded && loadRequests) {
                          fetchAdminRequests(orgId, planAppId);
                        }
                        return (
                          <ResourceLoaderStatusRender
                            statusObjects={[
                              isChangesLoaded // if changes not auto loading, status default to done
                                ? changesStatus
                                : { ...changesStatus, initial: false, done: true },
                              notesStatus,
                            ]}
                            renderAllDone={() => (
                              <OrgPlanAppWorksheetPage
                                orgId={orgId}
                                planAppId={planAppId}
                                worksheet={worksheet}
                                isStateUnderReview={isStateUnderReview}
                                isStateInitialApplication={isStateInitialApplication}
                              />
                            )}
                          />
                        );
                      }}
                    </PlanAppChangeLoader>
                  )}
                </PlanAppWorksheetNotesLoader>
              );
            }}
          </PlanAppWorksheetAnswersLoader>
        )}
      </PlanAppLoader>
    </OrgPlanAppPageTemplate>
  );
};

OrgPlanAppWorksheetPageContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  changeRequestId: sowTypes.planAppChangeRequestIdType,
  worksheetId: sowTypes.planAppWorksheetIdType.isRequired,
  worksheet: sowTypes.planAppWorksheetType,
};

OrgPlanAppWorksheetPageContainer.defaultProps = {
  worksheet: null,
  fetchAdminRequests: null
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrgPlanAppWorksheetPageContainer);
