import React, { Fragment } from 'react';

import Tooltip from 'sow/components/molecules/Tooltip';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const InformationIcon = ({ overlay, className }) => (
  // Waiting on text info
  // <Tooltip overlay={overlay}>
  // <FontAwesome className={className} icon="info-circle" />
  <Fragment></Fragment>
  // </Tooltip>
);

export default InformationIcon;
