import React, { Component } from 'react';
import { Glyphicon, MenuItem, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import FormGroup from 'sow/components/organisms/FormGroup';
import { patchOspDefForAca } from 'sow/actions/ospDefinition';

const mapDispatchToProps = { patchOspDefForAca };

class SetOspDefNameModal extends Component {
  state = {
    show: false,
    ospDefName: '',
  };

  handleHide = () => {
    this.setState({
      show: false,
      ospDefName: '',
    });
  };

  handleShow = event => {
    const { ospDefName } = this.props;
    this.setState({
      show: true,
      ospDefName,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { patchOspDefForAca, acaId, ospDefId } = this.props;
    const { ospDefName } = this.state;
    const ospDefData = { name: ospDefName };
    patchOspDefForAca(acaId, ospDefId, ospDefData, this.handleHide);
  };

  render() {
    const { show, ospDefName } = this.state;
    const errors = {};

    return (
      <React.Fragment>
        <MenuItem id="setOspDefNameButton" onClick={this.handleShow}>
          <Glyphicon glyph="pencil" /> Rename
        </MenuItem>
        <Modal backdrop="static" show={show} onHide={this.handleHide}>
          <form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Rename Plan Definition</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <FormGroup fieldName="name" errors={errors} label="Name" required>
                <input
                  type="text"
                  id="name"
                  value={ospDefName}
                  onChange={e => this.setState({ ospDefName: e.target.value })}
                  className="form-control"
                  required
                />
              </FormGroup>
            </Modal.Body>

            <Modal.Footer>
              <button type="submit" className="btn btn-primary">
                Done
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(null, mapDispatchToProps)(SetOspDefNameModal);
