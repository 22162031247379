const mark = action => `CONSULTANT_COMPANY/${action}`;

export const LOAD_CONSULTANT_COMPANIES = mark('LOAD_CONSULTANT_COMPANIES');
export const LOAD_CONSULTANT_COMPANY_BY_ID = mark('LOAD_CONSULTANT_COMPANY_BY_ID');
export const ADD_CONSULTANT_COMPANY = mark('ADD_CONSULTANT_COMPANY');
export const DELETE_CONSULTANT_COMPANY = mark('DELETE_CONSULTANT_COMPANY');
export const UPDATE_CONSULTANT_COMPANY = mark('UPDATE_CONSULTANT_COMPANY');

export const loadConsultantCompanies = (payload, onSuccess) => ({
  type: LOAD_CONSULTANT_COMPANIES,
  payload,
  meta: { onSuccess },
});

export const loadConsultantCompanyById = (id, onSuccess) => ({
  type: LOAD_CONSULTANT_COMPANY_BY_ID,
  id,
  meta: { onSuccess },
});

export const addConsultantCompany = (payload, onSuccess) => ({
  type: ADD_CONSULTANT_COMPANY,
  payload,
  meta: { onSuccess },
});

export const updateConsultantCompany = (id, payload, onSuccess) => ({
  type: UPDATE_CONSULTANT_COMPANY,
  id,
  payload,
  meta: { onSuccess },
});

export const deleteConsultantCompany = (id, onSuccess) => ({
  type: DELETE_CONSULTANT_COMPANY,
  id,
  meta: { onSuccess },
});
