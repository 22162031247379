import * as R from 'ramda';
import { makeImmutable } from 'sow/utils/data';

const mapReducer = (state = {}, action) => ({
  users: action.payload.users.result,
});

const byOrgId = (state = makeImmutable({}), action) => {
  if (R.path(['payload', 'users'], action)) {
    const id = action.payload.users.orgId;
    return R.assoc(id, mapReducer(state[id], action), state);
  }

  return state;
};

export default byOrgId;

export const getIds = state => Object.keys(state);
export const getOrg = (state, id) => state[id];
export const getOrgList = state => getIds(state).map(id => state[id]);
