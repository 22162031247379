import React, { Fragment } from 'react';
import * as R from 'ramda';
import moment from 'moment-timezone';

import { humanize, titleize } from 'sow/utils/string';

import Spinner from 'sow/components/atoms/Spinner';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import ImprovedDataTable from 'sow/components/molecules/ImprovedDataTable';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';

const UserManagementUserHistory = ({ userHistory }) => (
  <Fragment>
    {!userHistory ? (
      <Spinner />
    ) : (
      <Widget>
        <WidgetHeading>User History</WidgetHeading>
        <WidgetBody>
          {userHistory.length > 0 ? (
            <ImprovedDataTable
              selector=".userHistoryTable"
              tableDef={{
                pageLength: 25,
                destroy: true,
                data: userHistory,
                deferRender: true,
                order: [],
                columns: [
                  { data: 'name' },
                  { data: 'field' },
                  { data: 'notes' },
                  { data: 'ip_address' },
                  { data: 'created_on' },
                  { data: 'created_by' },
                ],
                stateSave: true,
                columnDefs: [
                  { targets: 0, sortable: false },
                  { targets: 1, render: field => R.pipe(humanize, titleize)(field) },
                  {
                    targets: 4,
                    render: timestamp =>
                      moment
                        .utc(timestamp)
                        .tz(moment.tz.guess())
                        .format('L LT'),
                  },
                ],
              }}
            >
              <Table
                className="userHistoryTable"
                bordered
                striped
                condensed
                head={
                  <TableRow>
                    <TableCell heading textCenter>
                      User
                    </TableCell>
                    <TableCell heading textCenter>
                      Field
                    </TableCell>
                    <TableCell heading textCenter>
                      Notes
                    </TableCell>
                    <TableCell heading textCenter>
                      IP Address
                    </TableCell>
                    <TableCell heading textCenter>
                      Timestamp
                    </TableCell>
                    <TableCell heading textCenter>
                      Edited By
                    </TableCell>
                  </TableRow>
                }
              >
                <TableRow />
              </Table>
            </ImprovedDataTable>
          ) : (
            <Table
              className="userHistoryTable"
              bordered
              striped
              condensed
              head={
                <TableRow>
                  <TableCell heading textCenter>
                    User
                  </TableCell>
                  <TableCell heading textCenter>
                    Field
                  </TableCell>
                  <TableCell heading textCenter>
                    Notes
                  </TableCell>
                  <TableCell heading textCenter>
                    IP Address
                  </TableCell>
                  <TableCell heading textCenter>
                    Timestamp
                  </TableCell>
                  <TableCell heading textCenter>
                    Edited By
                  </TableCell>
                </TableRow>
              }
            >
              <TableRow>
                <TableCell colSpan={6} textCenter>
                  No user history.
                </TableCell>
              </TableRow>
            </Table>
          )}
        </WidgetBody>
      </Widget>
    )}
  </Fragment>
);

export default UserManagementUserHistory;
