import * as Yup from 'yup';

import { API_URL } from 'sow/constants/api';

export const defaultOrg = () => ({
  name: '',
  contactName: 'N/A',
  contactPhone: 'N/A',
  location: defaultLocation(),
  acaId: '',
  // legalName: '',
  // generalDescription: '',
  // nonOrganicProductionDescription: '',
  // currentlyCertified: '',
  // everDeniedCertification: '',
  // everSuspendedRevokedCertification: '',
  // prevSuspendedRevokedCert: '',
  // isExempt: '',
  // doesExport: '',
  // exportsToA: '',
  // usersA: '',
  // organizationTypeOther: '',
  // organizationType: '',
  // organizationTypeLabel: '',
  // certificationsMax: '',
  // phone: '',
  // url: '',
  // yearStarted: '',
  // employees: '',
  // contact2Name: '',
  // contact2Phone: '',
  // overseer: '',
  // overseerName: '',
  // legalLocation: defaultLocation(),
  // billingLocation: defaultLocation(),
  // acaName: '',
});

export const defaultLocation = () => ({
  name: '',
  addressText: '',
  address: '',
  city: '',
  state: '',
  postal: '',
  country: '',
  data: '',
});

export const downloadExportUrl = url => `${API_URL}/v1/file/by_path/${url}`;

export const exportFileTypes = {
  main_osp: 'Main Plan',
  lands: 'Locations',
  products: 'Products',
  include_notes: 'Include Notes',
  include_files: 'Include Files',
};

export const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  // contactName: Yup.string().required('Contact name is required'),
  // contactPhone: Yup.string().required('Contact phone is required'),
  location: Yup.object({
    // name: yup.string(),
    // addressText: yup.string(),
    address: Yup.string().required('Street address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    postal: Yup.string().required('Postal code is required'),
    country: Yup.string().required('Country is required'),
    // data: ???,
  }).required(),
  acaId: Yup.number().required('You must select a certifier from the list'),
});
