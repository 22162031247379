import React from 'react';

import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Block from 'sow/components/atoms/Block';
import Heading from 'sow/components/atoms/Heading';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';

const SearchResult = ({ org }) => (
  <Block>
    <Heading level={3}>Results</Heading>
    <Widget key={org.id} style={{ marginBottom: '10px' }}>
      <WidgetHeading>
        {org.name} ({org.id})
      </WidgetHeading>
      <WidgetBody>
        <Table
          head={
            <TableRow>
              <TableCell heading colSpan={2}>
                Users
              </TableCell>
            </TableRow>
          }
        >
          {org.users.map(user => (
            <TableRow key={user.id}>
              <TableCell md={6}>{user.name}</TableCell>
              <TableCell md={6}>{user.email}</TableCell>
            </TableRow>
          ))}
        </Table>
      </WidgetBody>
    </Widget>
  </Block>
);

export default SearchResult;
