import React from 'react';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import PropTypes from 'prop-types';

import { worksheetQuestionType } from 'sow/types';

const parseValueToState = value => {
  if (typeof value !== 'object') return '';

  try {
    return value.format();
  } catch (e) {
    console.error(
      'Error converting DatePicker onChange return value to string for worksheet answer',
      e,
    );
  }
};

const parseStateToValue = state => {
  if (!state) return '';

  try {
    return moment(state).format('L');
  } catch (e) {
    console.error(
      'Error converting worksheet answer to pass in as `value` prop of DatePicker',
      e,
    );
  }
};

const DatePicker = ({ question, answer = '', handleChange, disabled }) => (
  <ReactDatetime
    value={parseStateToValue(answer)}
    onChange={value => handleChange(question.uuid, parseValueToState(value))}
    dateFormat={true}
    timeFormat={false}
    closeOnSelect={true}
    inputProps={{
      disabled,
      placeholder: 'Input date',
    }}
  />
);

DatePicker.defaultProps = {
  answer: '',
  disabled: false,
};

DatePicker.propTypes = {
  question: worksheetQuestionType.isRequired,
  answer: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default DatePicker;
