import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormikField from 'sow/components/organisms/FormikField';
import CountrySelect from 'sow/containers/CountrySelect';

const LocationForm = ({ prefix, disabled }) => {
  return (
    <>
      <Field
        component={FormikField}
        name={`${prefix}address`}
        label="Street Address"
        disabled={disabled}
        required
      />

      <Row>
        <Column xs={6}>
          <Field
            component={FormikField}
            name={`${prefix}city`}
            label="City"
            disabled={disabled}
            required
          />
        </Column>
        <Column xs={4}>
          <Field
            component={FormikField}
            name={`${prefix}state`}
            label="State"
            disabled={disabled}
            required
          />
        </Column>
        <Column xs={2}>
          <Field
            component={FormikField}
            name={`${prefix}postal`}
            label="Postal Code"
            disabled={disabled}
            required
          />
        </Column>
      </Row>

      <Field
        component={FormikField}
        type={CountrySelect}
        name={`${prefix}country`}
        label="Country"
        disabled={disabled}
        required
      />
    </>
  );
};

LocationForm.propTypes = {
  prefix: PropTypes.string,
  disabled: PropTypes.bool,
};

LocationForm.defaultProps = {
  prefix: '',
  disabled: false,
};

export default LocationForm;
