import React, { Component } from 'react';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { acaIdType } from 'sow/types';
import { API_URL } from 'sow/constants/api';
import { hasAnyRole } from 'sow/utils/auth';
import * as currentUser from 'sow/selectors/currentUser';
import * as currentAca from 'sow/selectors/currentAca';
import { loadAllForAca, deleteDoc } from 'sow/actions/pure/certificationDocs';
import { confirm } from 'sow/actions/pure/messaging';
import Spinner from 'sow/components/atoms/Spinner';
import AcaPageTemplate from 'sow/containers/AcaPageTemplate';
import CertificationDocsPage from 'sow/components/pages/CertificationDocsPage';

const mapStateToProps = (state, props) => ({
  acaId: currentAca.acaId(state, props),
  isLoading: state.certificationDocs.isLoading,
  docs: state.certificationDocs.items,
  canEdit: hasAnyRole(currentUser.roles(state), ['admin', 'aca_owner']),
});

const mapDispatchToProps = {
  confirm,
  deleteDoc,
  loadAllForAca,
};

class AcaCertificationDocsPageContainer extends Component {
  componentDidMount() {
    this.props.loadAllForAca(this.props.acaId);
  }

  handleDelete = docId => () => {
    this.props.confirm({
      message: 'Are you sure you would like to delete this document?',
      onConfirm: () => {
        this.props.deleteDoc(this.props.acaId, docId);
      },
    });
  };

  render() {
    const { isLoading, docs, canEdit } = this.props;
    const acaId = this.props.acaId;

    return (
      <AcaPageTemplate title="Certification Docs">
        {isLoading && <Spinner />}
        {!isLoading && (
          <CertificationDocsPage
            acaId={acaId}
            docs={docs}
            canEdit={canEdit}
            uploadUrl={`${API_URL}/v1/aca/${acaId}/certification_doc`}
            onDelete={this.handleDelete}
          />
        )}
      </AcaPageTemplate>
    );
  }
}

AcaCertificationDocsPageContainer.propTypes = {
  acaId: acaIdType.isRequired,
  docs: PropTypes.array,
  isLoading: PropTypes.bool,
  canEdit: PropTypes.bool,
  confirm: PropTypes.func.isRequired,
  deleteDoc: PropTypes.func.isRequired,
  loadAllForAca: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AcaCertificationDocsPageContainer);
