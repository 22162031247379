import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import ConsultantCompaniesListContainer from 'sow/containers/ConsultantCompaniesListContainer';

const ConsultantCompaniesListPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <ConsultantCompaniesListContainer />}
  </AdminPageTemplate>
);

export default ConsultantCompaniesListPage;
