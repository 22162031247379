import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import actions from 'sow/actions/pure';
import { fromPlanApp } from 'sow/store/selectors';
import { resourceUpdate } from 'sow/store/helpers';
import PlanAppLocationEditModal from 'sow/components/organisms/PlanAppLocationEditModal';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  locationId: fromPlanApp.locationId(state, props),
  locationDetails: fromPlanApp.locationDetails(state, props),
  isStateInitialApplication: fromPlanApp.isStateInitialApplication(state, props),
});

const mapResource = () => {
  const updateLocationResource = (orgId, planAppId) =>
    resourceUpdate(`org/${orgId}/application/${planAppId}/land`, 'planAppLocation');

  const mapResourceDispatchToProps = (dispatch, ownProps) => {
    const { orgId, planAppId, locationId } = ownProps;
    const { action } = updateLocationResource(orgId, planAppId, locationId);

    return {
      onSubmit: location =>
        dispatch(action(locationId, { land: location }))
          .then(() =>
            dispatch(actions.shell.toast('success', 'Location updated successfully.')),
          )
          .catch(() =>
            dispatch(
              actions.shell.toast('danger', 'There was a problem updating the location.'),
            ),
          ),
    };
  };

  return connect(
    null,
    mapResourceDispatchToProps,
  );
};

const LocationEditModalContainer = props => <PlanAppLocationEditModal {...props} />;

LocationEditModalContainer.propTypes = {
  children: PropTypes.func.isRequired,
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType,
  locationId: sowTypes.planAppLocationIdType.isRequired,
  locationDetails: sowTypes.planAppLocationType.isRequired,
};

LocationEditModalContainer.defaultProps = {
  planAppId: null, // This component is used on legacy locations, not attached to a plan
  isStateInitialApplication: false,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
  mapResource(),
)(LocationEditModalContainer);
