import * as R from 'ramda';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

export const propSelector = propName => (state, props = {}) => props[propName];

/**
 * Returns coerced int value if source is number or string otherwise, returns
 * original source value.
 */
export const coerceToInt = source => {
  if (R.is(Number, source) || R.is(String, source)) {
    return ~~source;
  }
  return source;
};

/**
 * Returns coerced int value if source is number or string otherwise, returns
 * the defaultValue (defaults to 0)
 */
export const coerceToIntOr = (defaultValue = 0) => source => {
  const int = coerceToInt(source);

  if (!R.is(Number, int)) {
    // was not coerced to int :(
    return defaultValue;
  }

  return int;
};

export const safeResolver = resolver => (...args) => {
  const cacheKey = resolver(...args);

  switch (typeof cacheKey) {
    case undefined:
      return '-void-';

    case 'number':
    case 'boolean':
      return `${cacheKey}`;

    case 'string':
      return cacheKey;

    case null:
      return '-null-';

    default:
      return '-safe-';
  }
};

export const createSafeCachedSelector = (...funcs) => resolver => {
  if (typeof resolver === 'function') {
    return createCachedSelector(...funcs)(safeResolver(resolver));
  }

  // return not-cached selector if no resolver passed in
  return createSelector(...funcs);
};

export const defaultToSelector = R.curry((defaultValue, selector) => {
  return R.compose(
    R.defaultTo(defaultValue),
    selector,
  );
});

// Selector Factories

/**
 * Creates a selector that returns a prop value.
 * @param {string} prop - The key used to look up value in props object.
 * @returns {selector}
 */
export const selectPropFactory = prop => {
  return (state, props) => R.path([prop], props);
};

/**
 * Creates a selector for pulling a specific item from an object by id.
 * @param {selector} objSelector - The selector used to get object.
 * @param {selector} idSelector - The selector used to get id.
 * @returns {selector}
 */
export const itemByIdFactory = (objSelector, idSelector) => {
  return createSelector(
    [objSelector, idSelector],
    (obj, id) => {
      return obj[id];
    },
  );
};

/**
 * Creates a selector for pulling a list of specific items from an object by list of ids.
 * @param {selector} objSelector - The selector used to get object.
 * @param {selector} idListSelector - The selector used to get list of ids.
 * @returns {selector}
 */
export const itemListByIdListFactory = (objSelector, idListSelector) => {
  return createSelector(
    [objSelector, idListSelector],
    (obj, ids) => {
      return R.map(R.prop(R.__, obj), ids);
    },
  );
};

/**
 * Creates a selector for pulling a specific item from an object by id prop.
 * @param {selector} objSelector - The selector used to get object.
 * @param {string} idProp - The prop holding id.
 * @returns {selector}
 */
export const itemByIdPropFactory = R.curry((objSelector, idProp) => {
  return itemByIdFactory(objSelector, selectPropFactory(idProp));
});

/**
 * Creates a selector for pulling a list of specific items from an object by prop holding a list of ids.
 * @param {selector} objSelector - The selector used to get object.
 * @param {string} idListProp - The prop holding id.
 * @returns {selector}
 */
export const itemListByIdListPropFactory = R.curry((objSelector, idListProp) => {
  return itemListByIdListFactory(objSelector, selectPropFactory(idListProp));
});
