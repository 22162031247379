import * as R from 'ramda';

export const getOptions = wsQuestion => {
  return R.propOr([], 'answers', wsQuestion);
};

export const getVersion = answer => {
  const version = R.path(['__version'], answer);
  if (!version) return 0;
  if (version === '1') return 1;
  if (version === 2) return 2;
};

// --
// -- Answer Formatting Functions
// --

const buildAnswerV1 = R.assoc('__version', '1');

const buildAnswerV2 = R.pipe(
  R.assoc('__version', 2),
  R.assoc('__fieldType', 'checkbox_list'),
);

export const getAnswerV1 = options => answer => {
  const version = getVersion(answer);
  const noAnswerExists = !answer;

  if (noAnswerExists) {
    return buildAnswerV1({});
  }

  if (version === 0) {
    const invalidAnswer = !Array.isArray(answer);
    if (invalidAnswer) {
      return buildAnswerV1({});
    }

    const noAnswerMap = R.into({}, R.map(R.pair(R.__, false)), options);
    const answerMap = R.into(noAnswerMap, R.map(R.pair(R.__, true)), answer);

    return buildAnswerV1(answerMap);
  }

  return buildAnswerV1(answer);
};

export const getAnswerV2 = options => answer => {
  const version = getVersion(answer);
  const noAnswerExists = !answer;

  if (noAnswerExists) {
    return buildAnswerV2({});
  }

  if (version === 1) {
    // const checked = R.filter(val => !!val, answer);
    const checked = R.pipe(
      R.filter(val => val),
      R.keys,
      R.filter(R.contains(R.__, options)),
    )(answer);
    const unchecked = R.pipe(
      R.filter(val => !val),
      R.keys,
      R.filter(R.contains(R.__, options)),
    )(answer);

    return buildAnswerV2({ checked, unchecked });
  }

  return buildAnswerV2(answer);
};

export const getAnswer = (answer, options) => {
  const version = getVersion(answer);

  if (version === 0) {
    return R.pipe(
      getAnswerV1(options),
      getAnswerV2(options),
    )(answer);
  }

  return getAnswerV2(options)(answer);
};

export const getCheckedItems = answer => {
  return R.propOr([], 'checked', answer);
};

export const getUncheckedItems = answer => {
  return R.propOr([], 'unchecked', answer);
};

export const getOptionValue = (answer, option) => {
  const checkedItems = getCheckedItems(answer);
  const uncheckedItems = getUncheckedItems(answer);

  const isChecked = R.contains(option, checkedItems);
  const isUnchecked = R.contains(option, uncheckedItems);

  if (isChecked) return true;
  if (isUnchecked) return false;
  return null;
};

export const setOptionValue = (answer, option, value) => {
  const checkedItems = getCheckedItems(answer);
  const uncheckedItems = getUncheckedItems(answer);

  if (value === true) {
    return {
      ...answer,
      checked: R.uniq([...checkedItems, option]),
      unchecked: R.without([option], [...uncheckedItems]),
    };
  }

  if (value === false) {
    return {
      ...answer,
      checked: R.without([option], [...checkedItems]),
      unchecked: R.uniq([...uncheckedItems, option]),
    };
  }

  return answer;
};
