import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { humanize, titleize } from 'sow/utils/string';

import { planAppStateText } from 'sow/constants/planApp';
import { orgRoute } from 'sow/routes';

import PaginatedTable from 'sow/components/molecules/PaginatedTable';
import Atom from 'sow/components/atoms/Atom';
import Table from 'sow/components/molecules/Table';
import Timestamp from 'sow/components/molecules/Timestamp';
import Link from 'sow/components/atoms/Link';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import ActivityLogChangesOverlayButton from 'sow/components/organisms/ActivityLogChangesOverlayButton';
import ActivityLogLink from './ActivityLogLink';

const ActionText = ({ log }) => {
  const capitalizeAction = R.pipe(humanize, titleize);
  const newState = R.path(['data', 'changes', 'state', 'new_value'], log);

  if (newState) {
    return (
      <Atom>New state: {planAppStateText[newState] || capitalizeAction(newState)}</Atom>
    );
  } else {
    return <Atom>{capitalizeAction(log.action)}</Atom>;
  }
};

ActionText.propTypes = {
  log: PropTypes.object.isRequired,
};

const NoLogs = () => (
  <TableRow>
    <TableCell colSpan={6} textCenter>
      No entries found
    </TableCell>
  </TableRow>
);

const LogRow = ({ log, ospData }) => {
  const isDeleted = log.action.substr(-7) === 'deleted';
  const isProduct = log.action.substr(0, 7) === 'product';
  const isLand = log.action.substr(0, 4) === 'land';
  const isInspection = log.log_type.substr(0, 10) === 'inspection';
  const isSample = log.log_type.substr(0, 6) === 'sample';
  const hasChangeId = !R.isNil(log.change);
  const isOrgAssociation =
    R.propEq('log_type', 'organization')(log) && !R.propEq('action', 'created')(log);

  const showChangesOverlayButton =
    isLand ||
    isProduct ||
    hasChangeId ||
    isDeleted ||
    isOrgAssociation ||
    isInspection ||
    isSample;

  return (
    <TableRow>
      <TableCell valignMiddle>
        <Atom textNowrap>
          <Timestamp date={log.inserted_at} format="L LT" />
        </Atom>
      </TableCell>
      <TableCell valignMiddle>
        <Link to={orgRoute(log.organization.id)}>{log.organization.name}</Link>
      </TableCell>
      <TableCell valignMiddle>{log.user.name}</TableCell>
      <TableCell valignMiddle>
        {isDeleted ? log.log_type : <ActivityLogLink log={log} />}
      </TableCell>
      <TableCell valignMiddle>
        <ActionText log={log} />
      </TableCell>
      <TableCell valignMiddle textCenter>
        {showChangesOverlayButton && !isDeleted && (
          <ActivityLogChangesOverlayButton log={log} ospData={ospData} />
        )}
      </TableCell>
    </TableRow>
  );
};

LogRow.propTypes = {
  log: PropTypes.object,
};

const AcaActivityTable = ({ logs, tableData, selectPage, ospData }) => (
  <PaginatedTable tableData={tableData} selectPage={selectPage}>
    <Table
      bordered
      condensed
      striped
      head={
        <TableRow>
          <TableCell heading>Date</TableCell>
          <TableCell heading>Organization</TableCell>
          <TableCell heading>User</TableCell>
          <TableCell heading>Url</TableCell>
          <TableCell heading>Action</TableCell>
          <TableCell heading>Changes</TableCell>
        </TableRow>
      }
    >
      {R.isEmpty(logs) && <NoLogs />}
      {logs.map(log => (
        <LogRow key={log.id} log={log} ospData={ospData} />
      ))}
    </Table>
  </PaginatedTable>
);

AcaActivityTable.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AcaActivityTable;
