import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import FontAwesome from 'sow/components/atoms/FontAwesome';

const blueTheme = {
  color: {
    bg: '#fff',
    text: '#4E6F95',
    textDisabled: '#abbed3',
    mark: '#2f78b9',
    markHover: '#c1daf1',
    markInactive: '#ebebeb',
  },
};

const greenCheckTheme = {
  color: {
    ...blueTheme.color,
    mark: '#5cb85c',
    markHover: '#9ccebf99',
  },
};

const theme = greenCheckTheme;

const CheckboxButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 4px 12px;
  padding: 16px;
  border-radius: 8px;
  background: ${theme.color.bg};
  user-select: none;
  flex: 1 1;

  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.12), 0 1px 2px hsla(0, 0%, 0%, 0.24);
  transition: all 150ms cubic-bezier(0.02, 0.01, 0.47, 1);

  &::after {
    content: '';
    border-radius: 10px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    box-shadow: 0 3px 6px hsla(0, 0%, 0%, 0.15), 0 2px 4px hsla(0, 0%, 0%, 0.12);
    transition: all 300ms cubic-bezier(0.02, 0.01, 0.47, 1);
  }
  &:hover {
    cursor: ${props => (props.disabled ? null : 'pointer')};
    transform: ${props => (props.disabled ? null : 'scale(1.005, 1.005)')};
  }
  &:hover::after {
    opacity: ${props => (props.disabled ? null : '1')};
  }
  &:active {
    transform: ${props => (props.disabled ? null : 'scale3d(0.9995, 0.9995, 1)')};
    transition: all 20ms cubic-bezier(0.02, 0.01, 0.47, 1);
  }
  &:active::after {
    opacity: ${props => (props.disabled ? null : '0')};
    transition: all 20ms cubic-bezier(0.02, 0.01, 0.47, 1);
  }
`;

const CheckMarkWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.8em;
  padding-right: 10px;
  color: ${props => (props.isChecked ? theme.color.mark : theme.color.markInactive)};

  ${CheckboxButtonWrapper}:hover & {
    ${props =>
      !props.disabled &&
      css`
        color: ${props => (props.isChecked ? theme.color.mark : theme.color.markHover)};
      `};
  }
`;

const NameWrapper = styled.div`
  color: ${props => (props.disabled ? theme.color.textDisabled : theme.color.text)}
  font-size: 1.2em
  line-height: 1em;
`;

const CheckboxButton = ({ label, name, value, setFieldValue, disabled, ...props }) => {
  const isChecked = !!value;
  return (
    <CheckboxButtonWrapper
      isChecked={isChecked}
      disabled={disabled}
      onClick={() => {
        if (!disabled) setFieldValue(name, !value);
      }}
    >
      <input type="checkbox" checked={isChecked} style={{ display: 'none' }} readOnly />
      <CheckMarkWrapper isChecked={isChecked} disabled={disabled}>
        {isChecked ? (
          <FontAwesome
            icon="check-circle"
            className="checkbox-button-circle-check"
            variant="fas"
          />
        ) : (
          <FontAwesome
            icon="check-circle"
            className="checkbox-button-circle-check"
            variant="fas"
          />
        )}
      </CheckMarkWrapper>
      <NameWrapper isChecked={isChecked} disabled={disabled}>
        {label}
      </NameWrapper>
    </CheckboxButtonWrapper>
  );
};

CheckboxButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  small: PropTypes.bool,
};

export default CheckboxButton;
