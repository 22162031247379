import React from 'react';
import * as R from 'ramda';
import { v4 as guid } from 'uuid';
import PropTypes from 'prop-types';

import { API_URL } from 'sow/constants/api';

import Image from 'sow/components/atoms/Image';

const acaLogoUrl = (acaId, cacheBuster) =>
  `${API_URL}/v1/file/by_path/logo/${acaId}?${cacheBuster}`;

const getAcaId = props => props.acaId || R.path(['id'], props.aca);

const AcaLogo = props => {
  const { aca, acaId, hidden, bustCache, ...imageProps } = props;
  const cacheBuster = bustCache ? guid() : '';

  if ((!acaId && !aca) || hidden) return null;

  return (
    <Image
      imageUrl={acaLogoUrl(getAcaId(props), cacheBuster)}
      alt={props.alt || props.title}
      {...imageProps}
    />
  );
};

AcaLogo.propTypes = {
  aca: PropTypes.object,
  acaId: PropTypes.number,
  hidden: PropTypes.bool,
  bustCache: PropTypes.bool,
  alt: PropTypes.string,
  title: PropTypes.string,
};

AcaLogo.defaultProps = {
  bustCache: true,
};

export default AcaLogo;
