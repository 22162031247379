import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { orgRoute, checklistRoute, historicalPlansRoute } from 'sow/routes';
import OrgPageTemplate from 'sow/containers/OrgPageTemplate';
import PlanAppOverview from 'sow/containers/planApp/Overview';
import Spacer from 'sow/components/atoms/Spacer';

const OrgPlanAppPageTemplate = ({
  renderFatalError,
  isPlanAppIdMostRecent,
  planAppId,
  orgId,
  org,
  title,
  breadcrumb,
  children,
}) => {
  const orgName = R.pathOr('', ['name'], org);

  const baseBreadcrumb = [[orgName, orgRoute(orgId)]];
  if (!isPlanAppIdMostRecent) {
    baseBreadcrumb.push(['Historical Plans', historicalPlansRoute(orgId)]);
    baseBreadcrumb.push([`Plan #${planAppId}`, checklistRoute(orgId, planAppId)]);
  }

  return (
    <OrgPageTemplate
      title={title}
      breadcrumb={[...baseBreadcrumb, ...breadcrumb]}
      renderFatalError={renderFatalError}
    >
      <PlanAppOverview />
      <Spacer vertical={20} />
      {children}
    </OrgPageTemplate>
  );
};

OrgPlanAppPageTemplate.propTypes = {
  renderFatalError: PropTypes.func,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  breadcrumb: PropTypes.arrayOf(PropTypes.array),
  org: sowTypes.orgType,
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  isPlanAppIdMostRecent: PropTypes.bool,
};

OrgPlanAppPageTemplate.defaultProps = {
  title: null,
  breadcrumb: [],
  renderFatalError: null,
};

export default OrgPlanAppPageTemplate;
