import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { planAppNoteIdType } from 'sow/types';
import Block from 'sow/components/atoms/Block';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Tooltip from 'sow/components/molecules/Tooltip';

const noop = () => {};

const DeleteButtonWrapper = styled(Block)`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const DeleteNoteButton = ({ noteId, onDelete, isDeleting }) => {
  const disabled = isDeleting;
  return (
    <DeleteButtonWrapper onClick={disabled ? noop : onDelete(noteId)} disabled={disabled}>
      <Tooltip overlay="Delete this note">
        <FontAwesome icon="trash" />
      </Tooltip>
    </DeleteButtonWrapper>
  );
};

DeleteNoteButton.propTypes = {
  noteId: planAppNoteIdType.isRequired,
  onDelete: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
};

export default DeleteNoteButton;
