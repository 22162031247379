import React, { Fragment } from 'react';

import { API_URL } from 'sow/constants/api';
import { certificationTypeRoute } from 'sow/routes';
import Spinner from 'sow/components/atoms/Spinner';
import ImgFileUploadContainer from 'sow/containers/ImgFileUploadContainer';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Confirm from 'sow/components/molecules/Confirm';
import InputFormGroup from 'sow/components/molecules/InputFormGroup';
import Button from 'sow/components/atoms/Button';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import OrderInput from 'sow/components/atoms/OrderInput';
import FormGroup from 'sow/components/organisms/FormGroup';
import Block from 'sow/components/atoms/Block';

const CertificationTypesEdit = ({
  formValues,
  isLoading,
  handleInputChange,
  handleOptionChange,
  handleSave,
  handleDelete,
  deleteImage,
}) => (
  <Fragment>
    {isLoading && !formValues.isNew ? (
      <Spinner />
    ) : (
      <Widget className="system-admin-list-edit">
        <WidgetHeading>{formValues.isNew ? 'Add' : 'Edit'}</WidgetHeading>
        <WidgetBody>
          <Row className="form-row">
            <Column className="form-column" xs={4} sm={3} md={2}>
              <Button className="form-button" onClick={handleSave} primary>
                Save
              </Button>
            </Column>
            <Column className="form-column" xs={4} sm={3} md={2}>
              <Button
                className="form-button reset-return-button"
                to={certificationTypeRoute()}
              >
                Cancel
              </Button>
            </Column>
            <Column sm={3} md={6}></Column>
            <Column className="form-column" xs={4} sm={3} md={2}>
              <Confirm
                message="Are you sure you want to delete this certification type?"
                onConfirm={handleDelete}
              >
                {toggleConfirm => (
                  <Button
                    className="delete-button"
                    onClick={toggleConfirm}
                    disabled={formValues.isNew}
                  >
                    <FontAwesome className="button-icon" icon="trash" />
                    Delete
                  </Button>
                )}
              </Confirm>
            </Column>
          </Row>
          <HorizontalRule className="divider" />
          <Row className="form-row">
            <Column className="form-column" md={12}>
              <InputFormGroup
                id="name"
                className="form-group"
                label="Name"
                inputClassName="input-filter"
                onChange={handleInputChange}
                value={formValues.name}
              />
            </Column>
            <Column className="form-column" md={12}>
              <FormGroup className="form-group" fieldName="imageUpload" label="Image">
                <ImgFileUploadContainer
                  deleteImage={deleteImage}
                  imageUrl={
                    formValues.file_path
                      ? `${API_URL}/v1/file/by_path/${formValues.file_path}`
                      : null
                  }
                />
              </FormGroup>
            </Column>
            <Column className="form-column" md={12}>
              <FormGroup className="form-group" fieldName="sort" label="Sort">
                <OrderInput
                  id="sort_order"
                  className="sort-order-input"
                  value={formValues.sort_order}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Column>
            <Column className="form-column" md={12}>
              <FormGroup className="form-group" fieldName="status" label="Status">
                <Block className="checkbox-block">
                  <FontAwesome
                    className="checkbox-icon"
                    variant={formValues.enabled ? 'fa' : 'far'}
                    icon={formValues.enabled ? 'check-square' : 'square'}
                    onClick={handleOptionChange}
                  />
                  Enabled
                </Block>
              </FormGroup>
            </Column>
          </Row>
        </WidgetBody>
      </Widget>
    )}
  </Fragment>
);

export default CertificationTypesEdit;
