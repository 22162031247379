import React, { Fragment, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import Block from 'sow/components/atoms/Block';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const SectionListItem = ({
  index,
  section,
  reorderList,
  updateOrder,
  showPopover,
  onSectionSelect,
  selectedSectionId,
}) => {
  const ref = useRef(null);

  const [{ isOver }, drop] = useDrop({
    accept: 'item',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex !== hoverIndex) {
        reorderList(dragIndex, hoverIndex);
      }
      item.index = hoverIndex;
    },
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
      };
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'item', index },
    end(item) {
      updateOrder();
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));

  return (
    <div
      ref={ref}
      className={
        isDragging
          ? 'dragging-picklist-item section'
          : section.id === selectedSectionId
          ? 'picklist-item section selected-section'
          : 'picklist-item section'
      }
    >
      {!isDragging && (
        <Fragment>
          <span ref={drag} className="material-icons dnd-icon">
            drag_indicator
          </span>
          <Block onClick={() => onSectionSelect(section.id)} className="section-name">
            {section.name}
          </Block>
          <Block
            onClick={event => showPopover(event, section.id)}
            className="section-ellipsis"
          >
            <FontAwesome icon="ellipsis" />
          </Block>
        </Fragment>
      )}
    </div>
  );
};

export default SectionListItem;
