import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';

import { planAppStateText } from 'sow/constants/planApp';
import { API_URL } from 'sow/constants/api';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import ImprovedDataTable from 'sow/components/molecules/ImprovedDataTable';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Block from 'sow/components/atoms/Block';
import Spinner from 'sow/components/atoms/Spinner';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Image from 'sow/components/atoms/Image';

const AcaOrgSearchTable = ({ searchInitiated, isSearching, searchResults }) => {
  const history = useHistory();

  return (
    <Fragment>
      {searchInitiated && (
        <Widget className="search-results">
          <WidgetHeading>Organizations</WidgetHeading>
          <WidgetBody>
            {isSearching ? (
              <Spinner />
            ) : searchResults.length > 0 ? (
              <ImprovedDataTable
                selector=".orgSearchResultTable"
                tableDef={{
                  pageLength: 25,
                  destroy: true,
                  searching: false,
                  data: searchResults,
                  deferRender: true,
                  columns: [
                    { data: 'enabled' },
                    { data: 'org_data', name: 'Organization' },
                    { data: 'osp_status', name: 'OSP Status' },
                    { data: 'anniversary_date', name: 'Anniversary Date' },
                    { data: 'inspectors', name: 'Inspector Assigned' },
                    { data: 'initial_reviewers', name: 'Initial Reviewer' },
                    { data: 'final_reviewers', name: 'Final Reviewer' },
                    { data: 'status_date', name: 'Status Age' },
                    { data: 'category', name: 'Category' },
                    { data: 'hard_copy', name: 'Hard Copy' },
                    { data: 'osp_tags', name: 'OSP Tags' },
                    { data: 'certifications', name: 'Certifications' },
                  ],
                  stateSave: true,
                  columnDefs: [
                    { orderable: false, targets: [0] },
                    {
                      targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                      className: 'dt-body-center',
                    },
                    {
                      targets: 0,
                      createdCell: (td, cellData, rowData, row, col) => {
                        if (cellData === false) {
                          ReactDOM.render(
                            <FontAwesome icon="circle" className="disabled" />,
                            td,
                          );
                        } else {
                          ReactDOM.render(
                            <FontAwesome icon="circle" className="enabled" />,
                            td,
                          );
                        }
                      },
                    },
                    {
                      targets: 1,
                      render: (data, type, row, meta) => {
                        return `<a class="org-link" org_id=${data[0]}>${data[1]}</a>`;
                      },
                    },
                    {
                      targets: 2,
                      render: (data, type, row, meta) => {
                        if (!data) {
                          return `<p>Not Started</p>`;
                        } else {
                          return `<p>${planAppStateText[data]}</p>`;
                        }
                      },
                    },
                    { targets: 3, type: 'date' },
                    {
                      targets: 7,
                      render: data => {
                        if (data >= 1) {
                          return `<p  class='status-date' value=${data}>${data}</p>`;
                        } else {
                          return '';
                        }
                      },
                    },
                    {
                      targets: 9,
                      render: (data, type, row, meta) => {
                        if (data) {
                          return 'Yes';
                        } else {
                          return 'No';
                        }
                      },
                    },
                    {
                      targets: 10,
                      render: (data, type, row, meta) => {
                        if (data) {
                          return `${data}`;
                        } else {
                          return '';
                        }
                      },
                    },
                    {
                      targets: 11,
                      createdCell: (td, cellData, rowData, row, col) => {
                        if (cellData) {
                          const imageIcons = cellData.map((cert, index) => (
                            <Image
                              key={index}
                              imageUrl={`${API_URL}/v1/file/by_path/${cert.s3_name}`}
                              title={`${cert.name}`}
                            />
                          ));
                          ReactDOM.render(imageIcons, td);
                        }
                      },
                    },
                  ],
                  fnDrawCallback: () => {
                    $('.status-date')
                      .get()
                      .forEach(target => {
                        const targetText = $(target).text();
                        if (targetText > 1) {
                          target.append(' days old');
                        } else if (targetText == 1) {
                          target.append(' day old');
                        }
                      });
                    $('.org-link')
                      .get()
                      .forEach(target => {
                        $(target).attr('href', `/org/${target.getAttribute('org_id')}`);
                        target.addEventListener('click', event => {
                          event.preventDefault();
                          history.push(`/org/${target.getAttribute('org_id')}`);
                        });
                      });
                  },
                  order: [1, 'asc'],
                }}
              >
                <Table
                  className="orgSearchResultTable"
                  bordered
                  striped
                  condensed
                  head={
                    <TableRow>
                      <TableCell heading textCenter></TableCell>
                      <TableCell heading textCenter>
                        Organization
                      </TableCell>
                      <TableCell heading textCenter>
                        OSP Status
                      </TableCell>
                      <TableCell heading textCenter>
                        Anniversary Date
                      </TableCell>
                      <TableCell heading textCenter>
                        Inspector Assigned
                      </TableCell>
                      <TableCell heading textCenter>
                        Initial Reviewer
                      </TableCell>
                      <TableCell heading textCenter>
                        Final Reviewer
                      </TableCell>
                      <TableCell heading textCenter>
                        Status Age
                      </TableCell>
                      <TableCell heading textCenter>
                        Category
                      </TableCell>
                      <TableCell heading textCenter>
                        Hard Copy
                      </TableCell>
                      <TableCell heading textCenter>
                        OSP Tags
                      </TableCell>
                      <TableCell heading textCenter>
                        Certifications
                      </TableCell>
                    </TableRow>
                  }
                >
                  <TableRow></TableRow>
                  <TableRow></TableRow>
                </Table>
              </ImprovedDataTable>
            ) : (
              <Block className="no-results">No organizations found.</Block>
            )}
          </WidgetBody>
        </Widget>
      )}
    </Fragment>
  );
};

export default AcaOrgSearchTable;
