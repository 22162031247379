import React, { Fragment } from 'react';
import moment from 'moment-timezone';

import Spinner from 'sow/components/atoms/Spinner';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import ImprovedDataTable from 'sow/components/molecules/ImprovedDataTable';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';

const OrgSettingsHistory = ({ isLoading, organizationHistory }) => (
  <Fragment>
    {isLoading ? (
      <Spinner />
    ) : (
      <Widget>
        <WidgetHeading>Organization History</WidgetHeading>
        <WidgetBody>
          <ImprovedDataTable
            selector=".orgHistoryTable"
            tableDef={{
              pageLength: 25,
              destroy: true,
              data: organizationHistory,
              deferRender: true,
              order: [],
              columns: [
                { data: 'user' },
                { data: 'notes' },
                { data: 'ip_address' },
                { data: 'created_on' },
              ],
              stateSave: true,
              columnDefs: [
                {
                  targets: 3,
                  render: timestamp =>
                    moment
                      .utc(timestamp)
                      .tz(moment.tz.guess())
                      .format('L LT'),
                },
              ],
            }}
          >
            <Table
              className="orgHistoryTable"
              bordered
              striped
              condensed
              head={
                <TableRow>
                  <TableCell heading textCenter>
                    User
                  </TableCell>
                  <TableCell heading textCenter>
                    Notes
                  </TableCell>
                  <TableCell heading textCenter>
                    IP Address
                  </TableCell>
                  <TableCell heading textCenter>
                    Timestamp
                  </TableCell>
                </TableRow>
              }
            >
              <TableRow />
            </Table>
          </ImprovedDataTable>
        </WidgetBody>
      </Widget>
    )}
  </Fragment>
);

export default OrgSettingsHistory;
