import { apiRequest } from 'sow/utils/api';
import { API_URL } from 'sow/constants/api';

export const fetchAttachmentListAsync = orgId =>
  apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/org/${orgId}/attachment`,
  });

export const fetchAttachmentOspListAsync = (orgId, ospAppId) =>
  apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/org/${orgId}/application/${ospAppId}/files`,
  });

export const updateAttachmentAsync = (orgId, attachment) =>
  apiRequest({
    method: 'PUT',
    url: `${API_URL}/v1/org/${orgId}/attachment/${attachment.id}`,
    data: {
      // id: attachment.id,
      category: attachment.category,
      name: attachment.name,
      public: !!attachment.file.public,
    },
  });

export const deleteAttachmentAsync = (orgId, attachmentId) =>
  apiRequest({
    method: 'DELETE',
    url: `${API_URL}/v1/org/${orgId}/attachment/${attachmentId}`,
  });
