import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SystemAdminTemplate from 'sow/components/templates/SystemAdminTemplate';

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = {};

class SystemAdminTemplateContainer extends Component {
  render() {
    return <SystemAdminTemplate {...this.props} />;
  }
}

SystemAdminTemplateContainer.propTypes = {
  title: PropTypes.string,
};

SystemAdminTemplateContainer.defaultProps = {
  title: 'System Administrator',
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SystemAdminTemplateContainer);
