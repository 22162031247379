import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as currentUser from 'sow/selectors/currentUser';

import UserButton from 'sow/components/organisms/UserButton';

const mapStateToProps = (state, props) => ({
  name: currentUser.name(state, props),
  email: currentUser.email(state, props),
});

const UserButtonContainer = ({ name, email }) => <UserButton name={name} email={email} />;

UserButtonContainer.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
};

export default connect(mapStateToProps)(UserButtonContainer);
