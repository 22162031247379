import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as actionGroupActions from 'sow/actions/pure/actionGroup';
import { apiGet, apiPost } from 'sow/sagas/api';

export const getActionGroups = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/action_group', payload);
    if (response.data) {
      if (meta.onSuccess) meta.onSuccess(response);
      else yield put(actionGroupActions.loadActionGroupsDone(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getActionGroupById = function*({ id, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/action_group/${id}`);
    if (response.data) {
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addActionGroup = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/action_group', payload);

    if (response.data) {
      yield put(actions.shell.toast('success', 'Action group added.'));
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateActionGroup = function*({ id, payload, meta }) {
  try {
    const { response } = yield call(apiPost, `/admin/action_group/${id}`, payload);

    if (response && response.data) {
      yield put(actions.shell.toast('success', 'Action group updated.'));
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteActionGroup = function*({ id, meta }) {
  try {
    const { response } = yield call(apiPost, `/admin/action_group/delete/${id}`);
    if (response.data) {
      yield put(actions.shell.toast('success', 'Action group deleted.'));
      if (meta.onSuccess) meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(actionGroupActions.LOAD_ACTION_GROUPS, getActionGroups);
  yield takeEvery(actionGroupActions.LOAD_ACTION_GROUP_BY_ID, getActionGroupById);
  yield takeEvery(actionGroupActions.ADD_ACTION_GROUP, addActionGroup);
  yield takeEvery(actionGroupActions.UPDATE_ACTION_GROUP, updateActionGroup);
  yield takeEvery(actionGroupActions.DELETE_ACTION_GROUP, deleteActionGroup);
}
