import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import { humanize, titleize } from 'sow/utils/string';
import { activityLogType } from 'sow/types';
import {
  stateChangeFormat,
  answerChangeFormat,
  changeCommentFormat,
  orgAssociationFormat,
  inspectionCreatedFormat,
  inspectionUpdatedFormat,
  sampleUpdatedFormat,
} from 'sow/constants/activityLog';
import Table from 'sow/components/molecules/Table';
import PreformattedText from 'sow/components/atoms/PreformattedText';
import Atom from 'sow/components/atoms/Atom';
import Button from 'sow/components/atoms/Button';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Block from 'sow/components/atoms/Block';

const formatEntry = R.pipe(humanize, titleize);

const isChangeAnswers = (val, key) => key !== 'answers';

const getLogChanges = R.compose(
  // remove answers because they are not supported
  R.pickBy(isChangeAnswers),
  R.path(['data', 'changes']),
);

const answerUpdates = (log, ospData) => {
  switch (log.action) {
    case 'change_added':
    case 'change_modified':
      return answerChangeFormat(log, ospData);
    case 'change_approved':
    case 'change_rejected':
      return stateChangeFormat(log, ospData);
    case 'comment_added':
      return changeCommentFormat(log, ospData);
    case 'user_added':
    case 'user_updated':
    case 'user_removed':
      return orgAssociationFormat(log);
    default:
      return {};
  }
};

const inspectionChanges = log => {
  switch (log.action) {
    case 'created':
      return inspectionCreatedFormat(log);
    case 'updated':
      return inspectionUpdatedFormat(log);
    default:
      return {};
  }
};

const sampleChanges = log => {
  switch (log.action) {
    case 'updated':
      return sampleUpdatedFormat(log);
    default:
      return {};
  }
};

// Needed until we create support for worksheet answer changes
const filterWorksheetAnswersKey = key => key != 'answers';

const ValueText = ({ value }) => {
  if (typeof value === 'object') {
    return <PreformattedText>{JSON.stringify(value)}</PreformattedText>;
  } else if (typeof value === 'boolean') {
    if (value) {
      return <Atom>Yes</Atom>;
    } else if (value === false) {
      return <Atom>No</Atom>;
    }
  }

  return <Atom>{value}</Atom>;
};

ValueText.propTypes = {
  value: PropTypes.any,
};

const popover = changes => (
  <Popover id="activityLogChanges" style={{ maxWidth: 'none' }}>
    <Block style={{ maxHeight: '500px', overflowY: 'auto' }}>
      <Table
        condensed
        style={{ marginBottom: 0 }}
        head={
          <TableRow>
            <TableCell heading>Field</TableCell>
            <TableCell heading>Value</TableCell>
          </TableRow>
        }
      >
        {Object.keys(changes)
          .filter(filterWorksheetAnswersKey)
          .map(key => (
            <TableRow key={key}>
              <TableCell valignMiddle>{formatEntry(key)}</TableCell>
              <TableCell valignMiddle style={{ maxWidth: '400px' }}>
                {key === 'change' ? (
                  <Button
                    className="no-margin-button"
                    onClick={() => {
                      window.open(
                        `/aca/${changes[key].new_value.aca_id}/activity?change_id=${changes[key].new_value.change_id}`,
                        '_blank',
                      );
                    }}
                    xs
                  >
                    View Change History
                  </Button>
                ) : (
                  <ValueText value={changes[key].new_value} />
                )}
              </TableCell>
            </TableRow>
          ))}
      </Table>
    </Block>
  </Popover>
);

const LogChangesOverlayButton = ({ log, ospData }) => {
  if (!log) return null;
  let changes =
    !R.isNil(log.change_id) || R.propEq('log_type', 'organization')(log)
      ? answerUpdates(log, ospData)
      : getLogChanges(log);
  if (R.propEq('log_type', 'inspection')(log)) {
    changes = inspectionChanges(log);
  }
  if (R.propEq('log_type', 'sample')(log)) {
    changes = sampleChanges(log);
  }

  if (R.isEmpty(changes)) return null;

  return (
    <OverlayTrigger trigger="click" rootClose placement="left" overlay={popover(changes)}>
      <Button sm>
        <FontAwesome icon="bolt" />
      </Button>
    </OverlayTrigger>
  );
};

LogChangesOverlayButton.propTypes = {
  log: activityLogType.isRequired,
};

export default LogChangesOverlayButton;
