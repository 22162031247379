import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Atom from 'sow/components/atoms/Atom';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const ToggleButtonContainer = styled(Atom)`
  cursor: pointer;
`;

const ToggleVisibilityButton = ({ hasNotes, onClick }) => (
  <ToggleButtonContainer>
    <FontAwesome
      variant={hasNotes ? 'fas' : 'far'}
      icon="sticky-note"
      onClick={onClick}
    />
  </ToggleButtonContainer>
);

ToggleVisibilityButton.propTypes = {
  hasNotes: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ToggleVisibilityButton;
