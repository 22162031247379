import * as R from 'ramda';
import actions from 'sow/actions/pure';

import byId from './byId';
import currentOspAppId from './currentOspAppId';
import errorMessage from './errorMessage';
import ids from './ids';
import isLoaded from './isLoaded';
import isLoading from './isLoading';

const mapReducer = (state = {}, action) => ({
  byId: byId(state.byId, action),
  currentOspAppId: currentOspAppId(state.currentOspAppId, action),
  errorMessage: errorMessage(state.errorMessage, action),
  ids: ids(state.ids, action),
  isLoaded: isLoaded(state.isLoaded, action),
  isLoading: isLoading(state.isLoading, action),
});

const listByOrgId = (state = {}, action) => {
  switch (action.type) {
    case String(actions.ospApp.loadOrgList):
    case String(actions.ospApp.loadOrgListDone):
    case String(actions.ospApp.loadOrgListFail): {
      const id = action.payload.orgId;
      return R.assoc(id, mapReducer(state[id], action), state);
    }

    case String(actions.ospApp.setCurrentForOrg): {
      const id = action.payload.orgId;
      return R.assoc(id, mapReducer(state[id], action), state);
    }

    default:
      return state;
  }
};

export default listByOrgId;

export const getCurrentOspAppId = (state, orgId) =>
  R.path([orgId, 'currentOspAppId'], state);

export const getIds = (state, orgId) => R.path([orgId, 'ids'], state);

export const getOspAppListMap = (state, orgId) => R.path([orgId, 'byId'], state);

export const getOspAppList = (state, orgId) => R.values(getOspAppListMap(state, orgId));

export const getIsLoaded = (state, orgId) => R.path([orgId, 'isLoaded'], state);

export const getIsLoading = (state, orgId) => R.path([orgId, 'isLoading'], state);

export const getErrorMessage = (state, orgId) => R.path([orgId, 'errorMessage'], state);
