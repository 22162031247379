import React from 'react';

import { itemVersionRoute } from 'sow/routes';

import AdminObjectTemplateContainer from 'sow/containers/AdminObjectTemplateContainer';
import ItemVersionCreatorContainer from 'sow/containers/ItemVersionCreatorContainer';

const ItemVersionCreatorPage = () => (
  <AdminObjectTemplateContainer
    breadcrumbPath={[['Item Versions', itemVersionRoute()]]}
    statePath={['itemVersion']}
  >
    <ItemVersionCreatorContainer />
  </AdminObjectTemplateContainer>
);

export default ItemVersionCreatorPage;
