import React from 'react';
import PropTypes from 'prop-types';

import { registerRoute } from 'sow/routes';
import { authCredentialsType } from 'sow/types';

import CenteredLayout from 'sow/components/templates/CenteredLayout';
import Form from 'sow/components/atoms/Form';
import Image from 'sow/components/atoms/Image';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Link from 'sow/components/atoms/Link';
import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import Well from 'sow/components/molecules/Well';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';
import LoginForm from 'sow/components/organisms/LoginForm';

const LoginPage = ({ credentials, onChange, onSubmit, isAuthenticating }) => (
  <Block className="body-wrapper login-wrapper">
    <Block>
      <CenteredLayout isLogin={true}>
        <Form onSubmit={onSubmit}>
          <Row>
            <Column sm={8} smOffset={2} textLeft>
              <Widget className="login-form">
                <WidgetHeading>
                  Sign in to your Where Food Comes From Organic account
                </WidgetHeading>
                <WidgetBody>
                  <LoginForm credentials={credentials} onChange={onChange} />
                </WidgetBody>
                <WidgetFooter>
                  <Row>
                    <Column xs={3}>
                      <Image src="/assets/images/sow-logo.png" className="sow-logo" />
                    </Column>
                    <Column xs={6} textCenter>
                      <Block className="data-security">
                        <Image src="/assets/images/data-security.png" className="image" />
                        <Block className="text">
                          COMMITMENT TO DATA
                          <br />
                          SECURITY AND PRIVACY
                        </Block>
                      </Block>
                    </Column>
                    <Column xs={3}>
                      <Button
                        type="submit"
                        disabled={isAuthenticating}
                        primary
                        sm
                        pullRight
                      >
                        Sign In
                      </Button>
                    </Column>
                  </Row>
                </WidgetFooter>
              </Widget>
              <Well sm className="no-account">
                Don't have an account with WFCF Organic yet?&nbsp;
                <a
                  href="https://www.wfcforganic.com/request-a-quote"
                  target="_blank"
                  rel="noreferrer"
                >
                  Apply now!
                </a>
              </Well>
            </Column>
          </Row>
        </Form>
        <Row>
          <Column sm={8} smOffset={2} textCenter>
            <Block className="wfcf-note">
              Where Food Comes From is the <strong>number one provider</strong> of
              certification and verification services to the food industry.
            </Block>
            <Block className="bundle-wrapper">
              <Block className="content-wrapper">
                <Block className="title">
                  Did you know that Where Food Comes From Organic can bundle various
                  certification and verification programs with your Organic certification?
                </Block>
                <Block className="sub-title">
                  A few of the bundling options are below.{' '}
                  <a href="https://www.wfcforganic.com/request-a-quote" target="_blank">
                    Contact us
                  </a>{' '}
                  to get a quote on bundling your existing programs!
                </Block>

                <Block className="verification-block">
                  <Block className="logo-item verification-img">
                    <Image
                      src="/assets/images/WFCFO-logo.png"
                      title="Where Food Comes From Organic"
                    />
                  </Block>
                  <Block className="logo-item verification-img">
                    <Image
                      src="/assets/images/verification-programs/logo-wfcf-plant-based.png"
                      title="Plant-Based Certified by WFCF"
                    />
                  </Block>
                  <Block className="logo-item verification-img">
                    <Image
                      src="/assets/images/verification-programs/logo-ngp-2x.png"
                      title="Non GMO Project Verified"
                    />
                  </Block>
                  <Block className="logo-item verification-img">
                    <Image
                      src="/assets/images/verification-programs/logo-upcycled.png"
                      title="Upcycled Certified"
                    />
                  </Block>
                  <Block className="logo-item verification-img">
                    <Image
                      src="/assets/images/verification-programs/logo-paleo.png"
                      title="The Paleo Diet"
                    />
                  </Block>
                  <Block className="logo-item verification-img">
                    <Image
                      src="/assets/images/verification-programs/logo-ROC.png"
                      title="Regenerative Organic Certified"
                    />
                  </Block>
                  <Block className="logo-item verification-img">
                    <Image
                      src="/assets/images/verification-programs/logo-sqf-2x.png"
                      title="SQF Institute"
                    />
                  </Block>
                  <Block className="logo-item verification-img">
                    <Image
                      src="/assets/images/verification-programs/logo-wfcf-gf-2x.png"
                      title="Gluten Free Certified By WFCF"
                    />
                  </Block>
                  <Block className="logo-item verification-img">
                    <Image
                      src="/assets/images/verification-programs/logo-american-humane-2x.png"
                      title="American Humane Certified"
                    />
                  </Block>
                  <Block className="logo-item verification-img">
                    <Image
                      src="/assets/images/verification-programs/logo-wfcf-care-2x.png"
                      title="Where Food Comes From CARE Certified"
                    />
                  </Block>
                  <Block className="logo-item verification-img">
                    <Image
                      src="/assets/images/verification-programs/logo-validus-2x.png"
                      title="Validus"
                    />
                  </Block>
                  <Block className="logo-item verification-img">
                    <Image
                      src="/assets/images/verification-programs/logo-uep-2x.png"
                      title="United Egg Producers Certified"
                    />
                  </Block>
                  <Block className="logo-item verification-img">
                    <Image
                      src="/assets/images/verification-programs/logo-wfcf-grass-fed.png"
                      title="Grass-Fed Certified By WFCF"
                    />
                  </Block>
                  <Block className="logo-item verification-img">
                    <Image
                      src="/assets/images/verification-programs/logo-cswa-2x.png"
                      title="Certified California Sustainable Winegrowing"
                    />
                  </Block>
                </Block>
              </Block>
            </Block>
          </Column>
        </Row>
        <Row>
          <Column xs={8} xsOffset={2} textLeft />
        </Row>
      </CenteredLayout>
    </Block>
  </Block>
);

LoginPage.propTypes = {
  credentials: authCredentialsType.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
};

export default LoginPage;
