import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import actions from 'sow/actions/pure';
import * as currentUser from 'sow/selectors/currentUser';
import { fromPlanApp } from 'sow/store/selectors';
import { planAppChangeCommentIdType } from 'sow/types';
import { PlanAppChangeCommentDismissLoader } from 'sow/store/containers';
import ChangeCommentDismissAllButton from 'sow/components/organisms/PlanAppChangeCommentDismissAllButton';

const mapStateToProps = (state, props) => {
  const { changeList } = props;
  const visibleChangeIds = R.map(R.prop('id'))(changeList);
  const commentList = fromPlanApp.changeRequestCommentList(state, props);
  const showAcaUI = currentUser.showAcaUI(state, props);

  const visibleCommentIds = R.pipe(
    R.filter(
      R.where({
        changeId: R.contains(R.__, visibleChangeIds),
        notifyAca: R.equals(showAcaUI),
        notifyOrg: R.equals(!showAcaUI),
      }),
    ),
    R.map(R.prop('id')),
  )(commentList);

  const hasNotifications = R.pipe(
    R.length,
    R.gt(R.__, 0),
  )(visibleCommentIds);

  return {
    hasNotifications,
    commentIds: visibleCommentIds,
  };
};

const mapDispatchToProps = {
  toast: actions.shell.toast,
};

const ChangeCommentDismissAllButtonContainer = ({
  hasNotifications,
  toast,
  commentIds,
  ...props
}) => {
  return (
    hasNotifications && (
      <PlanAppChangeCommentDismissLoader {...props}>
        {({ status, updateResource }) => (
          <ChangeCommentDismissAllButton
            isDisabled={status.loading}
            onClick={event => {
              event.preventDefault;
              updateResource({ change_comment_ids: commentIds }).then(() =>
                toast('success', 'Comments marked read'),
              );
            }}
          />
        )}
      </PlanAppChangeCommentDismissLoader>
    )
  );
};

ChangeCommentDismissAllButtonContainer.propTypes = {
  hasNotifications: PropTypes.bool.isRequired,
  toast: PropTypes.func.isRequired,
  commentIds: PropTypes.arrayOf(planAppChangeCommentIdType),
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ChangeCommentDismissAllButtonContainer);
