import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';
import Block from 'sow/components/atoms/Block';
import Form from 'sow/components/atoms/Form';
import Button from 'sow/components/atoms/Button';
import ProfileForm from 'sow/components/organisms/ProfileForm';

const setUserField = (field, value) => R.assocPath(['user', field], value);

const unsetPassword = R.compose(
  setUserField('old_password', ''),
  setUserField('password', ''),
);

class SettingsTab extends Component {
  constructor(props) {
    super();
    this.state = R.compose(R.pick(['user']), unsetPassword)(props);
  }

  handleChange = (field, value) => this.setState(setUserField(field, value));

  handleSubmit = () => this.props.onSubmit(this.state.user, this.handleUpdateSuccess);

  // Clear the password fields when user is updated
  handleUpdateSuccess = () => this.setState(unsetPassword);

  render() {
    const { user } = this.state;
    const { isUpdating, updateErrors } = this.props;

    return (
      <Widget>
        <Form onSubmit={this.handleSubmit}>
          <WidgetBody>
            <ProfileForm
              user={user}
              handleChange={this.handleChange}
              errors={updateErrors}
            />
          </WidgetBody>

          <WidgetFooter>
            <Block textRight>
              <Button type="submit" primary sm disabled={isUpdating}>
                {isUpdating ? 'Updating...' : 'Save Changes'}
              </Button>
            </Block>
          </WidgetFooter>
        </Form>
      </Widget>
    );
  }
}

SettingsTab.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  updateErrors: PropTypes.object,
};

export default SettingsTab;
