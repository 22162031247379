import * as R from 'ramda';

const initialState = {};

export default function inspection(state = initialState, { type, payload }) {
  switch (type) {
    case 'INSPECTION/GET_ALL_BY_ORG':
      return state;

    case 'INSPECTION/GET_ALL_BY_ORG_DONE':
      return {
        ...state,
        list: [...payload.data],
      };

    case 'INSPECTION/UPDATE_DONE':
      return { ...state, list: payload };

    case 'INSPECTION/DELETE_DONE':
      return {
        ...state,
        list: [
          ...R.reject(
            partialInspection => partialInspection.id === payload.partial_inspection_id,
            state.list,
          ),
        ],
      };

    case 'INSPECTION/GET_OPTIONS_DONE':
      return {
        ...state,
        inspectionTypeOptions: payload.type_options,
        inspectionStateOptions: payload.state_options,
        inspectionYearOptions: payload.year_options,
      };

    case 'INSPECTION/GET_UNANNOUNCED_OPTIONS_DONE':
      return {
        ...state,
        unannouncedFilterOptions: { ...payload },
      };

    default:
      return state;
  }
}
