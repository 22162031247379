import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as itemSectionTypeActions from 'sow/actions/pure/itemSectionType';
import { apiGet, apiPost, apiPut } from 'sow/sagas/api';

export const loadItemSectionTypes = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/item_section_type', payload);

    if (response && meta.onSuccess) {
      meta.onSuccess(response.data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchById = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/item_section_type/${payload}`);

    if (response && meta.onSuccess) {
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addItemSectionType = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/item_section_type', payload);
    if (response && meta.onSuccess) {
      yield put(actions.shell.toast('success', 'Item section type created.'));
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateItemSectionType = function*({ id, payload, meta }) {
  try {
    const { response } = yield call(apiPut, `/admin/item_section_type/${id}`, payload);
    if (response) {
      yield put(actions.shell.toast('success', 'Item section type updated.'));
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateSortOrder = function*({ payload }) {
  try {
    yield call(apiPost, '/admin/item_section_type/update_order', {
      updated_list: payload,
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteItemSectionType = function*({ id, meta }) {
  try {
    const { response } = yield call(apiPut, `/admin/item_section_type/delete/${id}`);
    if (response) {
      yield put(actions.shell.toast('success', 'Item section type deleted.'));
      if (meta.onSuccess) meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(itemSectionTypeActions.LOAD_ITEM_SECTION_TYPES, loadItemSectionTypes);
  yield takeEvery(itemSectionTypeActions.FETCH_BY_ID, fetchById);
  yield takeEvery(itemSectionTypeActions.ADD_ITEM_SECTION_TYPE, addItemSectionType);
  yield takeEvery(itemSectionTypeActions.UPDATE_ITEM_SECTION_TYPE, updateItemSectionType);
  yield takeEvery(itemSectionTypeActions.UPDATE_SORT_ORDER, updateSortOrder);
  yield takeEvery(itemSectionTypeActions.DELETE_ITEM_SECTION_TYPE, deleteItemSectionType);
}
