import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import actions from 'sow/actions/pure';
import {
  ResourceDetailLoader,
  resourceUpdate,
  resourceDetailRead,
} from 'sow/store/helpers';
import { acaIdType } from 'sow/types';
import { fromAca } from 'sow/store/selectors';
import Spinner from 'sow/components/atoms/Spinner';
import AcaSettingsWorkflowForm from 'sow/components/organisms/AcaSettingsWorkflowForm';

const mapStateToProps = (state, props) => ({
  acaId: fromAca.acaId(state, props),
  planEditLocationByOrgAllowed: fromAca.configPlanEditLocationByOrgAllowed(state, props),
  planMinNumLocations: fromAca.configPlanMinNumLocations(state, props),
});

const mapDispatchToProps = dispatch => ({
  notify: (type, message) => {
    const action = actions.shell.toast(type, message);
    return dispatch(action);
  },
  loadAca: acaId => {
    const action = resourceDetailRead(`aca/${acaId}`, 'aca').action();
    return dispatch(action);
  },
  updateConfig: (acaId, updates) => {
    const resource = resourceUpdate(`aca/${acaId}/configuration`);
    const action = resource.action(null, { configuration: { ...updates } });
    return dispatch(action);
  },
});

class AcaSettingsWorkflowPageContainer extends React.Component {
  handleSubmit = async (formValues, formikBag) => {
    const { acaId, loadAca, notify, updateConfig } = this.props;
    try {
      const { planEditLocationByOrgAllowed, planMinNumLocations } = formValues;
      await updateConfig(acaId, { planEditLocationByOrgAllowed, planMinNumLocations });
      await loadAca(acaId);
      notify('success', `Settings successfully updated.`);
    } catch (e) {
      notify('danger', 'Error updating settings. Please try again.');
    }
    formikBag.setSubmitting(false);
  };

  render() {
    const { acaId, planEditLocationByOrgAllowed, planMinNumLocations } = this.props;
    return (
      <ResourceDetailLoader resource={`aca/${acaId}`} entityType="aca" autoLoad>
        {({ result }) => {
          if (result === null) return <Spinner />;
          return (
            <AcaSettingsWorkflowForm
              initialValues={{ planEditLocationByOrgAllowed, planMinNumLocations }}
              onSubmit={this.handleSubmit}
            />
          );
        }}
      </ResourceDetailLoader>
    );
  }
}
AcaSettingsWorkflowPageContainer.propTypes = {
  acaId: acaIdType.isRequired,
  planEditLocationByOrgAllowed: PropTypes.bool.isRequired,
  planMinNumLocations: PropTypes.number,
  notify: PropTypes.func.isRequired,
  loadAca: PropTypes.func.isRequired,
  updateConfig: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AcaSettingsWorkflowPageContainer);
