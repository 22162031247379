import React from 'react';
import { connect } from 'react-redux';

import * as currentUser from 'sow/selectors/currentUser';

const dataToString = data => JSON.stringify(data);

const logOnClick = data => event => {
  event.preventDefault();
  console.log(data);
};

export const AdminXrayLogButton = ({ data }) => {
  if (!data) return null;
  return (
    <button
      className="btn btn-xs btn-warning pull-left"
      style={{ marginRight: 3 }}
      onClick={logOnClick(data)}
    >
      Log
    </button>
  );
};

export const AdminXrayPre = ({ text, data }) =>
  <div
    className="alert alert-danger"
    style={{ padding: 3, borderWidth: 4, marginBottom: 3 }}
  >
    <AdminXrayLogButton data={data} />
    <pre style={{ marginBottom: 0, whiteSpace: 'pre-wrap' }}>
      {text}
    </pre>
  </div>;

export const AdminXrayJson = ({ data, log = false }) => {
  if (log) console.log('AdminXrayJson', data);
  return <AdminXrayPre data={data} text={dataToString(data)} />;
};

export const AdminXrayRaw = ({ children, isAdminXrayMode, text, data, log }) => {
  if (!isAdminXrayMode) return null;
  if (log && data) return <AdminXrayLogButton data={data} />;
  if (text) return <AdminXrayPre text={text} />;
  if (data) return <AdminXrayJson data={data} />;
  return React.cloneElement(children);
};

const mapStateToProps = (state, props) => ({
  isAdminXrayMode: currentUser.isAdminXrayMode(state, props),
});

export const AdminXrayPanel = ({
  colorContext = 'danger',
  title = 'AdminXrayPanel',
  data,
}) => {
  return (
    <div className={`panel panel-${colorContext}`}>
      <div className="panel-heading">
        <h4 className="panel-title">
          {title}
        </h4>
      </div>
      <div className="panel-body">
        <pre>
          {JSON.stringify(data)}
        </pre>
      </div>
    </div>
  );
};

export default connect((state, props) => ({
  isAdminXrayMode: state.layout.adminXrayMode,
}))(AdminXrayRaw);
