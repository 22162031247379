import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as sowTypes from 'sow/types';
import { worksheetRoute, checklistRoute, locationRoute } from 'sow/routes';
import Atom from 'sow/components/atoms/Atom';
import Link from 'sow/components/atoms/Link';
import Block from 'sow/components/atoms/Block';
import Spacer from 'sow/components/atoms/Spacer';
import ChangeListItemComments from './ChangeListItemComments';
import {
  locationWorksheetMatrixRowScrollRoute,
  locationWorksheetMatrixRowScrollRouteLinkToObject,
  locationWorksheetQuestionScrollRoute,
  planAppWorksheetMatrixRowScrollRoute,
  planAppWorksheetMatrixRowScrollRouteLinkToObject,
  planAppWorksheetQuestionScrollRoute,
} from 'sow/components/atoms/ScrollTarget/scrollRoutes';

import BlockedText from 'sow/components/organisms/BlockedText';

const ChangeWrapper = styled(Block)`
  padding: 10px;
  margin-bottom: 5px;
  background-color: ${({ theme, change }) => {
    if (change.blocked) return theme.crBackground.blocked;
    return theme.crBackground[change.state];
  }};
  border-left: ${({ theme, change, showComments }) =>
    showComments ? `5px solid ${theme.cr[change.state]}` : 'none'};
`;

const ChangeStateText = styled(Block)`
  float: right;
  margin: 0 0 10px 10px;
  font-weight: 700;
  font-size: 12pt;
  color: ${({ theme, change }) => {
    if (change.blocked) return theme.cr.blocked;
    return theme.cr[change.state];
  }};
`;

const DisplayNameWrapper = styled(Block)`
  color: black;
  font-size: 12pt;
`;

const ChangeActionText = styled(Atom)`
  font-weight: 500;
  color: #555555;
`;

const changeStateDisplayText = state => {
  switch (state) {
    case 'open':
      return 'pending';
    case 'rejected':
      return 'discarded';
    case 'not_applied':
      return 'not applied';
    case 'accepted':
    case 'applied':
    default:
      return state;
  }
};

function locationSelfLink(orgId, planAppId, locationId, change) {
  const route = locationRoute(orgId, planAppId, locationId);
  if (typeof change !== 'undefined') {
    switch (change.action) {
      case 'added':
      case 'updated':
        return {
          pathname: route,
          state: {
            openModalId: `location-${locationId}`,
          },
        };
  
      case 'deleted':
      case 'default':
        return route;
    }
  } else {
    return route;
  }
}

const ChangeListItem = ({
  orgId,
  planAppId,
  worksheetId,
  locationId,
  questionId,
  questionName,
  change,
  comments,
  showComments,
  toggleComments,
}) => {
  let linkTo;
  let displayName;

  const formatQuestionName = qName =>
    qName ? qName.trim().replace(/:$/, '') : '(unknown)';

  if (change.context === 'main') {
    switch (change.type) {
      case 'plan':
        linkTo = checklistRoute(orgId, planAppId);
        displayName = 'Activities Checklist';
        break;

      case 'worksheet':
        linkTo = worksheetRoute(orgId, planAppId, worksheetId);
        displayName = 'Is Not Applicable';
        break;

      case 'worksheet_answer':
        linkTo = questionId
          ? planAppWorksheetQuestionScrollRoute(orgId, planAppId, worksheetId, questionId)
          : worksheetRoute(orgId, planAppId, worksheetId);
        displayName = (
          <Atom>
            {formatQuestionName(questionName)}{' '}
            <ChangeActionText>(answer {((typeof change !== 'undefined') ? change.action : '')})</ChangeActionText>
          </Atom>
        );
        break;

      case 'matrix_row_answer':
        linkTo = {
          ...planAppWorksheetMatrixRowScrollRouteLinkToObject(
            orgId,
            planAppId,
            worksheetId,
            change.matrixRowId,
          ),
          state: { openModalId: change.matrixRowId },
        };
        displayName = (
          <Atom>
            {formatQuestionName(questionName)}{' '}
            <ChangeActionText>(answer {((typeof change !== 'undefined') ? change.action : '')})</ChangeActionText>
          </Atom>
        );
        break;

      case 'matrix_row':
        linkTo = planAppWorksheetMatrixRowScrollRoute(
          orgId,
          planAppId,
          worksheetId,
          change.matrixRowId,
        );
        displayName = (
          <Atom>
            Matrix row <ChangeActionText>(row removed)</ChangeActionText>
          </Atom>
        );
        break;

      default:
        linkTo = null;
        displayName = null;
    }
  } else if (change.context === 'land') {
    switch (change.type) {
      case 'self':
        linkTo = locationSelfLink(orgId, planAppId, locationId, change);
        displayName = `Location ${((typeof change !== 'undefined') ? change.action : '')}`;
        break;

      case 'worksheet':
        linkTo = locationRoute(orgId, planAppId, locationId);
        displayName = 'Is Not Applicable';
        break;

      case 'worksheet_answer':
        linkTo = locationWorksheetQuestionScrollRoute(
          orgId,
          planAppId,
          locationId,
          questionId,
        );
        displayName = (
          <Atom>
            {formatQuestionName(questionName)}{' '}
            <ChangeActionText>(answer {((typeof change !== 'undefined') ? change.action : '')})</ChangeActionText>
          </Atom>
        );
        break;

      case 'matrix_row_answer':
        linkTo = {
          ...locationWorksheetMatrixRowScrollRouteLinkToObject(
            orgId,
            planAppId,
            locationId,
            change.matrixRowId,
          ),
          state: { openModalId: change.matrixRowId },
        };
        displayName = (
          <Atom>
            {formatQuestionName(questionName)}{' '}
            <ChangeActionText>(answer {change.action})</ChangeActionText>
          </Atom>
        );
        break;

      case 'matrix_row':
        linkTo = locationWorksheetMatrixRowScrollRoute(
          orgId,
          planAppId,
          locationId,
          change.matrixRowId,
        );
        displayName = (
          <Atom>
            Matrix row <ChangeActionText>(row removed)</ChangeActionText>
          </Atom>
        );
        break;

      default:
        linkTo = null;
        displayName = null;
    }
  }

  if (linkTo) {
    return (
      <ChangeWrapper change={change} showComments={showComments}>
        <ChangeStateText change={change}>
          {changeStateDisplayText(change.state)}
          {change.blocked && (
            <Fragment>
              {' '}
              <BlockedText />
            </Fragment>
          )}
        </ChangeStateText>
        <Link to={linkTo}>
          <DisplayNameWrapper>{displayName}</DisplayNameWrapper>
        </Link>

        {!R.isEmpty(comments) && (
          <Block>
            <Spacer vertical={5} />
            <ChangeListItemComments
              changeState={change.state}
              comments={comments}
              showComments={showComments}
              toggleComments={toggleComments}
            />
          </Block>
        )}
      </ChangeWrapper>
    );
  }

  return null;
};

ChangeListItem.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  changeId: sowTypes.planAppChangeIdType.isRequired,
  change: sowTypes.planAppChangeType.isRequired,
  worksheetId: sowTypes.planAppWorksheetIdType,
  locationId: sowTypes.planAppLocationIdType,
  questionId: sowTypes.planAppWorksheetQuestionIdType,
  questionName: PropTypes.string,
  children: PropTypes.node,
  comments: PropTypes.arrayOf(sowTypes.planAppChangeCommentType).isRequired,
  showAcaUI: PropTypes.bool.isRequired,
  showComments: PropTypes.bool.isRequired,
  toggleComments: PropTypes.func.isRequired,
};

ChangeListItem.defaultProps = {
  children: null,
  worksheetId: null,
  locationId: null,
  questionId: null,
  questionName: null,
};

export default ChangeListItem;
