import {
  FETCH_TIMEZONE_LIST,
  FETCH_TIMEZONE_LIST_SUCCESS,
  FETCH_TIMEZONE_LIST_FAILED,
} from 'sow/actions/aca';

const initialState = {
  items: [],
  isFetching: false,
};

const actionHandlers = {
  [FETCH_TIMEZONE_LIST]: () => ({ ...initialState, isFetching: true }),
  [FETCH_TIMEZONE_LIST_SUCCESS]: (state, { items }) => ({
    ...state,
    isFetching: false,
    items,
  }),
  [FETCH_TIMEZONE_LIST_FAILED]: state => ({ ...state, isFetching: false }),
};

export default function timezoneList(state = initialState, action) {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action.data) : state;
}
