import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { NoteRow } from './Note';
import { CommentRow } from './Comment';
import WorksheetAnswer from './WorksheetAnswer';

const PlanAppExportContentWorksheetQuestion = ({
  question,
  value,
  noteList,
  commentList,
  addNotes,
  addComments,
  locationPending,
}) => {
  const { questionValue, pending } = value;

  return (
    <Fragment>
      {question.fieldType === 'descriptive_text' ? (
        <Fragment>
          {question.answers.map(answer => (
            <tr>
              <td className="non-conditional" colSpan={2}>
                {answer}
              </td>
            </tr>
          ))}
        </Fragment>
      ) : (
        <tr>
          <td
            className={
              question.conditions && question.conditions.length
                ? 'conditional-question'
                : 'non-conditional'
            }
          >
            <p>{question.name}</p>
          </td>
          <td>
            <WorksheetAnswer
              value={questionValue}
              question={question}
              pending={pending}
              locationPending={locationPending}
            />
          </td>
        </tr>
      )}
      {addNotes && noteList.map(note => <NoteRow key={note.id} note={note} />)}
      {addComments &&
        commentList.map(comment => <CommentRow key={comment.id} comment={comment} />)}
    </Fragment>
  );
};

PlanAppExportContentWorksheetQuestion.propTypes = {
  question: sowTypes.planAppWorksheetQuestionType.isRequired,
  value: PropTypes.any,
  commentList: sowTypes.planAppChangeCommentListType.isRequired,
  noteList: sowTypes.planAppNoteListType.isRequired,
  addComments: PropTypes.bool,
  addNotes: PropTypes.bool,
  exportConfig: sowTypes.planAppExportConfigType.isRequired,
};

export default PlanAppExportContentWorksheetQuestion;
