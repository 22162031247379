import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Heading from 'sow/components/atoms/Heading';
import Well from 'sow/components/molecules/Well';

const WellFormGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0;
  margin-bottom: 20px;

  & .well {
    border-radius: 10px;
    margin: 0 10px;
    background: hsl(0, 0%, 90%);

    display: ${props => (props.small ? 'flex' : 'block')};
  }
  & .well > div {
    margin-bottom: ${props => (props.small ? '4px' : null)};
  }
`;

const WellFormGroupTitle = styled(Heading).attrs({ level: 3 })`
  margin: 0 0.3em 0.4em 0.8em;
  padding-top: 6px;
  flex: 0 0;
  color: #1c3b61;
`;

const WellFormGroup = ({ title, children, small }) => {
  return (
    <WellFormGroupWrapper small={small}>
      {title && <WellFormGroupTitle>{title}</WellFormGroupTitle>}
      <Well sm>{children}</Well>
    </WellFormGroupWrapper>
  );
};

WellFormGroup.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  small: PropTypes.bool,
};

const WellFormGroupSubGroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const WellFormGroupSubTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

const WellFormGroupSubTitleLine = styled.div`
  flex: 1 1;
  height: 1px;
  background: #4e6f95;
  opacity: 0.6;
`;

const WellFormGroupSubTitleContent = styled.div`
  flex: 0 0;
  margin: 0 6px;
  padding: 2px 10px;
  border-radius: 1em;
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.12), 0 1px 2px hsla(0, 0%, 0%, 0.24);

  background: #e3eef8;
  color: #0d3b64;
  white-space: nowrap;
`;

const WellFormGroupSubTitle = ({ title }) => (
  <WellFormGroupSubTitleWrapper>
    <WellFormGroupSubTitleLine />
    <WellFormGroupSubTitleContent>{title}</WellFormGroupSubTitleContent>
    <WellFormGroupSubTitleLine />
  </WellFormGroupSubTitleWrapper>
);

WellFormGroupSubTitle.propTypes = {
  title: PropTypes.string,
};

const WellFormGroupSubGroupContent = styled.div`
  width: 100%;
  display: flex;
  flex: wrap;
  align-content: stretch;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const WellFormGroupSubGroup = ({ title, children }) => (
  <WellFormGroupSubGroupWrapper>
    {title && <WellFormGroupSubTitle title={title} />}
    <WellFormGroupSubGroupContent>{children}</WellFormGroupSubGroupContent>
  </WellFormGroupSubGroupWrapper>
);

WellFormGroupSubGroup.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default WellFormGroup;
