import * as acaAdminActions from 'sow/actions/pure/acaAdmin';

const initialState = {
  isAddingStaff: false,
  addStaffErrors: null,
};

export default function acaAdminAddStaff(state = initialState, action) {
  switch (action.type) {
    case acaAdminActions.ADD_STAFF:
      return { ...state, isAddingStaff: true };

    case acaAdminActions.ADD_STAFF_DONE:
      return { ...state, isAddingStaff: false };

    case acaAdminActions.ADD_STAFF_FAIL:
      return {
        ...state,
        isAddingStaff: false,
        addStaffErrors: action.payload.errors,
      };

    case acaAdminActions.SHOW_INVITE_FIELDS:
      return { ...state, showInviteFields: true };

    case acaAdminActions.HIDE_INVITE_FIELDS:
      return { ...initialState };

    default:
      return state;
  }
}
