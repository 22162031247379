const products = (
  state = { isFetchingOptions: true, searchResults: [] },
  { type, payload },
) => {
  switch (type) {
    case 'PRODUCTS/FETCH_FILTER_OPTIONS':
      return { ...state, isFetchingOptions: true };
    case 'PRODUCTS/FETCH_FILTER_OPTIONS_DONE':
      return { ...state, isFetchingOptions: false, filterOptions: payload };
    case 'PRODUCTS/PRODUCT_SEARCH':
      return { ...state, searchInitiated: true, isSearching: true };
    case 'PRODUCTS/PRODUCTS_SEARCH_DONE':
      return { ...state, isSearching: false, searchResults: payload };
    case 'PRODUCTS/CLEAR_SEARCH':
      return {
        ...state,
        searchInitiated: false,
        isSearching: false,
        searchResults: [],
      };
    default:
      return state;
  }
};

export default products;
