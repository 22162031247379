const mark = action => `ITEM_ATTRIBUTE_TYPE/${action}`;

export const LOAD_ITEM_ATTRIBUTE_TYPES = mark('LOAD_ITEM_ATTRIBUTE_TYPES');
export const FETCH_BY_ID = mark('FETCH_BY_ID');
export const ADD_ITEM_ATTRIBUTE_TYPE = mark('ADD_ITEM_ATTRIBUTE_TYPE');
export const UPDATE_ITEM_ATTRIBUTE_TYPE = mark('UPDATE_ITEM_ATTRIBUTE_TYPE');
export const UPDATE_SORT_ORDER = mark('UPDATE_SORT_ORDER');
export const DELETE_ITEM_ATTRIBUTE_TYPE = mark('DELETE_ITEM_ATTRIBUTE_TYPE');

export const loadItemAttributeTypes = (payload, onSuccess) => ({
  type: LOAD_ITEM_ATTRIBUTE_TYPES,
  payload,
  meta: { onSuccess },
});

export const fetchById = (payload, onSuccess) => ({
  type: FETCH_BY_ID,
  payload,
  meta: { onSuccess },
});

export const addItemAttributeType = (payload, onSuccess) => ({
  type: ADD_ITEM_ATTRIBUTE_TYPE,
  payload,
  meta: { onSuccess },
});

export const updateItemAttributeType = (id, payload, onSuccess) => ({
  type: UPDATE_ITEM_ATTRIBUTE_TYPE,
  id,
  payload,
  meta: { onSuccess },
});

export const updateSortOrder = (payload, onSuccess) => ({
  type: UPDATE_SORT_ORDER,
  payload,
  meta: { onSuccess },
});

export const deleteItemAttributeType = (id, onSuccess) => ({
  type: DELETE_ITEM_ATTRIBUTE_TYPE,
  id,
  meta: { onSuccess },
});
