import React from 'react';
import PropTypes from 'prop-types';

import Spinner from 'sow/components/atoms/Spinner';
import StandardLayout from 'sow/components/templates/StandardLayout';

const AdminPageTemplate = ({ title, isLoadingAcas, isLoadingOrgs, acas, children }) => {
  const isLoading = isLoadingAcas || isLoadingOrgs;
  return (
    <StandardLayout title={title}>
      {isLoading ? <Spinner /> : children(acas)}
    </StandardLayout>
  );
};

AdminPageTemplate.propTypes = {
  title: PropTypes.string,
  isLoadingAcas: PropTypes.bool.isRequired,
  isLoadingOrgs: PropTypes.bool.isRequired,
  acas: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.func.isRequired,
};

AdminPageTemplate.defaultProps = {
  title: 'Administration',
};

export default AdminPageTemplate;
