import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Atom from 'sow/components/atoms/Atom';
import Block from 'sow/components/atoms/Block';

const Addon = ({ children }) => <Atom className="input-group-addon">{children}</Atom>;

Addon.propTypes = {
  children: PropTypes.node.isRequired,
};

const InputGroup = ({
  className,
  sm,
  lg,
  addonBefore,
  addonAfter,
  children,
  ...props
}) => {
  const classes = classnames(className, 'input-group', {
    'input-group-sm': sm,
    'input-group-lg': lg,
  });

  return (
    <div className={classes} {...props}>
      {addonBefore && <Addon>{addonBefore}</Addon>}
      {children}
      {addonAfter && <Addon>{addonAfter}</Addon>}
    </div>
  );
};

InputGroup.propTypes = {
  className: PropTypes.string,
  sm: PropTypes.bool,
  lg: PropTypes.bool,
  children: PropTypes.node,
  addonBefore: PropTypes.node,
  addonAfter: PropTypes.node,
};

export default InputGroup;
