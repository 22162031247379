export const BULK_DOWNLOAD_REQUEST = 'BULK_DOWNLOAD_REQUEST';
export const BULK_DOWNLOAD_SUCCESS = 'BULK_DOWNLOAD_SUCCESS';
export const BULK_DOWNLOAD_FAILURE = 'BULK_DOWNLOAD_FAILURE';

export const bulkDownloadRequest = (resource, params) => ({
  type: BULK_DOWNLOAD_REQUEST,
  payload: { params },
  meta: {
    resource,
    thunk: `${resource}BulkDownload`,
  },
});

export const bulkDownloadSuccess = (resource, detail, request, thunk) => ({
  type: BULK_DOWNLOAD_SUCCESS,
  payload: detail,
  meta: {
    request,
    thunk,
    resource,
  },
});

export const bulkDownloadFailure = (resource, error, request, thunk) => ({
  type: BULK_DOWNLOAD_FAILURE,
  error: true,
  payload: error,
  meta: {
    request,
    thunk,
    resource,
  },
});
