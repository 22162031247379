import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Glyphicon, MenuItem, Modal } from 'react-bootstrap';

import FormGroup from 'sow/components/organisms/FormGroup';
import { createOspDefForAca } from 'sow/actions/ospDefinition';
// import { denormalizeData } from 'sow/components/OspDefEditor/OspDefEditor';

import { genOspDef } from './newOspDef';

const mapDispatchToProps = { createOspDefForAca };

class CreateEmptyOspDefModal extends Component {
  state = {
    show: false,
    name: '',
  };

  handleHide = () => {
    this.setState({
      show: false,
      name: '',
    });
  };

  handleShow = event => {
    this.setState({
      show: true,
      name: '(New Plan Definition)',
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { createOspDefForAca, acaId } = this.props;
    const { name } = this.state;
    const newOspDef = genOspDef({ name });
    createOspDefForAca(acaId, newOspDef, this.handleHide);
  };

  render() {
    const { ospDef } = this.props;
    const { show, name } = this.state;
    const errors = {};

    return (
      <React.Fragment>
        <MenuItem id={`createNewEmptyOspDefButton-new`} onClick={this.handleShow}>
          <Glyphicon glyph="plus" /> Create New Plan Definition
        </MenuItem>
        <Modal backdrop="static" show={show} onHide={this.handleHide}>
          <form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Create New Plan Definition</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <FormGroup
                fieldName="name"
                errors={errors}
                label="New Plan Definition's Name"
                required
              >
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={e => this.setState({ name: e.target.value })}
                  className="form-control"
                  required
                />
              </FormGroup>
            </Modal.Body>

            <Modal.Footer>
              <button type="submit" className="btn btn-primary">
                Done
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(null, mapDispatchToProps)(CreateEmptyOspDefModal);
