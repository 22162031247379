import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './SortableTable.module.css';

import Button from 'sow/components/atoms/Button';
import Block from 'sow/components/atoms/Block';
import Paragraph from 'sow/components/atoms/Paragraph';

const sortColumn = (questionId, filteredTableData, sortedColumn, sortOrderAsc) => {
  filteredTableData.sort((a, b) => {
    let aValue;
    let bValue;

    if (a[questionId] && typeof a[questionId] == 'object') {
      const pdfArray = a[questionId].map(file => file.name);
      aValue = pdfArray.join(' ').toUpperCase();
    } else if (typeof a[questionId] == 'boolean') {
      aValue = a[questionId] ? 'Yes' : 'No';
    } else {
      aValue = a[questionId] ? a[questionId].toUpperCase().trimStart() : '';
    }

    if (b[questionId] && typeof b[questionId] == 'object') {
      const pdfArray = b[questionId].map(file => file.name);
      bValue = pdfArray.join(' ').toUpperCase();
    } else if (typeof b[questionId] == 'boolean') {
      bValue = b[questionId] ? 'Yes' : 'No';
    } else {
      bValue = b[questionId] ? b[questionId].toUpperCase().trimStart() : '';
    }

    if (!sortOrderAsc || sortedColumn != questionId) {
      if (aValue < bValue) {
        return -1;
      } else if (aValue > bValue) {
        return 1;
      } else {
        return 0;
      }
    } else if (sortOrderAsc) {
      if (aValue < bValue) {
        return 1;
      } else if (aValue > bValue) {
        return -1;
      } else {
        return 0;
      }
    }
  });
  return filteredTableData;
};

const snapToIndex = (sortedData, selectedRowId, indexSize) => {
  const snapToRowIndex = sortedData.indexOf(selectedRowId);
  return Math.floor(snapToRowIndex / indexSize) * indexSize;
};

const createPageButtons = (listLength, indexSize, currentIndex) => {
  const lastPageNumber = Math.ceil(listLength / indexSize);
  const currentPage = currentIndex / indexSize;
  let buttonLabels = [];
  if (lastPageNumber <= 7) {
    for (let i = 2; i < lastPageNumber; i++) {
      buttonLabels.push(i);
    }
  } else if (lastPageNumber > 7 && currentPage < 5) {
    buttonLabels = [2, 3, 4, 5, null];
  } else if (currentPage >= 5 && lastPageNumber - currentPage > 3) {
    buttonLabels = [null, currentPage - 1, currentPage, currentPage + 1, null];
  } else if (lastPageNumber - currentPage <= 3) {
    buttonLabels = [
      null,
      lastPageNumber - 4,
      lastPageNumber - 3,
      lastPageNumber - 2,
      lastPageNumber - 1,
    ];
  }

  return buttonLabels;
};

const SortableTable = ({
  className,
  bordered,
  striped,
  condensed,
  caption,
  head,
  hover,
  foot,
  children,
  tableData,
  columnData,
  ...props
}) => {
  const selectedRowId = window.location.href.split('--')[1];

  const [sortedTableData, setSortedTableData] = useState(
    sortColumn(columnData[0], tableData, '', true).map(row => row.rowId),
  );
  const [indexSize, setIndexSize] = useState(50);
  const [firstIndex, setFirstIndex] = useState(
    selectedRowId ? snapToIndex(sortedTableData, selectedRowId, indexSize) : 0,
  );
  const [secondIndex, setSecondIndex] = useState(firstIndex + indexSize);
  const [searchValue, setSearchValue] = useState('');
  const [sortedColumn, setSortedColumn] = useState('');
  const [sortOrderAsc, setSortOrderAsc] = useState(true);
  const [filteredTableData, setFilteredTableData] = useState(tableData);
  const [selectedPage, setSelectedPage] = useState((firstIndex + indexSize) / indexSize);
  const [tableDataLength, setTableDataLength] = useState(tableData.length);

  useEffect(() => {
    if (selectedRowId) {
      const startIndex = snapToIndex(sortedTableData, selectedRowId, indexSize);
      const currentPage = (startIndex + indexSize) / indexSize;
      setFirstIndex(startIndex);
      setSecondIndex(startIndex + indexSize);
      setSelectedPage(currentPage);
    }
  }, [selectedRowId]);

  useEffect(() => {
    if (tableDataLength != tableData.length) {
      setSortedTableData(
        sortColumn(sortedColumn, tableData, '', true).map(row => row.rowId),
      );
      setFilteredTableData(tableData);
      setTableDataLength(tableData.length);
      setSearchValue('');
    }
  }, [tableData]);

  const incrementIndex = () => {
    setSelectedPage(selectedPage + 1);
    setFirstIndex(secondIndex);
    setSecondIndex(secondIndex + indexSize);
  };

  const decrementIndex = () => {
    setSelectedPage(selectedPage - 1);
    setFirstIndex(firstIndex - indexSize);
    setSecondIndex(firstIndex);
  };

  const pageButtonClick = (startIndex, endIndex, pageNumber) => {
    setSelectedPage(pageNumber);
    setFirstIndex(startIndex);
    setSecondIndex(endIndex);
  };

  const handleSelectChange = ({ target }) => {
    const { value } = target;
    setIndexSize(Number(value));
    setFirstIndex(0);
    setSecondIndex(Number(value));
    setSelectedPage(1);
  };

  const handleSortColumn = questionId => {
    if (!sortOrderAsc || sortedColumn != questionId) {
      setSortOrderAsc(true);
    } else if (sortOrderAsc) {
      setSortOrderAsc(false);
    }
    sortColumn(questionId, filteredTableData, sortedColumn, sortOrderAsc);
    setSortedColumn(questionId);
    setSortedTableData(filteredTableData.map(item => item.rowId));
    setFirstIndex(0);
    setSecondIndex(indexSize);
    setSelectedPage(1);
  };

  const searchForText = text => {
    let dataToSort;
    if (searchValue.length > text.length) {
      dataToSort = tableData;
    } else {
      dataToSort = filteredTableData;
    }
    const filteredData = dataToSort.filter(row => {
      let check;
      columnData.forEach(questionId => {
        if (row[questionId] && typeof row[questionId] == 'object') {
          if (!check) {
            check = row[questionId]
              .map(file => file.name)
              .join('')
              .toUpperCase()
              .includes(text.toUpperCase());
          }
        } else if (typeof row[questionId] == 'boolean') {
          if (!check) {
            if (row[questionId]) {
              check = 'YES'.includes(text.toUpperCase());
            } else {
              check = 'NO'.includes(text.toUpperCase());
            }
          }
        } else if (row[questionId]) {
          if (!check) {
            check = row[questionId].toUpperCase().includes(text.toUpperCase());
          }
        }
      });
      if (check) {
        return true;
      }
    });
    setFilteredTableData(filteredData);
    setSortedTableData(filteredData.map(item => item.rowId));
  };

  const handleInputChange = ({ target }) => {
    const { value } = target;
    searchForText(value);
    setSearchValue(value);
    setFirstIndex(0);
    setSecondIndex(indexSize);
    setSelectedPage(1);
  };

  const classes = classNames(className, 'table', {
    'table-bordered': bordered,
    'table-hover': hover,
    'table-striped': striped,
    'table-condensed': condensed,
  });

  return (
    <Block className="sortable-datatable">
      <Block className="datatable-filter-options">
        <Paragraph className="filter-options-text">Show </Paragraph>
        <select
          className="sortable-datatable-select"
          value={indexSize}
          onChange={handleSelectChange}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
        </select>
        <Paragraph className="filter-options-text"> entries</Paragraph>
        <Paragraph className="filter-options-text filter-options-search">
          Search:
        </Paragraph>
        <input
          className="filter-options-input"
          value={searchValue}
          onChange={handleInputChange}
        />
      </Block>
      <table {...props} className={`${classes} sortable-datatable-table`}>
        {head && (
          <thead>{head(columnData, handleSortColumn, sortOrderAsc, sortedColumn)}</thead>
        )}
        {children && (
          <tbody>{children(sortedTableData.slice(firstIndex, secondIndex))}</tbody>
        )}
        {foot && <tfoot>{foot}</tfoot>}
      </table>
      <Block className="page-data-block">
        <Paragraph className="page-select-text">{`Showing ${firstIndex + 1} to ${
          secondIndex < sortedTableData.length ? secondIndex : sortedTableData.length
        } ${
          filteredTableData.length == tableData.length
            ? `of ${tableData.length} entries`
            : `of ${filteredTableData.length} filtered from ${tableData.length} entries`
        }`}</Paragraph>
        <Block className="page-select-block">
          <Button
            className="page-index-button"
            onClick={decrementIndex}
            disabled={firstIndex == 0}
          >
            Previous
          </Button>
          <Button
            className={selectedPage == 1 ? 'page-select-button' : 'page-index-button'}
            onClick={() => pageButtonClick(0, indexSize, 1)}
          >
            {1}
          </Button>
          {createPageButtons(sortedTableData.length, indexSize, secondIndex).map(
            (buttonLabel, index) => (
              <Fragment key={index}>
                {buttonLabel ? (
                  <Button
                    onClick={() => {
                      const endIndex = buttonLabel * indexSize;
                      pageButtonClick(endIndex - indexSize, endIndex, buttonLabel);
                    }}
                    className={
                      selectedPage == buttonLabel
                        ? 'page-select-button'
                        : 'page-index-button'
                    }
                  >
                    {buttonLabel}
                  </Button>
                ) : (
                  <span>...</span>
                )}
              </Fragment>
            ),
          )}
          {Math.ceil(sortedTableData.length / indexSize) > 1 && (
            <Button
              className={
                selectedPage == Math.ceil(sortedTableData.length / indexSize)
                  ? 'page-select-button'
                  : 'page-index-button'
              }
              onClick={() => {
                const endIndex =
                  Math.ceil(sortedTableData.length / indexSize) * indexSize;

                pageButtonClick(
                  endIndex - indexSize,
                  endIndex,
                  Math.ceil(sortedTableData.length / indexSize),
                );
              }}
            >
              {Math.ceil(sortedTableData.length / indexSize)}
            </Button>
          )}
          <Button
            className="page-index-button"
            onClick={incrementIndex}
            disabled={secondIndex > sortedTableData.length}
          >
            Next
          </Button>
        </Block>
      </Block>
    </Block>
  );
};

SortableTable.propTypes = {
  className: PropTypes.string,
  bordered: PropTypes.bool,
  striped: PropTypes.bool,
  condensed: PropTypes.bool,
  caption: PropTypes.string,
  head: PropTypes.any,
  hover: PropTypes.bool,
  foot: PropTypes.node,
  children: PropTypes.any,
};

export default SortableTable;
