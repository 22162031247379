import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { fromPlanApp } from 'sow/store/selectors';
import WorksheetStatusIcon from 'sow/components/organisms/WorksheetStatusIcon';

const mapStateToProps = (state, props) => ({
  answers: fromPlanApp.worksheetAnswers(state, props),
  isNotApplicable: fromPlanApp.isWorksheetNotApplicable(state, props),
  isStateInitialApplication: fromPlanApp.isStateInitialApplication(state, props),
});

const WorksheetStatusIconContainer = props => <WorksheetStatusIcon {...props} />;

WorksheetStatusIconContainer.propTypes = {
  isNotApplicable: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(WorksheetStatusIconContainer);
