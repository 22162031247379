import React from 'react';

import {
  deployBranch,
  isDeployLocalDev,
  isDeployQA,
  isDeployAlpha,
  isDeployBeta,
  isDeployProd,
} from 'sow/config';

// import Block from 'sow/components/atoms/Block';

const BranchRibbon = () => {
  // do NOT show ribbon on production site
  if (isDeployProd) return null;

  let color = 'red';
  let title = deployBranch;

  if (isDeployLocalDev) color = 'blue';
  if (isDeployAlpha) color = 'purple';
  if (isDeployBeta) color = 'blue';
  if (isDeployQA) color = 'magenta';

  /* Always return null until production deploy is fixed
  return (
    <Block className={`cr cr-top cr-left cr-sticky cr-${color}`}>
    {R.toUpper(title)}
    </Block>
  );
  */
  return null;
};

export default BranchRibbon;
