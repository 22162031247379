import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import Spacer from 'sow/components/atoms/Spacer';
import InfoPanel from 'sow/components/organisms/InfoPanel';
import QualificationChecklistForm from 'sow/containers/planApp/QualificationChecklistForm';
import ChangeRequestManager from 'sow/containers/planApp/ChangeRequestManager';

const OrgPlanAppChecklistPage = ({ 
    questions, 
    answers,
    isStateUnderReview
}) => {
  return (
    <Fragment>
      {isStateUnderReview && 
        <ChangeRequestManager title="Changes for activities checklist" showPlanChecklist />
      }

      <Spacer vertical={20} />

      <InfoPanel
        text={`
          Please select all checkboxes that apply to your operation. By selecting the applicable checkboxes,
          WFCF Organic will automatically populate the forms necessary to create your Organic System Plan.
          These completed documents will collectively serve as your application for certification.`}
      />

      <QualificationChecklistForm questions={questions} answers={answers} />
    </Fragment>
  );
};

OrgPlanAppChecklistPage.propTypes = {
  /** Array of qualification questions that can be selected */
  questions: PropTypes.arrayOf(sowTypes.planAppQualificationQuestionType).isRequired,
  /** Array of question uuids that have been selected */
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,
  isStateUnderReview: PropTypes.bool,
};

export default OrgPlanAppChecklistPage;
