import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import UnannouncedInspectionReport from 'sow/components/organisms/UnannouncedInspectionReport';

const mapStateToProps = state => ({ ...state.inspectionReport });

class UnannouncedInspectionReportContainer extends Component {
  render() {
    if (this.props.unannouncedReportGenerated) {
      return <UnannouncedInspectionReport {...this.props} />;
    }
    return null;
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, null),
)(UnannouncedInspectionReportContainer);
