import { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { updateOne } from 'sow/actions/aca';

const mapDispatchToProps = {
  updateAca: updateOne,
};

class AcaSettingsPageContainer extends Component {
  constructor(props) {
    super();

    this.state = {
      aca: { ...props.aca },
    };
  }

  handleChange = (field, value) => this.setState(R.assocPath(['aca', field], value));

  handleSubmit = () => {
    this.props.updateAca(this.props.acaId, this.state.aca);
  };

  render() {
    const { aca } = this.state;
    const { children } = this.props;

    return children({
      aca,
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
    });
  }
}

AcaSettingsPageContainer.propTypes = {
  // Passed props
  children: PropTypes.func.isRequired,
  aca: PropTypes.object,
  acaId: PropTypes.number,

  // Connected props
  updateAca: PropTypes.func,
};

export default R.compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(AcaSettingsPageContainer);
