import * as R from 'ramda';
import { createSelector } from 'reselect';
import * as ospQQReducer from 'sow/reducers/ospQualificationQuestions';
// NOTE: In core selectors we only import other core selectors that have a parent
//       relationship to the core selector your importing to. This is to stop
//       circular dependencies. For example ospApplication can import
//       organization core selectors but not worksheets/worksheetAnswers core
//       selectors. Instead we would import ospApplication core selectors into
//       worksheet/worksheetAnswers core selectors and compose selectors there.
//       For higher level selectors that compose multiple core selectors you
//       should create them in files directly inside soworganic/selectors/
import * as ospDefSelect from 'sow/selectors/core/ospDefinition';
// import * as ospAppSelect from 'sow/selectors/core/ospApplication';

export const ospQualificationQuestionsMap = createSelector(
  [ospQQReducer.getByIdState],
  R.identity,
);

export const ospQualificationQuestionIdsForCurrentOspDef = createSelector(
  [ospQQReducer.getListByOspDefIdState, ospDefSelect.currentOspDefId],
  ospQQReducer.getIdsForOspDefId,
);

export const ospQualificationQuestionsForCurrentOspDef = createSelector(
  [ospQualificationQuestionIdsForCurrentOspDef, ospQualificationQuestionsMap],
  R.pick,
);
