import { all } from 'redux-saga/effects';

import aca from 'sow/sagas/aca';
import acaAdmin from 'sow/sagas/acaAdmin';
import analytics from 'sow/sagas/analytics';
import api from 'sow/sagas/api';
import auth from 'sow/sagas/auth';
import certificationDocs from 'sow/sagas/certificationDocs';
// import orgExportFiles from 'sow/sagas/orgExportFiles';
import gis from 'sow/sagas/gis';
import orgRegistration from 'sow/sagas/orgRegistration';
import orgUser from 'sow/sagas/orgUser';
import organization from 'sow/sagas/org';
import ospApplication from 'sow/sagas/ospApplication';
import ospDefinition from 'sow/sagas/ospDefinition';
import products from 'sow/sagas/products';
import profile from 'sow/sagas/profile';
import registration from 'sow/sagas/registration';
import resetPassword from 'sow/sagas/resetPassword';
import shell from 'sow/sagas/shell';
import userManagement from 'sow/sagas/userManagement';
import certificationType from 'sow/sagas/certificationType';
import consultantCompany from 'sow/sagas/consultantCompany';
import organizationHistory from 'sow/sagas/organizationHistory';
import organizationType from 'sow/sagas/organizationType';
import announcementType from 'sow/sagas/announcementType';
import announcement from 'sow/sagas/announcement';
import accessType from 'sow/sagas/accessType';
import priorityType from 'sow/sagas/priorityType';
import item from 'sow/sagas/item';
import itemSubtype from 'sow/sagas/itemSubtype';
import itemType from 'sow/sagas/itemType';
import itemVersion from 'sow/sagas/itemVersion';
import itemVersionPicklist from 'sow/sagas/itemVersionPicklist';
import itemVersionSection from 'sow/sagas/itemVersionSection';
import itemAttributeType from 'sow/sagas/itemAttributeType';
import itemSectionType from 'sow/sagas/itemSectionType';
import actionGroup from 'sow/sagas/actionGroup';
import inspectionYear from 'sow/sagas/inspectionYear';
import inspection from 'sow/sagas/inspection';
import sample from 'sow/sagas/sample';

// redux-modules
import { sagas as resource } from 'sow/store/modules/resource';
import bulkDownload from 'sow/store/modules/bulkDownload/sagas';

// sagas using resource/entities redux-modules
import inspector from 'sow/sagas/inspector';

// single entry point to start all Sagas at once
export default function*(services = {}) {
  try {
    yield all([
      aca(),
      userManagement(),
      api(),
      shell(),
      acaAdmin(),
      analytics(),
      certificationDocs(),
      // orgExportFiles(),
      orgRegistration(),
      organization(),
      orgUser(),
      ospApplication(),
      ospDefinition(),
      products(),
      registration(),
      resetPassword(),
      gis(),
      profile(),
      certificationType(),
      consultantCompany(),
      organizationHistory(),
      organizationType(),
      announcementType(),
      announcement(),
      accessType(),
      priorityType(),
      item(),
      itemSubtype(),
      itemType(),
      itemVersion(),
      itemVersionPicklist(),
      itemVersionSection(),
      itemAttributeType(),
      itemSectionType(),
      actionGroup(),
      inspectionYear(),
      inspection(),
      sample(),

      // using new redux-modules and services
      auth(services),
      inspector(services),

      // redux-modules
      resource(services),
      bulkDownload(services),
    ]);
  } catch (error) {
    console.error('ROOT SAGA ERROR!!!', error);
    console.trace();
  }
}
