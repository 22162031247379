import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import actions from 'sow/actions/pure';
import * as currentAca from 'sow/selectors/currentAca';
import { acaType, acaIdType } from 'sow/types';
import { acaRoute } from 'sow/routes';
import Spinner from 'sow/components/atoms/Spinner';
import StandardLayout from 'sow/components/templates/StandardLayout';
import Link from 'sow/components/atoms/Link';

const mapStateToProps = (state, props) => ({
  loadFailed: currentAca.isLoadFail(state, props),
  loading: currentAca.isLoading(state, props),
  shouldLoad: currentAca.shouldLoad(state, props),
  aca: currentAca.aca(state, props),
  acaId: currentAca.acaId(state, props),
  acaName: currentAca.name(state, props),
});

const mapDispatchToProps = {
  loadAca: actions.aca.loadOne,
};

class AcaPageTemplateContainer extends Component {
  componentDidMount() {
    this.loadData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.loadData(nextProps);

    if (!this.props.loadFailed && nextProps.loadFailed) {
      throw new Error('Error loading ACA.');
    }
  }

  loadData = ({ acaId, shouldLoad, loadAca }) => {
    if (shouldLoad && acaId) loadAca(acaId);
  };

  render() {
    const { title, loading, aca, acaId, acaName, children } = this.props;

    const showSpinner = loading || R.isNil(aca);
    const breadcrumb = [[acaName, acaRoute(acaId)]];

    return (
      <StandardLayout title={title} breadcrumb={breadcrumb}>
        {showSpinner ? <Spinner /> : children}
      </StandardLayout>
    );
  }
}

AcaPageTemplateContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  loadFailed: PropTypes.bool,
  loaded: PropTypes.bool,
  loading: PropTypes.bool,
  aca: acaType,
  acaId: acaIdType,
  acaName: PropTypes.string,
  shouldLoad: PropTypes.bool,
};

AcaPageTemplateContainer.defaultProps = {
  aca: null,
  acaId: null,
  acaName: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AcaPageTemplateContainer);
