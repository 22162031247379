import React from 'react';

import * as sowTypes from 'sow/types';
import Button from 'sow/components/atoms/Button';
import Form from 'sow/components/atoms/Form';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';
import AcaSettingsForm from 'sow/containers/AcaSettingsForm';
import AcaForm from 'sow/components/organisms/AcaForm';

const AcaSettingsGeneralPage = ({ aca, acaId }) => (
  <AcaSettingsForm aca={aca} acaId={acaId}>
    {({ aca: formAca, onChange, onSubmit }) => (
      <Widget>
        <WidgetBody>
          <Form onSubmit={onSubmit}>
            <Widget>
              <WidgetHeading>Certifier Details</WidgetHeading>
              <WidgetBody>
                <AcaForm aca={formAca} onChange={onChange} />
              </WidgetBody>
              <WidgetFooter textRight>
                <Button type="submit" primary>
                  Save Changes
                </Button>
              </WidgetFooter>
            </Widget>
          </Form>
        </WidgetBody>
      </Widget>
    )}
  </AcaSettingsForm>
);

AcaSettingsGeneralPage.propTypes = {
  aca: sowTypes.acaType.isRequired,
  acaId: sowTypes.acaIdType.isRequired,
};

export default AcaSettingsGeneralPage;
