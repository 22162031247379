import React from 'react';
import * as R from 'ramda';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as currentUser from 'sow/selectors/currentUser';
import {
  adminRoute,
  acaRoute,
  inspectorRoute,
  errorForbiddenRoute,
  orgRoute,
  clientRoute,
  setupOrgRoute,
  workflowManagerRoute,
  certificationSpecialistRoute,
  errorInactiveAccountRoute,
} from 'sow/routes';

const mapStateToProps = (state, props) => ({
  isAuthenticated: currentUser.isAuthenticated(state, props),
  isSuperAdmin: currentUser.isSuperAdmin(state, props),
  isInspector: currentUser.isInspector(state, props),
  isAcaAdmin: currentUser.isAcaAdmin(state, props),
  isOrgAdmin: currentUser.isOrgAdmin(state, props),
  isWorkflowManager: currentUser.isWorkflowManager(state, props),
  isCertificationSpecialist: currentUser.isCertificationSpecialist(state, props),
  acaId: currentUser.acaId(state, props),
  orgId: currentUser.orgId(state, props),
  activeOrgs: currentUser.activeOrgs(state, props),
});

const DashboardRedirectContainer = ({
  isSuperAdmin,
  isInspector,
  isAcaAdmin,
  isOrgAdmin,
  isWorkflowManager,
  isCertificationSpecialist,
  acaId,
  orgId,
  activeOrgs,
}) => {
  if (isSuperAdmin) return <Redirect to={adminRoute()} />;
  if (isInspector) return <Redirect to={inspectorRoute()} />;
  if (isWorkflowManager) return <Redirect to={workflowManagerRoute(acaId)} />;
  if (isCertificationSpecialist)
    return <Redirect to={certificationSpecialistRoute(acaId)} />;
  // if (isAcaAdmin) return <Redirect to={acaRoute(acaId)} />;
  if (isOrgAdmin) {
    if (R.length(activeOrgs) > 1) return <Redirect to={clientRoute()} />;
    else if (R.length(activeOrgs) === 1)
      return <Redirect to={orgRoute(activeOrgs[0].org_id)} />;
    else if (!R.isNil(orgId)) return <Redirect to={orgRoute(orgId)} />;
    return <Redirect to={errorInactiveAccountRoute()} />;
  }
  return <Redirect to={errorForbiddenRoute()} />;
};

DashboardRedirectContainer.propTypes = {
  isSuperAdmin: PropTypes.bool.isRequired,
  isInspector: PropTypes.bool.isRequired,
  isAcaAdmin: PropTypes.bool.isRequired,
  isOrgAdmin: PropTypes.bool.isRequired,
  isWorkflowManager: PropTypes.bool.isRequired,
  isCertificationSpecialist: PropTypes.bool.isRequired,
  acaId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  orgId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

DashboardRedirectContainer.defaultProps = {
  acaId: null,
  orgId: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(DashboardRedirectContainer);
