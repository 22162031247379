import React from 'react';

import './SpecialistAssignBlock.module.css';
import { userTypeDropdownOptions, userTypeId } from 'sow/constants/userType';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Button from 'sow/components/atoms/Button';
import SelectFormGroup from 'sow/components/molecules/SelectFormGroup';
import Clearfix from 'sow/components/atoms/Clearfix';

const SpecialistAssignBlock = ({
  dropDownHandler,
  onAssign,
  certificationSpecialistList,
  dashboardList,
  csForm,
}) => {
  const specialistList = [];
  const orgList = [];
  if (certificationSpecialistList.length > 0) {
    certificationSpecialistList.map(specialist => {
      specialistList.push({
        label: specialist.name,
        value: specialist.id,
      });
    });
  }

  if (dashboardList.length > 0) {
    dashboardList.map(ospApp => {
      orgList.push({
        label: ospApp.organization_name,
        value: ospApp.organization_id,
      });
    });
  }

  return (
    <div className="specialistAssignBlock">
      <Widget className="workflowManagerWidget">
        <WidgetHeading>Add a CS to an Organization</WidgetHeading>
        <WidgetBody>
          <SelectFormGroup
            fieldName="orgDropDown"
            value={csForm.orgDropDown}
            onChange={dropDownHandler}
            options={orgList}
            className="cvsDropDown"
            placeholder="Select Organization"
          />
          <SelectFormGroup
            fieldName="specialistTypeDropDown"
            value={csForm.specialistTypeDropDown}
            onChange={dropDownHandler}
            options={userTypeDropdownOptions.specialist}
            disabled={!csForm.orgDropDown}
            className="cvsDropDown"
            placeholder="Select CS Type"
          />
          <SelectFormGroup
            fieldName="specialistDropDown"
            value={csForm.specialistDropDown}
            onChange={dropDownHandler}
            options={specialistList}
            disabled={!csForm.orgDropDown || !csForm.specialistTypeDropDown}
            className="cvsDropDown"
            placeholder="Select CS"
          />
          <Button
            className="assignButton"
            primary
            onClick={() =>
              onAssign(
                csForm.orgDropDown,
                csForm.specialistDropDown,
                userTypeId.specialist,
                csForm.specialistTypeDropDown,
              )
            }
            disabled={
              !csForm.orgDropDown ||
              !csForm.specialistTypeDropDown ||
              !csForm.specialistDropDown
            }
          >
            Assign
          </Button>
          <Clearfix />
        </WidgetBody>
      </Widget>
    </div>
  );
};

export default SpecialistAssignBlock;
