import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import DescriptiveText from './DescriptiveText';
import ProductIngredient from './ProductIngredient';

export const fieldType = R.compose(
  // R.toLower,
  R.pathOr('', ['question', 'field_type']),
);

export const OspAppEditorQuestionField = ({
  orgId,
  ospId,
  worksheetId,
  question,
  answer,
  handleChange,
  disabled = false,
}) => {
  const fieldProps = {
    orgId,
    ospId,
    worksheetId,
    question,
    answer,
    handleChange,
    disabled,
  };

  switch (fieldType(fieldProps)) {
    case 'descriptive_text':
      return <DescriptiveText {...fieldProps} />;

    case 'product_ingredient':
      return <ProductIngredient {...fieldProps} />;

    default:
      // This shouldn't ever render, but just in case...
      return <div>Placeholder for {question.field_type}</div>;
  }
};

OspAppEditorQuestionField.propTypes = {
  orgId: PropTypes.number,
  ospId: PropTypes.number,
  worksheetId: PropTypes.string,
  question: PropTypes.object,
  answer: PropTypes.any,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default OspAppEditorQuestionField;
