import * as R from 'ramda';
import { createSelector } from 'reselect';
import { worksheetAnswers, worksheetChangeList, worksheetChange } from './core';

// --
// -- Predicate Functions
// --

// --
// -- Filter Functions
// --

const filterChangeListWorksheetAnswers = R.filter(
  R.where({
    context: R.equals('main'),
    type: R.equals('worksheet_answer'),
  }),
);

const filterChangeListWorksheetMatrixRowAnswers = R.filter(
  R.where({
    context: R.equals('main'),
    type: R.equals('matrix_row_answer'),
  }),
);

const reduceMatrixChanges = valueType =>
  R.reduce(
    (acc, elem) =>
      R.assocPath(
        [elem.matrixRowId, 'values', elem.questionId],
        R.prop(valueType, elem),
        acc,
      ),
    {},
  );

const addPlaceholderMatrixRows = answersObj => {
  if (!R.path(['answers', 'matrixRows'], answersObj))
    return R.assocPath(['answers', 'matrixRows'], {}, answersObj);
  else return answersObj;
};

// --
// -- Selector Functions
// --

/**
 * Returns worksheet answers augmented with change old values
 */
export const formWorksheetInitialValuesWorksheetAnswers = createSelector(
  [worksheetAnswers, worksheetChangeList, worksheetChange],
  (wsAnswers, wsChangeList, wsChange) => {
    let mergedVals = wsAnswers;
    if (wsChange) {
      mergedVals = R.mergeDeepLeft(wsAnswers, wsChange.old);
    }

    const mergedAnswerValues = R.pipe(
      filterChangeListWorksheetAnswers,
      R.map(change => [change.questionId, change.old]),
      R.fromPairs,
      R.flip(R.merge),
      R.over(R.lensPath(['answers', 'values']), R.__, mergedVals),
      addPlaceholderMatrixRows,
    )(wsChangeList);

    const mergedMatrixAnswerValues = R.pipe(
      filterChangeListWorksheetMatrixRowAnswers,
      reduceMatrixChanges('old'),
      R.mergeDeepLeft,
      R.over(R.lensPath(['answers', 'matrixRows']), R.__, mergedAnswerValues),
    )(wsChangeList);

    return mergedMatrixAnswerValues;
  },
);

/**
 * Returns worksheet answers changes object for formik initial values.
 */
export const formWorksheetInitialValuesWorksheetAnswersChanges = createSelector(
  [worksheetChangeList, worksheetChange],
  (wsChangeList, wsChange) => {
    const changeAnswers = {};

    changeAnswers.isNotApplicable = R.pathOr(false, ['new', 'isNotApplicable'], wsChange);

    changeAnswers.values = R.pipe(
      filterChangeListWorksheetAnswers,
      R.map(change => [change.questionId, change.new]),
      R.fromPairs,
    )(wsChangeList);

    changeAnswers.matrixRows = R.pipe(
      filterChangeListWorksheetMatrixRowAnswers,
      reduceMatrixChanges('new'),
    )(wsChangeList);

    return changeAnswers;
  },
);

/**
 * Returns initial values for worksheet form.
 */
export const formWorksheetInitialValues = createSelector(
  [
    formWorksheetInitialValuesWorksheetAnswers,
    formWorksheetInitialValuesWorksheetAnswersChanges,
  ],
  (worksheetAnswers, worksheetAnswersChanges) => {
    return {
      worksheetAnswers,
      worksheetAnswersChanges,
    };
  },
);
