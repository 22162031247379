import * as R from 'ramda';
import { createSelector } from 'reselect';

import * as acaReducers from 'sow/reducers/acas';
// NOTE: Unlike all other selectors, we DO import this module's child selector
//       module ORG. Do NOT import this module in 'sow/selectors/core/aca'!
import * as orgSelect from 'sow/selectors/core/organization';

export const acaState = state => state.acas;

export const acaIdRouteParam = (state, props) =>
  R.path(['match', 'params', 'acaId'], props);

export const acaSlugRouteParam = (state, props) =>
  R.path(['match', 'params', 'acaSlug'], props);

export const isLoading = createSelector(
  [acaState],
  acaReducers.getIsLoading,
);

export const acaList = createSelector(
  [acaState],
  acaReducers.getAcaList,
);

export const currentAcaId = createSelector(
  [acaIdRouteParam, orgSelect.currentOrgAcaId],
  (routeAcaId, orgAcaId) => {
    const acaId = routeAcaId || orgAcaId;
    return acaId ? ~~acaId : null;
  },
);

export const acaDetailMap = createSelector(
  [acaState],
  R.prop('detailById'),
);

export const currentAcaDetail = createSelector(
  [currentAcaId, acaDetailMap],
  R.propOr(null),
);

export const isOneLoadingAcaIds = createSelector(
  [acaState],
  R.path(['isLoadingOne', 'loading']),
);

export const isOneLoadingDoneAcaIds = createSelector(
  [acaState],
  R.path(['isLoadingOne', 'loaded']),
);

export const isOneLoadingFailAcaIds = createSelector(
  [acaState],
  R.path(['isLoadingOne', 'failed']),
);

export const isLoadingCurrentAca = createSelector(
  [currentAcaId, isOneLoadingAcaIds],
  R.contains,
);

export const isLoadFailedCurrentAca = createSelector(
  [currentAcaId, isOneLoadingFailAcaIds],
  R.contains,
);

// NOTE: we may want to add invalidate and what not in future
// export const isLoadedCurrentAca = createSelector(
//   [currentAcaId, isOneLoadingDoneAcaIds],
//   R.contains
// );
export const isLoadedCurrentAca = createSelector(
  [currentAcaId, currentAcaDetail, isOneLoadingDoneAcaIds],
  (acaId, acaDetail, isLoadedAcaIds) => {
    return R.contains(acaId, isLoadedAcaIds) && acaDetail;
  },
);

export const shouldLoadCurrentAca = createSelector(
  [isLoadedCurrentAca, isLoadingCurrentAca, isLoadFailedCurrentAca],
  (loaded, loading, failed) => {
    return !loaded && !loading && !failed;
  },
);

export const currentAcaSlug = createSelector(
  [acaSlugRouteParam],
  R.identity,
);

export const currentAca = createSelector(
  [acaState, currentAcaId],
  (state, acaId) => acaReducers.getAca(state, acaId),
);

export const currentAcaName = createSelector(
  [currentAca],
  R.path(['name']),
);
