import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { desaturate, darken, lighten } from 'polished';

import * as sowTypes from 'sow/types';
import Atom from 'sow/components/atoms/Atom';
import Block from 'sow/components/atoms/Block';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Timestamp from 'sow/components/molecules/Timestamp';

const CommentNotificationIcon = styled(Block)`
  display: inline-block;
  background-color: ${props => props.theme.cr.comment};
  height: 24px;
  width: 24px;
  border-radius: 12px;
  text-align: center;
  font-size: 10pt;
  color: white;
  padding-top: ${({ showComments }) => (showComments ? '2px' : '3px')};
`;

const CommentToggleText = styled(Block)`
  display: inline-block;
  margin-left: 5px;
  color: ${({ theme, changeState }) =>
    R.pipe(
      desaturate(0.8),
      lighten(0.2),
    )(theme.cr[changeState])};
  font-size: 10pt;
`;

const CommentCountText = styled(Atom)`
  color: ${({ theme, changeState }) =>
    R.pipe(
      desaturate(0.8),
      darken(0),
    )(theme.cr[changeState])};
  font-size: 11pt;
`;

const CommentWrapper = styled(Block)`
  margin: 10px 10px 0 0px;
  border-top: 1px solid ${props => props.theme.cr.comment};
  border-left: 5px solid ${props => props.theme.cr.comment};
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
`;

const CommentAuthor = styled(Block)`
  font-weight: 700;
  font-size: 11pt;
`;

const CommentTimestamp = styled(Block)`
  color: #777777;
  font-size: 9pt;
`;

const CommentText = styled(Block)`
  font-size: 12pt;
`;

const CommentDetailColumn = styled(Column)``;

const ChangeListItemComments = ({
  changeState,
  comments,
  showComments,
  toggleComments,
}) => (
  <Block>
    <Block onClick={toggleComments} style={{ cursor: 'pointer' }}>
      <CommentNotificationIcon showComments={showComments}>
        <FontAwesome
          variant={showComments ? 'far' : 'fas'}
          icon={showComments ? 'envelope-open' : 'envelope'}
        />
      </CommentNotificationIcon>
      <CommentToggleText changeState={changeState}>
        <CommentCountText changeState={changeState}>
          {comments.length} unread comment
          {comments.length > 1 && 's'}
        </CommentCountText>{' '}
        [{showComments ? 'hide' : 'show'}]
      </CommentToggleText>
    </Block>

    {showComments && (
      <Block>
        {comments.map(comment => (
          <CommentWrapper key={comment.id}>
            <Row>
              <CommentDetailColumn md={2}>
                <CommentAuthor>{comment.user.name}</CommentAuthor>
                <CommentTimestamp>
                  wrote <Timestamp date={comment.insertedAt} />
                </CommentTimestamp>
              </CommentDetailColumn>
              <Column md={10}>
                <CommentText>{comment.text}</CommentText>
              </Column>
            </Row>
          </CommentWrapper>
        ))}
      </Block>
    )}
  </Block>
);

ChangeListItemComments.propTypes = {
  comments: PropTypes.arrayOf(sowTypes.planAppChangeCommentType).isRequired,
  showComments: PropTypes.bool.isRequired,
  toggleComments: PropTypes.func.isRequired,
  changeState: sowTypes.planAppChangeStateType.isRequired,
};

export default ChangeListItemComments;
