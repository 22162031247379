import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';

import { urlSubtypeId } from 'sow/constants/userType';
import { fetchDashboardList } from 'sow/actions/orgWorkflow';
import { getFilter } from 'sow/utils/dashboardFilterStorage';
import * as currentAca from 'sow/selectors/currentAca';

import SpecialistDashboardSearch from 'sow/components/organisms/SpecialistDashboardSearch';

const cleanFilters = filters => {
  let cleanedFilters = {};
  for (let key in filters) {
    switch (R.type(filters[key])) {
      case 'Object':
        const cleanedObj = cleanRangeFilter(filters[key]);
        if (R.isEmpty(cleanedObj)) {
          break;
        } else {
          cleanedFilters = { ...cleanedFilters, ...cleanedObj };
        }
        break;
      case 'String':
      case 'Number':
        if (R.isEmpty(filters[key])) {
          break;
        } else {
          cleanedFilters[key] = filters[key];
          break;
        }
      case 'Boolean':
        if (filters[key]) cleanedFilters[key] = filters[key];
        break;
      case 'Array':
        cleanedFilters[key] = cleanMultiFilter(filters[key]);
        break;
      default:
        break;
    }
  }
  return cleanedFilters;
};

const cleanRangeFilter = rangeObj => {
  const cleanObj = {};
  for (let key in rangeObj) {
    switch (R.isEmpty(rangeObj[key])) {
      case true:
        break;
      default:
        cleanObj[key] = rangeObj[key];
        break;
    }
  }
  return cleanObj;
};

const cleanMultiFilter = list =>
  R.map(filter => {
    if (R.has('value', filter)) {
      return filter.value;
    } else {
      return filter;
    }
  }, list);

const emptyInitialValues = {
  anniversary_date_range: {
    anniversary_date_start: '',
    anniversary_date_end: '',
  },
  inspection_date_range: {
    inspection_date_start: '',
    inspection_date_end: '',
  },
  hard_copy: false,
};

const mapStateToProps = (state, props) => ({
  acaId: currentAca.acaId(state, props),
  filterOptions: state.dashboard.filterOptions,
  fetchingFilters: !R.has('filterOptions', state.dashboard),
  filter: getFilter(),
  initialSearchComplete: state.dashboard.initialSearchComplete,
});

const mapDispatchToProps = { fetchDashboardList };

class SpecialistDashboardSearchContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValues: R.clone(emptyInitialValues),
      showFilters: false,
    };
  }

  componentDidMount() {
    const urlFilters = queryString.parse(this.props.location.search, {
      arrayFormat: 'comma',
    });
    this.setInitialValues(urlFilters);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.clientFilter !== prevProps.match.params.clientFilter) {
      if (!R.isEmpty(prevProps.location.search)) {
        this.props.history.push(prevProps.location.search);
      }
    }
  }

  setInitialValues(urlFilters) {
    const { initialValues } = this.state;

    if (!R.isEmpty(urlFilters)) {
      for (let key in urlFilters) {
        switch (key) {
          case 'org_id':
            initialValues[key] = Number(urlFilters[key]);
            break;
          case 'inspection_date_start':
          case 'inspection_date_end':
            initialValues.inspection_date_range[key] = urlFilters[key];
            break;
          case 'anniversary_date_start':
          case 'anniversary_date_end':
            initialValues.anniversary_date_range[key] = urlFilters[key];
            break;
          default:
            if (R.type(urlFilters[key]) === 'String') {
              initialValues[key] = [urlFilters[key]];
              break;
            } else {
              initialValues[key] = urlFilters[key];
              break;
            }
        }
      }
      this.setState({ initialValues: initialValues, showFilters: true }, () => {
        const cleanedFilters = cleanFilters(initialValues);

        this.loadDashboard(cleanedFilters);
      });
    } else {
      this.setState({ initialValues: R.clone(emptyInitialValues), showFilters: false });
      this.loadDashboard({});
    }
  }

  toggleFilters() {
    this.setState({ showFilters: !this.state.showFilters });
  }

  handleSearch(values, formikBag) {
    const cleanedFilters = cleanFilters(values);

    this.props.history.push(
      `?${queryString.stringify(cleanedFilters, { arrayFormat: 'comma' })}`,
    );

    this.loadDashboard(cleanedFilters);
  }

  resetSearch(values, formikBag) {
    this.setState({ initialValues: { ...emptyInitialValues } }, () => {
      this.loadDashboard({});
      this.props.history.push(this.props.location.pathname);
      formikBag.resetForm();
    });
  }

  loadDashboard(searchFilters) {
    if (!R.pathEq(['match', 'params', 'clientFilter'], 'all')(this.props)) {
      searchFilters.type = urlSubtypeId[this.props.match.params.clientFilter];
      this.props.fetchDashboardList(
        this.props.acaId,
        getFilter(),
        queryString.stringify(searchFilters, { arrayFormat: 'bracket' }),
      );
    } else {
      this.props.fetchDashboardList(
        this.props.acaId,
        getFilter(),
        queryString.stringify(searchFilters, { arrayFormat: 'bracket' }),
      );
    }
  }

  render() {
    return (
      <SpecialistDashboardSearch
        toggleFilters={this.toggleFilters.bind(this)}
        resetSearch={this.resetSearch.bind(this)}
        handleSearch={this.handleSearch.bind(this)}
        {...this.state}
        {...this.props}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SpecialistDashboardSearchContainer);
