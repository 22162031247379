import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';

import { announcementTypeRoute } from 'sow/routes';
import * as announcementTypeActions from 'sow/actions/pure/announcementType';

import AnnouncementTypeEdit from 'sow/components/organisms/AnnouncementTypeEdit';

const mapDispatchToProps = {
  loadAnnouncementTypeById: announcementTypeActions.loadAnnouncementTypeById,
  addAnnouncementType: announcementTypeActions.addAnnouncementType,
  updateAnnouncementType: announcementTypeActions.updateAnnouncementType,
  deleteAnnouncementType: announcementTypeActions.deleteAnnouncementType,
  redirect: routerPush,
};

const initialValues = {
  name: '',
  icon: '',
  primary_color: '#000000',
  secondary_color: '#000000',
  sort_order: 1,
  enabled: true,
};

class AnnouncementTypeEditContainer extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = { isNew: false, isLoading: true, initialValues: R.clone(initialValues) };
  }

  componentDidMount() {
    this._isMounted = true;
    const routeId = R.path(['match', 'params', 'id'], this.props);

    if (routeId !== 'new') {
      this.props.loadAnnouncementTypeById(routeId, this.updateState.bind(this));
    } else {
      this.setState({ isNew: true, isLoading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState({ data }) {
    if (this.state.isNew) {
      history.pushState(null, null, `${data.id}`);
    }
    if (this._isMounted) {
      if (!R.isEmpty(data)) {
        this.setState({
          isNew: false,
          isLoading: false,
          announcementTypeId: data.id,
          initialValues: { ...data },
        });
      } else {
        this.redirectToList();
      }
    }
  }

  redirectToList() {
    this.props.redirect(announcementTypeRoute());
  }

  handleSave(values, formikBag) {
    if (this.state.isNew) {
      this.props.addAnnouncementType(values, this.updateState.bind(this));
    } else {
      this.props.updateAnnouncementType(
        this.state.announcementTypeId,
        values,
        this.updateState.bind(this),
      );
    }
  }

  handleDelete() {
    this.props.deleteAnnouncementType(
      this.state.announcementTypeId,
      this.redirectToList.bind(this),
    );
  }

  render() {
    return (
      <AnnouncementTypeEdit
        handleSave={this.handleSave.bind(this)}
        handleDelete={this.handleDelete.bind(this)}
        {...this.state}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(AnnouncementTypeEditContainer);
