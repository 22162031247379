import React from 'react';
import PropTypes from 'prop-types';

const HorizontalRule = ({ ...props }) => <hr {...props} />;

HorizontalRule.propTypes = {
  children: PropTypes.node,
};

export default HorizontalRule;
