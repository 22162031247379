import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import * as sowTypes from 'sow/types';
import { FormattedDatetime } from './style/atoms';

// Icon uses PNG stored as base64 data in CSS generated by:
// - http://fa2png.io/r/font-awesome/comment/?color=ffffff&margin=0&size=128
const CommentIcon = () => <span className="comment-icon" />;

export const CommentLabel = ({ comment }) => (
  <div className="comment-label">
    {/* <CommentIcon /> */}
    <span className="comment-label-text">Comment #{comment.id}</span>
  </div>
);

CommentLabel.propTypes = {
  comment: sowTypes.planAppChangeCommentType.isRequired,
};

export const CommentMarker = ({ comment }) => (
  <div className="comment-marker">
    <CommentIcon />
    <span className="comment-marker-id">#{comment.id}</span>
  </div>
);

CommentMarker.propTypes = {
  comment: sowTypes.planAppChangeCommentType.isRequired,
};

export const CommentRow = ({ comment, colspan = 2 }) => {
  const author = R.path(['user', 'name'], comment);
  const createdAt = R.path(['insertedAt'], comment);
  const text = R.path(['text'], comment);
  return (
    <tr className="comment-row">
      <td className="comment-column" colSpan={colspan}>
        <CommentLabel comment={comment} />
        <div className="comment-details">
          <span className="comment-author">{author}</span>
          <span className="comment-datetime">
            <FormattedDatetime datetime={createdAt} />
          </span>
          <div className="comment-text">{text}</div>
        </div>
      </td>
    </tr>
  );
};
CommentRow.propTypes = {
  comment: sowTypes.planAppChangeCommentType.isRequired,
  colspan: PropTypes.number.isRequired,
};
