import React from 'react';

import InspectorDashboardPageContainer from 'sow/containers/pages/InspectorDashboardPage';
import StandardLayout from 'sow/components/templates/StandardLayout';

const InspectorDashboardPage = () => (
  <StandardLayout title="Inspector Dashboard">
    <InspectorDashboardPageContainer />
  </StandardLayout>
);

export default InspectorDashboardPage;
