import * as R from 'ramda';

import actions from 'sow/actions/pure';

const byId = (state = {}, action) => {
  switch (action.type) {
    case String(actions.ospApp.loadOrgListDone):
      return R.indexBy(R.prop('id'), action.payload.result);

    default:
      return state;
  }
};

export default byId;

export const getIds = state => Object.keys(state);
export const getOspApp = (state, ospAppId) => state[ospAppId];
export const getOspAppList = state => getIds(state).map(id => state[id]);
