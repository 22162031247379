import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as sowTypes from 'sow/types';
import { coerceToInt } from 'sow/utils/selectors';
import * as currentUser from 'sow/selectors/currentUser';
import { fromPlanApp } from 'sow/store/selectors';
import PlanAppExportBuilder from 'sow/containers/planAppExport/Builder';
import PlanAppExportForm from 'sow/components/organisms/PlanAppExportForm';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  initialPlanAppId: fromPlanApp.planAppSummaryIdMostRecent(state, props),
  planAppList: fromPlanApp.planAppSummaryList(state, props),
  showAcaUI: currentUser.showAcaUI(state, props),
});

class PlanAppExportFormContainer extends React.Component {
  state = {
    formValues: {
      planAppId: this.props.initialPlanAppId,
      showMainPlan: false,
      showLocations: false,
      addComments: false,
      addFiles: false,
      addNotes: false,
    },
    buildDownloadUrl: null,
  };

  getPlanAppOptionList() {
    const { planAppList } = this.props;
    return planAppList.map(planApp => ({
      value: planApp.id,
      text: `${planApp.ospDefinition.name} #${planApp.id} (${planApp.state})`,
    }));
  }

  getIsLivePreviewEnabled() {
    return !!this.props.showAcaUI;
  }

  // ==
  // == Form Functions
  // ==

  onChangePlanAppId = event => {
    this.setFormValue('planAppId', coerceToInt(event.target.value));
  };

  setFormValue = (name, value) => {
    this.setState(R.assocPath(['formValues', name], value));
  };

  // ==
  // == Build Export Functions
  // ==

  canGenerateExport() {
    const { orgId, planAppId, showMainPlan, showLocations } = this.getExportConfig();
    if (~~orgId < 1) return false;
    if (~~planAppId < 1) return false;
    if (!showMainPlan && !showLocations) return false;
    return true;
  }

  getExportConfig() {
    const { orgId } = this.props;
    return { orgId, ...this.state.formValues };
  }

  // ==
  // == Render
  // ==

  render() {
    const { formValues } = this.state;
    const exportConfig = this.getExportConfig();

    const localProps = {
      formValues,
      exportConfig,
      isBuildDisabled: !this.canGenerateExport(),
      isLivePreviewEnabled: this.getIsLivePreviewEnabled(),
      planAppOptionList: this.getPlanAppOptionList(),
      onChangePlanAppId: this.onChangePlanAppId,
      setFormValue: this.setFormValue,
    };

    return (
      <PlanAppExportBuilder
        planAppId={formValues.planAppId}
        exportConfig={exportConfig}
        render={builderProps => <PlanAppExportForm {...localProps} {...builderProps} />}
      />
    );
  }
}

PlanAppExportFormContainer.propTypes = {
  // connected props
  orgId: sowTypes.orgIdType.isRequired,
  initialPlanAppId: sowTypes.planAppIdType,
  planAppList: sowTypes.planAppListType.isRequired,
  showAcaUI: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppExportFormContainer);
