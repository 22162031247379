import React from 'react';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { FormattedDatetime } from './style/atoms';

// Icon uses PNG stored as base64 data in CSS generated by:
// - http://fa2png.io/r/font-awesome/sticky-note/?color=ffffff&margin=0&size=128
const NoteIcon = () => <span className="note-icon" />;

export const NoteLabel = ({ note }) => (
  <div className="note-label">
    {/* <NoteIcon /> */}
    <span className="note-label-text">Note #{note.id}</span>
  </div>
);

NoteLabel.propTypes = {
  note: sowTypes.planAppNoteType.isRequired,
};

export const NoteMarker = ({ note }) => (
  <div className="note-marker">
    <NoteIcon />
    <span className="note-marker-id">#{note.id}</span>
  </div>
);

NoteMarker.propTypes = {
  note: sowTypes.planAppNoteType.isRequired,
};

export const NoteRow = ({ note, colspan = 2 }) => (
  <tr className="note-row">
    <td className="note-column" colSpan={colspan}>
      <NoteLabel note={note} />
      <div className="note-details">
        <span className="note-author">{note.author}</span>
        <span className="note-datetime">
          <FormattedDatetime datetime={note.createdAt} />
        </span>
        <div className="note-text">{note.text}</div>
      </div>
    </td>
  </tr>
);

NoteRow.propTypes = {
  note: sowTypes.planAppNoteType.isRequired,
  colspan: PropTypes.number.isRequired,
};
