import React from 'react';
import * as R from 'ramda';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormikField from 'sow/components/organisms/FormikField';
import FormikReactSelect from 'sow/components/molecules/FormikReactSelect';
import FormikDateRange from 'sow/components/molecules/FormikDateRange';
import FormGroup from 'sow/components/organisms/FormGroup';
import Spinner from 'sow/components/atoms/Spinner';
import Button from 'sow/components/atoms/Button';
import Block from 'sow/components/atoms/Block';
import Strong from 'sow/components/atoms/Strong';
import Glyphicon from 'sow/components/molecules/Glyphicon';

const formSchema = Yup.object({
  anniversary_date_range: Yup.object({
    anniversary_date_start: Yup.string().matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Incorrect Format',
    ),
    anniversary_date_end: Yup.string().matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Incorrect Format',
    ),
  }),
  inspection_date_range: Yup.object({
    inspection_date_start: Yup.string().matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Incorrect Format',
    ),
    inspection_date_end: Yup.string().matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Incorrect Format',
    ),
  }),
  inspection_age_start: Yup.number()
    .positive('Value must be positive.')
    .integer('Value must be an integer.')
    .typeError('Value must be a number.'),
  inspection_age_end: Yup.number()
    .positive('Value must be positive.')
    .integer('Value must be an integer.')
    .typeError('Value must be a number.'),
});

const misorderedInspectionAge = (values, errors) => {
  const startKey = 'inspection_age_start';
  const endKey = 'inspection_age_end';

  const fieldsHaveError = R.has(startKey, errors) || R.has(endKey, errors);
  const hasStartAge = R.has(startKey, values) && !R.isEmpty(values[startKey]);
  const hasEndAge = R.has(endKey, values) && !R.isEmpty(values[endKey]);

  if (fieldsHaveError) {
    return false;
  } else if (hasStartAge && !hasEndAge) {
    return false;
  } else if (!hasStartAge && hasEndAge) {
    return false;
  } else if (Number(values[endKey]) > Number(values[startKey])) {
    return false;
  } else if (Number(values[endKey]) < Number(values[startKey])) {
    return true;
  }
};

const AllInspectionReportFilters = ({
  fetchingOptions,
  filterOptions,
  exportList,
  hasResults,
  handleSearch,
  resetFilters,
  initialValues,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={formSchema}
    onSubmit={handleSearch}
    onReset={resetFilters}
  >
    {({ values, handleSubmit, handleReset, errors }) => (
      <Widget className="search-filter-block">
        <WidgetHeading>
          Inspection Search Filter
          {hasResults && (
            <Block pullRight>
              <Button onClick={() => exportList(values)} primary sm>
                <Glyphicon glyph="export" /> Export
              </Button>
            </Block>
          )}
        </WidgetHeading>
        <WidgetBody>
          {fetchingOptions ? (
            <Spinner />
          ) : (
            <Form onSubmit={handleSubmit}>
              <Row className="search-filter-row">
                <Column className="column-no-margin" xs={12} sm={6} md={4} lg={3}>
                  <Field
                    label="Organization"
                    placeholder=""
                    name={'org_id'}
                    component={FormikField}
                    type={FormikReactSelect}
                    clearable={true}
                    options={filterOptions.org_options}
                  />
                </Column>
                <Column className="column-no-margin" xs={12} sm={6} md={4} lg={3}>
                  <Field
                    className="input-filter"
                    label="Physical City"
                    placeholder=""
                    name={'city'}
                    component={FormikField}
                  />
                </Column>
                <Column className="column-no-margin" xs={12} sm={6} md={4} lg={3}>
                  <Field
                    className="input-filter"
                    label="Physical State"
                    placeholder=""
                    name={'state'}
                    component={FormikField}
                  />
                </Column>
                <Column className="column-no-margin" xs={12} sm={6} md={4} lg={3}>
                  <Field
                    label="Anniversary Date"
                    placeholder=""
                    name={'anniversary_date_range'}
                    dateFormat="MM/DD/YYYY"
                    startDateID={'anniversary_date_start'}
                    endDateID={'anniversary_date_end'}
                    component={FormikField}
                    type={FormikDateRange}
                  />
                  {R.has('anniversary_date_range', errors) && (
                    <Block textDanger>
                      <Strong>Error:</Strong>{' '}
                      {'Date is invalid. Please use MM/DD/YYYY format ex. (04/05/2022)'}
                    </Block>
                  )}
                </Column>
                <Column className="column-no-margin" xs={12} sm={6} md={4} lg={3}>
                  <Field
                    label="Inspection Status"
                    placeholder=""
                    name={'inspection_state'}
                    component={FormikField}
                    type={FormikReactSelect}
                    clearable={true}
                    multi={true}
                    options={filterOptions.inspection_statuses}
                  />
                </Column>
                <Column className="column-no-margin" xs={12} sm={6} md={4} lg={3}>
                  <Field
                    label="Category"
                    placeholder=""
                    name={'category'}
                    component={FormikField}
                    type={FormikReactSelect}
                    clearable={true}
                    multi={true}
                    options={filterOptions.organization_type_options}
                  />
                </Column>
                <Column className="column-no-margin" xs={12} sm={6} md={4} lg={3}>
                  <Field
                    label="Inspection Sub Type"
                    placeholder=""
                    name={'inspection_sub_type'}
                    component={FormikField}
                    type={FormikReactSelect}
                    clearable={true}
                    options={filterOptions.inspection_sub_types}
                  />
                </Column>
                <Column className="column-no-margin" xs={12} sm={6} md={4} lg={3}>
                  <Field
                    label="Inspection Type"
                    placeholder=""
                    name={'inspection_type'}
                    component={FormikField}
                    type={FormikReactSelect}
                    clearable={true}
                    options={filterOptions.inspection_types}
                  />
                </Column>
                <Column className="column-no-margin" xs={12} sm={6} md={4} lg={3}>
                  <Field
                    label="Inspection Year"
                    placeholder=""
                    name={'inspection_year'}
                    component={FormikField}
                    type={FormikReactSelect}
                    clearable={true}
                    options={filterOptions.year_options}
                  />
                </Column>
                <Column className="column-no-margin" xs={12} sm={6} md={4} lg={3}>
                  <Field
                    label="Sample Type"
                    placeholder=""
                    name={'sample_type'}
                    component={FormikField}
                    type={FormikReactSelect}
                    clearable={true}
                    multi={true}
                    options={filterOptions.sample_types}
                  />
                </Column>
                <Column className="column-no-margin" xs={12} sm={6} md={4} lg={3}>
                  <Field
                    label="Sample Status"
                    placeholder=""
                    name={'sample_state'}
                    component={FormikField}
                    type={FormikReactSelect}
                    clearable={true}
                    multi={true}
                    options={filterOptions.sample_states}
                  />
                </Column>
                <Column className="column-no-margin" xs={12} sm={6} md={4} lg={3}>
                  <FormGroup
                    fieldName="inspection_age"
                    className="search-filter"
                    label="Inspection Age"
                    errors={
                      misorderedInspectionAge(values, errors)
                        ? {
                            inspection_age: [
                              'Invalid entry. Starting age must be before ending age.',
                            ],
                          }
                        : {}
                    }
                  >
                    <Row className="search-filter-row">
                      <Column className="column-no-margin-range" xs={6}>
                        <Field
                          className="input-filter"
                          name={'inspection_age_start'}
                          component={FormikField}
                        />
                      </Column>
                      <Column className="column-no-margin-range-end" xs={6}>
                        <Field
                          className="input-filter"
                          name={'inspection_age_end'}
                          component={FormikField}
                        />
                      </Column>
                    </Row>
                  </FormGroup>
                </Column>
                <Column className="column-no-margin" xs={12} sm={6} md={4} lg={3}>
                  <Field
                    label="Inspection Date"
                    placeholder=""
                    name={'inspection_date_range'}
                    startDateID={'inspection_date_start'}
                    endDateID={'inspection_date_end'}
                    component={FormikField}
                    type={FormikDateRange}
                  />
                  {R.has('inspection_date_range', errors) && (
                    <Block textDanger>
                      <Strong>Error:</Strong>{' '}
                      {'Date is invalid. Please use MM/DD/YYYY format ex. (04/05/2022)'}
                    </Block>
                  )}
                </Column>
                <Column className="column-no-margin" xs={12} sm={6} md={4} lg={3}>
                  <Field
                    label="Inspector"
                    placeholder=""
                    name={'inspectors'}
                    component={FormikField}
                    type={FormikReactSelect}
                    clearable={true}
                    multi={true}
                    options={filterOptions.inspector_options}
                  />
                </Column>
                <Column className="column-no-margin" xs={12} sm={6} md={4} lg={3}>
                  <Field
                    label="Certifications"
                    placeholder=""
                    name={'certifications'}
                    component={FormikField}
                    type={FormikReactSelect}
                    clearable={true}
                    multi={true}
                    options={filterOptions.cert_options}
                  />
                </Column>
              </Row>
              <Row className="search-filter-row">
                <Column
                  className="search-filter-column button-column"
                  xs={12}
                  md={4}
                  lg={3}
                >
                  <Row className="search-filter-row">
                    <Column className="search-filter-column button-column" xs={6}>
                      <Button
                        className="filter-command-button search-button"
                        type="submit"
                        disabled={
                          !R.isEmpty(errors) || misorderedInspectionAge(values, errors)
                        }
                        primary
                      >
                        Search
                      </Button>
                    </Column>
                    <Column className="search-filter-column button-column" xs={6}>
                      <Button
                        className="filter-command-button reset-button"
                        onClick={handleReset}
                        primary
                      >
                        Reset
                      </Button>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </Form>
          )}
        </WidgetBody>
      </Widget>
    )}
  </Formik>
);

export default AllInspectionReportFilters;
