import * as rpActions from 'sow/actions/pure/resetPassword';

const passwordResetInitialState = {
  isSubmittingPasswordResetRequest: false,
  isSubmittingPasswordForNewAccountRequest: false,
  isResetting: false,
};

export default function(state = passwordResetInitialState, action) {
  switch (action.type) {
    case rpActions.REQUEST_PASSWORD_RESET:
      return {
        ...state,
        isSubmittingPasswordResetRequest: true,
      };

    case rpActions.REQUEST_PASSWORD_RESET_DONE:
    case rpActions.REQUEST_PASSWORD_RESET_FAIL:
      return {
        ...state,
        isSubmittingPasswordResetRequest: false,
      };

    case rpActions.REQUEST_PASSWORD_FOR_NEW_ACCOUNT:
      return {
        ...state,
        isSubmittingPasswordForNewAccountRequest: true,
      };

    case rpActions.REQUEST_PASSWORD_FOR_NEW_ACCOUNT_DONE:
    case rpActions.REQUEST_PASSWORD_FOR_NEW_ACCOUNT_FAIL:
      return {
        ...state,
        isSubmittingPasswordForNewAccountRequest: false,
      };

    case rpActions.RESET_PASSWORD:
      return { ...state, isResetting: true };

    case rpActions.RESET_PASSWORD_DONE:
    case rpActions.RESET_PASSWORD_FAIL:
      return { ...state, isResetting: false };

    default:
      return state;
  }
}
