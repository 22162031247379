const TOKEN_NAME = 'sow_auth_token';

export function setAuthToken(token, rememberMe = true) {
  if (rememberMe) {
    localStorage.setItem(TOKEN_NAME, token);
  } else {
    sessionStorage.setItem(TOKEN_NAME, token);
  }
}

export function removeAuthToken() {
  localStorage.removeItem(TOKEN_NAME);
  sessionStorage.removeItem(TOKEN_NAME);
}

export function getAuthToken() {
  const ls_token = localStorage.getItem(TOKEN_NAME);
  return ls_token || sessionStorage.getItem(TOKEN_NAME);
}
