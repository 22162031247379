import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import Atom from 'sow/components/atoms/Atom';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Tooltip from 'sow/components/molecules/Tooltip';
import Timestamp from 'sow/components/molecules/Timestamp';
import AppStateText from './AppStateText';
import PlanAppTableRowProgressActionButtons from 'sow/containers/planApp/TableRowProgressActionButtons';

const PlanTable = ({ acaName, orgId, planList, hasOpenPlanApp }) => (
  <Table
    striped
    bordered
    style={{ marginBottom: 0 }}
    head={
      <TableRow>
        <TableCell heading textCenter>
          <Tooltip
            placement="bottom"
            overlay={`Date ${acaName} published these Plan worksheets, and the unique application file name`}
          >
            <Atom>Name</Atom>
          </Tooltip>
        </TableCell>
        <TableCell heading textCenter>
          <Tooltip placement="bottom" overlay="Start date of the plan">
            <Atom>Date Created</Atom>
          </Tooltip>
        </TableCell>
        <TableCell heading textCenter>
          <Tooltip placement="bottom" overlay={`Date the plan was marked "Complete"`}>
            <Atom>Date Completed</Atom>
          </Tooltip>
        </TableCell>
        <TableCell heading textCenter>
          <Tooltip
            placement="bottom"
            overlay="The last day the plan was active. A plan is no longer active when a newer plan is completed."
          >
            <Atom>Active Until</Atom>
          </Tooltip>
        </TableCell>
        <TableCell heading textCenter>
          <Tooltip
            placement="bottom"
            overlay={`This is the current state the application is within the application process. Once submitted, the application is locked for editing unless ${acaName} changes the state to New Application, Renewal or Initial Review – More info. needed. If you need to open the application after submitting, contact ${acaName}.`}
          >
            <Atom>State</Atom>
          </Tooltip>
        </TableCell>
        <TableCell heading textCenter>
          <Tooltip
            placement="bottom"
            overlay="This progress bar measures your forward movement on completing your organic systems plan. The progress bar will update as you finish worksheets within the application."
          >
            <Atom>Progress</Atom>
          </Tooltip>
          /
          <Tooltip
            placement="bottom"
            overlay='Use this section to submit your application or renew for next year. In order to submit, the organization must have selected either "Not Applicable" or "Yes, Finished" for every worksheet on the Organic Systems Plan application, and the organization must have entered at least one location for the organization.'
          >
            <Atom>Actions</Atom>
          </Tooltip>
        </TableCell>
      </TableRow>
    }
  >
    {planList.map(app => (
      <TableRow key={app.id}>
        <TableCell textCenter valignMiddle>
          {app.ospDefinition.name} #{app.id}
        </TableCell>

        <TableCell textCenter valignMiddle>
          <Timestamp date={app.insertedAt} format="L" />
        </TableCell>

        <TableCell textCenter valignMiddle>
          {app.completedOn ? <Timestamp date={app.completedOn} format="L" /> : '--'}
        </TableCell>

        <TableCell textCenter valignMiddle>
          {app.activeUntil ? <Timestamp date={app.activeUntil} format="L" /> : '--'}
        </TableCell>

        <TableCell valignMiddle xs={2}>
          <AppStateText appState={app.state} />
        </TableCell>

        <TableCell textCenter valignMiddle xs={3}>
          <PlanAppTableRowProgressActionButtons
            orgId={orgId}
            planAppId={app.id}
            planAppSummary={app}
            hasOpenPlanApp={hasOpenPlanApp}
          />
        </TableCell>
      </TableRow>
    ))}

    {R.isEmpty(planList) && (
      <TableRow>
        <TableCell colSpan={6} textMuted textCenter>
          No historical plans to show.
        </TableCell>
      </TableRow>
    )}
  </Table>
);

PlanTable.propTypes = {
  acaName: PropTypes.string.isRequired,
  orgId: sowTypes.orgIdType.isRequired,
  planList: PropTypes.arrayOf(sowTypes.planAppType).isRequired,
  hasOpenPlanApp: PropTypes.bool.isRequired,
};

export default PlanTable;
