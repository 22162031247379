import React, { Component } from 'react';

import DragDropList from 'sow/components/organisms/DragDropList';

class DragDropListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { list: [] };
  }

  componentDidMount() {
    this.setState({ list: this.props.list });
  }

  reorderList(dragIndex, hoverIndex) {
    const { list } = this.state;
    let reorderedList = [];
    if (dragIndex < hoverIndex) {
      const listStart = list.slice(0, dragIndex);
      const listSecond = list.slice(dragIndex + 1, hoverIndex + 1);
      const listThird = [list[dragIndex]];
      const listEnd = list.slice(hoverIndex + 1);
      reorderedList = listStart.concat(listSecond, listThird, listEnd);
    } else if (dragIndex > hoverIndex) {
      const listStart = list.slice(0, hoverIndex);
      const listSecond = [list[dragIndex]];
      const listThird = list.slice(hoverIndex, dragIndex);
      const listEnd = list.slice(dragIndex + 1);
      reorderedList = listStart.concat(listSecond, listThird, listEnd);
    }
    this.setState({ list: reorderedList });
  }

  updateSortOrder(movedItem) {
    const { list } = this.state;
    const { list: unsortedList } = this.props;

    let newOrder;
    if (this.props.listFiltered) {
      newOrder = list.map(item => {
        if (item.id === movedItem.id) {
          item.sort_order = unsortedList[movedItem.index].sort_order;
        }
        return item;
      });
    } else {
      newOrder = list.map((item, index) => {
        item.sort_order = index + 1;
        return item;
      });
    }

    this.props.updateSortOrder(newOrder);
  }

  render() {
    return (
      <DragDropList
        list={this.state.list}
        reorderList={this.reorderList.bind(this)}
        updateSortOrder={this.updateSortOrder.bind(this)}
        listItemComponents={this.props.children}
        editRoute={this.props.editRoute}
      />
    );
  }
}

export default DragDropListContainer;
