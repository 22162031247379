import React from 'react';

import { PlanAppSummaryLoader } from 'sow/store/containers';
import OrgPageTemplate from 'sow/containers/OrgPageTemplate';
import PlanAppExportForm from 'sow/containers/planAppExport/Form';

const OrgPlanAppExportPage = () => (
  <OrgPageTemplate title="Export">
    <PlanAppSummaryLoader autoLoad>{() => <PlanAppExportForm />}</PlanAppSummaryLoader>
  </OrgPageTemplate>
);

OrgPlanAppExportPage.propTypes = {};

export default OrgPlanAppExportPage;
