import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import './CSSModules/OrgContactsPage.module.css';
import * as currentUser from 'sow/selectors/currentUser';
import OrgPageTemplate from 'sow/containers/OrgPageTemplate';
import ContactInfoNonstaffContainer from 'sow/containers/ContactInfoNonstaff';
import ContactInfoStaffContainer from 'sow/containers/ContactInfoStaff';

const mapStateToProps = (state, props) => ({
  isCSStaff: currentUser.isCSStaff(state, props),
  isInspector: currentUser.isInspector(state, props),
});

const OrgClientContactsPage = ({ isCSStaff, isInspector }) => (
  <OrgPageTemplate title="Contacts">
    {isCSStaff && !isInspector ? (
      <ContactInfoStaffContainer />
    ) : (
      <ContactInfoNonstaffContainer />
    )}
  </OrgPageTemplate>
);

export default R.compose(withRouter, connect(mapStateToProps))(OrgClientContactsPage);
