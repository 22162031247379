import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { systemAdminRoute, announcementsEditRoute } from 'sow/routes';
import AnnouncementIcon from 'sow/components/organisms/AnnouncementDisplay/AnnouncementIcon';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormikField from 'sow/components/organisms/FormikField';
import FormikReactSelect from 'sow/components/molecules/FormikReactSelect';
import FormikDate from 'sow/components/molecules/FormikDate';
import Atom from 'sow/components/atoms/Atom';
import Button from 'sow/components/atoms/Button';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Spinner from 'sow/components/atoms/Spinner';
import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';

const formSchema = Yup.object({
  start_on: Yup.string()
    .ensure()
    .matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Date is invalid. Please use MM/DD/YYYY ex. (04/05/2020)',
    )
    .typeError('Date is invalid. Please use MM/DD/YYYY ex. (04/05/2020)'),
  end_on: Yup.string()
    .ensure()
    .matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Date is invalid. Please use MM/DD/YYYY ex. (04/05/2020)',
    ),
});

const AnnouncementsList = ({
  loadingList,
  initialValues,
  announcementList,
  handleSearch,
  resetSearch,
  announcementTypes,
}) => (
  <Widget className="system-admin-list-edit">
    <WidgetHeading>Announcements</WidgetHeading>
    <WidgetBody>
      <Formik
        enableReinitialize={true}
        validateOnChange={false}
        validationSchema={formSchema}
        initialValues={initialValues}
        onSubmit={handleSearch}
        onReset={resetSearch}
      >
        {({ values, handleSubmit, handleReset }) => (
          <Form onSubmit={handleSubmit}>
            <Row className="form-row search-row">
              <Column className="form-column" xs={12} md={4}>
                <Field
                  className="input-filter"
                  label="Title"
                  placeholder=""
                  name={'title'}
                  component={FormikField}
                />
              </Column>
              <Column className="form-column" xs={12} md={4}>
                <Block className="admin-date-search">
                  <Block className="date-field">
                    <Field
                      className="input-filter"
                      label="Start Date"
                      name={'start_on'}
                      dateFormat={'MM/DD/YYYY'}
                      component={FormikField}
                      type={FormikDate}
                      selectedDate={values.end_on ? { end: values.end_on } : null}
                    />
                  </Block>
                  <Atom className="to-span">to</Atom>
                  <Block className="date-field end-date">
                    <Field
                      className="input-filter"
                      name={'end_on'}
                      dateFormat={'MM/DD/YYYY'}
                      component={FormikField}
                      type={FormikDate}
                      selectedDate={values.start_on ? { start: values.start_on } : null}
                    />
                  </Block>
                </Block>
              </Column>
              <Column className="form-column" xs={12} md={4}>
                <Field
                  className="input-filter"
                  label="Type"
                  placeholder=""
                  name={'announcement_type_id'}
                  component={FormikField}
                  type={FormikReactSelect}
                  options={announcementTypes}
                />
              </Column>
              <Column className="form-column" xs={12} md={4}>
                <Field
                  className="input-filter"
                  label="Active"
                  placeholder=""
                  name={'enabled'}
                  component={FormikField}
                  type={FormikReactSelect}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                />
              </Column>
            </Row>
            <Row className="form-row">
              <Column className="form-column" xs={12} sm={6} md={4}>
                <Row className="button-row search-button-row">
                  <Column className="button-column" xs={6} sm={6} md={6}>
                    <Button className="form-button search-button" type="submit">
                      Search
                    </Button>
                  </Column>
                  <Column className="button-column" xs={6} sm={6} md={6}>
                    <Button
                      className="form-button reset-return-button"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                  </Column>
                </Row>
              </Column>
            </Row>
          </Form>
        )}
      </Formik>
      <HorizontalRule className="divider" />
      <Row className="form-row">
        <Column className="form-column" xs={12} sm={6} md={4}>
          <Row className="button-row">
            <Column className="button-column" xs={6} sm={6} md={6}>
              <Button className="form-button" to={announcementsEditRoute('new')} primary>
                <FontAwesome className="button-icon" icon="plus" />
                Add
              </Button>
            </Column>
            <Column className="button-column" xs={6} sm={6} md={6}>
              <Button className="form-button reset-return-button" to={systemAdminRoute()}>
                Back
              </Button>
            </Column>
          </Row>
        </Column>
      </Row>
      {loadingList ? (
        <Spinner />
      ) : (
        <Row className="form-row list-row">
          <Column className="form-column" xs={12} sm={12} md={12}>
            <Table
              bordered
              striped
              head={
                <TableRow>
                  <TableCell heading>Title</TableCell>
                  <TableCell heading>Type</TableCell>
                  <TableCell heading>Start Date</TableCell>
                  <TableCell heading>End Date</TableCell>
                  <TableCell heading>Show For</TableCell>
                  <TableCell heading>Active</TableCell>
                  <TableCell heading></TableCell>
                </TableRow>
              }
            >
              {announcementList.length > 0 ? (
                <Fragment>
                  {announcementList.map(announcement => (
                    <TableRow key={announcement.id}>
                      <TableCell className="wrap">{announcement.title}</TableCell>
                      <TableCell>
                        {announcement.announcement_type ? (
                          <Block>
                            <AnnouncementIcon {...announcement.announcement_type} inline>
                              <FontAwesome icon={announcement.announcement_type.icon} />
                            </AnnouncementIcon>
                            {announcement.announcement_type.name}
                          </Block>
                        ) : (
                          'None'
                        )}
                      </TableCell>
                      <TableCell>{announcement.start_on}</TableCell>
                      <TableCell>
                        {announcement.end_on ? announcement.end_on : 'Not set'}
                      </TableCell>
                      <TableCell>
                        {announcement.show_for_display
                          ? announcement.show_for_display
                          : 'Not set'}
                      </TableCell>
                      <TableCell>{announcement.enabled ? 'Yes' : 'No'}</TableCell>
                      <TableCell>
                        <Link
                          className="start-end-width"
                          to={announcementsEditRoute(announcement.id)}
                        >
                          Edit
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </Fragment>
              ) : (
                <TableRow>
                  <TableCell colSpan={7} textCenter>
                    No announcements found.
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </Column>
        </Row>
      )}
    </WidgetBody>
  </Widget>
);

export default AnnouncementsList;
