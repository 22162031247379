import React from 'react';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import FormikField from 'sow/components/organisms/FormikField';

const NumberFormikField = ({ integerLimit, style, ...props }) => {
  const numberMask = createNumberMask({
    prefix: '',
    allowLeadingZeroes: true,
    integerLimit,
  });

  return (
    <FormikField
      {...props}
      textMask={numberMask}
      style={{ textAlign: 'right', ...style }}
    />
  );
};

// NOTE: The following are all of the createNumberMask options we could provide
//       props to adjust in future.
// ~~
// ~~ from: https://github.com/text-mask/text-mask/tree/master/addons/#readme
// ~~
// prefix (string): what to display before the amount. Defaults to '$'.
// suffix (string): what to display after the amount. Defaults to empty string.
// includeThousandsSeparator (boolean): whether or not to separate thousands. Defaults to to true.
// thousandsSeparatorSymbol (string): character with which to separate thousands. Default to ','.
// allowDecimal (boolean): whether or not to allow the user to enter a fraction with the amount. Default to false.
// decimalSymbol (string): character that will act as a decimal point. Defaults to '.'
// decimalLimit (number): how many digits to allow after the decimal. Defaults to 2
// integerLimit (number): limit the length of the integer number. Defaults to null for unlimited
// requireDecimal (boolean): whether or not to always include a decimal point and placeholder for decimal digits after the integer. Defaults to false.
// allowNegative (boolean): whether or not to allow negative numbers. Defaults to false
// allowLeadingZeroes
// allowLeadingZeroes (boolean): whether or not to allow leading zeroes. Defaults to false

NumberFormikField.propTypes = {
  ...FormikField.propTypes,
  integerLimit: PropTypes.number,
  style: PropTypes.object,
};

export default NumberFormikField;
