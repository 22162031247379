import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const handleSubmit = fn => event => {
  event.preventDefault();
  fn();
};

/** Displays a form tag */
const Form = ({ inline, className, onSubmit, children, ...props }) => {
  const classes = classnames(className, {
    'form-inline': inline,
  });

  return (
    <form className={classes} onSubmit={handleSubmit(onSubmit)} {...props}>
      {children}
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.node,

  /** Adds the css class 'form-inline' */
  inline: PropTypes.bool,

  /** CSS classes to be passed through */
  className: PropTypes.string,

  /** Submit handler function that automatically prevents the default event */
  onSubmit: PropTypes.func.isRequired,
};

export default Form;
