import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as inspectionYearActions from 'sow/actions/pure/inspectionYear';
import { apiGet, apiPost } from 'sow/sagas/api';

export const getInspectionYear = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/inspection_year', payload);
    if (response.data) {
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getInspectionYearById = function*({ id, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/inspection_year/${id}`);
    if (response.data) {
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addInspectionYear = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/inspection_year', payload);

    if (response.data) {
      yield put(actions.shell.toast('success', 'Inspection year added.'));
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateInspectionYear = function*({ id, payload, meta }) {
  try {
    const { response } = yield call(apiPost, `/admin/inspection_year/${id}`, payload);

    if (response && response.data) {
      yield put(actions.shell.toast('success', 'Inspection year updated.'));
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteInspectionYear = function*({ id, meta }) {
  try {
    const { response } = yield call(apiPost, `/admin/inspection_year/delete/${id}`);
    if (response.data) {
      yield put(actions.shell.toast('success', 'Inspection year deleted.'));
      if (meta.onSuccess) meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(inspectionYearActions.LOAD_INSPECTION_YEARS, getInspectionYear);
  yield takeEvery(
    inspectionYearActions.LOAD_INSPECTION_YEAR_BY_ID,
    getInspectionYearById,
  );
  yield takeEvery(inspectionYearActions.ADD_INSPECTION_YEAR, addInspectionYear);
  yield takeEvery(inspectionYearActions.UPDATE_INSPECTION_YEAR, updateInspectionYear);
  yield takeEvery(inspectionYearActions.DELETE_INSPECTION_YEAR, deleteInspectionYear);
}
