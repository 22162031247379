import React from 'react';

const BooleanCheckbox = ({ disabled, value, text, onChange }) =>
  <div>
    <div className="checkbox">
      <label>
        <input
          type="checkbox"
          checked={value === true}
          onChange={onChange(!value)}
          disabled={disabled}
        />
        {text}
      </label>
    </div>
  </div>;

export default BooleanCheckbox;
