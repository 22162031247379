import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import * as itemActions from 'sow/actions/pure/item';

import ItemList from 'sow/components/organisms/ItemList';

const mapDispatchToProps = { loadItems: itemActions.loadItems };

class ItemListContainer extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = { loadingList: true, itemList: [] };
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.loadItems({}, this.updateState.bind(this));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState({ data }) {
    if (this._isMounted) {
      this.setState({ loadingList: false, itemList: data });
    }
  }

  render() {
    return <ItemList {...this.state} />;
  }
}

export default R.compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(ItemListContainer);
