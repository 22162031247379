import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'sow/components/atoms/Button';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Tooltip from 'sow/components/molecules/Tooltip';

const noop = () => {};

const PlanAppChangeCommentDismissAllButtonWrapper = styled(Button)`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const PlanAppChangeCommentDismissAllButton = ({ onClick, isDisabled }) => (
  <Tooltip overlay={`Marking the comments as read will remove them from the overview.`}>
    <PlanAppChangeCommentDismissAllButtonWrapper
      onClick={isDisabled ? noop : onClick}
      disabled={isDisabled}
      xs
      link
    >
      <FontAwesome icon="eye" /> Mark all comments read
    </PlanAppChangeCommentDismissAllButtonWrapper>
  </Tooltip>
);

PlanAppChangeCommentDismissAllButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default PlanAppChangeCommentDismissAllButton;
