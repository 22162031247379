import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Block from 'sow/components/atoms/Block';

const Well = ({ className, sm, children, ...props }) => {
  const classes = classnames(className, 'well', {
    'well-sm': sm,
  });

  return (
    <Block className={classes} {...props}>
      {children}
    </Block>
  );
};

Well.propTypes = {
  className: PropTypes.string,
  sm: PropTypes.bool,
  children: PropTypes.node,
};

export default Well;
