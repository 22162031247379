import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ListItem from 'sow/components/atoms/ListItem';
import Link from 'sow/components/atoms/Link';
import Heading from 'sow/components/atoms/Heading';
import Paragraph from 'sow/components/atoms/Paragraph';

const PlanAppStepperStep = ({ isActive, disabled, to, onClick, heading, text }) => {
  let classes = classnames({
    active: isActive && !disabled,
    disabled,
  });

  return (
    <ListItem className={classes}>
      <Link
        to={disabled ? null : to}
        onClick={disabled ? () => {} : onClick}
        disabled={disabled}
      >
        <Heading level={4} className="list-group-item-heading">
          {heading}
        </Heading>
        <Paragraph className="list-group-item-text">{text}</Paragraph>
      </Link>
    </ListItem>
  );
};

PlanAppStepperStep.propTypes = {
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  heading: PropTypes.node,
  text: PropTypes.node,
};

export default PlanAppStepperStep;
