import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { orgIdType, planAppSummaryType } from 'sow/types';

import Heading from 'sow/components/atoms/Heading';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import OrgPlanAppTable from 'sow/components/organisms/OrgPlanAppTable';

const OrgHistoricalPlansPage = ({ acaName, orgId, planAppSummaryList }) => {
  return (
    <Fragment>
      <Heading pageTitle>Historical Plans</Heading>

      <Widget>
        <WidgetBody>
          <OrgPlanAppTable
            acaName={acaName}
            orgId={orgId}
            planAppSummaryList={planAppSummaryList}
          />
        </WidgetBody>
      </Widget>
    </Fragment>
  );
};

OrgHistoricalPlansPage.propTypes = {
  acaName: PropTypes.string.isRequired,
  showAcaUI: PropTypes.bool,
  orgId: orgIdType.isRequired,
  planAppSummaryList: PropTypes.arrayOf(planAppSummaryType).isRequired,
};

export default OrgHistoricalPlansPage;
