import { apiRequest } from 'sow/utils/api';
import { API_URL } from 'sow/constants/api';

export function fetchAcaAsync(id) {
  return apiRequest({
    type: 'GET',
    url: `${API_URL}/v1/aca/${id}`,
  });
}

export function fetchAcaListAsync() {
  return apiRequest({
    type: 'GET',
    url: `${API_URL}/v1/aca/register`,
  });
}

export const fetchActivityLogAsync = (acaId, filters) =>
  apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/aca/${acaId}/activity_log`,
    data: filters,
  });

export const fetchActivityLogOptionsAsync = acaId =>
  apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/aca/${acaId}/activity_log_options`,
  });

export const fetchLogOspDefinitionAsync = (acaId, defId) =>
  apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/aca/${acaId}/osp/${defId}`,
  });

export const fetchReportAsync = (acaId, reportId) =>
  apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/aca/${acaId}/reports/${reportId}`,
  });

export const fetchInspectionReportAsync = (acaId, filters) =>
  apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/aca/${acaId}/inspection_report`,
    data: filters,
  });

export const fetchInspectionReportOptionsAsync = acaId =>
  apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/aca/${acaId}/inspection_report/options`,
  });

export const fetchUnannouncedReportAsync = (acaId, filters) =>
  apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/aca/${acaId}/unannounced_report`,
    data: filters,
  });

export const fetchLocationReportOptionsAsync = acaId =>
  apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/aca/${acaId}/location_report/options`,
  });

export const fetchLocationReportAsync = (acaId, filters) =>
  apiRequest({
    method: 'GET',
    url: `${API_URL}/v1/aca/${acaId}/location_report`,
    data: filters,
  });
