import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import Fuse from 'fuse.js';
import * as R from 'ramda';

import { orgIdType } from 'sow/types';
import Block from 'sow/components/atoms/Block';
import SearchResult from 'sow/components/organisms/OrgUserLookupField/SearchResult';

const filterOptions = (options, filter) => {
  if (!filter || filter.length < 3) return options;

  const fuseOptions = {
    shouldSort: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['id', 'name', 'users.name', 'users.email'],
  };
  const fuse = new Fuse(options, fuseOptions);
  return fuse.search(filter);
};

const OrgUserLookupField = ({
  name,
  value,
  values,
  setFieldValue,
  disabled,
  orgList,
}) => {
  const handleChange = option => setFieldValue(name, R.propOr('', 'value', option));
  const selectedOrg = R.find(R.propEq('id', parseInt(value)), orgList);

  return (
    <Block>
      <ReactSelect
        name={name}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        options={orgList.map(org => ({
          ...org,
          value: org.id,
          label: `${org.name} [${org.users.map(u => u.name).join(', ')}]`,
        }))}
        filterOptions={filterOptions}
      />

      {selectedOrg && <SearchResult org={selectedOrg} />}
    </Block>
  );
};

OrgUserLookupField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([orgIdType, PropTypes.string]).isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  orgList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

OrgUserLookupField.defaultProps = {
  disabled: false,
};

export default OrgUserLookupField;
