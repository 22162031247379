import React from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';

import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormikField from 'sow/components/organisms/FormikField';
import FormikReactSelect from 'sow/components/molecules/FormikReactSelect';
import FormikWysiwyg from 'sow/components/molecules/FormikWysiwyg';
import Button from 'sow/components/atoms/Button';

const SectionsModal = ({
  show,
  isNew,
  closeModal,
  itemSectionTypeOptions,
  handleSave,
  initialValues,
}) => (
  <Modal className="modal-has-wysiwyg" backdrop="static" show={show} onHide={closeModal}>
    <Modal.Header closeButton>{isNew ? 'Add' : 'Edit'} Section</Modal.Header>
    <Modal.Body>
      <Formik onSubmit={handleSave} initialValues={initialValues}>
        {({ values, handleSubmit }) => (
          <Form>
            <Row className="form-row">
              <Column className="form-column form-group" xs={12}>
                <Button className="no-margin-button" onClick={handleSubmit} primary>
                  Save
                </Button>
              </Column>
              <Column className="form-column" xs={12}>
                <Field
                  className="input-filter"
                  label="Name"
                  placeholder=""
                  name={'name'}
                  component={FormikField}
                  required
                />
              </Column>
              <Column className="form-column" xs={12}>
                <Field
                  label="Section Type"
                  placeholder=""
                  name={'item_section_type_id'}
                  component={FormikField}
                  type={FormikReactSelect}
                  clearable={true}
                  options={itemSectionTypeOptions}
                  required
                />
              </Column>
              <Column className="form-column" xs={12}>
                <Field
                  label="Description"
                  name={'description'}
                  component={FormikField}
                  type={FormikWysiwyg}
                />
              </Column>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal.Body>
  </Modal>
);

export default SectionsModal;
