import actions from 'sow/actions/pure';

const currentOspAppId = (state = null, action) => {
  switch (action.type) {
    case String(actions.ospApp.setCurrentForOrg):
      return action.payload.ospAppId;

    default:
      return state;
  }
};

export default currentOspAppId;
