import React, { Fragment, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { certificationTypeAddEditRoute } from 'sow/routes';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';

const ListItem = ({
  listItem,
  id,
  index,
  reorderList,
  updateSortOrder,
  listItemComponents,
  editRoute,
}) => {
  const ref = useRef(null);

  const [{ isOver }, drop] = useDrop({
    accept: 'item',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex !== hoverIndex) {
        reorderList(dragIndex, hoverIndex);
      }
      item.index = hoverIndex;
    },
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
      };
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'item', id, index },
    end(item) {
      updateSortOrder(item);
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));

  return (
    <div ref={ref} className="list-item-wrapper">
      <div
        className={
          isDragging
            ? 'dragging-list-item'
            : listItem.enabled
            ? 'list-item'
            : 'list-item inactive'
        }
      >
        {!isDragging && (
          <Fragment>
            <span ref={drag} className="material-icons dnd-icon">
              drag_indicator
            </span>
            {listItemComponents ? (
              listItemComponents(listItem)
            ) : (
              <Fragment>
                <Block>
                  {listItem.enabled ? listItem.name : `${listItem.name} [INACTIVE]`}
                </Block>
                <FontAwesome className="edit-icon" icon="pencil" />
                <Link className="start-end-width" to={editRoute(listItem.id)}>
                  Edit
                </Link>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ListItem;
