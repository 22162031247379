import React, { Fragment } from 'react';

import './OrgSettingsCertification.module.css';
import { API_URL } from 'sow/constants/api';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Spinner from 'sow/components/atoms/Spinner';
import Widget from 'sow/components/molecules/Widget';
import Image from 'sow/components/atoms/Image';
import Paragraph from 'sow/components/atoms/Paragraph';

const OrgSettingsCertification = ({
  certificationList,
  isLoading,
  selectedCerts,
  handleSelect,
}) => (
  <Fragment>
    {isLoading ? (
      <Spinner />
    ) : (
      <Row>
        {certificationList.length > 0 ? (
          certificationList.map(cert => (
            <Column key={cert.id} xs={12} sm={6} md={4} lg={4}>
              <Widget
                className="certification-type-block"
                onClick={() => handleSelect(cert.id)}
              >
                <input
                  type="checkbox"
                  className="cert-checkbox"
                  checked={selectedCerts[cert.id]}
                  onChange={() => {}}
                />

                <Image
                  className="cert-image"
                  imageUrl={
                    cert.file && cert.file.s3_name
                      ? `${API_URL}/v1/file/by_path/${cert.file.s3_name}`
                      : null
                  }
                />
                <Paragraph className="cert-name">{cert.name}</Paragraph>
              </Widget>
            </Column>
          ))
        ) : (
          <Column md={12}>
            <Paragraph className="no-certification-types">
              No certifications to display.
            </Paragraph>
          </Column>
        )}
      </Row>
    )}
  </Fragment>
);

export default OrgSettingsCertification;
