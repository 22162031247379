import * as docActions from 'sow/actions/pure/certificationDocs';

const initialState = {
  isLoading: false,
  items: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case docActions.LOAD_ALL:
      return { ...initialState, isLoading: true };

    case docActions.LOAD_ALL_DONE:
      return { ...state, isLoading: false, items: action.payload.docs };

    case docActions.LOAD_ALL_FAIL:
      return { ...state, isLoading: false };

    case docActions.DELETE_DOC_DONE: {
      const index = state.items.map(d => d.id).indexOf(action.payload.docId);
      if (index === -1) return state;
      return {
        ...state,
        items: [...state.items.slice(0, index), ...state.items.slice(index + 1)],
      };
    }

    default:
      return state;
  }
}
