import actions from 'sow/actions/pure';
import * as wsAnswersAction from 'sow/actions/worksheetAnswers';
import { makeImmutable, getIn } from 'sow/utils/data';

import byWorksheetId from './byWorksheetId';
import errorMessage from './errorMessage';
import ids from './ids';
import isLoading from './isLoading';

const mapKeyData = action => action.data.ospAppId;

const mapReducer = (state = makeImmutable({}), action) =>
  state.merge({
    byWorksheetId: byWorksheetId(state.byWorksheetId, action),
    errorMessage: errorMessage(state.errorMessage, action),
    ids: ids(state.ids, action),
    isLoading: isLoading(state.isLoading, action),
  });

const listByOspAppId = (state = makeImmutable({}), action) => {
  switch (action.type) {
    case wsAnswersAction.LOAD_ALL_FOR_ORG_OSP_APP_REQUEST:
    case wsAnswersAction.LOAD_ALL_FOR_ORG_OSP_APP_SUCCESS:
    case wsAnswersAction.LOAD_ALL_FOR_ORG_OSP_APP_FAILURE:
    case wsAnswersAction.SAVE_ONE_WORKSHEET_SUCCESS:
      return state.updateIn([mapKeyData(action)], mapReducer, action);
    // TODO (orther) implement this or remove the case
    case 'ADD_DROP_ACTION_TYPES_HERE':
      return state.without(mapKeyData(action));

    default:
      return state;
  }
};

export default listByOspAppId;

export const getIds = (state, ospAppId) =>
  getIn(state, [ospAppId, 'ids'], makeImmutable([]));

export const getIsLoading = (state, ospAppId) =>
  getIn(state, [ospAppId, 'isLoading'], false);

export const getErrorMessage = (state, ospAppId) =>
  getIn(state, [ospAppId, 'errorMessage']);
