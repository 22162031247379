import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { push as routerPush, replace as routerReplace } from 'connected-react-router';

import { itemSubtypeRoute, itemSubtypeEditRoute } from 'sow/routes';

import * as itemSubtypeActions from 'sow/actions/pure/itemSubtype';

import ItemSubtypeEdit from 'sow/components/organisms/ItemSubtypeEdit';

const mapStateToProps = (state, props) => {
  const itemSubtypeId = R.path(['match', 'params', 'id'], props);

  return {
    isNew: R.equals(itemSubtypeId, 'new'),
    itemSubtypeId: itemSubtypeId,
  };
};

const mapDispatchToProps = {
  loadOptions: itemSubtypeActions.loadOptions,
  loadItemSubtypeById: itemSubtypeActions.fetchById,
  addItemSubtype: itemSubtypeActions.addItemSubtype,
  updateItemSubtype: itemSubtypeActions.updateItemSubtype,
  deleteItemSubtype: itemSubtypeActions.deleteItemSubtype,
  redirect: routerPush,
  routerReplace: routerReplace,
};

const initialValues = { isLoading: true, initialValues: { sort_order: 1 } };

class ItemSubtypeEditContainer extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = R.clone(initialValues);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadItem();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadItem() {
    if (this._isMounted) {
      const { isNew, itemSubtypeId } = this.props;

      if (!isNew) {
        this.props.loadItemSubtypeById(itemSubtypeId, this.updateState.bind(this));
      } else {
        this.setState({ isNew: true, isLoading: false });
      }
    }
  }

  updateState({ data }) {
    if (this._isMounted) {
      if (this.state.isNew) {
        this.props.routerReplace(itemSubtypeEditRoute(data.id));
      }

      if (!R.isEmpty(data)) {
        this.setState({
          isNew: false,
          isLoading: false,
          initialValues: { ...data },
        });
      } else {
        this.redirectToList();
      }
    }
  }

  redirectToList() {
    this.props.routerReplace(itemSubtypeRoute());
  }

  handleSave(values, formikBag) {
    if (this.state.isNew) {
      this.props.addItemSubtype(values, this.updateState.bind(this));
    } else {
      this.props.updateItemSubtype(
        this.props.itemSubtypeId,
        values,
        this.updateState.bind(this),
      );
    }
  }

  handleDelete() {
    const { itemSubtypeId, deleteItemSubtype } = this.props;
    deleteItemSubtype(itemSubtypeId, this.redirectToList.bind(this));
  }

  render() {
    return (
      <ItemSubtypeEdit
        onSave={this.handleSave.bind(this)}
        onDelete={this.handleDelete.bind(this)}
        {...this.state}
        {...this.props}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ItemSubtypeEditContainer);
