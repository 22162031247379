import React from 'react';

import AnnouncementBlock from './AnnouncementBlock';
import AnnouncementIcon from './AnnouncementIcon';
import AnnouncementTitle from './AnnouncementTitle';
import Block from 'sow/components/atoms/Block';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const dangerousHtml = html => ({ __html: html });

const AnnouncementDisplay = ({ announcement }) => (
  <AnnouncementBlock {...announcement.announcement_type}>
    <AnnouncementIcon {...announcement.announcement_type}>
      <FontAwesome
        icon={
          announcement.announcement_type
            ? announcement.announcement_type.icon
            : 'circle-exclamation'
        }
      />
    </AnnouncementIcon>
    <Block inline>
      <AnnouncementTitle {...announcement.announcement_type}>
        {announcement.title}
      </AnnouncementTitle>
      <Block
        className="wrap"
        dangerouslySetInnerHTML={dangerousHtml(announcement.message)}
      />
    </Block>
  </AnnouncementBlock>
);

export default AnnouncementDisplay;
