import React from 'react';
import PropTypes from 'prop-types';

import Block from 'sow/components/atoms/Block';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Tooltip from 'sow/components/molecules/Tooltip';

const ChangeUnreadNotificationsIcon = ({ hasComments, hasCommentNotifications }) => {
  if (!hasComments) return null;

  return (
    <Block inline style={{ margin: '0 2px 0 2px' }}>
      <Tooltip
        overlay={`Question has ${hasCommentNotifications ? 'unread ' : ''}comments`}
      >
        <FontAwesome icon="comment" variant={hasCommentNotifications ? 'fas' : 'far'} />
      </Tooltip>
    </Block>
  );
};

ChangeUnreadNotificationsIcon.propTypes = {
  hasComments: PropTypes.bool.isRequired,
  hasCommentNotifications: PropTypes.bool.isRequired,
};

export default ChangeUnreadNotificationsIcon;
