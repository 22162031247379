import React, { Fragment } from 'react';
import styled from 'styled-components';

import './PlanAppProgressCircle.module.css';
import Block from 'sow/components/atoms/Block';

const ProgressCircle = styled(Block)`
  background: ${({ locationComplete, progress }) => {
    return `linear-gradient(${locationComplete ? '270deg' : '90deg'}, ${
      locationComplete ? '#31708f' : '#d8d8d8'
    } 50%, rgba(0, 255, 0, 0) 50%),
    linear-gradient(${progress}deg, #31708f 50%, #d8d8d8 50%)`;
  }};
`;

const PlanAppProgressCircle = ({ percentageComplete, className, innerTextContent }) => (
  <Block className={className}>
    <Block className="progress-circle-block">
      <ProgressCircle
        className={`${className} outer-percentage-circle`}
        locationComplete={percentageComplete >= 50}
        progress={(percentageComplete / 100) * 360 + 90}
      >
        <Block className="inner-percentage-circle">
          {innerTextContent ? (
            innerTextContent
          ) : (
            <Fragment>
              <Block className="percentage">{Math.round(percentageComplete)}%</Block>
              <Block className="percentage-label">Completed</Block>
            </Fragment>
          )}
        </Block>
      </ProgressCircle>
    </Block>
  </Block>
);

export default PlanAppProgressCircle;
