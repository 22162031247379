import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';

const mapStateToProps = (state, props) => ({
  question: fromPlanApp.question(state, props),
});

const QuestionDetailsContainer = ({ question, children }) => children(question);

QuestionDetailsContainer.propTypes = {
  // Passed props
  questionId: sowTypes.worksheetQuestionIdType.isRequired,
  children: PropTypes.func.isRequired,

  // Connected props
  question: sowTypes.worksheetQuestionType.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(QuestionDetailsContainer);
