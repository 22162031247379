import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as currentOrg from 'sow/selectors/currentOrg';
import * as currentOspApp from 'sow/selectors/currentOspApp';
import Spinner from 'sow/components/atoms/Spinner';
import actions from 'sow/actions/pure';

// TODO: have the saga handle this?
const getDefaultOspAppId = R.compose(
  R.path(['id']),
  R.last,
  R.defaultTo([]),
);

const mapStateToProps = (state, props) => ({
  // loadFailed: currentOrg.isLoadFail(state, props),
  loading: currentOrg.ospAppListIsLoading(state, props),
  shouldLoad: currentOrg.ospAppListShouldLoad(state, props),
  ospAppList: currentOrg.ospAppList(state, props),
  ospAppId: currentOspApp.ospAppId(state, props),
  orgId: currentOrg.orgId(state, props),
});

const mapDispatchToProps = {
  loadOspAppList: actions.ospApp.loadOrgList,
  setCurrentOspAppForOrg: actions.ospApp.setCurrentForOrg,
};

class OspAppListForOrgLoader extends React.Component {
  static propTypes = {
    children: PropTypes.func,
    // loadFailed: PropTypes.bool,
    // loaded: PropTypes.bool,
    loading: PropTypes.bool,
    ospAppList: PropTypes.any,
    orgId: PropTypes.number,
    shouldLoad: PropTypes.bool,
  };

  componentWillMount() {
    this.loadData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.loadData(nextProps);
  }

  loadData = ({
    ospAppId,
    ospAppList,
    orgId,
    shouldLoad,
    loadOspAppList,
    setCurrentOspAppForOrg,
  }) => {
    if (shouldLoad && orgId) {
      return loadOspAppList(orgId);
    }

    // TODO: move this out of here? maybe this is fine
    if (ospAppId === null && Array.isArray(ospAppList) && ospAppList.length) {
      const defaultOspAppId = getDefaultOspAppId(ospAppList);
      if (defaultOspAppId) setCurrentOspAppForOrg(orgId, defaultOspAppId);
    }
  };

  render() {
    // if (this.props.loadFailed) {
    //   return <ErrorLoadingData />;
    // }

    if (this.props.loading) {
      return <Spinner />;
    }

    if (this.props.ospAppList) {
      return this.props.children(this.props.ospAppList);
    }

    return null;
  }
}

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(OspAppListForOrgLoader);
