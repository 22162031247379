import React from 'react';
import PropTypes from 'prop-types';

import FontAwesome from 'sow/components/atoms/FontAwesome';
import Link from 'sow/components/atoms/Link';
import Confirm from 'sow/components/molecules/Confirm';

const handleClick = cb => event => {
  event.preventDefault();
  cb();
};

const LogoutButton = ({ onLogout }) => (
  <Confirm message="Are you sure you want to log out?" onConfirm={onLogout}>
    {toggleConfirm => (
      <Link to="" onClick={handleClick(toggleConfirm)}>
        <FontAwesome icon="sign-out" /> Log Out
      </Link>
    )}
  </Confirm>
);

LogoutButton.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default LogoutButton;
