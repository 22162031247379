import * as R from 'ramda';
import { createSelector } from 'reselect';

import * as wsReducer from 'sow/reducers/worksheets';

export const worksheetState = state => wsReducer.getWorksheetState(state);

export const worksheetIdProp = (state, props) => R.path(['worksheetId'], props);

export const worksheetIdRouteParam = (state, props) =>
  R.path(['match', 'params', 'worksheetId'], props);

export const currentWorksheetId = createSelector(
  [worksheetIdProp, worksheetIdRouteParam],
  R.defaultTo,
);

export const getWorksheetById = R.curry((state, worksheetId) =>
  wsReducer.getWorksheet(state, worksheetId),
);

export const worksheetForWorksheetIdRouteParam = createSelector(
  [worksheetState, worksheetIdRouteParam],
  (state, worksheetId) => wsReducer.getWorksheet(state, worksheetId),
);

export const worksheetForCurrentWorksheetId = createSelector(
  [worksheetState, currentWorksheetId],
  wsReducer.getWorksheet,
);

// export const currentWorksheet = createSelector(
//   [worksheetForWorksheetIdRouteParam],
//   worksheet => worksheet,
// );

export const currentWorksheet = createSelector(
  [worksheetForCurrentWorksheetId],
  R.identity,
);
