import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

import TimezoneList from 'sow/containers/TimezoneList';

const createOptions = timezones => timezones.map(tz => ({ value: tz, label: tz }));

const TimezoneSelector = ({ value, disabled, required, onChange }) =>
  <TimezoneList>
    {(timezoneList, isFetching) =>
      <Select
        options={createOptions(timezoneList)}
        onChange={timezone => onChange(timezone.value)}
        value={value}
        disabled={disabled}
        required={required}
        isLoading={isFetching}
      />}
  </TimezoneList>;

TimezoneSelector.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

TimezoneSelector.defaultProps = {
  value: '',
  disabled: false,
  required: true,
};

export default TimezoneSelector;
