import React from 'react';
import * as R from 'ramda';

import FormGroup from 'sow/components/organisms/FormGroup';
import CountrySelect from 'sow/containers/CountrySelect';
import InfoPanel from 'sow/components/organisms/InfoPanel';

const getFieldValue = org => field => {
  const value = R.path([field], org);
  return R.defaultTo('', value);
};

const LocationForm = ({
  location,
  errors,
  handleChange,
  disabled,
  prefix = 'location',
}) => {
  const safeLocation = location || {};
  return (
    <div>
      <div className="row">
        <div className="col-xs-12">
          <FormGroup
            fieldName={`${prefix}-address`}
            errors={errors}
            label="Street Address"
            required
          >
            <input
              type="text"
              id={`${prefix}-address`}
              value={
                R.prop('address', safeLocation) || R.prop('address_text', safeLocation)
              }
              onChange={e => handleChange('address', e.target.value)}
              className="form-control"
              disabled={disabled}
              required
            />
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <FormGroup fieldName={`${prefix}-city`} errors={errors} label="City" required>
            <input
              type="text"
              id={`${prefix}-city`}
              value={R.prop('city', safeLocation) || ''}
              onChange={e => handleChange('city', e.target.value)}
              className="form-control"
              disabled={disabled}
              required
            />
          </FormGroup>
        </div>
        <div className="col-xs-4">
          <FormGroup fieldName={`${prefix}-state`} errors={errors} label="State" required>
            <input
              type="text"
              id={`${prefix}-state`}
              value={R.prop('state', safeLocation) || ''}
              onChange={e => handleChange('state', e.target.value)}
              className="form-control"
              disabled={disabled}
              required
            />
          </FormGroup>
        </div>

        <div className="col-xs-2">
          <FormGroup
            fieldName={`${prefix}-postal`}
            errors={errors}
            label="Postal Code"
            required
          >
            <input
              type="text"
              id={`${prefix}-postal`}
              value={R.prop('postal', safeLocation) || ''}
              onChange={e => handleChange('postal', e.target.value)}
              className="form-control"
              disabled={disabled}
              required
            />
          </FormGroup>
        </div>

        <div className="col-xs-12">
          <FormGroup
            fieldName={`${prefix}-country`}
            errors={errors}
            label="Country"
            required
          >
            <CountrySelect
              id={`${prefix}-country`}
              value={R.prop('country', safeLocation)}
              onChange={event => handleChange('country', event.target.value)}
              disabled={disabled}
              required
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default LocationForm;
