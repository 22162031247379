import React, { Component, Fragment } from 'react';
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import * as R from 'ramda';

import { locationRoute } from 'sow/routes';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';
import Paragraph from 'sow/components/atoms/Paragraph';

const { google } = window;

class OrgLocationsMap extends Component {
  constructor(props) {
    super(props);

    this.state = { show: {} };
  }

  componentDidMount() {
    const { locationList, locationCoords } = this.props;
    this.setMapBounds(locationList);
    const modLocationList = [];
    for (let i = 0; i < locationList.length; i++) {
      const sameCoordLocations = [];
      while (
        locationList[i + 1] &&
        locationList[i + 1].location_index == locationList[i].location_index
      ) {
        if (sameCoordLocations.length == 0) {
          sameCoordLocations.push(locationList[i]);
        }
        sameCoordLocations.push(locationList[i + 1]);

        i++;
      }
      if (sameCoordLocations.length == 0) {
        modLocationList.push(locationList[i]);
      } else {
        modLocationList.push(sameCoordLocations);
      }
    }
    this.setState({ locationList: modLocationList, show: locationCoords });
  }

  componentDidUpdate(prevState) {
    const { locationCoords } = this.props;

    if (this.state.show == {} || prevState.locationCoords != locationCoords) {
      this.map.panTo({
        lat: locationCoords.lat,
        lng: locationCoords.lng,
      });
      this.setState({ show: locationCoords });
    }
  }

  setMapBounds(locationList) {
    const bounds = new google.maps.LatLngBounds();
    locationList.map(location => {
      const curpoint = new google.maps.LatLng(location.geom.lat, location.geom.lng);

      bounds.extend(curpoint);
    });
    this.map.fitBounds(bounds);
  }

  onMarkerClick(event) {
    this.map.panTo({ lat: event.latLng.lat(), lng: event.latLng.lng() });
    this.setState({ show: { lat: event.latLng.lat(), lng: event.latLng.lng() } });
  }

  onInfoWindowClose() {
    this.setState({ show: {} });
  }

  render() {
    const { locationList } = this.state;
    if (!locationList) {
      return (
        <GoogleMap
          options={{ maxZoom: 15 }}
          ref={ref => {
            this.map = ref;
          }}
          mapTypeId={google.maps.MapTypeId.ROADMAP}
        ></GoogleMap>
      );
    }
    return (
      <GoogleMap
        options={{ maxZoom: 15 }}
        ref={ref => {
          this.map = ref;
        }}
        mapTypeId={google.maps.MapTypeId.ROADMAP}
      >
        {locationList.map(location => {
          const multipleLocations = location.length > 0;
          const locationData = multipleLocations > 0 ? location[0] : location;
          return (
            <Fragment key={locationData.id}>
              <Marker
                position={{
                  lat: Number(locationData.geom.lat),
                  lng: Number(locationData.geom.lng),
                }}
                icon={{
                  path: google.maps.SymbolPath.CIRCLE,
                  fillColor: 'red',
                  fillOpacity: 1,
                  scale: 16,
                  strokeColor: 'white',
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
                label={{
                  color: 'white',
                  text: `${locationData.location_index}`,
                  fontWeight: 'bold',
                  fontSize: '15px',
                }}
                onClick={event => this.onMarkerClick(event)}
              >
                {JSON.stringify(this.state.show) == JSON.stringify(locationData.geom) && (
                  <InfoWindow
                    position={{
                      lat: Number(locationData.geom.lat),
                      lng: Number(locationData.geom.lng),
                    }}
                    onCloseClick={this.onInfoWindowClose.bind(this)}
                  >
                    <Block className="location-info-window">
                      {multipleLocations ? (
                        location.map((sublocation, index) => (
                          <Fragment key={sublocation.id}>
                            <Paragraph className="info-heading">
                              {sublocation.name}
                            </Paragraph>
                            {sublocation.location.address ? (
                              <Fragment>
                                <Paragraph>{sublocation.location.address}</Paragraph>
                                <Paragraph>
                                  {sublocation.location.city},{' '}
                                  {sublocation.location.state}{' '}
                                  {sublocation.location.postal}
                                </Paragraph>
                              </Fragment>
                            ) : (
                              <Paragraph>{sublocation.location.full_address}</Paragraph>
                            )}
                            <Link
                              to={locationRoute(
                                this.props.orgId,
                                sublocation.app_id,
                                sublocation.id,
                              )}
                            >
                              View Location
                            </Link>
                            {index != location.length - 1 && (
                              <HorizontalRule className="info-divider" />
                            )}
                          </Fragment>
                        ))
                      ) : (
                        <Fragment>
                          <Paragraph className="info-heading">{location.name}</Paragraph>
                          {location.location.address ? (
                            <Fragment>
                              <Paragraph>{location.location.address}</Paragraph>
                              <Paragraph>
                                {location.location.city}, {location.location.state}{' '}
                                {location.location.postal}
                              </Paragraph>
                            </Fragment>
                          ) : (
                            <Paragraph>{location.location.full_address}</Paragraph>
                          )}
                          <Link
                            to={locationRoute(
                              this.props.orgId,
                              location.app_id,
                              location.id,
                            )}
                          >
                            View Location
                          </Link>
                        </Fragment>
                      )}
                    </Block>
                  </InfoWindow>
                )}
              </Marker>
            </Fragment>
          );
        })}
      </GoogleMap>
    );
  }
}

export default R.compose(withGoogleMap)(OrgLocationsMap);
