import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

const ThWrapper = styled.th``;
const TdWrapper = styled.td``;

/** Renders a `td` or `th` tag for use inside tables */
const TableCell = ({
  heading,
  children,
  className,
  xs,
  sm,
  md,
  lg,
  textCenter,
  textRight,
  valignTop,
  valignMiddle,
  valignBottom,
  textMuted,
  textSuccess,
  textInfo,
  textWarning,
  textDanger,
  success,
  info,
  warning,
  danger,
  ...props
}) => {
  const classes = classNames(className, {
    [`col-xs-${xs}`]: xs,
    [`col-sm-${sm}`]: sm,
    [`col-md-${md}`]: md,
    [`col-lg-${lg}`]: lg,
    'text-center': textCenter,
    'text-right': textRight,
    'text-muted': textMuted,
    'text-success': textSuccess,
    'text-info': textInfo,
    'text-warning': textWarning,
    'text-danger': textDanger,
    'valign-middle': valignMiddle,
    'valign-top': valignTop,
    'valign-bottom': valignBottom,
    success: success,
    info: info,
    warning: warning,
    danger: danger,
  });

  if (heading) {
    return (
      <ThWrapper className={classes} {...props}>
        {children}
      </ThWrapper>
    );
  }

  return (
    <TdWrapper className={classes} {...props}>
      {children}
    </TdWrapper>
  );
};

TableCell.propTypes = {
  heading: PropTypes.bool,
  children: PropTypes.any,
  style: PropTypes.object,
  className: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  textCenter: PropTypes.bool,
  textRight: PropTypes.bool,
  valignTop: PropTypes.bool,
  valignMiddle: PropTypes.bool,
  valignBottom: PropTypes.bool,
  textMuted: PropTypes.bool,
  textSuccess: PropTypes.bool,
  textInfo: PropTypes.bool,
  textWarning: PropTypes.bool,
  textDanger: PropTypes.bool,
  success: PropTypes.bool,
  info: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
};

export default TableCell;
