import { normalize } from 'normalizr';

import actions from 'sow/actions/pure';
import * as acaApi from 'sow/api/aca';
import * as schema from 'sow/schema';
import {
  fetchActivityLogAsync,
  fetchActivityLogOptionsAsync,
  fetchLogOspDefinitionAsync,
} from 'sow/services/aca';
import {
  fetchReportAsync,
  fetchInspectionReportAsync,
  fetchInspectionReportOptionsAsync,
  fetchUnannouncedReportAsync,
  fetchLocationReportOptionsAsync,
  fetchLocationReportAsync,
} from 'sow/services/aca';
import {
  fetchDefinitionWorksheets,
  worksheetListByQualification,
} from 'sow/utils/planAppWorksheets';

const mark = action => `soworganic/aca/${action}`;

export const LOAD_ALL_REQUEST = mark('LOAD_ALL_REQUEST');
export const LOAD_ALL_SUCCESS = mark('LOAD_ALL_SUCCESS');
export const LOAD_ALL_FAILURE = mark('LOAD_ALL_FAILURE');

export const loadAll = () => dispatch => {
  dispatch({ type: LOAD_ALL_REQUEST });

  acaApi
    .fetchAll()
    .done(res => {
      const data = normalize(res.data, [schema.aca]);
      dispatch({ type: LOAD_ALL_SUCCESS, data });
    })
    .fail(err => {
      dispatch({
        type: LOAD_ALL_FAILURE,
        data: { err },
      });
    });
};

export const loadAllRegister = () => dispatch => {
  dispatch({ type: LOAD_ALL_REQUEST });

  acaApi
    .fetchAllRegister()
    .done(res => {
      const data = normalize(res.data, [schema.aca]);
      dispatch({ type: LOAD_ALL_SUCCESS, payload: data });
    })
    .fail(err => {
      dispatch({
        type: LOAD_ALL_FAILURE,
        payload: { err },
      });
    });
};

export const FETCH_PENDING_REQUEST = mark('FETCH_PENDING_REQUEST');
export const FETCH_PENDING_SUCCESS = mark('FETCH_PENDING_SUCCESS');
export const FETCH_PENDING_FAILURE = mark('FETCH_PENDING_FAILURE');

export const fetchPending = acaId => dispatch => {
  dispatch({ type: FETCH_PENDING_REQUEST });

  acaApi
    .fetchPendingList(acaId)
    .done(res => {
      dispatch({
        type: FETCH_PENDING_SUCCESS,
        data: res.data,
      });
    })
    .fail(err => {
      console.error('Error fetching aca pending:', err.responseJSON);
      dispatch({ type: FETCH_PENDING_FAILURE });
    });
};

export const FETCH_ACA_ACTIVITY_LOG = mark('FETCH_ACA_ACTIVITY_LOG');
export const FETCH_ACA_ACTIVITY_LOG_SUCCESS = mark('FETCH_ACA_ACTIVITY_LOG_SUCCESS');
export const FETCH_ACA_ACTIVITY_LOG_FAILED = mark('FETCH_ACA_ACTIVITY_LOG_FAILED');
export const RESET_ACA_ACTIVITY_LOG = mark('RESET_ACA_ACTIVITY_LOG');

export const resetActivityLog = () => dispatch =>
  dispatch({ type: RESET_ACA_ACTIVITY_LOG });

export const fetchActivityLog = (acaId, filters) => dispatch => {
  dispatch({ type: FETCH_ACA_ACTIVITY_LOG });

  fetchActivityLogAsync(acaId, filters)
    .done(res => {
      dispatch({
        type: FETCH_ACA_ACTIVITY_LOG_SUCCESS,
        data: res.data,
        entriesData: {
          pageNumber: res.page_number,
          totalPages: res.total_pages,
          totalEntries: res.total_entries,
          pageSize: res.page_size,
          ospDefIds: res.osp_definition_ids,
        },
      });
    })
    .fail(err => {
      console.error('Error fetching aca activity log:', err.responseJSON);
      dispatch({ type: FETCH_ACA_ACTIVITY_LOG_FAILED });
    });
};

export const FETCH_ACA_ACTIVITY_LOG_OPTIONS = mark('FETCH_ACA_ACTIVITY_LOG_OPTIONS');
export const FETCH_ACA_ACTIVITY_LOG_OPTIONS_SUCCESS = mark(
  'FETCH_ACA_ACTIVITY_LOG_OPTIONS_SUCCESS',
);
export const FETCH_ACA_ACTIVITY_LOG_OPTIONS_FAILED = mark(
  'FETCH_ACA_ACTIVITY_LOG_OPTIONS_FAILED',
);

export const fetchActivityLogOptions = acaId => dispatch => {
  dispatch({ type: FETCH_ACA_ACTIVITY_LOG_OPTIONS });

  fetchActivityLogOptionsAsync(acaId)
    .done(res => {
      dispatch({
        type: FETCH_ACA_ACTIVITY_LOG_OPTIONS_SUCCESS,
        data: res.data,
      });
    })
    .fail(err => {
      console.error('Error fetching aca activity log options:', err.responseJSON);
      dispatch({ type: FETCH_ACA_ACTIVITY_LOG_FAILED });
    });
};

export const FETCH_LOG_OSP_DEFINITION = mark('FETCH_LOG_OSP_DEFINITION');
export const FETCH_LOG_OSP_DEFINITION_SUCCESS = mark('FETCH_LOG_OSP_DEFINITION_SUCCESS');
export const FETCH_LOG_OSP_DEFINITION_FAILED = mark('FETCH_LOG_OSP_DEFINITION_FAILED');

export const fetchLogOspDefinition = (acaId, defId) => dispatch => {
  dispatch({ type: FETCH_LOG_OSP_DEFINITION });

  fetchLogOspDefinitionAsync(acaId, defId)
    .done(res => {
      const worksheets = fetchDefinitionWorksheets(res.data.definition);
      const qualificationList = worksheetListByQualification(res.data.definition);
      dispatch({
        type: FETCH_LOG_OSP_DEFINITION_SUCCESS,
        data: { [res.data.id]: worksheets },
        qualificationList: { [res.data.id]: qualificationList },
      });
    })
    .fail(err => {
      console.error('Error fetching osp definition:', err.responseJSON);
      dispatch({ type: FETCH_LOG_OSP_DEFINITION_FAILED });
    });
};

export const FETCH_ACA_REPORT = mark('FETCH_ACA_ACTIVITY_LOG');
export const FETCH_ACA_REPORT_SUCCESS = mark('FETCH_ACA_REPORT_SUCCESS');
export const FETCH_ACA_REPORT_FAILED = mark('FETCH_ACA_REPORT_FAILED');
export const RESET_ACA_REPORT = mark('RESET_ACA_REPORT');

export const resetReport = () => dispatch => dispatch({ type: RESET_ACA_REPORT });

export const fetchReport = (acaId, reportId) => dispatch => {
  dispatch({ type: FETCH_ACA_REPORT });

  fetchReportAsync(acaId, reportId)
    .done(res => {
      dispatch({
        type: FETCH_ACA_REPORT_SUCCESS,
        data: res.data,
      });
    })
    .fail(err => {
      console.error('Error fetching report:', err.responseJSON);
      dispatch({ type: FETCH_ACA_REPORT_FAILED });
    });
};

export const FETCH_TIMEZONE_LIST = mark('FETCH_TIMEZONE_LIST');
export const FETCH_TIMEZONE_LIST_SUCCESS = mark('FETCH_TIMEZONE_LIST_SUCCESS');
export const FETCH_TIMEZONE_LIST_FAILED = mark('FETCH_TIMEZONE_LIST_FAILED');

export const fetchTimezoneList = () => dispatch => {
  dispatch({ type: FETCH_TIMEZONE_LIST });

  acaApi
    .fetchTimezoneList()
    .done(res => {
      dispatch({
        type: FETCH_TIMEZONE_LIST_SUCCESS,
        data: { items: res.data },
      });
    })
    .fail(err => {
      dispatch({
        type: FETCH_TIMEZONE_LIST_FAILED,
        data: err.responseJSON,
      });
    });
};

export const UPDATE_ONE_REQUEST = mark('UPDATE_ONE_REQUEST');
export const UPDATE_ONE_SUCCESS = mark('UPDATE_ONE_SUCCESS');
export const UPDATE_ONE_FAILURE = mark('UPDATE_ONE_FAILURE');

export const updateOne = (acaId, data) => dispatch => {
  dispatch({ type: UPDATE_ONE_REQUEST });

  acaApi
    .updateOne(acaId, data)
    .done(res => {
      dispatch({
        type: UPDATE_ONE_SUCCESS,
        data: res.data,
      });

      dispatch(
        actions.shell.toast(
          'success',
          'ACA updated. You may need to reload the page to see all changes.',
        ),
      );
    })
    .fail(err => {
      dispatch({
        type: UPDATE_ONE_FAILURE,
        data: err.responseJSON,
      });
    });
};

export const LOAD_NEW_REGISTRATIONS_LIST = mark('LOAD_NEW_REGISTRATIONS_LIST');
export const LOAD_NEW_REGISTRATIONS_LIST_DONE = mark('LOAD_NEW_REGISTRATIONS_LIST_DONE');
export const LOAD_NEW_REGISTRATIONS_LIST_FAIL = mark('LOAD_NEW_REGISTRATIONS_LIST_FAIL');

export const fetchNewRegistrations = acaId => dispatch => {
  dispatch({
    type: LOAD_NEW_REGISTRATIONS_LIST,
  });

  acaApi
    .fetchNewRegistrations(acaId)
    .done(res =>
      dispatch({
        type: LOAD_NEW_REGISTRATIONS_LIST_DONE,
        payload: res.data,
      }),
    )
    .fail(err =>
      dispatch({
        type: LOAD_NEW_REGISTRATIONS_LIST_FAIL,
        payload: err,
      }),
    );
};

export const LOAD_IN_PROGRESS_LIST = mark('LOAD_IN_PROGRESS_LIST');
export const LOAD_IN_PROGRESS_LIST_DONE = mark('LOAD_IN_PROGRESS_LIST_DONE');
export const LOAD_IN_PROGRESS_LIST_FAIL = mark('LOAD_IN_PROGRESS_LIST_FAIL');

export const fetchInProgress = acaId => dispatch => {
  dispatch({ type: LOAD_IN_PROGRESS_LIST });

  acaApi
    .fetchInProgress(acaId)
    .done(res =>
      dispatch({
        type: LOAD_IN_PROGRESS_LIST_DONE,
        payload: res.data,
      }),
    )
    .fail(err =>
      dispatch({
        type: LOAD_IN_PROGRESS_LIST_FAIL,
        payload: err,
      }),
    );
};

export const FETCH_INSPECTION_REPORT = mark('FETCH_INSPECTION_REPORT');
export const FETCH_INSPECTION_REPORT_SUCCESS = mark('FETCH_INSPECTION_REPORT_SUCCESS');
export const FETCH_INSPECTION_REPORT_FAIL = mark('FETCH_INSPECTION_REPORT_FAIL');
export const FETCH_INSPECTION_REPORT_OPTIONS = mark('FETCH_INSPECTION_REPORT_OPTIONS');
export const FETCH_INSPECTION_REPORT_OPTIONS_SUCCESS = mark(
  'FETCH_INSPECTION_REPORT_OPTIONS_SUCCESS',
);
export const FETCH_INSPECTION_REPORT_OPTIONS_FAIL = mark(
  'FETCH_INSPECTION_REPORT_OPTIONS_FAIL',
);
export const CLEAR_INSPECTION_REPORT_FILTER = mark('CLEAR_INSPECTION_REPORT_FILTER');
export const FETCH_UNANNOUNCED_REPORT = mark('FETCH_UNANNOUNCED_REPORT');
export const FETCH_UNANNOUNCED_REPORT_SUCCESS = mark('FETCH_UNANNOUNCED_REPORT_SUCCESS');
export const FETCH_UNANNOUNCED_REPORT_FAIL = mark('FETCH_UNANNOUNCED_REPORT_FAIL');

export const fetchInspectionReport = (acaId, filters) => dispatch => {
  dispatch({ type: FETCH_INSPECTION_REPORT });

  fetchInspectionReportAsync(acaId, filters)
    .done(res => {
      const hasResults = res.data.length > 0;

      dispatch({
        type: FETCH_INSPECTION_REPORT_SUCCESS,
        payload: { hasResults, list: res.data },
      });
    })
    .fail(err => {
      dispatch({ type: FETCH_INSPECTION_REPORT_FAIL });
    });
};

export const fetchInspectionReportOptions = acaId => dispatch => {
  dispatch({ type: FETCH_INSPECTION_REPORT_OPTIONS });

  fetchInspectionReportOptionsAsync(acaId)
    .done(res => {
      dispatch({
        type: FETCH_INSPECTION_REPORT_OPTIONS_SUCCESS,
        payload: { ...res.data },
      });
    })
    .fail(err => {
      dispatch({ type: FETCH_INSPECTION_REPORT_OPTIONS_FAIL });
    });
};

export const clearInspectionReportFilters = () => dispatch => {
  dispatch({ type: CLEAR_INSPECTION_REPORT_FILTER });
};

export const fetchUnannouncedReport = (acaId, filters) => dispatch => {
  dispatch({ type: FETCH_UNANNOUNCED_REPORT });
  fetchUnannouncedReportAsync(acaId, filters)
    .done(res => {
      dispatch({
        type: FETCH_UNANNOUNCED_REPORT_SUCCESS,
        payload: { ...res.data },
      });
    })
    .fail(err => {
      dispatch({ type: FETCH_UNANNOUNCED_REPORT_FAIL });
    });
};

export const FETCH_LOCATION_REPORT_OPTIONS = mark('FETCH_LOCATION_REPORT_OPTIONS');
export const FETCH_LOCATION_REPORT_OPTIONS_DONE = mark(
  'FETCH_LOCATION_REPORT_OPTIONS_DONE',
);
export const FETCH_LOCATION_REPORT = mark('FETCH_LOCATION_REPORT');
export const FETCH_LOCATION_REPORT_DONE = mark('FETCH_LOCATION_REPORT_DONE');
export const CLEAR_LOCATION_REPORT = mark('CLEAR_LOCATION_REPORT');

export const fetchLocationReportOptions = (acaId, filters) => dispatch => {
  dispatch({ type: FETCH_LOCATION_REPORT_OPTIONS });
  fetchLocationReportOptionsAsync(acaId, filters).done(res =>
    dispatch({
      type: FETCH_LOCATION_REPORT_OPTIONS_DONE,
      payload: { ...res.data },
    }),
  );
};

export const fetchLocationReport = (acaId, filters) => dispatch => {
  dispatch({ type: FETCH_LOCATION_REPORT });
  fetchLocationReportAsync(acaId, filters).done(res => {
    const hasResults = res.data.length > 0;
    dispatch({
      type: FETCH_LOCATION_REPORT_DONE,
      payload: { hasResults, list: res.data },
    });
  });
};

export const clearLocationReport = () => dispatch => {
  dispatch({ type: CLEAR_LOCATION_REPORT });
};
