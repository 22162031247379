import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { Modal } from 'react-bootstrap';

import { sampleStates } from 'sow/constants/sampleConstants';
import { inspectionStates } from 'sow/constants/inspectionConstants';
import { inspectionDashboardDone } from 'sow/actions/orgWorkflow';
import { getFilter } from 'sow/utils/dashboardFilterStorage';

import Button from 'sow/components/atoms/Button';
import List from 'sow/components/atoms/List';
import ListItem from 'sow/components/atoms/ListItem';

const mapStateToProps = state => ({
  acaId: () => {
    if (state.auth.metadata.orgs_v2[0]) {
      return state.auth.metadata.orgs_v2[0].aca_id;
    }
  },
});

const mapDispatchToProps = { inspectionDashboardDone };

class InspectionDoneModal extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    const sampleShow = {};
    if (this.props.inspection.has_sample) {
      for (let i = 0; i < this.props.inspection.samples.length; i++) {
        sampleShow[this.props.inspection.samples[i].id] = false;
      }
    }
    this.state = {
      ...sampleShow,
      inspectionDone: false,
      samplesUpdated: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setVisiblity = (id, show) => () => {
    this.setState(R.assoc(id, show));
  };

  handleYes = (currentId, nextId) => {
    this.setState(
      {
        samplesUpdated: [
          ...this.state.samplesUpdated,
          { sample_id: currentId, sample_state_id: sampleStates.collectedPendingResults },
        ],
      },
      () => {
        this.setVisiblity(currentId, false)();
        this.setVisiblity(nextId, true)();
      },
    );
  };

  handleNo = (currentId, nextId) => {
    this.setState(
      {
        samplesUpdated: [
          ...this.state.samplesUpdated,
          { sample_id: currentId, sample_state_id: sampleStates.unsuccessful },
        ],
      },
      () => {
        this.setVisiblity(currentId, false)();
        this.setVisiblity(nextId, true)();
      },
    );
  };

  handleCancel = currentId => {
    this.setState(
      { samplesUpdated: [], samplesUpdated: [] },
      this.setVisiblity(currentId, false)(),
    );
  };

  handleSave = () => {
    const { samplesUpdated } = this.state;
    const { annual, inspection, inspectionDashboardDone } = this.props;
    inspectionDashboardDone(
      inspection.organization_id,
      inspection.app_id,
      {
        annual,
        sample_updates: samplesUpdated,
        inspection_updates: annual
          ? { state: 'final_review' }
          : {
              inspection_id: inspection.inspection_id,
              inspection_state_id: inspectionStates.readyForFinalReview,
            },
        dashboard_config: { filter: getFilter(), aca_id: String(this.props.acaId()) },
      },
      this.setVisiblity('inspectionDone', false),
    );
  };

  render() {
    const { children, inspection } = this.props;
    const { samples } = inspection;

    return (
      <Fragment>
        {children(
          this.setVisiblity(
            inspection.has_sample ? samples[0].id : 'inspectionDone',
            true,
          ),
        )}
        {samples &&
          samples.map((sample, index) => (
            <Modal
              backdrop="static"
              key={sample.id}
              show={this.state[sample.id]}
              onHide={() => this.handleCancel(sample.id)}
            >
              <Modal.Header closeButton>
                <Modal.Title>{sample.type_name} Sample</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                Were you able to collect the {sample.type_name} from the organization?
              </Modal.Body>

              <Modal.Footer>
                <Button
                  onClick={() =>
                    this.handleNo(
                      sample.id,
                      samples[index + 1] ? samples[index + 1].id : 'inspectionDone',
                    )
                  }
                >
                  No
                </Button>
                <Button
                  primary
                  onClick={() =>
                    this.handleYes(
                      sample.id,
                      samples[index + 1] ? samples[index + 1].id : 'inspectionDone',
                    )
                  }
                >
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>
          ))}
        <Modal
          key={'inspectionDone'}
          show={this.state.inspectionDone}
          onHide={() => this.handleCancel('inspectionDone')}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              Please verify that the following documents have been uploaded to the SOW
              general attachments before clicking save:
            </p>
            <List>
              <ListItem>Inspection Report</ListItem>
              <ListItem>Exit Interview</ListItem>
              <ListItem>
                If sample was collected: chain of custody & receipt provided to operation
              </ListItem>
            </List>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.handleCancel('inspectionDone')}>Cancel</Button>
            <Button primary onClick={this.handleSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default R.compose(connect(mapStateToProps, mapDispatchToProps))(
  InspectionDoneModal,
);
