import React, { Fragment } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { resourceUpdate } from 'sow/store/helpers';

import Button from 'sow/components/atoms/Button';
import Glyphicon from 'sow/components/molecules/Glyphicon';

const getCreateChangeResource = (orgId, planAppId, changeRequestId) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`,
    'changeRequestOverviewResp',
  );

const mapStateToProps = (state, props) => {
  const { selectors } = getCreateChangeResource(
    props.orgId,
    props.planAppId,
    props.changeRequestId,
  );
  return {
    isCreating: selectors.pending(state, props),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    orgId,
    planAppId,
    changeRequestId,
    worksheetId,
    field,
    form,
    changeFieldName,
  } = ownProps;
  const { action } = getCreateChangeResource(orgId, planAppId, changeRequestId);

  const newChange = {
    applicationId: planAppId,
    changeRequestId,
    worksheetId,
    context: 'main',
    type: 'worksheet',
    action: 'updated',
    old: { isNotApplicable: field.value },
    new: { isNotApplicable: field.value },
  };

  return {
    createChange: () =>
      dispatch(action(null, { changes: [newChange] })).then(() =>
        form.setFieldValue(changeFieldName, field.value),
      ),
  };
};

const PlanAppWorksheetCreateChangeButtonContainer = ({
  createChange,
  showAcaUI,
  disabled,
}) => {
  return (
    <Button
      sm
      warning={showAcaUI}
      primary={!showAcaUI}
      onClick={createChange}
      disabled={disabled}
    >
      {showAcaUI ? (
        <Fragment>
          <Glyphicon glyph="flag" /> Request Changes
        </Fragment>
      ) : (
        <Fragment>
          <Glyphicon glyph="pencil" /> Make Changes
        </Fragment>
      )}
    </Button>
  );
};

PlanAppWorksheetCreateChangeButtonContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheetId: sowTypes.worksheetIdType.isRequired,
  changeRequestId: sowTypes.planAppChangeRequestIdType.isRequired,
  showAcaUI: PropTypes.bool,
  createChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

PlanAppWorksheetCreateChangeButtonContainer.defaultProps = {
  matrixRowId: null,
  locationId: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanAppWorksheetCreateChangeButtonContainer);
