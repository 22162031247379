import React from 'react';

import { ButtonToolbar, DropdownButton, MenuItem } from 'react-bootstrap';

export default ({ handleEditClick, handleDeleteClick, disabled }) =>
  <ButtonToolbar>
    <DropdownButton
      id="matrixRowActionsButton"
      bsSize="sm"
      title={<span className="fa fa-cog" />}
      pullRight
      disabled={disabled}
    >
      <MenuItem id="editButton" onClick={handleEditClick}>
        <span className="fa fa-pencil" /> Edit Row
      </MenuItem>
      <MenuItem id="deleteButton" onClick={handleDeleteClick}>
        <span className="fa fa-remove" /> Delete Row
      </MenuItem>
    </DropdownButton>
  </ButtonToolbar>;
