import * as R from 'ramda';
import { combineReducers } from 'redux';
import byId, * as fromById from './byId';
import listByOspAppId, * as fromListByOspAppId from './listByOspAppId';
import listByOspDefId, * as fromListByOspDefId from './listByOspDefId';

export default combineReducers({
  byId,
  listByOspAppId,
  listByOspDefId,
});

export const getState = R.prop('ospQualificationQuestions');

export const getByIdState = R.compose(R.prop('byId'), getState);

export const getListByOspAppIdState = R.compose(R.prop('listByOspAppId'), getState);

export const getListByOspDefIdState = R.compose(R.prop('listByOspDefId'), getState);

export const getQuestion = R.curry((state, id) => fromById.getQuestion(state, id));

export const getIdsForOspAppId = (state, ospAppId) =>
  fromListByOspAppId.getIds(state, ospAppId);

export const getIdsForOspDefId = (state, ospDefId) =>
  fromListByOspDefId.getIds(state, ospDefId);
