import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as itemVersionPicklistActions from 'sow/actions/pure/itemVersionPicklist';
import { apiGet, apiPost, apiPut } from 'sow/sagas/api';

export const loadItemVersionPicklists = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/item_version_picklist', payload);

    if (response && meta.onSuccess) {
      yield put(itemVersionPicklistActions.loadItemVersionPicklistsDone(response));
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchById = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/item_version_picklist/${payload}`);

    if (response && meta.onSuccess) {
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addItemVersionPicklist = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/item_version_picklist', payload);

    if (response && meta.onSuccess) {
      yield put(actions.shell.toast('success', 'Item version picklist created.'));
      meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateItemVersionPicklist = function*({ id, payload, meta }) {
  try {
    const { response } = yield call(
      apiPut,
      `/admin/item_version_picklist/${id}`,
      payload,
    );

    if (response && meta.onSuccess) {
      meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteItemVersionPicklist = function*({ id, meta }) {
  try {
    const { response } = yield call(apiPut, `/admin/item_version_picklist/delete/${id}`);
    if (response) {
      yield put(actions.shell.toast('success', 'Item version picklist deleted.'));
      if (meta.onSuccess) meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(
    itemVersionPicklistActions.LOAD_ITEM_VERSION_PICKLISTS,
    loadItemVersionPicklists,
  );
  yield takeEvery(itemVersionPicklistActions.FETCH_BY_ID, fetchById);
  yield takeEvery(
    itemVersionPicklistActions.ADD_ITEM_VERSION_PICKLIST,
    addItemVersionPicklist,
  );
  yield takeEvery(
    itemVersionPicklistActions.UPDATE_ITEM_VERSION_PICKLIST,
    updateItemVersionPicklist,
  );
  yield takeEvery(
    itemVersionPicklistActions.DELETE_ITEM_VERSION_PICKLIST,
    deleteItemVersionPicklist,
  );
}
