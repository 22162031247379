import React from 'react';
import Dropzone from 'react-dropzone';

import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import Image from 'sow/components/atoms/Image';
import Confirm from 'sow/components/molecules/Confirm';

const ImgFileUpload = ({ handleUpload, handleRemove, queue, imageUrl, deleteImage }) => (
  <Block className="img-file-upload">
    {queue.length > 0
      ? queue.map(image => (
          <Block className="image-block" key={image.guid}>
            <Image className="upload-image" src={image.file.preview} alt={image.name} />
            <Button className="btn btn-danger remove-icon" onClick={handleRemove(image)}>
              <span className="glyphicon glyphicon-remove" />
            </Button>
          </Block>
        ))
      : imageUrl && (
          <Block className="image-block">
            <Image className="upload-image" imageUrl={imageUrl} />
            <Confirm
              message="Are you sure you want to remove this image?"
              onConfirm={deleteImage}
            >
              {toggleConfirm => (
                <Button className="btn btn-danger remove-icon" onClick={toggleConfirm}>
                  <span className="glyphicon glyphicon-remove" />
                </Button>
              )}
            </Confirm>
          </Block>
        )}
    <Dropzone className="image-dropzone" onDrop={handleUpload}>
      <Button className="upload-button" disabled={queue.length > 0}>
        Upload Image
      </Button>
    </Dropzone>
  </Block>
);

export default ImgFileUpload;
