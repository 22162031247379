import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import ActionGroupListContainer from 'sow/containers/ActionGroupListContainer';

const ActionGroupPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <ActionGroupListContainer />}
  </AdminPageTemplate>
);

export default ActionGroupPage;
