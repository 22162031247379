import * as R from 'ramda';
import { createSelector } from 'reselect';

export const authState = state => state.auth;

export const isAuthenticating = createSelector([authState], R.prop('isAuthenticating'));

export const isValidating = createSelector([authState], R.prop('isValidating'));

export const metadata = createSelector([authState], R.prop('metadata'));

export const roles = createSelector([authState], R.prop('roles'));

export const userId = createSelector([authState], R.prop('user_id'));

export const impersonating = createSelector([authState], R.prop('impersonating'));

// metadata based
export const acaIds = createSelector([metadata], R.propOr([], 'acas'));

export const acaId = createSelector([acaIds], R.head);

export const orgIds = createSelector([metadata], R.propOr([], 'orgs'));

export const orgId = createSelector([orgIds], R.head);

export const orgData = createSelector([metadata], R.propOr([], 'orgs_v2'));

export const activeOrgs = createSelector([orgData], R.filter(R.propEq('active', true)));

export const name = createSelector([metadata], R.prop('name'));

export const username = createSelector([metadata], R.prop('username'));

export const settings = createSelector([metadata], R.prop('settings'));
