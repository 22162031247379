import React from 'react';
import PropTypes from 'prop-types';

import Link from 'sow/components/atoms/Link';

/** Renders a list item that contains a link tag, for use inside ListGroup component */
const ListGroupItem = ({
  active,
  button,
  className,
  disabled,
  success,
  info,
  warning,
  danger,
  ...props
}) => {
  let cssClasses = ['list-group-item'];

  // contexts
  if (success) cssClasses[1] = 'list-group-item-success';
  if (info) cssClasses[1] = 'list-group-item-info';
  if (warning) cssClasses[1] = 'list-group-item-warning';
  if (danger) cssClasses[1] = 'list-group-item-danger';

  if (active) cssClasses.push('active');
  if (disabled) cssClasses.push('disabled');
  if (className) cssClasses.push(className);

  const cssClassNames = cssClasses.join(' ');

  if (props.to) {
    return <Link {...props} className={cssClassNames} />;
  } else if (props.href) {
    return <a {...props} className={cssClassNames} />;
  } else if (button) {
    return <button type="button" {...props} className={cssClassNames} />;
  }

  return <li {...props} className={cssClassNames} />;
};

ListGroupItem.propTypes = {
  active: PropTypes.bool,
  button: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,

  // contexts
  success: PropTypes.bool,
  info: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
};

export default ListGroupItem;
