import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import UserManagementTemplateContainer from 'sow/containers/UserManagementTemplateContainer';
import UserManagementEditUser from 'sow/containers/UserManagementEditUser';

const UserManagementEditUserPage = () => (
  <AdminPageTemplate title="Organization User Lookup">
    {() => (
      <UserManagementTemplateContainer>
        <UserManagementEditUser />
      </UserManagementTemplateContainer>
    )}
  </AdminPageTemplate>
);

export default UserManagementEditUserPage;
