import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import PriorityTypeListContainer from 'sow/containers/PriorityTypeListContainer';

const PriorityTypeListPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <PriorityTypeListContainer />}
  </AdminPageTemplate>
);

export default PriorityTypeListPage;
