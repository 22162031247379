import React from 'react';
import Select from 'react-select';

import FormGroup from 'sow/components/organisms/FormGroup';

const SelectFormGroup = ({
  className,
  fieldName,
  label,
  options,
  disabled,
  value,
  clearable,
  onChange,
  multi,
  placeholder,
}) => (
  <FormGroup className={className} fieldName={fieldName} label={label}>
    <Select
      ignoreAccents={false}
      clearable={clearable}
      multi={multi}
      value={value}
      onChange={target => onChange(target, fieldName)}
      options={options}
      disabled={disabled}
      placeholder={placeholder}
    />
  </FormGroup>
);

export default SelectFormGroup;
