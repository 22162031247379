import * as R from 'ramda';

const baseOspDef = {
  // 'updated_at': '2016-10-18T20:49:50',
  published: false,
  name: null,
  // 'inserted_at': '2016-10-18T20:49:50',
  // 'id': 2,
  definition: {
    sections: [],
    scopes: [
      'crop',
      'textile',
      'livestock',
      'restaurant',
      'retail',
      'handler',
      'personal care',
    ],
    qualifications: {
      questions: [],
    },
    product: {},
    land: {},
  },
};

export const genOspDef = ({ name = 'New Plan Definition' }) => {
  return R.compose(R.assoc('name', name), R.clone)(baseOspDef);
};
