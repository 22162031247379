import * as coreSelectors from './selectors/core';
import * as changeOverviewSelectors from './selectors/changeOverview';
import * as formSelectors from './selectors/form';
import * as exportSelectors from './selectors/export';
import * as locationSelectors from './selectors/location';
import * as locationFormSelectors from './selectors/locationForm';

export const selectors = {
  ...changeOverviewSelectors,
  ...coreSelectors,
  ...exportSelectors,
  ...formSelectors,
  ...locationFormSelectors,
  ...locationSelectors,
};
