import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Block from 'sow/components/atoms/Block';

const Alert = ({ success, info, warning, danger, className, children, ...props }) => {
  const divClasses = classnames(
    'alert',
    { 'alert-success': success },
    { 'alert-info': info },
    { 'alert-warning': warning },
    { 'alert-danger': danger },
    className,
  );

  return (
    <Block className={divClasses} {...props}>
      {children}
    </Block>
  );
};

Alert.propTypes = {
  success: PropTypes.bool,
  info: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Alert;
