import { call, put, takeEvery } from 'redux-saga/effects';
import * as R from 'ramda';
import { normalize } from 'normalizr';

import actions from 'sow/actions/pure';
import * as ospDefAction from 'sow/actions/pure/ospDefinition';
import * as ospDefApi from 'sow/api/newApi/aca/ospDefinition';
import * as schema from 'sow/schema';

export function* updateOne({ meta, payload }) {
  try {
    const { acaId, ospDef } = payload;

    // TODO (orther) split this to use custom action type OR use saga state
    //  e.g. https://redux-saga.github.io/redux-saga/docs/recipes/index.html#undo
    const forceSaveOspDef = R.assoc('force_change', true, ospDef);
    const resp = yield call(ospDefApi.updateOne, acaId, forceSaveOspDef); // <<-- always overwrites
    // const resp = yield call(ospDefApi.updateOne, acaId, ospDef);

    // TODO (orther) create general normalizr saga to handle this
    const data = yield call(normalize, resp.data, schema.ospDef);

    yield put(ospDefAction.updateOneDone(data));

    if (typeof meta.onSuccess === 'function') {
      yield call(meta.onSuccess);
    }
  } catch (error) {
    yield put(ospDefAction.updateOneFail(error));
    yield put(actions.api.requestError(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(ospDefAction.UPDATE_ONE, updateOne);
}
