import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'sow/components/atoms/Button';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Tooltip from 'sow/components/molecules/Tooltip';

const noop = () => {};

const PlanAppChangeCommentDismissButtonWrapper = styled(Button)`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const PlanAppChangeCommentDismissButton = ({ onClick, isDisabled }) => (
  <Tooltip overlay={`Marking the comment as read will remove it from the overview.`}>
    <PlanAppChangeCommentDismissButtonWrapper
      onClick={isDisabled ? noop : onClick}
      disabled={isDisabled}
      xs
      link
    >
      <FontAwesome icon="eye" /> Mark read
    </PlanAppChangeCommentDismissButtonWrapper>
  </Tooltip>
);

PlanAppChangeCommentDismissButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default PlanAppChangeCommentDismissButton;
