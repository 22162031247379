import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/** Clears floats */
const Clearfix = ({ className, ...props }) =>
  <div className={classnames('clearfix', className)} {...props} />;

Clearfix.propTypes = {
  /** CSS classes to be passed through */
  className: PropTypes.string,
};

export default Clearfix;
