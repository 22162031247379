import * as R from 'ramda';
import { createActions } from 'redux-actions';

const actionCreators = createActions({
  ORG: {
    RESET_CHILD_DATA: R.identity,

    ACTIVATE: orgId => ({ orgId }),
    DEACTIVATE: orgId => ({ orgId }),

    EXPORT: {
      RESET: R.identity,

      TOGGLE_TYPE: R.identity,
      TOGGLE_OSP_SECTION: R.identity,

      GENERATE: [
        (orgId, ospAppId, options) => ({ orgId, ospAppId, options }),
        () => ({ thunk: true }), // <- meta | ^- payload
      ],
      GENERATE_DONE: [url => ({ url }), (url, thunk) => ({ thunk })],
      GENERATE_FAIL: [error => error, (error, thunk) => ({ error: true, thunk })],
    },

    LOAD_ONE: orgId => ({ orgId }),
    LOAD_ONE_DONE: R.identity,
    LOAD_ONE_FAIL: (orgId, error) => ({ orgId, error }),

    LOAD_LIST: R.identity,
    LOAD_LIST_DONE: R.identity,
    LOAD_LIST_FAIL: R.identity,

    LOAD_ACA_LIST: acaId => ({ acaId }),
    LOAD_ACA_LIST_DONE: R.identity,
    LOAD_ACA_LIST_FAIL: R.identity,

    UPDATE_ONE: [org => ({ org }), (org, onSuccess) => ({ onSuccess })],
    UPDATE_ONE_DONE: R.identity,
    UPDATE_ONE_FAIL: R.identity,

    LIST_LOCATIONS: (orgId, appId) => ({ orgId, appId }),
    LIST_LOCATIONS_DONE: R.identity,

    ORG_SELECT_OPTIONS: acaId => ({ acaId }),
    ORG_SELECT_OPTIONS_DONE: results => results,

    ORG_SEARCH_OPTIONS: onSuccess => ({ meta: { onSuccess } }),

    ORG_SEARCH: (params, savedSearch) => ({
      params: params,
      savedSearch: savedSearch,
    }),
    ORG_SEARCH_DONE: results => results,

    ORG_SEARCH_CLEAR: R.identity,

    ORG_POC_USERS: orgId => ({ orgId }),
  },
});

export default actionCreators.org;
