import React from 'react';

import { itemVersionRoute } from 'sow/routes';

import AdminObjectTemplateContainer from 'sow/containers/AdminObjectTemplateContainer';
import ItemVersionHistoryContainer from 'sow/containers/ItemVersionHistoryContainer';

const ItemVersionInfoEditPage = () => (
  <AdminObjectTemplateContainer
    breadcrumbPath={[['Item Versions', itemVersionRoute()]]}
    statePath={['itemVersion']}
  >
    <ItemVersionHistoryContainer />
  </AdminObjectTemplateContainer>
);

export default ItemVersionInfoEditPage;
