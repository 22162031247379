import React, { Fragment } from 'react';

import { adminUsersLookupRoute } from 'sow/routes';
import { userTypeId } from 'sow/constants/userType';

import './UserManagementEditUser.module.css';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import Block from 'sow/components/atoms/Block';
import ListItem from 'sow/components/atoms/ListItem';
import List from 'sow/components/atoms/List';
import FormGroup from 'sow/components/organisms/FormGroup';
import Input from 'sow/components/atoms/Input';
import Button from 'sow/components/atoms/Button';
import Spinner from 'sow/components/atoms/Spinner';
import Table from 'sow/components/molecules/Table';
import TableCell from 'sow/components/atoms/TableCell';
import TableRow from 'sow/components/atoms/TableRow';
import Checkbox from 'sow/components/atoms/Checkbox';
import RadioButton from 'sow/components/atoms/RadioButton';
import UpdateModal from 'sow/components/molecules/UpdateUserModal';
import Paragraph from 'sow/components/atoms/Paragraph';
import Column from 'sow/components/molecules/Column';
import Panel from 'sow/components/molecules/Panel';
import Link from 'sow/components/atoms/Link';
import Clearfix from 'sow/components/atoms/Clearfix';
import DataTable from 'sow/components/molecules/DataTable';
import Confirm from 'sow/components/molecules/Confirm';
import ConfirmModal from 'sow/components/molecules/ConfirmModal';
import SelectFormGroup from 'sow/components/molecules/SelectFormGroup';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import UserPriorityPopover from './UserPriorityPopover';
import PriorityBubble from 'sow/components/molecules/PriorityBubble';

const UserManagementEditUser = ({
  handleInputChange,
  onSaveHandler,
  state,
  userData,
  acaList,
  onAssignHandler,
  dropDownHandler,
  handleOptionChange,
  onLoginAsUser,
  onRevokeHandler,
  showModal,
  closeModal,
  onModalSave,
  handleEmailConfirm,
  onSavePrimary,
  onCancelPrimary,
  fetchingOptions,
  selectOptions,
}) => {
  if ((!userData && !state.isNew) || !acaList || !userData || fetchingOptions) {
    return <Spinner />;
  }

  const filteredUserData = userData.filter(user => user.orgId);

  let userChanges = state.isNew ? (
    <p>Click Save To Confirm New User</p>
  ) : (
    <p>No Changes Made</p>
  );

  const checkForEdits = () => {
    if (JSON.stringify(state.userEdits) !== JSON.stringify(state.currentSetup)) {
      const changesArray = [];
      for (const property in state.userEdits) {
        const columnProperty =
          String(state.userEdits[property])
            .charAt(0)
            .toUpperCase() + String(state.userEdits[property]).slice(1);
        if (state.userEdits[property] !== state.currentSetup[property]) {
          switch (property) {
            case 'name':
              changesArray.push('Name');
              break;
            case 'email':
              changesArray.push('Email');
              break;
            case 'password':
              changesArray.push('Password');
              break;
            case 'cs':
              changesArray.push(`Certification Specialist Status: ${columnProperty}`);
              break;
            case 'admin':
              changesArray.push(`Admin Status: ${columnProperty}`);
              break;
            case 'inspector':
              changesArray.push(`Inspector Status: ${columnProperty}`);
              break;
            case 'workflowManager':
              changesArray.push(`Workflow Manager Status: ${columnProperty}`);
              break;
            case 'consultant':
              changesArray.push(`Consultant Status: ${columnProperty}`);
              break;
            case 'consultantCompanyId':
              changesArray.push('Consultant Company');
              break;
            case 'staffRole':
              switch (state.userEdits[property]) {
                case 'aca_admin':
                  changesArray.push('Staff Role Changed To Staff');
                  break;
                case 'aca_osp_editor':
                  changesArray.push('Staff Role Changed To Plan Editor');
                  break;
                case 'aca_owner':
                  changesArray.push('Staff Role Changed To Owner');
                  break;
                case 'client':
                  changesArray.push('Staff Role Changed To Client');
                  break;
                case 'none':
                  if (state.currentSetup.staffRole === 'client') {
                    changesArray.push('Removed Client Role');
                  } else {
                    changesArray.push('Removed Staff Role');
                    changesArray.push('Removed Staff ACA');
                  }
                  break;
                default:
                  break;
              }
              break;
            case 'acaStaff':
              changesArray.push('Staff ACA Changed');
              break;
            case 'enabled':
              changesArray.push(`Enabled Status: ${columnProperty}`);
              break;
            case 'phoneNumber':
              changesArray.push(`Phone Number`);
              break;
            case 'userTitle':
              changesArray.push('Title');
              break;
            default:
              break;
          }
        }
      }
      changesArray.sort(function(a, b) {
        return a.length - b.length;
      });
      userChanges = (
        <div>
          <p>User Updates Made:</p>
          <List>
            {changesArray.map((change, index) => (
              <ListItem key={index}>{change}</ListItem>
            ))}
          </List>
          <p>Click Save To Confirm Updated</p>
        </div>
      );
      return true;
    }
    return false;
  };

  const headingName = isNew => {
    if (isNew) {
      return { heading: 'Add New ' };
    }
    return { heading: 'Edit ' };
  };

  const staffRadioOptions = [
    { value: 'none', label: 'None', stateName: 'staffRole' },
    {
      value: 'client',
      label: 'Client',
      stateName: 'staffRole',
      disabled:
        state.userEdits.inspector ||
        state.userEdits.admin ||
        state.userEdits.cs ||
        state.userEdits.workflowManager,
    },
    {
      value: 'aca_owner',
      label: 'Owner',
      stateName: 'staffRole',
      disabled:
        state.userEdits.inspector ||
        state.userEdits.acaStaff === 0 ||
        state.userEdits.consultant,
    },
    {
      value: 'aca_osp_editor',
      label: 'Plan Editor',
      stateName: 'staffRole',
      disabled:
        state.userEdits.inspector ||
        state.userEdits.acaStaff === 0 ||
        state.userEdits.consultant,
    },
    {
      value: 'aca_admin',
      label: 'Staff',
      stateName: 'staffRole',
      disabled:
        state.userEdits.inspector ||
        state.userEdits.acaStaff === 0 ||
        state.userEdits.consultant,
    },
  ];

  const statusRadioOptions = [
    { value: true, label: 'Enabled', stateName: 'enabled' },
    { value: false, label: 'Disabled', stateName: 'enabled' },
  ];

  const acaDropDownOptions = acaList.map(aca => ({
    value: aca.id,
    label: aca.name,
  }));

  return (
    <Block className="user-management">
      <Widget>
        <WidgetHeading>{headingName(state.isNew).heading}User</WidgetHeading>
        <WidgetBody
          className={!state.isNew && checkForEdits() ? 'editing-user' : 'no-changes'}
        >
          <Confirm
            message={userChanges}
            onConfirm={() => onSaveHandler(state)}
            confirmButtonText="Save"
          >
            {toggleConfirm => (
              <Button
                pullRight
                sm
                primary
                className="edit-page-save"
                onClick={toggleConfirm}
              >
                Save
              </Button>
            )}
          </Confirm>
          <Button
            pullRight
            sm
            primary
            className="edit-page-cancel"
            to={adminUsersLookupRoute()}
          >
            Cancel
          </Button>
          <Clearfix />
          <FormGroup fieldName="name" label="Name" required>
            <Input
              type="name"
              id="name"
              className="form-control"
              value={state.userEdits.name}
              onChange={event => handleInputChange(event)}
              required
            />
          </FormGroup>
          <FormGroup fieldName="phoneNumber" label="Primary Phone Number" required>
            <Input
              type="tel"
              id="phoneNumber"
              className="form-control"
              value={state.userEdits.phoneNumber}
              onChange={event => handleInputChange(event)}
            />
          </FormGroup>
          <FormGroup fieldName="userTitle" label="Title">
            <Input
              type="title"
              id="userTitle"
              className="form-control"
              value={state.userEdits.userTitle}
              onChange={event => handleInputChange(event)}
            />
          </FormGroup>
          {state.isNew ? (
            <FormGroup fieldName="email" label="Email" required>
              <Input
                type="email"
                id="email"
                className="form-control"
                value={state.userEdits.email}
                onChange={event => handleInputChange(event)}
                required
              />
            </FormGroup>
          ) : (
            <FormGroup fieldName="email" label="Email" required>
              <div>
                <Paragraph className="inline-style paragraph-margin">
                  {state.userEdits.email}
                </Paragraph>
                <Link
                  className="inline-style"
                  onClick={() => showModal(['email', 'Email'])}
                >
                  Change Email
                </Link>
              </div>
            </FormGroup>
          )}
          {state.isNew ? (
            <div>
              <FormGroup fieldName="password" label="Password">
                <Input
                  type="password"
                  id="password"
                  className="form-control"
                  value={state.userEdits.password}
                  onChange={event => handleInputChange(event)}
                />
              </FormGroup>
              <FormGroup fieldName="passwordConfirmation" label="Confirm Password">
                <Input
                  type="password"
                  id="passwordConfirmation"
                  className="form-control"
                  value={state.userEdits.passwordConfirmation}
                  onChange={event => handleInputChange(event)}
                />
              </FormGroup>
              {state.missMatch && (
                <Paragraph className="non-match">Passwords do not match</Paragraph>
              )}
            </div>
          ) : (
            <FormGroup fieldName="password" label="Password" required>
              <UpdateModal
                show={state.showModal}
                onHide={closeModal}
                onModalSave={onModalSave}
                type={state.modalTypeValues}
              />
              <div>
                <Paragraph className="inline-style paragraph-margin">
                  **********
                </Paragraph>
                <Link
                  className="inline-style"
                  onClick={() => showModal(['password', 'Password'])}
                >
                  Change Password
                </Link>
              </div>
            </FormGroup>
          )}
          <FormGroup fieldName="role" label="System Roles">
            <Checkbox
              text={'Admin'}
              onChange={handleOptionChange}
              value={state.userEdits.admin}
              field={'admin'}
              disabled={
                state.userEdits.inspector ||
                state.userEdits.consultant ||
                state.userEdits.staffRole === 'client'
              }
            />
            <Checkbox
              text={'Certification Specialist'}
              onChange={handleOptionChange}
              value={state.userEdits.cs}
              field={'cs'}
              disabled={
                state.userEdits.inspector ||
                state.userEdits.workflowManager ||
                state.userEdits.consultant ||
                state.userEdits.staffRole === 'client'
              }
            />
            <Checkbox
              text={'Inspector'}
              onChange={handleOptionChange}
              value={state.userEdits.inspector}
              field={'inspector'}
              disabled={
                state.userEdits.admin ||
                state.userEdits.workflowManager ||
                state.userEdits.cs ||
                state.userEdits.consultant ||
                state.userEdits.staffRole !== 'none'
              }
            />
            <Checkbox
              text={'Workflow Manager'}
              onChange={handleOptionChange}
              value={state.userEdits.workflowManager}
              field={'workflowManager'}
              disabled={
                state.userEdits.inspector ||
                state.userEdits.cs ||
                state.userEdits.consultant ||
                state.userEdits.staffRole === 'client'
              }
            />
            <Confirm
              message="Removing consultant tag will allow user to be editable by primary contacts of below organizations."
              onConfirm={() =>
                handleOptionChange(!state.userEdits.consultant, 'consultant')
              }
            >
              {toggleConfirm => (
                <Checkbox
                  text={'Consultant'}
                  onChange={
                    state.currentSetup.consultant && state.userEdits.consultant
                      ? toggleConfirm
                      : handleOptionChange
                  }
                  value={state.userEdits.consultant}
                  field={'consultant'}
                  disabled={
                    state.userEdits.inspector ||
                    state.userEdits.cs ||
                    state.userEdits.workflowManager ||
                    state.userEdits.admin ||
                    (state.userEdits.staffRole !== 'client' &&
                      state.userEdits.staffRole !== 'none')
                  }
                />
              )}
            </Confirm>
            <Clearfix />
          </FormGroup>
          {state.userEdits.consultant && (
            <FormGroup fieldName="consultantCompanyId" label="Consultant Company">
              <SelectFormGroup
                fieldName="consultantCompanyId"
                className="select-margin"
                clearable={true}
                value={state.userEdits.consultantCompanyId}
                onChange={dropDownHandler}
                options={selectOptions.consultantOptions}
                placeholder="Select Consultant Company"
              />
            </FormGroup>
          )}
          <FormGroup fieldName="staffRole" label="Staff Role" required>
            <SelectFormGroup
              fieldName="acaStaff"
              className="select-margin"
              clearable={false}
              value={state.userEdits.acaStaff}
              onChange={dropDownHandler}
              options={acaDropDownOptions}
              disabled={true}
            />
            <RadioButton
              options={staffRadioOptions}
              onChange={handleOptionChange}
              currentState={state.userEdits.staffRole || state.currentSetup.staffRole}
              labelClassName="user-management-radio-label"
              inputClassName="user-management-input"
            />
          </FormGroup>
          <FormGroup fieldName="status" label="Status" required>
            <RadioButton
              options={statusRadioOptions}
              onChange={handleOptionChange}
              currentState={state.userEdits.enabled}
              labelClassName="user-management-radio-label"
              inputClassName="user-management-input"
            />
          </FormGroup>
          {!state.isNew && (
            <Fragment>
              {state.currentSetup.inspector ||
              state.currentSetup.cs ||
              state.currentSetup.staffRole == 'client' ? (
                <Fragment>
                  <FormGroup
                    className="org-settings-label"
                    fieldName="organization"
                    label="Organization Settings"
                    required
                  ></FormGroup>
                  <SelectFormGroup
                    disabled={true}
                    fieldName="acaSelect"
                    clearable={false}
                    value={state.acaSelect}
                    onChange={dropDownHandler}
                    options={acaDropDownOptions}
                    className={
                      state.userType === userTypeId.specialist
                        ? 'edit-page-priority-drop'
                        : 'edit-page-sub-drop'
                    }
                  />
                  <SelectFormGroup
                    fieldName="orgSelect"
                    value={state.orgSelect}
                    onChange={dropDownHandler}
                    options={selectOptions.organizationOptions}
                    disabled={state.acaSelect === 0}
                    className={
                      state.userType === userTypeId.specialist
                        ? 'edit-page-priority-drop'
                        : 'edit-page-sub-drop'
                    }
                    placeholder="Select Organization"
                  />
                  <SelectFormGroup
                    fieldName="userType"
                    value={state.userType}
                    onChange={dropDownHandler}
                    options={selectOptions.userTypeOptions}
                    disabled={!state.orgSelect}
                    className={
                      state.userType === userTypeId.specialist
                        ? 'edit-page-priority-drop'
                        : 'edit-page-sub-drop'
                    }
                    placeholder="Select Type of User"
                  />
                  <SelectFormGroup
                    fieldName="userSubtype"
                    value={state.userSubtype}
                    onChange={dropDownHandler}
                    options={
                      state.userType === userTypeId.inspector
                        ? selectOptions.partialOptions[state.orgSelect]
                        : selectOptions.userSubtypeOptions[state.userType]
                    }
                    disabled={!state.orgSelect || !state.userType}
                    className={
                      state.userType === userTypeId.specialist
                        ? 'edit-page-priority-drop'
                        : 'edit-page-sub-drop'
                    }
                    placeholder={
                      state.userType === userTypeId.specialist
                        ? 'Select CS Type'
                        : state.userType === userTypeId.inspector
                        ? 'Select Inspection'
                        : 'No Sub Type'
                    }
                  />
                  {state.userType === userTypeId.specialist && (
                    <SelectFormGroup
                      fieldName="userPriority"
                      value={state.userPriority}
                      onChange={dropDownHandler}
                      options={selectOptions.priorityTypeOptions}
                      disabled={!state.orgSelect || !state.userType || !state.userSubtype}
                      className={
                        state.userType === userTypeId.specialist
                          ? 'edit-page-priority-drop'
                          : 'edit-page-sub-drop'
                      }
                      placeholder={'Select Priority Level'}
                    />
                  )}
                  {state.primaryModal && (
                    <ConfirmModal
                      title={
                        state.currentPrimary
                          ? 'Primary contact for organization already assigned.'
                          : 'Remove primary contact for organization.'
                      }
                      confirmButtonText="Confirm"
                      message={
                        state.currentPrimary ? (
                          <Fragment>
                            <Paragraph>The current primary contact is:</Paragraph>
                            <List>
                              <ListItem>{state.currentPrimary.name}</ListItem>
                              <ListItem>{state.currentPrimary.email}</ListItem>
                            </List>
                            <Paragraph>
                              Do you want to switch the primary contact to{' '}
                              {state.currentSetup.name}?
                            </Paragraph>
                          </Fragment>
                        ) : (
                          <Paragraph>
                            Do you wish to remove the primary contact for this
                            organization?
                          </Paragraph>
                        )
                      }
                      onConfirm={() => onSavePrimary()}
                      onCancel={() => onCancelPrimary()}
                      show={state.primaryModal}
                    />
                  )}
                  <Button
                    disabled={
                      state.userType !== 3
                        ? !state.orgSelect || !state.userType || !state.userSubtype
                        : !state.orgSelect || !state.userType
                    }
                    className={
                      state.currentSetup.cs
                        ? !state.orgSelect || !state.userType || !state.userSubtype
                          ? 'edit-page-add add-disabled'
                          : 'edit-page-add add-enabled'
                        : !state.orgSelect || !state.userType
                        ? 'edit-page-add add-disabled'
                        : 'edit-page-add add-enabled'
                    }
                    primary
                    onClick={onAssignHandler}
                  >
                    Add
                  </Button>
                  <Clearfix />
                  <DataTable
                    selector={'.orgsTable'}
                    tableDef={{
                      pageLength: 10,
                      destroy: true,
                      searching: false,
                      order: [1, 'asc'],
                      columnDefs: [
                        {
                          sortable: false,
                          targets: state.currentSetup.cs ? [4, 6] : [4],
                        },
                      ],
                    }}
                  >
                    <Table
                      className={'orgsTable'}
                      bordered
                      striped
                      condensed
                      head={
                        <TableRow>
                          <TableCell heading className="aca-column">
                            ACA
                          </TableCell>
                          <TableCell heading className="org-column">
                            Organization
                          </TableCell>
                          <TableCell heading className="type-column">
                            Type
                          </TableCell>
                          <TableCell heading>Sub Type</TableCell>
                          {state.currentSetup.cs && (
                            <Fragment>
                              <TableCell heading>Actions</TableCell>
                              <TableCell heading>CS Priority</TableCell>
                            </Fragment>
                          )}
                          <TableCell heading className="revoke-column"></TableCell>
                        </TableRow>
                      }
                    >
                      {filteredUserData.length > 0 ? (
                        filteredUserData.map(org => (
                          <TableRow key={org.uniqueId}>
                            <TableCell valignMiddle>{org.acaName}</TableCell>
                            <TableCell valignMiddle>{org.orgName}</TableCell>
                            <TableCell valignMiddle>{org.type}</TableCell>
                            <TableCell valignMiddle>{org.subtype || 'None'}</TableCell>
                            {state.currentSetup.cs && (
                              <Fragment>
                                <TableCell
                                  className="action-cell"
                                  textCenter
                                  valignMiddle
                                >
                                  <UserPriorityPopover
                                    priorityTypes={selectOptions.priorityTypeOptions}
                                    org={org}
                                  />
                                </TableCell>
                                <TableCell
                                  data-order={org.priorityOrder ? org.priorityOrder : 100}
                                  valignMiddle
                                >
                                  <PriorityBubble color={org.priorityColor}>
                                    {org.priorityName}
                                  </PriorityBubble>
                                </TableCell>
                              </Fragment>
                            )}
                            <TableCell valignMiddle textCenter>
                              <Button
                                sm
                                danger
                                className="revoke-button"
                                onClick={() =>
                                  onRevokeHandler({
                                    userType: org.type,
                                    orgId: org.orgId,
                                    userId: org.id,
                                    uniqueId: org.uniqueId,
                                    userSubtypeId: org.subtypeId,
                                  })
                                }
                              >
                                Revoke
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell textCenter colSpan={5}>
                            No Attached Organizations
                          </TableCell>
                        </TableRow>
                      )}
                    </Table>
                  </DataTable>
                </Fragment>
              ) : (
                <Fragment />
              )}

              <Column className="column-style" xs={12} sm={6}>
                <Panel className="edit-page-panel">
                  <Paragraph>
                    Send Where Food Comes From Organic Welcome email to user.
                  </Paragraph>
                  <Confirm
                    title="Send Welcome Email"
                    message={`Send email to: ${state.currentSetup.email}`}
                    onConfirm={() =>
                      handleEmailConfirm(state.currentSetup.email, 'welcome')
                    }
                  >
                    {toggleConfirm => (
                      <Button
                        onClick={toggleConfirm}
                        className="email-button"
                        disabled={state.userEdits.email !== state.currentSetup.email}
                      >
                        Send Welcome Email
                      </Button>
                    )}
                  </Confirm>
                </Panel>
              </Column>
              <Column className="column-style" xs={12} sm={6}>
                <Panel className="edit-page-panel">
                  <Paragraph>
                    Send a request to reset the password for the user's Where Food Comes
                    From Organic Account.
                  </Paragraph>
                  <Confirm
                    title="Send Reset Password Email"
                    message={`Send email to: ${state.currentSetup.email}`}
                    onConfirm={() =>
                      handleEmailConfirm(state.currentSetup.email, 'reset')
                    }
                  >
                    {toggleConfirm => (
                      <Button
                        onClick={toggleConfirm}
                        className="email-button"
                        disabled={state.userEdits.email !== state.currentSetup.email}
                      >
                        Send Reset Password Email
                      </Button>
                    )}
                  </Confirm>
                </Panel>
              </Column>
            </Fragment>
          )}
          <Clearfix />
          {!state.currentSetup.admin && !state.isNew && (
            <Fragment>
              <HorizontalRule className="impersonation-divider" />
              <Block className="impersonation-block">
                <Paragraph className="impersonation-text">
                  Want to login as {state.currentSetup.name}?
                </Paragraph>
                <Button
                  className="impersonation-button"
                  onClick={() => onLoginAsUser(state.id)}
                >
                  <span className="material-symbols-outlined ">login</span>
                  <span className="impersonation-button-text ">Login as User</span>
                </Button>
              </Block>
              <HorizontalRule className="impersonation-divider" />
            </Fragment>
          )}
          <Confirm
            message={userChanges}
            onConfirm={() => onSaveHandler(state)}
            confirmButtonText="Save"
          >
            {toggleConfirm => (
              <Button
                sm
                primary
                pullRight
                className="edit-page-save"
                onClick={toggleConfirm}
              >
                Save
              </Button>
            )}
          </Confirm>
          <Button
            sm
            pullRight
            primary
            className="edit-page-cancel"
            to={adminUsersLookupRoute()}
          >
            Cancel
          </Button>
          <Clearfix />
        </WidgetBody>
      </Widget>
    </Block>
  );
};

export default UserManagementEditUser;
