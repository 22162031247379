import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import AccessTypeEditContainer from 'sow/containers/AccessTypeEditContainer';

const AccessTypeEditPage = () => (
  <AdminPageTemplate title="System Adminstrator">
    {() => <AccessTypeEditContainer />}
  </AdminPageTemplate>
);

export default AccessTypeEditPage;
