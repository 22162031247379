import { createActions } from 'redux-actions';
import * as R from 'ramda';

const actionCreators = createActions({
  ACA: {
    LOAD_ONE: acaId => ({ acaId }),
    LOAD_ONE_DONE: R.identity,
    LOAD_ONE_FAIL: (acaId, error) => ({ acaId, error }),
  },
});

export default actionCreators.aca;
