import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as currentUser from 'sow/selectors/currentUser';
import { fromPlanApp } from 'sow/store/selectors';
import * as sowTypes from 'sow/types';
import * as adminRequestActions from 'sow/actions/adminRequest';

import ChangesListItem from 'sow/components/organisms/PlanAppChangeRequestManager/ChangesListItem';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.changePlanAppId(state, props),
  worksheetId: fromPlanApp.changeWorksheetId(state, props),
  questionId: fromPlanApp.changeQuestionId(state, props),
  locationId: fromPlanApp.changeLocationId(state, props),
  change: fromPlanApp.change(state, props),
  comments: fromPlanApp.changeCommentList(state, props),
  worksheetName: fromPlanApp.changeWorksheetName(state, props),
  questionName: fromPlanApp.changeQuestionName(state, props),
  showAcaUI: currentUser.showAcaUI(state, props),
});

const mapDispatchToProps = dispatch => ({
  clearRequest: (orgId, planAppId, requestId) =>
    dispatch(adminRequestActions.clearAdminRequest(orgId, planAppId, requestId)),
});

class ChangesListItemContainer extends Component {
  state = {
    showComments: false,
  };

  componentDidUpdate() {
    if (this.props.toggleMeasure) {
      this.props.toggleMeasure();
    }
  }

  toggleComments = () => this.setState(R.over(R.lensPath(['showComments']), R.not));

  handleClearTodo() {
    const { listItem, clearRequest, orgId } = this.props;
    const { id, applicationId } = listItem;

    clearRequest(orgId, applicationId, id);
  }

  render() {
    const { comments, showAcaUI, clearTodos } = this.props;
    const { showComments } = this.state;

    const commentsWithNotifications = R.filter(
      R.where({
        [showAcaUI ? 'notifyAca' : 'notifyOrg']: R.equals(true),
      }),
    )(comments);

    return (
      <ChangesListItem
        {...this.props}
        handleClearTodo={clearTodos && this.handleClearTodo.bind(this)}
        comments={commentsWithNotifications}
        showComments={showComments}
        toggleComments={this.toggleComments}
      />
    );
  }
}

ChangesListItemContainer.propTypes = {
  // Passed props
  changeId: sowTypes.planAppChangeIdType,

  // Connected props
  orgId: sowTypes.orgIdType.isRequired,
  questionId: sowTypes.planAppWorksheetQuestionIdType,
  locationId: sowTypes.planAppLocationIdType,
  worksheetId: sowTypes.planAppWorksheetIdType,
  questionName: PropTypes.string,
  worksheetName: PropTypes.string,
  comments: PropTypes.arrayOf(sowTypes.planAppChangeCommentType).isRequired,
  showAcaUI: PropTypes.bool.isRequired,
};

ChangesListItemContainer.defaultProps = {
  questionId: null,
  locationId: null,
  worksheetId: null,
  questionName: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ChangesListItemContainer);
