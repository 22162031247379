import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import PlanAppLocationCard from 'sow/components/organisms/PlanAppLocationCard';

const mapStateToProps = (state, props) => ({
  locationId: fromPlanApp.locationId(state, props),
  locationDetails: fromPlanApp.locationDetails(state, props),
  isNew: fromPlanApp.isNewLocation(state, props),
  isUpdated: fromPlanApp.isUpdatedLocation(state, props),
  isDeleted: fromPlanApp.isLocationDeleted(state, props),
  locationChange: fromPlanApp.locationChange(state, props),
  isReadyForReview: fromPlanApp.locationIsReadyForReview(state, props),
  isStateInitialApplication: fromPlanApp.isStateInitialApplication(state, props),
  isExcluded: fromPlanApp.locationIsExcluded(state, props),
});

const LocationCardContainer = ({
  locationId,
  locationDetails,
  children,
  isNew,
  isUpdated,
  locationChange,
  isDeleted,
  isReadyForReview,
  isStateInitialApplication,
  isExcluded,
}) => {
  // console.log('isExcluded:', isExcluded);
  if (!locationDetails) return null;
  return (
    <PlanAppLocationCard
      locationId={locationId}
      locationDetails={locationDetails}
      isNew={isNew}
      isUpdated={isUpdated}
      changeState={R.propOr(null, 'state', locationChange)}
      changeId={R.propOr(null, 'id', locationChange)}
      isDeleted={isDeleted}
      isReadyForReview={isReadyForReview}
      isStateInitialApplication={isStateInitialApplication}
      isExcluded={isExcluded}
    >
      {children}
    </PlanAppLocationCard>
  );
};

LocationCardContainer.propTypes = {
  children: PropTypes.node,
  locationId: sowTypes.planAppLocationIdType.isRequired,
  // May be null on initial render
  locationDetails: sowTypes.planAppLocationType,
  isNew: PropTypes.bool.isRequired,
  isUpdated: PropTypes.bool.isRequired,
  locationChange: sowTypes.planAppChangeType,
  isDeleted: PropTypes.bool.isRequired,
  isReadyForReview: PropTypes.bool.isRequired,
  isStateInitialApplication: PropTypes.bool.isRequired,
  isExcluded: PropTypes.bool,
};

LocationCardContainer.defaultProps = {
  children: null,
  locationChange: null,
  locationDetails: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(LocationCardContainer);
