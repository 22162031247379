import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fromPlanApp } from 'sow/store/selectors';
import * as sowTypes from 'sow/types';
import { ResourceDetailLoader } from 'sow/store/helpers';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
});

const PlanAppChangeRequestOverviewLoader = ({ orgId, ...props }) => (
  <ResourceDetailLoader
    resource={`org/${orgId}/change_request`}
    entityType="changeRequestOverviewResp"
    {...props}
  />
);

PlanAppChangeRequestOverviewLoader.defaultProps = {
  autoLoad: false,
  children: () => null,
};

PlanAppChangeRequestOverviewLoader.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  children: PropTypes.func.isRequired,
  autoLoad: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppChangeRequestOverviewLoader);
