import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { fromPlanApp } from 'sow/store/selectors';
import Tooltip from 'sow/components/molecules/Tooltip';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const mapStateToProps = (state, props) => ({
  isNewRow: fromPlanApp.matrixRowIsNewRow(state, props),
});

const MatrixRowNewRowIndicator = ({ isNewRow }) => {
  if (!isNewRow) return null;

  return (
    <Tooltip overlay="This row will be added">
      <FontAwesome icon="plus-circle" />
    </Tooltip>
  );
};

MatrixRowNewRowIndicator.propTypes = {
  isNewRow: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(MatrixRowNewRowIndicator);
