import React, { Component, Fragment } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import actions from 'sow/actions/pure';
import ErrorPage from 'sow/components/templates/ErrorPageTemplate';
import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';

const message = (
  <Fragment>
    Please contact Where Food Comes From Organic to verify the status of your account.{' '}
    <br /> 303.895.3002 <br />
    <Link mailTo={'info@wfcforganic.com'}>info@wfcforganic.com</Link>
    <br />
    <Link href={'https://wfcforganic.com'}>https://wfcforganic.com</Link>
    <br />
    202 6th Street, Suite 400
    <br />
    Castle Rock, CO 80104
  </Fragment>
);

const mapStateToProps = state => {
  if (state.auth.user_id) {
    return { id: state.auth.user_id };
  }
  return {};
};

const mapDispatchToProps = {
  logout: actions.auth.inactive,
};

class ErrorInactiveAccountPage extends Component {
  logout() {
    this.props.logout();
  }

  componentDidMount() {
    if (this.props.id) {
      this.logout();
    } else {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <Fragment>
        <ErrorPage message={message} />
        <Block textCenter textMuted>
          <Link to="/">Go Home</Link>
        </Block>
      </Fragment>
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ErrorInactiveAccountPage);
