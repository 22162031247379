const mark = action => `ITEM_VERSION_PICKLIST/${action}`;

export const LOAD_ITEM_VERSION_PICKLISTS = mark('LOAD_ITEM_VERSION_PICKLISTS');
export const LOAD_ITEM_VERSION_PICKLISTS_DONE = mark('LOAD_ITEM_VERSION_PICKLISTS_DONE');
export const FETCH_BY_ID = mark('FETCH_BY_ID');
export const ADD_ITEM_VERSION_PICKLIST = mark('ADD_ITEM_VERSION_PICKLIST');
export const UPDATE_ITEM_VERSION_PICKLIST = mark('UPDATE_ITEM_VERSION_PICKLIST');
export const DELETE_ITEM_VERSION_PICKLIST = mark('DELETE_ITEM_VERSION_PICKLIST');

export const loadItemVersionPicklists = (payload, onSuccess) => ({
  type: LOAD_ITEM_VERSION_PICKLISTS,
  payload,
  meta: { onSuccess },
});

export const loadItemVersionPicklistsDone = payload => ({
  type: LOAD_ITEM_VERSION_PICKLISTS_DONE,
  payload,
});

export const fetchById = (payload, onSuccess) => ({
  type: FETCH_BY_ID,
  payload,
  meta: { onSuccess },
});

export const addItemVersionPicklist = (payload, onSuccess) => ({
  type: ADD_ITEM_VERSION_PICKLIST,
  payload,
  meta: { onSuccess },
});

export const updateItemVersionPicklist = (id, payload, onSuccess) => ({
  type: UPDATE_ITEM_VERSION_PICKLIST,
  id,
  payload,
  meta: { onSuccess },
});

export const deleteItemVersionPicklist = (id, onSuccess) => ({
  type: DELETE_ITEM_VERSION_PICKLIST,
  id,
  meta: { onSuccess },
});
