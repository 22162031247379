import React, { Fragment } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { orgIdType, planAppIdType, planAppSummaryType, planAppType } from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import { isStateOpen, isStateUnlocked } from 'sow/utils/planAppState';
import { PlanAppLoader } from 'sow/store/containers';

import PlanAppActionButtons from 'sow/containers/planApp/ActionButtons';
import PlanAppProgressBar from 'sow/components/organisms/PlanAppProgressBar';
import Spinner from 'sow/components/atoms/Spinner';

const mapStateToProps = (state, props) => ({
  planApp: fromPlanApp.planApp(state, props),
});

const PlanAppTableRowProgressActionButtons = ({
  orgId,
  planAppId,
  planAppSummary,
  planApp,
  hasOpenPlanApp,
}) => {
  if (isStateOpen(planAppSummary)) {
    return (
      <PlanAppLoader orgId={orgId} planAppId={planAppId} autoLoad>
        {({ status }) => (
          <Fragment>
            {status.loading && <Spinner />}
            {status.done && (
              <Fragment>
                {isStateUnlocked(planAppSummary) && (
                  <PlanAppProgressBar percentageComplete={planApp.percentageComplete} />
                )}
                <PlanAppActionButtons
                  orgId={orgId}
                  planAppId={planAppId}
                  planAppSummary={planAppSummary}
                  hasOpenPlanApp={hasOpenPlanApp}
                  planApp={planApp}
                />
              </Fragment>
            )}
          </Fragment>
        )}
      </PlanAppLoader>
    );
  }

  return (
    <PlanAppActionButtons
      orgId={orgId}
      planAppId={planAppId}
      planAppSummary={planAppSummary}
      hasOpenPlanApp={hasOpenPlanApp}
    />
  );
};

PlanAppTableRowProgressActionButtons.defaultProps = {
  planApp: null,
};

PlanAppTableRowProgressActionButtons.propTypes = {
  orgId: orgIdType.isRequired,
  planAppId: planAppIdType.isRequired,
  planAppSummary: planAppSummaryType.isRequired,
  // Only loaded when app has open state
  planApp: planAppType,
  hasOpenPlanApp: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppTableRowProgressActionButtons);
