import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { acaIdType } from 'sow/types';
import * as acaAdminAction from 'sow/actions/pure/acaAdmin';
import * as currentAca from 'sow/selectors/currentAca';

import AcaAddStaffModal from 'sow/components/organisms/AcaAddStaffModal';

const mapStateToProps = (state, props) => ({
  acaId: currentAca.acaId(state, props),
  isAddingStaff: state.acaAdminAddStaff.isAddingStaff,
  addStaffErrors: state.acaAdminAddStaff.addStaffErrors,
  showInviteFields: state.acaAdminAddStaff.showInviteFields,
});

const mapDispatchToProps = {
  addStaff: acaAdminAction.addStaff,
  hideInviteFields: acaAdminAction.hideInviteFields,
};

const defaultUser = () => ({
  email: '',
  role: 'aca_admin',
});

class AcaAddStaffModalContainer extends Component {
  state = {
    showModal: false,
    user: defaultUser(),
  };

  showModal = () => this.setState({ showModal: true });

  hideModal = () => {
    this.setState({
      showModal: false,
      user: defaultUser(),
    });

    this.props.hideInviteFields();
  };

  handleChange = field => event => {
    const value = event.target.value;
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [field]: value,
      },
    }));
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.showInviteFields && !this.state.user.name) {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          name: '',
        },
      }));
    }
  }

  handleSubmit = () => {
    this.props.addStaff(this.props.acaId, this.state.user, this.hideModal);
  };

  render() {
    const { showModal, user } = this.state;
    const { showInviteFields, isAddingStaff, addStaffErrors } = this.props;

    return (
      <AcaAddStaffModal
        showModal={showModal}
        onShow={this.showModal}
        onHide={this.hideModal}
        user={user}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        showInviteFields={showInviteFields}
        isAddingStaff={isAddingStaff}
        addStaffErrors={addStaffErrors}
      />
    );
  }
}

AcaAddStaffModalContainer.propTypes = {
  acaId: acaIdType.isRequired,
  showInviteFields: PropTypes.bool,
  addStaffErrors: PropTypes.object,
  addStaff: PropTypes.func.isRequired,
  hideInviteFields: PropTypes.func.isRequired,
  isAddingStaff: PropTypes.bool.isRequired,
};

export default R.compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(
  AcaAddStaffModalContainer,
);
