import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const WrappedTR = styled.tr``;

/** Renders a `tr` tag */
const TableRow = props => <WrappedTR {...props} />;

TableRow.propTypes = {
  children: PropTypes.node,
};

export default TableRow;
