import actions from 'sow/actions/pure';
const isLoading = (state = false, action) => {
  switch (action.type) {
    case String(actions.ospApp.loadOrgList):
      return true;

    case String(actions.ospApp.loadOrgListDone):
    case String(actions.ospApp.loadOrgListFail):
      return false;

    default:
      return state;
  }
};

export default isLoading;
