import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import * as currentOrg from 'sow/selectors/currentOrg';
import OrgPlanAppPageTemplate from 'sow/components/templates/OrgPlanAppPageTemplate';

const mapStateToProps = (state, props) => ({
  isPlanAppIdMostRecent: fromPlanApp.isPlanAppIdMostRecent(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  orgId: fromPlanApp.orgId(state, props),
  org: currentOrg.org(state, props),
});

const OrgPlanAppPageTemplateContainer = props => <OrgPlanAppPageTemplate {...props} />;

OrgPlanAppPageTemplateContainer.propTypes = {
  org: sowTypes.orgType,
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  isPlanAppIdMostRecent: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(OrgPlanAppPageTemplateContainer);
