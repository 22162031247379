import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import * as priorityTypeActions from 'sow/actions/pure/priorityType';

import PriorityTypeList from 'sow/components/organisms/PriorityTypeList';

const mapDispatchToProps = {
  loadList: priorityTypeActions.loadPriorityTypes,
  updateOrder: priorityTypeActions.updateSortOrder,
};

const initialState = { listFiltered: false, loadingList: true, initialValues: {} };

class PriorityTypeListContainer extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = R.clone(initialState);
  }

  componentDidMount() {
    const urlParams = queryString.parse(this.props.location.search, {
      arrayFormat: 'comma',
    });
    this.setState({ initialValues: urlParams });
    this._isMounted = true;
    this.props.loadList(urlParams, this.updateState.bind(this));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState(data) {
    if (this._isMounted) {
      this.setState({ loadingList: false, priorityTypesList: data.priority_types });
    }
  }

  onSearch(values, formik) {
    const urlParams = queryString.stringify(values, {
      arrayFormat: 'comma',
      skipEmptyString: true,
      skipNull: true,
    });
    if (urlParams) {
      this.setState({ listFiltered: true });
      this.props.history.push(`?${urlParams}`);
    } else {
      this.props.history.push(this.props.location.pathname);
    }
    this.setState({ loadingList: true });
    this.props.loadList(values, this.updateState.bind(this));
  }

  onReset(values, formik) {
    this.setState(R.clone(initialState));
    this.props.history.push(this.props.location.pathname);
    this.props.loadList({}, this.updateState.bind(this));
  }

  updateSortOrder(updatedList) {
    this.props.updateOrder(updatedList);
  }

  render() {
    return (
      <PriorityTypeList
        onSearch={this.onSearch.bind(this)}
        onReset={this.onReset.bind(this)}
        updateSortOrder={this.updateSortOrder.bind(this)}
        {...this.state}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(PriorityTypeListContainer);
