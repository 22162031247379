import * as R from 'ramda';
import { createSelector } from 'reselect';

export const params = (state, props) => R.path(['match', 'params'], props);

export const paramAcaId = createSelector(
  params,
  routeParams => routeParams && ~~routeParams.acaId,
);

export const paramOrgId = createSelector(
  params,
  routeParams => routeParams && ~~routeParams.orgId,
);

export const paramPlanAppId = createSelector(
  params,
  routeParams => routeParams && routeParams.planAppId,
);

export const paramLocationId = createSelector(
  params,
  routeParams => routeParams && ~~routeParams.locationId,
);

export const paramPlanAppProductId = createSelector(
  params,
  routeParams => routeParams && ~~routeParams.planAppProductId,
);

export const paramWorksheetId = createSelector(
  params,
  routeParams => routeParams && routeParams.worksheetId,
);
