import * as R from 'ramda';

export const fetchDefinitionWorksheets = definition => {
  const landWorksheets = fetchLandWorksheets(R.path(['mini_osp', 'land'], definition));
  const mainWorksheets = fetchMainWorksheet(R.path(['sections'], definition));
  return R.mergeAll([landWorksheets, mainWorksheets]);
};

export const fetchLandWorksheets = landDefinition => {
  const landSections = R.values(landDefinition);
  return R.mergeAll(R.map(sectionWorksheets, landSections));
};

export const sectionWorksheets = section => {
  return R.mergeAll(R.map(worksheetObject, R.path(['section', 'worksheets'], section)));
};

export const worksheetObject = worksheetData => {
  return {
    [worksheetData.uuid]: {
      name: worksheetData.name,
      questions: worksheetData.questions,
    },
  };
};

export const fetchMainWorksheet = mainDefinition => {
  return R.mergeAll(
    R.map(section => {
      return sectionWorksheets({ section: section });
    }, mainDefinition),
  );
};

export const worksheetListByQualification = definition => {
  const qualificationList = R.path(['qualifications', 'questions'], definition);
  const qualificationProperties = fetchQualificationProperties(qualificationList);
  const completeQualificationList = addSubquestionProperties(qualificationProperties);
  return completeQualificationList;
};

export const fetchQualificationProperties = qualificationList =>
  R.map(
    qualification => ({
      uuid: qualification.uuid,
      subquestions: qualification.sub_questions,
      question: qualification.question,
    }),
    qualificationList,
  );

export const addSubquestionProperties = qualificationProperties => {
  return R.mergeAll(
    R.map(
      qualification =>
        R.mergeAll(
          R.concat(
            [{ [qualification.uuid]: qualification.question }],
            R.map(
              subquestion => ({ [subquestion.uuid]: subquestion.question }),
              qualification.subquestions,
            ),
          ),
        ),
      qualificationProperties,
    ),
  );
};
