import React, { Component } from 'react';
import * as R from 'ramda';
import { AsyncCreatable } from 'react-select';
import PropTypes from 'prop-types';

import { loadTypeahead } from 'sow/api/newApi/org/ingredient';

const listToSelectOptions = R.map(item => ({
  label: item,
  value: item,
}));

class ProductIngredientQuestionField extends Component {
  static propTypes = {
    orgId: PropTypes.number,
    ospAppId: PropTypes.string,
    worksheetId: PropTypes.number,
    handleChange: PropTypes.func,
    question: PropTypes.object,
    answer: PropTypes.any,
    disabled: PropTypes.bool,
  };

  loadOptions = text => {
    const { orgId, answer } = this.props;

    return loadTypeahead(orgId, 'name', text)
      .then(R.prop('data'))
      .then(listToSelectOptions)
      .then(options => ({ options }));
  };

  handleSelectChange = option => {
    console.log('handleSelectChange', option);
    const value = R.path(['value'], option);
    this.props.handleChange(value);
  };

  getSelectComponent = () => {
    return AsyncCreatable;
  };

  getSelectOptions = () => {
    const { categoryList, value } = this.props;
    const optionList = R.union([value, this.state.initialAnswer], categoryList);
    return listToSelectOptions(optionList);
  };

  handleChange = option => {
    if (option === null) return;
    this.props.handleChange(this.props.question.uuid, option.value);
  };

  render() {
    const { answer, disabled } = this.props;

    const SelectComponent = this.getSelectComponent();
    return (
      <SelectComponent
        loadOptions={this.loadOptions}
        value={R.defaultTo('', answer)}
        onChange={this.handleChange}
        disabled={disabled}
      />
    );
  }
}

export default ProductIngredientQuestionField;
