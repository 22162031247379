import * as R from 'ramda';

const byId = (state = {}, action) => {
  if (R.path(['payload', 'entities', 'organizations'], action)) {
    return R.merge(state, action.payload.entities.organizations);
  }

  return state;
};

export default byId;

export const getIds = state => Object.keys(state);
export const getOrg = (state, id) => state[id];
export const getOrgList = state => getIds(state).map(id => state[id]);
