import { SHOW_CONFIRM, HIDE_CONFIRM } from 'sow/actions/pure/messaging';

const initialState = {
  showConfirm: false,
  confirmOptions: {},
};

export default function messaging(state = initialState, action) {
  switch (action.type) {
    case SHOW_CONFIRM:
      return {
        ...state,
        confirmOptions: action.data.options,
        showConfirm: true,
      };

    case HIDE_CONFIRM:
      return { ...state, options: {}, showConfirm: false };

    default:
      return state;
  }
}

export function getMessagingState(state) {
  return state.messaging;
}
