import * as R from 'ramda';
import { makeImmutable, getIn } from 'sow/utils/data';

const initialState = makeImmutable({});

const byId = (state = initialState, action) => {
  if (getIn(action, 'data.entities.worksheetAnswers')) {
    return state.merge(action.data.entities.worksheetAnswers);
  }

  if (getIn(action, 'payload.entities.worksheetAnswers')) {
    return state.merge(action.payload.entities.worksheetAnswers);
  }

  return state;
};

export default byId;

export const getIds = R.keys;

export const getWorksheetAnswers = (state, id) => state[id];

export const getWorksheetAnswersList = state => getIds(state).map(id => state[id]);
