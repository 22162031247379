import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import * as orgRoutes from 'sow/routes';
import Link from 'sow/components/atoms/Link';
import Spacer from 'sow/components/atoms/Spacer';
import Button from 'sow/components/atoms/Button';
import Paragraph from 'sow/components/atoms/Paragraph';
import Glyphicon from 'sow/components/molecules/Glyphicon';
import InfoPanel from 'sow/components/organisms/InfoPanel';
import LocationCard from 'sow/containers/planApp/LocationCard';
import LocationEditModal from 'sow/containers/planApp/LocationEditModal';

const OrgLegacyLocationsPage = ({ orgId, locationIdList }) => (
  <Fragment>
    <InfoPanel
      text={
        <Paragraph style={{ marginBottom: 0 }}>
          As of 2019, all locations within WFCF Organic are created as part of a{' '}
          <Link to={orgRoutes.orgRoute(orgId)}>plan</Link>. For your reference, this page
          lists any previously created locations that have not been associated with a
          plan.
        </Paragraph>
      }
    />

    {locationIdList.map(locationId => (
      <Fragment key={locationId}>
        <LocationCard locationId={locationId}>
          <LocationEditModal locationId={locationId}>
            {showModal => (
              <Button sm primary onClick={showModal}>
                <Glyphicon glyph="eye-open" /> View Details
              </Button>
            )}
          </LocationEditModal>
        </LocationCard>
        <Spacer vertical={20} />
      </Fragment>
    ))}
  </Fragment>
);

OrgLegacyLocationsPage.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  locationIdList: PropTypes.arrayOf(sowTypes.planAppLocationIdType).isRequired,
};

export default OrgLegacyLocationsPage;
