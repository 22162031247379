import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';

import { systemAdminRoute, actionGroupEditRoute } from 'sow/routes';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormikField from 'sow/components/organisms/FormikField';
import Button from 'sow/components/atoms/Button';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Spinner from 'sow/components/atoms/Spinner';
import Link from 'sow/components/atoms/Link';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';

const ActionGroupList = ({
  loadingList,
  initialValues,
  actionGroupList,
  handleSearch,
  resetSearch,
}) => (
  <Widget className="system-admin-list-edit">
    <WidgetHeading>Action Groups</WidgetHeading>
    <WidgetBody>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSearch}
        onReset={resetSearch}
      >
        {({ handleSubmit, handleReset }) => (
          <Form onSubmit={handleSubmit}>
            <Row className="form-row">
              <Column className="form-column" xs={12} md={8}>
                <Field
                  className="input-filter"
                  label="Name"
                  placeholder=""
                  name={'name'}
                  component={FormikField}
                />
              </Column>
              <Column className="form-column" xs={12} sm={6} md={4}>
                <Row className="button-row search-button-row">
                  <Column className="button-column" xs={6} sm={6} md={6}>
                    <Button className="form-button search-button" type="submit">
                      Search
                    </Button>
                  </Column>
                  <Column className="button-column" xs={6} sm={6} md={6}>
                    <Button
                      className="form-button reset-return-button"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                  </Column>
                </Row>
              </Column>
            </Row>
          </Form>
        )}
      </Formik>
      <HorizontalRule className="divider" />
      <Row className="form-row">
        <Column className="form-column" xs={12} sm={6} md={4}>
          <Row className="button-row">
            <Column className="button-column" xs={6} sm={6} md={6}>
              <Button className="form-button" to={actionGroupEditRoute('new')} primary>
                <FontAwesome className="button-icon" icon="plus" />
                Add
              </Button>
            </Column>
            <Column className="button-column" xs={6} sm={6} md={6}>
              <Button className="form-button reset-return-button" to={systemAdminRoute()}>
                Back
              </Button>
            </Column>
          </Row>
        </Column>
      </Row>
      {loadingList ? (
        <Spinner />
      ) : (
        <Row className="form-row list-row">
          <Column className="form-column" xs={12}>
            <Table
              className="admin-action-group-list-table"
              bordered
              striped
              head={
                <TableRow>
                  <TableCell heading>Name</TableCell>
                  <TableCell heading>Sort Order</TableCell>
                  <TableCell heading>Active</TableCell>
                  <TableCell heading></TableCell>
                </TableRow>
              }
            >
              {actionGroupList.length > 0 ? (
                <Fragment>
                  {actionGroupList.map(actionGroup => (
                    <TableRow key={actionGroup.id}>
                      <TableCell className="wrap">{actionGroup.name}</TableCell>
                      <TableCell>{actionGroup.sort_order}</TableCell>
                      <TableCell>{actionGroup.enabled ? 'Yes' : 'No'}</TableCell>
                      <TableCell>
                        <Link
                          className="start-end-width"
                          to={actionGroupEditRoute(actionGroup.id)}
                        >
                          Edit
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </Fragment>
              ) : (
                <TableRow>
                  <TableCell colSpan={6} textCenter>
                    No action groups found.
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </Column>
        </Row>
      )}
    </WidgetBody>
  </Widget>
);

export default ActionGroupList;
