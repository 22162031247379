// import { handle401 } from 'actions/AuthActions';
// import { handleServerError } from 'actions/AppActions';
import $ from 'jquery';
import { getAuthToken } from 'sow/utils/authToken';

export function apiRequest(request) {
  request.headers = request.headers || {};

  // Inject auth token into the headers unless already set
  if (!request.headers['X-Auth-Token']) {
    let token = getAuthToken();
    if (token) {
      request.headers['X-Auth-Token'] = token;
    }
  }

  // Send JSON as the request payload
  if (request.data && !request.headers['Content-Type']) {
    request.headers['Content-Type'] = 'application/json';
    if (request.method !== 'GET') {
      request.data = JSON.stringify(request.data);
    }
  }

  // Expect JSON from the server
  request.dataType = 'json';

  let promise = $.ajax(request);
  promise.fail(err => {
    if (err.status === 401) {
      // handle401();
    } else if (err.status >= 500 && err.status <= 599) {
      // handleServerError(err.responseJSON);
    }
    return;
  });

  return promise;
}

export function sendFile(request) {
  request.headers = request.headers || {};

  // Inject auth token into the headers unless already set
  if (!request.headers['X-Auth-Token']) {
    let token = getAuthToken();
    if (token) {
      request.headers['X-Auth-Token'] = token;
    }
  }

  request.contentType = false;
  request.processData = false;

  let promise = $.ajax(request);
  promise.fail(err => {
    // If a 401 is thrown, the user may have a bad token. Kill any
    // existing sessions and redirect them to a login page
    if (err.status === 401) {
      // handle401();
    } else if (err.status >= 500 && err.status <= 599) {
      // handleServerError(err.responseJSON);
    }
  });

  return promise;
}

export function receiveFile(request) {
  request.headers = request.headers || {};

  // Inject auth token into the headers unless already set
  if (!request.headers['X-Auth-Token']) {
    let token = getAuthToken();
    if (token) {
      request.headers['X-Auth-Token'] = token;
    }
  }

  // Send JSON as the request payload
  if (request.data && !request.headers['Content-Type']) {
    request.headers['Content-Type'] = 'application/json';
    if (request.method !== 'GET') {
      request.data = JSON.stringify(request.data);
    }
  }

  // Expect JSON from the server
  request.dataType = 'blob';

  let promise = $.ajax(request);
  // eslint-disable-next-line no-unused-vars
  promise.fail(err => {
    return;
  });

  return promise;
}
