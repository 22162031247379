import React from 'react';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import ExportContentLocation from 'sow/containers/planAppExport/Content/Location';

const LocationsSection = ({ locationIdList, ...props }) => {
  return locationIdList.map(locationId => (
    <ExportContentLocation key={locationId} {...props} locationId={locationId} />
  ));
};

LocationsSection.propTypes = {
  locationIdList: sowTypes.planAppLocationIdListType.isRequired,

  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  planApp: sowTypes.planAppType.isRequired,
  planAppSummary: sowTypes.planAppSummaryType.isRequired,
  qualifiedWorksheetIds: PropTypes.arrayOf(sowTypes.planAppWorksheetIdType),
  worksheetSections: PropTypes.arrayOf(sowTypes.planAppWorksheetSectionType),
  showMainPlan: PropTypes.bool,
  showLocations: PropTypes.bool,
  addNotes: PropTypes.bool,
  addComments: PropTypes.bool,
  addFiles: PropTypes.bool,
  userList: sowTypes.userLimitedListType.isRequired,
};

export default LocationsSection;
