import { takeEvery, put, call } from 'redux-saga/effects';
import FileSaver from 'file-saver';

import { consoleErrorRecovery, safeSaga } from 'sow/sagas/helpers';
import * as actions from './actions';

export function* bulkDownloadResource(api, { params }, { resource, thunk }) {
  try {
    const resp = yield call(api.post, `/${resource}`, params, {
      responseType: 'blob',
    });

    if (resp.data.type === 'application/zip') {
      FileSaver.saveAs(resp.data, 'bulk-download.zip');
    }

    yield put(actions.bulkDownloadSuccess(resource, resp.data, { params }, thunk));
  } catch (e) {
    yield put(actions.bulkDownloadFailure(resource, e, { params }, thunk));
  }
}

export function* watchBulkDownloadRequest(api, { payload, meta }) {
  yield call(bulkDownloadResource, api, payload, meta);
}

export default function*({ api }) {
  const safe = safeSaga(consoleErrorRecovery);

  yield takeEvery(actions.BULK_DOWNLOAD_REQUEST, safe(watchBulkDownloadRequest, api));
}
