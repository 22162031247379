import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { replace as routerReplace } from 'connected-react-router';

import { itemVersionRoute } from 'sow/routes';

import * as itemVersionActions from 'sow/actions/pure/itemVersion';

import ItemVersionHistory from 'sow/components/organisms/ItemVersionHistory';

const mapStateToProps = (state, props) => ({
  itemVersionId: R.path(['match', 'params', 'id'], props),
});

const mapDispatchToProps = {
  getHistory: itemVersionActions.getItemVersionHistory,
  redirectNoHistory: routerReplace,
};

class ItemVersionHistoryContainer extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = { isLoading: true, itemVersionHistory: [] };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.itemVersionId === 'new') {
      this.redirectToList();
    } else {
      this.props.getHistory(this.props.itemVersionId, this.updateState.bind(this));
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState({ data }) {
    if (this._isMounted) {
      this.setState({ isLoading: false, itemVersionHistory: [...data] });
    }
  }

  redirectToList() {
    this.props.redirectNoHistory(itemVersionRoute());
  }

  render() {
    return <ItemVersionHistory {...this.props} {...this.state} />;
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ItemVersionHistoryContainer);
