import { createActions } from 'redux-actions';

const actionCreators = createActions({
  SAMPLE: {
    GET_ALL_BY_ORG: (payload, onSuccess, onFailure) => ({
      payload,
      meta: { onSuccess, onFailure },
    }),
    GET_ALL_BY_ORG_DONE: payload => payload,
    UPDATE: (routeParams, updates, onSuccess, onFailure) => ({
      payload: { routeParams, updates },
      meta: { onSuccess, onFailure },
    }),
    ADD: (payload, onSuccess) => ({
      payload,
      meta: { onSuccess },
    }),
    ADD_DONE: payload => payload,
    DELETE: (payload, onSuccess) => ({
      payload,
      meta: { onSuccess },
    }),
    DELETE_DONE: payload => payload,
    GET_OPTIONS: () => {},
    GET_OPTIONS_DONE: payload => payload,
  },
});

export default actionCreators.sample;
