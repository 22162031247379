import React from 'react';

import OrgSettingsPageTemplate from 'sow/containers/OrgSettingsPageTemplate';
import OrgSettingsEdit from 'sow/components/old/OrgSettings/Edit';
import GeneralForm from 'sow/components/old/createOrg/GeneralForm';

const OrgSettingsGeneralTabPage = () => {
  return (
    <OrgSettingsPageTemplate>
      <OrgSettingsEdit>
        <GeneralForm />
      </OrgSettingsEdit>
    </OrgSettingsPageTemplate>
  );
};

export default OrgSettingsGeneralTabPage;
