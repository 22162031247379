import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pending } from 'redux-saga-thunk';

import * as currentOrg from 'sow/selectors/currentOrg';
import * as currentOspApp from 'sow/selectors/currentOspApp';
import * as currentUser from 'sow/selectors/currentUser';
import FileDownloadLink from 'sow/components/old/File/DownloadLink';
import NavLink from 'sow/components/molecules/NavLink';
import Spinner from 'sow/components/atoms/Spinner';
import { attachmentRoute, attachmentOspRoute } from 'sow/routes';
import { confirm } from 'sow/actions/pure/messaging';
import { bulkDownloadRequest } from 'sow/store/actions';
import { utcStrToLocalMoment } from 'sow/utils/dateTime';
import {
  fetchAttachmentList,
  fetchAttachmentOspList,
  deleteAttachment,
  clearAttachmentList,
} from 'sow/actions/attachment';

import AddAttachmentModal from 'sow/components/old/attachment/AddAttachmentModal';
import AttachmentListRowActionsButton from 'sow/components/old/attachment/AttachmentListRowActionsButton';
import EditAttachmentModal from 'sow/components/old/attachment/EditAttachmentModal';
import WorksheetLink from 'sow/components/organisms/AttachmentWorksheetLink';
import OrgAttachmentPageTemplate from 'sow/containers/OrgAttachmentPageTemplate';
import OspAppSelectBar from 'sow/components/old/OspApp/SelectBar';

export const safeSortString = R.compose(R.toLower, R.trim, R.defaultTo(''));

export const categorySortValue = R.compose(safeSortString, R.prop('category'));

export const nameSortValue = R.compose(safeSortString, R.prop('name'));

export const sortAttachments = R.sortWith([
  R.ascend(R.compose(R.isNil, R.prop('category'))),
  R.ascend(categorySortValue),
  R.ascend(nameSortValue),
]);

const mapStateToProps = (state, props) => ({
  attachmentList: state.attachment.items,
  isFetching: state.attachment.isFetching,
  isBulkDownloading: pending(state, 'file/bulkBulkDownload'),
  orgId: currentOrg.orgId(state, props),
  ospAppId: currentOspApp.ospAppId(state, props),
  userId: currentUser.id(state, props),
  org: currentOrg.org(state, props),
  isAdmin: currentUser.showAcaUI(state, props),
  isAcaOwner: currentUser.isAcaOwner(state, props),
  isAcaStaff: currentUser.isAcaStaff(state, props),
  isInspector: currentUser.isInspector(state, props),
});

const mapDispatchToProps = {
  confirm,
  deleteAttachment,
  fetchAttachmentList,
  fetchAttachmentOspList,
  bulkDownloadRequest,
  clearAttachmentList,
};

const worksheetLinksDisabled = true;

/* eslint-disable react/no-deprecated */
class AttachmentList extends React.Component {
  state = {
    editingAttachmentIds: [],
    selectedIds: [],
  };

  componentDidMount() {
    const {
      fetchAttachmentList,
      fetchAttachmentOspList,
      isFetching,
      isOspList,
      orgId,
      ospAppId,
    } = this.props;

    if (!isFetching) {
      if (isOspList) {
        if (isOspList && ospAppId) {
          fetchAttachmentOspList(orgId, ospAppId);
        }
      } else {
        fetchAttachmentList(orgId);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fetchAttachmentList,
      fetchAttachmentOspList,
      isFetching,
      isOspList,
      orgId,
      ospAppId,
    } = this.props;

    if (!isFetching && orgId) {
      if (isOspList) {
        if (ospAppId && ospAppId !== prevProps.ospAppId) {
          fetchAttachmentOspList(orgId, ospAppId);
        }
      } else {
        if (orgId && orgId !== prevProps.orgId) {
          fetchAttachmentList(orgId);
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.clearAttachmentList();
  }

  allowedToDelete = attachment => {
    const { userId, isAdmin } = this.props;

    return isAdmin || userId === attachment.file.user_id;
  };

  areAllSelected = attachmentIds => {
    const unselectedIds = R.without(this.state.selectedIds, attachmentIds);
    return R.length(unselectedIds) < 1;
  };

  areAnySelected = () => {
    return !!R.length(this.state.selectedIds);
  };

  selectedAttachments = () => {
    const { attachmentList } = this.props;
    return R.filter(R.propSatisfies(this.isAttachmentSelected, 'id'), attachmentList);
  };

  handleBulkDownload = event => {
    const fileIds = R.map(R.path(['file', 'id']), this.selectedAttachments());

    return this.props.bulkDownloadRequest('file/bulk', {
      ids: fileIds,
    });
  };

  handleSelectCategoryAttachments = category => event => {
    const { attachmentList } = this.props;
    const categoryString = safeSortString(category);
    const categoryPred = R.compose(R.equals(categoryString), categorySortValue);
    const categoryAttachments = R.filter(categoryPred, attachmentList);
    const selectedAttachmentIds = R.pluck('id', categoryAttachments);

    return this.setState(R.assoc('selectedIds', selectedAttachmentIds));
  };

  handleToggleSelectAllAttachments = event => {
    const { attachmentList } = this.props;
    const allAttachmentIds = R.pluck('id', attachmentList);

    if (this.areAllSelected(allAttachmentIds)) {
      return this.setState(R.assoc('selectedIds', []));
    }

    return this.setState(R.assoc('selectedIds', allAttachmentIds));
  };

  handleToggleSelectAttachment = event => {
    const attachmentId = ~~event.target.value;

    if (this.isAttachmentSelected(attachmentId)) {
      this.unselectAttachment(attachmentId);
    } else {
      this.selectAttachment(attachmentId);
    }
  };

  isAttachmentSelected = attachmentId => {
    return R.contains(attachmentId, this.state.selectedIds);
  };

  selectAttachment = attachmentId => {
    this.setState(
      R.assoc('selectedIds', R.union([attachmentId], this.state.selectedIds)),
    );
  };

  unselectAttachment = attachmentId => {
    this.setState(
      R.assoc('selectedIds', R.without([attachmentId], this.state.selectedIds)),
    );
  };

  isAttachmentInEditMode(attachmentId) {
    return R.contains(attachmentId, this.state.editingAttachmentIds);
  }

  handleEditAttachmentFn = attachmentId => event => {
    // event.preventDefault();
    const { editingAttachmentIds } = this.state;
    const editingIds = R.append(attachmentId, editingAttachmentIds);

    this.setState(R.assoc('editingAttachmentIds', editingIds));
  };

  handleEditCloseAttachmentFn = attachmentId => event => {
    const { editingAttachmentIds } = this.state;
    const editingIds = R.without([attachmentId], editingAttachmentIds);

    this.setState(R.assoc('editingAttachmentIds', editingIds));
  };

  handleDeleteAttachmentFn = attachmentId => event => {
    // event.preventDefault();
    const { deleteAttachment, confirm, orgId } = this.props;

    confirm({
      message: 'Are you sure you want to delete this row?',
      onConfirm() {
        deleteAttachment(orgId, attachmentId);
      },
    });
  };

  renderRows() {
    const {
      attachmentList,
      userId,
      orgId,
      isAdmin,
      isOspList,
      ospAppId,
      isAcaOwner,
      isAcaStaff,
      isInspector,
    } = this.props;
    const sortedAttachmentList = sortAttachments(attachmentList);
    return sortedAttachmentList.map(attachment =>
      this.renderRow({
        userId,
        orgId,
        attachment,
        isAdmin,
        isOspList,
        ospAppId,
        isAcaOwner,
        isAcaStaff,
        isInspector,
      }),
    );
  }

  renderRow({
    userId,
    orgId,
    attachment,
    isAdmin,
    isOspList,
    ospAppId,
    isAcaOwner,
    isAcaStaff,
    isInspector,
  }) {
    const isSelected = this.isAttachmentSelected(attachment.id);
    const activeCss = isSelected ? 'bg-info' : '';
    const uploadDate = attachment.file
      ? attachment.file.inserted_at
      : attachment.inserted_at;

    if (
      isOspList ||
      (isAcaOwner && !isOspList) ||
      (!isAcaOwner && !isOspList && attachment.file.public) ||
      (isAcaStaff && !isInspector)
    )
      return (
        <tr key={attachment.id} className={`${activeCss}`}>
          <td className={`col-xs-1 text-center ${activeCss}`}>
            <input
              type="checkbox"
              onChange={this.handleToggleSelectAttachment}
              checked={isSelected}
              value={attachment.id}
            />
          </td>
          <td className={`${activeCss}`}>
            <FileDownloadLink fileId={attachment.file.id}>
              {attachment.name}
            </FileDownloadLink>
          </td>
          <td className={`${activeCss}`}>
            {utcStrToLocalMoment(uploadDate).format('L')}{' '}
            {utcStrToLocalMoment(uploadDate).format('LT')}
          </td>

          {isOspList && !worksheetLinksDisabled && (
            <td className={activeCss}>
              <WorksheetLink orgId={orgId} ospAppId={ospAppId} attachment={attachment} />
            </td>
          )}

          {!isOspList && (
            <td className={`text-center ${activeCss}`}>
              {R.isNil(attachment.category) ? null : (
                <code
                  onClick={this.handleSelectCategoryAttachments(attachment.category)}
                  className="text-nowrap"
                  style={{ cursor: 'pointer' }}
                  alt="Select Category"
                >
                  {attachment.category}
                </code>
              )}
            </td>
          )}
          {!isOspList && (
            <td className={`text-center ${activeCss}`}>
              {attachment.file.public ? (
                <span className="label label-primary col-xs-12">Public</span>
              ) : (
                <span className="label label-danger col-xs-12">ACA Only</span>
              )}
            </td>
          )}
          {!isOspList && (
            <td className={`col-xs-1 text-center ${activeCss}`}>
              <AttachmentListRowActionsButton
                handleEditClick={this.handleEditAttachmentFn(attachment.id)}
                handleDeleteClick={this.handleDeleteAttachmentFn(attachment.id)}
                hideDelete={!this.allowedToDelete(attachment)}
              />

              <EditAttachmentModal
                show={this.isAttachmentInEditMode(attachment.id)}
                onHide={this.handleEditCloseAttachmentFn(attachment.id)}
                orgId={orgId}
                attachment={attachment}
                isAdmin={isAdmin}
                isAcaOwner={isAcaOwner}
                isAcaStaff={isAcaStaff}
                isInspector={isInspector}
              />
            </td>
          )}
        </tr>
      );
  }

  renderNoRows() {
    const { isAdmin, isOspList } = this.props;
    const baseColSpan = !isOspList ? 7 : 3;
    const colSpan = isAdmin ? baseColSpan : baseColSpan + 1;

    return (
      <tr>
        <td className="text-center" colSpan={colSpan}>
          No entries found
        </td>
      </tr>
    );
  }

  render() {
    const {
      orgId,
      isAdmin,
      isOspList,
      attachmentList,
      isFetching,
      isBulkDownloading,
    } = this.props;

    if (isFetching)
      return (
        <OrgAttachmentPageTemplate>
          <Spinner />
        </OrgAttachmentPageTemplate>
      );

    const attachments = !R.isEmpty(attachmentList)
      ? this.renderRows()
      : this.renderNoRows();

    const allAttachmentIds = R.pluck('id', attachmentList);

    const bulkDownloadDisabled = !this.areAnySelected() || isBulkDownloading;
    const bulkDownloadBsColor = isBulkDownloading ? 'warning' : 'primary';

    return (
      <OrgAttachmentPageTemplate>
        <div className="nav nav-tabs">
          <NavLink to={attachmentOspRoute(orgId)}>Plan</NavLink>
          <NavLink to={attachmentRoute(orgId)} exact>
            General
          </NavLink>
          {!isOspList && (
            <div className="pull-right">
              <AddAttachmentModal orgId={orgId} />
            </div>
          )}

          <div className="pull-right">
            <button
              type="button"
              className={`btn btn-xs btn-${bulkDownloadBsColor}`}
              style={{ marginRight: 10 }}
              onClick={this.handleBulkDownload}
              disabled={bulkDownloadDisabled}
            >
              <span className="fa fa-file-zip-o" /> Bulk Download
            </button>
          </div>
        </div>

        {isOspList && <OspAppSelectBar />}

        <div className="panel panel-default">
          <table className="dataTable attachmentTable table table-bordered table-condensed table-striped">
            <thead>
              <tr>
                <th className="col-xs-1 text-center">
                  <input
                    type="checkbox"
                    onChange={this.handleToggleSelectAllAttachments}
                    checked={this.areAllSelected(allAttachmentIds)}
                  />
                </th>
                <th>Name</th>
                <th>Date</th>
                {isOspList && !worksheetLinksDisabled && <th>Worksheet</th>}
                {!isOspList && <th className="col-xs-1">Category</th>}
                {!isOspList && <th className="col-xs-1">Visibility</th>}
                {!isOspList && <th>{/* Actions */}</th>}
              </tr>
            </thead>

            <tbody>{attachments}</tbody>
          </table>
        </div>
      </OrgAttachmentPageTemplate>
    );
  }
}
/* eslint-disable */

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AttachmentList);
