import React, { Fragment } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { orgRoute } from 'sow/routes';
import * as sowTypes from 'sow/types';
import * as currentAca from 'sow/selectors/currentAca';
import * as currentOrg from 'sow/selectors/currentOrg';
import OrgLoader from 'sow/containers/loader/Org';
import OspAppListForOrgLoader from 'sow/containers/loader/OspAppListForOrg';
import StandardLayout from 'sow/components/templates/StandardLayout';
import SuspendedPage from 'sow/components/pages/SuspendedPage';

const mapStateToProps = (state, props) => ({
  org: currentOrg.org(state, props),
  acaSuspended: currentAca.suspended(state, props),
  orgDeactivated: currentOrg.deactivated(state, props),
  acaId: currentAca.acaId(state, props),
});

const OrgPageTemplateContainer = ({
  org,
  children,
  acaSuspended,
  orgDeactivated,
  title,
  breadcrumb,
  renderFatalError,
}) => {
  if (!children) return null;

  const acaSuspendedMessage = R.path(['message'], acaSuspended);

  if (acaSuspendedMessage) return <SuspendedPage message={acaSuspendedMessage} />;

  const orgName = R.pathOr('', ['name'], org);
  const orgId = R.pathOr('', ['id'], org);
  const defaultBreadcrumb = [[orgName, orgRoute(orgId)]];

  return (
    <StandardLayout title={title} breadcrumb={R.defaultTo(defaultBreadcrumb)(breadcrumb)}>
      <Fragment>
        {!R.isNil(renderFatalError) && renderFatalError()}

        <OrgLoader>
          {org => (
            <Fragment>
              {orgDeactivated && (
                <div className="alert alert-danger" role="alert">
                  <p className="lead">
                    <span
                      className="glyphicon glyphicon-exclamation-sign"
                      aria-hidden="true"
                    />{' '}
                    <b>Account Disabled!</b>
                  </p>
                  <p>Please contact your certifier for more information.</p>
                </div>
              )}

              {children}
            </Fragment>
          )}
        </OrgLoader>
      </Fragment>
    </StandardLayout>
  );
};

OrgPageTemplateContainer.propTypes = {
  children: PropTypes.node,
  acaSuspended: PropTypes.any,
  orgDeactivated: PropTypes.bool,
  org: sowTypes.orgType,
  title: PropTypes.string,
  breadcrumb: PropTypes.arrayOf(PropTypes.array),
  renderFatalError: PropTypes.func,
};

OrgPageTemplateContainer.defaultProps = {
  renderFatalError: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(OrgPageTemplateContainer);
