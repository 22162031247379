import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import ItemVersionCreatorQuestion from 'sow/components/organisms/ItemVersionCreatorQuestion';

const mapStateToProps = (state, props) => ({
  enableAdd: !R.isEmpty(props.selectedSection),
});

const mapDispatchToProps = {};

class ItemVersionCreatorQuestionContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { anchorEl: null };
  }

  showPopover({ currentTarget }) {
    this.setState({ anchorEl: currentTarget });
  }

  hidePopover() {
    this.setState({ anchorEl: null });
  }

  render() {
    return (
      <ItemVersionCreatorQuestion
        showPopover={this.showPopover.bind(this)}
        popoverParams={{
          anchorEl: this.state.anchorEl,
          hidePopover: this.hidePopover.bind(this),
        }}
        {...this.props}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ItemVersionCreatorQuestionContainer);
