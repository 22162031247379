import React from 'react';
import PropTypes from 'prop-types';

/** Renders a `strong` tag. All props are passed through. */
const Strong = ({ children, ...props }) =>
  <strong {...props}>
    {children}
  </strong>;

Strong.propTypes = {
  children: PropTypes.node,
};

export default Strong;
