import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';

import * as sowTypes from 'sow/types';
import { acaRoute } from 'sow/routes';
import * as currentAca from 'sow/selectors/currentAca';
import {
  fetchActivityLog,
  resetActivityLog,
  fetchActivityLogOptions,
} from 'sow/actions/aca';
import AcaActivityDashboardPage from 'sow/components/pages/AcaActivityDashboardPage';
import AcaPageTemplate from '../AcaPageTemplate';
import Spinner from 'sow/components/atoms/Spinner';

const isMoment = R.is(moment);

const utcFormatDatetimeForApi = datetime => {
  if (!isMoment(datetime)) return null;
  return moment(datetime)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss');
};

const compactObj = R.reject(R.isEmpty);

const formatFiltersDataForApi = R.pipe(
  R.evolve({
    start_datetime: utcFormatDatetimeForApi,
    end_datetime: utcFormatDatetimeForApi,
  }),
  compactObj,
);

const mapStateToProps = (state, props) => ({
  aca: currentAca.aca(state, props),
  acaId: currentAca.acaId(state, props),
  acaName: currentAca.name(state, props),
  isFetching: state.acaActivityLog.isFetching,
  logs: state.acaActivityLog.items,
  fetchingOptions: state.acaActivityLog.isFetchingOptions,
  selectOptions: state.acaActivityLog.selectOptions,
  searchInitiated: state.acaActivityLog.searchInitiated,
});

const mapDispatchToProps = {
  fetchActivityLog,
  resetActivityLog,
  fetchActivityLogOptions,
};

class AcaActivityDashboardPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      filters: this.defaultFilters(props),
    };
  }

  defaultFilters = props => {
    const query = queryString.parse(props.location.search);
    return {
      change_id: query.change_id || '',
      user_id: query.user_id,
      page_size: query.page_size || 25,
      start_datetime: !query.change_id
        ? query.start_datetime
          ? moment(query.start_datetime, 'ddd MMM DD yyyy HH:mm:ss').local()
          : moment().subtract(14, 'days')
        : moment().subtract(3, 'years'),
      end_datetime: query.end_datetime
        ? moment(query.end_datetime, 'ddd MMM DD yyyy HH:mm:ss').local()
        : moment(),
      organization_ids: query.organization_ids ? [query.organization_ids] : [],
      action: query.action || '',
    };
  };

  componentDidMount() {
    this.props.fetchActivityLogOptions(this.props.acaId);
    const query = queryString.parse(this.props.location.search);
    if (!R.isEmpty(query)) {
      this.loadActivityLog();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { filters } = this.state;

    if (!R.equals(filters, prevState.filters)) {
      const end = R.path(['end_datetime'], filters);
      const start = R.path(['start_datetime'], filters);
      const change_id = R.path(['change_id'], filters);

      const errors = compactObj({
        end_datetime: !isMoment(end) ? ['Invalid Date/Time'] : [],
        start_datetime: !isMoment(start) ? ['Invalid Date/Time'] : [],
        change_id: isNaN(change_id) ? ['Input is invalid. Please use only numbers.'] : [],
      });

      if (!R.isEmpty(errors)) {
        this.setState(R.assoc('errors', errors), this.props.resetActivityLog);
      } else {
        this.setState(R.assoc('errors', {}));
      }
    }
  }

  componentWillUnmount() {
    this.props.resetActivityLog();
  }

  loadActivityLog() {
    const { start_datetime, end_datetime } = this.state.filters;

    const filters = {
      ...this.state.filters,
      page: 1,
      start_datetime: moment(start_datetime).second(0),
      end_datetime: moment(end_datetime)
        .second(0)
        .add('1', 'minute'),
    };

    const filtersData = formatFiltersDataForApi(filters);
    this.props.fetchActivityLog(this.props.acaId, filtersData);
  }

  handleChange = field => value => {
    let newValue;

    if (field === 'organization_ids') {
      if (value) newValue = [value];
      else newValue = [];
    } else {
      newValue = value || '';
    }

    this.setState(R.assocPath(['filters', field], newValue));
  };

  handleResetFilters = () => {
    history.pushState({}, null, `/aca/${this.props.acaId}/activity`);
    this.setState({ filters: this.defaultFilters(window) }, this.props.resetActivityLog);
  };

  handleSearch = () => {
    const stringified = queryString.stringify(this.state.filters, {
      skipEmptyString: true,
      skipNull: true,
    });
    history.pushState(null, null, `?${stringified}`);
    this.loadActivityLog();
  };

  render() {
    const { errors, filters } = this.state;
    const {
      acaId,
      aca,
      acaName,
      logs,
      isFetching,
      fetchingOptions,
      selectOptions,
      searchInitiated,
    } = this.props;

    return (
      <AcaPageTemplate title="Activity Log" breadcrumb={[[acaName, acaRoute(acaId)]]}>
        <AcaActivityDashboardPage
          acaId={acaId}
          aca={aca}
          logs={logs}
          errors={errors}
          filters={filters}
          onFilterChange={this.handleChange.bind(this)}
          isFetching={isFetching}
          fetchingOptions={fetchingOptions}
          selectOptions={selectOptions}
          searchInitiated={searchInitiated}
          onResetFilters={this.handleResetFilters}
          handleSearch={this.handleSearch.bind(this)}
        />
      </AcaPageTemplate>
    );
  }
}

AcaActivityDashboardPageContainer.propTypes = {
  acaId: sowTypes.acaIdType,
  aca: sowTypes.acaType,
  acaName: PropTypes.string,
  logs: PropTypes.arrayOf(PropTypes.object),
  fetchActivityLog: PropTypes.func,
  resetActivityLog: PropTypes.func,
  isFetching: PropTypes.bool,
  location: PropTypes.object,
};

AcaActivityDashboardPageContainer.defaultProps = {
  acaId: null,
  aca: null,
  acaName: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  // withCurrentAca,
)(AcaActivityDashboardPageContainer);
