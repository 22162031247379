import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { worksheetQuestionType } from 'sow/types';

import Input from 'sow/components/atoms/Input';

const TextareaField = ({ question, answer, handleChange, disabled }) => (
  <Input
    type="textarea"
    id={question.uuid}
    value={(typeof answer === 'string' ? answer : typeof answer === 'object' && answer !=null ? (answer.checked != null ? answer.checked : '') : answer === true ? 'Yes' : answer)}
    onChange={e => handleChange(question.uuid, e.target.value)}
    disabled={disabled}
    placeholder={R.propOr('', 'placeholder', question)}
  />
);

TextareaField.defaultProps = {
  answer: '',
  disabled: false,
};

TextareaField.propTypes = {
  question: worksheetQuestionType.isRequired,
  answer: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default TextareaField;
