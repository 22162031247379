import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Select = ({
  className,
  options,
  value,
  onChange,
  showBlankOption,
  blankOptionText,
  sm,
  lg,
  children,
  ...props
}) => {
  const classes = classnames(className, 'form-control', {
    'input-sm': sm,
    'input-lg': lg,
  });

  // Discard formik props if present
  const {
    setFieldValue,
    setValues,
    updateValues,
    type,
    staticContext,
    ...fieldProps
  } = props;

  return (
    <select value={value} onChange={onChange} className={classes} {...fieldProps}>
      {showBlankOption && <option value="">{blankOptionText}</option>}
      {options.map((option, i) => (
        <option key={i} value={option.value} disabled={option.disabled}>
          {option.text}
        </option>
      ))}
      {children}
    </select>
  );
};

Select.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
      ]).isRequired,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disabled: PropTypes.bool,
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    .isRequired,
  sm: PropTypes.bool,
  lg: PropTypes.bool,
  showBlankOption: PropTypes.bool,
  blankOptionText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
  value: '',
  sm: false,
  lg: false,
  showBlankOption: true,
  blankOptionText: '',
  options: [],
  children: null,
};

export default Select;
