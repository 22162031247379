import React, { Component } from 'react';
import * as R from 'ramda';
import * as currentUser from 'sow/selectors/currentUser';
import LocationForm from 'sow/components/old/createOrg/LocationForm';
import FormGroup from 'sow/components/organisms/FormGroup';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state, props) => ({
  isInspector: currentUser.isInspector(state, props),
  isOrgAdmin: currentUser.isOrgAdmin(state, props),
  isAcaStaff: currentUser.isAcaStaff(state, props),
  isSuperAdmin: currentUser.isSuperAdmin(state, props),
});

const getOrgFieldValue = org => field => {
  const value = R.path([field], org);
  return R.defaultTo('', value);
};

class GeneralForm extends Component {
  handleLocationChange = (locationField, value) => {
    const { handleChange, org } = this.props;
    const location = R.prop('location', org) || {};

    handleChange('location', R.assoc(locationField, value, location));
  };

  render() {
    const {
      org,
      errors,
      handleChange,
      inEditMode,
      disabled,
      isInspector,
      isOrgAdmin,
      isAcaStaff,
      isSuperAdmin,
    } = this.props;
    const disableField =
      (disabled || isInspector || isOrgAdmin) &&
      (!isAcaStaff || isInspector) &&
      !isSuperAdmin;
    const { location } = org;
    const locationErrors = R.propOr(null, 'location', errors);

    const getValue = getOrgFieldValue(org);

    return (
      <div>
        <FormGroup
          fieldName="name"
          errors={errors}
          label="What is your organization's name?"
          required
        >
          <input
            type="text"
            id="name"
            value={getValue('name')}
            onChange={e => handleChange('name', e.target.value)}
            className="form-control"
            disabled={disableField}
            required
          />
        </FormGroup>

        {/* <FormGroup
          fieldName="contact_name"
          errors={errors}
          label="What is your organization's primary contact's name?"
          required
        >
          <input
            type="text"
            id="contact_name"
            value={getValue('contact_name')}
            onChange={e => handleChange('contact_name', e.target.value)}
            className="form-control"
            disabled={disableField}
            required
          />
        </FormGroup>

        <FormGroup
          fieldName="contact_phone"
          errors={errors}
          label="What is your organization's primary contact's phone number?"
          required
        >
          <input
            type="text"
            id="contact_phone"
            value={getValue('contact_phone')}
            onChange={e => handleChange('contact_phone', e.target.value)}
            className="form-control"
            disabled={disableField}
            required
          />
        </FormGroup> */}

        <LocationForm
          location={location}
          handleChange={this.handleLocationChange}
          errors={locationErrors}
          disabled={disableField}
        />
      </div>
    );
  }
}

export default R.compose(withRouter, connect(mapStateToProps))(GeneralForm);
