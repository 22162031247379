import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as userManagementActions from 'sow/actions/pure/userManagement';
import { register } from 'sow/actions/pure/registration';
import * as currentUser from 'sow/selectors/currentUser';
import actions from 'sow/actions/pure';
import * as acaAdminAction from 'sow/actions/pure/acaAdmin';
import * as acaAdminActions from 'sow/actions/acaAdmin';
import * as shellActions from 'sow/actions/pure/shell';
import {
  requestPasswordForNewAccount,
  requestPasswordReset,
} from 'sow/actions/pure/resetPassword';
import UserManagementEditUser from 'sow/components/organisms/UserManagementEditUser';
import { ResourceListLoader } from 'sow/store/helpers';
import { acaIdConstant } from 'sow/constants/acaId';
import { userTypeId, userSubtypeId } from 'sow/constants/userType';
import { inspectionTypes } from 'sow/constants/inspectionConstants';

const mapStateToProps = (state, props) => ({
  user: state.userManagement.user,
  fetchingOptions: state.userManagement.fetchingOptions,
  selectOptions: state.userManagement.selectOptions,
  currentUserId: currentUser.id(state, props),
});

const mapDispatchToProps = {
  addOrgToInspector: userManagementActions.assignInspector,
  addNewStaff: acaAdminAction.addStaff,
  removeStaff: acaAdminActions.revokeAcaAdmin,
  loadSingleUser: userManagementActions.loadSingleUser,
  editUserInfo: userManagementActions.editUser,
  addNewUser: userManagementActions.newUser,
  revokeInspector: userManagementActions.removeInspector,
  revokeSpecialist: userManagementActions.removeSpecialist,
  assignUser: userManagementActions.assignUser,
  loadFormOptions: userManagementActions.loadFormOptions,
  registerNewUser: register,
  revokeClientUser: userManagementActions.revokeUser,
  clearResults: userManagementActions.clearResults,
  requestPasswordForNewAccount,
  requestPasswordReset,
  addSpecialist: userManagementActions.addSpecialist,
  notify: shellActions.default.toast,
  loginAsUser: actions.auth.impersonateUser,
  addOrUpdatePriority: userManagementActions.addOrUpdatePriorityToOrg,
};

class UserManagementEditUserContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: true,
      id: '',
      userEdits: {
        name: '',
        email: '',
        inspector: false,
        workflowManager: false,
        admin: false,
        cs: false,
        enabled: true,
        acaStaff: acaIdConstant,
        staffRole: 'none',
        phoneNumber: null,
        userTitle: null,
      },
      currentSetup: {},
      showModal: false,
      modalTypeValues: ['', ''],
      acaSelect: acaIdConstant,
      userSubtype: null,
    };
  }

  componentDidMount() {
    this.props.loadFormOptions(acaIdConstant);
    const urlLocation = window.location.href.split('/').pop();
    if (urlLocation !== 'new') {
      this.props.loadSingleUser(urlLocation);
    }
  }

  componentDidUpdate(prevProps) {
    const userData = this.props.user[0];
    if (prevProps.user !== this.props.user && this.props.user.length > 0) {
      this.setState({
        currentSetup: {
          ...userData,
          acaStaff: userData.acaStaff || acaIdConstant,
          phoneNumber: userData.settings ? userData.settings.primary_phone_number : null,
          userTitle: userData.settings ? userData.settings.title : null,
        },
        userEdits: {
          ...userData,
          acaStaff: userData.acaStaff || acaIdConstant,
          phoneNumber: userData.settings ? userData.settings.primary_phone_number : null,
          userTitle: userData.settings ? userData.settings.title : null,
        },
        id: userData.id,
        isNew: false,
      });
      if (
        !userData.admin &&
        !userData.inspector &&
        !userData.cs &&
        !userData.workflowManager &&
        !userData.staffRole
      ) {
        this.setState(prevState => ({
          userEdits: {
            ...prevState.userEdits,
            staffRole: 'client',
            phoneNumber: userData.settings
              ? userData.settings.primary_phone_number
              : null,
            userTitle: userData.settings ? userData.settings.title : null,
          },
          currentSetup: {
            ...prevState.currentSetup,
            staffRole: 'client',
            phoneNumber: userData.settings
              ? userData.settings.primary_phone_number
              : null,
            userTitle: userData.settings ? userData.settings.title : null,
          },
          wasStaff: false,
        }));
      } else if (userData.staffRole) {
        this.setState(prevState => ({
          userEdits: {
            ...prevState.userEdits,
            staffRole: userData.staffRole,
            phoneNumber: userData.settings
              ? userData.settings.primary_phone_number
              : null,
            userTitle: userData.settings ? userData.settings.title : null,
          },
          currentSetup: {
            ...prevState.currentSetup,
            staffRole: userData.staffRole,
            phoneNumber: userData.settings
              ? userData.settings.primary_phone_number
              : null,
            userTitle: userData.settings ? userData.settings.title : null,
          },
          wasStaff: true,
        }));
      } else {
        this.setState(prevState => ({
          userEdits: {
            ...prevState.userEdits,
            staffRole: 'none',
            phoneNumber: userData.settings
              ? userData.settings.primary_phone_number
              : null,
            userTitle: userData.settings ? userData.settings.title : null,
          },
          currentSetup: {
            ...prevState.currentSetup,
            staffRole: 'none',
            phoneNumber: userData.settings
              ? userData.settings.primary_phone_number
              : null,
            userTitle: userData.settings ? userData.settings.title : null,
          },
          wasStaff: false,
        }));
      }
    }
  }

  componentWillUnmount() {
    this.props.clearResults('user');
  }

  showModal(modalTypeValues) {
    this.setState({ showModal: true, modalTypeValues });
  }

  closeModal() {
    this.setState({ showModal: false, password: '' });
  }

  onModalSave(field, value) {
    if (field === 'password') {
      this.setState(prevState => ({
        userEdits: {
          ...prevState.userEdits,
          [field]: value,
          passwordConfirmation: value,
        },
        showModal: false,
      }));
    } else {
      this.setState(prevState => ({
        userEdits: { ...prevState.userEdits, [field]: value },
        showModal: false,
      }));
    }
  }

  handleEmailConfirm(email, type) {
    switch (type) {
      case 'welcome':
        this.props.requestPasswordForNewAccount(email, null);
        break;
      case 'reset':
        this.props.requestPasswordReset(email);
        break;
      default:
        break;
    }
  }

  handleInputChange({ target }) {
    this.setState(prevState => ({
      userEdits: { ...prevState.userEdits, [target.id]: target.value },
    }));
    if (
      (target.id === 'password' || target.id === 'passwordConfirmation') &&
      this.state.missMatch
    ) {
      this.setState({ missMatch: false });
    }
  }

  handleOptionChange(value, field) {
    if (field === 'staffRole') {
      if (
        (!this.state.wasStaff || this.state.isNew) &&
        ['aca_admin', 'aca_owner', 'aca_osp_editor'].includes(value)
      ) {
        this.setState({ handleStaffUpdate: 'add' });
      } else if (this.state.wasStaff && ['none', 'client'].includes(value)) {
        this.setState({ handleStaffUpdate: 'delete' });
      } else if (
        this.state.wasStaff &&
        ['aca_admin', 'aca_owner', 'aca_osp_editor'].includes(value)
      ) {
        this.setState({ handleStaffUpdate: 'changed' });
      }
    }
    this.setState(prevProps => ({
      userEdits: { ...prevProps.userEdits, [field]: value },
    }));
  }

  dropDownHandler(target, field) {
    if (field === 'acaStaff') {
      this.setState(prevState => ({
        userEdits: { ...prevState.userEdits, [field]: target ? target.value : null },
        handleStaffUpdate: 'changed',
      }));
    } else if (field === 'consultantCompanyId') {
      this.setState(prevState => ({
        userEdits: { ...prevState.userEdits, [field]: target ? target.value : null },
      }));
    } else if (target && R.has('inspection_type_id', target)) {
      this.setState({
        [field]: target ? target.value : null,
        inspectionType: target.inspection_type_id,
      });
    } else {
      this.setState({ [field]: target ? target.value : null });
    }
  }

  updateUser(data) {
    let id;
    if (data) {
      id = data.id;
    } else {
      id = this.state.id;
    }
    history.pushState(null, null, `${id}`);
    this.props.loadSingleUser(String(id));
    if (this.state.isNew) {
      this.props.notify('success', 'User Added Successfully');
    } else {
      this.props.notify('success', 'User Updated Successfully');
    }
    this.setState(prevState => ({
      ...prevState,
      isNew: false,
      handleStaffUpdate: '',
    }));
  }

  updateOrgs(data) {
    this.props.loadSingleUser(String(data.user_id));
    history.pushState(null, null, `${data.user_id}`);
  }

  primaryUserAssigned(data, revoke) {
    this.setState({ primaryModal: true, currentPrimary: data, revoke: revoke });
  }

  onSavePrimary() {
    const { revoke } = this.state;
    if (revoke) {
      this.props.revokeClientUser(
        { user_id: revoke.user_id, organization_id: revoke.org_id, override: true },
        this.updateOrgs.bind(this),
      );
    } else {
      this.props.assignUser(
        {
          name: this.state.currentSetup.name,
          phone_number: this.state.currentSetup.phoneNumber,
          overwrite: true,
          user_id: this.state.id,
          organization_id: this.state.orgSelect,
          admin: true,
          user_type_id: userTypeId.client,
          user_subtype_id: this.state.userSubtype,
        },
        this.updateOrgs.bind(this),
      );
    }
    this.setState({ currentPrimary: {}, primaryModal: false, revoke: {} });
  }

  onCancelPrimary() {
    this.setState({ currentPrimary: {}, primaryModal: false });
  }

  handleStaffUser(data) {
    if (this.state.handleStaffUpdate === 'add') {
      this.addStaffUser(data);
    } else if (this.state.handleStaffUpdate === 'delete') {
      this.deleteStaffUser(data, this.updateUser.bind(this));
    } else if (this.state.handleStaffUpdate === 'changed') {
      this.deleteStaffUser(data);
      this.addStaffUser(data);
    } else {
      this.updateUser(data);
    }
  }

  addStaffUser(data) {
    this.props.addNewStaff(
      this.state.userEdits.acaStaff,
      {
        email: this.state.userEdits.email,
        user_id: data.id,
        role: this.state.userEdits.staffRole,
      },
      this.updateUser.bind(this, data),
    );
  }

  deleteStaffUser(data, success) {
    this.props.removeStaff(this.state.currentSetup.acaStaff, data.id, success);
  }

  onSaveHandler(user) {
    const newValues = {
      id: user.id,
      name: user.userEdits.name,
      email: user.userEdits.email,
      inspector: user.userEdits.inspector,
      is_workflow_manager: user.userEdits.workflowManager,
      admin: user.userEdits.admin,
      is_certification_specialist: user.userEdits.cs,
      is_enabled: user.userEdits.enabled,
      consultant: user.userEdits.consultant,
      consultant_company_id: user.userEdits.consultantCompanyId,
      settings: {
        ...user.userEdits.settings,
        primary_phone_number: user.userEdits.phoneNumber,
        title: user.userEdits.userTitle,
      },
    };

    if (user.userEdits.password && user.userEdits.password.length > 0) {
      newValues.password = user.userEdits.password;
      newValues.password_confirmation = user.userEdits.passwordConfirmation;
      if (user.userEdits.password !== user.userEdits.passwordConfirmation) {
        this.setState({ missMatch: true });
      }
    } else if (user.isNew) {
      const charString = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let randomPassword = '';
      for (let i = 0; i < 10; i++) {
        randomPassword += charString[Math.floor(Math.random() * charString.length)];
      }
      newValues.password = randomPassword;
      newValues.password_confirmation = randomPassword;
    }

    if (newValues.password === newValues.password_confirmation) {
      if (newValues.id) {
        this.props.editUserInfo(newValues, this.handleStaffUser.bind(this));
      } else {
        this.props.addNewUser(newValues, this.handleStaffUser.bind(this));
      }
    }
  }

  onAssignHandler() {
    // This can be overhauled once organization_user table is fully implemented.  Shouldn't need switch.
    // Look into userManagement action for refactoring as well.
    switch (this.state.userType) {
      case userTypeId.client:
        if (this.state.currentSetup.staffRole === 'client') {
          this.props.assignUser(
            {
              user_id: this.state.id,
              organization_id: this.state.orgSelect,
              admin: true,
              user_type_id: this.state.userType,
              user_subtype_id: this.state.userSubtype,
            },
            this.updateOrgs.bind(this),
            this.primaryUserAssigned.bind(this),
          );
        } else {
          this.props.notify('danger', 'User is not setup as a client');
        }
        break;
      case userTypeId.specialist:
        if (this.state.currentSetup.cs) {
          this.props.addSpecialist(
            {
              orgId: this.state.orgSelect,
              userId: this.state.id,
              userTypeId: userTypeId.specialist,
              userSubtypeId: this.state.userSubtype,
            },
            this.addUserPriority.bind(this),
          );
        } else {
          this.props.notify('danger', 'User is not setup as a certification specialist');
        }
        break;
      case userTypeId.inspector:
        const inspectorTypeId =
          this.state.inspectionType === inspectionTypes.annual
            ? userSubtypeId.annualInspector
            : this.state.inspectionType === inspectionTypes.partial
            ? userSubtypeId.partialInspector
            : null;
        if (this.state.currentSetup.inspector) {
          if (inspectorTypeId) {
            this.props.addOrgToInspector(
              {
                orgId: this.state.orgSelect,
                inspectorId: this.state.id,
                userSubtypeId: inspectorTypeId,
                inspectionId: this.state.userSubtype,
              },
              this.updateOrgs.bind(this),
            );
          }
        } else {
          this.props.notify('danger', 'User is not setup as an inspector');
        }
        break;
      default:
        break;
    }
  }

  onRevokeHandler(user) {
    switch (user.userType) {
      case 'Client':
        this.props.revokeClientUser(
          { user_id: user.userId, id: user.uniqueId, organization_id: user.orgId },
          this.updateOrgs.bind(this),
          this.primaryUserAssigned.bind(this),
        );
        break;
      case 'Inspector':
        this.props.revokeInspector(
          { rowId: user.uniqueId, orgId: user.orgId, userId: user.userId },
          this.updateOrgs.bind(this),
        );
        break;
      case 'Certification Specialist':
        this.props.revokeSpecialist(
          {
            orgId: user.orgId,
            userId: user.userId,
            userTypeId: userTypeId.specialist,
            userSubtypeId: user.userSubtypeId,
          },
          this.updateOrgs.bind(this),
        );
      default:
        break;
    }
  }

  onLoginAsUser(userId) {
    this.props.loginAsUser({
      impersonatedUserId: userId,
      impersonatorId: this.props.currentUserId,
    });
  }

  addUserPriority(data) {
    const { organization_user_id, user_id } = data;
    const { addOrUpdatePriority } = this.props;
    const { userEdits, userPriority } = this.state;
    const { applicationId, orgId } = userEdits;
    if (userPriority) {
      addOrUpdatePriority(
        {
          orgId,
          appId: applicationId,
          priority_type_id: userPriority,
          organization_user_id,
        },
        this.updateOrgs.bind(this),
      );
    } else {
      this.updateOrgs({ user_id });
    }
  }

  render() {
    return (
      <ResourceListLoader resource="aca/register" autoLoad>
        {({ result: acaList }) => {
          return (
            <UserManagementEditUser
              handleOptionChange={this.handleOptionChange.bind(this)}
              handleInputChange={this.handleInputChange.bind(this)}
              onSaveHandler={this.onSaveHandler.bind(this)}
              dropDownHandler={this.dropDownHandler.bind(this)}
              onAssignHandler={this.onAssignHandler.bind(this)}
              onRevokeHandler={this.onRevokeHandler.bind(this)}
              showModal={this.showModal.bind(this)}
              closeModal={this.closeModal.bind(this)}
              onModalSave={this.onModalSave.bind(this)}
              handleEmailConfirm={this.handleEmailConfirm.bind(this)}
              onLoginAsUser={this.onLoginAsUser.bind(this)}
              state={this.state}
              userData={this.props.user}
              acaList={acaList}
              columnTitles={this.props.columnTitles}
              onSavePrimary={this.onSavePrimary.bind(this)}
              onCancelPrimary={this.onCancelPrimary.bind(this)}
              fetchingOptions={this.props.fetchingOptions}
              selectOptions={this.props.selectOptions}
            />
          );
        }}
      </ResourceListLoader>
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(UserManagementEditUserContainer);
