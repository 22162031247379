import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadAllDone } from 'sow/actions/pure/certificationDocs';
import { Modal } from 'react-bootstrap';
import DocFileUploader from './DocFileUploader';

const mapDispatchToProps = { loadAllDone };

class UploadDocModal extends Component {
  state = {
    showModal: false,
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  handleUploadSuccess = res => {
    this.props.loadAllDone(res.data);
  };

  render() {
    const { showModal } = this.state;
    const { uploadUrl } = this.props;

    return (
      <div>
        <button type="button" className="btn btn-primary" onClick={this.showModal}>
          Upload Files <span className="fa fa-upload" />
        </button>

        <Modal backdrop="static" show={showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>Upload Files</Modal.Header>

          <Modal.Body>
            <DocFileUploader
              uploadUrl={uploadUrl}
              onUploadSuccess={this.handleUploadSuccess}
            />
          </Modal.Body>

          <Modal.Footer>
            <button type="button" className="btn btn-primary" onClick={this.hideModal}>
              Done
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(UploadDocModal);
