import React from 'react';

import AdminObjectTemplateContainer from 'sow/containers/AdminObjectTemplateContainer';
import ItemSubtypeListContainer from 'sow/containers/ItemSubtypeListContainer';

const ItemSubtypeListPage = () => (
  <AdminObjectTemplateContainer pageName="Item Subtypes" list>
    <ItemSubtypeListContainer />
  </AdminObjectTemplateContainer>
);

export default ItemSubtypeListPage;
