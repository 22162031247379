import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import ItemAttributeTypeEditContainer from 'sow/containers/ItemAttributeTypeEditContainer';

const ItemAttributeTypeEditPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <ItemAttributeTypeEditContainer />}
  </AdminPageTemplate>
);

export default ItemAttributeTypeEditPage;
