import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import ItemAttributeTypeListContainer from 'sow/containers/ItemAttributeTypeListContainer';

const ItemAttributeTypeListPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <ItemAttributeTypeListContainer />}
  </AdminPageTemplate>
);

export default ItemAttributeTypeListPage;
