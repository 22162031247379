import React from 'react';

import OrgSettingsPageTemplate from 'sow/containers/OrgSettingsPageTemplate';
import OrgSettingsHistoryContainer from 'sow/containers/OrgSettingsHistoryContainer';

const OrgSettingsHistoryTabPage = () => (
  <OrgSettingsPageTemplate>
    <OrgSettingsHistoryContainer />
  </OrgSettingsPageTemplate>
);

export default OrgSettingsHistoryTabPage;
