import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import SystemAdminItemHome from 'sow/components/organisms/SystemAdminItemHome';

const SystemAdminItemPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <SystemAdminItemHome />}
  </AdminPageTemplate>
);

export default SystemAdminItemPage;
