import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as priorityTypeActions from 'sow/actions/pure/priorityType';
import { apiGet, apiPost, apiPut } from 'sow/sagas/api';

export const loadPriorityTypes = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/priority_type', payload);
    if (response && meta.onSuccess) {
      meta.onSuccess(response.data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchById = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/priority_type/${payload}`);

    if (response && meta.onSuccess) {
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addPriorityType = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/priority_type', payload);
    if (response && meta.onSuccess) {
      yield put(actions.shell.toast('success', 'Priority type created.'));
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updatePriorityType = function*({ id, payload, meta }) {
  try {
    const { response } = yield call(apiPut, `/admin/priority_type/${id}`, payload);
    if (response) {
      yield put(actions.shell.toast('success', 'Priority type updated.'));
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateSortOrder = function*({ payload }) {
  try {
    yield call(apiPost, '/admin/priority_type/update_order', { updated_list: payload });
  } catch (error) {
    console.error(error);
  }
};

export const deletePriorityType = function*({ id, meta }) {
  try {
    const { response } = yield call(apiPut, `/admin/priority_type/delete/${id}`);
    if (response) {
      yield put(actions.shell.toast('success', 'Priority type deleted.'));
      if (meta.onSuccess) meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(priorityTypeActions.LOAD_PRIORITY_TYPES, loadPriorityTypes);
  yield takeEvery(priorityTypeActions.FETCH_BY_ID, fetchById);
  yield takeEvery(priorityTypeActions.ADD_PRIORITY_TYPE, addPriorityType);
  yield takeEvery(priorityTypeActions.UPDATE_PRIORITY_TYPE, updatePriorityType);
  yield takeEvery(priorityTypeActions.UPDATE_SORT_ORDER, updateSortOrder);
  yield takeEvery(priorityTypeActions.DELETE_PRIORITY_TYPE, deletePriorityType);
}
