import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { planAppChangeCommentType } from 'sow/types';

import Block from 'sow/components/atoms/Block';
import Spacer from 'sow/components/atoms/Spacer';
import { CommentLabel } from 'sow/components/organisms/PlanAppChangeComment';
import PlanAppChangeAddCommentForm from 'sow/components/organisms/PlanAppChangeAddCommentForm';
import PlanAppChangeComment from 'sow/containers/planApp/ChangeComment';

const SectionWrapper = styled(Block)`
  margin: 0;
  padding: 0;
  border-top: ${props => (props.showBorder ? '5px solid' : 'none')};
  border-left: ${props => (props.showBorder ? '5px solid' : 'none')};
  border-color: ${props => props.theme.cr.comment};
`;

const FormWrapper = styled(Block)`
  padding-left: 10px;
`;

const PlanAppChangeCommentSection = ({
  changeCommentList,
  onAddComment,
  addCommentDisabled,
  changeRequestIsOpen,
}) => {
  const hasComments = changeCommentList.length > 0;

  return (
    <SectionWrapper showBorder={changeRequestIsOpen || hasComments}>
      {changeCommentList.map(comment => (
        <Block key={comment.id}>
          <PlanAppChangeComment changeCommentId={comment.id} />
        </Block>
      ))}

      {changeRequestIsOpen && (
        <Block>
          {!hasComments && (
            <Block>
              <CommentLabel>Add a comment</CommentLabel>
              <Spacer vertical={10} />
            </Block>
          )}

          <FormWrapper>
            <PlanAppChangeAddCommentForm
              disabled={addCommentDisabled}
              onSubmit={onAddComment}
            />
          </FormWrapper>
        </Block>
      )}
    </SectionWrapper>
  );
};

PlanAppChangeCommentSection.propTypes = {
  changeCommentList: PropTypes.arrayOf(planAppChangeCommentType),
  onAddComment: PropTypes.func.isRequired,
  addCommentDisabled: PropTypes.bool,
  changeRequestIsOpen: PropTypes.bool,
};

export default PlanAppChangeCommentSection;
