import React from 'react';
import PropTypes from 'prop-types';

import Block from 'sow/components/atoms/Block';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';

const IconWithText = ({ icon, text }) => (
  <Row>
    <Column xs={2}>
      <Block textCenter>{icon}</Block>
    </Column>

    <Column xs={10}>{text}</Column>
  </Row>
);

IconWithText.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.node,
};

export default IconWithText;
