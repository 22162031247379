import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { planAppNoteType } from 'sow/types';
import Block from 'sow/components/atoms/Block';
import Emphasis from 'sow/components/atoms/Emphasis';
import ToggleVisibilityButton from './ToggleVisibilityButton';
import Note from './Note';
import NoteForm from './NoteForm';

const DeletedNotesToggle = styled(Block)`
  padding: 5px;
  margin-bottom: 10px;
  background-color: ${props => props.theme.note.backgroundDeleted};
  cursor: pointer;
`;

class PlanAppNotes extends Component {
  state = {
    showNotes: false,
    showDeleted: false,
  };

  toggleVisibility = path => this.setState(R.over(R.lensPath(path), R.not));
  toggleNotesVisibility = () => this.toggleVisibility(['showNotes']);
  toggleDeletedVisibility = () => this.toggleVisibility(['showDeleted']);

  filteredNotes = () => {
    const { notes } = this.props;
    const { showDeleted } = this.state;

    if (showDeleted) return notes;

    return R.filter(
      R.where({
        isDeleted: R.either(R.isNil, R.equals(false)),
      }),
    )(notes);
  };

  render() {
    const {
      notes,
      onCreate,
      onDelete,
      isCreating,
      isDeleting,
      showAcaUI,
      children,
    } = this.props;
    const { showNotes, showDeleted } = this.state;

    const hasNotes = notes.length > 0;
    const hasPublicNotes =
      hasNotes &&
      R.pipe(
        R.map(R.prop('public')),
        R.any(R.equals(true)),
      )(notes);

    const deletedCount = R.pipe(
      R.filter(R.whereEq({ isDeleted: true })),
      R.length,
    )(notes);

    // For org users don't show the toggle if there aren't any public notes
    if (!showAcaUI && !hasPublicNotes) {
      return children({
        NotesToggle: null,
        NoteList: null,
      });
    }

    return children({
      NotesToggle: (
        <ToggleVisibilityButton
          hasNotes={hasNotes}
          onClick={this.toggleNotesVisibility}
        />
      ),
      NoteList: showNotes && (
        <Block>
          {hasNotes && (
            <Block>
              {!showDeleted && deletedCount > 0 && (
                <DeletedNotesToggle textMuted onClick={this.toggleDeletedVisibility}>
                  <Emphasis>
                    {deletedCount} deleted notes are hidden. Click to show.
                  </Emphasis>
                </DeletedNotesToggle>
              )}

              {this.filteredNotes().map(note => (
                <Note
                  key={note.id}
                  note={note}
                  showAcaUI={showAcaUI}
                  onDelete={onDelete}
                  isDeleting={isDeleting}
                  toggleDeletedVisibility={this.toggleDeletedVisibility}
                />
              ))}
            </Block>
          )}
          {showAcaUI && <NoteForm onCreate={onCreate} isCreating={isCreating} />}
        </Block>
      ),
    });
  }
}

PlanAppNotes.propTypes = {
  children: PropTypes.func.isRequired,
  notes: PropTypes.arrayOf(planAppNoteType).isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isCreating: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  showAcaUI: PropTypes.bool.isRequired,
};

export default PlanAppNotes;
