import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';

import { urlSubtypeId } from 'sow/constants/userType';
import { setFilter, getFilter } from 'sow/utils/dashboardFilterStorage';
import { fetchDashboardList, fetchDashboardCounts } from 'sow/actions/orgWorkflow';
import * as currentAca from 'sow/selectors/currentAca';

import SpecialistDashboardFilters from 'sow/components/organisms/SpecialistDashboardFilters';

const mapStateToProps = (state, props) => ({
  acaId: currentAca.acaId(state, props),
  counts: state.dashboard.counts,
  filter: getFilter(),
});

const mapDispatchToProps = { fetchDashboardList, fetchDashboardCounts };

class SpecialistDashboardFiltersContainer extends Component {
  handleFilterChange(filter) {
    const searchFilters = queryString.parse(this.props.location.search, {
      arrayFormat: 'comma',
    });

    setFilter(filter);
    if (R.pathEq(['match', 'params', 'clientFilter'], 'all')(this.props)) {
      this.reloadDashboard(searchFilters);
    } else {
      this.reloadFilteredDashboard(this.props.match.params.clientFilter, searchFilters);
    }
  }

  reloadDashboard(additionalFilters) {
    this.props.fetchDashboardList(
      this.props.acaId,
      getFilter(),
      queryString.stringify(additionalFilters, { arrayFormat: 'bracket' }),
    );
  }

  reloadFilteredDashboard(clientFilter, additionalFilters) {
    const filters = { type: urlSubtypeId[clientFilter], ...additionalFilters };

    this.props.fetchDashboardList(this.props.acaId, getFilter(), [
      queryString.stringify(filters, { arrayFormat: 'bracket' }),
    ]);
  }

  render() {
    return (
      <SpecialistDashboardFilters
        filter={getFilter()}
        handleFilterChange={this.handleFilterChange.bind(this)}
        {...this.props}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SpecialistDashboardFiltersContainer);
