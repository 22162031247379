import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Caption from 'sow/components/atoms/Caption';

const Table = ({
  className,
  bordered,
  striped,
  condensed,
  caption,
  head,
  hover,
  foot,
  children,
  ...props
}) => {
  const classes = classNames(className, 'table', {
    'table-bordered': bordered,
    'table-hover': hover,
    'table-striped': striped,
    'table-condensed': condensed,
  });

  return (
    <table {...props} className={classes}>
      {caption && <Caption>{caption}</Caption>}
      {head && <thead>{head}</thead>}
      {children && <tbody>{children}</tbody>}
      {foot && <tfoot>{foot}</tfoot>}
    </table>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  bordered: PropTypes.bool,
  striped: PropTypes.bool,
  condensed: PropTypes.bool,
  caption: PropTypes.string,
  head: PropTypes.node,
  hover: PropTypes.bool,
  foot: PropTypes.node,
  children: PropTypes.any,
};

export default Table;
