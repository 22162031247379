import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Link from 'sow/components/atoms/Link';
import List from 'sow/components/atoms/List';
import ListItem from 'sow/components/atoms/ListItem';
import NavLink from 'sow/components/molecules/NavLink';

const SidebarLink = ({
  to,
  exact,
  disabled,
  onClick,
  className,
  children,
  href,
  target,
}) => {
  // Render a disabled link
  if (disabled) {
    return (
      <List className="sidebar-list">
        <Link
          disabled
          onClick={e => e.preventDefault()}
          className="text-muted"
          style={{ cursor: 'not-allowed' }}
        >
          {children}
        </Link>
      </List>
    );
  }

  return (
    <NavLink to={to} onClick={onClick} exact={exact} href={href} target={target}>
      {children}
    </NavLink>
  );
};

SidebarLink.defaultProps = {
  disabled: false,
  index: false,
  className: '',
};

SidebarLink.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.bool,
  className: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  children: PropTypes.node,
  router: PropTypes.object,
};

export default withRouter(SidebarLink);
