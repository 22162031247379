import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import Select from 'sow/components/molecules/Select';

const scopeLabelsMap = {
  Grower: 'Farming',
  Livestock: 'Livestock',
  Handler: 'Handling or Processing',
  'Handler COR': 'Handling or Processing in Canada',
  'Private Label': 'Private Label',
  Retail: 'Retail or Restaurant',
};

const scopeLabel = scope => R.propOr(scope, scope, scopeLabelsMap);

class LandScopeSelect extends React.Component {
  componentDidMount() {
    if (!this.isValueValid(this.props.scopeList, this.props.value)) {
      this.resetValue();
    }
  }

  componentDidUpdate() {
    if (!this.isValueValid(this.props.scopeList, this.props.value)) {
      this.resetValue();
    }
  }

  isValueValid(scopeList, value) {
    if (value === '') {
      return true;
    }

    return scopeList.includes(value);
  }

  setValue(value) {
    this.props.setFieldValue(this.props.name, value);
  }

  resetValue() {
    this.props.setFieldValue(this.props.name, '');
  }

  handleChange = event => this.setValue(event.target.value);

  render() {
    const { scopeList, ...props } = this.props;

    const isScopeDisabled = scope => {
      return !scope || !R.contains(scope, scopeList);
    };

    const options = ['', ...scopeList];
    const selectOptions = options.map(scope => ({
      value: scope,
      text: scopeLabel(scope),
      disabled: isScopeDisabled(scope),
    }));

    return (
      <Select
        {...props}
        onChange={this.handleChange}
        options={selectOptions}
        showBlankOption={false}
      />
    );
  }
}

LandScopeSelect.propTypes = {
  scopeList: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default LandScopeSelect;
