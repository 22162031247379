import React from 'react';
import PropTypes from 'prop-types';
import { DropdownButton, MenuItem } from 'react-bootstrap';

import { initialize } from 'sow/utils/string';
import { profileRoute } from 'sow/routes';
import Atom from 'sow/components/atoms/Atom';
import Block from 'sow/components/atoms/Block';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import ListItem from 'sow/components/atoms/ListItem';
import LogoutButton from 'sow/containers/LogoutButton';

const InitialsCircle = ({ name }) => {
  return !name ? (
    <FontAwesome icon="user" />
  ) : (
    <Block inline className="user-initials">
      {initialize(name)}
    </Block>
  );
};

InitialsCircle.propTypes = {
  name: PropTypes.string,
};

const UserName = ({ name }) => (
  <Atom className="hidden-xs" style={{ fontSize: '12pt', paddingLeft: '5px' }}>
    {name}
  </Atom>
);

UserName.propTypes = {
  name: PropTypes.string,
};

const PrettyNameDisplay = ({ name }) => (
  <Atom>
    <InitialsCircle name={name} />
    <UserName name={name} />
  </Atom>
);

PrettyNameDisplay.propTypes = {
  name: PropTypes.string,
};

const UserButton = ({ name, email }) => (
  <Block className="user text-right">
    {/* This needs a positioning fix since react-bootstrap won't
        give us access to the div we *actually* need classes on. */}
    <Block style={{ textAlign: 'right', paddingRight: '10px' }}>
      <DropdownButton
        id="userProfileButton"
        title={<PrettyNameDisplay name={name} />}
        className="item"
        bsStyle="link"
        pullRight
      >
        <MenuItem header>Logged in as: {email}</MenuItem>
        <MenuItem divider />
        <MenuItem href={profileRoute()}>
          <FontAwesome icon="user" /> My Profile
        </MenuItem>
        <ListItem>
          <LogoutButton />
        </ListItem>
      </DropdownButton>
    </Block>
  </Block>
);

UserButton.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
};

export default UserButton;
