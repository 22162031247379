import React from 'react';

import ItemVersionEditPageTemplate from 'sow/components/templates/ItemVersionEditPageTemplate';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Block from 'sow/components/atoms/Block';
import ItemVersionCreatorSectionContainer from 'sow/containers/ItemVersionCreatorSectionContainer';
import ItemVersionCreatorQuestionContainer from 'sow/containers/ItemVersionCreatorQuestionContainer';

const ItemVersionCreator = ({
  isLoading,
  itemVersionId,
  loadItemVersionSections,
  sectionBlockData,
  questionBlockParams,
}) => (
  <ItemVersionEditPageTemplate isLoading={isLoading} id={itemVersionId}>
    <Widget className="nav-widget">
      <WidgetBody>
        <Row className="item-version-editor-block">
          <Column className="editor-column" xs={3}>
            <ItemVersionCreatorSectionContainer
              itemVersionId={itemVersionId}
              loadItemVersionSections={loadItemVersionSections}
              {...sectionBlockData}
            />
          </Column>
          <Column className="editor-column section-creator grey-background" xs={9}>
            <Block className="editor-header creator-header">
              <ItemVersionCreatorQuestionContainer {...questionBlockParams} />
            </Block>
          </Column>
        </Row>
      </WidgetBody>
    </Widget>
  </ItemVersionEditPageTemplate>
);

export default ItemVersionCreator;
