import * as R from 'ramda';
import React, { Component } from 'react';
import ReactDatetime from 'react-datetime';
import Switch from 'react-bootstrap-switch';
import moment from 'moment';
import { Glyphicon } from 'react-bootstrap';

import AdminXray from 'sow/components/old/AdminXray';
import FileDownloadLink from 'sow/components/old/File/DownloadLink';
import FileUploadModal from 'sow/components/old/osp/FileUploadModal';
import OspAppEditorQuestionField from 'sow/components/old/OspApp/Editor/Question/Field';
import FileListQuestionField from 'sow/components/old/OspApp/Editor/Question/Field/FileList';
import Tooltip from 'sow/components/molecules/Tooltip';

export const TextField = ({ answer = '', disabled, handleChange, question }) => (
  <input
    type="text"
    id={question.uuid}
    placeholder={question.placeholder}
    value={answer || ''}
    onChange={e => handleChange(question.uuid, e.target.value)}
    className="form-control"
    disabled={disabled}
  />
);

export class CheckboxList extends Component {
  buildAnswer = R.assoc('__version', '1');

  getAnswer = () => {
    const { answer } = this.props;
    const options = this.getOptions();

    // no existing answer
    if (!answer) return this.buildAnswer({});

    const answerVersion = R.path(['__version'], answer);

    if (!answerVersion) {
      const noAnswerMap = R.into({}, R.map(R.pair(R.__, false)), options);
      const answerMap = R.into(noAnswerMap, R.map(R.pair(R.__, true)), answer);

      return this.buildAnswer(answerMap);
    }

    return this.buildAnswer(answer);
  };

  getOptions = () => R.pathOr([], ['question', 'answers'], this.props);

  handleChange = (answer, option) => event => {
    const { handleChange, question, disabled } = this.props;

    if (disabled) return;

    const newValue = !this.optionValue(answer, option);

    const updatedAnswer = R.assoc(option, newValue, answer);

    handleChange(question.uuid, updatedAnswer);
  };

  optionValue = (answer, option) => R.propOr(null, option, answer);

  render() {
    const { question, disabled } = this.props;
    const answer = this.getAnswer();

    return (
      <div
        className={question.isBaseQuestion ? null : 'well well-sm'}
        style={{ margin: 0, padding: '5px 10px 0 10px' }}
      >
        {this.getOptions().map(option => {
          const onChange = this.handleChange(answer, option);
          const value = this.optionValue(answer, option);

          return (
            <div key={option} className="row" style={{ margin: '5px 0' }}>
              <div className="col-xs-2" style={{ width: 60, paddingLeft: 0 }}>
                <AdminXray data={{ question, answer, disabled }} log />
                <Switch
                  value={value}
                  tristate={true}
                  disabled={disabled}
                  onChange={onChange}
                  offText="No"
                  onText="Yes"
                  bsSize="mini"
                  handleWidth={50}
                  labelWidth={10}
                />
              </div>
              <div className="col-xs-10" style={{ paddingLeft: 0 }}>
                <label
                  className={`bg-${value ? 'info' : ''}`}
                  style={{ margin: '0 8px', padding: '3px', cursor: 'pointer' }}
                  onClick={onChange}
                  disabled={disabled}
                >
                  {option}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export const SelectBox = ({ question, answer = '', handleChange, disabled }) => (
  <select
    id={question.uuid}
    value={answer}
    onChange={e => handleChange(question.uuid, e.target.value)}
    className="form-control"
    disabled={disabled}
  >
    <option value="">Select...</option>
    {question.answers.map((answer, i) => (
      <option key={i} value={answer}>
        {answer}
      </option>
    ))}
  </select>
);

export const Checkbox = ({ question, answer = null, handleChange, disabled }) => {
  return (
    <span className="osp-ws-field--checkbox">
      <input
        type="checkbox"
        checked={answer === true}
        onChange={_ => handleChange(question.uuid, !answer)}
        disabled={disabled}
      />
    </span>
  );
};

export const BooleanSelector = ({ question, answer = null, handleChange, disabled }) => {
  const options = { Yes: true, No: false };

  const handleOnChange = (elm, state) => handleChange(question.uuid, state);

  return (
    <div
      className={question.isBaseQuestion ? null : 'well well-sm'}
      style={{ margin: 0 }}
    >
      <Switch
        value={answer}
        tristate={R.isNil(answer)}
        disabled={disabled}
        onChange={handleOnChange}
        offText="No"
        onText="Yes"
        bsSize="normal"
        offColor={question.isBaseQuestion ? 'danger' : 'default'}
        handleWidth={60}
        labelWidth={10}
      />
    </div>
  );
};

export const renderFileListRow = handleRemove => file => {
  console.log('renderFileListRow', { file, handleRemove });
  // if (!file) return null;

  return (
    <tr key={file.id}>
      <td>
        <FileDownloadLink fileId={file.id}>{file.name}</FileDownloadLink>
      </td>
      <td className="text-center">
        <button className="btn btn-danger btn-xs" onClick={handleRemove(file.id)}>
          <Tooltip overlay="Remove">
            <Glyphicon glyph="remove" />
          </Tooltip>
        </button>
      </td>
    </tr>
  );
};

export const FileList = ({
  questionId,
  files,
  orgId,
  worksheetId,
  disabled,
  handleRemove = fileId => event => console.log('handleRemove', { fileId, event }),
}) => {
  const fileList = files && R.reject(R.isNil, files);
  if (!fileList || R.isEmpty(fileList)) return FileListNoRows;

  const renderRows = R.map(renderFileListRow(handleRemove));

  return (
    <div className="panel panel-default">
      <table className="table table-bordered table-condensed table-striped">
        <tbody>{renderRows(fileList)}</tbody>
      </table>
    </div>
  );
};

// todo use the nice file upload component
export const FileSelector = ({
  question,
  answer = [],
  handleChange,
  orgId,
  worksheetId,
  disabled,
}) => (
  <div>
    {/* Placeholder here until we know what the file objects look like */}
    {answer.map(file => (
      <div key={file.id}>{file.name}</div>
    ))}
    <FileList
      orgId={orgId}
      files={answer}
      worksheetId={worksheetId}
      questionId={question.uuid}
      handleChange={handleChange}
      disabled={disabled}
    />

    <FileUploadModal
      orgId={orgId}
      files={answer}
      worksheetId={worksheetId}
      questionId={question.uuid}
      handleChange={handleChange}
      disabled={disabled}
      autoUpload
    />
  </div>
);

export const TextareaField = ({ question, answer = '', handleChange, disabled }) => (
  <textarea
    id={question.uuid}
    value={answer}
    onChange={e => handleChange(question.uuid, e.target.value)}
    className="form-control"
    disabled={disabled}
    placeholder={question.placeholder}
  />
);

const parseValueToState = value => {
  if (typeof value !== 'object') return '';

  try {
    return value.format();
  } catch (e) {
    console.error(
      'Error converting DatePicker onChange return value to string for worksheet answer',
      e,
    );
  }
};

const parseStateToValue = state => {
  if (!state) return '';

  try {
    return moment(state).format('L');
  } catch (e) {
    console.error(
      'Error converting worksheet answer to pass in as `value` prop of DatePicker',
      e,
    );
  }
};

export const DatePicker = ({ question, answer = '', handleChange, disabled }) => (
  <ReactDatetime
    value={parseStateToValue(answer)}
    onChange={value => handleChange(question.uuid, parseValueToState(value))}
    dateFormat={true}
    timeFormat={false}
    closeOnSelect={true}
    inputProps={{
      disabled,
      placeholder: 'Input date',
    }}
  />
);

export function createField(
  orgId,
  ospId,
  worksheetId,
  question,
  answer,
  handleChange,
  disabled = false,
) {
  const fieldProps = {
    orgId,
    ospId,
    worksheetId,
    question,
    answer,
    handleChange,
    disabled,
  };

  const fieldType = question.field_type && question.field_type.toLowerCase();
  switch (fieldType) {
    case 'text':
    case 'number':
      return <TextField {...fieldProps} />;

    case 'checkbox_list':
      return <CheckboxList {...fieldProps} />;

    case 'select':
      return <SelectBox {...fieldProps} />;

    case 'checkbox':
      return <Checkbox {...fieldProps} />;

    case 'bool':
      return <BooleanSelector {...fieldProps} />;

    case 'files':
      // return <FileSelector {...fieldProps} />;
      return <FileListQuestionField {...fieldProps} />;

    case 'textarea':
      return <TextareaField {...fieldProps} />;

    case 'date':
      return <DatePicker {...fieldProps} />;

    default:
      // TODO move all fields to separate files in OspAppEditorQuestionField
      return <OspAppEditorQuestionField {...fieldProps} />;
  }
}
