import * as R from 'ramda';
import actions from 'sow/actions/pure';
import {
  fetchAcaAdminListAsync,
  fetchCertificationSpecialistListAsync,
  revokeAcaAdminAsync,
  createAcaAsync,
} from 'sow/services/acaAdmin';

const mark = action => `soworganic/aca_admin/${action}`;

export const FETCH_ACA_ADMIN_LIST = mark('FETCH_ACA_ADMIN_LIST');
export const FETCH_ACA_ADMIN_LIST_SUCCESS = mark('FETCH_ACA_ADMIN_LIST_SUCCESS');
export const FETCH_ACA_ADMIN_LIST_FAILED = mark('FETCH_ACA_ADMIN_LIST_FAILED');

export const fetchAcaAdminList = acaId => dispatch => {
  dispatch({ type: FETCH_ACA_ADMIN_LIST });

  fetchAcaAdminListAsync(acaId)
    .done(res => {
      dispatch({
        type: FETCH_ACA_ADMIN_LIST_SUCCESS,
        data: res.data,
      });
    })
    .fail(err => {
      console.error('Error fetching aca admin list:', err.responseJSON);
      dispatch({ type: FETCH_ACA_ADMIN_LIST_FAILED });
    });
};

export const fetchCertificationSpecialistList = (acaId, filters) => dispatch => {
  dispatch({ type: FETCH_ACA_ADMIN_LIST });

  fetchCertificationSpecialistListAsync(acaId, filters)
    .done(res => {
      dispatch({
        type: FETCH_ACA_ADMIN_LIST_SUCCESS,
        data: res.data,
      });
    })
    .fail(err => {
      console.error('Error fetching aca admin list:', err.responseJSON);
      dispatch({ type: FETCH_ACA_ADMIN_LIST_FAILED });
    });
};

export const REVOKE_ACA_ADMIN = mark('REVOKE_ACA_ADMIN');
export const REVOKE_ACA_ADMIN_SUCCESS = mark('REVOKE_ACA_ADMIN_SUCCESS');
export const REVOKE_ACA_ADMIN_FAILED = mark('REVOKE_ACA_ADMIN_FAILED');

export const revokeAcaAdmin = (acaId, userId, success) => dispatch => {
  dispatch({ type: REVOKE_ACA_ADMIN });

  revokeAcaAdminAsync(acaId, userId)
    .done(res => {
      dispatch({
        type: REVOKE_ACA_ADMIN_SUCCESS,
        data: res.data,
      });

      if (success) success();
    })
    .fail(err => {
      console.error('Error revoking user aca admin:', err.responseJSON);
      dispatch({ type: REVOKE_ACA_ADMIN_FAILED });
    });
};

export const CREATE_ACA = mark('CREATE_ACA');
export const CREATE_ACA_SUCCESS = mark('CREATE_ACA_SUCCESS');
export const CREATE_ACA_FAILED = mark('CREATE_ACA_FAILED');

export const createAca = (aca, success) => dispatch => {
  dispatch({ type: CREATE_ACA });

  createAcaAsync(aca)
    .done(res => {
      dispatch({
        type: CREATE_ACA_SUCCESS,
        data: res.data,
      });

      dispatch(actions.shell.toast('success', 'ACA created successfully.'));

      if (success) success(res.data);
    })
    .fail(err => {
      dispatch({
        type: CREATE_ACA_FAILED,
        data: err.responseJSON,
      });

      dispatch(
        actions.shell.toast(
          'danger',
          'Error creating ACA',
          R.path(['responseJSON', 'error', 'errors'], err),
        ),
      );
    });
};
