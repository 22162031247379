import * as R from 'ramda';
import { combineActions, handleActions } from 'redux-actions';

import actions from 'sow/actions/pure';

const initialConfirmState = () => ({
  isShowing: false,
  title: null,
  message: null,
  noButtonText: null,
  yesButtonText: null,
});

const confirmReducer = handleActions(
  {
    [combineActions(
      // actions.shell.confirm.answerNo,
      // actions.shell.confirm.answerYes,
      // actions.shell.confirm.dismiss,
      actions.shell.confirm.hide,
    )](state) {
      return initialConfirmState();
    },

    [actions.shell.confirm.show](state, action) {
      const { title, message, noButtonText, yesButtonText } = action.payload;
      return R.merge(state, {
        title,
        message,
        noButtonText,
        yesButtonText,
        isShowing: true,
      });
    },
  },
  initialConfirmState(),
);

export default confirmReducer;

export const getIsShowing = R.prop('isShowing');
export const getTitle = R.prop('title');
export const getMessage = R.prop('message');
export const getNoButtonText = R.prop('noButtonText');
export const getYesButtonText = R.prop('yesButtonText');
