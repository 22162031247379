import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';

import { planAppChangeCommentType } from 'sow/types';

import Block from 'sow/components/atoms/Block';
import Strong from 'sow/components/atoms/Strong';
import Emphasis from 'sow/components/atoms/Emphasis';
import Paragraph from 'sow/components/atoms/Paragraph';
import Timestamp from 'sow/components/molecules/Timestamp';
import PlanAppChangeCommentDismissButton from 'sow/containers/planApp/ChangeCommentDismissButton';

const CommentWrapper = styled(Block)`
  background-color: ${props => rgba(props.theme.cr.comment, 0.15)};
  padding: 0px;
  margin-bottom: 15px;
`;

export const CommentLabel = styled(Block)`
  display: inline-block;
  padding: 7px 20px 5px 15px;
  font-size: 9pt;
  color: white;
  background-color: ${props => props.theme.cr.comment};
`;

const CommentDismiss = styled(Block)`
  background-color: white;
  float: right;
`;

const CommentText = styled(Paragraph)`
  padding: 20px;
  color: #555;
`;

const PlanAppChangeComment = ({ changeComment, notifyUser }) => (
  <CommentWrapper>
    <CommentLabel>
      Comment by <Strong>{changeComment.user && changeComment.user.name}</Strong>{' '}
      <Emphasis style={{ color: '#eee' }}>
        <Timestamp date={changeComment.insertedAt} />
      </Emphasis>
    </CommentLabel>
    {notifyUser && (
      <CommentDismiss>
        <PlanAppChangeCommentDismissButton changeCommentId={changeComment.id} />
      </CommentDismiss>
    )}
    <CommentText>{changeComment.text}</CommentText>
  </CommentWrapper>
);

PlanAppChangeComment.propTypes = {
  changeComment: planAppChangeCommentType.isRequired,
  notifyUser: PropTypes.bool.isRequired,
};

export default PlanAppChangeComment;
