import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import * as currentOrg from 'sow/selectors/currentOrg';
import PlanAppExportContent from 'sow/components/organisms/PlanAppExportContent';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  org: currentOrg.org(state, props),
  planApp: fromPlanApp.planApp(state, props),
  planAppSummary: fromPlanApp.planAppSummary(state, props),
  qualifiedWorksheetIds: fromPlanApp.qualifiedWorksheetIds(state, props),
  worksheetSections: fromPlanApp.qualifiedWorksheetSections(state, props),
  locationIdList: fromPlanApp.planAppLocationIdList(state, props),
  showMainPlan: fromPlanApp.exportConfigShowMainPlan(state, props),
  showLocations: fromPlanApp.exportConfigShowLocations(state, props),
});

const PlanAppExportContentContainer = props => <PlanAppExportContent {...props} />;

PlanAppExportContentContainer.propTypes = {
  // passed props
  orgId: sowTypes.orgIdType,
  planAppId: sowTypes.planAppIdType,
  exportConfig: sowTypes.planAppExportConfigType,
  userList: sowTypes.userLimitedListType,

  // connected props
  planApp: sowTypes.planAppType,
  planAppSummary: sowTypes.planAppSummaryType,
  qualifiedWorksheetIds: PropTypes.arrayOf(sowTypes.planAppWorksheetIdType),
  worksheetSections: PropTypes.arrayOf(sowTypes.planAppWorksheetSectionType),
  locationIdList: sowTypes.planAppLocationIdListType,
  showMainPlan: PropTypes.bool,
  showLocations: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppExportContentContainer);
