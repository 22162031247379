import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { defaultAca } from 'sow/utils/aca';
import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import Button from 'sow/components/atoms/Button';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';
import Form from 'sow/components/atoms/Form';
import AcaForm from 'sow/components/organisms/AcaForm';

class CreateAcaPage extends Component {
  state = {
    aca: defaultAca(),
  };

  handleChange = (field, value) => {
    this.setState(R.assocPath(['aca', field], value));
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state.aca);
  };

  render() {
    const { isCreating, createErrors } = this.props;
    const { aca } = this.state;

    return (
      <AdminPageTemplate title="Create ACA">
        {() => (
          <Widget>
            <WidgetHeading>Create New ACA</WidgetHeading>
            <Form onSubmit={this.handleSubmit}>
              <WidgetBody>
                <AcaForm aca={aca} onChange={this.handleChange} errors={createErrors} />
              </WidgetBody>
              <WidgetFooter>
                <Button type="submit" primary sm disabled={isCreating}>
                  Create
                </Button>
              </WidgetFooter>
            </Form>
          </Widget>
        )}
      </AdminPageTemplate>
    );
  }
}

CreateAcaPage.propTypes = {
  isCreating: PropTypes.bool,
  createErrors: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default CreateAcaPage;
