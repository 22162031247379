import React, { Fragment } from 'react';
import * as R from 'ramda';

import { formatPhoneNumber } from 'sow/utils/formatPhoneNumber';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import InputFormGroup from 'sow/components/molecules/InputFormGroup';
import Paragraph from 'sow/components/atoms/Paragraph';
import Strong from 'sow/components/atoms/Strong';
import FormGroup from 'sow/components/organisms/FormGroup';
import UpdateModal from 'sow/components/molecules/UpdateUserModal';
import Link from 'sow/components/atoms/Link';
import Button from 'sow/components/atoms/Button';
import Block from 'sow/components/atoms/Block';
import Clearfix from 'sow/components/atoms/Clearfix';
import Confirm from 'sow/components/molecules/Confirm';
import CheckboxButton from 'sow/components/molecules/CheckboxButton';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';

const ContactInfoEdit = ({
  isStaff,
  requestedUser,
  showModal,
  formValues,
  handleInputChange,
  toggleModal,
  onModalSave,
  generateOrgList,
  onCancel,
  onSave,
  errors,
  removeUser,
  editableContact,
  isSelf,
  manageableOrgsList,
  checkboxSelect,
  additionalOrgIds,
  sendPasswordReset,
  staffRemoveUser,
  removePrimaryContact,
}) => (
  <Widget className="contact-info-edit search-filter-block">
    <WidgetHeading>{editableContact ? 'Edit' : 'View'} Contact</WidgetHeading>
    <WidgetBody>
      {editableContact || isStaff ? (
        <Fragment>
          <InputFormGroup
            id="userName"
            fieldName="userName"
            label={<Strong>Name</Strong>}
            className={`edit-field ${R.has('userName')(errors) ? 'form-error' : ''}`}
            inputClassName="input-field"
            onChange={handleInputChange}
            value={formValues.userName}
            fieldErrors={errors}
            hideErrorLabel
            required
          />
          <InputFormGroup
            id="userEmail"
            fieldName="userEmail"
            label={<Strong>Email</Strong>}
            className={`edit-field ${R.has('userEmail')(errors) ? 'form-error' : ''}`}
            inputClassName="input-field"
            onChange={handleInputChange}
            value={formValues.userEmail}
            fieldErrors={errors}
            hideErrorLabel
            required
          />
          <InputFormGroup
            id="userNumber"
            fieldName="userNumber"
            label={<Strong>Phone Number</Strong>}
            className={`edit-field ${R.has('userNumber')(errors) ? 'form-error' : ''}`}
            inputClassName="input-field"
            onChange={handleInputChange}
            value={formValues.userNumber}
            fieldErrors={errors}
            hideErrorLabel
            required
          />
          <InputFormGroup
            id="userTitle"
            label={<Strong>Title</Strong>}
            className="edit-field"
            inputClassName="input-field"
            onChange={handleInputChange}
            value={formValues.userTitle}
          />
          <Paragraph>
            <Strong>Type</Strong>
          </Paragraph>
          <Paragraph>
            {requestedUser.userSubtype ? requestedUser.userSubtype : 'None'}
          </Paragraph>
          <FormGroup fieldName="password" label={<Strong>Password</Strong>} required>
            <UpdateModal
              show={showModal}
              onHide={toggleModal}
              onModalSave={onModalSave}
              type={['password', 'Password']}
            />
            <Block>
              <Paragraph className="inline-style">**********</Paragraph>
              <Link className="inline-style" onClick={toggleModal}>
                Change Password
              </Link>
            </Block>
          </FormGroup>
          <FormGroup label={<Strong>Send Password Reset Email</Strong>}>
            <Confirm
              title="Send Reset Password Email"
              message={`Send email to: ${requestedUser.email}`}
              onConfirm={sendPasswordReset}
            >
              {toggleConfirm => (
                <Block>
                  <Button
                    onClick={toggleConfirm}
                    className="no-margin-button remove-button reset-button"
                    primary
                  >
                    Send Email
                  </Button>
                </Block>
              )}
            </Confirm>
          </FormGroup>
        </Fragment>
      ) : (
        <Fragment>
          <FormGroup fieldName="userName" label={<Strong>Name</Strong>}>
            <Block>{formValues.userName}</Block>
          </FormGroup>
          <FormGroup fieldName="userEmail" label={<Strong>Email</Strong>}>
            <Block>{formValues.userEmail}</Block>
          </FormGroup>
          <FormGroup fieldName="userNumber" label={<Strong>Phone Number</Strong>}>
            <Block>{formatPhoneNumber(formValues.userNumber || '')}</Block>
          </FormGroup>
          <FormGroup fieldName="userTitle" label={<Strong>Title</Strong>}>
            <Block>{formValues.userTitle || 'N/A'}</Block>
          </FormGroup>
        </Fragment>
      )}
      {!isSelf && (
        <FormGroup label={<Strong>Remove User From Organization</Strong>}>
          <Confirm
            message={
              <Paragraph>
                Do you wish to remove the primary contact for this organization?
              </Paragraph>
            }
            onConfirm={removePrimaryContact}
          >
            {togglePrimaryConfirm => (
              <Confirm
                message={
                  <Block className="remove-modal">
                    <Paragraph>
                      Are you sure you want remove this user from the organization?
                    </Paragraph>
                    {manageableOrgsList.length > 0 && (
                      <Fragment>
                        <Paragraph>
                          Select other organizations you would like to remove user from:
                        </Paragraph>
                        <Block className="removeable-org-list scrollable-window">
                          {manageableOrgsList.map(org => (
                            <CheckboxButton
                              key={org.org_id}
                              label={org.org_name}
                              name={String(org.org_id)}
                              setFieldValue={checkboxSelect}
                              value={additionalOrgIds[org.org_id]}
                            />
                          ))}
                        </Block>
                      </Fragment>
                    )}
                  </Block>
                }
                onConfirm={
                  isStaff
                    ? () => staffRemoveUser(togglePrimaryConfirm)
                    : () => removeUser()
                }
              >
                {toggleConfirm => (
                  <Block>
                    <Button
                      className="no-margin-button remove-button"
                      onClick={() => generateOrgList(toggleConfirm)}
                      danger
                    >
                      Remove User
                    </Button>
                  </Block>
                )}
              </Confirm>
            )}
          </Confirm>
        </FormGroup>
      )}
      <Row className="search-filter-row">
        <Column
          className="search-filter-column button-column"
          xs={0}
          sm={editableContact || isStaff ? 0 : 9}
          md={editableContact || isStaff ? 8 : 9}
          lg={editableContact || isStaff ? 9 : 10}
        ></Column>
        <Column
          className="search-filter-column button-column"
          xs={12}
          sm={editableContact || isStaff ? 12 : 3}
          md={editableContact || isStaff ? 4 : 3}
          lg={editableContact || isStaff ? 3 : 2}
        >
          <Row className="search-filter-row">
            <Column
              className="search-filter-column button-column"
              xs={editableContact || isStaff ? 6 : 12}
            >
              <Button
                className="filter-command-button reset-button"
                onClick={onCancel}
                primary
              >
                Cancel
              </Button>
            </Column>
            {(editableContact || isStaff) && (
              <Column className="search-filter-column button-column" xs={6}>
                <Button
                  type="submit"
                  className="filter-command-button search-button"
                  onClick={onSave}
                  disabled={!R.isEmpty(errors)}
                  primary
                >
                  Save
                </Button>
              </Column>
            )}
          </Row>
        </Column>
      </Row>
      <Clearfix />
    </WidgetBody>
  </Widget>
);

export default ContactInfoEdit;
