import { createActions } from 'redux-actions';

const actionCreators = createActions({
  CORE: {
    REPORT: {
      ERROR: options => options,
      FAILURE: options => options,
    },
    STATE: {
      RESET: () => undefined,
    },
  },
});

export default actionCreators.core;
