import * as R from 'ramda';
import {
  // createAttachmentAsync,
  deleteAttachmentAsync,
  fetchAttachmentListAsync,
  fetchAttachmentOspListAsync,
  updateAttachmentAsync,
} from 'sow/services/attachment';

var appContext;

$.fn.dataTable.Api.register('restorePage()', function(stateObject) {
  var page = stateObject.start / stateObject.length;
  if (this.page() != page) {
    this.page(page).draw('page');
  }
  return this;
});

const mark = action => `soworganic/attachment/${action}`;

export const FETCH_ATTACHMENT_LIST = mark('FETCH_ATTACHMENT_LIST');
export const FETCH_ATTACHMENT_LIST_SUCCESS = mark('FETCH_ATTACHMENT_LIST_SUCCESS');
export const FETCH_ATTACHMENT_LIST_FAILED = mark('FETCH_ATTACHMENT_LIST_FAILED');

export const fetchAttachmentList = orgId => dispatch => {
  if ($.fn.DataTable.isDataTable('.attachmentTable')) {
    var table = $('.dataTable')
      .first()
      .DataTable();
    if (table != null) {
      var appContext = { tableState: $.extend({}, table.state()) };
    }
  }
  dispatch({ type: FETCH_ATTACHMENT_LIST });

  fetchAttachmentListAsync(orgId)
    .done(res => {
      waitForEl('.attachmentTable', 1, function() {
        if (
          $('.attachmentTable tr').length > 2 &&
          !$.fn.DataTable.isDataTable('.attachmentTable')
        ) {
          $('.attachmentTable').DataTable({
            pageLength: 50,
            destroy: true,
            stateSave: true,
            columnDefs: [
              { sortable: false, targets: 0 },
              { type: 'date', targets: 2 },
            ],
          });
          var table = $('.dataTable')
            .first()
            .DataTable();
          if (appContext != null && table != null) {
            if (appContext.tableState.length > 0) {
              table.restorePage(appContext.tableState);
              table.order(appContext.tableState.order).draw();
              table.page.len(appContext.tableState.length).draw();
              table.search(appContext.tableState.search.search).draw();
              table
                .page(appContext.tableState.start / appContext.tableState.length)
                .draw('page');
            }
          }
        }
      });
      dispatch({
        type: FETCH_ATTACHMENT_LIST_SUCCESS,
        data: res.data,
      });
    })
    .fail(err => {
      console.error('Error fetching attachment list:', err.responseJSON);
      dispatch({ type: FETCH_ATTACHMENT_LIST_FAILED });
    });
};

export const fetchAttachmentOspList = (orgId, ospAppId) => dispatch => {
  dispatch({ type: FETCH_ATTACHMENT_LIST });

  fetchAttachmentOspListAsync(orgId, ospAppId)
    .done(res => {
      const files = res.data;
      const fauxAttachments = R.map(
        file => ({
          category: null,
          id: file.id,
          name: file.name,
          file: {
            ...file,
            public: true,
          },
        }),
        files,
      );
      waitForEl('.dataTable', 1, function() {
        if ($('.dataTable tr').length > 2 && !$.fn.DataTable.isDataTable('.dataTable')) {
          $('.dataTable').DataTable({
            pageLength: 50,
            destroy: true,
            stateSave: true,
            columnDefs: [
              { sortable: false, targets: 0 },
              { type: 'date', targets: 2 },
            ],
          });
        }
      });
      dispatch({
        type: FETCH_ATTACHMENT_LIST_SUCCESS,
        data: fauxAttachments, // files,
      });
    })
    .fail(err => {
      console.error('Error fetching Plan attachment list:', err.responseJSON);
      dispatch({ type: FETCH_ATTACHMENT_LIST_FAILED });
    });
};

export const UPDATE_ATTACHMENT = mark('UPDATE_ATTACHMENT');
export const UPDATE_ATTACHMENT_SUCCESS = mark('UPDATE_ATTACHMENT_SUCCESS');
export const UPDATE_ATTACHMENT_FAILED = mark('UPDATE_ATTACHMENT_FAILED');

export const updateAttachment = (orgId, attachment, success) => dispatch => {
  dispatch({ type: UPDATE_ATTACHMENT });

  updateAttachmentAsync(orgId, attachment)
    .done(res => {
      var table = $('.dataTable')
        .first()
        .DataTable();
      appContext = { tableState: $.extend({}, table.state()) };
      table.destroy();
      dispatch({
        type: UPDATE_ATTACHMENT_SUCCESS,
        data: res.data,

        // TODO remove this when Oliver fixes backend
        meta: {
          orgId,
          attachment,
        },
      });
      waitForEl('.attachmentTable', 1, function() {
        if (
          $('.attachmentTable tr').length > 2 &&
          !$.fn.DataTable.isDataTable('.attachmentTable')
        ) {
          $('.attachmentTable').DataTable({
            pageLength: 50,
            destroy: true,
            stateSave: true,
          });
        }
      });
      table = $('.dataTable')
        .first()
        .DataTable();
      table.restorePage(appContext.tableState);
      if (success) success();
    })
    .fail(err => {
      dispatch({
        type: UPDATE_ATTACHMENT_FAILED,
        data: err.responseJSON,
      });
    });
};

export const DELETE_ATTACHMENT = mark('DELETE_ATTACHMENT');
export const DELETE_ATTACHMENT_SUCCESS = mark('DELETE_ATTACHMENT_SUCCESS');
export const DELETE_ATTACHMENT_FAILED = mark('DELETE_ATTACHMENT_FAILED');

export const deleteAttachment = (orgId, attachmentId, success) => dispatch => {
  dispatch({ type: DELETE_ATTACHMENT });

  deleteAttachmentAsync(orgId, attachmentId)
    .done(() => {
      dispatch({
        type: DELETE_ATTACHMENT_SUCCESS,
        data: { attachmentId },
      });

      if (success) success();
    })
    .fail(err => {
      dispatch({
        type: DELETE_ATTACHMENT_FAILED,
        data: err.responseJSON,
      });
    });
};

export const CLEAR_ATTACHMENT_LIST = mark('CLEAR_ATTACHMENT_LIST');

export const clearAttachmentList = () => dispatch => {
  dispatch({ type: CLEAR_ATTACHMENT_LIST });
};
