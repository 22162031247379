import * as R from 'ramda';
import { createActions } from 'redux-actions';

const actionCreators = createActions({
  PRODUCTS: {
    FETCH_FILTER_OPTIONS: R.identity,
    FETCH_FILTER_OPTIONS_DONE: results => results,

    PRODUCT_SEARCH: (params, onSuccess) => ({
      params,
      onSuccess,
    }),
    PRODUCTS_SEARCH_DONE: results => results,
    CLEAR_SEARCH: R.identity,
  },
});

export default actionCreators.products;
