import React, { Component } from 'react';
import * as R from 'ramda';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FileUpload from 'sow/components/old/fileUpload/FileUpload';
import { API_URL } from 'sow/constants/api';
import { removeAllFromFileQueue } from 'sow/actions/fileUpload';

const mapDispatchToProps = {
  removeAllFromFileQueue,
};

class FileUploadModal extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    files: PropTypes.array,
    handleChange: PropTypes.func,
    onUploadFinish: PropTypes.func,
    onUploadStart: PropTypes.func,
    orgId: PropTypes.number,
    questionId: PropTypes.string,
    removeAllFromFileQueue: PropTypes.func,
  };

  state = {
    show: false,
  };

  modalShow = () => this.setState({ show: true });
  modalHide = () => this.setState({ show: false });

  handleModalClose = () => {
    this.modalHide();
  };

  handleModalOpen = () => {
    this.props.removeAllFromFileQueue();
    this.modalShow();
  };

  handleUploadFinish = () => {
    if (typeof this.props.onUploadFinish === 'function') {
      this.props.onUploadFinish();
    }
  };

  handleUploadStart = () => {
    if (typeof this.props.onUploadStart === 'function') {
      this.props.onUploadStart();
    }
  };

  handleUploadSuccess = file => {
    const { questionId, files, handleChange } = this.props;
    const { id, name } = file.data;
    const newFilesArray = R.append({ id, name }, files);

    this.handleUploadFinish();
    handleChange(questionId, newFilesArray);
  };

  render() {
    const { show } = this.state;
    const { orgId, disabled } = this.props;

    // todo - replace this with the actual URL to upload the files to for the particular question
    const uploadUrl = `${API_URL}/v1/org/${orgId}/files`;

    return (
      <div>
        <button
          disabled={disabled}
          type="button"
          className="btn btn-primary btn-sm"
          onClick={this.handleModalOpen}
        >
          Upload File(s)
        </button>

        <Modal backdrop="static" bsSize="lg" show={show} onHide={this.handleModalClose}>
          <Modal.Header closeButton>Upload File(s)</Modal.Header>
          <Modal.Body>
            <FileUpload
              uploadUrl={uploadUrl}
              onUploadSuccess={this.handleUploadSuccess}
              onUploadStart={this.handleUploadStart}
              autoUpload
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(FileUploadModal);
