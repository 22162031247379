import React, { Component, Fragment } from 'react';
import * as R from 'ramda';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import * as sowTypes from 'sow/types';
import { validateLocationChange, validateLocation } from 'sow/utils/planAppLocation';
import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import PlanAppLocationFormFields from 'sow/components/organisms/PlanAppLocationFormFields';
import PlanAppChange from 'sow/containers/planApp/Change';
import PlanAppLocationEditCreateChangeButton from 'sow/containers/planApp/LocationEditCreateChangeButton';

class PlanAppLocationEditChangeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: R.pathOr(false, ['defaultOpen'], props),
      isSaving: false,
    };
  }

  setModalVisibility = show => this.setState({ show });
  showModal = () => this.setModalVisibility(true);
  hideModal = () => {
    const { onHide } = this.props;

    this.setModalVisibility(false);
    if (onHide) onHide();
  };

  componentDidUpdate() {
    if (!this.state.show && this.props.defaultOpen && !this.state.isSaving) {
      this.showModal();
    }
  }

  handleSubmit = (values, formikBag) => {
    this.setState({ isSaving: true }, () => {
      this.hideModal();
      this.props.onSubmit(values).finally(() => {
        formikBag.setSubmitting(false);
        this.setState({ isSaving: false });
      });
    });
  };

  render() {
    const { show } = this.state;
    const {
      changeRequestId,
      children,
      planAppLocation,
      locationDetails,
      planAppLocationChange,
      isStateInitialApplication,
      isPlanAppOpen,
      isEditAllowed,
    } = this.props;

    const showCreateChangeButton = R.all(R.equals(true), [
      isEditAllowed,
      isPlanAppOpen,
      !R.isNil(changeRequestId),
      R.isNil(planAppLocationChange),
    ]);

    let oldValues = R.pipe(
      R.pathOr(null, ['old']),
      R.defaultTo(locationDetails),
      R.defaultTo(null),
    )(planAppLocationChange);

    const newValues = R.pipe(
      R.pathOr(null, ['new']),
      R.defaultTo(locationDetails),
      R.defaultTo(null),
    )(planAppLocationChange);

    const initialValues = {
      location: oldValues,
      locationChange: newValues,
    };

    const changeType = R.pathOr(null, ['action'], planAppLocationChange);
    const changeId = R.propOr(null, ['id'], planAppLocationChange);
    const changeExists = R.not(R.isNil(changeType)) && R.not(R.isNil(changeId));
    const validateFunction = changeExists ? validateLocationChange : validateLocation;

    return (
      <Fragment>
        {children(this.showModal)}

        <Modal backdrop="static" show={show} onHide={this.hideModal} bsSize="lg">
          <Modal.Header closeButton>
            <Modal.Title>Edit Location Details</Modal.Title>
          </Modal.Header>

          <Formik
            initialValues={initialValues}
            onSubmit={this.handleSubmit}
            validate={validateFunction}
            // Validating on change creates unnecessary noise since there is
            // conditional validation on the address/geom fields. Use onBlur instead.
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize={true}
          >
            {({ values, isSubmitting }) => {
              return (
                <Form>
                  <Modal.Body>
                    <PlanAppChange
                      changeId={R.propOr(null, 'id', planAppLocationChange)}
                      renderAnswer={() => {
                        // Don't render old form if location was just created via change
                        if (changeType === 'added') return '---';
                        return (
                          <PlanAppLocationFormFields
                            planAppLocation={values.location}
                            namePrefix="location."
                            disabled={changeExists}
                          />
                        );
                      }}
                      renderChange={() => {
                        // Don't render new form if location is being deleted
                        if (changeType === 'deleted') return 'Location deleted';
                        else {
                          if (values.locationChange == null) {
                            window.location.reload();
                            return (
                              <PlanAppLocationFormFields
                                planAppLocation={values.location}
                                namePrefix="location."
                                disabled={!isPlanAppOpen || !isEditAllowed}
                              />
                            );
                          }
                          return (
                            <PlanAppLocationFormFields
                              planAppLocation={values.locationChange}
                              namePrefix="locationChange."
                              disabled={!isPlanAppOpen || !isEditAllowed}
                            />
                          );
                        }
                      }}
                    />
                  </Modal.Body>

                  <Modal.Footer>
                    <Button default onClick={this.hideModal}>
                      Cancel
                    </Button>
                    <Button type="submit" primary disabled={isSubmitting}>
                      Save
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </Fragment>
    );
  }
}

PlanAppLocationEditChangeModal.propTypes = {
  children: PropTypes.func.isRequired,
  changeRequestId: sowTypes.planAppChangeRequestIdType,
  onSubmit: PropTypes.func.isRequired,
  planAppLocation: sowTypes.planAppLocationType,
  planAppLocationChange: sowTypes.planAppChangeType,
  isStateInitialApplication: PropTypes.bool.isRequired,
  isPlanAppOpen: PropTypes.bool.isRequired,
  isEditAllowed: PropTypes.bool.isRequired,
  defaultOpen: PropTypes.bool,
  onHide: PropTypes.func,
};

PlanAppLocationEditChangeModal.defaultProps = {
  planAppLocation: null,
  planAppLocationChange: null,
  changeRequestId: null,
  defaultOpen: false,
  onHide: null,
};

export default PlanAppLocationEditChangeModal;
