import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as itemVersionSectionActions from 'sow/actions/pure/itemVersionSection';

import ItemVersionCreatorSection from 'sow/components/organisms/ItemVersionCreatorSection';

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = {
  updateItemVersionSection: itemVersionSectionActions.updateItemVersionSection,
  createItemVersionSection: itemVersionSectionActions.addItemVersionSection,
};

class ItemVersionCreatorSectionContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      modalParams: { isNew: true, show: false, initialValues: {} },
    };
  }

  showPopover({ currentTarget }) {
    this.setState({ anchorEl: currentTarget });
  }

  hidePopover() {
    this.setState({ anchorEl: null });
  }

  showModal() {
    const { modalParams } = this.state;
    this.setState({ modalParams: { ...modalParams, show: true }, anchorEl: null });
  }

  closeModal() {
    this.setState({ modalParams: { isNew: true, show: false, initialValues: {} } });
  }

  editSection(sectionId) {
    const section = R.find(R.propEq('id', sectionId))(this.props.itemVersionSectionList);

    this.setState({
      modalParams: { show: true, isNew: false, initialValues: { ...section } },
    });
  }

  cloneSection(sectionId) {
    const { itemVersionSectionList, createItemVersionSection } = this.props;
    const section = R.find(R.propEq('id', sectionId))(itemVersionSectionList);
    const { sort_order, created_on, ...newSection } = section;
    newSection.name = `CLONED: ${section.name}`;
    createItemVersionSection(newSection, this.refreshList.bind(this));
  }

  handleSave(values) {
    if (R.has('id', values)) {
      const { updateItemVersionSection } = this.props;
      updateItemVersionSection(values.id, values, this.refreshList.bind(this));
    } else {
      const { itemVersionId, createItemVersionSection } = this.props;
      values.item_version_id = itemVersionId;
      createItemVersionSection(values, this.refreshList.bind(this));
    }
  }

  refreshList() {
    const { loadItemVersionSections } = this.props;
    loadItemVersionSections();
    this.setState({ modalParams: { isNew: true, show: false, initialValues: {} } });
  }

  render() {
    return (
      <ItemVersionCreatorSection
        itemVersionSectionList={this.props.itemVersionSectionList}
        loadItemVersionSections={this.props.loadItemVersionSections}
        updateListOrder={this.props.updateListOrder}
        onSectionSelect={this.props.onSectionSelect}
        selectedSectionId={this.props.selectedSectionId}
        editSection={this.editSection.bind(this)}
        cloneSection={this.cloneSection.bind(this)}
        showPopover={this.showPopover.bind(this)}
        popoverParams={{
          ...this.state,
          hidePopover: this.hidePopover.bind(this),
          showModal: this.showModal.bind(this),
        }}
        modalParams={{
          ...this.state.modalParams,
          ...this.props.formOptions,
          closeModal: this.closeModal.bind(this),
          handleSave: this.handleSave.bind(this),
        }}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ItemVersionCreatorSectionContainer);
