import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { monthDayYearDateFormat } from 'sow/utils/dateTime';
import { PLAN_APP_STATES_STATE_EDITABLE } from 'sow/constants/planApp';

import { updateOspApplicationAdditionalInformation } from 'sow/actions/ospApplication';
import { orgRoute } from 'sow/routes';
import { planAppStateText } from 'sow/constants/planApp';
import { API_URL } from 'sow/constants/api';
import Link from 'sow/components/atoms/Link';
import DataTable from 'sow/components/molecules/DataTable';
import Block from 'sow/components/atoms/Block';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Button from 'sow/components/atoms/Button';
import PriorityFlag from 'sow/components/molecules/PriorityFlag';
import Spinner from 'sow/components/atoms/Spinner';
import Input from 'sow/components/atoms/Input';
import Image from 'sow/components/atoms/Image';
import PriorityPopover from 'sow/components/organisms/CertifiedSpecialistList/PriorityPopover';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateOspApplicationAdditionalInformation,
};

const appNotSubmitted = ospApp =>
  !R.contains(R.__, PLAN_APP_STATES_STATE_EDITABLE)(ospApp.app_state);

const highlightAnniversaryDate = ospApp => {
  if (appNotSubmitted(ospApp)) {
    const daysToAnniversary = moment(ospApp.organization_anniversary_date).diff(
      moment().startOf('day'),
      'days',
    );
    if (daysToAnniversary < 0) {
      return 'overdue-date';
    } else if (daysToAnniversary <= 15) {
      return 'highlight-date';
    }
  }
  return '';
};

const SpecialistTable = ({ children }) => {
  const TableRow = styled.tr`
    background: #dff0d8;
    color: #3c763d;
    font-weight: bold;
  `;

  return (
    <DataTable
      selector={'.specialistTable'}
      tableDef={{
        pageLength: 50,
        destroy: true,
        clear: true,
        stateSave: true,
        aaSorting: [],
        columnDefs: [
          { targets: 0, sortable: false, searchable: false },
          { targets: 2, type: 'html' },
          { targets: 3, type: 'date' },
          { targets: 8, sortable: false, searchable: false },
        ],
      }}
    >
      <Table
        className="specialistTable"
        bordered
        striped
        condensed
        head={
          <TableRow>
            <TableCell heading textCenter valignMiddle>
              Actions
            </TableCell>
            <TableCell heading textCenter valignMiddle>
              CS Priority
            </TableCell>
            <TableCell heading textCenter valignMiddle>
              Client
            </TableCell>
            <TableCell heading textCenter valignMiddle>
              Anniversary Date
            </TableCell>
            <TableCell heading textCenter valignMiddle>
              Primary Contact
            </TableCell>
            <TableCell heading textCenter valignMiddle>
              Status
            </TableCell>
            <TableCell heading textCenter valignMiddle>
              Status Age
            </TableCell>
            <TableCell heading textCenter valignMiddle>
              <Row>
                <Column xs={12} style={{ width: '250px' }}>
                  Changes
                </Column>
              </Row>
              <Row>
                <Column xs={4}>Priority</Column>
                <Column xs={4}>To-do</Column>
                <Column xs={4}>Pending</Column>
              </Row>
            </TableCell>
            <TableCell
              heading
              textCenter
              valignMiddle
              style={{ width: '75px', maxWidth: '75px' }}
            >
              Notes
            </TableCell>
            <TableCell heading textCenter valignMiddle>
              Certifications
            </TableCell>
          </TableRow>
        }
      >
        {children}
      </Table>
    </DataTable>
  );
};

SpecialistTable.propTypes = {
  children: PropTypes.node,
};

const AcaBubble = styled.div`
  width: 50px;
  border-radius: 5px;
  margin: 5px;
  padding: 3px 0;
  color: white;
  background-color: ${props =>
    props.blue ? '#4478b6' : props.red ? '#cf2b21' : '#efad4e'};
`;

const PriorityBubble = styled(Block)`
  border-radius: 5px;
  padding: 3px 0;
  color: white;
  text-align: center;
  background-color: ${props => props.color};
`;

const CertImage = styled(Image)`
  height: 25px;
  margin: 3px;
`;

const statusStyle = appState =>
  appState === 'noncompliance' ? { color: 'red', clear: 'right' } : { clear: 'right' };

const SpecialistRow = ({ ospApp, showModal, priorityTypes }) => (
  <TableRow>
    <TableCell textCenter className="action-column" style={{ width: '70px' }}>
      <PriorityPopover priorityTypes={priorityTypes} ospApp={ospApp} />
    </TableCell>
    <TableCell
      data-order={ospApp.priority_order ? ospApp.priority_order : 100}
      style={{ width: '50px' }}
    >
      <PriorityBubble color={ospApp.priority_color}>
        {ospApp.priority_name}
      </PriorityBubble>
    </TableCell>
    <TableCell style={{ width: '200px' }}>
      <PriorityFlag flags={{ hard_copy_account: ospApp.hard_copy_account }} />
      <Link to={orgRoute(ospApp.organization_id)}>{ospApp.organization_name}</Link>
    </TableCell>
    <TableCell className={highlightAnniversaryDate(ospApp)}>
      {ospApp.organization_anniversary_date &&
        monthDayYearDateFormat(ospApp.organization_anniversary_date)}
    </TableCell>
    <TableCell>{ospApp.primary_contact}</TableCell>
    <TableCell style={{ width: '125px' }}>
      <PriorityFlag flags={ospApp.application_priority_flags} />
      <div style={statusStyle(ospApp.app_state)}>
        {planAppStateText[ospApp.app_state]}
      </div>
    </TableCell>
    <TableCell data-order={ospApp.status_age}>
      {ospApp.status_age}
      {R.isNil(ospApp.status_age)
        ? ''
        : ospApp.status_age === 1
        ? ' day old'
        : ' days old'}
    </TableCell>
    <TableCell
      data-order={
        ospApp.application_stats.priority_changes_count * -1000000 -
        ospApp.application_stats.num_requests_by_internal * 1000 -
        ospApp.application_stats.pending_changes_count
      }
      textCenter
      valignMiddle
      style={{ width: '130px' }}
    >
      <Row>
        <Column xs={4}>
          <AcaBubble red>{ospApp.application_stats.priority_changes_count}</AcaBubble>
        </Column>
        <Column xs={4}>
          <AcaBubble blue>{ospApp.application_stats.num_requests_by_internal}</AcaBubble>
        </Column>
        <Column xs={4}>
          <AcaBubble>{ospApp.application_stats.pending_changes_count}</AcaBubble>
        </Column>
      </Row>
    </TableCell>
    <TableCell textCenter valignMiddle>
      <Button primary sm success onClick={() => showModal(ospApp)}>
        <FontAwesome
          icon="file-alt"
          style={{
            fontSize: '18px',
            opacity: R.isNil(ospApp.application_note) ? '0.5' : '1',
          }}
        />
      </Button>
    </TableCell>
    <TableCell valignMiddle>
      {ospApp.certifications &&
        ospApp.certifications.map((cert, index) => (
          <Fragment key={index}>
            <div style={{ display: 'none' }}>{cert.name}</div>
            <CertImage
              imageUrl={`${API_URL}/v1/file/by_path/${cert.s3_name}`}
              title={`${cert.name}`}
            />
          </Fragment>
        ))}
    </TableCell>
  </TableRow>
);

SpecialistRow.propTypes = {
  ospApp: PropTypes.object,
  showModal: PropTypes.func,
};

const NoItems = () => (
  <TableRow>
    <TableCell colSpan={10} textCenter textSuccess>
      No results
    </TableCell>
  </TableRow>
);

class CertifiedSpecialistList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      orgName: '',
      orgId: '',
      text: '',
    };
  }

  showModal = ospApp => {
    this.setState({
      showModal: true,
      orgName: ospApp.organization_name,
      orgId: ospApp.organization_id,
      appId: ospApp.app_id,
      text: ospApp.application_note,
    });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  handleChange = e => {
    this.setState({ text: e.target.value });
  };

  handleSave = e => {
    const { updateOspApplicationAdditionalInformation } = this.props;
    const { orgId, appId, text } = this.state;
    const apiData = {
      osp_application: {
        note: text,
      },
    };

    updateOspApplicationAdditionalInformation(orgId, appId, apiData);
    this.hideModal();
  };

  handleCancel = e => {
    this.setState({ text: '' });
    this.hideModal();
  };

  render() {
    const { dashboardList, isFetchingDashboard, priorityTypes } = this.props;
    const { showModal, orgName, text } = this.state;

    return (
      <div>
        {isFetchingDashboard ? (
          <Spinner />
        ) : (
          <SpecialistTable>
            {dashboardList.length > 0 ? (
              dashboardList.map((ospApp, index) => (
                <SpecialistRow
                  key={index}
                  ospApp={ospApp}
                  showModal={this.showModal}
                  priorityTypes={priorityTypes}
                />
              ))
            ) : (
              <NoItems />
            )}
          </SpecialistTable>
        )}
        <Modal backdrop="static" show={showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>Notes: {orgName}</Modal.Header>

          <Modal.Body>
            <Input
              value={text}
              type="textarea"
              onChange={this.handleChange}
              rows={10}
              cols={40}
            />
          </Modal.Body>

          <Modal.Footer>
            <button type="button" className="btn btn-primary" onClick={this.handleCancel}>
              Cancel
            </button>
            <button type="button" className="btn btn-primary" onClick={this.handleSave}>
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

CertifiedSpecialistList.propTypes = {
  dashboardList: PropTypes.array,
  orgId: PropTypes.number,
  updateOspApplicationAdditionalInformation: PropTypes.func,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CertifiedSpecialistList);
