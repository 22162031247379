import * as R from 'ramda';
import { combineReducers } from 'redux';
import { makeImmutable } from 'sow/utils/data';
import byOspAppId from './byOspAppId';

export default combineReducers({
  byOspAppId,
});

const changesForWorksheetPath = (ospAppId, worksheetId) =>
  R.path(['changes', 'byOspAppId', ospAppId, 'byWorksheetId', worksheetId]);

const formatMatrixRow = R.map(
  R.applySpec({
    values: R.prop('valuesByQuestionId'),
    isDeleted: R.prop('isDeleted'),
  }),
);

const formatMatrixRowsById = R.map(formatMatrixRow);

const changesToWorksheetAnswers = R.applySpec({
  isNotApplicable: R.propOr(undefined, 'isNotApplicable'),
  readyForReview: R.propOr(undefined, 'readyForReview'),
  answers: {
    values: R.propOr(undefined, 'valuesByQuestionId'),
    matrixRows: formatMatrixRowsById(R.propOr({}, ['matrixRowsById'])),
  },
});

export const getRawChangesForWorksheet = (state, ospAppId, worksheetId) =>
  R.compose(changesForWorksheetPath(ospAppId, worksheetId))(state);

export const getChangesForWorksheet = (state, ospAppId, worksheetId) =>
  R.compose(changesToWorksheetAnswers, changesForWorksheetPath(ospAppId, worksheetId))(
    state,
  );
