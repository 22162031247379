import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchDashboardList, fetchDashboardCounts } from 'sow/actions/orgWorkflow';
import { fromPlanApp } from 'sow/store/selectors';
import ClientDashboardPage from 'sow/components/pages/ClientDashboardPage';
import { setFilter, getFilter } from 'sow/utils/dashboardFilterStorage';

const mapStateToProps = (state, props) => {
  return {
    isDashboardFetching: state.dashboard.isFetching,
    acaId: state.auth.metadata.orgs_v2[0].aca_id,
    planId: fromPlanApp.planAppSummaryIdMostRecent(state, props),
    orgList: state.auth.metadata.orgs,
    orgCounts: state.dashboard.counts,
    planList: state.dashboard.items,
  };
};

const mapDispatchToProps = {
  fetchDashboardList,
  fetchDashboardCounts,
};

class ClientDashboardPageContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.loadDashboard();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.loadDashboard();
    }
  }

  loadDashboard() {
    this.props.fetchDashboardList(this.props.acaId, getFilter());
    this.props.fetchDashboardCounts(this.props.acaId);
  }

  handleFilterChange(filter) {
    setFilter(filter);
    this.loadDashboard();
  }

  render() {
    return (
      <ClientDashboardPage
        ospApp={this.props.planList}
        filter={getFilter()}
        handleFilterChange={this.handleFilterChange.bind(this)}
        {...this.props}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ClientDashboardPageContainer);
