export const bgColorDanger = '#f2dede';
export const bgColorInfo = '#d9edf7';

export const boxShadow = {
  sm: { boxShadow: '0 4px 8px 0 rgba(0,0,0,0.10)' },
  md: { boxShadow: '0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08)' },
  lg: { boxShadow: '0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08)' },
};

export const changeRequestIndicatorColors = {
  open: 'orange',
  accepted: 'rgb(76, 174, 76)',
  rejected: '#a94442',
  requested: '#5bc0de',
  notify: '#5bc0de',
};
