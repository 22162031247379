import React from 'react';

import InfoTooltip from 'sow/components/organisms/InfoTooltip';

const BlockedText = () => (
  <InfoTooltip overlay="This change is currently blocked by another change. It can be updated, but will not be applied until the other change is resolved.">
    (blocked)
  </InfoTooltip>
);

export default BlockedText;
