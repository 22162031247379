import { apiGet, apiPut } from 'sow/utils/apiNew';
import { apiUrl } from 'sow/config';

export const baseUrl = `${apiUrl}/org`;
export const orgUrl = orgId => `${baseUrl}/${orgId}`;

export const loadAll = () => {
  const url = baseUrl;
  return apiGet(url);
};

export const loadOne = orgId => {
  const url = orgUrl(orgId);
  return apiGet(url);
};

export const updateOne = organization => {
  const url = orgUrl(organization.id);
  return apiPut(url, { organization });
};
