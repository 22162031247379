import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';

import { systemAdminRoute, inspectionYearEditRoute } from 'sow/routes';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormikField from 'sow/components/organisms/FormikField';
import FormikReactSelect from 'sow/components/molecules/FormikReactSelect';
import Button from 'sow/components/atoms/Button';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Spinner from 'sow/components/atoms/Spinner';
import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';

const InspectionYearList = ({
  loadingList,
  initialValues,
  inspectionYearList,
  selectOptions,
  handleSearch,
  resetSearch,
}) => (
  <Widget className="system-admin-list-edit">
    <WidgetHeading>Inspection Year</WidgetHeading>
    <WidgetBody>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSearch}
        onReset={resetSearch}
      >
        {({ values, handleSubmit, handleReset, errors }) => (
          <Form onSubmit={handleSubmit}>
            <Row className="form-row">
              <Column className="form-column" xs={12} md={8}>
                <Field
                  className="input-filter"
                  label="Year"
                  placeholder=""
                  name={'year'}
                  component={FormikField}
                  type={FormikReactSelect}
                  options={selectOptions}
                />
              </Column>
              <Column className="form-column" xs={12} sm={6} md={4}>
                <Row className="button-row search-button-row">
                  <Column className="button-column" xs={6} sm={6} md={6}>
                    <Button className="form-button search-button" type="submit">
                      Search
                    </Button>
                  </Column>
                  <Column className="button-column" xs={6} sm={6} md={6}>
                    <Button
                      className="form-button reset-return-button"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                  </Column>
                </Row>
              </Column>
            </Row>
          </Form>
        )}
      </Formik>
      <HorizontalRule className="divider" />
      <Row className="form-row">
        <Column className="form-column" xs={12} sm={6} md={4}>
          <Row className="button-row">
            <Column className="button-column" xs={6} sm={6} md={6}>
              <Button className="form-button" to={inspectionYearEditRoute('new')} primary>
                <FontAwesome className="button-icon" icon="plus" />
                Add
              </Button>
            </Column>
            <Column className="button-column" xs={6} sm={6} md={6}>
              <Button className="form-button reset-return-button" to={systemAdminRoute()}>
                Back
              </Button>
            </Column>
          </Row>
        </Column>
      </Row>
      {loadingList ? (
        <Spinner />
      ) : (
        <Row className="form-row list-row">
          <Column className="form-column" xs={12} sm={12} md={12}>
            <Block className="static-list">
              {inspectionYearList.length > 0 ? (
                <Fragment>
                  {inspectionYearList.map(inspectionYear => (
                    <Block key={inspectionYear.id} className="static-list-item">
                      <Block className="standard-item">
                        <Block className="list-item-name">{inspectionYear.year}</Block>
                        <FontAwesome className="list-item-icon" icon="pencil" />
                        <Link
                          className="start-end-width"
                          to={inspectionYearEditRoute(inspectionYear.id)}
                        >
                          Edit
                        </Link>
                      </Block>
                    </Block>
                  ))}
                </Fragment>
              ) : (
                <Block className="empty-list">No inspection years found.</Block>
              )}
            </Block>
          </Column>
        </Row>
      )}
    </WidgetBody>
  </Widget>
);

export default InspectionYearList;
