import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import ConsultantCompaniesEditContainer from 'sow/containers/ConsultantCompaniesEditContainer';

const ConsultantCompaniesEditPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <ConsultantCompaniesEditContainer />}
  </AdminPageTemplate>
);

export default ConsultantCompaniesEditPage;
