import {
  FETCH_ACA_REPORT,
  FETCH_ACA_REPORT_FAILED,
  FETCH_ACA_REPORT_SUCCESS,
  RESET_FETCH_ACA_REPORT,
} from 'sow/actions/aca';

const initialState = {
  items: [],
  isFetching: false,
};

export default function acaAdminAvailableUsersList(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACA_REPORT:
      return { ...initialState, isFetching: true };

    case FETCH_ACA_REPORT_SUCCESS:
      return { ...state, isFetching: false, items: [...action.data] };

    case FETCH_ACA_REPORT_FAILED:
      return { ...state, isFetching: false };

    case RESET_FETCH_ACA_REPORT:
      return { ...initialState, items: [] };

    default:
      return state;
  }
}
