import * as R from 'ramda';
import * as wsAnswersAction from 'sow/actions/worksheetAnswers';

const actionToWorksheetIdMap = R.compose(
  R.invertObj,
  R.map(R.prop('worksheet_uuid')),
  R.pathOr({}, ['data', 'entities', 'worksheetAnswers']),
);

const byWorksheetId = (state = {}, action) => {
  switch (action.type) {
    case wsAnswersAction.LOAD_ALL_FOR_ORG_OSP_APP_SUCCESS:
      return actionToWorksheetIdMap(action);

    case wsAnswersAction.SAVE_ONE_WORKSHEET_SUCCESS:
      return R.merge(state, actionToWorksheetIdMap(action));

    default:
      return state;
  }
};

export default byWorksheetId;
