import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { itemVersionRoute } from 'sow/routes';

import ItemVersionEditPageTemplate from 'sow/components/templates/ItemVersionEditPageTemplate';
import Block from 'sow/components/atoms/Block';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Confirm from 'sow/components/molecules/Confirm';
import FormikField from 'sow/components/organisms/FormikField';
import FormikDate from 'sow/components/molecules/FormikDate';
import FormikReactSelect from 'sow/components/molecules/FormikReactSelect';
import FormikSingleCheckbox from 'sow/components/molecules/FormikSingleCheckbox';
import FormikWysiwyg from 'sow/components/molecules/FormikWysiwyg';
import Button from 'sow/components/atoms/Button';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Spinner from 'sow/components/atoms/Spinner';
import Heading from 'sow/components/atoms/Heading';

const formSchema = Yup.object({
  name: Yup.string()
    .required('Name required.')
    .max(255, 'Maximum characters exceeded: 255/255'),
  version: Yup.string()
    .required('Version required.')
    .max(50, 'Maximum characters exceeded: 50/50'),
  item_id: Yup.number('Type required.')
    .typeError('Type required')
    .required('Type required.')
    .positive('Type required.'),
  start_date: Yup.string()
    .ensure()
    .matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Date is invalid. Please use MM/DD/YYYY ex. (04/05/2020)',
    )
    .typeError('Date is invalid. Please use MM/DD/YYYY ex. (04/05/2020)'),
  end_date: Yup.string()
    .ensure()
    .matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Date is invalid. Please use MM/DD/YYYY ex. (04/05/2020)',
    ),
});

const ItemVersionInfoEdit = ({
  itemVersionId,
  isLoading,
  itemOptions,
  actionGroupOptions,
  initialValues,
  handleSave,
}) => (
  <ItemVersionEditPageTemplate id={itemVersionId} isLoading={isLoading}>
    <Widget className="system-admin-list-edit nav-widget">
      <WidgetBody>
        <Formik
          initialValues={initialValues}
          validateOnChange={false}
          enableReinitialize={true}
          validationSchema={formSchema}
          onSubmit={handleSave}
          //   onReset={handleDelete}
        >
          {({ values, handleSubmit }) => (
            <Form>
              <Row className="form-row">
                <Column className="form-column" xs={12}>
                  <Field
                    className="input-filter"
                    label="Name"
                    placeholder=""
                    name={'name'}
                    component={FormikField}
                    required
                  />
                </Column>
                <Column className="form-column" xs={12}>
                  <Field
                    className="input-filter"
                    label="Version"
                    placeholder=""
                    name={'version'}
                    component={FormikField}
                    required
                  />
                </Column>
                <Column className="form-column" xs={12}>
                  <Field
                    label="Item Type"
                    placeholder=""
                    name={'item_id'}
                    component={FormikField}
                    type={FormikReactSelect}
                    clearable={true}
                    options={itemOptions}
                    required
                  />
                </Column>
                <Column className="form-column" xs={12} sm={6}>
                  <Field
                    className="form-control date-input"
                    label="Start Date"
                    placeholder=""
                    name={'start_date'}
                    dateFormat={'MM/DD/YYYY'}
                    component={FormikField}
                    type={FormikDate}
                    selectedDate={values.end_date ? { end: values.end_date } : null}
                  />
                </Column>
                <Column className="form-column" xs={12} sm={6}>
                  <Field
                    className="form-control date-input"
                    label="End Date"
                    placeholder=""
                    name={'end_date'}
                    dateFormat={'MM/DD/YYYY'}
                    component={FormikField}
                    type={FormikDate}
                    selectedDate={values.start_date ? { start: values.start_date } : null}
                  />
                </Column>
                <Column className="form-column" xs={12}>
                  <Field
                    label="Groups"
                    placeholder=""
                    name={'action_group_id'}
                    component={FormikField}
                    type={FormikReactSelect}
                    clearable={true}
                    multi={true}
                    options={actionGroupOptions}
                  />
                </Column>
              </Row>
              <Row className="form-row">
                <Column className="form-column" xs={4} sm={3} md={2}>
                  <Button className="form-button" onClick={handleSubmit} primary>
                    Save
                  </Button>
                </Column>
                <Column className="form-column" xs={4} sm={3} md={2}>
                  <Button
                    className="form-button reset-return-button"
                    to={itemVersionRoute()}
                  >
                    Back
                  </Button>
                </Column>
                {/* <Column sm={3} md={6}></Column>
                <Column className="form-column" xs={4} sm={3} md={2}>
                  <Confirm
                    message="Are you sure you want to delete this announcement?"
                    onConfirm={handleReset}
                  >
                    {toggleConfirm => (
                      <Button
                        className="delete-button"
                        onClick={toggleConfirm}
                        disabled={isNew}
                      >
                        <FontAwesome className="button-icon" icon="trash" />
                        Delete
                      </Button>
                    )}
                  </Confirm>
                </Column> */}
              </Row>
            </Form>
          )}
        </Formik>
      </WidgetBody>
    </Widget>
  </ItemVersionEditPageTemplate>
);

export default ItemVersionInfoEdit;
