import React from 'react';
import * as R from 'ramda';
import { Glyphicon, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { API_URL } from 'sow/constants/api';
import { fetchAttachmentList } from 'sow/actions/attachment';
import { removeAllFromFileQueue } from 'sow/actions/fileUpload';

import AttachmentFileUpload from './AttachmentFileUpload';

const uploadUrl = orgId => `${API_URL}/v1/org/${orgId}/attachment`;

class AddAttachmentModal extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    orgId: PropTypes.number,
  };

  state = {
    modalOpen: false,
  };

  handleModalClose = () => this.setState(R.assoc('modalOpen', false));
  handleModalOpen = () => {
    this.props.dispatch(removeAllFromFileQueue());
    this.setState(R.assoc('modalOpen', true));
  };

  handleUploadSuccess = () => {
    this.props.dispatch(fetchAttachmentList(this.props.orgId));

    if (this.state.modalOpen) {
      this.setState({ modalOpen: false });
    }
  };

  render() {
    const { orgId } = this.props;
    const { modalOpen } = this.state;

    return (
      <div>
        <button
          type="button"
          className="btn btn-success btn-xs"
          onClick={this.handleModalOpen}
        >
          <Glyphicon glyph="plus" /> Add Attachment
        </button>

        <Modal bsSize="lg" show={modalOpen} onHide={this.handleModalClose}>
          <Modal.Header closeButton>Add Attachment</Modal.Header>

          <Modal.Body>
            {modalOpen && (
              <AttachmentFileUpload
                uploadUrl={uploadUrl(orgId)}
                onUploadSuccess={this.handleUploadSuccess}
              />
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default connect()(AddAttachmentModal);
