import React from 'react';
import { Link } from 'react-router-dom';

import ErrorPage from 'sow/components/templates/ErrorPageTemplate';
import Block from 'sow/components/atoms/Block';
// import Link from 'sow/components/atoms/Link';

const ErrorNotFoundPage = () => (
  <ErrorPage large message="Page not found!">
    <Block textCenter textMuted>
      <Link to="/">Go Home</Link>
    </Block>
  </ErrorPage>
);

export default ErrorNotFoundPage;
