import React from 'react';
import PropTypes from 'prop-types';
import { scrollToCurrentHash } from 'sow/utils/scrollTo';

/**
 * This component will scroll to element on FIRST mount (only). On first mount
 * the following happens:
 *  1. Look for hash in the current browser url
 *  2. If hash exist look for element with id==hash
 *  3. If element found scroll to that element
 *
 * By default the scroll will be animated. To scroll without animation set the
 * `animate` prop to false.
 */
class ScrollToHashTargetOnMount extends React.Component {
  componentDidMount() {
    this._scrollToCurrentHash();
  }

  _scrollToCurrentHash() {
    const { animate, offset } = this.props;
    scrollToCurrentHash(offset, animate);
  }

  render() {
    return null;
  }
}

ScrollToHashTargetOnMount.propTypes = {
  animate: PropTypes.bool,
  offset: PropTypes.number,
};

ScrollToHashTargetOnMount.defaultProps = {
  animate: true,
};

export default ScrollToHashTargetOnMount;
