import * as R from 'ramda';

import actions from 'sow/actions/pure';
import { exportFileTypes } from 'sow/utils/org';

const initExportTypes = fileTypes => {
  const exportTypes = {
    worksheet_ids: [],
  };

  for (let ft in fileTypes) {
    exportTypes[ft] = false;
  }

  return exportTypes;
};

const initialState = () => ({
  selectedExports: initExportTypes(exportFileTypes),
  isGenerating: false,
  exportUrl: null,
});

const updateWorksheetIds = (currentIds, worksheetId) => {
  const prevSelected = R.contains(worksheetId, currentIds);

  return prevSelected
    ? R.without([worksheetId], currentIds)
    : R.append(worksheetId, currentIds);
};

export default function orgExportFiles(state = initialState(), action) {
  switch (action.type) {
    case String(actions.org.export.reset):
      return initialState();

    case String(actions.org.export.toggleType):
      return R.evolve({
        selectedExports: {
          [action.payload]: R.not,
        },
        exportUrl: R.always(null),
      })(state);

    case String(actions.org.export.toggleOspSection): {
      return {
        ...state,
        selectedExports: {
          ...state.selectedExports,
          worksheet_ids: updateWorksheetIds(
            state.selectedExports.worksheet_ids,
            action.payload.worksheetId,
          ),
        },
      };
    }

    // case exportAction.EXPORT_FILES:
    case String(actions.org.export.generate):
      return R.merge(state, {
        exportUrl: null,
        isGenerating: true,
      });

    case String(actions.org.export.generateDone):
      return R.merge(state, {
        isGenerating: false,
        exportUrl: action.payload.url,
      });

    case String(actions.org.export.generateFail):
      return R.merge(state, {
        isGenerating: false,
      });

    default:
      return state;
  }
}
