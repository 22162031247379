import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { resourceUpdate } from 'sow/store/helpers';

import * as sowTypes from 'sow/types';
import * as currentUser from 'sow/selectors/currentUser';
import { fromPlanApp } from 'sow/store/selectors';
import WorksheetQuestionChange from 'sow/components/organisms/WorksheetQuestionChange';

const getCreateChangeResource = (orgId, planAppId, changeRequestId) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`,
    'changeRequestOverviewResp',
  );

const mapStateToProps = (state, props) => {
  const { selectors } = getCreateChangeResource(
    props.orgId,
    props.planAppId,
    props.changeRequestId,
  );
  return {
    orgId: fromPlanApp.orgId(state, props),
    planAppId: fromPlanApp.planAppId(state, props),
    worksheetId: fromPlanApp.worksheetId(state, props),
    matrixRowId: fromPlanApp.matrixRowId(state, props),
    question: fromPlanApp.question(state, props),
    changeRequest: fromPlanApp.changeRequest(state, props),
    changeRequestIsOpen: fromPlanApp.changeRequestIsOpen(state, props),
    change: R.isNil(props.locationId)
      ? fromPlanApp.questionChange(state, props)
      : fromPlanApp.locationQuestionChange(state, props),
    changeId: R.isNil(props.locationId)
      ? fromPlanApp.questionChangeId(state, props)
      : fromPlanApp.locationQuestionChangeId(state, props),
    showAcaUI: currentUser.showAcaUI(state, props),
    isCreating: selectors.pending(state, props),
    requestList: state.adminRequests.worksheetRequests.list,
    locationRequestList: state.adminRequests.locationRequests.list,
    isInspector: currentUser.isInspector(state, props),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    orgId,
    planAppId,
    changeRequestId,
    worksheetId,
    matrixRowId,
    locationId,
    questionId,
    answerValue,
  } = ownProps;

  const { action } = getCreateChangeResource(orgId, planAppId, changeRequestId);

  const newChange = {
    context: R.isNil(locationId) ? 'main' : 'land',
    type: R.isNil(matrixRowId) ? 'worksheet_answer' : 'matrix_row_answer',
    action: R.isNil(answerValue) ? 'added' : 'updated',
    applicationId: planAppId,
    changeRequestId: changeRequestId,
    worksheetId: worksheetId,
    questionId: questionId,
    matrixRowId: null,
    landId: null,
    old: answerValue,
    new: answerValue,
  };

  return {
    // createChange: () => {
    //   console.log('dispatch change', ownProps);
    // },

    createChange: () => dispatch(action(null, { changes: [newChange] })),
  };
};

class WorksheetQuestionChangeContainer extends Component {
  state = {
    isLoading: false,
    conditionalAnswerValue: null,
  };

  componentDidUpdate(prevProps) {
    if (this.props.openChangeRequest && !this.state.isLoading) {
      this.setState({ isLoading: true });
      this.props.createChange();
    }
    if (this.state.isLoading && prevProps.isCreating && !this.props.isCreating) {
      this.setState({
        conditionalAnswerValue: this.props.answerValue,
      });
    }
  }

  render() {
    const { requestList, locationRequestList, questionId, locationId, matrixRowId, landId } = this.props;

    function isRequestedQuestion(questionId, matrixRowId) {
      let matches = [];
      if (locationId && locationRequestList && locationRequestList.length > 0) {
        if (!R.isNil(matrixRowId)) {
          matches = locationRequestList.filter(item => 
            item.location_id == locationId && 
            item.question_id == questionId && 
            item.matrix_row_id == matrixRowId);
        } else {
          matches = locationRequestList.filter(item => 
            item.location_id == locationId && 
            item.question_id == questionId);
        }
        if (matches.length > 0) {
          return true;
        } else {
          return false;
        }
      }
      if (requestList && requestList.length > 0) {
        if (!R.isNil(matrixRowId)) {
          matches = requestList.filter(item => item.question_id == questionId && item.matrix_row_id == matrixRowId);
        } else {
          matches = requestList.filter(item => item.question_id == questionId);
        }
        if (matches.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    
    return (
      <WorksheetQuestionChange
        {...this.props}
        conditionalAnswerValue={this.state.conditionalAnswerValue}
        requestedQuestion={isRequestedQuestion(questionId, matrixRowId)}
      />
    );
  }
}

WorksheetQuestionChangeContainer.propTypes = {
  // Passed props
  locationId: sowTypes.planAppLocationIdType,
  question: sowTypes.planAppWorksheetQuestionType.isRequired,
  /** formik field name for the answer */
  baseAnswersName: PropTypes.string.isRequired,
  /** formik field name for the change answer */
  baseAnswersChangesName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  changeUIDisabled: PropTypes.bool.isRequired,
  /** create conditional change request */
  openChangeRequest: PropTypes.bool,
  createChange: PropTypes.func,
  answerValue: PropTypes.any,
  isCreating: PropTypes.bool,

  // Connected props
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheetId: sowTypes.planAppWorksheetIdType.isRequired,
  matrixRowId: sowTypes.planAppWorksheetQuestionMatrixRowIdType,
  changeRequest: sowTypes.planAppChangeRequestType,
  changeRequestIsOpen: PropTypes.bool.isRequired,
  changeId: sowTypes.planAppChangeIdType,
  change: sowTypes.planAppChangeType,
  showAcaUI: PropTypes.bool.isRequired,
  isInspector: PropTypes.bool.isRequired,
};

WorksheetQuestionChangeContainer.defaultProps = {
  locationId: null,
  matrixRowId: null,
  changeRequest: null,
  disabled: false,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(WorksheetQuestionChangeContainer);
