import React from 'react';
import styled from 'styled-components';

const Flag = styled.div`
  & {
    top: 1px;
    right: 1px;
    position: absolute;
    text-align: right;
    color: white;
    font-size: 10px;
    padding: 1px 5px;
    height: 15px;
    background-color: #449ba8;
  }

  &:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    width: 0;
    height: 0;
    border-right: 10px solid #449ba8;
    border-bottom: 8px solid transparent;
  }

  &:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 7px;
    width: 0;
    height: 0;
    border-right: 10px solid #449ba8;
    border-top: 8px solid transparent;
  }
`;

const SampleFlag = () => <Flag>Sample</Flag>;

export default SampleFlag;
