import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import WorksheetBaseQuestionChange from 'sow/components/organisms/WorksheetBaseQuestionChange';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  worksheetAnswers: fromPlanApp.worksheetAnswers(state, props),
  worksheetChange: fromPlanApp.worksheetChange(state, props),
  isPlanAppLocked: fromPlanApp.isPlanAppLocked(state, props),
  changeRequestIsOpen: fromPlanApp.changeRequestIsOpen(state, props),
});

const WorksheetBaseQuestionChangeContainer = props => (
  <WorksheetBaseQuestionChange {...props} />
);

WorksheetBaseQuestionChangeContainer.propTypes = {
  // Passed props
  worksheetId: sowTypes.planAppWorksheetIdType.isRequired,
  changeUIDisabled: PropTypes.bool.isRequired,

  // Connected props
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  changeRequestId: sowTypes.planAppChangeRequestIdType,
  worksheetChange: sowTypes.planAppChangeType,
  changeRequestIsOpen: PropTypes.bool.isRequired,
};

WorksheetBaseQuestionChangeContainer.defaultProps = {
  changeRequestId: null,
  worksheetChange: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(WorksheetBaseQuestionChangeContainer);
