import React from 'react';
import * as R from 'ramda';
import RS from 'ramdasauce';

const Regulations = ({ regulations }) => {
  if (RS.isNilOrEmpty(regulations)) return null;

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <h3 className="panel-title">Guidance &amp; Additional Resources</h3>
      </div>
      <table className="table table-striped">
        <thead style={{ display: 'none' }}>
          <tr className="info text-default">
            <th className="text-center">Code</th>
            <th className="text-center">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ display: 'none' }}>
            <td colSpan={2}>FIX FIRST ROW BG WHITE</td>
          </tr>
          {regulations.map(regulation => (
            <tr key={regulation.uuid}>
              <td className="text-right">
                <a href={regulation.url} target="_blank">
                  {regulation.code}
                </a>
              </td>
              <td>{regulation.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default R.compose(
  x => x,
  /* pure,*/
)(Regulations);
