import * as R from 'ramda';
// import { normalize } from 'normalizr';

// import * as osActions from 'sow/actions/ospSection';
import { makeImmutable } from 'sow/utils/data';

// const mapKey = R.path(['ospSectionId']);

const byId = (state = makeImmutable({}), action) => {
  if (R.path(['data', 'entities', 'ospSections'], action)) {
    return state.merge(action.data.entities.ospSections);
  }

  // TODO (orther) add separate subreducer to hold changes
  switch (action.type) {
    // case osActions.SET_NAME:
    //   return state.setIn([mapKey(action.data), 'name'], action.data.name);

    default:
      return state;
  }
};

export default byId;

export const getIds = state => Object.keys(state);
export const getOspSection = R.curry((state, id) => R.path([id], state));
export const getOspSectionList = state => R.map(getOspSection(state));
