import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { replace as routerReplace } from 'connected-react-router';

import { itemVersionRoute } from 'sow/routes';

import * as itemVersionSectionActions from 'sow/actions/pure/itemVersionSection';

import ItemVersionCreator from 'sow/components/organisms/ItemVersionCreator';

const mapStateToProps = (state, props) => ({
  itemVersionId: R.path(['match', 'params', 'id'], props),
});

const mapDispatchToProps = {
  loadOptions: itemVersionSectionActions.loadOptions,
  loadList: itemVersionSectionActions.loadItemVersionSections,
  redirectNoHistory: routerReplace,
};

class ItemVersionCreatorContainer extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = { isLoading: true, selectedSection: {} };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.itemVersionId === 'new') {
      this.redirectToList();
    } else {
      this.props.loadOptions(this.storeOptionsAndFetch.bind(this));
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadItemVersionSections() {
    const { itemVersionId, loadList } = this.props;
    loadList({ item_version_id: itemVersionId }, this.storeItemVersionSection.bind(this));
  }

  storeOptionsAndFetch({ data }) {
    if (this._isMounted) {
      this.setState({
        sectionModalOptions: { itemSectionTypeOptions: data.item_section_type_options },
      });

      this.loadItemVersionSections();
    }
  }

  storeItemVersionSection({ data }) {
    if (this._isMounted) {
      this.setState({ itemVersionSectionList: data, isLoading: false });
    }
  }

  updateListOrder(reorderedList) {
    this.setState({ itemVersionSectionList: reorderedList });
  }

  onSectionSelect(sectionId) {
    const section = R.find(R.propEq('id', sectionId))(this.state.itemVersionSectionList);
    this.setState({ selectedSection: section });
  }

  redirectToList() {
    this.props.redirectNoHistory(itemVersionRoute());
  }

  render() {
    return (
      <ItemVersionCreator
        isLoading={this.state.isLoading}
        loadItemVersionSections={this.loadItemVersionSections.bind(this)}
        sectionBlockData={{
          itemVersionSectionList: this.state.itemVersionSectionList,
          updateListOrder: this.updateListOrder.bind(this),
          formOptions: this.state.sectionModalOptions,
          onSectionSelect: this.onSectionSelect.bind(this),
          selectedSectionId: this.state.selectedSection.id,
        }}
        questionBlockParams={{
          selectedSection: this.state.selectedSection,
        }}
        {...this.props}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ItemVersionCreatorContainer);
