import React from 'react';

import { inspectionReportAllRoute, inspectionReportUnannouncedRoute } from 'sow/routes';

import AcaPageTemplate from 'sow/containers/AcaPageTemplate';
import Nav from 'sow/components/atoms/Nav';
import NavLink from 'sow/components/molecules/NavLink';

const InspectionReportPageTemplate = ({ children, acaId }) => (
  <AcaPageTemplate title="Inspections">
    <Nav tabs>
      <NavLink to={inspectionReportAllRoute(acaId)}>All Inspections</NavLink>
      <NavLink to={inspectionReportUnannouncedRoute(acaId)}>
        Unannounced Inspections
      </NavLink>
    </Nav>
    {children}
  </AcaPageTemplate>
);

export default InspectionReportPageTemplate;
