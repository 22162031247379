import React from 'react';
import PropTypes from 'prop-types';

import ToastMessage from 'sow/components/organisms/ToastMessage';

class FileUploadQueueItem extends React.Component {
  static propTypes = {
    onRemoveFromQueue: PropTypes.func,
  };

  static defaultProps = {
    onRemoveFromQueue: () => {
      console.log('onRemoveFromQueue prop not set');
    },
  };

  hanleRemoveFromQueue = item => () => {
    if (this.props.onRemoveFromQueue) {
      this.props.onRemoveFromQueue(item.guid);
    }
  };

  prettyStatus(status) {
    switch (status) {
      case 'pending':
        return <span>Pending</span>;

      case 'uploading':
        return <span className="text-muted">Uploading...</span>;

      case 'success':
        return (
          <span className="text-success">
            <span className="glyphicon glyphicon-ok" /> Success
          </span>
        );

      case 'failed':
        return (
          <span className="text-danger">
            <span className="glyphicon glyphicon-remove" /> Failed
          </span>
        );

      default:
        return null;
    }
  }

  render() {
    const { item } = this.props;

    return (
      <tr>
        <td className="col-xs-8">
          {item.file.name}
          {item.error && (
            <div className="text-danger text-left">
              {/* Reusing the ToastMessage text portion for the error formatting */}
              <ToastMessage
                message="There was a problem uploading the file."
                validationErrors={item.errors}
              />
            </div>
          )}
        </td>

        <td className="col-xs-2">
          <div className="text-center">{this.prettyStatus(item.status)}</div>

          <div className="progress" style={{ height: '20px' }}>
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow={item.progress}
              style={{ width: `${item.progress}%` }}
            />
          </div>
        </td>

        <td className="col-xs-2">
          <button
            type="button"
            className="btn btn-danger btn-xs"
            onClick={this.hanleRemoveFromQueue(item)}
            disabled={item.status !== 'pending'}
          >
            <span className="glyphicon glyphicon-remove" />
          </button>
        </td>
      </tr>
    );
  }
}

export default FileUploadQueueItem;
