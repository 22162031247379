import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import Tooltip from 'sow/components/molecules/Tooltip';
import Glyphicon from 'sow/components/molecules/Glyphicon';

const mapStateToProps = (state, props) => ({
  isWorksheetNew: fromPlanApp.isWorksheetNew(state, props),
});

const GlyphWrapper = styled.span`
  background-color: ${props => props.theme.colors.green};
  padding: 5px 3px 2px 4px;
  color: white;
  border-radius: 2px;
`;

const NewWorksheetIndicatorContainer = ({ isWorksheetNew }) => {
  if (isWorksheetNew) {
    return (
      <Tooltip overlay="This worksheet has been added as part of a change to the Activities Checklist">
        <GlyphWrapper>
          <Glyphicon glyph="plus" />
        </GlyphWrapper>
      </Tooltip>
    );
  }

  return null;
};

NewWorksheetIndicatorContainer.propTypes = {
  // Passed props
  worksheetId: sowTypes.worksheetIdType.isRequired,

  // Connected props
  isWorksheetNew: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(NewWorksheetIndicatorContainer);
