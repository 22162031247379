import * as R from 'ramda';
import moment from 'moment';

export const utcStrToLocalMoment = utcDateTime => {
  return moment.utc(utcDateTime).local();
};

export const readableDateTime = dateTime => moment(dateTime).format('Y-M-D H:mm');

export const monthDayYearDateFormat = date => moment(date).format('L');

export const formattedDate = date =>
  moment(date, ['MM-DD-YYYY', 'YYYY-MM-DD']).format('L');

export const fromNow = dateTime =>
  R.pipe(utcStrToLocalMoment, dt => moment(dt).fromNow())(dateTime);

export const within90Days = date => {
  const compareDate = moment(date, ['MM-DD-YYYY', 'YYYY-MM-DD']);
  const currentDate = moment().startOf('day');
  const daysDiff = compareDate.diff(currentDate, 'd');
  return daysDiff <= 90;
};
