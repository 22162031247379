import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import SystemAdminObjectHome from 'sow/components/organisms/SystemAdminObjectHome';

const SystemAdminObjectPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <SystemAdminObjectHome />}
  </AdminPageTemplate>
);

export default SystemAdminObjectPage;
