import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import Switch from 'react-bootstrap-switch';

import { worksheetQuestionType } from 'sow/types';

import Block from 'sow/components/atoms/Block';
import Well from 'sow/components/molecules/Well';

const BaseEl = ({ isBaseQuestion, ...props }) => {
  if (isBaseQuestion) return <Block {...props} />;
  return <Well sm {...props} />;
};
BaseEl.propTypes = { isBaseQuestion: PropTypes.bool.isRequired };

const BooleanSelector = ({ question, answer, handleChange, disabled }) => {
  const isBaseQuestion = R.propOr(false, 'isBaseQuestion', question);

  return (
    <BaseEl isBaseQuestion={isBaseQuestion} style={{ margin: 0 }}>
      <Switch
        value={answer}
        tristate={true}
        disabled={disabled}
        onChange={(event, state) => handleChange(question.uuid, state)}
        offText="No"
        onText="Yes"
        bsSize="normal"
        offColor={isBaseQuestion ? 'danger' : 'default'}
        handleWidth={60}
        labelWidth={10}
      />
    </BaseEl>
  );
};

BooleanSelector.defaultProps = {
  answer: null,
};

BooleanSelector.propTypes = {
  question: worksheetQuestionType.isRequired,
  answer: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default BooleanSelector;
