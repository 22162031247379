import React, { Component } from 'react';
import * as R from 'ramda';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { v4 as guid } from 'uuid';
import PropTypes from 'prop-types';

import * as currentUser from 'sow/selectors/currentUser';
import { getFileUploadState } from 'sow/reducers/fileUpload';
import {
  addSingleFile,
  addToFileQueue,
  uploadFileQueue,
  updateFile,
} from 'sow/actions/fileUpload';
import QueueItem from './QueueItem';

function setPublic(pub, isAdmin, isAcaOwner, isAcaStaff, isInspector) {
	if (pub == null && !((isAdmin || isAcaOwner || isAcaStaff) && !isInspector))	{
		return true;
	} else {
		return pub;
	}
}

const mapStateToProps = (state, props) => ({
  // queue: getFileUploadState(state).singleFile,
  queue: getFileUploadState(state).fileQueue,
  isAdmin: currentUser.showAcaUI(state, props),
  isAcaOwner: currentUser.isAcaOwner(state, props),
  isAcaStaff: currentUser.isAcaStaff(state, props),
  isInspector: currentUser.isInspector(state, props),
});

const mapDispatchToProps = {
  addSingleFile,
  addToFileQueue,
  uploadFileQueue,
  updateFile,
};

class AttachmentFileUpload extends Component {
  componentDidMount() {
    // Clear the current file if there is one
    this.props.addSingleFile(null);
  }

  handleDrop = files => {
    files.forEach(file => {
      this.props.addToFileQueue({
        guid: guid(),
        uploadUrl: this.props.uploadUrl,
        signedUploadUrl: `${this.props.uploadUrl}/signedUrl`,
        status: 'pending',
        progress: 0,
        error: null,
        errors: [],
        file,
        name: file.name,
        public: setPublic(file.public, this.props.isAdmin, this.props.isAcaOwner, this.props.isAcaStaff, this.props.isInspector),
      });
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { queue, onUploadStart, onUploadSuccess } = this.props;

    if (typeof onUploadStart === 'function') {
      onUploadStart();
    }
    this.props.uploadFileQueue(queue, onUploadSuccess);
  };

  canUpload = queue => !R.isNil(queue);

  updateSingleFile = guid => (field, value) => {
    this.props.updateFile(guid, field, value);
  };

  render() {
    const { queue, isAdmin, isAcaOwner, isAcaStaff, isInspector } = this.props;

    return (
      <div>
        <Dropzone onDrop={this.handleDrop} className="drop-zone" multiple={true}>
          <p>Drop file here or click to browse.</p>
        </Dropzone>

        <form onSubmit={this.handleSubmit}>
          {queue &&
            queue.map(item => (
              <QueueItem
                key={item.guid}
                item={item}
                isAdmin={isAdmin}
				isAcaOwner={isAcaOwner}
				isAcaStaff={isAcaStaff}
				isInspector={isInspector}
                onChange={this.updateSingleFile(item.guid)}
              />
            ))}

          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!this.canUpload(queue)}
            >
              Upload
            </button>
          </div>
        </form>
      </div>
    );
  }
}

AttachmentFileUpload.propTypes = {
  uploadUrl: PropTypes.string,
  onUpoadSuccess: PropTypes.func,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AttachmentFileUpload);
