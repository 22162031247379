import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as consultantCompanyActions from 'sow/actions/pure/consultantCompany';
import { apiGet, apiPost } from 'sow/sagas/api';

export const getConsultantCompanies = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/consultant_companies', payload);
    if (response.data) {
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getConsultantCompanyById = function*({ id, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/consultant_companies/${id}`);
    if (response.data) {
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addConsultantCompany = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/consultant_companies', payload);
    if (response.data) {
      yield put(actions.shell.toast('success', 'Consultant company added.'));
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateConsultantCompany = function*({ id, payload, meta }) {
  try {
    const { response } = yield call(
      apiPost,
      `/admin/consultant_companies/${id}`,
      payload,
    );
    if (response.data) {
      yield put(actions.shell.toast('success', 'Consultant company updated.'));
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteConsultantCompany = function*({ id, meta }) {
  try {
    const { response } = yield call(apiPost, `/admin/consultant_companies/delete/${id}`);
    if (response.data) {
      yield put(actions.shell.toast('success', 'Consultant company deleted.'));
      if (meta.onSuccess) meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(
    consultantCompanyActions.LOAD_CONSULTANT_COMPANIES,
    getConsultantCompanies,
  );
  yield takeEvery(
    consultantCompanyActions.LOAD_CONSULTANT_COMPANY_BY_ID,
    getConsultantCompanyById,
  );
  yield takeEvery(consultantCompanyActions.ADD_CONSULTANT_COMPANY, addConsultantCompany);
  yield takeEvery(
    consultantCompanyActions.UPDATE_CONSULTANT_COMPANY,
    updateConsultantCompany,
  );
  yield takeEvery(
    consultantCompanyActions.DELETE_CONSULTANT_COMPANY,
    deleteConsultantCompany,
  );
}
