import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Block from 'sow/components/atoms/Block';
import Spinner from 'sow/components/atoms/Spinner';
import AcaActivityLogFilters from 'sow/components/organisms/AcaActivityLogFilters';
import AcaActivityTableContainer from 'sow/containers/AcaActivityTableContainer';
import ExportActiveUsersButton from 'sow/components/organisms/ExportActiveUsersButton';

const AcaActivityDashboardPage = ({
  acaId,
  filters,
  errors,
  onFilterChange,
  isFetching,
  onResetFilters,
  handleSearch,
  fetchingOptions,
  selectOptions,
  searchInitiated,
}) => (
  <Fragment>
    <Widget className="activity-log-page search-filter-block">
      <WidgetHeading>
        Activity Log Search Filter
        <Block pullRight>
          <ExportActiveUsersButton acaId={acaId} />
        </Block>
      </WidgetHeading>
      <WidgetBody>
        {fetchingOptions ? (
          <Spinner />
        ) : (
          <AcaActivityLogFilters
            errors={errors}
            filters={filters}
            onChange={onFilterChange}
            onResetFilters={onResetFilters}
            handleSearch={handleSearch}
            selectOptions={selectOptions}
          />
        )}
      </WidgetBody>
    </Widget>
    {searchInitiated && (
      <Widget>
        <WidgetHeading>Activity Log</WidgetHeading>
        <WidgetBody>
          {isFetching ? <Spinner /> : <AcaActivityTableContainer filters={filters} />}
        </WidgetBody>
      </Widget>
    )}
  </Fragment>
);

AcaActivityDashboardPage.propTypes = {
  acaId: sowTypes.acaIdType.isRequired,
  logs: PropTypes.arrayOf(PropTypes.object).isRequired,
  filters: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  onResetFilters: PropTypes.func.isRequired,
};

export default AcaActivityDashboardPage;
