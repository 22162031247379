import * as R from 'ramda';
import { combineReducers } from 'redux';

import confirm, * as fromConfirm from './confirm';

export default combineReducers({
  confirm,
});

export const getState = R.prop('shell');

export const getConfirmState = R.compose(
  R.prop('confirm'),
  getState,
);

export const getConfirmIsShowing = R.compose(
  fromConfirm.getIsShowing,
  getConfirmState,
);

export const getConfirmTitle = R.compose(
  fromConfirm.getTitle,
  getConfirmState,
);

export const getConfirmMessage = R.compose(
  fromConfirm.getMessage,
  getConfirmState,
);

export const getConfirmNoButtonText = R.compose(
  fromConfirm.getNoButtonText,
  getConfirmState,
);

export const getConfirmYesButtonText = R.compose(
  fromConfirm.getYesButtonText,
  getConfirmState,
);
