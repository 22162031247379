import React, { Component } from 'react';
import { connect } from 'react-redux';

import ImgFileUpload from 'sow/components/organisms/ImgFileUpload';
import { API_URL } from 'sow/constants/api';
import { getFileUploadState } from 'sow/reducers/fileUpload';
import { addToFileQueue, removeFromFileQueue } from 'sow/actions/fileUpload';
import {
  hasValidFilename,
  formatItemForQueue,
  formatBadItemForQueue,
} from 'sow/utils/fileUpload';

const mapStateToProps = state => ({ queue: getFileUploadState(state).fileQueue });

const mapDispatchToProps = {
  addToFileQueue,
  removeFromFileQueue,
};

class ImgFileUploadContainer extends Component {
  handleUpload(files) {
    const { addToFileQueue } = this.props;

    files.forEach(file => {
      if (hasValidFilename(file)) {
        addToFileQueue(formatItemForQueue(file, `${API_URL}/v1/admin/certification_img`));
      } else {
        // Add a failed item to the queue for rendering the errors -- this won't upload
        addToFileQueue(formatBadItemForQueue(file));
      }
    });
  }

  handleRemove(image) {
    const { removeFromFileQueue } = this.props;
    const { guid } = image;

    return event => {
      removeFromFileQueue(guid);
    };
  }

  render() {
    const { queue } = this.props;

    return (
      <ImgFileUpload
        queue={queue}
        handleUpload={this.handleUpload.bind(this)}
        handleRemove={this.handleRemove.bind(this)}
        {...this.props}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImgFileUploadContainer);
