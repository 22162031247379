import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ItemVersionEditPageTemplate from 'sow/components/templates/ItemVersionEditPageTemplate';
import PicklistModal from './PicklistModal';
import OptionListPopover from 'sow/components/molecules/OptionListPopover';
import PopoverOption from 'sow/components/molecules/OptionListPopover/PopoverOption';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Button from 'sow/components/atoms/Button';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Link from 'sow/components/atoms/Link';
import Block from 'sow/components/atoms/Block';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const ItemVersionPicklist = ({
  isLoading,
  itemVersionId,
  showModal,
  modalParams,
  picklistList,
  showPopover,
  popoverParams,
  editPicklist,
}) => (
  <ItemVersionEditPageTemplate isLoading={isLoading} id={itemVersionId}>
    <Widget className="picklist-list nav-widget">
      <DndProvider backend={HTML5Backend}>
        <PicklistModal {...modalParams} />
      </DndProvider>
      <OptionListPopover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        {...popoverParams}
      >
        <PopoverOption onClick={() => editPicklist()}>Edit</PopoverOption>
        <PopoverOption onClick={popoverParams.removePicklist}>Delete</PopoverOption>
      </OptionListPopover>
      <WidgetBody>
        <Row>
          <Column className="form-group" xs={12}>
            <Button className="no-margin-button" onClick={showModal} primary>
              Add Picklist
            </Button>
          </Column>
          <Column md={8}>
            <Table
              className="picklist-table"
              head={
                <TableRow>
                  <TableCell heading>Name</TableCell>
                </TableRow>
              }
            >
              {picklistList.length > 0 ? (
                picklistList.map(picklist => (
                  <TableRow key={picklist.id}>
                    <TableCell>
                      <Block>
                        <Link onClick={() => editPicklist(picklist.id)}>
                          {picklist.name}
                        </Link>
                      </Block>
                      <Block
                        className="action-ellipsis"
                        onClick={event => showPopover(event.currentTarget, picklist.id)}
                      >
                        <FontAwesome icon="ellipsis" />
                      </Block>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>No picklists created.</TableCell>
                </TableRow>
              )}
            </Table>
          </Column>
        </Row>
      </WidgetBody>
    </Widget>
  </ItemVersionEditPageTemplate>
);

export default ItemVersionPicklist;
