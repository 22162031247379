import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { inspectionYearRoute } from 'sow/routes';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Confirm from 'sow/components/molecules/Confirm';
import FormikField from 'sow/components/organisms/FormikField';
import FormikDate from 'sow/components/molecules/FormikDate';
import Button from 'sow/components/atoms/Button';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Spinner from 'sow/components/atoms/Spinner';

const formSchema = Yup.object({
  year: Yup.number()
    .required('Value must be a 4 digit year.')
    .positive('Value must be a 4 digit year.')
    .integer('Value must be a 4 digit year.')
    .typeError('Value must be a 4 digit year.')
    .max(9999, 'Value must be a 4 digit year.')
    .min(1000, 'Value must be a 4 digit year.'),
  start_on: Yup.string()
    .matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Date is invalid. Please use MM/DD/YYYY ex. (04/05/2020)',
    )
    .required('Date is invalid. Please use MM/DD/YYYY ex. (04/05/2020)'),
  certified_operations_count: Yup.number()
    .required('Numeric value required.')
    .typeError('Numeric value required.'),
  required_quantity: Yup.number()
    .required('Numeric value required.')
    .typeError('Numeric value required.'),
});

const InspectionYearEdit = ({
  isLoading,
  isNew,
  initialValues,
  handleSave,
  handleDelete,
}) => (
  <Fragment>
    {isLoading ? (
      <Spinner />
    ) : (
      <Widget className="system-admin-list-edit">
        <WidgetHeading>{isNew ? 'Add' : 'Edit'}</WidgetHeading>
        <WidgetBody>
          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validationSchema={formSchema}
            onSubmit={handleSave}
            onReset={handleDelete}
          >
            {({ values, handleSubmit, handleReset, errors }) => {
              return (
                <Form>
                  <Row className="form-row">
                    <Column className="form-column" xs={4} sm={3} md={2}>
                      <Button className="form-button" onClick={handleSubmit} primary>
                        Save
                      </Button>
                    </Column>
                    <Column className="form-column" xs={4} sm={3} md={2}>
                      <Button
                        className="form-button reset-return-button"
                        to={inspectionYearRoute()}
                      >
                        Cancel
                      </Button>
                    </Column>
                    <Column sm={3} md={6}></Column>
                    <Column className="form-column" xs={4} sm={3} md={2}>
                      <Confirm
                        message="Are you sure you want to delete this inspection year?"
                        onConfirm={handleReset}
                      >
                        {toggleConfirm => (
                          <Button className="delete-button" onClick={toggleConfirm}>
                            <FontAwesome className="button-icon" icon="trash" />
                            Delete
                          </Button>
                        )}
                      </Confirm>
                    </Column>
                  </Row>
                  <HorizontalRule className="divider" />
                  <Row className="form-row">
                    <Column className="form-column" xs={12}>
                      <Field
                        className="input-filter"
                        label="Year"
                        placeholder=""
                        name={'year'}
                        component={FormikField}
                      />
                    </Column>
                    <Column className="form-column" xs={12}>
                      <Field
                        className="form-control date-input"
                        label="Start On Date"
                        placeholder=""
                        name={'start_on'}
                        dateFormat={'MM/DD/YYYY'}
                        component={FormikField}
                        type={FormikDate}
                      />
                    </Column>
                    <Column className="form-column" xs={12}>
                      <Field
                        className="input-filter"
                        label="Certified Operations Count"
                        placeholder=""
                        name={'certified_operations_count'}
                        component={FormikField}
                      />
                    </Column>
                    <Column className="form-column" xs={12}>
                      <Field
                        className="input-filter"
                        label="Required Quantity"
                        placeholder=""
                        name={'required_quantity'}
                        component={FormikField}
                      />
                    </Column>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </WidgetBody>
      </Widget>
    )}
  </Fragment>
);

export default InspectionYearEdit;
