import * as R from 'ramda';
import React, { Component } from 'react';
import Select from 'react-select';
import { AsyncCreatable } from 'react-select';
import PropTypes from 'prop-types';

import { loadTypeahead } from 'sow/api/newApi/org/ingredient';

// import QuestionFieldProps from './propTypes';

const listToSelectOptions = R.map(item => ({
  label: item,
  value: item,
}));

//class ProductIngredientQuestionField extends Component {
//  // static propTypes = {
//  //   handleChange: PropTypes.func.isRequired,
//  //   orgId: PropTypes.number.isRequired,
//  //   ospAppId: PropTypes.number.isRequired,
//  //   worksheetId: PropTypes.string.isRequired,
//  //   question: PropTypes.string,
//  //   answer: PropTypes.any.isRequired,
//  //   required: PropTypes.bool,
//  //   value: PropTypes.string,
//
//  //   answer,
//  //   handleChange,
//  //   disabled = false,
//  // };
//
//  // static defaultProps = {
//  //   className: 'form-control',
//  //   disabled: false,
//  //   required: true,
//  //   value: '',
//  // };
//
//  loadOptions = text => {
//    const { orgId } = this.props;
//    return loadTypeahead(orgId, 'name', text)
//      .then(resp => listToSelectOptions(resp.data))
//      .then(R.uniq)
//      .then(options => ({ options }));
//  };
//
//  handleSelectChange = option => {
//    const value = R.path(['value'], option);
//    this.props.handleChange(value);
//  };
//
//  // promptTextCreator = label => `Create: "${label}"`;
//
//  render() {
//    const { value, disabled, required } = this.props;
//    const options = listToSelectOptions([value]);
//    console.log({ options });
//
//    return (
//      <AsyncCreatable
//        autoload={false}
//        loadOptions={this.loadOptions}
//        onChange={this.handleSelectChange}
//        promptTextCreator={this.promptTextCreator}
//        ignoreCase={false}
//        options={options}
//        required={required}
//        disabled={disabled}
//        value={value}
//      />
//    );
//  }
//}

class ProductIngredientQuestionField extends Component {
  static propTypes = {
    orgId: PropTypes.number,
    ospAppId: PropTypes.string,
    worksheetId: PropTypes.number,
    handleChange: PropTypes.func,
    question: PropTypes.object,
    answer: PropTypes.any,
    disabled: PropTypes.bool,
  };

  // state = {
  //   initialOptions: R.isEmpty(this.props.answer) ? [] : this.props.answer,
  //   options: [],
  // };

  //componentwillmount() {
  //componentwillmount() {
  //  const { acaId, scope } = this.props;
  //  const { categoryList, isFetching } = this.props;
  //  if (scope && !isFetching) {
  //    //} && !categoryList.length) {
  //    fetchProductCategoryList(acaId, scope);
  //  }
  //}
  //componentWillReceiveProps(nextProps) {
  //  const { acaId, scope } = nextProps;
  //  const { isFetching } = nextProps;
  //  if (scope !== this.props.scope && !isFetching) {
  //    fetchProductCategoryList(acaId, scope);
  //  }
  //}

  loadOptions = text => {
    const { orgId, answer } = this.props;

    return (
      loadTypeahead(orgId, 'name', text)
        // .then(resp => listToSelectOptions(resp.data))
        .then(R.prop('data'))
        // .then(d => console.log('load', { d }) && d)
        // .then(R.union([answer])) // add initial value
        // .then(R.reject(R.isEmpty))
        // .then(R.uniq)
        .then(listToSelectOptions)
        .then(options => ({ options }))
    );
  };

  handleSelectChange = option => {
    console.log('handleSelectChange', option);
    const value = R.path(['value'], option);
    this.props.handleChange(value);
  };

  getSelectComponent = () => {
    return AsyncCreatable;
    // return Select.Creatable;
    // return this.props.acaSpecific ? Select : Select.Creatable;
  };

  getSelectOptions = () => {
    const { categoryList, value } = this.props;
    const optionList = R.union([value, this.state.initialAnswer], categoryList);
    return listToSelectOptions(optionList);
  };

  handleChange = option => {
    if (option === null) return;
    this.props.handleChange(this.props.question.uuid, option.value);
  };

  render() {
    const { answer, disabled } = this.props;

    // const { isFetching, value, required, disabled } = this.props;
    const SelectComponent = this.getSelectComponent();
    //onChange={this.handleSelectChange}
    console.log({ answer }, this.props);
    return (
      <SelectComponent
        loadOptions={this.loadOptions}
        value={R.defaultTo('', answer)}
        onChange={this.handleChange}
        disabled={disabled}
      />
    );
  }
}

// ProductIngredientQuestionField.protoTypes = propTypes(
//   QuestionFieldProps,
//   {
//     // temp disable
//     // strict: false,
//   },
// );

export default ProductIngredientQuestionField;
