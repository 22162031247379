import React from 'react';
import PropTypes from 'prop-types';

import Block from 'sow/components/atoms/Block';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';

import UploadDocModal from 'sow/components/old/CertificationDocs/UploadDocModal';
import DocList from 'sow/components/old/CertificationDocs/DocList';

const CertificationDocsPage = ({ docs, canEdit, uploadUrl, onDelete }) => (
  <Widget>
    <WidgetHeading>
      Certification Docs
      {canEdit && (
        <Block pullRight>
          <UploadDocModal uploadUrl={uploadUrl} />
        </Block>
      )}
    </WidgetHeading>
    <WidgetBody>
      <DocList docs={docs} canEdit={canEdit} onDelete={onDelete} />
    </WidgetBody>
  </Widget>
);

CertificationDocsPage.propTypes = {
  docs: PropTypes.array.isRequired,
  canEdit: PropTypes.bool.isRequired,
  uploadUrl: PropTypes.string,
  onDelete: PropTypes.func,
};

export default CertificationDocsPage;
