import { call, put, takeLatest } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import { apiPost } from 'sow/sagas/api';
// import { downloadExportUrl } from 'sow/utils/org';

export const exportOspAppUrl = (orgId, ospAppId) =>
  `/org/${orgId}/application/export/${ospAppId}`;

export function* generateExport(orgId, ospAppId, options, { thunk }) {
  try {
    const url = exportOspAppUrl(orgId, ospAppId);
    const { response, error } = yield call(apiPost, url, options);

    if (response) {
      const downloadUrl = R.path(['data', 'path'], response);
      yield put(actions.org.export.generateDone(downloadUrl, thunk));
    }

    if (error) {
      yield put(actions.org.export.generateFail(error, thunk));
    }
  } catch (error) {
    yield put(actions.org.export.generateFail(error, thunk));
  }
}

export function* watchGenerateExport({ payload, meta }) {
  try {
    const { orgId, ospAppId, options } = payload;
    yield call(generateExport, orgId, ospAppId, options, meta);
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  try {
    yield takeLatest(actions.org.export.generate, watchGenerateExport);
  } catch (error) {
    console.error(error);
  }
}
