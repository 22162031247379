import React from 'react';
import FormGroup from 'sow/components/organisms/FormGroup';

export const WorksheetRegulationForm = ({ regulationId, regulation, onSetValue }) => {
  // TODO (orther) add validation if needed
  const errors = {};

  return (
    <div>
      <FormGroup
        fieldName="regulation_code"
        label="Code"
        tooltip="Enter the regulatory standards (NOP, COR, etc.) this worksheet complies with."
      >
        <input
          type="text"
          id="code"
          className="form-control"
          value={regulation.code}
          onChange={onSetValue('code')}
        />
      </FormGroup>
      <FormGroup
        fieldName="url"
        label="URL"
        tooltip="Enter a reference URL, if applicable, for the Regulation. Your clients will be able to click on the Regulation label and follow the link."
      >
        <input
          type="text"
          id="url"
          className="form-control"
          value={regulation.url}
          onChange={onSetValue('url')}
        />
      </FormGroup>
      <FormGroup
        fieldName="description"
        label="Description"
        tooltip="Enter the description for the regulation"
      >
        <input
          type="text"
          id="description"
          className="form-control"
          value={regulation.description}
          onChange={onSetValue('description')}
        />
      </FormGroup>
    </div>
  );
};

export default WorksheetRegulationForm;
