import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const FormikWysiwyg = ({ value, id, setFieldValue }) => (
  <Editor
    onEditorChange={value => setFieldValue(id, value)}
    tinymceScriptSrc={'/assets/tinymce-6.7.1/tinymce.min.js'}
    value={value}
    init={{
      menubar: false,
      plugins: [
        'advlist',
        'anchor',
        'autolink',
        'code',
        'codesample',
        'image',
        'lists',
        'link',
        'media',
        'searchreplace',
        'table',
        'visualblocks',
      ],
      toolbar:
        'fontfamily | ' +
        'fontsize | ' +
        'bold italic underline | ' +
        'forecolor backcolor | ' +
        'alignleft aligncenter ' +
        'alignright alignjustify | ' +
        'bullist numlist ',
      branding: false,
      statusbar: false,
    }}
  />
);

export default FormikWysiwyg;
