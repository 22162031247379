import * as R from 'ramda';
import { normalize } from 'normalizr';

import * as schema from 'sow/schema';
import * as wsAnswersApi from 'sow/api/org/worksheetAnswers';
import actions from 'sow/actions/pure';
import { makeImmutable, makeImmutableData } from 'sow/utils/data';

function mark(action) {
  return `soworganic/worksheetAnswers/${action}`;
}

export const LOAD_ALL_FOR_ORG_OSP_APP_REQUEST = mark('LOAD_ALL_FOR_ORG_OSP_APP_REQUEST');
export const LOAD_ALL_FOR_ORG_OSP_APP_SUCCESS = mark('LOAD_ALL_FOR_ORG_OSP_APP_SUCCESS');
export const LOAD_ALL_FOR_ORG_OSP_APP_FAILURE = mark('LOAD_ALL_FOR_ORG_OSP_APP_FAILURE');

export const MATRIX_ROW_CREATE = mark('MATRIX_ROW_CREATE');
export const MATRIX_ROW_DELETE = mark('MATRIX_ROW_DELETE');
export const MATRIX_ROW_UNDELETE = mark('MATRIX_ROW_UNDELETE');
export const MATRIX_ROW_SET_ANSWER = mark('MATRIX_ROW_SET_ANSWER');

export const SAVE_ONE_WORKSHEET_REQUEST = mark('SAVE_ONE_WORKSHEET_REQUEST');
export const SAVE_ONE_WORKSHEET_SUCCESS = mark('SAVE_ONE_WORKSHEET_SUCCESS');
export const SAVE_ONE_WORKSHEET_FAILURE = mark('SAVE_ONE_WORKSHEET_FAILURE');

export const SET_ANSWER = mark('SET_ANSWER');
export const SET_BASE_ANSWER = mark('SET_BASE_ANSWER');

const normalizeWorksheetAnswers = R.flip(normalize)([schema.worksheetAnswers]);

const formatWorksheetAnswerProps = R.curry(([worksheetId, wsAnswers]) =>
  R.pipe(
    R.merge({
      isNotApplicable: R.prop('is_not_applicable', wsAnswers),
      readyForReview: R.prop('ready_for_review', wsAnswers),
      worksheet_uuid: worksheetId,
    }),
    R.omit(['ready_for_review', 'is_not_applicable']),
  )(wsAnswers),
);

export const formatWorksheetAnswersRespData = R.pipe(
  R.toPairs,
  R.map(formatWorksheetAnswerProps),
  normalizeWorksheetAnswers,
);

export const loadAllWorksheetAnswersForOrgOspApp = (orgId, ospAppId) => dispatch => {
  const buildData = makeImmutableData({ orgId, ospAppId });

  dispatch({
    type: LOAD_ALL_FOR_ORG_OSP_APP_REQUEST,
    data: buildData(),
  });

  wsAnswersApi
    .fetchAll(orgId, ospAppId)
    .done(resp => {
      const data = formatWorksheetAnswersRespData(resp.data);
      dispatch({
        type: LOAD_ALL_FOR_ORG_OSP_APP_SUCCESS,
        data: buildData(data),
      });
    })
    .fail(err => {
      dispatch({
        type: LOAD_ALL_FOR_ORG_OSP_APP_FAILURE,
        data: buildData({ err }),
      });
    });
};

export const saveWorksheetAnswersForOrgOspApp = (
  orgId,
  ospAppId,
  worksheetId,
  wsAnswers,
  onSuccess,
  onFailure,
) => dispatch => {
  const buildData = makeImmutableData({
    orgId,
    ospAppId,
    worksheetId,
    wsAnswers,
  });
  dispatch({
    type: SAVE_ONE_WORKSHEET_REQUEST,
    data: buildData(),
  });

  wsAnswersApi
    .saveOne(orgId, ospAppId, worksheetId, wsAnswers)
    .done(resp => {
      const data = formatWorksheetAnswersRespData(resp.data);
      dispatch({
        type: SAVE_ONE_WORKSHEET_SUCCESS,
        data: buildData(data),
      });

      // TODO (orther) I'm a bad person for putting this here and it should be moved
      if (typeof onSuccess === 'function') onSuccess();
    })
    .fail(err => {
      dispatch({
        type: SAVE_ONE_WORKSHEET_FAILURE,
        data: buildData({ err }),
      });

      const errorMessage = R.path(['responseJSON', 'error', 'message'], err);
      if (errorMessage) {
        dispatch(actions.shell.toast('danger', errorMessage));
      }
    });
};

// -- ----
// -- NOTE (orther) these had already been ported -> actions/pure/worksheetAnswers
// -- ----

// export const setBaseAnswer = (ospAppId, worksheetId, name, value) => {
//   dispatch({
//     type: SET_BASE_ANSWER,
//     data: makeImmutable({ ospAppId, worksheetId, name, value }),
//   });
// };

// export const setAnswer = (ospAppId, worksheetId, questionId, value) => {
//   dispatch({
//     type: SET_ANSWER,
//     data: makeImmutable({ ospAppId, worksheetId, questionId, value }),
//   });
// };

// export const createMatrixRow = (ospAppId, worksheetId, rowId) => {
//   dispatch({
//     type: MATRIX_ROW_CREATE,
//     data: makeImmutable({ ospAppId, worksheetId, rowId }),
//   });
// };

// export const deleteMatrixRow = (ospAppId, worksheetId, rowId) => {
//   dispatch({
//     type: MATRIX_ROW_DELETE,
//     data: makeImmutable({ ospAppId, worksheetId, rowId }),
//   });
// };

// export const undeleteMatrixRow = (ospAppId, worksheetId, rowId) => {
//   dispatch({
//     type: MATRIX_ROW_UNDELETE,
//     data: makeImmutable({ ospAppId, worksheetId, rowId }),
//   });
// };

// export const setMatrixRowAnswer = (
//   ospAppId,
//   worksheetId,
//   rowId,
//   questionId,
//   value
// ) => {
//   dispatch({
//     type: MATRIX_ROW_SET_ANSWER,
//     data: makeImmutable({ ospAppId, worksheetId, rowId, questionId, value }),
//   });
// };

// export const clearAnswers = (ospAppId, worksheetId) => {
//   dispatch({
//     type: CLEAR,
//     data: makeImmutable({ ospAppId, worksheetId }),
//   });
// };
