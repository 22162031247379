import React from 'react';
import classnames from 'classnames';

import { systemAdminObjectRoute, systemAdminItemRoute } from 'sow/routes';

import List from 'sow/components/atoms/List';
import NavLink from 'sow/components/molecules/NavLink';

const AdminSubmenu = ({ navClasses }) => (
  <List className={classnames('sidebar-submenu', navClasses)}>
    <NavLink to={systemAdminObjectRoute()} exact>
      System Objects
    </NavLink>
    <NavLink to={systemAdminItemRoute()} exact>
      Item Objects
    </NavLink>
  </List>
);

export default AdminSubmenu;
