import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as currentOrg from 'sow/selectors/currentOrg';
import * as currentOspApp from 'sow/selectors/currentOspApp';
import AttachmentList from 'sow/containers/attachment/OrgAttachmentList';

const mapStateToProps = (state, props) => ({
  attachmentList: state.attachment.items,
  isFetching: state.attachment.isFetching,
  orgId: currentOrg.orgId(state, props),
  ospAppId: currentOspApp.ospAppId(state, props),
  isOspList: true,
});

const OrgAttachmentOspListContainer = props => <AttachmentList {...props} />;

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(OrgAttachmentOspListContainer);
