import * as R from 'ramda';
import { normalize } from 'normalizr';
import * as schema from 'sow/schema';
import { makeImmutable } from 'sow/utils/data';

const byId = (state = makeImmutable({}), action) => {
  if (R.path(['data', 'entities', 'ospDefs'], action)) {
    return state.merge(action.data.entities.ospDefs);
  }

  return state;
};

export default byId;

export const getIds = state => Object.keys(state);
export const getOspDef = (state, ospDefId) => R.path([ospDefId], state);
export const getOspDefList = state => getIds(state).map(id => state[id]);
