import React from 'react';

import { itemSubtypeRoute } from 'sow/routes';

import AdminObjectTemplateContainer from 'sow/containers/AdminObjectTemplateContainer';
import ItemSubtypeEditContainer from 'sow/containers/ItemSubtypeEditContainer';

const ItemSubtypeEditPage = () => (
  <AdminObjectTemplateContainer
    breadcrumbPath={[['Item Subtypes', itemSubtypeRoute()]]}
    statePath={['itemSubtype']}
    pageName="New Item Subtype"
  >
    <ItemSubtypeEditContainer />
  </AdminObjectTemplateContainer>
);

export default ItemSubtypeEditPage;
