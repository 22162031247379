import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import * as currentUser from 'sow/selectors/currentUser';
import { fromPlanApp } from 'sow/store/selectors';
import PlanAppExportContentWorksheetMatrix from 'sow/components/organisms/PlanAppExportContent/WorksheetMatrix';

const mapStateToProps = (state, props) => ({
  matrixRowAnswerMap: fromPlanApp.exportMatrixRowAnswerMap(state, props),
  matrixRowIdList: fromPlanApp.exportMatrixRowIdList(state, props),
  changeRequest: fromPlanApp.changeRequest(state, props),
  changeRequestIsOpen: fromPlanApp.changeRequestIsOpen(state, props),
  questionList: fromPlanApp.worksheetQuestionList(state, props),
  showAcaUI: currentUser.showAcaUI(state, props),
});

const PlanAppExportContentWorksheetMatrixContainer = ({ ...props }) => (
  <PlanAppExportContentWorksheetMatrix {...props} />
);

PlanAppExportContentWorksheetMatrixContainer.propTypes = {
  // Passed props
  planAppId: sowTypes.planAppIdType.isRequired,
  locationId: sowTypes.planAppLocationIdType,
  worksheetId: sowTypes.planAppWorksheetIdType.isRequired,

  // Connected props
  matrixRowAnswerMap: PropTypes.object,
  matrixRowIdList: sowTypes.planAppWorksheetQuestionMatrixRowIdTListType.isRequired,
  questionList: sowTypes.planAppWorksheetQuestionListType.isRequired,
  changeRequest: sowTypes.planAppChangeRequestType,
  changeRequestIsOpen: PropTypes.bool.isRequired,
  showAcaUI: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppExportContentWorksheetMatrixContainer);
