import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';

import { systemAdminItemRoute, itemEditRoute } from 'sow/routes';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormikField from 'sow/components/organisms/FormikField';
import Button from 'sow/components/atoms/Button';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Spinner from 'sow/components/atoms/Spinner';
import DragDropListContainer from 'sow/containers/DragDropListContainer';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Link from 'sow/components/atoms/Link';

const ItemList = ({ loadingList, itemList }) => (
  <Widget className="system-admin-list-edit">
    <WidgetHeading>Items</WidgetHeading>
    <WidgetBody>
      <Row className="form-row">
        <Column className="form-column" xs={12} sm={6} md={4}>
          <Row className="button-row">
            <Column className="button-column" xs={6} sm={6} md={6}>
              <Button className="form-button" to={itemEditRoute('new')} primary>
                <FontAwesome className="button-icon" icon="plus" />
                Add
              </Button>
            </Column>
            <Column className="button-column" xs={6} sm={6} md={6}>
              <Button
                className="form-button reset-return-button"
                to={systemAdminItemRoute()}
              >
                Back
              </Button>
            </Column>
          </Row>
        </Column>
      </Row>
      {loadingList ? (
        <Spinner />
      ) : (
        <Row className="form-row list-row">
          <Column className="form-column" xs={12} sm={12} md={12}>
            <Table
              bordered
              striped
              head={
                <TableRow>
                  <TableCell heading>Name</TableCell>
                  <TableCell heading>Item Type</TableCell>
                  <TableCell heading>Item Subtype</TableCell>
                  <TableCell heading></TableCell>
                </TableRow>
              }
            >
              {itemList.length > 0 ? (
                <Fragment>
                  {itemList.map(item => (
                    <TableRow key={item.id}>
                      <TableCell className="wrap">{item.name}</TableCell>
                      <TableCell>{item.item_type}</TableCell>
                      <TableCell>{item.item_subtype}</TableCell>
                      <TableCell>
                        <Link className="start-end-width" to={itemEditRoute(item.id)}>
                          Edit
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </Fragment>
              ) : (
                <TableRow>
                  <TableCell colSpan={4} textCenter>
                    No items found.
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </Column>
        </Row>
      )}
    </WidgetBody>
  </Widget>
);

export default ItemList;
