import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  planAppStates,
  planAppFlags,
  planAppFlagAbbreviations,
} from 'sow/constants/planApp';

const NOT_STARTED_THRESHOLD_DAYS = 60;

const Flag = styled.div`
  & {
    top: -7px;
    right: -5px;
    position: relative;
    text-align: right;
    float: right;
    display: inline;
    color: white;
    font-size: 10px;
    padding: 1px 5px;
    height: 15px;
    background-color: red;
  }

  &:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    width: 0;
    height: 0;
    border-right: 10px solid red;
    border-bottom: 8px solid transparent;
  }

  &:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 7px;
    width: 0;
    height: 0;
    border-right: 10px solid red;
    border-top: 8px solid transparent;
  }
`;

class CountdownFlag extends Component {
  render() {
    const { ospApp } = this.props;
    const flags = ospApp.application_alerts;
    let notStartedFlag = null;
    let inspectionUnpaidFlag = null;
    let renewalUnpaidFlag = null;

    if (flags[planAppFlags.IS_NOT_STARTED]) {
      notStartedFlag = (
        <Flag key={'notStartedFlag'}>
          {planAppFlagAbbreviations[planAppFlags.IS_NOT_STARTED]}
        </Flag>
      );
    }

    if (flags[planAppFlags.IS_INSPECTION_UNPAID]) {
      inspectionUnpaidFlag = (
        <Flag key={'inspectionUnpaidFlag'}>
          {planAppFlagAbbreviations[planAppFlags.IS_INSPECTION_UNPAID]}
        </Flag>
      );
    }

    if (flags[planAppFlags.IS_RENEWAL_UNPAID]) {
      renewalUnpaidFlag = (
        <Flag key={'renewalUnpaidFlag'}>
          {planAppFlagAbbreviations[planAppFlags.IS_RENEWAL_UNPAID]}
        </Flag>
      );
    }

    return (
      <Fragment>{[notStartedFlag, inspectionUnpaidFlag, renewalUnpaidFlag]}</Fragment>
    );
  }
}

CountdownFlag.propTypes = {
  ospApp: PropTypes.object.isRequired,
};

export default CountdownFlag;
