import actions from 'sow/actions/pure';

const isLoaded = (state = false, action) => {
  switch (action.type) {
    case String(actions.ospApp.loadOrgListDone):
      // case String(actions.ospApp.loadOrgListFail):
      return true;

    default:
      return state;
  }
};

export default isLoaded;
