import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import ActionGroupEditContainer from 'sow/containers/ActionGroupEditContainer';

const ActionGroupEditPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <ActionGroupEditContainer />}
  </AdminPageTemplate>
);

export default ActionGroupEditPage;
