//import * as R from 'ramda';
import {
  fetchDashboardAsync,
  fetchDashboardCountsAsync,
  addSpecialistToOrg,
  removeSpecialistFromOrg,
  inspectorDone,
  inspectorUpdatePlanState,
  addSpecialistPriority,
  addOrUpdateSpecialistPriority,
  removeSpecialistPriority,
} from 'sow/services/dashboard';

const mark = action => `soworganic/orgWorkflow/${action}`;

export const FETCH_DASHBOARD_LIST = mark('FETCH_DASHBOARD_LIST');
export const FETCH_DASHBOARD_LIST_SUCCESS = mark('FETCH_DASHBOARD_LIST_SUCCESS');
export const FETCH_DASHBOARD_LIST_FAILED = mark('FETCH_DASHBOARD_LIST_FAILED');

export const FETCH_DASHBOARD_COUNTS = mark('FETCH_DASHBOARD_COUNTS');
export const FETCH_DASHBOARD_COUNTS_SUCCESS = mark('FETCH_DASHBOARD_COUNTS_SUCCESS');
export const FETCH_DASHBOARD_COUNTS_FAILED = mark('FETCH_DASHBOARD_COUNTS_FAILED');

export const ADD_SPECIALIST = 'ADD_SPECIALIST';
export const ADD_SPECIALIST_SUCCESS = 'ADD_SPECIALIST_SUCCESS';
export const ADD_SPECIALIST_FAILED = 'ADD_SPECIALIST_FAILED';

export const REMOVE_SPECIALIST = 'REMOVE_SPECIALIST';
export const REMOVE_SPECIALIST_SUCCESS = 'REMOVE_SPECIALIST_SUCCESS';
export const REMOVE_SPECIALIST_FAILED = 'REMOVE_SPECIALIST_FAILED';

export const UPDATE_DASHBOARD_NOTES = 'UPDATE_DASHBOARD_NOTES';

export const INSPECTOR_UPDATES = 'INSPECTOR_UPDATES';
export const INSPECTOR_UPDATES_DONE = 'INSPECTOR_UPDATES_DONE';

export const UPDATE_PLAN_STATE = 'UPDATE_PLAN_STATE';

export const ADD_USER_PRIORITY = 'ADD_USER_PRIORITY';
export const USER_PRIORITY_DONE = 'USER_PRIORITY_DONE';
export const REMOVE_USER_PRIORITY_DONE = 'REMOVE_USER_PRIORITY_DONE';

export const setNoteForDashboardItem = (index, note) => dispatch => {
  dispatch({ type: UPDATE_DASHBOARD_NOTES, data: { index, note } });
};

export const addSpecialist = payload => dispatch => {
  dispatch({ type: ADD_SPECIALIST });

  addSpecialistToOrg(payload)
    .done(res => {
      dispatch({
        type: ADD_SPECIALIST_SUCCESS,
        data: payload.specialistsByOrg,
      });
    })
    .fail(err => {
      console.error('Error adding specialist:', err.responseJSON);
      dispatch({ type: ADD_SPECIALIST_FAILED });
    });
};

//removeSpecialistFromOrg
export const removeSpecialist = payload => dispatch => {
  dispatch({ type: REMOVE_SPECIALIST });

  removeSpecialistFromOrg(payload)
    .done(res => {
      dispatch({
        type: REMOVE_SPECIALIST_SUCCESS,
        data: payload.specialistsByOrg,
      });
    })
    .fail(err => {
      console.error('Error removing specialist:', err.responseJSON);
      dispatch({ type: REMOVE_SPECIALIST_FAILED });
    });
};

export const fetchDashboardList = (acaId, filter, additionalFilters) => dispatch => {
  dispatch({ type: FETCH_DASHBOARD_LIST });

  fetchDashboardAsync(acaId, filter, additionalFilters)
    .done(res => {
      // res.data.map(item => {
      // Thunks can be dispatched, if Redux Thunk is applied,
      // just like normal action creators:
      // });

      dispatch({
        type: FETCH_DASHBOARD_LIST_SUCCESS,
        data: res.data,
      });
    })
    .fail(err => {
      console.error('Error fetching dashboard:', err.responseJSON);
      dispatch({ type: FETCH_DASHBOARD_LIST_FAILED });
    });
};

export const fetchDashboardCounts = (acaId, filter) => dispatch => {
  dispatch({ type: FETCH_DASHBOARD_COUNTS });

  fetchDashboardCountsAsync(acaId, filter)
    .done(res => {
      dispatch({
        type: FETCH_DASHBOARD_COUNTS_SUCCESS,
        counts: res.counts,
        filter_options: res.filter_options,
        priority_types: res.priority_types ? res.priority_types : [],
      });
    })
    .fail(err => {
      console.error('Error fetching dashboard counts:', err.responseJSON);
      dispatch({ type: FETCH_DASHBOARD_COUNTS_FAILED });
    });
};

export const inspectionDashboardDone = (
  orgId,
  planAppId,
  updates,
  onSuccess,
) => dispatch => {
  dispatch({ type: INSPECTOR_UPDATES });

  inspectorDone(orgId, planAppId, updates)
    .done(({ data }) => {
      dispatch({
        type: INSPECTOR_UPDATES_DONE,
        data,
      });
      if (onSuccess) onSuccess();
    })
    .fail(err => {});
};

export const updatePlanState = (orgId, planAppId, state, onSuccess) => dispatch => {
  dispatch({ type: UPDATE_PLAN_STATE });

  inspectorUpdatePlanState(orgId, planAppId, state)
    .done(res => {
      if (onSuccess) onSuccess();
    })
    .fail(error => console.log(error));
};

export const dashboardAddUserPriority = (orgId, planAppId, data) => dispatch => {
  dispatch({ type: ADD_USER_PRIORITY });

  addSpecialistPriority(orgId, planAppId, data)
    .done(res => {
      dispatch({
        type: USER_PRIORITY_DONE,
        data: res,
      });
    })
    .fail(error => console.error(error));
};

export const dashboardAddOrUpdateUserPriority = (orgId, planAppId, data) => dispatch => {
  addOrUpdateSpecialistPriority(orgId, planAppId, data)
    .done(res => {
      dispatch({
        type: USER_PRIORITY_DONE,
        data: res,
      });
    })
    .fail(error => console.error(error));
};

export const dashboardRemoveUserPriority = (orgId, planAppId, data) => dispatch => {
  removeSpecialistPriority(orgId, planAppId, data)
    .done(res => {
      dispatch({
        type: REMOVE_USER_PRIORITY_DONE,
        data: res,
      });
    })
    .fail(error => console.error(error));
};
