import React, { Fragment } from 'react';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { DropdownButton, Glyphicon, MenuItem } from 'react-bootstrap';
import Image from 'sow/components/atoms/Image';

import * as currentAca from 'sow/selectors/currentAca';
import * as currentUser from 'sow/selectors/currentUser';
import * as schema from 'sow/schema';
import FormPanel from 'sow/components/old/OspDefEditor/FormPanel';
import InfoTooltip from 'sow/components/organisms/InfoTooltip';
import { bgColorDanger } from 'sow/constants/style';
import { acaOspBaseRoute } from 'sow/routes';
import {
  landFacilitySectionLens,
  landOfficeSectionLens,
  landParcelSectionLens,
  landPastureSectionLens,
  productSectionLens,
  qualQuestionsLens,
} from 'sow/reducers/ospDefinitions';

import * as editorAction from './reducers/OspDefEditor';
import * as select from './selectors';
import OspSection from './OspSection';
import MiniOspSection from './MiniOsp';
import QualificationQuestion from './QualificationQuestion';
import StaticWorksheet from './Worksheet/StaticWorksheet';
import { answersUntagger } from './reducers/worksheetQuestions';
import { createQualQuestion } from './reducers/ospQualificationQuestions';
import {
  actions as ospDefAction,
  createSection,
  createLocationType,
} from './reducers/ospDef';
import RouterWillLeaveBlock from 'sow/components/atoms/RouterWillLeaveBlock';

export const denormalizeData = ({
  ospDef,
  ospSections,
  worksheets,
  worksheetQuestions,
  ospQualificationQuestions,
}) => {
  const normData = { ospDef };
  const normSchema = { ospDef: schema.ospDef };
  const entities = {
    ospDef,
    ospSections,
    worksheets,
    worksheetQuestions,
    ospQualificationQuestions,
    ospQualificationQuestionSubQuestions: ospQualificationQuestions,
  };
  return denormalize(normData, normSchema, entities);
};

const mapStateToProps = (state, props) => ({
  // TODO replace with OspDefEditor specific selectors
  aca: currentAca.aca(state, props),
  acaId: currentAca.acaId(state, props),
  editorIsDirty: select.editorIsDirty(state, props),
  ospDef: select.ospDef(state, props),
  ospSections: select.ospSections(state, props),
  worksheets: select.worksheets(state, props),
  // staticWorksheets: select.staticWorksheetIds(state, props),
  worksheetQuestions: select.worksheetQuestions(state, props),
  ospQualificationQuestions: select.ospQualificationQuestions(state, props),
  canEditOspDef: currentUser.canEditOspDef(state, props),
});

const mapDispatchToProps = (
  dispatch,
  {
    originalOspDef,
    aca,
    acaId,
    ospDef,
    ospSections,
    worksheets,
    worksheetQuestions,
    ospQualificationQuestions,
    canEditOspDef,
    onSave,
  },
) => {
  const setEditorClean = () => dispatch(editorAction.setClean());
  // TODO (orther) remove after dev
  return {
    resetEditor: () => dispatch(editorAction.initEditor(originalOspDef)),
    onSaveClick: event => {
      if (canEditOspDef) {
        onSave(
          acaId,
          denormalizeData({
            ospDef,
            ospSections,
            worksheets,
            worksheetQuestions: answersUntagger(worksheetQuestions),
            ospQualificationQuestions,
          }),
          setEditorClean,
        );
      }
    },
    onAddSection: event => {
      const newSection = createSection();
      dispatch(ospDefAction.sectionAdd(newSection));
    },
    onAddRootQualificationQuestion: event => {
      const newQualQuestion = createQualQuestion();
      dispatch(ospDefAction.ospQualificationQuestionAdd(newQualQuestion));
    },
    onAddLocationType: event => {
      const newLocationType = createLocationType();
      dispatch(ospDefAction.locationTypeAdd(newLocationType));
    },
  };
};

// TODO (orther) have this type of data scrubbing done before it is in component
const stripNil = R.reject(R.isNil);

class OspDefEditor extends React.Component {
  componentDidMount() {
    this.props.resetEditor();
  }

  isDirty() {
    return !!this.props.editorIsDirty;
  }

  render() {
    const {
      aca,
      ospDef,
      ospQualificationQuestions,
      onSaveClick,
      onAddLocationType,
      onAddSection,
      onAddRootQualificationQuestion,
      canEditOspDef,
    } = this.props;

    const isDirty = this.isDirty();

    const locationSectionIds = R.path(['definition', 'location_types'], ospDef);
    const ospSectionIds = R.path(['definition', 'sections'], ospDef);
    const productSectionId = R.view(productSectionLens, ospDef);
    const landFacilitySectionId = R.view(landFacilitySectionLens, ospDef);
    const landOfficeSectionId = R.view(landOfficeSectionLens, ospDef);
    const landParcelSectionId = R.view(landParcelSectionLens, ospDef);
    const landPastureSectionId = R.view(landPastureSectionLens, ospDef);
    const qualQuestions = R.view(qualQuestionsLens, ospDef);
    const tooltipText =
      "This is the place where you will edit your company's organic systems plan";

    const dirtyWarningHtml = !isDirty ? null : (
      <div
        className="panel-body bg-danger text-danger text-center"
        style={{ padding: '.3em' }}
      >
        Changes to Plan Definition have not been saved!
      </div>
    );

    return (
      <Fragment>
        <RouterWillLeaveBlock blocked={isDirty} />
        <div className="panel panel-default">
          <div className="panel-heading">
            <div className="row">
              <div className="col-xs-6">
                <h2 style={{ margin: 0 }}>
                  Plan Editor <InfoTooltip overlay={tooltipText} />
                </h2>
              </div>
              <div>
                <div className="col-xs-6">
                  <div className="pull-right">
                    {canEditOspDef && (
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={onSaveClick}
                          disabled={!canEditOspDef}
                        >
                          Save
                        </button>{' '}
                        <Link to={acaOspBaseRoute(aca.id)} className="btn btn-danger">
                          Cancel
                        </Link>
                      </div>
                    )}
                    {!canEditOspDef && (
                      <Link to={acaOspBaseRoute(aca.id)} className="btn btn-primary">
                        Return to Plan Definitions
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {dirtyWarningHtml}
          <div
            className="panel-body"
            style={{
              borderLeft: `5px solid ${isDirty ? bgColorDanger : 'white'}`,
              borderRight: `5px solid ${isDirty ? bgColorDanger : 'white'}`,
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
          >
            {locationSectionIds ? (
              <Fragment>
                <h3>
                  Location Types{' '}
                  <DropdownButton
                    id="OspDefEditor-ActionsButtons--LocationSections"
                    title={<Glyphicon glyph="cog" />}
                    bsSize="xs"
                  >
                    <MenuItem onClick={onAddLocationType}>
                      <Glyphicon glyph="plus" /> Add Location Type
                    </MenuItem>
                  </DropdownButton>
                </h3>
                {stripNil(locationSectionIds).map(id => (
                  <OspSection key={id} ospSectionId={id} locationSection />
                ))}
              </Fragment>
            ) : (
              <Fragment>
                <h3>Location/Product Plan </h3>
                <OspSection miniOsp ospSectionId={landFacilitySectionId} />
                <OspSection miniOsp ospSectionId={landOfficeSectionId} />
                <OspSection miniOsp ospSectionId={landParcelSectionId} />
                <OspSection miniOsp ospSectionId={landPastureSectionId} />
                <OspSection
                  miniOsp
                  ospSectionId={productSectionId}
                  sectionType="product"
                />
                <MiniOspSection />
              </Fragment>
            )}

            <h3>
              Activities Checklist Questions{' '}
              <DropdownButton
                id="OspDefEditor-ActionsButtons--QualificationChecklistQuestions"
                title={<Glyphicon glyph="cog" />}
                bsSize="xs"
              >
                <MenuItem onClick={onAddRootQualificationQuestion}>
                  <Glyphicon glyph="plus" /> Add Root Activities Question
                </MenuItem>
              </DropdownButton>
            </h3>
            {qualQuestions &&
              stripNil(qualQuestions).map(id => (
                <QualificationQuestion
                  key={id}
                  ospQualificationQuestionId={id}
                  ospSectionIds={ospSectionIds}
                  rootQualificationQuestionIds={qualQuestions}
                />
              ))}

            <h3>
              Worksheet Sections{' '}
              <InfoTooltip overlay="Sections divide the Organic Systems Plan." />{' '}
              <DropdownButton
                id="OspDefEditor-ActionsButtons--WorksheetSections"
                title={<Glyphicon glyph="cog" />}
                bsSize="xs"
              >
                <MenuItem onClick={onAddSection}>
                  <Glyphicon glyph="plus" /> Add Section
                </MenuItem>
              </DropdownButton>
            </h3>
            {ospSectionIds &&
              stripNil(ospSectionIds).map(id => (
                <OspSection key={id} ospSectionId={id} />
              ))}
          </div>
          {dirtyWarningHtml}
        </div>
      </Fragment>
    );
  }
}

export default R.compose(connect(mapStateToProps, mapDispatchToProps))(OspDefEditor);
