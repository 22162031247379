import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as currentAca from 'sow/selectors/currentAca';
import AcaPageTemplate from 'sow/containers/AcaPageTemplate';
import AcaReportsPage from 'sow/components/pages/AcaReportsPage';

const mapStateToProps = (state, props) => ({
  aca: currentAca.aca(state, props),
  acaId: currentAca.acaId(state, props),
  title: 'Reports'
});

const AcaReportsPageContainer = ({ aca, acaId, title }) => {
  return (
    <AcaPageTemplate title={title}>
      {aca && <AcaReportsPage acaId={acaId} aca={aca} />}
    </AcaPageTemplate>
  );
};

AcaReportsPageContainer.propTypes = {
  aca: PropTypes.object,
  acaId: PropTypes.number,
  updateAca: PropTypes.func,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(AcaReportsPageContainer);
