export default function itemSubtype(state = {}, { type, payload }) {
  switch (type) {
    case 'ITEM_SUBTYPE/FETCH_BY_ID':
      return { ...state };

    case 'ITEM_SUBTYPE/FETCH_BY_ID_DONE':
    case 'ITEM_SUBTYPE/ADD_ITEM_SUBTYPE_DONE':
    case 'ITEM_SUBTYPE/UPDATE_ITEM_SUBTYPE_DONE':
      return { ...state, id: payload.id, name: payload.name };
    default:
      return state;
  }
}
