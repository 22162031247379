import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import * as sowTypes from 'sow/types';
import * as currentUser from 'sow/selectors/currentUser';
import { fromPlanApp } from 'sow/store/selectors';
import PlanAppExportContentWorksheetQuestion from 'sow/components/organisms/PlanAppExportContent/WorksheetQuestion';

const isChangeStateShown = R.pipe(
  R.path(['state']),
  R.contains(R.__, ['open', 'accepted', 'applied']),
);

const questionValue = createSelector(
  [
    fromPlanApp.questionId,
    fromPlanApp.formWorksheetInitialValues,
    fromPlanApp.questionChange,
  ],
  (questionId, worksheetInitialValues, questionChange) => {
    const value = R.path(
      ['worksheetAnswers', 'answers', 'values', questionId],
      worksheetInitialValues,
    );
    const changeValue = R.path(
      ['worksheetAnswersChanges', 'values', questionId],
      worksheetInitialValues,
    );

    const questionState = R.path(['state'], questionChange);

    const ignored = R.path(['ignored'], questionChange);

    // TODO in the future we can use a boolean prop to toggle use of change value
    if (questionChange && isChangeStateShown(questionChange) && !ignored) {
      if (questionState === 'open')
        return { questionValue: changeValue, pending: questionChange.action };
      else return { questionValue: changeValue, pending: false };
    }

    return { questionValue: value, pending: false };
  },
);

const locationQuestionValue = createSelector(
  [
    fromPlanApp.worksheetId,
    fromPlanApp.questionId,
    fromPlanApp.locationFormInitialValues,
    fromPlanApp.locationQuestionChange,
  ],
  (worksheetId, questionId, locationInitValues, questionChange) => {
    const value = R.path(
      ['answers', 'worksheets', worksheetId, 'answers', 'values', questionId],
      locationInitValues,
    );
    const changeValue = R.path(
      ['answersChanges', 'worksheets', worksheetId, 'answers', 'values', questionId],
      locationInitValues,
    );

    const questionState = R.path(['state'], questionChange);

    const ignored = R.path(['ignored'], questionChange);

    // TODO in the future we can use a boolean prop to toggle use of change value
    if (questionChange && isChangeStateShown(questionChange) && !ignored) {
      if (changeValue && questionState === 'open')
        return { questionValue: changeValue, pending: questionChange.action };
      if (changeValue) return { questionValue: changeValue, pending: false };
    }

    return { questionValue: value, pending: false };
  },
);

export const exportCurrentChange = createSelector(
  [
    fromPlanApp.currentLocationId,
    fromPlanApp.questionChange,
    fromPlanApp.locationQuestionChange,
  ],
  (locationId, change, locationChange) => {
    return R.isNil(locationId) ? change : locationChange;
  },
);

const exportCurrentChangeId = createSelector([exportCurrentChange], R.path(['id']));

export const exportQuestionChangeCommentList = createSelector(
  [fromPlanApp.questionChangeId, fromPlanApp.changeCommentEntity],
  (changeId, commentEntity) => {
    const isCurrentChange = R.propEq('changeId', changeId);
    const commentMap = R.pickBy(isCurrentChange, commentEntity);
    const commentList = R.values(commentMap);
    return commentList;
  },
);

const mapStateToProps = (state, props) => {
  const locationId = fromPlanApp.currentLocationId(state, props);

  return {
    question: fromPlanApp.question(state, props),
    changeRequest: fromPlanApp.changeRequest(state, props),
    changeRequestIsOpen: fromPlanApp.changeRequestIsOpen(state, props),
    changeId: exportCurrentChangeId(state, props),
    change: exportCurrentChange(state, props),
    commentList: exportQuestionChangeCommentList(state, props),
    noteList: fromPlanApp.notes(state, props),
    showAcaUI: currentUser.showAcaUI(state, props),
    value: R.isNil(locationId)
      ? questionValue(state, props)
      : locationQuestionValue(state, props),
    addNotes: fromPlanApp.exportConfigAddNotes(state, props),
    addComments: fromPlanApp.exportConfigAddComments(state, props),
  };
};

const PlanAppExportContentWorksheetQuestionContainer = ({ ...props }) => (
  <PlanAppExportContentWorksheetQuestion {...props} />
);

PlanAppExportContentWorksheetQuestionContainer.propTypes = {
  // Passed props
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  locationId: sowTypes.planAppLocationIdType,
  worksheetId: sowTypes.planAppWorksheetIdType.isRequired,
  questionId: sowTypes.planAppWorksheetQuestionIdType.isRequired,
  exportConfig: sowTypes.planAppExportConfigType,

  // Connected props
  changeId: sowTypes.planAppChangeIdType,
  question: sowTypes.planAppWorksheetQuestionType.isRequired,
  change: sowTypes.planAppChangeType,
  changeRequest: sowTypes.planAppChangeRequestType,
  changeRequestIsOpen: PropTypes.bool.isRequired,
  showAcaUI: PropTypes.bool.isRequired,
  noteList: sowTypes.planAppNoteListType.isRequired,
  addComments: PropTypes.bool,
  addNotes: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppExportContentWorksheetQuestionContainer);
