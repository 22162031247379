import React from 'react';
import PropTypes from 'prop-types';

import Atom from 'sow/components/atoms/Atom';
import List from 'sow/components/atoms/List';
import IconWithText from 'sow/components/molecules/IconWithText';
import SidebarLink from 'sow/components/molecules/SidebarLink';

const SidebarUnaffiliated = ({ navClasses }) => (
  <List className={navClasses}>
    <SidebarLink to="/">
      <IconWithText
        icon={<Atom className="fa fa-arrow-circle-o-left" />}
        text="Return to Dashboard"
      />
    </SidebarLink>
  </List>
);

SidebarUnaffiliated.propTypes = {
  navClasses: PropTypes.string,
};

export default SidebarUnaffiliated;
