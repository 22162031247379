import React from 'react';

import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Strong from 'sow/components/atoms/Strong';
import Block from 'sow/components/atoms/Block';
import { formatPhoneNumber } from 'sow/utils/formatPhoneNumber';
import { userSubtypeId } from 'sow/constants/userType';

const ContactInfoTable = ({ orgId, clientUsers, handleUnassign, actionColumn }) => (
  <Table
    className="contact-table"
    bordered
    head={
      <TableRow>
        <TableCell className="name-column">
          <Strong>Name:</Strong>
        </TableCell>
        <TableCell className="email-column">
          <Strong>Email:</Strong>
        </TableCell>
        <TableCell className="phone-column">
          <Strong>Phone Number:</Strong>
        </TableCell>
        <TableCell className="title-column">
          <Strong>Title:</Strong>
        </TableCell>
        <TableCell className="type-column">
          <Strong>Type:</Strong>
        </TableCell>
        {actionColumn && <TableCell className="action-column"></TableCell>}
      </TableRow>
    }
  >
    {clientUsers.length > 0 ? (
      clientUsers.map(user => (
        <TableRow
          key={user.id}
          className={
            user.userSubtypeId == userSubtypeId.primaryContact && 'primary-contact'
          }
        >
          <TableCell>{user.name}</TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell>{formatPhoneNumber(user.phoneNumber) || 'N/A'}</TableCell>
          <TableCell>{user.title}</TableCell>
          <TableCell>
            <Block>{user.userSubtype}</Block>
            {handleUnassign && user.userSubtypeId == userSubtypeId.primaryContact && (
              <a onClick={() => handleUnassign(user.userId)}>Unassign Contact</a>
            )}
          </TableCell>
          {actionColumn && actionColumn(orgId, user)}
        </TableRow>
      ))
    ) : (
      <TableRow>
        <TableCell colSpan={6} className="no-clients-assigned">
          <Strong>No contacts assigned.</Strong>
        </TableCell>
      </TableRow>
    )}
  </Table>
);

export default ContactInfoTable;
