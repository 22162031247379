import React from 'react';
import PropTypes from 'prop-types';

import { worksheetQuestionType } from 'sow/types';

import Atom from 'sow/components/atoms/Atom';
import Input from 'sow/components/atoms/Input';

const Checkbox = ({ question, answer, handleChange, disabled }) => (
  <Atom style={{ padding: '0 10px 0 16px' }}>
    <Input
      type="checkbox"
      checked={answer === true}
      onChange={() => handleChange(question.uuid, !answer)}
      disabled={disabled}
      style={{ marginTop: '-15px' }}
    />
  </Atom>
);

Checkbox.defaultProps = {
  answer: null,
  disabled: false,
};

Checkbox.propTypes = {
  question: worksheetQuestionType.isRequired,
  answer: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Checkbox;
