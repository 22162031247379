import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import OrganizationTypeListContainer from 'sow/containers/OrganizationTypeListContainer';

const OrganizationTypeListPage = () => (
  <AdminPageTemplate title="System Adminstrator">
    {() => <OrganizationTypeListContainer />}
  </AdminPageTemplate>
);

export default OrganizationTypeListPage;
