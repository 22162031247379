import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';

import { announcementsRoute } from 'sow/routes';

import * as announcementActions from 'sow/actions/pure/announcement';

import AnnouncementHistory from 'sow/components/organisms/AnnouncementHistory';

const mapStateToProps = (state, props) => ({
  announcementId: R.path(['match', 'params', 'id'], props),
});

const mapDispatchToProps = {
  getHistory: announcementActions.getAnnouncementHistory,
  redirect: routerPush,
};

class AnnouncementHistoryContainer extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = { isLoading: true, announcementHistory: [] };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.announcementId === 'new') {
      this.redirectToList();
    } else {
      this.props.getHistory(this.props.announcementId, this.updateState.bind(this));
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState({ data }) {
    if (this._isMounted) {
      this.setState({ isLoading: false, announcementHistory: [...data] });
    }
  }

  redirectToList() {
    this.props.redirect(announcementsRoute());
  }

  render() {
    return <AnnouncementHistory {...this.props} {...this.state} />;
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AnnouncementHistoryContainer);
