import React, { Fragment } from 'react';
import ReactDatetime from 'react-datetime';
import moment from 'moment';

import { formattedDate } from 'sow/utils/dateTime';

import Clearfix from 'sow/components/atoms/Clearfix';

const FormikDateRange = props => {
  const {
    id,
    disabled,
    dateFormat,
    setFieldValue,
    startDateID,
    endDateID,
    value,
  } = props;
  const checkStartDate = currentDate => {
    if (value[endDateID]) {
      currentDate = moment(currentDate);
      return currentDate.isSameOrBefore(moment(value[endDateID]));
    } else {
      return true;
    }
  };

  const checkEndDate = currentDate => {
    if (value[startDateID]) {
      currentDate = moment(currentDate);
      return currentDate.isSameOrAfter(moment(value[startDateID]));
    } else {
      return true;
    }
  };

  return (
    <Fragment>
      <ReactDatetime
        id={startDateID}
        timeFormat={false}
        dateFormat={dateFormat}
        closeOnSelect={true}
        onChange={event => {
          if (typeof event === 'object') {
            setFieldValue(id, {
              ...value,
              [startDateID]: formattedDate(event),
            });
          } else {
            setFieldValue(id, { ...value, [startDateID]: event });
          }
        }}
        isValidDate={checkStartDate}
        value={value[startDateID]}
        inputProps={{
          disabled: disabled,
          className: 'formik-date-range',
        }}
      />
      <ReactDatetime
        id={endDateID}
        timeFormat={false}
        dateFormat={dateFormat}
        closeOnSelect={true}
        onChange={event => {
          if (typeof event === 'object') {
            setFieldValue(id, {
              ...value,
              [endDateID]: formattedDate(event),
            });
          } else {
            setFieldValue(id, { ...value, [endDateID]: event });
          }
        }}
        isValidDate={checkEndDate}
        value={value[endDateID]}
        inputProps={{
          disabled: disabled,
          className: 'formik-date-range formik-date-range-end',
        }}
      />
      <Clearfix />
    </Fragment>
  );
};

export default FormikDateRange;
