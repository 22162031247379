import React from 'react';
import PropTypes from 'prop-types';

// import { profileRoute } from 'sow/routes';
// import Nav from 'sow/components/atoms/Nav';
// import NavLink from 'sow/components/molecules/NavLink';
import StandardLayout from 'sow/components/templates/StandardLayout';

const ProfilePageTemplate = ({ children }) => (
  <StandardLayout title="My Profile">
    {/*
    Why are there tabs here when there's only one page?
    <Nav tabs>
      <NavLink to={profileRoute()} exact>
        My Profile
      </NavLink>
    </Nav>
    */}

    {children}
  </StandardLayout>
);

ProfilePageTemplate.propTypes = {
  children: PropTypes.node,
};

export default ProfilePageTemplate;
