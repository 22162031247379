import React, { Fragment } from 'react';

import { orgContactsEditRoute } from 'sow/routes';
import Button from 'sow/components/atoms/Button';
import Block from 'sow/components/atoms/Block';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import TableCell from 'sow/components/atoms/TableCell';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import ContactAddUserModal from 'sow/components/organisms/ContactAddUserModal';
import Link from 'sow/components/atoms/Link';
import ContactInfoTable from 'sow/components/organisms/ContactInfoTable';

const ContactInfoNonstaff = ({
  toggleModal,
  modalProperties,
  isPrimaryContact,
  isConsultant,
  currentUser,
  orgId,
  ...props
}) => (
  <Widget className="general-contact-widget">
    <WidgetHeading>
      Contacts
      {isPrimaryContact && (
        <Fragment>
          <ContactAddUserModal {...modalProperties} />
          <Block pullRight>
            <Button onClick={toggleModal} primary sm>
              Add New User
            </Button>
          </Block>
        </Fragment>
      )}
    </WidgetHeading>
    <WidgetBody>
      <ContactInfoTable
        orgId={orgId}
        {...props}
        actionColumn={
          isPrimaryContact &&
          ((orgId, user) => (
            <TableCell>
              <FontAwesome icon="pencil-alt" className="edit-icon" />
              <Link to={orgContactsEditRoute(orgId, user.userId)}>Edit</Link>
            </TableCell>
          ))
        }
      />
    </WidgetBody>
  </Widget>
);

export default ContactInfoNonstaff;
