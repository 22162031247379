import React, { Fragment } from 'react';

import { API_URL } from 'sow/constants/api';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';
import Glyphicon from 'sow/components/molecules/Glyphicon';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import SelectFormGroup from 'sow/components/molecules/SelectFormGroup';
import InputFormGroup from 'sow/components/molecules/InputFormGroup';
import Button from 'sow/components/atoms/Button';
import Spinner from 'sow/components/atoms/Spinner';
import RadioButton from 'sow/components/atoms/RadioButton';
import FormGroup from 'sow/components/organisms/FormGroup';

const ProductSearchFilters = ({
  orgId,
  formValues,
  orgList,
  dataToExport,
  isSearching,
  exportList,
  handleSelectChange,
  handleMultiSelectChange,
  handleOptionChange,
  handleInputChange,
  handleSearch,
  handleReset,
  isFetchingOptions,
  filterOptions,
}) => (
  <Fragment>
    {isFetchingOptions ? (
      <Spinner />
    ) : (
      <Widget>
        <WidgetHeading>
          Product Search Filter
          {dataToExport && (
            <Block pullRight>
              <Link
                href={`${API_URL}/v1/product/product_search/export${exportList()}`}
                target="_blank"
                btn
                primary
                sm
              >
                <Glyphicon glyph="export" /> Export
              </Link>
            </Block>
          )}
        </WidgetHeading>
        <WidgetBody>
          <form onSubmit={handleSearch}>
            <Row className="search-filter-row">
              <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                <SelectFormGroup
                  fieldName="status"
                  label="Status"
                  options={filterOptions.status_options}
                  clearable={true}
                  onChange={handleSelectChange}
                  value={formValues.status}
                  placeholder="Select Status"
                />
              </Column>
              <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                <InputFormGroup
                  id="productName"
                  label="Product Name"
                  inputClassName="input-filter"
                  onChange={handleInputChange}
                  value={formValues.productName}
                />
              </Column>
              <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                <SelectFormGroup
                  fieldName="type"
                  label="Type"
                  options={filterOptions.type_options}
                  clearable={true}
                  multi={true}
                  onChange={target => handleMultiSelectChange('type', target)}
                  value={formValues.type}
                  placeholder="Select Type"
                />
              </Column>
              <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                <InputFormGroup
                  id="certStandard"
                  label="Certification Standard"
                  inputClassName="input-filter"
                  onChange={handleInputChange}
                  value={formValues.certStandard}
                />
              </Column>
              {!orgId && (
                <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                  <SelectFormGroup
                    fieldName="orgSelect"
                    label="Organization"
                    options={orgList}
                    clearable={true}
                    onChange={handleSelectChange}
                    value={formValues.orgSelect}
                    placeholder="Select Organization"
                  />
                </Column>
              )}
              <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                <InputFormGroup
                  id="nopId"
                  label="NOP ID"
                  inputClassName="input-filter"
                  onChange={handleInputChange}
                  value={formValues.nopId}
                />
              </Column>
              <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                <InputFormGroup
                  id="brandName"
                  label="Brand Name"
                  inputClassName="input-filter"
                  onChange={handleInputChange}
                  value={formValues.brandName}
                />
              </Column>
              {!orgId && (
                <Fragment>
                  <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                    <SelectFormGroup
                      fieldName="org_status"
                      label="Organization Status"
                      options={[
                        { value: true, label: 'Enabled' },
                        { value: false, label: 'Disabled' },
                      ]}
                      clearable={true}
                      onChange={handleSelectChange}
                      value={formValues.org_status}
                      placeholder="Select Organization Status"
                    />
                  </Column>
                  <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
                    <FormGroup fieldName="status" label="Only Active OSP Statuses">
                      <RadioButton
                        options={[
                          { value: true, label: 'Yes', stateName: 'active_statuses' },
                          { value: false, label: 'No', stateName: 'active_statuses' },
                        ]}
                        labelClassName="radio-label"
                        inputClassName="radio-input"
                        onChange={handleOptionChange}
                        currentState={formValues.active_statuses}
                      />
                    </FormGroup>
                  </Column>
                </Fragment>
              )}
            </Row>
            <Row className="search-filter-row">
              <Column
                className="search-filter-column button-column"
                xs={12}
                sm={12}
                md={4}
                lg={3}
              >
                <Row className="search-filter-row">
                  <Column
                    className="search-filter-column button-column"
                    xs={6}
                    sm={6}
                    md={6}
                  >
                    <Button
                      type="submit"
                      className="search-button filter-command-button"
                      disabled={isSearching}
                      primary
                    >
                      Search
                    </Button>
                  </Column>
                  <Column
                    className="search-filter-column button-column"
                    xs={6}
                    sm={6}
                    md={6}
                  >
                    <Button
                      type="button"
                      className="reset-button filter-command-button"
                      onClick={handleReset}
                      disabled={isSearching}
                      primary
                    >
                      Reset
                    </Button>
                  </Column>
                </Row>
              </Column>
            </Row>
          </form>
        </WidgetBody>
      </Widget>
    )}
  </Fragment>
);

export default ProductSearchFilters;
