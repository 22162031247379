import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as sowTypes from 'sow/types';
import {
  isStateOpen,
  isStateSubmitted,
  isStateWithdrawn,
  isUnderReview,
  isStateInitialApplication,
} from 'sow/utils/planAppState';
import { requestPasswordForNewAccount } from 'sow/actions/pure/resetPassword';
import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Tooltip from 'sow/components/molecules/Tooltip';
import Confirm from 'sow/components/molecules/Confirm';
import InformationIcon from 'sow/components/molecules/InformationIcon';

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  requestPasswordForNewAccount,
};

class OrgDashboardActionButtonsAca extends Component {
  render() {
    const {
      planApp,
      orgId,
      hasOpenPlanApp,
      planAppId,
      showAcaUI,
      showInspectorUI,
      isPlanAppIdMostRecent,
      changeRequestCanBeApplied,
      disabled,
      onComplete,
      onMarkNoncompliant,
      onWithdrawPlan,
      onUnwithdrawPlan,
      onRevokePlan,
      onSurrenderPlan,
      onSuspendPlan,
      requestPasswordForNewAccount,
      email,
    } = this.props;

    const showWithdrawButton = showAcaUI && isPlanAppIdMostRecent && isStateOpen(planApp);
    const showSurrenderButton =
      showAcaUI && isPlanAppIdMostRecent && isStateOpen(planApp);
    const showSuspendButton = showAcaUI && isPlanAppIdMostRecent && isStateOpen(planApp);
    const showRevokeButton = showAcaUI && isPlanAppIdMostRecent && isStateOpen(planApp);
    const showSendWelcomeEmailButton = showAcaUI && isStateInitialApplication(planApp);

    const showUnwithdrawButton =
      showAcaUI && isPlanAppIdMostRecent && isStateWithdrawn(planApp);

    const showMarkNoncompliantButton =
      (showAcaUI || showInspectorUI) &&
      isPlanAppIdMostRecent &&
      (isStateSubmitted(planApp) || isUnderReview(planApp));

    const showMarkCompleteButton =
      (showAcaUI || showInspectorUI) && isUnderReview(planApp);
    const disableMarkCompleteButton = disabled || !changeRequestCanBeApplied;

    const showSeparator = showMarkNoncompliantButton || showMarkCompleteButton;

    const handleSubmitSuccess = () => {
      console.log('submitted');
    };

    const onSendWelcomeEmail = () => {
      requestPasswordForNewAccount(email, handleSubmitSuccess);
    };

    return (
      <Block>
        {showMarkCompleteButton && (
          <Confirm
            onConfirm={onComplete}
            message="All changes marked accepted will be applied to this application and overwrite their corresponding old answer, and the application will be marked complete. If there are any changes that have not been accepted or discarded, a new plan will be created with those pending changes."
          >
            {toggleConfirm => {
              const button = (
                <Fragment>
                  <Button
                    block
                    onClick={disableMarkCompleteButton ? () => {} : toggleConfirm}
                    className={
                      disableMarkCompleteButton
                        ? 'disabled org-sidebar-button'
                        : 'org-sidebar-button'
                    }
                  >
                    <FontAwesome
                      className="sidebar-button-icon"
                      variant="far"
                      icon="check-circle"
                    />
                    Mark Plan Complete
                    <InformationIcon className="sidebar-button-info" overlay="test" />
                  </Button>
                  <HorizontalRule className="sidebar-button-divider" />
                </Fragment>
              );

              if (disableMarkCompleteButton) {
                return (
                  <Tooltip overlay="Before a plan can be completed, a change request must exist and the plan must be in the Final Review state.">
                    <Block>{button}</Block>
                  </Tooltip>
                );
              }
              return button;
            }}
          </Confirm>
        )}

        {/* {showSeparator && (
          // <HorizontalRule style={{ marginTop: '5px', marginBottom: '5px' }} />
        )} */}

        {showSendWelcomeEmailButton && (
          <Confirm
            message="Are you ready to send a welcome email to the client?"
            onConfirm={onSendWelcomeEmail}
          >
            {toggleConfirm => (
              <Fragment>
                <Button
                  className="org-sidebar-button"
                  block
                  onClick={toggleConfirm}
                  disabled={disabled}
                >
                  <FontAwesome className="sidebar-button-icon" icon="envelope" />
                  Send Welcome Email
                  <InformationIcon className="sidebar-button-info" overlay="test" />
                </Button>
                <HorizontalRule className="sidebar-button-divider" />
              </Fragment>
            )}
          </Confirm>
        )}

        {showWithdrawButton && (
          <Confirm
            message="This plan will be marked as Withdrawn. The organization will be shown a message to contact their certifier if they wish to unlock and update the plan for resubmission."
            onConfirm={onWithdrawPlan}
          >
            {toggleConfirm => (
              <Fragment>
                <Button
                  className="org-sidebar-button"
                  block
                  onClick={toggleConfirm}
                  disabled={disabled}
                >
                  <FontAwesome className="sidebar-button-icon" icon="sign-out-alt" />
                  Withdraw Plan
                  <InformationIcon className="sidebar-button-info" overlay="test" />
                </Button>
                <HorizontalRule className="sidebar-button-divider" />
              </Fragment>
            )}
          </Confirm>
        )}

        {showRevokeButton && (
          <Confirm
            message="This plan will be marked as Revoked. The organization will not be able to unlock or update the plan for resubmission."
            onConfirm={onRevokePlan}
          >
            {toggleConfirm => (
              <Fragment>
                <Button
                  className="org-sidebar-button"
                  block
                  onClick={toggleConfirm}
                  disabled={disabled}
                >
                  <FontAwesome className="sidebar-button-icon" icon="undo" />
                  Revoke Plan
                  <InformationIcon className="sidebar-button-info" overlay="test" />
                </Button>
                <HorizontalRule className="sidebar-button-divider" />
              </Fragment>
            )}
          </Confirm>
        )}

        {showSurrenderButton && (
          <Confirm
            message="This plan will be marked as Surrendered. The organization will not be able to unlock or update the plan for resubmission."
            onConfirm={onSurrenderPlan}
          >
            {toggleConfirm => (
              <Fragment>
                <Button
                  className="org-sidebar-button"
                  block
                  onClick={toggleConfirm}
                  disabled={disabled}
                >
                  <FontAwesome
                    variant="far"
                    className="sidebar-button-icon"
                    icon="flag"
                  />
                  Surrender Plan
                  <InformationIcon className="sidebar-button-info" overlay="test" />
                </Button>
                <HorizontalRule className="sidebar-button-divider" />
              </Fragment>
            )}
          </Confirm>
        )}

        {showSuspendButton && (
          <Confirm
            message="This plan will be marked as Suspended. The organization will not be able to unlock or update the plan for resubmission."
            onConfirm={onSuspendPlan}
          >
            {toggleConfirm => (
              <Fragment>
                <Button
                  className="org-sidebar-button"
                  block
                  onClick={toggleConfirm}
                  disabled={disabled}
                >
                  <FontAwesome className="sidebar-button-icon" icon="ban" />
                  Suspend Plan
                  <InformationIcon className="sidebar-button-info" overlay="test" />
                </Button>
                <HorizontalRule className="sidebar-button-divider" />
              </Fragment>
            )}
          </Confirm>
        )}

        {showUnwithdrawButton && (
          <Fragment>
            <Button
              className="org-sidebar-button"
              block
              onClick={onUnwithdrawPlan}
              disabled={disabled}
            >
              <FontAwesome className="sidebar-button-icon" icon="sign-in-alt" />
              Unwithdraw Plan
              <InformationIcon className="sidebar-button-info" overlay="test" />
            </Button>
            <HorizontalRule className="sidebar-button-divider" />
          </Fragment>
        )}

        {/* Can be deleted if new method of noncompliance is accepted
        {showMarkNoncompliantButton && (
          <Confirm
            message="This plan will be marked as Non-compliance. The organization will have the option to unlock the plan for update and make changes to bring the plan into compliance."
            onConfirm={onMarkNoncompliant}
          >
            {toggleConfirm => (
              <Fragment>
                <Button
                  className="org-sidebar-button"
                  block
                  onClick={toggleConfirm}
                  disabled={disabled}
                >
                  <FontAwesome
                    style={{ marginRight: '5%', fontSize: '15pt' }}
                    icon="times"
                  />
                  Mark Plan Noncompliant
                </Button>
                <HorizontalRule style={{ margin: '0' }} />
              </Fragment>
            )}
          </Confirm>
        )} */}
      </Block>
    );
  }
}

OrgDashboardActionButtonsAca.propTypes = {
  planApp: sowTypes.planAppType.isRequired,
  changeRequestCanBeApplied: PropTypes.bool.isRequired,
  hasOpenPlanApp: PropTypes.bool.isRequired,
  isPlanAppIdMostRecent: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  showAcaUI: PropTypes.bool,
  showInspectorUI: PropTypes.bool,
  onComplete: PropTypes.func.isRequired,
  onMarkNoncompliant: PropTypes.func.isRequired,
  onWithdrawPlan: PropTypes.func.isRequired,
  onUnwithdrawPlan: PropTypes.func.isRequired,
  onRevokePlan: PropTypes.func.isRequired,
  onSurrenderPlan: PropTypes.func.isRequired,
  onSuspendPlan: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrgDashboardActionButtonsAca);
