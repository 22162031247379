import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import CertifiedSpecialistList from 'sow/components/organisms/CertifiedSpecialistList';

const mapStateToProps = state => ({
  dashboardList: state.dashboard.items,
  priorityTypes: state.dashboard.priorityTypes,
  isFetchingDashboard: state.dashboard.isFetching,
});

class SpecialistDashboardTableContainer extends Component {
  render() {
    return <CertifiedSpecialistList {...this.props} />;
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, null),
)(SpecialistDashboardTableContainer);
