import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import AnnouncementTypeListContainer from 'sow/containers/AnnouncementTypeListContainer';

const AnnouncementTypePage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <AnnouncementTypeListContainer />}
  </AdminPageTemplate>
);

export default AnnouncementTypePage;
