import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'ramda';

import { loadOspDefForAca } from 'sow/actions/ospDefinition';
import * as currentAca from 'sow/selectors/currentAca';
import * as currentOspDef from 'sow/selectors/currentOspDef';
import OspDefEditor from 'sow/components/old/OspDefEditor';
import Spinner from 'sow/components/atoms/Spinner';
import AcaPageTemplate from '../AcaPageTemplate';
import OspDefListLoader from 'sow/containers/OspDefListLoader';

const mapDispatchToProps = { loadOspDefForAca };

const mapStateToProps = (state, props) => ({
  isLoading: currentOspDef.isLoadingOspDef(state, props),
  acaId: currentAca.acaId(state, props),
  ospDef: currentOspDef.ospDef(state, props),
  ospDefId: currentOspDef.ospDefId(state, props),
});

class ContainerOspDef extends Component {
  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const { loadOspDefForAca, acaId, ospDefId } = this.props;

    if (acaId && ospDefId) {
      loadOspDefForAca(acaId, ospDefId);
    }
  }

  render() {
    const { isLoading, ospDef } = this.props;

    const isLoadingOspDef = isLoading || !ospDef;

    return (
      <AcaPageTemplate title="Plan Definition Editor">
        <OspDefListLoader />
        {isLoadingOspDef ? <Spinner /> : <OspDefEditor />}
      </AcaPageTemplate>
    );
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ContainerOspDef);
