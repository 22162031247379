import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { fetchPending, fetchNewRegistrations, fetchInProgress } from 'sow/actions/aca';
import { fromRouter } from 'sow/store/selectors';
import AcaPageTemplate from 'sow/containers/AcaPageTemplate';
import AcaDashboardPage from 'sow/components/pages/AcaDashboardPage';
import Spinner from 'sow/components/atoms/Spinner';

const sortByDate = R.sortWith([
  R.descend(R.path(['last_activity_at'])),
  R.ascend(R.path(['organization', 'name'])),
]);

const mapStateToProps = (state, props) => ({
  acaId: fromRouter.paramAcaId(state, props),
  isLoadingPendingList: state.acaPendingList.isLoading,
  isLoadingInProgressList: state.acaInProgressList.isLoading,
  isLoadingNewRegistrationsList: state.acaNewRegistrationsList.isLoading,
  pendingList: state.acaPendingList.items,
  newRegistrationsList: state.acaNewRegistrationsList.items,
  inProgressList: state.acaInProgressList.items
    ? sortByDate(state.acaInProgressList.items)
    : null,
});

const mapDispatchToProps = {
  fetchPending,
  fetchNewRegistrations,
  fetchInProgress,
};

class AcaDashboardPageContainer extends Component {
  componentDidMount() {
    this.loadPendingList(this.props.acaId);
    this.loadNewRegistrationsList(this.props.acaId);
    this.loadInProgressList(this.props.acaId);
  }

  loadPendingList(acaId) {
    if (acaId && !this.props.isLoadingPendingList) {
      this.props.fetchPending(acaId);
    }
  }

  loadNewRegistrationsList(acaId) {
    if (acaId && !this.props.isLoadingNewRegistrationsList) {
      this.props.fetchNewRegistrations(acaId);
    }
  }

  loadInProgressList(acaId) {
    if (acaId && !this.props.isLoadingInProgressList) {
      this.props.fetchInProgress(acaId);
    }
  }

  showLoading() {
    if (
      !this.props.acaId ||
      (this.props.isLoadingPendingList || !this.props.pendingList) ||
      (this.props.isLoadingNewRegistrationsList || !this.props.newRegistrationsList) ||
      (this.props.isLoadingInProgressList || !this.props.inProgressList)
    ) {
      return true;
    }

    return false;
  }

  render() {
    const { pendingList, inProgressList, newRegistrationsList } = this.props;

    return (
      <AcaPageTemplate title="Certifier Dashboard - Sow">
        {this.showLoading() ? (
          <Spinner />
        ) : (
          <AcaDashboardPage
            pendingList={pendingList}
            newRegistrationsList={newRegistrationsList}
            inProgressList={inProgressList}
          />
        )}
      </AcaPageTemplate>
    );
  }
}

AcaDashboardPageContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  acaId: PropTypes.number,
  isLoadingPendingList: PropTypes.bool,
  isLoadingNewRegistrationsList: PropTypes.bool,
  isLoadingInProgressList: PropTypes.bool,
  pendingList: PropTypes.array,
  newRegistrationsList: PropTypes.array,
  inProgressList: PropTypes.array,
  fetchPending: PropTypes.func,
  fetchNewRegistrations: PropTypes.func,
  fetchInProgress: PropTypes.func,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AcaDashboardPageContainer);
