import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as adminRequestActions from 'sow/actions/adminRequest';
import * as sowTypes from 'sow/types';
import { fromPlanApp, fromRouter } from 'sow/store/selectors';
import {
  PlanAppLoader,
  PlanAppWorksheetAnswersLoader,
  PlanAppChangeLoader,
} from 'sow/store/containers';
import OrgPlanAppPageTemplate from 'sow/containers/OrgPlanAppPageTemplate';
import ResourceLoaderStatusRender from 'sow/components/molecules/ResourceLoaderStatusRender';
import OrgPlanAppWorksheetOverviewPage from 'sow/components/pages/OrgPlanAppWorksheetOverviewPage';

const mapStateToProps = (state, props) => ({
  orgId: fromRouter.paramOrgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  qualifiedWorksheetIds: fromPlanApp.qualifiedWorksheetIds(state, props),
  worksheetSections: fromPlanApp.qualifiedWorksheetSections(state, props),
  isStateUnderReview: fromPlanApp.isStateUnderReview(state, props),
  adminRequests: state.adminRequests,
  requestsFetching: state.adminRequests.worksheetRequests.isFetching,
  requestsLoaded: state.adminRequests.worksheetRequests.isLoaded,
});

const mapDispatchToProps = {
  fetchAdminRequests: adminRequestActions.fetchAdminRequests,
};

const OrgPlanAppWorksheetOverviewPageContainer = ({
  orgId,
  planAppId,
  qualifiedWorksheetIds,
  changeRequestId,
  worksheetSections,
  fetchAdminRequests,
  requestsLoaded,
  requestsFetching,
  adminRequests,
  isStateUnderReview,
}) => {
  const loadAdminRequests = () => {
    if (
      (!requestsLoaded && !requestsFetching) ||
      (requestsLoaded && adminRequests.planAppId != planAppId)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const loadRequests = loadAdminRequests();

  return (
    <OrgPlanAppPageTemplate title="Worksheets">
      <PlanAppLoader orgId={orgId} planAppId={planAppId} autoLoad>
        {({ status: planAppStatus }) => (
          <PlanAppWorksheetAnswersLoader autoLoad>
            {({ status: answersStatus }) => (
              <PlanAppChangeLoader autoLoad={!R.isNil(changeRequestId)}>
                {({ status: chStatus }) => {
                  const changeStatus =
                    changeRequestId && !chStatus.initial
                      ? chStatus
                      : { ...chStatus, error: false, done: true };
                  return (
                    <ResourceLoaderStatusRender
                      statusObjects={[planAppStatus, answersStatus, changeStatus]}
                      renderAllDone={() => {
                        if (planAppStatus.success && loadRequests) {
                          fetchAdminRequests(orgId, planAppId);
                        }
                        return (
                          planAppStatus.success && (
                            <OrgPlanAppWorksheetOverviewPage
                              orgId={orgId}
                              planAppId={planAppId}
                              worksheetSections={worksheetSections}
                              qualifiedWorksheetIds={qualifiedWorksheetIds}
                              isStateUnderReview={isStateUnderReview}
                            />
                          )
                        );
                      }}
                    />
                  );
                }}
              </PlanAppChangeLoader>
            )}
          </PlanAppWorksheetAnswersLoader>
        )}
      </PlanAppLoader>
    </OrgPlanAppPageTemplate>
  );
};

OrgPlanAppWorksheetOverviewPageContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  qualifiedWorksheetIds: PropTypes.arrayOf(sowTypes.planAppWorksheetIdType),
  worksheetSections: PropTypes.arrayOf(sowTypes.planAppWorksheetSectionType),
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrgPlanAppWorksheetOverviewPageContainer);
