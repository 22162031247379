import * as R from 'ramda';
import { createActions } from 'redux-actions';

const actionCreators = createActions({
  OSP_APP: {
    RESET: R.identity,

    SET_CURRENT_FOR_ORG: (orgId, ospAppId) => ({
      orgId,
      ospAppId,
    }),

    // LOAD_ONE: orgId => ({ orgId }),
    // LOAD_ONE_DONE: R.identity,
    // LOAD_ONE_FAIL: R.identity,

    LOAD_ORG_LIST: orgId => ({ orgId }),
    LOAD_ORG_LIST_DONE: R.identity,
    LOAD_ORG_LIST_FAIL: R.identity,

    // LOAD_ACA_LIST: acaId => ({ acaId }),
    // LOAD_ACA_LIST_DONE: R.identity,
    // LOAD_ACA_LIST_FAIL: R.identity,

    // UPDATE_ONE: [org => ({ org }), (org, onSuccess) => ({ onSuccess })],
    // UPDATE_ONE_DONE: R.identity,
    // UPDATE_ONE_FAIL: R.identity,

    CHANGE_INSPECTION_DATE: (orgId, planAppId, inspectionDate, onSuccess) => ({
      orgId,
      planAppId,
      inspectionDate,
      onSuccess,
    }),
  },
});

export default actionCreators.ospApp;
