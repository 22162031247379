import React from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import PropTypes from 'prop-types';
import moment from 'moment';

import { planAppWorksheetQuestionType } from 'sow/types';
import ErrorBoundary from 'sow/components/ErrorBoundary';
import * as checkboxListAV from 'sow/components/organisms/WorksheetField/CheckboxList/answerVersions';
import PendingAsterisk from 'sow/components/molecules/PendingAsterisk';

const BoolAnswerRenderer = ({ value, question }) => {
  try {
    if (value === false) return 'No';
    if (value === true) return 'Yes';
  } catch (error) {
    console.error('Export failed to render question answer', { error, value, question });
  }
  return '';
};

BoolAnswerRenderer.propTypes = {
  value: PropTypes.any,
  question: planAppWorksheetQuestionType.isRequired,
};

const TextAnswerRenderer = ({ value, question }) => {
  try {
    if (RA.isString(value)) return value;
  } catch (error) {
    console.error('Export failed to render question answer', { error, value, question });
  }

  return '';
};

TextAnswerRenderer.propTypes = {
  value: PropTypes.any,
  question: planAppWorksheetQuestionType.isRequired,
};

const SelectAnswerRenderer = ({ ...props }) => {
  return RA.isObjLike(props.value) ? (
    <CheckboxListAnswerRenderer {...props} />
  ) : (
    <TextAnswerRenderer {...props} />
  );
};

SelectAnswerRenderer.propTypes = {
  value: PropTypes.any,
  question: planAppWorksheetQuestionType.isRequired,
};

const CheckboxListAnswerRenderer = ({ value, question }) => {
  try {
    if (Array.isArray(value)) {
      return value.join(', ');
    }

    const valueFormatVerion = R.path(['__version'], value);

    if (valueFormatVerion == 1) {
      const answers = R.defaultTo([], R.path(['answers'], question));
      const yesAnswers = answers.filter(R.propEq(R.__, true, value));
      return yesAnswers.join(', ');
    }

    if (valueFormatVerion == 2) {
      const options = checkboxListAV.getOptions(question);
      const answer = checkboxListAV.getAnswer(value, options);
      const yesAnswers = checkboxListAV.getCheckedItems(answer);
      return yesAnswers.join(', ');
    }
  } catch (error) {
    console.error('Export failed to render question answer', { error, value, question });
  }

  return '';
};

CheckboxListAnswerRenderer.propTypes = {
  value: PropTypes.any,
  question: planAppWorksheetQuestionType.isRequired,
};

const DateAnswerRenderer = ({ value, question }) => {
  try {
    return value ? moment(value).format('L') : '';
  } catch (e) {
    console.error('Error formatting date for matrix cell', e);
  }

  return '';
};

DateAnswerRenderer.propTypes = {
  value: PropTypes.any,
  question: planAppWorksheetQuestionType.isRequired,
};

const FilesAnswerRenderer = ({ value, question }) => {
  try {
    if (value) {
      return R.pipe(R.pluck('name'), R.join(', '))(value);
    }
  } catch (error) {
    console.error('Export failed to render question answer', { error, value, question });
  }

  return '';
};

FilesAnswerRenderer.propTypes = {
  value: PropTypes.any,
  question: planAppWorksheetQuestionType.isRequired,
};

const WorksheetAnswer = ({ value, question, pending, locationPending }) => {
  const fieldProps = { value, question };

  const deleted = pending === 'deleted' || locationPending;
  const updated = pending === 'updated' || pending === 'added';

  if (R.isNil(value) || R.isEmpty(value)) return 'N/A';

  switch (question.fieldType) {
    case 'descriptive_text':
    case 'number':
    case 'text':
    case 'textarea':
      return (
        <PendingAsterisk deleted={deleted} updated={updated}>
          <TextAnswerRenderer {...fieldProps} />
        </PendingAsterisk>
      );

    case 'bool':
      return (
        <PendingAsterisk deleted={deleted} updated={updated}>
          <BoolAnswerRenderer {...fieldProps} />
        </PendingAsterisk>
      );

    case 'checkbox_list':
      return (
        <PendingAsterisk deleted={deleted} updated={updated}>
          <CheckboxListAnswerRenderer {...fieldProps} />
        </PendingAsterisk>
      );

    case 'date':
      return (
        <PendingAsterisk deleted={deleted} updated={updated}>
          <DateAnswerRenderer {...fieldProps} />
        </PendingAsterisk>
      );

    case 'files':
      return (
        <PendingAsterisk deleted={deleted} updated={updated}>
          <FilesAnswerRenderer {...fieldProps} />
        </PendingAsterisk>
      );

    case 'select':
      return (
        <PendingAsterisk deleted={deleted} updated={updated}>
          <SelectAnswerRenderer {...fieldProps} />
        </PendingAsterisk>
      );

    default:
      return '-- (Unknown field type!) --';
  }
};

WorksheetAnswer.propTypes = {
  value: PropTypes.any,
  question: planAppWorksheetQuestionType.isRequired,
};

const EmptyStringFallbackComponent = () => '';

const WorksheetAnswerSafe = ({ ...props }) => (
  <ErrorBoundary FallbackComponent={EmptyStringFallbackComponent}>
    <WorksheetAnswer {...props} />
  </ErrorBoundary>
);

export default WorksheetAnswerSafe;
