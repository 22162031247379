import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Block from 'sow/components/atoms/Block';
import Clearfix from 'sow/components/atoms/Clearfix';

const Panel = ({
  className,
  children,
  footer,
  heading,
  title,
  noBody,

  // contexts
  primary,
  success,
  info,
  warning,
  danger,

  ...props
}) => {
  // Color context class
  let colorClass = 'panel-default';
  if (primary) colorClass = 'panel-primary';
  if (success) colorClass = 'panel-success';
  if (info) colorClass = 'panel-info';
  if (warning) colorClass = 'panel-warning';
  if (danger) colorClass = 'panel-danger';

  const containerClasses = classnames(className, 'panel', colorClass);

  return (
    <Block {...props} className={containerClasses}>
      {(heading || title) && (
        <Block className="panel-heading">
          {heading}
          {title && <Block className="panel-title">{title}</Block>}
          {heading && <Clearfix />}
        </Block>
      )}
      {children && (noBody ? children : <Block className="panel-body">{children}</Block>)}
      {footer && <Block className="panel-footer">{footer}</Block>}
    </Block>
  );
};

Panel.defaultProps = {
  noBody: false,
};

Panel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  heading: PropTypes.node,
  footer: PropTypes.node,
  title: PropTypes.node,
  noBody: PropTypes.bool,

  // contexts
  primary: PropTypes.bool,
  success: PropTypes.bool,
  info: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
};

export default Panel;
