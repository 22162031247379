import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import * as sowTypes from 'sow/types';
import Block from 'sow/components/atoms/Block';
import PlanAppChange from 'sow/containers/planApp/Change';
import WorksheetBaseQuestion from 'sow/components/organisms/WorksheetBaseQuestion';
import PlanAppWorksheetCreateBaseQuestionChangeButton from 'sow/containers/planApp/WorksheetCreateBaseQuestionChangeButton';

const WorksheetBaseQuestionChange = ({
  orgId,
  planAppId,
  worksheetId,
  changeRequestId,
  worksheetChange,
  isPlanAppLocked,
  changeRequestIsOpen,
  isSubmitting,
  changeUIDisabled,
  showAcaUI,
}) => {
  const worksheetChangeId = R.pathOr(null, ['id'], worksheetChange);

  return (
    <Block>
      <PlanAppChange
        changeId={worksheetChangeId}
        showChangeUI={changeRequestIsOpen}
        changeUIDisabled={changeUIDisabled}
        renderAnswer={() => (
          <WorksheetBaseQuestion
            name="worksheetAnswers.isNotApplicable"
            text="This worksheet is not applicable."
            fieldType="checkbox"
            orgId={orgId}
            ospId={planAppId}
            worksheetId={worksheetId}
            disabled={isPlanAppLocked || isSubmitting}
            showAcaUI={showAcaUI}
          >
            {changeRequestIsOpen && !worksheetChange && (
              <Field
                name="worksheetAnswers.isNotApplicable"
                changeFieldName="worksheetAnswersChanges.isNotApplicable"
                component={PlanAppWorksheetCreateBaseQuestionChangeButton}
                orgId={orgId}
                planAppId={planAppId}
                changeRequestId={changeRequestId}
                worksheetId={worksheetId}
                showAcaUI={showAcaUI}
                disabled={changeUIDisabled}
              />
            )}
          </WorksheetBaseQuestion>
        )}
        renderChange={change => (
          <WorksheetBaseQuestion
            name="worksheetAnswersChanges.isNotApplicable"
            text="This worksheet is not applicable."
            fieldType="checkbox"
            orgId={orgId}
            ospId={planAppId}
            worksheetId={worksheetId}
            disabled={!changeRequestIsOpen || isSubmitting}
          />
        )}
      />
    </Block>
  );
};

WorksheetBaseQuestionChange.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheetId: sowTypes.worksheetIdType.isRequired,
  changeRequestId: sowTypes.planAppChangeRequestIdType,
  worksheetChange: sowTypes.planAppChangeType,
  isPlanAppLocked: PropTypes.bool.isRequired,
  changeRequestIsOpen: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  changeUIDisabled: PropTypes.bool.isRequired,
  showAcaUI: PropTypes.bool.isRequired,
};

WorksheetBaseQuestionChange.defaultProps = {
  worksheetChange: null,
  changeRequestId: null,
};

export default WorksheetBaseQuestionChange;
