import React from 'react';

import FormGroup from 'sow/components/organisms/FormGroup';

const RegistrationForm = ({ user, handleChange, errors }) => (
  <div>
    <FormGroup fieldName="name" errors={errors} label="Name" required>
      <input
        type="text"
        id="name"
        value={user.name}
        onChange={e => handleChange('name', e.target.value)}
        className="form-control"
      />
    </FormGroup>

    <FormGroup fieldName="email" errors={errors} label="Email address" required>
      <input
        type="text"
        id="email"
        value={user.email}
        onChange={e => handleChange('email', e.target.value)}
        className="form-control"
      />
    </FormGroup>

    <FormGroup fieldName="phoneNumber" errors={errors} label="Phone Number" required>
      <input
        type="text"
        id="phoneNumber"
        value={user.phoneNumber}
        onChange={e => handleChange(['settings', 'primary_phone_number'], e.target.value)}
        className="form-control"
      />
    </FormGroup>
  </div>
);

export default RegistrationForm;
