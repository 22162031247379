import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import WorksheetQuestionChange from 'sow/containers/planApp/WorksheetQuestionChange';

const mapStateToProps = (state, props) => ({
  locationId: fromPlanApp.locationId(state, props),
  changeUIDisabled: fromPlanApp.isDeletedLocation(state, props),
  isPlanAppLocked: fromPlanApp.isPlanAppLocked(state, props),
});

const LocationWorksheetQuestionContainer = ({
  locationId,
  worksheetId,
  questionId,
  changeUIDisabled,
  isPlanAppLocked,
}) => {
  return (
    <WorksheetQuestionChange
      key={questionId}
      locationId={locationId}
      worksheetId={worksheetId}
      questionId={questionId}
      baseAnswersName={`answers.worksheets.${worksheetId}.answers.values.`}
      baseAnswersChangesName={`answersChanges.worksheets.${worksheetId}.answers.values.`}
      disabled={isPlanAppLocked}
      changeUIDisabled={changeUIDisabled}
    />
  );
};

LocationWorksheetQuestionContainer.propTypes = {
  // Passed props
  worksheetId: sowTypes.planAppWorksheetIdType.isRequired,
  questionId: sowTypes.worksheetQuestionIdType.isRequired,

  // Connected props
  locationId: sowTypes.planAppLocationIdType.isRequired,
  changeUIDisabled: PropTypes.bool.isRequired,
  isPlanAppLocked: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(LocationWorksheetQuestionContainer);
