import { API_URL } from 'sow/constants/api';
import { apiRequest } from 'sow/utils/api';

export const baseUrl = `${API_URL}/v1/aca`;
export const getBaseUrlForAca = acaId => `${API_URL}/v1/aca/${acaId}`;

export const fetchAll = () =>
  apiRequest({
    type: 'GET',
    url: baseUrl,
  });

export const fetchAllRegister = () =>
  apiRequest({
    type: 'GET',
    url: `${baseUrl}/register`,
  });

export const fetchOne = acaId =>
  apiRequest({
    type: 'GET',
    url: `${baseUrl}/${acaId}`,
  });

export const fetchTimezoneList = () =>
  apiRequest({
    method: 'GET',
    url: `${baseUrl}/timezones`,
  });

export const updateOne = (acaId, data) =>
  apiRequest({
    method: 'PUT',
    url: `${baseUrl}/${acaId}`,
    data: { aca: data },
  });

export const fetchPendingList = acaId =>
  apiRequest({
    method: 'GET',
    url: `${getBaseUrlForAca(acaId)}/pending`,
  });

export const fetchNewRegistrations = acaId =>
  apiRequest({
    method: 'GET',
    url: `${getBaseUrlForAca(acaId)}/new_registrations`,
  });

export const fetchInProgress = acaId =>
  apiRequest({
    method: 'GET',
    url: `${getBaseUrlForAca(acaId)}/application_statuses`,
  });
