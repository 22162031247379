export function hasAnyRole(userRoles = [], allowedRoles = []) {
  if (!userRoles) return false;

  for (let i in allowedRoles) {
    if (userRoles.indexOf(allowedRoles[i]) > -1) {
      return true;
    }
  }

  return false;
}

/* DO NOT USE

// import store from 'sow/store';
// import { getAuthState, isAuthenticated } from 'sow/reducers/auth';

const locationAction = R.path(['location', 'action']);

// usage: <Route path="/foo" component={Foo} onEnter={secured(['admin'])} />
export function secured(allowedRoles) {
  return function(nextState, replace) {
    const state = store.getState();
    const { roles } = getAuthState(state);
    // console.log('getAuthState = ', state, getAuthState(state));
    // console.log('isAuthenticated(state)', isAuthenticated(state));
    // console.log('location', locationAction(nextState));

    // console.log('Route secured:', {
    //   nextState,
    //   replace,
    //   ...nextState.location.query,
    //   redirect: nextState.location.pathname,
    // });

    // TODO - move all this logic into a saga... :(
    if (locationAction(nextState) === 'POP') {
      // return;
    }

    if (!isAuthenticated(state)) {
      if (nextState.location.pathname === '/auth/login') {
        return;
      }

      replace({
        pathname: '/auth/login',
        query: {
          ...nextState.location.query,
          redirect: nextState.location.pathname,
        },
      });
      return;
    }

    // Check user roles
    // 'admin' role has access to everything
    if (!hasAnyRole(roles, ['admin', ...allowedRoles])) {
      console.log('Forbidden', roles, allowedRoles);
      replace('/error/forbidden');
      return;
    }
  };
}
*/
