import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  PLAN_APP_STATES_UNLOCKED_FOR_ORG,
  planAppStateText,
} from 'sow/constants/planApp';
import { utcStrToLocalMoment } from 'sow/utils/dateTime';
import { orgRoute } from 'sow/routes';
import DataTable from 'sow/components/molecules/DataTable';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Link from 'sow/components/atoms/Link';
import TruncatedText from 'sow/components/molecules/TruncatedText';

const isAppUnlocked = state => !PLAN_APP_STATES_UNLOCKED_FOR_ORG.includes(state);

const InProgressRow = ({ osp }) => {
  const stateClasses = classnames({
    warning: isAppUnlocked(osp.application.state),
  });

  return (
    <TableRow>
      <TableCell xs={3} textCenter valignMiddle className={stateClasses}>
        {planAppStateText[osp.application.state]}
      </TableCell>
      <TableCell xs={6} textCenter valignMiddle>
        <Link to={`${orgRoute(osp.organization.id)}`}>
          <TruncatedText text={osp.organization.name} maxLength={45} />
        </Link>
      </TableCell>
      <TableCell xs={3} valignMiddle textCenter>
        {utcStrToLocalMoment(osp.last_activity_at).format('L LT')}
      </TableCell>
    </TableRow>
  );
};

InProgressRow.propTypes = {
  osp: PropTypes.object.isRequired,
};

const NoInProgress = () => (
  <TableRow>
    <TableCell colSpan={3} textCenter textInfo>
      No applications in progress
    </TableCell>
  </TableRow>
);

const OspAppInProgress = props => {
  return (
    <DataTable
      selector={'.inProgressTable'}
      tableDef={{
        pageLength: 50,
        destroy: true,
        columnDefs: [{ type: 'date', targets: 2 }],
      }}
    >
      <Table
        className={
          props.inProgressList.length > 0
            ? 'dashboardTable dataTable inProgressTable'
            : 'dashboardTable inProgressTable'
        }
        bordered
        condensed
        striped
        head={
          <TableRow>
            <TableCell heading textCenter>
              State
            </TableCell>
            <TableCell heading textCenter>
              Organization
            </TableCell>
            <TableCell heading textCenter>
              Last Changed
            </TableCell>
          </TableRow>
        }
      >
        {!props.inProgressList.length && <NoInProgress />}
        {props.inProgressList.map(osp => (
          <InProgressRow key={osp.application.id} osp={osp} />
        ))}
      </Table>
    </DataTable>
  );
};

OspAppInProgress.propTypes = {
  inProgressList: PropTypes.array,
};

export default OspAppInProgress;
