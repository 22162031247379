import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';

import Button from 'sow/components/atoms/Button';
import FormGroup from 'sow/components/organisms/FormGroup';
import Input from 'sow/components/atoms/Input';
import Paragraph from 'sow/components/atoms/Paragraph';

class UpdateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      missMatch: false,
    };
  }

  handleInputChange({ target }) {
    this.setState({ [target.id]: target.value, missMatch: false });
  }

  onClose() {
    this.setState({ inputOne: '', inputTwo: '', missMatch: false });
    this.props.onHide();
  }

  onSaveHandler(inputOne, inputTwo) {
    if (inputOne === inputTwo && inputOne.length > 0) {
      this.props.onModalSave(this.props.type[0], inputOne);
      this.setState({
        inputOne: '',
        inputTwo: '',
      });
    } else {
      this.setState({ missMatch: true });
    }
  }

  render() {
    const { type } = this.props;

    return (
      <Fragment>
        <Modal backdrop="static" show={this.props.show} onHide={() => this.onClose()}>
          <Modal.Header closeButton>Change {type[1]}</Modal.Header>
          <Modal.Body>
            <FormGroup fieldName={type[0]} label={type[1]} required>
              <Input
                id="inputOne"
                type={type[0]}
                value={this.state.inputOne}
                onChange={event => this.handleInputChange(event)}
              ></Input>
            </FormGroup>
            <FormGroup
              fieldName="password_confirmation"
              label={`Confirm ${type[1]}`}
              required
            >
              <Input
                id="inputTwo"
                type={type[0]}
                value={this.state.inputTwo}
                onChange={event => this.handleInputChange(event)}
              ></Input>
            </FormGroup>
            {this.state.missMatch && <Paragraph>{type[1]}s do not match</Paragraph>}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.onClose()}>Cancel</Button>
            <Button
              primary
              onClick={() => this.onSaveHandler(this.state.inputOne, this.state.inputTwo)}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default UpdateModal;
