import * as ospDef from 'sow/actions/pure/ospDefinition';
import * as ospDefAction from 'sow/actions/ospDefinition';

import { allQuestionIds } from '../helpers';

const ids = (state = [], action) => {
  switch (action.type) {
    case ospDefAction.LOAD_ONE_FOR_ACA_SUCCESS:
    // case ospDef.SAVE_ONE_FOR_ACA_SUCCESS:
    case ospDef.UPDATE_ONE_DONE:
      return allQuestionIds(action);

    default:
      return state;
  }
};

export default ids;
