import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';

import { announcementsRoute } from 'sow/routes';
import { accessTypeConstants } from 'sow/constants/announcementShowFor';

import * as announcementActions from 'sow/actions/pure/announcement';
import * as announcementTypeActions from 'sow/actions/pure/announcementType';

import AnnouncementsEdit from 'sow/components/organisms/AnnouncementsEdit';

const mapDispatchToProps = {
  loadAnnouncementById: announcementActions.loadAnnouncementById,
  addAnnouncement: announcementActions.addAnnouncement,
  updateAnnouncement: announcementActions.updateAnnouncement,
  deleteAnnouncement: announcementActions.deleteAnnouncement,
  loadAnnouncementTypeOptions: announcementTypeActions.loadAnnouncementTypes,
  loadShowForOptions: announcementActions.loadShowForOptions,
  redirect: routerPush,
};

const initialValues = {
  title: '',
  announcement_type_id: 0,
  start_on: '',
  message: '',
  enabled: false,
  showForRows: [{ announcement_show_id: 'new', access_type_id: 1 }],
};

class AnnouncementsEditContainer extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = {
      isNew: false,
      isLoading: true,
      showForRows: [],
      initialValues: R.clone(initialValues),
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.props.loadAnnouncementTypeOptions(
      { enabled: true, options: true },
      this.loadShowForOptions.bind(this),
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadShowForOptions({ data }) {
    if (this._isMounted) {
      this.setState({ announcementTypes: data.announcement_types });
      this.props.loadShowForOptions(this.loadAnnouncement.bind(this));
    }
  }

  loadAnnouncement({ data }) {
    if (this._isMounted) {
      const routeId = R.path(['match', 'params', 'id'], this.props);

      this.setState({
        announcementId: routeId,
        groupOptions: {
          actionGroupOptions: data.action_group_options,
          organizationTypeOptions: data.organization_type_options,
        },
        accessTypeOptions: data.access_type_options,
      });

      if (routeId !== 'new') {
        this.props.loadAnnouncementById(routeId, this.updateState.bind(this));
      } else {
        this.setState({
          isNew: true,
          isLoading: false,
        });
      }
    }
  }

  updateState({ data }) {
    if (this.state.isNew) {
      history.pushState(null, null, `${data.id}`);
    }
    if (this._isMounted) {
      if (!R.isEmpty(data)) {
        this.setState({
          isNew: false,
          isLoading: false,
          announcementId: data.id,
          initialValues: {
            ...data,
            showForRows: data.announcement_show ? [...data.announcement_show] : [],
          },
        });
      } else {
        this.redirectToList();
      }
    }
  }

  redirectToList() {
    this.props.redirect(announcementsRoute());
  }

  handleSave(values, formikBag) {
    if (this.state.isNew) {
      this.props.addAnnouncement(values, this.updateState.bind(this));
    } else {
      this.props.updateAnnouncement(
        this.state.announcementId,
        values,
        this.updateState.bind(this),
      );
    }
  }

  handleDelete() {
    this.props.deleteAnnouncement(
      this.state.announcementId,
      this.redirectToList.bind(this),
    );
  }

  addShowTypeRow(showForRows, setFieldValue) {
    showForRows.push({
      announcement_show_id: 'new',
      access_type_id: accessTypeConstants.everyone,
    });
    setFieldValue('showForRows', showForRows);
  }

  removeShowTypeRow(index, showForRows, setFieldValue) {
    showForRows.splice(index, 1);
    setFieldValue('showForRows', showForRows);
  }

  render() {
    return (
      <AnnouncementsEdit
        handleSave={this.handleSave.bind(this)}
        handleDelete={this.handleDelete.bind(this)}
        addShowTypeRow={this.addShowTypeRow.bind(this)}
        removeShowTypeRow={this.removeShowTypeRow.bind(this)}
        {...this.state}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(AnnouncementsEditContainer);
