import React from 'react';
import ReactDatetime from 'react-datetime';
import * as R from 'ramda';
import moment from 'moment';

const checkDate = (currentDate, selectedDate) => {
  if (selectedDate) {
    if (R.has('start', selectedDate))
      return currentDate.isSameOrAfter(moment(selectedDate.start));
    else if (R.has('end', selectedDate))
      return currentDate.isSameOrBefore(moment(selectedDate.end));
  }
  return true;
};

const FormikDate = ({
  disabled,
  dateFormat,
  setFieldValue,
  value,
  name,
  className,
  selectedDate,
}) => (
  <ReactDatetime
    timeFormat={false}
    dateFormat={dateFormat}
    closeOnSelect={true}
    onChange={event => {
      if (typeof event === 'object') {
        setFieldValue(name, event.format(dateFormat));
      } else {
        setFieldValue(name, event);
      }
    }}
    value={value}
    isValidDate={currentDate => checkDate(currentDate, selectedDate)}
    inputProps={{ disabled: disabled, className: className }}
  />
);

export default FormikDate;
