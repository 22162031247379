import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';
import ChangeUnreadNotificationsIcon from 'sow/components/organisms/WorksheetMatrix/ChangeUnreadNotificationsIcon';

const mapStateToProps = (state, props) => {
  const commentList = fromPlanApp.changeCommentList(state, props);
  return {
    hasComments: R.length(commentList) > 0,
    hasCommentNotifications: fromPlanApp.questionHasCommentNotifications(state, props),
  };
};

const ChangeUnreadNotificationsIconContainer = ({
  hasComments,
  hasCommentNotifications,
}) => (
  <ChangeUnreadNotificationsIcon
    hasComments={hasComments}
    hasCommentNotifications={hasCommentNotifications}
  />
);

ChangeUnreadNotificationsIconContainer.propTypes = {
  // Passed props
  changeId: sowTypes.planAppChangeIdType.isRequired,

  // Connected props
  hasComments: PropTypes.bool.isRequired,
  hasCommentNotifications: PropTypes.bool.isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(ChangeUnreadNotificationsIconContainer);
