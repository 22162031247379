import * as R from 'ramda';
import { v4 as guid } from 'uuid';

import { moveListItemAway, moveListItemCloser } from 'sow/utils/data';

import { INIT_STATE } from './OspDefEditor';
import {
  LAND_FACILITY_WORKSHEET_ADD,
  LAND_OFFICE_WORKSHEET_ADD,
  LAND_PARCEL_WORKSHEET_ADD,
  LAND_PASTURE_WORKSHEET_ADD,
  PRODUCT_WORKSHEET_ADD,
} from './ospDef';
import { WORKSHEET_ADD, WORKSHEET_REMOVE } from './ospSections';

// Actions
export const QUESTION_ADD = 'OspDefEditor/worksheets/QUESTION_ADD';
export const QUESTION_MOVE_DOWN = 'OspDefEditor/worksheets/QUESTION_MOVE_DOWN';
export const QUESTION_MOVE_UP = 'OspDefEditor/worksheets/QUESTION_MOVE_UP';
export const QUESTION_REMOVE = 'OspDefEditor/worksheets/QUESTION_REMOVE';
export const REGULATION_ADD = 'OspDefEditor/worksheets/REGULATION_ADD';
export const REGULATION_MOVE_DOWN = 'OspDefEditor/worksheets/REGULATION_MOVE_DOWN';
export const REGULATION_MOVE_UP = 'OspDefEditor/worksheets/REGULATION_MOVE_UP';
export const REGULATION_REMOVE = 'OspDefEditor/worksheets/REGULATION_REMOVE';
export const REGULATION_SET_VALUE = 'OspDefEditor/worksheets/REGULATION_SET_VALUE';
export const SET_VALUE = 'OspDefEditor/worksheets/SET_VALUE';

// entity defauts/creators
export const getQuestionDefaults = () => ({
  uuid: guid(),
  tooltip: '',
  scopes: [],
  notes: '',
  name: '',
  field_type: 'text',
  answers: [],
});

export const getRegulationDefaults = () => ({
  uuid: guid(),
  code: '',
  description: '',
  url: '',
});

export const getWorksheetDefaults = () => ({
  uuid: guid(),
  name: 'New Worksheet',
  questions: [],
  not_applicable: false,
  matrix: false,
  header: '',
  regulations: [],
});

export function createQuestion(defaultValues = {}) {
  return R.merge(getQuestionDefaults(), defaultValues);
}

export function createRegulation(defaultValues = {}) {
  return R.merge(getRegulationDefaults(), defaultValues);
}

export function createWorksheet(defaultValues = {}) {
  return R.merge(getWorksheetDefaults(), defaultValues);
}

// Reducer functions
export const reduceWorksheetAdd = (state = {}, action = {}) => {
  const { worksheet } = action.data;
  const worksheetId = worksheet.uuid;
  return R.assoc(worksheetId, worksheet, state);
};

export const reduceWorksheetRemove = (state = {}, action = {}) => {
  return R.dissoc(action.data.worksheetId, state);
};

export const reduceWorksheetSetValue = (state = {}, action = {}) => {
  const { worksheetId, field, value } = action.data;
  return R.assocPath([worksheetId, field], value, state);
};

export const reduceQuestionAdd = (state = {}, action = {}) => {
  const { question, worksheetId } = action.data;
  const questionId = question.uuid;
  const lens = R.lensPath([worksheetId, 'questions']);
  return R.over(lens, R.append(questionId), state);
};

export const reduceQuestionMoveDown = (state = {}, action = {}) => {
  const { questionId, worksheetId } = action.data;
  const lens = R.lensPath([worksheetId, 'questions']);
  const all = R.view(lens, state);
  const count = R.length(all);
  const idx = R.findIndex(R.equals(questionId), all);

  // already last item so do nothing
  if (idx >= count - 1) return state;

  return R.over(lens, moveListItemAway(idx), state);
};

export const reduceQuestionMoveUp = (state = {}, action = {}) => {
  const { questionId, worksheetId } = action.data;
  const lens = R.lensPath([worksheetId, 'questions']);
  const all = R.view(lens, state);
  const count = R.length(all);
  const idx = R.findIndex(R.equals(questionId), all);

  // already first item so do nothing
  if (idx < 1) return state;

  return R.over(lens, moveListItemCloser(idx), state);
};

export const reduceQuestionRemove = (state = {}, action = {}) => {
  const { questionId, worksheetId } = action.data;
  const lens = R.lensPath([worksheetId, 'questions']);
  const pred = R.equals(questionId);
  return R.over(lens, R.reject(pred), state);
};

export const reduceRegulationAdd = (state = {}, action = {}) => {
  const { regulation, worksheetId } = action.data;
  const lens = R.lensPath([worksheetId, 'regulations']);
  return R.over(lens, R.append(regulation), state);
};

export const reduceRegulationMoveDown = (state = {}, action = {}) => {
  const { regulationId, worksheetId } = action.data;
  const lens = R.lensPath([worksheetId, 'regulations']);
  const all = R.view(lens, state);
  const count = R.length(all);
  const idx = R.findIndex(R.propEq('uuid', regulationId), all);

  // already last item so do nothing
  if (idx >= count - 1) return state;

  return R.over(lens, moveListItemAway(idx), state);
};

export const reduceRegulationMoveUp = (state = {}, action = {}) => {
  const { regulationId, worksheetId } = action.data;
  const lens = R.lensPath([worksheetId, 'regulations']);
  const all = R.view(lens, state);
  const count = R.length(all);
  const idx = R.findIndex(R.propEq('uuid', regulationId), all);

  // already first item so do nothing
  if (idx < 1) return state;

  return R.over(lens, moveListItemCloser(idx), state);
};

export const reduceRegulationRemove = (state = {}, action = {}) => {
  const { regulationId, worksheetId } = action.data;
  const lens = R.lensPath([worksheetId, 'regulations']);
  const pred = R.whereEq({ uuid: regulationId });
  return R.over(lens, R.reject(pred), state);
};

export const reduceRegulationSetValue = (state = {}, action = {}) => {
  const lens = R.lensPath([action.data.worksheetId, 'regulations']);
  const pred = R.whereEq({ uuid: action.data.regulationId });
  const op = R.assoc(action.data.field, action.data.value);
  return R.over(lens, R.map(R.when(pred, op)), state);
};

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case INIT_STATE:
      return R.path(['payload', 'worksheets'], action);
    // WORKSHEET
    case LAND_FACILITY_WORKSHEET_ADD:
    case LAND_OFFICE_WORKSHEET_ADD:
    case LAND_PARCEL_WORKSHEET_ADD:
    case LAND_PASTURE_WORKSHEET_ADD:
    case PRODUCT_WORKSHEET_ADD:
    case WORKSHEET_ADD:
      return reduceWorksheetAdd(state, action);

    case WORKSHEET_REMOVE:
      return reduceWorksheetRemove(state, action);

    case SET_VALUE:
      return reduceWorksheetSetValue(state, action);
    // QUESTIONS
    case QUESTION_ADD:
      return reduceQuestionAdd(state, action);

    case QUESTION_MOVE_DOWN:
      return reduceQuestionMoveDown(state, action);

    case QUESTION_MOVE_UP:
      return reduceQuestionMoveUp(state, action);

    case QUESTION_REMOVE:
      return reduceQuestionRemove(state, action);
    // REGULATIONS
    case REGULATION_ADD:
      return reduceRegulationAdd(state, action);

    case REGULATION_MOVE_DOWN:
      return reduceRegulationMoveDown(state, action);

    case REGULATION_MOVE_UP:
      return reduceRegulationMoveUp(state, action);

    case REGULATION_REMOVE:
      return reduceRegulationRemove(state, action);

    case REGULATION_SET_VALUE:
      return reduceRegulationSetValue(state, action);

    default:
      return state;
  }
}

// Action Creators
export function questionAdd(worksheetId) {
  const question = createQuestion();
  return { type: QUESTION_ADD, data: { worksheetId, question } };
}

export function questionMoveDown(worksheetId, questionId) {
  return { type: QUESTION_MOVE_DOWN, data: { worksheetId, questionId } };
}

export function questionMoveUp(worksheetId, questionId) {
  return { type: QUESTION_MOVE_UP, data: { worksheetId, questionId } };
}

export function questionRemove(worksheetId, questionId) {
  return { type: QUESTION_REMOVE, data: { worksheetId, questionId } };
}

export function regulationAdd(worksheetId) {
  const regulation = createRegulation();
  return { type: REGULATION_ADD, data: { worksheetId, regulation } };
}

export function regulationMoveDown(worksheetId, regulationId) {
  return { type: REGULATION_MOVE_DOWN, data: { worksheetId, regulationId } };
}

export function regulationMoveUp(worksheetId, regulationId) {
  return { type: REGULATION_MOVE_UP, data: { worksheetId, regulationId } };
}

export function regulationRemove(worksheetId, regulationId) {
  return { type: REGULATION_REMOVE, data: { worksheetId, regulationId } };
}

export function regulationSetValue(worksheetId, regulationId, field, value) {
  return {
    type: REGULATION_SET_VALUE,
    data: { worksheetId, regulationId, field, value },
  };
}

export function setValue(worksheetId, field, value) {
  return { type: SET_VALUE, data: { worksheetId, field, value } };
}

// Action Creator Export Map
export const actions = {
  setValue,

  // questions
  questionAdd,
  questionMoveDown,
  questionMoveUp,
  questionRemove,

  // regulations
  regulationAdd,
  regulationMoveDown,
  regulationMoveUp,
  regulationRemove,
  regulationSetValue,
};
