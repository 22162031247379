import React from 'react';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

import './ChangeList.module.css';

const ChangeList = ({ minHeight, height, changeList, children }) => {
  if (changeList.length === 0) return children(null, null, true);
  if (changeList.length < 8) {
    return changeList.map((change, index) => (
      <div key={index}>{children(null, change)}</div>
    ));
  }
  const cache = new CellMeasurerCache({
    minHeight: minHeight,
    fixedWidth: true,
  });

  const rowRenderer = ({ index, style, parent }) => {
    return (
      <CellMeasurer
        key={index}
        cache={cache}
        columnIndex={0}
        parent={parent}
        rowIndex={index}
      >
        {({ measure }) => {
          return (
            <div key={index} style={style}>
              {children(measure, changeList[index])}
            </div>
          );
        }}
      </CellMeasurer>
    );
  };

  return (
    <AutoSizer disableHeight>
      {({ width }) => {
        return (
          <List
            className="change-list-scroll"
            rowCount={changeList.length}
            height={height}
            deferredMeasurementCache={cache}
            rowRenderer={rowRenderer}
            width={width}
            rowHeight={cache.rowHeight}
          />
        );
      }}
    </AutoSizer>
  );
};

export default ChangeList;
