import React from 'react';

const OspDefState = ({ stateName, colorContext }) =>
  <div style={{ marginBottom: 0 }} className={`panel panel-${colorContext}`}>
    <div className="panel-heading">
      <b>
        {stateName}
      </b>
    </div>
  </div>;

export default OspDefState;
