import React, { Component, Fragment } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';

import { fetchDashboardList, fetchDashboardCounts } from 'sow/actions/orgWorkflow';
import { fetchCertificationSpecialistList } from 'sow/actions/acaAdmin';
import AcaPageTemplate from 'sow/containers/AcaPageTemplate';
import OrgWorkflowDashboardPage from 'sow/components/pages/OrgWorkflowDashboardPage';
import CertifiedSpecialistList from 'sow/components/organisms/CertifiedSpecialistList';
import WorkflowManagerList from 'sow/components/organisms/WorkflowManagerList';
import Spinner from 'sow/components/atoms/Spinner';
import * as currentUser from 'sow/selectors/currentUser';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import * as currentAca from 'sow/selectors/currentAca';
import DashboardFilterCounts from 'sow/components/organisms/DashboardFilterCounts';
import { setFilter, getFilter } from 'sow/utils/dashboardFilterStorage';
import SpecialistAssignBlock from 'sow/containers/SpecialistAssignBlock';

const mapStateToProps = (state, props) => ({
  isDashboardFetching: state.dashboard.isFetching,
  isDashboardCountsFetching: state.dashboard.countsIsFetching,
  dashboardList: state.dashboard.items,
  dashboardCounts: state.dashboard.counts,
  isWorkflowManager: currentUser.isWorkflowManager(state, props),
  isCertificationSpecialist: currentUser.isCertificationSpecialist(state, props),
  aca: currentAca.aca(state, props),
  acaId: currentAca.acaId(state, props),
  isAcaAdminsFetching: state.acaAdminList.isFetching,
  acaAdminList: state.acaAdminList.items,
});

const mapDispatchToProps = {
  fetchDashboardList,
  fetchDashboardCounts,
  fetchCertificationSpecialistList,
};

function debounce(a, b, c) {
  let d, e;
  return function() {
    function h() {
      (d = null), c || (e = a.apply(f, g));
    }
    let f = this,
      g = arguments;
    return clearTimeout(d), (d = setTimeout(h, b)), c && !d && (e = a.apply(f, g)), e;
  };
}

class OrgWorkflowDashboardPageContainer extends Component {
  state = {
    searchText: '',
    searchTerm: '',
    sortOrder: [],
    loadSpecialistList: true,
    inactiveList: [],
    forceLoader: false,
  };

  componentDidMount() {
    if (getFilter() === null) {
      setFilter('all');
    }
    this.loadDashboard(this.props.acaId, getFilter());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.loadDashboard(this.props.acaId, getFilter());
    }

    if (!this.props.isDashboardFetching && this.state.forceLoader) {
      this.setState({ forceLoader: false });
    }

    if (
      this.props.acaId &&
      this.props.isWorkflowManager &&
      !this.props.isCertifiedSpecialistListFetching
    ) {
      this.loadCertifiedSpecialists(this.props.acaId);
    }
  }

  loadDashboard(acaId, filter) {
    if (!this.props.isDashboardFetching) {
      this.props.fetchDashboardList(acaId, filter);
    }
    if (!this.props.isDashboardCountsFetching) {
      this.props.fetchDashboardCounts(acaId);
    }
  }

  loadCertifiedSpecialists(acaId) {
    const filter = {
      only_specialists: true,
    };

    if (
      this.state.loadSpecialistList &&
      acaId &&
      !this.props.isCertifiedSpecialistListFetching
    ) {
      this.props.fetchCertificationSpecialistList(acaId, filter);
      this.setState({ loadSpecialistList: false });
    }
  }

  handleSearchTextChange = event => {
    event.preventDefault();
    this.setState(R.assoc('searchText', event.target.value));
    this.setSearchTerm(event.target.value);
  };

  handleFilterChange = filter => {
    this.setState({ forceLoader: true });
    this.resetSearchTerm();
    setFilter(filter);
    this.loadDashboard(this.props.acaId, getFilter());
  };

  resetSearchTerm() {
    this.setState({
      searchTerm: '',
      searchText: '',
    });
  }

  setSearchTerm = debounce(searchTerm => {
    this.setState({ searchTerm });
  }, 750);

  filterDashboardList = (options, filter) => {
    if (!filter) return options;

    const fuseOptions = {
      shouldSort: true,
      threshold: 0.2,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['organization_name'],
    };
    const fuse = new Fuse(options, fuseOptions);
    return fuse.search(filter);
  };

  filterDashboardCounts = () => {
    const { dashboardCounts } = this.props;
    const results = this.searchResults();
    const filter = getFilter();
    const counts = R.clone(dashboardCounts);
    const filterKeys = {
      all: 'all',
      expedited: 'expedited',
      new: 'is_new',
      renewal: 'is_renewal',
      not_started: 'not_started',
    };
    if (counts != null && filterKeys[filter] != null) {
      counts[filterKeys[filter]] = results.length;
      return counts;
    } else {
      return dashboardCounts;
    }
  };

  searchResults = () => {
    const { dashboardList } = this.props;
    const { searchTerm } = this.state;
    return this.filterDashboardList(dashboardList, searchTerm);
  };

  showLoading() {
    if (this.state.forceLoader) return true;
    if (
      !this.props.acaId ||
      this.props.isDashboardFetching ||
      !this.props.dashboardList
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { isWorkflowManager } = this.props;
    const { searchText } = this.state;
    const title = isWorkflowManager
      ? 'Workflow Manager Dashboard'
      : 'Certification Specialist Dashboard';
    const searchPlaceholder = 'Client';

    return (
      <AcaPageTemplate title={title}>
        {this.showLoading() ? (
          <Spinner />
        ) : (
          <OrgWorkflowDashboardPage
            searchTextChange={this.handleSearchTextChange}
            searchText={searchText}
            placeholder={searchPlaceholder}
          >
            <Row>
              <DashboardFilterCounts
                dashboardCounts={this.filterDashboardCounts()}
                handleFilterChange={this.handleFilterChange}
                filter={getFilter()}
              />
            </Row>
            <Row>
              <Column xs={12} sm={12}>
                {isWorkflowManager ? (
                  <Fragment>
                    <SpecialistAssignBlock dashboardList={this.props.dashboardList} />
                    <WorkflowManagerList dashboardList={this.searchResults()} />
                  </Fragment>
                ) : (
                  <Fragment>
                    <CertifiedSpecialistList dashboardList={this.searchResults()} />
                  </Fragment>
                )}
              </Column>
            </Row>
          </OrgWorkflowDashboardPage>
        )}
      </AcaPageTemplate>
    );
  }
}

OrgWorkflowDashboardPageContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  isDashboardFetching: PropTypes.bool,
  isDashboardCountsFetching: PropTypes.bool,
  fetchDashboardList: PropTypes.func,
  fetchDashboardCounts: PropTypes.func,
  isCertifiedSpecialistListFetching: PropTypes.bool,
  fetchCertificationSpecialistList: PropTypes.func,
  dashboardList: PropTypes.array,
  dashboardCounts: PropTypes.object,
  certificationSpecialistList: PropTypes.array,
  acaId: PropTypes.number,
  isWorkflowManager: PropTypes.bool,
  isCertificationSpecialist: PropTypes.bool,
  location: PropTypes.object,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrgWorkflowDashboardPageContainer);
