import { makeImmutable, getIn } from 'sow/utils/data';
import {
  MATRIX_ROW_CREATE,
  MATRIX_ROW_DELETE,
  MATRIX_ROW_UNDELETE,
  MATRIX_ROW_SET_ANSWER,
  SAVE_ONE_WORKSHEET_SUCCESS,
  SET_ANSWER,
  SET_BASE_ANSWER,
} from 'sow/actions/worksheetAnswers';
import actions from 'sow/actions/pure';

import byWorksheetId from './byWorksheetId';

const mapKeyData = action => action.data.ospAppId;
const mapKeyPayload = action => action.payload.ospAppId;

const mapReducer = (state = makeImmutable({}), action) =>
  state.merge({
    byWorksheetId: byWorksheetId(state.byWorksheetId, action),
  });

const byOspAppId = (state = makeImmutable({}), action) => {
  switch (action.type) {
    // Old actions using `data` key
    case MATRIX_ROW_CREATE:
    case MATRIX_ROW_DELETE:
    case MATRIX_ROW_UNDELETE:
    case MATRIX_ROW_SET_ANSWER:
    case SET_ANSWER:
    case SET_BASE_ANSWER:
    case SAVE_ONE_WORKSHEET_SUCCESS:
      return state.updateIn([mapKeyData(action)], mapReducer, action);

    default:
      return state;
  }
};

export default byOspAppId;
