import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/** Renders a `p` tag */
const Paragraph = ({
  children,
  className,
  textLeft,
  textCenter,
  textRight,
  textMuted,
  ...props
}) => {
  const classes = classNames(className, {
    'text-left': textLeft,
    'text-center': textCenter,
    'text-right': textRight,
    'text-muted': textMuted,
  });

  return (
    <p className={classes} {...props}>
      {children}
    </p>
  );
};

Paragraph.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  textLeft: PropTypes.bool,
  textCenter: PropTypes.bool,
  textRight: PropTypes.bool,
  textMuted: PropTypes.bool,
};

export default Paragraph;
