import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { acaType } from 'sow/types';
import Block from 'sow/components/atoms/Block';
import Input from 'sow/components/atoms/Input';
import AcaLogo from 'sow/components/molecules/AcaLogo';
import FormGroup from 'sow/components/organisms/FormGroup';
import TimezoneSelector from 'sow/components/molecules/TimezoneSelector';
import UploadLogoModal from 'sow/components/organisms/AcaUploadLogoModal';

class AcaForm extends React.Component {
  state = {
    isLogoUploading: false,
  };

  handleLogoUploadStart = () => {
    this.setState(R.assoc('isLogoUploading', true));
  };

  handleLogoUploadFinish = () => {
    this.setState(R.assoc('isLogoUploading', false));
  };

  render() {
    const { aca, errors, onChange } = this.props;
    const { isLogoUploading } = this.state;

    return (
      <Block>
        <FormGroup fieldName="name" errors={errors} label="Name" required>
          <Input
            id="name"
            value={aca.name}
            onChange={e => onChange('name', e.target.value)}
            required
          />
        </FormGroup>

        <FormGroup fieldName="timezone" errors={errors} label="Time Zone" required>
          <TimezoneSelector
            value={aca.timezone}
            onChange={value => onChange('timezone', value)}
          />
        </FormGroup>

        <FormGroup fieldName="logo" errors={errors} label="Logo">
          <Block style={{ margin: 5, maxWidth: 750 }}>
            <AcaLogo
              acaId={aca.id}
              hidden={isLogoUploading}
              className="img-responsive img-thumbnail"
              style={{
                backgroundColor: '#2a1b0c',
                padding: '1.5em',
              }}
              title={`${aca.name} Logo`}
            />
          </Block>
          <UploadLogoModal
            aca={aca}
            handleChange={value => onChange('logo_url', value)}
            onUploadStart={this.handleLogoUploadStart}
            onUploadFinish={this.handleLogoUploadFinish}
          />
        </FormGroup>
      </Block>
    );
  }
}

AcaForm.propTypes = {
  aca: acaType.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default AcaForm;
