import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { urlSubtypeId } from 'sow/constants/userType';
import { fetchDashboardList, fetchDashboardCounts } from 'sow/actions/orgWorkflow';
import { setFilter } from 'sow/utils/dashboardFilterStorage';
import * as currentAca from 'sow/selectors/currentAca';

import SpecialistDashboardTemplate from 'sow/components/templates/SpecialistDashboardTemplate';

const mapStateToProps = (state, props) => ({
  acaId: currentAca.acaId(state, props),
  fetchingDashboardCounts: state.dashboard.isFetchingCounts,
});

const mapDispatchToProps = { fetchDashboardList, fetchDashboardCounts };

class SpecialistDashboardTemplateContainer extends Component {
  componentDidMount() {
    if (R.pathEq(['match', 'params', 'clientFilter'], 'all')(this.props)) {
      this.loadDashboardCounts();
    } else {
      this.tabFilter(this.props.match.params.clientFilter);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.clientFilter !== this.props.match.params.clientFilter) {
      if (R.pathEq(['match', 'params', 'clientFilter'], 'all')(this.props)) {
        this.loadDashboardCounts();
      } else {
        this.tabFilter(this.props.match.params.clientFilter);
      }
    }
  }

  componentWillUnmount() {
    this.resetButtonFilter();
  }

  resetButtonFilter() {
    setFilter('all');
  }

  loadDashboardCounts() {
    this.props.fetchDashboardCounts(this.props.acaId);
  }

  tabFilter(clientFilter) {
    this.props.fetchDashboardCounts(
      this.props.acaId,
      `type=${urlSubtypeId[clientFilter]}`,
    );
  }

  render() {
    return <SpecialistDashboardTemplate {...this.props} />;
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SpecialistDashboardTemplateContainer);
