import React from 'react';

import { DropdownButton, MenuItem } from 'react-bootstrap';

export const AttachmentListRowActionsButton = ({
  handleEditClick,
  handleDeleteClick,
  hideDelete = false,
}) =>
  <DropdownButton
    id="attachmentActionsButton"
    bsSize="xs"
    title={<span className="fa fa-cog" />}
    pullRight
  >
    <MenuItem id="editButton" onClick={handleEditClick}>
      <span className="fa fa-pencil" /> Edit
    </MenuItem>

    {hideDelete
      ? null
      : <MenuItem id="deleteButton" onClick={handleDeleteClick}>
          <span className="fa fa-remove" /> Delete
        </MenuItem>}
  </DropdownButton>;

export default AttachmentListRowActionsButton;
