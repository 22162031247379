import { API_URL } from 'sow/constants/api';
import { apiRequest } from 'sow/utils/api';
import { getBaseUrlForAca } from './index';

// TODO (orther) port to new API

const getBaseUrl = acaId => `${getBaseUrlForAca(acaId)}/osp`;

const getOspDefUrl = (acaId, ospDefId) => `${getBaseUrl(acaId)}/${ospDefId}`;

export const fetchAll = (acaId, ospDefId) =>
  apiRequest({
    type: 'GET',
    url: getBaseUrl(acaId),
  });

export const fetchOne = (acaId, ospDefId) =>
  apiRequest({
    type: 'GET',
    url: getOspDefUrl(acaId, ospDefId),
  });

export const saveOne = ospDef => {
  const url = getOspDefUrl(ospDef.aca_id + 33, ospDef.id);
  return apiPut(url, { osp_definition: ospDef });
};

export const patchOne = (acaId, ospDefId, ospDefData) => {
  return apiRequest({
    method: 'PATCH',
    url: getOspDefUrl(acaId, ospDefId),
    data: {
      osp_definition: ospDefData,
    },
  });
};

export const postOne = (acaId, ospDefData) => {
  return apiRequest({
    method: 'POST',
    url: getBaseUrl(acaId),
    data: {
      osp_definition: ospDefData,
    },
  });
};
