import React, { Fragment } from 'react';

import { systemAdminRoute, consultantCompaniesEditRoute } from 'sow/routes';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import InputFormGroup from 'sow/components/molecules/InputFormGroup';
import Button from 'sow/components/atoms/Button';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Spinner from 'sow/components/atoms/Spinner';
import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';

const ConsultantCompaniesList = ({
  loadingList,
  formValues,
  consultantList,
  handleInputChange,
  handleSearch,
  handleReset,
}) => (
  <Widget className="system-admin-list-edit">
    <WidgetHeading>Consultant Companies</WidgetHeading>
    <WidgetBody>
      <form onSubmit={handleSearch}>
        <Row className="form-row">
          <Column className="form-column" xs={12} sm={12} md={8}>
            <InputFormGroup
              id="name"
              label="Name"
              inputClassName="input-filter"
              onChange={handleInputChange}
              value={formValues.name}
            />
          </Column>
          <Column className="form-column" xs={12} sm={6} md={4}>
            <Row className="button-row search-button-row">
              <Column className="button-column" xs={6} sm={6} md={6}>
                <Button className="form-button search-button" type="submit">
                  Search
                </Button>
              </Column>
              <Column className="button-column" xs={6} sm={6} md={6}>
                <Button className="form-button reset-return-button" onClick={handleReset}>
                  Reset
                </Button>
              </Column>
            </Row>
          </Column>
        </Row>
      </form>
      <HorizontalRule className="divider" />
      <Row className="form-row">
        <Column className="form-column" xs={12} sm={6} md={4}>
          <Row className="button-row">
            <Column className="button-column" xs={6} sm={6} md={6}>
              <Button
                className="form-button"
                to={consultantCompaniesEditRoute('new')}
                primary
              >
                <FontAwesome className="button-icon" icon="plus" />
                Add
              </Button>
            </Column>
            <Column className="button-column" xs={6} sm={6} md={6}>
              <Button className="form-button reset-return-button" to={systemAdminRoute()}>
                Back
              </Button>
            </Column>
          </Row>
        </Column>
      </Row>
      {loadingList ? (
        <Spinner />
      ) : (
        <Row className="form-row list-row">
          <Column className="form-column" xs={12} sm={12} md={12}>
            <Block className="static-list">
              {consultantList.length > 0 ? (
                <Fragment>
                  {consultantList.map(consultant => (
                    <Block key={consultant.id} className="static-list-item">
                      <Block className="standard-item">
                        <Block className="list-item-name">{consultant.name}</Block>
                        <FontAwesome className="list-item-icon" icon="pencil" />
                        <Link
                          className="start-end-width"
                          to={consultantCompaniesEditRoute(consultant.id)}
                        >
                          Edit
                        </Link>
                      </Block>
                    </Block>
                  ))}
                </Fragment>
              ) : (
                <Block className="empty-list">No items found.</Block>
              )}
            </Block>
          </Column>
        </Row>
      )}
    </WidgetBody>
  </Widget>
);

export default ConsultantCompaniesList;
