import React from 'react';
import PropTypes from 'prop-types';

import Atom from 'sow/components/atoms/Atom';
import Strong from 'sow/components/atoms/Strong';
import Button from 'sow/components/atoms/Button';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const ToggleButton = ({ enabled, onClick, children, ...props }) => {
  return (
    <Button {...props} sm onClick={onClick}>
      <FontAwesome
        icon={enabled ? 'check-square' : 'square'}
        variant={enabled ? 'fas' : 'far'}
      />{' '}
      Show {children}
    </Button>
  );
};

ToggleButton.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const RequestFilterToggleButtons = ({ filter, onToggleFilter, requestListCount }) => {
  const handleButtonClick = filterName => event => {
    event.preventDefault();
    onToggleFilter(filterName);
  };

  return (
    <Atom>
      <ToggleButton
        info={filter.requested}
        enabled={filter.requested}
        onClick={handleButtonClick('requested')}
      >
        <Atom textInfo={!filter.requested}>
          <Strong>To-do</Strong>
        </Atom>{' '}
        ({requestListCount.toLocaleString()})
      </ToggleButton>
    </Atom>
  );
};

RequestFilterToggleButtons.propTypes = {
  filter: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    accepted: PropTypes.bool.isRequired,
    rejected: PropTypes.bool.isRequired,
    requested: PropTypes.bool.isRequired,
  }).isRequired,
  onToggleFilter: PropTypes.func.isRequired,
  requestListCount: PropTypes.number,
};

export default RequestFilterToggleButtons;
