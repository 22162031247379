import * as R from 'ramda';

import {
  FETCH_DASHBOARD_LIST,
  FETCH_DASHBOARD_LIST_SUCCESS,
  FETCH_DASHBOARD_LIST_FAILED,
  FETCH_DASHBOARD_COUNTS,
  FETCH_DASHBOARD_COUNTS_SUCCESS,
  FETCH_DASHBOARD_COUNTS_FAILED,
  ADD_SPECIALIST_SUCCESS,
  REMOVE_SPECIALIST_SUCCESS,
  UPDATE_DASHBOARD_NOTES,
  INSPECTOR_UPDATES_DONE,
  USER_PRIORITY_DONE,
  REMOVE_USER_PRIORITY_DONE,
} from 'sow/actions/orgWorkflow';

import { UPDATE_APP_REQUEST } from 'sow/actions/ospApplication';
import { makeImmutable } from 'sow/utils/data';

const initialState = {
  items: [],
  counts: null,
  isFetching: false,
  isFetchingCounts: false,
  createErrors: null,
  specialistsByOrg: [],
  priorityTypes: [],
};

export default function dashboardList(state = initialState, action) {
  switch (action.type) {
    case FETCH_DASHBOARD_LIST:
      return { ...state, isFetching: true };

    case FETCH_DASHBOARD_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: [...action.data.dashboard_list],
        specialistsByOrg: { ...action.data.specialists_by_org },
      };

    case FETCH_DASHBOARD_LIST_FAILED:
      return { ...state, isFetching: false };

    case FETCH_DASHBOARD_COUNTS:
      return { ...state, isFetchingCounts: true, counts: {} };

    case FETCH_DASHBOARD_COUNTS_SUCCESS:
      return {
        ...state,
        isFetchingCounts: false,
        counts: action.counts,
        filterOptions: { ...action.filter_options },
        priorityTypes: [...action.priority_types],
      };

    case FETCH_DASHBOARD_COUNTS_FAILED:
      return { ...state, isFetchingCounts: false };

    case ADD_SPECIALIST_SUCCESS:
      return { ...state, specialistsByOrg: { ...action.data } };

    case REMOVE_SPECIALIST_SUCCESS:
      return { ...state, specialistsByOrg: { ...action.data } };

    case UPDATE_APP_REQUEST:
      const { items } = state;
      //const found = items.find(x => x.app_id === '45');
      return { ...state, isFetching: false };

    case UPDATE_DASHBOARD_NOTES:
      const newData = makeImmutable(state).setIn(
        ['items', action.data.index, 'application_note'],
        action.data.note,
      );
      return { ...state, items: newData.items };

    case INSPECTOR_UPDATES_DONE:
      return {
        ...state,
        items: [...action.data.dashboard_list],
        counts: action.data.counts,
      };

    case USER_PRIORITY_DONE:
      const index = R.findIndex(
        R.propEq('organization_user_id', action.data.organization_user_id),
      )(state.items);
      const updatedData = makeImmutable(state)
        .setIn(['items', index, 'priority_color'], action.data.priority_type.color)
        .setIn(['items', index, 'priority_order'], action.data.priority_type.sort_order)
        .setIn(['items', index, 'priority_name'], action.data.priority_type.name)
        .setIn(['items', index, 'priority_id'], action.data.priority_type.id)
        .setIn(['items', index, 'priority_enabled'], action.data.priority_type.enabled)
        .setIn(['items', index, 'priority_active'], action.data.priority_type.is_active);

      return { ...state, items: updatedData.items };

    case REMOVE_USER_PRIORITY_DONE:
      const item = R.findIndex(
        R.propEq('organization_user_id', action.data.organization_user_id),
      )(state.items);
      const removedData = makeImmutable(state)
        .setIn(['items', item, 'priority_color'], null)
        .setIn(['items', item, 'priority_order'], null)
        .setIn(['items', item, 'priority_name'], null)
        .setIn(['items', item, 'priority_id'], null)
        .setIn(['items', item, 'priority_enabled'], null)
        .setIn(['items', item, 'priority_active'], null);

      return { ...state, items: removedData.items };

    default:
      return state;
  }
}
