import * as R from 'ramda';

const isOspDefEditorAction = R.compose(
  // NOTE: Use `OspDefEditor!` w/ trailing ! (bang) for ignored action types
  R.test(/^OspDefEditor\//),
  R.path(['type']),
);

// Actions
export const INIT_STATE = 'OspDefEditor/editor/INIT_STATE';
export const SET_CLEAN = 'OspDefEditor/editor/SET_CLEAN';
export const SET_DIRTY = 'OspDefEditor/editor/SET_DIRTY';

// Reducer functions
export const reduceSetClean = (state = {}, action = {}) => {
  return R.assoc('isDirty', false, state);
};

export const reduceSetDirty = (state = {}, action = {}) => {
  return R.assoc('isDirty', true, state);
};

// Action Creators
export const initEditor = ospDefState => ({
  type: INIT_STATE,
  payload: ospDefState,
});

export const setClean = () => ({ type: SET_CLEAN });
export const setDirty = () => ({ type: SET_DIRTY });

// Action Creator Export Map
export const actions = {
  initEditor,
  setClean,
  setDirty,
};

// Reducer
export const defaultState = {
  isDirty: false,
};

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case INIT_STATE:
    case SET_CLEAN:
      return reduceSetClean(state, action);

    case SET_DIRTY:
      return reduceSetDirty(state, action);

    default:
      if (isOspDefEditorAction(action)) {
        return reduceSetDirty(state, action);
      }

      return state;
  }
}
