import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Block from 'sow/components/atoms/Block';

const defaultStyle = {};

const WidgetHeader = ({ children, className, style, ...props }) =>
  <Block className={classnames('widget-footer', className)} style={style} {...props}>
    {children}
  </Block>;

WidgetHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};

WidgetHeader.defaultProps = {
  style: defaultStyle,
};

export default WidgetHeader;
