import * as R from 'ramda';
import { combineReducers } from 'redux';
import byId, * as fromById from './byId';

export default combineReducers({
  byId,
});

export const wsQuestionState = state => state.worksheetQuestions;

export const getWorksheetQuestion = R.curry((state, id) =>
  fromById.getWorksheetQuestion(state.byId, id),
);

// export const getWorksheetQuestionId = state =>
//   R.path(['uuid'], getWorksheetQuestion(state, id));

// export const getWorksheetQuestionName = state =>
//   R.path(['name'], getWorksheetQuestion(state, id));

export const getName = worksheetQuestion => R.path(['name'], worksheetQuestion);

export const getId = worksheetQuestion => R.path(['uuid'], worksheetQuestion);

export const getQuestions = R.curry((wsQuestionState, ids) => {
  if (!ids) return [];
  return R.map(getWorksheetQuestion(wsQuestionState), ids);
});
