import React from 'react';
import styled from 'styled-components';

import Block from 'sow/components/atoms/Block';

const PriorityBubble = styled(Block)`
  border-radius: 5px;
  padding: 3px 0;
  color: white;
  text-align: center;
  background-color: ${props => props.color};
`;

export default PriorityBubble;
