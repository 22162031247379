import React from 'react';

import FontAwesome from 'sow/components/atoms/FontAwesome';
import Block from 'sow/components/atoms/Block';
import List from 'sow/components/atoms/List';
import IconWithText from 'sow/components/molecules/IconWithText';
import SidebarLink from 'sow/components/molecules/SidebarLink';

const SidebarResources = ({ navClasses }) => (
  <Block className="sidebar-aca-admin">
    <List className={navClasses}>
      <SidebarLink
        to={'/placeholder'}
        href={'https://www.wfcforganic.com/training'}
        target="_blank"
        exact
      >
        <IconWithText icon={<FontAwesome icon="laptop" />} text="Resources" />
      </SidebarLink>
    </List>
  </Block>
);

export default SidebarResources;
