import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';
import List from 'sow/components/atoms/List';
import ListItem from 'sow/components/atoms/ListItem';

const SidebarDropdown = ({
  to,
  onClick,
  index,
  className,
  children,
  disabled,
  label,
  // router,
}) => {
  const classes = classnames(className, 'panel', 'panel-default', 'dropdown');

  let topLevelLink = null;

  if (disabled) {
    topLevelLink = (
      <Link
        disabled
        onClick={e => e.preventDefault()}
        className="text-muted"
        style={{ cursor: 'not-allowed' }}
      >
        {label}
      </Link>
    );
  } else {
    // TODO: Figure out how to do this in RR4
    // if (router.isActive(to, index)) {
    //   classes.push('active');
    // }

    topLevelLink = (
      <Link to={to} onClick={onClick}>
        {label}
      </Link>
    );
  }

  return (
    <ListItem className={classes}>
      {topLevelLink}

      <Block className="panel-collapse in">
        <Block className="panel-body">{children}</Block>
      </Block>
    </ListItem>
  );
};

SidebarDropdown.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  index: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  // router: PropTypes.object,
};

SidebarDropdown.defaultProps = {
  disabled: false,
  index: false,
  className: '',
  index: false,
};

const SidebarDropdownSubmenu = ({ children, navClasses }) => (
  <List className={navClasses}>{children}</List>
);

SidebarDropdownSubmenu.propTypes = {
  children: PropTypes.node,
  navClasses: PropTypes.string,
};

SidebarDropdown.Submenu = SidebarDropdownSubmenu;

export default withRouter(SidebarDropdown);
