import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import WorksheetAnswer from './WorksheetAnswer';
import { CommentMarker, CommentRow } from './Comment';
import { NoteMarker, NoteRow } from './Note';

const PlanAppExportContentWorksheetMatrixRow = ({
  questionList,
  matrixRowAnswerMap,
  changeList,
  commentList,
  noteList,
  worksheetChangeList,
  index,
  locationPending,
  ...props
}) => {
  const { worksheet, matrixRowId, addNotes, addComments } = props;

  const filterMatrixRowAnswerState = R.filter(
    R.where({
      type: R.equals('matrix_row_answer'),
      state: R.equals('open'),
      ignored: R.equals(false),
    }),
  );

  const filterMatrixRowPendingDelete = R.filter(
    R.where({
      type: R.equals('matrix_row'),
      state: R.equals('open'),
      ignored: R.equals(false),
    }),
  );

  const filterMatrixRowAcceptedDelete = R.filter(
    R.where({
      type: R.equals('matrix_row'),
      action: R.equals('deleted'),
      state: R.equals('accepted'),
      ignored: R.equals(false),
    }),
  );

  const reduceMatrixChanges = valueType =>
    R.reduce(
      (acc, elem) =>
        R.assocPath(
          [elem.matrixRowId, 'values', elem.questionId || 'action'],
          R.prop(valueType, elem),
          acc,
        ),
      {},
    );

  const pendingRowChanges = R.pipe(
    filterMatrixRowAnswerState,
    reduceMatrixChanges('action'),
  )(changeList);

  const pendingRowDeletes = R.pipe(
    filterMatrixRowPendingDelete,
    reduceMatrixChanges('action'),
  )(changeList);

  const pendingChanges = R.mergeDeepLeft(pendingRowChanges, pendingRowDeletes);

  const acceptedChanges = R.pipe(
    filterMatrixRowAcceptedDelete,
    reduceMatrixChanges('action'),
  )(changeList);

  const totalRowDeletes = R.pipe(
    filterMatrixRowAcceptedDelete,
    R.filter(R.where({ matrixRowId: R.equals(matrixRowId) })),
  )(worksheetChangeList);

  const checkDelete = () => {
    if (R.path([matrixRowId, 'values', 'action'], acceptedChanges)) {
      return true;
    }
  };

  if (!questionList) return null;
  const questionIdList = R.path(['questions'], worksheet);

  const colspan = questionList.length;
  const getValue = questionId =>
    R.path([matrixRowId, 'values', questionId], matrixRowAnswerMap);

  const getPending = questionId => {
    if (R.path([matrixRowId, 'values', 'action'], pendingChanges)) {
      return R.path([matrixRowId, 'values', 'action'], pendingChanges);
    } else {
      return R.path([matrixRowId, 'values', questionId], pendingChanges);
    }
  };

  return (
    <Fragment>
      {!checkDelete() && (
        <Fragment>
          <tr>
            {questionList.map(question => (
              <td key={question.uuid}>
                <WorksheetAnswer
                  value={getValue(question.uuid)}
                  question={question}
                  pending={getPending(question.uuid)}
                  locationPending={locationPending}
                />
              </td>
            ))}
          </tr>

          <tr
            style={{
              borderStyle: 'hidden',
              borderLeft: 0,
              borderRight: 0,
              borderBottom: 0,
            }}
          >
            {questionIdList.map(questionId => {
              const questionNoteList = R.filter(
                R.whereEq({ uuid: questionId, subUuid: matrixRowId }),
                noteList,
              );
              const questionChangeList = R.filter(
                R.whereEq({ questionId, matrixRowId }),
                changeList,
              );
              const questionChangeCommentIdList = R.chain(R.prop('comments'))(
                questionChangeList,
              );
              const questionCommentList = R.filter(
                R.where({
                  id: R.contains(R.__, questionChangeCommentIdList),
                }),
                commentList,
              );

              return (
                <td key={questionId}>
                  {addNotes &&
                    questionNoteList.map(note => (
                      <NoteMarker key={note.id} note={note} />
                    ))}
                  {addComments &&
                    questionCommentList.map(comment => (
                      <CommentMarker key={comment.id} comment={comment} />
                    ))}
                </td>
              );
            })}
          </tr>
          {addNotes &&
            noteList.map(note => <NoteRow key={note.id} note={note} colspan={colspan} />)}
          {addComments &&
            commentList.map(comment => (
              <CommentRow key={comment.id} comment={comment} colspan={colspan} />
            ))}
        </Fragment>
      )}
      {Object.keys(matrixRowAnswerMap).length === totalRowDeletes.length && index === 0 && (
        <tr>
          <td className="empty" colSpan={colspan}>
            N/A
          </td>
        </tr>
      )}
    </Fragment>
  );
};

PlanAppExportContentWorksheetMatrixRow.propTypes = {
  matrixRowId: sowTypes.planAppWorksheetQuestionMatrixRowIdType.isRequired,
  worksheet: sowTypes.planAppWorksheetType.isRequired,
  questionList: PropTypes.arrayOf(sowTypes.planAppWorksheetQuestionType).isRequired,
  changeList: sowTypes.planAppChangeListType.isRequired,
  matrixRowAnswerMap: PropTypes.object.isRequired,
  commentList: sowTypes.planAppChangeCommentListType.isRequired,
  noteList: sowTypes.planAppNoteListType.isRequred,
  addNotes: PropTypes.bool.isRequired,
  addComments: PropTypes.bool.isRequired,

  matrixRowAnswerMap: PropTypes.object,
  matrixRowIdList: sowTypes.planAppWorksheetQuestionMatrixRowIdTListType.isRequired,
};

export default PlanAppExportContentWorksheetMatrixRow;
