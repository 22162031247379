import React, { Component, Fragment } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';

import actions from 'sow/actions/pure';
import * as organizationTypeActions from 'sow/actions/pure/organizationType';
import * as currentAca from 'sow/selectors/currentAca';
import * as currentOrg from 'sow/selectors/currentOrg';
import * as currentUser from 'sow/selectors/currentUser';
import FormGroup from 'sow/components/organisms/FormGroup';
import FormPanel from 'sow/components/old/OrgSettings/FormPanel';
import InfoPanel from 'sow/components/organisms/InfoPanel';
import Button from 'sow/components/atoms/Button';
import ReactDatetime from 'react-datetime';
import Checkbox from 'sow/components/atoms/Checkbox';
import Clearfix from 'sow/components/atoms/Clearfix';
import Spinner from 'sow/components/atoms/Spinner';

const validAcreage = value => {
  const acerage = Number(value);
  if (Number.isNaN(acerage)) {
    return false;
  } else if (value.split('.')[1] && value.split('.')[1].length > 2) {
    return false;
  }
  return true;
};

const mapStateToProps = (state, props) => ({
  acaId: currentAca.acaId(state, props),
  showAdminUI: currentUser.showAdminUI(state, props),
  orgActive: currentOrg.org(state, props).active,
  isInspector: currentUser.isInspector(state, props),
});

const mapDispatchToProps = {
  activateOrg: actions.org.activate,
  deactivateOrg: actions.org.deactivate,
  loadOrganizationTypes: organizationTypeActions.loadOrganizationTypes,
};

const isMoment = R.is(moment);

class AcaForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldErrors: {},
      loadingOptions: true,
    };
  }

  componentDidMount() {
    this.props.loadOrganizationTypes(
      { enabled: true, options: true },
      this.updateState.bind(this),
    );
  }

  updateState({ data }) {
    this.setState({
      organizationTypeOptions: data.organization_types,
      loadingOptions: false,
    });
  }

  getSettings = () => {
    const { org } = this.props;
    return R.defaultTo({}, R.prop('settings', org));
  };

  getOrganizationType = () => {
    const { org } = this.props;
    return R.defaultTo([], R.prop('organization_type', org));
  };

  getAcaSettingsPath = field => {
    const { acaId } = this.props;
    return ['aca_byid', `${acaId}`, `${field}`];
  };

  getAcaSettingValue = field => {
    return R.pathOr('', this.getAcaSettingsPath(field), this.getSettings());
  };

  setAcaSettingValue = (field, value) => {
    return R.assocPath(this.getAcaSettingsPath(field), value, this.getSettings());
  };

  handleActivateOrg = () => {
    const { activateOrg, org } = this.props;
    activateOrg(org.id);
  };

  handleDeactivateOrg = () => {
    const { deactivateOrg, org } = this.props;
    deactivateOrg(org.id);
  };

  handleChangeInput = field => event => {
    const value = event.target.value;
    this.props.handleChange(field, value);
  };

  isValidDate = dateStr => {
    const regex = /[0-9\/]/g;
    if (dateStr.length < 10) {
      return false;
    }
    if (!isMoment(dateStr) && dateStr.match(regex) != null) {
      return false;
    }
    if (!moment(dateStr).isValid()) {
      return false;
    }
    return true;
  };

  handleChangeSetting = field => value => hasErrors => {
    if (field === 'total_certified_acres') {
      const { fieldErrors } = this.state;

      if (validAcreage(value)) {
        $('.btn-primary')
          .first()
          .prop('disabled', false);
        delete fieldErrors[field];
        this.setState({ fieldErrors });
      } else {
        const updateFieldErrors = { [field]: ['Value must be a number.'] };
        this.setState({ fieldErrors: updateFieldErrors });
        $('.btn-primary')
          .first()
          .prop('disabled', true);
      }
    }
    this.props.handleChangeWithErrors(
      'settings',
      this.setAcaSettingValue(field, value),
    )(hasErrors);
  };

  handleAnniversaryDateChange = field => value => {
    const invalidDateMsg = 'Date is invalid. Please use MM/DD/YYYY ex. (04/05/2020)';
    let fieldErrors = {};
    let dateStr = value;
    let isValid = this.isValidDate(dateStr);

    if (dateStr.length == 0) {
      isValid = true;
    }
    if (!isValid) {
      fieldErrors = { anniversary_date: [invalidDateMsg] };
    }
    this.setState({
      fieldErrors: fieldErrors,
    });
    if (isMoment(dateStr)) {
      dateStr = moment.utc(dateStr).format('MM/DD/YYYY');
    }
    if (isValid) {
      this.handleChangeSetting(field)(dateStr)(!isValid);
      $('.btn-primary')
        .first()
        .prop('disabled', false);
    } else {
      $('.btn-primary')
        .first()
        .prop('disabled', true);
    }
  };

  handleChangeSettingInput = field => event => {
    const value = event.target.value;
    this.handleChangeSetting(field)(value)(false);
  };

  handleChangeSettingSelect = field => arr => {
    const typeIds = arr.map(type => type.value);
    this.props.handleChange(field)(typeIds);
  };

  handleChangeSettingCheckbox = field => value => {
    this.handleChangeSetting(field)(value)(false);
  };

  render() {
    const { disabled, errors, orgActive, showAdminUI, isInspector } = this.props;
    const disableField = disabled || isInspector;
    const { fieldErrors } = this.state;
    const formErrors = errors ? errors.join(fieldErrors) : fieldErrors;
    //const billingErrors  = R.propOr(null, 'billing_location', errors);
    //const legalErrors    = R.propOr(null, 'legal_location', errors);
    //const locationErrors = R.propOr(null, 'location', errors);

    return (
      <Fragment>
        {this.state.loadingOptions ? (
          <Spinner />
        ) : (
          <div>
            <InfoPanel
              text={
                <span>
                  <b>NOTE:</b> The ACA settings tab is only accessible to ACA staff and
                  organization users will not see it.
                </span>
              }
            />
            <FormPanel title="Client ID" disabled={disableField}>
              {/* WO-818: Can be deleted in future if not longer needed. Hiding for now. */}
              {/* <FormGroup fieldName="client_id_label" errors={errors} label="Label" required>
            <input
              type="text"
              id="client_id_label"
              value={this.getAcaSettingValue('client_id_label')}
              onChange={this.handleChangeSettingInput('client_id_label')}
              className="form-control"
              disabled={disableField}
            />
          </FormGroup> */}
              <FormGroup
                fieldName="client_id_value"
                errors={errors}
                label="NOP Operation ID"
                required
              >
                <input
                  type="text"
                  id="client_id_value"
                  value={this.getAcaSettingValue('client_id_value')}
                  onChange={this.handleChangeSettingInput('client_id_value')}
                  className="form-control"
                  disabled={disableField}
                />
                {/* <small className="help-block">
                  This field is searchable on the organization list page.
                </small> */}
              </FormGroup>
            </FormPanel>
            <FormPanel title="Client Information">
              <FormGroup label="Organization Type">
                <Select
                  onChange={this.handleChangeSettingSelect('organization_type')}
                  value={this.getOrganizationType()}
                  options={this.state.organizationTypeOptions}
                  placeholder=""
                  multi={true}
                  disabled={disableField}
                />
              </FormGroup>
              <FormGroup
                fieldName="anniversary_date"
                errors={formErrors}
                label="Anniversary Date (for yearly renewal reminder)"
              >
                <ReactDatetime
                  id="anniversary_date"
                  value={this.getAcaSettingValue('anniversary_date')}
                  onChange={this.handleAnniversaryDateChange('anniversary_date')}
                  timeFormat={false}
                  dateFormat="MM/DD/YYYY"
                  closeOnSelect={true}
                  inputProps={{ placeholder: 'MM/DD/YYYY', disabled: disableField }}
                  style={{ textAlign: 'center' }}
                  disabled={disableField}
                />
              </FormGroup>
              <FormGroup
                fieldName="total_certified_acres"
                errors={formErrors}
                label="Total Certified Acres"
              >
                <input
                  id="total_certified_acres"
                  value={this.getAcaSettingValue('total_certified_acres')}
                  onChange={this.handleChangeSettingInput('total_certified_acres')}
                  className="form-control"
                  disabled={disableField}
                />
              </FormGroup>
              <FormGroup>
                <Checkbox
                  text="Hard Copy Account"
                  field="hard_copy"
                  onChange={this.handleChangeSettingCheckbox('hard_copy')}
                  value={this.getAcaSettingValue('hard_copy')}
                />
                <Clearfix />
              </FormGroup>
            </FormPanel>
            {showAdminUI && (
              <FormPanel
                title={
                  <span>
                    WFCF Organic Admin Options <small>Not visible to ACA</small>
                  </span>
                }
              >
                <InfoPanel
                  text={
                    <span>
                      <b>NOTE:</b> Inactive users will be able to login and to make
                      changes BUT they will see large notice to contact their ACA.
                    </span>
                  }
                />

                {orgActive ? (
                  <Button danger onClick={this.handleDeactivateOrg}>
                    Deactivate Organization
                  </Button>
                ) : (
                  <Button onClick={this.handleActivateOrg}>Activate Organization</Button>
                )}
              </FormPanel>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AcaForm);
