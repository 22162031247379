import React, { Component } from 'react';
import * as R from 'ramda';
// import Popover, { ArrowContainer } from 'react-tiny-popover';
import Popover from '@material-ui/core/Popover';
import styled from 'styled-components';
import { darken } from 'polished';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import PlanAppChange from 'sow/containers/planApp/Change';
import Tooltip from 'sow/components/molecules/Tooltip';
import ChangeStateIcon from 'sow/components/organisms/ChangeStateIcon';

const PopoverWrapper = styled(Block)`
  background-color: white;
  padding: 10px;
  border: 2px solid #ddd;
  width: 600px;
  max-width: 600px;
`;

const StyledButton = styled(Button)`
  display: inline-block;
  background-color: #dddddd;
  position: relative;
  padding: 6px 35px 8px 5px;
  border: 2px solid;
  border-color: ${({ changeState, theme }) => {
    switch (changeState) {
      case 'open':
        return darken(0.05, theme.cr.open);
      case 'rejected':
      case 'not_applied':
        return darken(0.05, theme.cr.rejected);
      case 'accepted':
      case 'applied':
        return darken(0.05, theme.cr.accepted);
      default:
        return 'transparent';
    }
  }};
`;

class DeletionChange extends Component {
  state = {
    anchorEl: null,
  };

  showPopover = event => this.setState({ anchorEl: event.currentTarget });
  hidePopover = () => this.setState({ anchorEl: null });

  render() {
    const { change, changeUIDisabled, changeText } = this.props;
    const { anchorEl } = this.state;

    const open = Boolean(anchorEl);

    return (
      <div>
        <Tooltip overlay="View change details">
          <StyledButton sm changeState={change.state} onClick={this.showPopover}>
            {/* For some reason the FontAwesome component breaks the click event here; just use className instead */}
            <Block className="fa fa-trash" style={{ marginTop: '2px' }} />
            <Block style={{ position: 'absolute', top: '-2px', right: '-2px' }}>
              <ChangeStateIcon changeState={change.state} />
            </Block>
          </StyledButton>
        </Tooltip>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={this.hidePopover}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <PopoverWrapper>
            <PlanAppChange
              changeId={change.id}
              changeUIDisabled={changeUIDisabled}
              renderChange={() => <Block>{changeText}</Block>}
              noCollapse
            />
          </PopoverWrapper>
        </Popover>
      </div>
    );

    // return (
    //   <Popover
    //     isOpen={showPopover}
    //     position="left"
    //     onClickOutside={this.togglePopover}
    //     content={({ position, targetRect, popoverRect }) => (
    //       <ArrowContainer
    //         position={position}
    //         targetRect={targetRect}
    //         popoverRect={popoverRect}
    //         arrowColor="#dddddd"
    //         arrowSize={10}
    //       >
    //         <PopoverWrapper>
    //           <ThemeProvider theme={theme}>
    //             <PlanAppChange
    //               changeId={change.id}
    //               changeUIDisabled={changeUIDisabled}
    //               renderChange={() => <Block>{changeText}</Block>}
    //               noCollapse
    //             />
    //           </ThemeProvider>
    //         </PopoverWrapper>
    //       </ArrowContainer>
    //     )}
    //   >
    // <Tooltip overlay="View change details">
    //   <StyledButton sm changeState={change.state} onClick={this.togglePopover}>
    //     {/* For some reason the FontAwesome component breaks the click event here; just use className instead */}
    //     <Block className="fa fa-trash" style={{ marginTop: '2px' }} />

    //     <Block style={{ position: 'absolute', top: '-2px', right: '-2px' }}>
    //       <ChangeStateIcon changeState={change.state} />
    //     </Block>
    //   </StyledButton>
    // </Tooltip>
    //   </Popover>
    // );
  }
}

DeletionChange.propTypes = {
  change: sowTypes.planAppChangeType,
  changeUIDisabled: PropTypes.bool.isRequired,
  changeText: PropTypes.node.isRequired,
};

export default DeletionChange;
