const mark = action => `ITEM_VERSION_SECTION/${action}`;

export const LOAD_ITEM_VERSION_SECTIONS = mark('LOAD_ITEM_VERSION_SECTIONS');
export const LOAD_ITEM_VERSION_SECTIONS_DONE = mark('LOAD_ITEM_VERSION_SECTIONS_DONE');
export const FETCH_BY_ID = mark('FETCH_BY_ID');
export const ADD_ITEM_VERSION_SECTION = mark('ADD_ITEM_VERSION_SECTION');
export const UPDATE_ITEM_VERSION_SECTION = mark('UPDATE_ITEM_VERSION_SECTION');
export const UPDATE_SORT_ORDER = mark('UPDATE_SORT_ORDER');
export const DELETE_ITEM_VERSION_SECTION = mark('DELETE_ITEM_VERSION_SECTION');
export const LOAD_OPTIONS = mark('LOAD_OPTIONS');

export const loadItemVersionSections = (payload, onSuccess) => ({
  type: LOAD_ITEM_VERSION_SECTIONS,
  payload,
  meta: { onSuccess },
});

export const loadItemVersionSectionsDone = payload => ({
  type: LOAD_ITEM_VERSION_SECTIONS_DONE,
  payload,
});

export const fetchById = (payload, onSuccess) => ({
  type: FETCH_BY_ID,
  payload,
  meta: { onSuccess },
});

export const addItemVersionSection = (payload, onSuccess) => ({
  type: ADD_ITEM_VERSION_SECTION,
  payload,
  meta: { onSuccess },
});

export const updateItemVersionSection = (id, payload, onSuccess) => ({
  type: UPDATE_ITEM_VERSION_SECTION,
  id,
  payload,
  meta: { onSuccess },
});

export const updateSortOrder = (payload, onSuccess) => ({
  type: UPDATE_SORT_ORDER,
  payload,
  meta: { onSuccess },
});

export const deleteItemVersionSection = (id, onSuccess) => ({
  type: DELETE_ITEM_VERSION_SECTION,
  id,
  meta: { onSuccess },
});

export const loadOptions = onSuccess => ({
  type: LOAD_OPTIONS,
  meta: { onSuccess },
});
