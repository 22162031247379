import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import actions from 'sow/actions/pure';
import { getAuthToken } from 'sow/utils/authToken';
import * as currentUser from 'sow/selectors/currentUser';
import ValidatingTokenPage from 'sow/components/pages/ValidatingTokenPage';

const mapStateToProps = (state, props) => ({
  isValidating: currentUser.isValidating(state, props),
  isAuthenticated: currentUser.isAuthenticated(state, props),
});

const mapDispatchToProps = {
  validateToken: actions.auth.validateToken,
};

/**
 * When an auth token from an existing session is stored in the browser, this component
 * blocks any further app rendering until the token is finished validating against the API.
 */
class TokenValidator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Token validation should be attempted if a token is present in browser storage
      shouldValidate: !R.isNil(getAuthToken()),
      attemptedValidation: false,
    };
  }

  componentDidMount() {
    if (this.state.shouldValidate) {
      this.props.validateToken();
      this.setState({ attemptedValidation: true });
    }
  }

  render() {
    const { shouldValidate, attemptedValidation } = this.state;
    const { children, isValidating } = this.props;

    if (shouldValidate && (!attemptedValidation || isValidating)) {
      return <ValidatingTokenPage />;
    }

    return children;
  }
}

TokenValidator.propTypes = {
  // Passed props
  children: PropTypes.node.isRequired,

  // Connected props
  isValidating: PropTypes.bool.isRequired,
  validateToken: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(TokenValidator);
