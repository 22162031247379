import React from 'react';

import OrgSettingsPageTemplate from 'sow/containers/OrgSettingsPageTemplate';
import Block from 'sow/components/atoms/Block';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Panel from 'sow/components/molecules/Panel';
import MigrateOrgToAcaForm from 'sow/containers/MigrateOrgToAcaForm';
import LinkUsersToOrgForm from 'sow/containers/LinkUsersToOrgForm';

const OrgSettingsMigratePage = () => (
  <OrgSettingsPageTemplate>
    <Block>
      <Widget>
        <WidgetHeading>Migrate Organization to Another ACA</WidgetHeading>

        <WidgetBody>
          <Panel
            warning
            heading="Create a new duplicate of this organization under the selected ACA and
          links the organization's users to that newly created organization,
          allowing the new organization to be accessed by this organization's
          login credentials."
          />
          <MigrateOrgToAcaForm />
        </WidgetBody>
      </Widget>

      <Block style={{ marginTop: '20px' }} />

      <Widget>
        <WidgetHeading>
          Link an Organization's Users to an Existing Organization
        </WidgetHeading>

        <WidgetBody>
          <Panel
            warning
            heading="Link an organization's users to another already existing organization,
          allowing that organization to be accessed with this organization's
          login credentials."
          />
          <LinkUsersToOrgForm />
        </WidgetBody>
      </Widget>
    </Block>
  </OrgSettingsPageTemplate>
);

export default OrgSettingsMigratePage;
