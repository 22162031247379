import { call, put, takeEvery } from 'redux-saga/effects';
import { push as routerPush } from 'connected-react-router';

import * as resetPasswordActions from 'sow/actions/pure/resetPassword';
import * as resetPasswordApi from 'sow/api/user/resetPassword';
import actions from 'sow/actions/pure';
import { requestNewPasswordRoute } from 'sow/routes';

export function* requestPasswordReset({ payload, meta }) {
  const { email } = payload;

  try {
    yield call(resetPasswordApi.requestPasswordReset, email);
    yield put(resetPasswordActions.requestPasswordResetDone());

    if (meta && meta.onSuccess) {
      yield call(meta.onSuccess);
    }
  } catch (error) {
    yield put(actions.api.requestError(error));
    yield put(resetPasswordActions.requestPasswordResetFail(error));
  }
}

export function* requestPasswordForNewAccount({ payload, meta }) {
  const { email } = payload;

  try {
    yield call(resetPasswordApi.requestPasswordForNewAccount, email);
    yield put(resetPasswordActions.requestPasswordForNewAccountDone());

    if (meta && meta.onSuccess) {
      yield call(meta.onSuccess);
    }
  } catch (error) {
    yield put(actions.api.requestError(error));
    yield put(resetPasswordActions.requestPasswordForNewAccountFail(error));
  }
}

export function* resetPassword({ payload, meta }) {
  try {
    yield call(resetPasswordApi.resetPassword, payload);
    yield put(resetPasswordActions.resetPasswordDone());
    yield put(
      actions.shell.toast(
        'success',
        'Your password has been successfully reset. You may now log in.',
      ),
    );

    if (meta && meta.onSuccess) {
      yield call(meta.onSuccess);
    }
  } catch (error) {
    yield put(actions.api.requestError(error));
    yield put(resetPasswordActions.resetPasswordFail(error));

    if (error.status === 404) {
      yield put(
        actions.shell.toast(
          'danger',
          'The password reset link used was invalid. You must request a new password reset.',
        ),
      );

      yield put(routerPush(requestNewPasswordRoute()));
    }
  }
}

export default function* rootSaga() {
  yield takeEvery(resetPasswordActions.REQUEST_PASSWORD_RESET, requestPasswordReset);
  yield takeEvery(
    resetPasswordActions.REQUEST_PASSWORD_FOR_NEW_ACCOUNT,
    requestPasswordForNewAccount,
  );
  yield takeEvery(resetPasswordActions.RESET_PASSWORD, resetPassword);
}
