import React from 'react';

import OrgSettingsPageTemplate from 'sow/containers/OrgSettingsPageTemplate';
import OrgSettingsEdit from 'sow/components/old/OrgSettings/Edit';
import AcaForm from 'sow/components/old/OrgSettings/AcaForm';

const OrgSettingsAcaTabPage = () => (
  <OrgSettingsPageTemplate>
    <OrgSettingsEdit>
      <AcaForm />
    </OrgSettingsEdit>
  </OrgSettingsPageTemplate>
);

export default OrgSettingsAcaTabPage;
