const mark = action => `ITEM_TYPE/${action}`;

export const LOAD_ITEM_TYPES = mark('LOAD_ITEM_TYPES');
export const FETCH_BY_ID = mark('FETCH_BY_ID');
export const ADD_ITEM_TYPE = mark('ADD_ITEM_TYPE');
export const UPDATE_ITEM_TYPE = mark('UPDATE_ITEM_TYPE');
export const UPDATE_SORT_ORDER = mark('UPDATE_SORT_ORDER');
export const DELETE_ITEM_TYPE = mark('DELETE_ITEM_TYPE');

export const loadItemTypes = (payload, onSuccess) => ({
  type: LOAD_ITEM_TYPES,
  payload,
  meta: { onSuccess },
});

export const fetchById = (payload, onSuccess) => ({
  type: FETCH_BY_ID,
  payload,
  meta: { onSuccess },
});

export const addItemType = (payload, onSuccess) => ({
  type: ADD_ITEM_TYPE,
  payload,
  meta: { onSuccess },
});

export const updateItemType = (id, payload, onSuccess) => ({
  type: UPDATE_ITEM_TYPE,
  id,
  payload,
  meta: { onSuccess },
});

export const updateSortOrder = (payload, onSuccess) => ({
  type: UPDATE_SORT_ORDER,
  payload,
  meta: { onSuccess },
});

export const deleteItemType = (id, onSuccess) => ({
  type: DELETE_ITEM_TYPE,
  id,
  meta: { onSuccess },
});
