import * as R from 'ramda';
import { createSelector } from 'reselect';
import * as ospDefSelect from 'sow/selectors/core/ospDefinition';
import * as ospQQSelect from 'sow/selectors/core/ospQualificationQuestion';
import * as ospSectionSelect from 'sow/selectors/core/ospSection';
import * as wsQuestionReducer from 'sow/reducers/worksheetQuestions';
import * as wsReducer from 'sow/reducers/worksheets';
import * as wsSelect from 'sow/selectors/core/worksheet';
import {
  landFacilitySectionLens,
  landParcelSectionLens,
  landPastureSectionLens,
  productSectionLens,
} from 'sow/reducers/ospDefinitions';

export const isLoadingOspDefList = createSelector(
  [ospDefSelect.isLoadingListForCurrentAca],
  R.identity,
);

export const isLoadingOspDef = createSelector(
  [ospDefSelect.isLoadingOneForCurrentAca],
  R.identity,
);

export const ospDefList = createSelector(
  [ospDefSelect.ospDefListForCurrentAca],
  R.identity,
);

export const ospDef = createSelector([ospDefSelect.currentOspDef], R.identity);

export const ospDefId = createSelector([ospDefSelect.currentOspDefId], R.identity);

export const ospDefName = createSelector([ospDef], R.path(['name']));

export const ospSectionIds = createSelector(
  [ospSectionSelect.ospSectionIdsForCurrentOspDef],
  R.identity,
);

export const ospSections = createSelector(
  [ospSectionSelect.ospSectionsForCurrentOspDef],
  R.identity,
);

export const miniOspSectionIds = createSelector(
  [ospSectionSelect.miniOspSectionIdsForCurrentOspDef],
  R.identity,
);

export const miniOspSections = createSelector(
  [ospSectionSelect.miniOspSectionsForCurrentOspDef],
  R.identity,
);

export const allOspSectionIds = createSelector(
  [ospSectionSelect.allOspSectionIdsForCurrentOspDef],
  R.identity,
);

export const allOspSections = createSelector(
  [ospSectionSelect.allOspSectionsForCurrentOspDef],
  R.identity,
);

export const miniOspWorksheetIds = createSelector(
  [miniOspSections],
  R.compose(R.chain(R.prop('worksheets')), R.values),
);

export const worksheetIds = createSelector(
  [allOspSections],
  R.compose(R.chain(R.prop('worksheets')), R.values),
);

export const allWorksheetIds = createSelector(
  [worksheetIds, miniOspWorksheetIds],
  R.concat,
);

export const worksheets = createSelector(
  [wsSelect.worksheetState, allWorksheetIds],
  (state, prop) => {
    return wsReducer.getWorksheetsByIds(state, prop);
  },
);

export const worksheetQuestions = createSelector(
  [wsQuestionReducer.wsQuestionState, worksheets],
  (wsQuestionState, worksheets) => {
    const questions = R.compose(
      R.indexBy(R.prop('uuid')),
      R.chain(
        R.compose(
          wsQuestionReducer.getQuestions(wsQuestionState),
          R.reject(R.isNil),
          R.path(['questions']),
        ),
      ),
      R.values,
    )(worksheets);

    return questions;
  },
);

export const qualificationQuestionRootIds = createSelector(
  [ospDef],
  R.pathOr([], ['definition', 'qualifications', 'questions']),
);

export const qualificationQuestions = createSelector(
  [ospQQSelect.ospQualificationQuestionsForCurrentOspDef],
  R.identity,
);
