import React, { Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import { titleize } from 'sow/utils/string';

import { inspectionStates } from 'sow/constants/inspectionConstants';

import Atom from 'sow/components/atoms/Atom';
import Block from 'sow/components/atoms/Block';
import InfoPanel from 'sow/components/organisms/InfoPanel';
import FormikField from 'sow/components/organisms/FormikField';
import FormikReactSelect from 'sow/components/molecules/FormikReactSelect';
import Button from 'sow/components/atoms/Button';

const InspectionStateChangeModal = ({
  children,
  toggleModal,
  onSubmit,
  show,
  initialValues,
  type,
  options,
  saveBlocked,
}) => (
  <Fragment>
    {children(toggleModal)}
    <Modal show={show} onHide={toggleModal}>
      <Modal.Header closeButton>
        <Modal.Title>Update {titleize(type)} Status</Modal.Title>
      </Modal.Header>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Block>Current {titleize(type)} Status:</Block>
              <InfoPanel
                style={{ textAlign: 'center' }}
                text={initialValues[`${type}_state_id`].label}
              />
              <Field
                placeholder=""
                clearable={false}
                label={`New ${titleize(type)} Status`}
                name={`${type}_state_id`}
                component={FormikField}
                type={FormikReactSelect}
                options={options}
              />
              {saveBlocked &&
                values[`${type}_state_id`] === inspectionStates.complete && (
                  <Atom textDanger>
                    All associated samples must be in "Results Received - Negative",
                    "Closed", or "Unsuccessful" status to mark inspection complete.
                  </Atom>
                )}
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={toggleModal}>Cancel</Button>
              <Button
                type="submit"
                disabled={
                  saveBlocked && values[`${type}_state_id`] === inspectionStates.complete
                }
                primary
              >
                Save
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  </Fragment>
);

export default InspectionStateChangeModal;
