import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Block from 'sow/components/atoms/Block';
import List from 'sow/components/atoms/List';

const ListGroup = ({ className, children, linked, ...props }) => {
  const cssClasses = classnames('list-group', className);

  return linked
    ? <Block className={cssClasses} {...props}>
        {children}
      </Block>
    : <List className={cssClasses} {...props}>
        {children}
      </List>;
};

ListGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  className: PropTypes.string,
  linked: PropTypes.bool,
};

export default ListGroup;
