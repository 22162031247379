import * as R from 'ramda';
import { compose, defaultProps, withHandlers, withProps, withState } from 'recompose';

export const hocCollapsible = compose(
  defaultProps({
    defaultIsCollaped: true,
  }),
  withState('isCollapsed', 'hocSetIsCollapsed', R.prop('defaultIsCollaped')),
  withProps(({ hocSetIsCollapsed }) => ({
    setIsCollapsed: isCollapsed => hocSetIsCollapsed(props => isCollapsed),
  })),
  withHandlers({
    onCollapseClose: ({ setIsCollapsed }) => event => {
      if (event) event.preventDefault();
      setIsCollapsed(true);
    },
    onCollapseOpen: ({ setIsCollapsed }) => event => {
      if (event) event.preventDefault();
      setIsCollapsed(false);
    },
    onCollapseToggle: ({ setIsCollapsed, isCollapsed }) => event => {
      if (event) event.preventDefault();
      setIsCollapsed(!isCollapsed);
    },
  }),
);

export const hocEditable = compose(
  defaultProps({
    defaultEditing: false,
  }),
  withState('isEditing', 'hocSetIsEditing', R.prop('defaultEditing')),
  withProps(({ hocSetIsEditing }) => ({
    setIsEditing: isEditing => hocSetIsEditing(props => !!isEditing),
  })),
  withHandlers({
    onEditDone: ({ setIsEditing }) => event => {
      if (event) event.preventDefault();
      setIsEditing(false);
    },
    onEditStart: ({ setIsEditing }) => event => {
      if (event) event.preventDefault();
      setIsEditing(true);
    },
  }),
);

export const hocPreviewable = compose(
  withState('isPreviewing', 'hocSetIsPreviewing', false),
  withProps(({ hocSetIsPreviewing }) => ({
    setIsPreviewing: isPreviewing => hocSetIsPreviewing(props => !!isPreviewing),
  })),
  withHandlers({
    onPreviewDone: ({ setIsPreviewing }) => event => {
      if (event) event.preventDefault();
      setIsPreviewing(false);
    },
    onPreviewStart: ({ setIsPreviewing }) => event => {
      if (event) event.preventDefault();
      setIsPreviewing(true);
    },
  }),
);

export const hocSaveable = compose(
  withState('isSaving', 'hocSetIsSaving', false),
  withProps(({ hocSetIsSaving }) => ({
    setIsSaving: isSaving => hocSetIsSaving(props => !!isSaving),
  })),
  withHandlers({
    onSaveDone: ({ setIsSaving }) => event => {
      if (event) event.preventDefault();
      setIsSaving(false);
    },
    onSaveStart: ({ setIsSaving }) => event => {
      if (event) event.preventDefault();
      setIsSaving(true);
    },
  }),
);
