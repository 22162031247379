import React from 'react';
import Popover from '@material-ui/core/Popover';

import PopoverWrapper from './PopoverWrapper';

const OptionListPopover = ({
  anchorEl,
  hidePopover,
  anchorOrigin,
  transformOrigin,
  children,
}) => (
  <Popover
    open={Boolean(anchorEl)}
    anchorEl={anchorEl}
    onClose={hidePopover}
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
  >
    <PopoverWrapper>{children}</PopoverWrapper>
  </Popover>
);

export default OptionListPopover;
