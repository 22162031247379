import React from 'react';
import { Formik, Form, Field } from 'formik';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import FormikField from 'sow/components/organisms/FormikField';
import AcaSelect from 'sow/containers/AcaSelect';
import Paragraph from 'sow/components/atoms/Paragraph';
import OrgUserLookupField from 'sow/containers/OrgUserLookupField';
import Strong from 'sow/components/atoms/Strong';

const noop = () => {};

const UserLookupPage = () => (
  <AdminPageTemplate title="Organization User Lookup">
    {() => (
      <Widget>
        <WidgetHeading>Organization User Lookup</WidgetHeading>
        <WidgetBody>
          <Paragraph>
            Search for an <Strong>organization</Strong> or <Strong>user</Strong> by org
            id, org name, user name, or user email:
          </Paragraph>
          <Formik
            initialValues={{ acaId: '', orgId: '', userSearch: '' }}
            onSubmit={noop}
          >
            {({ values }) => (
              <Form>
                <Field
                  label="Select an ACA:"
                  name="acaId"
                  component={FormikField}
                  type={AcaSelect}
                  required
                />

                <Field
                  name="orgId"
                  label="Organization or user:"
                  component={FormikField}
                  type={OrgUserLookupField}
                  acaId={values.acaId}
                  values={values}
                />
              </Form>
            )}
          </Formik>
        </WidgetBody>
      </Widget>
    )}
  </AdminPageTemplate>
);

export default UserLookupPage;
