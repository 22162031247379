import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import Switch from 'react-bootstrap-switch';

import { worksheetQuestionType } from 'sow/types';

import Block from 'sow/components/atoms/Block';
import Well from 'sow/components/molecules/Well';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';

// Old non-atomic components to be refactored
import AdminXray from 'sow/components/old/AdminXray';

import { getAnswer, getOptions, getOptionValue, setOptionValue } from './answerVersions';

class CheckboxList extends Component {
  getAnswer = () => {
    const { answer } = this.props;
    const options = this.getOptions();
    return getAnswer(answer, options);
  };

  getOptions = () => {
    const { question } = this.props;
    return getOptions(question);
  };

  handleChange = (answer, option) => event => {
    const { handleChange, question, disabled } = this.props;

    if (disabled) return;

    const newValue = !this.optionValue(answer, option);
    const updatedAnswer = setOptionValue(answer, option, newValue);

    handleChange(question.uuid, updatedAnswer);
  };

  optionValue = (answer, option) => {
    return getOptionValue(answer, option);
  };

  render() {
    const { question, disabled } = this.props;
    const answer = this.getAnswer();

    const BaseEl = ({ isBaseQuestion, ...props }) =>
      isBaseQuestion ? <Block {...props} /> : <Well sm {...props} />;

    return (
      <BaseEl
        isBaseQuestion={question.isBaseQuestion}
        style={{ margin: 0, padding: '5px 10px 0 10px' }}
      >
        {this.getOptions().map(option => {
          const onChange = this.handleChange(answer, option);
          const value = this.optionValue(answer, option);

          return (
            <Row key={option} style={{ margin: '5px 0' }}>
              <Column xs={2} style={{ width: 60, paddingLeft: 0 }}>
                <AdminXray data={{ question, answer, disabled }} log />
                <Switch
                  value={value}
                  tristate={true}
                  disabled={disabled}
                  onChange={onChange}
                  offText="No"
                  onText="Yes"
                  bsSize="mini"
                  handleWidth={50}
                  labelWidth={10}
                />
              </Column>
              <Column xs={10} style={{ paddingLeft: 0 }}>
                <Block
                  className={`bg-${value ? 'info' : ''}`}
                  style={{ margin: '0 8px', padding: '3px', cursor: 'pointer' }}
                  onClick={onChange}
                  disabled={disabled}
                >
                  {option}
                </Block>
              </Column>
            </Row>
          );
        })}
      </BaseEl>
    );
  }
}

CheckboxList.defaultProps = {
  disabled: false,
};

CheckboxList.propTypes = {
  answer: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  handleChange: PropTypes.func.isRequired,
  question: worksheetQuestionType.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CheckboxList;
