import React, { Component } from 'react';
import * as R from 'ramda';
import { Glyphicon } from 'react-bootstrap';
import PropTypes from 'prop-types';

import FileDownloadLink from 'sow/components/old/File/DownloadLink';
import FileUploadModal from 'sow/components/old/osp/FileUploadModal';
import Tooltip from 'sow/components/molecules/Tooltip';

const getId = R.path(['id']);
const getName = R.path(['name']);

export const FileListNoRows = (
  <tr>
    <td className="text-center">No files uploaded.</td>
  </tr>
);

class FileListQuestionField extends Component {
  static propTypes = {
    orgId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ospAppId: PropTypes.number,
    worksheetId: PropTypes.string,
    handleChange: PropTypes.func,
    question: PropTypes.object,
    answer: PropTypes.any,
    disabled: PropTypes.bool,
  };

  getQuestionId = () => R.path(['question', 'uuid'], this.props);

  getFileList = () => {
    if (!this.props.answer) return [];
    return R.reject(R.isNil, this.props.answer);
  };

  handleRemoveFile = file => event => {
    const questionId = this.getQuestionId();
    const fileId = getId(file);
    const fileList = this.getFileList();
    const newFileList = R.reject(R.propEq('id', fileId), fileList);

    this.props.handleChange(questionId, newFileList);
  };

  renderFileTable = () => {
    const fileList = this.getFileList();
    const hasFiles = !Array.isArray(fileList) || R.isEmpty(fileList);

    return (
      <div className="panel panel-default">
        <table className="table table-bordered table-condensed table-striped">
          <tbody>
            {hasFiles ? FileListNoRows : fileList.map(this.renderFileTableRow)}
          </tbody>
        </table>
      </div>
    );
  };

  renderFileTableRow = file => {
    const fileId = getId(file);
    const fileName = getName(file);
    const { disabled } = this.props;

    return (
      <tr key={`file-table-row-fileId--${fileId}-${fileName}`}>
        <td>
          <FileDownloadLink fileId={fileId}>{fileName}</FileDownloadLink>
        </td>
        <td className="text-center">
          <button
            className="btn btn-danger btn-xs"
            onClick={this.handleRemoveFile(file)}
            disabled={disabled}
          >
            <Tooltip overlay="Remove">
              <Glyphicon glyph="remove" />
            </Tooltip>
          </button>
        </td>
      </tr>
    );
  };

  render() {
    const { handleChange, orgId, worksheetId, answer, disabled } = this.props;

    const questionId = this.getQuestionId();

    return (
      <div>
        {this.renderFileTable()}

        <FileUploadModal
          orgId={orgId}
          files={answer}
          worksheetId={worksheetId}
          questionId={questionId}
          handleChange={handleChange}
          disabled={disabled}
        />
      </div>
    );
  }
}

export default FileListQuestionField;
