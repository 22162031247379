import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const ListItem = ({
  children,
  className,
  textMuted,
  textSuccess,
  textInfo,
  textWarning,
  textDanger,
  textNowrap,
  textCenter,
  textLeft,
  textRight,
  hiddenXs,
  hiddenSm,
  hiddenMd,
  hiddenLg,
  visibleXs,
  visibleSm,
  visibleMd,
  visibleLg,
  ...props
}) => {
  const classes = classnames(className, {
    'text-muted': textMuted,
    'text-success': textSuccess,
    'text-info': textInfo,
    'text-warning': textWarning,
    'text-danger': textDanger,
    'text-nowrap': textNowrap,
    'text-center': textCenter,
    'text-left': textLeft,
    'text-right': textRight,
    'hidden-xs': hiddenXs,
    'hidden-sm': hiddenSm,
    'hidden-md': hiddenMd,
    'hidden-lg': hiddenLg,
    'visible-xs': visibleXs,
    'visible-sm': visibleSm,
    'visible-md': visibleMd,
    'visible-lg': visibleLg,
  });

  return (
    <li {...props} className={classes}>
      {children}
    </li>
  );
};

ListItem.propTypes = {
  children: PropTypes.node,

  /** Additional css classes to be passed through */
  className: PropTypes.string,

  /** Aligns text center */
  textCenter: PropTypes.bool,

  /** Aligns text left */
  textLeft: PropTypes.bool,

  /** Aligns text right */
  textRight: PropTypes.bool,

  /** Colors text in 'muted' color */
  textMuted: PropTypes.bool,

  /** Colors text in 'success' color */
  textSuccess: PropTypes.bool,

  /** Colors text in 'fino' color */
  textInfo: PropTypes.bool,

  /** Colors text in 'warning' color */
  textWarning: PropTypes.bool,

  /** Colors text in 'danger' color */
  textDanger: PropTypes.bool,

  /** Disable text-wrapping */
  textNowrap: PropTypes.bool,



  hiddenXs: PropTypes.bool,
  hiddenSm: PropTypes.bool,
  hiddenMd: PropTypes.bool,
  hiddenLg: PropTypes.bool,
  visibleXs: PropTypes.bool,
  visibleSm: PropTypes.bool,
  visibleMd: PropTypes.bool,
  visibleLg: PropTypes.bool,
};

export default ListItem;
