import React, { Fragment } from 'react';

import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';

const SpecialistDashboardFilters = ({ filter, handleFilterChange, counts }) => (
  <Block className="specialist-filters">
    {counts ? (
      <Fragment>
        <Block inline>
          <Button
            onClick={() => handleFilterChange('all')}
            className={filter === 'all' ? 'selected-filter' : ''}
          >
            All Plans ({counts.all || 0})
          </Button>
        </Block>
        <Block inline>
          <Button
            onClick={() => handleFilterChange('expedited')}
            className={filter === 'expedited' ? 'selected-filter' : ''}
          >
            Expedited ({counts.expedited || 0})
          </Button>
        </Block>
        <Block inline>
          <Button
            onClick={() => handleFilterChange('new')}
            className={filter === 'new' ? 'selected-filter' : ''}
          >
            New ({counts.is_new || 0})
          </Button>
        </Block>
        <Block inline>
          <Button
            onClick={() => handleFilterChange('renewal')}
            className={filter === 'renewal' ? 'selected-filter' : ''}
          >
            Renewals ({counts.is_renewal || 0})
          </Button>
        </Block>
      </Fragment>
    ) : null}
  </Block>
);

export default SpecialistDashboardFilters;
