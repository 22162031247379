import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as currentAca from 'sow/selectors/currentAca';
import AcaSettingsPageTemplate from 'sow/components/templates/AcaSettingsPageTemplate';
import AcaSettingsGeneralPage from 'sow/components/pages/AcaSettingsGeneralPage';

const mapStateToProps = (state, props) => ({
  aca: currentAca.aca(state, props),
  acaId: currentAca.acaId(state, props),
});

const AcaSettingsGeneralPageContainer = ({ aca, acaId }) => {
  return (
    <AcaSettingsPageTemplate>
      {aca && <AcaSettingsGeneralPage acaId={acaId} aca={aca} />}
    </AcaSettingsPageTemplate>
  );
};

AcaSettingsGeneralPageContainer.propTypes = {
  aca: PropTypes.object,
  acaId: PropTypes.number,
  updateAca: PropTypes.func,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(AcaSettingsGeneralPageContainer);
