import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import ExportWorksheetMatrixRow from 'sow/containers/planAppExport/Content/WorksheetMatrixRow';
import { WorksheetMatrixTable } from './style/atoms';

const PlanAppExportContentWorksheetMatrix = ({
  questionList,
  matrixRowIdList,
  ...props
}) => {
  const { worksheet } = props;
  const colspan = worksheet.questions.length;
  if (!questionList) return null;

  return (
    <div className="export-table">
      <WorksheetMatrixTable className="generic-table">
        <thead>
          <tr>
            {questionList.map(question => (
              <th key={question.uuid}>{question.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {R.isEmpty(matrixRowIdList) ? (
            <tr>
              <td className="empty" colSpan={colspan}>
                N/A
              </td>
            </tr>
          ) : (
            matrixRowIdList.map((matrixRowId, index) => (
              <ExportWorksheetMatrixRow
                key={matrixRowId}
                {...props}
                matrixRowId={matrixRowId}
                index={index}
              />
            ))
          )}
        </tbody>
      </WorksheetMatrixTable>
    </div>
  );
};

PlanAppExportContentWorksheetMatrix.propTypes = {
  locationId: sowTypes.planAppLocationIdType,
  worksheet: sowTypes.planAppWorksheetType.isRequired,
  questionList: PropTypes.arrayOf(sowTypes.planAppWorksheetQuestionType).isRequired,
  wsAnswers: sowTypes.planAppWorksheetAnswersType,
  matrixRowAnswerMap: PropTypes.object,
  matrixRowIdList: sowTypes.planAppWorksheetQuestionMatrixRowIdTListType.isRequired,
  exportConfig: sowTypes.planAppExportConfigType.isRequired,
};

export default PlanAppExportContentWorksheetMatrix;
