import * as R from 'ramda';
import {
  PLAN_APP_STATES_OPEN_FOR_ORG,
  PLAN_APP_STATES_UNLOCKED_FOR_ORG,
} from 'sow/constants/planApp';

export const appState = R.path(['state']);
export const isProgressNotStarted = R.pathEq(['numComplete'], 0);
export const isProgressStarted = R.complement(isProgressNotStarted);
export const isProgressComplete = R.pathEq(['percentComplete'], 100);

export const isStateUnlocked = R.compose(
  R.contains(R.__, PLAN_APP_STATES_UNLOCKED_FOR_ORG),
  appState,
);

export const isStateLocked = R.complement(isStateUnlocked);

export const isStateOpen = R.compose(
  R.contains(R.__, PLAN_APP_STATES_OPEN_FOR_ORG),
  appState,
);

export const isStateClosed = R.complement(isStateOpen);
