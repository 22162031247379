import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push as routerPush } from 'connected-react-router';

import CenteredLayout from 'sow/components/templates/CenteredLayout';
import actions from 'sow/actions/pure';
import { loginRoute } from 'sow/routes';
import Heading from 'sow/components/atoms/Heading';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';

const mapDispatchToProps = { logout: actions.auth.logout, redirect: routerPush };

class LogoutPageContainer extends Component {
  componentDidMount() {
    const { logout, redirect } = this.props;

    logout();
    redirect(loginRoute());
  }

  render() {
    return (
      <CenteredLayout>
        <Row>
          <Column md={6} mdOffset={3}>
            <Heading level={3}>Logging out...</Heading>
          </Column>
        </Row>
      </CenteredLayout>
    );
  }
}

export default R.compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps,
  ),
)(LogoutPageContainer);
