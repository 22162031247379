import * as R from 'ramda';
import { combineReducers } from 'redux';

import actions from 'sow/actions/pure';

export const isLoadingList = (state = false, action) => {
  switch (action.type) {
    case String(actions.org.loadAcaList):
      return true;

    case String(actions.org.loadAcaListDone):
    case String(actions.org.loadAcaListFail):
      return false;

    default:
      return state;
  }
};

const onePayloadId = R.path(['payload', 'orgId']);
const oneResultId = R.path(['payload', 'result']);

export const isLoadingOne = (state = [], action) => {
  switch (action.type) {
    case String(actions.org.loadOne):
      return R.union([onePayloadId(action)], state);

    case String(actions.org.loadOneDone):
      return R.without([oneResultId(action)], state);

    case String(actions.org.loadOneFail):
      return R.without([onePayloadId(action)], state);

    default:
      return state;
  }
};

export const isLoadingOneFailed = (state = [], action) => {
  switch (action.type) {
    case String(actions.org.loadOne):
      return R.without([onePayloadId(action)], state);

    case String(actions.org.loadOneDone):
      return R.without([oneResultId(action)], state);

    case String(actions.org.loadOneFail):
      return R.union([onePayloadId(action)], state);

    default:
      return state;
  }
};

export default combineReducers({
  list: isLoadingList,
  one: isLoadingOne,
  oneFailed: isLoadingOneFailed,
});
