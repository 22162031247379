import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { resourceListRead } from 'sow/store/helpers';
import actions from 'sow/actions/pure';
import OrgSettingsInspectors from 'sow/components/organisms/OrgSettingsInspectors';

const inspectorListResource = resourceListRead('inspector', 'inspector');

const mapStateToProps = (state, props) => {
  const { selectors: inspectorListSelectors } = inspectorListResource;
  const inspectorListResult = inspectorListSelectors.result(state);

  return {
    inspectorListPending: inspectorListSelectors.pending(state),
    inspectorListDone: inspectorListSelectors.done(state),
    inspectorListFailed: inspectorListSelectors.rejected(state),
    inspectorList: inspectorListResult,
  };
};

const mapDispatchToProps = {
  loadInspectorList: inspectorListResource.action,
  getInspectionOptions: actions.inspection.getOptions,
  getSampleOptions: actions.sample.getOptions,
};

const mergeProps = (stateProps, dispatchProps) => {
  const { getInspectionOptions, getSampleOptions } = dispatchProps;

  const loadSelectOptions = () => {
    getInspectionOptions();
    getSampleOptions();
  };
  return {
    ...stateProps,
    ...dispatchProps,
    loadSelectOptions: () => loadSelectOptions(),
  };
};

class OrgSettingsInspectorsContainer extends React.Component {
  componentDidMount() {
    const {
      loadInspectorList,
      inspectorListDone,
      inspectorListPending,
      loadSelectOptions,
    } = this.props;

    if (!inspectorListDone && !inspectorListPending) {
      loadInspectorList();
    }
    loadSelectOptions();
  }

  render() {
    return <OrgSettingsInspectors {...this.props} />;
  }
}

OrgSettingsInspectorsContainer.propTypes = {
  inspectorList: PropTypes.array,
  inspectorListDone: PropTypes.bool,
  inspectorListFailed: PropTypes.bool,
  inspectorListPending: PropTypes.bool,
  loadInspectorList: PropTypes.func,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
)(OrgSettingsInspectorsContainer);
