import * as R from 'ramda';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { worksheetRoute } from 'sow/routes';

import Block from 'sow/components/atoms/Block';
import Spacer from 'sow/components/atoms/Spacer';
import Panel from 'sow/components/molecules/Panel';
import ListGroupItem from 'sow/components/atoms/ListGroupItem';
import ListGroup from 'sow/components/molecules/ListGroup';
import Tooltip from 'sow/components/molecules/Tooltip';
import WorksheetStatusIcon from 'sow/containers/planApp/WorksheetStatusIcon';
import ChangeRequestManager from 'sow/containers/planApp/ChangeRequestManager';
import WorksheetDetails from 'sow/containers/planApp/WorksheetDetails';
import NewWorksheetIndicator from 'sow/containers/planApp/NewWorksheetIndicator';

const TooltipWrapper = ({ overlay, ...props }) => {
  if (overlay) {
    return <Tooltip {...props} overlay={overlay} />;
  }
  return <Fragment {...props} />;
};
TooltipWrapper.defaultProps = {
  overlay: null,
};
TooltipWrapper.propTypes = {
  overlay: PropTypes.node,
};

const OrgPlanAppWorksheetOverviewPage = ({
  orgId,
  planAppId,
  qualifiedWorksheetIds,
  worksheetSections,
  isStateUnderReview
}) => (
  <Fragment>
    {isStateUnderReview && 
      <ChangeRequestManager title="Changes for plan worksheets" showPlanWorksheets />
    }

    <Spacer vertical={20} />

    {worksheetSections.map(section => {
      const worksheetList = R.filter(R.contains(R.__, qualifiedWorksheetIds))(
        section.worksheets,
      );

      return (
        <Panel noBody title={section.name} key={section.uuid}>
          <ListGroup className="osp-worksheet-list">
            {worksheetList.map(wsId => (
              <WorksheetDetails worksheetId={wsId} key={wsId}>
                {({ name, tooltip }) => (
                  <TooltipWrapper overlay={tooltip}>
                    <ListGroupItem to={worksheetRoute(orgId, planAppId, wsId)}>
                      {name}
                      <Block pullRight>
                        <WorksheetStatusIcon worksheetId={wsId} />
                        <NewWorksheetIndicator worksheetId={wsId} />
                      </Block>
                    </ListGroupItem>
                  </TooltipWrapper>
                )}
              </WorksheetDetails>
            ))}
          </ListGroup>
        </Panel>
      );
    })}
  </Fragment>
);

OrgPlanAppWorksheetOverviewPage.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  qualifiedWorksheetIds: PropTypes.arrayOf(sowTypes.planAppWorksheetIdType),
  worksheetSections: PropTypes.arrayOf(sowTypes.planAppWorksheetSectionType).isRequired,
};

export default OrgPlanAppWorksheetOverviewPage;
