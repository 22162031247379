import * as R from 'ramda';
import {
  CREATE_ONE_FOR_ACA_SUCCESS,
  LOAD_ALL_FOR_ACA_SUCCESS,
} from 'sow/actions/ospDefinition';

const ids = (state = [], action) => {
  switch (action.type) {
    case CREATE_ONE_FOR_ACA_SUCCESS:
      return R.union(state, [action.data.result]);

    case LOAD_ALL_FOR_ACA_SUCCESS:
      return action.data.result;

    default:
      return state;
  }
};

export default ids;
