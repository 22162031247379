import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { ResourceDetailLoader } from 'sow/store/helpers';
import { fromPlanApp, fromRouter } from 'sow/store/selectors';
import * as sowTypes from 'sow/types';
import { planAppRequest } from 'sow/store/requests';

const changeActionTypes = ['accept', 'reject', 'update'];

/**
 * Returns URL for the Change resource based on the action. Defaults to base URL
 * which is used for loading changes.
 */
const resourceActionUrl = (action, orgId, planAppId, changeRequestId, changeId) => {
  const baseUrl = planAppRequest.changeListUrl(orgId, planAppId, changeRequestId);
  if (action === 'accept') return `${baseUrl}/${changeId}/accept`;
  if (action === 'reject') return `${baseUrl}/${changeId}/reject`;
  return baseUrl;
};

const mapStateToProps = (state, props) => {
  const orgId = fromRouter.paramOrgId(state, props);
  const planAppId = fromPlanApp.planAppId(state, props);
  const changeRequestId = fromPlanApp.changeRequestId(state, props);
  const changeId = fromPlanApp.changeId(state, props);

  return {
    resourcePath: resourceActionUrl(
      props.action,
      orgId,
      planAppId,
      changeRequestId,
      changeId,
    ),
  };
};

// eslint-disable-next-line no-unused-vars
const PlanAppChangeLoader = ({ resourcePath, action, ...props }) => {
  const entityType = changeActionTypes.includes(action)
    ? 'changeRequestOverviewResp'
    : 'change';

  return (
    <ResourceDetailLoader resource={resourcePath} entityType={entityType} {...props} />
  );
};

PlanAppChangeLoader.defaultProps = {
  autoLoad: false,
};

PlanAppChangeLoader.propTypes = {
  /* only required when action set to accept or reject */
  changeId: sowTypes.planAppChangeIdType,
  action: PropTypes.oneOf(changeActionTypes),
  autoLoad: PropTypes.bool,
  children: PropTypes.func.isRequired,
  resourcePath: PropTypes.string.isRequired,

  // One or more of the following query params may be provided to filter results
  requestParams: PropTypes.shape({
    states: PropTypes.arrayOf(sowTypes.planAppChangeStateType),
    contexts: PropTypes.arrayOf(sowTypes.planAppChangeContextType),
    types: PropTypes.arrayOf(sowTypes.planAppChangeTypeType),
    actions: PropTypes.arrayOf(sowTypes.planAppChangeActionType),
    application_ids: PropTypes.arrayOf(sowTypes.planAppIdType),
    product_ids: PropTypes.arrayOf(sowTypes.planAppProductIdType),
    landIds: PropTypes.arrayOf(sowTypes.planAppLocationIdType),
    worksheet_ids: PropTypes.arrayOf(sowTypes.planAppWorksheetIdType),
    matrixRowIds: PropTypes.arrayOf(sowTypes.planAppWorksheetQuestionMatrixRowIdType),
    questionIds: PropTypes.arrayOf(sowTypes.planAppWorksheetQuestionIdType),
  }),
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(PlanAppChangeLoader);
