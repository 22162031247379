import * as R from 'ramda';

import * as ospAppAction from 'sow/actions/ospApplication';

import { allQuestionIds } from '../helpers';

const ids = (state = [], action) => {
  switch (action.type) {
    case ospAppAction.CREATE_ONE_FOR_ORG_SUCCESS:
    case ospAppAction.LOAD_ONE_FOR_ORG_SUCCESS:
      return allQuestionIds(action);

    default:
      return state;
  }
};

export default ids;
