import React from 'react';
import PropTypes from 'prop-types';

import { authCredentialsType } from 'sow/types';
import { requestNewPasswordRoute } from 'sow/routes';

import Input from 'sow/components/atoms/Input';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormGroup from 'sow/components/organisms/FormGroup';
import Link from 'sow/components/atoms/Link';

const LoginForm = ({ credentials, onChange }) =>
  <Row>
    <Column md={6}>
      <FormGroup fieldName="email" label="Email address">
        <Input
          id="email"
          value={credentials.email}
          onChange={e => onChange('email', e.target.value)}
        />
      </FormGroup>
    </Column>

    <Column md={6}>
      <FormGroup fieldName="password" label="Password" style={{ marginBottom: 0 }}>
        <Input
          type="password"
          id="password"
          value={credentials.password}
          onChange={e => onChange('password', e.target.value)}
        />
      </FormGroup>

      <Link to={requestNewPasswordRoute()}>Forgot your password?</Link>
    </Column>
  </Row>;

LoginForm.propTypes = {
  credentials: authCredentialsType.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LoginForm;
