import React from 'react';

import {
  certificationTypeRoute,
  consultantCompaniesRoute,
  organizationTypeRoute,
  announcementTypeRoute,
  announcementsRoute,
  accessTypeRoute,
  inspectionYearRoute,
  priorityTypeRoute,
  actionGroupRoute,
} from 'sow/routes';

import SystemAdminTemplateContainer from 'sow/containers/SystemAdminTemplateContainer';
import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';
import Paragraph from 'sow/components/atoms/Paragraph';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const SystemAdminObjectHome = () => (
  <SystemAdminTemplateContainer>
    <Widget className="nav-widget">
      <WidgetBody>
        <Block className="system-admin-home">
          <Paragraph>Click a field below to edit its list of options</Paragraph>
          <Row>
            {/* Copy this block of code to create new admin option. */}
            <Column xs={12} sm={6} md={4} lg={3}>
              <Link className="field-block-link" to={certificationTypeRoute()}>
                <Widget className="field-block">
                  <WidgetBody className="field-block-body">
                    <FontAwesome icon="certificate" />
                    <Paragraph>Certification Types</Paragraph>
                  </WidgetBody>
                </Widget>
              </Link>
            </Column>
            <Column xs={12} sm={6} md={4} lg={3}>
              <Link className="field-block-link" to={consultantCompaniesRoute()}>
                <Widget className="field-block">
                  <WidgetBody className="field-block-body">
                    <FontAwesome icon="user-tie" />
                    <Paragraph>Consultant Companies</Paragraph>
                  </WidgetBody>
                </Widget>
              </Link>
            </Column>
            <Column xs={12} sm={6} md={4} lg={3}>
              <Link className="field-block-link" to={organizationTypeRoute()}>
                <Widget className="field-block">
                  <WidgetBody className="field-block-body">
                    <FontAwesome icon="users-line" />
                    <Paragraph>Organization Types</Paragraph>
                  </WidgetBody>
                </Widget>
              </Link>
            </Column>
            <Column xs={12} sm={6} md={4} lg={3}>
              <Link className="field-block-link" to={announcementTypeRoute()}>
                <Widget className="field-block">
                  <WidgetBody className="field-block-body">
                    <FontAwesome icon="megaphone" />
                    <Paragraph>Announcement Types</Paragraph>
                  </WidgetBody>
                </Widget>
              </Link>
            </Column>
            <Column xs={12} sm={6} md={4} lg={3}>
              <Link className="field-block-link" to={announcementsRoute()}>
                <Widget className="field-block">
                  <WidgetBody className="field-block-body">
                    <FontAwesome icon="bullhorn" />
                    <Paragraph>Announcements</Paragraph>
                  </WidgetBody>
                </Widget>
              </Link>
            </Column>
            <Column xs={12} sm={6} md={4} lg={3}>
              <Link className="field-block-link" to={accessTypeRoute()}>
                <Widget className="field-block">
                  <WidgetBody className="field-block-body">
                    <FontAwesome icon="address-card" />
                    <Paragraph>Access Types</Paragraph>
                  </WidgetBody>
                </Widget>
              </Link>
            </Column>
            <Column xs={12} sm={6} md={4} lg={3}>
              <Link className="field-block-link" to={inspectionYearRoute()}>
                <Widget className="field-block">
                  <WidgetBody className="field-block-body">
                    <FontAwesome icon="calendar-check" />
                    <Paragraph>Inspection Year</Paragraph>
                  </WidgetBody>
                </Widget>
              </Link>
            </Column>
            <Column xs={12} sm={6} md={4} lg={3}>
              <Link className="field-block-link" to={priorityTypeRoute()}>
                <Widget className="field-block">
                  <WidgetBody className="field-block-body">
                    <FontAwesome icon="list-ol" />
                    <Paragraph>Priority Types</Paragraph>
                  </WidgetBody>
                </Widget>
              </Link>
            </Column>
            <Column xs={12} sm={6} md={4} lg={3}>
              <Link className="field-block-link" to={actionGroupRoute()}>
                <Widget className="field-block">
                  <WidgetBody className="field-block-body">
                    <FontAwesome icon="angles-right" />
                    <Paragraph>Action Groups</Paragraph>
                  </WidgetBody>
                </Widget>
              </Link>
            </Column>
          </Row>
        </Block>
      </WidgetBody>
    </Widget>
  </SystemAdminTemplateContainer>
);

export default SystemAdminObjectHome;
