import { call, takeEvery } from 'redux-saga/effects';

import * as organizationHistoryActions from 'sow/actions/pure/organizationHistory';
import { apiGet } from 'sow/sagas/api';

export const getOrganizationHistory = function*({ payload, meta }) {
  const { orgId } = payload;
  try {
    const { response } = yield call(apiGet, `/org/${orgId}/history`);
    if (response.data && meta.onSuccess) {
      meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(organizationHistoryActions.LOAD_ALL, getOrganizationHistory);
}
