import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { acaIdType } from 'sow/types';
import actions from 'sow/actions/pure';
import * as currentAca from 'sow/selectors/currentAca';
import * as orgSelect from 'sow/selectors/core/organization';
import Spinner from 'sow/components/atoms/Spinner';

const mapStateToProps = (state, props) => ({
  acaId: currentAca.acaId(state, props),
  isLoadingOrgs: orgSelect.isLoadingList(state, props),
});

const mapDispatchToProps = {
  loadOrgs: actions.org.loadAcaList,
};

class AcaOrgListLoader extends Component {
  componentDidMount() {
    this.loadOrgs();
  }

  loadOrgs() {
    const { isLoadingOrgs, acaId, loadOrgs } = this.props;
    if (!isLoadingOrgs) loadOrgs(acaId);
  }

  render() {
    const { children, isLoadingOrgs } = this.props;
    return isLoadingOrgs ? <Spinner /> : children;
  }
}

AcaOrgListLoader.propTypes = {
  children: PropTypes.node.isRequired,
  acaId: acaIdType.isRequired,
  isLoadingOrgs: PropTypes.bool.isRequired,
  loadOrgs: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AcaOrgListLoader);
