import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Block from 'sow/components/atoms/Block';

const ActionBarWrapper = styled(Block)`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(240, 240, 240, 0.9);
  box-shadow: 0 0 10px #777;
`;

const PlanAppActionBar = ({ children }) => {
  if (!children) return null;

  return (
    <ActionBarWrapper>
      <Block className="container" textCenter>
        {children}
      </Block>
    </ActionBarWrapper>
  );
};

PlanAppActionBar.propTypes = {
  children: PropTypes.node,
};

PlanAppActionBar.defaultProps = {
  children: null,
};

export default PlanAppActionBar;
