const mark = action => `ANNOUNCEMENT_TYPE/${action}`;

export const LOAD_ANNOUNCEMENT_TYPES = mark('LOAD_ANNOUNCEMENT_TYPES');
export const LOAD_ANNOUNCEMENT_TYPES_DONE = mark('LOAD_ANNOUNCEMENT_TYPES_DONE');
export const LOAD_ANNOUNCEMENT_TYPE_BY_ID = mark('LOAD_ANNOUNCEMENT_TYPE_BY_ID');
export const ADD_ANNOUNCEMENT_TYPE = mark('ADD_ANNOUNCEMENT_TYPE');
export const DELETE_ANNOUNCEMENT_TYPE = mark('DELETE_ANNOUNCEMENT_TYPE');
export const UPDATE_SORT_ORDER = mark('UPDATE_SORT_ORDER');
export const UPDATE_ANNOUNCEMENT_TYPE = mark('UPDATE_ANNOUNCEMENT_TYPE');

export const loadAnnouncementTypes = (payload, onSuccess) => ({
  type: LOAD_ANNOUNCEMENT_TYPES,
  payload,
  meta: { onSuccess },
});

export const loadAnnouncementTypesDone = payload => ({
  type: LOAD_ANNOUNCEMENT_TYPES_DONE,
  payload,
});

export const loadAnnouncementTypeById = (id, onSuccess) => ({
  type: LOAD_ANNOUNCEMENT_TYPE_BY_ID,
  id,
  meta: { onSuccess },
});

export const addAnnouncementType = (payload, onSuccess) => ({
  type: ADD_ANNOUNCEMENT_TYPE,
  payload,
  meta: { onSuccess },
});

export const updateAnnouncementType = (id, payload, onSuccess) => ({
  type: UPDATE_ANNOUNCEMENT_TYPE,
  id,
  payload,
  meta: { onSuccess },
});

export const updateSortOrder = (payload, onSuccess) => ({
  type: UPDATE_SORT_ORDER,
  payload,
  meta: { onSuccess },
});

export const deleteAnnouncementType = (id, onSuccess) => ({
  type: DELETE_ANNOUNCEMENT_TYPE,
  id,
  meta: { onSuccess },
});
