import * as R from 'ramda';
import * as ospAppAction from 'sow/actions/ospApplication';
import ids from './ids';

const mapReducer = (state = {}, action) =>
  R.merge(state, {
    ids: ids(state.ids, action),
  });

const mapKey = action => action.data.ospAppId;
const keyLens = R.compose(R.lensProp, mapKey);
const update = (state, action) =>
  R.over(keyLens(action), R.flip(mapReducer)(action), state);

const listByOspAppId = (state = {}, action) => {
  switch (action.type) {
    case ospAppAction.CREATE_ONE_FOR_ORG_SUCCESS:
    case ospAppAction.LOAD_ONE_FOR_ORG_SUCCESS:
      return update(state, action);

    case 'ADD_DROP_ACTION_TYPES_HERE':
      // TODO (orther) implement this or remove the case
      return R.dissoc(mapKey(action), state);

    default:
      return state;
  }
};

export default listByOspAppId;

export const getIds = (state, ospAppId) => R.pathOr([], [ospAppId, 'ids'], state);
