import React from 'react';
import PropTypes from 'prop-types';

import Panel from 'sow/components/molecules/Panel';

const InfoPanel = ({ text, ...props }) => <Panel warning heading={text} {...props} />;

InfoPanel.propTypes = {
  text: PropTypes.node,
};

export default InfoPanel;
