import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push as routerPush } from 'connected-react-router';
import PropTypes from 'prop-types';

import { validateMail } from 'sow/actions/pure/registration';
import { loginRoute } from 'sow/routes';
import CenteredLayout from 'sow/components/templates/CenteredLayout';
import Spinner from 'sow/components/atoms/Spinner';

const mapStateToProps = state => ({
  isValidatingMail: state.registration.isValidatingMail,
});

const mapDispatchToProps = {
  validateMail,
  redirect: routerPush,
};

class ValidateMailPageContainer extends Component {
  componentDidMount() {
    const { match, validateMail } = this.props;
    validateMail(match.params.emailHash, this.handleValidationDone);
  }

  handleValidationDone = () => {
    const { redirect } = this.props;
    redirect(loginRoute());
  };

  render() {
    return (
      <CenteredLayout>
        <Spinner />
      </CenteredLayout>
    );
  }
}

ValidateMailPageContainer.propTypes = {
  // Connected props
  isValidatingMail: PropTypes.bool.isRequired,
  validateMail: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ValidateMailPageContainer);
