import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import * as sowTypes from 'sow/types';
import { FormattedDatetime } from './style/atoms';
import PendingAsterisk from 'sow/components/molecules/PendingAsterisk';

// --
// -- Components
// --

const HeaderWrapper = styled.div`
  p {
    font-size: 26px;
    margin-top: 60px;
    text-align: center;
  }

  .legend {
    margin-bottom: 60px;
  }
`;

const Header = ({ planAppId, planAppSummary }) => {
  const ospDefName = R.path(['ospDefinition', 'name'], planAppSummary);
  return (
    <HeaderWrapper>
      Exported from WFCF Organic on{' '}
      <strong>
        <FormattedDatetime />
      </strong>
      <p>
        {ospDefName} #{planAppId}
      </p>
      <div className="legend">
        <PendingAsterisk centered updated>
          Will mark pending change
        </PendingAsterisk>
      </div>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  planAppId: sowTypes.planAppIdType.isRequired,
  planAppSummary: sowTypes.planAppSummaryType.isRequired,
};

Header.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  planAppSummary: sowTypes.planAppSummaryType.isRequired,
};

export default Header;
