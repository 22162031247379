import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import Input from 'sow/components/atoms/Input';

const defaultNote = () => ({
  text: '',
});

class NoteForm extends Component {
  state = {
    note: defaultNote(),
  };

  handleChange = event =>
    this.setState(R.assocPath(['note', 'text'], event.target.value));

  handleSubmit = () => {
    this.props.onCreate(this.state.note, this.handleCreateSuccess);
  };

  handleCreateSuccess = () => this.setState(R.assoc('note', defaultNote()));

  render() {
    const { note } = this.state;
    const { isCreating } = this.props;

    return (
      <Block>
        <Input
          type="textarea"
          value={note.text}
          onChange={this.handleChange}
          placeholder="Type a new note here"
        />
        <Button primary onClick={this.handleSubmit} disabled={isCreating}>
          Add Note
        </Button>
      </Block>
    );
  }
}

NoteForm.propTypes = {
  onCreate: PropTypes.func.isRequired,
  isCreating: PropTypes.bool.isRequired,
};

export default NoteForm;
