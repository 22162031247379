import * as R from 'ramda';
import { createSafeCachedSelector } from 'sow/utils/selectors';

import * as currentOspApp from 'sow/selectors/currentOspApp';
import * as wsAnswersReducer from 'sow/reducers/worksheetAnswers';
import * as wsAnswersSelect from 'sow/selectors/core/worksheetAnswers';
import * as wsQuestionReducer from 'sow/reducers/worksheetQuestions';
import * as wsReducer from 'sow/reducers/worksheets';
import * as wsSelect from 'sow/selectors/core/worksheet';

export const worksheetId = createSafeCachedSelector(
  [wsSelect.currentWorksheetId],
  R.identity,
)(wsSelect.currentWorksheetId);

export const worksheet = createSafeCachedSelector(
  [wsSelect.currentWorksheet],
  R.identity,
)(worksheetId);

export const questionIds = createSafeCachedSelector([worksheet], R.path(['questions']))(
  worksheetId,
);

export const worksheetWithQuestions = createSafeCachedSelector(
  [wsQuestionReducer.wsQuestionState, worksheet],
  (state, worksheet) => {
    const questionIds = R.path(['questions'], worksheet);
    return worksheet.merge({
      questions: wsQuestionReducer.getQuestions(state, questionIds),
    });
  },
)(worksheetId);

export const nextWorksheetId = createSafeCachedSelector(
  [worksheetId, currentOspApp.qualifiedWorksheetIdsOrdered],
  (currentWorksheetId, allWorksheetIds) => {
    if (!currentWorksheetId) return currentWorksheetId;
    if (!allWorksheetIds) return currentWorksheetId;

    const isCurrentWorksheetId = R.complement(R.equals(currentWorksheetId));

    return R.compose(
      R.head,
      R.takeLastWhile(isCurrentWorksheetId),
    )(allWorksheetIds);
  },
)(worksheetId);

export const prevWorksheetId = createSafeCachedSelector(
  [worksheetId, currentOspApp.qualifiedWorksheetIdsOrdered],
  (currentWorksheetId, allWorksheetIds) => {
    if (!currentWorksheetId) return currentWorksheetId;
    if (!allWorksheetIds) return currentWorksheetId;

    const isCurrentWorksheetId = R.complement(R.equals(currentWorksheetId));

    return R.compose(
      R.last,
      R.takeWhile(isCurrentWorksheetId),
    )(allWorksheetIds);
  },
)(worksheetId);

export const worksheetName = createSafeCachedSelector([worksheet], wsReducer.getName)(
  worksheetId,
);

export const worksheetHeader = createSafeCachedSelector(
  [worksheet],
  R.propOr(null, 'header'),
)(worksheetId);

export const worksheetRegulations = createSafeCachedSelector(
  [worksheet],
  R.propOr(null, 'regulations'),
)(worksheetId);

export const worksheetAnswers = createSafeCachedSelector(
  [worksheetId, currentOspApp.qualifiedWorksheetAnswersMap],
  (worksheetId, wsAnswersMap) => R.path([worksheetId], wsAnswersMap),
)(worksheetId);

export const worksheetAnswersChanges = createSafeCachedSelector(
  [wsAnswersSelect.worksheetAnswersState, currentOspApp.ospAppId, worksheetId],
  wsAnswersSelect.getChangesForWorksheet,
)(worksheetId);

export const worksheetAnswersWithChanges = createSafeCachedSelector(
  [worksheetAnswers, worksheetAnswersChanges],
  wsAnswersReducer.mergeWorksheetAnswers,
)(worksheetId);

export const rawWorksheetAnswersChanges = createSafeCachedSelector(
  [wsAnswersSelect.worksheetAnswersState, currentOspApp.ospAppId, worksheetId],
  wsAnswersSelect.getRawChangesForWorksheet,
)(worksheetId);

export const hasChanges = createSafeCachedSelector(
  [rawWorksheetAnswersChanges],
  changes => {
    console.log(changes);
    return changes;
  },
)(worksheetId);

export const isNotApplicable = createSafeCachedSelector(
  [worksheetAnswersWithChanges],
  R.propOr(false, 'isNotApplicable'),
)(worksheetId);

export const isReadyForReview = createSafeCachedSelector(
  [worksheetAnswersWithChanges],
  R.propOr(false, 'readyForReview'),
)(worksheetId);

export const makeWorksheetAnswers = worksheetId =>
  createSafeCachedSelector([currentOspApp.worksheetAnswersMap], wsAnswers => {
    return R.path([worksheetId])(wsAnswers);
  })(worksheetId);

export const makeWorksheetAnswersChanges = worksheetId =>
  createSafeCachedSelector(
    [wsAnswersSelect.worksheetAnswersState, currentOspApp.ospAppId],
    (wsAnswersState, ospAppId) => {
      const changes = wsAnswersSelect.getChangesForWorksheet(
        wsAnswersState,
        ospAppId,
        worksheetId,
      );
      return changes;
    },
  )(worksheetId);

export const makeWorksheetAnswersWithChanges = worksheetId =>
  createSafeCachedSelector(
    [makeWorksheetAnswers(worksheetId), makeWorksheetAnswersChanges(worksheetId)],
    wsAnswersReducer.mergeWorksheetAnswers,
  )(worksheetId);

// const getSectionFromProps = (state, props) => R.path(['section'], props);
export const makeWorksheetAnswersForMiniOsp = worksheetId =>
  createSafeCachedSelector([currentOspApp.worksheetAnswersMapForMiniOsp], wsAnswers => {
    return R.path([worksheetId])(wsAnswers);
  })(worksheetId);

export const makeWorksheetAnswersWithChangesForMiniOsp = worksheetId =>
  createSafeCachedSelector(
    [
      makeWorksheetAnswersForMiniOsp(worksheetId),
      makeWorksheetAnswersChanges(worksheetId),
    ],
    wsAnswersReducer.mergeWorksheetAnswers,
  )(worksheetId);

// TODO (orther) Clean this all up and simplify the reducer
export const makeMiniOspAnswers = sectionSelector => (state, props) => {
  const ospAppId = currentOspApp.ospAppId(state, props);
  const section = sectionSelector(state, props);
  const worksheetIds = R.path(['worksheets'], section);

  if (!worksheetIds || !ospAppId || !section) {
    return {};
  }

  const answersList = R.map(wsId => {
    // return [wsId, makeWorksheetAnswersWithChanges(wsId)(state, props)];
    return [wsId, makeWorksheetAnswersWithChangesForMiniOsp(wsId)(state, props)];
  })(worksheetIds);

  return {
    worksheets: R.fromPairs(answersList),
  };
};
