import React from 'react';
import * as R from 'ramda';

import WorksheetContainer from '../Worksheet/WorksheetContainer';

class OspAppEditorPreviewWorksheet extends React.Component {
  static defaultProps = {
    isLoadingAnswers: false,
    orgId: 0,
    ospAppId: 0,
  };

  state = {
    worksheetAnswers: {
      answers: {
        values: {},
      },
      isNotApplicable: false,
      readyForReview: false,
    },
  };

  getAnswer = questionId => {
    return R.path(['worksheetAnswers', 'answers', 'values', questionId], this.state);
  };

  handleChangeAnswer = (questionId, value) => {
    this.setState(
      R.assocPath(['worksheetAnswers', 'answers', 'values', questionId], value),
    );
  };

  handleChangeBaseAnswer = (name, value) => {
    this.setState(R.assocPath(['worksheetAnswers', name], value));
  };

  getQuestionById = id => {
    const { questions } = this.props;
    return R.path([id], questions);
  };

  render() {
    const { isLoadingAnswers, orgId, ospAppId } = this.props;
    const { worksheet, questions } = this.props;
    const { worksheetAnswers } = this.state;

    const questionList = R.map(this.getQuestionById, worksheet.questions);

    return (
      <WorksheetContainer
        orgId={orgId}
        ospAppId={ospAppId}
        worksheetId={worksheet.uuid}
        worksheet={R.assoc('questions', questionList, worksheet)}
        questions={questions}
        wsAnswers={worksheetAnswers}
        getAnswer={this.getAnswer}
        handleChangeBaseAnswer={this.handleChangeBaseAnswer}
        handleChangeAnswer={this.handleChangeAnswer}
      />
    );
  }
}

export default OspAppEditorPreviewWorksheet;
