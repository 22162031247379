import React from 'react';
import * as R from 'ramda';
import { MenuItem, Glyphicon } from 'react-bootstrap';
import FormPanel from '../FormPanel';
import WorksheetRegulationForm from './WorksheetRegulationForm';

export const WorksheetRegulations = ({
  worksheetId,
  regulations,
  onAddRegulation,
  onDeleteRegulation,
  onSetRegulationValue,
  onMoveRegulationDown,
  onMoveRegulationUp,
}) => {
  return (
    <FormPanel>
      <FormPanel.Heading>
        Guidance &amp; Additional Resources{' '}
        <FormPanel.Heading.Dropdown>
          <MenuItem onClick={onAddRegulation(worksheetId)}>
            <Glyphicon glyph="plus" /> Add Guidance / Additional Resources
          </MenuItem>
        </FormPanel.Heading.Dropdown>
      </FormPanel.Heading>
      <FormPanel.Body>
        {R.map(regulation => {
          const regulationId = R.path(['uuid'], regulation);
          return (
            <FormPanel.Body.Row
              key={regulationId}
              onDelete={onDeleteRegulation(worksheetId, regulationId)}
              onMoveDown={onMoveRegulationDown(worksheetId, regulationId)}
              onMoveUp={onMoveRegulationUp(worksheetId, regulationId)}
            >
              <WorksheetRegulationForm
                regulation={regulation}
                regulationId={regulationId}
                onSetValue={onSetRegulationValue(worksheetId, regulationId)}
              />
            </FormPanel.Body.Row>
          );
        }, regulations)}
      </FormPanel.Body>
    </FormPanel>
  );
};

export default WorksheetRegulations;
