import React from 'react';
import PropTypes from 'prop-types';

import Input from 'sow/components/atoms/Input';
import InputGroup from 'sow/components/molecules/InputGroup';
import Glyphicon from 'sow/components/molecules/Glyphicon';

const SearchField = ({ value, placeholder, onChange }) => (
  <InputGroup lg addonBefore={<Glyphicon glyph="search" />}>
    <Input placeholder={placeholder} value={value} onChange={onChange} />
  </InputGroup>
);

SearchField.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SearchField;
