const mark = action => `ITEM_VERSION/${action}`;

export const LOAD_ITEM_VERSIONS = mark('LOAD_ITEM_VERSIONS');
export const FETCH_BY_ID = mark('FETCH_BY_ID');
export const FETCH_BY_ID_DONE = mark('FETCH_BY_ID_DONE');
export const ADD_ITEM_VERSION = mark('ADD_ITEM_VERSION');
export const ADD_ITEM_VERSION_DONE = mark('ADD_ITEM_VERSION_DONE');
export const UPDATE_ITEM_VERSION = mark('UPDATE_ITEM_VERSION');
export const UPDATE_ITEM_VERSION_DONE = mark('UPDATE_ITEM_VERSION_DONE');
export const UPDATE_SORT_ORDER = mark('UPDATE_SORT_ORDER');
export const DELETE_ITEM_VERSION = mark('DELETE_ITEM_VERSION');
export const LOAD_OPTIONS = mark('LOAD_OPTIONS');
export const GET_HISTORY = mark('GET_HISTORY');
export const CLEAR = mark('CLEAR');

export const loadItemVersions = (payload, onSuccess) => ({
  type: LOAD_ITEM_VERSIONS,
  payload,
  meta: { onSuccess },
});

export const fetchById = (payload, onSuccess) => ({
  type: FETCH_BY_ID,
  payload,
  meta: { onSuccess },
});

export const fetchByIdDone = payload => ({
  type: FETCH_BY_ID_DONE,
  payload,
});

export const addItemVersion = (payload, onSuccess) => ({
  type: ADD_ITEM_VERSION,
  payload,
  meta: { onSuccess },
});

export const addItemVersionDone = payload => ({
  type: ADD_ITEM_VERSION_DONE,
  payload,
});

export const updateItemVersion = (id, payload, onSuccess) => ({
  type: UPDATE_ITEM_VERSION,
  id,
  payload,
  meta: { onSuccess },
});

export const updateItemVersionDone = payload => ({
  type: UPDATE_ITEM_VERSION_DONE,
  payload,
});

export const deleteItemVersion = (id, onSuccess) => ({
  type: DELETE_ITEM_VERSION,
  id,
  meta: { onSuccess },
});

export const loadOptions = onSuccess => ({
  type: LOAD_OPTIONS,
  meta: { onSuccess },
});

export const getItemVersionHistory = (id, onSuccess) => ({
  type: GET_HISTORY,
  id,
  meta: { onSuccess },
});

export const clear = () => ({
  type: CLEAR,
});
