import React from 'react';

import AcaPageTemplate from 'sow/containers/AcaPageTemplate';
import LocationReportFiltersContainer from 'sow/containers/LocationReportFiltersContainer';
import LocationReportTableContainer from 'sow/containers/LocationReportTableContainer';

const LocationReportPage = () => (
  <AcaPageTemplate title="Locations">
    <LocationReportFiltersContainer />
    <LocationReportTableContainer />
  </AcaPageTemplate>
);

export default LocationReportPage;
