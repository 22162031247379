import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import { apiGet } from 'sow/sagas/api';

export function* filterOptionsLoad() {
  try {
    const { response } = yield call(apiGet, '/product/search_options');

    if (response) {
      yield put(actions.products.fetchFilterOptionsDone(response.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* loadList(params, onSuccess) {
  try {
    const { response, error } = yield call(apiGet, '/product/product_search', params);
    if (response) {
      const { payload } = yield put(actions.products.productsSearchDone(response));
      if (payload && onSuccess) {
        onSuccess();
      }
    } else if (error) {
      yield put(actions.products.productsSearchDone([]));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchFilterOptionsLoad() {
  try {
    yield call(filterOptionsLoad);
  } catch (error) {
    console.error(error);
  }
}

export function* watchLoadList({ payload }) {
  const { params, onSuccess } = payload;
  try {
    yield call(loadList, params, onSuccess);
  } catch (error) {
    console.error(error);
  }
}

export default function* rootSaga() {
  try {
    yield takeEvery(actions.products.productSearch, watchLoadList);
    yield takeEvery(actions.products.fetchFilterOptions, watchFilterOptionsLoad);
  } catch (error) {
    console.error(error);
  }
}
