import React from 'react';

import AdminObjectTemplateContainer from 'sow/containers/AdminObjectTemplateContainer';
import ItemVersionListContainer from 'sow/containers/ItemVersionListContainer';

const ItemVersionListPage = () => (
  <AdminObjectTemplateContainer pageName="Item Versions" list>
    <ItemVersionListContainer />
  </AdminObjectTemplateContainer>
);

export default ItemVersionListPage;
