import * as R from 'ramda';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as currentAca from 'sow/selectors/currentAca';
import NotFoundPage from 'sow/components/pages/ErrorNotFoundPage';
import { acaSlugToId } from 'sow/utils/aca';
import Register from './Register';

const mapStateToProps = (state, props) => {
  const acaSlug = currentAca.acaSlug(state, props);
  const acaId = acaSlugToId(acaSlug);
  const isValidSlug = acaSlug === 'ccof';

  return {
    acaId,
    acaSlug,
    showPage: acaId && isValidSlug,
  };
};

const AcaRegister = ({ showPage, acaId }) =>
  showPage ? <Register acaId={acaId} /> : <NotFoundPage />;

AcaRegister.propTypes = {
  acaId: PropTypes.number,
  acaSlug: PropTypes.string,
  showPage: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(AcaRegister);
