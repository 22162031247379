import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import actions from 'sow/actions/pure';
import { planAppChangeCommentIdType } from 'sow/types';
import { PlanAppChangeCommentDismissLoader } from 'sow/store/containers';
import ChangeCommentDismissButton from 'sow/components/organisms/PlanAppChangeCommentDismissButton';

const mapDispatchToProps = {
  toast: actions.shell.toast,
};

const ChangeCommentDismissButtonContainer = ({ toast, changeCommentId, ...props }) => (
  <PlanAppChangeCommentDismissLoader {...props}>
    {({ status, updateResource }) => (
      <ChangeCommentDismissButton
        isDisabled={status.loading}
        onClick={event => {
          event.preventDefault;
          updateResource({ change_comment_ids: [changeCommentId] }).then(() =>
            toast('success', 'Comment marked read'),
          );
        }}
      />
    )}
  </PlanAppChangeCommentDismissLoader>
);

ChangeCommentDismissButtonContainer.propTypes = {
  changeCommentId: planAppChangeCommentIdType.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps,
  ),
)(ChangeCommentDismissButtonContainer);
