import React from 'react';
import * as R from 'ramda';
import { DropdownButton, MenuItem, Glyphicon } from 'react-bootstrap';
import { connect } from 'react-redux';

import FormGroup from 'sow/components/organisms/FormGroup';
import FormModal from 'sow/components/old/FormModal';
import {
  default as FormPanel,
  ButtonMoveDown,
  ButtonMoveUp,
} from 'sow/components/old/OspDefEditor/FormPanel';
import { confirm } from 'sow/actions/pure/messaging';
import { hocEditable } from 'sow/components/old/hocHelpers/uiState';
import * as currentUser from 'sow/selectors/currentUser';

import * as select from '../selectors';
import Worksheet from '../Worksheet';
import { actions as ospDefAction } from '../reducers/ospDef';
import { actions as ospSecAction } from '../reducers/ospSections';
import { createWorksheet } from '../reducers/worksheets';

const mapDispatchToProps = (dispatch, props) => {
  const ospSectionId = props.ospSectionId;
  return {
    onDelete: event => {
      dispatch(
        confirm({
          message: 'Are you sure you want to delete this section?',
          onConfirm() {
            dispatch(ospDefAction.sectionRemove(ospSectionId));
          },
        }),
      );
    },

    onMoveDown: R.curry(event => {
      dispatch(ospDefAction.sectionMoveDown(ospSectionId));
    }),
    onMoveUp: R.curry(event => {
      dispatch(ospDefAction.sectionMoveUp(ospSectionId));
    }),
    onSetInputValue: R.curry((field, event) => {
      dispatch(ospSecAction.setValue(ospSectionId, field, event.target.value));
    }),
    onAddWorksheet: event => {
      const newWorksheet = createWorksheet();
      dispatch(ospSecAction.worksheetAdd(ospSectionId, newWorksheet));
    },
    onMoveWorksheetDown: R.curry((worksheetId, event) => {
      dispatch(ospSecAction.worksheetMoveDown(ospSectionId, worksheetId));
    }),
    onMoveWorksheetUp: R.curry((worksheetId, event) => {
      dispatch(ospSecAction.worksheetMoveUp(ospSectionId, worksheetId));
    }),

    onScopeWorksheetsEditStart: event => {
      dispatch(ospSecAction.scopeWorksheetsEditStart(ospSectionId));
    },
    // onScopeWorksheetsEditDone: R.curry((scope, event) => {
    //   dispatch(ospSecAction.scopeWorksheetsEditDone(ospSectionId, scope));
    // }),
  };
};

const makeMapStateToProps = () => (state, props) => ({
  ospSection: select.makeOspSectionSelector()(state, props),
  canEditOspDef: currentUser.canEditOspDef(state, props),
});

export const OspSection = ({
  ospSection,
  ospSectionId,
  onDelete,
  onMoveDown,
  onMoveUp,
  onSetInputValue,
  onAddWorksheet,
  onMoveWorksheetDown,
  onMoveWorksheetUp,
  onScopeWorksheetsEditStart,

  isEditing,
  onEditDone,
  onEditStart,
  miniOsp,
  locationSection,
  sectionType,
  canEditOspDef,
  ...props
}) => {
  if (!ospSection) return null;
  const errors = {};
  const worksheetIds = ospSection.worksheets;
  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <div className="panel-title">
          <div className="row">
            <div className="col-xs-9">{ospSection.name}</div>
            <div className="col-xs-3 text-right">
              {!miniOsp && !locationSection && (
                <ButtonMoveUp
                  btnColor="info"
                  className="btn-xs"
                  style={{ marginRight: 3 }}
                  onClick={onMoveUp}
                />
              )}
              {!miniOsp && !locationSection && (
                <ButtonMoveDown
                  btnColor="info"
                  className="btn-xs"
                  style={{ marginRight: 3 }}
                  onClick={onMoveDown}
                />
              )}
              <DropdownButton
                id="OspDefEditor-OspSectionActionsButton"
                title={<Glyphicon glyph="cog" />}
                bsSize="xs"
                pullRight
                disabled={!canEditOspDef}
              >
                {miniOsp && <MenuItem header>Scope</MenuItem>}
                {miniOsp && (
                  <MenuItem onClick={onScopeWorksheetsEditStart}>
                    <Glyphicon glyph="check" /> Location/Product Plan Worksheets
                  </MenuItem>
                )}
                {miniOsp && <MenuItem divider />}

                <MenuItem header>Worksheet</MenuItem>
                <MenuItem onClick={onAddWorksheet}>
                  <Glyphicon glyph="plus" /> Add Worksheet
                </MenuItem>

                {!miniOsp && <MenuItem divider />}
                {!miniOsp && !locationSection && <MenuItem header>Section</MenuItem>}
                {!miniOsp && (
                  <MenuItem onClick={onEditStart}>
                    <Glyphicon glyph="pencil" />{' '}
                    {locationSection ? 'Edit Location Type' : 'Edit Section'}
                  </MenuItem>
                )}
                {!miniOsp && !locationSection && (
                  <MenuItem onClick={onDelete}>
                    <Glyphicon glyph="remove" /> Delete Section
                  </MenuItem>
                )}
              </DropdownButton>
            </div>
          </div>
        </div>
      </div>
      <FormPanel.Body>
        {worksheetIds &&
          worksheetIds.map(worksheetId => (
            <Worksheet
              key={worksheetId}
              ospSectionId={ospSectionId}
              worksheetId={worksheetId}
              onMoveWorksheetDown={onMoveWorksheetDown}
              onMoveWorksheetUp={onMoveWorksheetUp}
              sectionType={sectionType}
            />
          ))}
      </FormPanel.Body>
      <FormModal
        show={isEditing}
        title="Edit Section"
        onHide={onEditDone}
        onSubmit={onEditDone}
      >
        <FormGroup fieldName="name" error={errors} label="Plan Section Name" required>
          <input
            type="text"
            id="name"
            className="form-control"
            onChange={onSetInputValue('name')}
            value={ospSection.name}
            required
          />
        </FormGroup>

        {!locationSection && (
          <FormGroup
            fieldName="tooltip"
            error={errors}
            label="Plan Section Tooltip"
            tooltip="Use this to enter helpful text for your clients that is specific to the Worksheet Section you are creating."
          >
            <input
              type="text"
              id="tooltip"
              className="form-control"
              onChange={onSetInputValue('tooltip')}
              value={ospSection.tooltip}
            />
          </FormGroup>
        )}
      </FormModal>
    </div>
  );
};

export default R.compose(
  connect(makeMapStateToProps, mapDispatchToProps),
  hocEditable,
)(OspSection);
