import React, { Fragment } from 'react';

import { orgRoute } from 'sow/routes';
import {
  inspectionStates,
  inspectionStatesRank,
} from 'sow/constants/inspectionConstants';
import { monthDayYearDateFormat } from 'sow/utils/dateTime';
import { API_URL } from 'sow/constants/api';

import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Link from 'sow/components/atoms/Link';
import SampleFlag from 'sow/components/molecules/SampleFlag';
import Block from 'sow/components/atoms/Block';
import Paragraph from 'sow/components/atoms/Paragraph';
import Button from 'sow/components/atoms/Button';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import PriorityFlag from 'sow/components/molecules/PriorityFlag';
import Image from 'sow/components/atoms/Image';
import InspectionDoneModal from 'sow/components/organisms/InspectorDashboard/InspectionDoneModal';

const InspectionState = ({ inspection }) => {
  switch (inspection.inspection_state_id) {
    case inspectionStates.readyForFinalReview:
    case inspectionStates.inFinalReview:
      return (
        <Fragment>
          <Paragraph className="inspection-done-text">Inspection Done</Paragraph>
          <FontAwesome className="inspection-done-check" icon="check-circle" />
        </Fragment>
      );
    case inspectionStates.inInspection:
      return (
        <InspectionDoneModal annual={false} inspection={inspection}>
          {toggleSampleModal => (
            <Button className="inspection-done" primary onClick={toggleSampleModal}>
              Inspection Done
            </Button>
          )}
        </InspectionDoneModal>
      );

    default:
      return null;
  }
};

const PartialInspectionRow = ({
  inspection,
  getInspectionAge,
  handleRemoveInspectionDate,
}) => (
  <TableRow>
    <TableCell valignMiddle className="client-column">
      {inspection.inspection_state_rank < inspectionStatesRank.readyForFinalReview ? (
        <Link to={orgRoute(inspection.organization_id)}>
          {inspection.organization_name}
        </Link>
      ) : (
        inspection.organization_name
      )}
    </TableCell>
    <TableCell className="inspection-type-column" valignMiddle>
      {inspection.has_sample && <SampleFlag />}
      <Block>
        <FontAwesome icon={inspection.inspection_type_icon} />{' '}
        {inspection.inspection_type}
      </Block>
    </TableCell>
    <TableCell valignMiddle className="physical-column">
      {inspection.org_city}
    </TableCell>
    <TableCell valignMiddle className="physical-column">
      {inspection.org_state}
    </TableCell>
    <TableCell valignMiddle className="anniversary-column">
      {inspection.organization_anniversary_date &&
        monthDayYearDateFormat(inspection.organization_anniversary_date)}
    </TableCell>
    <TableCell valignMiddle className="category-column">
      {inspection.organization_types}
    </TableCell>
    <TableCell valignMiddle className="age-column">
      {getInspectionAge(inspection.inspection_age)}
    </TableCell>
    <TableCell valignMiddle className="date-column">
      {inspection.app_state !== 'complete' && inspection.inspection_date && (
        <Fragment>
          <Paragraph className="inspection-date">
            {monthDayYearDateFormat(inspection.inspection_date)}
          </Paragraph>
          {inspection.inspection_state_id === inspectionStates.inInspection &&
            inspection.inspection_date && (
              <Button
                className="remove-date"
                onClick={() => handleRemoveInspectionDate(inspection)}
              >
                <FontAwesome className="remove-date-x" icon="times" />
              </Button>
            )}
        </Fragment>
      )}
    </TableCell>
    <TableCell valignMiddle className="status-column">
      <PriorityFlag flags={inspection.application_priority_flags} isInspector={true} />
      <Paragraph className="state-text">{inspection.app_state}</Paragraph>
    </TableCell>
    <TableCell className="certification-column">
      {inspection.certifications &&
        inspection.certifications.map((cert, index) => (
          <Fragment key={index}>
            <div className="cert-name">{cert.name}</div>
            <Image
              key={index}
              imageUrl={`${API_URL}/v1/file/by_path/${cert.s3_name}`}
              title={`${cert.name}`}
            />
          </Fragment>
        ))}
    </TableCell>
    <TableCell valignMiddle textCenter className="done-column">
      <InspectionState inspection={inspection} />
    </TableCell>
  </TableRow>
);

export default PartialInspectionRow;
