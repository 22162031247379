import React from 'react';
import PropTypes from 'prop-types';

import InfoTooltip from 'sow/components/organisms/InfoTooltip';

const InfoTooltipWrapper = ({ children, tooltipText }) => {
  if (!tooltipText)
    return (
      <span>
        {children}
      </span>
    );

  return (
    <InfoTooltip overlay={tooltipText}>
      {children}
    </InfoTooltip>
  );
};

InfoTooltipWrapper.propTypes = {
  children: PropTypes.node,
  tooltipText: PropTypes.string,
};

export default InfoTooltipWrapper;
