import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { worksheetRoute, locationRoute } from 'sow/routes';
import * as sowTypes from 'sow/types';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Clearfix from 'sow/components/atoms/Clearfix';
import WorksheetDetails from 'sow/containers/planApp/WorksheetDetails';
import Link from 'sow/components/atoms/Link';
import Heading from 'sow/components/atoms/Heading';
import Block from 'sow/components/atoms/Block';
import ChangeListItem from 'sow/containers/planApp/ChangeListItem';
import ChangesListItem from 'sow/containers/planApp/ChangesListItem';
import ChangeCommentDismissAllButton from 'sow/containers/planApp/ChangeCommentDismissAllButton';
import ChangeListFilterToggleButtons from './ChangeListFilterToggleButtons';
import LocationDetails from 'sow/containers/planApp/LocationDetails';
import RequestFilterToggleButtons from './RequestFilterToggleButtons';
import FilterBlockToggle from 'sow/components/organisms/PlanAppChangeRequestManager/FilterBlockToggle';
import RequestListItem from 'sow/containers/planApp/RequestListItem';
import Spacer from 'sow/components/atoms/Spacer';
import ChangeList from 'sow/components/organisms/PlanAppChangeRequestManager/ChangeList';

const WorksheetName = styled(Heading)`
  color: #333333;
`;

const standardizeItem = item => ({
  ...item,
  questionId: item.question_id,
  worksheetId: item.worksheet_id,
  applicationId: item.application_id,
  questionName: item.question_name,
  worksheetName: item.worksheet_name,
  locationId: item.location_id,
  landId: item.location_id,
  locationName: item.location_name,
  matrixRowId: item.matrix_row_id,
});

const PlanAppChangeRequestManager = ({
  title,
  orgId,
  planAppId,
  locationId,
  worksheetId,
  changeList,
  worksheetSections,
  qualifiedWorksheetIds,
  filter,
  onToggleFilter,
  changeCounts,
  locationRequestList,
  worksheetRequestList,
  locations,
  showPlanChecklist,
  showPlanWorksheets,
  showLocations,
  clearTodos,
}) => {
  const activityChecklistChange = R.pipe(
    R.filter(
      R.whereEq({
        context: 'main',
        type: 'plan',
      }),
    ),
    R.propOr(null, 0),
  )(changeList);

  let requestList = showPlanWorksheets ? worksheetRequestList : locationRequestList;

  const getWorksheetRequestsWithoutLocation = () =>
    R.filter(R.where({ location_id: R.isNil }))(requestList);
  const getRequestsForLocation = locationId =>
    R.filter(R.where({ location_id: R.equals(locationId) }))(requestList);

  const worksheetChanges = worksheetId =>
    R.filter(R.whereEq({ worksheetId, landId: null }))(changeList);
  const worksheetHasChanges = worksheetId => !R.isEmpty(worksheetChanges(worksheetId));
  const locationChanges = locationId =>
    R.filter(R.whereEq({ landId: locationId }))(changeList);
  const locationHasChanges = locationId => !R.isEmpty(locationChanges(locationId));
  const locationHasChangesOrRequests = locationId =>
    locationHasChanges(locationId) || locationHasRequests(locationId);
  const worksheetRequestsForId = worksheetId =>
    R.filter(R.where({ worksheet_id: R.equals(worksheetId), location_id: R.isNil }))(
      requestList,
    );
  const worksheetHasRequests = worksheetId =>
    filter.requested && !R.isEmpty(worksheetRequestsForId(worksheetId));

  const locationHasRequests = locationId =>
    filter.requested && !R.isEmpty(getRequestsForLocation(locationId));
  const worksheetsNoLocation = getWorksheetRequestsWithoutLocation();
  const isOrgDashboardPage =
    worksheetId === null &&
    locationId === null &&
    showPlanWorksheets &&
    showLocations &&
    showPlanChecklist;
  const isActivitiesPage =
    worksheetId === null &&
    locationId === null &&
    !showPlanWorksheets &&
    !showLocations &&
    showPlanChecklist;
  const isWorksheetsPage =
    worksheetId === null &&
    locationId === null &&
    showPlanWorksheets &&
    !showLocations &&
    !showPlanChecklist;
  const isWorksheetPage =
    worksheetId !== null &&
    locationId === null &&
    showPlanWorksheets &&
    !showLocations &&
    !showPlanChecklist;
  const isLocationsPage =
    worksheetId === null &&
    locationId === null &&
    !showPlanWorksheets &&
    showLocations &&
    !showPlanChecklist;
  const isLocationPage =
    worksheetId === null &&
    locationId !== null &&
    !showPlanWorksheets &&
    showLocations &&
    !showPlanChecklist;
  const showActivityChanges =
    (isOrgDashboardPage || isActivitiesPage) && showPlanChecklist;
  const showLocationChanges =
    (isOrgDashboardPage || isLocationsPage || isLocationPage) && showLocations;
  const showAllWorksheetChanges =
    (isOrgDashboardPage || isWorksheetsPage) && showPlanWorksheets;
  const showWorksheetChanges = isWorksheetPage && showPlanWorksheets;
  const showWorksheetTitleHeader = isOrgDashboardPage || isWorksheetsPage;
  const showLocationTitleHeader = locationId =>
    !isLocationPage && locationHasChangesOrRequests(locationId);
  const hasWorksheetRequestMatches = (requestItem, itemWorksheetId) =>
    (isWorksheetPage &&
      worksheetId === itemWorksheetId &&
      requestItem.worksheet_id === worksheetId) ||
    (!isWorksheetPage && requestItem.worksheet_id === itemWorksheetId);
  const showNoFilterResults =
    (R.isEmpty(changeList) && R.isEmpty(requestList)) ||
    (!filter.requested && !filter.open && !filter.accepted && !filter.rejected);

  let requestCount = 0;
  let requestedList = requestList;
  if (isWorksheetPage) {
    requestCount = worksheetRequestsForId(worksheetId).length;
  } else if (showLocations && showPlanWorksheets) {
    requestCount = locationRequestList.length + worksheetRequestList.length;
    requestedList = worksheetRequestList.concat(locationRequestList);
  } else if (showLocations) {
    if (!R.isNil(locationId)) {
      requestedList = getRequestsForLocation(locationId);
      requestCount = requestedList.length;
    } else {
      requestCount = locationRequestList.length;
    }
  } else if (showPlanWorksheets) {
    requestedList = worksheetsNoLocation;
    requestCount = worksheetsNoLocation.length;
  }

  const fetchRequestElements = wsId => {
    const worksheetRequests = requestList.filter(request =>
      hasWorksheetRequestMatches(request, wsId),
    );
    return worksheetRequests;
  };

  const generateElementList = () => {
    const elementList = [];
    if (showActivityChanges && activityChecklistChange) {
      elementList.push(activityChecklistChange);
    }

    if (showAllWorksheetChanges) {
      worksheetSections.forEach(section => {
        const worksheetList = R.filter(R.contains(R.__, qualifiedWorksheetIds))(
          section.worksheets,
        );
        worksheetList.forEach(wsId => {
          if (
            showWorksheetTitleHeader &&
            (worksheetHasRequests(wsId) || worksheetHasChanges(wsId))
          ) {
            elementList.push({ type: 'header', id: wsId });
          }
          if (filter.requested) {
            elementList.push(...fetchRequestElements(wsId));
          }
          elementList.push(...worksheetChanges(wsId));
        });
      });
    }

    if (showWorksheetChanges) {
      if (filter.requested) {
        elementList.push(...fetchRequestElements(worksheetId));
      }
      elementList.push(...worksheetChanges(worksheetId));
    }

    if (showLocationChanges) {
      for (var i = 0; i < requestList.length; i++) {
        if (requestList[i].location_id != null) {
          if (locations.indexOf(requestList[i].location_id) < 0) {
            locations.push(requestList[i].location_id);
          }
        }
      }
      locations.map(locId => {
        if (showLocationTitleHeader(locId)) {
          elementList.push({ type: 'locationHeader', locationId: locId });
        }
        if (filter.requested) {
          elementList.push(
            ...requestedList.filter(request => request.location_id === locId),
          );
        }
        elementList.push(...locationChanges(locId));
      });
    }

    return elementList;
  };

  const WorksheetHeader = props => {
    const { itemWorksheetId, headerName } = props;
    return (
      <Link to={worksheetRoute(orgId, planAppId, itemWorksheetId)}>
        <WorksheetName level={4}>{headerName}</WorksheetName>
      </Link>
    );
  };

  return (
    <Widget>
      <WidgetHeading
        className={isOrgDashboardPage ? 'organization-dashboard-heading' : ''}
      >
        {title}
        <Block pullRight style={{ marginTop: '-5px' }}>
          <ChangeCommentDismissAllButton changeList={changeList} />
          {!isOrgDashboardPage && (
            <Fragment>
              <RequestFilterToggleButtons
                filter={filter}
                onToggleFilter={onToggleFilter}
                requestListCount={requestCount}
              />
              <ChangeListFilterToggleButtons
                filter={filter}
                onToggleFilter={onToggleFilter}
                changeCounts={changeCounts}
              />
            </Fragment>
          )}
        </Block>
      </WidgetHeading>
      <Clearfix />
      <WidgetBody>
        {isOrgDashboardPage && (
          <Fragment>
            <FilterBlockToggle
              type="requested"
              filter={filter}
              onToggleFilter={onToggleFilter}
              counts={requestCount}
            >
              To-do
            </FilterBlockToggle>
            <FilterBlockToggle
              type="open"
              filter={filter}
              onToggleFilter={onToggleFilter}
              counts={changeCounts}
            >
              Pending
            </FilterBlockToggle>
            <FilterBlockToggle
              type="accepted"
              filter={filter}
              onToggleFilter={onToggleFilter}
              counts={changeCounts}
            >
              Accepted
            </FilterBlockToggle>
            <FilterBlockToggle
              type="rejected"
              filter={filter}
              onToggleFilter={onToggleFilter}
              counts={changeCounts}
              endToggle
            >
              Discarded
            </FilterBlockToggle>
            <Spacer vertical={20} />
          </Fragment>
        )}
        <Block>
          {!showNoFilterResults && (
            <ChangeList minHeight={20} height={350} changeList={generateElementList()}>
              {(measure, change, emptyList) => {
                if (emptyList)
                  return <Block textMuted>No changes matching current filters.</Block>;
                return (
                  <Fragment>
                    {change.type === 'locationHeader' ? (
                      <LocationDetails locationId={change.locationId}>
                        {loc => (
                          <Link to={locationRoute(orgId, planAppId, change.locationId)}>
                            <WorksheetName level={4}>{loc.name}</WorksheetName>
                          </Link>
                        )}
                      </LocationDetails>
                    ) : change.type === 'header' ? (
                      <WorksheetDetails worksheetId={change.id}>
                        {({ name }) => (
                          <WorksheetHeader
                            itemWorksheetId={change.id}
                            headerName={name}
                          />
                        )}
                      </WorksheetDetails>
                    ) : change.state === 'requested_change' ? (
                      <ChangesListItem
                        listItem={standardizeItem(change)}
                        clearTodos={clearTodos}
                      />
                    ) : (
                      <ChangesListItem
                        toggleMeasure={measure}
                        listItem={change}
                        changeId={change.id}
                      />
                    )}
                  </Fragment>
                );
              }}
            </ChangeList>
          )}
        </Block>
        {showNoFilterResults && (
          <Block textMuted>No changes matching current filters.</Block>
        )}
      </WidgetBody>
    </Widget>
  );
};

PlanAppChangeRequestManager.propTypes = {
  title: PropTypes.node.isRequired,
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  // worksheetId prop may optionally be passed in when CR manager is displayed on a worksheet page
  worksheetId: sowTypes.planAppWorksheetIdType,
  locationId: sowTypes.planAppLocationIdType,
  changeList: sowTypes.planAppChangeListType.isRequired,
  filter: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    accepted: PropTypes.bool.isRequired,
    rejected: PropTypes.bool.isRequired,
    requested: PropTypes.bool.isRequired,
  }).isRequired,
  onToggleFilter: PropTypes.func.isRequired,
  changeCounts: PropTypes.shape({
    open: PropTypes.number.isRequired,
    accepted: PropTypes.number.isRequired,
    rejected: PropTypes.number.isRequired,
    requested: PropTypes.number.isRequired,
  }).isRequired,
  locations: PropTypes.arrayOf(sowTypes.planAppLocationIdType),
  requestList: PropTypes.array,
  locationRequestList: PropTypes.array,
  worksheetRequestList: PropTypes.array,
  showPlanChecklist: PropTypes.bool.isRequired,
  showPlanWorksheets: PropTypes.bool.isRequired,
  showLocations: PropTypes.bool.isRequired,
};

PlanAppChangeRequestManager.defaultProps = {
  worksheetId: null,
  locationId: null,
};

export default PlanAppChangeRequestManager;
