import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as currentUser from 'sow/selectors/currentUser';
import { orgIdType, planAppIdType, planAppSummaryType, planAppType } from 'sow/types';
import { resourceCreate, resourceListRead, resourceUpdate } from 'sow/store/helpers';
import { fromPlanApp } from 'sow/store/selectors';

import AppActionButtons from 'sow/components/organisms/OrgPlanAppTable/AppActionButtons';

const withResourceHelpers = connect(
  // mapStateToProps
  (state, props) => ({
    orgId: fromPlanApp.orgId(state, props),
    planAppId: fromPlanApp.planAppId(state, props),
    isPlanAppIdMostRecent: fromPlanApp.isPlanAppIdMostRecent(state, props),
  }),
  // mapDispatchToProps
  null,
  // mergeProps
  (stateProps, dispatchProps, ownProps) => {
    const { orgId, planAppId } = stateProps;
    const baseUrl = `org/${orgId}/application/${planAppId}`;
    return {
      ...stateProps,
      ...ownProps,
      // Set state to: Initial Application Submitted
      submitInitial: resourceUpdate(`${baseUrl}/submit_initial`, 'planAppResp'),
      // Set state to: Update Submitted
      submitUpdate: resourceUpdate(`${baseUrl}/submit_revision`, 'planAppResp'),
      // Set state to one of: Initial Review, Inspection, Final Review
      updateState: resourceUpdate(`${baseUrl}/review_state`, 'planAppResp'),
      // Set state to: Complete
      complete: resourceUpdate(`${baseUrl}/complete`, 'planAppResp'),
      // Set state to: Noncompliance
      markNoncompliant: resourceUpdate(`${baseUrl}/noncompliant`, 'planAppResp'),
      // Create new plan and set its state to: Unlocked for Update
      unlockForUpdate: resourceCreate(`${baseUrl}/revision`, 'planAppResp'),
      // Set state to: Withdrawn
      withdrawPlan: resourceUpdate(`${baseUrl}/withdraw`, 'planAppResp'),
      // Unwithdraw plan
      unwithdrawPlan: resourceUpdate(`${baseUrl}/unwithdraw`, 'planAppResp'),
      // Set state to: Revoked
      revokePlan: resourceUpdate(`${baseUrl}/decommission/revoked`, 'planAppResp'),
      // Set state to: Surrendered
      surrenderPlan: resourceUpdate(`${baseUrl}/decommission/surrendered`, 'planAppResp'),
      // Set state to: Suspended
      suspendPlan: resourceUpdate(`${baseUrl}/decommission/suspended`, 'planAppResp'),
    };
  },
);

const mapStateToProps = (state, props) => {
  const {
    submitInitial,
    submitUpdate,
    updateState,
    complete,
    markNoncompliant,
    unlockForUpdate,
    withdrawPlan,
    unwithdrawPlan,
    revokePlan,
    surrenderPlan,
    suspendPlan,
  } = props;

  return {
    showAcaUI: currentUser.showAcaUI(state, props),
    showInspectorUI: currentUser.showInspectorUI(state, props),
    changeRequestCanBeApplied: fromPlanApp.changeRequestCanBeApplied(state, props),
    disabled: R.any(R.equals(true), [
      submitInitial.selectors.pending(state, props),
      submitUpdate.selectors.pending(state, props),
      updateState.selectors.pending(state, props),
      complete.selectors.pending(state, props),
      markNoncompliant.selectors.pending(state, props),
      unlockForUpdate.selectors.pending(state, props),
      withdrawPlan.selectors.pending(state, props),
      unwithdrawPlan.selectors.pending(state, props),
      revokePlan.selectors.pending(state, props),
      surrenderPlan.selectors.pending(state, props),
      suspendPlan.selectors.pending(state, props),
    ]),
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    submitInitial,
    submitUpdate,
    complete,
    markNoncompliant,
    unlockForUpdate,
    withdrawPlan,
    unwithdrawPlan,
    revokePlan,
    surrenderPlan,
    suspendPlan,
    orgId,
  },
) => {
  return {
    onSubmitInitial: () => dispatch(submitInitial.action()),
    onSubmitUpdate: () => dispatch(submitUpdate.action()),
    onComplete: async () => {
      // Submit the plan to be marked 'completed'. Changes will be rolled over...
      const resp = await dispatch(complete.action());

      // Load the new list of changes for this plan
      const planAppId = R.path(['data', 'detail', 'id'], resp);
      const changeRequestId = R.path(['data', 'detail', 'changeRequest', 'id'], resp);
      const loadChangesUrl = `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`;
      return dispatch(resourceListRead(loadChangesUrl, 'change').action());
    },
    onMarkNoncompliant: () => dispatch(markNoncompliant.action()),
    onUnlockForUpdate: () => dispatch(unlockForUpdate.action()),
    onWithdrawPlan: () => dispatch(withdrawPlan.action()),
    onRevokePlan: () => dispatch(revokePlan.action()),
    onSurrenderPlan: () => dispatch(surrenderPlan.action()),
    onSuspendPlan: () => dispatch(suspendPlan.action()),
    onUnwithdrawPlan: () => dispatch(unwithdrawPlan.action()),
  };
};

const PlanAppActionButtonsContainer = props => <AppActionButtons {...props} />;

PlanAppActionButtonsContainer.defaultProps = {
  disabled: false,
  planApp: null,
};

PlanAppActionButtonsContainer.propTypes = {
  orgId: orgIdType.isRequired,
  planAppId: planAppIdType.isRequired,
  planAppSummary: planAppSummaryType.isRequired,
  hasOpenPlanApp: PropTypes.bool.isRequired,
  isPlanAppIdMostRecent: PropTypes.bool.isRequired,
  // Only loaded if the app is open
  planApp: planAppType,

  // Added from merge
  showAcaUI: PropTypes.bool,
  showInspectorUI: PropTypes.bool,
  showAdminUI: PropTypes.bool,
  disabled: PropTypes.bool,

  // Button events
  onSubmitInitial: PropTypes.func.isRequired,
  onSubmitUpdate: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onMarkNoncompliant: PropTypes.func.isRequired,
  onUnlockForUpdate: PropTypes.func.isRequired,
  onWithdrawPlan: PropTypes.func.isRequired,
  onRevokePlan: PropTypes.func.isRequired,
  onSurrenderPlan: PropTypes.func.isRequired,
  onSuspendPlan: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  withResourceHelpers,
  connect(mapStateToProps, mapDispatchToProps),
)(PlanAppActionButtonsContainer);
