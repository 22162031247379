import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Block from 'sow/components/atoms/Block';
import Input from 'sow/components/atoms/Input';
import FormLabel from 'sow/components/atoms/FormLabel';

const Checkbox = ({
  value,
  className,
  onChange,
  checked,
  containerProps,
  children,
  ...props
}) => {
  const classes = classnames(className, 'checkbox');

  return (
    <Block {...containerProps} className={classes}>
      <FormLabel>
        <Input
          {...props}
          type="checkbox"
          value={value}
          onChange={onChange}
          checked={checked}
        />{' '}
        {children}
      </FormLabel>
    </Block>
  );
};

Checkbox.defaultProps = {
  value: '',
  className: null,
  checked: false,
};

Checkbox.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  containerProps: PropTypes.object,
  children: PropTypes.node,
};

export default Checkbox;
