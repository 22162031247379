import React from 'react';

import AdminObjectTemplateContainer from 'sow/containers/AdminObjectTemplateContainer';
import ItemListContainer from 'sow/containers/ItemListContainer';

const ItemListPage = () => (
  <AdminObjectTemplateContainer pageName="Items" list>
    <ItemListContainer />
  </AdminObjectTemplateContainer>
);

export default ItemListPage;
