import React from 'react';
import { Modal } from 'react-bootstrap';
import Worksheet from './Worksheet';

export default ({ showModal, rowId, handleEditCloseRow, ...worksheetProps }) =>
  <Modal show={showModal} onHide={handleEditCloseRow} bsSize="large">
    <Modal.Header closeButton>
      <Modal.Title>Matrix Editor</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Worksheet {...worksheetProps} rowId={rowId} />
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-primary" onClick={handleEditCloseRow}>
        Done
      </button>
    </Modal.Footer>
  </Modal>;
