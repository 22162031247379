import React, { Fragment } from 'react';

import { consultantCompaniesRoute } from 'sow/routes';
import Spinner from 'sow/components/atoms/Spinner';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Confirm from 'sow/components/molecules/Confirm';
import InputFormGroup from 'sow/components/molecules/InputFormGroup';
import Button from 'sow/components/atoms/Button';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const ConsultantCompaniesEdit = ({
  formValues,
  isNew,
  isLoading,
  handleInputChange,
  handleSave,
  handleDelete,
}) => (
  <Fragment>
    {isLoading ? (
      <Spinner />
    ) : (
      <Widget className="system-admin-list-edit">
        <WidgetHeading>{isNew ? 'Add' : 'Edit'}</WidgetHeading>
        <WidgetBody>
          <Row className="form-row">
            <Column className="form-column" xs={4} sm={3} md={2}>
              <Button className="form-button" onClick={handleSave} primary>
                Save
              </Button>
            </Column>
            <Column className="form-column" xs={4} sm={3} md={2}>
              <Button
                className="form-button reset-return-button"
                to={consultantCompaniesRoute()}
              >
                Cancel
              </Button>
            </Column>
            <Column sm={3} md={6}></Column>
            <Column className="form-column" xs={4} sm={3} md={2}>
              <Confirm
                message="Are you sure you want to delete this consultant company?"
                onConfirm={handleDelete}
              >
                {toggleConfirm => (
                  <Button
                    className="delete-button"
                    onClick={toggleConfirm}
                    disabled={isNew}
                  >
                    <FontAwesome className="button-icon" icon="trash" />
                    Delete
                  </Button>
                )}
              </Confirm>
            </Column>
          </Row>
          <HorizontalRule className="divider" />
          <Row className="form-row">
            <Column className="form-column" md={12}>
              <InputFormGroup
                id="name"
                className="form-group"
                label="Name"
                inputClassName="input-filter"
                onChange={handleInputChange}
                value={formValues.name}
              />
            </Column>
          </Row>
        </WidgetBody>
      </Widget>
    )}
  </Fragment>
);

export default ConsultantCompaniesEdit;
