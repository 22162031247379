import React from 'react';
import PropTypes from 'prop-types';

import OrgPageTemplate from './OrgPageTemplate';

const OrgAttachmentPageTemplate = ({ children }) => (
  <OrgPageTemplate title="Attachments">{children}</OrgPageTemplate>
);

OrgAttachmentPageTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OrgAttachmentPageTemplate;
