import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import ItemSectionTypeListContainer from 'sow/containers/ItemSectionTypeListContainer';

const ItemSectionTypeListPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <ItemSectionTypeListContainer />}
  </AdminPageTemplate>
);

export default ItemSectionTypeListPage;
