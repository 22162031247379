import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

import Link from 'sow/components/atoms/Link';
// TBI
// import { linkToType } from 'sow/types';

const StyledButton = styled.button``;

/**
 * Button UI component
 */
const Button = ({
  className,
  style,
  type,
  danger,
  info,
  primary,
  success,
  warning,
  link,
  block,
  xs,
  sm,
  small,
  lg,
  large,
  pullLeft,
  pullRight,
  textMuted,
  ...props
}) => {
  const classes = classNames(
    className,
    {
      'pull-left': pullLeft,
      'pull-right': pullRight,
      'btn-block': block,
      'btn-xs': xs,
      'btn-sm': sm || small,
      'btn-lg': lg || large,
    },
    link
      ? {
          'btn-link': link,
          'text-danger': danger,
          'text-info': info,
          'text-primary': primary,
          'text-primary': primary,
          'text-success': success,
          'text-warning': warning,
          'text-muted': textMuted,
        }
      : {
          btn: true,
          'btn-default': !(danger || info || primary || success || warning),
          'btn-danger': danger,
          'btn-info': info,
          'btn-primary': primary,
          'btn-primary': primary,
          'btn-success': success,
          'btn-warning': warning,
        },
  );

  if (props.to) {
    return <Link className={classes} {...{ ...props, style }} />;
  } else if (props.href) {
    return <a className={classes} {...{ ...props, style }} />;
  }

  const btnStyle = { margin: 2, ...style };

  return <StyledButton className={classes} {...props} style={btnStyle} type={type} />;
};

Button.propTypes = {
  /** Additional css classes to be passed through */
  className: PropTypes.string,

  /** Additional css styles to be passed through */
  style: PropTypes.object,

  /** Whether the button can be interacted with */
  disabled: PropTypes.bool,

  /** Renders an <a> element instead of <button> with given url */
  href: PropTypes.string,

  // to: linkToType,
  type: PropTypes.string,

  /** Float left */
  pullLeft: PropTypes.bool,

  /** Float right */
  pullRight: PropTypes.bool,

  /** Style button to appear as a link */
  link: PropTypes.bool,

  /** Display as block element (100% width) */
  block: PropTypes.bool,

  /** Display as size 'xs' */
  xs: PropTypes.bool,

  /** Display as size 'sm' */
  sm: PropTypes.bool,

  /** Alias for 'sm' */
  small: PropTypes.bool,

  /** Display as size 'lg' */
  lg: PropTypes.bool,

  /** Alias for 'lg' */
  large: PropTypes.bool,

  /** Display text in 'muted' color when used with link="true" */
  textMuted: PropTypes.bool,

  // Contexts

  /** Display button in 'danger' color */
  danger: PropTypes.bool,

  /** Display button in 'info' color */
  info: PropTypes.bool,

  /** Display button in 'primary' color */
  primary: PropTypes.bool,

  /** Display button in 'success' color */
  success: PropTypes.bool,

  /** Display button in 'warning' color */
  warning: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
};

export default Button;
