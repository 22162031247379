import { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { replace as routerReplace } from 'connected-react-router';

const mapDispatchToProps = { routerReplace };

class ModalOpenState extends Component {
  // Redirects to the same location without the 'openModalId' state property
  handleCloseModal = () => {
    const hasOpenModalId = R.pathOr(false, ['state', 'openModalId'], this.props.location);
    if (hasOpenModalId) {
      this.props.routerReplace(
        R.dissocPath(['state', 'openModalId'], this.props.location),
      );
    }
    history.pushState(null, null, window.location.href.split('#')[0]);
  };

  render() {
    const { id, location, children } = this.props;

    const openModalId = R.pathOr(false, ['state', 'openModalId'], location);

    return children({
      onHide: this.handleCloseModal,
      show: id && openModalId && openModalId === id,
    });
  }
}

ModalOpenState.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  routerReplace: PropTypes.func.isRequired,
};

export default R.compose(withRouter, connect(null, mapDispatchToProps))(ModalOpenState);
