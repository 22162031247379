import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import Button from 'sow/components/atoms/Button';
import Spacer from 'sow/components/atoms/Spacer';
import Glyphicon from 'sow/components/molecules/Glyphicon';
import PlanAppLocationCard from 'sow/containers/planApp/LocationCard';
import LocationEditModal from 'sow/containers/planApp/LocationEditModal';
import LocationEditChangeModal from 'sow/containers/planApp/LocationEditChangeModal';
import LocationWorksheetsForm from 'sow/containers/planApp/LocationWorksheetsForm';
import ChangeRequestManager from 'sow/containers/planApp/ChangeRequestManager';
import ModalOpenState from 'sow/containers/ModalOpenState';

const OrgPlanAppLocationPage = ({
  orgId,
  planAppId,
  locationId,
  changeRequestId,
  isStateInitialApplication,
  isStateUnderReview,
  isPlanAppOpen,
  isEditAllowed,
  loadPlanApp,
  saveChanges,
  saveWorksheetAnswers,
}) => {
  let editButton;
  if (isStateInitialApplication) {
    // If the plan is initial, show regular edit button
    editButton = (
      <LocationEditModal>
        {showModal => (
          <Button sm primary onClick={showModal}>
            <Glyphicon glyph="pencil" /> Edit Details
          </Button>
        )}
      </LocationEditModal>
    );
  } else {
    // If the plan is open and has a CR, show button to edit the location change
    const editable = isPlanAppOpen && changeRequestId && isEditAllowed;
    editButton = (
      <ModalOpenState id={`location-${locationId}`}>
        {({ show, onHide }) => (
          <LocationEditChangeModal
            defaultOpen={show}
            onHide={onHide}
            isEditAllowed={isEditAllowed}
          >
            {showModal => (
              <Button sm primary onClick={showModal}>
                {editable && <Glyphicon glyph="pencil" />}
                {editable && ' '}
                {editable ? 'Edit' : 'View'} Details
              </Button>
            )}
          </LocationEditChangeModal>
        )}
      </ModalOpenState>
    );
  }

  return (
    <Fragment>
      {isStateUnderReview && (
        <ChangeRequestManager
          title="Changes for this location"
          showLocations
          clearTodos
        />
      )}
      <Spacer vertical={20} />

      <PlanAppLocationCard>{editButton}</PlanAppLocationCard>
      <LocationWorksheetsForm
        loadPlanApp={loadPlanApp}
        saveChanges={saveChanges}
        saveWorksheetAnswers={saveWorksheetAnswers}
      />
    </Fragment>
  );
};

OrgPlanAppLocationPage.propTypes = {
  planAppLocation: sowTypes.planAppLocationType,
  changeRequestId: sowTypes.planAppChangeRequestIdType,
  isStateInitialApplication: PropTypes.bool.isRequired,
  isStateUnderReview: PropTypes.bool.isRequired,
  isPlanAppOpen: PropTypes.bool.isRequired,
  isEditAllowed: PropTypes.bool.isRequired,
  loadPlanApp: PropTypes.func,
};

OrgPlanAppLocationPage.defaultProps = {
  changeRequestId: null,
  planAppLocation: null,
};

export default OrgPlanAppLocationPage;
