import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { clearRequestsForLocationWorksheet } from 'sow/utils/worksheet';
import * as adminRequestActions from 'sow/actions/adminRequest';
import * as currentOrg from 'sow/selectors/currentOrg';
import * as currentUser from 'sow/selectors/currentUser';
import * as sowTypes from 'sow/types';
import { fromPlanApp } from 'sow/store/selectors';

import LocationWorksheet from 'sow/components/organisms/PlanAppLocationWorksheet';

const mapStateToProps = (state, props) => ({
  orgId: currentOrg.orgId(state, props),
  worksheetId: fromPlanApp.worksheetId(state, props),
  worksheet: fromPlanApp.locationWorksheet(state, props),
  questions: fromPlanApp.locationWorksheetQuestions(state, props),
  locationAnswersChangesMap: fromPlanApp.locationAnswersChangesMap(state, props),
  locationId: fromPlanApp.locationId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  isPlanAppClosed: fromPlanApp.isPlanAppClosed(state, props),
  isStateUnderReview: fromPlanApp.isStateUnderReview(state, props),
  requestList: state.adminRequests.locationRequests.list,
  isStaff: currentUser.isStaff(state, props),
});

const mapDispatchToProps = dispatch => ({
  clearRequest: (orgId, planAppId, requestId) =>
    dispatch(adminRequestActions.clearAdminRequest(orgId, planAppId, requestId)),
});

const LocationWorksheetContainer = ({ worksheet, values, ...props }) => {
  const clearWorksheetRequests = () => {
    const {
      orgId,
      planAppId,
      worksheetId,
      locationId,
      clearRequest,
      requestList,
    } = props;
    clearRequestsForLocationWorksheet(
      orgId,
      planAppId,
      worksheetId,
      locationId,
      clearRequest,
      requestList,
    );
  };

  return (
    <LocationWorksheet
      {...props}
      clearWorksheetRequests={clearWorksheetRequests}
      worksheet={worksheet}
      values={values}
    />
  );
};

LocationWorksheetContainer.propTypes = {
  // Connected props
  worksheet: sowTypes.planAppWorksheetType.isRequired,

  // Passed props
  values: PropTypes.object.isRequired,
};

LocationWorksheetContainer.defaultProps = {
  worksheet: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(LocationWorksheetContainer);
