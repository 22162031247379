import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import {
  fetchLocationReportOptions,
  fetchLocationReport,
  clearLocationReport,
} from 'sow/actions/aca';
import { API_URL } from 'sow/constants/api';
import * as currentAca from 'sow/selectors/currentAca';
import * as sowTypes from 'sow/types';

import LocationReportFilters from 'sow/components/organisms/LocationReport/LocationReportFilters';
import Spinner from 'sow/components/atoms/Spinner';

const paramsForURL = filters =>
  queryString.stringify(filters, {
    arrayFormat: 'comma',
    skipEmptyString: true,
    skipNull: true,
  });

const paramsForExport = filters =>
  queryString.stringify(filters, {
    arrayFormat: 'bracket',
    skipEmptyString: true,
    skipNull: true,
  });

const initialValues = {};

const mapStateToProps = (state, props) => ({
  acaId: currentAca.acaId(state, props),
  loadingOptions: state.locationReport.loadingOptions,
  options: state.locationReport.options,
  hasResults: state.locationReport.hasResults,
});

const mapDispatchToProps = {
  fetchLocationReport,
  fetchLocationReportOptions,
  clearLocationReport,
};

class LocationReportFiltersContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { initialValues: R.clone(initialValues) };
  }

  componentDidMount() {
    this.props.fetchLocationReportOptions(this.props.acaId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingOptions !== this.props.loadingOptions) {
      const urlFilters = queryString.parse(this.props.location.search, {
        arrayFormat: 'comma',
      });
      this.setInitialValues(urlFilters);
    }
  }

  componentWillUnmount() {
    this.props.clearLocationReport();
  }

  setInitialValues(urlFilters) {
    const { initialValues } = this.state;

    for (let key in urlFilters) {
      initialValues[key] = urlFilters[key];
    }

    this.setState({ initialValues: { ...initialValues } });
    if (!R.isEmpty(urlFilters)) {
      this.props.fetchLocationReport(this.props.acaId, initialValues);
    }
  }

  handleSearch(values) {
    const urlParams = paramsForURL(values);
    if (urlParams) {
      history.pushState(null, null, `?${urlParams}`);
    } else {
      this.props.history.push(this.props.location.pathname);
    }
    this.props.fetchLocationReport(this.props.acaId, urlParams);
  }

  resetReport(values, formikBag) {
    this.setState({ initialValues: { ...initialValues } }, () => {
      this.props.clearLocationReport();
      history.pushState(null, null, location.pathname);
      formikBag.resetForm({ ...initialValues });
    });
  }

  exportList(values) {
    const { acaId } = this.props;
    const params = paramsForExport(values);
    window.open(`${API_URL}/v1/aca/${acaId}/location_report/export?${params}`, '_blank');
  }

  render() {
    if (this.props.loadingOptions) return <Spinner />;
    return (
      <LocationReportFilters
        handleSearch={this.handleSearch.bind(this)}
        resetReport={this.resetReport.bind(this)}
        exportList={this.exportList.bind(this)}
        initialValues={this.state.initialValues}
        options={this.props.options}
        {...this.props}
      />
    );
  }
}

LocationReportFiltersContainer.propTypes = {
  acaId: sowTypes.acaIdType,
  fetchLocationReport: PropTypes.func,
  fetchLocationReportOptions: PropTypes.func,
  clearLocationReport: PropTypes.func,
  loadingOptions: PropTypes.bool,
  filterOptions: PropTypes.object,
  hasResults: PropTypes.bool,
};

LocationReportFiltersContainer.defaultProps = {
  acaId: null,
  loadingOptions: true,
  hasResults: false,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(LocationReportFiltersContainer);
