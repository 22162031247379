import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as announcementTypeActions from 'sow/actions/pure/announcementType';
import { apiGet, apiPost } from 'sow/sagas/api';

export const getAnnouncementTypes = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/announcement_type', payload);
    if (response.data) {
      if (meta.onSuccess) meta.onSuccess(response);
      else yield put(announcementTypeActions.loadAnnouncementTypesDone(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAnnouncementTypeById = function*({ id, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/announcement_type/${id}`);
    if (response.data) {
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addAnnouncementType = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/announcement_type', payload);

    if (response.data) {
      yield put(actions.shell.toast('success', 'Announcement type added.'));
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateAnnouncementType = function*({ id, payload, meta }) {
  try {
    const { response } = yield call(apiPost, `/admin/announcement_type/${id}`, payload);

    if (response && response.data) {
      yield put(actions.shell.toast('success', 'Announcement type updated.'));
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateSortOrder = function*({ payload }) {
  try {
    yield call(apiPost, '/admin/announcement_type/update_order', {
      updated_list: payload,
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteAnnouncementType = function*({ id, meta }) {
  try {
    const { response } = yield call(apiPost, `/admin/announcement_type/delete/${id}`);
    if (response.data) {
      yield put(actions.shell.toast('success', 'Announcement type deleted.'));
      if (meta.onSuccess) meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(announcementTypeActions.LOAD_ANNOUNCEMENT_TYPES, getAnnouncementTypes);
  yield takeEvery(
    announcementTypeActions.LOAD_ANNOUNCEMENT_TYPE_BY_ID,
    getAnnouncementTypeById,
  );
  yield takeEvery(announcementTypeActions.ADD_ANNOUNCEMENT_TYPE, addAnnouncementType);
  yield takeEvery(
    announcementTypeActions.UPDATE_ANNOUNCEMENT_TYPE,
    updateAnnouncementType,
  );
  yield takeEvery(announcementTypeActions.UPDATE_SORT_ORDER, updateSortOrder);
  yield takeEvery(
    announcementTypeActions.DELETE_ANNOUNCEMENT_TYPE,
    deleteAnnouncementType,
  );
}
