import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import OrganizationTypeEditContainer from 'sow/containers/OrganizationTypeEditContainer';

const OrganizationTypeEditPage = () => (
  <AdminPageTemplate title="System Adminstrator">
    {() => <OrganizationTypeEditContainer />}
  </AdminPageTemplate>
);

export default OrganizationTypeEditPage;
