import React from 'react';
import PropTypes from 'prop-types';

import Atom from 'sow/components/atoms/Atom';
import Strong from 'sow/components/atoms/Strong';
import Button from 'sow/components/atoms/Button';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const ToggleButton = ({ enabled, onClick, children, ...props }) => {
  return (
    <Button {...props} sm onClick={onClick}>
      <FontAwesome
        icon={enabled ? 'check-square' : 'square'}
        variant={enabled ? 'fas' : 'far'}
      />{' '}
      Show {children}
    </Button>
  );
};

ToggleButton.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const ChangeListFilterToggleButtons = ({ filter, onToggleFilter, changeCounts }) => {
  const handleButtonClick = filterName => event => {
    event.preventDefault();
    onToggleFilter(filterName);
  };

  return (
    <Atom>
      <ToggleButton
        warning={filter.open}
        enabled={filter.open}
        onClick={handleButtonClick('open')}
      >
        <Atom textWarning={!filter.open}>
          <Strong>Pending</Strong>
        </Atom>{' '}
        ({changeCounts.open.toLocaleString()})
      </ToggleButton>

      <ToggleButton
        success={filter.accepted}
        enabled={filter.accepted}
        onClick={handleButtonClick('accepted')}
      >
        <Atom textSuccess={!filter.accepted}>
          <Strong>Accepted</Strong>
        </Atom>{' '}
        ({changeCounts.accepted.toLocaleString()})
      </ToggleButton>

      <ToggleButton
        danger={filter.rejected}
        enabled={filter.rejected}
        onClick={handleButtonClick('rejected')}
      >
        <Atom textDanger={!filter.rejected}>
          <Strong>Discarded</Strong>
        </Atom>{' '}
        ({changeCounts.rejected.toLocaleString()})
      </ToggleButton>
    </Atom>
  );
};

ChangeListFilterToggleButtons.propTypes = {
  filter: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    accepted: PropTypes.bool.isRequired,
    rejected: PropTypes.bool.isRequired,
  }).isRequired,
  onToggleFilter: PropTypes.func.isRequired,
  changeCounts: PropTypes.shape({
    open: PropTypes.number.isRequired,
    accepted: PropTypes.number.isRequired,
    rejected: PropTypes.number.isRequired,
  }).isRequired,
};

export default ChangeListFilterToggleButtons;
