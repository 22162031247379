import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import { userTypeId } from 'sow/constants/userType';
import { orgContactsRoute } from 'sow/routes';
import { requestPasswordReset } from 'sow/actions/pure/resetPassword';
import * as userManagementActions from 'sow/actions/pure/userManagement';
import * as currentOrg from 'sow/selectors/currentOrg';
import * as currentUser from 'sow/selectors/currentUser';
import ContactInfoEdit from 'sow/components/organisms/ContactInfoEdit';
import Spinner from 'sow/components/atoms/Spinner';

const compactObj = R.reject(R.isEmpty);

const mapStateToProps = (state, props) => ({
  orgId: currentOrg.orgId(state, props),
  currentUserId: currentUser.id(state, props),
  isPrimaryContact: currentUser.isPrimaryContact(state, props),
  isStaff: currentUser.isStaff(state, props),
  requestedUser: state.userManagement.requestedUser,
  userLoaded: state.userManagement.userLoaded,
  manageableOrgs: state.userManagement.manageableOrgs,
});

const mapDispatchToProps = {
  requestPasswordReset,
  loadSingleUser: userManagementActions.loadRequestedUser,
  clearResults: userManagementActions.clearResults,
  updateOrgContact: userManagementActions.updateOrgContact,
  removeUser: userManagementActions.revokeUser,
  fetchManageableOrgs: userManagementActions.fetchManageableOrgs,
};

class ContactInfoEditContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { showModal: false, formValues: {}, errors: {}, additionalOrgIds: {} };
  }

  componentDidMount() {
    const { userId } = this.props.match.params;
    const { orgId } = this.props;
    this.props.loadSingleUser(orgId, userId, userTypeId.client);
  }

  componentDidUpdate(prevProps) {
    const { requestedUser, userLoaded } = this.props;

    if (!prevProps.userLoaded && userLoaded) {
      this.setState({
        editableContact: requestedUser.editable_by_primary,
        formValues: {
          userName: requestedUser.name,
          userEmail: requestedUser.email,
          userNumber: requestedUser.phoneNumber || '',
          userTitle: requestedUser.title,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.clearResults('requestedUser');
    this.props.clearResults('manageableOrgs');
  }

  handleInputChange({ target }) {
    const { id, value } = target;
    const { formValues } = this.state;
    this.setState({ formValues: { ...formValues, [id]: value } }, () =>
      this.errorSpotCheck(id),
    );
  }

  toggleModal() {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  onModalSave(field, value) {
    const { formValues } = this.state;
    this.setState({ formValues: { ...formValues, [field]: value }, showModal: false });
  }

  onCancel() {
    const { orgId, history } = this.props;
    history.push(`/org/${orgId}/contacts`);
  }

  errorSpotCheck(id) {
    const { errors } = this.state;
    if (R.has(id)(errors)) {
      const resetErrors = compactObj({
        ...errors,
        [id]: [],
      });
      this.setState(R.assoc('errors', resetErrors));
    }
  }

  errorCheck() {
    const { formValues } = this.state;

    const errors = compactObj({
      userName: R.isEmpty(formValues.userName) ? ['Field must be filled out.'] : [],
      userEmail: R.isEmpty(formValues.userEmail) ? ['Field must be filled out.'] : [],
      userNumber: R.isEmpty(formValues.userNumber) ? ['Field must be filled out.'] : [],
    });

    this.setState(R.assoc('errors', errors));

    return errors;
  }

  onSave() {
    const { userId } = this.props.match.params;
    const { orgId } = this.props;
    const { formValues } = this.state;

    const formErrors = this.errorCheck();

    if (R.isEmpty(formErrors)) {
      const newValues = {
        userId,
        orgId,
        password: formValues.password,
        name: formValues.userName,
        email: formValues.userEmail,
        settings: {
          primary_phone_number: formValues.userNumber,
          title: formValues.userTitle,
        },
      };

      this.props.updateOrgContact(newValues, this.onCancel.bind(this));
    }
  }

  removeUser() {
    const { match, orgId, removeUser } = this.props;
    const { userId } = match.params;
    removeUser({ user_id: userId, organization_id: orgId }, () => {});
    for (let id in this.state.additionalOrgIds) {
      if (this.state.additionalOrgIds[id]) {
        removeUser({ user_id: userId, organization_id: id }, () => {});
      }
    }
    this.returnToContactsList();
  }

  returnToContactsList() {
    this.props.history.push(`/org/${this.props.orgId}/contacts`);
  }

  generateOrgList(cb) {
    if (this.isStaff) {
      cb();
    } else {
      this.props.fetchManageableOrgs(
        {
          orgId: this.props.orgId,
          userId: this.props.match.params.userId,
        },
        cb,
      );
    }
  }

  staffRemoveUser(cb) {
    const { match, orgId, removeUser } = this.props;
    const { userId } = match.params;
    removeUser(
      { user_id: userId, organization_id: orgId },
      this.returnToContactsList.bind(this),
      cb,
    );
  }

  removePrimaryContact() {
    const { match, orgId, removeUser } = this.props;
    const { userId } = match.params;
    removeUser(
      { user_id: userId, organization_id: orgId, override: true },
      this.returnToContactsList.bind(this),
    );
  }

  additionalOrgRemoval(id, value) {
    this.setState({ additionalOrgIds: { ...this.state.additionalOrgIds, [id]: value } });
  }

  sendPasswordReset() {
    this.props.requestPasswordReset(this.props.requestedUser.email);
  }

  render() {
    const {
      userLoaded,
      isPrimaryContact,
      isStaff,
      orgId,
      requestedUser,
      currentUserId,
      manageableOrgs,
    } = this.props;

    const {
      showModal,
      formValues,
      errors,
      editableContact,
      additionalOrgIds,
    } = this.state;

    if (!userLoaded) {
      return <Spinner />;
    }

    if ((!isPrimaryContact && !isStaff) || !requestedUser) {
      return <Redirect to={orgContactsRoute(orgId)} />;
    }

    return (
      <ContactInfoEdit
        {...this.props}
        isSelf={currentUserId === requestedUser.user_id}
        editableContact={editableContact}
        showModal={showModal}
        formValues={formValues}
        errors={errors}
        handleInputChange={this.handleInputChange.bind(this)}
        toggleModal={this.toggleModal.bind(this)}
        onModalSave={this.onModalSave.bind(this)}
        onCancel={this.onCancel.bind(this)}
        onSave={this.onSave.bind(this)}
        removeUser={this.removeUser.bind(this)}
        generateOrgList={this.generateOrgList.bind(this)}
        checkboxSelect={this.additionalOrgRemoval.bind(this)}
        manageableOrgsList={manageableOrgs}
        additionalOrgIds={additionalOrgIds}
        sendPasswordReset={this.sendPasswordReset.bind(this)}
        staffRemoveUser={this.staffRemoveUser.bind(this)}
        removePrimaryContact={this.removePrimaryContact.bind(this)}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ContactInfoEditContainer);
