import React from 'react';
import ReactDOM from 'react-dom';

import './UserManagementUserLookup.module.css';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Paragraph from 'sow/components/atoms/Paragraph';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import SelectFormGroup from 'sow/components/molecules/SelectFormGroup';
import InputFormGroup from 'sow/components/molecules/InputFormGroup';
import Button from 'sow/components/atoms/Button';
import ImprovedDataTable from 'sow/components/molecules/ImprovedDataTable';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Spinner from 'sow/components/atoms/Spinner';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import { adminUserEdit } from 'sow/routes';

const dropDownSystemRoles = [
  { value: 'Admin', label: 'Admin' },
  { value: 'Certification Specialist', label: 'Certification Specialist' },
  { value: 'Inspector', label: 'Inspector' },
  { value: 'Workflow Manager', label: 'Workflow Manager' },
  { value: 'Client', label: 'Client' },
  { value: 'Consultant', label: 'Consultant' },
];

const dropDownStaffRoles = [
  { value: 'aca_owner', label: 'Owner' },
  { value: 'aca_osp_editor', label: 'Plan Editor' },
  { value: 'aca_admin', label: 'Staff' },
];

const UserManagementUserLookup = ({
  fetchingOptions,
  selectOptions,
  handleInputChange,
  handleSearchSubmit,
  users,
  state,
  dropDownHandler,
  acaList,
  handleReset,
  getTablePage,
}) => {
  if (!acaList) {
    return <Spinner />;
  }

  const checkUser = user => {
    if (user.admin || user.cs || user.workflowManager || user.staffRole) {
      return 'Staff';
    } else if (user.inspector) {
      return 'Inspector';
    } else if (user.consultant) {
      return 'Consultant';
    } else {
      return 'Client';
    }
  };

  const acaDropDownOptions = acaList.map(aca => ({
    value: aca.id,
    label: aca.name,
  }));

  return (
    <Widget className="user-management">
      <WidgetHeading>
        Organization User Lookup
        <Button to={adminUserEdit('new')} pullRight primary className="edit-page-save">
          Add User
        </Button>
      </WidgetHeading>
      <WidgetBody>
        <Paragraph>
          Search for an organization or user by org id, org name, user name, or user
          email:
        </Paragraph>
        <form onSubmit={handleSearchSubmit}>
          <Row className="search-filter-row">
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <SelectFormGroup
                fieldName="aca"
                label="ACA"
                clearable={true}
                value={state.aca}
                onChange={target => dropDownHandler('aca', target)}
                options={acaDropDownOptions}
                placeholder="Select ACA"
                disabled={acaDropDownOptions.length <= 1}
              />
            </Column>
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <SelectFormGroup
                fieldName="org"
                label="Organization"
                clearable={true}
                value={state.orgId}
                onChange={target => dropDownHandler('orgId', target)}
                options={selectOptions.organizationOptions}
                placeholder="Select Organization"
                disabled={!state.aca || fetchingOptions}
              />
            </Column>
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <SelectFormGroup
                fieldName="staffRole"
                label="Staff Role"
                clearable={true}
                value={state.staffRole}
                onChange={target => dropDownHandler('staffRole', target)}
                placeholder="Select Staff Role"
                options={dropDownStaffRoles}
              />
            </Column>
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <SelectFormGroup
                fieldName="systemRole"
                label="System Role"
                clearable={true}
                value={state.systemRole}
                onChange={target => dropDownHandler('systemRole', target)}
                options={dropDownSystemRoles}
                placeholder="Select System Role"
              />
            </Column>
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <InputFormGroup
                inputClassName="input-search-field"
                fieldName="name"
                label="Name"
                type="name"
                id="name"
                value={state.name}
                onChange={({ target }) => {
                  handleInputChange(target);
                }}
              />
            </Column>
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <InputFormGroup
                inputClassName="input-search-field"
                fieldName="email"
                label="Email"
                id="email"
                value={state.email}
                onChange={({ target }) => {
                  handleInputChange(target);
                }}
              />
            </Column>
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <SelectFormGroup
                fieldName="enabled"
                label="Status"
                clearable={true}
                value={state.enabled}
                onChange={target => dropDownHandler('enabled', target)}
                options={[
                  { value: true, label: 'Enabled' },
                  { value: false, label: 'Disabled' },
                ]}
                placeholder="Select Status"
              />
            </Column>
            <Column className="search-filter-column" xs={12} sm={6} md={4} lg={3}>
              <SelectFormGroup
                fieldName="consultantCompany"
                label="Consultant Company"
                clearable={true}
                value={state.consultantCompany}
                onChange={target => dropDownHandler('consultantCompany', target)}
                options={selectOptions.consultantOptions}
                placeholder="Select Consultant Company"
                disabled={fetchingOptions}
              />
            </Column>
          </Row>
          <Row className="search-filter-row">
            <Column
              className="search-filter-column button-column"
              xs={0}
              sm={0}
              md={8}
              lg={9}
            ></Column>
            <Column
              className="search-filter-column button-column"
              xs={12}
              sm={12}
              md={4}
              lg={3}
            >
              <Row className="search-filter-row">
                <Column
                  className="search-filter-column button-column"
                  xs={6}
                  sm={6}
                  md={6}
                >
                  <Button
                    className="search-button reset-button"
                    onClick={handleReset}
                    primary
                  >
                    Reset
                  </Button>
                </Column>
                <Column
                  className="search-filter-column button-column"
                  xs={6}
                  sm={6}
                  md={6}
                >
                  <Button type="submit" className="search-button" primary>
                    Search
                  </Button>
                </Column>
              </Row>
            </Column>
          </Row>
        </form>
        {state.isSearching && <Spinner />}
        {state.searchDone ? (
          users && users.length > 0 ? (
            <ImprovedDataTable
              selector={'.usersTable'}
              tableDef={{
                pageLength: 10,
                destroy: true,
                searching: false,
                data: users,
                deferRender: true,
                order: [1, 'asc'],
                columns: [
                  { data: 'enabled' },
                  { data: 'name' },
                  { data: 'email' },
                  { data: null },
                  { data: null },
                ],
                columnDefs: [
                  { sortable: false, targets: [0, 4] },
                  {
                    targets: 0,
                    createdCell: (td, cellData) => {
                      if (cellData === false) {
                        ReactDOM.render(
                          <FontAwesome icon="circle" className="disabled" />,
                          td,
                        );
                      } else {
                        ReactDOM.render(
                          <FontAwesome icon="circle" className="enabled" />,
                          td,
                        );
                      }
                    },
                  },
                  {
                    targets: 3,
                    render: data => {
                      return `${checkUser(data)}`;
                    },
                  },
                  {
                    targets: 4,
                    render: data => {
                      return `<a  href="/admin/user-lookup/${data.id}">Edit</a>`;
                    },
                  },
                ],
              }}
            >
              <Table
                className={'usersTable'}
                bordered
                striped
                condensed
                head={
                  <TableRow>
                    <TableCell heading textCenter className="active-column"></TableCell>
                    <TableCell heading textCenter className="name-column">
                      Name
                    </TableCell>
                    <TableCell heading textCenter className="email-column">
                      Email
                    </TableCell>
                    <TableCell heading textCenter className="user-type-column">
                      User Type
                    </TableCell>
                    <TableCell heading textCenter className="edit-column">
                      Edit User
                    </TableCell>
                  </TableRow>
                }
              >
                <TableRow></TableRow>
              </Table>
            </ImprovedDataTable>
          ) : (
            <Table
              className={'usersTable'}
              bordered
              striped
              condensed
              head={
                <TableRow>
                  <TableCell heading textCenter className="active-column"></TableCell>
                  <TableCell heading textCenter className="name-column">
                    Name
                  </TableCell>
                  <TableCell heading textCenter className="email-column">
                    Email
                  </TableCell>
                  <TableCell heading textCenter className="user-type-column">
                    User Type
                  </TableCell>
                  <TableCell heading textCenter className="edit-column">
                    Edit User
                  </TableCell>
                </TableRow>
              }
            >
              <TableRow>
                <TableCell textCenter colSpan={5}>
                  No users found.
                </TableCell>
              </TableRow>
            </Table>
          )
        ) : null}
      </WidgetBody>
    </Widget>
  );
};

export default UserManagementUserLookup;
