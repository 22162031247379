import React from 'react';

import StandardLayout from 'sow/components/templates/StandardLayout';

const AdminObjectTemplate = ({ title, children, breadcrumb, pageName }) => (
  <StandardLayout title={title} breadcrumb={breadcrumb} pageName={pageName}>
    {children}
  </StandardLayout>
);

export default AdminObjectTemplate;
