import { createActions } from 'redux-actions';
import * as R from 'ramda';

const actionCreators = createActions({
  INSPECTOR: {
    ASSIGN_ACCESS: (orgId, inspectorId) => ({ orgId, inspectorId }),
    ASSIGN_ACCESS_DONE: R.identity,
    ASSIGN_ACCESS_FAIL: R.identity,

    LOAD_LIST: R.identity,
    LOAD_LIST_DONE: R.identity,
    LOAD_LIST_FAIL: R.identity,

    LOAD_ACCESS_LIST: orgId => ({ orgId }),
    LOAD_ACCESS_LIST_DONE: R.identity,
    LOAD_ACCESS_LIST_FAIL: R.identity,

    REVOKE_ACCESS: (orgId, inspectorId) => ({
      orgId,
      inspectorId,
    }),
    REVOKE_ACCESS_DONE: R.identity,
    REVOKE_ACCESS_FAIL: R.identity,
  },
});

export default actionCreators.inspector;
