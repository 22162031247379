import React from 'react';
import styled from 'styled-components';

import { isDeployQA } from 'sow/config';

const Ribbon = styled.div`
  z-index: 99999;
  width: 200px;
  background: #e43;
  position: fixed;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 50px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  box-shadow: 5px 5px 5px #111;
`;

const BranchRibbon = () => {
  if (!isDeployQA) return null;
  return <Ribbon>QA</Ribbon>;
};

export default BranchRibbon;
