import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as userManagementActions from 'sow/actions/pure/userManagement';
import { apiGet, apiDelete, apiPut, apiPost } from 'sow/sagas/api';

export const getUsers = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/aca/admin/users', payload);

    if (response) {
      yield put(userManagementActions.loadUsersDone(response.data));
      if (meta.onSuccess) {
        yield call(meta.onSuccess);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSingleUser = function*({ payload }) {
  try {
    const { response } = yield call(apiGet, '/aca/admin/users', {
      searchQuery: { userId: payload },
    });

    if (response) {
      const userData = response.data;
      try {
        const { response } = yield call(apiGet, `/user/edit/${payload}`);

        if (response) {
          yield put(
            userManagementActions.loadSingleUserDone(
              (response.data.length > 0 && response.data) || userData,
            ),
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const getUserHistory = function*({ payload }) {
  try {
    const { response } = yield call(apiGet, `/user/history/${payload}`);

    if (response) {
      yield put(userManagementActions.loadUserHistoryDone(response.data.user_history));
    }
  } catch (error) {
    console.error(error);
  }
};

export const loadSingleRequestedUser = function*({ payload }) {
  const { orgId, userId, userTypeId } = payload;
  try {
    const { response } = yield call(apiGet, `/org_users/${orgId}/${userId}`, {
      user_type_id: userTypeId,
    });
    if (response) {
      yield put(userManagementActions.loadRequestedUserDone(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const revokeUser = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiDelete, `/user/delete/org`, {
      user: payload,
    });

    if (response.data) {
      yield call(meta.onSuccess, response.data);
    } else if (response.hasOwnProperty('conflict') && meta.onFailure) {
      yield call(meta.onFailure, response.conflict, response.user);
    }
  } catch (error) {
    console.log(error);
  }
};

export const editUser = function*({ payload, meta }) {
  const { user } = payload;
  const { id } = user;
  try {
    const { response } = yield call(apiPut, `/user/edit/${id}`, { user });
    if (response && meta.onSuccess) {
      yield call(meta.onSuccess, response.data);
    }
  } catch (error) {
    console.log(error);
  }
};

export const newUser = function*({ payload, meta }) {
  const { user } = payload;

  try {
    const { response } = yield call(apiPost, `/user/register/new`, { user });

    if (response && meta) {
      yield call(meta.onSuccess, response.data);
    }
  } catch (error) {
    console.log(error);
  }
};

export const assignOrgToUser = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, `/user/register/${payload.user_id}`, {
      user: payload,
    });

    if (response.error) {
      yield put(actions.shell.toast('danger', response.error));
    } else if (response.data && meta) {
      yield call(meta.onSuccess, response.data);
    } else if (response.hasOwnProperty('conflict') && meta.onFailure) {
      yield call(meta.onFailure, response.conflict, false);
    } else if (response.updated) {
      yield put(actions.shell.toast('success', response.updated.message));
      yield call(meta.onSuccess, response.updated.data);
    }
  } catch (error) {
    console.log(error);
  }
};

export const assignInspectorToOrg = function*({ payload, meta }) {
  const { orgId, inspectorId, userSubtypeId, inspectionId } = payload;

  try {
    const { response } = yield call(
      apiPost,
      `/org_users/${orgId}/inspector/${inspectorId}`,
      { user_subtype_id: userSubtypeId, inspection_id: inspectionId },
    );

    if (response.data && meta.onSuccess) {
      yield call(meta.onSuccess, { user_id: response.data.user_id });
    } else if (response.error) {
      yield put(actions.shell.toast('danger', response.error));
    }
  } catch (error) {
    console.log(error);
  }
};

export const removeInspectorFromOrg = function*({ payload, meta }) {
  const { rowId, orgId, userId } = payload;

  try {
    const { response } = yield call(
      apiDelete,
      `/org_users/${orgId}/inspector/${userId}`,
      {
        row_id: rowId,
      },
    );
    if (response && meta.onSuccess) {
      yield call(meta.onSuccess, { user_id: response.data.user_id });
    } else if (response.error) {
      yield put(actions.shell.toast('danger', response.error));
    }
  } catch (error) {
    console.log(error);
  }
};

export const addCSToOrg = function*({ payload, meta }) {
  const { orgId, userId, userTypeId, userSubtypeId } = payload;
  try {
    const { response } = yield call(apiPut, `/org/${orgId}/users/${userId}/role`, {
      role: 'certification_specialist',
      userTypeId,
      userSubtypeId,
    });
    if (response && meta) {
      yield call(meta.onSuccess, response);
    }
  } catch (error) {
    console.log(error);
  }
};

export const removeCSFromOrg = function*({ payload, meta }) {
  const { orgId, userId, userTypeId, userSubtypeId } = payload;
  try {
    const { response } = yield call(apiDelete, `/org/${orgId}/users/${userId}/role`, {
      role: 'certification_specialist',
      userTypeId,
      userSubtypeId,
    });
    if (response && meta) {
      yield call(meta.onSuccess, { user_id: response.user_id });
    }
  } catch (error) {
    console.log(error);
  }
};

export const loadFormOptions = function*({ payload }) {
  try {
    const { response } = yield call(apiGet, '/user/options', { aca_id: payload });
    if (response) {
      yield put(userManagementActions.loadFormOptionsDone(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const loadRequestedOrgUsers = function*({ payload }) {
  const { orgId, userTypeId, selectOptions } = payload;
  try {
    const { response } = yield call(apiGet, '/org/requested_org_users', {
      org_id: orgId,
      user_type_id: userTypeId,
      select_options: selectOptions == true,
    });

    if (response) {
      yield put(userManagementActions.loadRequestedUsersDone(response));
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateOrgContact = function*({ payload, meta }) {
  const { orgId, userId } = payload;
  try {
    const { response, error } = yield call(apiPut, `/org_users/${orgId}/user/${userId}`, {
      params: payload,
    });

    if (error && error.status && error.status === 403 && meta.onFailure) {
      yield call(meta.onFailure);
    }

    if (response && response.data.id) {
      yield put(actions.shell.toast('success', 'User updated successfully.'));
    }
  } catch (error) {
    console.error(error);
  }
};

export const orgsRemovableByPrimary = function*({ payload, meta }) {
  const { orgId, userId } = payload;
  try {
    const { response } = yield call(apiGet, `/org_users/${orgId}/orgs/${userId}`);
    if (response && meta.onSuccess) {
      yield put(userManagementActions.fetchManageableOrgsDone(response.data));
      meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

// User add function for contact page.
export const contactPageAddClientToOrg = function*({ payload, meta }) {
  const { email, orgId } = payload;

  try {
    const { response } = yield call(apiPost, `/org_users/${orgId}/user/add`, {
      email,
    });

    if (response.error && meta.onFailure) {
      meta.onFailure();
      yield put(actions.shell.toast('danger', response.error));
    } else if (response.compatible && meta.onFailure) {
      meta.onFailure();
      yield put(actions.shell.toast('danger', response.compatible));
    } else if (response.data && !response.data.new && meta.onSuccess) {
      meta.onSuccess(response.data);
      yield put(actions.shell.toast('success', 'User added to organization.'));
    } else if (response.data && response.data.new && meta.onSuccess) {
      meta.onSuccess(response.data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const createUserAddToOrg = function*({ payload, meta }) {
  try {
    const { user, orgId, welcomeEmail } = payload;

    const { response } = yield call(apiPost, `/org_users/${orgId}/user/new`, {
      user,
      welcome_email: welcomeEmail,
    });

    if (response.data && meta.onSuccess) {
      meta.onSuccess(response);
      yield put(actions.shell.toast('success', 'User added to organization.'));
    }
  } catch (error) {
    console.error(error);
  }
};

export const addOrUpdateUserPriority = function*({ payload, meta }) {
  const { orgId, appId } = payload;

  const { response } = yield call(
    apiPut,
    `/org/${orgId}/application/${appId}/add_or_update_priority`,
    payload,
  );

  if (response && meta.onSuccess) {
    meta.onSuccess({ user_id: response.organization_user.user_id });
  }
};

export const removeUserPriority = function*({ payload, meta }) {
  const { orgId, appId } = payload;

  const { response } = yield call(
    apiPut,
    `/org/${orgId}/application/${appId}/remove_priority`,
    payload,
  );

  if (response && meta.onSuccess) {
    meta.onSuccess({ user_id: response.organization_user.user_id });
  }
};

export default function*() {
  yield takeEvery(userManagementActions.LOAD_ALL_USERS, getUsers);
  yield takeEvery(userManagementActions.LOAD_SINGLE_USER, getSingleUser);
  yield takeEvery(userManagementActions.LOAD_USER_HISTORY, getUserHistory);
  yield takeEvery(userManagementActions.REMOVE_USER_FROM_ORG, revokeUser);
  yield takeEvery(userManagementActions.LOAD_USER_FOR_EDIT_REQUEST, editUser);
  yield takeEvery(userManagementActions.ADD_USER_REQUEST, newUser);
  yield takeEvery(userManagementActions.ASSIGN_USER_TO_ORG, assignOrgToUser);
  yield takeEvery(userManagementActions.ASSIGN_INSPECTOR_TO_ORG, assignInspectorToOrg);
  yield takeEvery(userManagementActions.REMOVE_INSPECTOR, removeInspectorFromOrg);
  yield takeEvery(userManagementActions.ADD_SPECIALIST, addCSToOrg);
  yield takeEvery(userManagementActions.REMOVE_SPECIALIST, removeCSFromOrg);
  yield takeEvery(userManagementActions.LOAD_FORM_OPTIONS, loadFormOptions);
  yield takeEvery(userManagementActions.LOAD_REQUESTED_ORG_USERS, loadRequestedOrgUsers);
  yield takeEvery(userManagementActions.LOAD_REQUESTED_ORG_USER, loadSingleRequestedUser);
  yield takeEvery(userManagementActions.UPDATE_ORG_CONTACT, updateOrgContact);
  yield takeEvery(userManagementActions.FETCH_MANAGEABLE_ORGS, orgsRemovableByPrimary);
  yield takeEvery(
    userManagementActions.CONTACT_PAGE_ADD_CLIENT_TO_ORG,
    contactPageAddClientToOrg,
  );
  yield takeEvery(userManagementActions.CREATE_USER_ADD_TO_ORG, createUserAddToOrg);
  yield takeEvery(
    userManagementActions.ADD_OR_UPDATE_USER_PRIORITY,
    addOrUpdateUserPriority,
  );
  yield takeEvery(userManagementActions.REMOVE_USER_PRIORITY, removeUserPriority);
}
