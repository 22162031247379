import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// TODO move these somewhere sane
import VirtualizedSelect from 'react-virtualized-select';
import { Creatable as SelectCreatable } from 'react-select';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';

export const attachmentListCategories = R.compose(
  R.uniq,
  R.reject(R.isNil),
  R.pluck('category'),
);

const listToSelectOptions = R.map(item => ({
  label: item,
  value: item,
}));

const mapStateToProps = (state, props) => ({
  attachmentList: state.attachment.items,
  categories: attachmentListCategories(state.attachment.items),
});

export class AttachmentCategorySelect extends React.Component {
  state = {
    // selectValue: this.props.defaultValue,
  };

  getSelectOptions = () => {
    const { categories, value } = this.props;
    const { selectedValue } = this.state;
    const optionList = R.union([value], categories);
    return listToSelectOptions(optionList);
  };

  handleSelectValue = selectValue => {
    this.setState({ selectValue });
    this.props.onChange(R.propOr(null, 'value', selectValue));
  };

  render() {
    return (
      <VirtualizedSelect
        options={this.getSelectOptions()}
        onChange={this.handleSelectValue}
        value={this.props.value}
        selectComponent={SelectCreatable}
      />
    );
  }
}

export default R.compose(withRouter, connect(mapStateToProps))(AttachmentCategorySelect);
