import React from 'react';
import { Modal } from 'react-bootstrap';

const handleSubmit = onSubmit => event => {
  event.preventDefault();
  onSubmit();
};

export const FormModal = ({
  bsSize,
  children,
  onHide,
  onSubmit,
  show,
  title = 'Edit',
}) => (
  <Modal show={show} onHide={onHide} bsSize={bsSize} backdrop="static">
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <button type="submit" className="btn btn-primary">
          Done
        </button>
      </Modal.Footer>
    </form>
  </Modal>
);

export default FormModal;
