import React from 'react';

import {
  itemRoute,
  itemTypeRoute,
  itemSubtypeRoute,
  itemVersionRoute,
  itemAttributeTypeRoute,
  itemSectionTypeRoute,
} from 'sow/routes';

import SystemAdminTemplateContainer from 'sow/containers/SystemAdminTemplateContainer';
import Block from 'sow/components/atoms/Block';
import Link from 'sow/components/atoms/Link';
import Paragraph from 'sow/components/atoms/Paragraph';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import FontAwesome from 'sow/components/atoms/FontAwesome';

const SystemAdminItemHome = () => (
  <SystemAdminTemplateContainer>
    <Widget className="nav-widget">
      <WidgetBody>
        <Block className="system-admin-home">
          <Paragraph>Click a field below to edit its list of options</Paragraph>
          <Row>
            {/* Copy this block of code to create new admin option. */}
            <Column xs={12} sm={6} md={4} lg={3}>
              <Link className="field-block-link" to={itemTypeRoute()}>
                <Widget className="field-block">
                  <WidgetBody className="field-block-body">
                    <FontAwesome icon="boxes-stacked" />
                    <Paragraph>Item Types</Paragraph>
                  </WidgetBody>
                </Widget>
              </Link>
            </Column>
            <Column xs={12} sm={6} md={4} lg={3}>
              <Link className="field-block-link" to={itemSubtypeRoute()}>
                <Widget className="field-block">
                  <WidgetBody className="field-block-body">
                    <FontAwesome icon="boxes-stacked" />
                    <Paragraph>Item Subtypes</Paragraph>
                  </WidgetBody>
                </Widget>
              </Link>
            </Column>
            <Column xs={12} sm={6} md={4} lg={3}>
              <Link className="field-block-link" to={itemRoute()}>
                <Widget className="field-block">
                  <WidgetBody className="field-block-body">
                    <FontAwesome icon="boxes-stacked" />
                    <Paragraph>Items</Paragraph>
                  </WidgetBody>
                </Widget>
              </Link>
            </Column>
            <Column xs={12} sm={6} md={4} lg={3}>
              <Link className="field-block-link" to={itemVersionRoute()}>
                <Widget className="field-block">
                  <WidgetBody className="field-block-body">
                    <FontAwesome icon="boxes-stacked" />
                    <Paragraph>Item Versions</Paragraph>
                  </WidgetBody>
                </Widget>
              </Link>
            </Column>
            <Column xs={12} sm={6} md={4} lg={3}>
              <Link className="field-block-link" to={itemAttributeTypeRoute()}>
                <Widget className="field-block">
                  <WidgetBody className="field-block-body">
                    <FontAwesome icon="square-list" />
                    <Paragraph>Item Attribute Types</Paragraph>
                  </WidgetBody>
                </Widget>
              </Link>
            </Column>
            <Column xs={12} sm={6} md={4} lg={3}>
              <Link className="field-block-link" to={itemSectionTypeRoute()}>
                <Widget className="field-block">
                  <WidgetBody className="field-block-body">
                    <FontAwesome icon="grid-dividers" />
                    <Paragraph>Item Section Types</Paragraph>
                  </WidgetBody>
                </Widget>
              </Link>
            </Column>
          </Row>
        </Block>
      </WidgetBody>
    </Widget>
  </SystemAdminTemplateContainer>
);

export default SystemAdminItemHome;
