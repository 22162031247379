import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { push as routerPush, replace as routerReplace } from 'connected-react-router';

import { itemRoute, itemEditRoute } from 'sow/routes';

import * as itemActions from 'sow/actions/pure/item';

import ItemEdit from 'sow/components/organisms/ItemEdit';

const mapStateToProps = (state, props) => {
  const itemId = R.path(['match', 'params', 'id'], props);

  return {
    isNew: R.equals(itemId, 'new'),
    itemId: itemId,
  };
};

const mapDispatchToProps = {
  loadOptions: itemActions.loadOptions,
  loadItemById: itemActions.fetchById,
  addItem: itemActions.addItem,
  updateItem: itemActions.updateItem,
  deleteItem: itemActions.deleteItem,
  redirect: routerPush,
  routerReplace: routerReplace,
};

class ItemEditContainer extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = {
      isLoading: true,
      initialValues: {},
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadAdminOptions();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadAdminOptions() {
    if (this._isMounted) {
      this.props.loadOptions(this.loadItem.bind(this));
    }
  }

  loadItem({ data }) {
    if (this._isMounted) {
      const { isNew, itemId } = this.props;

      this.setState({
        itemTypeOptions: data.item_type_options,
        itemSubtypeOptions: data.item_subtype_options,
      });

      if (!isNew) {
        this.props.loadItemById(itemId, this.updateState.bind(this));
      } else {
        this.setState({ isNew: true, isLoading: false });
      }
    }
  }

  updateState({ data }) {
    if (this._isMounted) {
      if (this.state.isNew) {
        this.props.routerReplace(itemEditRoute(data.id));
      }

      if (!R.isEmpty(data)) {
        this.setState({
          isNew: false,
          isLoading: false,
          initialValues: { ...data },
        });
      } else {
        this.redirectToList();
      }
    }
  }

  redirectToList() {
    this.props.routerReplace(itemRoute());
  }

  handleSave(values, formikBag) {
    if (this.state.isNew) {
      this.props.addItem(values, this.updateState.bind(this));
    } else {
      this.props.updateItem(this.props.itemId, values, this.updateState.bind(this));
    }
  }

  handleDelete() {
    const { itemId, deleteItem } = this.props;
    deleteItem(itemId, this.redirectToList.bind(this));
  }

  render() {
    return (
      <ItemEdit
        onSave={this.handleSave.bind(this)}
        onDelete={this.handleDelete.bind(this)}
        {...this.state}
        {...this.props}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ItemEditContainer);
