import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push as routerPush } from 'connected-react-router';
import PropTypes from 'prop-types';

import actions from 'sow/actions/pure';
import { orgRoute } from 'sow/routes';
import { resourceCreate } from 'sow/store/helpers';
import { orgIdType, acaIdType } from 'sow/types';
import * as currentOrg from 'sow/selectors/currentOrg';
import * as currentAca from 'sow/selectors/currentAca';
import MigrateOrgToAcaForm from 'sow/components/organisms/MigrateOrgToAcaForm';

const mapStateToProps = (state, props) => ({
  orgId: currentOrg.orgId(state, props),
  acaId: currentAca.acaId(state, props),
});

const mapDispatchToProps = dispatch => {
  return {
    migrateOrg: (orgId, fromAcaId, toAcaId) => {
      const { action } = resourceCreate(
        `admin/migrate_org/${orgId}/from/${fromAcaId}/to/${toAcaId}`,
      );
      return dispatch(action());
    },
    notify: (...args) => dispatch(actions.shell.toast(...args)),
    routerPush: (...args) => dispatch(routerPush(...args)),
  };
};

class MigrateOrgToAcaFormContainer extends Component {
  handleSubmit = (values, formikBag) => {
    const confirmText =
      'This organization will be copied to the selected ACA. This action cannot be undone. Would you like to continue?';
    if (!window.confirm(confirmText)) return;

    const { orgId, acaId: fromAcaId, migrateOrg, routerPush, notify } = this.props;
    const { acaId: toAcaId } = values;

    migrateOrg(orgId, fromAcaId, toAcaId)
      .then(({ data }) => {
        const newOrgId = data.id;
        notify('success', `Organization copied to new organization id ${newOrgId}.`);
        routerPush(orgRoute(data.id));
      })
      .catch(err => {
        notify('danger', 'Error migrating organization; see console for details.');
        console.log('Error migrating org:', err);
        formikBag.setSubmitting(false);
      });
  };

  render() {
    return <MigrateOrgToAcaForm onSubmit={this.handleSubmit} />;
  }
}

MigrateOrgToAcaFormContainer.propTypes = {
  orgId: orgIdType.isRequired,
  acaId: acaIdType.isRequired,
  migrateOrg: PropTypes.func.isRequired,
  routerPush: PropTypes.func.isRequired,
};

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(MigrateOrgToAcaFormContainer);
