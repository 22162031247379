import { apiGet, apiPost } from 'sow/utils/apiNew';
import { apiUrl } from 'sow/config';

export const baseUrl = `${apiUrl}/user`;
export const registerUrl = () => `${baseUrl}/register`;
export const userUrl = userId => `${baseUrl}/${userId}`;
export const verifyEmailUrl = emailHash => `${baseUrl}/verify_email/${emailHash}`;

export const register = user => {
  const url = registerUrl();
  return apiPost(url, { user });
};

export const verifyEmail = emailHash => {
  const url = verifyEmailUrl(emailHash);
  return apiGet(url);
};
