import React from 'react';
import { Field } from 'formik';

import AcaSelect from 'sow/containers/AcaSelect';
import FormikField from 'sow/components/organisms/FormikField';

function OrgSettingsCertifierForm({ disabled }) {
  return (
    <Field
      name="acaId"
      label="Select the certifying agency you are registering with"
      component={FormikField}
      type={AcaSelect}
      disabled={disabled}
      required
    />
  );
}

export default OrgSettingsCertifierForm;
