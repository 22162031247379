import { call, put, takeEvery } from 'redux-saga/effects';

import actions from 'sow/actions/pure';
import * as accessTypeActions from 'sow/actions/pure/accessType';
import { apiGet, apiPost } from 'sow/sagas/api';

export const getAccessTypes = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiGet, '/admin/access_type', payload);
    if (response.data) {
      if (meta.onSuccess) meta.onSuccess(response);
      else yield put(accessTypeActions.loadAccessTypesDone(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAccessTypeById = function*({ id, meta }) {
  try {
    const { response } = yield call(apiGet, `/admin/access_type/${id}`);
    if (response.data) {
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const addAccessType = function*({ payload, meta }) {
  try {
    const { response } = yield call(apiPost, '/admin/access_type', payload);

    if (response.data) {
      yield put(actions.shell.toast('success', 'Access type added.'));
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateAccessType = function*({ id, payload, meta }) {
  try {
    const { response } = yield call(apiPost, `/admin/access_type/${id}`, payload);

    if (response && response.data) {
      yield put(actions.shell.toast('success', 'Access type updated.'));
      if (meta.onSuccess) meta.onSuccess(response);
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteAccessType = function*({ id, meta }) {
  try {
    const { response } = yield call(apiPost, `/admin/access_type/delete/${id}`);
    if (response.data) {
      yield put(actions.shell.toast('success', 'Access type deleted.'));
      if (meta.onSuccess) meta.onSuccess();
    }
  } catch (error) {
    console.error(error);
  }
};

export default function*() {
  yield takeEvery(accessTypeActions.LOAD_ACCESS_TYPES, getAccessTypes);
  yield takeEvery(accessTypeActions.LOAD_ACCESS_TYPE_BY_ID, getAccessTypeById);
  yield takeEvery(accessTypeActions.ADD_ACCESS_TYPE, addAccessType);
  yield takeEvery(accessTypeActions.UPDATE_ACCESS_TYPE, updateAccessType);
  yield takeEvery(accessTypeActions.DELETE_ACCESS_TYPE, deleteAccessType);
}
