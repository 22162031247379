import React, { Fragment } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';

import * as sowTypes from 'sow/types';
import Atom from 'sow/components/atoms/Atom';
import Block from 'sow/components/atoms/Block';
import Heading from 'sow/components/atoms/Heading';
import Image from 'sow/components/atoms/Image';
import Spacer from 'sow/components/atoms/Spacer';
import Widget from 'sow/components/molecules/Widget';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import WidgetFooter from 'sow/components/molecules/WidgetFooter';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Label from 'sow/components/molecules/Label';
import Tooltip from 'sow/components/molecules/Tooltip';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import FormattedAddress from './FormattedAddress';
import ChangeBanner from './ChangeBanner';
import Clearfix from 'sow/components/atoms/Clearfix';

const ScopeTypeLabel = styled(Atom)`
  font-size: 8pt;
  color: ${({ theme }) => theme.colors.lightGrey};
`;

const ImageWrapper = styled(Block)`
  text-align: center;
`;

const BannerWrapperSmallLayout = styled(Block)`
  margin: -20px -20px 10px -20px;
`;

const BannerWrapperLargeLayout = styled(Block)`
  position: absolute;
  top: -20px;
  left: -20px;
  width: calc(100% + 20px);
`;

const WidgetBodyWrapper = styled(WidgetBody)`
  background: ${({ isDeleted }) =>
    isDeleted &&
    `linear-gradient(
      to top left,
      rgba(0,0,0,0) 0%,
      rgba(0,0,0,0) calc(50% - 1px),
      rgba(255, 0, 0, 0.5) 50%,
      rgba(0,0,0,0) calc(50% + 1px),
      rgba(0,0,0,0) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0,0,0,0) 0%,
      rgba(0,0,0,0) calc(50% - 1px),
      rgba(255, 0, 0, 0.5) 50%,
      rgba(0,0,0,0) calc(50% + 1px),
      rgba(0,0,0,0) 100%
    )`};
  background-color: ${({ isExcluded }) => (isExcluded ? 'rgb(255, 240, 240)' : null)};
  color: ${({ isExcluded }) => (isExcluded ? 'rgb(130, 120, 120)' : null)};
`;

const WidgetFooterWrapper = styled(WidgetFooter)`
  background-color: rgb(245, 245, 245);
  text-align: right;
`;

const ReadyForReviewLabel = styled(Label)`
  background-color: ${({ theme, readyForReview }) =>
    readyForReview ? theme.colors.green : rgba(theme.colors.lightGrey, 0.5)};
`;

const PlanAppLocationCard = ({
  locationDetails,
  children,
  isNew,
  isUpdated,
  isDeleted,
  changeState,
  changeId,
  isReadyForReview,
  isStateInitialApplication,
  isExcluded,
}) => {
  const showBanner = R.any(R.equals(true))([isNew, isUpdated, isDeleted]);
  const showReadyForReview = isStateInitialApplication && isReadyForReview;
  const showFooter = showReadyForReview || isExcluded;

  return (
    <Widget>
      <WidgetBodyWrapper isDeleted={isDeleted} isExcluded={isExcluded}>
        <Row>
          <Column md={3} lg={2} textCenter>
            <Block style={{ position: 'relative' }}>
              <ImageWrapper hiddenXs hiddenSm>
                <Image
                  src={
                    locationDetails.imageSrc
                      ? locationDetails.imageSrc
                      : `/assets/images/icon-${locationDetails.type}.png`
                  }
                  style={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: '128px',
                  }}
                />
              </ImageWrapper>

              {showBanner && (
                <Fragment>
                  <BannerWrapperLargeLayout hiddenXs hiddenSm>
                    <ChangeBanner
                      isNew={isNew}
                      isUpdated={isUpdated}
                      isDeleted={isDeleted}
                      changeState={changeState}
                      changeId={changeId}
                    />
                  </BannerWrapperLargeLayout>
                  <BannerWrapperSmallLayout visibleXs visibleSm>
                    <ChangeBanner
                      isNew={isNew}
                      isUpdated={isUpdated}
                      isDeleted={isDeleted}
                      changeState={changeState}
                      changeId={changeId}
                    />
                  </BannerWrapperSmallLayout>
                </Fragment>
              )}
            </Block>
          </Column>

          <Column xs={12} sm={12} md={9} lg={10}>
            <Block pullRight textRight>
              <Heading level={4} noMargin>
                <ScopeTypeLabel>Type:</ScopeTypeLabel> {locationDetails.type}
              </Heading>

              <Spacer vertical={5} />
              {children}
            </Block>

            <Heading level={3} noMargin>
              {locationDetails.name}
            </Heading>
            <FormattedAddress loc={locationDetails.location} />
          </Column>
        </Row>
      </WidgetBodyWrapper>

      {showFooter && (
        <WidgetFooterWrapper>
          {isExcluded && (
            <Block pullLeft>
              <Tooltip overlay="This location is not considered part of this plan">
                <Label danger>Excluded from Plan</Label>
              </Tooltip>
            </Block>
          )}

          {showReadyForReview && (
            <Fragment>
              Ready for review:{' '}
              <Block inline className="ws-status">
                <ReadyForReviewLabel readyForReview={isReadyForReview}>
                  <FontAwesome icon="check" />
                </ReadyForReviewLabel>
              </Block>
            </Fragment>
          )}
          <Clearfix />
        </WidgetFooterWrapper>
      )}
    </Widget>
  );
};

PlanAppLocationCard.propTypes = {
  locationDetails: sowTypes.planAppLocationType.isRequired,
  locationTypes: PropTypes.array.isRequired,
  children: PropTypes.node,
  isNew: PropTypes.bool.isRequired,
  isUpdated: PropTypes.bool.isRequired,
  isDeleted: PropTypes.bool.isRequired,
  changeState: sowTypes.planAppChangeStateType,
  changeId: sowTypes.planAppChangeIdType,
  isReadyForReview: PropTypes.bool.isRequired,
  isStateInitialApplication: PropTypes.bool.isRequired,
  isExcluded: PropTypes.bool.isRequired,
};

PlanAppLocationCard.defaultProps = {
  locationTypes: [],
  children: null,
  changeState: null,
  changeId: null,
};

export default PlanAppLocationCard;
