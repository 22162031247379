import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { acaIdType, orgType } from 'sow/types';
import * as currentAca from 'sow/selectors/currentAca';
import AcaPageTemplate from 'sow/containers/AcaPageTemplate';
import AcaOrgListPage from 'sow/components/pages/AcaOrgListPage';
import AcaOrgListLoader from 'sow/containers/AcaOrgListLoader';

const sortOrgsByName = R.sortWith([R.ascend(R.prop('name'))]);

const mapStateToProps = (state, props) => ({
  acaId: currentAca.acaId(state, props),
  orgMap: currentAca.orgMap(state, props),
  orgList: sortOrgsByName(currentAca.orgs(state, props)),
});

const sortOrgList = R.sortWith([R.ascend(R.prop('name'))]);

class AcaOrgListPageContainer extends Component {
  state = {
    searchText: '',
    sortOrder: [],
  };

  componentDidMount() {
    this.updateSortOrder(this.props.orgList);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.orgList !== nextProps.orgList) {
      this.updateSortOrder(nextProps.orgList);
    }
  }

  updateSortOrder(orgList) {
    const sortOrderedOrgList = sortOrgList(orgList);
    const sortOrderOrgIds = R.pluck('id', sortOrderedOrgList);
    this.setState(R.assoc('sortOrder', sortOrderOrgIds));
  }

  handleSearchTextChange = event => {
    event.preventDefault();
    this.setState(R.assoc('searchText', event.target.value));
  };

  searchResults = () => {
    const { orgList, orgMap } = this.props;
    const { searchText, sortOrder } = this.state;

    if (orgList && !R.isEmpty(orgList)) {
      if (R.length(searchText) > 0) {
        const searchPred = string => R.toLower(string).includes(R.toLower(searchText));
        return R.compose(
          R.filter(
            R.either(
              this.filterClientId(searchPred),
              R.propSatisfies(searchPred, 'name'),
            ),
          ),
          R.map(R.prop(R.__, orgMap)),
        )(sortOrder);
      }

      return R.map(R.prop(R.__, orgMap), sortOrder);
      // const searchTarget = R.trim(searchText);
      // const results = R.find(R.propSatisfies(R.test(searchTarget, 2))(xs); //=> {a: 2}

      return R.take(10, orgList);
      return [];
    }

    return [];
  };

  filterClientId = searchPred => org => {
    const clientId = this.getOrgSettings('client_id_value', org);
    if (!clientId) return false;
    return searchPred(clientId);
  };

  getOrgSettingsPath = field => {
    const { acaId } = this.props;
    return ['settings', 'aca_byid', `${acaId}`, field];
  };

  getOrgSettings = R.curry((field, org) => {
    return R.path(this.getOrgSettingsPath(field), org);
  });

  render() {
    const { searchText } = this.state;

    return (
      <AcaPageTemplate title="Organizations">
        <AcaOrgListLoader>
          <AcaOrgListPage
            orgList={this.searchResults()}
            searchText={searchText}
            onSearchTextChange={this.handleSearchTextChange}
          />
        </AcaOrgListLoader>
      </AcaPageTemplate>
    );
  }
}

AcaOrgListPageContainer.propTypes = {
  acaId: acaIdType.isRequired,
  orgList: PropTypes.arrayOf(orgType).isRequired,
  orgMap: PropTypes.objectOf(orgType).isRequired,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps),
)(AcaOrgListPageContainer);
