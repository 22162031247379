import { call, put, takeEvery } from 'redux-saga/effects';

import * as acaDocApi from 'sow/api/aca/certificationDocs';
import * as docActions from 'sow/actions/pure/certificationDocs';
import * as orgDocApi from 'sow/api/org/certificationDocs';
import actions from 'sow/actions/pure';

export function* loadAll({ payload }) {
  const { acaId, orgId } = payload;
  const loadFn = acaId ? acaDocApi.loadAll : orgDocApi.loadAll;
  const loadArg = acaId ? acaId : orgId;

  try {
    const res = yield call(loadFn, loadArg);
    yield put(docActions.loadAllDone(res.data));
  } catch (err) {
    yield put(actions.api.requestError(err));
    yield put(docActions.loadAllFail());
  }
}

export function* deleteDoc({ payload }) {
  const { acaId, docId } = payload;

  try {
    yield call(acaDocApi.deleteDoc, acaId, docId);
    yield put(docActions.deleteDocDone(docId));
  } catch (err) {
    yield put(actions.api.requestError(err));
  }
}

export default function*() {
  try {
    yield takeEvery(docActions.LOAD_ALL, loadAll);
    yield takeEvery(docActions.DELETE_DOC, deleteDoc);
  } catch (error) {
    console.error(error);
  }
}
