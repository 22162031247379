import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { orgIdType, planAppIdType, planAppStateType } from 'sow/types';
import { planAppStateText, planAppStates } from 'sow/constants/planApp';

import Button from 'sow/components/atoms/Button';
import Select from 'sow/components/molecules/Select';
import Tooltip from 'sow/components/molecules/Tooltip';
import FormikField from 'sow/components/organisms/FormikField';
import AppStateText from './AppStateText';
import Confirm from 'sow/components/molecules/Confirm';
import Paragraph from 'sow/components/atoms/Paragraph';
import Link from 'sow/components/atoms/Link';
// WO-329: Uncomment when WO is ready
// import List from 'sow/components/atoms/List';
// import ListItem from 'sow/components/atoms/ListItem';

const confirmMessage = state => {
  switch (state) {
    // WO-329
    // case 'in_final_review':
    //   return (
    //     <Fragment>
    //       <Paragraph>
    //         You are overriding the Inspector workflow process. If you override this
    //         process, the Inspector may not have finished one of the following steps:
    //       </Paragraph>
    //       <List>
    //         <ListItem>Entered Inspection Date</ListItem>
    //         <ListItem>Uploaded Exit Interview</ListItem>
    //         <ListItem>Uploaded Inspection Report</ListItem>
    //       </List>
    //       <Paragraph>
    //         Please click cancel, assign the inspector, and set the status to “Ready for
    //         Inspection” to start the Inspector workflow process. If you want to continue
    //         to override the Inspector workflow process, click save.
    //       </Paragraph>
    //     </Fragment>
    //   );
    case 'inspection':
      return (
        <Fragment>
          <Paragraph>
            An inspector hasn't been assigned. Please notify{' '}
            <Link mailTo={'inspections@wherefoodcomesfrom.com'}>
              inspections@wherefoodcomesfrom.com
            </Link>{' '}
            that this file is “Ready for Inspection” but does not have an inspector
            assigned at this time.
          </Paragraph>
          <Paragraph>
            If you want to continue without assigning an Inspector first, click Save.
          </Paragraph>
        </Fragment>
      );
    default:
      return;
  }
};

const checkPlanState = (state, finalReview, inspectorAccessList) => {
  // WO-329
  // if (state === 'in_final_review' && !finalReview) {
  //   return true;
  // } else
  if (state === 'inspection' && !inspectorAccessList.length) {
    return true;
  } else {
    return false;
  }
};

class OrgPlanAppEditStateModal extends Component {
  state = { showModal: false };

  showModal = () => this.setState({ showModal: true });
  hideModal = () => this.setState({ showModal: false });

  handleSubmit = values => {
    this.props.onSubmit(values.state).then(this.hideModal);
  };

  render() {
    const { showModal } = this.state;
    const { children, appState, inspectorAccessList, finalReview } = this.props;

    const validStates = [
      planAppStates.INITIAL_REVIEW,
      planAppStates.IN_INITIAL_REVIEW,
      planAppStates.INSPECTION,
      planAppStates.IN_INSPECTION,
      planAppStates.FINAL_REVIEW,
      planAppStates.IN_FINAL_REVIEW,
    ];

    const defaultValue = validStates.includes(appState) ? appState : '';

    // Formik setup
    const initialFormValues = { state: defaultValue };
    const validationSchema = Yup.object({ state: Yup.string().required() });
    const options = validStates.map(s => ({ value: s, text: planAppStateText[s] }));

    return (
      <Fragment>
        <Tooltip overlay="Change this application's state">
          {children(this.showModal)}
        </Tooltip>

        <Modal show={showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Application State</Modal.Title>
          </Modal.Header>

          <Formik
            onSubmit={this.handleSubmit}
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            {({ values, handleSubmit }) => (
              <Form>
                <Modal.Body>
                  {/* Not a field but an easy way to get the formatting the same as the dropdown */}
                  <Field
                    name="oldState"
                    label="Current application state:"
                    component={FormikField}
                    type={AppStateText}
                    appState={appState}
                  />

                  <Field
                    name="state"
                    label="New application state:"
                    component={FormikField}
                    type={Select}
                    options={options}
                  />
                </Modal.Body>

                <Modal.Footer>
                  <Button onClick={this.hideModal}>Cancel</Button>
                  {checkPlanState(values.state, finalReview, inspectorAccessList) ? (
                    <Confirm
                      message={confirmMessage(values.state)}
                      onConfirm={handleSubmit}
                      onCancel={this.hideModal}
                      confirmButtonText="Save"
                    >
                      {toggleConfirm => (
                        <Button onClick={toggleConfirm} primary>
                          Save
                        </Button>
                      )}
                    </Confirm>
                  ) : (
                    <Button type="submit" primary>
                      Save
                    </Button>
                  )}
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </Fragment>
    );
  }
}

OrgPlanAppEditStateModal.propTypes = {
  children: PropTypes.func.isRequired, // Modal toggle
  orgId: orgIdType.isRequired,
  planAppId: planAppIdType.isRequired,
  appState: planAppStateType.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default OrgPlanAppEditStateModal;
