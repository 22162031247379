import React from 'react';
import Block from 'sow/components/atoms/Block';
import Image from 'sow/components/atoms/Image';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Link from 'sow/components/atoms/Link';
import PropTypes from 'prop-types';

const HeaderContent = ({ isLogin }) => {
  const beeLogoSrc = isLogin
    ? '/assets/images/abee-ics-logo.png'
    : '/assets/images/abee-ics-logo-black.png';
  const arrowSrc = isLogin
    ? '/assets/images/logo-arrow.png'
    : '/assets/images/logo-arrow-black.png';
  const primaryLogoSrc = isLogin
    ? '/assets/images/WFCFO-logo-border.png'
    : '/assets/images/WFCFO-logo.png';

  return (
    <Block>
      <Row>
        <Column sm={8} smOffset={2}>
          <Block className="header-logo-wrapper">
            <Block className="logo-table">
              <Block className="logo-item">
                <Link href="https://www.wfcforganic.com/" target="_blank">
                  <Image
                    src="/assets/images/WFCFO-logo-border.png"
                    className="wfcf-organic-logo"
                    title="Where Food Comes From Organic"
                  />
                </Link>
              </Block>
            </Block>
          </Block>
        </Column>
      </Row>
    </Block>
  );
};

HeaderContent.propTypes = {
  isLogin: PropTypes.bool,
};

HeaderContent.defaultProps = {
  isLogin: false,
};

export default HeaderContent;
