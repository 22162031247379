import React, { Component } from 'react';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PicklistModalItemList from 'sow/components/organisms/ItemVersionPicklist/PicklistModalItemList';

const mapStateToProps = (state, props) => ({ itemList: props.itemList });

const mapDispatchToProps = {};

class PicklistModalItemListContainer extends Component {
  constructor(props) {
    super(props);
  }

  addItem() {
    const { setFieldValue, itemList } = this.props;

    itemList.push({
      id: 'new',
      name: '',
      sort_order: itemList.length + 1,
      allow_additional_info: false,
    });

    setFieldValue('picklist_item_list', itemList);
  }

  removeItem(index) {
    const { setFieldValue, itemList } = this.props;
    itemList.splice(index, 1);
    setFieldValue('picklist_item_list', itemList);
  }

  reorderList(dragIndex, hoverIndex) {
    const { itemList, setFieldValue } = this.props;
    let reorderedList = [];
    if (dragIndex < hoverIndex) {
      const listStart = itemList.slice(0, dragIndex);
      const listSecond = itemList.slice(dragIndex + 1, hoverIndex + 1);
      const listThird = [itemList[dragIndex]];
      const listEnd = itemList.slice(hoverIndex + 1);
      reorderedList = listStart.concat(listSecond, listThird, listEnd);
    } else if (dragIndex > hoverIndex) {
      const listStart = itemList.slice(0, hoverIndex);
      const listSecond = [itemList[dragIndex]];
      const listThird = itemList.slice(hoverIndex, dragIndex);
      const listEnd = itemList.slice(dragIndex + 1);
      reorderedList = listStart.concat(listSecond, listThird, listEnd);
    }
    setFieldValue('picklist_item_list', reorderedList);
  }

  updateOrder() {
    const { itemList, setFieldValue } = this.props;
    const newOrder = itemList.map((item, index) => {
      item.sort_order = index + 1;
      return item;
    });
    setFieldValue('picklist_item_list', newOrder);
  }

  render() {
    return (
      <PicklistModalItemList
        addItem={this.addItem.bind(this)}
        removeItem={this.removeItem.bind(this)}
        reorderList={this.reorderList.bind(this)}
        updateOrder={this.updateOrder.bind(this)}
        itemList={this.props.itemList}
      />
    );
  }
}

PicklistModalItemList.propTypes = {
  // Passed props
  itemList: PropTypes.array.isRequired,
};

PicklistModalItemList.defaultProps = {
  itemList: [],
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PicklistModalItemListContainer);
