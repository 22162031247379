import React from 'react';

import AcaSettingsPageTemplate from 'sow/components/templates/AcaSettingsPageTemplate';
import AcaSettingsWorkflowForm from 'sow/containers/AcaSettingsWorkflowForm';

const AcaSettingsWorkflowPage = () => (
  <AcaSettingsPageTemplate>
    <AcaSettingsWorkflowForm />
  </AcaSettingsPageTemplate>
);

export default AcaSettingsWorkflowPage;
