import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

export const ospAppSelectText = ospAppInfo =>
  `${ospAppInfo.osp_definition.name} #${ospAppInfo.id} (${ospAppInfo.state})`;

class OspAppSelectBar extends React.Component {
  static propTypes = {
    orgId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ospAppId: PropTypes.number,
    ospAppList: PropTypes.arrayOf(PropTypes.object),
    setCurrentOspApp: PropTypes.func,
  };

  handleChange = event => {
    const ospAppId = event.target.value;
    this.props.setCurrentOspApp(ospAppId);
  };

  render() {
    const { ospAppId, ospAppList } = this.props;

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="input-group">
            <span className="input-group-addon">Plan</span>
            <select
              id="ospAppSelectBarSelect"
              value={ospAppId || ''}
              onChange={this.handleChange}
              className="form-control"
            >
              {R.reverse(ospAppList).map(ospAppInfo => (
                <option key={ospAppInfo.id} value={ospAppInfo.id}>
                  {ospAppSelectText(ospAppInfo)}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

export default OspAppSelectBar;
