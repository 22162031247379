import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { organizationTypeRoute } from 'sow/routes';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Confirm from 'sow/components/molecules/Confirm';
import FormikField from 'sow/components/organisms/FormikField';
import FormikSingleCheckbox from 'sow/components/molecules/FormikSingleCheckbox';
import Button from 'sow/components/atoms/Button';
import HorizontalRule from 'sow/components/atoms/HorizontalRule';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import Spinner from 'sow/components/atoms/Spinner';

const formSchema = Yup.object({
  name: Yup.string()
    .ensure()
    .required('Name required.'),
});

const OrganizationTypeEdit = ({
  isLoading,
  isNew,
  initialValues,
  handleSave,
  handleDelete,
}) => (
  <Fragment>
    {isLoading ? (
      <Spinner />
    ) : (
      <Widget className="system-admin-list-edit">
        <WidgetHeading>{isNew ? 'Add' : 'Edit'}</WidgetHeading>
        <WidgetBody>
          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validationSchema={formSchema}
            onSubmit={handleSave}
            onReset={handleDelete}
          >
            {({ handleSubmit, handleReset }) => (
              <Form>
                <Row className="form-row">
                  <Column className="form-column" xs={4} sm={3} md={2}>
                    <Button className="form-button" onClick={handleSubmit} primary>
                      Save
                    </Button>
                  </Column>
                  <Column className="form-column" xs={4} sm={3} md={2}>
                    <Button
                      className="form-button reset-return-button"
                      to={organizationTypeRoute()}
                    >
                      Cancel
                    </Button>
                  </Column>
                  <Column sm={3} md={6}></Column>
                  <Column className="form-column" xs={4} sm={3} md={2}>
                    <Confirm
                      message="Are you sure you want to delete this organization type?"
                      onConfirm={handleReset}
                    >
                      {toggleConfirm => (
                        <Button
                          className="delete-button"
                          onClick={toggleConfirm}
                          disabled={isNew}
                        >
                          <FontAwesome className="button-icon" icon="trash" />
                          Delete
                        </Button>
                      )}
                    </Confirm>
                  </Column>
                </Row>
                <HorizontalRule className="divider" />
                <Row className="form-row">
                  <Column className="form-column" xs={12}>
                    <Field
                      className="input-filter"
                      label="Name"
                      placeholder=""
                      name={'name'}
                      component={FormikField}
                      required
                    />
                  </Column>
                  <Column className="form-column" xs={12}>
                    <Field
                      label="Status"
                      placeholder=""
                      name={'enabled'}
                      labelText="Enabled"
                      component={FormikField}
                      type={FormikSingleCheckbox}
                    />
                  </Column>
                </Row>
              </Form>
            )}
          </Formik>
        </WidgetBody>
      </Widget>
    )}
  </Fragment>
);

export default OrganizationTypeEdit;
