import React from 'react';

import AdminPageTemplate from 'sow/containers/AdminPageTemplate';
import AnnouncementHistoryContainer from 'sow/containers/AnnouncementHistoryContainer';

const AnnouncementHistoryPage = () => (
  <AdminPageTemplate title="System Administrator">
    {() => <AnnouncementHistoryContainer />}
  </AdminPageTemplate>
);

export default AnnouncementHistoryPage;
