import * as R from 'ramda';
import Immutable from 'seamless-immutable';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

export const trace = R.curry((tag, a) => {
  console.log(tag, a);
  return a;
});

// TODO (orther) rename this to getIn when original version removed
// export const getOr = (path, state, defaultValue) => {
//   if (R.isArrayLike(path)) return getPath(path);
//   if (R.is(String, path)) return getPath(R.split('.'));
//   throw new Error('Invalid `path` argument. Must be string or array.');
// };

// access nested data with getIn like Immutable.js api
export const getIn = (state, path, notSetValue) => {
  // console.error('getIn - DEPRECIATING - Use new getOr')
  if (_isArray(path)) path = path.join('.');
  return _get(state, path, notSetValue);
};

export const makeBuildData = defaultData => data => ({
  ...defaultData,
  ...data,
});

export const makeImmutable = (data = {}) => Immutable(data);

// build immutable data for actions dispatch to store with default properties
export const makeImmutableData = defaultData => data =>
  makeImmutable({
    ...defaultData,
    ...data,
  });

export const defaultToView = R.curry((view, base, changes) =>
  R.defaultTo(view(base), view(changes)),
);

export const mergeView = R.curry((view, base, changes) =>
  R.merge(view(base), view(changes)),
);

export const mergeWithView = R.curry((fn, view, base, changes) =>
  R.mergeWith(fn, view(base), view(changes)),
);

export const mergeWithKeyView = R.curry((fn, view, base, changes) =>
  R.mergeWithKey(fn, view(base), view(changes)),
);

/**
 * Takes a function and a list, and returns whichever value in the list that
 * produces the larger result when passed to the provided function.
 *
 * @sig a -> [b] -> b
 */
export const maxByList = compare =>
  R.converge(R.reduce(R.maxBy(compare)), [R.head, R.tail]);

// TODO (orther) clean this up after some rest; doesn't protect bad idx
export const moveListItemCloser = idx =>
  R.converge(R.insert(idx), [R.prop(idx - 1), R.remove(idx - 1, 1)]);

export const moveListItemAway = idx =>
  R.converge(R.insert(idx + 1), [R.prop(idx), R.remove(idx, 1)]);
